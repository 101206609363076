import _ from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { EntityType, RenditionType } from 'const';
import * as Models from 'models';
import { getDocumentRendition } from 'services/api';
import { runEventChanel } from 'services/EventChannel';
import { SubscribeFunction } from 'services/EventChannel/models';
import { ImageCache } from 'services/imageCache';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';
import { updateLayoutDocumentThumbnailUrl } from '../actions';

async function getVerifiedImageInternalInfo(previewUrl: string): Promise<Models.ImageInternalInfo> {
  try {
    return previewUrl ? await getImageInternalInfo(previewUrl) : null;
  } catch (error) {
    return null;
  }
}

export function* updateLayoutThumbnails(
  layoutDocument: Models.CombinedLayoutDocument[],
  action: (id: string, thumbnailInternalInfo: Models.ImageInternalInfo, entityType?: EntityType) => Models.IAction,
  needToStop = _.stubFalse, // any function that returns boolean value
): SagaIterator {
  try {
    const subscribeFunction: SubscribeFunction<ReturnType<typeof updateLayoutDocumentThumbnailUrl>, Models.CombinedLayoutDocument> = async (
      emitter,
      isDone,
      layoutDocument,
    ) => {
      const { id, documentId, previewUrl, renditions, _thumbnailUrl, _thumbnailHeight, _thumbnailWidth, entityType } = layoutDocument;

      let thumbnailInternalInfo: Models.ImageInternalInfo = {
        height: _thumbnailHeight,
        source: _thumbnailUrl,
        width: _thumbnailWidth,
      };

      if (!_.get(thumbnailInternalInfo, 'source')) {
        thumbnailInternalInfo = await getVerifiedImageInternalInfo(previewUrl);
      }

      if (!_.get(thumbnailInternalInfo, 'source')) {
        try {
          const thumbnailChecksum = _.get(renditions, [RenditionType.CUSTOM_THUMBNAIL, 'checksum']);
          const imageCache = ImageCache.getInstance();
          thumbnailInternalInfo = imageCache.getItem(RenditionType.CUSTOM_THUMBNAIL, thumbnailChecksum) as Models.ImageInternalInfo;

          if (!_.get(thumbnailInternalInfo, 'source')) {
            const rendition = await getDocumentRendition(documentId, thumbnailChecksum, RenditionType.CUSTOM_THUMBNAIL);
            thumbnailInternalInfo = await getImageInternalInfo(rendition);
            imageCache.setItem(RenditionType.CUSTOM_THUMBNAIL, thumbnailChecksum, thumbnailInternalInfo);
          } else {
            // todo: check if we can remove this completely (test)
            thumbnailInternalInfo = await getImageInternalInfo(thumbnailInternalInfo.source);
          }
        } catch (error) {
          thumbnailInternalInfo = null;
        }
      }

      emitter({
        action: action(id, thumbnailInternalInfo, entityType),
        done: isDone(),
      });
    };

    yield call(runEventChanel, layoutDocument, subscribeFunction, { needToStop });
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during updating images previews.', error);
  }
}
