import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import Title from './components/Title';
import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  title: string;
  isOpen?: boolean;
  onClose: () => void;
}>;

const Panel: React.FunctionComponent<Props> = (props) => {
  const { title, children, isOpen, onClose } = props;

  return (
    <div className={classNames(styles.container, { [styles.isOpen]: isOpen })}>
      <Title onClose={onClose}>
        {title}
      </Title>
      <div className={styles.body}>
        {isOpen ? children : null}
      </div>
    </div>
  );
};

Panel.defaultProps = {
  isOpen: false,
};

export default Panel;
