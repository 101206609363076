import { CSSProperties } from 'react';
import { BrandColorMap, BrandColorGradientMap, brandColorGradientIsValid } from 'models';
import * as Models from 'models';
import { BackgroundGradientDirection, BackgroundGradientMap } from 'models/styles/style/BackgroundGradient';
import { colorFromSource, brandColorToValue } from 'utils/converters';
import { brandColorGradientFromSource } from 'utils/converters/colorGradient';
import { getBackgroundImage } from './getBackgroundImage';

export const getCSSBackgroundBrandColorLayer = (
  color: BrandColorMap | undefined,
  opacity?: number,
  defaultColor?: string,
): string | undefined => brandColorToValue(color, defaultColor, opacity);

export const getCSSBackgroundColorLayer = (
  colors: Models.BrandColorsList,
  color: string | undefined,
  tint: number,
  opacity?: number,
  defaultColor?: string,
): string | undefined => {
  return getCSSBackgroundBrandColorLayer(
    colorFromSource(colors, color, tint),
    opacity,
    defaultColor,
  );
};

export const getCSSBackgroundBrandGradientLayer = (
  gradient: BrandColorGradientMap | undefined,
  opacity?: number,
): string | undefined => {
  if (!gradient || !brandColorGradientIsValid(gradient)) {
    return undefined;
  }

  const color1 = brandColorToValue(gradient.get('color1'), undefined, opacity);
  const color2 = brandColorToValue(gradient.get('color2'), undefined, opacity);

  if (gradient.get('direction') === BackgroundGradientDirection.RADIAL) {
    return `radial-gradient(${color1}, ${color2})`;
  }

  return `linear-gradient(${gradient.get('direction')}, ${color1}, ${color2})`;
};

export const getCSSBackgroundGradientLayer = (
  colors: Models.BrandColorsList,
  gradient: BackgroundGradientMap | undefined,
  opacity?: number,
): string | undefined => getCSSBackgroundBrandGradientLayer(
  brandColorGradientFromSource(colors, gradient),
  opacity,
);

export const getCSSBackgroundImageLayer = (
  image: Models.BackgroundImageMap,
  documents: Models.CombinedDocumentsMap,
): string | undefined => {
  const {
    backgroundImage,
    backgroundPositionX, backgroundPositionY,
    backgroundSize,
    backgroundRepeat,
  } = getBackgroundImage(image, documents);

  if (!backgroundImage) {
    return undefined;
  }

  const size = backgroundSize ? `/ ${backgroundSize}` : '';

  return `${backgroundImage} ${backgroundPositionX} ${backgroundPositionY} ${size} ${backgroundRepeat}`;
};

export const getCSSBackgroundBrand = (
  color: BrandColorMap | undefined,
  colorOpacity: number | undefined,
  gradient: BrandColorGradientMap | undefined,
  image: Models.BackgroundImageMap | undefined,
  documents?: Models.CombinedDocumentsMap,
): CSSProperties => {

  const layers: (string | undefined)[] = [];
  if (image) {
    layers.push(getCSSBackgroundImageLayer(image, documents));
  }
  if (gradient) {
    layers.push(getCSSBackgroundBrandGradientLayer(gradient, colorOpacity));
  }
  if (color) {
    layers.push(getCSSBackgroundBrandColorLayer(color, colorOpacity));
  }

  return { background: layers.filter(layer => !!layer).join(',') };
};

export const getCSSBackgroundPreview = (
  gradient: BrandColorGradientMap | undefined,
  color: BrandColorMap | undefined,
  defaultColor?: string,
): CSSProperties => {
  if (gradient && brandColorGradientIsValid(gradient)) {
    const color1 = brandColorToValue(gradient.get('color1'));
    const color2 = brandColorToValue(gradient.get('color2'));

    return {
      background: `linear-gradient(90deg, ${color1}, ${color1} 50%, ${color2} 50%, ${color2})`,
    };
  }

  const background = brandColorToValue(color, defaultColor);

  return background ? { background } : {};
};
