import Draft from 'draft-js';
// import { Options, stateToHTML } from 'draft-js-export-html';
import _ from 'lodash';

import { DraftEntity, DraftEntityMutability, REFERENCES_MARKER } from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';

export function replaceReferenceMarkersByEntities(
  textComponent: Models.TextComponent,
  getActualReferenceCitationId: (referenceId: string) => string,
  // textStateToHTMLOptions: Options,
): Models.TextComponent {
  const actualTextComponent = _.cloneDeep(textComponent);
  const actualReferenceCitationIds: string[] = [];

  const { text, referenceCitations: referenceCitationIds } = actualTextComponent;
  const editorState = editorUtils.convertTextComponentToRawEditorState(actualTextComponent);
  let contentState = editorState.getCurrentContent();

  if (!_.isEmpty(referenceCitationIds)) {
    contentState.getBlockMap().forEach((block) => {
      const blockText = block.getText();
      const blockKey = block.getKey();

      // replace all {r} by reference entities
      // TODO: should we remove excess {r} markers (e.g. references.length < quantity of markers)?
      blockText.replace(/\{r\}/g, (match: string, offset: number) => {
        const referenceCitationId = referenceCitationIds.shift();
        const actualReferenceCitationId = getActualReferenceCitationId(referenceCitationId);

        if (!actualReferenceCitationId) {
          return '';
        }

        actualReferenceCitationIds.push(actualReferenceCitationId);

        const contentWithEntity = contentState.createEntity(
          DraftEntity.REFERENCE,
          DraftEntityMutability.IMMUTABLE,
          { id: actualReferenceCitationId },
        );

        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        const selection = editorState.getSelection().merge({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: offset,
          focusOffset: offset + match.length,
        }) as Draft.SelectionState;
        contentState = Draft.Modifier.replaceText(contentState, selection, REFERENCES_MARKER, null, entityKey);

        return '';
      });
    });

    // TBC: There is no sense to support text property in case text has raw content!!!
    // actually text field is needed to support components from ASM7 storycards, is it right?
    // const markup = stateToHTML(contentState, textStateToHTMLOptions);
    const content = Draft.convertToRaw(contentState);

    // actualTextComponent.text = markup;
    actualTextComponent.rawContent = JSON.stringify(content);
  } else {
    actualTextComponent.text = text.replace(/\{r\}/g, '');

    if (!actualTextComponent.rawContent) {
      const editorState = editorUtils.convertTextComponentToRawEditorState(actualTextComponent);
      actualTextComponent.rawContent = editorUtils.getSerializedRawContent(editorState);
    }
  }

  actualTextComponent.referenceCitations = actualReferenceCitationIds;

  return actualTextComponent;
}
