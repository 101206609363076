import { EditorState } from 'draft-js';
import _ from 'lodash';

import { StylePrefix, Styles } from 'const';
import { createStyles } from 'factories/relationFactory';
import { createTextStyles } from 'factories/style';
import * as Models from 'models';
import { getTextStylesFromBrandStyle, isDefaultBrandStyleAvailable } from 'utils/brandStyles';
import * as editor from 'utils/editor';
import {
  getFontFamilyStylesFromBrandStyle,
  getFontSizeStyle,
  findBrandColorByHEX,
  getBulletColorStyle,
} from 'utils/inlineStyles';
import { mergeObjectsDeep } from 'utils/mergeObjectsDeep';
import { getValue } from '../getter';

// TODO: why do we need to return the new Editor State?
export const toggleBrandStyle = (
  editorState: EditorState,
  textStyles: Partial<Models.TextBrandStyles>,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): EditorState => {
  const {
    bulletColor,
    characterStyle,
    fontColor,
    fontFamily,
    fontSize,
    lineHeight,
    textAlign,
  } = textStyles;

  // Apply styles section
  // Please follow the order to apply inline styles after block styles
  // To save inline style override.
  // Font size toggle also modifies selection, so assign it before others inline styles
  // TODO: put 'saveInlineStylesOverride' defense in appropriate methods
  return _.flow(
    editorState => editor.setFullSelection(editorState),
    editorState => textAlign ? editor.toggleBlockType(editorState, textAlign, true) : editorState,
    editorState => lineHeight ? editor.setLineHeight(editorState, lineHeight) : editorState,
    editorState => fontSize ? toggleFontSize(editorState, fontSize) : editorState,
    editorState => fontColor ? toggleBrandStyleColor(editorState, fontColor, colors) : editorState,
    editorState => fontFamily ? toggleBrandStyleFontFamily(editorState, fontFamily, characterStyle, fonts) : editorState,
    editorState => bulletColor ? toggleBrandStyleColor(editorState, bulletColor, colors, true) : editorState,
  )(editorState);
};

export const toggleFontSize = (editorState: EditorState, fontSize: number): EditorState => {
  return editor.toggleCustomInlineStyle(
    editorState,
    getFontSizeStyle(fontSize),
    StylePrefix.FONT_SIZE,
  );
};

// TODO: maybe return the Editor State?
// why do not we save the new Editor State?
export const getTransformedStyles = (
  editorState: EditorState,
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): Partial<Models.TextRelationStyles> => {
  const textStyles = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
  const withBrandStyle = toggleBrandStyle(editorState, textStyles, colors, fonts);
  const { backgroundColor, padding, verticalAlignment } = textStyles;
  const editorDependentStyles = editor.isolateEditorStateStyles(withBrandStyle);

  return mergeObjectsDeep(
    {
      alignment: {
        vertical: verticalAlignment,
      },
      backgroundColor: backgroundColor ? backgroundColor.get('name') : null,
      brandStyleId: brandStyle ? brandStyle.get('id') : null,
      padding: padding.toJS() as Models.Padding,
    },
    editorDependentStyles,
  );
};

export function applyBrandStyle(
  textComponent: Models.TextComponent | Models.TextComponentMap,
  textRelation: Models.RegularRelation<Models.TextRelationStyles>,
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  const editorState = editor.convertTextComponentToRawEditorState(textComponent);

  const newTextRelationStyles = getTransformedStyles(editorState, brandStyle, colors, fonts);
  textRelation.styles = {
    ...textRelation.styles,
    ...newTextRelationStyles,
    brandStyleChanged: false,
  };
}

export function createStylesWithDefaultBrandStyle(
  component: Models.TextComponent | Models.TextComponentMap,
  sectionStyles: Models.MasterScreen.SectionStylesMap,
  styles: Models.BrandStylesMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): Models.TextRelationStyles {
  if (!sectionStyles || !styles || !colors || !fonts || !isDefaultBrandStyleAvailable(styles, sectionStyles)) {
    return createTextStyles();
  }

  const editorState = editor.convertTextComponentToRawEditorState(component);
  const brandStyle = styles.get(Styles.DefaultTextBrandStyle);

  return createStyles(
    getTransformedStyles(editorState, brandStyle, colors, fonts),
    getValue(component, 'entityType'),
  ) as Models.TextRelationStyles;
}

export const toggleBrandStyleColor = (
  editorState: EditorState, hexOrNameColor: string,
  colors: Models.BrandColorsList,
  isBullet = false,
): EditorState => {
  const brandColor = colors && findBrandColorByHEX(hexOrNameColor, colors);
  const resultColor = brandColor ? brandColor.get('name') : hexOrNameColor;

  if (!isBullet) {
    let nextEditor = editor.toggleCustonInlineStyleValue(editorState, StylePrefix.FONT_COLOR_NAME, resultColor);
    nextEditor = editor.toggleCustonInlineStyleValue(nextEditor, StylePrefix.FONT_COLOR_TINT, 0);

    return editor.toggleCustonInlineStyleValue(nextEditor, StylePrefix.FONT_COLOR, resultColor);
  } else {
    return editor.toggleCustomInlineStyle(editorState, getBulletColorStyle(resultColor), StylePrefix.BULLET_COLOR);
  }
};

export const toggleBrandStyleFontFamily = (
  editorState: EditorState,
  fontFamily: string,
  characterStyle: string,
  fonts: Models.BrandFontsList,
): EditorState => {
  const {
    fontFamilyStyle,
    characterStyleNameStyle,
  } = getFontFamilyStylesFromBrandStyle(fontFamily, characterStyle, fonts);

  let newEditorState = editor.toggleCustomInlineStyle(editorState, fontFamilyStyle, StylePrefix.FONT_FAMILY);
  newEditorState = characterStyleNameStyle
    ? editor.toggleCustomInlineStyle(newEditorState, characterStyleNameStyle, StylePrefix.CHARACTER_STYLE_NAME)
    : newEditorState;

  return newEditorState;
};
