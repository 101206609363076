import Immutable from 'immutable';
import { batchActions } from 'redux-batched-actions';
import { call, fork, put, select, take } from 'redux-saga/effects';
import guid from 'uuid';

import { ModalType, ProjectsConfig } from 'const';
import { addArtboard } from 'containers/Artboards/actions';
import { artboards as artboardsSelector } from 'containers/Artboards/selectors';
import { showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { projectType as projectTypeSelector } from 'containers/Project/selectors';
import { addSurface, refreshThumbnail } from 'containers/Surfaces/actions';
import { getThumbnailUrl } from 'containers/Surfaces/sagas/getArtboardThumbnailUrl';
import { surfaces as surfacesSelector } from 'containers/Surfaces/selectors';
import { ArtboardMap, ThumbnailMap } from 'models';
import { ScreenSettingsWindowConfigOptions } from 'models/ScreenSettingsWindowOptions';
import { handleSagaError } from 'services/handleError';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';
import * as Models from '../models';

export function* setSurfaceSettings(action: Models.Action.ISetSurfaceSettings) {
  try {
    const { id: screenId } = action.payload;
    const setSurfaceSettingsWindowId = guid();
    const surfaces: ReturnTypeSaga<typeof surfacesSelector> = yield select(surfacesSelector);
    const screen = surfaces.get(screenId);
    const currentSurfaceThumbnailUrl = screen.getIn(['thumbnail', 'url']);
    yield put(showModal(ModalType.SCREEN_SETTINGS, {}, setSurfaceSettingsWindowId));

    if (!currentSurfaceThumbnailUrl) {
      yield fork(getThumbnailUrl, refreshThumbnail(screenId));
    }

    const { payload: { response } }: ModalManagerModels.Action.IHideModal<ScreenSettingsWindowConfigOptions> = yield take(
      takeModalWindowResponse(setSurfaceSettingsWindowId),
    );
    if (!response) {
      return;
    }

    // NOTE: the artboard should be selected after the setting modal is closed
    // because it can be updated while the modal is active
    const artboards: ReturnTypeSaga<typeof artboardsSelector> = yield select(artboardsSelector);
    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const artboardId = screen.get('artboardId');
    const artboard = artboards.get(artboardId);
    const { areScreensResizable } = ProjectsConfig[projectType];

    const { screenName, thumbnailBorder, thumbnailName, thumbnailUrl, width, height, type } = response;
    const thumbnail: ThumbnailMap = Immutable.fromJS({ border: thumbnailBorder, name: thumbnailName, url: thumbnailUrl });

    let updatedScreen = screen.set('name', screenName).set('thumbnail', thumbnail);
    let updatedArtboard: ArtboardMap;

    if (areScreensResizable) {
      updatedScreen = updatedScreen.set('formatType', type);
      updatedArtboard = artboard.update('styles', styles => styles.set('width', width).set('height', height));
    }

    yield put(batchActions([
      addSurface(updatedScreen),
      ...updatedArtboard ? [addArtboard(updatedArtboard)] : [],
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Surfaces.setSurfaceSettings', 'SetScreenSettings');
  }
}
