import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { stopPropagation } from 'utils/events';
import { DropdownProps } from './models';
import styles from './styles.module.scss';

const Dropdown: React.FunctionComponent<PropsWithChildren<DropdownProps>> = (props) => {
  const { children, className, stopClickPropagation, onMouseEnter, onMouseLeave } = props;

  return (<div
    className={classNames(styles.Dropdown, className)}
    onClick={stopClickPropagation ? stopPropagation : undefined}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </div>);
};

Dropdown.defaultProps = {
  stopClickPropagation: false,
};

export default Dropdown;
