import _ from 'lodash';

import { isAbbreviatioinsListLayout } from 'modules/Abbreviations/utils/isAbbreviatioinsListLayout';
import * as Models from 'models';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isReferenceCitationElement } from 'utils/layouts/isReferenceCitationElement';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { ActionsColor } from './constants';
import { LayoutActionsConfig } from './models';

const LayoutActionsDefaultConfig: LayoutActionsConfig = {
  addColumnAvailable: true,
  addRowAvailable: true,
  backgroundColor: ActionsColor.BLUE,
  borderColor: ActionsColor.BLUE,
  copyLayoutAvailable: true,
  doubleHover: false,
  editModeAvailable: true,
  resizeBtnControlledByEditMode: true,
  rightActionsSize: 4,
  saveAsReusableAvailable: true,
  separatorsAvailable: true,
  unlinkAvailable: false,
};

export function getLayoutActionsConfig(
  layout: Models.CombinedLayoutMap,
  documents: Models.CombinedDocumentsMap,
  isChildLayout: boolean,
): LayoutActionsConfig {
  const withDefault = (config: Partial<LayoutActionsConfig>): LayoutActionsConfig => _.defaults(config, LayoutActionsDefaultConfig);

  switch (true) {
    case isReferenceCitationElement(layout): return withDefault({
      addColumnAvailable: false,
      addRowAvailable: false,
      copyLayoutAvailable: false,
      rightActionsSize: 2,
      saveAsReusableAvailable: false,
      separatorsAvailable: false,
    });
    case isAbbreviatioinsListLayout(layout): return withDefault({
      addColumnAvailable: false,
      addRowAvailable: false,
      copyLayoutAvailable: false,
      saveAsReusableAvailable: false,
      separatorsAvailable: false,
    });
    case isReusableLayout(layout, documents): return withDefault({
      borderColor: ActionsColor.GREEN,
      copyLayoutAvailable: false,
      rightActionsSize: 3,
      saveAsReusableAvailable: false,
      unlinkAvailable: !isChildLayout,
    });
    case isGroupLayout(layout): return withDefault({
      addColumnAvailable: false,
      addRowAvailable: false,
      backgroundColor: ActionsColor.GREEN,
      borderColor: ActionsColor.GREEN,
      copyLayoutAvailable: false,
      doubleHover: true,
      editModeAvailable: false,
      resizeBtnControlledByEditMode: false,
      rightActionsSize: 1,
      saveAsReusableAvailable: false,
      separatorsAvailable: false,
    });
    default: return LayoutActionsDefaultConfig;
  }
}
