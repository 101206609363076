import { Layer } from 'const';
import * as Models from 'models';
import { getSafeLayer } from 'utils/layers';
import { isRegularRelation } from './relations/isRegularRelation';

export const prioritizeLayeredRelation = <S extends Models.CombinedRelationStyles>(
  layeredRelation: Models.LayeredRegularRelationMap<S>,
  layer: Layer,
): Models.RegularRelationMap<S> => {
  return (layeredRelation)
    .update('styles', styles => styles.get(layer))
    .update('documentId', documentId => documentId.get(layer)) as Models.RegularRelationMap<S>;
};

export const prioritizeLayeredRelations = (layeredRelations: Models.LayeredRelationsMap, layer: Layer): Models.RelationsMap => {
  return layeredRelations.map((layeredRelation) => {
    if (!isRegularRelation(layeredRelation)) {
      return layeredRelation;
    }

    const safeLayer = getSafeLayer(layeredRelation, layer);

    return prioritizeLayeredRelation(layeredRelation, safeLayer);
  }) as Models.RelationsMap;
};

