import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { put, select } from 'redux-saga/effects';

import { Layer } from 'const';
import { resetScreensReadyForOutput } from 'containers/ArtboardPreview/actions';
import { setArtboards } from 'containers/Artboards/actions';
import { setBrandDefinition } from 'containers/BrandDefinition/actions';
import * as commonSelectors from 'containers/Common/selectors';
import * as DocumentsActions from 'containers/Documents/actions';
import * as DocumentTypesActions from 'containers/DocumentTypes/actions';
import { setLayouts } from 'containers/Layouts/actions';
import * as ProjectActions from 'containers/Project/actions';
import * as RelationActions from 'containers/Relations/actions';
import * as RootDocumentActions from 'containers/RootDocument/actions';
import { setScreenDefinitions } from 'containers/ScreenDefinitions/actions';
import { setSections } from 'containers/Sections/actions';
import { setSurfaces } from 'containers/Surfaces/actions';
import { createStyles } from 'factories/relationFactory';
import * as Models from 'models';
import { createStylesWithDefaultBrandStyle } from 'utils/editor';
import { isTextComponent } from 'utils/entityType';
import { getSafeLayer } from 'utils/layers';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { removeAbsenceRelations } from 'utils/relations/removeAbsenceRelations';

export function* setProjectAssets(assets: Models.ProjectAssets) {
  const actions: Models.IAction[] = [];

  const {
    layouts,
    sections,
    relations,
    artboards,
    surfaces,
    documents,
    documentTypes,
    rootDocument,
    project: {
      version,
      surfaceIds,
      originalLanguage,
      originalCountry,
      originalProduct,
      activeLayer,
      suppressTranslation,
    },
    masterScreenData,
  } = assets;

  const sectionStyles: ReturnTypeSaga<typeof commonSelectors.sectionStylesByRelationId> = yield select(commonSelectors.sectionStylesByRelationId);
  const brandStyles: ReturnTypeSaga<typeof commonSelectors.brandStylesByRelationId> = yield select(commonSelectors.brandStylesByRelationId);
  const colors: ReturnTypeSaga<typeof commonSelectors.flatColorsByRelationId> = yield select(commonSelectors.flatColorsByRelationId);
  const fonts: ReturnTypeSaga<typeof commonSelectors.flatFontsByRelationId> = yield select(commonSelectors.flatFontsByRelationId);

  const clearedRelations = relations ? removeAbsenceRelations(relations) : undefined;

  // ensure that all relations' styles have both layers
  const updatedRelations = _.mapValues(clearedRelations, (relation, id) => {
    if (!isRegularRelation(relation)) {
      return relation;
    }

    const { entityType } = relation;
    const layer = getSafeLayer(relation, activeLayer);
    const documentId = _.get(relation, ['documentId', layer]);
    const document = documents[documentId];

    return _.update(relation, ['styles', layer], (styles) => {
      const copiedStyles = _.cloneDeep(relation.styles[Layer.ORIGINAL]);
      const targetStyles = _.cloneDeep(relation.styles[Layer.TRANSLATED]);
      const assetBorderRadius = copiedStyles?.assetBorderRadius;
      const borderRadius = copiedStyles?.borderRadius ?? targetStyles?.borderRadius;
      if (styles) {
        return assetBorderRadius
          ? { ...styles, borderRadius, assetBorderRadius }
          : { ...styles, borderRadius };
      }

      if (isTextComponent(document)) {
        return createStylesWithDefaultBrandStyle(
          document as Models.TextComponent,
          sectionStyles.get(id),
          brandStyles.get(id),
          colors.get(id),
          fonts.get(id),
        );
      }
      const stylesToRetain = assetBorderRadius
        ? { borderRadius, assetBorderRadius }
        : { borderRadius };

      return createStyles(stylesToRetain, entityType);
    });
  });

  if (masterScreenData) {
    const {
      screenDefinitions,
      brandDefinition,
      artboardBackground,
      helperFileName,
      placeholderMinHeight,
      projectType,
    } = masterScreenData;

    actions.push(
      setScreenDefinitions(screenDefinitions),
      setBrandDefinition(brandDefinition),
      ProjectActions.updateProjectState({
        artboardBackground,
        helperFileName,
        placeholderMinHeight,
        projectType,
      }),
    );
  }

  actions.push(
    setLayouts(layouts),
    setSections(sections),
    RelationActions.setRelations(updatedRelations),
    setArtboards(artboards),
    setSurfaces(surfaces),
    ProjectActions.updateProjectState({
      originalLanguage,
      originalCountry,
      originalProduct,
      surfaceIds,
      version,
      activeLayer,
      suppressTranslation,
    }),
    DocumentsActions.setDocuments(documents),
    RootDocumentActions.setRootDocument(rootDocument),
    DocumentTypesActions.setDocumentTypes(documentTypes),
    resetScreensReadyForOutput(),
  );

  yield put(batchActions(actions));
}
