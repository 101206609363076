import Immutable from 'immutable';
import { createSelector } from 'reselect';
import * as documentsSelectors from 'containers/Documents/selectors';
import * as projectSelectors from 'containers/Project/selectors';
import * as Models from 'models';
import { getAbbreviationIdsFromRelationsAndDocuments } from '../utils/getAbbreviationIdsFromDocumentsAndRelations';
import { State } from './models';

const sorter = (a: Models.TextAbbreviationDocument, b: Models.TextAbbreviationDocument): number =>
  a.abbreviation.definition?.toLowerCase().localeCompare(
    b.abbreviation.definition?.toLocaleLowerCase(),
  );

// TODO: replace object with Immutable Map for textAbbreviations key in store
type RootState = Immutable.Map<'textAbbreviations', State.IState>;

const selectAbbreviationsState = (state: RootState): State.IState => state.get('textAbbreviations')!;

export const selectAbbreviationsData = (state: RootState): State.IState['abbreviationList'] => selectAbbreviationsState(state).abbreviationList;

export const selectAbbreviationsSortedData = (state: RootState): Models.TextAbbreviationDocumentsArray =>
  Object.values(
    selectAbbreviationsState(state).abbreviationList.toJS(),
  ).sort(sorter);

export const selectAbbreviationIdsByRelationId = createSelector(
  [
    projectSelectors.activeLayer,
    documentsSelectors.relationsOnScreens,
    documentsSelectors.documentsByEntityType,
  ],
  (activeLayer, relations, documents) => {
    const mapping: Record<string, string[]> = {};

    relations.forEach((relation) => {
      const ids = getAbbreviationIdsFromRelationsAndDocuments(Immutable.List([relation]), documents, activeLayer);
      if (ids.length) {
        mapping[(relation as Models.LayeredRegularRelationMap).get('id') as string] = ids;
      }
    });

    return mapping;
  },
);

