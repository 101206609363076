import Immutable from 'immutable';

import * as Constants from 'const';
import * as Models from 'models';
import { getDesktopBrandStyles } from 'utils/brandStyles';
import { getBackgroundColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import { boxPropertiesFromString } from '../converters';

interface BlockStyles {
  backgroundColor: Models.BrandColorMap | null;
  padding: Models.BoxPropertyMap;
  verticalAlignment: Constants.AssetAlignmentType;
}

export function getCellBrandStylesForExport(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
): BlockStyles {
  let padding = Immutable.Map(Constants.Styles.DefaultTextPadding);
  let verticalAlignment = Constants.AssetAlignmentType.TOP;
  let backgroundColor: Models.BrandColorMap | null = null;

  if (brandStyle) {
    const desktopStyles = getDesktopBrandStyles(brandStyle);

    // Cell styles
    padding = boxPropertiesFromString<Models.PaddingMap>(desktopStyles.get(Models.BrandStyleProp.PADDING));
    verticalAlignment = desktopStyles.get(Models.BrandStyleProp.VERTICAL_ALIGN);
    backgroundColor = getBackgroundColorFromBrandStyle(colors, desktopStyles);
  }

  return {
    backgroundColor,
    padding,
    verticalAlignment,
  };
}
