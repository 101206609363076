import { ActionTypes } from './constants';
import { ActionCreator } from './models';

export const addSections: ActionCreator.AddSections = sections => ({
  type: ActionTypes.ADD,
  payload: {
    sections,
  },
});

export const deleteSections: ActionCreator.DeleteSections = sectionIds => ({
  type: ActionTypes.DELETE,
  payload: {
    sectionIds,
  },
});

export const setSections: ActionCreator.SetSections = sections => ({
  type: ActionTypes.SET,
  payload: {
    sections,
  },
});

export const updateSection: ActionCreator.UpdateSection = section => ({
  type: ActionTypes.UPDATE,
  payload: {
    section,
  },
});
