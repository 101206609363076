import _ from 'lodash';

import { UUID_PATTERN } from 'const';
import * as Models from 'models';

export function pickKeysMatchUUID<T>(object: T): T {
  const uuidRegExp = new RegExp(UUID_PATTERN, 'i');

  return _.pick(object, _.keys(object).filter(key => uuidRegExp.test(key))) as T;
}

export function removeInvalidDocuments<T extends Models.GetProjectDataProcessArgs>({ assets }: T): void {
  assets.documents = pickKeysMatchUUID(assets.documents);
}
