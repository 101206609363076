import classNames from 'classnames';
import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import ArtboardLayoutContainerList from 'components/ArtboardLayoutContainerList';
import { DragSourceType, ModalType } from 'const';
import SSIActions from 'containers/SSIActions';
import { intlGet } from 'utils/intlGet';
import { getSSISize } from 'utils/styles/getSSISize';
import { SSICollectedProps, SSIOwnProps, SSIProps, SSIState } from './models';
import styles from './styles.module.scss';

const SSIDragSource: DragSourceSpec<SSIProps, {}> = {
  beginDrag(props) {
    const { toggleDragState } = props;
    toggleDragState(DragSourceType.ARTBOARD_SSI);

    return {};
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

const collect: DragSourceCollector<SSICollectedProps, SSIOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

// SSI is an abbreviation for Select Safety Information
class SSI extends React.PureComponent<SSIProps, SSIState> {
  readonly state: SSIState = {
    isMouseOver: false,
  };

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  private showSSISourceWindow = (): void => {
    this.props.showModal(ModalType.SSI_SOURCE);
  };

  private renderHint = (): JSX.Element => {
    const { isMouseOver } = this.state;

    return isMouseOver && <div className={styles.hint}>{intlGet('SSI', 'Hint')}</div>;
  };

  private onMouseEnter = (): void => this.setState({ isMouseOver: true });

  private onMouseLeave = (): void => this.setState({ isMouseOver: false });

  private renderSSIContent = (): JSX.Element => {
    const { ssi, layouts, artboardLayoutIds } = this.props;

    return !layouts
      ? <div className={styles.placeholder} onDoubleClick={this.showSSISourceWindow}>{intlGet('SSI', 'Placeholder')}</div>
      : (
        <div className={styles.ssiContentWrapper}>
          <div className={styles.ssiContent}>
            <ArtboardLayoutContainerList
              isSectionActive={false}
              layoutIds={artboardLayoutIds}
              layouts={layouts}
              ssi={ssi}
            />
          </div>
        </div>
      );
  };

  render() {
    const { isMouseOver } = this.state;
    const { ssi, sectionHeight, sectionWidth, connectDragSource } = this.props;

    return (
      <div
        className={classNames(styles.ssi, {
          [styles.highlighted]: isMouseOver,
        })}
        style={{
          ...getSSISize(ssi, sectionWidth, sectionHeight),
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.renderHint()}
        {this.renderSSIContent()}
        <SSIActions connectDragSource={connectDragSource} />
      </div>
    );
  }
}

export default DragSource<SSIOwnProps, SSICollectedProps, {}>(
  DragSourceType.ARTBOARD_SSI,
  SSIDragSource,
  collect,
)(SSI);
