import { connect } from 'react-redux';

import ResizeButton from 'components/ResizeButton';
import { ResizeButtonActions, ResizeButtonOwnProps } from 'components/ResizeButton/models';
import { setIsResizingLayout } from 'containers/App/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';

const mapDispatchToProps = {
  setIsResizingLayout,
  saveAppState,
};

export default connect<{}, ResizeButtonActions, ResizeButtonOwnProps>(null, mapDispatchToProps)(ResizeButton);
