import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import BoxInput from 'components/Toolbar/Shared/BoxInput';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { BoxPropertySide } from 'const';
import { areBoxPropertiesEqual } from 'utils/immutable';
import { intlGet } from 'utils/intlGet';
import { BorderRadiusProps } from './models';
import styles from './styles.module.scss';

const SideControls = [
  BoxPropertySide.TOP,
  BoxPropertySide.RIGHT,
  BoxPropertySide.LEFT,
  BoxPropertySide.BOTTOM,
];

const TextsByBoxPropertySide: Record<BoxPropertySide, () => string> = {
  [BoxPropertySide.TOP]: () => intlGet('EditorToolbar.Property', 'TopLeft'),
  [BoxPropertySide.RIGHT]: () => intlGet('EditorToolbar.Property', 'TopRight'),
  [BoxPropertySide.LEFT]: () => intlGet('EditorToolbar.Property', 'BottomLeft'),
  [BoxPropertySide.BOTTOM]: () => intlGet('EditorToolbar.Property', 'BottomRight'),
};

const BorderRadius: React.FunctionComponent<BorderRadiusProps> = (props) => {
  const { borderRadius, toggleBorderRadius, title, dropdownWarningMessage, className, iconTooltipClassName } = props;

  return (
    <Dropdown
      className={classNames(styles.BorderRadiusDropdown, className)}
      stopClickPropagation={true}
    >
      <div className={styles.title}>
        {intlGet('EditorToolbar', title.toUpperCase())}
        {dropdownWarningMessage && (
          <Icon
            type={IconType.EXCLAMATION_MARK}
            size="sm"
            color="primary"
            hoverTooltipText={dropdownWarningMessage}
            className='ml-1'
            iconTooltipClassName={iconTooltipClassName}
          />
        )}
      </div>
      <BoxInput
        property={borderRadius}
        toggleProperty={toggleBorderRadius}
        max={100}
        isShorthand={areBoxPropertiesEqual(borderRadius)}
        sideControls={SideControls}
        textsByBoxPropertySide={TextsByBoxPropertySide}
      />
    </Dropdown>
  );
};

export default BorderRadius;
