import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ToastType } from 'const';
import { intlGet } from 'utils/intlGet';
import { IconColor } from '../Icon/models';
import { ToastProps } from './models';
import styles from './styles.module.scss';

const Toast: React.FunctionComponent<ToastProps> = ({ closeToast, title, message, type }) => {
  let defaultTitle = intlGet('Notification', 'Success');
  let iconColor: IconColor = 'success';
  let iconType = IconType.CHECK_MARK;

  if (type === ToastType.ERROR) {
    defaultTitle = intlGet('Notification', 'Error');
    iconType = IconType.EXCLAMATION_MARK;
    iconColor = 'error';
  }

  if (type === ToastType.WARNING) {
    defaultTitle = intlGet('Notification', 'Warning');
    iconType = IconType.EXCLAMATION_MARK;
    iconColor = 'primary';
  }

  return (
    <div
      className={classNames(styles.Toast, {
        [styles.ToastSuccess]: type === ToastType.SUCCESS,
        [styles.ToastError]: type === ToastType.ERROR,
        [styles.ToastWarning]: type === ToastType.WARNING,
      })}
    >
      <div className={styles.icon}>
        <Icon type={iconType} size="auto" color={iconColor} />
      </div>
      <div className={styles.messageContainer}>
        <div className={styles.messageTitle}>{title || defaultTitle}</div>
        <div className={styles.messageDescription}>{message}</div>
      </div>
      <div className={styles.closeIcon}>
        <Icon onClick={closeToast} type={IconType.CLOSE} size="auto" color="light-grey" />
      </div>
    </div>
  );
};

export default Toast;
