import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import NumericInput from 'components/NumericInput';
import { intlGet } from 'utils/intlGet';
import { HeightWidthProps } from './models';
import styles from './styles.module.scss';

const HeightWidth: React.FunctionComponent<HeightWidthProps> = (props) => {
  const {
    disableCellWidthEditing = false,
    height,
    isIncreasingWidthDisabled = false,
    maxHeight = Infinity,
    maxWidth = Infinity,
    minHeight = 1,
    minWidth = 1,
    toggleColumnWidth,
    toggleHeight,
    toggleWidth,
    transparentControls = true,
    width,
    isAutoHeight,
    isAutoFitContent,
    toggleAutoFitContent,
  } = props;

  const [widthState, setWidth] = React.useState(width);
  const applyWidth = (newWidth) => {
    let newValue: number;
    // TODO: rename toggleColumnWidth and toggleWidth to changeColumnWidth and changeWidth.
    if (newWidth !== props.width) {
      if (toggleColumnWidth) {
        newValue = toggleColumnWidth(newWidth);
      } else {
        newValue = _.clamp(newWidth, minWidth, maxWidth);
        toggleWidth(newValue);
      }
      setWidth(newValue);
    }
  };

  const setHeightAndDisableAutoHeight = (height) => {
    if (isAutoFitContent) {
      toggleAutoFitContent();
    }
    setHeight(height);
  };

  const [heightState, setHeight] = React.useState(height);
  const applyHeight = (newHeight) => {
    if (isAutoFitContent) {
      toggleAutoFitContent();
    }
    const newValue = Math.round(_.clamp(newHeight, minHeight, maxHeight));
    if (newHeight !== props.height) {
      toggleHeight(newValue);
      setHeight(newValue);
    }
  };

  React.useEffect(
    () => {
      setWidth(width);
      setHeight(height);
    },
    [width, height],
  );

  return (
    <div className={classNames(styles.HeightWidth)}>
      <NumericInput
        className={classNames(styles.numInput)}
        value={widthState}
        disabled={disableCellWidthEditing || !width}
        isIncreasingWidthDisabled={isIncreasingWidthDisabled}
        onChange={setWidth}
        onBlur={() => applyWidth(widthState)}
        onEnterPress={() => applyWidth(widthState)}
        onButtonClick={newWidth => applyWidth(newWidth)}
        suffix={intlGet('EditorToolbar.Size', 'Width')}
        transfluentControls={transparentControls}
      />
      <NumericInput
        disableWhenValueAbsent={false}
        allowEmptyStringValue={true}
        className={classNames(styles.numInput)}
        value={heightState}
        isAutoHeight={isAutoHeight}
        disabled={isAutoHeight} // TODO: check cases when we have to disable it
        onChange={setHeightAndDisableAutoHeight}
        onBlur={() => applyHeight(heightState)}
        onEnterPress={() => applyHeight(heightState)}
        onButtonClick={newHeight => applyHeight(newHeight)}
        suffix={intlGet('EditorToolbar.Size', 'Height')}
        transfluentControls={transparentControls}
      />
    </div>
  );
};

export default HeightWidth;
