import Immutable from 'immutable';
import * as Models from 'models';

export type GetBrandStylesProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  brandStylesByRelationId: Immutable.Map<string, Models.BrandStylesMap>;
};

export function getBrandStyles(props: GetBrandStylesProps): Models.BrandStylesMap {
  const { brandStylesByRelationId, relation } = props;

  return brandStylesByRelationId.get(relation.get('id'));
}

export type GetColorsProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  flatColorsByRelationId: Models.BrandColorsMap;
};

export function getColors(props: GetColorsProps): Models.BrandColorsList {
  const { flatColorsByRelationId, relation } = props;

  return flatColorsByRelationId.get(relation.get('id'));
}

export type GetFontsProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  flatFontsByRelationId: Models.BrandFontsMap;
};

export function getFonts(props: GetFontsProps): Models.BrandFontsList {
  const { flatFontsByRelationId, relation } = props;

  return flatFontsByRelationId.get(relation.get('id'));
}
