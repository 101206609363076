import React, { useCallback } from 'react';

import BackgroundImage from 'containers/Toolbar/Shared/BackgroundImage';
import * as Models from 'models';
import { BackgroundImageTabProps } from './models';
import styles from './styles.module.scss';

const ControlState: Models.ControlState = {
  ACTIVE: 'primary',
  DEFAULT: 'disabled',
  DISABLED: 'grey',
};

const BackgroundImageTab: React.FunctionComponent<BackgroundImageTabProps> = (props) => {
  const {
    artboard,
    updateArtboard,
  } = props;

  const backgroundImage = artboard.getIn(['styles', 'backgroundImage']);

  const toggleBackgroundImage = useCallback(
    (backgroundImage: Models.BackgroundImageMap): void => {
      updateArtboard(artboard.setIn(['styles', 'backgroundImage'], backgroundImage));
    },
    [artboard],
  );

  return (
    <BackgroundImage
      className={styles.BackgroundImageTab}
      backgroundImage={backgroundImage}
      toggleBackgroundImage={toggleBackgroundImage}
      controlState={ControlState}
    />
  );
};

export default BackgroundImageTab;
