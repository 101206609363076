import { EditorCommand } from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import { useCallback } from 'react';
import * as Constants from 'const';

type Handler = (command: EditorCommand | null) => Draft.DraftHandleValue;

export function useHandleKeyCommand(
  fillUndoStackIfEmpty: () => void,
  undo?: () => boolean,
  redo?: () => boolean,
): Handler {
  return useCallback((command: string | null): Draft.DraftHandleValue => {
    if (command === Constants.EditorKeyBindingCommand.BACKSPACE) {
      fillUndoStackIfEmpty();

      // default action from draft js is still needed, its just middleware
      return Constants.HandleValue.NOT_HANDLED;
    }
    if (command === Constants.EditorKeyBindingCommand.UNDO) {
      return !undo || undo()
        ? Constants.HandleValue.HANDLED
        : Constants.HandleValue.NOT_HANDLED;
    }
    if (command === Constants.EditorKeyBindingCommand.REDO) {
      return !redo || redo()
        ? Constants.HandleValue.HANDLED
        : Constants.HandleValue.NOT_HANDLED;
    }

    return Constants.HandleValue.NOT_HANDLED;
  }, [undo, redo, fillUndoStackIfEmpty]);
}
