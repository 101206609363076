import Immutable from 'immutable';
import _ from 'lodash';

import { BoxProperty, BoxPropertyMap } from 'models';

/**
 * Parses css string and converts it into BoxPropertyMap
 *
 * Examples of usage:
 *  const padding = boxPropertiesFromString<PaddingMap>('1px');
 *  const boxProperties = boxPropertiesFromString('20px 0');
 *  const padding = boxPropertiesFromString<PaddingMap>('0 1px 2px');
 *  const boxProperties = boxPropertiesFromString('25px 0 10px 3px');
 *
 * @param boxPropertiesString - css string
 */
export const boxPropertiesFromString = <T extends BoxPropertyMap = BoxPropertyMap>(boxPropertiesString: string): T => {
  const [top = 0, rightValue, bottomValue, leftValue] = (boxPropertiesString || '')
    .trim()
    .split(' ')
    .filter(value => !!value)
    .map(value => parseInt(value, 10) || 0);

  const right = _.isFinite(rightValue) ? rightValue : top;
  const bottom = _.isFinite(bottomValue) ? bottomValue : top;
  const left = _.isFinite(leftValue) ? leftValue : right;

  const boxProperties: BoxProperty = {
    top,
    right,
    bottom,
    left,
  };

  return Immutable.Map(boxProperties) as T;
};
