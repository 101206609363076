import classNames from 'classnames';
import React, { useRef } from 'react';

import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Spinner from 'components/Spinner';
import { KeyboardKey } from 'const';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { intlGet } from 'utils/intlGet';
import { ThumbnailTabProps } from './models';
import styles from './styles.module.scss';

const ThumbnailTab: React.FunctionComponent<ThumbnailTabProps> = (props) => {
  const {
    color,
    thumbnailBorder,
    toggleThumbnailBorder,
    thumbnailName,
    setThumbnailName,
    thumbnailUrl,
    refreshThumbnail,
    screenId,
    thumbnailsRefreshProgress,
  } = props;

  const checkTextOverflowContainerRef = useRef<HTMLDivElement>();
  const thumbnailTextRef = useRef<HTMLDivElement>();
  const isRefreshInProgress = thumbnailsRefreshProgress.get(screenId);

  const onCheckboxChange = (): void => {
    toggleThumbnailBorder(!thumbnailBorder);
  };

  const onThumbnailNameChange: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    const container = checkTextOverflowContainerRef.current;
    const { lineHeight } = getComputedStyle(container);
    const newName = (event.target as HTMLDivElement).textContent + event.key;
    container.textContent = newName;

    if (container.getBoundingClientRect().height > getIntegerFromStyle(lineHeight) * 2) {
      event.preventDefault();

      return;
    }
  };

  const selectAllText = (element: HTMLDivElement): void => {
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const enableEditMode = (): void => {
    const textElement = thumbnailTextRef.current;
    textElement.setAttribute('contentEditable', 'true');
    selectAllText(textElement);
  };

  const disableEditMode = (): void => {
    const textElement = thumbnailTextRef.current;
    setThumbnailName(textElement.textContent);
    textElement.setAttribute('contentEditable', 'false');
  };

  const onKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === KeyboardKey.ENTER) {
      event.preventDefault();
      disableEditMode();
    } else {
      onThumbnailNameChange(event);
    }
  };

  const onRefreshThumbnail = () => {
    refreshThumbnail(screenId);
  };

  return (
    <>
      <div ref={checkTextOverflowContainerRef} className={styles.checkTextOverflowContainer} />
      <p className={styles.title}>{intlGet('ThumbnailTab', 'ScreenThumbnail')}</p>
      <p className={styles.description}>{intlGet('ThumbnailTab', 'SlideViewPages')}</p>
      <Checkbox onChange={onCheckboxChange} checked={thumbnailBorder} label={intlGet('ThumbnailTab', 'Border')} />
      <div className={styles.thumbnailPreviewWrapper}>
        <div
          className={classNames(styles.thumbnailBackground, { [styles.loading] : isRefreshInProgress })}
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
        >
          {isRefreshInProgress && <Spinner className={styles.spinner__thumbnailTab}/>}
        </div>
        <div
          className={classNames(styles.thumbnailPreview, { [styles.border]: thumbnailBorder })}
          style={{ borderColor: thumbnailBorder && color }}
          onBlur={disableEditMode}
        >
          <div
            ref={thumbnailTextRef}
            className={styles.thumbnailName}
            style={{ color }}
            onKeyPress={onKeyPress}
            onDoubleClick={enableEditMode}
            contentEditable={false}
          >
            {thumbnailName}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.refreshThumbnailButton, { [styles.disabled]: isRefreshInProgress })}
        onClick={isRefreshInProgress ? null : onRefreshThumbnail}
      >
        <Icon type={IconType.REFRESH} size="badge" color="primary" />
        <span className={styles.refreshThumbnailButtonText}>{intlGet('ThumbnailTab', 'RefreshThumbnail')}</span>
      </div>
    </>
  );
};

export default ThumbnailTab;
