import React from 'react';
/* eslint-disable max-len */

const Settings = (): JSX.Element => (
  <svg viewBox="0 0 12 12">
    <path d="M10.5901077,6.585 C10.6132511,6.3975 10.62868,6.2025 10.62868,6 C10.62868,5.7975 10.6132511,5.6025 10.5823932,5.415 L11.8861381,4.425 C12.0018551,4.335 12.0327129,4.17 11.9632827,4.0425 L10.7289681,1.965 C10.6518234,1.83 10.4898196,1.785 10.3509592,1.83 L8.81578034,2.43 C8.49177274,2.19 8.1523362,1.995 7.77432734,1.845 L7.54289334,0.255 C7.51974994,0.105 7.388604,0 7.23431467,0 L4.76568533,0 C4.611396,0 4.48796453,0.105 4.46482113,0.255 L4.23338713,1.845 C3.85537826,1.995 3.50822726,2.1975 3.19193413,2.43 L1.65675526,1.83 C1.51789486,1.7775 1.35589106,1.83 1.27874639,1.965 L0.044431721,4.0425 C-0.0327129458,4.1775 -0.00185507912,4.335 0.121576388,4.425 L1.42532126,5.415 C1.39446339,5.6025 1.37131999,5.805 1.37131999,6 C1.37131999,6.195 1.38674892,6.3975 1.41760679,6.585 L0.113861921,7.575 C-0.00185507912,7.665 -0.0327129458,7.83 0.0367172543,7.9575 L1.27103192,10.035 C1.34817659,10.17 1.51018039,10.215 1.64904079,10.17 L3.18421966,9.57 C3.50822726,9.81 3.8476638,10.005 4.22567266,10.155 L4.45710666,11.745 C4.48796453,11.895 4.611396,12 4.76568533,12 L7.23431467,12 C7.388604,12 7.51974994,11.895 7.53517887,11.745 L7.76661287,10.155 C8.14462174,10.005 8.49177274,9.8025 8.80806587,9.57 L10.3432447,10.17 C10.4821051,10.2225 10.6441089,10.17 10.7212536,10.035 L11.9555683,7.9575 C12.0327129,7.8225 12.0018551,7.665 11.8784236,7.575 L10.5901077,6.585 L10.5901077,6.585 Z M6,8.25 C4.72711302,8.25 3.68566004,7.2375 3.68566004,6 C3.68566004,4.7625 4.72711302,3.75 6,3.75 C7.27288698,3.75 8.31433996,4.7625 8.31433996,6 C8.31433996,7.2375 7.27288698,8.25 6,8.25 Z" />
  </svg>
);

export default Settings;
