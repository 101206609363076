import { batchActions } from 'redux-batched-actions';
import { call, put, select, take } from 'redux-saga/effects';
import guid from 'uuid';

import { DocumentSavingStatus, EntityType, ModalType } from 'const';
import { unlockProjectContent } from 'containers/App/actions';
import { replaceReferenceCitationWithinComponents } from 'containers/ArtboardCell/services/replaceReferenceCitationsWithinComponents';
import { hideModal, showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { deleteUploadingDocumentId } from 'containers/Project/actions';
import { handleSagaError } from 'services/handleError';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';
import { addReferenceCitation as addReferenceCitationAction } from '../actions';
import { Action } from '../models';
import { citationsForAssetsPanel as citationsSelector } from '../selectors';
import { updateEditedDocument } from '../services/updateEditedDocument';
import { updateReferenceCitationDocument } from '../services/updateReferenceCitationDocument';
import { addReferenceCitation } from './addReferenceCitation';

export function* updateReferenceRequest(action: Action.IUpdateReferenceRequest) {
  const documentSaveWindowId = guid();
  let uploadingDocumentId = guid();

  try {
    const { id, referenceText, documentIdsToUpdate } = action.payload;
    const referenceCitations: ReturnTypeSaga<typeof citationsSelector> = yield select(citationsSelector);
    const referenceCitation = referenceCitations.get(id);
    const updatedReferenceCitation = referenceCitation.set('text', referenceText);

    yield put(showModal(ModalType.DOCUMENT_SAVE, { entityType: EntityType.REFERENCE_CITATION, documentId: id }, documentSaveWindowId));

    const { payload: { response: actionType } }: ModalManagerModels.Action.IHideModal<DocumentSavingStatus> = yield take(
      takeModalWindowResponse(documentSaveWindowId),
    );

    // TODO: use batch actions update
    switch (actionType) {
      case DocumentSavingStatus.CREATE:
        const newReferenceId: string = yield* addReferenceCitation(addReferenceCitationAction(documentSaveWindowId, referenceText));

        if (!newReferenceId) {
          return;
        }

        yield call(replaceReferenceCitationWithinComponents, documentIdsToUpdate, id, newReferenceId);

        return;
      case DocumentSavingStatus.EDIT:
        function* updateDocument() {
          yield call(updateReferenceCitationDocument, updatedReferenceCitation);
        }

        function* replaceDocument(uploadingDocumentId: string) {
          yield call(replaceReferenceCitationWithinComponents, documentIdsToUpdate, id, uploadingDocumentId);
        }

        uploadingDocumentId = yield call(
          updateEditedDocument,
          id,
          referenceText,
          updateDocument,
          replaceDocument,
        );

        return;
      default: return;
    }
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.updateReferenceRequest', 'UpdateReferenceCitation');
  } finally {
    yield put(batchActions([
      unlockProjectContent(),
      hideModal(documentSaveWindowId),
      deleteUploadingDocumentId(uploadingDocumentId),
    ]));
  }
}
