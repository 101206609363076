export enum DocumentsKey {
  CALL_TO_ACTIONS = 'callToActions',
  GROUP_LAYOUTS = 'groupLayouts',
  IMAGES = 'images',
  LAYOUTS = 'reusableLayouts',
  MAGIC_FORMS = 'magicForms',
  MASTER_SURFACES = 'masterSurfaces',
  MODULE_BUNDLES = 'moduleBundles',
  PROJECT_FILES = 'projectFiles',
  REFERENCE_CITATIONS = 'referenceCitations',
  STORY_CARDS = 'storyCards',
  TEXT_COLLECTIONS = 'textCollections',
  TEXT_COMPONENTS = 'textComponents',
}
