import { $patchStyleText } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection, $isRangeSelection,
  COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_HIGH, FORMAT_TEXT_COMMAND,
  LexicalEditor, createCommand,
} from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import { toPx } from 'utils/toPx';
import { FontPluginStyle } from './style';
import { $getInlineStyle, $patchFontStyle, $patchFontWeight, brandColorToStyle } from './utils';

export const FONT_COMMAND = {
  BRAND_COLOR: createCommand<Models.BrandColorMap | undefined>(),
  BRAND_FONT: createCommand<{
    brandFont: Models.BrandFontMap;
    characterStyle?: Models.CharacterStyleMap;
  }>(),
  SIZE: createCommand<number>(),
  INLINE_STYLE: createCommand<Constants.InlineStyle>(),
} as const;

export function registerCommands(
  editor: LexicalEditor,
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): () => void {
  return mergeRegister(

    editor.registerCommand(
      FONT_COMMAND.BRAND_COLOR,
      (brandColor) => {
        editor.update(() => {
          const selection = $getSelection();
          if (!selection || !$isRangeSelection(selection)) {
            return;
          }
          const color = brandColor
            ? brandColor.toJS() as BrandDefinition.BrandColorLike
            : (brandStyle && BrandDefinition.getDefaultBrandColor(brandStyle, colors));

          $patchStyleText(
            selection,
            {
              [FontPluginStyle.COLOR]: color?.HEX ? color.HEX : Constants.DefaultCustomStyle.FONT_COLOR,
              ... brandColorToStyle(color),
            },
          );
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.BRAND_FONT,
      ({ brandFont, characterStyle }) => {
        editor.update(() => {
          const selection = $getSelection();
          if (selection && $isRangeSelection(selection)) {
            const style: Record<string, string | null> = {
              [FontPluginStyle.BRAND_FONT_NAME]: brandFont.get('name') ?? null,
              [FontPluginStyle.FONT_FAMILY]: BrandDefinition.getCSSFontFamilyFromBrandFont(
                brandFont.get('name'),
                characterStyle?.get('name'),
                fonts,
              ),
            };
            const characterStyleName = characterStyle?.get('name') ?? null;
            if (characterStyleName) {
              style[FontPluginStyle.CHARACTER_STYLE_NAME] = characterStyleName;
            }
            const fontWeight = characterStyle?.get('fontWeight');
            if (fontWeight) {
              style[FontPluginStyle.FONT_WEIGHT] = fontWeight;
            }
            const fontStyle = characterStyle?.get('fontStyle');
            if (fontStyle) {
              style[FontPluginStyle.FONT_STYLE] = fontStyle;
            }
            $patchStyleText(selection, style);
          }
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.SIZE,
      (size) => {
        // IN-PROGRESS: revise logick from draftjs useEditor
        editor.update(() => {
          const selection = $getSelection();
          if (selection && $isRangeSelection(selection)) {
            $patchStyleText(selection, {
              [FontPluginStyle.FONT_SIZE]: toPx(size) ?? null,
            });
          }
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.INLINE_STYLE,
      (style) => {
        // IN-PROGRESS: revise logick for applyBrandStyles from draftjs useEditor

        if (style === Constants.InlineStyle.UNDERLINE) {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        } else {
          editor.update(() => {
            const selection = $getSelection();
            if (!selection && !$isRangeSelection(selection)) {
              return;
            }
            const inlineStyles = $getInlineStyle();
            switch (style) {
              case Constants.InlineStyle.BOLD:
                $patchFontWeight(selection, !inlineStyles.includes(Constants.InlineStyle.BOLD));
                break;
              case Constants.InlineStyle.ITALIC:
                $patchFontStyle(selection, inlineStyles.includes(Constants.InlineStyle.ITALIC));
                break;
              default:
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-console
                console.error(`unknown style ${style}`);
            }
          });
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FORMAT_TEXT_COMMAND,
      (formatType) => {
        if (formatType === 'bold') {
          editor.dispatchCommand(FONT_COMMAND.INLINE_STYLE, Constants.InlineStyle.BOLD);

          return true;
        }
        if (formatType === 'italic') {
          editor.dispatchCommand(FONT_COMMAND.INLINE_STYLE, Constants.InlineStyle.ITALIC);

          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH,
    ),
  );
}
