import { call, put, select, take } from 'redux-saga/effects';

import { areImagesBeingDownloaded as areImagesBeingDownloadedSelector } from 'containers/App/selectors';
import { screenIdsReadyForOutput as screenIdsReadyForOutputSelector } from 'containers/ArtboardPreview/selectors';
import { ActionTypes } from 'containers/Artboards/constants';
import { artboardsContext } from 'containers/Common/selectors';
import { updateActiveSurfaceId } from 'containers/Project/actions';
import { activeSurfaceId as activeSurfaceIdSelector, surfaceIds } from 'containers/Project/selectors';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { ArtboardConverter } from 'services/ArtboardConverter';
import { ArtboardsJson, ScreenOptionsById } from 'services/ArtboardConverter/models';

export function* getArtboardsJson(screenIds?: string[], options?: ScreenOptionsById): Generator<unknown, ArtboardsJson> {
  const areImagesBeingDownloaded: ReturnTypeSaga<typeof areImagesBeingDownloadedSelector> = yield select(areImagesBeingDownloadedSelector);
  if (areImagesBeingDownloaded) {
    // eslint-disable-next-line no-console
    console.warn('Images are not downloaded yet. This can produce discrepancies between artboards and generated content');
  }

  const activeScreenId: ReturnTypeSaga<typeof activeSurfaceIdSelector> = yield select(activeSurfaceIdSelector);
  const screenIdsReadyForOutput: ReturnTypeSaga<typeof screenIdsReadyForOutputSelector> = yield select(screenIdsReadyForOutputSelector);

  const screenIdsToConvert: string[] = screenIds || (yield select(surfaceIds)).toJS();
  const screenIdsToRender = screenIdsToConvert.filter(surfaceId => !screenIdsReadyForOutput.has(surfaceId));

  for (let i = 0; i < screenIdsToRender.length; i++) {
    yield put(updateActiveSurfaceId(screenIdsToRender[i]));
    yield take(ActionTypes.ARTBOARD_READY);
  }

  // to restore an initially active screen
  if (activeScreenId !== (yield select(activeSurfaceIdSelector))) {
    yield put(updateActiveSurfaceId(activeScreenId));
  }

  const context: ReturnTypeSaga<typeof artboardsContext> = yield select(artboardsContext);
  const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
  const converter = new ArtboardConverter(context, rootDocument);
  const convertedArtboards: ReturnTypeSaga<typeof converter.createOutput> = yield call(
    [converter, converter.createOutput],
    {
      screenIds: screenIdsToConvert,
      screenOptionsById: options,
    },
  );

  return convertedArtboards;
}
