import _ from 'lodash';

import { Layer } from 'const';
import { toJS } from './immutable';

export function getCurrentMetadataByLayer<T extends object>(layer: Layer, originalData: T, targetData: T, isProjectTranslatable: boolean): T {
  return isProjectTranslatable
    ? layer === Layer.TRANSLATED && !_.isEmpty(toJS(targetData))
      ? targetData
      : originalData
    : targetData;
}
