import Immutable from 'immutable';

import * as Models from 'models';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const documentsInitialState = Immutable.Map() as State.IState;

const documentTypesReducer: Models.Reducer<State.IState> =
  (state = documentsInitialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_DOCUMENT_TYPES:
        return setDocumentTypes(state, action);
      default:
        return state;
    }
  };

const setDocumentTypes: Models.Reducer<State.IState, Action.ISetDocumentTypes> = (state, action) => (
  Immutable.fromJS(action.payload.documentTypes || {})
);

export default documentTypesReducer;
