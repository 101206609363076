import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import ActionIcon from 'components/ActionIcon';
import { ActionsColor } from 'components/ArtboardLayoutActions/constants';
import { IconType } from 'components/Icon/constants';
import { AddCellDirection, SplitCellDirection } from 'const';
import { IArtboardCellActionsProps, IArtboardCellActionsState } from './models';
import styles from './styles.module.scss';

export default class ArtboardCellActions extends React.PureComponent<IArtboardCellActionsProps, IArtboardCellActionsState> {
  readonly state: IArtboardCellActionsState = {
    actionsExpanded: false,
    showActions: false,
  };

  private showActions = (): void => {
    if (!this.props.areActionsDisabled) {
      this.setState({ showActions: true });
    }
  };

  private expandActions = (): void => {
    if (!this.props.areActionsDisabled) {
      this.setState({ showActions: true, actionsExpanded: true });
    }
  };

  private collapseActions = (): void => {
    this.setState({ actionsExpanded: false });
  };

  private hideActions = (): void => {
    this.setState({ showActions: false, actionsExpanded: false });
  };

  render() {
    const {
      addCell,
      canDrop,
      cellWidth,
      children,
      component,
      copyContent,
      copyingEnabled,
      deleteCell,
      deletingDisabled,
      isAddingDisabled,
      isEditMode,
      isOver,
      isSelected,
      onSelectHandler,
      outlineOnly,
      splitCell,
    } = this.props;
    const {
      actionsExpanded,
      showActions,
    } = this.state;

    const cellClassName = classNames(
      styles.ArtboardCellsActions,
      {
        [styles.showActions]: showActions || isSelected || isEditMode,
        [styles.over]: isOver && showActions,
        [styles.canDrop]: canDrop,
        [styles.outlineOnly]: outlineOnly,
        [styles.large]: _.inRange(cellWidth, 33, 121),
        [styles.medium]: _.inRange(cellWidth, 11, 33),
        [styles.small]: _.inRange(cellWidth, 0, 11),
      },
    );

    return (
      <div
        ref={component}
        className={cellClassName}
        onMouseOver={this.showActions}
        onMouseLeave={this.hideActions}
        onMouseDown={onSelectHandler}
      >
        {children}
        <div className={styles.rightActions}>
          {
            actionsExpanded
              ?
              <div className={styles.expanded} onMouseLeave={this.collapseActions}>
                <ActionIcon
                  backgroundColor={ActionsColor.ORANGE}
                  type={IconType.DELETE}
                  onClick={deleteCell}
                  size="xs"
                  isDisabled={deletingDisabled}
                  color="secondary"
                />
                {
                  copyingEnabled &&
                  <ActionIcon
                    backgroundColor={ActionsColor.ORANGE}
                    type={IconType.COPY}
                    size="xs"
                    onClick={copyContent}
                    color="secondary"
                  />
                }
                <ActionIcon
                  backgroundColor={ActionsColor.ORANGE}
                  type={IconType.ADD}
                  size="xs"
                  onClick={() => splitCell(SplitCellDirection.RIGHT)}
                  isDisabled={isAddingDisabled}
                  color="secondary"
                />
              </div>
              :
              <ActionIcon
                backgroundColor={ActionsColor.ORANGE}
                type={IconType.MENU}
                size="xs"
                color="secondary"
                onMouseEnter={this.expandActions}
              />
          }
        </div>
        <div className={styles.leftActions}>
          <ActionIcon
            backgroundColor={ActionsColor.ORANGE}
            type={IconType.ADD}
            size="xs"
            onClick={() => splitCell(SplitCellDirection.LEFT)}
            isDisabled={isAddingDisabled}
            color="secondary"
          />
        </div>
        <div className={styles.topActions}>
          <ActionIcon
            backgroundColor={ActionsColor.ORANGE}
            type={IconType.ADD}
            size="xs"
            onClick={() => addCell(AddCellDirection.TOP)}
            color="secondary"
          />
        </div>
        <div className={styles.bottomActions}>
          <ActionIcon
            backgroundColor={ActionsColor.ORANGE}
            type={IconType.ADD}
            size="xs"
            onClick={() => addCell(AddCellDirection.BOTTOM)}
            color="secondary"
          />
        </div>
      </div>
    );
  }
}
