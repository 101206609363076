import * as Models from 'models';
import { getRemoveNonexistentBackgroundImagesCallback, validateBackgroundImages } from 'utils/validator';

type Args = Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs | Models.SyncProcessArgs;

export const processBackgroundImages = <T extends Args>(args: T): T => {
  const { assets } = args;
  const { artboards, sections, layouts, relations, documents } = assets;
  const {
    artboards: processedArtboards,
    sections: processedSections,
    layouts: processedLayouts,
    relations: processedRelations,
  } = validateBackgroundImages(artboards, sections, layouts, relations, getRemoveNonexistentBackgroundImagesCallback(documents));

  assets.artboards = processedArtboards;
  assets.sections = processedSections;
  assets.layouts = processedLayouts;
  assets.relations = processedRelations;

  return args;
};
