import { ConnectedRouter } from 'connected-react-router/immutable';
import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ErrorBoundary from 'components/ErrorBoundary';
import { ProjectType } from 'const';
import { AppProps } from 'containers/App/models';
import routes from 'routes';

export const ProjectTypeContext = React.createContext<ProjectType>(undefined);

const App: React.FunctionComponent<AppProps> = ({ history, projectType }) => {
  return (
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <ProjectTypeContext.Provider value={projectType}>
          {routes}
        </ProjectTypeContext.Provider>
      </ErrorBoundary>
    </ConnectedRouter>
  );
};

export default DragDropContext(HTML5Backend)(App);
