import Immutable from 'immutable';

import { Layer } from 'const';
import * as Models from 'models';
import { isTextComponent } from 'utils/entityType';
import { getValue, getValueIn } from 'utils/getter';

export function getTextComponentsIds(
  relationIds: Immutable.List<string> | string[],
  relations: Models.LayeredRelations | Models.LayeredRelationsMap,
  layer: Layer,
): Immutable.List<string>;
export function getTextComponentsIds(
  relationIds: Immutable.List<string> | string[],
  relations: Models.Relations | Models.RelationsMap,
): Immutable.List<string>;
export function getTextComponentsIds(
  relationIds: Immutable.List<string> | string[],
  relations: Models.Relations | Models.RelationsMap | Models.LayeredRelations | Models.LayeredRelationsMap,
  layer?: Layer,
): Immutable.List<string> {
  return (Immutable.fromJS(relationIds) as Immutable.List<string>)
    .filter(relationId => isTextComponent(getValue(relations as Models.RelationsMap, relationId)))
    .map(relationId => layer
      ? getValueIn(relations as Models.LayeredRegularRelationsMap, [relationId, 'documentId', layer])
      : getValueIn(relations as Models.RegularRelationsMap, [relationId, 'documentId']),
    )
    .filter(Boolean);
}
