import _ from 'lodash';

/**
 * Converts string to camel case with 1st letter capitalized (also known as Pascal case):
 * https://en.wikipedia.org/wiki/Camel_case
 * @param string - string to convert
 * @example
 * upperCamelCase('FOO BAR');     // => 'FooBar'
 * upperCamelCase('__FOO_BAR__'); // => 'FooBar'
 * upperCamelCase('--foo--bar-'); // => 'FooBar'
 */
export const upperCamelCase = _.flow(_.camelCase, _.upperFirst);
