import _ from 'lodash';

import { AUTOGEN_COLORS_CATEGORY, HEX_COLOR_START_KEY } from 'const';
import { createBrandColor, createColorsCategory } from 'factories/style/brandStyles';
import { OnlineBrandDefinition } from 'models';
import { colorExistsInColorsCategories } from './colorExistsInBrandColors';

/**
 * Appends colors category generated from Brand Styles
 */
export function appendColorsCategory(brandDefinition: OnlineBrandDefinition): OnlineBrandDefinition {
  const newBrandDefinition = _.cloneDeep(brandDefinition);
  newBrandDefinition.colorsCategories = newBrandDefinition.colorsCategories || [];
  const { colorsCategories, colors, brandStyles } = newBrandDefinition;

  if (_.isEmpty(brandStyles)) {
    return newBrandDefinition;
  }

  const autogenColorsCategory = _.find(colorsCategories, category => category.name === AUTOGEN_COLORS_CATEGORY);
  if (!autogenColorsCategory) {
    colorsCategories.push(createColorsCategory());
  }

  _.forEach(brandStyles, brandStyle => brandStyle.breakpoints.forEach((breakpoint) => {
    const { style: { backgroundColor } } = breakpoint; // Maybe in future need to make same for "color" prop
    if (
      backgroundColor
      && !colorExistsInColorsCategories(backgroundColor, colors)
      && backgroundColor.startsWith(HEX_COLOR_START_KEY)
    ) {
      const color = createBrandColor(backgroundColor);
      colors.push(color);
      colorsCategories
        .find(category => category.name === AUTOGEN_COLORS_CATEGORY).brandColors
        .push(color.name);
    }
  }));

  return newBrandDefinition;
}
