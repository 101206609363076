import guid from 'uuid';

import { DocumentStatus } from 'const';
import { Document } from 'models';

export function baseDocumentFactory({
  classification = null,
  country = null,
  creationDate = null,
  documentId = null,
  documentSource = null,
  entityType = null,
  filename = null,
  id,
  isEdited = true,
  isUpdatedInPromoMats = false,
  language = [],
  modifyDate = null,
  name = '',
  number = null,
  product = null,
  resourceType = null,
  status = DocumentStatus.DRAFT,
  subtype = null,
  type = null,
  version = '',
  checksum = null,
  description = '',
  isMockDocument = false,
} = {} as Partial<Document>): Document {
  return {
    classification,
    country,
    creationDate,
    documentId,
    documentSource,
    entityType,
    filename,
    id: id || guid(),
    isEdited,
    isUpdatedInPromoMats,
    language,
    modifyDate,
    name,
    number,
    product,
    resourceType,
    status,
    subtype,
    type,
    version,
    checksum,
    description,
    isMockDocument,
  };
}
