import _ from 'lodash';

import { Layer } from 'const';
import { deleteLayout } from 'containers/Layouts/services/deleteLayout';
import * as Models from 'models';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';

interface DeleteLayoutsFromArtboardsArg {
  activeLayer: Layer;
  layouts: Models.LayeredCombinedLayouts;
  layoutsToUpdate?: Models.LayeredCombinedLayouts;
  artboards: Models.Artboards;
  relations: Models.LayeredRelations;
  documents: Models.CombinedDocuments;
}

interface DeleteLayoutsFromArtboardsByDocumentIdsArg extends DeleteLayoutsFromArtboardsArg {
  layoutDocumentIds: string[];
}

export function deleteLayoutsFromArtboardsByDocumentIds<T extends DeleteLayoutsFromArtboardsByDocumentIdsArg>(arg: T): void {
  const {
    layoutDocumentIds,
    layouts,
    layoutsToUpdate,
    artboards,
    relations,
    documents,
    activeLayer,
  } = arg;

  const layoutIdsToDelete = _(layoutsToUpdate || layouts)
    .pickBy(layout => layoutDocumentIds.includes(layout.documentId[activeLayer]))
    .keys()
    .value();

  deleteLayoutsFromArtboardsByLayoutIds({
    layoutIdsToDelete,
    layouts,
    artboards,
    relations,
    documents,
    activeLayer,
  });
}

interface DeleteLayoutsFromArtboardsByLayoutIdsArg extends DeleteLayoutsFromArtboardsArg {
  layoutIdsToDelete: string[];
}

export function deleteLayoutsFromArtboardsByLayoutIds<T extends DeleteLayoutsFromArtboardsByLayoutIdsArg>(arg: T): void {
  const {
    layoutIdsToDelete,
    layouts,
    layoutsToUpdate,
    artboards,
    relations,
    documents,
    activeLayer,
  } = arg;

  const layoutsToDelete = _.pick(layouts, layoutIdsToDelete);

  if (_.isEmpty(layoutsToDelete)) {
    return;
  }

  _.forEach(layoutsToDelete, (layout => deleteLayout(layout, layouts, relations, documents, activeLayer)));
  _.forEach(artboards, artboard => _.pull(artboard.layoutIds, ...layoutIdsToDelete));
  _.forEach(layoutsToUpdate || layouts, (layout) => { isGroupLayout(layout) && _.pull(layout.layoutIds, ...layoutIdsToDelete); });
}
