import React from 'react';

import { IImageListItemProps } from './models';
import styles from './styles.module.scss';

const ImageListItemDragPreview: React.FunctionComponent<IImageListItemProps> = ({ imageSrc }) => {
  return (
    <div className={styles.ImageListItemDragPreview}>
      <img className={styles.ImageListItemDragPreview__image} src={imageSrc} />
    </div>
  );
};

export default ImageListItemDragPreview;
