import Draft from 'draft-js';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as editorUtils from 'utils/editor';

type Handler = (chars: string) => Draft.DraftHandleValue;

export function useHandleBeforeInputWithFormat(
  editorState: Draft.EditorState,
  onEditorChange: (state: Draft.EditorState) => void,
  fillUndoStackIfEmpty: () => void,
): Handler {
  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  return useCallback((chars): Draft.DraftHandleValue => {
    fillUndoStackIfEmpty();

    const state = editorStateRef.current;
    if (editorUtils.isInsertingSpaceAfterLink(state, chars)) {
      const newContentState = Draft.Modifier.insertText(
        state.getCurrentContent(),
        state.getSelection(),
        Constants.Character.SPACE,
        state.getCurrentInlineStyle(),
      );
      onEditorChange(Draft.EditorState.push(
        state,
        newContentState,
        Constants.EditorChangeType.INSERT_CHARACTERS,
      ));

      return Constants.HandleValue.HANDLED;
    }

    return Constants.HandleValue.NOT_HANDLED;
  }, [onEditorChange, fillUndoStackIfEmpty]);
}
