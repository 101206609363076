import Immutable from 'immutable';
import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, delay, put, race, select, spawn, take } from 'redux-saga/effects';

import { DocumentSubtype, EntityType, MethodName, ProjectPanelSection, ProjectPanelTab } from 'const';
import { setLastEditedLayoutId, startHandlingReusableLayoutsEditing, stopHandlingReusableLayoutsEditing } from 'containers/App/actions';
import { handleReusableLayoutsEditing } from 'containers/App/selectors';
import { ActionTypes as ArtboardPreviewActionTypes } from 'containers/ArtboardPreview/constants';
import { brandDefinition } from 'containers/BrandDefinition/selectors';
import { layoutAssets as layoutAssetsSelector } from 'containers/Common/selectors';
import { getLayoutPreviewUrl } from 'containers/Common/services/getLayoutPreviewUrl';
import { deleteDocuments, mergeDocuments, setDocument, updateDocument } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { addLayouts, setLayout } from 'containers/Layouts/actions';
import { ActionTypes } from 'containers/Layouts/constants';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { getTextComponentIdsToDelete } from 'containers/Layouts/services/getTextComponentIdsToDelete';
import { deleteUploadingDocumentId, setUploadingDocumentId } from 'containers/Project/actions';
import * as projectSelectors from 'containers/Project/selectors';
import { addActiveSection, setActiveTab, setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { deleteRelations, mergeRelations } from 'containers/Relations/actions';
import { layeredRelations } from 'containers/Relations/selectors';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { layoutDocumentFactory } from 'factories/document/layoutDocumentFactory';
import * as Models from 'models';
import { createLayout } from 'services/api';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { isMockDocument } from 'utils/documents';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';
import { toJS } from 'utils/immutable';
import { intlGet } from 'utils/intlGet';
import { getGroupLayoutsChildLayoutIds } from 'utils/layouts/getGroupLayoutsChildLayoutIds';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { updateLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';
import { replaceRelationsWithCopies } from 'utils/reusableLayouts/replaceRelationsWithCopies';
import { isRelationUsedSeveralTimes } from 'utils/validator/isRelationStillUsed';
import { Action } from '../models';

export function* saveReusableLayout(action: Action.SaveReusableLayout) {
  let mockLayoutDocumentId: string;
  let layoutToSave: Models.LayoutMap;
  let relationIdsToDelete: string[];
  let documentIdsToDelete: string[];
  let relationIdsToSet: string[];
  let documentIdsToSet: string[];

  const logId = logger.performanceStart();
  const methodName = MethodName.CREATE_REUSABLE_LAYOUT;

  const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
  const relations = (yield select(layeredRelations)).toJS() as Models.LayeredRelations;

  try {
    const {
      saveReusableLayoutPayload: {
        classification,
        layoutId,
        name,
        previewUrl = null,
        subtype,
      },
    } = action.payload;

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const layouts: ReturnTypeSaga<typeof layoutsSelector> = yield select(layoutsSelector);
    layoutToSave = layouts.get(layoutId) as Models.LayoutMap;

    const layer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);
    const reusableLayoutsEditingActive: ReturnTypeSaga<typeof handleReusableLayoutsEditing> = yield select(handleReusableLayoutsEditing);
    const language = (yield select(projectSelectors.currentLanguage)).toJS() as string[];
    const country = (yield select(projectSelectors.currentCountry)).toJS() as string[];
    const product = (yield select(projectSelectors.currentProduct)).toJS() as string[];
    const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;

    const documentInternalId = layoutToSave.get('documentId');
    const currentLayoutDocument = _.get(documents, [documentInternalId, layer]);
    const needToCreateNewInstance = documentInternalId && isPlainLayout(layoutToSave) && !isMockDocument(currentLayoutDocument);

    const {
      relations: relationsToSet,
      newDocuments: documentsToSet,
      newRelationIdsByOld,
    }: ReturnTypeSaga<typeof replaceRelationsWithCopies> = yield call(
      replaceRelationsWithCopies,
      layoutToSave.get('relationId'),
      relations,
      documents,
      layer,
    );
    relationIdsToSet = _.keys(relationsToSet);
    documentIdsToSet = _.keys(documentsToSet);

    const oldRelationIds = _.keys(newRelationIdsByOld);
    relationIdsToDelete = oldRelationIds.filter(relationId => !isRelationUsedSeveralTimes(relationId, toJS(layouts), relations));
    documentIdsToDelete = getTextComponentIdsToDelete(toJS(layoutToSave), relationIdsToDelete, documents, relations, layer);

    const id = !needToCreateNewInstance && documentInternalId;
    const mockLayoutDocument = layoutDocumentFactory({
      id,
      previewUrl,
      name,
      documentId: _.get(currentLayoutDocument, 'documentId'),
      language,
      country,
      product,
      subtype: DocumentSubtype.CONTENT_UNIT,
      classification,
      entities: {
        brandDefinition: _.get(currentLayoutDocument, ['entities', 'brandDefinition']) || projectBrandDefinition,
      } as Models.ReusableLayoutEntities,
    });
    mockLayoutDocumentId = mockLayoutDocument.id;

    // replace old relationId with new one
    const updatedLayout = layoutToSave
      .set('documentId', mockLayoutDocumentId)
      .update('relationId', relationId => newRelationIdsByOld[relationId]);

    const groupLayoutsChildLayoutIds = getGroupLayoutsChildLayoutIds(layouts);
    const isEditedLayoutInsideOfGroupLayout = groupLayoutsChildLayoutIds.includes(layoutId);
    let updatedLayouts = Immutable.List([updatedLayout]) as Models.CombinedLayoutsList;

    if (isEditedLayoutInsideOfGroupLayout) {
      const layoutInstancesInsideOfGroupLayouts = layouts
        .filter((layout, id) => groupLayoutsChildLayoutIds.includes(id) && layout.get('documentId') === layoutToSave.get('documentId'))
        .map(layout => (layout as Models.LayoutMap).set('documentId', updatedLayout.get('documentId'))) as Models.CombinedLayoutsMap;

      updatedLayouts = updateLayoutInstances(updatedLayout, layoutInstancesInsideOfGroupLayouts).toList();
    }

    reusableLayoutsEditingActive && (yield put(stopHandlingReusableLayoutsEditing()));
    yield put(batchActions([
      mergeRelations(relationsToSet),
      mergeDocuments(documentsToSet),
      setDocument(mockLayoutDocument),
      setUploadingDocumentId(mockLayoutDocumentId),
      setAssetIdToScroll(mockLayoutDocumentId),
      setActiveTab(ProjectPanelTab.ASSETS),
      addActiveSection(ProjectPanelSection.REUSABLE_LAYOUTS),
      addLayouts(updatedLayouts),
      deleteDocuments(documentIdsToDelete),
      deleteRelations(relationIdsToDelete),
    ]));
    reusableLayoutsEditingActive && (yield put(startHandlingReusableLayoutsEditing()));

    const layoutAssets = (yield select(layoutAssetsSelector(layoutId, { cleanUp: true }))).toJS() as Models.LayoutAssets;
    // let 'restoreLayoutAssets' saga know that group layout instances can be restored
    yield put({ type: ActionTypes.RESTORE_LAYOUT_ASSETS });

    // after replacing layout relations need to wait for updating their heights for new relation ids
    let needToStop = false;
    while (!needToStop) {
      ([, needToStop] = yield race([
        take(ArtboardPreviewActionTypes.SET_CELL_HEIGHT),
        delay(1000),
      ]));
    }

    if (!previewUrl) {
      const s3PreviewUrl: ReturnTypeSaga<typeof getLayoutPreviewUrl> = yield call(getLayoutPreviewUrl, [layoutId]);
      yield put(updateDocument({ id: mockLayoutDocumentId, previewUrl: s3PreviewUrl, entityType: EntityType.LAYOUT }));
      layoutAssets.previewUrl = s3PreviewUrl;
    }

    const rootDocumentId = rootDocument.get('documentId');
    const { data: layoutDocument }: ReturnTypeSaga<typeof createLayout> = yield call(
      createLayout,
      rootDocumentId,
      layoutAssets,
      { name, subtype, classification },
      { language, country, product },
    );

    const thumbnailInternalInfo: ReturnTypeSaga<typeof getImageInternalInfo> = yield call(getImageInternalInfo, layoutAssets.previewUrl);

    layoutDocument.id = mockLayoutDocumentId;
    layoutDocument.previewUrl = layoutAssets.previewUrl;
    layoutDocument._thumbnailUrl = layoutAssets.previewUrl;
    if (thumbnailInternalInfo) {
      layoutDocument._thumbnailWidth = thumbnailInternalInfo.width;
      layoutDocument._thumbnailHeight = thumbnailInternalInfo.height;
    }

    yield put(batchActions([
      setDocument(layoutDocument),
      ...isEditedLayoutInsideOfGroupLayout
        ? [setLastEditedLayoutId(
          layouts.findKey(layout => isGroupLayout(layout) && (layout as Models.GroupLayoutMap).get('layoutIds').includes(layoutId)),
        )]
        : [],
    ]));

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'LayoutHasBeenSaved'),
      intlGet('Notification', 'Success'),
    );
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'SaveReusableLayoutWindow.saveReusableLayout', 'SaveReusableLayout');

    const reusableLayoutsEditingActive: ReturnTypeSaga<typeof handleReusableLayoutsEditing> = yield select(handleReusableLayoutsEditing);
    reusableLayoutsEditingActive && (yield put(stopHandlingReusableLayoutsEditing()));

    yield put(batchActions([
      // delete mock layout document and hard copies which were added
      deleteDocuments([mockLayoutDocumentId, ...documentIdsToSet]),
      deleteRelations(relationIdsToSet),
      // restore documents and relations which were deleted
      mergeRelations(_.pick(relations, relationIdsToDelete)),
      mergeDocuments(_.pick(documents, documentIdsToDelete)),
      ...layoutToSave ? [setLayout(layoutToSave)] : [],
    ]));

    reusableLayoutsEditingActive && (yield put(startHandlingReusableLayoutsEditing()));
  } finally {
    yield put(deleteUploadingDocumentId(mockLayoutDocumentId));
  }
}
