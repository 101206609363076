import { HeaderIds } from 'const/Header';
import { CreateCustomClickHandlerWithToolbar } from 'hooks/useClickOutside';

export const createUndoRedoClickOutsideHandler: CreateCustomClickHandlerWithToolbar<HTMLDivElement> =
  (cell, toolbar, toggleEditModeOff, editMode) => (event) => {
    if (!editMode || !cell.current) {
      return;
    }

    const eventTarget = event.target as Node;
    // undo redo actions in header should be considered as edit actions DCC-6690
    const undoRedo = window.document.getElementById(HeaderIds.UNDO_REDO);
    const isClickOutside = !toolbar.contains(eventTarget) && !cell.current.contains(eventTarget) && !undoRedo.contains(eventTarget);

    if (isClickOutside) {
      toggleEditModeOff();
    }
  };
