import _ from 'lodash';

import * as Models from 'models';
import { sum } from 'utils/sum';
import { getValue } from '../getter';
import { toJS } from '../immutable';

export function recalculateColumnsWidth(
  layoutRelationStyles: Models.RowRelationStyles | Models.RowRelationStylesMap,
  availableColumnsWidthSum: number,
): number[] {
  const columnsWidth = toJS<number[]>(getValue(layoutRelationStyles, 'columnsWidth'));
  const columnsWidthSum = sum(columnsWidth);

  if (columnsWidthSum === availableColumnsWidthSum) {
    return columnsWidth;
  }

  let newColumnsWidthSum = 0;

  return columnsWidth.map((columnWidth, index) => {
    const newColumnWidth = _.round(availableColumnsWidthSum * columnWidth / columnsWidthSum);

    if (index === columnsWidth.length - 1 || newColumnsWidthSum + newColumnWidth > availableColumnsWidthSum) {
      return _.round(availableColumnsWidthSum - newColumnsWidthSum);
    }

    newColumnsWidthSum += newColumnWidth;

    return newColumnWidth;
  });
}
