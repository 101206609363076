import _ from 'lodash';
import { createAssectAlignmentWithTextAlignment } from 'factories/style/alignment';
import { createBrandColor } from 'factories/style/brandStyles';
import * as Models from 'models';
import { getDesktopBrandStyles, getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { toImmutable, toJS } from 'utils/immutable';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { BrandStyleIdForAbbreviationsList } from '../constants';
import { createAbbreviationsListLayoutStyles, createAbbreviationsListRelationStyles } from '../factories';
import { AbbreviationsListRelationStyles } from '../types';
import { isAbbreviatioinsListLayout } from '../utils/isAbbreviatioinsListLayout';

export function createBrandedStylesForAbbreviationsList(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): {
    relationStyles: Partial<AbbreviationsListRelationStyles>;
    layoutStyles: Partial<Models.LayoutStyles>;
  } {
  const {
    backgroundColor,
    characterStyle,
    fontColor,
    fontFamily,
    fontSize,
    lineHeight,
    padding,
    textAlign,
    verticalAlignment,
  } = getTextStylesFromBrandStyle(brandStyle, colors, fonts);

  const relationStyles: Partial<AbbreviationsListRelationStyles> = {
    brandStyleId: brandStyle.get('id'),
    alignment: createAssectAlignmentWithTextAlignment(textAlign, verticalAlignment),
    fontColor,
    fontFamily,
    fontSize,
    fontStyle: characterStyle,
    lineHeight,
  };

  const layoutStyles: Partial<Models.LayoutStyles> = {
    backgroundColor: backgroundColor
      ? backgroundColor.get('name')
      : createBrandColor(getDesktopBrandStyles(brandStyle).get(Models.BrandStyleProp.BACKGROUND_COLOR)).name,
    padding: toJS(padding) as unknown as Models.BoxProperty,
    // borderRadius will not be applied as for References
  };

  return { relationStyles, layoutStyles };
}

export function processBrandDefinitionForAbbreviations(
  assets: Models.ProjectAssets,
  brandDefinition: Models.OnlineBrandDefinition,
  brandStylesModified: boolean,
): void {
  const {
    brandStyles,
    colors,
    fonts,
  } = brandDefinition;
  const brandStyle = brandStyles[BrandStyleIdForAbbreviationsList];
  const { relations } = assets;

  if (!relations) {
    return;
  }

  let stylesForRelation: AbbreviationsListRelationStyles;
  let stylesForLayout: Partial<Models.LayoutStyles>;
  let shouldUpdateStyles: (styles: AbbreviationsListRelationStyles) => boolean;
  if (brandStyle) {
    const { relationStyles, layoutStyles } = createBrandedStylesForAbbreviationsList(
      toImmutable(brandStyle),
      toImmutable(colors),
      toImmutable(fonts),
    );
    stylesForRelation = createAbbreviationsListRelationStyles(relationStyles);
    stylesForLayout = layoutStyles;
    shouldUpdateStyles = (styles): boolean => brandStylesModified || styles.brandStyleId !== BrandStyleIdForAbbreviationsList;
  } else {
    stylesForRelation = createAbbreviationsListRelationStyles({});
    stylesForLayout = createAbbreviationsListLayoutStyles({});
    shouldUpdateStyles = (styles): boolean => styles.brandStyleId === BrandStyleIdForAbbreviationsList
     || (!styles.brandStyleId && brandStylesModified);
  }

  const abbreviationLayouts = _.pickBy(assets.layouts, isAbbreviatioinsListLayout) as Models.Layouts;
  const relationIdsByLayoutId = _.mapValues(abbreviationLayouts, layout => (
    _.keys(getFlattenedRelations(layout, relations, true))
  ));
  const abbreviationsRelations = _.pick(
    relations,
    _.flatMap(relationIdsByLayoutId),
  ) as Models.LayeredRegularRelations<AbbreviationsListRelationStyles>;

  const layoutIdsToUpdate = new Set<string>();

  // mutate relations
  _.forEach(abbreviationsRelations, (layeredRelation) => {
    _.forEach(layeredRelation.styles, (styles) => {
      if (!styles || !shouldUpdateStyles(styles)) {
        return;
      }

      Object.assign(styles, _.cloneDeep(stylesForRelation));

      // save layout id which should be updated
      const layoutId = _.findKey(relationIdsByLayoutId, relationIds => relationIds.includes(layeredRelation.id));
      if (layoutId) {
        layoutIdsToUpdate.add(layoutId);
      }
    });
  });

  // mutate layouts
  _.forEach(abbreviationLayouts, (layout) => {
    if (!layoutIdsToUpdate.has(layout.id)) {
      return;
    }
    Object.assign(layout.styles, _.cloneDeep(stylesForLayout));
  });
}
