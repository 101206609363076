import classNames from 'classnames';
import Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';

import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import NumericInput from 'components/NumericInput';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { intlGet } from 'utils/intlGet';
import { splitIntoEqualParts } from 'utils/layouts/splitIntoEqualParts';
import { sum } from 'utils/sum';
import { ColumnWidthProps, ColumnWidthState } from './models';
import styles from './styles.module.scss';

export default class ColumnWidth extends React.PureComponent<ColumnWidthProps, ColumnWidthState> {
  readonly state: ColumnWidthState = {
    columnPositionToEdit: null,
    editableColumnWidth: null,
    equalized: false,
  };

  componentWillUnmount() {
    const { columnPositionToEdit, editableColumnWidth } = this.state;

    if (_.isNumber(columnPositionToEdit) && _.isNumber(editableColumnWidth)) {
      this.applyNewColumnWidth(columnPositionToEdit, editableColumnWidth);
    }
  }

  private applyNewColumnWidth = (columnPosition: number, newValue: number): void => {
    const { columnsWidth, applyColumnsWidth, getMinColumnWidth } = this.props;

    // if a column is the last column, the column to the left should be resized
    const editableNeighborPosition = columnsWidth.size === columnPosition + 1 ? columnPosition - 1 : columnPosition + 1;
    const editableNeighborWidth = columnsWidth.get(editableNeighborPosition);
    const minColumnWidth = getMinColumnWidth(columnPosition);
    const columnWidth = columnsWidth.get(columnPosition);

    const maxWidth = columnWidth + editableNeighborWidth - minColumnWidth;

    if (newValue >= minColumnWidth && newValue <= maxWidth) {
      const newEditableNeighborWidth = editableNeighborWidth - (newValue - columnWidth);
      applyColumnsWidth(columnsWidth.set(columnPosition, newValue).set(editableNeighborPosition, newEditableNeighborWidth));
    }

    this.setState({
      columnPositionToEdit: null,
      editableColumnWidth: null,
    });
  };

  private applyColumnWidth = (): void => {
    const { columnPositionToEdit, editableColumnWidth } = this.state;
    this.applyNewColumnWidth(columnPositionToEdit, editableColumnWidth);
  };

  private equalizeColumns = (): void => {
    const { applyColumnsWidth, columnsWidth } = this.props;
    const { equalized } = this.state;
    const width = sum(columnsWidth);

    !equalized && applyColumnsWidth(Immutable.List(splitIntoEqualParts(width, columnsWidth.size)));

    this.setState(state => ({
      equalized: !state.equalized,
    }));
  };

  private onFocus = (columnPositionToEdit: number): void => {
    const { columnsWidth } = this.props;

    this.setState({
      columnPositionToEdit,
      editableColumnWidth: columnsWidth.get(columnPositionToEdit),
    });
  };

  private setEditableColumnWidth = (columnPositionToEdit: number, editableColumnWidth: number): void => {
    this.setState({
      columnPositionToEdit,
      editableColumnWidth,
    });
  };

  private getColumns = (): JSX.Element => {
    const { columnPositionToEdit, editableColumnWidth, equalized } = this.state;
    const { columnsWidth, deleteLayoutColumn } = this.props;
    const isOneColumn = columnsWidth.size === 1;

    return (
      <div className={styles.columns}>
        <div className={styles.title}>{intlGet('EditorToolbar.BackgroundImage', 'Columns')}</div>
        <div>
          <div className={styles.columnsContainer}>
            <div>
              {columnsWidth.map((columnWidth, columnPosition) => (
                <div key={`${columnPosition}-${columnWidth}`} className={styles.item}>
                  <NumericInput
                    value={columnPositionToEdit === columnPosition ? editableColumnWidth : columnWidth}
                    disabled={equalized || isOneColumn}
                    onChange={this.setEditableColumnWidth.bind(null, columnPosition)}
                    onBlur={this.applyColumnWidth}
                    onEnterPress={this.applyColumnWidth}
                    onButtonClick={this.applyNewColumnWidth.bind(null, columnPosition)}
                    onFocus={this.onFocus.bind(null, columnPosition)}
                  />
                  <div className={styles.description}>{intlGet('EditorToolbar.ColumnWidth', 'Column', { number: columnPosition + 1 })}</div>
                  {!isOneColumn &&
                    <Icon type={IconType.DELETE_BIN} color="secondary" size="sm" onClick={deleteLayoutColumn.bind(null, columnPosition)}/>
                  }
                </div>
              ))}
            </div>
            {!isOneColumn &&
              <div className={styles.equalize}>
                <Checkbox
                  className={styles.checkbox}
                  onChange={this.equalizeColumns}
                  checked={equalized}
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { addLayoutColumn, isAddingDisabled } = this.props;

    return (
      <Dropdown className={styles.ColumnWidth} stopClickPropagation={true}>
        {this.getColumns()}
        <div className={classNames(styles.button, { [styles.disabled]: isAddingDisabled })} onClick={isAddingDisabled ? null : addLayoutColumn}>
          <Icon type={IconType.PLUS} color={isAddingDisabled ? 'disabled' : 'secondary'} />
          <div className={styles.buttonDescription}>{intlGet('EditorToolbar.ColumnWidth', 'AddColumn')}</div>
        </div>
      </Dropdown>
    );
  }
}
