import { DraftEntity } from 'const';
import * as Models from 'models';
import { removeAbbreviationEntities } from './removeAbbreviationEntities';

export function removeAbbreviationsIfNotPresent(textAbbreviationDocuments: Models.TextAbbreviationDocumentsArray, rawContent): string {

  const content: Draft.RawDraftContentState = JSON.parse(rawContent);

  if (!content.entityMap) {
    return rawContent;
  }

  for (const entityIndex in content.entityMap) {
    const entity = content.entityMap[entityIndex];
    const isTextAbbreviationDocumentPresent = Boolean(textAbbreviationDocuments[entity.data.id as string]);
    if (entity.type === DraftEntity.ABBREVIATION && !isTextAbbreviationDocumentPresent) {
      removeAbbreviationEntities(content, entityIndex);
    }
  }

  return JSON.stringify(content);
}
