import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Export from 'components/ModalWindows/Export';
import { hideModal } from 'containers/ModalManager/actions';
import { projectType } from 'containers/Project/selectors';
import { exportPackage } from './actions';

const mapStateToProps = createStructuredSelector({
  projectType,
});

const mapDispatchToProps = {
  exportPackage,
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
