import Immutable from 'immutable';

import { Layer } from 'const';
import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { createLayers } from 'utils/layers';

export function convertLayoutToLayered(layout: Models.CombinedLayout, layer?: Layer): Models.LayeredCombinedLayout;
export function convertLayoutToLayered(layout: Models.CombinedLayoutMap, layer?: Layer): Models.LayeredCombinedLayoutMap;
export function convertLayoutToLayered(
  layout: Models.CombinedLayout | Models.CombinedLayoutMap,
  layer = Layer.ORIGINAL,
): Models.LayeredCombinedLayout | Models.LayeredCombinedLayoutMap {
  if (isImmutable<Models.CombinedLayout>(layout)) {
    return (layout as Models.LayoutMap)
      .update('documentId', documentId => Immutable.fromJS(createLayers({ [layer]: documentId }))) as Models.LayeredCombinedLayoutMap;
  }

  return {
    ...layout,
    documentId: createLayers({ [layer]: layout.documentId }),
  } as Models.LayeredCombinedLayout;
}
