import Immutable from 'immutable';
import _ from 'lodash';

import { isImmutable } from 'utils/immutable';

export function removeLayoutId<T extends { layoutIds: string[] }>(object: T, layoutId: string): T;
export function removeLayoutId<T extends { layoutIds: string[] }>(object: DeepIMap<T>, layoutId: string): DeepIMap<T>;
export function removeLayoutId<T extends { layoutIds: string[] }>(object: T | DeepIMap<T>, layoutId: string): T | DeepIMap<T> {
  if (isImmutable(object)) {
    return object.update('layoutIds', (layoutIds: Immutable.List<string>) => layoutIds.filter(id => layoutId !== id));
  }

  return _.update(object, 'layoutIds', (layoutIds: string[]) => layoutIds.filter(id => layoutId !== id));
}

export function replaceLayoutId<T extends { layoutIds: string[] }>(object: T, oldLayoutId: string, newLayoutId: string): T;
export function replaceLayoutId<T extends { layoutIds: string[] }>(object: DeepIMap<T>, oldLayoutId: string, newLayoutId: string): DeepIMap<T>;
export function replaceLayoutId<T extends { layoutIds: string[] }>(
  object: T | DeepIMap<T>,
  oldLayoutId: string,
  newLayoutId: string,
): T | DeepIMap<T> {
  function updater(layoutIds: string[]): string[];
  function updater(layoutIds: Immutable.List<string>): Immutable.List<string>;
  function updater(layoutIds: Immutable.List<string> | string[]): Immutable.List<string> | string[] {
    const idx = layoutIds.findIndex(id => id === oldLayoutId);

    return idx !== -1 ? layoutIds.splice(idx, 1, newLayoutId) : layoutIds;
  }

  if (isImmutable(object)) {
    return object.update('layoutIds', updater);
  }

  return _.update(object, 'layoutIds', updater);
}
