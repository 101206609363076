import React from 'react';
import guid from 'uuid';

const TextTop = (): JSX.Element => {
  const pathId = guid();
  const maskId = guid();

  return (
    <svg viewBox="6 5 18 18">
      <defs>
        <path d="M4,8 L7,8 L7,18 L9,18 L9,8 L12,8 L8,4 L4,8 Z M0,0 L0,2 L16,2 L16,0 L0,0 Z" id={pathId} />
      </defs>
      <g id="Icons/StyleBar/Alignment/top" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Color" transform="translate(7.000000, 6.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`#${pathId}`} />
          </mask>
          <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`} />
          <g id="Colors/White" mask={`url(#${maskId})`} fill="#FFFFFF">
            <g transform="translate(-5.000000, -4.000000)" id="White">
              <rect x="0" y="0" width="26" height="25" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TextTop;
