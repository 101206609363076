import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ScreenSettings from 'components/ModalWindows/ScreenSettings';
import { thumbnailsRefreshProgress } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { thumbnailsColor } from 'containers/BrandDefinition/selectors';
import { hideModal } from 'containers/ModalManager/actions';
import { projectType } from 'containers/Project/selectors';
import { refreshThumbnail } from 'containers/Surfaces/actions';
import { activeSurface, surfaceNames } from 'containers/Surfaces/selectors';

const mapStateToProps = createStructuredSelector({
  artboard: activeArtboard,
  color: thumbnailsColor,
  projectType,
  screen: activeSurface,
  screenNames: surfaceNames,
  thumbnailsRefreshProgress,
});

const mapDispatchToProps = {
  hideModal,
  updateArtboard,
  refreshThumbnail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSettings);
