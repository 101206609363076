import cryptoJS from 'crypto-js';

const isFile = (data: File | string): data is File => {
  return data instanceof Blob;
};

const handleTypedArray = function (typedArrayArgument) {
  let typedArray = typedArrayArgument;
  // Convert buffers to uint8
  if (typedArray instanceof ArrayBuffer) {
    typedArray = new Uint8Array(typedArray);
  }

  // Convert other array views to uint8
  if (typedArray instanceof Int8Array
    || typeof Uint8ClampedArray !== 'undefined'
    && typedArray instanceof Uint8ClampedArray
    || typedArray instanceof Int16Array
    || typedArray instanceof Uint16Array
    || typedArray instanceof Int32Array
    || typedArray instanceof Uint32Array
    || typedArray instanceof Float32Array
    || typedArray instanceof Float64Array
  ) {
    typedArray = new Uint8Array(typedArray.buffer, typedArray.byteOffset, typedArray.byteLength);
  }

  // Handle Uint8Array
  if (typedArray instanceof Uint8Array) {
    // Shortcut
    const typedArrayByteLength = typedArray.byteLength;

    // Extract bytes
    const words = [];
    for (let i = 0; i < typedArrayByteLength; i++) {
      words[i >>> 2] |= typedArray[i] << 24 - i % 4 * 8;
    }

    return ({ typedArray: words, length: typedArrayByteLength });
  } else {
    return ({ typedArray, length: typedArray.byteLength });
  }
};

// TODO: need to rewrite it with separating by pure functions ASAP
export async function getChecksum(dataToEncrypt: File | string): Promise<string> {
  return new Promise((resolve) => {
    const MD5 = cryptoJS.algo.MD5.create();
    let encrypted = '';

    const updateChecksum = (data: string | ArrayBuffer): void => {
      const { typedArray, length } = handleTypedArray(data);
      const wordBuffer = cryptoJS.lib.WordArray.create(typedArray, length);
      MD5.update(wordBuffer);
    };

    const finishChecksumCalculating = (): void => {
      encrypted = MD5.finalize().toString();
      resolve(encrypted);
    };

    if (isFile(dataToEncrypt)) {
      loading(dataToEncrypt, updateChecksum, finishChecksumCalculating);
    } else {
      resolve(cryptoJS.MD5(dataToEncrypt).toString());
    }
  });
}

function loading(file: File, callbackProgress: (result: unknown) => void, callbackFinal: () => void): void {
  const chunkSize = 1024 * 1024; // bytes
  const previousChunks = new Set<ChunkData>();

  let partial: Blob;
  let lastOffset = 0;
  let offset = 0;

  if (file.size === 0) {
    callbackFinal();

    return;
  }

  const parseResult = (_offset: number, size: number, result: FileReader['result']): void => {
    lastOffset = _offset + size;

    callbackProgress(result);

    if (lastOffset >= file.size) {
      lastOffset = 0;
      callbackFinal();
    }
  };

  while (offset < file.size) {
    const reader = new FileReader();
    const newOffset = offset;

    partial = file.slice(offset, offset + chunkSize);
    reader.onload = (): void => {
      // not of order chunk: put into buffer
      if (lastOffset !== newOffset) {
        previousChunks.add({ offset: newOffset, size: chunkSize, result: reader.result });
        // in order chunk
      } else {
        parseResult(newOffset, chunkSize, reader.result);

        // check previous buffered chunks
        let buffered: ChunkData[] = [{} as ChunkData];

        while (buffered.length > 0) {
          buffered = Array.from(previousChunks).filter(item => item.offset === lastOffset);
          buffered.forEach((item) => {
            parseResult(item.offset, item.size, item.result);
            previousChunks.delete(item);
          });
        }
      }
    };

    reader.readAsArrayBuffer(partial);
    offset += chunkSize;
  }
}

interface ChunkData {
  size: number;
  offset: number;
  result: FileReader['result'];
}
