import React from 'react';

import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { intlGet } from 'utils/intlGet';
import { ResponsiveProps } from './models';
import styles from './styles.module.scss';

const Responsive: React.FunctionComponent<ResponsiveProps> = (props) => {
  const { responsive, toggleResponsive } = props;
  const description = intlGet('EditorToolbar.Responsive', responsive ? 'ColumnsOneBelowTheOther' : 'ColumnsInOneRow');

  return (
    <Dropdown className={styles.Responsive} stopClickPropagation={true}>
      <Checkbox onChange={toggleResponsive} checked={responsive} label={intlGet('EditorToolbar.Responsive', 'ResponsiveLayout')} />
      <div className={styles.description}>{description}</div>
    </Dropdown>
  );
};

export default Responsive;
