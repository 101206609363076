import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import { getParentRelation } from 'utils/relations/getParentRelation';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { getRootWithHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';

interface CellHeightInfo {
  id: string;
  isAutoFitContent: boolean;
  height: number;
}
type CellHeightInfoMap = Record<string, CellHeightInfo>;

function getBottomCells(
  relationId: string,
  relations: Models.LayeredRelationsMap,
  layer = Layer.ORIGINAL,
  bottomCells = {} as CellHeightInfoMap,
): CellHeightInfoMap {
  const relation = relations.get(relationId);

  if (!relation) {
    return bottomCells;
  }

  if (isColumnRelation(relation)) {
    const lastCellRelationId = relation.get('relationIds').last() as string;
    getBottomCells(lastCellRelationId, relations, layer, bottomCells);
  }

  if (isRowRelation(relation)) {
    relation.get('relationIds').map(_relationId => getBottomCells(_relationId, relations, layer, bottomCells));
  }

  if (isRegularRelation(relation)) {
    const parentRelation = getParentRelation(relationId, relations);
    const parentColumnRelation = !isColumnRelation(parentRelation)
      ? getParentRelation(parentRelation.get('id'), relations)
      : parentRelation;

    const isLastCell = isRowRelation(parentRelation) || parentColumnRelation.get('relationIds').last() === relationId;

    const cellPosition = isColumnRelation(parentRelation)
      ? parentRelation.get('relationIds').size - 1
      : parentColumnRelation.get('relationIds').indexOf(parentRelation.get('id'));

    if (isLastCell && !bottomCells[relationId]) {
      bottomCells[relationId] = {
        id: relationId,
        isAutoFitContent: relation.getIn(['styles', layer, 'isAutoFitContent']),
        height: parentColumnRelation.getIn(['styles', 'rowsHeight', cellPosition]),
      };
    }
  }

  return bottomCells;
}

function getDelta(
  delta: number,
  allBottomCells: CellHeightInfoMap,
  columnBottomCells: CellHeightInfoMap,
): number {
  if (!delta || _.isEmpty(allBottomCells)) {
    return 0;
  }

  // cell height is increased, no need to check other cells
  if (delta < 0) {
    return delta;
  }

  let calculatedDelta = delta;
  const contentHeights = ContentHeightCache.getInstance();

  _.some(allBottomCells, (cellHeightInfo) => {
    const { id, isAutoFitContent, height } = cellHeightInfo;
    if (columnBottomCells[id]) {
      return false;
    }

    const cellContentHeightFromCache = contentHeights.getItem(id);
    if (!isAutoFitContent || !cellContentHeightFromCache) {
      calculatedDelta = 0;

      return true;
    }

    const currentCellHeight = height - cellContentHeightFromCache;
    calculatedDelta = Math.min(calculatedDelta, currentCellHeight);

    return !calculatedDelta;
  });

  return calculatedDelta;
}

function getExtraDelta(
  delta: number,
  columnBottomCells: CellHeightInfoMap,
  relationId: string,
): number {
  if (!delta || _.isEmpty(columnBottomCells)) {
    return 0;
  }

  const isCellBlocked = _.some(
    columnBottomCells,
    ({ id, isAutoFitContent }) => !isAutoFitContent || id === relationId,
  );

  return !isCellBlocked ? delta : 0;
}

// TODO: CHECK: triggered on hover over cell
export function getPossibleRowsHeightReduction(
  heightDelta: number,
  relationId: string,
  layoutRelationId: string,
  relations: Models.LayeredRelationsMap,
  layer = Layer.ORIGINAL,
): {
    delta: number;
    extraDelta: number;
  } {
  const rootWithHorizontalNeighborsRelationId = getRootWithHorizontalNeighbors(relationId, relations) || layoutRelationId;
  if (!rootWithHorizontalNeighborsRelationId) {
    return {
      delta: heightDelta,
      extraDelta: 0,
    };
  }

  const allBottomCells = getBottomCells(rootWithHorizontalNeighborsRelationId, relations, layer);

  let parentRelation = getParentRelation(relationId, relations);
  // in some cases there is extra parent Row relation only with one child, need to ignore this relation
  if (isRowRelation(parentRelation) && parentRelation.get('relationIds').size === 1) {
    parentRelation = getParentRelation(parentRelation.get('id'), relations);
  }
  const parentColumnRelationId = isColumnRelation(parentRelation)
    ? parentRelation.get('id')
    : relationId;

  const columnBottomCells = isColumnRelation(parentRelation)
    ? getBottomCells(parentColumnRelationId, relations, layer)
    : {};

  const delta = heightDelta > 0
    ? getDelta(heightDelta, allBottomCells, columnBottomCells)
    : heightDelta;

  const extraDelta = getExtraDelta(heightDelta - delta, columnBottomCells, relationId);

  return {
    delta,
    extraDelta,
  };
}
