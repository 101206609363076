import localForage from 'localforage';
import _ from 'lodash';

import { MAX_NUMBER_OF_LOCALLY_SAVED_PROJECTS } from 'const';
import { ProjectAssetsToUpload } from 'models';
import { ProjectAssetsWithTimestamp } from './models';
import { getProjectKeysMap } from './utils';

const getProjectKey = async (projectId: number, keys?: string[]): Promise<string> => {
  return _.findLastKey(getProjectKeysMap(keys || await localForage.keys()), { documentId: `${projectId}` });
};

const removeExcessiveProjects = async (): Promise<void> => {
  const savedProjectKeys = await localForage.keys();
  const numberOfLocallySavedProjects = savedProjectKeys.length;

  if (numberOfLocallySavedProjects <= MAX_NUMBER_OF_LOCALLY_SAVED_PROJECTS) {
    return;
  }

  const numberOfProjectsToRemove = numberOfLocallySavedProjects - MAX_NUMBER_OF_LOCALLY_SAVED_PROJECTS;
  const keysToRemove = _(savedProjectKeys).sortBy().slice(0, numberOfProjectsToRemove).value();

  await Promise.all(keysToRemove.map(key => localForage.removeItem(key)));
};

export async function getSavedLocallyProject(projectId: number): Promise<ProjectAssetsWithTimestamp> {
  const projectKey = await getProjectKey(projectId);
  const project = await localForage.getItem<string>(projectKey);

  return JSON.parse(project);
}

export async function removeSavedLocallyProject(projectId: number): Promise<void> {
  const projectKey = await getProjectKey(projectId);

  await localForage.removeItem(projectKey);
}

export async function saveProjectLocally(projectToSave: ProjectAssetsToUpload): Promise<number> {
  const { documentId: projectToSaveId } = projectToSave.rootDocument;
  const savedProjectKeys = await localForage.keys();
  const projectKey = await getProjectKey(projectToSaveId, savedProjectKeys);

  const lastLocalSaveTime = Date.now();
  const savedProject = _.assign({ lastLocalSaveTime }, projectToSave);

  if (projectKey) {
    await localForage.removeItem(projectKey);
  }

  await localForage.setItem(`${lastLocalSaveTime}_${projectToSaveId}`, JSON.stringify(savedProject));
  // we should save not more than 10 projects
  await removeExcessiveProjects();

  return lastLocalSaveTime;
}
