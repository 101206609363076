import React from 'react';

import { ILayoutListItemRenditionProps } from './models';
import styles from './styles.module.scss';

const LayoutListItemRenditionDragPreview: React.FunctionComponent<ILayoutListItemRenditionProps> = ({ imageSrc }) => {
  return (
    <div className={styles.LayoutListItemRenditionDragPreview}>
      <img className={styles.LayoutListItemRenditionDragPreview__image} src={imageSrc} />
    </div>
  );
};

export default LayoutListItemRenditionDragPreview;
