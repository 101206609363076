import React from 'react';

const ChevronDown = (): JSX.Element => (
  <svg viewBox="0 0 12 8">
    <g id="ASM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Storycard-Dropdown" transform="translate(-1400.000000, -845.000000)">
        <g id="Assets-Panel" transform="translate(1090.000000, 182.000000)">
          <g id="Navigate-Card" transform="translate(0.000000, 151.000000)">
            <g id="content">
              <g transform="translate(0.000000, 485.000000)">
                <g transform="translate(301.000000, 16.000000)">
                  <g mask="url(#ChevronDownId)" fill="#000000" fillRule="evenodd" opacity="1">
                    <polygon points="10.41 11 15 15.58 19.59 11 21 12.41 15 18.41 9 12.41" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChevronDown;
