import classNames from 'classnames';
import React, { useCallback } from 'react';

import AddImageItem from 'components/AddImageItem';
import ImageBox from 'components/ImageBox';
import { ImageMap } from 'models';
import { MobileViewImageProps } from './models';
import styles from './styles.module.scss';

const MobileViewImage: React.FunctionComponent<MobileViewImageProps> = (props) => {
  const {
    imagesForAssetsPanel,
    showModal,
    mobileViewImage,
    toggleMobileViewImage,
    documentIdsAreBeingUploaded,
    className,
  } = props;

  function isActive(image: ImageMap): boolean {
    return image.get('id') === mobileViewImage.get('id')
    || image.get('documentId') === mobileViewImage.get('documentId');
  }

  const setImage = useCallback(
    (imageId: string, number: number, image: ImageMap): void => {
      if (!isActive(image)) {
        toggleMobileViewImage(
          mobileViewImage.set('id', imageId).set('documentId', number),
        );

        return;
      }

      toggleMobileViewImage(
        mobileViewImage.set('id', null).delete('documentId'),
      );

    },
    [mobileViewImage, toggleMobileViewImage],
  );

  return (
    <div className={classNames(styles.MobileViewImage, className)}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <AddImageItem showModal={showModal} />
        </li>

        {imagesForAssetsPanel.valueSeq().map((image) => {
          const imageId = image.get('id');

          return (
            <li className={styles.item} key={imageId}>
              <ImageBox
                image={image}
                isActive={isActive(image)}
                isImageBeingUploaded={documentIdsAreBeingUploaded.has(imageId)}
                onImageClick={setImage}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MobileViewImage;
