import { ActionTypes } from './constants';
import * as Models from './models';

export const saveGroupLayout: Models.ActionCreator.SaveGroupLayout = (layoutIds, name, previewUrl, groupLayoutId) => ({
  type: ActionTypes.SAVE_GROUP_LAYOUT,
  payload: {
    groupLayoutId,
    layoutIds,
    name,
    previewUrl,
  },
});
