import { ASSET_ALIGNMENTS, AssetAlignmentDirection } from 'const';
import { AssetAlignmentMap } from 'models';

export const getAlignment = (alignment: AssetAlignmentMap): React.CSSProperties => {
  if (!alignment) {
    return {};
  }

  return {
    ...ASSET_ALIGNMENTS.find(align => align.type === alignment.get(AssetAlignmentDirection.HORIZONTAL)).style as React.CSSProperties,
    ...ASSET_ALIGNMENTS.find(align => align.type === alignment.get(AssetAlignmentDirection.VERTICAL)).style as React.CSSProperties,
  };
};
