import { call, select } from 'redux-saga/effects';

import { projectAssets as projectAssetsSelector } from 'containers/Common/selectors';
import { ProjectAssetsToUpload, ProjectAssetsToUploadMap } from 'models';
import { deleteInternalInfo } from 'utils/deleteInternalInfo';

interface GetProjectAssetsOptions {
  removeInternalInfo?: boolean;
  toJS?: boolean;
}

export function* getProjectAssets(options?: GetProjectAssetsOptions): Generator<unknown, ProjectAssetsToUpload | ProjectAssetsToUploadMap> {
  const { removeInternalInfo = true, toJS = true } = options || {};
  const projectAssets: ReturnTypeSaga<typeof projectAssetsSelector> = yield select(projectAssetsSelector);
  const actualProjectAssets = toJS ? projectAssets.toJS() : projectAssets;

  return removeInternalInfo ? yield call(deleteInternalInfo, actualProjectAssets) : actualProjectAssets;
}
