import _ from 'lodash';

import { DocumentType, DocumentsSource, EntityType, LATEST_REUSABLE_LAYOUT_VERSION } from 'const';
import { createBrandDefinition } from 'factories/brandDefinitionFactory';
import { ReusableLayout } from 'models';
import { baseDocumentFactory } from './baseDocumentFactory';

export function layoutDocumentFactory(args = {} as Partial<ReusableLayout>): ReusableLayout {
  const {
    previewUrl = null,
    documents,
    isEdited = false,
    documentSource = [DocumentsSource.COMPONENTS],
    renditions = {},
    needToUpdatePreview = false,
  } = args;
  const entities = _.defaults(args.entities, { brandDefinition: createBrandDefinition(), version: LATEST_REUSABLE_LAYOUT_VERSION });

  return {
    ...baseDocumentFactory(args),
    type: DocumentType.COMPONENT,
    entityType: EntityType.LAYOUT,
    documentSource,
    previewUrl,
    documents,
    entities,
    isEdited,
    renditions,
    needToUpdatePreview,
  };
}
