import _ from 'lodash';

import * as Models from 'models';

export function updateSurfacesThumbnailUrls(surfaces: Models.Screens, thumbnailsUrls: Record<string, string>): Models.Screens {
  return _.mapValues(surfaces, (surface) => {
    const { artboardId } = surface;
    _.update(surface, 'thumbnail.url', url => thumbnailsUrls[artboardId] || url);

    return surface;
  });
}
