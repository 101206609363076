import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import NumericInput from 'components/NumericInput';
import { MIN_SCREEN_FORMAT_HEIGHT, MIN_SCREEN_FORMAT_WIDTH } from 'const';
import { intlGet } from 'utils/intlGet';
import { ScreenFormatSizeProps } from './models';
import styles from './styles.module.scss';

const ScreenFormatSize: React.FunctionComponent<ScreenFormatSizeProps> = (props) => {
  const {
    className,
    height,
    isDisabled,
    setHeight,
    setWidth,
    width,
  } = props;

  const [widthState, setWidthState] = React.useState(width);

  const applyWidth = (newWidth) => {
    const newValue = _.clamp(newWidth, MIN_SCREEN_FORMAT_WIDTH, Infinity);
    setWidthState(newValue);
    setWidth(newValue);
  };

  const [heightState, setHeightState] = React.useState(height);
  const applyHeight = (newHeight) => {
    const newValue = _.clamp(newHeight, MIN_SCREEN_FORMAT_HEIGHT, Infinity);
    setHeightState(newValue);
    setHeight(newValue);
  };

  React.useEffect(
    () => {
      setWidthState(width);
      setHeightState(height);
    },
    [width, height],
  );

  return (
    <div className={classNames(styles.ScreenFormatSize, className)}>
      <NumericInput
        allowEmptyStringValue={true}
        className={classNames(styles.numInput)}
        disableWhenValueAbsent={false}
        disabled={isDisabled}
        onBlur={() => applyWidth(widthState)}
        onButtonClick={newWidth => applyWidth(newWidth)}
        onChange={setWidthState}
        onEnterPress={() => applyWidth(widthState)}
        suffix={intlGet('EditorToolbar.Size', 'Width')}
        value={widthState}
      />
      <NumericInput
        allowEmptyStringValue={true}
        className={classNames(styles.numInput)}
        disableWhenValueAbsent={false}
        disabled={isDisabled}
        onBlur={() => applyHeight(heightState)}
        onButtonClick={newHeight => applyHeight(newHeight)}
        onChange={setHeightState}
        onEnterPress={() => applyHeight(heightState)}
        suffix={intlGet('EditorToolbar.Size', 'Height')}
        value={heightState}
      />
    </div>
  );
};

export default ScreenFormatSize;
