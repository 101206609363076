import Immutable from 'immutable';
import { createSelector } from 'reselect';

import { EntityType } from 'const';
import { selectedDocumentIds } from 'containers/App/selectors';
import { artboards } from 'containers/Artboards/selectors';
import { documents, relationsByScreenId } from 'containers/Documents/selectors';
import { layouts } from 'containers/Layouts/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { orderedSurfaces } from 'containers/Surfaces/selectors';
import { TextComponentMap } from 'models';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

export const selectedEntityTypesByScreenId = createSelector(
  [orderedSurfaces, relationsByScreenId, artboards, layouts, documents, selectedDocumentIds, activeLayer],
  (orderedSurfaces, relationsByScreenId, artboards, layouts, documents, selectedDocumentIds, activeLayer) => {
    const id = selectedDocumentIds.last('');
    const entityType = documents.getIn([id, 'entityType']) as EntityType;

    return orderedSurfaces.reduce(
      (selectedEntityTypesByScreens: DeepIMap<Record<string, EntityType[]>>, orderedSurface) => {
        const artboardId = orderedSurface.get('artboardId');
        const screenId = orderedSurface.get('id');
        const relationsByArtboard = relationsByScreenId.get(artboardId);

        const updatedSelectedEntityTypesByScreens = selectedEntityTypesByScreens.set(screenId, Immutable.List<EntityType>());

        switch (entityType) {
          case EntityType.GROUP_LAYOUT:
          case EntityType.LAYOUT:
            const artboard = artboards.get(artboardId);

            const isLayout = getFlattenedLayouts(artboard, layouts)
              .valueSeq()
              .some(layout => layout.get('documentId') === id);

            if (isLayout) {
              return updatedSelectedEntityTypesByScreens.update(screenId, selectedEntityTypes => selectedEntityTypes.push(entityType));
            }

            return updatedSelectedEntityTypesByScreens;
          case EntityType.TEXT:
          case EntityType.IMAGE:
            return relationsByArtboard.reduce(
              (updatedSelectedEntityTypesByScreens: DeepIMap<Record<string, EntityType[]>>, relation) => {
                if (!isRegularRelation(relation)) {
                  return updatedSelectedEntityTypesByScreens;
                }

                const documentId = relation.getIn(['documentId', activeLayer]);
                const backgroundImageId = relation.getIn(['styles', 'backgroundImage', 'id']);
                if (documentId === id || backgroundImageId === id) {
                  return updatedSelectedEntityTypesByScreens.update(screenId, selectedEntityTypes => selectedEntityTypes.push(entityType));
                }

                return updatedSelectedEntityTypesByScreens;
              },
              updatedSelectedEntityTypesByScreens,
            );
          case EntityType.REFERENCE_CITATION:
            return relationsByArtboard.reduce(
              (updatedSelectedEntityTypesByScreens, relation) => {
                if (!isRegularRelation(relation)) {
                  return updatedSelectedEntityTypesByScreens;
                }

                const documentId = relation.getIn(['documentId', activeLayer]);
                const document = documents.get(documentId) as TextComponentMap;
                const references = document && document.get('referenceCitations');
                const isReference = references && references.some(referenceCitation => referenceCitation === id);

                if (isReference) {
                  return updatedSelectedEntityTypesByScreens.update(screenId, selectedEntityTypes => selectedEntityTypes.push(entityType));
                }

                return updatedSelectedEntityTypesByScreens;
              },
              updatedSelectedEntityTypesByScreens,
            );
          default: return updatedSelectedEntityTypesByScreens;
        }
      },
      Immutable.Map({}) as DeepIMap<Record<string, EntityType[]>>,
    );
  },
);
