import _ from 'lodash';
import { MutableRefObject, useCallback, useRef } from 'react';
import { StylesState, StylesStateSetters } from './useStyles';

export function useOnResize(
  containerRef: MutableRefObject<HTMLDivElement | undefined>,
  styles: StylesState,
  stylesSetters: StylesStateSetters,
): ((event: DragEvent) => void) {
  const stylesRef = useRef<StylesState>(styles);
  stylesRef.current = styles;

  return useCallback(({ clientY }: DragEvent): void => {
    const containerElement = containerRef.current;
    if (!containerElement || !stylesRef.current.scrollable) {
      return;
    }
    const { bottom } = containerElement.getBoundingClientRect() || 0;
    const change = bottom - clientY;
    const maxHeight = containerElement.scrollHeight;
    const newHeight = (stylesRef.current.height || 0) - change;

    if (_.inRange(newHeight, stylesRef.current.minHeight, maxHeight + 1)) {
      stylesSetters.height(newHeight);
    }
  }, [stylesSetters]);
}
