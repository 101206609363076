import { Layer } from 'const';
import { LayeredRelationsMap, LayoutMap } from 'models';
import { recalculateRowsAndNeighborsHeight, recalculateRowsHeight } from 'utils/rowsHeight';
import { getPossibleRowsHeightReduction } from 'utils/rowsHeight/getPossibleRowsHeightReduction';

export const calculateDeltaAndRelations = (
  relationId: string,
  relations: LayeredRelationsMap,
  possibleHeightDelta: number,
  activeLayer: Layer,
  layout: LayoutMap,
): LayeredRelationsMap | null => {
  let processedRelations = relations;

  const {
    delta,
    extraDelta,
  } = getPossibleRowsHeightReduction(
    possibleHeightDelta,
    relationId,
    layout.get('relationId'),
    processedRelations,
    activeLayer,
  );

  if (delta) {
    processedRelations = recalculateRowsAndNeighborsHeight(
      relationId,
      processedRelations,
      delta,
      activeLayer,
    );
  }

  if (extraDelta) {
    processedRelations = recalculateRowsHeight(
      relationId,
      processedRelations,
      extraDelta,
      activeLayer,
      true,
      true,
    );
  }

  return (delta || extraDelta) ? processedRelations : null;
};
