import AbbreviationsElement from 'assets/images/AbbreviationsElement';
import CallToActionLarge from 'assets/images/CallToActionLarge';
import CallToActionMedium from 'assets/images/CallToActionMedium';
import CallToActionSmall from 'assets/images/CallToActionSmall';
import ReferenceCitationElement from 'assets/images/ReferenceCitationElement';
import SpacerLarge from 'assets/images/SpacerLarge';
import SpacerMedium from 'assets/images/SpacerMedium';
import SpacerSmall from 'assets/images/SpacerSmall';
import SSI from 'assets/images/SSI';
import Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';
import { DndComponentClass } from 'react-dnd';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import ComponentItem from 'components/ComponentItem';
import { IComponentItemOwnProps } from 'components/ComponentItem/models';
import ElementItem from 'components/ElementItem';
import LayoutItem from 'components/LayoutItem';
import { AvailableComponentSizes, ComponentSize, EntityType, LayoutType, ProjectsConfig } from 'const';
import SSIElement from 'containers/SSIElement';
import { intlGet } from 'utils/intlGet';
import { ElementListProps } from './models';
import styles from './styles.module.scss';

type Element = EntityType.CALL_TO_ACTION
| LayoutType.SPACER
| LayoutType.REFERENCE_CITATION_ELEMENT
| LayoutType.ABBREVIATIONS_LIST
| EntityType.SSI_ELEMENT;

const Elements: Element[] = [
  EntityType.CALL_TO_ACTION,
  LayoutType.SPACER,
  LayoutType.REFERENCE_CITATION_ELEMENT,
  LayoutType.ABBREVIATIONS_LIST,
];

const LayoutIconsByType: Record<Element, Record<ComponentSize, React.FunctionComponent> | React.FunctionComponent> = {
  [EntityType.CALL_TO_ACTION]: {
    [ComponentSize.SMALL]: CallToActionSmall,
    [ComponentSize.MEDIUM]: CallToActionMedium,
    [ComponentSize.LARGE]: CallToActionLarge,
  },
  [LayoutType.SPACER]: {
    [ComponentSize.SMALL]: SpacerSmall,
    [ComponentSize.MEDIUM]: SpacerMedium,
    [ComponentSize.LARGE]: SpacerLarge,
  },
  [LayoutType.REFERENCE_CITATION_ELEMENT]: ReferenceCitationElement,
  [LayoutType.ABBREVIATIONS_LIST]: AbbreviationsElement,
  [EntityType.SSI_ELEMENT]: SSI,
};

type FlattenedElement = {
  type: Element;
  size?: ComponentSize;
};

const getComponent = (type: Element): DndComponentClass<typeof ComponentItem, IComponentItemOwnProps> => {
  switch (true) {
    case type === EntityType.SSI_ELEMENT: return SSIElement;
    case _.includes(LayoutType, type as LayoutType): return LayoutItem;
    default: return ElementItem;
  }
};

const ElementList: React.FunctionComponent<ElementListProps> = (props) => {
  const {
    projectType,
    isReferenceCitationElementOnArtboard,
    toggleDragState,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({ isExpand: true });

  const getElements = (): Element[] => {
    const { allowSSI } = ProjectsConfig[projectType];

    return allowSSI ? [...Elements, EntityType.SSI_ELEMENT] : Elements;
  };

  const renderElement = (type: Element, size?: ComponentSize): JSX.Element => {
    const icon: React.FunctionComponent = size ? LayoutIconsByType[type][size] : LayoutIconsByType[type];
    const Component = getComponent(type);

    return (
      <Component
        key={size ? `${type}_${size}` : type}
        type={type}
        size={size}
        icon={icon}
        toggleDragState={toggleDragState}
        isReferenceCitationElementOnArtboard={isReferenceCitationElementOnArtboard}
      />
    );
  };

  const getFlattenedElements = (): FlattenedElement[] => {
    return getElements().reduce(
      (acc, type) => {
        const availableComponents = AvailableComponentSizes[type];
        const components = availableComponents
          ? availableComponents.map(size => ({ type, size }))
          : [{ type }];

        return acc.concat(components);
      },
      [] as FlattenedElement[],
    );
  };

  const elements = getFlattenedElements();

  return (
    <div className={styles.ElementList} data-title="elements">
      <Header
        title={intlGet('Assets', 'Elements', { count: elements.length })}
        expanded={expanded}
        onClick={toggle}
      />
      {
        expanded &&
        <List
          layout="grid"
          // TODO: define types properly
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          elements={Immutable.List(elements) as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderElement={({ type, size }: any) => renderElement(type, size)}
        />
      }
    </div>
  );
};

export default ElementList;
