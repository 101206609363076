import _ from 'lodash';
import { PutEffect, all, call, put } from 'redux-saga/effects';

import { RenditionType } from 'const';
import * as Models from 'models';
import { ImageCache } from 'services/imageCache';
import { updateStoryCardImageInfo } from '../actions';
import { Action } from '../models';
import { updateImagesPreviews } from '../services/updateImagesPreviews';

export function* getStoryCardImagesAdditionalInfo(
  action: Action.IGetStoryCardImagesInfo,
): Generator {
  try {
    if (!action.payload) {
      return;
    }
    const { storyCardId, documents } = action.payload;

    const actions: PutEffect<Action.IUpdateStoryCardImageInfo>[] = [];
    const imageCache = ImageCache.getInstance();
    const documentsToProcess = _.filter(documents, (document) => {
      const { id, renditions } = document;
      const checksum = _.get(renditions, [RenditionType.LOW, 'checksum']);
      const cachedData = imageCache.getItem(RenditionType.LOW, checksum) as Models.ImageInternalInfo;

      if (cachedData) {
        actions.push(put(updateStoryCardImageInfo(id, cachedData, storyCardId)));
      }

      return !cachedData;
    });

    yield all(actions);

    if (_.isEmpty(documentsToProcess)) {
      return;
    }

    yield call(
      updateImagesPreviews,
      documentsToProcess,
      (id: string, imageInternalInfo: Models.ImageInternalInfo) => updateStoryCardImageInfo(id, imageInternalInfo, storyCardId),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during getting Story Card additional info.', error);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    yield call(alert, `Error occurred during getting Story Card additional info: ${error}`);
  }
}
