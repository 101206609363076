import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isTextComponent(entity: Models.CombinedDocument): entity is Models.TextComponent;
export function isTextComponent(entity: Models.CombinedDocumentMap): entity is Models.TextComponentMap;
export function isTextComponent(entity: Models.Relation): entity is Models.RegularRelation<Models.TextRelationStyles>;
export function isTextComponent(entity: Models.RelationMap): entity is Models.RegularRelationMap<Models.TextRelationStyles>;
export function isTextComponent(entity: Models.LayeredRelation): entity is Models.LayeredRegularRelation<Models.TextRelationStyles>;
export function isTextComponent(entity: Models.LayeredRelationMap): entity is Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
export function isTextComponent(entity): boolean {
  return getValue(entity, 'entityType') === EntityType.TEXT;
}
