import React from 'react';

import Icon from 'components/Icon';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { TEXT_LINE_HEIGHTS } from 'const';
import { TextLineHeightProps } from './models';
import styles from './styles.module.scss';

const TextLineHeight: React.FunctionComponent<TextLineHeightProps> = (props) => {
  const { setLineHeight, blockLineHeight } = props;

  return (
    <Dropdown className={styles.TextLineHeight}>
      {TEXT_LINE_HEIGHTS.map(
        ({ icon, hint, type }) => (
          <div className={styles.item} key={type} onClick={setLineHeight.bind(null, type)}>
            <Icon type={icon} size="md" color={type === blockLineHeight ? 'primary' : 'secondary'} />
            <span className={styles.hint}>{hint}</span>
          </div>
        ),
      )}
    </Dropdown>
  );
};

export default TextLineHeight;
