import { DragSourceCollector, DragSourceSpec } from 'react-dnd';

import { DragSourceType } from 'const';
import { ArtboardLayoutDragObject } from 'models';
import * as Models from './models';
import { shouldDisableDragAndDeleteActions } from './utils';

export const ArtboardLayoutDragSource: DragSourceSpec<Models.ArtboardLayoutProps, ArtboardLayoutDragObject> = {
  beginDrag(props) {
    const { layout, toggleDragState } = props;
    toggleDragState(DragSourceType.ARTBOARD_LAYOUT);

    return { layout };
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

export const artboardLayoutCollector: DragSourceCollector<Models.ArtboardLayoutCollectedProps, Models.ArtboardLayoutOwnProps> = (
  connect,
  monitor,
) => ({
  connectLayoutDragSource: connect.dragSource(),
  isDraggingLayout: monitor.isDragging(),
});

export const ArtboardReusableLayoutDragSource: DragSourceSpec<Models.ArtboardLayoutProps, ArtboardLayoutDragObject> = {
  beginDrag(props) {
    const { layout, toggleDragState } = props;
    toggleDragState(DragSourceType.ARTBOARD_REUSABLE_LAYOUT);

    return { layout };
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

export const artboardReusableLayoutCollector: DragSourceCollector<Models.ArtboardReusableLayoutCollectedProps, Models.ArtboardLayoutOwnProps> = (
  connect,
  monitor,
) => ({
  connectReusableLayoutDragSource: connect.dragSource(),
  isDraggingReusableLayout: monitor.isDragging(),
});

export const GroupLayoutReusableLayoutDragSource: DragSourceSpec<Models.ArtboardLayoutProps, ArtboardLayoutDragObject> = {
  beginDrag(props) {
    const { layout, toggleDragState } = props;
    toggleDragState(DragSourceType.GROUP_LAYOUT_REUSABLE_LAYOUT);

    return { layout };
  },
  endDrag(props) {
    props.toggleDragState();
  },
  canDrag(props) {
    return !shouldDisableDragAndDeleteActions(props);
  },
};

export const groupLayoutReusableLayoutCollector: DragSourceCollector<
Models.GroupLayoutReusableLayoutCollectedProps,
Models.ArtboardLayoutOwnProps
> = (
  connect,
  monitor,
) => ({
  connectGroupLayoutReusableLayoutDragSource: connect.dragSource(),
  isDraggingGroupLayoutReusableLayout: monitor.isDragging(),
});
