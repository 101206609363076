import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FontFamily from 'components/Toolbar/Dropdowns/FontFamily';
import { FontFamilyOwnProps, FontFamilySelectors } from 'components/Toolbar/Dropdowns/FontFamily/models';
import { availableForUserFontsCategories, projectFlatFonts } from 'containers/BrandDefinition/selectors';

const mapStateToProps = createStructuredSelector({
  fontsCategories: availableForUserFontsCategories,
  fonts: projectFlatFonts,
});

export default connect<FontFamilySelectors, void, FontFamilyOwnProps>(mapStateToProps)(FontFamily);
