import { Effect, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { getProjectData } from './getProjectData';

const effects: Effect[] = [
  takeEvery(ActionTypes.GET_PROJECT_DATA, getProjectData),
];

export default effects;
