import { CellEntityType, ParagraphType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { createListItemParagraph, createListParagraph, createTextParagraph } from './createParagraph';

export const createText = ({
  paragraphs = [],
  isReferenceCitation = false,
} = {} as Partial<Schemas.ReferenceText>): Schemas.ReferenceText => ({
  entityType: CellEntityType.TEXT,
  paragraphs: paragraphs.map((paragraph) => {
    switch (paragraph.type) {
      case ParagraphType.PLAINT_TEXT: return createTextParagraph(paragraph);
      case ParagraphType.UNORDERED_LIST: return createListParagraph(paragraph);
      case ParagraphType.UNORDERED_LIST_ITEM: return createListItemParagraph(paragraph);
    }
  }),
  isReferenceCitation,
});
