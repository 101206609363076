import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AssetsCollection from 'components/AssetsCollection';
import { AssetsCollectionActions, AssetsCollectionOwnProps, AssetsCollectionSelectors } from 'components/AssetsCollection/models';
import { toggleDragState } from 'containers/App/actions';
import { getAssetsCollectionData, getStoryCardsPreviews } from 'containers/Documents/actions';
import { showModal } from 'containers/ModalManager/actions';
import { isNotificationAccessError } from 'containers/ModalManager/selectors';
import { lastRefreshTime } from 'containers/Project/selectors';

const mapStateToProps = createStructuredSelector({
  lastRefreshTime,
  isNotificationAccessError,
});

const mapDispatchToProps = {
  getInternalData: getAssetsCollectionData,
  getPreview: document => getStoryCardsPreviews([document]),
  toggleDragState,
  showModal,
};

export default connect<AssetsCollectionSelectors, AssetsCollectionActions, AssetsCollectionOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AssetsCollection);
