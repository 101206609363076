import _ from 'lodash';
import { call, select } from 'redux-saga/effects';
import guid from 'uuid';

import { HandleReusableLayoutsEditingExceptions, Layer } from 'const';
import {
  handleReusableLayoutsEditing as handleReusableLayoutsEditingSelector,
  lastEditedLayoutId as lastEditedLayoutIdSelector,
} from 'containers/App/selectors';
import { mockReusableLayouts as mockReusableLayoutsSelector, reusableLayouts as reusableLayoutsSelector } from 'containers/Common/selectors';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { getEditedLayoutIds } from 'containers/Layouts/services/getEditedLayoutIds';
import { getLayoutAssetsByLayoutId } from 'containers/Layouts/services/getLayoutAssetsByLayoutId';
import { activeLayer } from 'containers/Project/selectors';
import * as Models from 'models';
import { getGroupLayoutsChildLayoutIds } from 'utils/layouts/getGroupLayoutsChildLayoutIds';
import { groupLayouts as groupLayoutsSelector } from '../selectors';
import { chooseChangeType } from '../services/chooseChangeType';
import { updateLayoutAssets } from '../services/updateLayoutAssets';

let currentLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId;
let isHandlingActive: boolean;
let currentLayer: Layer;

export function* handleReusableLayoutsEditing(action: Models.IAction) {
  try {
    const prevLayer = currentLayer;
    currentLayer = yield select(activeLayer);
    const reusableLayouts: ReturnTypeSaga<typeof reusableLayoutsSelector> = yield select(reusableLayoutsSelector);
    const mockReusableLayouts: ReturnTypeSaga<typeof mockReusableLayoutsSelector> = yield select(mockReusableLayoutsSelector);
    const groupLayouts: ReturnTypeSaga<typeof groupLayoutsSelector> = yield select(groupLayoutsSelector);
    const layouts = reusableLayouts.merge(groupLayouts).merge(mockReusableLayouts);
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const lastEditedLayoutId: ReturnTypeSaga<typeof lastEditedLayoutIdSelector> = yield select(lastEditedLayoutIdSelector);
    const layoutIds = layouts.keySeq().toArray();

    let prevLayoutAssetsByLayoutId = currentLayoutAssetsByLayoutId;
    currentLayoutAssetsByLayoutId = yield call(getLayoutAssetsByLayoutId, layoutIds);

    const isHandlingActivePrevState = isHandlingActive;
    isHandlingActive = yield select(handleReusableLayoutsEditingSelector);

    if (
      // reset prev assets when handling has just been activated
      (!_.isNil(isHandlingActivePrevState) && isHandlingActivePrevState !== isHandlingActive && isHandlingActive)
      // reset prev assets in case active layer is switched
      || (!_.isNil(prevLayer) && prevLayer !== currentLayer)
    ) {
      prevLayoutAssetsByLayoutId = null;
    }

    if (_.isEmpty(prevLayoutAssetsByLayoutId) || !isHandlingActive || HandleReusableLayoutsEditingExceptions.has(action.type)) {
      return;
    }

    // by this we guarantee that lastEditedLayoutId will be selected as unique one of all it's copies (if there are any)
    // we do it to ensure that we will get right assets with getLayoutAssetsByLayoutId
    // for the last edited layout (not one of it's copies) and check for it's changes later
    layoutIds.includes(lastEditedLayoutId) && layoutIds.unshift(lastEditedLayoutId);

    // in order to reduce number of comparisons
    const uniqLayoutIds = _.uniqBy(layoutIds, (layoutId) => {
      const internalDocumentId = layouts.getIn([layoutId, 'documentId']);

      return documents.getIn([internalDocumentId, 'documentId']);
    });

    const editedLayoutIds: ReturnTypeSaga<typeof getEditedLayoutIds> = yield call(
      getEditedLayoutIds,
      _.pick(prevLayoutAssetsByLayoutId, uniqLayoutIds),
      _.pick(currentLayoutAssetsByLayoutId, uniqLayoutIds),
    );

    for (const layoutId of editedLayoutIds) {
      const modalWindowId = guid();
      const layout = layouts.get(layoutId);
      const layoutDocumentInternalId = layout.get('documentId');
      const isLayoutEdited = documents.getIn([layoutDocumentInternalId, 'isEdited']);
      const isMockDocument = documents.getIn([layoutDocumentInternalId, 'isMockDocument']);

      const currentLayoutAssets = currentLayoutAssetsByLayoutId[layoutId];

      // we check for presence of any copies of the edited layout to have a base for restoring the previous state of all copies
      // if it is needed after saving the current one as a new because we will change the parameters of the current one
      const layoutDocumentId = documents.getIn([layouts.getIn([layoutId, 'documentId']), 'documentId']);
      const groupLayoutsChildIds = getGroupLayoutsChildLayoutIds(layouts);
      const isEditedLayoutInsideOfGroupLayout = groupLayoutsChildIds.includes(layoutId);

      const layoutToRestoreId = layoutIds.find((reusableLayoutId) => {
        // we shouldn't take an instance to restore from other GRL, but only form artboard
        if (isEditedLayoutInsideOfGroupLayout && groupLayoutsChildIds.includes(reusableLayoutId)) {
          return false;
        }

        const internalDocumentId = layouts.getIn([reusableLayoutId, 'documentId']);

        return (layoutDocumentId === documents.getIn([internalDocumentId, 'documentId']) && layoutId !== reusableLayoutId);
      });

      const layoutToRestore = layoutToRestoreId ? layouts.get(layoutToRestoreId) : null;

      isLayoutEdited || isMockDocument
        ? yield call(updateLayoutAssets, layoutDocumentInternalId, currentLayoutAssets)
        : yield call(
          chooseChangeType,
          layout,
          prevLayoutAssetsByLayoutId,
          currentLayoutAssetsByLayoutId,
          modalWindowId,
          layoutToRestore,
          currentLayer,
        );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], error);
  }
}
