import { call } from 'redux-saga/effects';

import * as Models from 'models';
import * as processes from 'services/processes';
import { toJS } from 'utils/immutable';
import { sagaFlow } from 'utils/sagaFlow';
import { getProjectAssets } from './getProjectAssets';

export function* processSyncedAssets(
  documents: Models.CombinedDocuments,
  masterScreenData: Models.MasterScreenData,
  actionsWithMiddleware: Models.IAction[],
  isProjectTranslatable: boolean,
  projectAssets?: Models.ProjectAssetsToUpload,
): Generator<unknown, Models.SyncProcessArgs> {
  const projectPrevAssets =
    projectAssets ||
    toJS((yield call(getProjectAssets, { removeInternalInfo: false })) as ReturnTypeSaga<typeof getProjectAssets>);

  const prevAssets: Models.SyncProcessArgs['prevAssets'] = {
    documents: projectPrevAssets.documents,
  };

  const assets: Models.SyncProcessArgs['assets'] = {
    ...projectPrevAssets,
    documents,
    masterScreenData,
  };

  const dataToProcess: Models.SyncProcessArgs = {
    assets,
    prevAssets,
    actionsWithMiddleware,
    isProjectTranslatable,
  };

  const syncedDataProcesses = sagaFlow<Models.SyncProcessArgs>(
    processes.extendReusableLayoutBrandDefinitions,
    processes.refreshLayoutsOnArtboards,
    processes.resetLayoutsWithoutDocument,
    processes.processBackgroundImages,
    processes.getActionsToImagesLoading,
    processes.getActionsToLayoutPreviewsLoading,
  );

  const processedData: ReturnTypeSaga<typeof syncedDataProcesses> = yield call(syncedDataProcesses, dataToProcess);

  return processedData;
}
