import { take } from 'redux-saga/effects';

import * as ModalManagerModels from 'containers/ModalManager/models';
import { takeModalWindowResponse as takeResponse } from 'utils/takeModalWindowResponse';

export type takeModalResponse<T> = (modalWindowId: string) => Generator<unknown, T>;

/**
 * @todo stop using of `utils/takeModalWindowResponse` directly and start using this service everywhere
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export function* takeModalResponse<T>(modalWindowId: string): Generator<unknown, T> {
  const { payload: { response } }: ModalManagerModels.Action.IHideModal<T> = yield take(
    takeResponse(modalWindowId),
  );

  return response;
}
