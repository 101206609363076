import _ from 'lodash';

import { ProjectType, ProjectsConfig } from 'const';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import { ListIndents } from '../constants';
import { Schemas } from '../models';

export function getListIndent(
  projectType: ProjectType,
  listItemParagraphs = [] as Schemas.ListItemParagraph[],
): Schemas.ListIndentStyles {
  const { convertListIndentToPx } = ProjectsConfig[projectType];
  const indent = ListIndents[projectType];

  if (!convertListIndentToPx) {
    return indent;
  }

  const fontSize = _.get(listItemParagraphs, '[0].fontSize', '');

  return convertIndentToPx(indent, fontSize);
}

function convertIndentToPx(indent: Schemas.ListIndentStyles, fontSize: string): Schemas.ListIndentStyles {
  const fontSizeValue = getIntegerFromStyle(fontSize);
  if (!fontSizeValue) {
    return indent;
  }

  const { margin, padding } = indent || {} as Schemas.ListIndentStyles;

  return {
    margin: convertPaddingToPx(margin, fontSizeValue),
    padding: convertPaddingToPx(padding, fontSizeValue),
  };
}

function convertPaddingToPx(padding: string, fontSize: number): string {
  return _(padding)
    .split(' ')
    .map(value => toPx(parseFloat(value) * fontSize, 2))
    .join(' ');
}
