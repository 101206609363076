import { put, take } from 'redux-saga/effects';
import guid from 'uuid';

import { ModalType } from 'const';
import { showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { ConfirmationWindowOptions } from 'models';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';

export function* showConfirmation(options: ConfirmationWindowOptions): Generator<unknown, boolean> {
  const modalWindowId = guid();

  yield put(showModal(ModalType.CONFIRMATION, options, modalWindowId));
  const { payload: { response } }: ModalManagerModels.Action.IHideModal<boolean> = yield take(takeModalWindowResponse(modalWindowId));

  return response;
}
