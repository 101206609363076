import Draft from 'draft-js';
import Immutable from 'immutable';
import _ from 'lodash';
import { call, select } from 'redux-saga/effects';
import guid from 'uuid';

import * as documentsSelectors from 'containers/Documents/selectors';
import { referenceCitationFactory } from 'factories/document/referenceCitationFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { findReferenceCitationDuplicateId } from 'utils/findDocumentDuplicateId';
import { updateReferenceCitationsOnComponent } from './updateReferenceCitationsOnComponent';

interface AddReferenceCitationToComponentResult {
  newReferenceCitationDocument?: Models.ReferenceCitationMap;
  updatedTextComponent: Models.TextComponentMap;
}

export function* addReferenceCitationToComponent(
  component: Models.TextComponentMap,
  referenceCitation: Models.ReferenceCitationMap,
): Generator<unknown, AddReferenceCitationToComponentResult> {
  try {
    const result = {} as AddReferenceCitationToComponentResult;

    const storyCardDocuments: ReturnTypeSaga<typeof documentsSelectors.storyCardsAndMagicFormsDocuments> = yield select(
      documentsSelectors.storyCardsAndMagicFormsDocuments,
    );
    const textCollectionsDocuments: ReturnTypeSaga<typeof documentsSelectors.textCollectionsDocuments> = yield select(
      documentsSelectors.textCollectionsDocuments,
    );
    const referenceId = referenceCitation.get('id');
    const existingReference = (storyCardDocuments.get(referenceId) || textCollectionsDocuments.get(referenceId)) as Models.ReferenceCitationMap;
    let newReferenceCitationId: string;
    let textComponent = component;

    if (existingReference) {
      const references: ReturnTypeSaga<typeof documentsSelectors.citationsForAssetsPanel> = yield select(documentsSelectors.citationsForAssetsPanel);
      const duplicateId = findReferenceCitationDuplicateId(existingReference, references);

      if (duplicateId) {
        // use existed reference citation
        newReferenceCitationId = duplicateId;
      } else {
        // create new reference citation document in assets
        // pass the document through the factory to make sure that the document will match its interface
        const newReferenceCitationDocument = Immutable.fromJS(
          referenceCitationFactory(existingReference.set('id', guid()).toJS()),
        ) as Models.ReferenceCitationMap;
        result.newReferenceCitationDocument = newReferenceCitationDocument;
        newReferenceCitationId = newReferenceCitationDocument.get('id');
      }

      // replace id of reference citation from assets collection by correct one
      const rawContent: Draft.RawDraftContentState = JSON.parse(textComponent.get('rawContent'));
      const { entityMap } = rawContent;
      _.forEach(entityMap, (entity) => {
        if (entity.data.id === referenceId) {
          entity.data = {
            id: newReferenceCitationId,
          };
        }
      });
      textComponent = textComponent.set('rawContent', JSON.stringify(rawContent));
    }

    result.updatedTextComponent = updateReferenceCitationsOnComponent(textComponent);

    return result;
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.addReferenceCitationToComponent', 'AddReferenceCitationToComponent');
  }
}
