import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ImageList from 'components/ImageList';
import { IImageListActions, IImageListOwnProps, IImageListSelectors } from 'components/ImageList/models';
import { toggleDragState, toggleUpdatedDocumentsDisplaying } from 'containers/App/actions';
import { showUpdatedImages } from 'containers/App/selectors';
import { showModal } from 'containers/ModalManager/actions';

const mapStateToProps = createStructuredSelector({
  showUpdatedImages,
});

const mapDispatchToProps = {
  showModal,
  toggleDragState,
  toggleUpdatedDocumentsDisplaying,
};

export default connect<IImageListSelectors, IImageListActions, IImageListOwnProps>(mapStateToProps, mapDispatchToProps)(ImageList);
