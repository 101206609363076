import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ICitationItemProps } from './models';
import styles from './styles.module.scss';

const CitationItemDragPreview: React.FunctionComponent<ICitationItemProps> = () => {
  return (
    <div className={styles.CitationItemDragPreview}>
      <Icon type={IconType.REFERENCE_CITATION} size="sm-md" color="primary" />
    </div>
  );
};

export default CitationItemDragPreview;
