import Immutable from 'immutable';
import { IMap } from 'typings/DeepIMap';

import { UndoRedoActions } from 'const';
import { ActionTypes } from 'containers/UndoRedoControl/constants';
import * as Models from 'models';
import { addFutureItem, addPastItem } from './utils';

export function undoable(reducer: Models.Reducer<IMap<Partial<Models.AppState.StateMapObject>>>) {
  return function (state: IMap<Partial<Models.AppState.StateMapObject>>, action: Models.IAction) {
    const present = !state.has('future')
      ? state
        .set('past', Immutable.fromJS([]))
        .set('future', Immutable.fromJS([]))
      : state;

    switch (action.type) {
      case ActionTypes.UNDO: {
        const past = present.get('past');
        if (past.size === 0) {
          return present;
        }

        const previous = past.last();

        return previous
          .set('past', past.pop())
          .set('future', addFutureItem(present));
      }
      case ActionTypes.REDO: {
        const future = present.get('future');
        if (future.size === 0) {
          return present;
        }

        const next = future.last();

        return next
          .set('future', future.pop())
          .set('past', addPastItem(present));
      }
      case ActionTypes.SAVE_APP_STATE: {
        return present
          .set('past', addPastItem(present));
      }
      case ActionTypes.CANCEL:
        return present.update('past', past => past.pop());
      default: {
        const newPresent = reducer(present, action);
        if (newPresent === present) {
          return present;
        }

        return UndoRedoActions.has(action.type)
          ? newPresent.set('future', Immutable.fromJS([]))
          : newPresent;
      }
    }
  };
}
