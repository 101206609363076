import Immutable from 'immutable';
import { isAbbreviatioinsListLayout } from 'modules/Abbreviations/utils/isAbbreviatioinsListLayout';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { EntityType, LATEST_REUSABLE_LAYOUT_VERSION, Layer } from 'const';
import { activeArtboard } from 'containers/Artboards/selectors';
import { updateDocument } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { layeredLayouts } from 'containers/Layouts/selectors';
import { getLayoutAssetsByLayoutId } from 'containers/Layouts/services/getLayoutAssetsByLayoutId';
import { activeLayer as activeLayerSelector } from 'containers/Project/selectors';
import { relations as relationsSelector } from 'containers/Relations/selectors';
import * as Models from 'models';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isReferenceCitationElement } from 'utils/layouts/isReferenceCitationElement';
import { isSpacerElement } from 'utils/layouts/isSpacerElement';
import { prioritizeLayeredRelations } from 'utils/prioritizeLayeredRelations';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isMockReusableLayout } from 'utils/reusableLayouts/isMockReusableLayout';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { updateLayeredRelations } from '../actions';
import { ActionTypes } from '../constants';
import { Action } from '../models';

const relationsByLayoutId: Record<string, Models.LayeredRelationsMap> = {};

export function* updateLayeredRelationsSilentlyRequest(action: Action.UpdateLayeredRelationsSilently) {
  try {
    const { relations, layoutId } = action.payload;
    relationsByLayoutId[layoutId] = relations;

    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    const allRelations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const layouts: ReturnTypeSaga<typeof layeredLayouts> = yield select(layeredLayouts);
    const layoutIds = getFlattenedLayouts(artboard, layouts as Models.CombinedLayoutsMap, true).keySeq().toList();

    let layoutIdsToBeProcessed = layoutIds.filter((layoutId) => {
      const layout = layouts.get(layoutId) as Models.LayeredLayoutMap;

      if (isSpacerElement(layout) || isReferenceCitationElement(layout) || isAbbreviatioinsListLayout(layout)) {
        return false;
      }

      return areThereEmptyRowsHeight(
        layouts.get(layoutId) as Models.LayeredLayoutMap,
        allRelations,
      );
    });

    // in case all layouts already have heights, only current layout will be updated
    if (layoutIdsToBeProcessed.size === 0) {
      layoutIdsToBeProcessed = Immutable.List([layoutId]);
    }

    if (!layoutIdsToBeProcessed.every(layoutId => !!relationsByLayoutId[layoutId])) {
      return;
    }

    let actions: Models.IAction[] = [];
    const activeLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);

    for (let i = 0; i <= layoutIdsToBeProcessed.size - 1; i++) {
      const layoutId = layoutIdsToBeProcessed.get(i);
      const relations = relationsByLayoutId[layoutId];
      const layout = layouts.get(layoutId);
      const callToAction = relations.find(item => item?.get('entityType') === 'CallToAction');

      if (activeLayer === Layer.TRANSLATED && callToAction && !callToAction.getIn(['styles', Layer.TRANSLATED])) {
        return;
      }

      actions.push(updateLayeredRelations(relations));

      if (isReusableLayout(layout, documents, activeLayer) || isMockReusableLayout(layout, documents, activeLayer)) {
        const { [layoutId]: layoutAssets }: ReturnTypeSaga<typeof getLayoutAssetsByLayoutId> = yield call(getLayoutAssetsByLayoutId, [layoutId]);
        const updatedLayoutAssets = (layoutAssets as Models.LayoutAssetsMap).set('relations', prioritizeLayeredRelations(relations, activeLayer));
        const updatedEntities: PlainObjectWithImmutableProps<Models.ReusableLayoutEntities> = {
          brandDefinition: updatedLayoutAssets.get('brandDefinition'),
          relationId: updatedLayoutAssets.get('relationId'),
          relations: updatedLayoutAssets.get('relations'),
          styles: updatedLayoutAssets.get('styles'),
          version: LATEST_REUSABLE_LAYOUT_VERSION,
          layoutSourceBrandDefinition: updatedLayoutAssets.get('layoutSourceBrandDefinition'),
        };

        actions.push(updateDocument({
          id: layout.getIn(['documentId', activeLayer]),
          documents: updatedLayoutAssets.get('documents') as unknown as Models.CombinedDocuments,
          entities: Immutable.Map(updatedEntities) as unknown as Models.ReusableLayoutEntities,
          entityType: EntityType.LAYOUT,
        }));
      }
    }

    yield put(batchActions(actions, ActionTypes.UPDATE_LAYOUT_ASSETS_SILENTLY));

    layoutIdsToBeProcessed.forEach((layoutId) => {
      delete relationsByLayoutId[layoutId];
    });
    actions = [];
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], error);
  }
}

function areThereEmptyRowsHeight(layout: Models.LayeredLayoutMap, relations: Models.RelationsMap): boolean {
  const layoutRelations = getFlattenedRelations(layout, relations);

  return layoutRelations.some((relation) => {
    if (!isColumnRelation(relation)) {
      return false;
    }

    return relation.get('styles').get('rowsHeight').some(rowHeight => !rowHeight);
  });
}
