import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    HIDE_MODAL: null,
    SHOW_MODAL: null,
    UPDATE_IMAGE_INFO_WITHIN_MODAL_WINDOW: null,
    UPDATE_MODAL_WINDOW_OPTIONS: null,
  },
  'ModalManager',
);
