import * as processesAbbreviations from 'modules/Abbreviations/processes';
import { batchActions } from 'redux-batched-actions';
import { call, put, spawn } from 'redux-saga/effects';

import { Layer, MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import { initApp } from 'containers/Common/services/initApp';
import * as ProjectActions from 'containers/Project/actions';
import { setActiveLayer } from 'containers/Project/sagas/setActiveLayer';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import * as processes from 'services/processes';
import { isProjectTranslatable } from 'utils/isProjectTranslatable';
import { sagaFlow } from 'utils/sagaFlow';
import { Action } from '../models';
import { getProjectSourceData } from '../services/getProjectSourceData';

export function* getProjectData({ payload: { rootDocumentId } }: Action.IGetProjectData) {
  const logId = logger.performanceStart();
  const methodName = MethodName.OPEN_PROJECT;

  try {
    yield put(batchActions([
      AppActions.lockProjectContent(ProcessType.GET_PROJECT_DATA),
      AppActions.stopHandlingReusableLayoutsEditing(),
    ]));

    const {
      projectAssets,
      needToSaveProjectLocally,
      countryAbbreviations,
      textAbbreviationDocuments,
    }: ReturnTypeSaga<typeof getProjectSourceData> = yield call(
      getProjectSourceData,
      rootDocumentId,
      logId,
    );

    const dataToProcess: Models.GetProjectDataProcessArgs = {
      assets: projectAssets,
      notificationOptions: [],
      actionsWithMiddleware: [],
      isProjectTranslatable: isProjectTranslatable(projectAssets),
      textAbbreviationDocuments,
    };

    const getDataProcesses = sagaFlow<Models.GetProjectDataProcessArgs>(
      processes.removeInvalidDocuments,
      processes.prepareSurfacesProcess,
      processes.removeUnusedEntities,
      processes.resetRelationsWithoutDocument,
      processes.normalizeDocuments,
      processes.processLegacyThumbnailUrls,
      processes.validateEntities, // screens, artboards, sections, layouts, relations
      processes.validateAssets, // documents, relations
      processes.processBrandDefinition,
      processes.processScreenDefinitions,
      processes.validateSSIElements,
      processes.normalizeLayeredDocuments,
      processes.extendReusableLayoutBrandDefinitions,
      processes.refreshLayoutsOnArtboards,
      processes.resetLayoutsWithoutDocument,
      processes.processBackgroundImages,
      processes.setInitialScreensFormat,
      processes.getActionsToLayoutPreviewsLoading,
      processesAbbreviations.removeMissingAbbreviations,
      processesAbbreviations.removeImageAbbreviationsFromLayeredRelations,
      arg => processes.getActionsToImagesLoading(arg, true),
    );

    const processedData: ReturnTypeSaga<typeof getDataProcesses> = yield call(getDataProcesses, dataToProcess);
    const { actionsWithMiddleware, notificationOptions, assets } = processedData;
    const activeLayer = dataToProcess.isProjectTranslatable ? assets.project.activeLayer : Layer.ORIGINAL;

    yield call(initApp, { actionsWithMiddleware, projectAssets: assets, setActiveScreen: true, countryAbbreviations });
    yield call(setActiveLayer, ProjectActions.setActiveLayer(activeLayer));

    if (needToSaveProjectLocally) {
      yield put(AppActions.saveProjectLocally({ showNotifications: false }));
    }

    yield put(AppActions.unlockProjectContent());

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    // notifications should be shown after project content is unlocked
    // shouldn't be shown if there were errors
    for (const notificationOption of notificationOptions) {
      yield call(
        [Notifications, Notifications.showNotification],
        notificationOption,
      );
    }
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'WelcomeScreen.getProjectData', 'OpenProject', null, true);
  } finally {
    yield put(batchActions([
      AppActions.unlockProjectContent(),
      AppActions.startHandlingReusableLayoutsEditing(),
      ProjectActions.activateAutoSave(),
    ]));
  }
}
