import React from 'react';
/* eslint-disable max-len */

const PreviewHover = (): JSX.Element => (
  <svg viewBox="0 0 34 34">
    <g strokeWidth="1" fillRule="evenodd">
      <circle cx="17" cy="17" r="17"/>
      <g transform="translate(4, 4)">
        <g fillRule="evenodd">
          <path style={{ fill: '#ffffff' }} d="M16.65,15.3 L15.939,15.3 L15.687,15.057 C16.569,14.031 17.1,12.699 17.1,11.25 C17.1,8.019 14.481,5.4 11.25,5.4 C8.019,5.4 5.4,8.019 5.4,11.25 C5.4,14.481 8.019,17.1 11.25,17.1 C12.699,17.1 14.031,16.569 15.057,15.687 L15.3,15.939 L15.3,16.65 L19.8,21.141 L21.141,19.8 L16.65,15.3 L16.65,15.3 Z M11.25,15.3 C9.009,15.3 7.2,13.491 7.2,11.25 C7.2,9.009 9.009,7.2 11.25,7.2 C13.491,7.2 15.3,9.009 15.3,11.25 C15.3,13.491 13.491,15.3 11.25,15.3 L11.25,15.3 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default PreviewHover;
