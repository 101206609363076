import { useCallback, useRef, useState } from 'react';

type HookReturnType = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
};

export function useAbbreviationControl(
  onBeforeClose?: () => void,
): HookReturnType {
  const onBeforeCloseRef = useRef(onBeforeClose);
  onBeforeCloseRef.current = onBeforeClose;

  const [isOpen, changeIsOpen] = useState(false);
  const isOpenRef = useRef<boolean>(isOpen);
  isOpenRef.current = isOpen;

  const setIsOpen = useCallback(
    (value: boolean) => {
      if (!value) {
        const fn = onBeforeCloseRef.current;
        if (typeof fn === 'function') {
          fn();
        }
      }
      changeIsOpen(value);
    },
    [],
  );

  const toggle = useCallback(() => setIsOpen(!isOpenRef.current), [setIsOpen]);
  const toggleOn = useCallback(() => setIsOpen(true), [setIsOpen]);
  const toggleOff = useCallback(() => setIsOpen(false), [setIsOpen]);

  return {
    isOpen,
    setIsOpen,
    toggle,
    toggleOn,
    toggleOff,
  };
}
