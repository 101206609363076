import { ActionTypes } from './constants';
import * as Models from './models';

export const setDocuments: Models.ActionCreator.SetDocuments = documents => ({
  type: ActionTypes.SET_DOCUMENTS,
  payload: {
    documents,
  },
});

export const mergeDocuments: Models.ActionCreator.SetDocuments = documents => ({
  type: ActionTypes.MERGE_DOCUMENTS,
  payload: {
    documents,
  },
});

export const setDocument: Models.ActionCreator.SetDocument = document => ({
  type: ActionTypes.SET_DOCUMENT,
  payload: {
    document,
  },
});

export const addDocuments: Models.ActionCreator.AddDocuments = documents => ({
  type: ActionTypes.ADD_DOCUMENTS,
  payload: {
    documents,
  },
});

export const deleteDocuments: Models.ActionCreator.DeleteDocuments = ids => ({
  type: ActionTypes.DELETE_DOCUMENTS,
  payload: {
    ids,
  },
});

export const deleteComponent: Models.ActionCreator.DeleteComponent = (id, entityType) => ({
  type: ActionTypes.DELETE_COMPONENT,
  payload: {
    id,
    entityType,
  },
});

export const updateDocument: Models.ActionCreator.UpdateDocument = document => ({
  type: ActionTypes.UPDATE_DOCUMENT,
  payload: {
    document,
  },
});

export const updateReferenceRequest: Models.ActionCreator.UpdateReferenceRequest = (id, referenceText, documentIdsToUpdate) => ({
  type: ActionTypes.UPDATE_REFERENCE_REQUEST,
  payload: {
    id,
    referenceText,
    documentIdsToUpdate,
  },
});

export const getImagesInfo: Models.ActionCreator.GetImagesInfo = documents => ({
  type: ActionTypes.GET_IMAGES_INFO,
  payload: {
    documents,
  },
});

export const downloadImages: Models.ActionCreator.DownloadImages = (documents, options) => ({
  type: ActionTypes.DOWNLOAD_IMAGES,
  payload: {
    documents,
    options,
  },
});

// Use this action creator only for initial loading of images info, because its action type is exception in auto save logic
// If you want to update internalImage info in other cases, please create new action creator with action type differ this.
export const updateInternalImageInfo: Models.ActionCreator.UpdateInternalImageInfo = (id, internalImageInfo) => ({
  type: ActionTypes.UPDATE_INTERNAL_IMAGE_INFO,
  payload: {
    id,
    internalImageInfo,
  },
});

export const updateInternalImageInfoRequest: Models.ActionCreator.UpdateInternalImageInfoRequest = (id, internalImageInfo) => ({
  type: ActionTypes.UPDATE_INTERNAL_IMAGE_INFO_REQUEST,
  payload: {
    id,
    internalImageInfo,
  },
});

export const getAssetsCollectionData: Models.ActionCreator.GetAssetsCollectionData = id => ({
  type: ActionTypes.GET_ASSETS_COLLECTION_DATA,
  payload: {
    id,
  },
});

export const setAssetsCollectionData: Models.ActionCreator.SetAssetsCollectionData = (id, entityType, documents, entities) => ({
  type: ActionTypes.SET_ASSETS_COLLECTION_DATA,
  payload: {
    id,
    entityType,
    documents,
    entities,
  },
});

export const getStoryCardImagesInfo: Models.ActionCreator.GetStoryCardImagesInfo = (storyCardId, documents) => ({
  type: ActionTypes.GET_STORY_CARD_IMAGES_INFO,
  payload: {
    storyCardId,
    documents,
  },
});

export const getStoryCardLayoutThumbnails: Models.ActionCreator.GetStoryCardLayoutThumbnails = (storyCardId, documents) => ({
  type: ActionTypes.GET_STORY_CARD_LAYOUT_THUMBNAILS,
  payload: {
    storyCardId,
    documents,
  },
});

// Use this action creator only for initial loading of story card images info, because its action type is exception in auto save logic
// If you want to update story card internal image info in other cases, please create new action creator with action type differ this.
export const updateStoryCardImageInfo: Models.ActionCreator.UpdateStoryCardImageInfo = (id, internalImageInfo, storyCardId) => ({
  type: ActionTypes.UPDATE_STORY_CARD_IMAGE_INFO,
  payload: {
    id,
    internalImageInfo,
    storyCardId,
  },
});

export const updateStoryCardLayoutThumbnail: Models.ActionCreator.UpdateStoryCardLayoutThumbnail = (id, thumbnailInternalInfo, storyCardId) => ({
  type: ActionTypes.UPDATE_STORY_CARD_LAYOUT_THUMBNAIL,
  payload: {
    id,
    thumbnailInternalInfo,
    storyCardId,
  },
});

export const addReferenceCitation: Models.ActionCreator.AddReferenceCitation = (modalWindowId, text) => ({
  type: ActionTypes.ADD_REFERENCE_CITATION,
  payload: {
    modalWindowId,
    text,
  },
});

export const addImage: Models.ActionCreator.AddImage = (modalWindowId, imageDataToUpload) => ({
  type: ActionTypes.ADD_IMAGE,
  payload: {
    imageDataToUpload,
    modalWindowId,
  },
});

export const updateImage: Models.ActionCreator.UpdateImage = (modalWindowId, id, imageDataToUpload) => ({
  type: ActionTypes.UPDATE_IMAGE,
  payload: {
    id,
    modalWindowId,
    imageDataToUpload,
  },
});

export const updateDocumentSource: Models.ActionCreator.UpdateDocumentSource = (id, entityType, documentSource) => ({
  type: ActionTypes.UPDATE_DOCUMENT_SOURCE,
  payload: {
    id,
    entityType,
    documentSource,
  },
});

export const removeAnchor: Models.ActionCreator.RemoveAnchor = (documentId, anchorBlockKey) => ({
  type: ActionTypes.REMOVE_ANCHOR,
  payload: {
    documentId,
    anchorBlockKey,
  },
});

export const getStoryCardsPreviews: Models.ActionCreator.GetStoryCardsPreviews = documents => ({
  type: ActionTypes.GET_STORY_CARDS_PREVIEWS,
  payload: {
    documents,
  },
});

export const getLayoutsThumbnails: Models.ActionCreator.GetLayoutsThumbnails = documents => ({
  type: ActionTypes.GET_LAYOUTS_THUMBNAILS,
  payload: {
    documents,
  },
});

export const updateLayoutDocumentThumbnailUrl: Models.ActionCreator.UpdateLayoutDocumentThumbnailUrl = (id, entityType, thumbnailInternalInfo) => ({
  type: ActionTypes.UPDATE_LAYOUT_DOCUMENT_THUMBNAIL_URL,
  payload: {
    id,
    entityType,
    thumbnailInternalInfo,
  },
});
