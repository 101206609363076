import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import GroupLayout from 'components/GroupLayout';
import { GroupLayoutActions, GroupLayoutOwnProps, GroupLayoutSelectors } from 'components/GroupLayout/models';
import { toggleDragState, updateDragHotspotGroupLayoutId, updateDragHotspotPosition } from 'containers/App/actions';
import * as appSelectors from 'containers/App/selectors';
import { documents } from 'containers/Documents/selectors';
import { dropReusableLayout, moveLayout, resetLayoutHeightSilently, setLayoutAndInstances } from 'containers/Layouts/actions';
import { isChildLayoutBeingUploaded, isLayoutBeingUploaded, layouts } from 'containers/Layouts/selectors';
import { projectType } from 'containers/Project/selectors';
import { relationsByRegularLayoutId } from 'containers/Relations/selectors';

const mapStateToProps = (_, props) => {
  const layoutId = props.layout.get('id');

  return createStructuredSelector({
    documents,
    dragHotspotGroupLayoutId: appSelectors.dragHotspotGroupLayoutId,
    dragHotspotPosition: appSelectors.dragHotspotPosition,
    dragSourceType: appSelectors.dragSourceType,
    isChildLayoutBeingUploaded: isChildLayoutBeingUploaded(layoutId),
    isLayoutBeingUploaded: isLayoutBeingUploaded(layoutId),
    layouts,
    moveableLayoutId: appSelectors.moveableLayoutId,
    projectType,
    relationsByRegularLayoutId,
  });
};

const mapDispatchToProps = {
  dropReusableLayout,
  moveLayout,
  setLayout: setLayoutAndInstances,
  resetLayoutHeightSilently,
  toggleDragState,
  updateDragHotspotGroupLayoutId,
  updateDragHotspotPosition,
};

export default connect<GroupLayoutSelectors, GroupLayoutActions, GroupLayoutOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(GroupLayout);
