import React from 'react';

import { ISpacerProps } from './models';
import styles from './styles.module.scss';

const ScreensDragSpacer: React.FunctionComponent<ISpacerProps> = (props) => {
  const { position } = props;

  return (
    <div
      className={styles.screensSpacer}
      style={{ order: position }}
    />
  );
};

export default ScreensDragSpacer;
