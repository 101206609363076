import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/ColumnWidth';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { ColumnWidthProps } from './models';

const ColumnWidth: React.FunctionComponent<ColumnWidthProps> = (props) => {
  const {
    addLayoutColumn,
    applyColumnsWidth,
    columnsWidth,
    controlState = ControlState,
    deleteLayoutColumn,
    getMinColumnWidth,
    isAddingDisabled,
  } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.COLUMN_WIDTH}
      type={IconType.COLUMN_WIDTH}
    >
      {
        isOpen &&
        <Dropdown
          addLayoutColumn={addLayoutColumn}
          applyColumnsWidth={applyColumnsWidth}
          columnsWidth={columnsWidth}
          deleteLayoutColumn={deleteLayoutColumn}
          getMinColumnWidth={getMinColumnWidth}
          isAddingDisabled={isAddingDisabled}
        />
      }
    </Control>
  );
};

export default ColumnWidth;
