import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isMagicForm(document: Models.CombinedDocument): document is Models.MagicForm;
export function isMagicForm(document: Models.CombinedDocumentMap): document is Models.MagicFormMap;
export function isMagicForm(document): boolean {
  return getValue(document, 'entityType') === EntityType.MAGIC_FORM;
}

export function isModuleBundle(document: Models.CombinedDocument): document is Models.ModuleBundle;
export function isModuleBundle(document: Models.CombinedDocumentMap): document is Models.ModuleBundleMap;
export function isModuleBundle(document): boolean {
  return getValue(document, 'entityType') === EntityType.MODULE_BUNDLE;
}

export function isStoryCard(document: Models.CombinedDocument): document is Models.StoryCard;
export function isStoryCard(document: Models.CombinedDocumentMap): document is Models.StoryCardMap;
export function isStoryCard(document): boolean {
  return getValue(document, 'entityType') === EntityType.STORY_CARD;
}

export function isTextCollection(document: Models.CombinedDocument): document is Models.TextCollection;
export function isTextCollection(document: Models.CombinedDocumentMap): document is Models.TextCollectionMap;
export function isTextCollection(document): boolean {
  return getValue(document, 'entityType') === EntityType.TEXT_COLLECTION;
}

export function isAssetsCollection(document: Models.CombinedDocument): document is Models.AssetsCollection;
export function isAssetsCollection(document: Models.CombinedDocumentMap): document is Models.AssetsCollectionMap;
export function isAssetsCollection(document): boolean {
  return isMagicForm(document) || isModuleBundle(document) || isStoryCard(document) || isTextCollection(document);
}
