import React from 'react';
/* eslint-disable max-len */

const FontColor = (): JSX.Element => (
  <svg viewBox="0 0 20 20">
    <defs>
      <path id="fontColorPath" d="M9,3 L3.5,17 L5.75,17 L6.87,14 L13.12,14 L14.24,17 L16.49,17 L11,3 L9,3 L9,3 Z M7.62,12 L10,5.67 L12.38,12 L7.62,12 Z" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="fontColorMask" fill="white">
        <use xlinkHref="#fontColorPath" />
      </mask>
      <g mask="url(#fontColorMask)" fill="#FFFFFF">
        <rect x="0" y="0" width="20" height="20" />
      </g>
    </g>
  </svg>
);

export default FontColor;
