import { createSelector } from 'reselect';

import { AssetFilter } from 'const';
import { activeArtboardId } from 'containers/Artboards/selectors';
import { reusableLayoutsTextComponentIds } from 'containers/Common/selectors';
import * as documentSelectors from 'containers/Documents/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { filterDocuments } from 'utils/filterDocuments';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import * as Models from './models';

export const projectPanel = (state): Models.State.IState => state.get('projectPanel');
export const selectedTextComponent = state => projectPanel(state).get('selectedTextComponent');
export const selectedCitation = state => projectPanel(state).get('selectedCitation');
export const contentFilter = state => projectPanel(state).get('contentFilter');
export const assetIdToScroll = state => projectPanel(state).get('assetIdToScroll');
export const activeTab = state => projectPanel(state).get('activeTab');
export const activeSections = state => projectPanel(state).get('activeSections');

export const citations = createSelector(
  [documentSelectors.citationsForAssetsPanel, contentFilter],
  (citations, contentFilter) => citations.filter(citation => filterDocuments(citation, contentFilter)),
);

export const images = createSelector(
  [documentSelectors.imagesForAssetsPanel, contentFilter],
  (images, contentFilter) => images.filter(image => filterDocuments(image, contentFilter)),
);

export const moduleBundles = createSelector(
  [documentSelectors.moduleBundlesForAssetsPanel, contentFilter],
  (moduleBundles, contentFilter) => moduleBundles.filter(moduleBundle => filterDocuments(moduleBundle, contentFilter)),
);

export const storyCardsAndMagicForms = createSelector(
  [documentSelectors.storyCardsAndMagicFormsForAssetsPanel, contentFilter],
  (storyCardsAndMagicForms, contentFilter) => storyCardsAndMagicForms.filter(storyCard => filterDocuments(storyCard, contentFilter)),
);

export const textCollections = createSelector(
  [documentSelectors.textCollectionsForAssetsPanel, contentFilter],
  (textCollections, contentFilter) => textCollections.filter(textCollection => filterDocuments(textCollection, contentFilter)),
);

export const textComponents = createSelector(
  [documentSelectors.textComponentsForAssetsPanel, contentFilter, reusableLayoutsTextComponentIds],
  (textComponents, contentFilter, reusableLayoutsTextComponentIds) => textComponents.filter((textComponent, id) => (
    filterDocuments(textComponent, contentFilter) && !reusableLayoutsTextComponentIds.includes(id)),
  ),
);

export const reusableLayouts = createSelector(
  [documentSelectors.reusableLayoutDocumentsForAssetsPanel, documentSelectors.groupLayoutDocumentsForAssetsPanel, contentFilter],
  (reusableLayoutDocuments, groupLayoutDocuments, contentFilter) => reusableLayoutDocuments
    .merge(groupLayoutDocuments)
    .sortBy(document => document.get('name').trim(), (a, b) => a.localeCompare(b))
    .filter(reusableLayout => filterDocuments(reusableLayout, contentFilter)),
);

export const availableFilters = createSelector(
  [
    documentSelectors.citationsForAssetsPanel,
    documentSelectors.imagesForAssetsPanel,
    documentSelectors.storyCardsAndMagicFormsForAssetsPanel,
    documentSelectors.textCollectionsForAssetsPanel,
    documentSelectors.textComponentsForAssetsPanel,
    documentSelectors.reusableLayoutDocumentsForAssetsPanel,
    documentSelectors.groupLayoutDocumentsForAssetsPanel,
  ],
  (citations, images, storyCardsAndMagicForms, textCollections, textComponents, reusableLayouts) => {
    const availableFilters: string[] = [];
    if (citations.size > 0) {
      availableFilters.push(AssetFilter.REFERENCES);
    }
    if (images.size > 0) {
      availableFilters.push(AssetFilter.IMAGES);
    }
    if (storyCardsAndMagicForms.size > 0) {
      availableFilters.push(AssetFilter.STORY_CARDS);
    }
    if (textComponents.size > 0) {
      availableFilters.push(AssetFilter.TEXT);
    }
    if (textCollections.size > 0) {
      availableFilters.push(AssetFilter.TEXT_COLLECTIONS);
    }
    if (reusableLayouts.size > 0) {
      availableFilters.push(AssetFilter.LAYOUTS);
    }

    if (reusableLayouts.size > 0) {
      availableFilters.push(AssetFilter.GROUP_LAYOUTS);
    }

    return availableFilters;
  },
);

export const referencesDataForDocumentOnAssetPanel = (documentId?: string) => createSelector(
  [documentSelectors.referencesData, selectedTextComponent, activeArtboardId],
  (referencesData, selectedTextComponent, activeArtboardId) => {
    const referenceCitationsOrderByDocuments = referencesData.get('referenceCitationsOrderByDocuments');
    // HACK: the document can be used multiple times on the artboard and may has different reference data depending on the position
    // so we use layoutId to get references data for a particular document
    // but in the asset panel we can't get the appropriate layoutId, so we use artboardId to search for the first used document on the artboard
    const key = referenceCitationsOrderByDocuments.keySeq().toArray().find(id =>
      id.includes(`${activeArtboardId}${documentId || selectedTextComponent.get('id')}`),
    );

    return referenceCitationsOrderByDocuments.get(key);
  },
);

/**
 * Returns a count of artboards where the component is used
 */
export const selectComponentCount = (id: string) => createSelector(
  [documentSelectors.relationsByScreenId, documentSelectors.getImagesByScreenId, activeLayer],
  (relationsByScreenId, getImagesByScreenId, activeLayer) => {
    return relationsByScreenId.count((relationsOnScreen, screenId) => (
      relationsOnScreen.some(relation => isRegularRelation(relation) && relation.getIn(['documentId', activeLayer]) === id) ||
      !!getImagesByScreenId.get(screenId).find(backgroundImage => backgroundImage.get('id') === id)
    ));
  },
);

export const selectLayoutCount = (id: string) => createSelector(
  [documentSelectors.layoutDocumentsOnScreens],
  layoutDocumentsOnScreens => layoutDocumentsOnScreens.count(layoutDocumentsOnScreen => layoutDocumentsOnScreen.includes(id)),
);

export const selectCitationCount = (id: string) => createSelector(
  [documentSelectors.citationsOnScreens],
  citationsOnScreens => citationsOnScreens.count(citationsOnScreen => citationsOnScreen.includes(id)),
);
