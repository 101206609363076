import { EntityType } from 'const';
import * as Models from 'models';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { getParent } from './getParent';

export function areThereHorizontalNeighbors(relationId: string, relations: Models.RelationsMap | Models.LayeredRelationsMap): boolean {
  const { parent } = getParent(relationId, relations);

  if (!parent) {
    return false;
  }

  // column can't have horizontal neighbors
  // row with only one child, need to check her parent, it might have horizontal neighbors
  if (isColumnRelation(parent) || parent.get('relationIds').size <= 1) {
    return areThereHorizontalNeighbors(parent.get('id'), relations);
  }

  return true;
}

export function getRootWithHorizontalNeighbors(relationId: string, relations: Models.RelationsMap | Models.LayeredRelationsMap): string {
  const { parent } = getParent(relationId, relations);

  if (!parent) {
    return null;
  }

  const parentRelationId = parent.get('id');

  // column can't have horizontal neighbors
  // row with only one child, need to check her parent, it might have horizontal neighbors
  if (isColumnRelation(parent) || parent.get('relationIds').size <= 1) {
    return getRootWithHorizontalNeighbors(parentRelationId, relations);
  }

  return getRootWithHorizontalNeighbors(parentRelationId, relations) || parentRelationId;
}

export function areThereHorizontalNeighborsJS(relationId: string, relations: Models.LayeredRelations): boolean {
  const parent = Object.values(relations).find((relation) => {
    // regular relation
    if (relation.entityType === EntityType.ROW || relation.entityType === EntityType.COLUMN) {
      return relation.relationIds.includes(relationId);
    }

    return false;
  });

  if (!parent) {
    return false;
  }

  // column can't have horizontal neighbors
  // row with only one child, need to check her parent, it might have horizontal neighbors
  if (parent.entityType === EntityType.COLUMN
    || (parent.entityType === EntityType.ROW && parent.relationIds.length <= 1)) {
    return areThereHorizontalNeighborsJS(parent.id, relations);
  }

  return true;
}
