import React from 'react';

import ColorTints from 'components/Toolbar/Shared/ColorTints';
import { ColorSectionProps } from './models';
import styles from './styles.module.scss';

const ColorSection: React.FunctionComponent<ColorSectionProps> = (props) => {
  const { activeColor, colors, title, toggleColor, toggleTooltip } = props;

  return (
    <div className={styles.ColorSection}>
      <div>{title}</div>
      <div className={styles.controls}>
        {colors?.map(color => (
          <ColorTints
            key={color.get('name')}
            activeColor={activeColor}
            color={color}
            toggleColor={toggleColor}
            toggleTooltip={toggleTooltip}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorSection;
