import { Layer } from 'const';
import * as Models from 'models';
import { prioritizeLayeredRelation } from 'utils/prioritizeLayeredRelations';

export type GetPrioritizedRelationProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  activeLayer: Layer;
};

export function getPrioritizedRelation(
  props: GetPrioritizedRelationProps,
): Models.RegularRelationMap<Models.TextRelationStyles> {
  const { relation, activeLayer } = props;

  return prioritizeLayeredRelation(relation, activeLayer);
}
