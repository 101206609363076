import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const Move = (): JSX.Element => {
  const pathId = guid();
  const maskId = guid();

  return (
    <svg viewBox="0 0 24 24">
      <defs>
        <path d="M1.08642984,10.0033341 L3.85199564,12.872883 L6.72191134,10.1017018 L8.00979565,10.1241819 L7.98837866,11.3511602 C7.9876873,11.3907681 6.37459037,13.0361967 5.17930491,14.2500986 L7.88872845,17.060117 L0.939995644,16.9388264 L1.08642984,10.0033341 Z M16.8882032,10.0033341 L17.0346374,16.9388264 L10.0859046,17.060117 L12.7953281,14.2500986 C11.6000426,13.0361967 9.9869457,11.3907681 9.98625434,11.3511602 L9.98625434,11.3511602 L9.96483735,10.1241819 L11.2527217,10.1017018 L14.1226374,12.872883 L16.8882032,10.0033341 Z M16.9389372,0.939882978 L17.0346374,7.8762609 L14.1696567,5.10455545 L11.3991559,7.97462859 L10.1112716,7.9971087 L10.0898546,6.77013039 C10.0891889,6.73199343 11.5305232,5.15383048 12.6661115,3.91646885 L12.7956846,3.77531579 L9.99020435,1.06117356 L16.9389372,0.939882978 Z M1.03569584,0.939882978 L7.98442865,1.06117356 L5.17894837,3.77531579 L5.30852149,3.91646885 C6.44410984,5.15383048 7.88544412,6.73199343 7.88477843,6.77013039 L7.88477843,6.77013039 L7.86336145,7.9971087 L6.57547714,7.97462859 L3.80497629,5.10455545 L0.939995644,7.8762609 L1.03569584,0.939882978 Z" id={pathId} />
      </defs>
      <g id="04-SSI-anchors" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="anchors-1" transform="translate(-989.000000, -610.000000)">
          <g id="Group-3" transform="translate(970.000000, 280.000000)">
            <g id="Group-4" transform="translate(0.000000, 317.000000)">
              <g id="Icons/pack/drug-n-drop" transform="translate(16.000000, 10.000000)">
                <g id="Icon-Color" transform="translate(15.000000, 15.000000) rotate(45.000000) translate(-15.000000, -15.000000) translate(6.000000, 6.000000)">
                  <mask id={maskId} fill="white">
                    <use xlinkHref={`#${pathId}`} />
                  </mask>
                  <use id="Mask" fill="#D8D8D8" xlinkHref={`#${pathId}`} />
                  <g id="Colors/White" mask={`url(#${maskId})`} fill="#FFFFFF">
                    <g transform="translate(-4.000000, -4.000000)" id="White">
                      <rect x="0" y="0" width="26" height="26" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Move;
