import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select, spawn } from 'redux-saga/effects';

import { updateDragHotspotPosition } from 'containers/App/actions';
import { dragHotspotPosition } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { brandDefinition } from 'containers/BrandDefinition/selectors';
import { UIFontFaces } from 'containers/Common/selectors';
import { downloadImages, mergeDocuments } from 'containers/Documents/actions';
import {
  documents as documentsSelector,
  reusableLayoutDocumentsForAssetsPanel as reusableLayoutDocumentsSelector,
} from 'containers/Documents/selectors';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { mergeRelations } from 'containers/Relations/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { appendCustomFontFaces } from 'services/appendFontFace';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { findDuplicateIdByDocumentId } from 'utils/findDocumentDuplicateId';
import { addGroupLayoutToArtboard } from 'utils/reusableLayouts/addGroupLayoutToArtboard';
import { getUsingLayoutFontsNotExistsInProject } from 'utils/reusableLayouts/getUsingLayoutFontsNotExistsInProject';
import { addLayouts } from '../actions';
import { Action } from '../models';

export function* dropGroupReusableLayout(action: Action.DropGroupReusableLayout) {
  try {
    const { documentId, sectionId, storyCardId } = action.payload;
    const layouts = (yield select(layeredLayoutsSelector)).toJS() as Models.LayeredLayouts;
    const prevLayouts = _.cloneDeep(layouts);
    const artboard = (yield select(activeArtboard)).toJS() as Models.Artboard;
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const reusableLayoutDocuments = (yield select(reusableLayoutDocumentsSelector)).toJS() as Models.ReusableLayouts;
    const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;
    const position: ReturnTypeSaga<typeof dragHotspotPosition> = yield select(dragHotspotPosition);
    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);

    const groupLayoutDocumentKeyPath = storyCardId ? [storyCardId, 'documents', documentId] : [documentId];
    let groupLayoutDocument = _.get(documents, groupLayoutDocumentKeyPath) as Models.GroupLayoutDocument;

    const duplicateGroupLayoutDocumentId = findDuplicateIdByDocumentId(groupLayoutDocument, documents as Models.Documents);

    if (duplicateGroupLayoutDocumentId) {
      groupLayoutDocument = documents[duplicateGroupLayoutDocumentId] as Models.GroupLayoutDocument;
    }

    const result: ReturnTypeSaga<typeof addGroupLayoutToArtboard> = yield call(
      addGroupLayoutToArtboard,
      {
        groupLayoutDocument,
        sectionId,
        position,
        artboard,
        projectDocuments: documents,
        layouts,
        projectBrandDefinition,
        activeLayer: layer,
        storyCardId: duplicateGroupLayoutDocumentId ? null : storyCardId,
        adoptStyles: true,
      },
    );

    yield put(batchActions([
      saveAppState(),
      mergeRelations(result.relations),
      mergeDocuments(result.documents),
      addLayouts(result.layouts),
      updateArtboard(result.artboard),
      updateDragHotspotPosition(null),
    ]));

    result.newImages && (yield put(downloadImages(result.newImages)));

    const layoutFontsNotExistsInProject = getUsingLayoutFontsNotExistsInProject(
      result.relations,
      projectBrandDefinition,
      prevLayouts,
      _.chain(groupLayoutDocument)
        .get(['entities', 'layouts'])
        .map(veevaId => _.find(reusableLayoutDocuments, (document => document.documentId === veevaId)))
        .compact()
        .map('id')
        .value(),
      layer,
    );

    if (layoutFontsNotExistsInProject.length > 0) {
      yield spawn([Notifications, Notifications.showMissingFonts], layoutFontsNotExistsInProject);
    }

    const fontFaces: ReturnTypeSaga<typeof UIFontFaces> = yield select(UIFontFaces);
    appendCustomFontFaces(fontFaces.toJS());
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.dropGroupReusableLayout', 'DropGroupReusableLayout');
  }
}
