import React from 'react';

const CheckMark = (): JSX.Element => (
  <svg width="18px" height="14px" viewBox="0 0 18 14">
    <defs>
      <polygon id="CheckMark-path-1" points="11.6 18.6 7.4 14.4 6 15.8 11.6 21.4 23.6 9.4 22.2 8" />
    </defs>
    <g id="CheckMark-Export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="CheckMark-Uploading-Success" transform="translate(-1006.000000, -131.000000)">
        <g id="CheckMark-Notification" transform="translate(985.000000, 100.000000)">
          <g id="CheckMarkID" transform="translate(15.000000, 23.000000)">
            <mask id="CheckMark-mask-2" fill="white">
              <use xlinkHref="#CheckMark-path-1" />
            </mask>
            <g id="CheckMark-Icon" />
            <g id="CheckMark-Icon-Color" mask="url(#CheckMark-mask-2)" fill="#34CB49">
              <g transform="translate(2.000000, 2.000000)" id="CheckMark-Green">
                <rect x="0" y="0" width="26" height="26" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckMark;
