import _ from 'lodash';
import { call, put, select, spawn } from 'redux-saga/effects';

import { MethodName } from 'const';
import { endThumbnailRefresh, startThumbnailRefresh } from 'containers/App/actions';
import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import { UIFontFaces as UIFontFacesSelector } from 'containers/Common/selectors';
import { helperFileName as helperFileNameSelector } from 'containers/Project/selectors';
import { updateSurface } from 'containers/Surfaces/actions';
import { UIFontFace } from 'models';
import { getThumbnails } from 'services/api';
import logger from 'services/logger';
import * as Models from '../models';

export function* getThumbnailUrl(action: Models.Action.IRefreshThumbnail) {
  const { surfaceId: screenId } = action.payload;

  const logId = logger.performanceStart();
  const methodName = MethodName.GENERATE_SCREEN_THUMBNAIL;

  try {
    yield put(startThumbnailRefresh(screenId));
    const UIFontFaces: UIFontFace[] = (yield select(UIFontFacesSelector)).toJS();
    const artboardJson: ReturnTypeSaga<typeof getArtboardsJson> = yield call(getArtboardsJson, [screenId]);
    const helperFileName: ReturnTypeSaga<typeof helperFileNameSelector> = yield select(helperFileNameSelector);

    const { data: thumbnailUrlsByArtboardId }: ReturnTypeSaga<typeof getThumbnails> = yield call(
      getThumbnails,
      artboardJson,
      helperFileName,
      UIFontFaces,
    );
    // take the first value because it have to be single in the thumbnailUrlsByArtboardId
    const url = _(thumbnailUrlsByArtboardId).values().first();

    yield put(updateSurface({ id: screenId, thumbnail: { url } }));

    yield spawn([logger, logger.performanceEnd], logId, { methodName });
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during uploading thumbnail.', error);
  } finally {
    yield put(endThumbnailRefresh(screenId));
  }
}
