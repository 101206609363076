import { call, select } from 'redux-saga/effects';

import { artboards as artboardsSelectors } from 'containers/Artboards/selectors';
import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { sectionsWidthByScreenAndId as sectionsWidthByScreenAndIdSelector } from 'containers/ScreenDefinitions/selectors';
import { surfaces } from 'containers/Surfaces/selectors';
import { LayoutMap } from 'models';
import { ArtboardsJson, ScreenOptions } from 'services/ArtboardConverter/models';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { getLayoutWidth } from 'utils/layouts/getLayoutWidth';

export function* getLayoutJson(layoutIds: string[]): Generator<unknown, ArtboardsJson> {
  const screens: ReturnTypeSaga<typeof surfaces> = yield select(surfaces);
  const layouts: ReturnTypeSaga<typeof layoutsSelector> = yield select(layoutsSelector);
  const artboards: ReturnTypeSaga<typeof artboardsSelectors> = yield select(artboardsSelectors);
  const sectionsWidthByScreenAndId: ReturnTypeSaga<typeof sectionsWidthByScreenAndIdSelector> = yield select(sectionsWidthByScreenAndIdSelector);

  const firstLayout = layouts.get(layoutIds[0]) as LayoutMap;
  const artboard = artboards.find(artboard => getFlattenedLayouts(artboard, layouts).has(firstLayout.get('id')));
  const screen = screens.find(screen => screen.get('artboardId') === artboard.get('id'));
  const screenId = screen.get('id');
  const screenWidth = sectionsWidthByScreenAndId.getIn([screenId, firstLayout.get('section')]);

  const screenOptions: ScreenOptions = {
    layoutIds,
    artboardWidth: getLayoutWidth(firstLayout, screenWidth),
    allowArtboardBackground: false,
    allowArtboardHeight: false,
    allowSectionBackground: false,
    allowSectionHeight: false,
    allowSSI: false,
    isLayoutPreview: true,
  };

  const layoutJson: ReturnTypeSaga<typeof getArtboardsJson> = yield call(getArtboardsJson, [screenId], { [screenId]: screenOptions });

  return layoutJson;
}
