import React from 'react';
import AddImageItem from 'components/AddImageItem';
import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import { EntityType } from 'const';
import ImageListItem from 'containers/ImageListItem';
import { ImageMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { IImageListProps } from './models';
import styles from './styles.module.scss';

const ImageList: React.FunctionComponent<IImageListProps> = (props) => {
  const {
    documents,
    isExpand,
    showHeader,
    showModal,
    showUpdatedImages,
    toggleDragState,
    toggleExpand,
    toggleUpdatedDocumentsDisplaying,
    updatedInPromoMats,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({
    toggleUpdatedDocumentsDisplaying,
    showUpdatedDocuments: showUpdatedImages,
    entityType: EntityType.IMAGE,
    toggle: toggleExpand,
    isExpand,
  });

  return (
    <div className={styles.ImageListContainer} data-title="images">
      {
        showHeader &&
        <Header
          title={intlGet('Assets', 'Images', { count: documents ? documents.size : 0 })}
          showUpdatesHint={updatedInPromoMats && showUpdatedImages}
          expanded={expanded}
          onClick={toggle}
        />
      }
      {
        (expanded || !showHeader) &&
        <List
          layout="grid"
          border={showHeader}
          elements={documents}
          subheader={showHeader && <AddImageItem showModal={showModal} />}
          renderElement={(document: ImageMap) => (
            <ImageListItem
              key={document.get('id')}
              allowChange={showHeader}
              allowDelete={showHeader}
              component={document}
              showModal={showModal}
              toggleDragState={toggleDragState}
            />
          )}
        />
      }
    </div>
  );
};

export default ImageList;
