import { useContext, useEffect } from 'react';
import { CellActionsContext } from './CellActionsContext';
import { CellAction, CellActionExecutor } from './types';

export function useCellActionsExecutor<A extends CellAction>(
  action: A,
  executor: CellActionExecutor<A>,
): void {
  const { tryToExecuteAction } = useContext(CellActionsContext);

  // only tryToExecuteAction should be placed in deps
  // as effect should run only after change in actions (and tryToExecuteAction respectively)
  useEffect(() => {
    tryToExecuteAction?.(action, executor);
  }, [tryToExecuteAction]);
}
