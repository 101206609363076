import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import ScreenFormatSize from 'components/ScreenFormatSize';
import {
  FORMAT_SIZE_DELIMITER,
  PredefinedScreenFormatByType,
  PredefinedScreenFormats,
  ScreenFormatNameByType,
  ScreenFormatType,
} from 'const';
import { useScreenFormat } from 'hooks/useScreenFormat';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { isCustomScreenFormatType, isScreenFormatValid } from 'utils/screens/isCustomScreenFormat';
import { ChooseScreenFormatProps } from './models';
import styles from './styles.module.scss';

const ChooseScreenFormat: React.FunctionComponent<ChooseScreenFormatProps> = (props) => {
  const {
    height,
    setHeight,
    width,
    setWidth,
    formatType,
    setFormatType,
    isDisabled: isScreenFormatSizeDisabled,
  } = useScreenFormat();

  const selectedFormat = isCustomScreenFormatType(formatType)
    ? { type: formatType, height, width }
    : PredefinedScreenFormatByType[formatType];

  const onCreate: React.MouseEventHandler = () => {
    const { hideModal, modalWindowId } = props;

    hideModal<Models.ScreenFormat>(modalWindowId, selectedFormat);
  };

  return (
    <div className={styles.DocumentDuplicates}>
      <header className={styles.header}>
        <div className={styles.title}>{intlGet('ChooseScreenFormat', 'Title')}</div>
        <div>{intlGet('ChooseScreenFormat', 'Description')}</div>
      </header>
      <section>
        <ul className={styles.screenFormatsList}>
          {
            PredefinedScreenFormats.map((format) => {
              const { height, width, type } = format;
              const isSelected = type === formatType;

              return (
                <div
                  className={classNames(styles.screenFormatItem, { [styles.selected]: isSelected })}
                  onClick={() => setFormatType(type)}
                  key={type}
                >
                  <Icon
                    type={IconType.PREDEFINED_SCREEN_FORMAT}
                    color={isSelected ? 'primary' : 'light-grey'}
                    size="sm-md"
                  />
                  <div className={styles.info}>
                    <span>{ScreenFormatNameByType[type]()}</span>
                    <span>{width} {FORMAT_SIZE_DELIMITER} {height}</span>
                  </div>
                </div>
              );
            })
          }
          <div
            className={classNames(styles.screenFormatItem, { [styles.selected]: isCustomScreenFormatType(formatType) })}
            onClick={() => setFormatType(ScreenFormatType.CUSTOM)}
          >
            <Icon
              type={IconType.CUSTOM_SCREEN_FORMAT}
              color={isCustomScreenFormatType(formatType) ? 'primary' : 'light-grey'}
              size="sm-md"
            />
            <div className={styles.info}>
              <span>{intlGet('ScreenFormat.Name', 'Custom')}</span>
              <ScreenFormatSize
                height={height}
                isDisabled={isScreenFormatSizeDisabled}
                setHeight={setHeight}
                setWidth={setWidth}
                width={width}
              />
            </div>
          </div>
        </ul>
      </section>
      <footer>
        <Button
          disabled={!isScreenFormatValid(selectedFormat)}
          onClick={onCreate}
          variant="primary"
        >
          {intlGet('ChooseScreenFormat.Button', 'Create')}
        </Button>
      </footer>
    </div>
  );
};

export default ChooseScreenFormat;
