import _ from 'lodash';

import { ARTBOARD_WIDTH } from 'const';
import { createArtboard, createArtboards } from 'factories/artboardFactory';
import { createLayeredLayouts } from 'factories/layoutFactory';
import { createLayeredRelations } from 'factories/relationFactory';
import { createSections, createSectionsFromScreenDefinition } from 'factories/sectionFactory';
import * as Models from 'models';
import { createLayoutsAndRelations } from 'utils/createLayoutAndRelations';

export const validateEntities = <T extends Models.GetProjectDataProcessArgs>(args: T): T => {
  const { assets } = args;
  const { surfaces: screens, artboards, sections, layouts, relations, masterScreenData: { screenDefinitions } } = assets;

  let processedArtboards: Models.Artboards = {};
  let processedLayouts: Models.LayeredCombinedLayouts = {};
  let processedRelations: Models.LayeredRelations = {};
  let processedSections: Models.Sections = {};

  // NOTE: Don't check for empty sections here, because there are old projects without sections at all.
  if (!artboards || !layouts || !relations) {
    // create default artboard for every surface
    _.forEach(screens, (screen) => {
      const { artboardId, screenDefinitionId } = screen;
      const screenDefinition = screenDefinitions[screenDefinitionId];
      const availableSections = _.get(screenDefinition, 'sections', [] as Models.MasterScreen.Section[]);
      const sections = createSectionsFromScreenDefinition(availableSections, screenDefinitionId);

      ({ layouts: processedLayouts, relations: processedRelations } = createLayoutsAndRelations(
        _.map(sections, section => ({ section: section.id })),
        { layoutWidth: ARTBOARD_WIDTH, createLayeredRelations: true },
      ));

      processedSections = _.merge(processedSections, sections);

      const defaultArtboard = createArtboard({ layoutIds: _.keys(processedLayouts), id: artboardId });
      processedArtboards[defaultArtboard.id] = defaultArtboard;
    });
  } else {
    processedSections = createSections(sections);
    processedLayouts = createLayeredLayouts(layouts);
    processedArtboards = createArtboards(artboards);
    processedRelations = createLayeredRelations(relations, { useDefaultStyleId: false });
  }

  assets.sections = processedSections;
  assets.layouts = processedLayouts;
  assets.artboards = processedArtboards;
  assets.relations = processedRelations;

  return args;
};
