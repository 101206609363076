import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { intlGet } from 'utils/intlGet';
import { IconType } from '../../../Icon/constants';
import { AutoFitContentToggleProps } from './models';
import styles from './styles.module.scss';

const AutoFitContentToggle: React.FunctionComponent<AutoFitContentToggleProps> =
  ({
    isAutoFitContent,
    isAutoHeight,
    toggleAutoFitContent,
    beforeMouseDown = (): void => null,
  }: AutoFitContentToggleProps) => {
    React.useEffect(
      () => {
        if (isAutoHeight && !isAutoFitContent) {
          toggleAutoFitContent();
        }
      },
      [isAutoHeight],
    );

    const [isHover, setIsHover] = React.useState(false);
    const onMouseDownHandler = (event: React.SyntheticEvent<EventTarget>): void => {
      event.preventDefault();
      if (!isAutoHeight && toggleAutoFitContent) {
        beforeMouseDown();
        toggleAutoFitContent();
      }
    };

    return (
      <div
        className={classNames(
          [styles.autoFitContentToggle, styles.iconTooltipWrapper],
          {
            [styles.autoFitContentToggleDisabled]: isAutoHeight,
          },
        )}
        onMouseDown={onMouseDownHandler}
        onMouseEnter={(): void => setIsHover(true)}
        onMouseLeave={(): void => setIsHover(false)}
      >
        <div className={styles.iconTooltip} data-tooltip={intlGet('EditorToolbar.Tooltip', 'AutoHeight')}>
          <Icon
            type={IconType.DOUBLE_ARROW_AUTO}
            size="sm"
            color={isAutoFitContent ? 'primary' : 'secondary'}
            className={classNames({
              [styles.autoFitContentToggleHover]: isHover,
            })}
          />
          <span
            className={classNames(
              styles.autoFitContentToggleLetter,
              {
                [styles.autoFitContentToggleLetterActive]: isAutoFitContent,
                [styles.autoFitContentToggleHover]: isHover,
              },
            )}
          >
          A
          </span>
        </div>
      </div>
    );
  };

export default AutoFitContentToggle;
