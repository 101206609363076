import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Confirmation from 'components/ModalWindows/Confirmation';
import * as Models from 'components/ModalWindows/Confirmation/models';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: Models.ConfirmationProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
