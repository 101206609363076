export enum BackgroundGradientDirection {
  DEG0 = '0deg',
  DEG90 = '90deg',
  TOP_RIGHT = 'to top right',
  BOTTOM_RIGHT = 'to bottom right',
  RADIAL = 'radial',
}

type Color = {
  name: string;
  tint: number;
};

export type BackgroundGradient = {
  color1: Color;
  color2: Color;
  direction: BackgroundGradientDirection;
};

export type BackgroundGradientMap = DeepIMap<BackgroundGradient>;
