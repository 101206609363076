import Editor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import { removeAbbreviationFromEditorStateIfTermChanged } from 'modules/Abbreviations/utils/removeAbbreviationFromEditorStateIfTermChanged';
import * as BrandDefinition from 'modules/BrandDefinition';
import { compositeDecorator } from 'modules/draftjs/decorators';
import { applyEditorStateFontStylesForBrandStyles } from 'modules/draftjs/helpers/applyEditorStateFontStylesForBrandStyles';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import * as inlineStyles from 'utils/inlineStyles';
import { applyEditorStateHacks } from '../../helpers/applyEditorStateHacks';
import { Props, Setters, Context, Features } from './types';
import { createFontColorSetter, getBulletColor, getFontColor } from './utils';
import { createInlineStyleSetter } from './utils/inlineStyle';

export { Features } from './types';

export type UseDraftjsEditorReturnType<F extends readonly Features[]> = {
  editorRef: RefObject<Editor>;
  editorState: Draft.EditorState;
  getEditorState: () => Draft.EditorState;
  setEditorState: (state: Draft.EditorState) => void;
  resetEditorState: (state: Draft.EditorState) => void;
  onEditorChange: (editorState: Draft.EditorState) => void;
  returnFocusToEditor: () => void;
  props: Props<F>;
  setters: Setters<F>;
};

export function useDraftjsEditor<F extends readonly Features[]>(
  onInit: () => Draft.EditorState,
  context: Context<F>,
  features: F,
): UseDraftjsEditorReturnType<F> {

  const isFeatureEnabled = (feature: Features): feature is F[number] => {
    return features.includes(feature);
  };

  const featuresSignature = features.toString();
  const featureApplyHacks = isFeatureEnabled(Features.APPLY_HACKS);
  const featureAbbreviations = isFeatureEnabled(Features.ABBREVIATIONS);
  const featureDecorators = isFeatureEnabled(Features.DECORATORS);
  const featureInlineStyleExtended = isFeatureEnabled(Features.INLINE_STYLE_EXTENDED);
  const featureInlineStyleExtendedBrandStyles = isFeatureEnabled(Features.APPLY_BRANDSTYLE_FOR_EXTENDED_INLINE_STYLE);

  const editorRef = useRef<Editor>(null);

  const {
    editMode,
    projectType,
    brandStyle,
    colors,
    fonts,
    abbreviationsData,
  } = context as Context<Features[]>;

  const editModeRef = useRef(Boolean(editMode));
  editModeRef.current = Boolean(editMode);

  const abbreviationDataJS = useMemo(
    () => (abbreviationsData?.toJS() || []) as Models.TextAbbreviationDocumentsArray,
    [abbreviationsData],
  );

  const [editorState, setEditorState] = useState(
    () => featureDecorators
      ? Draft.EditorState.set(onInit(), { decorator: compositeDecorator })
      : onInit(),
  );

  const editorStateRef = useRef<Draft.EditorState>(editorState);
  editorStateRef.current = editorState;
  const getEditorState = useCallback(() => editorStateRef.current, []);

  const resetEditorState = useCallback(
    (newState): void => {
      setEditorState(
        featureDecorators
          ? Draft.EditorState.set(newState, { decorator: compositeDecorator })
          : newState,
      );
    },
    [featureDecorators],
  );

  const returnFocusToEditor = useCallback((): void => {
    // HACK: setTimeout is used to return a focus to an editor after all possible state updates because
    // returning focus causes an additional state update, so we can miss some updates which were before
    // and weren't completely applied due to setState is asynchronous.
    setTimeout(() => editorRef.current?.focus());
  }, []);

  const onEditorChange: UseDraftjsEditorReturnType<F>['onEditorChange'] = useCallback((state: Draft.EditorState) => {
    let newState = state;
    if (featureAbbreviations) {
      newState = removeAbbreviationFromEditorStateIfTermChanged(
        newState,
        abbreviationDataJS,
      );
    }
    if (featureApplyHacks) {
      newState = applyEditorStateHacks(newState, editorStateRef.current, editModeRef.current);
    }
    if (featureInlineStyleExtended) {
      if (featureInlineStyleExtendedBrandStyles) {
        newState = applyEditorStateFontStylesForBrandStyles(newState, projectType, fonts);
      } else {
        newState = editorUtils.applyFontStyles(newState, fonts, projectType);
      }
    }
    resetEditorState(newState);
  }, [
    resetEditorState, abbreviationDataJS, fonts, projectType,
    featureApplyHacks, featureAbbreviations,
    featureInlineStyleExtended, featureInlineStyleExtendedBrandStyles,
  ]);

  const props: Props<F> = useMemo(() => {
    const abbreviation = isFeatureEnabled(Features.ABBREVIATIONS) ? editorUtils.parseAbbreviationData(editorState) : undefined;

    return {
      abbreviationId: (
        isFeatureEnabled(Features.ABBREVIATIONS) ? abbreviation?.id : undefined
      ) as Props<F>['abbreviationId'],
      abbreviationTerm: (
        isFeatureEnabled(Features.ABBREVIATIONS) ? abbreviation?.term : undefined
      ),
      blockLineHeight: (
        isFeatureEnabled(Features.BLOCK)
          ? editorUtils.getBlockLineHeight(editorState)
          : undefined
      ) as Props<F>['blockLineHeight'],
      blockType: (
        isFeatureEnabled(Features.BLOCK)
          ? editorUtils.getBlockType(editorState)
          : undefined
      ) as Props<F>['blockType'],
      bulletColor: getBulletColor(
        isFeatureEnabled(Features.BULLET),
        editorState,
        colors,
      ) as Props<F>['bulletColor'],
      fontColor: getFontColor(
        isFeatureEnabled(Features.FONT_COLOR),
        editorState,
        colors,
      ) as Props<F>['fontColor'],
      fontFamily: editorUtils.getActiveFontFamily(editorState),
      fontSize: editorUtils.getCurrentFontSize(editorState),
      inlineStyle: editorState.getCurrentInlineStyle(),
      link: (
        isFeatureEnabled(Features.LINK) ? editorUtils.getLink(editorState) : undefined
      ) as Props<F>['link'],
      linkApplicable: (
        isFeatureEnabled(Features.LINK) ? editorUtils.canApplyLink(editorState) : undefined
      ) as Props<F>['linkApplicable'],
      scriptStyle: Constants.SCRIPT_CONTROLS.find(
        item => editorUtils.isStyleAppliedToAllSelection(editorState, item.style),
      )?.style,
      textNoWrap: editorUtils.isNoWrapActive(editorState),
    };
  }, [editorState, colors, featuresSignature]);

  const setters = useMemo((): Setters<F> => ({
    abbreviationId: (
      isFeatureEnabled(Features.ABBREVIATIONS)
        ? (value, onBeforeChange): void => {
          const state = getEditorState();
          const { id } = editorUtils.parseAbbreviationData(state);
          if (value !== id) {
            onBeforeChange?.();
            setEditorState(editorUtils.toggleAbbreviation(state, value));
          }
        }
        : undefined
    ) as Setters<F>['abbreviationId'],

    blockLineHeight: (
      isFeatureEnabled(Features.BLOCK)
        ? (value): void => setEditorState(editorUtils.setLineHeight(getEditorState(), value))
        : undefined
    ) as Setters<F>['blockLineHeight'],

    blockType: (
      isFeatureEnabled(Features.BLOCK)
        ? (type): void => setEditorState(editorUtils.toggleBlockType(getEditorState(), type))
        : undefined
    ) as Setters<F>['blockType'],

    bulletColor: (
      isFeatureEnabled(Features.BULLET)
        ? (brandColor: Models.BrandColorMap): void => {
          const { name, HEX, tint } = (brandColor?.toJS() || {}) as Partial<BrandDefinition.BrandColorLike>;

          let nextEditorState = editorUtils.toggleCustonInlineStyleValue(
            getEditorState(),
            Constants.StylePrefix.BULLET_COLOR,
            HEX ?? '',
          );
          nextEditorState = editorUtils.toggleCustonInlineStyleValue(
            nextEditorState,
            Constants.StylePrefix.BULLET_COLOR_NAME,
            name ?? '',
          );
          nextEditorState = editorUtils.toggleCustonInlineStyleValue(
            nextEditorState,
            Constants.StylePrefix.BULLET_COLOR_TINT,
            tint ?? 0,
          );

          resetEditorState(Draft.EditorState.set(nextEditorState, { decorator: compositeDecorator }));
        }
        : undefined
    ) as Setters<F>['bulletColor'],

    clearFormatting: (
      isFeatureEnabled(Features.CLEAR_FORMATTING)
        ? (): void => setEditorState(editorUtils.clearFormatting(getEditorState()))
        : undefined
    ) as Setters<F>['clearFormatting'],

    fontColor: createFontColorSetter(
      isFeatureEnabled(Features.FONT_COLOR),
      getEditorState,
      setEditorState,
      brandStyle,
      colors,
    ) as Setters<F>['fontColor'],

    fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap): void => {
      const { fontFamilyStyle, fontStyleStyle, fontWeightStyle, characterStyleNameStyle } = inlineStyles.getFontFamilyStyles(font, characterStyle);

      let nextEditorState = editorUtils.toggleCustomInlineStyle(
        editorStateRef.current,
        fontFamilyStyle,
        Constants.StylePrefix.FONT_FAMILY,
      );
      nextEditorState = fontStyleStyle
        ? editorUtils.toggleCustomInlineStyle(nextEditorState, fontStyleStyle, Constants.StylePrefix.FONT_STYLE)
        : nextEditorState;
      nextEditorState = fontWeightStyle
        ? editorUtils.toggleCustomInlineStyle(nextEditorState, fontWeightStyle, Constants.StylePrefix.FONT_WEIGHT)
        : nextEditorState;
      nextEditorState = characterStyleNameStyle
        ? editorUtils.toggleCustomInlineStyle(nextEditorState, characterStyleNameStyle, Constants.StylePrefix.CHARACTER_STYLE_NAME)
        : nextEditorState;

      setEditorState(nextEditorState);
    },

    fontSize: value => setEditorState(editorUtils.toggleFontSize(getEditorState(), value)),

    inlineStyle: createInlineStyleSetter(
      featureInlineStyleExtended,
      getEditorState,
      setEditorState,
      projectType,
      fonts,
      featureInlineStyleExtendedBrandStyles,
    ),

    link: (
      isFeatureEnabled(Features.LINK)
        ? (value: string, onBeforeChange): void => {
          const state = getEditorState();
          if (value !== editorUtils.getLink(state)) {
            onBeforeChange?.();
            setEditorState(editorUtils.toggleLink(state, value));
          }
        }
        : undefined
    ) as Setters<F>['link'],

    scriptStyle: (style: Constants.ScriptType): void => setEditorState(
      editorUtils.toggleScriptStyle(getEditorState(), style),
    ),

    textNoWrap: (): void => setEditorState(
      editorUtils.toggleTextWrap(getEditorState()),
    ),

  }), [
    getEditorState, resetEditorState, setEditorState,
    brandStyle, colors, fonts,
    projectType,
    featuresSignature, featureInlineStyleExtended, featureInlineStyleExtendedBrandStyles,
  ]);

  return {
    editorRef,
    editorState,
    getEditorState,
    setEditorState,
    resetEditorState,
    onEditorChange,
    returnFocusToEditor,
    props,
    setters,
  };
}
