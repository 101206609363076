import { useSelector } from 'react-redux';
import * as commonSelectors from 'containers/Common/selectors';
import * as Models from 'models';

export function useBrandColorsForLayout(
  layoutId: string,
): Models.BrandColorsList | undefined {
  const fonts = useSelector(commonSelectors.flatColorsByLayoutId);

  return fonts.get(layoutId);
}
