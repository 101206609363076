import React from 'react';
import guid from 'uuid';

const TextBottom = (): JSX.Element => {
  const pathId = guid();
  const maskId = guid();

  return (
    <svg viewBox="6 6 18 18">
      <defs>
        <path d="M12,10 L9,10 L9,0 L7,0 L7,10 L4,10 L8,14 L12,10 Z M0,16 L0,18 L16,18 L16,16 L0,16 Z" id={pathId} />
      </defs>
      <g id="Icons/StyleBar/Alignment/bottom" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Color" transform="translate(7.000000, 6.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`#${pathId}`} />
          </mask>
          <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`} />
          <g id="Colors/White" mask={`url(#${maskId})`} fill="#ffffff">
            <g transform="translate(-5.000000, -4.000000)" id="White">
              <rect x="0" y="0" width="26" height="25" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TextBottom;
