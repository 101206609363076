import React from 'react';
import ReactDOM from 'react-dom';
import UndoRedoControl from 'components/UndoRedoControl';
import { HeaderIds } from 'const/Header';
import css from '../styles.module.scss';

type Props = {
  undo: () => void;
  redo: () => void;
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
};

const UndoPortal: React.FunctionComponent<Props> = (props) => {
  const { undo, redo, isRedoDisabled, isUndoDisabled } = props;

  return ReactDOM.createPortal(
    <div className={css.undoRedoControl}>
      <UndoRedoControl
        undo={undo}
        redo={redo}
        isRedoDisabled={isRedoDisabled}
        isUndoDisabled={isUndoDisabled}
      />
    </div>,
    document.getElementById(HeaderIds.UNDO_REDO),
  );
};

export default UndoPortal;
