
import _ from 'lodash';

import * as Models from 'models';
import { deleteInternalInfo } from 'utils/deleteInternalInfo';
import { isGroupLayoutAssets } from 'utils/isGroupLayoutAssets';
import { stylesComparator } from 'utils/styles/stylesComparator';

/**
 * Prepares layout assets to comparison.
 * We should compare the only data that can be modified by user.
 */
function prepareLayoutAssetsToComparison(assets: Models.ExtendedCombinedLayoutAssets): Models.ExtendedCombinedLayoutAssets {
  assets.previewUrl = null;

  if (isGroupLayoutAssets(assets)) {
    assets.usedDocuments = null;

    return assets;
  }

  assets.brandDefinition = null;

  return deleteInternalInfo(assets);
}

function compareGroupLayoutHeight(oldHeight: number, newHeight: number): boolean {
  if (!oldHeight) { // no need to compare if there wasn't previous value
    return true;
  }

  return oldHeight === newHeight;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function customizer(oldValue: any, newValue: any, propName: string): boolean | undefined {
  return stylesComparator(oldValue, newValue, propName);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function groupCustomizer(oldValue: any, newValue: any, propName: string): boolean | undefined {
  switch (propName) {
    case 'height': return compareGroupLayoutHeight(oldValue, newValue);
    default: return undefined;
  }
}

export async function getEditedLayoutIds(
  oldLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId,
  newLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId,
): Promise<string[]> {
  const layoutIds = _.keys(newLayoutAssetsByLayoutId);

  const editedLayoutIds = await Promise.all(layoutIds.map(layoutId => new Promise<string>((resolve, reject) => {
    try {
      const newAssets = newLayoutAssetsByLayoutId[layoutId];
      const oldAssets = oldLayoutAssetsByLayoutId[layoutId];

      if (!oldAssets) {
        return resolve(null);
      }

      setTimeout(() => {
        const parsedOldAssets = oldAssets.toJS() as Models.ExtendedCombinedLayoutAssets;
        const parsedNewAssets = newAssets.toJS() as Models.ExtendedCombinedLayoutAssets;

        const isLayoutEdited = (
          oldAssets !== newAssets &&
          !_.isEqualWith(
            prepareLayoutAssetsToComparison(parsedOldAssets),
            prepareLayoutAssetsToComparison(parsedNewAssets),
            isGroupLayoutAssets(newAssets) ? groupCustomizer : customizer,
            // due to the fact (see DCC-7577) that we need to use assetBorderRadius for CTA we migrate the old RL
            // by moving borderRadius values to assetBorderRadius but we don't want to bother user with "edit" dialog in this case (see DCC-8728)
          ) && !(!parsedOldAssets.styles.assetBorderRadius && parsedNewAssets.styles.assetBorderRadius)
        );

        resolve(isLayoutEdited ? layoutId : null);
      });
    } catch (error) {
      reject(error);
    }
  })));

  return editedLayoutIds.filter(Boolean);
}
