import Immutable from 'immutable';

import { DocumentsKey } from 'const';
import * as Models from 'models';
import { DocumentsKeyByEntityType } from 'models/state';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const documentsInitialState = Immutable.fromJS({
  [DocumentsKey.CALL_TO_ACTIONS]: {},
  [DocumentsKey.GROUP_LAYOUTS]: {},
  [DocumentsKey.IMAGES]: {},
  [DocumentsKey.LAYOUTS]: {},
  [DocumentsKey.MAGIC_FORMS]: {},
  [DocumentsKey.MODULE_BUNDLES]: {},
  [DocumentsKey.PROJECT_FILES]: {},
  [DocumentsKey.REFERENCE_CITATIONS]: {},
  [DocumentsKey.STORY_CARDS]: {},
  [DocumentsKey.TEXT_COMPONENTS]: {},
  [DocumentsKey.TEXT_COLLECTIONS]: {},
  [DocumentsKey.MASTER_SURFACES]: {},
} as Models.AppState.Documents) as State.IState;

const documentsReducer: Models.Reducer<State.IState> =
  (state = documentsInitialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_DOCUMENTS:
        return setDocuments(state, action);
      case ActionTypes.SET_DOCUMENT:
        return setDocument(state, action);
      // case ActionTypes.ADD_DOCUMENTS:
      //   return addDocuments(state, action);
      case ActionTypes.DELETE_DOCUMENTS:
        return deleteDocuments(state, action);
      case ActionTypes.ADD_DOCUMENTS:
      case ActionTypes.MERGE_DOCUMENTS:
        return mergeDocuments(state, action);
      case ActionTypes.UPDATE_DOCUMENT:
        return updateDocument(state, action);
      case ActionTypes.UPDATE_INTERNAL_IMAGE_INFO:
        return updateInternalImageInfo(state, action);
      case ActionTypes.UPDATE_STORY_CARD_IMAGE_INFO:
        return updateStoryCardImageInfo(state, action);
      case ActionTypes.UPDATE_STORY_CARD_LAYOUT_THUMBNAIL:
        return updateStoryCardLayoutThumbnail(state, action);
      case ActionTypes.SET_ASSETS_COLLECTION_DATA:
        return setAssetsCollectionData(state, action);
      case ActionTypes.UPDATE_DOCUMENT_SOURCE:
        return updateDocumentSource(state, action);
      case ActionTypes.UPDATE_LAYOUT_DOCUMENT_THUMBNAIL_URL:
        return updateLayoutDocumentThumbnailUrl(state, action);
      default:
        return state;
    }
  };

const setDocuments: Models.Reducer<State.IState, Action.ISetDocuments> = (state, action) => {
  const documents: Models.CombinedDocumentsMap = Immutable.fromJS(action.payload.documents);

  return documents.reduce(
    (state, document) => {
      const id = document.get('id');
      const key = DocumentsKeyByEntityType[document.get('entityType')];

      return state.setIn([key, id], document);
    },
    documentsInitialState,
  );
};

const mergeDocuments: Models.Reducer<State.IState, Action.ISetDocuments> = (state, action) => {
  const documents: Models.CombinedDocumentsMap = Immutable.fromJS(action.payload.documents);

  return documents.reduce(
    (state, document) => {
      const id = document.get('id');
      const key = DocumentsKeyByEntityType[document.get('entityType')];

      return state.setIn([key, id], document);
    },
    state,
  );
};

// TODO: Revise all current usages, in some cases updateDocument might be more suitable
const setDocument: Models.Reducer<State.IState, Action.ISetDocument> = (state, action) => {
  const document: Models.CombinedDocumentMap = Immutable.fromJS(action.payload.document);
  const id = document.get('id');
  const key = DocumentsKeyByEntityType[document.get('entityType')];

  return state.setIn([key, id], document);
};

const updateDocument: Models.Reducer<State.IState, Action.IUpdateDocument> = (state, action) => {
  const document: Models.CombinedDocumentMap = Immutable.fromJS(action.payload.document);
  const key = DocumentsKeyByEntityType[document.get('entityType')];
  const id = document.get('id');

  return state.mergeIn([key, id], document);
};

const deleteDocuments: Models.Reducer<State.IState, Action.IDeleteDocuments> = (state, action) => {
  const allDocuments = state.flatMap<string, Models.CombinedDocumentMap>(documents => documents);

  return action.payload.ids.reduce(
    (state, id) => {
      const key = DocumentsKeyByEntityType[allDocuments.getIn([id, 'entityType'])];

      return state.deleteIn([key, id]);
    },
    state,
  );
};

const updateInternalImageInfo: Models.Reducer<State.IState, Action.IUpdateInternalImageInfo> = (state, action) => {
  const internalImageInfo = Immutable.fromJS(action.payload.internalImageInfo);
  const image = state.getIn(['images', action.payload.id]);

  return image
    ? state.setIn(['images', action.payload.id, '_internalInfo'], internalImageInfo)
    : state;
};

const updateStoryCardImageInfo: Models.Reducer<State.IState, Action.IUpdateStoryCardImageInfo> = (state, action) => {
  const internalImageInfo = Immutable.fromJS(action.payload.internalImageInfo) as DeepIMap<Models.ImageInternalInfo>;
  const { storyCardId, id } = action.payload;

  const documentsKey = state.findKey(documents => documents.has(storyCardId)) as DocumentsKey;

  // need to update the same image in the project because can be the situation
  // when Story Card has been imported earlier than the image's info has been loaded
  const storyCardImageNumber = state.getIn([documentsKey, storyCardId, 'documents', id, 'number']) as string;
  const assetImageId = state.get(DocumentsKey.IMAGES).findKey(document => document.get('number') === storyCardImageNumber);
  const storyCard = state.getIn([documentsKey, storyCardId]).updateIn(['documents', id], (image: Models.ImageMap) => (
    image.set('_internalInfo', internalImageInfo)
  ));

  const updatedState = state.setIn([documentsKey, storyCardId], storyCard);

  if (assetImageId) {
    const assetImage = state
      .get(DocumentsKey.IMAGES)
      .get(assetImageId)
      .setIn([assetImageId, '_internalInfo'], internalImageInfo);

    return updatedState.setIn([DocumentsKey.IMAGES, assetImageId], assetImage);
  }

  return updatedState;
};

const updateStoryCardLayoutThumbnail: Models.Reducer<State.IState, Action.IUpdateStoryCardLayoutThumbnail> = (state, action) => {
  const { storyCardId, id, thumbnailInternalInfo } = action.payload;
  const { source, height, width } = thumbnailInternalInfo || {} as Models.ImageInternalInfo;
  const documentsKey = state.findKey(documents => documents.has(storyCardId)) as DocumentsKey;
  const storyCard = state.getIn([documentsKey, storyCardId]).updateIn(['documents', id], layoutDocument => (
    layoutDocument.withMutations((tempLayoutDocument) => {
      tempLayoutDocument.setIn(['_thumbnailUrl'], source);
      tempLayoutDocument.setIn(['_thumbnailHeight'], height);
      tempLayoutDocument.setIn(['_thumbnailWidth'], width);
    })
  ));

  return state.setIn([documentsKey, storyCardId], storyCard);
};

const setAssetsCollectionData: Models.Reducer<State.IState, Action.ISetAssetsCollectionData> = (state, action) => {
  const { id, entityType, documents, entities } = action.payload;
  const key = DocumentsKeyByEntityType[entityType];

  return state.withMutations((tempState) => {
    documents && tempState.setIn([key, id, 'documents'], Immutable.fromJS(documents));
    entities && tempState.setIn([key, id, 'entities'], Immutable.fromJS(entities));
  });
};

const updateDocumentSource: Models.Reducer<State.IState, Action.IUpdateDocumentSource> = (state, action) => {
  const { id, entityType, documentSource } = action.payload;
  const key = DocumentsKeyByEntityType[entityType];

  return state.setIn([key, id, 'documentSource'], Immutable.fromJS(documentSource));
};

const updateLayoutDocumentThumbnailUrl: Models.Reducer<State.IState, Action.IUpdateLayoutDocumentThumbnailUrl> = (state, action) => {
  const { id, entityType, thumbnailInternalInfo } = action.payload;
  const { source, height, width } = thumbnailInternalInfo || {} as Models.ImageInternalInfo;
  const key = DocumentsKeyByEntityType[entityType];

  return state.getIn([key, id])
    ? state.withMutations((tempState) => {
      tempState.setIn([key, id, '_thumbnailUrl'], source);
      tempState.setIn([key, id, '_thumbnailHeight'], height);
      tempState.setIn([key, id, '_thumbnailWidth'], width);
    })
    : state;
};

export default documentsReducer;
