import { Padding } from 'models';
import { DefaultCustomStyle } from './Draft';
import { DefaultLineHeight } from './LineHeight';
import { DefaultTextAlignment } from './TextAlignment';

// TODO: [#defaultStyles] we need to move all default styles to 1 place
export const DefaultOpacity = 100;
export const DefaultTextBrandStyle = 'body_copy';

export const DefaultTextPadding: Padding = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const DefaultCallToActionBorderRadius = 5;

export const DefaultReferenceCitationElementPadding: Padding = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const DefaultReferenceCitationElementBrandStyleId = 'reference';

export const DefaultReferenceCitationElementLayoutStyles = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

export const ListItemByDepth = {
  0: {
    marginLeft: '21px',
    listStyleType: 'disc',
  },
  1: {
    marginLeft: '42px',
    listStyleType: 'circle',
  },
  2: {
    marginLeft: '63px',
    listStyleType: 'square',
  },
  3: {
    marginLeft: '84px',
    listStyleType: 'square',
  },
  4: {
    marginLeft: '105px',
    listStyleType: 'square',
  },
};

export const DefaultTextStyles = {
  lineHeight: DefaultLineHeight,
  textAlign: DefaultTextAlignment,
  bulletColor: DefaultCustomStyle.FONT_COLOR,
  fontColor: DefaultCustomStyle.FONT_COLOR,
  fontSize: DefaultCustomStyle.FONT_SIZE,
  fontFamily: DefaultCustomStyle.FONT_FAMILY,
};
