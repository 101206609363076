import { LayoutType } from 'const';
import * as Models from 'models';
import { getValue } from '../getter';

export const isSpacerElement = (
  layout: Models.CombinedLayoutMap | Models.LayeredCombinedLayoutMap | Models.CombinedLayout | Models.LayeredCombinedLayout,
): boolean => {
  if (!layout) {
    return false;
  }

  return getValue(layout as Models.CombinedLayout, 'type') === LayoutType.SPACER;
};
