import { fromJS } from 'immutable';
import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/Border';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { DefaultBorderColor } from 'const';
import { BorderMap } from 'models';
import { getBackgroundColor } from 'utils/styles';
import { BorderProps } from './models';
import styles from './styles.module.scss';

const defaultBorder: BorderMap = fromJS({
  color: DefaultBorderColor,
  width: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});

const Border: React.FunctionComponent<BorderProps> = (props) => {
  const { border, toggleBorder, controlState = ControlState, className, returnFocusToEditor } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState, closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.BORDER}
      type={IconType.TOOLBAR_BORDER}
      className={className}
    >
      {isOpen && <Dropdown
        border={border || defaultBorder}
        toggleBorder={toggleBorder}
      />}
      <div className={styles.Border} style={getBackgroundColor(border?.get('color'), DefaultBorderColor)}/>
    </Control>
  );
};

export default Border;
