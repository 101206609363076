import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { setLastEditedLayoutId, updateDragHotspotGroupLayoutId, updateDragHotspotPosition } from 'containers/App/actions';
import { dragHotspotGroupLayoutId, dragHotspotPosition } from 'containers/App/selectors';
import { setArtboards } from 'containers/Artboards/actions';
import { activeArtboard, artboards as artboardsSelector } from 'containers/Artboards/selectors';
import { setLayouts } from 'containers/Layouts/actions';
import { layouts as layoutsSelector, orderedActiveLayoutIds } from 'containers/Layouts/selectors';
import { activeLayer, projectType as projectTypeSelector } from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { screenDefinitions as screenDefinitionsSelector, sectionsWidthByName as sectionsWidthSelector } from 'containers/ScreenDefinitions/selectors';
import { setSections } from 'containers/Sections/actions';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import * as surfacesSelectors from 'containers/Surfaces/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { ensureLayoutOnSections } from 'utils/layouts/ensureLayoutOnSections';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { updateGroupLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';
import * as LayoutsModels from '../models';

function adjustPlainLayout(layout: Models.Layout, section: string): void {
  layout.section = section;
}

function adjustGroupLayout(
  groupLayout: Models.GroupLayout,
  layouts: Models.CombinedLayouts,
  section: string,
  sectionWidth: number,
  relations: Models.LayeredRelations,
): void {
  const childLayouts = getFlattenedLayouts(groupLayout, layouts, true);

  _.each(childLayouts, childLayout => adjustPlainLayout(childLayout, section, sectionWidth, relations));
  groupLayout.section = section;
}

export function* moveLayout(action: LayoutsModels.Action.IMoveLayout) {
  try {
    const { layoutId, section: targetSection } = action.payload;

    const artboard = (yield select(activeArtboard)).toJS() as Models.Artboard;
    let layouts = (yield select(layoutsSelector)).toJS() as Models.CombinedLayouts;
    let targetPosition: ReturnTypeSaga<typeof dragHotspotPosition> = yield select(dragHotspotPosition);
    const targetGroupLayoutId: ReturnTypeSaga<typeof dragHotspotGroupLayoutId> = yield select(dragHotspotGroupLayoutId);
    const sectionsMap: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
    const sectionsWidth: ReturnTypeSaga<typeof sectionsWidthSelector> = yield select(sectionsWidthSelector);
    const targetSectionWidth = sectionsWidth.get(sectionsMap.getIn([targetSection, 'name']));
    const orderedLayoutIds: ReturnType<typeof orderedActiveLayoutIds> = yield select(orderedActiveLayoutIds);
    const relations = (yield select(relationsSelector)).toJS() as Models.LayeredRelations;

    const layout = layouts[layoutId];
    const currentSection = layout.section;
    const sourceGroupLayout = _.find(layouts, layout => (
      isGroupLayout(layout) && layout.layoutIds.includes(layoutId)
    )) as Models.GroupLayout;
    const sourceGroupLayoutId = _.get(sourceGroupLayout, 'id');
    const currentPosition = (_.get(sourceGroupLayout, 'layoutIds') || orderedLayoutIds).indexOf(layoutId);

    if (
      currentSection === targetSection &&
      sourceGroupLayoutId === targetGroupLayoutId &&
      (currentPosition === targetPosition || currentPosition + 1 === targetPosition)
    ) {
      return;
    }

    const moveTarget = layouts[targetGroupLayoutId] as Models.GroupLayout || artboard;
    const moveSource = sourceGroupLayout || artboard;

    if (moveTarget === moveSource && targetPosition > currentPosition) {
      targetPosition -= 1;
    }

    // TBC: ignore movement of RL from one GRL to another GRL until it will be discased with BA
    if (isGroupLayout(moveTarget) && isGroupLayout(moveSource) && moveSource !== moveTarget) {
      return;
    }

    isGroupLayout(layout)
      ? adjustGroupLayout(layout, layouts, targetSection, targetSectionWidth, relations)
      : adjustPlainLayout(layout, targetSection, targetSectionWidth, relations);

    // we update the artboard layoutIds to ensure that they are in the right order
    artboard.layoutIds = orderedLayoutIds.toJS();

    // if we move RL inside GRL just need to swap that RLs
    if (isGroupLayout(moveTarget) && moveTarget === moveSource) {
      [
        moveTarget.layoutIds[currentPosition],
        moveTarget.layoutIds[targetPosition],
      ] = [
        moveTarget.layoutIds[targetPosition],
        moveTarget.layoutIds[currentPosition],
      ];

      layouts = updateGroupLayoutInstances(moveTarget, layouts);
    } else {
      moveSource.layoutIds.splice(currentPosition, 1);
      layouts = isGroupLayout(moveSource) ? updateGroupLayoutInstances(moveSource, layouts) : layouts;

      moveTarget.layoutIds.splice(targetPosition, 0, layoutId);
      layouts = isGroupLayout(moveTarget) ? updateGroupLayoutInstances(moveTarget, layouts) : layouts;
    }

    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);
    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const screenDefinitions = (yield select(screenDefinitionsSelector)).toJS() as Models.MasterScreen.ScreenDefinitions;
    const sections = sectionsMap.toJS() as Models.Sections;
    const artboards = (yield select(artboardsSelector)).toJS() as Models.Artboards;
    artboards[artboard.id] = artboard;
    const screens = (yield select(surfacesSelectors.surfaces)).toJS() as Models.Screens;

    yield call<typeof ensureLayoutOnSections>(
      ensureLayoutOnSections,
      {
        screens,
        artboards,
        layouts,
        sections,
        screenDefinitions,
        relations,
        activeLayer: layer,
        projectType,
      },
    );

    yield put(batchActions([
      saveAppState(),
      updateDragHotspotPosition(null),
      updateDragHotspotGroupLayoutId(null),
      // since we ignore movement from one GRL to another GRL, can be only one edited GRL after move operation
      setLastEditedLayoutId(targetGroupLayoutId || sourceGroupLayoutId),
      setLayouts(layouts),
      setArtboards(artboards),
      setRelations(relations),
      setSections(sections),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.moveLayout', 'MoveLayout');
  }
}
