import { call } from 'redux-saga/effects';

import { processSyncedAssets } from 'containers/Common/services/processSyncedAssets';
import { UploadProjectFlowArg } from '../models';
import { syncProjectAssets } from './syncProjectAssets';

export function* syncAssets(arg: UploadProjectFlowArg): Generator<unknown, UploadProjectFlowArg> {
  const { projectAssets, masterScreenData, actionsWithMiddleware, isProjectTranslatable } = arg;
  const { documents, notUpdatedLayoutIds }: ReturnTypeSaga<typeof syncProjectAssets> = yield call(syncProjectAssets, arg);

  const {
    assets,
    prevAssets,
  }: ReturnTypeSaga<typeof processSyncedAssets> = yield call(
    processSyncedAssets,
    documents,
    masterScreenData,
    actionsWithMiddleware,
    isProjectTranslatable,
    projectAssets,
  );

  arg.notUpdatedLayoutIds = notUpdatedLayoutIds;
  arg.prevAssets = prevAssets;
  arg.projectAssets = assets;
}
