import _ from 'lodash';
import React from 'react';

import NumericInput from 'components/NumericInput';
import { FontSizeLimit, MultiCustomStyle } from 'const';
import { useFontSize } from './hooks';
import { FontSizeProps } from './models';
import styles from './styles.module.scss';

const FontSize: React.FunctionComponent<FontSizeProps> = (props) => {
  const { container, fontSize, toggleFontSize, manual, toggleManual } = useFontSize(props);

  const [fontSizeState, setFontSize] = React.useState(fontSize);
  const applyFontSize = () => {
    const newValue = _.clamp(fontSizeState, FontSizeLimit.MIN, FontSizeLimit.MAX);
    setFontSize(newValue);
    toggleFontSize(newValue);
    toggleManual();
  };

  const onChangeSize = (value: number) => {
    const newValue = _.clamp(value, FontSizeLimit.MIN, FontSizeLimit.MAX);
    setFontSize(newValue);
    toggleFontSize(newValue);
  };

  React.useEffect(
    () => {
      setFontSize(fontSize);
    },
    [fontSize],
  );

  return (
    <div className={styles.FontSize} ref={container}>
      {
        manual
          ?
          <NumericInput
            className={styles.input}
            autoFocus={true}
            value={fontSizeState}
            onChange={setFontSize}
            onEnterPress={applyFontSize}
            showButtonControls={false}
            maxLength={2}
            onChangeSize={onChangeSize}
            addEvent={true}
          />
          : <span className={styles.text} onDoubleClick={toggleManual}>{fontSize || MultiCustomStyle.FONT_SIZE}</span>
      }
    </div>
  );
};

export default FontSize;
