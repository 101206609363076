import Draft from 'draft-js';
import { DraftEntity } from 'const';
import * as Models from 'models';

export function removeAbbreviationFromEditorStateIfTermChanged(
  editorState: Draft.EditorState,
  textAbbreviationDocuments: Models.TextAbbreviationDocumentsArray,
): Draft.EditorState {
  let editorStateCopy = editorState;
  const content = Draft.convertToRaw(editorState.getCurrentContent()) ?? {};
  const oldContentString = JSON.stringify(content);

  for (const entityIndex in content.entityMap) {
    const entity = content.entityMap[entityIndex];
    if (entity.type === DraftEntity.ABBREVIATION) {
      let abbreviationTermInText: string;
      let textBlockWithAbbreviationIndex: number;
      const documentAbbreviationTerm = (textAbbreviationDocuments[entity.data.id as string] as Models.TextAbbreviationDocument)?.abbreviation.term;
      content.blocks.forEach((block, blockIndex) => {
        const entityRange = block.entityRanges?.find(({ key }) => key === Number(entityIndex));
        if (entityRange) {
          abbreviationTermInText = block.text.slice(entityRange.offset, entityRange.offset + entityRange.length);
          textBlockWithAbbreviationIndex = blockIndex;
        }
      });
      if (abbreviationTermInText && abbreviationTermInText?.toLowerCase() !== documentAbbreviationTerm?.toLowerCase()) {
        content.blocks[textBlockWithAbbreviationIndex].entityRanges = content.blocks[textBlockWithAbbreviationIndex]
          .entityRanges.filter(range => range.key.toString() !== entityIndex);
        delete content.entityMap[entityIndex];
      }
    }
  }
  const newContentString = JSON.stringify(content);
  // the cheapest way to compare (potentially) large objects without diggin into details
  if (oldContentString !== newContentString) {
    editorStateCopy = Draft.EditorState.createWithContent(Draft.convertFromRaw(content));
  }

  return editorStateCopy;
}
