import * as Models from 'models';
import { validateScreenDefinitions } from 'services/validateScreenDefinitions';
import { ensureSectionsHeights } from 'utils/screenDefinitions/ensureSectionsHeights';

export const processScreenDefinitions = <T extends Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs>(args: T): T => {
  const { assets, notificationOptions } = args;
  const {
    masterScreenData,
    surfaces: screens,
    artboards,
    sections,
    layouts,
    relations,
    screenDefinitions: usedScreenDefs,
    documents,
  } = assets;
  const {
    screenDefinitions: newScreenDefs,
    placeholderMinHeight,
    projectType,
    helperFileName,
  } = masterScreenData;

  const newScreenDefinitions = ensureSectionsHeights(newScreenDefs);
  const data = validateScreenDefinitions(
    usedScreenDefs,
    newScreenDefinitions,
    screens,
    artboards,
    sections,
    layouts,
    relations,
    projectType,
    documents,
  );

  assets.sections = data.sections;
  assets.relations = data.relations;
  assets.layouts = data.layouts;
  notificationOptions.push(...data.notificationOptions);
  // TODO: need to move to another process/service and create a factory for master screen
  assets.masterScreenData.placeholderMinHeight = placeholderMinHeight || null;
  assets.masterScreenData.projectType = projectType || null;
  assets.masterScreenData.helperFileName = helperFileName || null;

  return args;
};
