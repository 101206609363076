import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const DesktopScreen = (): JSX.Element => {
  const id = guid();

  return (
    <svg viewBox="0 0 42 34" width="42px" height="34px">
      <defs>
        <path d="M44.6584979,31.3285014 L6.60775205,31.3285014 L6.60775205,14.0441732 C6.60775205,13.3966913 6.77365138,12.9363871 7.11220174,12.6384308 C7.52887911,12.2726704 8.17704391,12.1924514 8.57443066,12.1924514 C8.67763547,12.1924514 8.76444325,12.1981814 8.82617323,12.2058213 C8.86186088,12.2096412 8.89754852,12.2125062 8.93323617,12.2125062 L42.3330138,12.2125062 C42.3687015,12.2125062 42.4043891,12.2096412 42.4400768,12.2058213 C42.4516511,12.2048663 43.523245,12.0912227 44.1482611,12.6336559 C44.4916341,12.9316122 44.6584979,13.3938264 44.6584979,14.0441732 L44.6584979,31.3285014 Z M24.7679407,33.0455698 C25.2453836,32.5728508 26.0208664,32.5728508 26.4983093,33.0455698 C26.9757521,33.5192439 26.9757521,34.2860993 26.4983093,34.7588183 C26.0208664,35.2315374 25.2453836,35.2315374 24.7679407,34.7588183 C24.2904979,34.2860993 24.2904979,33.5192439 24.7679407,33.0455698 L24.7679407,33.0455698 Z M46.297236,33.7408011 L46.297236,13.3986013 C46.297236,12.2678955 45.937466,11.3826216 45.2285357,10.7676093 C44.1347576,9.8183512 42.6378056,9.9062101 42.2857518,9.94249965 L8.98049818,9.94249965 C8.62844439,9.9062101 7.13149236,9.8183512 6.03771426,10.7676093 C5.32878402,11.3826216 4.96901397,12.2678955 4.96901397,13.3986013 L4.96901397,33.7408011 C4.94875882,34.0483073 4.89956774,35.5972978 5.85445338,36.622 C6.37433556,37.179713 7.09966284,37.4757593 7.95327273,37.4757593 L21.5763078,37.4757593 L21.5763078,41.3749754 L19.6665366,41.3749754 L17.9786074,41.3749754 L17.9786074,42.9975 L19.6665366,42.9975 L31.8302363,42.9975 L33.2876426,42.9975 L33.2876426,41.3749754 L31.8302363,41.3749754 L29.684155,41.3749754 L29.684155,37.4757593 L43.3120127,37.4757593 C44.1665872,37.4757593 44.892879,37.179713 45.4117966,36.622 C46.3666823,35.5972978 46.3174912,34.0483073 46.297236,33.7408011 L46.297236,33.7408011 Z" id="desktop-screen-id-1" />
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Navigate-Card" transform="translate(-1365.000000, -130.000000)">
          <g id="content">
            <g transform="translate(0.000000, 28.000000)">
              <g id="bottom" transform="translate(46.743243, 92.000000)">
                <g id="language" transform="translate(1283.000000, 0.000000)">
                  <g id="3.-Icons/ic_iMac" transform="translate(30.845457, 0.700000)">
                    <mask id={id} fill="white">
                      <use xlinkHref="#desktop-screen-id-1" />
                    </mask>
                    <g id="Icon" />
                    <g id="5.-Colors/Orange" mask={`url(#${id})`} fill="#F9AD3D" fillRule="evenodd">
                      <rect id="Orange" x="0" y="0" width="54.023112" height="54.023112" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DesktopScreen;
