function prepareValueForSearch(value: string): string {
  return value.trim().toLocaleLowerCase().replace(/\s+/g, ' ');
}

/**
 * Search value in list according to abbreviation rules (trim and lowercase)
 * @param valueToSearch term or definition
 * @param list list of available terms or definitions
 * @returns boolean
 */
export function isExistsTermOrDefinition(valueToSearch: string, list: string[]): boolean {
  const preparedSearch = prepareValueForSearch(valueToSearch);

  return list.some(value => prepareValueForSearch(value) === preparedSearch);
}
