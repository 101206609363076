import { IList, IMap } from 'typings/DeepIMap';
import { AssetAlignmentType } from 'const';
import { UndoRedoMiddleware } from 'hooks/useUndoRedo';
import { AssetAlignment, BackgroundImage, BackgroundImageMap, Border, BoxProperty, BrandColor, BrandColorGradientMap } from 'models';
import { ImageStylesSetters } from './styles';

export type ImageStack = {
  cellHeight: number;
  cellWidth: number;
  height: number;
  width: number;
  isAutoFitContent: boolean;
  alignment: IMap<AssetAlignment<AssetAlignmentType>>;
  altTag: string;
  backgroundColor?: IMap<BrandColor>;
  backgroundColorOpacity: number;
  backgroundImage?: IMap<BackgroundImage>;
  backgroundGradient: [BrandColorGradientMap | undefined, IMap<BrandColor> | undefined];
  border?: IMap<Border<BrandColor>>;
  borderRadius?: IMap<BoxProperty>;
  link: string;
  mobileAlignment: IMap<AssetAlignment<AssetAlignmentType>>;
  mobileScale: number;
  mobileSettingsApplied: boolean;
  mobileViewImage: BackgroundImageMap;
  padding: IMap<BoxProperty>;
  scale: number;
  abbreviations: IList<string>;
};

export function wrapUndoMiddleware(
  middleware: UndoRedoMiddleware<ImageStack>,
  setters: ImageStylesSetters,
): ImageStylesSetters {
  return {
    ...setters,
    alignment: middleware(setters.alignment, 'alignment'),
    altTag: middleware(setters.altTag, 'altTag'),
    backgroundColor: middleware(setters.backgroundColor, 'backgroundColor'),
    backgroundColorOpacity: middleware(setters.backgroundColorOpacity, 'backgroundColorOpacity'),
    backgroundGradient: middleware(setters.backgroundGradient, 'backgroundGradient'),
    backgroundImage: middleware(setters.backgroundImage, 'backgroundImage'),
    border: middleware(setters.border, 'border'),
    borderRadius: middleware(setters.borderRadius, 'borderRadius'),
    height: middleware(setters.height, 'height'),
    link: middleware(setters.link, 'link'),
    mobileAlignment: middleware(setters.mobileAlignment, 'mobileAlignment'),
    mobileScale: middleware(setters.mobileScale, 'mobileScale'),
    mobileSettingsApplied: middleware(setters.mobileSettingsApplied, 'mobileSettingsApplied'),
    mobileViewImage: middleware(setters.mobileViewImage, 'mobileViewImage'),
    padding: middleware(setters.padding, 'padding'),
    scale: middleware(setters.padding, 'scale'),
    width: middleware(setters.width, 'width'),
    abbreviations: middleware(setters.abbreviations, 'abbreviations'),
  };
}
