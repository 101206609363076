import { Effect, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { saveGroupLayout } from './saveGroupLayout';

const effects: Effect[] = [
  takeEvery(ActionTypes.SAVE_GROUP_LAYOUT, saveGroupLayout),
];

export default effects;
