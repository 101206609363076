import { call } from 'redux-saga/effects';

import { ImageInternalInfo } from 'models';
import { handleSagaError } from 'services/handleError';
import { updateInternalImageInfoRequest } from '../actions';
import * as Models from '../models';
import { updateImagesPreviews } from '../services/updateImagesPreviews';

export function* getImagesAdditionalInfo(action: Models.Action.IGetImagesInfo) {
  try {
    const { documents } = action.payload;

    yield call(
      updateImagesPreviews,
      documents,
      (id: string, imageInternalInfo: ImageInternalInfo) => updateInternalImageInfoRequest(id, imageInternalInfo),
    );
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.getImagesAdditionalInfo', 'DownloadLowRenditions');
  }
}
