import Immutable from 'immutable';

import * as Models from 'models';
import { adjustRelationsToParticularLayout } from 'utils/relations/adjustRelationsToParticularLayout';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';

function getColumnsWidthFromRelations(
  relations: Models.LayeredRelationsMap,
): Immutable.Map<string, Immutable.List<number>> {
  return relations.filter(isRowRelation).map(relation => relation.getIn(['styles', 'columnsWidth']));
}

export function getRowsHeightFromRelations(
  relations: Models.LayeredRelationsMap,
): Immutable.Map<string, Immutable.List<number>> {
  return relations.filter(isColumnRelation).map(relation => relation.getIn(['styles', 'rowsHeight']));
}

export function getAdjustedColumnsWidth(
  allRelations: Models.LayeredRelationsMap,
  layout: Models.LayoutMap,
  ssi: Models.SSIMap,
  sectionsWidth: Models.MasterScreen.SectionsWidthMap,
  sectionWidth: number,
): Immutable.Map<string, Immutable.List<number>> {
  const adjustedRelations = adjustRelationsToParticularLayout(
    allRelations,
    layout,
    ssi,
    sectionsWidth,
    sectionWidth,
  );

  return getColumnsWidthFromRelations(adjustedRelations);
}

export function setColumnsWidthToRelations(
  columnsWidthByRelationId: Immutable.Map<string, Immutable.List<number>>,
  relations: Models.LayeredRelationsMap,
): Models.LayeredRelationsMap {
  return relations.map((relation, id) => columnsWidthByRelationId.get(id)
    ? relation.setIn(['styles', 'columnsWidth'], columnsWidthByRelationId.get(id))
    : relation,
  );
}

export function setRowsHeightToRelations(
  rowsHeightByRelationId: Immutable.Map<string, Immutable.List<number>>,
  relations: Models.LayeredRelationsMap,
): Models.LayeredRelationsMap {
  return relations.map((relation, id) => rowsHeightByRelationId.get(id)
    ? relation.updateIn(['styles', 'rowsHeight'], (
      rowsHeight: Models.ColumnRelationStyles['rowsHeight'],
    ) => rowsHeight.map((height, position) => (rowsHeightByRelationId.getIn([id, position]) || height)),
    )
    : relation,
  );
}

