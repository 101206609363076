import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { processLayout } from 'containers/Artboards/services/processLayout';
import {
  projectBrandStyles,
  projectFlatColors,
  projectFlatFonts as projectFlatFontsSelector,
} from 'containers/BrandDefinition/selectors';
import { setDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector, imagesForAssetsPanel } from 'containers/Documents/selectors';
import { setLayout, setLayouts } from 'containers/Layouts/actions';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { deleteLayout } from 'containers/Layouts/services/deleteLayout';
import { getLayoutAssetsByLayoutId } from 'containers/Layouts/services/getLayoutAssetsByLayoutId';
import { Action } from 'containers/ModalWindows/SaveReusableLayout/models';
import { activeLayer } from 'containers/Project/selectors';
import { textComponents } from 'containers/ProjectPanel/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { sections as screenDefinitionSectionsSelector } from 'containers/ScreenDefinitions/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { toImmutable, toJS } from 'utils/immutable';
import { createLayers, makeRelationsLayered } from 'utils/layers';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { getScreenDefinitionSectionStylesBySectionName } from 'utils/screenDefinitions/getScreenDefinitionSectionStylesBySectionName';

export function* unlinkReusableLayout(action: Action.UnlinkReusableLayout): Generator<unknown, void> {
  try {
    const { layoutId } = action.payload;
    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);
    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    const layouts = (yield select(layoutsSelector)).toJS() as Models.CombinedLayouts;
    const relations = (yield select(relationsSelector)).toJS() as Models.LayeredRelations;
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const colors: ReturnTypeSaga<typeof projectFlatColors> = yield select(projectFlatColors);
    const sections: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
    const brandStyles: ReturnTypeSaga<typeof projectBrandStyles> = yield select(projectBrandStyles);
    const fonts: ReturnTypeSaga<typeof projectFlatFontsSelector> = yield select(projectFlatFontsSelector);
    const textsForDuplicatesChecking: ReturnTypeSaga<typeof textComponents> = yield select(textComponents);
    const imagesForDuplicatesChecking: ReturnTypeSaga<typeof imagesForAssetsPanel> = yield select(imagesForAssetsPanel);
    const screenDefinitionSections: ReturnTypeSaga<typeof screenDefinitionSectionsSelector> = yield select(screenDefinitionSectionsSelector);
    const layoutAssetsById: ReturnTypeSaga<typeof getLayoutAssetsByLayoutId> = yield call(getLayoutAssetsByLayoutId, [layoutId]);
    const screenDefinitionSectionStylesBySectionName = getScreenDefinitionSectionStylesBySectionName(screenDefinitionSections);

    const layout = layouts[layoutId] as Models.Layout;
    const layoutAssets = toJS(layoutAssetsById[layoutId] as Models.LayoutAssetsMap);
    const sectionName = sections.getIn([layout.section, 'name']);
    const sectionStyles = screenDefinitionSectionStylesBySectionName.get(sectionName);
    const isReusableLayoutFlag = isReusableLayout(layout, documents);

    const {
      layout: unlinkedLayout,
      relationsToMerge: unlinkedRelations,
      documentsToMerge: unlinkedDocuments,
    }: ReturnTypeSaga<typeof processLayout> = yield call(
      processLayout,
      {
        layout,
        relations: layoutAssets.relations,
        documents: toImmutable(layoutAssets.documents),
        textsForDuplicatesChecking,
        imagesForDuplicatesChecking,
        brandStyles,
        sectionStyles,
        colors,
        fonts,
        shouldLookForDuplicates: false,
        shouldProcessBrandStyles: true,
        isReusableLayoutFlag,
      },
    );

    yield call(
      deleteLayout,
      layout,
      layouts,
      relations,
      documents,
      layer,
    );

    const layeredCopiedLayout: Models.LayeredLayout = { ...unlinkedLayout, documentId: createLayers() };
    const layeredCopiedRelations = makeRelationsLayered(unlinkedRelations, layer);
    const updatedArtboard = artboard.update('layoutIds', layoutIds => (
      layoutIds.map(layoutId => layoutId === layout.id ? unlinkedLayout.id : layoutId)
    ));

    yield put(batchActions([
      updateArtboard(updatedArtboard),
      setLayouts(layouts),
      setLayout(layeredCopiedLayout),
      setRelations({ ...relations, ...layeredCopiedRelations }),
      setDocuments({ ...documents, ...unlinkedDocuments }),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'SaveReusableLayoutWindow.unlinkReusableLayout', 'UnlinkReusableLayout');
  }
}
