import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/FontSize';
import { useControlWithDropdown, useControlWithTooltip } from 'components/Toolbar/hooks';
import FontSize from 'components/Toolbar/Shared/FontSize';
import { FontSizes } from 'const';
import { intlGet } from 'utils/intlGet';
import { FontSizeControlProps } from './models';
import styles from './styles.module.scss';

const FontSizeControl: React.FunctionComponent<FontSizeControlProps> = (props) => {
  const { fontSize, toggleFontSize, controlState = ControlState, returnFocusToEditor } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState, closeDropdownCallback: returnFocusToEditor });
  const { hoverOnControl, hoverOnDropdown, onControlEnter, onDropdownEnter, onControlLeave, onDropdownLeave } = useControlWithTooltip();

  /**
   * Populate Font Size Dropdown with values:
   * 1) Add current fontSize value (can be null) to list of pre-defined values
   * 2) Remove falsy values
   * 3) Remove duplicates
   * 4) Sort in ascending order
   */
  const fontSizes = _(FontSizes).concat(fontSize).compact().uniq().sort((a, b) => a - b).value();

  return (
    <div
      onMouseEnter={onControlEnter}
      onMouseLeave={onControlLeave}
      className={classNames(styles.ControlWrapper, { [styles.ControlWrapperTooltip]: hoverOnControl && !hoverOnDropdown })}
      data-tooltip={intlGet('EditorToolbar.Tooltip', Title.FONT_SIZE)}
    >
      <FontSize fontSize={fontSize} toggleFontSize={toggleFontSize} />
      <Control
        className={styles.FontSize}
        controlState={controlState}
        preventBlur={false}
        ref={control}
        onToggle={toggleDropdown}
        state={state}
        title={Title.FONT_SIZE}
        type={IconType.CHEVRON_DOWN}
        tooltipEnabled={false}
      >
        {
          isOpen &&
          <Dropdown
            activeFontSize={fontSize}
            fontSizes={fontSizes}
            toggleFontSize={toggleFontSize}
            onMouseEnter={onDropdownEnter}
            onMouseLeave={onDropdownLeave}
            onCloseDropdown={toggleDropdown}
          />
        }
      </Control>
    </div>
  );
};

export default FontSizeControl;
