import { DefaultBorderColor } from 'const';
import { BorderMap, BorderWidth } from 'models';
import { colorToValue } from 'utils/converters';
import { getBorderStyleString } from './getBorderStyleString';

export const getBorderCSSProperties = (
  border?: BorderMap,
): Pick<React.CSSProperties, 'borderTop' | 'borderRight' | 'borderBottom' | 'borderLeft'> => {
  if (!border) {
    return {};
  }

  const { top, right, bottom, left } = border.get('width').toJS() as BorderWidth;
  const borderColor = colorToValue(border.get('color'), DefaultBorderColor);
  const borderStyle = 'solid';

  return {
    borderTop: getBorderStyleString(top, borderStyle, borderColor),
    borderRight: getBorderStyleString(right, borderStyle, borderColor),
    borderBottom: getBorderStyleString(bottom, borderStyle, borderColor),
    borderLeft: getBorderStyleString(left, borderStyle, borderColor),
  };
};
