// TODO: keep relation types independent from entity types
export enum EntityType {
  ANCHOR = 'Anchor',
  BRAND_DEFINITION = 'BrandDefinition',
  CALL_TO_ACTION = 'CallToAction',
  COLUMN = 'Column',
  GROUP_LAYOUT = 'GroupLayout',
  IMAGE = 'Image',
  LAYOUT = 'SurfaceLayout',
  LAYOUT_RENDITION = 'LayoutRendition',
  MAGIC_FORM = 'MagicForm',
  MODULE_BUNDLE = 'ModuleBundle',
  MASTER_SURFACE = 'MasterSurface',
  PROJECT_FILE = 'ProjectFile',
  REFERENCE_CITATION = 'ReferenceCitation',
  REFERENCE_CITATION_ELEMENT = 'ReferenceCitationElement',
  ROOT_DOCUMENT = 'RootDocument',
  ROW = 'Row',
  SPACER = 'Spacer',
  SSI_ELEMENT = 'SSIElement',
  STORY_CARD = 'StoryCard',
  TEXT = 'Text',
  TEXT_COLLECTION = 'TextCollection',
  ABBREVIATION_DEFINITION_FILE = 'AbbreviationDefinitionFile',
  ABBREVIATIONS_LIST = 'AbbreviationsList', // mock type (isn't listed in veeva)
}
