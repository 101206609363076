import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ModalManager from 'components/ModalManager';
import { hideModal } from './actions';
import { activeModalIds, modalWindows } from './selectors';

const mapStateToProps = createStructuredSelector({
  activeModalIds,
  modalWindows,
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
