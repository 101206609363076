import { connect } from 'react-redux';

import BaseToolbar from 'components/Toolbar/BaseToolbar';
import { BaseToolbarActions, BaseToolbarOwnProps } from 'components/Toolbar/BaseToolbar/models';
import { setLastEditedLayoutId } from 'containers/App/actions';
import { updateProjectState } from 'containers/Project/actions';

const mapDispatchToProps = {
  setLastEditedLayoutId,
  updateProjectState,
};

export default connect<null, BaseToolbarActions, BaseToolbarOwnProps>(null, mapDispatchToProps)(BaseToolbar);
