import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/Scrollable';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { ScrollableProps } from './models';

const Scrollable: React.FunctionComponent<ScrollableProps> = (props) => {
  const { scrollable, toggleScrollable, controlState = ControlState } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.SCROLLABLE_LAYOUT}
      type={IconType.SCROLLABLE}
    >
      {isOpen && <Dropdown scrollable={scrollable} toggleScrollable={toggleScrollable} />}
    </Control>
  );
};

export default Scrollable;
