import _ from 'lodash';
import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { startHandlingReusableLayoutsEditing, stopHandlingReusableLayoutsEditing } from 'containers/App/actions';
import * as commonSelectors from 'containers/Common/selectors';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { initApp } from 'containers/Common/services/initApp';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { ActionTypes } from 'containers/Layouts/constants';
import * as Models from 'models';
import { refreshLayoutsOnArtboards } from 'services/processes';
import { toJS } from 'utils/immutable';
import { isGroupLayoutAssets } from 'utils/isGroupLayoutAssets';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';

export function* restoreLayoutAssets(
  layoutToRestore: Models.CombinedLayoutMap,
  editedLayout: Models.CombinedLayoutMap,
  prevLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId,
  currentLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId,
  forceRestore = false,
) {
  try {
    if (!layoutToRestore) {
      return;
    }

    const prevLayoutAssets = prevLayoutAssetsByLayoutId[layoutToRestore.get('id')];
    const currentLayoutAssets = currentLayoutAssetsByLayoutId[layoutToRestore.get('id')];

    if (!prevLayoutAssets) {
      return;
    }

    yield put(stopHandlingReusableLayoutsEditing());

    const editedLayoutId = editedLayout.get('id');
    const isSaveAsNewChosen = layoutToRestore.get('id') !== editedLayoutId;

    // have to wait till saving layout will be 'disconnected' from the current document
    if (isSaveAsNewChosen && !forceRestore) {
      const [cancelByTimeout] = yield race([delay(30000), take(ActionTypes.RESTORE_LAYOUT_ASSETS)]);

      // return if save will be failed for some reason
      if (cancelByTimeout) {
        return;
      }
    }

    const projectAssets: ReturnTypeSaga<typeof getProjectAssets> = yield call(getProjectAssets, { removeInternalInfo: false });
    const masterScreenData = (yield select(commonSelectors.masterScreenData)).toJS() as Models.MasterScreenData;
    const assets = _.set<Models.ProjectAssets>(_.cloneDeep(projectAssets), 'masterScreenData', masterScreenData);
    const arg = { assets } as Models.RestoreLayoutAssetsArgs;

    if (isGroupLayoutAssets(prevLayoutAssets) && isGroupLayoutAssets(currentLayoutAssets)) {
      const { usedDocuments } = toJS(prevLayoutAssets);

      _.update(assets, 'documents', documents => ({ ...usedDocuments, ...documents }));
    }

    const { artboards, layouts } = assets;
    const layoutToRestoreDocumentId = layoutToRestore.get('documentId');
    const artboardChildLayouts = _.pick(layouts, _.flatMap(artboards, artboard => artboard.layoutIds));
    const childLayoutsByGroupLayoutId = _(layouts)
      .filter<Models.LayeredGroupLayout>(isGroupLayout)
      .map<[string, Models.LayeredLayout[]]>(layout => [layout.id, layout.layoutIds.map(id => layouts[id] as Models.LayeredLayout)])
      .fromPairs()
      .value();

    const artboardChildLayoutsToRefresh = _.pickBy(artboardChildLayouts, ({ documentId }) => _.includes(documentId, layoutToRestoreDocumentId));

    // if layout is used within artboards and GRLs, all the GRLs were it's used should be refreshed
    const groupLayoutsToRefresh = isPlainLayout(layoutToRestore)
      ?
      _(childLayoutsByGroupLayoutId)
        .pickBy(childLayouts => childLayouts.some(({ documentId }) => _.includes(documentId, layoutToRestoreDocumentId)))
        .mapValues((_, groupLayoutId) => layouts[groupLayoutId])
        .value()
      :
      {};

    arg.layoutsToRefresh = { ...artboardChildLayoutsToRefresh, ...groupLayoutsToRefresh };

    yield call(refreshLayoutsOnArtboards, arg);
    const allDocuments = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    // combine documents after refresh because documents that are being uploaded at the moment are not involved into refresh
    arg.assets.documents = { ...allDocuments, ...arg.assets.documents };

    yield call(initApp, { projectAssets: arg.assets });
  } finally {
    yield put(startHandlingReusableLayoutsEditing());
  }
}
