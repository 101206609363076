import _ from 'lodash';

import { createImageRelation } from 'factories/relationFactory';
import { Relations } from 'models';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

/**
 * Unlinks all relations from the document with passed `id`
 * @param id - id of the document
 * @param relations
 */
export function resetRelationsByDocumentId(id: string, relations: Relations): Relations {
  const updatedRelations = {} as Relations;

  _.forEach(relations, (relation) => {
    if (!isRegularRelation(relation)) {
      return;
    }

    const { documentId, id: relationId } = relation;

    if (documentId !== id) {
      return;
    }

    const updatedRelation = createImageRelation({ id: relationId });
    updatedRelations[updatedRelation.id] = updatedRelation;
  });

  return updatedRelations;
}
