import React from 'react';
/* eslint-disable max-len */

const StoryCard = (): JSX.Element => (
  <svg viewBox="0 0 22 18">
    <path d="M18,9 L16,9 L16,12 L13,12 L13,14 L18,14 L18,9 Z M6,6 L9,6 L9,4 L4,4 L4,9 L6,9 L6,6 Z M20,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L20,18 C21.1,18 22,17.1 22,16 L22,2 C22,0.9 21.1,0 20,0 Z M20,16.01 L2,16.01 L2,1.99 L20,1.99 L20,16.01 L20,16.01 Z" />
  </svg>
);

export default StoryCard;
