import _ from 'lodash';

import { removeAbbreviationEntities } from 'modules/Abbreviations/utils/removeAbbreviationEntities';
import { DraftEntity, Layer } from 'const';
import * as Models from 'models';
import * as processes from 'services/processes';
import { isMockDocument } from 'utils/documents';
import { isCallToAction, isImage, isTextComponent } from 'utils/entityType';
import { moveToLayer } from 'utils/layers';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { UploadProjectFlowArg } from '../models';

export function completeTranslation(arg: UploadProjectFlowArg): UploadProjectFlowArg {
  const { projectAssets, masterScreenData, isProjectTranslatable, translationComplete } = arg;

  if (!isProjectTranslatable || !translationComplete) {
    return arg;
  }

  const { documents, relations, layouts } = projectAssets;

  const prioritizedRelations: Record<string, Models.LayeredRelation>
   = _.mapValues(relations, (relation: Models.LayeredRelation) => {
     if (!isRegularRelation(relation)) {
       return relation;
     }

     const { documentId, styles } = relation;
     const priorityLayer = getPriorityLayerForEntity(relation, documents);

     return _(relation)
       .set('styles', moveToLayer(styles, Layer.ORIGINAL, priorityLayer))
       .set('documentId', moveToLayer(documentId, Layer.ORIGINAL, priorityLayer))
       .value();
   });

  const prioritizedLayouts = _.mapValues(layouts, (layout: Models.LayeredCombinedLayout) => {
    const priorityLayer = getPriorityLayerForEntity(layout, documents);

    return _.set(layout, 'documentId', moveToLayer(layout.documentId, Layer.ORIGINAL, priorityLayer));
  });

  const dataToProcess: Models.SetActiveLayerArgs = {
    assets: {
      ...projectAssets,
      relations: prioritizedRelations,
      layouts: prioritizedLayouts,
      masterScreenData,
    },
    isProjectTranslatable: false,
    layerToCleanUp: Layer.ORIGINAL,
  };

  _.set(dataToProcess, 'assets.project.activeLayer', Layer.ORIGINAL);

  processes.resetRelationsWithoutDocument(dataToProcess);
  processes.refreshLayoutsOnArtboards(dataToProcess);

  const { assets: updatedAssets } = dataToProcess;

  for (const id in updatedAssets.documents) {
    const { country: targetCountries, language: [targetLangauge] } = updatedAssets.rootDocument;
    const currentDocument = updatedAssets.documents[id] as Models.TextComponent | Models.CallToAction;
    const { language: [currentLanguage], country: currentDocumentCountries, rawContent } = currentDocument;
    const isTargetDocument =
      Boolean(
        _.intersection(currentDocumentCountries, targetCountries).length) && currentLanguage === targetLangauge;
    const isTextOrCTA = isTextComponent(currentDocument) || isCallToAction(currentDocument);

    if (isTargetDocument) {
      continue;
    }

    if (isTextOrCTA && rawContent) {
      const content: Draft.RawDraftContentState = JSON.parse(rawContent);
      for (const entityIndex in content.entityMap) {
        if (content.entityMap[entityIndex].type === DraftEntity.ABBREVIATION) {
          removeAbbreviationEntities(content, entityIndex);
        }
      }
      (updatedAssets.documents[id] as Models.TextComponent | Models.CallToAction).rawContent = JSON.stringify(content);
    }

    if (isImage(currentDocument as unknown as Models.Image) && updatedAssets.relations !== undefined) {
      for (const relationId in updatedAssets.relations) {
        const relation = updatedAssets.relations as unknown as Models.LayeredRelationsMap;
        const { documentId } = relation[relationId];
        if (documentId?.original === currentDocument.id) {
          _.set(updatedAssets.relations[relationId], 'styles.original.abbreviations', []);
        }
      }
    }
  }

  arg.projectAssets = {
    ...projectAssets,
    documents: updatedAssets.documents,
    relations: updatedAssets.relations,
    layouts: updatedAssets.layouts,
    sections: updatedAssets.sections,
    artboards: updatedAssets.artboards,
  };
  arg.projectAssets.project.activeLayer = Layer.ORIGINAL;
  arg.projectAssets.project.suppressTranslation = true;
  arg.isProjectTranslatable = false;

  return arg;
}

function getPriorityLayerForEntity<T extends { documentId: Models.Layers<string> }>(entity: T, documents: Models.CombinedDocuments): Layer {
  const translatedDocumentId = entity.documentId[Layer.TRANSLATED];
  const translatedDocument = translatedDocumentId && documents[translatedDocumentId];

  return !translatedDocument || isMockDocument(translatedDocument)
    ? Layer.ORIGINAL
    : Layer.TRANSLATED;
}
