import Draft from 'draft-js';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as editorUtils from 'utils/editor';

type Handler = (event: React.SyntheticEvent<unknown, KeyboardEvent>) => void;

export function useOnTab(
  editorState: Draft.EditorState,
  setEditorState: (state: Draft.EditorState) => void,
): Handler {
  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  return useCallback<Handler>((event): void => {
    const state = editorStateRef.current;
    const newEditorState = editorUtils.adjustListBlockDepth(event, state, Constants.LIST_MAX_DEPTH);
    if (newEditorState !== state) {
      setEditorState(newEditorState);
    }
  }, [setEditorState]);
}
