import { Effect, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { generateLayoutPreview } from './generateLayoutPreview';
import { saveReusableLayout } from './saveReusableLayout';
import { unlinkReusableLayout } from './unlinkReusableLayout';

const effects: Effect[] = [
  takeEvery(ActionTypes.GENERATE_LAYOUT_PREVIEW, generateLayoutPreview),
  takeEvery(ActionTypes.SAVE_LAYOUT, saveReusableLayout),
  takeEvery(ActionTypes.UNLINK_REUSABLE_LAYOUT, unlinkReusableLayout),
];

export default effects;
