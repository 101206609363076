import React from 'react';

import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlMap } from 'components/Toolbar/Controls/TextHorizontalAlignment/constants';
import Dropdown from 'components/Toolbar/Controls/TextHorizontalAlignment/Dropdown';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { TextHorizontalAlignmentProps } from './models';

const TextHorizontalAlignment: React.FunctionComponent<TextHorizontalAlignmentProps> = (props) => {
  const { alignment, toggleAlignment } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown();

  const { icon } = ControlMap[alignment];

  return (
    <Control
      onToggle={toggleDropdown}
      ref={control}
      state={state}
      title={Title.TEXT_ALIGNMENT_HORIZONTAL}
      type={icon}
    >
      {isOpen && <Dropdown alignment={alignment} toggleAlignment={toggleAlignment} />}
    </Control>
  );
};

export default TextHorizontalAlignment;
