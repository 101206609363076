import React from 'react';

import { Title } from 'components/Toolbar/constants';
import { IconMap } from 'components/Toolbar/Controls/AssetAlignment/constants';
import Dropdown from 'components/Toolbar/Controls/AssetAlignment/Dropdown';
import Control from 'components/Toolbar/Controls/Control';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { AssetAlignmentDirection } from 'const';
import { AssetAlignmentProps } from './models';

const AssetAlignment: React.FunctionComponent<AssetAlignmentProps> = (props) => {
  const { alignment } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown();

  return (
    <Control
      onToggle={toggleDropdown}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.ASSET_ALIGNMENT_GROUP}
      type={IconMap[alignment.get(AssetAlignmentDirection.HORIZONTAL)][alignment.get(AssetAlignmentDirection.VERTICAL)]}
    >
      {isOpen && <Dropdown {...props} />}
    </Control>
  );
};

export default AssetAlignment;
