import React from 'react';
import { DragLayer as CustomDragLayer, DragLayerCollector } from 'react-dnd';

import { DragSourceType, EntityType } from 'const';
import * as Models from 'models';
import { DragLayerCollectedProps } from './models';
import AnchorDragPreview from './Previews/Anchor';
import CitationItemDragPreview from './Previews/CitationItem';
import ImageListItemDragPreview from './Previews/ImageListItem';
import LayoutListItemDragPreview from './Previews/LayoutListItem';
import LayoutListItemRenditionDragPreview from './Previews/LayoutListItemRendition';
import SSIElementDragPreview from './Previews/SSIElement';
import StoryCardDragPreview from './Previews/StoryCard';
import TextComponentItemDragPreview from './Previews/TextComponentItem';
import styles from './styles.module.scss';

class DragLayer extends React.Component<DragLayerCollectedProps> {
  private getItemStyles = (): React.CSSProperties => {
    const { currentOffset } = this.props;
    if (!currentOffset) {
      return {
        display: 'none',
      };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  };

  private renderDragItemPreview = (): JSX.Element => {
    switch (this.props.itemType) {
      case DragSourceType.ASSET: return this.renderAssetPreview();
      case DragSourceType.ARTBOARD_SSI: return <SSIElementDragPreview />;
      case DragSourceType.STORY_CARD: return this.renderStoryCardPreview();
      case DragSourceType.GROUP_LAYOUT:
      case DragSourceType.REUSABLE_LAYOUT: return this.renderReusableLayoutPreview();
      // define custom drag preview for any drag source here, e.g.
      // case DragSourceType.ARTBOARD_LAYOUT: return <ArtboardLayoutDragPreview dragItem={this.props.item} />;
      default: return null;
    }
  };

  private renderAssetPreview = (): JSX.Element => {
    const { type, component } = this.props.item as Models.AssetDragComponent;

    switch (type) {
      case EntityType.ANCHOR: return <AnchorDragPreview />;
      case EntityType.TEXT: return <TextComponentItemDragPreview />;
      case EntityType.REFERENCE_CITATION: return <CitationItemDragPreview />;
      case EntityType.IMAGE: {
        const internalInfo = (component as Models.ImageMap).get('_internalInfo');
        const imageSrc = internalInfo && internalInfo.get('source');

        return <ImageListItemDragPreview imageSrc={imageSrc} />;
      }
      case EntityType.LAYOUT_RENDITION: {
        const imageSrc = (component as Models.ReusableLayoutMap).get('_thumbnailUrl');

        return <LayoutListItemRenditionDragPreview imageSrc={imageSrc}/>;
      }
      default: return null;
    }
  };

  private renderStoryCardPreview = (): JSX.Element => {
    const { screenName, previewUrl } = this.props.item as Models.StoryCardDragObject;

    return <StoryCardDragPreview screenName={screenName} previewUrl={previewUrl} />;
  };

  private renderReusableLayoutPreview = (): JSX.Element => {
    const { document } = this.props.item as Models.LayoutDragObject;

    return <LayoutListItemDragPreview document={document} thumbnailUrl={document.get('_thumbnailUrl')} />;
  };

  render() {
    const { isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div className={styles.DragLayer}>
        <div style={this.getItemStyles()}>{this.renderDragItemPreview()}</div>
      </div>
    );
  }
}

const collect: DragLayerCollector<void, DragLayerCollectedProps> = monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType() as DragSourceType,
  currentOffset: monitor.getClientOffset(),
  isDragging: monitor.isDragging(),
});

export default CustomDragLayer(collect)(DragLayer);
