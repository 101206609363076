import { FloatingArrow, FloatingPortal, useMergeRefs } from '@floating-ui/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import * as ProjectSelectors from 'containers/Project/selectors';
import { useFloatingWrapper } from '../hooks/useFloatingWrapper';
import css from '../styles.module.scss';
import { AbbreviationWithRelation } from '../types';
import Panel from './Panel';

type Props = {
  abbreviationsList: AbbreviationWithRelation[];
};

const Container: React.FunctionComponent<Props> = (props) => {
  const { abbreviationsList } = props;

  const disabled = useSelector(ProjectSelectors.isOpenToolbar);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(val => !val), []);
  const toggleOff = useCallback(() => setIsOpen(false), []);

  const buttonRef = useRef<HTMLElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() =>{
    if (!isOpen || !popupRef.current) {
      return;
    }

    const popup = popupRef.current;
    const button = buttonRef.current;
    const listener: MouseEventHandler = (event) => {
      const target = event.target as HTMLElement;
      if (target && !popup.contains(target) && !button?.contains(target)) {
        toggleOff();
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('dragstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('dragstart', listener);
    };
  }, [popupRef.current, isOpen]);

  const { refs, floatingStyles, arrowRef, context } = useFloatingWrapper({ isOpen, setIsOpen });

  const buttonMergedRef = useMergeRefs([refs.setReference, buttonRef]);
  const popupMergedRef = useMergeRefs([refs.setFloating, popupRef]);

  return (
    <div className={css.wrapper}>
      <div
        className={classNames(css.container, { active: isOpen })}
      >
        <button
          className={classNames(css.button, { active: isOpen, disabled })}
          ref={buttonMergedRef}
          onClick={disabled ? _.noop : toggle}
        >
          <Icon
            color="inherit"
            size="sm"
            type={IconType.ABBREVIATION}
          />
          <div className={css.number}>
            {abbreviationsList?.length || '0'}
          </div>
        </button>

        {isOpen ? (
          <FloatingPortal>
            <div
              className={css.floatingContainer}
              ref={popupMergedRef}
              style={floatingStyles}
            >
              <FloatingArrow ref={arrowRef} context={context} />
              <Panel
                className={css.list}
                list={abbreviationsList}
                onClose={(): void => setIsOpen(false)}
              />
            </div>
          </FloatingPortal>
        )
          : null
        }
      </div>
    </div>
  );
};

export default Container;
