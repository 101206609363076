import { connect } from 'react-redux';

import LayoutListItemRendition from 'components/LayoutListItemRendition';
import { LayoutListItemRenditionActions, LayoutListItemRenditionOwnProps } from 'components/LayoutListItemRendition/models';
import { toggleDragState } from 'containers/App/actions';

const mapDispatchToProps = {
  toggleDragState,
};

export default connect<null, LayoutListItemRenditionActions, LayoutListItemRenditionOwnProps>(
  null,
  mapDispatchToProps,
)(LayoutListItemRendition);
