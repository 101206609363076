import _ from 'lodash';
import * as sanitizeHtml from 'sanitize-html';

export function getTextFromHtml(html: string): string {
  return sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {},
  }).trim();
}

export function getDecodedTextFromHtml(html: string): string {
  return _.unescape(getTextFromHtml(html));
}
