import { ProjectType, ProjectsConfig } from 'const';
import * as Models from 'models';
import { getValue, getValueIn } from 'utils/getter';

export function getScreenHeight(
  screenDefinition: Models.MasterScreen.AnyScreenDefinition,
  artboard: Models.AnyArtboard,
  projectType: ProjectType,
): number {
  const { areScreensResizable } = ProjectsConfig[projectType];

  if (!areScreensResizable) {
    return screenDefinition ? getValue(screenDefinition, 'screenHeight') : null;
  }

  return getValueIn(artboard, ['styles', 'height'], null);
}
