import _ from 'lodash';

import { createBrandDefinition } from 'factories/brandDefinitionFactory';
import * as Models from 'models';
import { compareById, compareByName, compareFontFaces } from './comparators';
import { applyTextTransformOverride } from './textTransform';

type CompareFn<T> = (targetObj: T, sourceObj: T) => boolean;
type NestedMergeFn<T> = (targetObj: T, sourceObj: T) => void;

function merge<T>(target: T[], source: T[], compareFn: CompareFn<T>, nestedMergeFn: NestedMergeFn<T> = _.noop): void {
  source.forEach((sourceObject) => {
    const targetObject = target.find(obj => compareFn(obj, sourceObject));

    if (targetObject) {
      nestedMergeFn(targetObject, sourceObject);
    } else {
      target.push(sourceObject);
    }
  });
}

function mergeNames(target: string[], source: string[]): void {
  merge(target, source, (a, b) => a === b);
}

function mergeColorsCategories(
  targetCategories: Models.ColorsCategories,
  targetColors: Models.BrandColors,
  sourceCategories: Models.ColorsCategories,
  sourceColors: Models.BrandColors,
): void {
  merge(targetCategories, sourceCategories, compareByName, (targetObj, sourceObj) => mergeNames(targetObj.brandColors, sourceObj.brandColors));

  targetCategories.forEach(category => category.brandColors.forEach((color) => {
    if (!targetColors.find(({ name }) => name === color)) {
      const srcColor = sourceColors.find(({ name }) => name === color);
      if (srcColor) {
        targetColors.push(srcColor);
      }
    }
  }));
}

function mergeFontsCategories(
  targetCategories: Models.FontsCategories,
  targetFonts: Models.BrandFonts,
  sourceCategories: Models.FontsCategories,
  sourceFonts: Models.BrandFonts,
): void {
  merge(targetCategories, sourceCategories, compareByName, (targetObj, sourceObj) => mergeNames(targetObj.brandFonts, sourceObj.brandFonts));

  targetCategories.forEach(category => category.brandFonts.forEach((font) => {
    if (!targetFonts.find(({ name }) => name === font)) {
      const srcFont = sourceFonts.find(({ name }) => name === font);
      if (srcFont) {
        targetFonts.push(srcFont);
      }
    }
  }));
}

function mergeBrandStyles(target: Models.BrandStylesRecord, source: Models.BrandStylesRecord): void {
  const targetValues = _.values(target);

  merge(targetValues, _.values(source), compareById);
  targetValues.forEach((targetValue) => {
    if (!target[targetValue.id]) {
      target[targetValue.id] = source[targetValue.id];
    }
    applyTextTransformOverride(target[targetValue.id], source[targetValue.id]);
  });
}

export function mergeUIFontFaces(...fontFaces: Models.UIFontFace[][]): Models.UIFontFace[] {
  const [target, ...sources] = fontFaces;

  sources.forEach((source) => {
    merge(target, source, compareFontFaces);
  });

  return target;
}

export function mergeBrandDefinitions(...definitions: Models.OnlineBrandDefinition[]): Models.OnlineBrandDefinition {
  const [target = createBrandDefinition(), ...sources] = definitions;

  sources.forEach((source) => {
    mergeColorsCategories(target.colorsCategories, target.colors, source.colorsCategories, source.colors);
    mergeFontsCategories(target.fontsCategories, target.fonts, source.fontsCategories, source.fonts);
    mergeBrandStyles(target.brandStyles, source.brandStyles);
    mergeUIFontFaces(target.UIFontFaces, source.UIFontFaces);
  });

  return target;
}
