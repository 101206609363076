import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProjectSource from 'components/ModalWindows/ProjectSource';
import * as Models from 'components/ModalWindows/ProjectSource/models';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: Models.IProjectSourceProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSource);
