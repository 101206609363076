import Immutable from 'immutable';
import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { addArtboard } from 'containers/Artboards/actions';
import { addLayouts } from 'containers/Layouts/actions';
import { addSurfaceId, updateActiveSurfaceId } from 'containers/Project/actions';
import * as projectSelectors from 'containers/Project/selectors';
import { addRelations } from 'containers/Relations/actions';
import { screenDefinitions as screenDefinitionsSelector } from 'containers/ScreenDefinitions/selectors';
import { addSections } from 'containers/Sections/actions';
import { addSurface } from 'containers/Surfaces/actions';
import { createArtboard } from 'factories/artboardFactory';
import { screenFactory } from 'factories/screenFactory';
import { createSectionsFromScreenDefinition } from 'factories/sectionFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { createLayoutsAndRelations } from 'utils/createLayoutAndRelations';
import { Action } from '../models';

export function* addScreen(action: Action.IAddScreen) {
  try {
    const { name, surfaceDefinitionId, type: formatType, height, width } = action.payload;

    const actions: Models.IAction[] = [];

    const screenDefinitions: ReturnTypeSaga<typeof screenDefinitionsSelector> = yield select(screenDefinitionsSelector);
    const screenDefinition = screenDefinitions.find(screenDefinition => screenDefinition.get('id') === surfaceDefinitionId);
    let screenDefinitionId: string;
    let { layouts, relations } = createLayoutsAndRelations();
    let sections: Models.Sections = {};

    if (screenDefinition) {
      screenDefinitionId = screenDefinition.get('id');

      const screenWidth = screenDefinition.get('screenWidth');
      const screenDefinitionSections: Models.MasterScreen.Section[] = screenDefinition.get('sections').toJS();
      sections = createSectionsFromScreenDefinition(screenDefinitionSections.map(section => ({ name: section.name })), screenDefinitionId);
      ({ layouts, relations } = createLayoutsAndRelations(_.map(sections, section => ({ section: section.id })), { layoutWidth: screenWidth }));
    }

    const artboard: ReturnTypeSaga<typeof createArtboard> = yield call(createArtboard, {
      layoutIds: Object.keys(layouts),
      styles: {
        height,
        width,
      } as Models.ArtboardStyles,
    });

    const surface: ReturnTypeSaga<typeof screenFactory> = yield call(screenFactory, {
      artboardId: artboard.id,
      screenDefinitionId,
      formatType,
      name,
    });

    actions.push(
      addArtboard(artboard),
      addLayouts(layouts),
      addSections(Immutable.fromJS(sections)),
      addRelations(relations),
    );

    const activeSurfaceId: ReturnTypeSaga<typeof projectSelectors.activeSurfaceId> = yield select(projectSelectors.activeSurfaceId);
    const surfaceIds: ReturnTypeSaga<typeof projectSelectors.surfaceIds> = yield select(projectSelectors.surfaceIds);
    const positionToPasteSurface = surfaceIds.indexOf(activeSurfaceId) + 1;

    actions.push(
      addSurface(surface),
      addSurfaceId(surface.id, positionToPasteSurface),
      updateActiveSurfaceId(surface.id),
    );

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'AddScreen.addScreen', 'AddScreen');
  }
}
