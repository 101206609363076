import { CellAction, dispatchCellActions } from 'context/CellActions';
import { CellActionsContext } from 'context/CellActions/CellActionsContext';
import React, { PropsWithChildren, useCallback, useContext } from 'react';
import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  blockKey: string;
  start: number;
  end: number;
}>;

const AbbreviationDecorator: React.FunctionComponent<Props> = (props) => {
  const { children, blockKey, end, start } = props;
  const { relationId } = useContext(CellActionsContext);

  const handleClick = useCallback((): void => dispatchCellActions(
    relationId,
    [CellAction.TOGGLE_EDITMODE_ON, true],
    [CellAction.APPLY_SELECTION, blockKey, start, end],
    CellAction.OPEN_ABBREVIATIONS_PANEL,
  ), [relationId, blockKey, start, end]);

  return (
    <span
      onClick={handleClick}
      className={styles.abbreviationDecorator}>
      {children}
    </span>
  );
};

export default AbbreviationDecorator;
