import _ from 'lodash';

export default function fetchAsmol(input: RequestInfo, init: RequestInit = {}): Promise<Response> {
  const config: RequestInit = {
    headers: {
      'user-id': sessionStorage.getItem('pm-userId') || undefined,
      Authorization: sessionStorage.getItem('pm-sessionId'),
      'Strict-Transport-Security': 'max-age=31536000',
      'X-Frame-Options': 'deny',
      'X-XSS-Protection': '1; mode=block',
    },
  };
  _.merge(config, init);

  return fetch(input, config);
}
