
import _ from 'lodash';
import { call, fork, select } from 'redux-saga/effects';

import { extendedCombinedLayoutAssets } from 'containers/Common/selectors';
import * as Models from 'models';

// In order to create selector for layout once only,
// It will allow us to use shallow comparison and avoid deep one in lots of cases
const createLayoutAssetsSelector = _.memoize(extendedCombinedLayoutAssets);

export function* getLayoutAssetsByLayoutId(layoutIds: string[]): Generator<unknown, Models.ExtendedCombinedLayoutAssetsByLayoutId> {
  const layoutAssetsByLayoutId = {} as Models.ExtendedCombinedLayoutAssetsByLayoutId;

  for (const id of layoutIds) {
    yield fork(function* () {
      // DON'T remove internal info on this step for case when we need to restore previous layout state
      // we clean up data right before comparison
      const layoutAssetsSelector: ReturnTypeSaga<typeof createLayoutAssetsSelector> = yield call(createLayoutAssetsSelector, id, { cleanUp: false });
      const layoutAssets: ReturnTypeSaga<typeof layoutAssetsSelector> = yield select(layoutAssetsSelector);
      layoutAssetsByLayoutId[id] = layoutAssets;
    });
  }

  return layoutAssetsByLayoutId;
}
