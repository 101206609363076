import { BoxProperty } from 'models';

export const createBoxProperty = ({
  top = 0,
  left = 0,
  right = 0,
  bottom = 0,
} = {} as Partial<BoxProperty>): BoxProperty => ({
  top,
  left,
  right,
  bottom,
});
