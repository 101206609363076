import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select, spawn } from 'redux-saga/effects';

import { Layer, MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import { Action } from 'containers/App/models';
import { setArtboards } from 'containers/Artboards/actions';
import { artboards as artboardsSelector } from 'containers/Artboards/selectors';
import * as commonSelectors from 'containers/Common/selectors';
import { downloadImages, setDocuments } from 'containers/Documents/actions';
import * as documentsSelectors from 'containers/Documents/selectors';
import { setLayouts } from 'containers/Layouts/actions';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import { setActiveLayer as createSetActiveLayerAction } from 'containers/Project/actions';
import { setActiveLayer } from 'containers/Project/sagas/setActiveLayer';
import { getDocumentsOnArtboards } from 'containers/Project/services/getDocumentsOnArtboards';
import { setRelations } from 'containers/Relations/actions';
import * as relationsSelectors from 'containers/Relations/selectors';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import * as Models from 'models';
import { importTranslationPackage as importPackage } from 'services/api';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { TranslationService } from 'services/Translation';
import { isMockDocument } from 'utils/documents';
import { isImage, isReferenceCitation } from 'utils/entityType';
import { intlGet } from 'utils/intlGet';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { translateGroupReusableLayouts } from '../services/translateGroupReusableLayouts';
import { translateReusableLayouts } from '../services/translateReusableLayouts';

function hasLayoutBeenTranslated(
  layout: Models.LayeredLayoutMap,
  documents: Models.CombinedDocumentsMap,
  translatedDocumentIdsByOriginal: Record<string, string>,
): boolean {
  const layoutDocument = documents.get(layout.getIn(['documentId', Layer.ORIGINAL])) as Models.ReusableLayoutMap;
  const layoutChildDocuments = layoutDocument.get('documents');

  // empty RLs are considered translated (https://issues.merck.com/browse/DCC-5141)
  return layoutChildDocuments.size === 0 || layoutChildDocuments.some(document => !!translatedDocumentIdsByOriginal[document.get('id')]);
}

function hasGroupLayoutBeenTranslated(
  groupLayout: Models.GroupLayoutMap,
  layouts: Models.LayeredCombinedLayoutsMap,
  documents: Models.DocumentsMap,
  translatedDocumentIdsByOriginal: Record<string, string>,
): boolean {
  const layoutDocument = documents.get(groupLayout.getIn(['documentId', Layer.TRANSLATED]));

  if (!isMockDocument(layoutDocument)) {
    return true;
  }

  const layoutIds = groupLayout.get('layoutIds');

  return layoutIds.some(
    layoutId => hasLayoutBeenTranslated(layouts.get(layoutId) as Models.LayeredLayoutMap, documents, translatedDocumentIdsByOriginal),
  );
}

function canGroupLayoutBeCreated(
  groupLayout: Models.GroupLayoutMap,
  reusableLayouts: Models.LayeredCombinedLayoutsMap,
  documents: Models.DocumentsMap,
): boolean {
  const layoutIds = groupLayout.get('layoutIds');

  return layoutIds.every((layoutId) => {
    const layoutDocumentId = reusableLayouts.getIn([layoutId, 'documentId', Layer.TRANSLATED]);
    const layoutDocument = documents.get(layoutDocumentId);

    return !layoutDocument.get('isMockDocument') && !!layoutDocument.get('documentId');
  });
}

export function* importTranslationPackage(action: Action.ImportTranslationPackage) {
  const logId = logger.performanceStart();
  const methodName = MethodName.TRANSLATION_IMPORT;

  try {
    yield put(AppActions.lockProjectContent(ProcessType.IMPORT_TRANSLATION_PACKAGE));
    yield put(AppActions.stopHandlingReusableLayoutsEditing());

    // to prevent different import errors
    yield call(setActiveLayer, createSetActiveLayerAction(Layer.ORIGINAL));

    const { packageFile } = action.payload;
    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const rootDocumentId = rootDocument.get('documentId');
    const documentsOnArtboards: ReturnTypeSaga<typeof getDocumentsOnArtboards> = yield call(
      getDocumentsOnArtboards,
      ProcessType.IMPORT_TRANSLATION_PACKAGE,
    );
    const imagesOnArtboards = documentsOnArtboards.filter(isImage) as Models.ImagesMap;
    const referencesOnArtboards = documentsOnArtboards.filter(isReferenceCitation);
    const usedImagesAndReferences = referencesOnArtboards.merge(imagesOnArtboards).toJS() as Models.CombinedDocuments;

    const { data: { documentsMap: translatedDocumentsMap } }: ReturnTypeSaga<typeof importPackage> = yield call(
      importPackage,
      packageFile,
      rootDocument.get('number'),
      rootDocumentId,
      usedImagesAndReferences,
    );

    const documents = (yield select(documentsSelectors.documents)).toJS() as Models.CombinedDocuments;
    const layeredRelations = (yield select(relationsSelectors.layeredRelations)).toJS() as Models.LayeredRelations;
    const layeredLayouts = (yield select(layeredLayoutsSelector)).toJS() as Models.LayeredLayouts;
    const artboards = (yield select(artboardsSelector)).toJS() as Models.Artboards;

    const sectionStyles: ReturnTypeSaga<typeof commonSelectors.sectionStylesByRelationId> = yield select(commonSelectors.sectionStylesByRelationId);
    const colorsByRelationId: ReturnTypeSaga<typeof commonSelectors.flatColorsByRelationId> = yield select(commonSelectors.flatColorsByRelationId);
    const fontsByRelationId: ReturnTypeSaga<typeof commonSelectors.flatFontsByRelationId> = yield select(commonSelectors.flatFontsByRelationId);
    const brandStylesByRelationId: ReturnTypeSaga<typeof commonSelectors.brandStylesByRelationId> =
      yield select(commonSelectors.brandStylesByRelationId);

    const translationService = new TranslationService({
      brandStylesByRelationId,
      colorsByRelationId,
      fontsByRelationId,
      sectionStylesByRelationId: sectionStyles,
    });

    const {
      relations: relationToSet,
      documents: documentsToSet,
      layouts: layoutsToSet,
      artboards: artboardsToSet,
      translatedDocumentIdsByOriginal,
    }: ReturnTypeSaga<typeof translationService.applyTranslations> = yield call(
      [translationService, translationService.applyTranslations],
      translatedDocumentsMap,
      documents,
      layeredRelations,
      layeredLayouts,
      artboards,
    );

    const newTranslatedImages = _.pickBy<Models.CombinedDocument, Models.Image>(documentsToSet, isImage);

    yield put(downloadImages(newTranslatedImages));
    yield put(batchActions([
      setDocuments(documentsToSet),
      setRelations(relationToSet),
      setLayouts(layoutsToSet),
      setArtboards(artboardsToSet),
    ]));

    yield call(setActiveLayer, createSetActiveLayerAction(Layer.TRANSLATED), true);

    const updatedDocuments: ReturnTypeSaga<typeof documentsSelectors.documents> = yield select(documentsSelectors.documents);
    const updatedLayeredLayouts: ReturnTypeSaga<typeof layeredLayoutsSelector> = yield select(layeredLayoutsSelector);

    // all RLs from original layer that could be translated
    const reusableLayouts = updatedLayeredLayouts
      .filter(layout => isReusableLayout(layout, updatedDocuments, Layer.ORIGINAL)) as Models.LayeredLayoutsMap;
    // RLs that could be created as translated RLs
    const translatedReusableLayouts = reusableLayouts
      .filter(layout => hasLayoutBeenTranslated(layout, updatedDocuments, translatedDocumentIdsByOriginal));

    const translatedReusableLayoutDocuments: ReturnTypeSaga<typeof translateReusableLayouts> = yield call(
      translateReusableLayouts,
      rootDocumentId,
      reusableLayouts,
      translatedReusableLayouts,
      updatedDocuments.toJS() as Models.CombinedDocuments,
    );

    const updatedDocumentsWithRLs: ReturnTypeSaga<typeof documentsSelectors.documents> = yield select(documentsSelectors.documents);
    const updatedLayouts: ReturnTypeSaga<typeof layeredLayoutsSelector> = yield select(layeredLayoutsSelector);
    // all GRLs from original layer that could be translated
    const groupReusableLayouts = updatedLayouts.filter((layout) => {
      const document = updatedDocumentsWithRLs.get(layout.getIn(['documentId', Layer.ORIGINAL])) as Models.ReusableLayoutMap;

      return isGroupLayout(layout) && document && !document.get('isMockDocument');
    }) as Models.GroupLayoutsMap;
    // GRLS from target layer that could be created as translated GRLs
    const translatedGroupReusableLayouts = updatedLayouts
      .filter<Models.GroupLayoutMap>(isGroupLayout)
      .filter(groupLayout => hasGroupLayoutBeenTranslated(groupLayout, updatedLayouts, updatedDocumentsWithRLs, translatedDocumentIdsByOriginal))
      .filter(groupLayout => canGroupLayoutBeCreated(groupLayout, updatedLayouts, updatedDocumentsWithRLs));

    yield call(
      translateGroupReusableLayouts,
      rootDocumentId,
      groupReusableLayouts,
      translatedGroupReusableLayouts,
      updatedDocumentsWithRLs.toJS() as Models.CombinedDocuments,
      translatedReusableLayoutDocuments,
    );

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'TranslationPackageHasBeenImported'),
      intlGet('Notification', 'Success'),
    );
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'App.importTranslationPackage', 'ImportTranslationPackage');
  } finally {
    yield put(AppActions.unlockProjectContent());
    yield put(AppActions.startHandlingReusableLayoutsEditing());
  }
}
