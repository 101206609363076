import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { ReferencesEditor } from 'modules/draftjs';
import { useEditor } from 'modules/References/hooks/useEditor';
import React from 'react';
import Toolbar from 'components/Toolbar/ReferenceCitation';
import * as editorUtils from 'utils/editor';
import ReferenceCitationActions from '../ReferenceCitationActions';
import { IReferenceCitationEditorProps } from './models';
import styles from './styles.module.scss';

// TODO: rewrite it using PlainTextEditor
const ReferenceCitationEditor: React.FunctionComponent<IReferenceCitationEditorProps> = (props) => {
  const {
    deleteReferenceFromText,
    layoutId,
    reference,
    updateReference,
  } = props;

  const initialEditorState = React.useRef<EditorState>(editorUtils.htmlToEditorState(reference ? reference.get('text') : ''));

  const {
    clearFormatting,
    disableLink,
    editor,
    editorState,
    fontStyles,
    link,
    onEditorStateChange,
    toggleFontStyle,
    toggleScriptStyle,
    toggleLink,
    focusEditor,
  } = useEditor(() => initialEditorState.current);

  const currentEditorState = React.useRef<EditorState>();
  currentEditorState.current = editorState;

  React.useEffect(
    () => {
      focusEditor();

      return () => {
        const options = editorUtils.getReferenceCitationStateToHTMLOptions();
        const html = stateToHTML(currentEditorState.current.getCurrentContent(), options);
        const originalHtml = stateToHTML(initialEditorState.current.getCurrentContent(), options);
        const isEmpty = currentEditorState.current.getCurrentContent().getPlainText().trim().length === 0;

        if (isEmpty) {
          deleteReference();
        } else if (html !== originalHtml) {
          updateReference(reference.get('id'), html);
        }
      };
    },
    [],
  );

  const deleteReference = (): void => {
    const id = reference.get('id');
    deleteReferenceFromText(id);
  };

  return (
    <ReferenceCitationActions deleteReference={deleteReference}>
      <div className={styles.ReferenceCitationEditor}>
        <Toolbar
          clearFormatting={clearFormatting}
          fontStyles={fontStyles}
          toggleFontStyle={toggleFontStyle}
          toggleScriptStyle={toggleScriptStyle}
          disableLink={disableLink}
          link={link}
          toggleLink={toggleLink}
          layoutId={layoutId}
        />
        <ReferencesEditor
          ref={editor}
          editorState={editorState}
          onEditorChange={onEditorStateChange}
        />
      </div>
    </ReferenceCitationActions>
  );
};

export default ReferenceCitationEditor;
