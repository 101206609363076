import Immutable from 'immutable';

import { createLayeredRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { toImmutable, toJS } from 'utils/immutable';
import { createLayers, getLayerFromAppState as getLayer, isObjectLayered } from 'utils/layers';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { getRelationStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const initialState = Immutable.Map() as State.IState;

export const relationsReducer: Models.StateInjectedReducer<State.IState> = (state = initialState, action, appState) => {
  switch (action.type) {
    case ActionTypes.ADD_RELATIONS: return addRelations(state, action, appState);
    case ActionTypes.DELETE_COMPONENTS: return deleteComponents(state, action, appState);
    case ActionTypes.DELETE_RELATIONS: return deleteRelations(state, action);
    case ActionTypes.MERGE_RELATIONS: return mergeRelations(state, action);
    case ActionTypes.REPLACE_DOCUMENT_ID_IN_RELATIONS: return replaceDocumentIdInRelations(state, action, appState);
    case ActionTypes.SET_RELATIONS: return setRelations(state, action);
    case ActionTypes.UPDATE_RELATION: return updateRelation(state, action, appState);
    case ActionTypes.UPDATE_RELATIONS: return updateRelations(state, action, appState);
    case ActionTypes.UPDATE_LAYERED_RELATIONS: return updateLayeredRelations(state, action);
    default: return state;
  }
};

const setRelations: Models.Reducer<State.IState, Action.ISetRelations> = (state, action) => {
  const { relations } = action.payload;

  return Immutable.fromJS(relations);
};

const mergeRelations: Models.Reducer<State.IState, Action.ISetRelations> = (state, action) => {
  const { relations } = action.payload;

  return state.merge(Immutable.fromJS(relations));
};

const addRelations: Models.StateInjectedReducer<State.IState, Action.IAddRelations> = (state, action, appState) => {
  const relations = Immutable.fromJS(action.payload.relations);

  return relations.reduce(
    (state: State.IState, relation: Models.RelationMap | Models.LayeredRelationMap) => {
      const id = relation.get('id');

      if (isRegularRelation(relation)) {
        const styles = relation.get('styles');
        const documentId = relation.get('documentId');
        const layer = getLayer(relation, appState);
        const layeredRelation = isRelationLayered(relation)
          ? relation
          : relation
            .set('styles', Immutable.fromJS(createLayers({ [layer]: styles })))
            .set('documentId', Immutable.fromJS(createLayers({ [layer]: documentId }))) as Models.LayeredRelationMap;

        return state.set(id, layeredRelation);
      }

      return state.set(id, relation);
    },
    state,
  );
};

const deleteRelations: Models.Reducer<State.IState, Action.IDeleteRelations> = (state, action) => {
  const { relationIds } = action.payload;

  return state.deleteAll(relationIds);
};

const updateRelation: Models.StateInjectedReducer<State.IState, Action.IUpdateRelation> = (state, action, appState) => {
  const { options: { resetLayeredData } } = action.payload;
  const relation = Immutable.fromJS(action.payload.relation) as Models.RelationMap;
  const updatedRelation = updateRelationHelper(relation, state, appState, resetLayeredData);

  return state.set(updatedRelation.get('id'), updatedRelation);
};

const updateRelations: Models.StateInjectedReducer<State.IState, Action.IUpdateRelations> = (state, action, appState) => {
  const relations = Immutable.fromJS(action.payload.relations) as Models.RelationsMap | DeepIMap<Models.Relation[]>;

  return (relations as Models.RelationsMap).reduce(
    (state, relation, id) => state.set(id, updateRelationHelper(relation, state, appState, false)),
    state,
  );
};

const updateLayeredRelations: Models.Reducer<State.IState, Action.UpdateLayeredRelations> = (state, action) => {
  const relations = action.payload.relations;

  return state.merge(relations);
};

const replaceDocumentIdInRelations: Models.StateInjectedReducer<State.IState, Action.IReplaceDocumentIdInRelations> = (state, action, appState) => {
  const { id, newId } = action.payload;

  return state.map((relation) => {
    if (!isRegularRelation(relation)) {
      return relation;
    }

    const layer = getLayer(relation, appState);

    if (relation.getIn(['documentId', layer]) !== id) {
      return relation;
    }

    return relation.setIn(['documentId', layer], newId);
  });
};

// TODO: Revise it, maybe we should rewrite it to a saga
export const deleteComponents: Models.StateInjectedReducer<State.IState, Action.IDeleteComponents> = (state, action, appState) => {
  const { ids } = action.payload;

  return state.map((relation, id) => {
    if (!isRegularRelation(relation)) {
      return relation;
    }

    const layer = getLayer(relation, appState);
    const layeredDocumentId = relation.get('documentId');
    const documentId = layeredDocumentId.get(layer);

    if (!ids.includes(documentId)) {
      return relation;
    }

    return Immutable.fromJS(createLayeredRelation({
      styles: getRelationStylesToBeKept(relation as Models.LayeredRegularRelationMap<Models.CombinedAssetRelationStyles>),
      entityType: relation.get('entityType'),
      id,
      documentId: toJS(layeredDocumentId.delete(layer)),
    } as Models.LayeredRegularRelation));
  });
};

function updateRelationHelper(
  relation: Models.RelationMap,
  state: State.IState,
  appState: Models.AppState.StateMap,
  resetLayeredData: boolean,
): Models.LayeredRelationMap {
  if (!isRegularRelation(relation)) {
    return relation;
  }

  const id = relation.get('id');
  const styles = relation.get('styles');
  const documentId = relation.get('documentId');

  const layer = getLayer(relation, appState);

  const layeredStyles = (state.getIn([id, 'styles']) as DeepIMap<Models.Layers<Models.CombinedRelationStyles>>).update(
    layeredStyles => resetLayeredData
      ? toImmutable(createLayers()).set(layer, styles)
      : layeredStyles.set(layer, styles),
  );
  const layeredDocumentId = state.getIn([id, 'documentId']).update(
    layeredDocumentId => resetLayeredData
      ? toImmutable(createLayers<string>()).set(layer, documentId)
      : layeredDocumentId.set(layer, documentId),
  );

  return (relation as Models.LayeredRegularRelationMap)
    .set('styles', layeredStyles)
    .set('documentId', layeredDocumentId);
}

function isRelationLayered(relation: Models.RegularRelationMap | Models.LayeredRegularRelationMap): relation is Models.LayeredRegularRelationMap {
  const relationToCheck = relation.toJS() as Models.RegularRelation | Models.LayeredRegularRelation;

  return isObjectLayered(relationToCheck.documentId);
}

export default relationsReducer;
