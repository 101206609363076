import React from 'react';
/* eslint-disable max-len */

const DragDots = (): JSX.Element => (
  <svg width="7px" height="18px" viewBox="0 0 7 18">
    <path d="M2.5,16 L2.5,18 L0.5,18 L0.5,16 L2.5,16 Z M6.5,16 L6.5,18 L4.5,18 L4.5,16 L6.5,16 Z M2.5,12 L2.5,14 L0.5,14 L0.5,12 L2.5,12 Z M6.5,12 L6.5,14 L4.5,14 L4.5,12 L6.5,12 Z M2.5,8 L2.5,10 L0.5,10 L0.5,8 L2.5,8 Z M6.5,8 L6.5,10 L4.5,10 L4.5,8 L6.5,8 Z M2.5,4 L2.5,6 L0.5,6 L0.5,4 L2.5,4 Z M6.5,4 L6.5,6 L4.5,6 L4.5,4 L6.5,4 Z M2.5,0 L2.5,2 L0.5,2 L0.5,0 L2.5,0 Z M6.5,0 L6.5,2 L4.5,2 L4.5,0 L6.5,0 Z" />
  </svg>
);

export default DragDots;
