import React from 'react';
import { IconType } from 'components/Icon/constants';
import EditorToolbarButton from '../EditorToolbarButton';

type Props = {
  isNoWrapActive: boolean;
  onToggle: () => void;
};

const WrapControl: React.FunctionComponent<Props> = ({ isNoWrapActive, onToggle }) => (
  <EditorToolbarButton
    active={isNoWrapActive}
    label={IconType.NO_WRAP}
    onToggle={onToggle}
    value={''}
    title={'NoWrap'}
  />
);

export default WrapControl;
