import { EntityType } from 'const';
import { CombinedDocumentMap } from 'models';

const selectableEntities: EntityType[] = [EntityType.TEXT, EntityType.IMAGE];

export function isSelectableDocument(document: CombinedDocumentMap): boolean {
  const entityType = document && document.get('entityType') as EntityType;

  return selectableEntities.includes(entityType);
}
