import Immutable from 'immutable';

import * as Models from 'models';
import { borderToSource } from 'utils/converters';
import { applyBoxProperty } from './applyBoxProperty';

export const updateBorder = (
  layout: Models.LayoutMap,
  columnsWidth: Immutable.List<number>,
  border: Models.BorderMap,
  getMinColumnWidth: (position: number) => number,
): {
  layout: Models.LayoutMap;
  columnsWidth: Immutable.List<number>;
} => {
  const newColumnsWidth = applyBoxProperty(
    columnsWidth,
    layout.getIn(['styles', 'border', 'width']),
    border.get('width'),
  );

  if (newColumnsWidth.every((column, idx) => column >= getMinColumnWidth(idx))) {
    return {
      layout: layout.setIn(['styles', 'border'], borderToSource(border)),
      columnsWidth: newColumnsWidth,
    };
  }

  return {
    layout,
    columnsWidth,
  };
};
