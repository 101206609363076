import Immutable from 'immutable';
import _ from 'lodash';
import guid from 'uuid';

import { ImageMap, Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

export const modalManagerInitialState: Models.State.IState = Immutable.fromJS({
  modalWindows: Immutable.OrderedMap({}),
});

export const modalManagerReducer: Reducer<Models.State.IState> = (state = modalManagerInitialState, action) => {
  switch (action.type) {
    case ActionTypes.HIDE_MODAL: return hideModal(state, action);
    case ActionTypes.SHOW_MODAL: return showModal(state, action);
    case ActionTypes.UPDATE_IMAGE_INFO_WITHIN_MODAL_WINDOW: return updateImageInfoWithinModalWindow(state, action);
    case ActionTypes.UPDATE_MODAL_WINDOW_OPTIONS: return updateModalWindowOptions(state, action);
    default: return state;
  }
};

const showModal: Reducer<Models.State.IState, Models.Action.IShowModal<{}>> = (state, action) => {
  const { id = guid(), modalType, options = {} } = action.payload;

  return state.update('modalWindows', modalWindows => modalWindows.set(id, Immutable.fromJS({
    type: modalType,
    options,
  })));
};

const updateImageInfoWithinModalWindow: Reducer<Models.State.IState, Models.Action.IUpdateImageInfoWithinModalWindow> = (state, action) => {
  const { id, imageInternalInfo, modalWindowId } = action.payload;
  const documentDuplicates = state.getIn(['modalWindows', modalWindowId, 'options', 'documentDuplicates']);
  const documentIndex = documentDuplicates && documentDuplicates.findIndex(document => document.get('id') === id);

  if (_.isNil(documentIndex)) {
    return state;
  }

  return state.updateIn(['modalWindows', modalWindowId, 'options', 'documentDuplicates'], documents => documents.update(
    documentIndex,
    (document: ImageMap) => document.set('_internalInfo', Immutable.fromJS(imageInternalInfo)),
  ));
};

const updateModalWindowOptions: Reducer<Models.State.IState, Models.Action.IUpdateModalWindowOptions> = (state, action) => {
  const { options, modalWindowId } = action.payload;

  return state.updateIn(['modalWindows', modalWindowId, 'options'], currentOptions => currentOptions.merge(Immutable.fromJS(options)));
};

const hideModal: Reducer<Models.State.IState, Models.Action.IHideModal> = (state, action) => {
  const { id, needToClose } = action.payload;

  return needToClose ? state.update('modalWindows', modalWindows => modalWindows.delete(id)) : state;
};

export default modalManagerReducer;
