import React from 'react';
/* eslint-disable max-len */

const ZoomPlus = (): JSX.Element => (
  <svg width="300" height="300" viewBox="0 0 300 300">
    <g>
      <rect stroke="#f9ad3d" height="20" width="150" y="135" x="70" fill="#f9ad3d"/>
      <rect stroke="#f9ad3d" height="150" width="20" y="70" x="135" fill="#f9ad3d"/>
    </g>
  </svg>
);

export default ZoomPlus;
