import classNames from 'classnames';
import Immutable from 'immutable';
import React from 'react';
import { Button } from 'react-bootstrap';

import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { SSISourceProps } from './models';
import styles from './styles.module.scss';

const SSISource: React.FunctionComponent<SSISourceProps> = (props) => {
  const {
    screens,
    screenDefinitions,
    hideModal,
    modalWindowId,
    setSSISource,
    activeArtboardId,
    options,
  } = props;

  const [activeScreenId, setActiveScreen] = React.useState(options ? options.get('screen') : '');
  const [activeSection, setActiveSection] = React.useState(options ? options.get('section') : '');

  const handleClick = (id: string): void => {
    setActiveScreen(activeScreenId === id ? '' : id);
    setActiveSection('');
  };

  const setSection = (name: string): void => {
    setActiveSection(activeSection === name ? '' : name);
  };

  const onCancelClick = (): void => {
    hideModal(modalWindowId);
  };

  const onImportClick = (): void => {
    setSSISource(activeArtboardId, { screen: activeScreenId, section: activeSection } as Models.SSISource);
    hideModal(modalWindowId);
  };

  const renderScreens = (): Immutable.Seq.Indexed<JSX.Element> => {
    return screens.valueSeq().map((screen) => {
      const screenDefinitionId = (screen as Models.ScreenMap).get('screenDefinitionId');
      const screenType = screenDefinitions.find(screen => screen.get('id') === screenDefinitionId).get('defaultName');
      const screenId = screen.get('id');

      return (
        <div
          key={screenId}
          onClick={handleClick.bind(null, screenId)}
          className={classNames(styles.optionItem, { [styles.active]: activeScreenId === screenId })}
        >
          <div className={styles.optionTitle}>
            {screen.get('name')}
          </div>
          <div className={styles.optionSubtitle}>{screenType}</div>
        </div>
      );
    });
  };

  const renderSections = (): Immutable.List<JSX.Element> => {
    const activeScreen = screens.valueSeq().find(screen => (screen as Models.ScreenMap).get('id') === activeScreenId);
    const screenDefinitionId = (activeScreen as Models.ScreenMap).get('screenDefinitionId');

    return screenDefinitions.getIn([screenDefinitionId, 'sections']).map((section) => {
      const sectionName = section.get('name');

      return (
        <div
          key={sectionName}
          onClick={setSection.bind(null, sectionName)}
          className={classNames(styles.optionItem, { [styles.active]: activeSection === sectionName })}
        >
          {section.get('displayName')}
        </div>
      );
    });
  };

  return (
    <div className={styles.SSISource}>
      <header className={styles.header}>
        <div className={styles.title}>{intlGet('SSISourceWindow', 'ChooseContentSource')}</div>
      </header>
      <main className={styles.main}>
        <div className={styles.optionsColumn}>
          {renderScreens()}
        </div>
        <div className={styles.optionsColumn}>
          {activeScreenId && renderSections()}
        </div>
      </main>
      <footer className={styles.footer}>
        <Button onClick={onCancelClick} variant="secondary">{intlGet('SSISourceWindow.Button', 'Cancel')}</Button>
        <Button onClick={onImportClick} variant="primary" disabled={!activeSection}>{intlGet('SSISourceWindow.Button', 'Import')}</Button>
      </footer>
    </div>
  );
};

export default SSISource;
