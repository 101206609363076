import _ from 'lodash';

import { isImmutable } from './immutable';

/**
 * Returns the value of Plain JS Object or its Immutable version by the property name
 * @param object - Plain JS Object or its Immutable version
 * @param property - Property's name
 */

export function getValue<T extends { [P in string]: T[P] }, P extends keyof T>(
  object: T,
  property: P,
  defaultValue?: T[P],
): T[P];
export function getValue<T extends { [P in string]: T[P] }, P extends keyof T>(
  object: DeepIMap<T>,
  property: P,
  defaultValue?: DeepIMap<T[P]>,
): DeepIMap<T[P]>;
export function getValue<T extends { [P in string]: T[P] }, P extends keyof T>(
  object: T | DeepIMap<T>,
  property: P,
  defaultValue?: T[P] | DeepIMap<T[P]>,
): T[P] | DeepIMap<T[P]>;
export function getValue<T extends { [P in string]: T[P] }, P extends keyof T>(
  object: T | DeepIMap<T>,
  property: P,
  defaultValue?: T[P] | DeepIMap<T[P]>,
): T[P] | DeepIMap<T[P]> {
  if (!_.isObjectLike(object)) {
    return defaultValue;
  }

  let value: T[P] | DeepIMap<T[P]>;

  if (isImmutable(object)) {
    value = object.get(property);
  } else {
    value = object[property];
  }

  return value;
}

/**
 * Returns values of Plain JS Object or its Immutable version by the properties' names
 * @param object - Plain JS Object or its Immutable version
 * @param properties - Properties' names
 */
export function getValues<T extends { [P in string]: T[P] }, P extends keyof T>(
  object: T | DeepIMap<T>,
  properties: P[],
): { [K in P]: T[K] | DeepIMap<T[K]> } {
  if (!_.isObjectLike(object)) {
    return {} as { [K in P]: T[K] | DeepIMap<T[K]> };
  }

  return properties.reduce(
    (result, property) => {
      const value = getValue(object, property);
      result[property] = value;

      return result;
    },
    {} as { [K in P]: T[K] | DeepIMap<T[K]> },
  );
}

// types overloading (can be expanded to get more than 5 items in path's array)
export function getValueIn<
  T extends { [P in string]: T[P] },
  K1 extends keyof T = keyof T,
  K2 extends keyof T[K1] = keyof T[K1],
>(
  object: T | DeepIMap<T>,
  path: [K1, K2],
  defaultValue?: T[K1][K2] | DeepIMap<T[K1][K2]>,
): T[K1][K2] | DeepIMap<T[K1][K2]>;
export function getValueIn<
  T extends { [P in string]: T[P] },
  K1 extends keyof T = keyof T,
  K2 extends keyof T[K1] = keyof T[K1],
  K3 extends keyof T[K1][K2] = keyof T[K1][K2],
>(
  object: T | DeepIMap<T>,
  path: [K1, K2, K3],
  defaultValue?: T[K1][K2][K3] | DeepIMap<T[K1][K2][K3]>,
): T[K1][K2][K3] | DeepIMap<T[K1][K2][K3]>;
export function getValueIn<
  T extends { [P in string]: T[P] },
  K1 extends keyof T = keyof T,
  K2 extends keyof T[K1] = keyof T[K1],
  K3 extends keyof T[K1][K2] = keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3] = keyof T[K1][K2][K3],
>(
  object: T | DeepIMap<T>,
  path: [K1, K2, K3, K4],
  defaultValue?: T[K1][K2][K3][K4] | DeepIMap<T[K1][K2][K3][K4]>,
): T[K1][K2][K3][K4] | DeepIMap<T[K1][K2][K3][K4]>;
export function getValueIn<
  T extends { [P in string]: T[P] },
  K1 extends keyof T = keyof T,
  K2 extends keyof T[K1] = keyof T[K1],
  K3 extends keyof T[K1][K2] = keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3] = keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4] = keyof T[K1][K2][K3][K4],
>(
  object: T | DeepIMap<T>,
  path: [K1, K2, K3, K4, K5],
  defaultValue?: T[K1][K2][K3][K4][K5] | DeepIMap<T[K1][K2][K3][K4][K5]>,
): T[K1][K2][K3][K4][K5] | DeepIMap<T[K1][K2][K3][K4][K5]>;

/**
 * Returns the value of Plain JS object or its Immutable version by the path of properties' names
 * @param object - Plain JS Object or its Immutable version
 * @param path - Path to the value to return
 */
export function getValueIn<T extends { [P in string]: T[P] }>(object: T | DeepIMap<T>, path: string[], defaultValue?) {
  let objectValue = object;
  let isValid = true;

  path.forEach((property) => {
    if (isValid && _.isObjectLike(objectValue)) {
      objectValue = getValue(objectValue, property);

      return;
    }

    isValid = false;
  });

  return isValid ? objectValue : defaultValue;
}
