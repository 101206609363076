import { createContext } from 'react';
import { CellAction, CellActionArguments, CellActionExecutor } from './types';

type Action<A extends CellAction = CellAction> = {
  name: A;
  args: CellActionArguments<A>;
  isExecuted?: boolean;
};

export type CellActionsContextType = {
  relationId: string;
  actions: Action[];
  tryToExecuteAction?: <A extends CellAction>(name: A, executor: CellActionExecutor<A>) => void;
};

export const CellActionsContext = createContext<CellActionsContextType>({
  relationId: '',
  actions: [],
});
