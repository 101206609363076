import _ from 'lodash';
import guid from 'uuid';

import { DocumentsSource, Layer } from 'const';
import { createLayeredGroupLayout, createLayeredLayout } from 'factories/layoutFactory';
import * as Models from 'models';
import { findDuplicateIdByDocumentId } from 'utils/findDocumentDuplicateId';
import { createLayers } from 'utils/layers';
import { addReusableLayout } from 'utils/reusableLayouts/addReusableLayout';

interface AddGroupReusableLayoutToArtboardResult {
  layouts: Models.LayeredCombinedLayouts;
  artboard: Models.Artboard;
  newImages: Models.Images;
  relations: Models.LayeredRelations;
  documents: Models.CombinedDocuments;
}

interface AddGroupReusableLayoutToArtboardArg {
  groupLayoutDocument: Models.GroupLayoutDocument;
  sectionId: string;
  position: number;
  artboard: Models.Artboard;
  projectDocuments: Models.CombinedDocuments;
  prevProjectDocuments?: Models.CombinedDocuments;
  layouts: Models.LayeredCombinedLayouts;
  projectBrandDefinition: Models.OnlineBrandDefinition;
  activeLayer: Layer;
  storyCardId?: string;
  groupLayoutId?: string;
  adoptStyles?: boolean;
}

export function addGroupLayoutToArtboard({
  groupLayoutDocument,
  sectionId,
  position,
  artboard,
  projectDocuments,
  layouts,
  projectBrandDefinition,
  activeLayer,
  storyCardId,
  groupLayoutId,
  adoptStyles,
}: AddGroupReusableLayoutToArtboardArg): AddGroupReusableLayoutToArtboardResult {
  const result = {
    layouts,
    artboard,
    relations: {},
    newImages: {},
    documents: projectDocuments,
  } as AddGroupReusableLayoutToArtboardResult;
  const newGroupLayoutId = groupLayoutId || guid();
  result.artboard.layoutIds.splice(position, 0, newGroupLayoutId);

  const {
    id: groupLayoutDocumentInternalId,
    entities: { layouts: layoutIds, styles },
  } = groupLayoutDocument;

  const existingLayout = _.find(layouts, ({ documentId }) => documentId[activeLayer] === groupLayoutDocumentInternalId) as Models.LayeredGroupLayout;

  if (existingLayout) {
    const newLayoutIds = existingLayout.layoutIds.map((oldLayoutId) => {
      const newLayoutId = guid();
      result.layouts[newLayoutId] = createLayeredLayout({
        ...layouts[oldLayoutId] as Models.LayeredLayout,
        section: sectionId,
        id: newLayoutId,
      });

      return newLayoutId;
    });

    result.layouts[newGroupLayoutId] = createLayeredGroupLayout({
      ...existingLayout,
      id: newGroupLayoutId,
      layoutIds: newLayoutIds,
      section: sectionId,
    });

    return result;
  }

  const newLayoutIds = layoutIds.map((childLayoutDocumentPMId) => {
    const { documents: projectDocuments } = result;

    let childLayoutDocument = _.find<Models.ReusableLayout>(
      storyCardId ? _.get(projectDocuments, [storyCardId, 'documents']) : projectDocuments,
      document => document.documentId === childLayoutDocumentPMId,
    );

    const duplicateId = findDuplicateIdByDocumentId(childLayoutDocument, projectDocuments as Models.Documents);
    duplicateId && (childLayoutDocument = projectDocuments[duplicateId] as Models.ReusableLayout);

    const addLayoutResult = addReusableLayout(
      childLayoutDocument,
      sectionId,
      projectDocuments,
      layouts,
      projectBrandDefinition,
      activeLayer,
      undefined,
      adoptStyles ||
      // adapt styles force if RL was updated in Promomats
      _.get(projectDocuments, [childLayoutDocument.id, 'isUpdatedInPromoMats']),
    );

    _.assign(result.relations, addLayoutResult.relations);
    _.assign(result.documents, addLayoutResult.documents);
    _.assign(result.newImages, addLayoutResult.newImages);
    _.assign(result.layouts, { [addLayoutResult.layout.id]: addLayoutResult.layout });

    return addLayoutResult.layout.id;
  });

  result.layouts[newGroupLayoutId] = createLayeredGroupLayout({
    id: newGroupLayoutId,
    documentId: createLayers({ [activeLayer]: groupLayoutDocumentInternalId }),
    layoutIds: newLayoutIds,
    section: sectionId,
    styles,
  });
  groupLayoutDocument.documentSource = _.union(groupLayoutDocument.documentSource, [DocumentsSource.COMPONENTS]);
  result.documents[groupLayoutDocumentInternalId] = groupLayoutDocument;

  return result;
}
