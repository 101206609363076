import _ from 'lodash';

type ReturnType<T extends {}> = { [K in keyof T]: string };

/**
 * Creates an object with the same keys as object and values generated
 * by running each own enumerable string keyed property through mapper.
 * Mapper invoked with 1 argument: (key)
 * @param object - object to iterate over
 * @param mapper - the function invoked per iteration
 */
export const keyMirrorWithMap = <T extends {}>(object: T, mapper: (key: string) => string = _.identity): ReturnType<T> =>
  _.mapValues(object, (_i, key) => mapper(key));

/**
 * Creates an object with the same keys as object and values generated to `${namespace}@${key}`.
 * @param object - object to iterate over
 * @param namespace - prefix namespace
 */
export function keyMirror<T>(object: T, namespace?: string): ReturnType<T> {
  return keyMirrorWithMap(object, key => namespace ? `${namespace}@${key}` : key);
}
