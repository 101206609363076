import _ from 'lodash';

import { AssetAlignmentType } from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { mergeObjectsDeep } from 'utils/mergeObjectsDeep';

export function applyDefaultStyles(
  textComponent: Models.TextComponent,
  relation: Models.RegularRelation<Models.TextRelationStyles>,
  defaultStyles?: Models.TextInlineStyles,
): void {
  const editorDependentStyles = _.flow(
    editorUtils.convertTextComponentToRawEditorState,
    editorState => editorUtils.applyDefaultStyles(editorState, defaultStyles),
    editorState => editorUtils.isolateEditorStateStyles(editorState),
  )(textComponent);

  _.defaultsDeep(relation.styles, { alignment: { vertical: AssetAlignmentType.TOP } });
  mergeObjectsDeep(relation.styles, editorDependentStyles);
}
