import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_TEXT_TO_CELL: null,
    COPY_CELL_CONTENT: null,
    DROP_ASSET: null,
    DELETE_REFERENCE_CITATION_FROM_COMPONENTS: null,
  },
  'ArtboardCell',
);
