import Draft from 'draft-js';
import _ from 'lodash';
import guid from 'uuid';

import { Layer, Styles, TextHorizontalAlignmentType, TextLineHeightValue } from 'const';
import { DefaultTextBrandStyle, DefaultTextStyles } from 'const/Styles';
import { applyDefaultStyles } from 'containers/ArtboardCell/services/applyDefaultStyles';
import { replaceReferenceMarkersByEntities } from 'containers/ArtboardCell/services/replaceReferenceMarkersByEntities';
import { createCallToActionRelation, createImageRelation, createTextRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { SectionStylesByRelationIdMap } from 'models/MasterScreen';
import {
  getInlineStylesFromBlocks,
  getParagraphStylesFromBlocks,
  getTextStylesFromBrandStyle,
  isDefaultBrandStyleAvailable,
  isStyleAppliedForWholeTextComponent,
} from 'utils/brandStyles';
import { applyBrandStyle } from 'utils/editor/brandStyle';
import { isCallToAction, isImage, isTextComponent } from 'utils/entityType';
import { findDuplicateIdByDocumentId } from 'utils/findDocumentDuplicateId';
import * as inlineStylesUtils from 'utils/inlineStyles';
import { replaceReferenceCitationsWithinComponent } from 'utils/referenceCitation';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { validateTextAssets } from 'utils/validator';
import * as TranslationServiceModels from './models';

export class TranslationService implements TranslationServiceModels.TranslationService {
  private colorsByRelationId: Models.BrandColorsMap;

  private fontsByRelationId: Models.BrandFontsMap;

  private sectionStylesByRelationId: SectionStylesByRelationIdMap;

  private brandStylesByRelationId: DeepIMap<Record<string, Models.BrandStylesRecord>>;

  constructor(config: TranslationServiceModels.Config) {
    this.fontsByRelationId = config.fontsByRelationId;
    this.colorsByRelationId = config.colorsByRelationId;
    this.brandStylesByRelationId = config.brandStylesByRelationId;
    this.sectionStylesByRelationId = config.sectionStylesByRelationId;
  }

  public applyTranslations(
    translatedDocumentsMap: Models.CombinedDocuments,
    documents: Models.CombinedDocuments,
    relations: Models.LayeredRelations,
    layouts: Models.LayeredLayouts,
    artboards: Models.Artboards,
  ): TranslationServiceModels.Result.ApplyTextTranslations {
    const translatedDocumentIdsByOriginal = {} as Record<string, string>;

    let translatedDocuments = _(translatedDocumentsMap)
      .map((translatedDocument, id) => {
        const originalDocument = documents[id];

        if (!originalDocument) {
          return translatedDocument;
        }

        const newDocumentId = findDuplicateIdByDocumentId(translatedDocument, documents) || guid();
        const updatedTranslatedDocument = { ...translatedDocument, id: newDocumentId };

        translatedDocumentIdsByOriginal[id] = newDocumentId;

        return this.processTranslatedDocument(
          updatedTranslatedDocument,
          documents,
          originalDocument,
        );
      })
      .compact()
      .keyBy(document => document.id)
      .mapValues(document => this.updateReferenceCitationsInText(document, translatedDocumentIdsByOriginal))
      .value();

    let updatedRelations = _(relations)
      .mapValues(relation => this.translateRelation(relation, documents, translatedDocuments, translatedDocumentIdsByOriginal))
      .mapValues(relation => this.translateRelationBackgroundImage(relation, translatedDocumentIdsByOriginal))
      .mapValues(relation => this.translateRelationMobileImage(relation, translatedDocumentIdsByOriginal, translatedDocuments))
      .pickBy(Boolean)
      .value() as Models.LayeredRelations<Models.TextRelationStyles>;

    const updatedLayouts = _.mapValues(layouts, layout => this.translateLayoutBackgroundImage(layout, translatedDocumentIdsByOriginal));
    const updatedArtboards = _.mapValues(artboards, screen => this.translateScreenBackgroundImage(screen, translatedDocumentIdsByOriginal));

    ({
      documents: translatedDocuments,
      relations: updatedRelations,
    } = validateTextAssets(
      translatedDocuments,
      updatedRelations,
    ));

    return {
      relations: _.assign(relations, updatedRelations),
      documents: _.assign(documents, translatedDocuments),
      layouts: updatedLayouts,
      artboards: updatedArtboards,
      translatedDocumentIdsByOriginal,
    };
  }

  private translateRelation(
    relation: Models.LayeredRelation,
    originalDocuments: Models.CombinedDocuments,
    translatedDocuments: Models.CombinedDocuments,
    translatedDocumentIdsByOriginal: Record<string, string>,
  ): Models.LayeredRelation {
    if (!isRegularRelation(relation)) {
      return relation;
    }

    const originalDocumentId = relation.documentId.original;
    const translatedDocumentId = translatedDocumentIdsByOriginal[originalDocumentId];

    if (!originalDocumentId || !translatedDocumentId) {
      return null;
    }

    if (!relation.styles.original) {
      return relation;
    }

    const originalDocument = originalDocuments[originalDocumentId];
    const translatedDocument = translatedDocuments[translatedDocumentId];
    // no need to set the correct documentId in this.createTranslatedRelation as it will be set below
    const translatedRelation = this.createTranslatedRelation(relation, originalDocument, translatedDocument);

    return {
      ...relation,
      documentId: {
        original: originalDocumentId,
        translated: translatedDocumentId,
      },
      styles: {
        original: relation.styles.original,
        translated: translatedRelation && translatedRelation.styles,
      },
    } as Models.LayeredRelation;
  }

  private translateRelationBackgroundImage(
    relation: Models.LayeredRelation,
    translatedDocumentIdsByOriginal: Record<string, string>,
  ): Models.LayeredRelation {
    const backgroundImageId: Models.BackgroundImage['id'] = _.get(relation, ['styles', Layer.ORIGINAL, 'backgroundImage', 'id']);
    const translateBackgroundImageId = translatedDocumentIdsByOriginal[backgroundImageId];

    if (!backgroundImageId || !translateBackgroundImageId) {
      return relation;
    }

    return _.set(relation, ['styles', Layer.TRANSLATED, 'backgroundImage', 'id'], translateBackgroundImageId);
  }

  private translateRelationMobileImage(
    relation: Models.LayeredRelation,
    translatedDocumentIdsByOriginal: Record<string, string>,
    translatedDocuments: Models.CombinedDocuments,
  ): Models.LayeredRelation {
    const imageId: Models.BackgroundImage['id'] = _.get(relation, ['styles', Layer.ORIGINAL, 'mobileViewImage', 'id']);
    const translatedImageId = translatedDocumentIdsByOriginal[imageId];

    if (!imageId || !translatedImageId || !isImage(relation)) {
      return relation;
    }

    _.set(relation, ['styles', Layer.TRANSLATED, 'mobileViewImage', 'id'], translatedImageId);
    const documentId = (translatedDocuments[translatedImageId] as Models.Image).documentId;

    return _.set(relation, ['styles', Layer.TRANSLATED, 'mobileViewImage', 'documentId'], documentId);
  }

  private translateLayoutBackgroundImage(
    layout: Models.LayeredLayout,
    translatedDocumentIdsByOriginal: Record<string, string>,
  ): Models.LayeredLayout {
    const backgroundImageId = _.get(layout, ['styles', 'backgroundImage', 'id']);
    const translateBackgroundImageId = translatedDocumentIdsByOriginal[backgroundImageId];

    if (!backgroundImageId || !translateBackgroundImageId) {
      return layout;
    }

    return _.set(layout, ['styles', 'backgroundImage', 'id'], translateBackgroundImageId);
  }

  private translateScreenBackgroundImage(
    artboard: Models.Artboard,
    translatedDocumentIdsByOriginal: Record<string, string>,
  ): Models.Artboard {
    const backgroundImageId = _.get(artboard, ['styles', 'backgroundImage', 'id']);
    const translateBackgroundImageId = translatedDocumentIdsByOriginal[backgroundImageId];

    if (!backgroundImageId || !translateBackgroundImageId) {
      return artboard;
    }

    return _.set(artboard, ['styles', 'backgroundImage', 'id'], translateBackgroundImageId);
  }

  private processTranslatedDocument(
    translatedDocument: Models.CombinedDocument,
    documents: Models.CombinedDocuments,
    originalDocument: Models.CombinedDocument,
  ): Models.CombinedDocument {
    if (isTextComponent(translatedDocument)) {
      return this.processTranslatedTextComponent(translatedDocument, documents);
    }

    if (isCallToAction(translatedDocument)) {
      return this.processTranslatedCallToAction(translatedDocument, originalDocument as Models.CallToAction);
    }

    return translatedDocument;
  }

  private updateReferenceCitationsInText(
    translatedDocument: Models.CombinedDocument,
    translatedDocumentIdsByOriginal: Record<string, string>,
  ): Models.CombinedDocument {
    if (!isTextComponent(translatedDocument) || _.isEmpty(translatedDocument.referenceCitations)) {
      return translatedDocument;
    }

    return replaceReferenceCitationsWithinComponent(translatedDocument, translatedDocumentIdsByOriginal);
  }

  private createTranslatedRelation(
    relation: Models.LayeredRegularRelation,
    originalDocument: Models.CombinedDocument,
    translatedDocument: Models.CombinedDocument,
  ): Models.RegularRelation {
    switch (true) {
      case isTextComponent(relation):
        return this.createTranslatedTextRelation(
          relation as Models.LayeredRegularRelation<Models.TextRelationStyles>,
          originalDocument as Models.TextComponent,
          translatedDocument as Models.TextComponent,
        );
      case isCallToAction(relation):
        return createCallToActionRelation(
          {
            id: relation.id,
            styles: this.takeCallToActionKeepingStyles((relation as Models.LayeredRegularRelation<Models.CallToActionStyles>).styles.original),
          },
          (translatedDocument as Models.CallToAction).size,
        );
      case isImage(relation):
        return createImageRelation({
          styles: this.takeImageKeepingStyles((relation as Models.LayeredRegularRelation<Models.ImageRelationStyles>).styles.original),
          id: relation.id,
        });
      default: return null;
    }
  }

  private processTranslatedCallToAction(
    translatedCallToAction: Models.CallToAction,
    originalCallToAction: Models.CallToAction,
  ): Models.CallToAction {
    const { size, link } = originalCallToAction as Models.CallToAction;

    return {
      ...translatedCallToAction,
      size,
      link,
    };
  }

  private processTranslatedTextComponent(
    translatedText: Models.TextComponent,
    documents: Models.CombinedDocuments,
  ): Models.TextComponent {
    const {
      cleanText: text,
      referenceCitationIds: referenceCitations,
    } = this.removeReferenceIdAttributes(translatedText.text);

    let translatedTextComponent: Models.TextComponent = {
      ...translatedText,
      referenceCitations,
      text,
    };

    translatedTextComponent = replaceReferenceMarkersByEntities(
      translatedTextComponent,
      referenceId => documents[referenceId] && referenceId,
    );

    return translatedTextComponent;
  }

  private createTranslatedTextRelation(
    relation: Models.LayeredRegularRelation<Models.TextRelationStyles>,
    originalTextComponent: Models.TextComponent,
    translatedTextComponent: Models.TextComponent,
  ): Models.RegularRelation<Models.TextRelationStyles> {
    const { id, styles } = relation;
    const colors = this.colorsByRelationId.get(id);
    const fonts = this.fontsByRelationId.get(id);
    const brandStyles = this.brandStylesByRelationId.get(id);
    const brandStyleHasNotBeenChanged = this.brandStyleHasNotBeenChanged(relation);
    const defaultBrandStyleAvailable = this.isDefaultBrandStyleAvailable(relation);

    const translatedRelation = createTextRelation({
      styles: this.takeTextKeepingStyles(styles.original),
      id,
    });

    const keepingTextInlineStyles = !brandStyleHasNotBeenChanged
      ? this.takeTextRelationKeepingStyles(originalTextComponent, styles.original)
      : {} as Models.TextBrandStyles;

    switch (true) {
      case brandStyleHasNotBeenChanged: {
        const brandStyle = brandStyles && brandStyles.get(styles.original.brandStyleId);
        applyDefaultStyles(translatedTextComponent, translatedRelation);
        brandStyle && applyBrandStyle(translatedTextComponent, translatedRelation, brandStyle, colors, fonts);

        break;
      }
      case defaultBrandStyleAvailable: {
        const brandStyle = brandStyles.get(DefaultTextBrandStyle);
        let textBrandStyles: Models.TextBrandStyles;

        if (brandStyle) {
          textBrandStyles = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
          translatedRelation.styles.brandStyleId = DefaultTextBrandStyle;
        }
        applyDefaultStyles(translatedTextComponent, translatedRelation, _.merge({}, textBrandStyles, keepingTextInlineStyles));

        if (this.isDefaultStylesChanged(keepingTextInlineStyles, textBrandStyles)) {
          translatedRelation.styles.brandStyleChanged = true;
        }

        break;
      }
      default: {
        applyDefaultStyles(translatedTextComponent, translatedRelation, keepingTextInlineStyles);

        translatedRelation.styles.brandStyleId = _.get(styles, 'original.brandStyleId', null);
        translatedRelation.styles.brandStyleChanged = _.get(styles, 'original.brandStyleChanged', false);
      }
    }

    return translatedRelation;
  }

  private isDefaultBrandStyleAvailable(relation: Models.LayeredRelation): boolean {
    const originalBrandStyle = _.get(relation, 'original.brandStyleId', null);
    if (originalBrandStyle !== Styles.DefaultTextBrandStyle) {
      return false;
    }

    const sectionStyles = this.sectionStylesByRelationId.get(relation.id);
    const brandStyles = this.brandStylesByRelationId.get(relation.id);

    return sectionStyles && isDefaultBrandStyleAvailable(brandStyles, sectionStyles);
  }

  private brandStyleHasNotBeenChanged(relation: Models.LayeredRegularRelation<Models.TextRelationStyles>): boolean {
    const { brandStyleId, brandStyleChanged } = relation.styles.original;

    return brandStyleId && !brandStyleChanged;
  }

  private takeTextKeepingStyles(styles: Models.TextRelationStyles): Models.TextRelationStyles {
    const { alignment: { vertical } } = styles;

    return {
      ...this.takeCommonKeepingStyles(styles),
      alignment: { vertical, horizontal: undefined },
    };
  }

  private takeTextRelationKeepingStyles(textComponent: Models.TextComponent, styles: Models.TextRelationStyles): Models.TextInlineStyles {
    const {
      fontColor,
      bulletColor,
      fontSize,
      fontFamily,
      fontStyle,
      lineHeight,
      alignment: { horizontal },
    } = styles;

    const rawContent: Draft.RawDraftContentState = JSON.parse(textComponent.rawContent);
    const contentState: Draft.ContentState = Draft.convertFromRaw(rawContent);
    const blockMap: Draft.BlockMap = contentState.getBlockMap();
    const blockKeys = blockMap.valueSeq().toArray().map(block => block.getKey());

    const fontColorStyles = getInlineStylesFromBlocks(fontColor, blockKeys);
    const bulletColorStyles = getInlineStylesFromBlocks(bulletColor, blockKeys);
    const fontSizeStyles = getInlineStylesFromBlocks(fontSize, blockKeys);
    const fontFamilyStyles = getInlineStylesFromBlocks(fontFamily, blockKeys);
    const fontStyleStyles = getInlineStylesFromBlocks(fontStyle, blockKeys);
    const lineHeightStyles = getParagraphStylesFromBlocks(lineHeight, blockKeys) as TextLineHeightValue[];
    const textAlignStyles = getParagraphStylesFromBlocks(horizontal, blockKeys) as TextHorizontalAlignmentType[];

    const fontColorStyle = isStyleAppliedForWholeTextComponent(fontColorStyles)
      ? inlineStylesUtils.getFontColorFromStyle(fontColorStyles[0])
      : undefined;
    const bulletColorStyle = isStyleAppliedForWholeTextComponent(bulletColorStyles)
      ? inlineStylesUtils.getBulletColorFromStyle(bulletColorStyles[0])
      : undefined;
    const fontSizeStyle = isStyleAppliedForWholeTextComponent(fontSizeStyles)
      ? Number(inlineStylesUtils.getFontSizeFromStyle(fontSizeStyles[0]))
      : undefined;
    const fontFamilyStyle = isStyleAppliedForWholeTextComponent(fontFamilyStyles)
      ? inlineStylesUtils.getFontFamilyFromStyle(fontFamilyStyles[0])
      : undefined;
    const fontStyleStyle = isStyleAppliedForWholeTextComponent(fontStyleStyles)
      ? inlineStylesUtils.getFontStyleFromStyle(fontStyleStyles[0])
      : undefined;
    const lineHeightStyle = isStyleAppliedForWholeTextComponent(lineHeightStyles) ? lineHeightStyles[0] : undefined;
    const textAlignStyle = isStyleAppliedForWholeTextComponent(textAlignStyles) ? textAlignStyles[0] : undefined;

    return {
      fontColor: fontColorStyle,
      bulletColor: bulletColorStyle,
      fontSize: fontSizeStyle,
      fontFamily: fontFamilyStyle,
      characterStyle: fontStyleStyle,
      lineHeight: lineHeightStyle,
      textAlign: textAlignStyle,
    };
  }

  private takeCallToActionKeepingStyles(styles: Models.CallToActionStyles): Models.CallToActionStyles {
    const {
      alignment: { vertical, horizontal },
      assetBackgroundColor,
      assetBackgroundColorTint,
      assetBackgroundOpacity,
      assetBackgroundGradient,
      assetPadding,
      borderRadius,
      fontColor,
      fontColorTint,
      fontDecoration,
      fontFamily,
      fontSize,
      fontStyle,
      height,
      width,
      textAlignment,
    } = styles;

    return {
      ...this.takeCommonKeepingStyles(styles),
      alignment: { vertical, horizontal },
      assetBackgroundColor,
      assetBackgroundColorTint,
      assetBackgroundOpacity,
      assetBackgroundGradient,
      assetPadding,
      borderRadius,
      fontColor,
      fontColorTint,
      fontDecoration,
      fontFamily,
      fontSize,
      fontStyle,
      height,
      width,
      textAlignment,
    };
  }

  private takeImageKeepingStyles(styles: Models.ImageRelationStyles): Models.ImageRelationStyles {
    const {
      alignment: { vertical, horizontal },
      scale,
      link,
      mobileSettingsApplied,
      mobileAlignment,
      mobileScale,
      mobileViewImage,
    } = styles;

    return _.cloneDeep({
      ...this.takeCommonKeepingStyles(styles),
      alignment: { vertical, horizontal },
      scale,
      link,
      mobileSettingsApplied,
      mobileAlignment,
      mobileScale,
      mobileViewImage,
    });
  }

  private takeCommonKeepingStyles<T extends Models.CommonStyles>(styles: T): T {
    const {
      backgroundColor,
      backgroundColorTint,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      border,
      borderRadius,
      padding,
    } = styles;

    return {
      backgroundColor,
      backgroundColorTint,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      border,
      borderRadius,
      padding,
    } as T;
  }

  private removeReferenceIdAttributes(text: string): TranslationServiceModels.Result.RemoveReferenceIdAttributes {
    const referenceCitationIds: string[] = [];
    const cleanText = text.replace(/data-reference-id=["\']?([^"\'\s>]+)["\']/g, (_, id) => {
      referenceCitationIds.push(id);

      return '';
    });

    return {
      referenceCitationIds,
      cleanText,
    };
  }

  private isDefaultStylesChanged(
    sourceStyles: Models.TextInlineStyles,
    defaultStyles = DefaultTextStyles as Models.TextBrandStyles,
  ): boolean {

    return _.some(sourceStyles, (value, key) => (!!value && value !== defaultStyles[key]));
  }
}
