import Editor from '@draft-js-plugins/editor';
import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import AnchorsList from '../components/AnchorsList';

type Props = {
  document: Models.TextComponentMap;
  notEditable: boolean;
};

type EditorAnchors = {
  anchors: JSX.Element | null;
  setAnchors: (editorState: Draft.EditorState) => void;
};

export default function useEditorAnchors(
  editorRef: React.RefObject<Editor>,
  props: Props,
): EditorAnchors {
  const propsRef = useRef(props);
  propsRef.current = props;

  const [anchors, setAnchors] = useState<JSX.Element | null>(null);

  const updateAnchors = useCallback((editorState: Draft.EditorState): void => {
    const { document, notEditable } = propsRef.current;
    const editor = _.get(editorRef.current, 'editor.editor') as HTMLDivElement;

    if (!notEditable || !editor || !document) {
      return;
    }

    const appliedAnchorKeys = editorUtils.getAppliedAnchorKeys(editorState);
    setAnchors(<AnchorsList
      editor={editor}
      appliedAnchorKeys={appliedAnchorKeys}
      document={document}
    />);
  }, []);

  return { anchors, setAnchors: updateAnchors };
}
