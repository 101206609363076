import React, { PropsWithChildren } from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import styles from '../styles.module.scss';

type Props = PropsWithChildren<{
  onClose: () => void;
}>;

const Title: React.FunctionComponent<Props> = (props) => {
  const { onClose, children } = props;

  return (
    <div className={styles.title} >
      <div>
        {children}
      </div>
      <div>
        <Icon
          type={IconType.CLOSE}
          size="sm"
          onClick={onClose}
          color='secondary'
        />
      </div>
    </div>
  );
};

export default Title;
