import { call, select } from 'redux-saga/effects';

import { textComponentsForAssetsPanel } from 'containers/Documents/selectors';
import { UploadProjectFlowArg } from '../models';
import { getDocumentsOnArtboards } from './getDocumentsOnArtboards';

export function* deleteUnusedTextComponents(arg: UploadProjectFlowArg) {
  const { projectAssets } = arg;
  const documentsOnArtboards: ReturnTypeSaga<typeof getDocumentsOnArtboards> = yield call(getDocumentsOnArtboards);
  const textComponents: ReturnTypeSaga<typeof textComponentsForAssetsPanel> = yield select(textComponentsForAssetsPanel);
  const unusedTextComponents = textComponents.filter(textComponent => !documentsOnArtboards.has(textComponent.get('id')));

  unusedTextComponents.forEach((textComponent) => {
    const id = textComponent.get('id');

    delete projectAssets.documents[id];
  });
}
