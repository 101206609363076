import classNames from 'classnames';
import Immutable from 'immutable';
import React from 'react';
import { DropTarget, DropTargetCollector, DropTargetSpec } from 'react-dnd';
import ImagesLoadingObserver from 'react-on-images-loaded';

import { ProjectTypeContext } from 'App';
import HeightExceedingIndicator from 'components/HeightExceedingIndicator';
import { DragSourceType, ProjectsConfig } from 'const';
import Section from 'containers/Section';
import SSI from 'containers/SSI';
import * as Models from 'models';
import { backgroundColorFromRelation as backgroundColorFromSource } from 'utils/converters';
import { isVerticalSSIPosition } from 'utils/ssi/ssiPosition';
import { getBackgroundColor, getBackgroundImage } from 'utils/styles';
import { toPx } from 'utils/toPx';
import { ArtboardCollectedProps, ArtboardOwnProps, ArtboardProps } from './models';
import styles from './styles.module.scss';

const ArtboardDropTarget: DropTargetSpec<ArtboardProps> = {
  drop(props, monitor) {
    const { dropStoryCard } = props;
    const { storyCardId, screenOrder } = monitor.getItem() as Models.StoryCardDragObject;

    dropStoryCard(storyCardId, screenOrder);
  },
};

const collect: DropTargetCollector<ArtboardCollectedProps, ArtboardOwnProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  } as ArtboardCollectedProps;
};

class Artboard extends React.PureComponent<ArtboardProps> {
  private layoutsRefs = Immutable.Map<string, HTMLDivElement>();

  componentWillMount(): void {
    this.props.artboardWillBeMounted();
  }

  componentDidMount(): void {
    this.props.artboardMounted();
  }

  componentDidUpdate(): void {
    this.props.artboardMounted();
  }

  componentWillUpdate(nextProps: ArtboardProps): void {
    this.props.artboardWillBeMounted();

    const currentArtboardId = this.props.artboard.get('id');
    const nextArtboardId = nextProps.artboard.get('id');

    if (currentArtboardId !== nextArtboardId) {
      this.layoutsRefs = Immutable.Map();
    }
  }

  private saveLayoutRef = (layoutId: string, element: HTMLDivElement): void => {
    this.layoutsRefs = element ? this.layoutsRefs.set(layoutId, element) : this.layoutsRefs.delete(layoutId);
  };

  private getLayoutsRefs = (): Immutable.Map<string, HTMLDivElement> => this.layoutsRefs;

  private renderSection = (section: Models.ExtendedSectionMap): JSX.Element => {
    const { layoutsBySectionId, sectionsHeight } = this.props;
    const sectionId = section.get('id');
    const sectionName = section.get('name');

    return (
      <Section
        section={section}
        layouts={layoutsBySectionId.get(sectionId)}
        sectionHeight={sectionsHeight.get(sectionName)}
        key={sectionId}
        saveLayoutRef={this.saveLayoutRef}
        getLayoutsRefs={this.getLayoutsRefs}
      />
    );
  };

  private renderSections = (): Immutable.List<JSX.Element> | JSX.Element => {
    const { sections, artboard, sectionsHeight, sectionsWidth } = this.props;

    return sections.map((section) => {
      const ssi = artboard.get('ssi');
      const sectionName = section.get('name');

      if (!ssi || ssi.get('section') !== sectionName) {
        return this.renderSection(section);
      }

      return (
        <div
          className={classNames({ [styles.verticalWrapper]: isVerticalSSIPosition(ssi.get('position')) })}
          key={`${artboard.get('id')}${sectionName}`}
        >
          {this.renderSection(section)}
          <SSI
            sectionHeight={sectionsHeight.get(sectionName)}
            sectionWidth={sectionsWidth.get(sectionName)}
          />
        </div>
      );
    });
  };

  private onImagesLoaded = (): void => {
    this.props.artboardImagesLoaded();
  };

  render() {
    const { artboard, colors, images, width, connectDropTarget, isOver, areImagesBeingDownloaded } = this.props;
    const backgroundImage = artboard.getIn(['styles', 'backgroundImage']);
    const height = artboard.getIn(['styles', 'height']);

    return connectDropTarget((
      <div
        className={styles.Artboard}
        style={{
          width: toPx(width),
          ...getBackgroundImage(backgroundImage, images),
          ...getBackgroundColor(backgroundColorFromSource(colors, artboard)),
        }}
      >
        <ImagesLoadingObserver
          onLoaded={this.onImagesLoaded}
          // HACK: ImagesLoadingObserver initiates its logic on componentDidMount,
          // 1) artboard id - is needed as part of key to wait images loading after switching between screens
          // 2) areImagesBeingDownloaded - while images' renditions are being loaded there are no <img />
          // tags within the artboard and nothing to wait, <img/> tags will be created after images' renditions are loaded
          // since areImagesBeingDownloaded is a part of key, ImagesLoadingObserver will be waiting images after they are complete loaded.
          key={`${artboard.get('id')}:${!!areImagesBeingDownloaded}`}
        >
          {this.renderSections()}
          <ProjectTypeContext.Consumer>
            {(projectType) => {
              const { areScreensResizable } = ProjectsConfig[projectType];

              return areScreensResizable && height && (
                <HeightExceedingIndicator
                  offsetTop={height}
                  isShown={true}
                />
              );
            }}
          </ProjectTypeContext.Consumer>
        </ImagesLoadingObserver>
        {isOver && <div className={styles.overlay} />}
      </div>
    ));
  }
}

export default DropTarget<ArtboardOwnProps, ArtboardCollectedProps>(
  [DragSourceType.STORY_CARD],
  ArtboardDropTarget,
  collect,
)(Artboard);
