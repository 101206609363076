import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { EntityType } from 'const';
import { setLastEditedLayoutId } from 'containers/App/actions';
import { deleteDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createLayeredRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { toImmutable } from 'utils/immutable';
import { intlGet } from 'utils/intlGet';
import { getRelationStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { Action } from '../models';
import { deleteRelationRecursively } from '../services/deleteRelationRecursively';
import { getTextComponentIdsToDelete } from '../services/getTextComponentIdsToDelete';

export function* deleteCell(action: Action.IDeleteCell) {
  try {
    const { layoutId, relationId } = action.payload;
    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const layouts: ReturnTypeSaga<typeof layoutsSelectors.layouts> = yield select(layoutsSelectors.layouts);
    const layeredLayouts: ReturnTypeSaga<typeof layoutsSelectors.layeredLayouts> = yield select(layoutsSelectors.layeredLayouts);
    const layout = layouts.get(layoutId) as Models.LayoutMap;

    const relation = relations.get(relationId) as Models.LayeredRegularRelationMap;
    const documentId = relation.get('documentId');
    const entityType = relation.get('entityType');

    if (documentId.get(layer)) {
      const stylesToBeKept = getRelationStylesToBeKept(relation);
      const newEntityType = entityType === EntityType.CALL_TO_ACTION ? EntityType.TEXT : entityType;
      const newRelation = createLayeredRelation({ id: relationId, entityType: newEntityType, styles: stylesToBeKept });
      const updatedRelations = relations.set(relationId, toImmutable(newRelation));

      const textComponentIdsToDelete: ReturnTypeSaga<typeof getTextComponentIdsToDelete> = yield call(
        getTextComponentIdsToDelete,
        layout,
        [relationId], // TODO: make sure relation is used once only
        documents,
        relations,
        layer,
      );
      yield put(batchActions([
        saveAppState(),
        deleteDocuments(textComponentIdsToDelete),
        setLastEditedLayoutId(layoutId),
        setRelations(updatedRelations),
      ]));

      return;
    }

    const accepted: boolean = yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title: intlGet('ConfirmationWindow.Title', 'DeleteCell'),
        message: intlGet('ConfirmationWindow.Message', 'RemoveCell'),
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Delete'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'Cancel'),
      },
    );

    if (!accepted) {
      return;
    }

    // delete the cell
    const updatedRelations = deleteRelationRecursively(relationId, relations, layeredLayouts, layer);
    const deletedRelationIds = relations.keySeq().filter(id => !updatedRelations.get(id)).toArray();

    const textComponentIdsToDelete: ReturnTypeSaga<typeof getTextComponentIdsToDelete> = yield call(
      getTextComponentIdsToDelete,
      layout,
      deletedRelationIds,
      documents,
      relations,
      layer,
    );

    yield put(batchActions([
      saveAppState(),
      deleteDocuments(textComponentIdsToDelete),
      setLastEditedLayoutId(layoutId),
      setRelations(updatedRelations),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.deleteCell', 'DeleteCell');
  }
}
