import { useEffect, useState } from 'react';

declare global {
  interface Window {
    GET_EDITOR_MODE: () => string;
    SET_EDITOR_MODE: (mode: string) => void;
  }
}

export enum EditorMode {
  DRAFTJS = 'draftjs',
  LEXICAL = 'lexical',
  BOTH = 'both',
}
let CURRENT_MODE: EditorMode = EditorMode.DRAFTJS;

class EditorModeEvent extends Event {
  static readonly TYPE = 'editor-mode';

  constructor(readonly mode: EditorMode) {
    super(EditorModeEvent.TYPE);
  }
}

const eventTarget = new EventTarget();
eventTarget.addEventListener(EditorModeEvent.TYPE, (event: EditorModeEvent) => {
  CURRENT_MODE = event.mode;
});

window.GET_EDITOR_MODE = (): string => CURRENT_MODE;
window.SET_EDITOR_MODE = (mode: string): void => {
  if (!(Object.values(EditorMode) as string[]).includes(mode)) {
    // eslint-disable-next-line no-console
    console.error('wrong mode');
  }
  eventTarget.dispatchEvent(new EditorModeEvent(mode as EditorMode));
};

export function useEditorToggle(): EditorMode {
  const [mode, setMode] = useState<EditorMode>(CURRENT_MODE);

  useEffect(() => {
    const listener = (event: EditorModeEvent): void => setMode(event.mode);
    eventTarget.addEventListener(EditorModeEvent.TYPE, listener);

    return () => eventTarget.removeEventListener(EditorModeEvent.TYPE, listener);
  }, []);

  return mode;
}
