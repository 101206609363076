import { DocumentClassification, DocumentSubtype, DocumentType, DocumentsSource, EntityType } from 'const';
import * as Models from 'models';
import { baseDocumentFactory } from './baseDocumentFactory';

export function groupLayoutDocumentFactory(args = {} as Partial<Models.GroupLayoutDocument>): Models.GroupLayoutDocument {
  const {
    entities,
    previewUrl,
    isEdited = false,
    documentSource = [DocumentsSource.COMPONENTS],
    renditions = {},
    needToUpdatePreview = false,
  } = args;

  return {
    ...baseDocumentFactory(args),
    type: DocumentType.COMPONENT,
    subtype: DocumentSubtype.ASSEMBLER,
    classification: DocumentClassification.SURFACE_LAYOUT,
    entityType: EntityType.GROUP_LAYOUT,
    documentSource,
    previewUrl,
    isEdited,
    entities,
    renditions,
    needToUpdatePreview,
  };
}
