import _ from 'lodash';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import ColorPicker from 'components/Toolbar/Dropdowns/ColorPicker';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { getBackgroundColor } from 'utils/styles';
import styles from './styles.module.scss';

type Props = {
  active: boolean;
  color?: Models.BrandColorMap;
  controlState?: Models.ControlState;
  defaultColor: string;
  onToggle: () => void;
  toggleBulletColor(color: Models.BrandColorMap): void;
};

const BulletedListControl: React.FunctionComponent<Props> = (props) => {
  const onToggle = (event: React.SyntheticEvent<EventTarget>): void => {
    event.preventDefault();
    props.onToggle();
  };

  const { active, color, defaultColor, toggleBulletColor, controlState = ControlState } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  return (
    <div
      className={styles.BulletedListControl}
      data-title={Title.TEXT_ALIGNMENT_HORIZONTAL_UNORDERED_LIST}
    >
      <div
        className={styles.button}
        onMouseDown={onToggle}
      >
        <Icon
          className={styles.bulletIcon}
          type={IconType.TOOLBAR_UNORDERED_LIST}
          size="badge"
          color={active ? 'primary' : 'secondary'}
          hoverColor="primary-hover"
          hoverTooltipText={intlGet('EditorToolbar.Tooltip', IconType.TOOLBAR_UNORDERED_LIST)}
        />
        <div className={styles.BulletColor} style={getBackgroundColor(color, defaultColor)} />
      </div>

      <Control
        className={styles.dropdown}
        controlState={controlState}
        disableHover={!active}
        onToggle={active ? toggleDropdown : _.noop}
        ref={control}
        size="xxs"
        state={state}
        title={Title.TEXT_ALIGNMENT_HORIZONTAL_UNORDERED_LIST}
        type={IconType.CHEVRON_DOWN}
        toggleControl={true}
        tooltipEnabled={false}
      >
        {isOpen &&
          <ColorPicker
            activeColor={color}
            toggleColor={toggleBulletColor}
            title={intlGet('EditorToolbar.BulletColorDropdown', 'Title')}
          />
        }
      </Control>
    </div>
  );
};

export default BulletedListControl;
