import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import ImageInputBlock from 'components/ImageInputBlock';
import { ImageInputStatus, ResolutionType } from 'const';
import { getImageResolution } from 'utils/getImageResolution';
import { intlGet } from 'utils/intlGet';
import { IImageDetailsProps } from './models';
import styles from './styles.module.scss';

const ImageDetails: React.FunctionComponent<IImageDetailsProps> = (props) => {
  const { options, hideModal, updateImage } = props;
  const document = options.get('document');
  const allowChange = options.get('allowChange');
  const name = document.get('name');
  const number = document.get('number');
  const status = document.get('status');
  const product = document.get('product');
  const productName = product && product.first();
  const internalImageInfo = document.get('_internalInfo');

  const [imageFiles, setImageFiles] = useState<PartialRecord<ResolutionType, File>>({});
  const [previewImageStatus, setPreviewImageStatus] = useState(null);
  const [fullSized, setFullSized] = useState(false);
  const toggleFullSizedState = (): void => setFullSized(fullSized => !fullSized);

  const setImageFilesAndStatus = (imageFiles: PartialRecord<ResolutionType, File>, previewImageStatus: ImageInputStatus): void => {
    setImageFiles(imageFiles);
    setPreviewImageStatus(previewImageStatus);
  };

  const onSaveClick: React.MouseEventHandler<HTMLButtonElement> = (): void => {
    updateImage(
      document.get('id'),
      {
        imageFiles,
        documentName: document.get('name'),
        subtype: document.get('subtype'),
        classification: document.get('classification'),
      },
    );
  };

  const getPreviewImageSource = (): string => {
    if (!allowChange) {
      return internalImageInfo && internalImageInfo.get('source');
    }

    switch (previewImageStatus) {
      case ImageInputStatus.EMPTY:
        return '';
      case ImageInputStatus.INITIAL:
        return internalImageInfo && internalImageInfo.get('source');
      case ImageInputStatus.ADDED:
      case ImageInputStatus.CHANGED:
        return imageFiles.low && URL.createObjectURL(imageFiles.low);
      default:
        return '';
    }
  };

  const imageSource = getPreviewImageSource();
  const resolution = getImageResolution(internalImageInfo);
  const isSaveButtonEnabled = !imageFiles.source && !isEmpty(imageFiles) || !!imageFiles.source && !!imageFiles.low;
  const image = React.createRef<HTMLImageElement>();

  return (
    <div className={styles.ImageDetails}>
      <header>
        <div className={styles.info}>
          <div className={styles.title}>{name}</div>
        </div>
        <Icon className={styles.closeIcon} onClick={hideModal} type={IconType.CLOSE} size="sm-md" color="primary" />
      </header>
      <section>
        <div className={styles.description}>
          <div className={styles.metadata}>
            {/* product and status can be absent, e.g. in components from ASM7 */}
            {status && <span className={styles.status}>{ status.toUpperCase() }</span>}
            <span className={styles.number}>{number}</span>
            {productName && <span className={styles.productName}>{productName}</span>}
          </div>
          <div>{resolution}</div>
        </div>
        <div className={classNames(styles.preview, { [styles.preview__changeable]: allowChange })}>
          {
            imageSource
              ?
              <div className={styles.imageContainer}>
                <div className={styles.spacer} />
                <img
                  ref={image}
                  className={classNames(styles.image, { [styles.fullSize]: fullSized })}
                  src={imageSource}
                  onClick={toggleFullSizedState}
                />
                <div className={styles.spacer} />
              </div>
              :
              <div className={styles.icon}>
                <Icon type={IconType.IMAGE} size="xxl" color="grey" />
                <span className={styles.iconText}>{intlGet('ProjectPanel.ImageNotAvailable', 'Preview')}</span>
              </div>
          }
        </div>
        {allowChange &&
          <ImageInputBlock
            setImageFiles={setImageFilesAndStatus}
            imageFiles={imageFiles}
            initialImage={document}
          />
        }
      </section>
      {allowChange &&
        <footer className={styles.footer}>
          <Button variant="secondary" onClick={hideModal}>
            {intlGet('ImageDetailsWindow.Button', 'Cancel')}
          </Button>
          <Button variant="primary" onClick={onSaveClick} disabled={!isSaveButtonEnabled}>
            {intlGet('ImageDetailsWindow.Button', 'SaveChanges')}
          </Button>
        </footer>
      }
    </div>
  );
};

export default ImageDetails;
