import _ from 'lodash';

import { keyMirrorWithMap as keyMirror } from 'utils/keyMirror';
import { upperCamelCase } from 'utils/string';

export const Title = keyMirror(
  {
    ALT_TAG: null,
    ASSET_ALIGNMENT_GROUP: null,
    ASSET_ALIGNMENT_HORIZONTAL_CENTER: null,
    ASSET_ALIGNMENT_HORIZONTAL_LEFT: null,
    ASSET_ALIGNMENT_HORIZONTAL_RIGHT: null,
    ASSET_ALIGNMENT_VERTICAL_CENTER: null,
    ASSET_ALIGNMENT_VERTICAL_TOP: null,
    ASSET_ALIGNMENT_VERTICAL_BOTTOM: null,
    ASSET_COLOR: null,
    BACKGROUND_COLOR: null,
    BACKGROUND_IMAGE: null,
    BORDER: null,
    BORDER_RADIUS: null,
    COLUMN_WIDTH: null,
    CLEAR_FORMATTING: null,
    FIT_TO_CELL: null,
    FONT_COLOR: null,
    FONT_DECORATION_BOLD: null,
    FONT_DECORATION_ITALIC: null,
    FONT_DECORATION_UNDERLINE: null,
    FONT_FAMILY: null,
    FONT_SIZE: null,
    FONT_SUBSCRIPT: null,
    FONT_SUPERSCRIPT: null,
    IMAGE_MOBILE_SETTINGS: null,
    IMAGE_DESKTOP_SETTINGS: null,
    LINE_HEIGHT: null,
    LINK: null,
    PADDING: null,
    PADDING_EXTERNAL: null,
    PADDING_INTERNAL: null,
    RESPONSIVE_LAYOUT: null,
    SCROLLABLE_LAYOUT: null,
    TEXT_ALIGNMENT_HORIZONTAL_CENTER: null,
    TEXT_ALIGNMENT_HORIZONTAL_LEFT: null,
    TEXT_ALIGNMENT_HORIZONTAL_RIGHT: null,
    TEXT_ALIGNMENT_HORIZONTAL_JUSTIFY: null,
    TEXT_ALIGNMENT_HORIZONTAL_UNORDERED_LIST: null,
    TEXT_ALIGNMENT_HORIZONTAL: null,
    TEXT_ALIGNMENT_VERTICAL: null,
    TEXT_STYLE: null,
    ABBREVIATION: null,
  },
  // Make values converted to upper camel case
  // TEXT_STYLE => TextStyle
  upperCamelCase,
);
export const NAMESPACE = 'Control';
// used only on old Toolbar, on new Toolbar namespace added on Control level
// TODO: remove it with removal of old Toolbar
export const TitleWithNamespace = _.mapValues(Title, value => `${value}@${NAMESPACE}`);

export enum HOVER_HINT {
  CELL = 'BorderRadiusCell',
  CALL_TO_ACTION = 'BorderRadiusCallToAction',
  LAYOUT = 'BorderRadiusLayout',
}
