import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as RelationsSelectors from 'containers/Relations/selectors';
import * as Models from 'models';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import * as AbbreviationsSelectors from '../../store/selectors';
import Container from './components/Container';
import { AbbreviationWithRelation } from './types';

type Props = {
  layoutId: string;
};

const AbbreviationsIndicator: React.FunctionComponent<Props> = (props) => {
  const { layoutId } = props;

  const relations = useSelector(
    (state: Models.AppState.StateMap) => RelationsSelectors.relationsByRegularLayoutId(state).get(layoutId),
  );
  const abbreviationsListByRelationId = useSelector(
    AbbreviationsSelectors.selectAbbreviationIdsByRelationId,
  );

  const abbreviationsList = useMemo<AbbreviationWithRelation[]>(() => {
    if (!relations?.size) {
      return [];
    }

    const list: AbbreviationWithRelation[] = [];
    relations.forEach((relation, relationId) => {
      if (!isRegularRelation(relation)) {
        return;
      }
      const numbers: Record<string, number> = {};
      (abbreviationsListByRelationId[relationId] || []).forEach((abbreviationId) => {
        numbers[abbreviationId] = numbers[abbreviationId] ? numbers[abbreviationId] + 1 : 1;
        list.push({
          relationId,
          relationType: relation.get('entityType'),
          abbreviationId,
          abbreviationNumber: numbers[abbreviationId],
        });
      });
    });

    return list;
  }, [relations, abbreviationsListByRelationId]);

  if (!abbreviationsList.length) {
    return null;
  }

  return (
    <Container abbreviationsList={abbreviationsList}/>
  );
};

export default memo(AbbreviationsIndicator);
