import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_ACTIVE_SECTION: null,
    ADD_SELECTED_ARTBOARD_COMPONENT: null,
    ADD_SELECTED_ASSET_PANEL_COMPONENT: null,
    DELETE_SELECTED_CITATION: null,
    REMOVE_SELECTED_ARTBOARD_COMPONENT: null,
    REMOVE_SELECTED_ASSET_PANEL_COMPONENT: null,
    SET_ACTIVE_TAB: null,
    SET_ASSET_ID_TO_SCROLL: null,
    SET_CONTENT_FILTER: null,
    SET_SELECTED_CITATION: null,
    SET_SELECTED_TEXT_COMPONENT: null,
    TOGGLE_SECTION: null,
  },
  'ProjectPanel',
);
