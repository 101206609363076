import { stateToHTML } from 'draft-js-export-html';
import { ReferencesEditor } from 'modules/draftjs';
import { useEditor } from 'modules/References/hooks/useEditor';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Toolbar from 'components/Toolbar/ReferenceCitation';
import { Priority } from 'hooks/useClickOutside/constants';
import { cleanUpHtml } from 'utils/cleanUpHtml';
import { getReferenceCitationStateToHTMLOptions, hasText } from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import { AddReferenceCitationProps } from './models';
import styles from './styles.module.scss';

const AddReferenceCitation: React.FunctionComponent<AddReferenceCitationProps> = (props) => {
  const { addCitation, hideModal, layoutId } = props;
  const {
    disableLink,
    editor,
    editorState,
    fontStyles,
    link,

    onEditorStateChange,
    clearFormatting,
    toggleFontStyle,
    toggleScriptStyle,
    toggleLink,
  } = useEditor();

  const onSaveClick = (): void => {
    const text = stateToHTML(editorState.getCurrentContent(), getReferenceCitationStateToHTMLOptions());
    addCitation(cleanUpHtml(text));
  };

  return (
    <div className={styles.AddReferenceCitation}>
      <header className={styles.header}>
        <div>{intlGet('AddCitationModal', 'Title')}</div>
        <Icon className={styles.closeIcon} onClick={hideModal} type={IconType.CLOSE} size="sm-md" color="primary" />
      </header>
      <main className={styles.main}>
        <div className={styles.tip}>{intlGet('AddCitationModal', 'Tip')}</div>
        <div className={styles.content}>
          <Icon type={IconType.REFERENCE_CITATION} size="sm-md" color="primary" />
          <div className={styles.editor}>
            <Toolbar
              className={styles.toolbar}
              showTitle={false}
              clearFormatting={clearFormatting}
              fontStyles={fontStyles}
              toggleFontStyle={toggleFontStyle}
              toggleScriptStyle={toggleScriptStyle}
              disableLink={disableLink}
              link={link}
              toggleLink={toggleLink}
              layoutId={layoutId}
              priority={Priority.MODAL_WINDOW_CONTROL}
              renderViaPortal={false}
            />
            <ReferencesEditor
              ref={editor}
              placeholder={intlGet('AddCitationModal', 'Placeholder')}
              editorState={editorState}
              onEditorChange={onEditorStateChange}
            />
          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <Button variant="secondary" onClick={hideModal}>{intlGet('AddCitationModal', 'Cancel')}</Button>
        <Button variant="primary" onClick={onSaveClick} disabled={!hasText(editorState)}>{intlGet('AddCitationModal', 'Save')}</Button>
      </footer>
    </div>
  );
};

export default AddReferenceCitation;
