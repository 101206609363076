import React from 'react';

import Icon from 'components/Icon';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { TEXT_VERTICAL_ALIGNMENTS } from 'const';
import { TextVerticalAlignmentProps } from './models';
import styles from './styles.module.scss';

const TextVerticalAlignment: React.FunctionComponent<TextVerticalAlignmentProps> = (props) => {
  const { alignment, toggleAlignment } = props;

  return (
    <Dropdown className={styles.TextVerticalAlignment}>
      {TEXT_VERTICAL_ALIGNMENTS.map((
        ({ icon, hint, type }) => (
          <div className={styles.item} key={type} onClick={toggleAlignment.bind(null, type)}>
            <Icon type={icon} size="badge" color={type === alignment ? 'primary' : 'secondary'} />
            <span className={styles.hint}>{hint}</span>
          </div>
        )
      ))}
    </Dropdown>
  );
};

export default TextVerticalAlignment;
