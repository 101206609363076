import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { intlGet } from 'utils/intlGet';
import { IDocumentDuplicateItemProps } from './models';
import styles from './styles.module.scss';

const DocumentDuplicateItem: React.FunctionComponent<IDocumentDuplicateItemProps> = (props) => {
  const { isSelected, link, documentNumber, documentName, documentStatus, imageSource, onSelect, iconType, iconSize } = props;
  const selectItem: React.MouseEventHandler<HTMLLIElement> = () => onSelect(documentNumber);

  return (
    <li
      className={classNames(styles.DocumentDuplicateItem, {
        [styles.selected]: isSelected,
      })}
      onClick={selectItem}
    >
      {
        imageSource
          ? <img src={imageSource} className={classNames(styles.box, styles.box__image)} />
          : <div className={classNames(styles.box, styles.box__icon)}>
            <Icon type={iconType} size={iconSize} color="light-grey" />
          </div>
      }
      <div className={styles.imageInfo}>
        <span className={styles.imageInfo_primary}>{documentName}</span>
        <div className={styles.imageInfo_secondary}>
          <a href={link} target="_blank">{documentNumber}</a>
          <span>{intlGet('DocumentDuplicatesWindow', 'Status', { documentStatus })}</span>
        </div>
      </div>
    </li>
  );
};

export default DocumentDuplicateItem;
