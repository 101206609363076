import { AUTOGEN_COLORS_CATEGORY, AUTOGEN_COLOR_ID, AUTOGEN_COLOR_NAME } from 'const';
import { BrandColor, ColorsCategory } from 'models';

/**
 * Creates Brand Color from Brand Style
 * @param backgroundColor
 */
export const createBrandColor = (backgroundColor: string): BrandColor => ({
  id: `${AUTOGEN_COLOR_ID}${backgroundColor}`,
  name: `${AUTOGEN_COLOR_NAME}${backgroundColor}`,
  HEX: backgroundColor,
  CMYK: null,
  RGB: null,
});

export const createColorsCategory = (): ColorsCategory => ({
  name: AUTOGEN_COLORS_CATEGORY,
  visible: false,
  brandColors: [],
});
