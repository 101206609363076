import { MutableRefObject, RefObject, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateCellsHeight } from 'containers/ArtboardPreview/actions';

export const useUpdateCellsHeight = (
  relationId: string,
  elementRef: MutableRefObject<HTMLDivElement | undefined> | RefObject<HTMLDivElement>,
  extraHeight = 0,
): void => {
  const dispatch = useDispatch();
  const extraHeightRef = useRef(extraHeight);

  useEffect(
    () => {
      const updateCellHeight = (height: number): void => {
        dispatch(updateCellsHeight(relationId, height - extraHeightRef.current));
      };
      const resizeObserver = new ResizeObserver((entries) => {
        updateCellHeight(entries[0].target.scrollHeight);
      });

      if (elementRef.current) {
        resizeObserver.observe(elementRef.current);
        updateCellHeight(elementRef.current.offsetHeight);
      }

      return () => resizeObserver.disconnect();
    },
    [relationId, elementRef.current],
  );
};
