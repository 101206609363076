// PromoMats errors
export enum PromoMats {
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  MALFORMED_URL = 'MALFORMED_URL',
  METHOD_NOT_SUPPORTED = 'METHOD_NOT_SUPPORTED',
  INACTIVE_USER = 'INACTIVE_USER',
  NO_PASSWORD_PROVIDED = 'NO_PASSWORD_PROVIDED',
  USERNAME_OR_PASSWORD_INCORRECT = 'USERNAME_OR_PASSWORD_INCORRECT',
  USER_LOCKED_OUT = 'USER_LOCKED_OUT',
  PASSWORD_CHANGE_REQUIRED = 'PASSWORD_CHANGE_REQUIRED',
  INVALID_SESSION_ID = 'INVALID_SESSION_ID',
  PARAMETER_REQUIRED = 'PARAMETER_REQUIRED',
  INVALID_DATA = 'INVALID_DATA',
  INSUFFICIENT_ACCESS = 'INSUFFICIENT_ACCESS',
  INSUFFICIENT_ACCESS_UPLOAD = 'INSUFFICIENT_ACCESS_UPLOAD',
  OPERATION_NOT_ALLOWED = 'OPERATION_NOT_ALLOWED',
  ATTRIBUTE_NOT_SUPPORTED = 'ATTRIBUTE_NOT_SUPPORTED',
  INVALID_FILTER = 'INVALID_FILTER',
  INCORRECT_QUERY_SYNTAX_ERROR = 'INCORRECT_QUERY_SYNTAX_ERROR',
  RACE_CONDITION = 'RACE_CONDITION',
  EXCEEDS_FILE_MAX_SIZE = 'EXCEEDS_FILE_MAX_SIZE',
  API_LIMIT_EXCEEDED = 'API_LIMIT_EXCEEDED',
  CONFIGURATION_MODE_ENABLED = 'CONFIGURATION_MODE_ENABLED',
  SDK_ERROR = 'SDK_ERROR',
}

// project configuration errors
export enum ProjectConfiguration {
  MULTIPLE_JSON_FILES = 'MULTIPLE_JSON_FILES',
  INVALID_JSON_FILE = 'INVALID_JSON_FILE',
  ASSEMBLER_7_JSON_FILE = 'ASSEMBLER_7_JSON_FILE',

  MULTIPLE_MASTER_SCREENS = 'MULTIPLE_MASTER_SCREENS',
  NO_MASTER_SCREEN = 'NO_MASTER_SCREEN',
  INVALID_MASTER_SCREEN = 'INVALID_MASTER_SCREEN',
  INVALID_PREVIEW_OPTION_NAME = 'INVALID_PREVIEW_OPTION_NAME',

  NO_SCREEN_DEFINITION = 'NO_SCREEN_DEFINITION',
  INVALID_SCREEN_DEFINITION = 'INVALID_SCREEN_DEFINITION',

  MULTIPLE_SCREEN_TEMPLATES = 'MULTIPLE_SCREEN_TEMPLATES',
  NO_SCREEN_TEMPLATE = 'NO_SCREEN_TEMPLATE',
  INVALID_SCREEN_TEMPLATE = 'INVALID_SCREEN_TEMPLATE',

  MULTIPLE_BRAND_STYLES = 'MULTIPLE_BRAND_STYLES',
  INVALID_BRAND_STYLES = 'INVALID_BRAND_STYLES',

  MULTIPLE_BRAND_DEFINITIONS = 'MULTIPLE_BRAND_DEFINITIONS',
  INVALID_BRAND_DEFINITIONS = 'INVALID_BRAND_DEFINITIONS',
}

// any custom errors
export enum Assembler {
  CORRUPTED_IMAGE = 'CORRUPTED_IMAGE',
  NO_INTERNET_CONNECTION = 'NO_INTERNET_CONNECTION',
  SAVE_LOCALLY = 'SAVE_LOCALLY',
  UNCLASSIFIED_ERROR = 'UNCLASSIFIED_ERROR',

  // comes from server
  HIGH_IMAGES_DATA = 'HIGH_IMAGES_DATA',
  HTML_PACKAGE_ARCHIVE = 'HTML_PACKAGE_ARCHIVE',
  HTML_GENERATION = 'HTML_GENERATION',
  INCORRECT_FOLDER_STRUCTURE = 'INCORRECT_FOLDER_STRUCTURE',
  IMPORT_TRANSLATION_PACKAGE = 'IMPORT_TRANSLATION_PACKAGE',
  INVALID_TRANSLATION_PROJECT_LANGUAGE = 'INVALID_TRANSLATION_PROJECT_LANGUAGE',
  INVALID_TRANSLATION_PROJECT_NUMBER = 'INVALID_TRANSLATION_PROJECT_NUMBER',
  INVALID_XLIFF_FILE = 'INVALID_XLIFF_FILE',
  MISSING_TRANSLATED_IMAGES = 'MISSING_TRANSLATED_IMAGES',
  NO_XLIFF_FILE = 'NO_XLIFF_FILE',
  NO_TRANSLATION_CONTROL_FILE = 'NO_TRANSLATION_CONTROL_FILE',
  PDF_GENERATION = 'PDF_GENERATION',
}
