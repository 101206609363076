import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_EDITOR, FORMAT_ELEMENT_COMMAND, FORMAT_TEXT_COMMAND, LexicalEditor, createCommand } from 'lexical';
import * as Constants from 'const';

export const BASE_PROPS_COMMAND = {
  BLOCK_LINE_HEIGHT: createCommand<Constants.TextLineHeightValue>(),
  BLOCK_TYPE: createCommand<Constants.TextHorizontalAlignmentType | undefined>(),
  SCRIPT_STYLE: createCommand<Constants.ScriptType>(),
  TEXT_NOWRAP: createCommand(),
} as const;

export function registerEditorPropsCommands(editor: LexicalEditor): () => void {
  return mergeRegister(

    editor.registerCommand(
      BASE_PROPS_COMMAND.BLOCK_LINE_HEIGHT,
      (value) => {
        // eslint-disable-next-line no-console
        console.error('NOT IMPLEMENTED', value);

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      BASE_PROPS_COMMAND.BLOCK_TYPE,
      (value) => {
        switch (value) {
          case Constants.TextHorizontalAlignmentType.LEFT:
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
            break;
          case Constants.TextHorizontalAlignmentType.CENTER:
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
            break;
          case Constants.TextHorizontalAlignmentType.RIGHT:
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'end');
            break;
          case Constants.TextHorizontalAlignmentType.JUSTIFY:
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
            break;
          default:
            // Constants.TextHorizontalAlignmentType.UNORDERED_LIST should not be set,
            // but we can not restrict it in types as it will cause ts issues in existing code

            // eslint-disable-next-line no-console
            console.error(`unknown block type ${value ?? '-'}`);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      BASE_PROPS_COMMAND.SCRIPT_STYLE,
      (value) => {
        switch (value) {
          case Constants.ScriptType.SUPERSCRIPT:
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
            break;
          case Constants.ScriptType.SUBSCRIPT:
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
            break;
          default:
            // eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
            console.error(`unknown script type ${value || '-'}`);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      BASE_PROPS_COMMAND.TEXT_NOWRAP,
      () => {
        // eslint-disable-next-line no-console
        console.error('NOT IMPLEMENTED');

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );
}
