import React from 'react';
import { Button } from 'react-bootstrap';

import { ConfirmationProps } from './models';
import styles from './styles.module.scss';

const Confirmation: React.FunctionComponent<ConfirmationProps> = (props) => {
  const { hideModal, modalWindowId, options } = props;

  const handleAccept = (): void => {
    hideModal(modalWindowId, true);
  };

  const handleDecline = (): void => {
    hideModal(modalWindowId, false);
  };

  const title = options.get('title');
  const message = options.get('message');
  const acceptLabel = options.get('acceptLabel');
  const declineLabel = options.get('declineLabel');

  return (
    <div className={styles.Confirmation}>
      <header>{title}</header>
      <section>{message}</section>
      <footer>
        <Button onClick={handleDecline} variant="secondary">{declineLabel}</Button>
        <Button onClick={handleAccept} variant="primary">{acceptLabel}</Button>
      </footer>
    </div>
  );
};

export default Confirmation;
