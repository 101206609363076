import React from 'react';

import { AlignmentValue, AssetAlignmentDirection, BackgroundImageType } from 'const';
import * as Models from 'models';

const backgroundStylesByType: PartialRecord<BackgroundImageType, React.CSSProperties> = {
  [BackgroundImageType.COVER]: { backgroundSize: 'cover', backgroundRepeat: 'no-repeat' },
  [BackgroundImageType.FILL]: { backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' },
  [BackgroundImageType.FIT]: { backgroundSize: 'contain', backgroundRepeat: 'no-repeat' },
  [BackgroundImageType.ORIGINAL_SIZE]: { backgroundRepeat: 'no-repeat' },
  [BackgroundImageType.TILE]: { backgroundRepeat: 'repeat' },
};

export const getBackgroundImage = (
  backgroundImage: Models.BackgroundImageMap,
  documents: Models.CombinedDocumentsMap | Models.ImagesMap,
): React.CSSProperties => {
  const backgroundImageId = backgroundImage && backgroundImage.get('id');
  const backgroundImageDocument = backgroundImageId && documents.get(backgroundImageId) as Models.ImageMap;
  const backgroundImageInfo = backgroundImageDocument && backgroundImageDocument.get('_internalInfo');
  const imageSrc = backgroundImageInfo && backgroundImageInfo.get('source');

  if (!imageSrc) {
    return {};
  }

  const type = backgroundImage.get('type');
  const alignment = backgroundImage.get('alignment');
  const horizontalAlignment = alignment.get(AssetAlignmentDirection.HORIZONTAL);
  const verticalAlignment = alignment.get(AssetAlignmentDirection.VERTICAL);

  return {
    backgroundImage: `url(${imageSrc})`,
    backgroundPositionX: AlignmentValue[horizontalAlignment],
    backgroundPositionY: AlignmentValue[verticalAlignment],
    ...backgroundStylesByType[type],
  };
};
