import React, { useEffect } from 'react';
import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import Panel from 'components/Toolbar/Shared/Panel';
import { useClickOutside } from 'hooks/useClickOutside';
import { Priority } from 'hooks/useClickOutside/constants';
import { intlGet } from 'utils/intlGet';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  toggleOff: () => void;
  className?: string;
  children: React.ReactNode;
  isDisabled: boolean;
};

const AbbreviationControl: React.FunctionComponent<Props> = (props) => {
  const {
    isOpen,
    toggle,
    toggleOff,
    className,
    children,
    isDisabled,
  } = props;

  const { container } = useClickOutside<HTMLDivElement>(
    Priority.CONTROL,
    { active: isOpen, onClickOutside: toggleOff, useToolbar: false },
  );

  useEffect(() => {
    if (isDisabled) {
      toggleOff();
    }
  }, [isDisabled]);

  return (
    <Control
      ref={container}
      className={className}
      disabled={isDisabled}
      onToggle={toggle}
      preventBlur={false}
      size="sm-md"
      state={isOpen ? 'primary' : 'secondary'}
      title={Title.ABBREVIATION}
      type={IconType.ABBREVIATION}
    >
      <Panel
        title={intlGet('EditorToolbar.Abbreviation', 'Title')}
        isOpen={isOpen}
        onClose={toggleOff}
      >
        {children}
      </Panel>
    </Control>
  );
};

export default AbbreviationControl;
