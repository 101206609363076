import Immutable from 'immutable';
import React from 'react';

import { Details } from 'components/Details';
import Loader from 'components/Loader';
import { Document } from 'models';
import { intlGet } from 'utils/intlGet';
import { IProjectDetailsProps, IProjectDetailsState } from './models';
import styles from './styles.module.scss';

const rowsMap: PartialRecord<keyof Document, () => string> = {
  name: () => intlGet('ProjectDetails.Label', 'Name'),
  description: () => intlGet('ProjectDetails.Label', 'Description'),
  number: () => intlGet('ProjectDetails.Label', 'Number'),
  product: () => intlGet('ProjectDetails.Label', 'Brand'),
  country: () => intlGet('ProjectDetails.Label', 'Market'),
  language: () => intlGet('ProjectDetails.Label', 'Language'),
  resourceType: () => intlGet('ProjectDetails.Label', 'ResourceType'),
};

class ProjectDetails extends React.PureComponent<IProjectDetailsProps, IProjectDetailsState> {
  componentDidMount() {
    const { areDetailsLoading, getProjectDetails } = this.props;

    if (!areDetailsLoading) {
      getProjectDetails();
    }
  }

  private onClose = (): void => {
    const { hideModal, modalWindowId } = this.props;

    hideModal(modalWindowId);
  };

  private renderProjectFields = (): JSX.Element[] => Object.entries(rowsMap).map(([key, getLabel]) => (
    <div className={styles.field} key={key}>
      <span className={styles.label}>{getLabel()}:</span>
      <span className={styles.value}>{this.getFormattedValue(key as keyof Document)}</span>
    </div>
  ));

  private getFormattedValue = (key: keyof Document): string => {
    const { rootDocument } = this.props;
    const value = rootDocument.get(key);

    switch (true) {
      case !value: return intlGet('ProjectDetails.Value', 'NotApplicable');
      case Immutable.List.isList(value): return (value as Immutable.List<string>).join(', ');
      default: return String(value);
    }
  };

  render() {
    const { areDetailsLoading } = this.props;
    const title = intlGet('ProjectDetails.Title', 'ProjectDetails');

    return (
      <div className={styles.ProjectDetails}>
        {
          areDetailsLoading
            ?
            <div className={styles.spinner}><Loader /></div>
            :
            <Details title={title} onClose={this.onClose}>
              {this.renderProjectFields()}
            </Details>
        }
      </div>
    );
  }
}

export default ProjectDetails;
