import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import * as commonSelectors from 'containers/Common/selectors';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { initApp } from 'containers/Common/services/initApp';
import { activeLayer as activeLayerSelector } from 'containers/Project/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import * as processes from 'services/processes';
import { getOppositeLayer } from 'utils/layers';
import { sagaFlow } from 'utils/sagaFlow';
import { Action } from '../models';

export function* setActiveLayer(action: Action.ISetActiveLayer, needToUpdateMockLayouts = false) {
  try {
    const { layer: activeLayer } = action.payload;
    const currentLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);

    if (activeLayer === currentLayer) {
      return;
    }

    const projectAssets: ReturnTypeSaga<typeof getProjectAssets> = yield call(getProjectAssets, { removeInternalInfo: false });
    const masterScreenData = (yield select(commonSelectors.masterScreenData)).toJS() as Models.MasterScreenData;
    const updatedAssets = _.set<Models.ProjectAssets>(_.cloneDeep(projectAssets), 'masterScreenData', masterScreenData);
    const oppositeLayer = getOppositeLayer(activeLayer);

    const setActiveLayerProcesses = sagaFlow<Models.SetActiveLayerArgs>(
      arg => processes.normalizeLayeredDocuments(arg, needToUpdateMockLayouts),
      (arg) => { arg.assets.project.activeLayer = activeLayer; }, // set active layer to assets
      processes.refreshLayoutsOnArtboards,
    );

    const { assets }: ReturnTypeSaga<typeof setActiveLayerProcesses> = yield call(
      setActiveLayerProcesses,
      { assets: updatedAssets, isProjectTranslatable: true, layerToCleanUp: oppositeLayer },
    );

    yield call(initApp, { projectAssets: assets });
  } catch (error) {
    yield call(handleSagaError, error, 'Project.setActiveLayer', 'SetActiveLayer');
  }
}
