import Immutable from 'immutable';

import { BoxPropertySide } from 'const';
import * as Models from 'models';
import { splitDifference } from './splitDifference';

export const applyBoxProperty = (
  columnsWidth: Immutable.List<number>,
  prevProperty: Models.BoxPropertyMap,
  nextProperty: Models.BoxPropertyMap,
): Immutable.List<number> => {
  const left = splitDifference(
    prevProperty.get(BoxPropertySide.LEFT),
    nextProperty.get(BoxPropertySide.LEFT),
    columnsWidth.size,
  );
  const right = splitDifference(
    prevProperty.get(BoxPropertySide.RIGHT),
    nextProperty.get(BoxPropertySide.RIGHT),
    columnsWidth.size,
  ).reverse();

  return columnsWidth.map((width, idx = 0) => Number(width) + Number(left[idx]) + Number(right[idx]));
};
