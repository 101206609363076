import { LayoutType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isGroupLayout(layout: Models.CombinedLayout): layout is Models.GroupLayout;
export function isGroupLayout(layout: Models.CombinedLayoutMap): layout is Models.GroupLayoutMap;
export function isGroupLayout(layout: Models.LayeredCombinedLayout): layout is Models.LayeredGroupLayout;
export function isGroupLayout(layout: Models.LayeredCombinedLayoutMap): layout is Models.LayeredGroupLayoutMap;
export function isGroupLayout<T extends Models.GroupLayout>(layout: T | unknown): layout is T;
export function isGroupLayout(layout): boolean {
  return getValue(layout, 'type') === LayoutType.GROUP_LAYOUT;
}
