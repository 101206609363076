import { isImmutable } from 'immutable';
import { Layer } from 'const';
import * as Models from 'models';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';

export function traverseDown(
  relationId: string,
  relations: Models.RelationsMap,
  delta: number,
  layer?: Layer,
  resetIsAutoFitContent?: boolean,
): Models.RelationsMap;
export function traverseDown(
  relationId: string,
  relations: Models.LayeredRelationsMap,
  delta: number,
  layer?: Layer,
  resetIsAutoFitContent?: boolean,
): Models.LayeredRelationsMap;
export function traverseDown(
  relationId: string,
  relations: Models.RelationsMap | Models.LayeredRelationsMap,
  delta: number,
  layer = Layer.ORIGINAL,
  resetIsAutoFitContent = false,
): Models.RelationsMap | Models.LayeredRelationsMap {
  if (isImmutable(relations)) {
    let updatedRelations = relations as Models.LayeredRelationsMap;
    const relation = updatedRelations.get(relationId);

    if (isRegularRelation(relation)) {
      if (resetIsAutoFitContent) {
        const relationToReset = relation.withMutations(
          _relation => _relation
            .setIn(['styles', layer, 'isAutoFitContent'], false)
            .setIn(['styles', layer, 'brandStyleChanged'], true),
        );

        updatedRelations = updatedRelations.set(relationId, relationToReset);
      }

      return updatedRelations;
    }

    if (isColumnRelation(relation)) {
      const relationIds = relation.get('relationIds');
      const lastPosition = relationIds.size - 1;
      const oldRowsHeight = relation.getIn(['styles', 'rowsHeight']);
      const newRowsHeight = oldRowsHeight.update(lastPosition, oldHeight => oldHeight - delta);
      const updatedRelation = relation.setIn(['styles', 'rowsHeight'], newRowsHeight);

      return traverseDown(
        relationIds.get(lastPosition),
        updatedRelations.set(relationId, updatedRelation),
        delta,
        layer,
        resetIsAutoFitContent,
      );
    }

    if (isRowRelation(relation)) {
      const relationIds = relation.get('relationIds');

      return relationIds.reduce(
        (_relations, _relationId) => traverseDown(_relationId, _relations, delta, layer, resetIsAutoFitContent),
        updatedRelations,
      );
    }

    return updatedRelations;
  }

  return relations;
}
