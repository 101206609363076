import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const Undo = (): JSX.Element => {
  const maskId = guid();
  const pathId = guid();

  return (
    <svg viewBox="0 0 30 30">
      <defs>
        <path id={pathId} d="M10.5,1 C7.85,1 5.45,1.99 3.6,3.6 L0,0 L0,9 L9,9 L5.38,5.38 C6.77,4.22 8.54,3.5 10.5,3.5 C14.04,3.5 17.05,5.81 18.1,9 L20.47,8.22 C19.08,4.03 15.15,1 10.5,1 Z"/>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(5.000000, 11.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`#${pathId}`}/>
          </mask>
          <use fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`}/>
          <g mask={`url(#${maskId})`} fill="#777777">
            <g transform="translate(-3.000000, -9.000000)">
              <rect x="0" y="0" width="26" height="26"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Undo;
