import _ from 'lodash';

import * as Models from 'models';
import { isStoryCard } from 'utils/entityType';

export function getStoryCardsToPreviewUpdate(
  actualDocuments: Models.CombinedDocuments,
  outdatedDocuments: Models.CombinedDocuments,
): Models.StoryCard[] {
  return _(actualDocuments)
    .mapValues(document => document as Models.StoryCard)
    .filter((actualDocument, id) => {
      if (!isStoryCard(actualDocument)) {
        return false;
      }

      const outdatedDocument = outdatedDocuments[id] as Models.StoryCard;

      return (
        !outdatedDocument || // the story card has been added in the PromoMats
        !!outdatedDocument._previewUrls // the preview has been already downloaded, so we should reload it
      );
    })
    .value();
}
