import { createSelector } from 'reselect';

import { layouts } from 'containers/Layouts/selectors';
import { activeLayer } from 'containers/Project/selectors';
import * as Models from 'models';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { prioritizeLayeredRelations } from 'utils/prioritizeLayeredRelations';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { State } from './models';

export const layeredRelations = (state: Models.AppState.StateMap): State.IState => state.get('relations');

// NOTE: DON'T use this selector in components and other selectors that may be used in components,
// it might lead to performance issues
export const relations = createSelector([layeredRelations, activeLayer], prioritizeLayeredRelations);

export const relationsByRegularLayoutId = createSelector(
  [layeredRelations, layouts],
  (layeredRelations, layouts) => {
    return layouts.filter<Models.LayoutMap>(isPlainLayout).map(layout => getFlattenedRelations(layout, layeredRelations));
  },
);
