import _ from 'lodash';

import { ARTBOARD_WIDTH, DEFAULT_SECTION, Layer, ProjectType, ProjectsConfig, ScreenFormatType } from 'const';
import { createSectionsFromScreenDefinition } from 'factories/sectionFactory';
import * as Models from 'models';
import { createLayoutsAndRelations } from 'utils/createLayoutAndRelations';
import { getScreenSectionsByName } from 'utils/getScreenSectionsByName';
import { intlGet } from 'utils/intlGet';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isEmptyPlainLayout } from 'utils/layouts/isLayoutEmpty';

type ValidateScreenDefinitionsResult = {
  layouts: Models.LayeredLayouts;
  relations: Models.LayeredRelations;
  sections: Models.Sections;
  notificationOptions: Models.NotificationWindowOptions[];
};

type AdjustScreenForNewDefinitionResult = {
  artboardSectionsToSet: _.Dictionary<Models.Section> & Models.Sections;
  layoutsToUpdate: Models.LayeredCombinedLayouts;
  layoutsToCreate: Models.LayeredCombinedLayouts;
  layoutsToMove: (Models.LayeredLayout | Models.LayeredGroupLayout)[];
  layoutsToCreateInEmptySections: Models.LayeredLayout[];
  deletedLayoutIds: string[];
  relationsToUpdate: Models.LayeredRelations;
  relationsToCreate: Models.LayeredRelations;
  missingNewSectionNames: string[];
  missingUsedSectionNames: string[];
  missingUsedSectionIds: string[];
  updatedArtboard: Models.Artboard;
};

/**
 * Validates used section names with section names from Screen Definitions
 * @todo A part of logic must be rewritten as the separate method(`assignScreenDefinition`), that will apply any screen definition to artboard
 */
export function validateScreenDefinitions(
  usedScreenDefs: Models.MasterScreen.ScreenDefinitions,
  newScreenDefs: Models.MasterScreen.ScreenDefinitions,
  screens: Models.Screens,
  artboards: Models.Artboards,
  sections: Models.Sections,
  initialLayouts: Models.LayeredCombinedLayouts,
  relations: Models.LayeredRelations,
  projectType: ProjectType,
  documents: Models.CombinedDocuments,
): ValidateScreenDefinitionsResult {
  const notificationOptions: Models.NotificationWindowOptions[] = [];
  // get all new Screen Definition IDs
  const newScreenDefIds = _.keys(newScreenDefs);
  // first Screen Definition will be used like a default one in case of absence previously used Screen Definition
  const defaultScreenDefinitionId = _.first(newScreenDefIds);

  const missingScreenDefinitionIds = new Set<string>();
  const missingUsedSectionNamesByScreenDefinitionId = {} as Record<string, Set<string>>;
  const missingNewSectionNamesByScreenDefinitionId = {} as Record<string, Set<string>>;

  let layouts = initialLayouts;

  const sectionsToSet = {} as Models.Sections;
  const layoutsToSet = {} as Models.LayeredLayouts;
  const relationsToSet = {} as Models.LayeredRelations;

  // for maintaining old projects without sections entity
  if (_.isEmpty(sections)) {
    let layoutsWithSectionEntity = {} as Models.LayeredLayouts;

    _.forEach(screens, (screen) => {
      const { artboardId, screenDefinitionId } = screen;
      const screenDefinition = newScreenDefs[screenDefinitionId];
      const screenSections = _.get(screenDefinition, 'sections', [] as Models.MasterScreen.Section[]);

      const { layoutIds } = artboards[artboardId];
      const layoutsToUpdate = _.pick(initialLayouts, layoutIds);

      const sectionEntities = createSectionsFromScreenDefinition(screenSections, screenDefinitionId);

      layoutsWithSectionEntity = _.merge(
        layoutsWithSectionEntity,
        _.reduce(
          layoutsToUpdate,
          (layouts, layout) => {
            const { section: sectionName, id: layoutId } = layout;
            const { id: sectionId } = _.find(sectionEntities, section => section.name === sectionName) || _(sectionEntities).values().first();
            layouts[layoutId] = { ...layout, section: sectionId };

            return layouts;
          },
          {} as Models.LayeredCombinedLayouts,
        ),
      );

      return _.merge(sections, sectionEntities);
    });

    layouts = layoutsWithSectionEntity;
  }
  const { areScreensResizable } = ProjectsConfig[projectType];

  _.forEach(screens, (screen) => {
    const { screenDefinitionId: usedScreenDefinitionId } = screen;

    // replace used Screen Definition with a default one if it's absent
    if (!newScreenDefIds.includes(usedScreenDefinitionId)) {
      const screenType = _.get(usedScreenDefs, [usedScreenDefinitionId, 'screenType']);
      const newScreenDefId = _.findKey(newScreenDefs, screenDef => screenDef.screenType === screenType);
      // collect absent Screen Definition IDs
      !newScreenDefId && missingScreenDefinitionIds.add(usedScreenDefinitionId);
      // replace Screen Definition ID
      screen.screenDefinitionId = newScreenDefId || defaultScreenDefinitionId;

      if (areScreensResizable) {
        const screenHeight = _.get(usedScreenDefs, [usedScreenDefinitionId, 'screenHeight'], null);
        const screenWidth = _.get(usedScreenDefs, [usedScreenDefinitionId, 'screenWidth'], null);
        const artboard = artboards[screen.artboardId];

        screen.formatType = ScreenFormatType.CUSTOM;
        artboard.styles.height = screenHeight;
        artboard.styles.width = screenWidth;
      }
    }

    const {
      artboardSectionsToSet,
      layoutsToUpdate,
      layoutsToCreate,
      relationsToUpdate,
      relationsToCreate,
      missingNewSectionNames,
      missingUsedSectionNames,
    } = adjustScreenForNewDefinition(screen, artboards, sections, layouts, relations, newScreenDefs, documents);

    const { screenDefinitionId } = screen;

    if (!_.isEmpty(missingNewSectionNames)) {
      _.merge(
        missingNewSectionNamesByScreenDefinitionId,
        { [screenDefinitionId]: new Set(missingNewSectionNames) },
      );
    }
    if (!_.isEmpty(missingUsedSectionNames)) {
      _.merge(
        missingUsedSectionNamesByScreenDefinitionId,
        { [screenDefinitionId]: new Set(missingUsedSectionNames) },
      );
    }

    _.merge(sectionsToSet, artboardSectionsToSet);
    _.merge(layoutsToSet, layoutsToUpdate, layoutsToCreate);
    _.merge(relationsToSet, relationsToUpdate, relationsToCreate);
  });

  const missingScreenDefinitionIdsArray = Array.from(missingScreenDefinitionIds);
  const isAnyScreenDefinitionMissing = !_.isEmpty(missingScreenDefinitionIdsArray);
  const isAnyUsedSectionNameMissing = !_.isEmpty(missingUsedSectionNamesByScreenDefinitionId);
  const isAnyNewSectionNameMissing = !_.isEmpty(missingNewSectionNamesByScreenDefinitionId);

  if (isAnyScreenDefinitionMissing || isAnyUsedSectionNameMissing || isAnyNewSectionNameMissing) {
    // logging what is particularly going wrong
    // eslint-disable-next-line no-console
    console.log('Screen Definitions have been modified!');
    // eslint-disable-next-line no-console
    isAnyScreenDefinitionMissing && console.log(`Missing Screen Definition document IDs: ${missingScreenDefinitionIdsArray.join(', ')}.`);
    isAnyUsedSectionNameMissing
      && logMissingSectionNames(missingUsedSectionNamesByScreenDefinitionId, 'Missing previously used section names');
    isAnyNewSectionNameMissing
      && logMissingSectionNames(missingNewSectionNamesByScreenDefinitionId, 'New section names which there were not previously');

    // notify users about Screen Definitions modifications
    notificationOptions.push({
      title: intlGet('Notification.Title', 'ProjectUpdated'),
      message: intlGet('Notification', 'ScreenDefinitionHasBeenModified'),
    });
  }

  return {
    layouts: _.merge({}, layouts, layoutsToSet),
    relations: _.merge({}, relations, relationsToSet),
    sections: _.merge({}, sections, sectionsToSet),
    notificationOptions,
  };
}

export function adjustScreenForNewDefinition(
  screen: Models.Screen,
  artboards: Models.Artboards,
  sections: Models.Sections,
  layouts: Models.LayeredCombinedLayouts,
  relations: Models.LayeredRelations,
  newScreenDefs: Models.MasterScreen.ScreenDefinitions,
  documents: Models.CombinedDocuments,
  layer = Layer.ORIGINAL,
): AdjustScreenForNewDefinitionResult {
  const { artboardId, screenDefinitionId } = screen;
  const prevArtboard = _.cloneDeep(artboards[artboardId]);
  const prevLayouts = _.cloneDeep(layouts);

  const artboard = artboards[artboardId];
  const artboardLayouts = getFlattenedLayouts(artboard, layouts);
  const artboardSections = _.pick(sections, _.map(artboardLayouts, layout => layout.section));
  const artboardSectionsNames = _.map(artboardSections, section => section.name);

  // to make sure each artboard's section has right Screen Definition ID
  _.forEach(artboardSections, section => section.screenDefinition = screenDefinitionId);

  const sectionNames = newScreenDefs[screenDefinitionId].sections.map(section => section.name);

  const validSectionsNames = _.intersection(artboardSectionsNames, sectionNames);
  const missingNewSectionNames = _.difference(sectionNames, artboardSectionsNames);
  const missingUsedSectionNames = _.difference(artboardSectionsNames, sectionNames);

  // create section entities for new sections from Screen Definition
  const artboardSectionsToCreate = createSectionsFromScreenDefinition(missingNewSectionNames.map(name => ({ name })), screenDefinitionId);
  const artboardSectionsToSet = _.merge(
    {},
    // pick section entities that already exist
    _.pickBy(artboardSections, (section => validSectionsNames.includes(section.name))),
    artboardSectionsToCreate,
  );
  const defaultSectionName = sectionNames.includes(DEFAULT_SECTION) ? DEFAULT_SECTION : _.first(sectionNames);
  const { id: defaultSectionId } = _.find(artboardSectionsToSet, section => section.name === defaultSectionName);

  const newScreenWidth = _.get(newScreenDefs, [screenDefinitionId, 'screenWidth'], ARTBOARD_WIDTH);
  // update existing layout section if it's missing by default
  const layoutsToUpdate = _.mapValues(artboardLayouts, layout => (
    artboardSectionsToSet[layout.section] ? layout : _.merge(layout, { section: defaultSectionId })
  ));

  // create layouts for new sections, relations for new layouts
  const {
    layouts: layoutsToCreate,
    relations: relationsToCreate,
  } = createLayoutsAndRelations(
    _.map(artboardSectionsToCreate, section => ({ section: section.id })),
    { createLayeredRelations: true, layer, layoutWidth: newScreenWidth },
  );

  // add new layouts to artboard
  artboard.layoutIds.push(..._.keys(layoutsToCreate));

  let layoutsToMove = _.values(layoutsToUpdate);

  let layoutIdsFromMissingSections: string[] = [];

  let missingUsedSectionIds: string[] = [];
  if (_.size(missingUsedSectionNames) > 0) {
    const artboardLayouts = _.pick(prevLayouts, prevArtboard.layoutIds);
    const artboardSectionIds = _(artboardLayouts).map('section').uniq().value();
    const screenSectionsByName = getScreenSectionsByName(sections, artboardSectionIds);

    missingUsedSectionIds = _(screenSectionsByName)
      .filter(section => missingUsedSectionNames.includes(section.name))
      .map('id')
      .uniq()
      .value();

    const layoutsBySectionId = _.groupBy(artboardLayouts, 'section');
    layoutIdsFromMissingSections = _(missingUsedSectionIds)
      .flatMap(sectionId => layoutsBySectionId[sectionId])
      .map('id')
      .value();

    // don't need to move empty layouts from missing sections
    layoutsToMove = _.reject(
      layoutsToMove,
      layout => layoutIdsFromMissingSections.includes(layout.id) && isEmptyPlainLayout(layout, relations, documents),
    );
  }

  // don't need to create empty layouts in the section where layouts were moved from missing sections
  const layoutsToCreateInEmptySections = _.reject(layoutsToCreate, ({ section }) => _.some(layoutsToMove, { section }));
  const createdLayoutIds = _.map(layoutsToCreateInEmptySections, 'id');
  const movedLayoutIds = _.map(layoutsToMove, 'id');
  const movedLayoutIdsOnArtboard = _.intersection(movedLayoutIds, layoutIdsFromMissingSections);
  const deletedLayoutIds = _(layoutsToUpdate).map('id').difference(movedLayoutIds).value();

  const updatedArtboard = _.update(
    prevArtboard,
    'layoutIds',
    layoutIds => layoutIds
      .filter(layoutId => ![...deletedLayoutIds, ...movedLayoutIdsOnArtboard].includes(layoutId))
      .concat(createdLayoutIds, movedLayoutIdsOnArtboard),
  );
  artboards[screen.artboardId] = updatedArtboard;

  return {
    artboardSectionsToSet,
    layoutsToUpdate,
    layoutsToCreate,
    layoutsToMove,
    layoutsToCreateInEmptySections,
    deletedLayoutIds,
    // TODO: revise (don't return relations at all)
    relationsToUpdate: relations,
    relationsToCreate,
    missingNewSectionNames,
    missingUsedSectionNames,
    missingUsedSectionIds,
    updatedArtboard,
  };
}

function logMissingSectionNames(sectionNamesByScreenDefinitionId: Record<string, Set<string>>, message: string): void {
  const missingSectionNames = _.map(sectionNamesByScreenDefinitionId, (sectionNames, screenDefinitionId) => {
    return `${screenDefinitionId}: ${Array.from(sectionNames).join(', ')}`;
  });
  // eslint-disable-next-line no-console
  console.log(`${message} - ${missingSectionNames.join('; ')}.`);
}
