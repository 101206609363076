import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';

export function applyEditorStateFontStylesForBrandStyles(
  editorState: Draft.EditorState,
  projectType: Constants.ProjectType,
  fonts: Models.BrandFontsList,
): Draft.EditorState {
  const { applyFontStyleForBrandStyles } = Constants.ProjectsConfig[projectType];
  let updatedEditorState;

  if (applyFontStyleForBrandStyles) {
    updatedEditorState = editorUtils.applyFontStylesForBrandStyles(editorState, fonts);
  }

  return updatedEditorState
    ? editorUtils.applyFontStyles(updatedEditorState, fonts, projectType)
    : editorUtils.applyFontStyles(editorState, fonts, projectType);
}
