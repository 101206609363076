import React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import TextComponentItem from 'containers/TextComponentItem';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { ITextComponentListProps } from './models';
import styles from './styles.module.scss';

const TextComponentList: React.FunctionComponent<ITextComponentListProps> = (props) => {
  const { isExpand, components, showHeader, toggleExpand } = props;
  const { expanded, toggle } = useToggle({
    isExpand,
    toggle: toggleExpand,
  });

  const renderHint = () => (
    <div className={styles.hint}>
      <div>{intlGet('Assets', 'Text.Hint.Title')}</div>
      <div>{intlGet('Assets', 'Text.Hint.Message')}</div>
    </div>
  );

  return !!(components) && (
    <div className={styles.TextComponentListContainer} data-title="text-components">
      {showHeader &&
        <Header
          title={intlGet('Assets', 'Text', { count: components.size })}
          expanded={expanded}
          onClick={toggle}
        />
      }
      {
        (expanded || !showHeader) &&
        <List
          border={showHeader}
          elements={components}
          subheader={components.size === 0 && renderHint()}
          renderElement={(component: Models.TextComponentMap) => (
            <TextComponentItem
              allowDelete={showHeader}
              key={component.get('id')}
              component={component}
            />
          )}
        />
      }
    </div>
  );
};

export default TextComponentList;
