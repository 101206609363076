import _ from 'lodash';
import { call } from 'redux-saga/effects';

import { ProcessType } from 'const';
import * as Models from 'models';
import { syncAssets } from 'services/api';
import { UploadProjectFlowArg } from '../models';
import { getDocumentsOnArtboards } from './getDocumentsOnArtboards';

export function* syncProjectAssets(arg: UploadProjectFlowArg): Generator<unknown, Models.SyncResponseData> {
  const { projectAssets, rootDocumentId } = arg;
  const { documents } = projectAssets;
  const documentsToSync = (yield call(getDocumentsOnArtboards, ProcessType.REFRESH)).toJS() as Models.CombinedDocuments;

  const { data: syncedAssets }: ReturnTypeSaga<typeof syncAssets> = yield call(
    syncAssets,
    rootDocumentId,
    documents,
    _.keys(documentsToSync),
  );

  return syncedAssets;
}
