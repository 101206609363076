import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArtboardCell from 'components/ArtboardCell';
import { ArtboardCellDispatchProps, ArtboardCellOwnProps, ArtboardCellSelectors } from 'components/ArtboardCell/models';
import { addSelectedArtboardComponent, removeSelectedArtboardComponent } from 'containers/App/actions';
import {
  areActionsDisabled,
  areImagesBeingDownloaded,
  isDraggingAnchor,
  isResizingColumn,
  isResizingRow,
  selectionState,
} from 'containers/App/selectors';
import { addTextToCell, copyCellContent, dropAsset } from 'containers/ArtboardCell/actions';
import { updateCellsHeight } from 'containers/ArtboardPreview/actions';
import { callToActions, images, reusableLayouts, textComponents } from 'containers/Documents/selectors';
import { addCell, deleteCell, splitCell } from 'containers/Layouts/actions';
import { activeLayer, isOpenToolbar } from 'containers/Project/selectors';
import { updateLayeredRelationsSilently } from 'containers/Relations/actions';

const mapStateToProps = createStructuredSelector({
  images,
  callToActions,
  reusableLayouts,
  textComponents,
  activeLayer,
  areActionsDisabled,
  areImagesBeingDownloaded,
  isDraggingAnchor,
  isOpenToolbar,
  isResizingColumn,
  isResizingRow,
  selectionState,
});

const mapDispatchToProps = {
  addCell,
  addSelectedArtboardComponent,
  addTextToCell,
  copyCellContent,
  deleteCell,
  dropAsset,
  removeSelectedArtboardComponent,
  splitCell,
  updateCellsHeight,
  updateRelationsSilently: updateLayeredRelationsSilently,
};

export default connect<ArtboardCellSelectors, ArtboardCellDispatchProps, ArtboardCellOwnProps>(mapStateToProps, mapDispatchToProps)(ArtboardCell);
