import React from 'react';
/* eslint-disable max-len */

const LineHeightOnePointFive = (): JSX.Element => (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <defs>
      <path d="M6,22 L24,22 L24,20 L6,20 L6,22 L6,22 Z M6,16 L24,16 L24,14 L6,14 L6,16 L6,16 Z M6,10 L24,10 L24,8 L6,8 L6,10 L6,10 Z" id="lineHeight1_5-id" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="lineHeight1_5-mask" fill="white">
        <use xlinkHref="#lineHeight1_5-id" />
      </mask>
      <g mask="url(#lineHeight1_5-mask)" fill="#FFFFFF">
        <g transform="translate(2.000000, 2.000000)" id="White">
          <rect x="0" y="0" width="26" height="26" />
        </g>
      </g>
    </g>
  </svg>
);

export default LineHeightOnePointFive;
