import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { brandDefinition } from 'containers/BrandDefinition/selectors';
import * as DocumentsActions from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import * as Models from 'models';
import { getAssetsCollection } from 'services/api';
import { handleSagaError } from 'services/handleError';
import { extendReusableLayoutBrandDefinitions } from 'services/processes';
import { isCombinedLayoutDocument } from 'utils/entityType';
import { getImageDocuments } from 'utils/getImageDocuments';
import { Action } from '../models';

export function* getAssetsCollectionData(action: Action.IGetAssetsCollectionData) {
  try {
    const { id } = action.payload;
    const projectDocuments: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const assetsCollection = projectDocuments.get(id) as Models.AssetsCollectionMap;
    const documentsExist = !!assetsCollection.get('documents');

    if (documentsExist) {
      return;
    }

    const documentId = assetsCollection.get('documentId');
    const getAssetsCollectionResponse: ReturnTypeSaga<typeof getAssetsCollection> = yield call(getAssetsCollection, documentId);
    const { data: { documents, entities } } = getAssetsCollectionResponse;
    const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;
    const args: Models.AssetsCollectionDataToUpload = {
      assets: {
        documents,
        masterScreenData: { brandDefinition: projectBrandDefinition },
      },
    };

    extendReusableLayoutBrandDefinitions(args);

    yield put(DocumentsActions.setAssetsCollectionData(id, assetsCollection.get('entityType'), documents, entities));

    const imageDocuments: ReturnTypeSaga<typeof getImageDocuments> = yield call(getImageDocuments, documents);
    yield put(DocumentsActions.getStoryCardImagesInfo(id, imageDocuments));

    const layoutDocuments = _.filter(documents, isCombinedLayoutDocument) as Models.CombinedLayoutDocument[];
    yield put(DocumentsActions.getStoryCardLayoutThumbnails(id, layoutDocuments));

    // TODO: Have to validate previews of story card screens. If they have invalid or broken links, we should delete screen.thumbnail.url at all.
    // Validation can be implemented in the same way as for story card images using getImageInternalInfo method under the hood.
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.getAssetsCollectionInternalDocuments', 'GetAssetsCollectionData');
  }
}
