import { useEffect, useMemo, useRef } from 'react';

export default function useResizeObserver(
  ref: HTMLElement,
  onResize: ResizeObserverCallback,
): void {

  const callbackRef = useRef<ResizeObserverCallback>(onResize);
  callbackRef.current = onResize;

  const resizeHandler = (): void => {
    if (callbackRef.current) {
      callbackRef.current();
    }
  };

  const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame((): void | undefined => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      resizeHandler();
    });
  };

  const resizeObserver = useMemo(() => new ResizeObserver(observerCallback), []);

  useEffect(() => {
    if (!ref) {
      return undefined;
    }
    resizeObserver.observe(ref);

    return () => resizeObserver.unobserve(ref);
  }, [ref]);
}
