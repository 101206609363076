import { CellEntityType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { removeScriptTag } from 'utils/removeScriptTag';
import { createScreenRef } from './createScreenRef';

export const createImage = ({
  src = '',
  alt = '',
  title = '',
  width = '0',
  documentId = null,
  href = '',
  screenRef = createScreenRef(),
  mobile = {} as Schemas.ImageMobileSettings,
} = {} as Partial<Schemas.Image>): Schemas.Image => ({
  src,
  alt,
  title,
  width,
  documentId,
  href: removeScriptTag(href),
  screenRef,
  entityType: CellEntityType.IMAGE,
  mobile,
});
