import AssemblerLogo from 'assets/images/AssemblerLogo';
import * as React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ASSEMBLER_HELP, AppRoute, DEFAULT_ZOOM_SIZE, ExportType, ModalType } from 'const';
import { HeaderIds } from 'const/Header';
import UndoRedo from 'containers/UndoRedoControl';
import ZoomControl from 'containers/ZoomControl';
import { convertTime } from 'utils/convertTime';
import { intlGet } from 'utils/intlGet';
import Translation from './Controls/Translation';
import { IHeaderProps } from './models';
import styles from './styles.module.scss';

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const onUploadClick = (): void => { props.showModal(ModalType.UPLOAD_PROJECT); };
  const onExportClick = (): void => { props.showModal(ModalType.EXPORT); };
  const onSaveClick = (): void => { props.saveProjectLocally(); };
  const showProjectDetails = (): void => { props.showModal(ModalType.PROJECT_DETAILS); };

  const getExternalActionsSection = (): JSX.Element => {
    const { isUploadAvailable, lastLocalSaveTime, areImagesBeingDownloaded, isOpenToolbar } = props;

    return (
      <div className={styles.externalActionsContainer}>
        {
          lastLocalSaveTime &&
          <>
            <div className={styles.saveTime}>{intlGet('Header', 'SavedAt', { time: convertTime(lastLocalSaveTime) })}</div>
            <div className={styles.spacer} />
          </>
        }
        <div className={styles.externalAction}>
          <Button
            icon={IconType.SAVE_PROJECT}
            title={intlGet('Header.Button', 'Save')}
            onClick={onSaveClick}
            highlightOnHover={true}
          />
        </div>
        {/**
         * The id in the following code is here because we need to manually block
         * the 'Upload to PromoMats' button from clicking when toolbar is opened (DCC-4278)
        */}
        <div className={styles.externalAction} id="uploadButton">
          <Button
            icon={IconType.UPLOAD_PROJECT}
            size="sm-md"
            title={intlGet('Header.Button', 'Upload')}
            disabled={!isUploadAvailable || areImagesBeingDownloaded || isOpenToolbar}
            onClick={onUploadClick}
            highlightOnHover={true}
          />
        </div>
        <div className={styles.externalAction}>
          <Button
            icon={IconType.EXPORT_PROJECT}
            title={intlGet('Header.Button', 'Export')}
            disabled={areImagesBeingDownloaded}
            onClick={onExportClick}
            highlightOnHover={true}
          />
        </div>
        {/**
         * The id in the following code is here because we need to manually block
         * the 'Preview' button from clicking when toolbar is opened (DCC-2711)
        */}
        <div className={styles.previewTitle} id="previewButton">
          <Button
            onClick={goToPreview}
            disabled={areImagesBeingDownloaded || isOpenToolbar}
            title={intlGet('Header.Label', 'Preview')}
            icon={IconType.VIEW_DETAILS}
            size="md"
            highlightOnHover={true}
          />
        </div>
      </div>
    );
  };

  const goToPreview = (): void => {
    const { activeSurfaceId, match, history, togglePreview } = props;
    props.resetPreviewsHtml();
    togglePreview(true, DEFAULT_ZOOM_SIZE);

    history.push(`${match.url}/${AppRoute.PREVIEW}/${activeSurfaceId}`);
  };

  const {
    activeLayer,
    areReusableLayoutsBeingUploaded,
    exportForTranslationsEnabled,
    exportPackage,
    importTranslationPackage,
    isTranslationAvailable,
    originalCountry,
    originalLanguage,
    rootDocument,
    setActiveLayer,
    countryAbbreviations,
  } = props;
  const language = rootDocument.get('language').first<string>();
  const countryLabel = rootDocument.get('country').map(country => countryAbbreviations.get(country)).join(', ');
  const originalCountryLabel = originalCountry.map(country => countryAbbreviations.get(country)).join(', ');
  const name = rootDocument.get('name');

  return (
    <header className={styles.Header}>
      <div className={styles.section}>
        <div className={styles.sectionLeft}>
          <AssemblerLogo />
          <div className={styles.projectTitleContainer}>
            <span className={styles.projectTitle}>{name}</span>
          </div>
          <div className={styles.projectDetailsContainer} title={intlGet('Header.Button', 'ViewProjectDetails')}>
            <Button icon={IconType.EXCLAMATION_MARK} size="sm" onClick={showProjectDetails} />
          </div>
          <div className={styles.spacer} />
          <div className={styles.helpContainer}>
            <a href={ASSEMBLER_HELP} target="_blank">
              <Icon color="primary" size="sm" type={IconType.QUESTION_MARK}/>
              <span className={styles.helpTitle}>{intlGet('Header.Label', 'Help')}</span>
            </a>
          </div>
          <div className={styles.spacer} />
          {
            isTranslationAvailable &&
            <>
              <Translation
                activeLayer={activeLayer}
                originalLang={`${originalLanguage.first()} - ${originalCountryLabel}`}
                language={`${language} - ${countryLabel}`}
                toggleLayer={setActiveLayer}
                disabled={areReusableLayoutsBeingUploaded}
                onImport={exportForTranslationsEnabled && importTranslationPackage}
                onExport={exportForTranslationsEnabled && exportPackage.bind(null, ExportType.TRANSLATION_PACKAGE)}
              />
              <div className={styles.spacer} />
            </>
          }
          <ZoomControl />
          <div className={styles.spacer} />
          <div id={HeaderIds.UNDO_REDO} className={styles.container}>
            <UndoRedo />
          </div>
          <div className={styles.spacer} />
        </div>
        <div className={styles.sectionRight}>
          {getExternalActionsSection()}
        </div>
      </div>
    </header>
  );
};


export default Header;
