import { DEFAULT_SSI_SCALE, SSIPosition } from 'const';
import { SSI, SSISource } from 'models';

export const createSSI = ({
  position = SSIPosition.VERTICAL,
  scale = DEFAULT_SSI_SCALE,
  section = null,
  source = null as SSISource,
} = {} as Partial<SSI>): SSI => ({
  position,
  section,
  scale,
  source,
});
