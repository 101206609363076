import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import ScreenFormatSize from 'components/ScreenFormatSize';
import ScreenFormatType from 'components/ScreensPanelItem/components/ScreenFormatType';
import * as Constants from 'const';
import BackgroundColorTab from 'containers/ModalWindows/ScreenSettings/BackgroundColorTab';
import { useScreenFormat } from 'hooks/useScreenFormat';
import { ArtboardMap, ScreenSettingsWindowConfigOptions, Thumbnail } from 'models';
import { intlGet } from 'utils/intlGet';
import { getValidScreenName, isScreenNameInvalid } from 'utils/screenNameValidator';
import { isCustomScreenFormatType } from 'utils/screens/isCustomScreenFormat';
import BackgroundImageTab from './components/BackgroundImageTab';
import ThumbnailTab from './components/ThumbnailTab';
import { ScreenSettingsProps } from './models';
import styles from './styles.module.scss';

const ScreenSettings: React.FunctionComponent<ScreenSettingsProps> = (props) => {
  const {
    artboard,
    color,
    hideModal,
    modalWindowId,
    screen,
    screenNames,
    updateArtboard,
    refreshThumbnail,
    thumbnailsRefreshProgress,
    projectType,
  } = props;

  const { allowThumbnailTab, allowBackgroundImage, areScreensResizable } = Constants.ProjectsConfig[projectType];

  const TabIcons: IconType[] = _.compact([
    allowThumbnailTab ? IconType.THUMBNAIL_PREVIEW : null,
    allowBackgroundImage ? IconType.IMAGE : null,
    IconType.FILL_COLOR_UNDERLINED,
  ]);

  const initialScreenName = screen.get('name');
  const thumbnail = screen.get('thumbnail');
  const initialFormatType = screen.get('formatType');
  const { url, name, border } = thumbnail.toJS() as Thumbnail;

  const [activeTab, setActiveTab] = useState(TabIcons[0]);
  const [screenName, setScreenName] = useState(initialScreenName);
  const [thumbnailBorder, toggleThumbnailBorder] = useState(_.isBoolean(border) ? border : true);
  const [thumbnailName, setThumbnailName] = useState(name || initialScreenName);
  const initialHeight = artboard.getIn(['styles', 'height']);
  const initialWidth = artboard.getIn(['styles', 'width']);
  const initialArtboard = useRef<ArtboardMap>(artboard);

  const {
    height,
    setHeight: setHeightState,
    width,
    setWidth: setWidthState,
    formatType,
    setFormatType: setFormatTypeState,
    isDisabled: isScreenFormatSizeDisabled,
  } = useScreenFormat({
    initialFormatType,
    initialHeight,
    initialWidth,
  });

  const setWidth = (width: number): void => {
    setWidthState(width);
    updateArtboard(artboard.setIn(['styles', 'width'], width));
  };

  const setHeight = (height: number): void => {
    setHeightState(height);
    updateArtboard(artboard.setIn(['styles', 'height'], height));
  };

  const setFormatType = (formatType: Constants.ScreenFormatType): void => {
    setFormatTypeState(formatType);

    if (!isCustomScreenFormatType(formatType)) {
      const { width, height } = Constants.PredefinedScreenFormatByType[formatType];
      setWidthState(width);
      setHeightState(height);
      updateArtboard(artboard.setIn(['styles', 'height'], height).setIn(['styles', 'width'], width));
    }
  };

  const onCancelClick = useCallback(
    (): void => {
      updateArtboard(initialArtboard.current);
      hideModal(modalWindowId);
    },
    [initialArtboard],
  );

  const onSaveClick = (): void => {
    const screenNameToSave = getValidScreenName(screenName, screenNames, initialScreenName);
    hideModal<ScreenSettingsWindowConfigOptions>(modalWindowId, {
      height,
      screenName: screenNameToSave,
      thumbnailBorder,
      thumbnailName,
      thumbnailUrl: url,
      type: formatType,
      width,
    });
  };

  const onTabClick = (iconType: IconType): void => {
    setActiveTab(iconType);
  };

  const onScreenNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newScreenName = event.target.value;
    if (isScreenNameInvalid(newScreenName)) {
      return;
    }

    setScreenName(newScreenName);
  };

  const validateScreenName: React.FocusEventHandler<HTMLInputElement> = () => {
    const screenNameToSave = getValidScreenName(screenName, screenNames, initialScreenName);

    if (screenNameToSave !== screenName) {
      setScreenName(screenNameToSave);
    }
  };

  const renderTabContent = (iconType: IconType): JSX.Element => {
    switch (iconType) {
      case IconType.THUMBNAIL_PREVIEW:
        return (
          <ThumbnailTab
            color={color}
            thumbnailBorder={thumbnailBorder}
            toggleThumbnailBorder={toggleThumbnailBorder}
            thumbnailName={thumbnailName}
            setThumbnailName={setThumbnailName}
            thumbnailUrl={url}
            refreshThumbnail={refreshThumbnail}
            screenId={screen.get('id')}
            thumbnailsRefreshProgress={thumbnailsRefreshProgress}
          />
        );

      case IconType.IMAGE:
        return <BackgroundImageTab artboard={artboard} updateArtboard={updateArtboard} />;

      case IconType.FILL_COLOR_UNDERLINED:
        return <BackgroundColorTab artboard={artboard} updateArtboard={updateArtboard} />;

      default: return null;
    }
  };

  return (
    <div className={styles.ScreenSettings}>
      <header className={styles.header}>
        <div className={styles.title}>{intlGet('ScreenSettingsWindow', 'ScreenSettings')}</div>
      </header>
      <main className={styles.main}>
        <div className={styles.controls}>
          <input
            className={styles.inputField}
            type="text"
            onChange={onScreenNameChange}
            onBlur={validateScreenName}
            value={screenName}
            maxLength={Constants.MAX_SCREEN_NAME_LENGTH}
          />
          {
            areScreensResizable &&
            <>
              <div className={styles.selectTypeContainer}>
                <ScreenFormatType
                  className={classNames(styles.inputField, styles.selectType)}
                  formatType={formatType}
                  isTooltipDisabled={true}
                  onFormateChange={setFormatType}
                />
              </div>
              <ScreenFormatSize
                className={styles.inputSize}
                height={height}
                isDisabled={isScreenFormatSizeDisabled}
                setHeight={setHeight}
                setWidth={setWidth}
                width={width}
              />
            </>
          }
        </div>
        <ul className={styles.tabIconsList}>
          {
            TabIcons.map((iconType, index) => (
              <li
                key={iconType}
                className={classNames(styles.tabIcon, { [styles.active]: activeTab === iconType })}
                value={index}
                onClick={onTabClick.bind(null, iconType)}
              >
                <Icon type={iconType} color={activeTab === iconType ? 'primary' : 'grey'} size="sm" />
              </li>
            ))
          }
        </ul>
        <div className={styles.tabContent}>
          {renderTabContent(activeTab)}
        </div>
      </main>
      <footer className={styles.footer}>
        <Button onClick={onCancelClick} variant="secondary">{intlGet('ScreenSettingsWindow.Button', 'Cancel')}</Button>
        <Button onClick={onSaveClick} variant="primary">{intlGet('ScreenSettingsWindow.Button', 'Save')}</Button>
      </footer>
    </div>
  );
};

export default ScreenSettings;
