import React from 'react';
/* eslint-disable max-len */

const ThumbnailPreview = (): JSX.Element => (
  <svg viewBox="0 0 22 18">
    <path d="M20,0 C21.1,0 22,0.9 22,2 L22,2 L22,16 C22,17.1 21.1,18 20,18 L20,18 L2,18 C0.9,18 0,17.1 0,16 L0,16 L0,2 C0,0.9 0.9,0 2,0 L2,0 Z M20,2 L2,2 L2,16 L20,16 L20,2 Z M15.3333333,10 L15.3333333,12 L6,12 L6,10 L15.3333333,10 Z M18,6 L18,8 L4,8 L4,6 L18,6 Z" />
  </svg>
);

export default ThumbnailPreview;
