import React from 'react';
/* eslint-disable max-len */

const GrabBar = (): JSX.Element => (
  <svg viewBox="0 0 25 25">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g>
          <rect width="25" height="25" style={{ fill: 'none' }} />
          <path d="M7.502040661871435,4.762424197793008 h3.1477795720100405 V1.6334936052560822 H7.502040661871435 zm6.276710164546967,0 H16.92653039842844 V1.6334936052560822 H13.778750826418399 zM7.502040661871435,11.057983341813088 h3.1477795720100405 V7.910203769803047 H7.502040661871435 zm6.276710164546967,0 H16.92653039842844 V7.910203769803047 H13.778750826418399 zM7.502040661871435,17.334693506360054 h3.1477795720100405 V14.186913934350013 H7.502040661871435 zm6.276710164546967,0 H16.92653039842844 V14.186913934350013 H13.778750826418399 zM7.502040661871435,23.611403670907016 h3.1477795720100405 V20.4824730783701 H7.502040661871435 zm6.276710164546967,0 H16.92653039842844 V20.4824730783701 H13.778750826418399 zM7.502040661871435,7.910203769803047 " style={{ fillRule: 'evenodd' }} />
        </g>
      </g>
    </g>
  </svg>
);

export default GrabBar;
