import { BrandColor } from './BrandColor';
import { BackgroundGradientDirection } from './styles/style/BackgroundGradient';

export type BrandColorGradient = {
  color1: BrandColor;
  color2: BrandColor;
  direction: BackgroundGradientDirection;
};

export type BrandColorGradientMap = DeepIMap<BrandColorGradient>;

export const brandColorGradientIsValid = (gradient: BrandColorGradientMap): boolean => !!(
  gradient.get('color1')
  && gradient.get('color2')
  && gradient.get('direction')
);

export const brandColorGradientIsMissingDirectionOnly = (gradient: BrandColorGradientMap): boolean => !!(
  !gradient.get('direction')
  && gradient.get('color1')
  && gradient.get('color2')
);
