import _ from 'lodash';
import React from 'react';

import { HorizontalAlignmentControlMap, VerticalAlignmentControlMap } from 'components/Toolbar/Controls/AssetAlignment/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import { AssetAlignmentDirection, AssetAlignmentType } from 'const';
import { AssetAlignmentProps } from './models';

const AssetAlignment: React.FunctionComponent<AssetAlignmentProps> = (props) => {
  const { alignment, toggleAlignment, controlState = ControlState, isDisabled = false, showHorizontal = true, showVertical = true } = props;
  const onToggle = (direction: AssetAlignmentDirection, type: AssetAlignmentType): void => toggleAlignment(alignment.set(direction, type));
  const horizontalAlignment = alignment.get(AssetAlignmentDirection.HORIZONTAL);
  const verticalAlignment = alignment.get(AssetAlignmentDirection.VERTICAL);

  return (
    <>
      {
        showHorizontal && _.map(HorizontalAlignmentControlMap, (options, type: AssetAlignmentType) => {
          const { icon, title } = options;

          return (
            <Control
              controlState={controlState}
              disabled={isDisabled}
              key={title}
              onToggle={onToggle.bind(null, AssetAlignmentDirection.HORIZONTAL, type)}
              size="sm"
              state={horizontalAlignment === type ? controlState.ACTIVE : controlState.DEFAULT}
              title={title}
              type={icon}
            />
          );
        })
      }
      {
        showVertical && _.map(VerticalAlignmentControlMap, (options, type: AssetAlignmentType) => {
          const { icon, title } = options;

          return (
            <Control
              controlState={controlState}
              disabled={isDisabled}
              key={title}
              onToggle={onToggle.bind(null, AssetAlignmentDirection.VERTICAL, type)}
              size="sm"
              state={verticalAlignment === type ? controlState.ACTIVE : controlState.DEFAULT}
              title={title}
              type={icon}
            />
          );
        })
      }
    </>
  );
};

export default AssetAlignment;
