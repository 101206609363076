import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_CELL: null,
    ADD_LAYOUTS: null,
    ADD_LAYOUT_COLUMN: null,
    ADD_LAYOUT_REQUEST: null,
    ADD_LAYOUT_ROW: null,
    COPY_LAYOUT: null,
    DELETE_CELL: null,
    DELETE_LAYOUTS: null,
    DELETE_LAYOUT_COLUMN: null,
    DELETE_LAYOUT_REQUEST: null,
    DROP_GROUP_REUSABLE_LAYOUT: null,
    DROP_REUSABLE_LAYOUT: null,
    MOVE_LAYOUT: null,
    RESET_LAYOUT_HEIGHT_SILENTLY: null,
    RESTORE_LAYOUT_ASSETS: null,
    SET_LAYOUT: null,
    SET_LAYOUTS: null,
    SET_LAYOUT_AND_INSTANCES: null,
    SPLIT_CELL: null,
  },
  'Layouts',
);
