import React from 'react';
import { Form } from 'react-bootstrap';

import { RadioListProps } from './models';
import styles from './styles.module.scss';

const RadioButtonList = <Option extends string>(props: RadioListProps<Option>) => {
  const {
    id,
    options,
    activeOption,
    isDisabled,
    onOptionChange,
    getOptionLabel,
    listLabel,
  } = props;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activeOption = event.target.name as Option;

    onOptionChange(activeOption);
  };

  return (
    <Form>
      <Form.Group>
        {listLabel && <Form.Label className={styles.formLabel}>{listLabel}</Form.Label>}
        {
          options.map((option) => {
            return (
              <Form.Check
                type="radio"
                key={option}
                id={`${id}-${option}`}
                disabled={isDisabled(option)}
                label={getOptionLabel(option)}
                name={option}
                checked={option === activeOption}
                onChange={onChange}
                className={styles.formCheck}
              />
            );
          })
        }
      </Form.Group>
    </Form>
  );
};

export default RadioButtonList;
