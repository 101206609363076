import Editor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import React, { CSSProperties, RefObject, forwardRef, useCallback } from 'react';
import * as Constants from 'const';
import { UndoRedoMiddleware } from 'hooks/useUndoRedo';
import * as Models from 'models';
import { undoRedoKeyBindings } from '../helpers/undoRedoKeyBindings';
import { useCustomStyleFn } from '../hooks/useCustomStyleFn';
import { useHandleBeforeInput } from '../hooks/useHandleBeforeInput';
import { useHandleKeyCommand } from '../hooks/useHandleKeyCommand';

type Props = {
  id: string;
  className: string;
  styles: CSSProperties;
  wrapperRef: RefObject<HTMLDivElement>;
  editorState: Draft.EditorState;
  onEditorChange: (state: Draft.EditorState) => void;
  returnFocusToEditor: () => void;
  editMode: boolean;
  fillUndoStackIfEmpty: () => void;
  undoStackMiddleware: UndoRedoMiddleware<unknown>;
  brandProps: {
    colors: Models.BrandColorsList;
    fonts: Models.BrandFontsList;
  };
};

export const CallToActionEditor = forwardRef<Editor, Props>((props, ref) => {
  const {
    id,
    className,
    styles,
    wrapperRef,
    editorState,
    onEditorChange,
    returnFocusToEditor,
    editMode,
    fillUndoStackIfEmpty,
    undoStackMiddleware,
    brandProps,
  } = props;

  const editorCustomStyleFn = useCustomStyleFn(brandProps);

  const noop = useCallback(() => Constants.HandleValue.NOT_HANDLED, []);
  const handleBeforeInput = useHandleBeforeInput(fillUndoStackIfEmpty);
  const handleKeyCommand = useHandleKeyCommand(fillUndoStackIfEmpty);

  return (
    <div
      id={id}
      className={className}
      onDoubleClick={returnFocusToEditor}
      onClick={returnFocusToEditor}
      style={styles}
    >
      <div ref={wrapperRef}>
        <Editor
          editorState={editorState}
          customStyleFn={editorCustomStyleFn}
          handleBeforeInput={handleBeforeInput}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={undoStackMiddleware(noop) as () => Draft.DraftHandleValue}
          handleReturn={undoStackMiddleware(noop) as () => Draft.DraftHandleValue}
          keyBindingFn={undoRedoKeyBindings}
          onChange={onEditorChange}
          readOnly={!editMode}
          ref={ref}
        />
      </div>
    </div>
  );
});
