import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isCallToAction(entity: Models.CombinedDocument): entity is Models.CallToAction;
export function isCallToAction(entity: Models.CombinedDocumentMap): entity is Models.CallToActionMap;
export function isCallToAction(entity: Models.LayeredRelation): entity is Models.LayeredRegularRelation<Models.CallToActionStyles>;
export function isCallToAction(entity: Models.LayeredRelationMap): entity is Models.LayeredRegularRelationMap<Models.ImageRelationStyles>;
export function isCallToAction(entity: Models.Relation): entity is Models.RegularRelation<Models.CallToActionStyles>;
export function isCallToAction(entity: Models.RelationMap): entity is Models.RegularRelationMap<Models.CallToActionStyles>;
export function isCallToAction(entity: string);
export function isCallToAction(entity): boolean {
  const entityType = typeof entity === 'string' ? entity : getValue(entity, 'entityType');

  return entityType === EntityType.CALL_TO_ACTION;
}
