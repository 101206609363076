import Draft from 'draft-js';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as editorUtils from 'utils/editor';

type Handler = () => Draft.DraftHandleValue;

export function useHandleReturn(
  editorState: Draft.EditorState,
  onEditorChange: (state: Draft.EditorState) => void,
): Handler {
  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  return useCallback(
    () => {
      /**
      * https://github.com/facebook/draft-js/issues/723
      * handles Enter button
      */
      const state = editorStateRef.current;
      const contentState = state.getCurrentContent();
      const selectionState = state.getSelection();
      let newContentState = Draft.Modifier.splitBlock(contentState, selectionState);

      const newBlockKey = newContentState.getSelectionAfter().getStartKey();
      const originalBlockKey = newContentState.getSelectionBefore().getStartKey();
      const originalBlock = contentState.getBlockMap().get(originalBlockKey);
      const originalBlockData = originalBlock.getData();
      const originalBlockType = originalBlock.getType();
      const originalBlockDepth = originalBlock.getDepth();

      newContentState = Draft.Modifier.setBlockData(newContentState, newContentState.getSelectionAfter(), originalBlockData);
      newContentState = Draft.Modifier.setBlockType(newContentState, newContentState.getSelectionAfter(), originalBlockType);
      newContentState = newContentState.setIn(['blockMap', newBlockKey, 'depth'], originalBlockDepth) as Draft.ContentState;

      newContentState = editorUtils.ensureEmptyUnorderedListStyle(newBlockKey, newContentState, state);
      newContentState = editorUtils.ensureEmptyUnorderedListStyle(originalBlockKey, newContentState, state);

      onEditorChange(Draft.EditorState.push(state, newContentState, Constants.EditorChangeType.SPLIT_BLOCK));

      return Constants.HandleValue.HANDLED;

    }, [onEditorChange],
  );
}
