/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { getDefaultKeyBinding } from 'draft-js';
import { EditorKeyBindingCommand } from 'const';

export const undoRedoKeyBindings = (e: any): string | null => {
  if (e.ctrlKey || e.metaKey) {
    if (e.code === 'KeyZ' && !e.shiftKey) { // ctrl/cmd + z
      e.preventDefault();

      return EditorKeyBindingCommand.UNDO;
    } else if ((e.code === 'KeyZ' && e.shiftKey) || e.code === 'KeyY') { // ctrl/cmd + shift + z and ctrl/cmd + y
      e.preventDefault();

      return EditorKeyBindingCommand.REDO;
    }
  }

  return getDefaultKeyBinding(e);
};
