import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { Layer, ModalType, ReusableLayoutChangeType } from 'const';
import { cancelLastUndoState } from 'containers/App/actions';
import { cancelLastUndoState as cancelLastUndoStateSelector } from 'containers/App/selectors';
import { takeModalResponse } from 'containers/Common/services/takeModalWindowResponse';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { updateLayoutAssets } from 'containers/Layouts/services/updateLayoutAssets';
import { showModal } from 'containers/ModalManager/actions';
import { unlinkReusableLayout as unlinkReusableLayoutActionCreator } from 'containers/ModalWindows/SaveReusableLayout/actions';
import { unlinkReusableLayout } from 'containers/ModalWindows/SaveReusableLayout/sagas/unlinkReusableLayout';
import { sections as screenDefsSectionsSelector } from 'containers/ScreenDefinitions/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import { cancel } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { pickWithOrder } from 'utils/pickWithOrder';
import { restoreLayoutAssets } from './restoreLayoutAssets';

export function* chooseChangeType(
  editedLayout: Models.CombinedLayoutMap,
  prevLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId,
  currentLayoutAssetsByLayoutId: Models.ExtendedCombinedLayoutAssetsByLayoutId,
  modalWindowId: string,
  layoutToRestore: Models.CombinedLayoutMap,
  currentLayer: Layer,
) {
  try {
    const layoutObject = editedLayout.toJS() as Models.CombinedLayout;
    yield put(showModal(
      ModalType.EDIT_REUSABLE_LAYOUT,
      {
        isGroupLayout: isGroupLayout(layoutObject),
        documentId: layoutObject.documentId,
        disableClickOutside: true,
        layoutId: layoutObject.id,
      },
      modalWindowId,
    ));
    const changeType: ReturnTypeSaga<takeModalResponse<ReusableLayoutChangeType>> = yield call(takeModalResponse, modalWindowId);

    switch (changeType) {
      case ReusableLayoutChangeType.SAVE_AS_NEW: {
        const screenDefinitionsSections: ReturnTypeSaga<typeof screenDefsSectionsSelector> = yield select(screenDefsSectionsSelector);
        const sections: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
        const layouts = (yield select(layoutsSelector)).toJS() as Models.CombinedLayouts;

        const { section: sectionId } = layoutObject;
        const sectionName = sections.getIn([sectionId, 'name']);
        const section = screenDefinitionsSections.find(section => section.get('name') === sectionName);
        const sectionDisplayName = section ? section.get('displayName') : '';

        const showModalAction = isGroupLayout(layoutObject)
          ?
          showModal(
            ModalType.SAVE_GROUP_LAYOUT,
            {
              groupLayout: layoutObject,
              layouts: _.values(pickWithOrder(layouts, layoutObject.layoutIds)),
              sectionDisplayName,
            } as Models.SaveGroupLayoutWindowOptions,
            modalWindowId,
          )
          :
          showModal(
            ModalType.SAVE_LAYOUT,
            {
              layout: layoutObject,
              sectionDisplayName,
            },
            modalWindowId,
          );
        yield put(showModalAction);

        const isSaveChosen: ReturnTypeSaga<takeModalResponse<boolean>> = yield call(takeModalResponse, modalWindowId);

        isSaveChosen
          // need to restore previous layout assets' state for all other copies of changed layout if we save it as a new
          ? yield call(
            restoreLayoutAssets,
            layoutToRestore,
            editedLayout,
            prevLayoutAssetsByLayoutId,
            currentLayoutAssetsByLayoutId,
          )
          : yield call(
            chooseChangeType,
            editedLayout,
            prevLayoutAssetsByLayoutId,
            currentLayoutAssetsByLayoutId,
            modalWindowId,
            layoutToRestore,
            currentLayer,
          );

        break;
      }
      case ReusableLayoutChangeType.UPDATE_EXISTING: {
        const currentLayoutAssets = currentLayoutAssetsByLayoutId[layoutObject.id];
        yield call(updateLayoutAssets, editedLayout.get('documentId'), currentLayoutAssets);
        yield call(
          [Notifications, Notifications.success],
          intlGet('Notification.Success', isGroupLayout(editedLayout) ? 'GroupLayoutHasBeenUpdated' : 'LayoutHasBeenUpdated'),
          intlGet('Notification', 'Success'),
        );
        break;
      }
      case ReusableLayoutChangeType.UNLINK: {
        yield call(unlinkReusableLayout, unlinkReusableLayoutActionCreator(layoutObject.id));
        yield call(
          restoreLayoutAssets,
          layoutToRestore,
          editedLayout,
          prevLayoutAssetsByLayoutId,
          currentLayoutAssetsByLayoutId,
          true,
        );
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        // need to restore previous layout assets' state
        yield call(restoreLayoutAssets, editedLayout, editedLayout, prevLayoutAssetsByLayoutId, currentLayoutAssetsByLayoutId);
        const cancelLastUndoState: ReturnTypeSaga<typeof cancelLastUndoStateSelector> = yield select(cancelLastUndoStateSelector);
        if (cancelLastUndoState) {
          yield put(cancel());
        }

        break;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], error);
  } finally {
    yield put(cancelLastUndoState(false));
  }
}
