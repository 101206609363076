import { push, routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'store/reducers';
import rootSaga from 'store/sagas';

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    alert(`Error occurred (saga): ${error}`);
    // eslint-disable-next-line no-console
    console.error('Error occurred in redux saga middleware.', error, sagaStack);
  },
});
const history = createBrowserHistory();
const router = routerMiddleware(history);

const actionCreators = Object.assign({}, {
  push,
});

const logger = createLogger({
  level: 'info',
  collapsed: true,
});

const composeEnhancer: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionCreators })
  : compose;

// export default configuration;
function configureStore(initialState: Object) {

  const store = createStore(
    enableBatching(rootReducer(history)),
    initialState,
    composeEnhancer(
      applyMiddleware(
        sagaMiddleware,
        router,
        logger,
      ),
    ),
  );
  sagaMiddleware.run(rootSaga);

  // Hot reloading
  if (module.hot) {
    module.hot.accept('store/reducers', () => {
      store.replaceReducer(rootReducer(history));
    });
  }

  return store;
}

export default configureStore;
