import { Schemas } from 'services/ArtboardConverter/models';

export const createBorder = ({
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
} = {} as Partial<Schemas.Border>): Schemas.Border => ({
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
});
