import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProjectPanel from 'components/ProjectPanel';
import { ProjectPanelActions, ProjectPanelSelectors } from 'components/ProjectPanel/models';
import { toggleDragState, toggleUpdatedDocumentsDisplaying } from 'containers/App/actions';
import {
  showUpdatedImages,
  showUpdatedModuleBundles,
  showUpdatedReferences,
  showUpdatedReusableLayouts,
  showUpdatedStoryCards,
  showUpdatedTextCollections,
} from 'containers/App/selectors';
import {
  groupLayoutDocumentsForAssetsPanel,
  reusableLayoutDocumentsForAssetsPanel,
  updatedInPromoMatsEntities,
} from 'containers/Documents/selectors';
import { isReferenceCitationElementOnArtboard } from 'containers/Layouts/selectors';
import { projectType } from 'containers/Project/selectors';
import { setActiveTab, setContentFilter, toggleSection } from 'containers/ProjectPanel/actions';
import {
  activeSections,
  activeTab,
  availableFilters,
  citations,
  images,
  moduleBundles,
  reusableLayouts,
  storyCardsAndMagicForms,
  textCollections,
  textComponents,
} from 'containers/ProjectPanel/selectors';

const mapStateToProps = createStructuredSelector({
  activeSections,
  activeTab,
  availableFilters,
  citations,
  groupLayoutDocuments: groupLayoutDocumentsForAssetsPanel,
  images,
  isReferenceCitationElementOnArtboard,
  moduleBundles,
  projectType,
  reusableLayouts,
  reusableLayoutDocuments: reusableLayoutDocumentsForAssetsPanel,
  showUpdatedImages,
  showUpdatedModuleBundles,
  showUpdatedReferences,
  showUpdatedReusableLayouts,
  showUpdatedStoryCards,
  showUpdatedTextCollections,
  storyCardsAndMagicForms,
  textCollections,
  textComponents,
  updatedInPromoMatsEntities,
});

const mapDispatchToProps = {
  setActiveTab,
  toggleSection,
  setContentFilter,
  toggleDragState,
  toggleUpdatedDocumentsDisplaying,
};

export default connect<ProjectPanelSelectors, ProjectPanelActions>(mapStateToProps, mapDispatchToProps)(ProjectPanel);
