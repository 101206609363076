import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EditReusableLayout from 'components/ModalWindows/EditReusableLayout';
import {
  EditReusableLayoutActions as Actions,
  EditReusableLayoutOwnProps as OwnProps,
  EditReusableLayoutProps,
  EditReusableLayoutSelectors as Selectors,
} from 'components/ModalWindows/EditReusableLayout/models';
import { groupLayoutDocumentsForAssetsPanel, reusableLayoutDocumentsForAssetsPanel } from 'containers/Documents/selectors';
import { layouts } from 'containers/Layouts/selectors';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: EditReusableLayoutProps) => createStructuredSelector({
  layouts,
  groupLayoutDocuments: groupLayoutDocumentsForAssetsPanel,
  reusableLayoutDocuments: reusableLayoutDocumentsForAssetsPanel,
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect<Selectors, Actions, OwnProps>(mapStateToProps, mapDispatchToProps)(EditReusableLayout);
