import React from 'react';

import { LayoutListItemContent } from 'components/LayoutListItem';
import styles from './styles.module.scss';

const LayoutListItemDragPreview = ({ document, thumbnailUrl }) => {
  return (
    <div className={styles.LayoutListItemDragPreview}>
      <LayoutListItemContent document={document} thumbnailUrl={thumbnailUrl} isPreview={true}/>
    </div>
  );
};

export default LayoutListItemDragPreview;
