import _ from 'lodash';

import * as Models from 'models';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';

interface Arg {
  project: {
    activeLayer: string;
  };
  layouts: Models.LayeredCombinedLayouts;
  documents: Models.CombinedDocuments;
}

export function deleteEmptyGroupLayoutDocuments<T extends Arg>(arg: T): void {
  const { layouts, documents } = arg;

  const groupLayoutDocumentIdsToDelete = _(layouts)
    .pickBy(layout => isGroupLayout(layout) && _.isEmpty(layout.layoutIds))
    .flatMap(layout => _.values(layout.documentId))
    .compact()
    .value();

  groupLayoutDocumentIdsToDelete.forEach((documentId) => { delete documents[documentId]; });
}
