import React from 'react';

const ToolbarSuperscript = (): JSX.Element => (
  <svg width="19px" height="17px" viewBox="0 0 19 17">
    <defs>
      <path
        d="M19,0 L19,2.1 L17,2.1 L17,7 L15,7 L15,2.1 L13,2.1 L13,0 L19,0 Z M4.60099338,11.2307692 L9.39900662,11.2307692 L7,4.86153846
       L4.60099338,11.2307692 Z M11.5778146,17 L10.2566225,13.5384615 L3.72019868,13.5384615 L2.42218543,17 L-8.8817842e-16,17
        L5.92218543,2 L8.07781457,2 L14,17 L11.5778146,17 Z"
        id="path-ToolbarSuperscript"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-570.000000, -200.000000)">
        <g transform="translate(0.000000, 180.000000)">
          <g transform="translate(565.000000, 15.000000)">
            <g transform="translate(5.000000, 5.000000)">
              <g>
                <mask id="mask-ToolbarSuperscript" fill="white">
                  <use xlinkHref="#path-ToolbarSuperscript" />
                </mask>
                <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-ToolbarSuperscript" />
                <g mask="url(#mask-ToolbarSuperscript)" fill="#FFFFFF" id="White">
                  <g transform="translate(0.000000, -2.000000)">
                    <rect x="0" y="0" width="22" height="22" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ToolbarSuperscript;
