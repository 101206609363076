import React from 'react';
/* eslint-disable max-len */

const Refresh = (): JSX.Element => (
  <svg viewBox="0 0 25 25">
    <path d="M12.36,0A12.45,12.45,0,0,0,0,12.5a1.19,1.19,0,0,0,2.38,0s0,0,0,0A10,10,0,0,1,20.87,7.14H18.45a1.19,1.19,0,1,0,0,2.38h4.8A1.19,1.19,0,0,0,24.4,8.33V3.57a1.19,1.19,0,0,0-1.17-1.21h0A1.19,1.19,0,0,0,22,3.57h0V4.66A12.37,12.37,0,0,0,12.36,0ZM23.79,11.29a1.19,1.19,0,0,0-1.17,1.21h0A10,10,0,0,1,4.11,17.84H6.55a1.19,1.19,0,0,0,0-2.38H1.79A1.19,1.19,0,0,0,.6,16.65v4.76a1.19,1.19,0,0,0,1.17,1.21h0A1.19,1.19,0,0,0,3,21.45s0,0,0,0V20.34A12.36,12.36,0,0,0,25,12.5a1.19,1.19,0,0,0-1.17-1.21Z" />
  </svg>
);

export default Refresh;
