import React from 'react';
/* eslint-disable max-len */

const TextCollection = (): JSX.Element => (
  <svg viewBox="0 0 80 80">
    <path d="M60.8060926,16 L61.3333333,27.1775047 L59.9976567,27.1775047 C59.7398933,25.2091292 59.3884029,23.8031679 58.9431752,22.9595784 C58.2167509,21.600462 57.2501525,20.5987145 56.0433509,19.9543059 C54.8365493,19.3098973 53.2489847,18.9876978 51.2806093,18.9876978 L44.5670767,18.9876978 L44.5670767,55.4024615 C44.5670767,58.3315916 44.883418,60.1593414 45.5161101,60.8857657 C46.4065657,61.8699534 47.777378,62.3620399 49.6285882,62.3620399 L51.2806093,62.3620399 L51.2806093,63.6625671 L31.0697129,63.6625671 L31.0697129,62.3620399 L32.7568834,62.3620399 C34.7721249,62.3620399 36.2015189,61.7527899 37.0451084,60.5342718 C37.5606353,59.7844145 37.8183948,58.0738282 37.8183948,55.4024615 L37.8183948,18.9876978 L32.0890451,18.9876978 C29.8629063,18.9876978 28.2811998,19.1517266 27.3438781,19.4797892 C26.12536,19.925017 25.0826054,20.7803101 24.2155829,22.0456944 C23.3485604,23.3110786 22.8330412,25.0216649 22.66901,27.1775047 L21.3333333,27.1775047 L21.8957235,16 L60.8060926,16 Z" fill="#B3B3B3" fillRule="nonzero" />
  </svg>
);

export default TextCollection;
