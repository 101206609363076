import React from 'react';

const ImageTextLeft = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <defs>
      <polygon id="path-1" points="0.0188306705 0.0430306653 26.9190841 0.0430306653 26.9190841 14.9298145 0.0188306705 14.9298145" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Layouts" transform="translate(-240.000000, -484.000000)">
        <g id="Image-and-Text-2" transform="translate(240.000000, 484.000000)">
          <rect id="Rectangle-Copy-24" fill="#FFFFFF" x="0.75" y="0.75" width="107.5" height="107.5" />
          <rect id="Rectangle-Copy-25" fill="#F9AD3D" x="17" y="40.75" width="30" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-26" fill="#F9AD3D" x="57" y="40.75" width="35" height="32" />
          <rect id="Rectangle-Copy-27" fill="#F9AD3D" x="17" y="53.75" width="30" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-28" fill="#F9AD3D" x="17" y="66.75" width="30" height="5" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default ImageTextLeft;
