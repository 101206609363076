import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { setDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import * as Models from 'models';
import { deleteReferenceCitationFromComponents as deleteReferenceCitation } from 'services/deleteReferenceCitationFromComponents';
import { handleSagaError } from 'services/handleError';
import { isTextComponent } from 'utils/entityType';
import { Action } from '../models';

export function* deleteReferenceCitationFromComponents(action: Action.IDeleteReferenceCitationFromComponents) {
  try {
    const { referenceCitationId, textComponentIds } = action.payload;

    const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const textComponents = documents.filter(isTextComponent) as Models.TextComponentsMap;

    const updatedEntities: ReturnTypeSaga<typeof deleteReferenceCitation> = yield call(
      deleteReferenceCitation,
      referenceCitationId,
      textComponents,
      relations,
      textComponentIds,
    );
    const { updatedDocuments, updatedRelations } = updatedEntities;

    yield put(batchActions([
      setDocuments(Object.assign(documents.toJS() as Models.CombinedDocumentsMap, updatedDocuments)),
      setRelations(Object.assign(relations.toJS() as Models.LayeredRelations, updatedRelations)),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.deleteReferenceCitationFromComponents', 'DeleteReferenceCitationFromComponents');
  }
}
