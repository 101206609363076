import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { ModalType } from 'const';
import { ModalWindowsMap, State } from './models';

const modalManager = (state): State.IState => state.get('modalManager');

export const modalWindows = state => modalManager(state).get('modalWindows');
export const activeModalIds = state => modalWindows(state).keySeq();

export const modalWindowOptions = <T = unknown>(modalWindowId: string): Selector<State.IState, T> =>
  createSelector<State.IState, ModalWindowsMap, T>(
    modalWindows,
    windows => windows.getIn([modalWindowId, 'options'], {}) as T,
  );

export const isNotificationAccessError = (state): boolean => {
  return Boolean([...modalWindows(state) as unknown as Map<string, ModalWindowsMap>]
    .map(item => item[1])
    .find(item => (item.get('type') as unknown as string) === ModalType.NOTIFICATION_ACCESS_ERROR));
};
