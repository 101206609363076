import _ from 'lodash';

import { DocumentsSource, Layer } from 'const';
import { groupLayoutDocumentFactory } from 'factories/document/groupLayoutDocumentFactory';
import * as Models from 'models';
import { generateRandomInteger } from 'utils/generateRandomInteger';

export function createMockGroupReusableLayoutOnActiveArtboard(
  groupLayout: Models.LayeredGroupLayout,
  layouts: Models.LayeredCombinedLayouts,
  reusableLayoutDocumentsOnActiveLayer: Models.ReusableLayouts,
  metadata: Models.MarketMetaData,
  activeLayer: Layer,
  newDocumentId: string,
): Models.GroupLayoutDocument {
  const { layoutIds, styles } = groupLayout;
  // create layout document ids list from already created mock reusable layout documents or RLs
  const newLayoutDocumentIds = _(layouts)
    .pick(layoutIds)
    .map(layout => layout.documentId[activeLayer])
    .compact()
    .map(layoutDocumentId => reusableLayoutDocumentsOnActiveLayer[layoutDocumentId].documentId)
    .value();

  return groupLayoutDocumentFactory({
    id: newDocumentId,
    documentId: generateRandomInteger(),
    entities: {
      layouts: newLayoutDocumentIds,
      styles,
    },
    ...metadata,
    documentSource: [DocumentsSource.ONLINE],
    isMockDocument: true,
  });
}
