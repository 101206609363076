import { DocumentsKey, EntityType } from 'const';
import * as Models from 'models';

export const DocumentsKeyByEntityType: PartialRecord<EntityType, DocumentsKey> = {
  [EntityType.CALL_TO_ACTION]: DocumentsKey.CALL_TO_ACTIONS,
  [EntityType.GROUP_LAYOUT]: DocumentsKey.GROUP_LAYOUTS,
  [EntityType.IMAGE]: DocumentsKey.IMAGES,
  [EntityType.LAYOUT]: DocumentsKey.LAYOUTS,
  [EntityType.MAGIC_FORM]: DocumentsKey.MAGIC_FORMS,
  [EntityType.MASTER_SURFACE]: DocumentsKey.MASTER_SURFACES,
  [EntityType.MODULE_BUNDLE]: DocumentsKey.MODULE_BUNDLES,
  [EntityType.PROJECT_FILE]: DocumentsKey.PROJECT_FILES,
  [EntityType.REFERENCE_CITATION]: DocumentsKey.REFERENCE_CITATIONS,
  [EntityType.STORY_CARD]: DocumentsKey.STORY_CARDS,
  [EntityType.TEXT_COLLECTION]: DocumentsKey.TEXT_COLLECTIONS,
  [EntityType.TEXT]: DocumentsKey.TEXT_COMPONENTS,
};

export interface Documents {
  [DocumentsKey.CALL_TO_ACTIONS]: Models.CallToActions;
  [DocumentsKey.GROUP_LAYOUTS]: Models.GroupLayoutDocuments;
  [DocumentsKey.IMAGES]: Models.Images;
  [DocumentsKey.MAGIC_FORMS]: Models.MagicForms;
  [DocumentsKey.MASTER_SURFACES]: Models.Documents; // TODO: create separate type
  [DocumentsKey.MODULE_BUNDLES]: Models.ModuleBundles;
  [DocumentsKey.PROJECT_FILES]: Models.ProjectFiles;
  [DocumentsKey.REFERENCE_CITATIONS]: Models.ReferenceCitations;
  [DocumentsKey.LAYOUTS]: Models.ReusableLayouts;
  [DocumentsKey.STORY_CARDS]: Models.StoryCards;
  [DocumentsKey.TEXT_COLLECTIONS]: Models.TextCollections;
  [DocumentsKey.TEXT_COMPONENTS]: Models.TextComponents;
}

export type DocumentsMap = DeepIMap<Documents>;
