import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { setLastEditedLayoutId } from 'containers/App/actions';
import { deleteDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { deleteColumn } from 'utils/deleteColumn';
import { intlGet } from 'utils/intlGet';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { isRelationUsedSeveralTimes } from 'utils/validator/isRelationStillUsed';
import { Action } from '../models';
import { getTextComponentIdsToDelete } from '../services/getTextComponentIdsToDelete';

export function* deleteLayoutColumn(action: Action.IDeleteLayoutColumn) {
  try {
    const { layoutId, position } = action.payload;

    const accepted: boolean = yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title: intlGet('ConfirmationWindow.Title', 'DeleteLayoutColumn'),
        message: intlGet('ConfirmationWindow.Message', 'RemoveLayoutColumnAndContent'),
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Delete'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'Cancel'),
      },
    );

    if (!accepted) {
      return;
    }

    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);
    const layouts: ReturnTypeSaga<typeof layeredLayoutsSelector> = yield select(layeredLayoutsSelector);
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const layout = layouts.get(layoutId) as Models.LayeredLayoutMap;
    const layoutRelation = relations.get(layout.get('relationId'));
    const layoutRelationChildIds = layoutRelation.get('relationIds');
    const firstLayoutChildRelationId = layoutRelationChildIds.first<string>();
    const firstLayoutChildRelation = relations.get(firstLayoutChildRelationId) as Models.RowRelationMap | Models.LayeredRegularRelationMap;

    if (!isRowRelation(firstLayoutChildRelation)) {
      return;
    }

    const columnToDeleteRelationId: string = firstLayoutChildRelation.getIn(['relationIds', position]);
    const columnToDelete = relations.get(columnToDeleteRelationId) as Models.ColumnRelationMap | Models.RegularRelationMap;

    const columnToDeleteChildRelationIds = isColumnRelation(columnToDelete)
      ? getFlattenedRelations(columnToDelete, relations).keySeq().toArray()
      : [];

    const updatedRelations = relations.update(firstLayoutChildRelationId, relation => relation
      .deleteIn(['relationIds', position])
      .updateIn(['styles', 'columnsWidth'], columnsWidth => deleteColumn(columnsWidth, position)),
    );

    const relationIdsToDelete = [
      columnToDeleteRelationId,
      ...columnToDeleteChildRelationIds,
    ].filter(relationId => !isRelationUsedSeveralTimes(relationId, layouts, relations));

    const textComponentIdsToDelete: ReturnTypeSaga<typeof getTextComponentIdsToDelete> = yield call(
      getTextComponentIdsToDelete,
      layout,
      relationIdsToDelete,
      documents,
      relations,
      layer,
    );
    yield put(batchActions([
      deleteDocuments(textComponentIdsToDelete),
      setLastEditedLayoutId(layout.get('id')),
      setRelations(updatedRelations.removeAll(relationIdsToDelete)),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.deleteLayoutColumn', 'DeleteLayoutColumn');
  }
}
