import React from 'react';
/* eslint-disable max-len */

const ViewDetails = (): JSX.Element => (
  <svg viewBox="0 0 23 16">
    <defs>
      <path d="M14.0961843,6.41904928 C14.3253532,6.8205686 14.4651794,7.27622163 14.4651794,7.76741028 C14.4651794,9.27352287 13.2389599,10.5 11.7327558,10.5 C10.2265517,10.5 9,9.27352287 9,7.76741028 C9,6.26129769 10.2265517,5.03482056 11.7327558,5.03482056 C11.761651,5.03482056 11.7865607,5.04212695 11.8154559,5.04212695 C11.7649723,5.18559787 11.7327558,5.33272199 11.7327558,5.49047358 C11.7327558,6.24336382 12.3458656,6.85643633 13.0991337,6.85643633 C13.4937027,6.85643633 13.845095,6.68805726 14.0961843,6.41904928 M11.5,14 C8.46382994,14 6,11.311748 6,8 C6,4.68825202 8.46382994,2 11.5,2 C14.535769,2 17,4.68825202 17,8 C17,11.311748 14.535769,14 11.5,14 M11.4997903,0 C5.14735748,0 0,7.99979165 0,7.99979165 C0,7.99979165 5.14735748,16 11.4997903,16 C17.8518039,16 23,7.99979165 23,7.99979165 C23,7.99979165 17.8518039,0 11.4997903,0" id="vd-path-1" />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Navigate-Card" transform="translate(-370.000000, -141.000000)">
        <g id="content">
          <g transform="translate(0.000000, 28.000000)">
            <g id="bottom" transform="translate(46.743243, 92.000000)">
              <g id="all-categories">
                <g id="3.-Icons/ic_preview" transform="translate(319.256757, 15.000000)">
                  <g id="Icon-Color" transform="translate(4.000000, 6.000000)">
                    <mask id="vd-mask-2" fill="white">
                      <use xlinkHref="#vd-path-1" />
                    </mask>
                    <use id="Mask" fill="#F05D97" xlinkHref="#vd-path-1" />
                    <g id="5.-Colors/Orange" mask="url(#vd-mask-2)" fill="#F9AD3D">
                      <g transform="translate(-2.000000, -6.428284)" id="Orange">
                        <rect x="0" y="0" width="32.4748387" height="32.4748387" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ViewDetails;
