import _ from 'lodash';
import React from 'react';

import Button from 'components/Button';
import { IconType } from 'components/Icon/constants';
import { DEFAULT_MAX_ZOOM_SIZE, DEFAULT_MIN_ZOOM_SIZE, KeyboardKey, ZOOM_SIZE_STEP } from 'const';
import { ZoomControlProps } from './models';
import styles from './styles.module.scss';
import { ZOOM_VALUE_REG_EXP, getZoomValue, toPercent } from './utils';

const ZoomControl: React.FunctionComponent<ZoomControlProps> = (props) => {
  const { showPreview, scale } = props;
  const zoomSize = Math.trunc(scale * 100);

  const [inputValue, setInputValue] = React.useState(toPercent(zoomSize));
  const disabledZoomMinus = zoomSize <= DEFAULT_MIN_ZOOM_SIZE;
  const disabledZoomPlus = zoomSize >= DEFAULT_MAX_ZOOM_SIZE;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
    const { value } = event.target;

    if (value.match(ZOOM_VALUE_REG_EXP)) {
      setInputValue(value);
    }
  };

  const setZoomState = (newZoomSize: number): void => {
    const { updateArtboardZoomState, updatePreviewZoomState } = props;

    const resultZoomSize = _.clamp(newZoomSize, DEFAULT_MIN_ZOOM_SIZE, DEFAULT_MAX_ZOOM_SIZE);
    setInputValue(toPercent(resultZoomSize));

    showPreview
      ? updatePreviewZoomState(resultZoomSize / 100)
      : updateArtboardZoomState(resultZoomSize / 100);
  };

  const onZoomOut = (): void => {
    const newZoomSize = zoomSize - ZOOM_SIZE_STEP ;
    const roundZoomSize = Math.ceil(newZoomSize / 10) * 10;
    setZoomState(roundZoomSize);
  };

  const onZoomIn = (): void => {
    const newZoomSize = zoomSize + ZOOM_SIZE_STEP ;
    const roundZoomSize = Math.trunc(newZoomSize / 10) * 10;
    setZoomState(roundZoomSize);
  };

  const applyZoomSize = (): void => {
    const newZoomSize = getZoomValue(inputValue);
    setZoomState(newZoomSize);
  };

  const onKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === KeyboardKey.ENTER) {
      applyZoomSize();
    }
  };

  return (
    <div className={styles.ZoomControl}>
      <Button
        icon={IconType.ZOOM_MINUS}
        size="md"
        disabled={disabledZoomMinus}
        onClick={onZoomOut}
        highlightOnHover={true}
      />
      <input
        type="text"
        value={inputValue}
        onKeyPress={onKeyPress}
        onBlur={applyZoomSize}
        onChange={onChange}
      />
      <Button
        icon={IconType.ZOOM_PLUS}
        size="md"
        disabled={disabledZoomPlus}
        onClick={onZoomIn}
        highlightOnHover={true}
      />
    </div>
  );
};

export default ZoomControl;
