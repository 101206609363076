import Draft from 'draft-js';
import * as Constants from 'const';
import * as Models from 'models';

export enum Features {
  APPLY_HACKS = 'apply-editor-hacks',
  ABBREVIATIONS = 'abbreviations',
  DECORATORS = 'decorators',
  CLEAR_FORMATTING = 'clear-formatting',
  BLOCK = 'block',
  BULLET = 'bullet',
  FONT_COLOR = 'font-color',
  INLINE_STYLE_EXTENDED = 'inline-style-extended',
  APPLY_BRANDSTYLE_FOR_EXTENDED_INLINE_STYLE = 'apply-brand-style-for-extended-inline-style',
  LINK = 'link',
}

export type Context<F extends readonly Features[]> = {} & (
  Features.APPLY_HACKS extends F[number]
    ? {
      editMode: boolean;
    }
    : Record<never, unknown>
) & (
  Features.ABBREVIATIONS extends F[number]
    ? {
      abbreviationsData: Models.TextAbbreviationsMap;
    }
    : Record<never, unknown>
) & (
  Features.INLINE_STYLE_EXTENDED extends F[number]
    ? {
      projectType: Constants.ProjectType;
      fonts: Models.BrandFontsList;
    }
    : Record<never, unknown>
) & (
  Features.FONT_COLOR extends F[number]
    ? {
      colors: Models.BrandColorsList;
      brandStyle?: Models.BrandStyleMap;
    }
    : Record<never, unknown>
) & (
  Features.BULLET extends F[number]
    ? {
      colors: Models.BrandColorsList;
    }
    : Record<never, unknown>
);

export type Props<F extends readonly Features[]> = {
  abbreviationId: Features.ABBREVIATIONS extends F[number]
    ? string | undefined
    : undefined;
  abbreviationTerm: string | undefined;
  blockLineHeight: Features.BLOCK extends F[number]
    ? Constants.TextLineHeightValue
    : undefined;
  blockType: Features.BLOCK extends F[number]
    ? Constants.TextHorizontalAlignmentType
    : undefined;
  bulletColor: Features.BULLET extends F[number]
    ? Models.BrandColorMap | undefined
    : undefined;
  fontColor: Features.FONT_COLOR extends F[number]
    ? Models.BrandColorMap | undefined
    : undefined;
  fontFamily: Models.FontFamily | undefined;
  fontSize: number | undefined;
  inlineStyle: Draft.DraftInlineStyle;
  link: Features.LINK extends F[number]
    ? string | undefined
    : undefined;
  linkApplicable: Features.LINK extends F[number]
    ? boolean
    : undefined;
  scriptStyle?: Constants.ScriptType;
  textNoWrap: boolean;
};

type OnBeforeChange = (() => void);

export type Setters<F extends readonly Features[]> = {
  abbreviationId: Features.ABBREVIATIONS extends F[number]
    ? (id: string | undefined, onBeforeChange?: OnBeforeChange) => void
    : undefined;
  blockLineHeight: Features.BLOCK extends F[number]
    ? (value: Constants.TextLineHeightValue) => void
    : undefined;
  blockType: Features.BLOCK extends F[number]
    ? (type: Constants.TextHorizontalAlignmentType | undefined) => void
    : undefined;
  bulletColor: Features.BULLET extends F[number]
    ? (color: Models.BrandColorMap) => void
    : undefined;
  clearFormatting: Features.CLEAR_FORMATTING extends F[number] ? () => void : undefined;
  fontColor: Features.FONT_COLOR extends F[number]
    ? (color: Models.BrandColorMap | undefined, editorState?: Draft.EditorState) => void
    : undefined;
  fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap) => void;
  fontSize: (value: number) => void;
  inlineStyle: (style: string) => void;
  link: Features.LINK extends F[number]
    ? (value: string | undefined, onBeforeChange?: OnBeforeChange) => void
    : undefined;
  scriptStyle: (style: Constants.ScriptType) => void;
  textNoWrap: () => void;
};

