import React from 'react';
import { HOVER_HINT } from 'components/Toolbar/constants';
import BackgroundImage from 'components/Toolbar/Controls/BackgroundImage';
import BorderRadius from 'components/Toolbar/Controls/BorderRadius';
import FillColor from 'components/Toolbar/Controls/FillColor';
import FontSize from 'components/Toolbar/Controls/FontSize';
import Padding from 'components/Toolbar/Controls/Padding';
import Scrollable from 'components/Toolbar/Controls/Scrollable';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import * as Const from 'const';
import BaseToolbar from 'containers/Toolbar/BaseToolbar';
import { intlGet } from 'utils/intlGet';
import { StylesState, StylesStateSetters } from '../hooks/useStyles';

type Props = {
  styles: StylesState;
  stylesSetters: StylesStateSetters;
  layoutId: string;
  projectConfig: Const.ProjectOptionType;
};

const Toolbar: React.FunctionComponent<Props> = (props) => {
  const {
    styles,
    stylesSetters,
    layoutId,
    projectConfig,
  } = props;
  const { allowBackgroundOpacity, allowBackgroundImage, allowScrollableLayout } = projectConfig;

  return (
    <BaseToolbar title={intlGet('EditorToolbar.Asset', 'Layout')} layoutId={layoutId}>
      <ControlGroup>
        <FontSize
          fontSize={styles.fontSize}
          toggleFontSize={stylesSetters.fontSize}
        />
      </ControlGroup>
      <ControlGroup>
        <Padding
          padding={styles.padding}
          togglePadding={stylesSetters.padding}
        />
        <FillColor
          activeColor={styles.backgroundColor}
          toggleColor={stylesSetters.backgroundColor}
          opacity={styles.backgroundColorOpacity}
          toggleOpacity={allowBackgroundOpacity ? stylesSetters.backgroundColorOpacity : undefined}
          gradient={styles.backgroundGradient}
          toggleGradient={stylesSetters.backgroundGradient}
        />
        {allowBackgroundImage && (
          <BackgroundImage
            backgroundImage={styles.backgroundImage}
            toggleBackgroundImage={stylesSetters.backgroundImage}
          />
        )}
        <BorderRadius
          borderRadius={styles.borderRadius}
          title={HOVER_HINT.LAYOUT}
          dropdownWarningMessage={intlGet('EditorToolbar.Tooltip', 'WarningMessage')}
          toggleBorderRadius={stylesSetters.borderRadius}
        />
      </ControlGroup>
      {allowScrollableLayout && (
        <ControlGroup>
          <Scrollable
            scrollable={styles.scrollable}
            toggleScrollable={(): void => stylesSetters.scrollable(!styles.scrollable)}
          />
        </ControlGroup>
      )}
    </BaseToolbar>
  );
};

export default Toolbar;
