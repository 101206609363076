import * as Draft from 'draft-js';

import Abbreviation from './Abbreaviation';
import Link from './Link';
import NoWrap from './NoWrap';
import { findAbbreviation, findLinks, findNoWrap } from './strategy';

export const compositeDecorator = new Draft.CompositeDecorator([
  {
    strategy: findLinks,
    component: Link,
  },
  {
    strategy: findNoWrap,
    component: NoWrap,
  },
  {
    strategy: findAbbreviation,
    component: Abbreviation,
  },
]);
