import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { startHandlingReusableLayoutsEditing, stopHandlingReusableLayoutsEditing } from 'containers/App/actions';
import { handleReusableLayoutsEditing } from 'containers/App/selectors';
import { updateDocument } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { setLayouts } from 'containers/Layouts/actions';
import { Action } from 'containers/Layouts/models';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import * as Models from 'models';
import { updateLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';

export function* resetLayoutHeightSilently(action: Action.ResetLayoutHeightSilently) {
  let handleReusableLayoutsEditingActive: boolean;

  try {
    const { layoutId, height } = action.payload;
    handleReusableLayoutsEditingActive = yield select(handleReusableLayoutsEditing);
    const layouts: ReturnTypeSaga<typeof layoutsSelector> = yield select(layoutsSelector);
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);

    handleReusableLayoutsEditingActive && (yield put(stopHandlingReusableLayoutsEditing()));

    const updatedLayout = (layouts.get(layoutId) as Models.CombinedLayoutMap).setIn(['styles', 'height'], height);
    const layoutDocument = documents.get(updatedLayout.get('documentId')) as Models.CombinedLayoutDocumentMap;

    yield put(batchActions([
      updateDocument(layoutDocument.setIn(['entities', 'styles', 'height'], height)),
      setLayouts(updateLayoutInstances(updatedLayout, layouts)),
    ]));
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], error);
  } finally {
    handleReusableLayoutsEditingActive && (yield put(startHandlingReusableLayoutsEditing()));
  }
}
