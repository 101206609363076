import _ from 'lodash';
import React from 'react';

interface UseImagesLoadingObserverArg {
  container?: React.MutableRefObject<HTMLElement>;
  onLoaded(): void;
  timeout?: number;
}

// TODO: use it everywhere instead of 'react-on-images-loaded' library
export const useImagesLoadingObserver = (arg: UseImagesLoadingObserverArg) => {
  const { onLoaded, timeout = 15000 } = arg;

  const imagesLoaded = React.useRef<boolean>(false);
  const ownContainerRef = React.useRef<HTMLElement>(null);
  const container = arg.container || ownContainerRef;

  const [imagesCount, setImagesCount] = React.useState(0);
  const [loadedImagesCount, setLoadedImagesCount] = React.useState(0);

  const onTimeout = () => !imagesLoaded.current && onLoaded();
  const onImageLoad = () => setLoadedImagesCount(count => count + 1);

  React.useEffect(
    () => {
      const images = container.current.getElementsByTagName('img');
      setImagesCount(images.length);
      let timerId: number;

      if (images.length !== 0) {
        timerId = window.setTimeout(onTimeout, timeout);
      } else {
        onLoaded();
      }

      _.forEach(images, image => image.addEventListener('load', onImageLoad));

      return () => {
        _.forEach(images, imgElement => imgElement.removeEventListener('load', onImageLoad));
        timerId && clearTimeout(timerId);
      };
    },
    [],
  );

  React.useEffect(
    () => {
      if (imagesCount > 0 && loadedImagesCount >= imagesCount) {
        imagesLoaded.current = true;
        onLoaded();
      }
    },
    [loadedImagesCount, imagesCount],
  );

  return {
    container,
  };
};
