import React from 'react';
/* eslint-disable max-len */

const FillColorUnderlined = (): JSX.Element => (
  <svg width="30px" height="30px" viewBox="0 1 21 22">
    <path fillRule="nonzero" d="M24,20 L24,24 L0,24 L0,20 L24,20 Z M7.62,0 L16.56,8.94 C17.15,9.53 17.15,10.48 16.56,11.06 L16.56,11.06 L11.06,16.56 C10.77,16.85 10.38,17 10,17 C9.62,17 9.23,16.85 8.94,16.56 L8.94,16.56 L3.44,11.06 C2.85,10.48 2.85,9.53 3.44,8.94 L3.44,8.94 L8.59,3.79 L6.21,1.41 L7.62,0 Z M19,11.5 C19,11.5 21,13.67 21,15 C21,16.1 20.1,17 19,17 C17.9,17 17,16.1 17,15 C17,13.67 19,11.5 19,11.5 Z M10,5.21 L5.21,10 L14.79,10 L10,5.21 Z" />
  </svg>
);

export default FillColorUnderlined;
