import { call, select } from 'redux-saga/effects';

import { thumbnailsColor } from 'containers/BrandDefinition/selectors';
import { orderedSurfaces } from 'containers/Surfaces/selectors';
import * as Models from 'models';
import { getThumbnailsByArtboardId } from 'utils/getThumbnailsByArtboardId';

export function* getThumbnailsInfo(surfaces?: Models.Screens): Generator<unknown, Models.ThumbnailsInfo> {
  const color: ReturnTypeSaga<typeof thumbnailsColor> = yield select(thumbnailsColor);
  const thumbnailsByArtboardId: ReturnTypeSaga<typeof getThumbnailsByArtboardId> = yield call(
    getThumbnailsByArtboardId,
    surfaces || (yield select(orderedSurfaces)).toJS() as Models.Screens,
  );

  return {
    thumbnailColor: color,
    thumbnailsByArtboardId,
  };
}
