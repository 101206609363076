
import * as Constants from 'const';
import * as Models from 'models';

export const getScreenFormatLabel = (format: Models.ScreenFormat): string => {
  const { type, width, height } = format;
  let label = Constants.ScreenFormatNameByType[type]();

  if (width && height) {
    label += ` ${width}${Constants.FORMAT_SIZE_DELIMITER}${height}`;
  }

  return label;
};
