import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { DocumentsSource, EntityType, ProjectPanelSection, ProjectPanelTab } from 'const';
import { unlockProjectContent } from 'containers/App/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { setUploadingDocumentId } from 'containers/Project/actions';
import { addActiveSection, setActiveTab, setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import * as Models from 'models';
import { Notifications } from 'services/Notifications';
import { isImage } from 'utils/entityType';
import { intlGet } from 'utils/intlGet';
import { setDocument } from '../actions';

const notificationMessages = {
  [EntityType.REFERENCE_CITATION]: {
    getNotificationText: () => intlGet('Notification.Success', 'ReferenceCitationIsAvailableOnAssetPanel'),
  },
  [EntityType.IMAGE]: {
    getNotificationText: () => intlGet('Notification.Success', 'ImageIsAvailableOnAssetPanel'),
  },
};

export function* uploadDocument(
  newDocument: Models.Image | Models.ReferenceCitation,
  uploadDocumentData: () => Promise<Models.Image | Models.ReferenceCitation>,
  modalWindowId: string,
): SagaIterator {
  yield put(batchActions([
    unlockProjectContent(),
    hideModal(modalWindowId),
    setDocument(newDocument),
    setUploadingDocumentId(newDocument.id),
    setAssetIdToScroll(newDocument.id),
    setActiveTab(ProjectPanelTab.ASSETS),
    addActiveSection(isImage(newDocument) ? ProjectPanelSection.IMAGES : ProjectPanelSection.REFERENCE_CITATIONS),
  ]));

  const createdDocument: ReturnTypeSaga<typeof uploadDocumentData> = yield call(uploadDocumentData);
  createdDocument.id = newDocument.id;
  // TODO: need to check
  createdDocument.documentSource = _.union(createdDocument.documentSource, [DocumentsSource.COMPONENTS]);

  yield put(setDocument(createdDocument));
  yield call(
    [Notifications, Notifications.success],
    notificationMessages[createdDocument.entityType].getNotificationText(),
  );
}
