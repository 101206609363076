import React from 'react';
/* eslint-disable max-len */

const CustomScreenFormat = (): JSX.Element => (
  <svg viewBox="0 0 29 23">
    <path d="M22.8,11.4 L20.2666667,11.4 L20.2666667,15.2 L16.4666667,15.2 L16.4666667,17.7333333 L22.8,17.7333333 L22.8,11.4 Z M7.6,7.6 L11.4,7.6 L11.4,5.06666667 L5.06666667,5.06666667 L5.06666667,11.4 L7.6,11.4 L7.6,7.6 Z M25.3333333,0 L2.53333333,0 C1.14,0 0,1.14 0,2.53333333 L0,20.2666667 C0,21.66 1.14,22.8 2.53333333,22.8 L25.3333333,22.8 C26.7266667,22.8 27.8666667,21.66 27.8666667,20.2666667 L27.8666667,2.53333333 C27.8666667,1.14 26.7266667,0 25.3333333,0 Z M25.3333333,20.2793339 L2.53333333,20.2793339 L2.53333333,2.52066668 L25.3333333,2.52066668 L25.3333333,20.2793339 L25.3333333,20.2793339 Z" />
  </svg>
);

export default CustomScreenFormat;
