import * as Models from 'models';
import { validateTextAssets } from 'utils/validator';

export const validateAssets = <T extends Models.GetProjectDataProcessArgs>(args: T): T => {
  const { assets } = args;
  const {
    documents: processedDocuments,
    relations: processedRelations,
  } = validateTextAssets(assets.documents, assets.relations);

  assets.documents = processedDocuments;
  assets.relations = processedRelations;

  return args;
};
