import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { isImmutable } from './immutable';

export function prioritizeLayeredLayout(layeredLayout: Models.LayeredLayout, layer: Layer): Models.Layout;
export function prioritizeLayeredLayout(layeredLayout: Models.LayeredLayoutMap, layer: Layer): Models.LayoutMap;
export function prioritizeLayeredLayout(layeredLayout: Models.LayeredGroupLayout, layer: Layer): Models.GroupLayout;
export function prioritizeLayeredLayout(layeredLayout: Models.LayeredCombinedLayout, layer: Layer): Models.CombinedLayout;
export function prioritizeLayeredLayout(layeredLayout: Models.LayeredCombinedLayoutMap, layer: Layer): Models.CombinedLayoutMap;
export function prioritizeLayeredLayout<T extends Models.LayeredCombinedLayout>(
  layeredLayout: T | DeepIMap<T>,
  layer: Layer,
): Models.CombinedLayout | Models.CombinedLayoutMap {
  return isImmutable(layeredLayout)
    ? (layeredLayout as Models.LayeredLayoutMap).update('documentId', documentId => documentId.get(layer)) as Models.CombinedLayoutMap
    : { ...layeredLayout as Models.LayeredLayout, documentId: layeredLayout.documentId[layer] } as Models.CombinedLayout;
}

export function prioritizeLayeredLayouts(layeredLayouts: Models.LayeredCombinedLayouts, layer: Layer): Models.CombinedLayouts;
export function prioritizeLayeredLayouts(layeredLayouts: Models.LayeredCombinedLayouts, layer: Layer): Models.CombinedLayouts;
export function prioritizeLayeredLayouts(layeredLayouts: Models.LayeredCombinedLayoutsMap, layer: Layer): Models.CombinedLayoutsMap;
export function prioritizeLayeredLayouts<T extends Models.LayeredCombinedLayouts>(
  layeredLayouts: T | DeepIMap<T>,
  layer: Layer,
): Models.CombinedLayouts | Models.CombinedLayoutsMap {
  return isImmutable(layeredLayouts)
    ? layeredLayouts.map(layout => prioritizeLayeredLayout(layout, layer)) as Models.CombinedLayoutsMap
    : _.mapValues<Models.LayeredCombinedLayouts, Models.CombinedLayout>(layeredLayouts, layout => prioritizeLayeredLayout(layout, layer));
}

