import downloadFile from 'js-file-download';
import { call } from 'redux-saga/effects';

import { ARTBOARDS_JSON_NAME } from 'const';
import { ArtboardsJson } from 'services/ArtboardConverter/models';
import { handleSagaError } from 'services/handleError';
import { getArtboardsJson } from '../services/getArtboardsJson';

export function* downloadArtboardsJson() {
  try {
    const artboardsJson: ArtboardsJson = yield call(getArtboardsJson);
    yield call(downloadFile, JSON.stringify(artboardsJson), ARTBOARDS_JSON_NAME);

  } catch (error) {
    yield call(handleSagaError, error, 'Artboards.downloadArtboardsJson', 'DownloadArtboardsJson');
  }
}
