import _ from 'lodash';

// Quotes needs for CSS when font-face has space(s).
export function getFontFaceStringWrapper(fontFace: string): string {
  return _(fontFace)
    .split(',')
    .map((font) => {
      const trimmedFont = font.trim();

      return trimmedFont.includes(' ') ? `'${trimmedFont}'` : trimmedFont;
    })
    .join(', ');
}
