import { call, put, select } from 'redux-saga/effects';

import { setProjectDetails } from 'containers/RootDocument/actions';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import * as api from 'services/api';
import { handleSagaError } from 'services/handleError';
import { updateProjectState } from '../actions';

export function* getProjectDetails() {
  try {
    yield put(updateProjectState({ areDetailsLoading: true }));

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const projectMetadataResponse = yield call(api.getProjectDetails, rootDocument.get('documentId'));

    yield put(setProjectDetails(projectMetadataResponse.data));
  } catch (error) {
    yield call(handleSagaError, error, 'Project.getProjectDetails', 'GetProjectDetails');
  } finally {
    yield put(updateProjectState({ areDetailsLoading: false }));
  }
}
