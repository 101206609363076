
import { Layer } from 'const';
import * as Models from 'models';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { areThereHorizontalNeighbors } from './areThereHorizontalNeighbors';
import { getParent } from './getParent';
import { getVerticalNeighborPosition } from './getVerticalNeighborPosition';
import { traverseDown } from './traverseDown';

/**
 * @param relationId - cell or row relation identifier
 * @param relations
 * @param delta
 * @param layer
 * @param shouldTraverseDown - for internal use only, don not set this argument from outside
 * @param modifyLastCell - update bottom cell height inside the same column to save whole column height
 * @param resetIsAutoFitContent - reset isAutoFitContent cell property (relation.styles)
 */
export function recalculateRowsHeight(
  relationId: string,
  relations: Models.RelationsMap,
  delta: number,
  layer?: Layer,
  shouldTraverseDown?: boolean,
  modifyLastCell?: boolean,
  resetIsAutoFitContent?: boolean,
): Models.RelationsMap;
export function recalculateRowsHeight(
  relationId: string,
  relations: Models.LayeredRelationsMap,
  delta: number,
  layer?: Layer,
  shouldTraverseDown?: boolean,
  modifyLastCell?: boolean,
  resetIsAutoFitContent?: boolean,
): Models.LayeredRelationsMap;
export function recalculateRowsHeight(
  relationId: string,
  relations: Models.RelationsMap | Models.LayeredRelationsMap,
  delta: number,
  layer = Layer.ORIGINAL,
  shouldTraverseDown = true,
  modifyLastCell = false,
  resetIsAutoFitContent = false,
): Models.RelationsMap | Models.LayeredRelationsMap {
  let updatedRelations = relations as Models.LayeredRelationsMap;
  const { parent, position } = getParent(relationId, updatedRelations as Models.LayeredRelationsMap);

  if (!parent) {
    return updatedRelations;
  }

  const parentId = parent.get('id');
  const parentRelationsIds = parent.get('relationIds');
  const parentHasHorizontalNeighbors = areThereHorizontalNeighbors(parentId, relations);

  if (isColumnRelation(parent)) {
    const neighborPosition = modifyLastCell
      ? parentRelationsIds.size - 1
      : getVerticalNeighborPosition(relationId, parent);
    const relationHasNeighbor = neighborPosition !== -1;

    if (shouldTraverseDown) {
      const relationEntry: [string, number] = [relationId, delta];
      const neighborEntry: [string, number] = [parentRelationsIds.get(neighborPosition), -delta];
      const entriesToResize = parentHasHorizontalNeighbors && relationHasNeighbor ? [relationEntry, neighborEntry] : [relationEntry];

      updatedRelations = entriesToResize.reduce(
        (_relations, [_relationId, _delta]) => traverseDown(_relationId, _relations, _delta, layer, resetIsAutoFitContent),
        updatedRelations,
      );
    }

    const parentRowsHeight = parent.getIn(['styles', 'rowsHeight']);
    let updatedParentRowsHeight = parentRowsHeight.update(position, oldHeight => oldHeight - delta);

    if (parentHasHorizontalNeighbors && relationHasNeighbor) {
      updatedParentRowsHeight = updatedParentRowsHeight.update(neighborPosition, oldHeight => oldHeight + delta);
    }

    updatedRelations = updatedRelations.set(parentId, parent.setIn(['styles', 'rowsHeight'], updatedParentRowsHeight));
  } else if (shouldTraverseDown) {
    updatedRelations = parentRelationsIds.reduce(
      (_relations, childRelId) => traverseDown(childRelId, _relations, delta, layer, resetIsAutoFitContent),
      updatedRelations,
    );
  }

  if (!parentHasHorizontalNeighbors) {
    return recalculateRowsHeight(parentId, updatedRelations, delta, layer, false, modifyLastCell, resetIsAutoFitContent);
  }

  return updatedRelations;
}

/**
 * @param relationId - cell relation identifier
 * @param relations
 * @param delta
 * @param layer
 * @param resetIsAutoFitContent - reset isAutoFitContent cell property (relation.styles)
 */
export function recalculateRowsAndNeighborsHeight(
  relationId: string,
  relations: Models.RelationsMap,
  delta: number,
  layer?: Layer,
  resetIsAutoFitContent?: boolean,
): Models.RelationsMap;
export function recalculateRowsAndNeighborsHeight(
  relationId: string,
  relations: Models.LayeredRelationsMap,
  delta: number,
  layer?: Layer,
  resetIsAutoFitContent?: boolean,
): Models.LayeredRelationsMap;
export function recalculateRowsAndNeighborsHeight(
  relationId: string,
  relations: Models.RelationsMap | Models.LayeredRelationsMap,
  delta: number,
  layer = Layer.ORIGINAL,
  resetIsAutoFitContent = false,
): Models.RelationsMap | Models.LayeredRelationsMap {
  let updatedRelations = relations as Models.LayeredRelationsMap;
  const { parent, position } = getParent(relationId, updatedRelations as Models.LayeredRelationsMap);

  if (!parent) {
    return updatedRelations;
  }

  const parentId = parent.get('id');
  const parentRelationsIds = parent.get('relationIds');
  const shouldTraverseDown = isRowRelation(parent);

  if (shouldTraverseDown) {
    updatedRelations = parentRelationsIds.reduce(
      (_relations, childRelId) => childRelId !== relationId ? traverseDown(childRelId, _relations, delta, layer, resetIsAutoFitContent) : _relations,
      updatedRelations,
    );
  }

  if (isColumnRelation(parent)) {
    const parentRowsHeight = parent.getIn(['styles', 'rowsHeight']);
    const updatedParentRowsHeight = parentRowsHeight.update(position, oldHeight => oldHeight - delta);
    updatedRelations = updatedRelations.set(parentId, parent.setIn(['styles', 'rowsHeight'], updatedParentRowsHeight));
  }

  return recalculateRowsAndNeighborsHeight(parentId, updatedRelations, delta, layer, resetIsAutoFitContent);
}

