import _ from 'lodash';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Spinner from 'components/Spinner';
import { AppRoute, LAST_VISITED_SLIDE, NEXT_SLIDE, PREVIOUS_SLIDE } from 'const';
import ZoomControl from 'containers/ZoomControl';
import ZoomWrapper from 'containers/ZoomWrapper';
import { PreviewOptionMap } from 'models';
import { appendCustomFontFaces } from 'services/appendFontFace';
import { formatNumberWithCommas } from 'utils/formatNumberWithCommas';
import { getDefaultPreviewOption } from 'utils/getDefaultPreviewOption';
import { intlGet } from 'utils/intlGet';
import { toPx } from 'utils/toPx';
import * as Models from './models';
import styles from './styles.module.scss';

class ArtboardPreview extends React.PureComponent<Models.ArtboardPreviewProps, Models.ArtboardPreviewState> {
  private timer: number;

  // -1 in order to avoid scrolling of ssi element for the first slide
  private navigationCounter = -1;

  private resizeObserver: ResizeObserver;

  private readonly iframe = React.createRef<HTMLIFrameElement>();

  private readonly previewContainer = React.createRef<HTMLDivElement>();

  readonly state: Models.ArtboardPreviewState = {
    isIframeLoaded: false,
    lastVisitedSlide: '',
  };

  componentDidMount() {
    const { generatePreview, match: { params: { screenId } } } = this.props;
    if (screenId) {
      this.props.togglePreview(true);
    }

    // https://stackoverflow.com/questions/20572734/load-event-not-firing-when-iframe-is-loaded-in-chrome
    this.timer = window.setInterval(
      () => {
        const { contentDocument: iframeDoc } = this.iframe.current;

        if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
          this.setState({ isIframeLoaded: true });
          clearInterval(this.timer);
        }
      },
      100,
    );

    generatePreview(screenId);
  }

  componentDidUpdate(prevProps: Models.ArtboardPreviewProps) {
    const {
      previewsHtml,
      generatePreview,
      activePreviewOptionName,
      match: { params: { screenId } },
    } = this.props;
    const { html } = this.getPreviewHtml();
    const { html: previousHtml } = this.getPreviewHtml(prevProps);
    const prevPreviewOptionName = prevProps.activePreviewOptionName;
    const { screenId: prevScreenId } = prevProps.match.params;
    const { current: previewContainer } = this.previewContainer;

    if (screenId !== prevScreenId) {
      this.setState({ lastVisitedSlide: prevScreenId });

      if (!previewsHtml.get(screenId)) {
        generatePreview(screenId);
      }
    }

    if (!this.state.isIframeLoaded) {
      return;
    }

    if (previousHtml !== html) {
      this.initPreview();
    } else if (activePreviewOptionName !== prevPreviewOptionName) {
      previewContainer.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    // TODO: implement more complex logic instead of reset generated html every time
    clearInterval(this.timer);
    this.props.togglePreview(false);
    this.props.resetPreviewsHtml();
    this.disconnectResizeObserver();
  }

  private initPreview = (): void => {
    const { fontFaces } = this.props;
    const { contentDocument } = this.iframe.current;
    const { html } = this.getPreviewHtml();
    contentDocument.write(html);
    appendCustomFontFaces(fontFaces.toJS(), contentDocument);
    contentDocument.close();

    const height = _.get(this.iframe, 'current.contentDocument.body.scrollHeight');
    this.adjustIframeByContentHeight(height);

    this.disconnectResizeObserver();
    this.resizeObserver = new ResizeObserver((entries) => {
      const { blockSize: borderBoxSize } = entries[0].borderBoxSize[0];
      const { marginTop, marginBottom } = getComputedStyle(contentDocument.documentElement);
      // borderBoxSize doesn't include margins, so need taking vertical margins as well to achieve a correct height
      this.adjustIframeByContentHeight(borderBoxSize + _.parseInt(marginTop) + _.parseInt(marginBottom));
    });
    this.resizeObserver.observe(contentDocument.documentElement);

    this.initPreviewNavigation();
    this.scrollSSI();
  };

  private initPreviewNavigation = (): void => {
    const { contentDocument } = this.iframe.current;
    const navElements = contentDocument.querySelectorAll<HTMLElement>('[data-screen-link]');

    navElements.forEach((element) => {
      const { screenLink } = element.dataset;

      element.addEventListener('click', (event) => {
        event.preventDefault();
        this.handleLinkClick(screenLink);
      });
    });
  };

  private scrollSSI = (): void => {
    const { contentDocument } = this.iframe.current;
    const ssiAnchors = contentDocument.querySelectorAll('[data-anchor=true]');

    if (ssiAnchors.length === 0 || this.navigationCounter < 0) {
      return;
    }

    if (this.navigationCounter >= ssiAnchors.length) {
      this.navigationCounter = -1;
    } else {
      const anchor = ssiAnchors[this.navigationCounter];
      const prevAnchor = ssiAnchors[this.navigationCounter - 1];
      anchor && requestAnimationFrame(() => {
        prevAnchor && prevAnchor.scrollIntoView();
        anchor.scrollIntoView({ behavior: 'smooth' });
      });
    }
  };

  private handleLinkClick = (screenLink: string): void => {
    const { surfaceIds, match: { params: { screenId } } } = this.props;

    if (screenId === screenLink.substr(1)) {
      return;
    }

    this.navigationCounter++;
    const currentScreenIndex = surfaceIds.findIndex(surfaceId => surfaceId === screenId);
    const prevScreenId = surfaceIds.get(currentScreenIndex - 1);
    const nextScreenId = surfaceIds.get(currentScreenIndex + 1);
    const lastVisitedScreenId = this.state.lastVisitedSlide;

    switch (screenLink) {
      case PREVIOUS_SLIDE: return !!prevScreenId && this.gotoSlide(prevScreenId);
      case NEXT_SLIDE: return !!nextScreenId && this.gotoSlide(nextScreenId);
      case LAST_VISITED_SLIDE: return !!lastVisitedScreenId && this.gotoSlide(lastVisitedScreenId);
      default: return this.gotoSlide(screenLink.substr(1));
    }
  };

  private gotoSlide = (screenId: string): void => {
    const { history, match: { params: { projectId }, url } } = this.props;
    const routeBaseName = url.includes(AppRoute.BLUE_ENV_BASENAME) ? `/${AppRoute.BLUE_ENV_BASENAME}` : '';

    history.replace(`${routeBaseName}/${AppRoute.PROJECT}/${projectId}/${AppRoute.PREVIEW}/${screenId}`);
  };

  private adjustIframeByContentHeight = (height: number): void => {
    const { current: iframe } = this.iframe;

    requestAnimationFrame(() => { iframe && (iframe.style.height = toPx(height)); });
  };

  private disconnectResizeObserver = (): void => {
    this.resizeObserver && this.resizeObserver.disconnect();
  };

  private getPreviewHtml = (props = this.props): Models.PreviewHtmlAndSize => {
    const { match: { params: { screenId } }, previewsHtml } = props;
    const html = previewsHtml.getIn([screenId, 'html']);
    const htmlUnicodeSize = previewsHtml.getIn([screenId, 'htmlUnicodeSize']);

    return { htmlUnicodeSize, html };
  };

  private getActivePreviewOption = () => {
    const { previewOptionsByScreenId, activePreviewOptionName, match: { params: { screenId } }, projectType } = this.props;
    const previewOptions = previewOptionsByScreenId.get(screenId);

    return (
      previewOptions.find(previewOption => previewOption.get('name') === activePreviewOptionName) ||
      getDefaultPreviewOption(previewOptions, projectType) ||
      previewOptions.last<PreviewOptionMap>()
    );
  };

  private renderHeader = (): JSX.Element => {
    const { previewOptionsByScreenId, setActivePreviewOption, history, match: { params: { screenId } } } = this.props;
    const previewOptions = previewOptionsByScreenId.get(screenId);
    const activePreviewOption = this.getActivePreviewOption();
    const { htmlUnicodeSize } = this.getPreviewHtml();

    return (
      <div className={styles.header}>
        <div className={styles.section}>
          <div className={styles.sectionLeft}>
            <ZoomControl />
            {htmlUnicodeSize > 0 &&
              <>
                <div className={styles.spacer} />
                <div className={styles.characterDisplay}>
                  {intlGet('Artboard.Preview', 'HtmlCharacterEstimation', { htmlVeevaCrmLimit: formatNumberWithCommas(htmlUnicodeSize) })}
                </div>
              </>
            }
          </div>
          <div className={styles.previewOptions}>
            {
              previewOptions.map((previewOption) => {
                const name = previewOption.get('name');
                const iconType = previewOption.get('iconType');
                const color = activePreviewOption.get('name') === name ? 'primary' : 'grey';

                return (
                  <div className={styles.option} key={name} onClick={setActivePreviewOption.bind(null, name)}>
                    <Icon type={iconType} size="auto" color={color} />
                  </div>
                );
              })
            }
          </div>
          <div className={styles.sectionRight}>
            <div className={styles.closeSection}>
              {intlGet('Artboard.Preview', 'Close')}
              <div className={styles.closeBtn} onClick={history.goBack}>
                <Icon type={IconType.CLOSE} size="sm-md" color="secondary" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isIframeLoaded } = this.state;
    const { screenWidth } = this.props;
    const activePreviewOption = this.getActivePreviewOption();
    const previewWidth = (activePreviewOption && activePreviewOption.get('width')) || toPx(screenWidth);
    const { html } = this.getPreviewHtml();
    const isLoading = !html || !isIframeLoaded;

    return (
      <div className={styles.ArtboardPreview}>
        {this.renderHeader()}
        <div
          ref={this.previewContainer}
          className={styles.content}
        >
          {isLoading && <div className={styles.spinnerWrap}><Spinner /></div>}
          <ZoomWrapper>
            <iframe
              scrolling="no"
              frameBorder={0}
              width={isLoading ? 0 : previewWidth}
              ref={this.iframe}
            />
          </ZoomWrapper>
        </div>
      </div>
    );
  }
}

export default ArtboardPreview;
