import React from 'react';
import { ToastOptions, toast } from 'react-toastify';

import Toast from 'components/Toast';
import { ToastType } from 'const';
import styles from './styles.module.scss';

toast.configure({
  autoClose: 10 * 1000, // time in ms
  position: toast.POSITION.TOP_RIGHT,
  draggable: false,
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  className: styles.ToastContainer,
  toastClassName: styles.toast,
  bodyClassName: styles.body,
});

export function success(message: string, title?: string, options?: ToastOptions): () => void {
  return toasterCreator(message, title, options, ToastType.SUCCESS);
}

export function warn(message: string, title?: string, options?: ToastOptions): () => void {
  return toasterCreator(message, title, options, ToastType.WARNING);
}

const toasterCreator = (message, title, options, type) => {
  const toastId = toast(
    ({ closeToast }) => <Toast closeToast={closeToast} title={title} message={message} type={type} />,
    options,
  );

  return () => toast.dismiss(toastId);
};
