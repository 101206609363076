import classNames from 'classnames';
import React from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import NumericInput from 'components/NumericInput';
import ColorsList from 'components/Toolbar/Shared/ColorsList';
import { Styles } from 'const';
import { intlGet } from 'utils/intlGet';
import GradientSection from './GradientSection';
import { ColorPickerProps } from './models';
import styles from './styles.module.scss';

const ColorPicker: React.FunctionComponent<ColorPickerProps> = (props) => {
  const {
    activeColor,
    colorsCategories,
    colors,
    opacity = Styles.DefaultOpacity,
    gradient,
    toggleGradient,
    resetIconColor = 'secondary',
    resetSectionBorderColor = 'mediumGrey',
    title,
    toggleColor,
    toggleOpacity,
    showGradientPickerModal,
  } = props;

  const resetColor = React.useCallback((): void => {
    toggleColor(undefined);
    if (toggleOpacity) {
      toggleOpacity(Styles.DefaultOpacity);
    }
    if (toggleGradient) {
      toggleGradient(undefined, undefined);
    }
  }, [toggleColor, toggleOpacity, toggleGradient]);

  return (
    <>
      {title && <div className={styles.dropdownTitle}>{title}</div>}

      {toggleGradient && (
        <GradientSection
          gradient={gradient}
          onClick={(): void => showGradientPickerModal(gradient, gradient ? activeColor : null, toggleGradient)}
        />
      )}

      <div className={styles.scrollContainer}>
        <ColorsList
          colorsCategories={colorsCategories}
          colors={colors}
          value={(toggleGradient && gradient) ? undefined : activeColor}
          onChange={toggleColor}
          tooltipClassName={styles.colorListTooltip}
        />
      </div>

      {toggleOpacity && (
        <div className={styles.OpacitySection}>
          <span>{intlGet('EditorToolbar.ColorPicker', 'Opacity')}</span>
          <div className={styles.controls}>
            <NumericInput value={opacity} min={0} max={100} onChange={toggleOpacity} />
            <span className={styles.percentMark}>%</span>
          </div>
        </div>
      )}

      <div
        className={classNames(styles.ResetSection, styles[`borderColor_${resetSectionBorderColor}`])}
        onClick={resetColor}
      >
        <Icon color={resetIconColor} size="sm-md" type={IconType.TOOLBAR_RESET_COLOR} />
        <div className={styles.title}>{intlGet('EditorToolbar.ColorPicker', 'Reset')}</div>
      </div>

    </>
  );
};

export default ColorPicker;
