import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const PhoneScreen = (): JSX.Element => {
  const id = guid();

  return (
    <svg viewBox="0 0 14 20" width="14px" height="20px">
      <defs>
        <path d="M8.69880653,19.2275364 L8.69880653,6.64480046 L18.6385368,6.64480046 L18.6393658,19.2275364 L8.69880653,19.2275364 Z M14.4169081,21.733857 C14.0032017,22.144567 13.3341416,22.144567 12.9212643,21.733857 C12.5075579,21.3223223 12.5075579,20.6559495 12.9212643,20.2452395 C13.3341416,19.8337047 14.0032017,19.8337047 14.4169081,20.2452395 C14.8306144,20.6559495 14.8306144,21.3223223 14.4169081,21.733857 L14.4169081,21.733857 Z M19.4543425,4.11951126 C18.922079,3.63870016 18.2405828,3.636226 18.0490674,3.64777207 L9.28827589,3.64777207 C9.09593146,3.636226 8.41443523,3.63870016 7.8838299,4.11951126 C7.61272374,4.36445277 7.29021518,4.82217175 7.29021518,5.61307715 L7.29021518,20.9767651 C7.28855704,21.0542887 7.28441169,21.7445784 7.78931583,22.2625018 C8.02145566,22.4991961 8.44428178,22.78125 9.14401757,22.78125 L18.1924967,22.78125 C18.8922324,22.78125 19.3158876,22.4991961 19.5471984,22.2625018 C20.0537607,21.7445784 20.0496153,21.0542887 20.0471281,20.9965584 L20.0471281,5.61307715 C20.0471281,4.82217175 19.7246196,4.36527749 19.4543425,4.11951126 L19.4543425,4.11951126 Z" id="phone-screen-p1" />
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Navigate-Card" transform="translate(-1185.000000, -138.000000)">
          <g id="content">
            <g transform="translate(0.000000, 28.000000)">
              <g id="bottom" transform="translate(46.743243, 92.000000)">
                <g id="help" transform="translate(1131.000000, 14.000000)">
                  <g id="3.-Icons/ic_iphone" transform="translate(0.251584, 0.765473)">
                    <mask id={id} fill="white">
                      <use xlinkHref="#phone-screen-p1" />
                    </mask>
                    <g id="Icon" />
                    <g id="5.-Colors/Gray-70" mask={`url(#${id})`} fill="#B3B3B3" fillRule="evenodd">
                      <g transform="translate(1.822500, 1.822500)" id="Gray-70">
                        <rect x="0" y="0" width="24.0685714" height="24.0685714" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PhoneScreen;
