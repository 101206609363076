import ChromeBrowserIcon from 'assets/images/ChromeBrowserIcon';
import EdgeBrowserIcon from 'assets/images/EdgeBrowserIcon';
import SafariBrowserIcon from 'assets/images/SafariBrowserIcon';
import React from 'react';

import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

const UnsupprotedBrowser: React.FunctionComponent = () => (
  <div className={styles.UnsupprotedBrowser}>
    <div className={styles.notification}>
      <section className={styles.title}>{intlGet('UnsupprotedBrowser', 'ImproveExperience')}</section>
      <section className={styles.text}>{intlGet('UnsupprotedBrowser', 'UnsupportedBrowser')}</section>
      <section className={styles.text}>{intlGet('UnsupprotedBrowser', 'TryOptions')}</section>
      <div className={styles.browsers}>
        <div className={styles.browser}>
          <ChromeBrowserIcon />
          <section className={styles.subheader}>Chrome</section>
          <section className={styles.nameOS}>MacOS</section>
        </div>
        <div className={styles.browser}>
          <SafariBrowserIcon />
          <section className={styles.subheader}>Safari</section>
          <section className={styles.nameOS}>MacOS</section>
        </div>
        <div className={styles.browser}>
          <EdgeBrowserIcon />
          <section className={styles.subheader}>Edge</section>
          <section className={styles.nameOS}>Windows</section>
        </div>
      </div>
    </div>
  </div>
);

export default UnsupprotedBrowser;
