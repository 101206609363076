import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, select, spawn, take } from 'redux-saga/effects';
import guid from 'uuid';

import { DocumentsSource, EntityType, ModalType, ProjectPanelTab } from 'const';
import { toggleUpdatedDocumentsDisplaying, unlockProjectContent } from 'containers/App/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { hideModal, showModal, updateImageInfoWithinModalWindow } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { activeModalIds as activeModalIdsSelector } from 'containers/ModalManager/selectors';
import { deleteUploadingDocumentId, setUploadingDocumentId } from 'containers/Project/actions';
import { setActiveTab, setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import * as Models from 'models';
import { getProjectLinkedDocuments } from 'services/api';
import { Notifications } from 'services/Notifications';
import { areImages, isImage, isReferenceCitation } from 'utils/entityType';
import { intlGet } from 'utils/intlGet';
import { isDocumentDeletedLocally } from 'utils/isDocumentDeletedLocally';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';
import { deleteDocuments, getImagesInfo, setDocument, updateDocumentSource } from '../actions';
import { getImagesAdditionalInfo } from '../sagas/getImagesAdditionalInfo';
import { linkDocument } from './linkDocument';
import { updateImagesPreviews } from './updateImagesPreviews';

export function* replaceDocumentWithDuplicate(
  id: string,
  documentDuplicates: Models.Image[] | Models.ReferenceCitation[],
  documentLinksByDocumentNumber: Models.DocumentLinksByDocumentNumber,
  addImageWindowId: string,
  referenceCitationText?: string,
): SagaIterator {
  const duplicateWindowId = guid();
  let uploadingDocumentId = id;

  try {
    let documentDuplicate: Models.Image | Models.ReferenceCitation;

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const rootDocumentId = rootDocument.get('documentId');

    const locallyExistedDocuments: Models.ImagesMap | Models.ReferenceCitationsMap = yield select(documentsSelector);
    const { data: updatedDocuments }: ReturnTypeSaga<typeof getProjectLinkedDocuments> = yield call(getProjectLinkedDocuments, rootDocumentId);

    yield put(batchActions([
      unlockProjectContent(),
      hideModal(addImageWindowId),
    ]));

    if (documentDuplicates.length === 1) {
      documentDuplicate = documentDuplicates[0];
    } else if (documentDuplicates.length > 1) {
      yield put(showModal(ModalType.DOCUMENT_DUPLICATES, { documentDuplicates, documentLinksByDocumentNumber }, duplicateWindowId));

      if (areImages(documentDuplicates)) {
        function* needToStop(): SagaIterator {
          const activeModalIds: ReturnTypeSaga<typeof activeModalIdsSelector> = yield select(activeModalIdsSelector);

          return !activeModalIds.includes(duplicateWindowId);
        }

        yield spawn(
          updateImagesPreviews,
          documentDuplicates,
          (id: string, imageInternalInfo: Models.ImageInternalInfo) => updateImageInfoWithinModalWindow(duplicateWindowId, id, imageInternalInfo),
          // TODO: define type when it will be possible,
          needToStop as any,
        );
      }

      const { payload: { response: duplicateDocumentNumber } }: ModalManagerModels.Action.IHideModal<string> = yield take(
        takeModalWindowResponse(duplicateWindowId),
      );

      if (!duplicateDocumentNumber) {
        return;
      }

      documentDuplicate = _.find<Models.Image | Models.ReferenceCitation>(documentDuplicates, ({ number }) => number === duplicateDocumentNumber);
    }

    const { number: documentNumber } = documentDuplicate || {} as Models.Image | Models.ReferenceCitation;
    const localDuplicate = locallyExistedDocuments.find(
      documents => documents.get('number') === documentNumber && !isDocumentDeletedLocally(documents),
    );
    const duplicateExistsLocally = !!localDuplicate;
    const duplicateExistsRemotely = updatedDocuments.some(({ number }: Models.Image | Models.ReferenceCitation) => number === documentNumber);

    const needToLinkDocument = !duplicateExistsRemotely;
    const needToUpdateSource = !duplicateExistsLocally || !duplicateExistsRemotely;

    uploadingDocumentId = documentDuplicate.id = duplicateExistsLocally ? localDuplicate.get('id') : documentDuplicate.id;
    isReferenceCitation(documentDuplicate) && (documentDuplicate.text = referenceCitationText);

    yield put(batchActions([
      ...(!duplicateExistsLocally ? [setDocument(documentDuplicate)] : []),
      setUploadingDocumentId(uploadingDocumentId),
      setAssetIdToScroll(uploadingDocumentId),
      isImage(documentDuplicate) && setActiveTab(ProjectPanelTab.ASSETS),
      isImage(documentDuplicate) && toggleUpdatedDocumentsDisplaying(true, EntityType.IMAGE),
    ]));

    if (needToLinkDocument) {
      yield call(linkDocument, rootDocument, documentDuplicate);
    }

    if (needToUpdateSource) {
      const documentSource = _(documentDuplicate.documentSource).union([DocumentsSource.COMPONENTS]).compact().value();

      yield put(updateDocumentSource(uploadingDocumentId, documentDuplicate.entityType, documentSource));
    }

    isImage(documentDuplicate) && (yield call(getImagesAdditionalInfo, getImagesInfo([documentDuplicate])));
    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'ReplacedWithDuplicate', { documentNumber }),
      intlGet('Notification', 'Note'),
    );

    return uploadingDocumentId;
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during replacing a document with a duplicate.', error);
    yield put(deleteDocuments([uploadingDocumentId]));

    throw (error);
  } finally {
    yield put(batchActions([
      deleteUploadingDocumentId(uploadingDocumentId),
      hideModal(duplicateWindowId),
    ]));
  }
}
