import React from 'react';
/* eslint-disable max-len */

const Group = (): JSX.Element => (
  <svg viewBox="3 3 24 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M12,18 L13,18 L13,24 L12,24 L12,18 Z M18,12 L24,12 L24,13 L18,13 L18,12 Z M23,13 L24,13 L24,24 L23,24 L23,13 Z M13,23 L23,23 L23,24 L13,24 L13,23 Z M18,18 L6,18 L6,6 L18,6 L18,18 Z M3,3 L4,3 L4,27 L3,27 L3,3 Z M4,26 L27,26 L27,27 L4,27 L4,26 Z M4,3 L27,3 L27,4 L4,4 L4,3 Z M26,4 L27,4 L27,26 L26,26 L26,4 Z" />
    </g>
  </svg>
);

export default Group;
