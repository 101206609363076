import Draft, { RichUtils } from 'draft-js';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import * as inlineStyles from 'utils/inlineStyles';
import { applyEditorStateFontStylesForBrandStyles } from '../../../helpers/applyEditorStateFontStylesForBrandStyles';

type Setter = (style: string) => void;

export function createInlineStyleSetter<T extends boolean | undefined>(
  isExtended: T,
  getEditorState: () => Draft.EditorState,
  setEditorState: (state: Draft.EditorState) => void,
  projectType: T extends true
    ? Constants.ProjectType
    : unknown,
  fonts:T extends true
    ? Models.BrandFontsList
    : unknown,
  applyBrandStyles: boolean | undefined,
): Setter {

  if (!isExtended) {
    return (style: string): void => {
      const newState = RichUtils.toggleInlineStyle(getEditorState(), style);
      setEditorState(newState);
    };
  }

  return (style: string): void => {
    const state = getEditorState();
    let newEditorState;
    const currentStyle = state.getCurrentInlineStyle();

    if (style === Constants.InlineStyle.ITALIC) {
      if (currentStyle.has(Constants.InlineStylesForToggling.ITALIC)) {
        newEditorState = editorUtils.toggleCustomInlineStyle(
          state,
          Constants.InlineStylesForToggling.NORMAL,
          Constants.StylePrefix.FONT_STYLE,
        );
      } else {
        newEditorState = editorUtils.toggleCustomInlineStyle(
          state,
          Constants.InlineStylesForToggling.ITALIC,
          Constants.StylePrefix.FONT_STYLE,
        );
      }
    }

    if (style === Constants.InlineStyle.BOLD) {
      const fontWeightStyle = inlineStyles.findFontWeightStyle(currentStyle);
      const fontWeight = fontWeightStyle
        ? Number(inlineStyles.getFontWeightFromStyle(fontWeightStyle))
        : Number(inlineStyles.getFontWeightFromStyle(Constants.InlineStylesForToggling.REGULAR));
      const fontWeightForBold = Number(inlineStyles.getFontWeightFromStyle(Constants.InlineStylesForToggling.BOLD));

      if (fontWeight >= fontWeightForBold) {
        newEditorState = editorUtils.toggleCustomInlineStyle(
          state,
          Constants.InlineStylesForToggling.REGULAR,
          Constants.StylePrefix.FONT_WEIGHT,
        );
      } else {
        newEditorState = editorUtils.toggleCustomInlineStyle(
          state,
          Constants.InlineStylesForToggling.BOLD,
          Constants.StylePrefix.FONT_WEIGHT,
        );
      }
    }

    if (style === Constants.InlineStyle.BOLD || style === Constants.InlineStyle.ITALIC) {
      if (applyBrandStyles) {
        newEditorState = applyEditorStateFontStylesForBrandStyles(
          newEditorState,
          projectType as Constants.ProjectType,
          fonts as Models.BrandFontsList,
        );
      } else {
        newEditorState = editorUtils.applyFontStyles(
          newEditorState,
          fonts as Models.BrandFontsList,
          projectType as Constants.ProjectType,
        );
      }
    } else {
      newEditorState = Draft.RichUtils.toggleInlineStyle(state, style);
    }

    setEditorState(newEditorState);
  };
}
