import { MapDispatchToPropsFunction, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AddImage from 'components/ModalWindows/AddImage';
import { AddImageActions, AddImageOwnProps, AddImageSelectors } from 'components/ModalWindows/AddImage/models';
import { isLocked } from 'containers/App/selectors';
import { addImage } from 'containers/Documents/actions';
import { documentTypes } from 'containers/DocumentTypes/selectors';
import { hideModal } from 'containers/ModalManager/actions';

const mapStateToProps = createStructuredSelector({
  documentTypes,
  isLocked,
});

const mapDispatchToProps: MapDispatchToPropsFunction<AddImageActions, AddImageOwnProps> = (dispatch, props) => ({
  hideModal: () => dispatch(hideModal(props.modalWindowId)),
  addImage: imageDataToUpload => dispatch(addImage(props.modalWindowId, imageDataToUpload)),
});

export default connect<AddImageSelectors, AddImageActions, AddImageOwnProps>(mapStateToProps, mapDispatchToProps)(AddImage);
