import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import {
  BackgroundImageMap,
  BrandColorGradientMap,
  BrandColorMap,
  BrandColorsList,
  CombinedDocumentsMap,
  SectionMap,
  SectionStyles,
} from 'models';
import { colorToSource, colorToTint } from 'utils/converters';
import { brandColorGradientToSource } from 'utils/converters/colorGradient';
import {
  getCSSBackgroundColorLayer,
  getCSSBackgroundGradientLayer,
  getCSSBackgroundImageLayer,
} from 'utils/styles/getBackground';

type Values = DeepIMap<SectionStyles>;

type Context = {
  colors: BrandColorsList;
  images: CombinedDocumentsMap;
};

type Config = {
  allowBackgroundOpacity: boolean;
  allowBackgroundImage: boolean;
};

export type SectionStylesSetters = {
  backgroundColor: (value: BrandColorMap | null) => void;
  backgroundColorOpacity?: (value: number) => void;
  backgroundGradient: (gradient?: BrandColorGradientMap, backupColor?: BrandColorMap) => void;
  backgroundImage?: (image: BackgroundImageMap | null) => void;
};

function sectionStylesToCSS(
  values: Values,
  context: Context,
  config: Config,
): CSSProperties {
  const backgroundLayers: (string | undefined)[] = [];
  if (values.has('backgroundImage')) {
    backgroundLayers.push(getCSSBackgroundImageLayer(values.get('backgroundImage'), context.images));
  }
  if (values.has('backgroundGradient')) {
    backgroundLayers.push(getCSSBackgroundGradientLayer(
      context.colors,
      values.get('backgroundGradient'),
      config.allowBackgroundOpacity ? values.get('backgroundColorOpacity') : undefined,
    ));
  }
  if (values.has('backgroundColor')) {
    backgroundLayers.push(getCSSBackgroundColorLayer(
      context.colors,
      values.get('backgroundColor'),
      values.get('backgroundColorTint'),
      config.allowBackgroundOpacity ? values.get('backgroundColorOpacity') : undefined,
    ));
  }

  return { background: backgroundLayers.filter(layer => !!layer).join(',') };
}

export function useStyles(
  section: SectionMap,
  context: Context,
  config: Config,
  editMode: boolean,
  onUpdate: (value: SectionMap) => void,
): [Values, SectionStylesSetters, CSSProperties] {
  const { colors, images } = context;
  const { allowBackgroundOpacity, allowBackgroundImage } = config;
  const [styles, setStyles] = useState(section.get('styles'));
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }
    if (!editMode) {
      onUpdate(section.set('styles', styles));

      return;
    }
    if (styles !== section.get('styles')) {
      setStyles(section.get('styles'));
    }
  }, [editMode]); // section and styles are intentionally skipped from the dependencies

  const setters = useMemo<SectionStylesSetters>(
    () => ({
      backgroundColor: value => setStyles(state => state.withMutations(values => values
        .set('backgroundColor', colorToSource(value))
        .set('backgroundColorTint', colorToTint(value))
        .set('backgroundGradient', undefined),
      )),
      backgroundColorOpacity: allowBackgroundOpacity
        ? (value): void => setStyles(values => values.set('backgroundColorOpacity', value))
        : undefined,
      backgroundGradient: (gradient, color) => setStyles(state => state.withMutations(values => values
        .set('backgroundColor', colorToSource(color))
        .set('backgroundColorTint', colorToTint(color))
        .set('backgroundGradient', brandColorGradientToSource(gradient)),
      )),
      backgroundImage: allowBackgroundImage
        ? (value): void => setStyles(values => values.set('backgroundImage', value))
        : undefined,
    }),
    [allowBackgroundOpacity, allowBackgroundImage],
  );

  const css = useMemo(
    () => sectionStylesToCSS(styles, { colors, images }, { allowBackgroundOpacity, allowBackgroundImage }),
    [styles, colors, images, allowBackgroundOpacity, allowBackgroundImage],
  );

  return [styles, setters, css];
}
