import React from 'react';

import { Priority, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import { FontSizeProps } from './models';

export const useFontSize = (props: FontSizeProps) => {
  const { fontSize: initialFontSize, toggleFontSize } = props;
  const { container, on, toggle } = useClickOutsideWithToggle<HTMLDivElement>(Priority.CONTROL);
  const [fontSize, setFontSize] = React.useState(initialFontSize);

  React.useEffect(() => setFontSize(initialFontSize), [initialFontSize]);

  const isFirstCall = React.useRef(true);
  React.useEffect(
    () => {
      if (isFirstCall.current) {
        isFirstCall.current = false;

        return;
      }
      !on && toggleFontSize(fontSize);
    },
    [on],
  );

  return {
    container,

    fontSize,
    toggleFontSize: setFontSize,

    manual: on,
    toggleManual: toggle,
  };
};
