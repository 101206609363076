import classNames from 'classnames';
import React from 'react';

import ActionIcon from 'components/ActionIcon';
import { IconType } from 'components/Icon/constants';
import { ModalType, ProjectsConfig } from 'const';
import ResizeButton from 'containers/ResizeButton';
import * as Models from 'models';
import { toJS } from 'utils/immutable';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { ActionsColor, LAYOUT_NAME_HINT_MAX_LENGTH } from './constants';
import { ArtboardLayoutActionsProps, IArtboardLayoutActionsState } from './models';
import styles from './styles.module.scss';
import { getLayoutActionsConfig } from './utils';

export default class ArtboardLayoutActions extends React.Component<ArtboardLayoutActionsProps, IArtboardLayoutActionsState> {
  readonly state: IArtboardLayoutActionsState = {
    hideActionsAfterResize: false,
    rightActionsExpanded: false,
  };

  private actions = React.createRef<HTMLDivElement>();

  private deleteLayout = (): void => {
    const { layout, deleteLayout } = this.props;
    const layoutId = layout.get('id');
    deleteLayout(layoutId);
  };

  private addLayoutColumn = (): void => {
    this.props.addLayoutColumn();
  };

  private addLayoutRow = (): void => {
    this.props.addLayoutRow();
  };

  private saveAsReusableLayout = (): void => {
    const { layout, sectionDisplayName = '', showModal, setLayout } = this.props;
    setLayout(layout);
    showModal(ModalType.SAVE_LAYOUT, { layout: toJS(layout as Models.LayoutMap), sectionDisplayName });
  };

  private copyLayout = (): void => {
    const { layout, copyLayout, setLayout } = this.props;
    setLayout(layout);
    copyLayout(layout.get('id'));
  };

  private unlinkReusableLayout = (): void => {
    const { unlinkReusableLayout, layout } = this.props;

    unlinkReusableLayout(layout.get('id'));
  };

  private setMoveableLayout = (isDraggable: boolean): void => {
    const { layout, setMoveableLayout } = this.props;
    const layoutId = layout.get('id');
    setMoveableLayout(isDraggable ? layoutId : '');
  };

  private allowDragging = (): void => {
    const { disableDragAndDelete } = this.props;
    this.showActions();

    if (!disableDragAndDelete) {
      this.setMoveableLayout(true);
    }
  };

  private disableDragging = (): void => {
    const { disableDragAndDelete } = this.props;
    this.hideActions();

    if (!disableDragAndDelete) {
      this.setMoveableLayout(false);
    }
  };

  private hideActions = (): void => {
    if (this.props.isResizingLayout) {
      this.setState({
        hideActionsAfterResize: true,
      });

      return;
    }

    this.setState({
      rightActionsExpanded: false,
    });

    this.props.removeActiveLayoutId(this.props.layout.get('id'));
  };

  private showActions = (): void => {
    if (this.props.isResizingLayout) {
      this.setState({
        hideActionsAfterResize: false,
      });

      return;
    }

    !this.props.areActionsDisabled && this.props.addActiveLayoutId(this.props.layout.get('id'));
  };

  private expandRightActions = (): void => {
    this.setState({ rightActionsExpanded: true });
  };

  private handleMouseEnterOnRightActionMenu = (): void => {
    this.expandRightActions();
    this.showActions();
  };

  private onResizeEnd = () => {
    this.state.hideActionsAfterResize && this.hideActions();
    this.props.handleDragStop();
  };

  private renderBorder = (backgroundColor: ActionsColor): JSX.Element => (
    <div
      className={classNames(styles.border, styles[`backgroundColor_${backgroundColor}`])}
      onMouseEnter={this.showActions}
      onMouseLeave={this.hideActions}
    />
  );

  render() {
    const { rightActionsExpanded } = this.state;
    const {
      activeLayoutIds,
      addingDisabled,
      connectDragSource,
      disableDragAndDelete,
      documents,
      editMode,
      enableLayoutEditMode,
      handleDrag,
      handleDragStop,
      height,
      hideButtons,
      hint,
      isBeingUploaded,
      isChildLayout,
      isResizeAvailable,
      layout,
      projectType,
      showActions,
    } = this.props;
    const {
      addColumnAvailable,
      addRowAvailable,
      backgroundColor,
      borderColor,
      copyLayoutAvailable,
      editModeAvailable,
      resizeBtnControlledByEditMode,
      rightActionsSize,
      saveAsReusableAvailable,
      unlinkAvailable,
    } = getLayoutActionsConfig(layout, documents, isChildLayout);
    const layoutId = layout.get('id');
    const groupLayout = isGroupLayout(layout);
    const { allowScrollableLayout } = ProjectsConfig[projectType];
    const layoutName = hint && (hint.length < LAYOUT_NAME_HINT_MAX_LENGTH ? hint : `${hint.substr(0, LAYOUT_NAME_HINT_MAX_LENGTH)}...`);

    const actionsVisible =
      editMode ||
      showActions ||
      activeLayoutIds.has(layoutId) ||
      (groupLayout && (layout as Models.GroupLayoutMap).get('layoutIds').some(layoutId => activeLayoutIds.has(layoutId)));

    const actionsClassNames = classNames(
      styles.ArtboardLayoutActions,
      styles[`borderColor_${borderColor}`],
      {
        [styles.hideButtons]: hideButtons,
        [styles.showActions]: actionsVisible,
        [styles.shiftActions]: groupLayout, // temporary solution, will be revised from UX perspective
      },
    );

    const renderResizeButton = () => {
      if (!allowScrollableLayout || !isResizeAvailable) {
        return null;
      }

      if (resizeBtnControlledByEditMode) {
        return editMode && (
          <ResizeButton
            handleDrag={handleDrag}
            handleDragStop={handleDragStop}
            height={height}
            disabled={isBeingUploaded}
          />
        );
      }

      return (
        <ResizeButton
          onMouseEnter={this.showActions}
          onMouseLeave={this.hideActions}
          handleDragStop={this.onResizeEnd}
          handleDrag={handleDrag}
          color={borderColor}
          height={height}
          disabled={isBeingUploaded}
          shiftResizeButton={true}
        />
      );
    };

    const renderAddRowButton = () => {
      if (!addRowAvailable) {
        return null;
      }

      return (
        <ActionIcon
          backgroundColor={backgroundColor}
          color={isBeingUploaded ? 'light-grey' : 'secondary'}
          isDisabled={isBeingUploaded}
          onClick={this.addLayoutRow}
          onMouseEnter={this.showActions}
          onMouseLeave={this.hideActions}
          size="xs"
          type={IconType.ADD}
        />
      );
    };

    return (
      <div
        className={actionsClassNames}
        ref={this.actions}
      >
        {hint && <div className={styles.hint}>{layoutName}</div>}
        <div className={styles.left}>
          {this.renderBorder(backgroundColor)}
          {
            connectDragSource(
              <div
                className={classNames(styles.actions)}
                onMouseEnter={this.allowDragging}
                onMouseLeave={this.disableDragging}
                onMouseDown={this.hideActions}
                onMouseUp={this.showActions}
              >
                <ActionIcon
                  backgroundColor={backgroundColor}
                  color="secondary"
                  isDisabled={isChildLayout && isBeingUploaded || disableDragAndDelete}
                  size="badge"
                  type={IconType.UP_AND_DOWN_ARROWS}
                />
              </div>,
            )
          }
        </div>
        <div className={styles.right}>
          {this.renderBorder(backgroundColor)}
          <div
            className={classNames(
              styles.actions,
              styles[`rightActionsSize_${rightActionsSize}`],
              { [styles.actions__expanded]: rightActionsExpanded },
            )}
            onMouseEnter={this.handleMouseEnterOnRightActionMenu}
            onMouseLeave={this.hideActions}
          >
            {
              rightActionsExpanded
                ?
                <>
                  {addColumnAvailable && <ActionIcon
                    backgroundColor={backgroundColor}
                    color={isBeingUploaded || addingDisabled ? 'light-grey' : 'secondary'}
                    isDisabled={isBeingUploaded || addingDisabled}
                    onClick={this.addLayoutColumn}
                    size="xs"
                    type={IconType.ADD}
                  />}
                  {editModeAvailable && <ActionIcon
                    backgroundColor={backgroundColor}
                    color="secondary"
                    isDisabled={isBeingUploaded || !enableLayoutEditMode}
                    onClick={enableLayoutEditMode}
                    size="xs"
                    type={IconType.GEAR}
                  />}
                  {copyLayoutAvailable && <ActionIcon
                    backgroundColor={backgroundColor}
                    color={'secondary'}
                    onClick={this.copyLayout}
                    size="xs"
                    type={IconType.COPY}
                  />}
                  {unlinkAvailable && <ActionIcon
                    backgroundColor={backgroundColor}
                    color={isBeingUploaded ? 'light-grey' : 'secondary'}
                    isDisabled={isBeingUploaded}
                    onClick={this.unlinkReusableLayout}
                    size="xs"
                    type={IconType.UNLINK}
                  />}
                  {saveAsReusableAvailable && <ActionIcon
                    backgroundColor={backgroundColor}
                    color={'secondary'}
                    onClick={this.saveAsReusableLayout}
                    size="xs"
                    type={IconType.SAVE_LAYOUT}
                  />}
                  <ActionIcon
                    backgroundColor={backgroundColor}
                    color="secondary"
                    isDisabled={isBeingUploaded || disableDragAndDelete}
                    onClick={this.deleteLayout}
                    size="xs"
                    type={IconType.DELETE}
                  />
                </>
                :
                <ActionIcon
                  backgroundColor={backgroundColor}
                  color="secondary"
                  size="xs"
                  type={IconType.MENU}
                />
            }
          </div>
        </div>
        <div className={styles.bottom}>
          {renderAddRowButton()}
          {renderResizeButton()}
        </div>
      </div>
    );
  }
}
