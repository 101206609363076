import { SSIMap } from 'models';
import { isVerticalSSIPosition } from 'utils/ssi/ssiPosition';
import { toPx } from 'utils/toPx';

export const getSSIElementSize = (ssi: SSIMap, targetSectionSize: number): number => {
  const ssiScale = ssi.get('scale');

  return targetSectionSize * ssiScale / (1 - ssiScale);
};

export const getSSISize = (ssi: SSIMap, targetSectionWidth: number, targetSectionHeight: number): React.CSSProperties => {
  if (!ssi) {
    return {};
  }

  const ssiPosition = ssi.get('position');

  return isVerticalSSIPosition(ssiPosition)
    ? { width: toPx(getSSIElementSize(ssi, targetSectionWidth)), height: toPx(targetSectionHeight) }
    : { height: toPx(getSSIElementSize(ssi, targetSectionHeight)) };
};
