import { ContentState } from 'draft-js';
import React, { PropsWithChildren } from 'react';
import { allowLinkRedirect } from 'utils/allowLinkRedirect';
import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  contentState: ContentState;
  entityKey: string;
}>;

const Link: React.FunctionComponent<Props> = ({ children, entityKey, contentState }) => {
  const handleClick = (e: React.MouseEvent): void => {
    if (!allowLinkRedirect(e)) {
      e.preventDefault();
    }
  };
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a
      href={url}
      onClick={handleClick}
      target="_blank"
      className={styles.linkDecorator}
    >
      {children}
    </a>
  );
};

export default Link;
