import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { ReferenceCitation, ReferenceCitationMap } from 'models';
import { updateReferenceCitationDocument as updateReferenceCitationDocumentRequest } from 'services/api';
import { setDocument } from '../actions';

export function* updateReferenceCitationDocument(referenceToUpdate: ReferenceCitationMap): SagaIterator {
  const { data: createdDocument }: ReturnTypeSaga<typeof updateReferenceCitationDocumentRequest> = yield call(
    updateReferenceCitationDocumentRequest,
    referenceToUpdate.get('documentId'),
    referenceToUpdate.toJS() as ReferenceCitation,
  );

  createdDocument.id = referenceToUpdate.get('id');
  createdDocument.documentSource = referenceToUpdate.get('documentSource').toJS();
  createdDocument.text = referenceToUpdate.get('text');

  yield put(setDocument(createdDocument));
}
