import Immutable from 'immutable';

import * as Models from 'models';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const artboardInitialState = Immutable.Map() as State.IState;

const artboardsReducer: Models.Reducer<State.IState> = (state = artboardInitialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_ARTBOARD: return addArtboard(state, action);
    case ActionTypes.SET_ARTBOARDS: return setArtboards(state, action);
    case ActionTypes.DELETE_ARTBOARDS: return deleteArtboards(state, action);
    case ActionTypes.UPDATE_ARTBOARD: return updateArtboard(state, action);
    case ActionTypes.SET_SSI_SOURCE: return setSSISource(state, action);
    default: return state;
  }
};

export const addArtboard: Models.Reducer<State.IState, Action.IAddArtboard> = (state, action) => {
  const artboard = Immutable.fromJS(action.payload.artboard) as Models.ArtboardMap;

  return state.set(artboard.get('id'), artboard);
};

export const setArtboards: Models.Reducer<State.IState, Action.ISetArtboards> = (state, action) => {
  const { artboards } = action.payload;

  return Immutable.fromJS(artboards);
};

export const deleteArtboards: Models.Reducer<State.IState, Action.IDeleteArtboards> = (state, action) => {
  const { artboardIds } = action.payload;

  return artboardIds.reduce((state, id) => state.delete(id), state);
};

export const updateArtboard: Models.Reducer<State.IState, Action.IUpdateArtboard> = (state, action) => {
  const artboard = Immutable.fromJS(action.payload.artboard) as Models.ArtboardMap;

  return state.mergeIn([artboard.get('id')], artboard);
};

export const setSSISource: Models.Reducer<State.IState, Action.ISetSSISource> = (state, action) => {
  const { artboardId, SSISource } = action.payload;

  return state.updateIn([artboardId, 'ssi'], ssi => ssi.set('source', Immutable.fromJS(SSISource)));
};

export default artboardsReducer;
