import { Map as ImmutableMap } from 'immutable';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import * as Models from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import * as editorUtils from 'utils/editor';
import { getExtraHeight } from 'utils/getExtraHeight';
import { CellProps, CellState, cellIsLast } from '../utils/cell';
import { Styles } from '../utils/styles';
import useEditor from './useEditor';

type Props = Omit<CellProps, keyof CellState | 'isIncreasingWidthDisabled' | 'isLastCell'> & {
  isIncreasingWidthDisabled?: boolean;
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  editMode: boolean;
  toggleColumnWidth: (newWidth: number) => number;
  toggleRowHeight: (newHeight: number) => number;
  toggleAutoFitContent: () => void;
  toggleRowAndNeighborsHeight: (newHeight: number) => void;
};

function readCellProps(props: Props, cellState: CellState): CellProps {
  return {
    cellHeightChanged: cellState.cellHeightChanged,
    cellWidthChanged: cellState.cellWidthChanged,
    cellHeight: props.cellHeight,
    cellWidth: props.cellWidth,
    cellPosition: props.cellPosition,
    cellsCount: props.cellsCount,
    disableCellWidthEditing: props.disableCellWidthEditing,
    maxCellHeight: props.maxCellHeight,
    minCellHeight: props.minCellHeight,
    isAutoFitContent: props.isAutoFitContent,
    isResizingRow: props.isResizingRow,
    isIncreasingWidthDisabled: Boolean(props.isIncreasingWidthDisabled),
    isLastCell: cellIsLast(props),
  };
}

type CellHook = {
  props: CellProps;
  toggleCellWidth: (newWidth: number) => number;
  toggleCellHeight: (newHeight: number) => number;
  toggleAutoFitContent: () => void;
  toggleProps: (cellHeight: number, cellWidth: number, isAutoFitContent: boolean) => void;
  resetHeight: () => void;
  resetCellWidhtAndHeightChange: () => void;
};

export default function useCell(
  props: Props,
  wrapperRef: MutableRefObject<HTMLDivElement>,
  editorHook: ReturnType<typeof useEditor>,
  styles: Styles,
): CellHook {

  const [state, setState] = useState<CellState>({
    cellHeightChanged: false,
    cellWidthChanged: false,
  });

  const propsRef = useRef(props);
  propsRef.current = props;

  const stylesRef = useRef(styles);
  stylesRef.current = styles;

  const resetCellWidhtAndHeightChange = useCallback(() => setState({
    cellHeightChanged: false,
    cellWidthChanged: false,
  }), []);

  const {
    toggleColumnWidth,
    toggleRowHeight,
    toggleAutoFitContent,
    toggleRowAndNeighborsHeight,
  } = props;

  const width = useCallback((newWidth: number): number => {
    setState(prev => ({ ...prev, cellWidthChanged: true }));

    return toggleColumnWidth(newWidth);
  }, [toggleColumnWidth]);


  const height = useCallback((newHeight: number): number => {
    setState(prev => ({ ...prev, cellHeightChanged: true }));

    return toggleRowHeight(newHeight);
  }, [toggleRowHeight]);

  const toggleProps = useCallback((cellHeight, cellWidth, isAutoFitContent): void => {
    if (cellHeight !== propsRef.current.cellHeight) {
      // TODO: aproach differs from other props
      height(cellHeight);
    }
    if (cellWidth !== propsRef.current.cellWidth) {
      toggleColumnWidth(cellWidth);
    }
    if (isAutoFitContent !== propsRef.current.isAutoFitContent) {
      toggleAutoFitContent();
    }
  }, [height, toggleColumnWidth, toggleAutoFitContent]);

  const { getEditorState, hasTextContent } = editorHook;

  /**
   * recompute height of cells
   */
  const resetHeight = useCallback((): void => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }

    const { padding, border } = stylesRef.current;
    const extraHeight = getExtraHeight(ImmutableMap({ padding, border }) as Models.CommonStylesMap);
    const { clientHeight: textHeight } = wrapper;
    const newCellHeight = textHeight + extraHeight;

    const contentHeights = ContentHeightCache.getInstance();
    contentHeights.setItem(propsRef.current.relation.get('id'), newCellHeight);

    const { isAutoFitContent, cellHeight } = propsRef.current;

    if (!cellHeight || (!hasTextContent() && !propsRef.current.editMode)) {
      return;
    }

    /*
        TODO: revise hasCustomToken condition.
        Check the issue DCC-7138 (ER is incorrect).
        Original ticket is DCC-6167
    */
    const hasCustomToken = editorUtils.hasToken(getEditorState());

    const shouldUpdateCellHeight = (isAutoFitContent || hasCustomToken)
      && !propsRef.current.isResizingRow
      ? newCellHeight !== cellHeight
      : newCellHeight > cellHeight;

    if (shouldUpdateCellHeight) {
      toggleRowAndNeighborsHeight(newCellHeight);
    }
  }, [getEditorState, hasTextContent, toggleRowAndNeighborsHeight]);

  return {
    props: readCellProps(props, state),
    toggleCellWidth: width,
    toggleCellHeight: height,
    toggleAutoFitContent,
    toggleProps,
    resetHeight,
    resetCellWidhtAndHeightChange,
  };
}
