import React from 'react';
import { FormControl } from 'react-bootstrap';

import Tooltip from 'components/Tooltip';
import { ScreenSelectProps } from './models';
import styles from './styles.module.scss';

const ScreenSelect: React.FunctionComponent<ScreenSelectProps> = (props) => {
  const {
    activeOption,
    className,
    onChange,
    options,
    isTooltipDisabled = false,
  } = props;
  const screenOptionsRef = React.createRef<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    onChange(options.find(option => option.value === event.target.value));
  };

  return (
    <div
      data-tooltip={activeOption.label}
      ref={screenOptionsRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <FormControl
        as="select"
        className={className || styles.ScreenSelect}
        value={activeOption.value}
        onChange={handleChange}
      >
        {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
      </FormControl>
      {
        !isTooltipDisabled &&
        <Tooltip
          arrow={false}
          className={styles.tooltip}
          parent={screenOptionsRef}
          show={showTooltip}
          text={activeOption.label}
        />
      }
    </div>
  );
};

export default ScreenSelect;
