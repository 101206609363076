import React from 'react';

const ToolbarSubscript = (): JSX.Element => (
  <svg width="20px" height="17px" viewBox="0 0 20 17">
    <defs>
      <path
        d="M20,10 L20,12.1 L18,12.1 L18,17 L16,17 L16,12.1 L14,12.1 L14,10 L20,10 Z M4.60099338,9.23076923 L9.39900662,9.23076923 L7,2.86153846
        L4.60099338,9.23076923 Z M11.5778146,15 L10.2566225,11.5384615 L3.72019868,11.5384615 L2.42218543,15 L-8.8817842e-15,15 L5.92218543,0
        L8.07781457,0 L14,15 L11.5778146,15 Z"
        id="path-ToolbarSubscript"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-600.000000, -202.000000)">
        <g transform="translate(0.000000, 180.000000)">
          <g transform="translate(595.000000, 15.000000)">
            <g transform="translate(5.000000, 7.000000)">
              <g>
                <mask id="mask-ToolbarSubscript" fill="white">
                  <use xlinkHref="#path-ToolbarSubscript" />
                </mask>
                <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-ToolbarSubscript" />
                <g mask="url(#mask-ToolbarSubscript)" fill="#FFFFFF" id="White">
                  <g transform="translate(0.000000, -3.000000)">
                    <rect x="0" y="0" width="22" height="22" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ToolbarSubscript;
