import React from 'react';

import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import { OLD_CHAR_STYLES } from 'const';
import { ControlMap } from './constants';
import { CharStyleControlProps } from './models';

// TODO: convert to the same interface as FontDecoration Control
const CharStyleControl: React.FunctionComponent<CharStyleControlProps> = (props) => {
  const { styles, toggleStyle, controlState = ControlState } = props;

  return (
    <>
      {
        OLD_CHAR_STYLES.map(({ label, style }) => (
          <Control
            key={label}
            controlState={controlState}
            onToggle={toggleStyle.bind(null, style)}
            state={styles && styles.has(style) ? controlState.ACTIVE : controlState.DEFAULT}
            title={ControlMap[style]}
            type={label}
          />
        ))
      }
    </>
  );
};

export default CharStyleControl;
