import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SSI from 'components/SSI';
import { SSIActions, SSIOwnProps, SSISelectors } from 'components/SSI/models';
import { toggleDragState } from 'containers/App/actions';
import { activeSSI } from 'containers/Artboards/selectors';
import { layoutsInActiveSSI } from 'containers/Common/selectors';
import { orderedActiveLayoutIds } from 'containers/Layouts/selectors';
import { showModal } from 'containers/ModalManager/actions';

const mapStateToProps = createStructuredSelector({
  artboardLayoutIds: orderedActiveLayoutIds,
  layouts: layoutsInActiveSSI,
  ssi: activeSSI,
});

const mapDispatchToProps = {
  showModal,
  toggleDragState,
};

export default connect<SSISelectors, SSIActions, SSIOwnProps>(mapStateToProps, mapDispatchToProps)(SSI);
