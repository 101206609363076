import { ProjectType, ProjectsConfig } from 'const';
import { PreviewOption, PreviewOptionMap, PreviewOptions, PreviewOptionsList } from 'models';
import { getValue } from './getter';

export function getDefaultPreviewOption(previewOptions: PreviewOptions, projectType: ProjectType): PreviewOption;
export function getDefaultPreviewOption(previewOptions: PreviewOptionsList, projectType: ProjectType): PreviewOptionMap;
// eslint-disable-next-line max-len
export function getDefaultPreviewOption(previewOptions: PreviewOptions | PreviewOptionsList, projectType: ProjectType): PreviewOption | PreviewOptionMap {
  const { defaultPreviewOption } = ProjectsConfig[projectType];

  return previewOptions.find(previewOption => getValue(previewOption, 'name') === defaultPreviewOption);
}
