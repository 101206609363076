import { isImmutable } from 'immutable';
import { EntityType } from 'const';
import * as Models from 'models';
import { AbbreviationsListRelationStyles } from '../types';

export function isAbbreviatioinsListRelation(
  relation: Models.Relation | Models.LayeredRelation
): relation is Models.RegularRelation<AbbreviationsListRelationStyles>;

export function isAbbreviatioinsListRelation(
  relation: Models.RelationMap | Models.LayeredRelationMap,
): relation is Models.RegularRelationMap<AbbreviationsListRelationStyles>;

export function isAbbreviatioinsListRelation(
  relation: Models.Relation
  | Models.RelationMap
  | Models.LayeredRelation
  | Models.LayeredRelationMap,
): boolean {
  if (!relation) {
    return false;
  }

  const type = isImmutable(relation) ? (relation as unknown as DeepIMap<Models.BaseRelation>).get('entityType') : relation.entityType;

  return type === EntityType.ABBREVIATIONS_LIST;
}
