import React from 'react';

import { areBoxPropertiesEqual } from 'utils/immutable';
import { BoxInputProps } from './models';

export const useBoxPropertyControl = (props: BoxInputProps) => {
  const { property, toggleProperty, isShorthand } = props;
  const [shorthandProperty, setShorthandProperty] = React.useState(isShorthand);

  React.useEffect(
    () => setShorthandProperty(isShorthand),
    [isShorthand],
  );

  const toggleShorthandProperty = React.useCallback(
    () => {
      !shorthandProperty && !areBoxPropertiesEqual(property) && toggleProperty(property.map(() => 0));
      setShorthandProperty(!shorthandProperty);
    },
    [property, shorthandProperty],
  );

  return {
    shorthandProperty,
    toggleShorthandProperty,
  };
};
