import _ from 'lodash';
import guid from 'uuid';

import { Assembler } from 'const/ErrorType';
import { AssemblerError } from 'models';
import { postPerformanceLog } from 'services/api';
import { ErrorLog, Log, PerformanceData, PerformanceDataMap, SuccessLog } from './models';

export class Logger {
  private readonly data: PerformanceDataMap;

  constructor() {
    this.data = {};
  }

  private requestLogs(body: Log): void {
    // eslint-disable-next-line no-console
    postPerformanceLog(body).catch(err => console.error('Post performance logs error.', err));
  }

  private getItem(id: string): PerformanceData {
    return _.get(this.data, id, {}) as PerformanceData;
  }

  private updateItem(id: string, options: Partial<PerformanceData>): void {
    const item = this.getItem(id);
    this.data[id] = {
      ...item,
      ...options,
    };
  }

  private deleteItem(id: string): void {
    delete this.data[id];
  }

  private parseError(error: AssemblerError): AssemblerError {
    const { type, message } = error;

    return {
      type: type || Assembler.UNCLASSIFIED_ERROR,
      message,
    };
  }

  public performanceStart(id = guid()): string {
    this.data[id] = {
      startTime: Date.now(),
      isPaused: false,
      executionTime: 0,
    };

    return id;
  }

  public performancePause(id: string): void {
    const item = this.getItem(id);
    const { startTime, isPaused } = item;

    if (_.isEmpty(item) || isPaused) {
      return;
    }

    const executionTime = Date.now() - startTime;
    this.updateItem(id, { isPaused: true, executionTime });
  }

  public performanceUnpause(id: string): void {
    const item = this.getItem(id);
    const { isPaused, executionTime } = item;

    if (_.isEmpty(item) || !isPaused) {
      return;
    }

    const startTime = Date.now() - executionTime;
    this.updateItem(id, { startTime, isPaused: false, executionTime: 0 });
  }

  public performanceEnd(id: string, options = {}): void {
    const item = this.getItem(id);
    const { startTime, isPaused } = item;

    if (_.isEmpty(item) || isPaused) {
      return;
    }

    const executionTime = Date.now() - startTime;
    this.requestLogs({ status: 200, executionTime, ...options } as SuccessLog);
    this.deleteItem(id);
  }

  public performanceCancel(id: string): void {
    this.deleteItem(id);
  }

  public error(error: AssemblerError, id: string, options = {}): void {
    const item = this.getItem(id);

    if (_.isEmpty(item) || !error) {
      return;
    }

    this.requestLogs({ status: 500, error: this.parseError(error), ...options } as ErrorLog);
    this.deleteItem(id);
  }
}
