import { call, put, race, select, spawn, take } from 'redux-saga/effects';

import { MethodName } from 'const';
import { updateAppState } from 'containers/App/actions';
import { getLayoutPreviewUrl } from 'containers/Common/services/getLayoutPreviewUrl';
import { layouts as layoutSelectors } from 'containers/Layouts/selectors';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { ActionTypes } from '../constants';
import * as Models from '../models';

export function* generateLayoutPreview(action: Models.Action.GenerateLayoutPreview) {
  yield race({
    task: call(function* () {
      const logId = logger.performanceStart();
      const methodName = MethodName.GENERATE_REUSABLE_LAYOUT_THUMBNAIL;

      try {
        const logId = logger.performanceStart();
        const { layoutIds } = action.payload;
        const layouts: ReturnTypeSaga<typeof layoutSelectors> = yield select(layoutSelectors);
        // if we re-save GRL or save mock GRL, layoutIds is an array with a single GRL id
        const layoutIdsToPreview = layoutIds.length === 1 && isGroupLayout(layouts.get(layoutIds[0]))
          ? layouts.getIn([layoutIds[0], 'layoutIds']).toJS() as string[]
          : layoutIds;
        // todo: add separate action to update temp layout preview
        yield put(updateAppState({ tempLayoutPreview: { isBeingGenerated: true } }));

        const s3Url: ReturnTypeSaga<typeof getLayoutPreviewUrl> = yield call(getLayoutPreviewUrl, layoutIdsToPreview);
        const { source: preloadedUrl }: ReturnTypeSaga<typeof getImageInternalInfo> = yield call(getImageInternalInfo, s3Url);

        yield put(updateAppState({ tempLayoutPreview: { isBeingGenerated: false, s3Url, preloadedUrl } }));

        yield spawn([logger, logger.performanceEnd], logId, { methodName });
      } catch (error) {
        yield spawn([logger, logger.error], error, logId, { methodName });
        yield put(updateAppState({ tempLayoutPreview: { isBeingGenerated: false } }));
        yield call(handleSagaError, error, 'Layouts.generateLayoutPreview', 'GenerateReusableLayoutPreview');
      }
    }),
    cancel: take(ActionTypes.CANCEL_LAYOUT_PREVIEW_GENERATION),
  });
}
