import Immutable from 'immutable';
import _ from 'lodash';

import * as Models from 'models';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { getValue } from '../getter';
import { isImmutable, toImmutable } from '../immutable';
import { recalculateColumnsWidth } from './recalculateLayoutColumnsWidth';

export function recalculateColumnsWidthRecursively(
  relationId: string,
  relations: Models.LayeredRelationsMap | Models.LayeredRelations | Models.Relations | Models.RelationsMap,
  newWidth: number,
): Models.LayeredRelationsMap | Models.LayeredRelations | Models.Relations | Models.RelationsMap {
  const relation = getValue(relations as Models.LayeredRelationsMap, relationId) as Models.LayeredRelationMap;

  if (isRegularRelation(relation)) {
    return relations;
  }

  if (isColumnRelation(relation)) {
    return (getValue(relation, 'relationIds') as Immutable.List<string>).reduce(
      (_relations, _relationId) => recalculateColumnsWidthRecursively(_relationId, _relations, newWidth),
      relations,
    );
  }

  const newColumnsWidth = recalculateColumnsWidth(getValue(relation, 'styles'), newWidth);

  const updatedRelations = (getValue(relation, 'relationIds') as Immutable.List<string>).reduce(
    (_relations, _relationId, index) => recalculateColumnsWidthRecursively(_relationId, _relations, newColumnsWidth[index]),
    relations,
  );

  return isImmutable(updatedRelations as Models.LayeredRelationsMap | Models.LayeredRelations)
    ?
    (updatedRelations as Models.LayeredRelationsMap | Models.RelationsMap).setIn(
      [relationId, 'styles', 'columnsWidth'], toImmutable(newColumnsWidth),
    )
    : _.set(updatedRelations as Models.LayeredRelations | Models.Relations, [relationId, 'styles', 'columnsWidth'], newColumnsWidth);
}
