import Immutable from 'immutable';

import * as Models from 'models';

/**
 * Returns List of sections on an artboard in correct order from Screen Definition
 * @param sectionByName - Map of sections on an artboard by their name (from Screen Definition)
 * @param screenDefinitionSections
 */
export function getOrderedSections(
  sectionByName: Immutable.Map<string, Models.SectionMap>,
  screenDefinitionSections: Models.MasterScreen.SectionsList,
): Models.SectionsList {
  return screenDefinitionSections.map(screenDefinitionSection => sectionByName.get(screenDefinitionSection.get('name')));
}
