import { DraftEntity } from 'const';
import { DecoratorStrategy } from 'models/draft-js/DecoratorStrategy';

const findEntity = (entityType: DraftEntity): DecoratorStrategy =>
  (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();

        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === entityType
        );
      },
      callback,
    );
  };

export const findReferences = findEntity(DraftEntity.REFERENCE);

export const findLinks = findEntity(DraftEntity.LINK);

export const findNoWrap = findEntity(DraftEntity.NO_WRAP);

export const findAbbreviation = findEntity(DraftEntity.ABBREVIATION);
