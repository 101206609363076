import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import { Alignment } from 'models';
import { intlGet } from 'utils/intlGet';
import { AssetAlignmentType } from './AssetAlignment';

/**
 * Using only for horizontal alignment in Text components
 */
interface HorizontalTextAlignment {
  type: TextHorizontalAlignmentType;
  icon: IconType;
  title: string;
}

// TODO: It will be better to use block data level for the alignment configuration instead of block type level
export enum TextHorizontalAlignmentType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
  UNORDERED_LIST = 'unordered-list-item',
}

export const HORIZONTAL_TEXT_ALIGNMENTS: HorizontalTextAlignment[] = [
  {
    type: TextHorizontalAlignmentType.LEFT,
    icon: IconType.TOOLBAR_LEFT,
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_LEFT,
  },
  {
    type: TextHorizontalAlignmentType.CENTER,
    icon: IconType.TOOLBAR_CENTER,
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_CENTER,
  },
  {
    type: TextHorizontalAlignmentType.RIGHT,
    icon: IconType.TOOLBAR_RIGHT,
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_RIGHT,
  },
  {
    type: TextHorizontalAlignmentType.JUSTIFY,
    icon: IconType.TOOLBAR_JUSTIFY,
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_JUSTIFY,
  },
];

export const TEXT_VERTICAL_ALIGNMENTS: Alignment[] = [
  {
    type: AssetAlignmentType.TOP,
    hint: intlGet('TextVerticalAlignment.Hint', 'Top'),
    style: { justifyContent: 'flex-start' },
    icon: IconType.TEXT_TOP,
  },
  {
    type: AssetAlignmentType.VERTICAL_CENTER,
    hint: intlGet('TextVerticalAlignment.Hint', 'Middle'),
    style: { justifyContent: 'center' },
    icon: IconType.TEXT_CENTER,
  },
  {
    type: AssetAlignmentType.BOTTOM,
    hint: intlGet('TextVerticalAlignment.Hint', 'Bottom'),
    style: { justifyContent: 'flex-end' },
    icon: IconType.TEXT_BOTTOM,
  },
];

// TODO: [#defaultStyles] we need to move all default styles to 1 place
export const DefaultTextAlignment = TextHorizontalAlignmentType.LEFT;

export const TextAlignmentMap: PartialRecord<TextHorizontalAlignmentType, AssetAlignmentType> = {
  [TextHorizontalAlignmentType.LEFT]: AssetAlignmentType.LEFT,
  [TextHorizontalAlignmentType.CENTER]: AssetAlignmentType.HORIZONTAL_CENTER,
  [TextHorizontalAlignmentType.RIGHT]: AssetAlignmentType.RIGHT,
};
