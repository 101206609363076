import classNames from 'classnames';
import React from 'react';

import ActionIcon from 'components/ActionIcon';
import { IconType } from 'components/Icon/constants';
import ResizeButton from 'containers/ResizeButton';
import { intlGet } from 'utils/intlGet';
import { ISpacerActionsProps, ISpacerActionsState } from './models';
import styles from './styles.module.scss';

export default class SpacerActions extends React.PureComponent<ISpacerActionsProps, ISpacerActionsState> {
  readonly state: ISpacerActionsState = {
    show: false,
    isResizing: false,
  };

  private handleDragStart = (): void => {
    this.setState({ isResizing: true });
  };

  private handleDragStop = (): void => {
    const { finishResize } = this.props;
    finishResize();
    this.setState({ isResizing: false, show: false });
  };

  private hideActions = (): void => {
    if (!this.state.isResizing) {
      this.setState({ show: false });
    }
  };

  private showActions = (): void => {
    if (!this.props.areActionsDisabled) {
      this.setState({ show: true });
    }
  };

  private enableLayoutMovement = (): void => {
    this.props.setMoveableAsset(true);
  };

  private disableLayoutMovement = (): void => {
    this.props.setMoveableAsset(false);
  };

  render() {
    const { spacerHeight, deleteLayout, connectDragSource, resize } = this.props;
    const opacityStyle = this.state.show ? 1 : 0;
    const hideHint = spacerHeight < 25;

    return (
      <div
        className={styles.SpacerActions}
        style={{ opacity: opacityStyle }}
        onMouseEnter={this.showActions}
        onMouseLeave={this.hideActions}
      >
        <div className={classNames(styles.hint, { [styles.hidden]: hideHint })}>{intlGet('Artboard.Spacer.Text', 'Hint')}</div>
        {
          connectDragSource(
            <div
              className={styles.leftAction}
              onMouseDown={this.hideActions}
              onMouseUp={this.showActions}
              onMouseEnter={this.enableLayoutMovement}
              onMouseLeave={this.disableLayoutMovement}
            >
              <ActionIcon
                type={IconType.UP_AND_DOWN_ARROWS}
                size="badge"
                color="secondary"
              />
            </div>,
          )
        }
        <div className={styles.rightAction} onClick={deleteLayout}>
          <ActionIcon
            type={IconType.DELETE}
            size="xs"
            color="secondary"
          />
        </div>
        <div className={styles.bottomActionContainer}>
          <ResizeButton
            handleDrag={resize}
            handleDragStart={this.handleDragStart}
            handleDragStop={this.handleDragStop}
            height={spacerHeight}
          />
        </div>
      </div>
    );
  }
}
