import { ActionTypes } from './constants';
import * as Models from './models';

export const addSurface: Models.ActionCreator.AddSurface = surface => ({
  type: ActionTypes.ADD_SURFACE,
  payload: {
    surface,
  },
});

export const setSurfaces: Models.ActionCreator.SetSurfaces = surfaces => ({
  type: ActionTypes.SET_SURFACES,
  payload: {
    surfaces,
  },
});

export const updateSurfaceType: Models.ActionCreator.UpdateSurfaceType = (surfaceId, screenDefinitionId) => ({
  type: ActionTypes.UPDATE_SURFACE_TYPE,
  payload: {
    surfaceId,
    screenDefinitionId,
  },
});

export const updateSurface: Models.ActionCreator.UpdateSurface = surface => ({
  type: ActionTypes.UPDATE_SURFACE,
  payload: {
    surface,
  },
});

export const updateSurfaces: Models.ActionCreator.UpdateSurfaces = surfaces => ({
  type: ActionTypes.UPDATE_SURFACES,
  payload: {
    surfaces,
  },
});

export const deleteSurfaceRequest: Models.ActionCreator.DeleteSurfaceRequest = id => ({
  type: ActionTypes.DELETE_SURFACE_REQUEST,
  payload: {
    id,
  },
});

export const deleteSurfaces: Models.ActionCreator.DeleteSurfaces = ids => ({
  type: ActionTypes.DELETE_SURFACES,
  payload: {
    ids,
  },
});

export const duplicateSurface: Models.ActionCreator.DuplicateSurface = id => ({
  type: ActionTypes.DUPLICATE_SURFACE,
  payload: {
    id,
  },
});

export const setSurfaceSettings: Models.ActionCreator.SetSurfaceSettings = id => ({
  type: ActionTypes.SET_SURFACE_SETTINGS,
  payload: {
    id,
  },
});

export const refreshThumbnail: Models.ActionCreator.RefreshThumbnail = surfaceId => ({
  type: ActionTypes.REFRESH_THUMBNAIL,
  payload: {
    surfaceId,
  },
});

export const setScreenFormat: Models.ActionCreator.SetScreenFormat = arg => ({
  type: ActionTypes.SET_SCREEN_FORMAT,
  payload: arg,
});
