import React from 'react';
/* eslint-disable max-len */

const ToolbarUnorderedList = (): JSX.Element => (
  <svg viewBox="5 6 20 20">
    <defs>
      <path d="M7.5,14 C6.67,14 6,14.67 6,15.5 C6,16.33 6.67,17 7.5,17 C8.33,17 9,16.33 9,15.5 C9,14.67 8.33,14 7.5,14 L7.5,14 Z M7.5,8 C6.67,8 6,8.67 6,9.5 C6,10.33 6.67,11 7.5,11 C8.33,11 9,10.33 9,9.5 C9,8.67 8.33,8 7.5,8 L7.5,8 Z M7.5,20.17 C6.76,20.17 6.17,20.77 6.17,21.5 C6.17,22.23 6.77,22.83 7.5,22.83 C8.23,22.83 8.83,22.23 8.83,21.5 C8.83,20.77 8.24,20.17 7.5,20.17 L7.5,20.17 Z M10.5,22.5 L24.5,22.5 L24.5,20.5 L10.5,20.5 L10.5,22.5 L10.5,22.5 Z M10.5,16.5 L24.5,16.5 L24.5,14.5 L10.5,14.5 L10.5,16.5 L10.5,16.5 Z M10.5,8.5 L10.5,10.5 L24.5,10.5 L24.5,8.5 L10.5,8.5 L10.5,8.5 Z" id="path-1-ic_format_list_bulleted" />
    </defs>
    <g id="3.-Icons/ic_format_list_bulleted" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="mask-2-ic_format_list_bulleted" fill="white">
        <use xlinkHref="#path-1-ic_format_list_bulleted" />
      </mask>
      <g id="Icon" />
      <g id="5.-Colors/Gray-85" mask="url(#mask-2-ic_format_list_bulleted)" fill="#D8D8D8">
        <g transform="translate(2.000000, 2.000000)" id="Gray-85">
          <rect x="0" y="0" width="26" height="26" />
        </g>
      </g>
    </g>
  </svg>
);

export default ToolbarUnorderedList;
