import React from 'react';

const ToolbarUnderline = (): JSX.Element => (
  <svg viewBox="0 -1 8 13">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontSize="14" fontWeight="bold">
      <g transform="translate(-403.000000, -23.500000)" fill="#FFFFFF">
        <g>
          <g>
            <g transform="translate(146.000000, 13.6)">
              <text>
                <tspan x="256" y="20" textDecoration="underline">U</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ToolbarUnderline;
