import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { isObjectLayered } from 'utils/layers';

export function isLayoutLayered(layout: Models.LayeredCombinedLayout | Models.CombinedLayout): layout is Models.LayeredCombinedLayout;
export function isLayoutLayered(layout: Models.LayeredCombinedLayoutMap | Models.CombinedLayoutMap): layout is Models.LayeredCombinedLayoutMap;
export function isLayoutLayered(
  layout: Models.LayeredCombinedLayout | Models.CombinedLayout | Models.LayeredCombinedLayoutMap | Models.CombinedLayoutMap,
): boolean {
  const layoutToCheck = isImmutable(layout as Models.LayoutMap)
    ? (layout as Models.LayoutMap).toJS() as Models.LayeredCombinedLayout | Models.CombinedLayout
    : layout as Models.LayeredCombinedLayout | Models.CombinedLayout;

  return isObjectLayered(layoutToCheck.documentId);
}
