import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { DetailsProps } from './models';
import styles from './styles.module.scss';

export const Details: React.FunctionComponent<PropsWithChildren<DetailsProps>> = (props) => {
  const { title, children, onClose, hint, subtitle, border = true } = props;

  return (
    <div className={styles.Details}>
      <div className={classNames(styles.header, { [styles.border]: border })}>
        <div className={styles.info}>
          <div className={styles.title}>{title}</div>
          {hint && <div className={styles.hint}>{hint}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <div className={styles.closeBtn} onClick={onClose}>
          <Icon type={IconType.CLOSE} size="sm-md" color="primary" />
        </div>
      </div>
      <div className={styles.body}>
        {children}
      </div>
    </div>
  );
};
