import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { intlGet } from 'utils/intlGet';
import { LayoutDetailsProps } from './models';
import styles from './styles.module.scss';

const LayoutDetails: React.FunctionComponent<LayoutDetailsProps> = ({ options, hideModal, modalWindowId }) => {
  const onClose = (): void => { hideModal(modalWindowId); };
  const document = options.get('document');
  const name = document.get('name');
  const number = document.get('number');
  const status = document.get('status');
  const product = document.get('product');
  const thumbnailUrl = document.get('_thumbnailUrl');
  const image = React.createRef<HTMLImageElement>();
  const [fullSized, setFullSized] = useState(false);
  const toggleFullSizedState = (): void => setFullSized(fullSized => !fullSized);
  const productName = _.first(product.toJS());

  return (
    <div className={styles.LayoutDetails}>
      <header>
        <div className={styles.info}>
          <div className={styles.title}>{name}</div>
        </div>
        <Icon className={styles.closeIcon} onClick={onClose} type={IconType.CLOSE} size="sm-md" color="primary" />
      </header>
      <section>
        <div className={styles.metadata}>
          {status && <span className={styles.status}>{ status.toUpperCase() }</span>}
          <span className={styles.number}>{number}</span>
          {productName && <span className={styles.productName}>{productName}</span>}
        </div>
        <div className={styles.preview}>
          {
            thumbnailUrl
              ?
              <img
                ref={image}
                className={classNames(styles.image, { [styles.fullSize]: fullSized })}
                src={thumbnailUrl}
                onClick={toggleFullSizedState}
              />
              :
              <div className={styles.icon}>
                <Icon type={IconType.IMAGE} size="xxl" color="grey" />
                <span className={styles.iconText}>{intlGet('ProjectPanel.ImageNotAvailable', 'Preview')}</span>
              </div>
          }
        </div>
      </section>
    </div>
  );
};

export default LayoutDetails;
