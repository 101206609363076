import { AlignmentValue, AssetAlignmentType } from 'const';
import { DefaultAlign, DefaultVAlign } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';

export const createAlignment = ({
  valign = DefaultVAlign,
  align = DefaultAlign,
} = {} as Partial<Schemas.Alignment>): Schemas.Alignment => {
  return {
    valign: valign === AlignmentValue[AssetAlignmentType.VERTICAL_CENTER] ? 'middle' : valign,
    align,
  };
};
