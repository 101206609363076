import React from 'react';
/* eslint-disable max-len */

const DoubleArrowAuto = (): JSX.Element => (
  <svg viewBox="0 0 512 512">
    <g strokeWidth="0"/>
    <g strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M370,387.12c-4.267-3.947-10.773-3.947-14.933,0l-88.427,88.533V36.4l88.427,88.427c4.267,4.053,10.987,3.947,15.04-0.213 c3.947-4.16,3.947-10.667,0-14.827L263.44,3.12c-4.16-4.16-10.88-4.16-15.04,0L141.733,109.787 c-4.053,4.267-3.947,10.987,0.213,15.04c4.16,3.947,10.667,3.947,14.827,0L245.307,36.4v439.147L156.88,387.12 c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827l106.667,106.667c4.16,4.16,10.88,4.16,15.04,0 L370.213,402.16C374.267,397.893,374.16,391.173,370,387.12z"/>
  </svg>
);

export default DoubleArrowAuto;
