import React from 'react';
/* eslint-disable max-len */

const AssemblerLogo = (): JSX.Element => (
  <svg width="30px" height="30px" viewBox="0 0 32 32">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#ECB131" offset="0%" />
        <stop stopColor="#F48932" offset="100%" />
      </linearGradient>
      <circle id="path-2" cx="15" cy="15" r="15" />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-19.000000, -14.000000)">
        <g id="asmo-logo" transform="translate(20.000000, 15.000000)">
          <path d="M15,-1 C23.836556,-1 31,6.163444 31,15 C31,23.836556 23.836556,31 15,31 C6.163444,31 -1,23.836556 -1,15 C-1,6.163444 6.163444,-1 15,-1 Z M15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842712 6.71572875,30 15,30 C23.2842712,30 30,23.2842712 30,15 C30,6.71572875 23.2842712,0 15,0 Z" id="Mask" fill="#F9AD3D" fillRule="nonzero" />
          <mask id="mask-3" fill="white">
            <use xlinkHref="#path-2" />
          </mask>
          <use id="Mask" fill="url(#linearGradient-1)" xlinkHref="#path-2" />
          <path d="M15.8732513,-0.786450957 L16.5898358,0.100953621 L27.6108689,23.1897598 L29.3179674,25.4503949 L25.1786435,26.0783749 C23.9730799,23.9080087 23.0162167,21.8967601 22.3080541,20.0446292 C12.3166228,17.697408 5.10877598,25.7655747 4.84419322,26.0783749 C4.66780472,26.2869084 3.56073325,26.2869084 1.52297881,26.0783749 L2.55145991,23.3106635 C9.8525481,7.89172824 13.6181946,0.121507062 13.8483995,5.68434189e-14 C14.0786044,-0.121507062 14.753555,-0.383657381 15.8732513,-0.786450957 Z M15.2050365,4.99093963 C10.1912926,15.0170854 7.92613982,19.6988069 8.40957819,19.0361043 C8.39447973,18.9239443 13.1438807,15.8552178 20.5741934,16.2797003 C19.2492674,13.677684 17.4595485,9.91476382 15.2050365,4.99093963 Z" id="Combined-Shape" fill="#FFFFFF" mask="url(#mask-3)" />
        </g>
      </g>
    </g>
  </svg>
);

export default AssemblerLogo;
