import React, { useMemo } from 'react';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { abbreviationsListToHTML } from '../../../utils/abbreviationsListToHTML';
import css from '../styles.module.scss';

type Props = {
  list: Models.TextAbbreviationJSON[];
};

const List: React.FunctionComponent<Props> = (props) => {
  const { list } = props;
  const escapedHTML = useMemo(
    () => abbreviationsListToHTML(list),
    [list],
  );

  if (!escapedHTML) {
    return (
      <div className={css.emptyList}>
        {intlGet('Artboard.Abbreviations', 'Blank')}
      </div>
    );
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: escapedHTML }} />
  );
};

export default List;
