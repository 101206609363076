import * as Models from 'models';
import { getDesktopBrandStylesRef } from './getDesktopBrandStyles';

export function applyTextTransformOverride(
  target: Models.BrandStyle, source: Models.BrandStyle,
): void {
  if (target.forced) {
    return;
  }
  const targetStyles = getDesktopBrandStylesRef(target);
  const sourceStyles = getDesktopBrandStylesRef(source);
  if (targetStyles && !targetStyles.textTransform && sourceStyles?.textTransform) {
    targetStyles.textTransform = sourceStyles?.textTransform;
  }
}
