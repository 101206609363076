import React from 'react';
/* eslint-disable max-len */

const Ungroup = (): JSX.Element => (
  <svg viewBox="3 3 24 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M15,15 L16,15 L16,27 L15,27 L15,15 Z M16,15 L27,15 L27,16 L16,16 L16,15 Z M26,16 L27,16 L27,27 L26,27 L26,16 Z M16,26 L26,26 L26,27 L16,27 L16,26 Z M15,15 L3,15 L3,3 L15,3 L15,15 Z" />
    </g>
  </svg>
);

export default Ungroup;
