export enum MethodName {
  OPEN_PROJECT = 'OpenProject',
  REFRESH_PROJECT = 'RefreshProject',
  PREVIEW_PROJECT = 'PreviewProject',
  GENERATE_PDF = 'GeneratePdf',
  GENERATE_HTML = 'GenerateHtml',
  GENERATE_IPAD = 'GenerateIpad',
  UPLOAD_DRAFT = 'UploadDraft',
  UPLOAD_FOR_REVIEW = 'UploadForReview',
  GENERATE_SCREEN_THUMBNAIL = 'GenerateScreenThumbnail',
  GENERATE_REUSABLE_LAYOUT_THUMBNAIL = 'GenerateReusableLayoutThumbnail',
  TRANSLATION_EXPORT = 'TranslationExport',
  TRANSLATION_IMPORT = 'TranslationImport',
  CREATE_IMAGE = 'CreateImage',
  UPDATE_IMAGE = 'UpdateImage',
  CREATE_REUSABLE_LAYOUT = 'CreateReusableLayout',
  UPDATE_REUSABLE_LAYOUT = 'UpdateReusableLayout',
  CREATE_REFERENCE_CITATION = 'CreateReferenceCitation',
  UPDATE_REFERENCE_CITATION = 'UpdateReferenceCitation',
  STORY_CARD_EXPAND = 'StoryCardExpand',
}
