import { call, delay, put, select, spawn } from 'redux-saga/effects';

import { MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import * as ProjectSelectors from 'containers/Project/selectors';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { getPackageForHtml } from 'services/api';
import { ArtboardsJson } from 'services/ArtboardConverter/models';
import { getUsedFontFaces } from 'services/getUsedFontFaces';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { getPackageName } from 'utils/getPackageName';
import { intlGet } from 'utils/intlGet';
import { downloadFileByUrl } from '../services/downloadFileByUrl';
import { getArtboardsJson } from '../services/getArtboardsJson';

export function* generatePackageForHTML() {
  const logId = logger.performanceStart();
  const methodName = MethodName.GENERATE_HTML;

  try {
    yield put(AppActions.lockProjectContent(ProcessType.EXPORTING_FILES));
    yield delay(0); // needs to show loader immediately, we have render delay due to artboards.json generation

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const projectType: ReturnTypeSaga<typeof ProjectSelectors.projectType> = yield select(ProjectSelectors.projectType);
    const archiveName: ReturnTypeSaga<typeof getPackageName> = yield call(getPackageName, rootDocument, projectType);
    const artboardsJson: ArtboardsJson = yield call(getArtboardsJson);
    const usedFontFaces: ReturnTypeSaga<typeof getUsedFontFaces> = yield call(getUsedFontFaces);
    const helperFileName: ReturnTypeSaga<typeof ProjectSelectors.helperFileName> = yield select(ProjectSelectors.helperFileName);

    const { data: { htmlUrl } }: ReturnTypeSaga<typeof getPackageForHtml> = yield call(
      getPackageForHtml,
      artboardsJson,
      archiveName,
      usedFontFaces,
      projectType,
      helperFileName,
    );

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    yield call(downloadFileByUrl, htmlUrl, archiveName);

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'ExportPackage'),
    );
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Artboards.generatePackageForHTML', 'GeneratePackageForHTML');
  } finally {
    yield put(AppActions.unlockProjectContent());
  }
}
