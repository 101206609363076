import { OperatingSystem, Platform } from 'const';

const checkOS = (operatingSystem: OperatingSystem) => (platform: string): boolean => Platform[operatingSystem].includes(platform);

/**
 * Checks if current operating system is MacOS
 * @param {string} platform - current platform, retrieved from 'window.navigator.platform'
 */
export const isMac = checkOS(OperatingSystem.Mac);

/**
 * Checks if current operating system is Windows
 * @param {string} platform - current platform, retrieved from 'window.navigator.platform'
 */
export const isWindows = checkOS(OperatingSystem.Windows);
