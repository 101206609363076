import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { ProcessType } from 'const';
import { lockProjectContent, unlockProjectContent } from 'containers/App/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { setUploadingDocumentId } from 'containers/Project/actions';
import { currentCountry, currentLanguage } from 'containers/Project/selectors';
import { setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { getDocumentDuplicates } from './getDocumentDuplicates';
import { replaceDocumentWithDuplicate } from './replaceDocumentWithDuplicate';

export function* updateEditedDocument(
  id: string,
  source: File | string,
  updateDocumentFunction: () => SagaIterator,
  replaceCallback: (id: string) => SagaIterator,
  documentDetailWindowId?: string,
): SagaIterator {
  let uploadingDocumentId = id;

  yield put(lockProjectContent(ProcessType.DUPLICATES_CHECKING));

  const language = (yield select(currentLanguage)).toJS() as string[];
  const country = (yield select(currentCountry)).toJS() as string[];
  const { documentDuplicates, documentLinksByDocumentNumber }: ReturnTypeSaga<typeof getDocumentDuplicates> = yield call(
    getDocumentDuplicates,
    source,
    _.first(language),
    _.first(country),
  );

  if (!Array.isArray(documentDuplicates) || documentDuplicates.length === 0) {
    yield put(batchActions([
      unlockProjectContent(),
      setUploadingDocumentId(uploadingDocumentId),
      setAssetIdToScroll(uploadingDocumentId),
      ...(documentDetailWindowId ? [hideModal(documentDetailWindowId)] : []),
    ]));

    yield call(updateDocumentFunction);
  } else {
    uploadingDocumentId = yield* replaceDocumentWithDuplicate(
      uploadingDocumentId,
      documentDuplicates,
      documentLinksByDocumentNumber,
      documentDetailWindowId,
      typeof source === 'string' && source,
    );

    uploadingDocumentId && (yield call(replaceCallback, uploadingDocumentId));
  }

  return uploadingDocumentId;
}
