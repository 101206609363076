import Immutable from 'immutable';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { SplitCellDirection } from 'const';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { addRelations } from 'containers/Relations/actions';
import { relations as relationsSelector } from 'containers/Relations/selectors';
import { sectionsWidthByName as sectionsWidthSelector } from 'containers/ScreenDefinitions/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createRelation, createRowRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { addColumn } from 'utils/addColumn';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { Action } from '../models';

export function* splitCell(action: Action.ISplitCell) {
  try {
    const actions: Models.IAction[] = [saveAppState()];
    const { direction, layoutId, parentRelationId, relationId: cellToBeSplitId } = action.payload;
    const splitCellLeft = direction === SplitCellDirection.LEFT;
    const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const layouts: ReturnTypeSaga<typeof layoutsSelector> = yield select(layoutsSelector);
    const sectionsWidth: ReturnTypeSaga<typeof sectionsWidthSelector> = yield select(sectionsWidthSelector);
    const sections: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
    const layout = layouts.get(layoutId);
    const parentRelation = relations.get(parentRelationId) as Models.ColumnRelationMap | Models.RowRelationMap;
    const cellToBeSplitIndex = parentRelation.get('relationIds').indexOf(cellToBeSplitId);

    const newCell = createRelation();
    actions.push(addRelations([newCell]));

    if (isRowRelation(parentRelation)) {
      const newCellPosition = splitCellLeft ? cellToBeSplitIndex : cellToBeSplitIndex + 1;
      const updatedParentRelation = (parentRelation as Models.RowRelationMap)
        .update('relationIds', relationIds => relationIds.insert(newCellPosition, newCell.id))
        .updateIn(['styles', 'columnsWidth'], (columnsWidth) => {
          const newWidth = addColumn(Immutable.List([columnsWidth.get(cellToBeSplitIndex)])).first<number>();

          return columnsWidth
            .set(cellToBeSplitIndex, newWidth)
            .insert(newCellPosition, newWidth);
        });

      actions.push(addRelations([updatedParentRelation]));
    } else {
      const grandparentRelation = relations.filter(isRowRelation).find(rel => rel.get('relationIds').includes(parentRelationId));
      const sectionId = layout.get('section');

      const newRowWidth = grandparentRelation
        ? grandparentRelation.getIn(['styles', 'columnsWidth', grandparentRelation.get('relationIds').indexOf(parentRelationId)])
        : sectionsWidth.get(sections.getIn([sectionId, 'name']));

      const relationIds = splitCellLeft ? [newCell.id, cellToBeSplitId] : [cellToBeSplitId, newCell.id];
      const newRow = createRowRelation({ relationIds }, { width: newRowWidth });

      const updatedParentRelation = (parentRelation as Models.ColumnRelationMap).update('relationIds', relationIds => (
        relationIds.set(cellToBeSplitIndex, newRow.id)
      ));

      actions.push(addRelations([updatedParentRelation, newRow]));
    }

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.addCell', 'AddCell');
  }
}
