import { CombinedDocument, CombinedDocumentMap, CombinedDocumentsMap } from 'models';
import { isNotMockDocument } from './documents';

export function filterCombinedDocuments<T extends CombinedDocument>(
  documents: CombinedDocumentsMap,
  filterFunction: (document: CombinedDocumentMap) => document is DeepIMap<T>,
): DeepIMap<Record<string, T>> {
  return documents
    .filter(filterFunction)
    .filter(isNotMockDocument)
    .sortBy(
      document => document.get('name').trim(),
      (a, b) => a.localeCompare(b),
    ) as DeepIMap<Record<string, T>>;
}

export function filterDocumentsForAssetsPanel<T>(documents: T): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (documents as any)
    .filter(isNotMockDocument)
    .sortBy(
      document => document.get('name').trim(),
      (a, b) => a.localeCompare(b),
    ) as T;
}
