import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_ACTIVE_LAYOUT_ID: null,
    ADD_SELECTED_ARTBOARD_COMPONENT: null,
    ADD_SELECTED_ASSET_PANEL_COMPONENT: null,
    CANCEL_LAST_UNDO_STATE: null,
    STOP_HANDLING_REUSABLE_LAYOUTS_EDITING: null,
    START_HANDLING_REUSABLE_LAYOUTS_EDITING: null,
    SET_LAST_EDITED_LAYOUT_ID: null,
    DOWNLOAD_ISSUE_REPORT: null,
    END_THUMBNAIL_REFRESH: null,
    IMPORT_TRANSLATION_PACKAGE: null,
    LOCK_PROJECT_REQUEST: null,
    REMOVE_ACTIVE_LAYOUT_ID: null,
    REMOVE_SELECTED_ARTBOARD_COMPONENT: null,
    REMOVE_SELECTED_ASSET_PANEL_COMPONENT: null,
    SAVE_PROJECT_LOCALLY: null,
    SET_IS_RESIZING_LAYOUT: null,
    SET_IS_RESIZING_COLUMN: null,
    SET_IS_RESIZING_ROW: null,
    SET_LAST_ERROR: null,
    SET_MOVEABLE_LAYOUT_ID: null,
    START_THUMBNAIL_REFRESH: null,
    TOGGLE_DRAG_STATE: null,
    TOGGLE_SHOW_SCREENS_PANEL: null,
    TOGGLE_UPDATED_DOCUMENTS_DISPLAYING: null,
    TOGGLE_PREVIEW: null,
    UNLOCK_PROJECT_REQUEST: null,
    UPDATE_APP_STATE: null,
    UPDATE_ARTBOARD_ZOOM_STATE: null,
    UPDATE_DRAG_HOTSPOT_GROUP_LAYOUT_ID: null,
    UPDATE_DRAG_HOTSPOT_POSITION: null,
    UPDATE_PREVIEW_ZOOM_STATE: null,
    UPDATE_SSI_HOTSPOT_POSITION: null,
  },
  'App',
);

export const FILE_NAMES_MAX_COUNT = 3;
