import { MapDispatchToPropsFunction, MergeProps, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IList } from 'typings/DeepIMap';
import ColorPicker from 'components/Toolbar/Shared/ColorPicker';
import { ColorPickerProps, ColorPickerOwnProps, ColorPickerSelectors } from 'components/Toolbar/Shared/ColorPicker/models';
import { ModalType } from 'const';
import { availableForUserColorsCategories, projectFlatColors } from 'containers/BrandDefinition/selectors';
import { showModal } from 'containers/ModalManager/actions';
import { BrandColor, BrandColorMap, BrandColorGradientMap, ColorsCategory } from 'models';

type GradientValue = BrandColorGradientMap | null;
type GradientSetter = (value: GradientValue, backupColor: BrandColorMap | null) => void;

type DispatchProps = {
  showGradientModal: (
    colorsCategories: IList<ColorsCategory>,
    colors: IList<BrandColor>,
    gradient: GradientValue,
    backupColor: BrandColorMap,
    onChange: GradientSetter,
  ) => void;
};

const mapStateToProps = createStructuredSelector({
  colorsCategories: availableForUserColorsCategories,
  colors: projectFlatColors,
});

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, ColorPickerOwnProps> = dispatch => ({
  showGradientModal: (
    colorsCategories: IList<ColorsCategory>,
    colors: IList<BrandColor>,
    gradient: GradientValue,
    backupColor: BrandColorMap,
    onChange: GradientSetter,
  ): void => {
    dispatch(showModal(ModalType.COLOR_GRADIENT_PICKER, {
      colorsCategories,
      colors,
      gradient,
      backupColor,
      onGradientChange: onChange,
    }));
  },
});

const mergeProps: MergeProps<ColorPickerSelectors, DispatchProps, ColorPickerOwnProps, ColorPickerProps> = (
  stateProps,
  dispatchProps,
  ownProps,
) => {
  const { showGradientModal } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    showGradientPickerModal: (gradient: GradientValue, backupColor: BrandColorMap, onChange: GradientSetter) => showGradientModal(
      stateProps.colorsCategories,
      stateProps.colors,
      gradient,
      backupColor,
      onChange,
    ),
  };
};

export default connect<ColorPickerSelectors, DispatchProps, ColorPickerOwnProps, ColorPickerProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ColorPicker);
