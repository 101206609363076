import React from 'react';

const TextOneColumn = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <defs>
      <polygon points="0.0188306705 0.0430306653 26.9190841 0.0430306653 26.9190841 14.9298145 0.0188306705 14.9298145" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-111.000000, -358.000000)">
        <g transform="translate(111.000000, 358.000000)">
          <rect fill="#FFFFFF" x="0.75" y="0.75" width="107.5" height="107.5" />
          <rect fill="#F9AD3D" x="22" y="40.75" width="65" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="22" y="53.75" width="65" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="22" y="66.75" width="65" height="5" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default TextOneColumn;
