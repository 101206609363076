import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const GroupLayout = (): JSX.Element => {
  const pathId = guid();
  const path1Id = guid();
  const maskId = guid();
  const mask1Id = guid();

  return (
    <svg width="17px" height="16px" viewBox="0 0 17 16">
      <defs>
        <rect id={pathId} x="0" y="0" width="16" height="16" rx="4"/>
        <path id={path1Id} d="M0,9.6 L10.6666667,9.6 L10.6666667,8 L0,8 L0,9.6 Z M10.1052632,2.66666667 L0.561403509,2.66666667 C0.252631579,2.66666667 0,2.90666667 0,3.2 L0,6.4 C0,6.69333333 0.252631579,6.93333333 0.561403509,6.93333333 L10.1052632,6.93333333 C10.4140351,6.93333333 10.6666667,6.69333333 10.6666667,6.4 L10.6666667,3.2 C10.6666667,2.90666667 10.4140351,2.66666667 10.1052632,2.66666667 Z M0,0 L0,1.6 L10.6666667,1.6 L10.6666667,0 L0,0 Z"/>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1351.000000, -476.000000)">
          <g transform="translate(-6.000000, 0.000000)">
            <g transform="translate(1334.000000, 58.000000)">
              <g transform="translate(14.000000, 413.000000)">
                <g>
                  <g transform="translate(7.179310, 3.000000)">
                    <g transform="translate(2.089655, 2.000000)">
                      <g>
                        <g mask={`url(#${maskId})`}>
                          <g transform="translate(2.666667, 3.200000)">
                            <mask id={mask1Id} fill="white">
                              <use xlinkHref={`#${path1Id}`}/>
                            </mask>
                            <use stroke="none" fill="#000000" fillRule="nonzero" xlinkHref={`#${path1Id}`}/>
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" mask={`url(#${mask1Id})`}>
                              <g transform="translate(-2.000000, -2.000000)" fill="#F9AD3D" id="Orange">
                                <rect x="0" y="0" width="15" height="14"/>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GroupLayout;
