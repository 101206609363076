import * as Models from 'models';
import { getDocumentDuplications } from 'services/api';
import { getChecksum } from 'utils/getChecksum';

export async function getDocumentDuplicates(
  checksumSource: string | File,
  language: string,
  country: string,
): Promise<{ documentDuplicates: Models.Image[] | Models.ReferenceCitation[]; documentLinksByDocumentNumber: Models.DocumentLinksByDocumentNumber }> {
  const checksum: ReturnTypeSaga<typeof getChecksum> = await getChecksum(checksumSource);
  const {
    data: {
      documentsByChecksums,
      documentLinksByDocumentNumber,
    },
  }: ReturnTypeSaga<typeof getDocumentDuplications> = await getDocumentDuplications(checksum, language, country);
  const documentDuplicates = documentsByChecksums[checksum];

  return { documentDuplicates, documentLinksByDocumentNumber };
}
