import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { DraggableCore } from 'react-draggable';

import ActionIcon from 'components/ActionIcon';
import { ActionsColor } from 'components/ArtboardLayoutActions/constants';
import { IconType } from 'components/Icon/constants';
import { toPx } from 'utils/toPx';
import { ResizeButtonProps } from './models';
import styles from './styles.module.scss';

const ResizeButton: React.FunctionComponent<ResizeButtonProps> = (props) => {
  const {
    color = ActionsColor.BLUE,
    handleDrag,
    handleDragStart = _.noop,
    handleDragStop = _.noop,
    height,
    onMouseEnter = _.noop,
    onMouseLeave = _.noop,
    setIsResizingLayout,
    saveAppState,
    disabled = false,
    shiftResizeButton = false,
  } = props;

  const onDragStart = () => {
    saveAppState();
    setIsResizingLayout(true);
    handleDragStart();
  };

  const onDragEnd = () => {
    setIsResizingLayout(false);
    handleDragStop();
  };

  return (
    <DraggableCore
      onDrag={handleDrag}
      onStart={onDragStart}
      onStop={onDragEnd}
      disabled={disabled}
    >
      <div
        className={classNames(
          styles.ResizeButton,
          { [styles.shiftResizeButton]: shiftResizeButton },
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={styles.bottomAction}
        >
          <div>
            <ActionIcon
              className={styles.resizeIcon}
              backgroundColor={color}
              color="secondary"
              isDisabled={disabled}
              size="badge"
              type={IconType.UP_AND_DOWN_ARROWS}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.elementHeight,
            styles[`borderColor_${color}`],
            styles[`backgroundColor_${color}`],
            { [styles.disabled]: disabled },
          )}
        >
          {toPx(height)}
        </div>
      </div>
    </DraggableCore>
  );
};

export default ResizeButton;
