import { put, call } from 'redux-saga/effects';
import { getAbbreviations } from '../../api';
import { TextAbbreviationDocuments } from '../../entities';
import { setTextAbbreviations } from '../actions';

export function* generatorTextAbbreviations(rootDocumentId: number) {
  const textAbbreviationDocuments: TextAbbreviationDocuments = yield call(getAbbreviations, rootDocumentId);

  yield put(setTextAbbreviations(textAbbreviationDocuments));

  return textAbbreviationDocuments;
}
