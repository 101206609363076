import { put } from 'redux-saga/effects';

import { ModalType } from 'const';
import { showModal } from 'containers/ModalManager/actions';
import { NotificationWindowOptions } from 'models';
import { intlGet } from 'utils/intlGet';

export function* showMissingFonts(fonts: string[]) {
  if (fonts.length === 0) {
    return;
  }

  const fontsToNotify = fonts.join(', ');
  const message = intlGet(
    'Notification.ReusableLayout.MissingFonts',
    'Message',
    { fonts: fontsToNotify });
  const options = {
    title: intlGet('Notification.ReusableLayout.MissingFonts', 'Title'),
    message,
  } as NotificationWindowOptions;

  yield put(showModal(ModalType.NOTIFICATION, options));
}
