import classNames from 'classnames';
import * as React from 'react';

import { ComponentLabel } from 'const';
import { IComponentItemProps } from './models';
import styles from './styles.module.scss';

const ComponentItem: React.FunctionComponent<IComponentItemProps> = (props) => {
  const { connectDragSource, isDragging, icon: Icon, type, size } = props;

  return (
    <div className={styles.ComponentItem}>
      {connectDragSource(
        <div className={classNames(styles.icon, { [styles.dragging]: isDragging })}>
          {!isDragging && <Icon />}
        </div>,
      )}
      <div className={styles.label}>
        <span>{ComponentLabel[type].toLowerCase()}</span>
        {!!size && <span className={styles.secondary}>{size}</span>}
      </div>
    </div>
  );
};

export default ComponentItem;
