import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/TextLineHeight';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { TextLineHeightProps } from './models';

const TextLineHeight: React.FunctionComponent<TextLineHeightProps> = (props) => {
  const { setLineHeight, blockLineHeight, controlState = ControlState } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      ref={control}
      state={state}
      title={Title.LINE_HEIGHT}
      type={IconType.LINE_HEIGHT_CONTROL}
    >
      {isOpen && <Dropdown blockLineHeight={blockLineHeight} setLineHeight={setLineHeight} />}
    </Control>
  );
};

export default TextLineHeight;
