import _ from 'lodash';

import { LayoutType } from 'const';
import { CombinedLayout, CombinedLayoutMap, LayeredCombinedLayoutMap, LayeredLayoutMap, Layout, LayoutMap } from 'models';
import { getValue } from 'utils/getter';

export function isPlainLayout(layout: CombinedLayout): layout is Layout;
export function isPlainLayout(layout: CombinedLayoutMap): layout is LayoutMap;
export function isPlainLayout(layout: LayeredCombinedLayoutMap): layout is LayeredLayoutMap;
export function isPlainLayout(layout: CombinedLayout | CombinedLayoutMap): boolean;
export function isPlainLayout<T extends Layout>(layout: T | unknown): layout is T;
export function isPlainLayout(layout): boolean {
  return _(LayoutType)
    .omit(LayoutType.GROUP_LAYOUT)
    .has(getValue(layout, 'type'));
}
