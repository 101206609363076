import { Effect, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { exportPackage } from './exportPackage';

const effects: Effect[] = [
  takeEvery(ActionTypes.EXPORT, exportPackage),
];

export default effects;
