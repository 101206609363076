import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';
import { isImmutable, removeAll, toJS } from 'utils/immutable';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isRelationUsedSeveralTimes } from 'utils/validator/isRelationStillUsed';
import { getTextComponentIdsToDelete } from '../services/getTextComponentIdsToDelete';

export function deleteLayout(
  layout: Models.LayeredCombinedLayoutMap,
  layouts: Models.LayeredCombinedLayoutsMap,
  relations: Models.LayeredRelationsMap,
  documents: Models.CombinedDocumentsMap,
  layer: Layer,
): {
  layouts: Models.LayeredCombinedLayoutsMap;
  relations: Models.LayeredRelationsMap;
  documents: Models.CombinedDocumentsMap;
};
export function deleteLayout(
  layout: Models.CombinedLayout,
  layouts: Models.CombinedLayouts,
  relations: Models.LayeredRelations,
  documents: Models.CombinedDocuments,
  layer: Layer,
): {
  layouts: Models.CombinedLayouts;
  relations: Models.LayeredRelations;
  documents: Models.CombinedDocuments;
};
export function deleteLayout(
  layout: Models.CombinedLayout | Models.LayeredCombinedLayout,
  layouts: Models.CombinedLayouts | Models.LayeredCombinedLayouts,
  relations: Models.LayeredRelations,
  documents: Models.CombinedDocuments,
  layer: Layer,
): {
  layouts: Models.LayeredCombinedLayouts;
  relations: Models.LayeredRelations;
  documents: Models.CombinedDocuments;
};
export function deleteLayout(
  layout: Models.CombinedLayout | Models.CombinedLayoutMap | Models.LayeredCombinedLayout | Models.LayeredCombinedLayoutMap,
  layouts: Models.CombinedLayouts | Models.CombinedLayoutsMap | Models.LayeredCombinedLayouts | Models.LayeredCombinedLayoutsMap,
  relations: Models.LayeredRelations | Models.LayeredRelationsMap,
  documents: Models.CombinedDocuments | Models.CombinedDocumentsMap,
  layer: Layer,
): {
    layouts: Models.CombinedLayouts | Models.LayeredCombinedLayouts | Models.LayeredCombinedLayoutsMap;
    relations: Models.LayeredRelations | Models.LayeredRelationsMap;
    documents: Models.CombinedDocuments | Models.CombinedDocumentsMap;
  } {
  const result = {
    layouts,
    relations,
    documents,
  } as {
    layouts: Models.CombinedLayouts | Models.LayeredCombinedLayouts | Models.LayeredCombinedLayoutsMap;
    relations: Models.LayeredRelations | Models.LayeredRelationsMap;
    documents: Models.CombinedDocuments | Models.CombinedDocumentsMap;
  };

  const layoutId = getValue(layout as Models.BaseLayoutMap | Models.BaseLayout, 'id');
  const layoutIdsToDelete: string[] = [layoutId];

  if (isGroupLayout(layout)) {
    layoutIdsToDelete.push(...toJS<string[]>(getValue(layout, 'layoutIds')));
  }

  layoutIdsToDelete.forEach(
    (layoutId) => {
      const layout = getValue(result.layouts as Models.LayeredCombinedLayouts, layoutId);

      if (!isPlainLayout(layout)) {
        result.layouts = removeAll(result.layouts as Models.LayeredCombinedLayouts, [layoutId]);

        return;
      }

      const layoutRelations = getFlattenedRelations(layout as Models.LayoutMap | Models.Layout, result.relations);

      const relationIdsToDelete = isImmutable(layoutRelations)
        ? layoutRelations.keySeq().filter(id => !isRelationUsedSeveralTimes(id, result.layouts, result.relations)).toArray()
        : _(layoutRelations).keys().filter(id => !isRelationUsedSeveralTimes(id, result.layouts, result.relations)).value();

      const textComponentIdsToDelete = getTextComponentIdsToDelete(
        layout,
        relationIdsToDelete,
        result.documents,
        result.relations,
        layer,
      );

      result.layouts = removeAll(result.layouts as Models.LayeredCombinedLayouts, [layoutId]);
      result.relations = removeAll(result.relations, relationIdsToDelete);
      result.documents = removeAll(result.documents, textComponentIdsToDelete);
    },
  );

  return result;
}
