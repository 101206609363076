import _ from 'lodash';

import * as Models from 'models';
import { prepareScreens } from 'utils/prepareScreens';

export function prepareSurfacesProcess<T extends Models.GetProjectDataProcessArgs>({ assets }: T): void {
  const {
    surfaces,
    project: { surfaceIds },
    masterScreenData: { screenDefinitions },
  } = assets;

  const screens = prepareScreens(surfaces, _(screenDefinitions).keys().first());
  assets.surfaces = screens;

  if (_.isEmpty(surfaceIds) || !surfaceIds.every(surfaceId => !!screens[surfaceId])) {
    assets.project.surfaceIds = _.keys(screens);
  }
}
