import React from 'react';
import { Route } from 'react-router';
import { withRouter } from 'react-router-dom';

import { AppRoute } from 'const';
import Artboard from 'containers/Artboard';
import ArtboardPreview from 'containers/ArtboardPreview';
import { ArtboardContainerProps } from './models';

const ArtboardContainer: React.FunctionComponent<ArtboardContainerProps> = ({ match }) => {
  return (
    <>
      <Route path={`${match.path}/${AppRoute.PREVIEW}/:screenId`} component={ArtboardPreview}/>
      <Artboard />
    </>
  );
};

export default withRouter(ArtboardContainer);
