import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';

import { Details } from 'components/Details';
import * as Models from 'models';
import { StoryCardDetailsProps } from './models';
import styles from './styles.module.scss';

const StoryCardDetails: React.FunctionComponent<StoryCardDetailsProps> = (props) => {
  const { options, hideModal, modalWindowId } = props;
  const {
    description,
    name,
    number,
    product,
    previewUrls,
    status,
  } = options.toJS() as Models.StoryCardDetailsWindowOptions;
  const productName = _.first(product);

  const images = previewUrls.map(() => React.createRef<HTMLImageElement>());
  const [fullSized, setFullSized] = useState(false);
  const toggleFullSizedState = (): void => setFullSized(fullSized => !fullSized);

  const onClose = (): void => { hideModal(modalWindowId); };

  const subtitle = (
    <div className={styles.metadata}>
      {status && <span className={styles.status}>{ status.toUpperCase() }</span>}
      <span className={styles.number}>{number}</span>
      {productName && <span className={styles.productName}>{productName}</span>}
      {!!description && <div className={styles.description}>{description}</div>}
    </div>
  );

  return (
    <div className={styles.StoryCardDetails}>
      <Details
        title={name}
        subtitle={subtitle}
        onClose={onClose}
      >
        <div className={styles.scrollableWrapper}>
          <div className={styles.pages}>
            {previewUrls.map((url, index) => (
              <div className={styles.page} key={url}>
                <img
                  ref={images[index]}
                  className={classNames(styles.image, { [styles.fullSize]: fullSized })}
                  src={url}
                  onClick={toggleFullSizedState}
                />
              </div>
            ))}
          </div>
        </div>
      </Details>
    </div>
  );
};

export default StoryCardDetails;
