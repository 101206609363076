import { EditorState } from 'draft-js';
import _ from 'lodash';

import { removeEntities } from './entity';
import { removeAllInlineStylesForSelection } from './style';

export const clearFormatting: (editorState: EditorState) => EditorState = _.flow<[EditorState], EditorState, EditorState>(
  removeAllInlineStylesForSelection,
  removeEntities,
);
