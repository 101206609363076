import * as Models from 'models';

export function createBrandDefinition({
  colorsCategories = [],
  colors = [],
  fontsCategories = [],
  fonts = [],
  brandStyles = {},
  brandDefinitionChecksum = null,
  brandStylesChecksum = null,
  UIFontFaces = [],
} = {} as Partial<Models.OnlineBrandDefinition>): Models.OnlineBrandDefinition {
  return {
    colorsCategories,
    colors,
    fontsCategories,
    fonts,
    brandStyles,
    brandDefinitionChecksum,
    brandStylesChecksum,
    UIFontFaces,
  };
}
