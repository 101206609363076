import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CitationItem from 'components/CitationItem';
import { ICitationItemSelectors } from 'components/CitationItem/models';
import { selectedComponentItem } from 'containers/App/selectors';
import { selectCitationCount } from 'containers/ProjectPanel/selectors';

const mapStateToProps = (state, props) => {
  const id = props.citation.get('id');

  return createStructuredSelector({
    citationCount: selectCitationCount(id),
    isSelected: selectedComponentItem(id),
  });
};

export default connect<ICitationItemSelectors>(
  mapStateToProps,
)(CitationItem);
