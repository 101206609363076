import React from 'react';
import { CallToActionMap } from 'models';

type Results = {
  link: string;
  toggleLink: (value: string) => void;
};

export const useLink = (asset: CallToActionMap): Results => {
  const [link, setLink] = React.useState(asset.get('link'));

  React.useEffect(
    () => setLink(asset.get('link')),
    [asset],
  );

  return { link, toggleLink: setLink };
};
