import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const List = (): JSX.Element => {
  const pathId = guid();
  const maskId = guid();

  return (
    <svg viewBox="0 0 18 18">
      <defs>
        <path d="M16,2 L16,16 L2,16 L2,2 L16,2 L16,2 Z M17.1,0 L0.9,0 C0.4,0 0,0.4 0,0.9 L0,17.1 C0,17.5 0.4,18 0.9,18 L17.1,18 C17.5,18 18,17.5 18,17.1 L18,0.9 C18,0.4 17.5,0 17.1,0 L17.1,0 Z M8,4 L14,4 L14,6 L8,6 L8,4 Z M8,8 L14,8 L14,10 L8,10 L8,8 Z M8,12 L14,12 L14,14 L8,14 L8,12 Z M4,4 L6,4 L6,6 L4,6 L4,4 Z M4,8 L6,8 L6,10 L4,10 L4,8 Z M4,12 L6,12 L6,14 L4,14 L4,12 Z" id={pathId} />
      </defs>
      <g id="02-SSI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3" transform="translate(-1345.000000, -637.000000)">
          <g id="Group-3" transform="translate(970.000000, 280.000000)">
            <g id="Group-5" transform="translate(365.000000, 341.000000)">
              <g id="Icons/pack/delete-1-Icons/pack/ssi" transform="translate(4.000000, 10.000000)">
                <g id="Icon-Color" transform="translate(6.000000, 6.000000)">
                  <mask id={maskId} fill="white">
                    <use xlinkHref={`#${pathId}`} />
                  </mask>
                  <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`} />
                  <g id="Icon-Color-Colors/White" mask={`url(#${maskId})`} fill="#FFFFFF">
                    <g transform="translate(-4.000000, -4.000000)" id="White">
                      <rect x="0" y="0" width="26" height="26" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g >
    </svg >
  );
};

export default List;
