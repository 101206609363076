import React, { useMemo } from 'react';
import { SectionStylesSetters } from 'components/Section/useStyles';
import BackgroundImage from 'components/Toolbar/Controls/BackgroundImage';
import BackgroundColor from 'components/Toolbar/Controls/FillColor';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import Toolbar from 'containers/Toolbar/BaseToolbar';
import { BrandColorsList, SectionStylesMap } from 'models';
import { colorFromSource } from 'utils/converters';
import { brandColorGradientFromSource } from 'utils/converters/colorGradient';
import { intlGet } from 'utils/intlGet';
import css from './styles.module.scss';

type Props = {
  name: string;
  styles: SectionStylesMap;
  stylesSetters: SectionStylesSetters;
  colors: BrandColorsList;
};

const Section: React.FunctionComponent<Props> = (props) => {
  const { name, styles, stylesSetters, colors } = props;

  const backgroundColor = useMemo(
    () => colorFromSource(colors, styles.get('backgroundColor'), styles.get('backgroundColorTint')),
    [colors, styles],
  );
  const backgroundGradient = useMemo(
    () => brandColorGradientFromSource(colors, styles.get('backgroundGradient')),
    [colors, styles],
  );

  return (
    <Toolbar title={intlGet('EditorToolbar.Asset', 'Section')}>
      <ControlGroup>
        <div className={css.SectionName}>{name}</div>
      </ControlGroup>
      <ControlGroup>
        <BackgroundColor
          activeColor={backgroundColor}
          toggleColor={stylesSetters.backgroundColor}
          gradient={backgroundGradient}
          toggleGradient={stylesSetters.backgroundGradient}
          opacity={styles.get('backgroundColorOpacity')}
          toggleOpacity={stylesSetters.backgroundColorOpacity}
        />
        {stylesSetters.backgroundImage && <BackgroundImage
          backgroundImage={styles.get('backgroundImage')}
          toggleBackgroundImage={stylesSetters.backgroundImage}
        />}
      </ControlGroup>
    </Toolbar>
  );
};

export default Section;
