import { registredSymbolToSup } from 'modules/Abbreviations/utils/abbreviationsListToHTML';
import React from 'react';
import css from '../styles.module.scss';

type Props = {
  text: string;
};

const SelectedText: React.FunctionComponent<Props> = (props) => {
  const { text } = props;
  const updatedTerm = registredSymbolToSup(text).replace(/\s\s+/g, ' ');

  return (
    <div className={css.term} dangerouslySetInnerHTML={{ __html: updatedTerm }} />
  );
};

export default SelectedText;
