import { TextAbbreviationJSON } from 'models';
import { cleanUpText } from 'utils/cleanUpText';

export function registredSymbolToSup(term: string): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return term.replaceAll(/®/g, '<sup>®</sup>');
}

export function abbreviationsListToHTML(
  list: TextAbbreviationJSON[],
): string {
  if (!list.length) {
    return '';
  }

  return list
    .map((item) => {
      const term = registredSymbolToSup(cleanUpText(item?.term));
      const definition = registredSymbolToSup(cleanUpText(item?.definition));

      return `<span>${term} = ${definition}</span>`;
    })
    .join('; ')
    .concat('.');
}
