import React from 'react';

const CallToActionMedium = (): JSX.Element => (
  <svg viewBox="0 0 96 96">
    <defs>
      <rect id="path-call-to-action-medium" x="7" y="35" width="82" height="23" rx="3" />
      <filter id="filter-call-to-action-medium" x="-8.5%" y="-21.7%" width="117.1%" height="160.9%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.230414117 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-a-citation-10" transform="translate(-1207.000000, -592.000000)">
        <g id="Group-5" transform="translate(1207.000000, 592.000000)">
          <g id="Rectangle-Copy-7">
            <use fill="black" fillOpacity="1" filter="url(#filter-call-to-action-medium)" href="#path-call-to-action-medium" />
            <rect stroke="#D8D8D8" strokeWidth="0.5" fill="#FFFFFF" fillRule="evenodd" x="7.25" y="35.25" width="81.5" height="22.5" rx="3" />
          </g>
          <text id="Button" fontFamily="Roboto-Medium, Roboto" fontSize="7" fontWeight="400" letterSpacing="1.05" fill="#A4A4A4">
            <tspan x="35" y="48">Button</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default CallToActionMedium;
