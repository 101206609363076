import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isRowRelation(
  entity: Models.RowRelation | Models.ColumnRelation | Models.RegularRelation | Models.LayeredRegularRelation,
): entity is Models.RowRelation;
export function isRowRelation(
  entity: Models.RowRelationMap | Models.ColumnRelationMap | Models.RegularRelationMap | Models.LayeredRegularRelationMap,
): entity is Models.RowRelationMap;
export function isRowRelation(
  entity: Models.RowRelation | unknown,
): entity is Models.RowRelation;
export function isRowRelation(
  entity: Models.RowRelationMap | unknown,
): entity is Models.RowRelationMap;
export function isRowRelation(entity): boolean {
  if (!entity) {
    return false;
  }

  return getValue(entity, 'entityType') === EntityType.ROW;
}
