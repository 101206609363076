import Immutable, { List } from 'immutable';

import { UNDO_STACK_LIMIT_GLOBAL } from 'const/UndoRedoControl';

const prepareStateToSave = (state) => {
  return state.withMutations((tempState) => {
    tempState
      .setIn(['project', 'isOpenToolbar'], false)
      .setIn(['project', 'isLocalSaveInProgress'], false)
      .setIn(['project', 'isUploadAvailable'], false)
      .setIn(['app', 'areImagesBeingDownloaded'], false)
      .setIn(['app', 'dragState'], Immutable.fromJS({ type: null, subtype: null }))
      .delete('future')
      .delete('past');
  });
};

const addItem = (state, key) => {
  const updateState = prepareStateToSave(state);
  const keyValue = state.get(key);

  return keyValue.push(updateState);
};

export const addFutureItem = state => addItem(state, 'future');

export const addPastItem = (state) => {
  const past = addItem(state, 'past');

  return validatePastItems(past);
};

const validatePastItems = <T>(past: List<T>): List<T> => {
  return past.size >= UNDO_STACK_LIMIT_GLOBAL
    ? past.shift()
    : past;
};

export const validateEventKey = (event: KeyboardEvent): boolean => event.code === 'KeyZ' || event.code === 'KeyY';
