import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import IframePlaceholder from 'components/IframePlaceholder';
import InterstitialPage from 'components/InterstitialPage';
import NoMatch from 'components/NoMatch';
import UnsupprotedBrowser from 'components/UnsupprotedBrowser';
import { AppRoute, TOOLTIP_ELEMENT_ID } from 'const';
import ModalManager from 'containers/ModalManager';
import ProjectScreen from 'containers/ProjectScreen';
import WelcomeScreen from 'containers/WelcomeScreen';
import styles from './styles.module.scss';

const BaseRoutes: React.FC<RouteComponentProps> = (props) => {
  const { path } = props.match;
  const baseRoutePath = path === '/' ? '' : path;

  return (
    <Switch>
      <Route exact={true} path={`${baseRoutePath}/`} component={WelcomeScreen}/>
      <Route path={`${baseRoutePath}/${AppRoute.PROJECT}/:projectId`} component={ProjectScreen}/>
      <Route path={`${baseRoutePath}/${AppRoute.INTERSTITIAL}`} component={InterstitialPage}/>
      <Route path={`${baseRoutePath}/${AppRoute.IFRAME_PLACEHOLDER}`} component={IframePlaceholder}/>
      <Route path={`${baseRoutePath}/${AppRoute.UNSUPPORTED_BROWSER}`} component={UnsupprotedBrowser}/>
      <Route path={`${baseRoutePath}/${AppRoute.BLUE_ENV_BASENAME}`} component={BaseRoutes}/>
      <Route component={NoMatch}/>
    </Switch>
  );
};

const routes = (
  <>
    <BrowserRouter>
      <Route component={BaseRoutes} />
      <ModalManager />
      <div className={styles.tooltip} id={TOOLTIP_ELEMENT_ID} />
    </BrowserRouter>
  </>
);

export default routes;
