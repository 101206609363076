import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { updateRelations } from 'containers/Relations/actions';
import * as relationsSelectors from 'containers/Relations/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { resetRelationsByDocumentId } from 'services/resetRelations';
import { updateInternalImageInfo } from '../actions';
import { Action } from '../models';

export function* updateInternalImageInfoRequest(action: Action.IUpdateInternalImageInfoRequest) {
  try {
    const { id, internalImageInfo } = action.payload;

    if (!_.isEmpty(internalImageInfo)) {
      yield put(updateInternalImageInfo(id, internalImageInfo));

      return;
    }

    const relations = (yield select(relationsSelectors.relations)).toJS() as Models.Relations;
    const updatedRelations: ReturnTypeSaga<typeof resetRelationsByDocumentId> = yield call(resetRelationsByDocumentId, id, relations);

    yield put(batchActions([
      updateInternalImageInfo(id, internalImageInfo),
      ..._.isEmpty(updatedRelations) ? [] : [updateRelations(updatedRelations)],
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.updateInternalImageInfoRequest', 'DownloadLowRendition');
  }
}
