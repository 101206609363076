import { ProjectType, ProjectsConfig } from 'const';
import * as Models from 'models';
import { getCSSBackgroundColorLayer } from 'utils/styles/getBackground';

export function getBackgroundColor(
  projectType: ProjectType,
  colors: Models.BrandColorsList,
  color: string | null,
  tint: number,
  opacity?: number,
  defaultColor?: string,
): string | null {
  const { allowBackgroundOpacity } = ProjectsConfig[projectType];

  return getCSSBackgroundColorLayer(
    colors,
    color || undefined,
    tint,
    allowBackgroundOpacity ? opacity : undefined,
    defaultColor,
  ) || null;
}
