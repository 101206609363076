import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { updateSSIHotspotPosition } from 'containers/App/actions';
import { ssiHotspotPosition } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboardId } from 'containers/Artboards/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createSSI } from 'factories/ssiFactory';
import { Artboard, IAction } from 'models';
import { handleSagaError } from 'services/handleError';
import * as Models from '../models';

export function* addSSI(action: Models.Action.IAddSSI) {
  try {
    const actions: IAction[] = [saveAppState()];
    const { section: sectionId } = action.payload;
    const ssiPosition: ReturnTypeSaga<typeof ssiHotspotPosition> = yield select(ssiHotspotPosition);
    const artboardId: ReturnTypeSaga<typeof activeArtboardId> = yield select(activeArtboardId);
    const sections: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);

    const section = sections.get(sectionId);
    const ssi: ReturnTypeSaga<typeof createSSI> = yield call(createSSI, {
      position: ssiPosition,
      section: section.get('name'),
    });

    actions.push(
      updateArtboard({ id: artboardId, ssi } as Artboard),
      updateSSIHotspotPosition(null),
    );

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'Artboards.addSSI', 'AddSSI');
  }
}
