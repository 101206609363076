import Immutable from 'immutable';
import * as Models from 'models';
import { ActionTypes } from './constants';
import { State } from './models';

export const initialState: State.IState = {
  abbreviationList: Immutable.fromJS({}),
};

const reducer: Models.Reducer<State.IState> =
  (state = initialState, action) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (action.type) {
      case ActionTypes.SET_TEXT_ABBREVIATIONS:
        return {
          ...state,
          abbreviationList: Immutable.fromJS(action.payload),
        };
      default:
        return state;
    }
  };

export default reducer;
