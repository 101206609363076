import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_SCREEN_READY_FOR_OUTPUT: null,
    RESET_SCREENS_READY_FOR_OUTPUT: null,
    DELETE_CELL_HEIGHTS: null,
    GENERATE_ARTBOARD_PREVIEW: null,
    RESET_PREVIEWS_HTML: null,
    SET_CELL_HEIGHT: null,
    SET_PREVIEW_HTML: null,
    SET_SCREEN_STATUS: null,
    UPDATE_CELLS_HEIGHT: null,
  },
  'ArtboardPreview',
);
