import { EditorState } from 'draft-js';
import _ from 'lodash';
import { TextDetailsEditor } from 'modules/draftjs/components/TextDetailsEditor';
import { compositeDecorator } from 'modules/draftjs/decorators';
import React from 'react';
import { Details } from 'components/Details';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { addReferenceCitationsOrder } from 'utils/addReferenceCitationsOrder';
import * as editorUtils from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import * as Models from './models';
import styles from './styles.module.scss';

class TextComponentDetails extends React.PureComponent<Models.ITextComponentDetailsProps> {

  private onClose = (): void => {
    const { hideModal, modalWindowId } = this.props;

    hideModal(modalWindowId);
  };

  render() {
    const { selectedTextComponent, referencesNumbersOrder } = this.props;

    const editorState = _.flow(
      editorUtils.convertTextComponentToRawEditorState,
      editorState => addReferenceCitationsOrder(editorState, referencesNumbersOrder),
      editorState => EditorState.set(editorState, { decorator: compositeDecorator }),
    )(selectedTextComponent);

    return (
      <div className={styles.TextComponentDetails}>
        <Details
          onClose={this.onClose}
          title={intlGet('TextComponentDetails.Value', 'Title')}
        >
          <div className={styles.textInfo}>
            <Icon type={IconType.TEXT} size="sm-md" color="primary" />
            <div className={styles.htmlText}>
              <TextDetailsEditor editorState={editorState} />
            </div>
          </div>
        </Details>
      </div>
    );
  }
}
export default TextComponentDetails;
