import Immutable from 'immutable';

import { Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

const initialState = Immutable.Map() as Models.State.IState;

const reducer: Reducer<Models.State.IState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD: return addSections(state, action);
    case ActionTypes.DELETE: return deleteSections(state, action);
    case ActionTypes.SET: return setSections(state, action);
    case ActionTypes.UPDATE: return updateSection(state, action);
    default: return state;
  }
};

const addSections: Reducer<Models.State.IState, Models.Action.AddSections> = (state, action) => state.merge(action.payload.sections);

const deleteSections: Reducer<Models.State.IState, Models.Action.DeleteSections> = (state, action) => {
  const { sectionIds } = action.payload;

  return sectionIds.reduce((state, sectionId) => state.delete(sectionId), state);
};

const setSections: Reducer<Models.State.IState, Models.Action.SetSections> = (state, action) => Immutable.fromJS(action.payload.sections);

const updateSection: Reducer<Models.State.IState, Models.Action.UpdateSection> = (state, action) => {
  const { section } = action.payload;

  return state.set(section.get('id'), section);
};

export default reducer;
