import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD: null,
    DELETE: null,
    UPDATE: null,
    SET: null,
  },
  'Sections',
);
