import React from 'react';

import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

const IframePlaceholder: React.FunctionComponent = () => (
  <div className={styles.IframePlaceholder}>
    <div className={styles.text}>
      <div>{intlGet('IframePlaceholder', 'AssemblerIsOpening')}</div>
      <div>{intlGet('IframePlaceholder', 'ClickCloseButton')}</div>
    </div>
    <div className={styles.hint}>{intlGet('IframePlaceholder', 'MakeSurePopUpIsAllowed')}</div>
  </div>
);

export default IframePlaceholder;
