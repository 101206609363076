import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { setLastEditedLayoutId } from 'containers/App/actions';
import { setArtboards } from 'containers/Artboards/actions';
import * as artboardsSelectors from 'containers/Artboards/selectors';
import { setDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { setLayouts } from 'containers/Layouts/actions';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import { activeLayer, projectType as projectTypeSelector } from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { screenDefinitions as screenDefinitionsSelector } from 'containers/ScreenDefinitions/selectors';
import { setSections } from 'containers/Sections/actions';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import * as surfacesSelectors from 'containers/Surfaces/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { ensureLayoutOnSections } from 'utils/layouts/ensureLayoutOnSections';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { removeLayoutId } from 'utils/layouts/removeLayoutId';
import { updateGroupLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import * as LayoutsModels from '../models';
import { deleteLayout } from '../services/deleteLayout';

const getNotificationText = (
  layout: Models.CombinedLayout,
  documents: Models.CombinedDocuments | Models.CombinedDocumentsMap,
): { title: string; message: string; successMessage: string } => {
  switch (true) {
    case isGroupLayout(layout): return {
      title: intlGet('ConfirmationWindow.Title', 'DeleteGroupReusableLayout'),
      message: intlGet('ConfirmationWindow.Message', 'RemoveGroupReusableLayoutFromArtboard'),
      successMessage: intlGet('Notification.Success', 'GroupReusableLayoutSuccessfullyDeleted'),
    };
    case isReusableLayout(layout, documents): return {
      title: intlGet('ConfirmationWindow.Title', 'DeleteReusableLayout'),
      message: intlGet('ConfirmationWindow.Message', 'RemoveReusableLayoutFromArtboard'),
      successMessage: intlGet('Notification.Success', 'ReusableLayoutSuccessfullyDeleted'),
    };
    default: return {
      title: intlGet('ConfirmationWindow.Title', 'DeleteLayout'),
      message: intlGet('ConfirmationWindow.Message', 'RemoveLayoutAndContent'),
      successMessage: intlGet('Notification.Success', 'LayoutSuccessfullyDeleted'),
    };
  }
};

// TODO: DON'T convert entities to JS, work with immutable ones
export function* deleteLayoutRequest(action: LayoutsModels.Action.IDeleteLayoutRequest) {
  try {
    const { layoutId, needToShowNotification } = action.payload;
    let layouts = (yield select(layeredLayoutsSelector)).toJS() as Models.CombinedLayouts;
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);
    const groupLayout = _.find(layouts, layout => isGroupLayout(layout) && layout.layoutIds.includes(layoutId)) as Models.GroupLayout;
    const layout = layouts[layoutId];
    const { title, message, successMessage } = getNotificationText(layout, documents);

    const accepted: ReturnTypeSaga<typeof Notifications.showConfirmation> = !needToShowNotification || (yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title,
        message,
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Delete'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'Cancel'),
      },
    ));

    if (!accepted) {
      return;
    }

    const artboard: ReturnTypeSaga<typeof artboardsSelectors.activeArtboard> = yield select(artboardsSelectors.activeArtboard);
    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const relations = (yield select(relationsSelector)).toJS() as Models.LayeredRelations;
    const screenDefinitions = (yield select(screenDefinitionsSelector)).toJS() as Models.MasterScreen.ScreenDefinitions;
    const sections = (yield select(sectionsSelector)).toJS() as Models.Sections;
    const artboards = (yield select(artboardsSelectors.artboards)).toJS() as Models.Artboards;
    const screens = (yield select(surfacesSelectors.surfaces)).toJS() as Models.Screens;

    if (groupLayout) {
      const updatedGroupLayout = removeLayoutId(groupLayout, layoutId);
      layouts = updateGroupLayoutInstances(updatedGroupLayout, layouts);
    } else {
      const updatedArtboard = removeLayoutId(artboard, layoutId);
      _.assign(artboards, { [updatedArtboard.get('id')]: updatedArtboard.toJS() });
    }

    yield call(
      deleteLayout,
      layout,
      layouts,
      relations,
      documents,
      layer,
    );

    yield call<typeof ensureLayoutOnSections>(
      ensureLayoutOnSections,
      {
        screens,
        artboards,
        layouts,
        sections,
        screenDefinitions,
        relations,
        activeLayer: layer,
        projectType,
      },
    );

    yield put(batchActions([
      saveAppState(),
      setArtboards(artboards),
      setLayouts(layouts),
      setRelations(relations),
      setSections(sections),
      setDocuments(documents),
      ...groupLayout ? [setLastEditedLayoutId(groupLayout.id)] : [],
    ]));
    yield call([Notifications, Notifications.success], successMessage);
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.deleteLayoutRequest', 'DeleteLayout');
  }
}
