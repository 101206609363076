import React from 'react';

const Collapse = (): JSX.Element => (
  <svg viewBox="0 0 25 25">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <rect width="25" height="25" style={{ fill: 'none' }} />
          <path d="M25,12.5c0,1.15-.1,2.08-1.25,2.08H1.25C.1,14.58,0,13.65,0,12.5s.1-2.08,1.25-2.08h22.5C24.9,10.42,25,11.35,25,12.5Z"/>
        </g>
      </g>
    </g>
  </svg>
);

export default Collapse;
