import { List } from 'immutable';
import _ from 'lodash';

import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';

export function isRelationUsedSeveralTimes(
  relationId: string,
  layouts: Models.CombinedLayouts | Models.CombinedLayoutsMap | Models.LayeredCombinedLayouts | Models.LayeredCombinedLayoutsMap,
  relations: Models.Relations | Models.RelationsMap | Models.LayeredRelations | Models.LayeredRelationsMap,
): boolean {
  let layoutsRelationIds: string[];

  if (
    isImmutable(layouts as Models.LayeredCombinedLayouts | Models.LayeredCombinedLayoutsMap)
    && isImmutable(relations as Models.LayeredRelations | Models.LayeredRelationsMap)
  ) {
    layoutsRelationIds = (layouts as Models.LayeredCombinedLayoutsMap)
      .toList()
      .flatMap(layout => isPlainLayout(layout)
        ? getFlattenedRelations(layout, relations as Models.LayeredRelationsMap).keySeq().toList()
        : List<string>(),
      )
      .toArray();
  } else {
    layoutsRelationIds = _.flatMapDeep(layouts as Models.LayeredCombinedLayouts, layout => (
      isPlainLayout(layout) ? _.keys(getFlattenedRelations(layout, relations as Models.LayeredRelations)) : []
    ));
  }

  const usagesById = _.countBy(layoutsRelationIds);

  return usagesById[relationId] > 1;
}
