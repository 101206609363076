import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArtboardLayoutContainer from 'components/ArtboardLayoutContainer';
import { ArtboardLayoutOwnProps, ArtboardLayoutSelectors } from 'components/ArtboardLayoutContainer/models';
import { isLayoutBeingUploaded } from 'containers/Layouts/selectors';
import { projectType } from 'containers/Project/selectors';

const mapStateToProps = (_, props) => {
  const { groupLayoutId } = props;

  return createStructuredSelector({
    isGroupLayoutBeingUploaded: isLayoutBeingUploaded(groupLayoutId),
    projectType,
  });
};

export default connect<ArtboardLayoutSelectors, null, ArtboardLayoutOwnProps>(
  mapStateToProps,
)(ArtboardLayoutContainer);
