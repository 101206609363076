import _ from 'lodash';

import { PROJECT_KEY_REGEXP } from './constants';
import { ProjectKeysMap } from './models';

export const getProjectKeysMap = (keys: string[]): ProjectKeysMap => _(keys)
  .map(key => PROJECT_KEY_REGEXP.exec(key))
  .compact()
  .keyBy(_.first)
  .mapValues(([, timestamp, documentId]) => ({ timestamp, documentId }))
  .value();
