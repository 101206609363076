import Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import Style from 'components/Toolbar/Shared/Style';
import * as Models from 'models';
import { getFontSizeFromBrandStyle } from 'utils/brandStyles';
import { BrandStyleProps } from './models';
import styles from './styles.module.scss';

const BrandStyle: React.FunctionComponent<BrandStyleProps> = (props) => {
  const { activeBrandStyleId, brandStyles, toggleBrandStyle, colors, fonts, sectionStyles } = props;

  const sectionBrandStyles = sectionStyles && sectionStyles.size > 0
    ? sectionStyles.reduce(
      (stylesList, id) => brandStyles.get(id) ? stylesList.push(brandStyles.get(id)) : stylesList,
      Immutable.List<Models.BrandStyleMap>(),
    )
    : brandStyles.toList();

  const getFontSize = _.memoize(getFontSizeFromBrandStyle);
  const sortedSectionBrandStyles = sectionBrandStyles.sort((style1, style2) => {
    return getFontSize(style2) - getFontSize(style1);
  });

  return (
    <Dropdown className={styles.StyleDropdown}>
      <div className={styles.StyleDropdownWrapper}>
        {
          sortedSectionBrandStyles.map((style) => {
            const styleId = style.get('id');

            return (
              <Style
                key={styleId}
                active={styleId === activeBrandStyleId}
                style={style}
                toggleBrandStyle={toggleBrandStyle}
                colors={colors}
                fonts={fonts}
              />
            );
          })
        }
      </div>
    </Dropdown>
  );
};

export default BrandStyle;
