import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import App from 'App';
import { activeLayer, projectType } from 'containers/Project/selectors';
import { AppActionsProps, AppOwnProps, AppSelectorsProps } from './models';

const mapStateToProps = createStructuredSelector({
  layer: activeLayer,
  projectType,
});

const mapDispatchToProps = null;

export default connect<AppSelectorsProps, AppActionsProps, AppOwnProps>(mapStateToProps, mapDispatchToProps)(App);
