import _ from 'lodash';

import { Layer, ProjectType } from 'const';
import * as Models from 'models';
import { createLayoutAndRelations } from 'utils/createLayoutAndRelations';
import { getScreenWidth } from 'utils/getScreenWidth';
import { getSectionWidth } from 'utils/getSectionsWidth';

interface EnsureLayoutOnSectionsArg {
  activeLayer: Layer;
  artboards: Models.Artboards;
  layouts: Models.LayeredCombinedLayouts | Models.CombinedLayouts;
  projectType: ProjectType;
  relations: Models.LayeredRelations;
  screenDefinitions: Models.MasterScreen.ScreenDefinitions;
  screens: Models.Screens;
  sections: Models.Sections;
}

/**
 * Checks that at least one layout exists on each section.
 * If there are no layouts on section a default layout will be created on it.
 */
export function ensureLayoutOnSections<T extends EnsureLayoutOnSectionsArg>(arg: T): void {
  const {
    activeLayer,
    artboards,
    layouts,
    projectType,
    relations,
    screenDefinitions,
    screens,
    sections,
  } = arg;

  _.forEach(screens, ({ artboardId, screenDefinitionId }) => {
    const artboard = artboards[artboardId];
    const { layoutIds: artboardLayoutIds, ssi } = artboard;
    const artboardLayouts = _.pick(layouts, artboardLayoutIds);
    const screenDefinition = screenDefinitions[screenDefinitionId];
    const { sections: screenDefinitionSections } = screenDefinition;

    const artboardLayoutsBySectionName = _(artboardLayouts)
      .groupBy(({ section }) => section)
      .mapKeys((_i, sectionId) => sections[sectionId].name)
      .value();

    _.forEach(screenDefinitionSections, (screenDefinitionSection) => {
      const { name } = screenDefinitionSection;

      if (_.isEmpty(artboardLayoutsBySectionName[name])) {
        // section is empty, find section instance and assign layout to it
        // TODO: create selector to filter empty sections by artboards
        const emptySections = _.omit(sections, _.map(layouts as Models.CombinedLayouts, 'section')) as Models.Sections;
        const emptySection = _.find(emptySections, section => section.screenDefinition === screenDefinitionId && section.name === name);
        const layoutWidth = getSectionWidth(emptySection.name, getScreenWidth(screenDefinition, artboard, projectType), ssi);
        const { layout, relations: newRelations } = createLayoutAndRelations(
          { section: emptySection.id },
          { layoutWidth, layer: activeLayer, createLayeredRelations: true },
        );

        layouts[layout.id] = layout;
        _.assign(relations, newRelations);
        artboardLayoutIds.push(layout.id);
      }
    });
  });
}
