import _ from 'lodash';

import * as Models from 'models';
import { colorExistsInBrandColors, getCharacterStyle } from 'utils/brandStyles';
import * as inlineStylesUtils from 'utils/inlineStyles';

interface MatchColor {
  areSeveralColorsApplied: boolean;
  colorStyle: string;
}

interface MatchFontFamilyAndFontStyle {
  areSeveralFontFamiliesApplied: boolean;
  fontFamilyStyle: string;
}

export function matchColor(
  color: Models.StyleRanges,
  colors: Models.BrandColorsList,
  brandColorStyle?: string,
  isBullet = false,
): MatchColor {
  let areSeveralColorsApplied = false;
  let brandColorStyleToMatch = brandColorStyle;
  let colorStyle: string;

  if (!brandColorStyleToMatch) {
    brandColorStyleToMatch = isBullet
      ? inlineStylesUtils.getBulletColorStyle()
      : inlineStylesUtils.getFontColorStyle();
  }

  color.forEach((styleRange, index) => {
    const { style: currentColorStyle } = styleRange;

    if (currentColorStyle !== brandColorStyleToMatch) {
      const currentColor = isBullet
        ? inlineStylesUtils.getBulletColorFromStyle(currentColorStyle)
        : inlineStylesUtils.getFontColorFromStyle(currentColorStyle);

      const styleExists = colorExistsInBrandColors(currentColor, colors);

      if (!styleExists) {
        styleRange.style = brandColorStyleToMatch;
      }
    }

    // save first font color style for further detecting whether multiple font colors applied
    if (index === 0) {
      colorStyle = styleRange.style;
    } else if (!areSeveralColorsApplied && styleRange.style !== colorStyle) {
      areSeveralColorsApplied = true;
    }
  });

  return {
    areSeveralColorsApplied,
    colorStyle,
  };
}

export function matchFontFamilyAndFontStyle(
  fontFamily: Models.StyleRanges,
  fontStyle: Models.StyleRanges,
  fonts: Models.BrandFontsList,
  textRelationStyles: Models.TextRelationStyles,
  brandFontFamilyStyle: string = inlineStylesUtils.getFontFamilyStyle(),
  brandFontStyleStyle: string = null,
): MatchFontFamilyAndFontStyle {
  let fontFamilyStyle: string;
  let areSeveralFontFamiliesApplied = false;

  const newFontStyle = [] as Models.StyleRanges;
  fontFamily.forEach((styleRange, index) => {
    const { style: currentFontFamilyStyle } = styleRange;

    // in order to find appropriate font style, all properties except 'style' should be same (e.g., blockKey, offset, length)
    const fontStyleRange = _.omit(styleRange, 'style');
    // style range with font style for currentFontFamilyStyle
    const currentFontStyleObject = _.find(fontStyle, styleRange => _.isEqual(fontStyleRange, _.omit(styleRange, 'style')));
    const currentFontStyleStyle = currentFontStyleObject && currentFontStyleObject.style;
    const currentFontStyle = currentFontStyleStyle && inlineStylesUtils.getFontStyleFromStyle(currentFontStyleStyle);
    const currentFontFamily = inlineStylesUtils.getFontFamilyFromStyle(currentFontFamilyStyle);
    const currentFont = fonts.find(font => font.get('name') === currentFontFamily);
    const currentCharacterStyles = currentFont && currentFont.get('characterStyles');
    const currentFontStyleExists = currentFontStyle && currentCharacterStyles
      && currentCharacterStyles.some(characterStyle => characterStyle.get('name') === currentFontStyle);
    const characterStyleFallback = !currentFontStyleExists && getCharacterStyle(currentCharacterStyles);

    if (currentFont) {
      const isBrandFontApplied = currentFontFamilyStyle === brandFontFamilyStyle;

      if (isBrandFontApplied && currentCharacterStyles && currentCharacterStyles.size > 0) {
        if (currentFontStyleExists) {
          newFontStyle.push(currentFontStyleObject);
        } else {
          if (brandFontStyleStyle) {
            newFontStyle.push({
              ...fontStyleRange,
              style: brandFontStyleStyle,
            });
          } else if (characterStyleFallback) {
            newFontStyle.push({
              ...fontStyleRange,
              style: inlineStylesUtils.getFontStyleStyle(characterStyleFallback.get('name')),
            });
          }
        }
      }

      if (!isBrandFontApplied) {
        if (currentFontStyleExists) {
          newFontStyle.push(currentFontStyleObject);
        } else if (characterStyleFallback) {
          newFontStyle.push({
            ...fontStyleRange,
            style: inlineStylesUtils.getFontStyleStyle(characterStyleFallback.get('name')),
          });
        }
      }
    } else {
      styleRange.style = brandFontFamilyStyle;

      if (brandFontStyleStyle) {
        newFontStyle.push({
          ...fontStyleRange,
          style: brandFontStyleStyle,
        });
      }
    }

    // save first font family style for further detecting whether multiple font families applied
    if (index === 0) {
      fontFamilyStyle = styleRange.style;
    } else if (!areSeveralFontFamiliesApplied && styleRange.style !== fontFamilyStyle) {
      areSeveralFontFamiliesApplied = true;
    }
  });
  textRelationStyles.fontStyle = newFontStyle;

  return {
    areSeveralFontFamiliesApplied,
    fontFamilyStyle,
  };
}

/**
 * DO NOT need to save font size during import
 * Just replace with brand font size if it's available
 */
export function matchFontSize(
  fontSize: Models.StyleRanges,
  brandFontSizeStyle: string = inlineStylesUtils.getFontSizeStyle(),
): void {
  fontSize.forEach((styleRange) => {
    styleRange.style = brandFontSizeStyle;
  });
}

/**
 * Returns available background color name
 */
export function matchBackgroundColor(
  backgroundColorName: string,
  colors: Models.BrandColorsList,
  brandBackgroundColorName: string = null,
): string {
  if (backgroundColorName && !colorExistsInBrandColors(backgroundColorName, colors)) {
    return brandBackgroundColorName;
  }

  return backgroundColorName;
}
