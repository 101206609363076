import React from 'react';
/* eslint-disable max-len */

const Plus = (): JSX.Element => (
  <svg viewBox="0 0 25 25">
    <path d="M25,12.5c0,1.15-.1,2.08-1.25,2.08H14.58v9.16c0,1.15-.93,1.25-2.08,1.25s-2.08-.1-2.08-1.25V14.58H1.25C.1,14.58,0,13.65,0,12.5s.1-2.08,1.25-2.08h9.16V1.25C10.42.1,11.35,0,12.5,0s2.08.1,2.08,1.25v9.16h9.16C24.9,10.42,25,11.35,25,12.5Z" />
  </svg>
);

export default Plus;
