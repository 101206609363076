import { AssetAlignmentType } from 'const';
import { ResizeComponentProps, ResizeComponentResponsiveProps } from './models';

export function areResponsiveProps(props: ResizeComponentProps): props is ResizeComponentResponsiveProps {
  return props.isResponsive;
}

export function calcTopLeftDelta(
  x: number,
  y: number,
  horizontalAlignment: AssetAlignmentType,
  isComponentWide: boolean,
): number {
  let delta: number;

  if (horizontalAlignment === AssetAlignmentType.HORIZONTAL_CENTER) {
    delta = x;
  } else {
    if (x <= 0 && y <= 0) {
      if (isComponentWide) {
        delta = Math.min(x, y);
      } else {
        delta = Math.max(x, y);
      }
    } else {
      if (isComponentWide) {
        delta = Math.max(x, y);
      } else {
        delta = Math.min(x, y);
      }
    }
  }

  return delta;
}

export function calcTopRightDelta(
  x: number,
  y: number,
  horizontalAlignment: AssetAlignmentType,
  isComponentWide: boolean,
): number {
  let delta: number;

  if (horizontalAlignment === AssetAlignmentType.HORIZONTAL_CENTER) {
    if (x >= 0 && y <= 0) {
      delta = -Math.abs(x);
    } else {
      delta = Math.abs(x);
    }
  } else {
    if (x >= 0 && y <= 0) {
      if (isComponentWide) {
        delta = -Math.max(Math.abs(x), Math.abs(y));
      } else {
        delta = -Math.min(Math.abs(x), Math.abs(y));
      }
    } else {
      if (isComponentWide) {
        delta = Math.max(Math.abs(x), Math.abs(y));
      } else {
        delta = Math.min(Math.abs(x), Math.abs(y));
      }
    }
  }

  return delta;
}

export function calcBottomRightDelta(
  x: number,
  y: number,
  horizontalAlignment: AssetAlignmentType,
  isComponentWide: boolean,
): number {
  return -calcTopLeftDelta(x, y, horizontalAlignment, isComponentWide);
}

export function calcBottomLeftDelta(
  x: number,
  y: number,
  horizontalAlignment: AssetAlignmentType,
  isComponentWide: boolean,
): number {
  return -calcTopRightDelta(x, y, horizontalAlignment, isComponentWide);
}
