import _ from 'lodash';

import * as Models from 'models';
import { isAssetsCollection, isReusableLayoutDocument } from './entityType';

/**
 * Removes all internal properties (which starts with '_') from the every 'document', e.g., _internalInfo, _previewUrl,
 * and removes Story Card and TextCollection internal data, e.g., documents, entities
 */
// TODO: It should be moved to BE
export function deleteInternalInfo<T extends { documents: Models.CombinedDocuments }>(arg: T): T {
  const documents = _.mapValues(arg.documents, deleteDocumentInternalInfo);

  return {
    ...arg,
    documents,
  };
}

export function deleteDocumentInternalInfo<T extends Models.CombinedDocument>(document: T): T {
  if (!document) {
    return document;
  }

  const keysToRemove = _.keys(document).filter((key) => {
    return (
      _.startsWith(key, '_') ||
      ((isAssetsCollection(document)) && ['documents', 'entities'].includes(key))
    );
  });

  return _(document)
    .omit(keysToRemove)
    // additionally delete internal info from RLs and GRLs
    .thru((document: unknown) => isReusableLayoutDocument(document as Models.CombinedDocument)
      ? deleteInternalInfo(document as { documents: Models.CombinedDocuments })
      : document,
    )
    .value() as T;
}
