import React from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { intlGet } from 'utils/intlGet';

type Props = {
  className?: string;
  tooltipClassName?: string;
};

const BackupNotice: React.FunctionComponent<Props> = (props) => {
  const { className, tooltipClassName } = props;

  return (
    <div className={className}>
      <Icon
        type={IconType.EXCLAMATION_MARK}
        size="sm"
        color="primary"
        hoverTooltipText={intlGet('ColorGradientPickerWindow', 'BackupInfo')}
        iconTooltipClassName={tooltipClassName}
      />
    </div>
  );
};

export default BackupNotice;
