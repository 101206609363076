import { DocumentClassification, DocumentSubtype, DocumentType, EntityType } from 'const';
import { ReferenceCitation } from 'models';
import { baseDocumentFactory } from './baseDocumentFactory';

export function referenceCitationFactory({
  text = '',
  translatable = true,
  ...args
} = {} as Partial<ReferenceCitation>): ReferenceCitation {
  return {
    ...baseDocumentFactory(args),
    text,
    type: DocumentType.COMPONENT,
    subtype: DocumentSubtype.ASSEMBLER,
    classification: DocumentClassification.REFERENCE_CITATION,
    entityType: EntityType.REFERENCE_CITATION,
    translatable,
  };
}
