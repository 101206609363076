import classNames from 'classnames';
import * as React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { Layer } from 'const';
import { Priority, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import { intlGet } from 'utils/intlGet';
import { TranslationControlProps } from './models';
import styles from './styles.module.scss';

const Translation: React.FunctionComponent<TranslationControlProps> = ({
  activeLayer,
  disabled,
  originalLang,
  language,
  toggleLayer,
  onImport,
  onExport,
}) => {
  const { container, on, toggle } = useClickOutsideWithToggle<HTMLDivElement>(Priority.HEADER, { useToolbar: false });
  const isOriginalActive = activeLayer === Layer.ORIGINAL;
  const importDisabled = !onImport;
  const exportDisabled = !onExport;

  React.useEffect(() => { disabled && on && toggle(); }, [disabled]);

  const handleImport: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (importDisabled) {
      return;
    }

    const packageFile = event.target.files.item(0);

    packageFile && onImport(packageFile);
    toggle();
  };

  const handleExport: React.MouseEventHandler<HTMLDivElement> = () => {
    if (exportDisabled) {
      return;
    }

    onExport();
    toggle();
  };

  const toggleOriginalLayer = () => {
    toggleLayer(Layer.ORIGINAL);
    toggle();
  };

  const toggleTranslatedLayer = () => {
    toggleLayer(Layer.TRANSLATED);
    toggle();
  };

  const renderTargetLabel = () => <span className={styles.targetLabel}>{intlGet('Translation', 'Target')}</span>;
  const renderSourceLabel = () => <span className={styles.sourceLabel}>{intlGet('Translation', 'Source')}</span>;
  const renderMarker = (layer: Layer) => (
    <span className={styles.marker}>
      {
        layer === activeLayer
          ? <Icon color="primary" size="badge" type={IconType.TICK} />
          : null
      }
    </span>
  );

  return (
    <div
      className={classNames(styles.TranslationControl, {
        [styles.disabled]: disabled,
      })}
      ref={container}
    >
      <div className={styles.header} onClick={!disabled ? toggle : null}>
        <Button
          icon={IconType.TRANSLATION}
          size="sm"
          title={isOriginalActive ? originalLang : language}
          disabled={disabled}
          highlightOnHover={true}
        />
        {isOriginalActive ? renderSourceLabel() : renderTargetLabel()}
      </div>
      {
        on &&
        <Dropdown className={styles.dropdown}>
          <div className={styles.language} onClick={toggleTranslatedLayer}>
            {renderMarker(Layer.TRANSLATED)}
            <span className={styles.value}>{language}</span>
            {renderTargetLabel()}
          </div>
          <div className={styles.language} onClick={toggleOriginalLayer}>
            {renderMarker(Layer.ORIGINAL)}
            <span className={styles.value}>{originalLang}</span>
            {renderSourceLabel()}
          </div>
          <div className={styles.separator}/>
          <label className={styles.label}>
            <div
              className={classNames(styles.option, {
                [styles.disabled]: importDisabled,
              })}
            >
              {intlGet('Translation', 'ImportPackage')}
            </div>
            <input
              className={styles.fileInput}
              disabled={importDisabled}
              onChange={handleImport}
              accept="application/zip"
              type="file"
            />
          </label>
          <div
            className={classNames(styles.option, {
              [styles.disabled]: exportDisabled,
            })}
            onClick={handleExport}
          >
            {intlGet('Translation', 'ExportPackage')}
          </div>
        </Dropdown>
      }
    </div>
  );
};

export default Translation;
