import Editor from '@draft-js-plugins/editor';
import { useCallback, useRef } from 'react';
import * as Models from 'models';

export function useOnWrapperClick(
  editorRef: React.RefObject<Editor | null>,
  editMode: boolean,
  returnFocusToEditor: () => void,
): () => void {

  const editModeRef = useRef(editMode);
  editModeRef.current = editMode;

  return useCallback((): void => {
    if (!editModeRef.current) {
      return;
    }
    // HACK: for some reasons Draft Editor loses the focus during click into it if there is no text
    const editor = editorRef.current;
    if (editor) {
      const { editorContainer } = editor.editor as unknown as Models.DraftEditor;
      if (!editorContainer.contains(document.activeElement)) {
        returnFocusToEditor();
      }
    }
  }, [returnFocusToEditor]);
}
