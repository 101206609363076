import * as React from 'react';
import { Button } from 'react-bootstrap';

import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { AssetFilter, DocumentsSource, OrderedAssetFilters, OrderedDocumentSources, ProjectPanelTab } from 'const';
import { intlGet } from 'utils/intlGet';
import { IAssetFilterContainerProps, IAssetFilterContainerState } from './models';
import styles from './styles.module.scss';

const FilterTexts: PartialRecord<DocumentsSource | AssetFilter, () => string> = {
  [DocumentsSource.FAVORITES]: () => intlGet('Assets.Filter', 'Favorited'),
  [DocumentsSource.RELATED_DOCUMENTS]: () => intlGet('Assets.Filter', 'Related'),
  [DocumentsSource.SHOPPING_CART]: () => intlGet('Assets.Filter', 'Shopped'),

  [AssetFilter.IMAGES]: () => intlGet('Assets.Filter', 'Images'),
  [AssetFilter.REFERENCES]: () => intlGet('Assets.Filter', 'References'),
  [AssetFilter.LAYOUTS]: () => intlGet('Assets.Filter', 'ReusableLayouts'),
  [AssetFilter.GROUP_LAYOUTS]: () => intlGet('Assets.Filter', 'GroupLayouts'),
  [AssetFilter.STORY_CARDS]: () => intlGet('Assets.Filter', 'StoryCards'),
  [AssetFilter.TEXT]: () => intlGet('Assets.Filter', 'Text'),
  [AssetFilter.TEXT_COLLECTIONS]: () => intlGet('Assets.Filter', 'TextCollections'),
};

export default class AssetListFilter extends React.PureComponent<IAssetFilterContainerProps, IAssetFilterContainerState> {
  readonly state: IAssetFilterContainerState = {
    isExpanded: false,
    assetFilters: [],
    contentFilters: [],
    appliedFilters: [],
  };

  private handleTitleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
    }));

    this.props.toggleFilterExpanded();
  };

  private handleApply: React.MouseEventHandler<HTMLButtonElement> = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
      appliedFilters: [...prevState.contentFilters, ...prevState.assetFilters],
    }));

    this.props.toggleFilterExpanded();
    this.props.setAssetFilters(this.state.assetFilters);
    this.props.setContentFilters(this.state.contentFilters);
  };

  private handleClear: React.MouseEventHandler<HTMLDivElement> = () => {
    this.setState({
      assetFilters: [],
      contentFilters: [],
    });
  };

  private handleAssetFilterToggle: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { assetFilters } = this.state;
    const { checked } = event.target;
    const name = event.target.name as AssetFilter;
    const newFilters = checked ? assetFilters.concat(name) : assetFilters.filter(filter => filter !== name);

    this.setState({
      assetFilters: newFilters,
    });
  };

  private handleContentFilterToggle: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { contentFilters } = this.state;
    const { checked } = event.target;
    const name = event.target.name as DocumentsSource;
    const newFilters = checked ? contentFilters.concat(name) : contentFilters.filter(filter => filter !== name);

    this.setState({
      contentFilters: newFilters,
    });
  };

  private shouldDisplayFilter = (filter: string): boolean => {
    return this.props.availableFilters.includes(filter);
  };

  render() {
    const { isExpanded, assetFilters, contentFilters, appliedFilters } = this.state;

    return (
      <>
        {
          this.props.activeTab === ProjectPanelTab.ASSETS &&
          <div className={styles.AssetListFilter}>
            <div className={styles.header} onClick={this.handleTitleClick}>
              <div className={styles.title}>
                {appliedFilters.length === 0
                  ? intlGet('Assets.Filter', 'FilterContent')
                  : `${intlGet('Assets.Filter', 'FilterBy')} ${appliedFilters.map(filter => FilterTexts[filter]()).join(', ')}`
                }
              </div>
              <Icon type={isExpanded ? IconType.COLLAPSE : IconType.PLUS} size="badge" color="primary" />
            </div>
            {
              isExpanded &&
                <div className={styles.filters}>
                  <div className={styles.header}>
                    <div onClick={this.handleClear}>{intlGet('Assets.Filter.Button', 'ClearAll')}</div>
                  </div>
                  <ul>
                    {
                      OrderedDocumentSources.map(filter => (
                        <li key={filter}>
                          <Checkbox
                            className={styles.checkbox}
                            onChange={this.handleContentFilterToggle}
                            checked={contentFilters.includes(filter)}
                            label={FilterTexts[filter]()}
                            name={filter}
                          />
                        </li>
                      ))
                    }
                  </ul>
                  <ul>
                    {
                      OrderedAssetFilters.filter(this.shouldDisplayFilter).map(filter => (
                        <li key={filter}>
                          <Checkbox
                            className={styles.checkbox}
                            onChange={this.handleAssetFilterToggle}
                            checked={assetFilters.includes(filter)}
                            label={FilterTexts[filter]()}
                            name={filter}
                          />
                        </li>
                      ))
                    }
                  </ul>
                  <div className={styles.footer}>
                    <Button onClick={this.handleApply} variant="primary">{intlGet('Assets.Filter.Button', 'Apply')}</Button>
                  </div>
                </div>
            }
          </div>
        }
      </>
    );
  }
}
