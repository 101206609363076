import { Protocol } from 'const';
import { isStartingWithProtocol } from 'utils/isStartingWithProtocol';

/**
 * Adds 'http://' to the beginning of url if 'ftp | http | https | mailto' protocol is missing
 * @param {string} link - url to verify
 * @returns {string}    - modified url
 */
export const appendProtocol = (link: string): string => {
  if (!link) {
    return link;
  }

  return isStartingWithProtocol(link) ? link : Protocol.HTTP.concat(link);
};
