import _ from 'lodash';
import guid from 'uuid';

import * as Models from 'models';
import { removeAll, toImmutable, toJS } from 'utils/immutable';
import { isGroupLayout } from './isGroupLayout';

interface LayoutEntry {
  documentId: string;
  index: number;
}
const getEntryHash = ({ documentId, index }: LayoutEntry): string => `${documentId}-${index}`;

export function updateGroupLayoutInstances(
  groupLayout: Models.GroupLayout,
  layouts: Models.CombinedLayouts,
): Models.CombinedLayouts {
  const {
    documentId: groupLayoutDocumentId,
    layoutIds: newLayoutIds,
    styles,
  } = groupLayout;
  const allLayouts = _.cloneDeep(layouts);

  const newLayoutEntryByHash = _(newLayoutIds)
    .map((layoutId, index) => ({ documentId: allLayouts[layoutId].documentId, index }))
    .keyBy(getEntryHash)
    .value();
  const newLayoutHashes = _.keys(newLayoutEntryByHash);
  const layoutIdsToRemove: string[] = [];

  _.each(allLayouts, (layout: Models.GroupLayout) => {
    const {
      section,
      documentId,
      id: currentLayoutId,
      layoutIds: currentLayoutIds,
    } = layout;

    if (documentId !== groupLayoutDocumentId || currentLayoutId === groupLayout.id) {
      return;
    }

    const currentLayoutEntryByHash = _(currentLayoutIds)
      .map((layoutId, index) => ({ documentId: allLayouts[layoutId].documentId, index }))
      .keyBy(getEntryHash)
      .value();
    const currentLayoutHashes = _.keys(currentLayoutEntryByHash);

    const addedLayoutHashes = _.without(newLayoutHashes, ...currentLayoutHashes);
    const removedLayoutHashes = _.without(currentLayoutHashes, ...newLayoutHashes);
    // layout that was removed from GRL should be removed from its instance and from layouts as well
    layoutIdsToRemove.push(..._.map(removedLayoutHashes, hash => currentLayoutIds[currentLayoutEntryByHash[hash].index]));

    // a copy of layout which was added to GRL should be added to its instance and to layouts as well
    const addedLayoutIdByHash = _(addedLayoutHashes).map<[string, string]>((hash) => {
      const { index: addedLayoutIndex } = newLayoutEntryByHash[hash];
      const addedLayoutId = newLayoutIds[addedLayoutIndex];
      const addedLayout = allLayouts[addedLayoutId];
      const addedLayoutEntry = {
        documentId: addedLayout.documentId,
        index: addedLayoutIndex,
      };

      const copiedLayoutId = guid();
      const copiedLayout = _(addedLayout)
        .chain()
        .cloneDeep()
        .set('section', section)
        .set('id', copiedLayoutId)
        .value();
      allLayouts[copiedLayoutId] = copiedLayout;

      return [getEntryHash(addedLayoutEntry), copiedLayoutId];
    }).fromPairs().value();

    // it applies actual number and order of layout ids from updated GRL to its instance
    layout.layoutIds = _.map(newLayoutHashes, (hash) => {
      const currentLayoutEntry = currentLayoutEntryByHash[hash];

      return currentLayoutEntry ? currentLayoutIds[currentLayoutEntry.index] : addedLayoutIdByHash[hash];
    });
    layout.styles = styles;
  });

  removeAll(allLayouts, layoutIdsToRemove);
  allLayouts[groupLayout.id] = groupLayout;

  return allLayouts;
}

export function updateReusableLayoutInstances(
  editedLayout: Models.LayoutMap,
  layouts: Models.CombinedLayoutsMap,
): Models.CombinedLayoutsMap {
  const documentId = editedLayout.get('documentId');

  return layouts
    .set(editedLayout.get('id'), editedLayout)
    .map((layout, id) => documentId && layout.get('documentId') === documentId
      ? editedLayout.set('id', id).set('section', layout.get('section'))
      : layout,
    );
}

export function updateLayoutInstances(
  editedLayout: Models.CombinedLayoutMap,
  layouts: Models.CombinedLayoutsMap,
): Models.CombinedLayoutsMap {
  return isGroupLayout(editedLayout)
    ? toImmutable(updateGroupLayoutInstances(toJS(editedLayout as Models.GroupLayoutMap), toJS(layouts)))
    : updateReusableLayoutInstances(editedLayout, layouts);
}
