import * as Models from 'models';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getValue } from '../getter';
import { recalculateColumnsWidthRecursively } from './recalculateColumnsWidthRecursively';

/**
 * @param sectionWidth width of container which layout should be fitted in, if it's absent layout width will be used
 */
export const fitLayoutIntoSection = <R extends Models.LayeredRelationsMap | Models.LayeredRelations | Models.Relations | Models.RelationsMap>(
  layout: Models.LayoutMap | Models.Layout,
  relations: R,
  sectionWidth: number,
): R => {
  const layoutRelationId = getValue(layout, 'relationId');
  const newLayoutColumnsWidth = sectionWidth - getExtraWidth(getValue(layout, 'styles'));

  return recalculateColumnsWidthRecursively(layoutRelationId, relations, newLayoutColumnsWidth) as R;
};
