export interface BrandColor {
  id: string;
  name: string;
  HEX: string;
  CMYK: string;
  RGB: string;
  tint?: number;
}
export type BrandColorMap = DeepIMap<BrandColor>;

export function brandColorsAreEqual(a: BrandColorMap, b: BrandColorMap): boolean {
  if (a.get('name') !== b.get('name')) {
    return false;
  }

  return (a.get('tint') || 0) === (b.get('tint') || 0); // in some cases tint is equal to null
}

export type BrandColors = BrandColor[];
export type BrandColorsList = DeepIMap<BrandColors>;

export type BrandColorsRecord = Record<string, BrandColors>;
export type BrandColorsMap = DeepIMap<BrandColorsRecord>;

export interface ColorsCategory {
  name: string;
  visible: boolean;
  brandColors: string[];
}
export type ColorsCategoryMap = DeepIMap<ColorsCategory>;

export type ColorsCategories = ColorsCategory[];
export type ColorsCategoriesList = DeepIMap<ColorsCategories>;
