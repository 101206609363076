import Immutable from 'immutable';
import React from 'react';
import { HOVER_HINT } from 'components/Toolbar/constants';
import BackgroundImage from 'components/Toolbar/Controls/BackgroundImage';
import Border from 'components/Toolbar/Controls/Border';
import BorderRadius from 'components/Toolbar/Controls/BorderRadius';
import ColumnWidth from 'components/Toolbar/Controls/ColumnWidth';
import FillColor from 'components/Toolbar/Controls/FillColor';
import Padding from 'components/Toolbar/Controls/Padding';
import Responsive from 'components/Toolbar/Controls/Responsive';
import Scrollable from 'components/Toolbar/Controls/Scrollable';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import { ProjectsConfig, ProjectType } from 'const';
import BaseToolbar from 'containers/Toolbar/BaseToolbar';
import { LayoutStylesState, LayoutStylesStateSetters } from 'hooks/layout/useLayoutStyles';
import { intlGet } from 'utils/intlGet';

type Props = {
  styles: LayoutStylesState;
  stylesSetters: LayoutStylesStateSetters;
  addLayoutColumn(): void;
  applyColumnsWidth?(columnsWidth: Immutable.List<number>): void;
  columnsWidth: Immutable.List<number>;
  deleteLayoutColumn?(position: number): void;
  getMinColumnWidth?(position: number): number;
  isAddingDisabled?: boolean;
  layoutId: string;
  projectType: ProjectType;
};

const Toolbar: React.FunctionComponent<Props> = (props) => {
  const {
    styles,
    stylesSetters,
    addLayoutColumn,
    applyColumnsWidth,
    columnsWidth,
    deleteLayoutColumn,
    getMinColumnWidth,
    isAddingDisabled,
    layoutId,
    projectType,
  } = props;
  const { allowBackgroundOpacity, allowBackgroundImage, allowScrollableLayout } = ProjectsConfig[projectType];

  return (
    <BaseToolbar title={intlGet('EditorToolbar.Asset', 'Layout')} layoutId={layoutId}>
      <ControlGroup>
        <ColumnWidth
          addLayoutColumn={addLayoutColumn}
          applyColumnsWidth={applyColumnsWidth}
          columnsWidth={columnsWidth}
          deleteLayoutColumn={deleteLayoutColumn}
          getMinColumnWidth={getMinColumnWidth}
          isAddingDisabled={isAddingDisabled}
        />
        <Padding
          padding={styles.padding}
          togglePadding={stylesSetters.padding}
        />
        <Border
          border={styles.border}
          toggleBorder={stylesSetters.border}
        />
        <FillColor
          activeColor={styles.backgroundColor}
          toggleColor={stylesSetters.backgroundColor}
          opacity={styles.backgroundColorOpacity}
          toggleOpacity={allowBackgroundOpacity ? stylesSetters.backgroundColorOpacity : null}
          gradient={styles.backgroundGradient}
          toggleGradient={stylesSetters.backgroundGradient}
        />
        {allowBackgroundImage && <BackgroundImage
          backgroundImage={styles.backgroundImage}
          toggleBackgroundImage={stylesSetters.backgroundImage}
        />}
        <BorderRadius
          borderRadius={styles.borderRadius}
          title={HOVER_HINT.LAYOUT}
          dropdownWarningMessage={intlGet('EditorToolbar.Tooltip', 'WarningMessage')}
          toggleBorderRadius={stylesSetters.borderRadius}
        />
      </ControlGroup>
      <ControlGroup>
        <Responsive
          responsive={styles.responsive}
          toggleResponsive={(): void => stylesSetters.responsive(!styles.responsive)}
        />
        {allowScrollableLayout && <Scrollable
          scrollable={styles.scrollable}
          toggleScrollable={(): void => stylesSetters.scrollable(!styles.scrollable)}
        />}
      </ControlGroup>
    </BaseToolbar>
  );
};

export default Toolbar;
