import React from 'react';
/* eslint-disable max-len */

const ExportProject = (): JSX.Element => (
  <svg viewBox="0 0 18 19">
    <g fillRule="evenodd">
      <path d="M9.28328799,12.8500102 L4.3135468,7.88026896 L7.15338953,7.88026896 L7.15338953,3.62053767 L11.4131865,3.62053767 L11.4131865,7.88026896 L14.2530292,7.88026896 L9.28328799,12.8500102 Z M4.3135468,15.6898529 L14.2530292,15.6898529 L14.2530292,14.2698987 L4.3135468,14.2698987 L4.3135468,15.6898529 Z M15.3443273,0.803664106 L3.22218302,0.803664106 C1.68744861,0.803664106 0.431756815,2.0593559 0.431756815,3.59409031 L0.431756815,15.7163003 C0.431756815,17.2510347 1.68744861,18.5067265 3.22218302,18.5067265 L15.3443273,18.5067265 C16.8790618,18.5067265 18.1347535,17.2510347 18.1347535,15.7163003 L18.1347535,3.59409031 C18.1347535,2.0593559 16.8790618,0.803664106 15.3443273,0.803664106 L15.3443273,0.803664106 Z" />
    </g>
  </svg>
);

export default ExportProject;
