import { fromJS } from 'immutable';
import { BrandColorGradientMap, BrandColorsList } from 'models';
import { BackgroundGradientMap } from 'models/styles/style/BackgroundGradient';
import { colorFromSource, colorToSource, colorToTint } from './color';


export function brandColorGradientToSource(
  gradient: BrandColorGradientMap | undefined,
): BackgroundGradientMap | undefined {
  if (!gradient) {
    return undefined;
  }

  return fromJS({
    color1: {
      name: colorToSource(gradient.get('color1')),
      tint: colorToTint(gradient.get('color1')),
    },
    color2: {
      name: colorToSource(gradient.get('color2')),
      tint: colorToTint(gradient.get('color2')),
    },
    direction: gradient.get('direction'),
  });
}

export function brandColorGradientFromSource(
  colors: BrandColorsList | undefined,
  gradient: BackgroundGradientMap | undefined,
): BrandColorGradientMap | undefined {
  if (!gradient) {
    return undefined;
  }

  const color1 = colorFromSource(colors, gradient.getIn(['color1', 'name']), gradient.getIn(['color1', 'tint']));
  const color2 = colorFromSource(colors, gradient.getIn(['color2', 'name']), gradient.getIn(['color2', 'tint']));
  const direction = gradient.get('direction');

  return fromJS({ color1, color2, direction });
}
