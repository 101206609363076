import { ExportType } from './ExportType';
import { PackageName } from './PackageName';
import { PreviewOptionName } from './PreviewOptionName';
import { ProjectType } from './ProjectType';
import { Protocol } from './Protocol';

enum ProjectOption {
  ALLOW_BACKGROUND_IMAGE = 'allowBackgroundImage',
  ALLOW_BACKGROUND_OPACITY = 'allowBackgroundOpacity',
  ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION = 'allowCustomRangeFontSizeSelection',
  ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON = 'allowEmptyLayoutsInArtboardsJson',
  ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON = 'allowEmptySectionsInArtboardsJson',
  ALLOW_SCREEN_SETTINGS = 'allowScreenSettings',
  ALLOW_IMAGE_MOBILE_SETTINGS = 'allowImageMobileSettings',
  ALLOW_SCROLLABLE_LAYOUT = 'allowScrollableLayout',
  ALLOW_SSI = 'allowSSI',
  ALLOW_THUMBNAILS = 'allowThumbnails',
  ALLOW_THUMBNAIL_TAB = 'allowThumbnailTab',
  APPLY_FONT_STYLES_FOR_BRAND_STYLES = 'applyFontStyleForBrandStyles',
  ARE_SCREENS_RESIZABLE = 'areScreensResizable',
  CONVERT_LIST_INDENT_TO_PX = 'convertListIndentToPx',
  DEFAULT_CELL_HEIGHT = 'defaultCellHeight',
  DEFAULT_PREVIEW_OPTION = 'defaultPreviewOption',
  ENABLE_LINK_PROTOCOL_APPENDING = 'enableLinkProtocolAppending',
  ENABLE_PROJECT_LINKS = 'enableProjectLinks',
  EXPORT_TYPE = 'exportType',
  EXTERNAL_LINK_PLACEHOLDER = 'externalLinkPlaceholder',
  INDENT_REFERENCE_CONTENT_IN_PARAGRAPH = 'indentReferenceContentInParagraph',
  PACKAGE_ARCHIVE_NAME_FIELD = 'packageArchiveNameField',
  PACKAGE_ARCHIVE_POSTFIX = 'packageArchivePostfix',
  USE_BOLD_NUMBERING_IN_REFERENCES = 'useBoldNumberingInReferences',
  USE_CONTENT_BOX = 'useContentBox',
  WRAP_REFERENCE_IN_LIST = 'wrapReferenceInList',
  WRAP_REFERENCE_IN_ORDERED_LIST = 'wrapReferenceInOrderedList',
}

export type ProjectOptionType = VerifyType<ProjectOption, {
  [ProjectOption.ALLOW_BACKGROUND_IMAGE]: boolean;
  [ProjectOption.ALLOW_BACKGROUND_OPACITY]: boolean;
  [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: boolean;
  [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: boolean;
  [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: boolean;
  [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: boolean;
  [ProjectOption.ALLOW_SCREEN_SETTINGS]: boolean;
  [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: boolean;
  [ProjectOption.ALLOW_SSI]: boolean;
  [ProjectOption.ALLOW_THUMBNAILS]: boolean;
  [ProjectOption.ALLOW_THUMBNAIL_TAB]: boolean;
  [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: boolean;
  [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: boolean;
  [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName;
  [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: boolean;
  [ProjectOption.ENABLE_PROJECT_LINKS]: boolean;
  [ProjectOption.EXPORT_TYPE]: ExportType;
  [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: Protocol;
  [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: boolean;
  [ProjectOption.ARE_SCREENS_RESIZABLE]: boolean;
  [ProjectOption.DEFAULT_CELL_HEIGHT]: number;
  [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName;
  [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: string;
  [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: boolean;
  [ProjectOption.USE_CONTENT_BOX]: boolean;
  [ProjectOption.WRAP_REFERENCE_IN_LIST]: boolean;
  [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: boolean;
}>;

export const ProjectsConfig: Record<ProjectType, ProjectOptionType> = {
  [ProjectType.BRAND_ALERT_MEDSCAPE]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: false,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: true,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: true,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 100,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NUMBER,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: true,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: true,
  },
  [ProjectType.CARD]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: true,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: false,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: false,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: false,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.DESKTOP,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: false,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 100,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NAME,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '_html_package',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: false,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: false,
  },
  [ProjectType.EMAIL]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: true,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: false,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: false,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: true,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: true,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.DESKTOP,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: false,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 100,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NUMBER,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: true,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: false,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: false,
  },
  [ProjectType.MOBILE_ALERT]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: false,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: true,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: true,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 100,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NUMBER,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: true,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: true,
  },
  [ProjectType.MOBILE_ALERT_DOXIMITY]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: false,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: true,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: true,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 100,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NUMBER,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: true,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: true,
  },
  [ProjectType.MOBILE_ALERT_EPOCRATES]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: false,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: false,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.MOBILE,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: true,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 100,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NUMBER,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: false,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: true,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: true,
  },
  [ProjectType.IPAD]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: true,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: true,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: true,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: false,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: true,
    [ProjectOption.ALLOW_SSI]: true,
    [ProjectOption.ALLOW_THUMBNAILS]: true,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: true,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: true,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.IPAD,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: true,
    [ProjectOption.EXPORT_TYPE]: ExportType.IPAD_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: false,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 40,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NAME,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '_ipad_package',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: true,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: true,
  },
  [ProjectType.DISPLAY_PANEL]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: true,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: true,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: true,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: false,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: true,
    [ProjectOption.ALLOW_SSI]: true,
    [ProjectOption.ALLOW_THUMBNAILS]: true,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: true,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: true,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.IPAD,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: true,
    [ProjectOption.EXPORT_TYPE]: ExportType.IPAD_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: false,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: false,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 40,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NAME,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '_ipad_package',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: true,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: true,
  },
  [ProjectType.UNIVERSAL_ARTBOARD]: {
    [ProjectOption.ALLOW_BACKGROUND_IMAGE]: true,
    [ProjectOption.ALLOW_BACKGROUND_OPACITY]: false,
    [ProjectOption.ALLOW_CUSTOM_RANGE_FONT_SIZE_SELECTION]: false,
    [ProjectOption.ALLOW_EMPTY_LAYOUTS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_EMPTY_SECTIONS_IN_ARTBOARDS_JSON]: true,
    [ProjectOption.ALLOW_IMAGE_MOBILE_SETTINGS]: false,
    [ProjectOption.ALLOW_SCREEN_SETTINGS]: true,
    [ProjectOption.ALLOW_SCROLLABLE_LAYOUT]: false,
    [ProjectOption.ALLOW_SSI]: false,
    [ProjectOption.ALLOW_THUMBNAILS]: false,
    [ProjectOption.ALLOW_THUMBNAIL_TAB]: false,
    [ProjectOption.APPLY_FONT_STYLES_FOR_BRAND_STYLES]: false,
    [ProjectOption.CONVERT_LIST_INDENT_TO_PX]: false,
    [ProjectOption.DEFAULT_PREVIEW_OPTION]: PreviewOptionName.DESKTOP,
    [ProjectOption.ENABLE_LINK_PROTOCOL_APPENDING]: false,
    [ProjectOption.ENABLE_PROJECT_LINKS]: false,
    [ProjectOption.EXPORT_TYPE]: ExportType.HTML_PACKAGE,
    [ProjectOption.EXTERNAL_LINK_PLACEHOLDER]: '' as Protocol,
    [ProjectOption.INDENT_REFERENCE_CONTENT_IN_PARAGRAPH]: false,
    [ProjectOption.ARE_SCREENS_RESIZABLE]: true,
    [ProjectOption.DEFAULT_CELL_HEIGHT]: 40,
    [ProjectOption.PACKAGE_ARCHIVE_NAME_FIELD]: PackageName.ROOT_DOCUMENT_NAME,
    [ProjectOption.PACKAGE_ARCHIVE_POSTFIX]: '_html_package',
    [ProjectOption.USE_BOLD_NUMBERING_IN_REFERENCES]: true,
    [ProjectOption.USE_CONTENT_BOX]: false,
    [ProjectOption.WRAP_REFERENCE_IN_LIST]: false,
    [ProjectOption.WRAP_REFERENCE_IN_ORDERED_LIST]: false,
  },
};
