import Immutable from 'immutable';
import { select } from 'redux-saga/effects';

import { createBrandStylesByRelationId, createFlatColorsByRelationId, createFlatFontsByRelationId } from 'containers/Common/selectors';
import * as Models from 'models';
import { createStylesWithDefaultBrandStyle } from 'utils/editor/brandStyle';

export function* useDefaultBrandStyle(
  component: Models.TextComponentMap,
  sectionStyles: Immutable.List<string>,
  relationId: string,
): Generator<unknown, Models.TextRelationStyles> {
  const styles: ReturnTypeSaga<ReturnTypeSaga<typeof createBrandStylesByRelationId>> = yield select(createBrandStylesByRelationId(relationId));
  const colors: ReturnTypeSaga<ReturnTypeSaga<typeof createFlatColorsByRelationId>> = yield select(createFlatColorsByRelationId(relationId));
  const fonts: ReturnTypeSaga<ReturnTypeSaga<typeof createFlatFontsByRelationId>> = yield select(createFlatFontsByRelationId(relationId));

  return createStylesWithDefaultBrandStyle(component, sectionStyles, styles, colors, fonts);
}
