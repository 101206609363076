import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import TextComponentDetails from 'components/ModalWindows/TextComponentDetails';
import * as Models from 'components/ModalWindows/TextComponentDetails/models';
import { hideModal } from 'containers/ModalManager/actions';
import { referencesDataForDocumentOnAssetPanel, selectedTextComponent } from 'containers/ProjectPanel/selectors';

const mapStateToProps = createStructuredSelector({
  referencesNumbersOrder: referencesDataForDocumentOnAssetPanel(),
  selectedTextComponent,
});

const mapDispatchToProps = {
  hideModal,
};

export default connect<Models.ITextComponentDetailsSelectors, Models.ITextComponentDetailsActions, Models.ITextComponentDetailsProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TextComponentDetails);
