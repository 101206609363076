import _ from 'lodash';
import { processBrandDefinitionForAbbreviations } from 'modules/Abbreviations/processes';
import { processBrandStyleForReferenceElement } from 'modules/References/processes';
import * as Constants from 'const';
import * as Models from 'models';
import { appendColorsCategory } from 'utils/brandStyles';
import { intlGet } from 'utils/intlGet';

export const processBrandDefinition = <T extends Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs>(args: T): void => {
  const { assets, notificationOptions } = args;
  const {
    brandDefinitionChecksum: prevBrandDefinitionChecksum,
    brandStylesChecksum: prevBrandStylesChecksum,
    masterScreenData,
  } = assets;
  const { brandDefinition, brandDefinition: {
    colorsCategories,
    fontsCategories,
    brandStyles,
    brandDefinitionChecksum,
    brandStylesChecksum,
    UIFontFaces,
  } } = masterScreenData;

  brandDefinition.brandStyles = brandStyles || {};
  brandDefinition.colorsCategories = colorsCategories || [];
  brandDefinition.fontsCategories = fontsCategories || [];
  brandDefinition.UIFontFaces = UIFontFaces || [];

  masterScreenData.brandDefinition = appendColorsCategory(brandDefinition);

  // notify users when Brand Definition is absent
  const isBrandDefinitionAbsent = _.isEmpty(colorsCategories) && _.isEmpty(fontsCategories);
  // notify users when Brand Styles is absent
  const areBrandStylesAbsent = _.isEmpty(brandStyles);
  const brandStylesModified = Boolean(prevBrandStylesChecksum && prevBrandStylesChecksum !== brandStylesChecksum);

  if (isBrandDefinitionAbsent) {
    notificationOptions.push({
      title: intlGet('Notification.ProjectConfigurationError.Title', 'IncompleteStarterProject'),
      message: intlGet('Notification.Error', 'MissingBrandDefinition'),
      helpType: Constants.ErrorHelpMessageType.HELP_OPTIONS,
    });
  } else if (prevBrandDefinitionChecksum && prevBrandDefinitionChecksum !== brandDefinitionChecksum) {
    notificationOptions.push({
      title: intlGet('Notification.Title', 'ProjectUpdated'),
      message: intlGet('Notification', 'BrandFontsAndColorsHaveBeenModified'),
    });
  }

  if (areBrandStylesAbsent) {
    notificationOptions.push({
      title: intlGet('Notification.ProjectConfigurationError.Title', 'IncompleteStarterProject'),
      message: intlGet('Notification.Error', 'MissingBrandStyles'),
      helpType: Constants.ErrorHelpMessageType.HELP_OPTIONS,
    });
  } else if (brandStylesModified) {
    notificationOptions.push({
      title: intlGet('Notification.Title', 'ProjectUpdated'),
      message: intlGet('Notification', 'BrandStylesHaveBeenModified'),
    });
  }

  processBrandStyleForReferenceElement(assets, brandDefinition, brandStylesModified);

  processBrandDefinitionForAbbreviations(assets, brandDefinition, brandStylesModified);
};
