import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    SET_TEXT_ABBREVIATIONS: null,
    REQUEST_TEXT_ABBREVIATIONS: null,
    TOGGLE_ABBREVIATION_PANEL: null,
  },
  'TextAbbreviations',
);
