import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';
import guid from 'uuid';

import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { mergeDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import * as projectSelectors from 'containers/Project/selectors';
import { mergeRelations } from 'containers/Relations/actions';
import { layeredRelations } from 'containers/Relations/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { replaceRelationsWithCopies } from 'utils/reusableLayouts/replaceRelationsWithCopies';
import { setLayout } from '../actions';
import { Action } from '../models';

export function* copyLayout(action: Action.ICopyLayout) {
  try {
    const { layoutId } = action.payload;

    const layouts: ReturnTypeSaga<typeof layeredLayoutsSelector> = yield select(layeredLayoutsSelector);
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;

    const layout = layouts.get(layoutId) as Models.LayeredCombinedLayoutMap;
    if (isGroupLayout(layout) || isReusableLayout(layout, documents)) {
      return;
    }

    const relations = (yield select(layeredRelations)).toJS() as Models.LayeredRelations;
    const layer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);

    const {
      relations: relationsToCreate,
      newDocuments,
      newRelationIdsByOld,
    }: ReturnTypeSaga<typeof replaceRelationsWithCopies> = yield call(
      replaceRelationsWithCopies,
      (layout as Models.LayeredLayoutMap).get('relationId'),
      relations,
      documents,
      layer,
    );

    const layoutToCreate = (layout as Models.LayeredLayoutMap)
      .set('id', guid())
      .update('relationId', relationId => newRelationIdsByOld[relationId]);

    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    const createdLayoutPosition = artboard.get('layoutIds').indexOf(layoutId) + 1;
    const updatedArtboard = artboard.update(
      'layoutIds',
      layoutIds => layoutIds.insert(createdLayoutPosition, layoutToCreate.get('id')),
    );

    yield put(batchActions([
      saveAppState(),
      mergeRelations(relationsToCreate),
      mergeDocuments(newDocuments),
      setLayout(layoutToCreate),
      updateArtboard(updatedArtboard),
    ]));

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'LayoutHasBeenCopied'),
      intlGet('Notification', 'Success'),
    );
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.copyLayout', 'CopyLayout');
  }
}
