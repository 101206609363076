import { ActionTypes } from './constants';
import * as Models from './models';

export const undo: Models.ActionCreator.Undo = () => ({
  type: ActionTypes.UNDO,
  payload: { },
});

export const redo: Models.ActionCreator.Redo = () => ({
  type: ActionTypes.REDO,
  payload: { },
});

export const saveAppState: Models.ActionCreator.SaveAppState = () => ({
  type: ActionTypes.SAVE_APP_STATE,
  payload: { },
});

export const cancel: Models.ActionCreator.Cancel = () => ({
  type: ActionTypes.CANCEL,
  payload: { },
});
