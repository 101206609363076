import { MapDispatchToPropsFunction, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ImageDetails from 'components/ModalWindows/ImageDetails';
import { IImageDetailsActions, IImageDetailsOwnProps, IImageDetailsSelectors } from 'components/ModalWindows/ImageDetails/models';
import { updateImage } from 'containers/Documents/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: IImageDetailsOwnProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps: MapDispatchToPropsFunction<IImageDetailsActions, IImageDetailsOwnProps> = (dispatch, props) => ({
  hideModal: () => dispatch(hideModal(props.modalWindowId)),
  updateImage: (id, imageDataToUpload) => dispatch(updateImage(props.modalWindowId, id, imageDataToUpload)),
});

export default connect<IImageDetailsSelectors, IImageDetailsActions, IImageDetailsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ImageDetails);
