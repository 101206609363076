import { ActionTypes } from './constants';
import { ActionCreator } from './models';

export const hideModal: ActionCreator.HideModal = (id, response, needToClose = true) => ({
  type: ActionTypes.HIDE_MODAL,
  payload: {
    id,
    response,
    needToClose,
  },
});

export const showModal: ActionCreator.ShowModal = (modalType, options?, id?) => ({
  type: ActionTypes.SHOW_MODAL,
  payload: {
    modalType,
    options,
    id,
  },
});

export const updateImageInfoWithinModalWindow: ActionCreator.UpdateImageInfoWithinModalWindow = (modalWindowId, id, imageInternalInfo) => ({
  type: ActionTypes.UPDATE_IMAGE_INFO_WITHIN_MODAL_WINDOW,
  payload: {
    modalWindowId,
    id,
    imageInternalInfo,
  },
});

export const updateModalWindowOptions: ActionCreator.UpdateModalWindowOptions = (modalWindowId, options) => ({
  type: ActionTypes.UPDATE_MODAL_WINDOW_OPTIONS,
  payload: {
    modalWindowId,
    options,
  },
});
