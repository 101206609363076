import classNames from 'classnames';
import React from 'react';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import ColorPicker from 'components/Toolbar/Dropdowns/ColorPicker';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { DefaultCallToActionBackgroundColor } from 'const';
import { intlGet } from 'utils/intlGet';
import { getCSSBackgroundPreview } from 'utils/styles/getBackground';
import { AssetColorProps } from './models';
import styles from './styles.module.scss';

const AssetColor: React.FunctionComponent<AssetColorProps> = (props) => {
  const { activeColor, toggleColor, opacity, toggleOpacity, gradient, toggleGradient } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown();

  return (
    <Control
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.ASSET_COLOR}
    >
      <div
        className={classNames(styles.AssetColor, { [styles.active]: isOpen })}
        style={getCSSBackgroundPreview(gradient, activeColor, DefaultCallToActionBackgroundColor)}
        data-tooltip={intlGet('EditorToolbar.Tooltip', Title.ASSET_COLOR)}
      />
      {
        isOpen &&
        <ColorPicker
          activeColor={activeColor}
          toggleColor={toggleColor}
          opacity={opacity}
          toggleOpacity={toggleOpacity}
          gradient={gradient}
          toggleGradient={toggleGradient}
          title={intlGet('EditorToolbar.ButtonColor', 'Title')}
        />
      }
    </Control>
  );
};

export default AssetColor;
