import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import ArtboardLayoutContainerList from 'components/ArtboardLayoutContainerList';
import createDndScrollZone from 'components/DndScrollZone';
import LayoutDragHotspot from 'components/LayoutDragHotspot';
import LoadingOverlay from 'components/LoadingOverlay';
import { DragSourceType, MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER, MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER, ProcessType, ProjectsConfig } from 'const';
import ArtboardLayoutActions from 'containers/ArtboardLayoutActions';
import ArtboardLayoutContainer from 'containers/ArtboardLayoutContainer';
import { ArtboardGroupLayoutDragObject } from 'models/DragObjects/ArtboardGroupLayoutDragObject';
import { createSmallVerticalStrength } from 'utils/dndScrollZone';
import { isMockDocument } from 'utils/documents';
import { getLayoutSpinnerSize } from 'utils/layouts/getLayoutSpinnerSize';
import { toPx } from 'utils/toPx';
import { allowedToDropItems, groupLayoutDragSource, groupLayoutDropTarget, sourceCollector, targetCollector } from './dnd';
import { useGroupLayout } from './hooks';
import { GroupLayoutOwnProps, GroupLayoutProps, GroupLayoutSourceCollectedProps, GroupLayoutTargetCollectedProps } from './models';
import styles from './styles.module.scss';

const DnDScrollZone = createDndScrollZone<React.HTMLAttributes<HTMLElement>>('div');

const GroupLayout: React.FunctionComponent<GroupLayoutProps> = (props) => {
  const {
    connectDragSource,
    connectDropTarget,
    documents,
    dragHotspotGroupLayoutId,
    dragHotspotPosition,
    getLayoutsRefs,
    isChildLayoutBeingUploaded,
    isLayoutBeingUploaded,
    isOver,
    isSectionActive,
    layout: groupLayout,
    moveableLayoutId,
    position,
    projectType,
    relationsByRegularLayoutId,
    saveLayoutRef = _.noop,
    section,
    ssi,
    toggleDragState,
  } = props;
  const {
    pinnedLayout,
    childLayouts,
    height,
    isDraggingLayout,
    isGroupLayoutWithOneChild,
    isOverByAllowedItem,
    layoutDocumentName,
    layoutDocumentNumber,
    layoutId,
    layoutIds,
    onDragOver,
    onResize,
    onResizeEnd,
    container,
    pinnedLayoutRef,
    unpinnedLayoutsContainer,
  } = useGroupLayout(props);
  const { allowScrollableLayout } = ProjectsConfig[projectType];
  const isMockGroupLayoutDocument = isMockDocument(documents.get(groupLayout.get('documentId')));

  const renderDragHotspot = () => (
    isOver &&
    isDraggingLayout() &&
    dragHotspotGroupLayoutId &&
    <LayoutDragHotspot position={dragHotspotPosition} />
  );

  const renderPinnedLayout = () => (
    <>
      {dragHotspotPosition === 0 && renderDragHotspot()}
      <ArtboardLayoutContainer
        disableDragAndDelete={isGroupLayoutWithOneChild}
        documents={documents}
        groupLayoutId={layoutId}
        isFirst={true}
        isLastLayout={childLayouts.size === 1}
        isSectionActive={isSectionActive}
        key={pinnedLayout.get('id')} // use key because pinned layout could be reordered
        layout={pinnedLayout}
        layoutId={pinnedLayout.get('id')}
        layoutRelations={relationsByRegularLayoutId.get(pinnedLayout.get('id'))}
        moveableArtboardLayoutId={moveableLayoutId}
        position={0}
        saveLayoutRef={saveLayoutRef}
        sectionDisplayName={section && section.get('displayName')}
        sectionName={section && section.get('name')}
        sectionStyles={section && section.get('brandStyles')}
        ssi={ssi}
        toggleDragState={toggleDragState}
      />
    </>
  );

  const renderUnpinnedLayouts = () => (
    <>
      {(!pinnedLayout || dragHotspotPosition > 0) && renderDragHotspot()}
      <ArtboardLayoutContainerList
        documents={documents}
        getLayoutsRefs={getLayoutsRefs}
        groupLayoutId={layoutId}
        isGroupLayoutWithOneChild={isGroupLayoutWithOneChild}
        isSectionActive={isSectionActive}
        layoutIds={layoutIds}
        layouts={childLayouts}
        moveableLayoutId={moveableLayoutId}
        relationsByRegularLayoutId={relationsByRegularLayoutId}
        saveLayoutRef={saveLayoutRef}
        section={section}
        skipFirstLayout={!!pinnedLayout}
        ssi={ssi}
        toggleDragState={toggleDragState}
      />
    </>
  );

  const clientHeight = container.current && container.current.getBoundingClientRect().height || MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER;

  return connectDropTarget((
    <div
      className={classNames(styles.GroupLayout, {
        [styles.scrollable]: allowScrollableLayout,
        [styles.insideOfSSI]: !!ssi,
      })}
      style={{ order: position }}
      onDragOver={onDragOver}
      ref={el => saveLayoutRef(layoutId, el)}
      data-document-number={layoutDocumentNumber}
    >
      <LoadingOverlay
        isLocked={isLayoutBeingUploaded}
        activeProcessType={clientHeight <= MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER
          ? ProcessType.SAVE_LAYOUT_WITHOUT_MESSAGE
          : isChildLayoutBeingUploaded ? ProcessType.SAVE_REUSABLE_LAYOUT : ProcessType.SAVE_GROUPED_REUSABLE_LAYOUT
        }
        spinnerSize={getLayoutSpinnerSize(clientHeight)}
      />
      {!ssi && !isMockGroupLayoutDocument && <ArtboardLayoutActions
        showActions={isOverByAllowedItem}
        hideButtons={isOverByAllowedItem}
        connectDragSource={connectDragSource}
        editMode={false}
        hint={layoutDocumentName}
        layout={groupLayout}
        isBeingUploaded={isLayoutBeingUploaded}
        isResizeAvailable={!!height}
        height={_.round(height)}
        handleDrag={onResize}
        handleDragStop={onResizeEnd}
      />}
      <DnDScrollZone
        verticalStrength={createSmallVerticalStrength}
        className={classNames(styles.container, { [styles.scrollable]: allowScrollableLayout })}
        style={{ maxHeight: (allowScrollableLayout && !ssi) ? toPx(height) : 'none' }}
        ref={container}
      >
        {
          pinnedLayout
            ?
            <>
              <div ref={pinnedLayoutRef}>{renderPinnedLayout()}</div>
              <DnDScrollZone
                verticalStrength={createSmallVerticalStrength}
                className={classNames(styles.unpinnedLayouts, {
                  [styles.scrollable]: allowScrollableLayout,
                })}
                ref={unpinnedLayoutsContainer}
              >
                {renderUnpinnedLayouts()}
              </DnDScrollZone>
            </>
            :
            renderUnpinnedLayouts()
        }
      </DnDScrollZone>
    </div>
  ));
};

export default _.flow(
  DragSource<GroupLayoutOwnProps, GroupLayoutSourceCollectedProps, ArtboardGroupLayoutDragObject>(
    DragSourceType.ARTBOARD_GROUP_LAYOUT,
    groupLayoutDragSource,
    sourceCollector,
  ),
  DropTarget<GroupLayoutOwnProps, GroupLayoutTargetCollectedProps>(
    allowedToDropItems,
    groupLayoutDropTarget,
    targetCollector,
  ),
)(GroupLayout);
