import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReferenceCitationDetails from 'components/ModalWindows/ReferenceCitationDetails';
import * as Models from 'components/ModalWindows/ReferenceCitationDetails/models';
import { hideModal } from 'containers/ModalManager/actions';
import { deleteSelectedCitation } from 'containers/ProjectPanel/actions';
import { selectedCitation } from 'containers/ProjectPanel/selectors';

type Actions = Models.ReferenceCitationDetailsActions;
type OwnProps = Models.ReferenceCitationDetailsOwnProps;
type Selectors = Models.ReferenceCitationDetailsSelectors;

const mapStateToProps = createStructuredSelector({
  selectedCitation,
});

const mapDispatchToProps = {
  hideModal,
  deleteSelectedCitation,
};

export default connect<Selectors, Actions, OwnProps>(mapStateToProps, mapDispatchToProps)(ReferenceCitationDetails);
