import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import BackgroundImage from 'containers/Toolbar/Shared/BackgroundImage';
import { BackgroundImageProps } from './models';
import styles from './styles.module.scss';

const BackgroundImageDropdown: React.FunctionComponent<BackgroundImageProps> = (props) => {
  return (
    <Dropdown className={styles.BackgroundImage} stopClickPropagation={true}>
      <BackgroundImage {...props} />
    </Dropdown>
  );
};

export default BackgroundImageDropdown;
