import _ from 'lodash';
import * as Constants from 'const';
import { createAssectAlignmentWithTextAlignment } from 'factories/style/alignment';
import { createBrandColor } from 'factories/style/brandStyles';
import * as Models from 'models';
import { getDesktopBrandStyles, getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { toJS, toImmutable } from 'utils/immutable';
import { isReferenceCitationElement } from 'utils/layouts/isReferenceCitationElement';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';

export function createBrandedStylesForReferenceElement(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
  insistBackgroundColor = false,
): {
    relationStyles: Partial<Models.ReferenceCitationElementStyles>;
    layoutStyles: Partial<Models.LayoutStyles>;
  } {
  const {
    backgroundColor,
    fontColor,
    fontFamily,
    fontSize,
    lineHeight,
    padding,
    verticalAlignment,
    textAlign,
    characterStyle,
  } = getTextStylesFromBrandStyle(brandStyle, colors, fonts);

  const relationStyles: Partial<Models.ReferenceCitationElementStyles> = {
    fontColor,
    fontFamily,
    fontSize,
    lineHeight,
    alignment: createAssectAlignmentWithTextAlignment(textAlign, verticalAlignment),
    fontStyle: characterStyle,
  };

  const layoutStyles: Partial<Models.LayoutStyles> = {
    padding: toJS(padding) as unknown as Models.BoxProperty,
    backgroundColor: backgroundColor
      ? backgroundColor.get('name')
      : insistBackgroundColor
        ? createBrandColor(getDesktopBrandStyles(brandStyle).get(Models.BrandStyleProp.BACKGROUND_COLOR)).name
        : undefined,
  };

  return {
    relationStyles,
    layoutStyles,
  };
}

export function processBrandStyleForReferenceElement(
  assets: Models.ProjectAssets,
  brandDefinition: Models.OnlineBrandDefinition,
  brandStylesModified: boolean,
): void {
  const {
    brandStyles,
    colors,
    fonts,
  } = brandDefinition;
  const referenceElementBrandStyle = brandStyles[Constants.Styles.DefaultReferenceCitationElementBrandStyleId];
  const { layouts, relations } = assets;

  if (!referenceElementBrandStyle || !relations) {
    return;
  }

  const referenceElementLayouts = _.pickBy(layouts, isReferenceCitationElement) as Models.Layouts;
  const referenceElementRelationIdsByLayoutId = _.mapValues(referenceElementLayouts, layout => (
    _.keys(getFlattenedRelations(layout, relations, true))
  ));
  const referenceElementRelations = _.pick(
    relations,
    _.flatMap(referenceElementRelationIdsByLayoutId),
  ) as Models.LayeredRegularRelations<Models.ReferenceCitationElementStyles>;

  const { relationStyles, layoutStyles } = createBrandedStylesForReferenceElement(
    toImmutable(referenceElementBrandStyle),
    toImmutable(colors),
    toImmutable(fonts),
    true,
  );

  const layoutIdsToUpdate = new Set<string>();

  // update reference element relations
  _.forEach(referenceElementRelations, (layeredRelation) => {
    _.forEach(layeredRelation.styles, (styles) => {
      if (!brandStylesModified && (!styles || styles.brandStyleId)) {
        return;
      }

      _.set(styles, 'alignment', relationStyles.alignment);
      _.set(styles, 'brandStyleId', Constants.Styles.DefaultReferenceCitationElementBrandStyleId);
      _.set(styles, 'fontColor', relationStyles.fontColor);
      _.set(styles, 'fontFamily', relationStyles.fontFamily);
      _.set(styles, 'fontSize', relationStyles.fontSize);
      _.set(styles, 'fontStyle', relationStyles.fontStyle);
      _.set(styles, 'lineHeight', relationStyles.lineHeight);

      // save layout id which should be updated
      const layoutId = _.findKey(referenceElementRelationIdsByLayoutId, relationIds => _.includes(relationIds, layeredRelation.id));
      if (layoutId) {
        layoutIdsToUpdate.add(layoutId);
      }
    });
  });

  // update reference element layouts
  _.forEach(referenceElementLayouts, (layout) => {
    if (!layoutIdsToUpdate.has(layout.id)) {
      return;
    }

    _.set(layout.styles, 'padding', layoutStyles.padding);
    _.set(layout.styles, 'backgroundColor', layoutStyles.backgroundColor);
  });
}
