import React from 'react';
/* eslint-disable max-len */

const NoWrap = (): JSX.Element => {
  return (
    <svg viewBox="3 3 18 18">
      <path d="M4,19 L10,19 L10,17 L4,17 L4,19 Z M20,5 L4,5 L4,7 L20,7 L20,5 Z M17,11 L4,11 L4,13 L17.25,13 C18.35,13 19.25,13.9 19.25,15 C19.25,16.1 18.35,17 17.25,17 L15,17 L15,15 L12,18 L15,21 L15,19 L17,19 C19.21,19 21,17.21 21,15 C21,12.79 19.21,11 17,11 Z" />
    </svg>
  );
};

export default NoWrap;
