import { createSelector } from 'reselect';

import { activeSurface } from 'containers/Surfaces/selectors';
import { ScreenMap } from 'models';
import * as Models from './models';

export const artboards = (state): Models.State.IState => state.get('artboards');

export const activeArtboard = createSelector(
  [artboards, activeSurface],
  (artboards, activeSurface) => {
    const artboardId = activeSurface && (activeSurface as ScreenMap).get('artboardId');

    return artboards.get(artboardId);
  },
);

export const activeArtboardId = createSelector(
  [activeArtboard],
  activeArtboard => activeArtboard.get('id'),
);

// DO NOT rely on order of layouts using this selector
// use online/containers/Layouts/selectors/orderedActiveLayoutIds instead
export const unorderedActiveArtboardLayoutIds = createSelector(
  activeArtboard,
  activeArtboard => activeArtboard.get('layoutIds'),
);

export const SSISource = createSelector(
  activeArtboard,
  activeArtboard => activeArtboard.getIn(['ssi', 'source']),
);

export const activeSSI = createSelector(
  [activeArtboard],
  activeArtboard => activeArtboard.get('ssi'),
);

export const isSSIElementOnArtboard = createSelector(
  activeSSI,
  ssi => !!ssi,
);
