import { ActionTypes } from './constants';
import * as Models from './models';

export const setRootDocument: Models.ActionCreator.SetRootDocument = rootDocument => ({
  type: ActionTypes.SET_ROOT_DOCUMENT,
  payload: {
    rootDocument,
  },
});

export const updateRootDocument: Models.ActionCreator.SetRootDocument = rootDocument => ({
  type: ActionTypes.UPDATE_ROOT_DOCUMENT,
  payload: {
    rootDocument,
  },
});

// exception in auto saving
export const setProjectDetails: Models.ActionCreator.SetProjectDetails = details => ({
  type: ActionTypes.SET_PROJECT_DETAILS,
  payload: {
    details,
  },
});
