import * as Models from 'models';
import { removeImageAbbreviationsFromStyles } from '../processes/removeImageAbbreviations';
import { removeMissingAbbreviations } from '../processes/removeMissingAbbreviations';

export const removeMissingAbbreviationsFromReusableLayout = (
  layoutDocument: Models.ReusableLayout,
  textAbbreviationDocuments: Models.TextAbbreviationDocumentsArray,
): Models.ReusableLayout => {
  const args = {
    assets: layoutDocument,
    textAbbreviationDocuments,
  } as unknown as Models.GetProjectDataProcessArgs;

  const { assets: { documents } } = removeMissingAbbreviations(args);

  layoutDocument.documents = documents;
  removeImageAbbreviationsFromStyles({
    relations: layoutDocument.entities.relations,
    textAbbreviationDocuments,
  });

  return layoutDocument;
};
