import _ from 'lodash';
/**
 * Function removes substring that start with <script> and end with </script>
 * @param stringToProcess string that should be filtered
 */
export function removeScriptTag(stringToProcess: string): string {
  return _(stringToProcess)
    .split(/<script>|<\/script>/gi)
    .reduce((result, value, i) => !(i % 2) ? result.concat(value) : result, '');
}
