import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { Middleware, applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'store/reducers';
import rootSaga from 'store/sagas';

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    alert(`Error occurred (saga): ${error}`);
    // eslint-disable-next-line no-console
    console.error('Error occurred in redux saga middleware.', error, sagaStack);
  },
});
const history = createBrowserHistory();
const router: Middleware = routerMiddleware(history);

function configureStore(initialState: Object) {
  const store = createStore(
    enableBatching(rootReducer(history)),
    initialState,
    applyMiddleware(
      sagaMiddleware,
      router,
    ),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
