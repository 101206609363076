import _ from 'lodash';

import { AssetAlignmentType, BackgroundImageType } from 'const';
import * as Models from 'models';

export const createBackgroundImage = (backgroundImage: Models.BackgroundImage = {} as Models.BackgroundImage): Models.BackgroundImage => {
  // HACK: temporary solution to avoid errors because previously backgroundImage was a string
  // TODO: remove it in future
  let backgroundImageSource: Models.BackgroundImage = backgroundImage;
  if (!_.isObject(backgroundImage)) {
    backgroundImageSource = {} as Models.BackgroundImage;
  }

  const {
    id = null,
    type = BackgroundImageType.ORIGINAL_SIZE,
    alignment: {
      horizontal = AssetAlignmentType.HORIZONTAL_CENTER,
      vertical = AssetAlignmentType.VERTICAL_CENTER,
    } = {},
  } = backgroundImageSource;

  return {
    id,
    type,
    alignment: {
      horizontal,
      vertical,
    },
  };
};
