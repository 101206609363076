import _ from 'lodash';

import { downloadImages } from 'containers/Documents/actions';
import * as Models from 'models';
import { isImage } from 'utils/entityType';

type GetActionsToImagesLoadingArgs =
  Models.GetProjectDataProcessArgs
  | Models.RefreshProcessArgs
  | Models.ImportTranslationPackageArgs
  | Models.SyncProcessArgs;

export function getActionsToImagesLoading<T extends GetActionsToImagesLoadingArgs>(
  arg: T,
  showNotification?: boolean,
): void {
  const {
    assets: {
      documents,
    },
  } = arg;

  const imagesToUpdate = _(documents)
    .pickBy(isImage)
    .mapValues((image: Models.Image) => {
      return image && !image._internalInfo
        ? image
        : null;
    })
    .omitBy(_.isNil)
    .value();

  if (!_.isEmpty(imagesToUpdate)) {
    arg.actionsWithMiddleware.push(downloadImages(imagesToUpdate, { showNotification }));
  }
}
