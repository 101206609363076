import React from 'react';
/* eslint-disable max-len */

const HorizontalAlignmentCenter = (): JSX.Element => (
  <svg width="16px" height="18px" viewBox="0 0 16 18">
    <defs>
      <path d="M16,12 L22,12 C22.5522847,12 23,12.4477153 23,13 L23,17 C23,17.5522847 22.5522847,18 22,18 L16,18 L16,24 L14,24 L14,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,13 C7,12.4477153 7.44771525,12 8,12 L14,12 L14,6 L16,6 L16,12 Z" id="path-horizontal-alignment-center" />
    </defs>
    <g id="Images_HorizontalAlignmentCenter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Images_Alignment_Center" transform="translate(-187.000000, -201.000000)">
        <g id="Widget_HorizontalAlignmentCenter" transform="translate(0.000000, 180.000000)">
          <g id="Icons/HorizontalAlignmentCenter" transform="translate(180.000000, 15.000000)">
            <g id="ToolbarIcons/HorizontalAlignmentCenter">
              <rect id="HorizontalAlignmentCenterRectangle" stroke="#FFFFFF" opacity="0" x="0.5" y="0.5" width="29" height="29" />
              <mask id="mask-horizontal-alignment-center" fill="white">
                <use xlinkHref="#path-horizontal-alignment-center" />
              </mask>
              <use id="MaskHorizontalAlignmentCenter" fill="#FFFFFF" xlinkHref="#path-horizontal-alignment-center" />
              <g id="Colors/OrangeHorizontalAlignmentCenter" mask="url(#mask-horizontal-alignment-center)" fill="#FFFFFF">
                <g transform="translate(4.000000, 4.000000)" id="WhiteHorizontalAlignmentCenter">
                  <rect x="0" y="0" width="22" height="22" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HorizontalAlignmentCenter;
