import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import React, { useCallback } from 'react';
import { TextAbbreviationDocument } from 'models';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import AbbreviationControl from '../AbbreviationControl';
import { useAbbreviationControl } from '../AbbreviationControl/useAbbreviationControl';
import TextAbbreviationPanel from './components/TextAbbreviationPanel';

type Props = {
  selectedText?: string;
  abbreviationId?: string;
  onChange: (value: string | undefined) => void;
  returnFocusToEditor: () => void;
  className?: string;
};

const TextAbbreviationControl: React.FunctionComponent<Props> = (props) => {
  const {
    selectedText,
    abbreviationId,
    onChange,
    returnFocusToEditor,
    className,
  } = props;

  const { isOpen, toggle, toggleOn, toggleOff } = useAbbreviationControl(returnFocusToEditor);

  const isDisabled = !selectedText && !abbreviationId;

  const updateSelectedAbbreviation = useCallback((id: TextAbbreviationDocument['id'] | undefined) => {
    onChange(id);
    if (!id) {
      Notifications.success(intlGet('Notification.Success', 'AbbreviationRemoved'));
      toggleOff();
    }
  }, [toggleOff]);

  useCellActionsExecutor(CellAction.OPEN_ABBREVIATIONS_PANEL, toggleOn);

  useCellActionsExecutor(CellAction.REMOVE_ABBREVIATION, (id) => {
    if (abbreviationId !== id) {
      return false;
    }
    updateSelectedAbbreviation(undefined);

    return true;
  });

  return (
    <AbbreviationControl
      isOpen={isOpen}
      toggle={toggle}
      toggleOff={toggleOff}
      isDisabled={isDisabled}
      className={className}
    >
      <TextAbbreviationPanel
        selectedText={selectedText || ''}
        abbreviationDocumentId={abbreviationId}
        onChange={updateSelectedAbbreviation}
      />
    </AbbreviationControl>
  );
};

export default TextAbbreviationControl;
