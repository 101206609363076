import _ from 'lodash';

import { IMap } from 'typings/DeepIMap';
import * as Models from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import { getAlignment } from './getAlignment';

export function getImageMobileSettings(
  relation: Models.RegularRelationMap<Models.ImageRelationStyles>,
  documents: IMap<Models.CombinedDocuments>,
): Schemas.ImageMobileSettings {
  if (!relation.getIn(['styles', 'mobileSettingsApplied'])) {
    return {} as Schemas.ImageMobileSettings;
  }

  const mobileImageId = relation.getIn(['styles', 'mobileViewImage', 'id']);
  const mobileImageDocumentId = relation.getIn(['styles', 'mobileViewImage', 'documentId']);

  let imageDocument;

  if (mobileImageId) {
    imageDocument = documents.get(mobileImageId);
  }

  if (!imageDocument && mobileImageDocumentId) {
    imageDocument = documents.find(document => document.get('documentId') === mobileImageDocumentId);
  }

  return {
    width: String(_.round((relation.getIn(['styles', 'mobileScale']) || 1) * 100)),
    ...getAlignment(relation.getIn(['styles', 'mobileAlignment'])),
    documentId: imageDocument?.get('documentId'),
    src: imageDocument?.getIn(['_internalInfo', 'source']),
  };
}
