import _ from 'lodash';

import { DocumentRefreshStatus } from 'const';
import * as Models from 'models';

export function getNewDocumentsQuantity<T extends Models.RefreshProcessArgs>(arg: T): void {
  const { documentStatusMap } = arg;

  arg.newDocumentsQuantity = _.filter(documentStatusMap, status => status === DocumentRefreshStatus.ADDED_IN_PROMOMATS).length;
}
