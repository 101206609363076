import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { intlGet } from 'utils/intlGet';
import { ImagePlaceholderProps } from './models';
import styles from './styles.module.scss';

const ImagePlaceholder: React.FunctionComponent<ImagePlaceholderProps> = (props) => {
  const { canDrop, showIcon } = props;

  const iconType = canDrop ? IconType.PLUS : IconType.IMAGE;
  const hintText = canDrop ? intlGet('Artboard.Layout.DropImage', 'Hint') : intlGet('Artboard.Layout.Image', 'Hint');

  return (
    <div className={styles.ImagePlaceholder}>
      {
        showIcon && <div className={styles.icon}>
          <Icon type={iconType} size="badge" color="secondary" />
        </div>
      }
      <div className={styles.hint}>{hintText}</div>
    </div>
  );
};

export default ImagePlaceholder;
