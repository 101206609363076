import React from 'react';
/* eslint-disable max-len */

const Abbreviation = (): JSX.Element => (
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7227 10.3984V3.98177H3.93099V16.013L4.98516 14.9818H18.5977V3.98177H16.306V10.3984L14.0143 9.02344L11.7227 10.3984ZM2.09766 20.4818V3.98177C2.09766 3.4776 2.27717 3.04601 2.6362 2.68698C2.99523 2.32795 3.42682 2.14844 3.93099 2.14844H18.5977C19.1018 2.14844 19.5334 2.32795 19.8924 2.68698C20.2515 3.04601 20.431 3.4776 20.431 3.98177V14.9818C20.431 15.4859 20.2515 15.9175 19.8924 16.2766C19.5334 16.6356 19.1018 16.8151 18.5977 16.8151H5.76432L2.09766 20.4818Z"/>
  </svg>
);

export default Abbreviation;
