import _ from 'lodash';

import { createBackgroundImage } from 'factories/style/backgroundImage';
import * as Models from 'models';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

interface ValidateBackgroundImagesResult {
  artboards: Models.Artboards;
  sections: Models.Sections;
  layouts: Models.LayeredCombinedLayouts;
  relations: Models.LayeredRelations;
}

type Entity = Models.CombinedLayout | Models.LayeredLayout | Models.Artboard | Models.Section | { styles: Models.CombinedRelationStyles };
type Callback = (backgroundImageId: string) => boolean;

/**
 * Returns a callback function for mapping entities,
 * which uses passed condition callback for defining whether need to reset a background image style
 * @param callback - returns true if need to reset current background image style, otherwise returns false
 * @returns - returns callback for mapping entities
 */
export const removeBackgroundImageFromStylesByCondition = (callback: Callback) => <T extends Entity>(entity: T): T => {
  const styles = entity && entity.styles as Models.CombinedAssetRelationStyles;
  const backgroundImage = styles && styles.backgroundImage;
  const backgroundImageId = backgroundImage && backgroundImage.id;

  if (backgroundImageId && callback(backgroundImageId)) {
    (entity.styles as Models.CombinedAssetRelationStyles).backgroundImage = createBackgroundImage();
  }

  return entity;
};

/**
 * Validates all passed entities with background image style by condition from callback
 * @param artboards
 * @param sections
 * @param layouts
 * @param relations
 * @param callback - returns true if need to reset current background image style, otherwise returns false
 */
export const validateBackgroundImages = (
  artboards: Models.Artboards,
  sections: Models.Sections,
  layouts: Models.LayeredCombinedLayouts,
  relations: Models.LayeredRelations,
  callback: Callback,
): ValidateBackgroundImagesResult => {
  const removeBackgroundImageFromStyles = removeBackgroundImageFromStylesByCondition(callback);
  const processedArtboards = _.mapValues(artboards, removeBackgroundImageFromStyles) as Models.Artboards;
  const processedSections = _.mapValues(sections, removeBackgroundImageFromStyles) as Models.Sections;
  const processedLayouts = _.mapValues(layouts, removeBackgroundImageFromStyles) as Models.LayeredCombinedLayouts;
  const processedRelations = _.mapValues(relations, (relation) => {
    if (!isRegularRelation(relation)) {
      return relation;
    }

    relation.styles = _(relation.styles)
      .mapValues(styles => removeBackgroundImageFromStyles({ styles }).styles)
      .value() as Models.Layers<Models.CombinedRelationStyles>;

    return relation;
  });

  return {
    artboards: processedArtboards,
    sections: processedSections,
    layouts: processedLayouts,
    relations: processedRelations,
  };
};

export const getRemoveNonexistentBackgroundImagesCallback = (documents: Models.CombinedDocuments): Callback => {
  return (backgroundImageId: string) => !documents[backgroundImageId];
};

export const getRemoveBackgroundImageByIdCallback = (imageId: string): Callback => {
  return (backgroundImageId: string) => backgroundImageId === imageId;
};

