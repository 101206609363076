import React from 'react';
/* eslint-disable max-len */

const ExclamationMark = (): JSX.Element => (
  <svg viewBox="0 0 20 20">
    <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M11,15 L9,15 L9,13 L11,13 L11,15 L11,15 Z M11,11 L9,11 L9,5 L11,5 L11,11 L11,11 Z" />
  </svg>
);

export default ExclamationMark;
