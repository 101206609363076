import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import Dropdown from 'containers/Toolbar/Dropdowns/Link';
import { LinkControlProps } from './models';

const LinkControl: React.FunctionComponent<LinkControlProps> = (props) => {
  const {
    isDisabled,
    link,
    toggleLink,
    className,
    enableInternalLinks = true,
    controlState = ControlState,
    returnFocusToEditor,
    priority,
  } = props;

  const {
    control,
    isOpen,
    state,
    toggleDropdown,
  } = useControlWithDropdown({ priority, controlState, closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      className={className}
      controlState={controlState}
      disabled={isDisabled}
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.LINK}
      type={IconType.LINK}
    >
      {isOpen && <Dropdown link={link} toggleLink={toggleLink} toggleDropdown={toggleDropdown} enableInternalLinks={enableInternalLinks} />}
    </Control>
  );
};

export default LinkControl;
