import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { IAction } from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';

export function* deleteSSI() {
  try {
    const actions: IAction[] = [];
    const accepted: boolean = yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title: intlGet('ConfirmationWindow.Title', 'DeleteSSI'),
        message: intlGet('ConfirmationWindow.Message', 'DeleteSSI'),
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Delete'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'Cancel'),
      },
    );

    if (!accepted) {
      return;
    }

    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    actions.push(updateArtboard(artboard.set('ssi', null)));

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'Artboards.deleteSSI', 'DeleteSSI');
  }
}
