import { ARTBOARD_WIDTH, ProjectType, ProjectsConfig } from 'const';
import * as Models from 'models';
import { getValue, getValueIn } from './getter';

export function getScreenWidth(
  screenDefinition: Models.MasterScreen.AnyScreenDefinition,
  artboard: Models.AnyArtboard,
  projectType: ProjectType,
): number {
  const { areScreensResizable } = ProjectsConfig[projectType];

  if (!areScreensResizable) {
    return screenDefinition && getValue(screenDefinition, 'screenWidth') || ARTBOARD_WIDTH;
  }

  return getValueIn(artboard, ['styles', 'width'], ARTBOARD_WIDTH);
}
