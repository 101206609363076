import _ from 'lodash';
import guid from 'uuid';

import { Artboard, Artboards } from 'models';
import { createArtboardStyles } from './style';

export const createArtboard = ({
  layoutIds = [],
  id = guid(),
  ssi = null,
  styles,
} = {} as Partial<Artboard>): Artboard => {
  return {
    id,
    layoutIds,
    ssi,
    styles: createArtboardStyles(styles),
  };
};

export const createArtboards = (artboards: Artboards): Artboards => {
  return _.reduce(
    artboards,
    (artboards, artboard) => {
      const createdArtboard = createArtboard(artboard);
      artboards[createdArtboard.id] = createdArtboard;

      return artboards;
    },
    {} as Artboards,
  );
};
