import { useSelector } from 'react-redux';
import * as commonSelectors from 'containers/Common/selectors';
import * as Models from 'models';

export function useBrandFontsForRelation(
  relationId: string,
): Models.BrandFontsList | undefined {
  const fonts = useSelector(commonSelectors.flatFontsByRelationId);

  return fonts.get(relationId);
}
