import { fromJS } from 'immutable';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Layer } from 'const';
import {
  BrandColorsList,
  CombinedDocumentsMap,
  ImageRelationStyles,
  LayeredRegularRelationMap,
} from 'models';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getExtraWidth } from 'utils/getExtraWidth';
import { ImageStyles, ImageStylesConfig, ImageStylesSetters, imageStylesFromSource, imageStylesToCSS } from './styles';

type Context = {
  cellWidth: number;
  source?: string;
  colors: BrandColorsList;
  images: CombinedDocumentsMap;
};

type SetPropertyAction = <T extends keyof ImageStyles>(prop: T, value: ImageStyles[T]) => void;

export function useStyles(
  image: LayeredRegularRelationMap<ImageRelationStyles>,
  layer: Layer,
  context: Context,
  config: ImageStylesConfig,
): [ImageStyles, ImageStylesSetters, CSSProperties] {
  const { cellWidth, source, colors, images } = context;
  const { allowBackgroundImage, allowBackgroundOpacity } = config;
  const imageStyles = image.getIn(['styles', layer]);

  const [styles, setStyles] = useState(() => imageStylesFromSource(
    imageStyles,
    colors,
    cellWidth,
  ));
  // cellWidth was made to affect values only on mount. Why?

  useEffect(() => {
    setStyles(imageStylesFromSource(imageStyles, colors));
  }, [image, layer, colors]);

  const setStylesProperty = useCallback<SetPropertyAction>(
    (prop, value) => setStyles(state => ({ ...state, [prop]: value })),
    [],
  );

  const setters = useMemo<ImageStylesSetters>(
    () => ({
      alignment: val => setStylesProperty('alignment', val),
      altTag: val => setStylesProperty('altTag', val),
      backgroundColor: (value): void => {
        setStylesProperty('backgroundGradient', undefined);
        setStylesProperty('backgroundColor', value);
      },
      backgroundColorOpacity: val => setStylesProperty('backgroundColorOpacity', val),
      backgroundGradient: (gradient, backupColor): void => {
        setStylesProperty('backgroundGradient', gradient);
        setStylesProperty('backgroundColor', backupColor);
      },
      backgroundImage: val => setStylesProperty('backgroundImage', val),
      border: border => setStyles(state => ({
        ...state,
        border,
        extraHeight: getExtraHeight(fromJS({ padding: state.padding, border })),
        extraWidth: getExtraWidth(fromJS({ padding: state.padding, border })),
      })),
      height: height => setStyles(state => ({ ...state, height })),
      link: val => setStylesProperty('link', val),
      mobileAlignment: val => setStylesProperty('mobileAlignment', val),
      mobileScale: val => setStylesProperty('mobileScale', val),
      mobileSettingsApplied: val => setStylesProperty('mobileSettingsApplied', val),
      mobileViewImage: val => setStylesProperty('mobileViewImage', val),
      padding: padding => setStyles(state => ({
        ...state,
        padding,
        extraHeight: getExtraHeight(fromJS({ padding, border: state.border })),
        extraWidth: getExtraWidth(fromJS({ padding, border: state.border })),
      })),
      scale: val => setStylesProperty('scale', val),
      borderRadius: val => setStylesProperty('borderRadius', val),
      width: width => setStyles(state => ({ ...state, width })),
      abbreviations: abbreviations => setStyles(state => ({ ...state, abbreviations })),
    }),
    [],
  );

  const css = useMemo(
    () => imageStylesToCSS(
      styles,
      { source, images },
      { allowBackgroundImage, allowBackgroundOpacity }),
    [styles, source, allowBackgroundImage, allowBackgroundOpacity],
  );

  return [styles, setters, css];
}
