import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import ColorPicker from 'containers/Toolbar/Shared/ColorPicker';
import * as Models from 'models';
import {
  backgroundColorFromRelation as getBackgroundColor,
  colorToSource,
  colorToTint,
  outsideBackgroundColor as getOutsideBackgroundColor,
} from 'utils/converters';
import { intlGet } from 'utils/intlGet';
import { BackgroundColorTabProps } from './models';
import styles from './styles.module.scss';

enum ColorTab {
  INSIDE_ARTBOARD = 'InsideArtboard',
  OUTSIDE_ARTBOARD = 'OutsideArtboard',
}

const TabTitles: Record<ColorTab, () => string> = {
  [ColorTab.INSIDE_ARTBOARD]: () => intlGet('ScreenSettingsWindow.BackgroundColor', 'Inside'),
  [ColorTab.OUTSIDE_ARTBOARD]: () => intlGet('ScreenSettingsWindow.BackgroundColor', 'Outside'),
};

const BackgroundColorTab: React.FunctionComponent<BackgroundColorTabProps> = (props) => {
  const {
    artboard,
    colors,
    updateArtboard,
  } = props;

  const [activeColorTab, setActiveColorTab] = React.useState(ColorTab.INSIDE_ARTBOARD);

  const backgroundColor = getBackgroundColor(colors, artboard);
  const outsideBackgroundColor = getOutsideBackgroundColor(colors, artboard);

  const toggleBackgroundColor = React.useCallback(
    (backgroundColor: Models.BrandColorMap): void => {
      updateArtboard(
        artboard
          .setIn(['styles', 'backgroundColor'], colorToSource(backgroundColor))
          .setIn(['styles', 'backgroundColorTint'], colorToTint(backgroundColor)),
      );
    },
    [artboard],
  );

  const toggleOutsideBackgroundColor = React.useCallback(
    (outsideBackgroundColor: Models.BrandColorMap): void => {
      updateArtboard(
        artboard
          .setIn(['styles', 'outsideBackgroundColor'], colorToSource(outsideBackgroundColor))
          .setIn(['styles', 'outsideBackgroundColorTint'], colorToTint(outsideBackgroundColor)),
      );
    },
    [artboard],
  );

  const [activeColor, toggleColor] = activeColorTab === ColorTab.OUTSIDE_ARTBOARD
    ? [outsideBackgroundColor, toggleOutsideBackgroundColor]
    : [backgroundColor, toggleBackgroundColor];

  return (
    <div className={styles.BackgroundColorTab}>
      <div className={styles.colorTabs}>
        {_.map(ColorTab, tab => (
          <div
            key={tab}
            onClick={setActiveColorTab.bind(null, tab)}
            className={classNames(styles.tab, { [styles.active]: tab === activeColorTab })}
          >
            {TabTitles[tab]()}
          </div>
        ))}
      </div>
      <div className={styles.colorPicker}>
        <ColorPicker
          activeColor={activeColor}
          toggleColor={toggleColor}
          resetIconColor="grey"
          resetSectionBorderColor="lightGrey"
        />
      </div>
    </div>
  );
};

export default BackgroundColorTab;
