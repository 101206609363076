import * as Models from 'models';
import { linkDocumentToProject } from 'services/api';

export async function linkDocument<T extends Models.Document>(rootDocument: Models.RootDocumentMap, documentToLink: T): Promise<void> {
  const { documentId, entityType } = documentToLink;
  const [majorVersion, minorVersion] = rootDocument.get('version').split('.');
  const rootDocumentId = rootDocument.get('documentId');

  await linkDocumentToProject(rootDocumentId, documentId, entityType, +majorVersion, +minorVersion);
}
