import React from 'react';
/* eslint-disable max-len */

const LineHeightOne = (): JSX.Element => (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <defs>
      <path d="M6,20 L24,20 L24,18 L6,18 L6,20 L6,20 Z M6,16 L24,16 L24,14 L6,14 L6,16 L6,16 Z M6,12 L24,12 L24,10 L6,10 L6,12 L6,12 Z" id="normalLineHeight-id" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="normalLineHeight-mask" fill="white">
        <use xlinkHref="#normalLineHeight-id" />
      </mask>
      <g mask="url(#normalLineHeight-mask)" fill="#FFFFFF">
        <g transform="translate(2.000000, 2.000000)" id="White">
          <rect x="0" y="0" width="26" height="26" />
        </g>
      </g>
    </g>
  </svg>
);

export default LineHeightOne;
