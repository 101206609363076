import { ProjectType, ProjectsConfig } from 'const';
import * as Models from 'models';

export function getPackageName(rootDocument: Models.RootDocumentMap, projectType: ProjectType): string {
  const { packageArchiveNameField, packageArchivePostfix } = ProjectsConfig[projectType];
  const projectName = rootDocument.get(packageArchiveNameField);

  return `${projectName}${packageArchivePostfix}`;
}

export function getTranslationPackageName(projectName: string, projectLanguage: string): string {
  return `${projectName}.${projectLanguage}.forTranslation.zip`;
}
