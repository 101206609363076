import _ from 'lodash';

import { Priority } from './constants';

const eventHandlers = [[], [], [], [], []] as EventListener[][];

const withPriorityListenerByOriginal = new Map<EventListener, EventListener>();

const isTopPriority = (priority: Priority): boolean => {
  return _(eventHandlers).drop(priority + 1).flatten().every(handler => !handler);
};
const wrapWithPriority = (priority: Priority, listener: EventListener): EventListener => (event) => {
  const { type } = event;

  // HACK: we mustn't prioritize listeners on dragstart event,
  // a toolbar should be closed, when user drag and drop a content on an artboard.
  if (type === 'dragstart' || isTopPriority(priority)) {
    listener(event);
  }
};

export const addEventListener = (priority: Priority, listener: EventListener): void => {
  const withPriority = wrapWithPriority(priority, listener);
  withPriorityListenerByOriginal.set(listener, withPriority);
  eventHandlers[priority].push(withPriority);
  document.addEventListener('mousedown', withPriority);
  document.addEventListener('dragstart', withPriority);
};

export const removeEventListener = (priority: Priority, listener: EventListener): void => {
  const withPriority = withPriorityListenerByOriginal.get(listener);
  const index = eventHandlers[priority].findIndex(listener => listener === withPriority);

  if (index !== -1) {
    document.removeEventListener('mousedown', eventHandlers[priority][index]);
    document.removeEventListener('dragstart', eventHandlers[priority][index]);
    withPriorityListenerByOriginal.delete(listener);
    eventHandlers[priority].splice(index, 1);
  }
};
