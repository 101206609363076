import { CSSProperties } from 'react';
import { IMap } from 'typings/DeepIMap';
import {
  AssetAlignmentMap,
  BackgroundImageMap,
  Border,
  BorderRadiusMap,
  BoxPropertyMap,
  BrandColor,
  CallToActionStyles,
  CombinedDocumentsMap,
  PaddingMap,
} from 'models';
import * as Models from 'models';
import {
  brandBorderFromSource,
  brandBorderToSource,
  brandColorFromHEX,
  colorFromSource,
  colorToSource,
  colorToSourceTint,
} from 'utils/converters';
import { brandColorGradientFromSource, brandColorGradientToSource } from 'utils/converters/colorGradient';
import { getAlignment, getBorderCSSProperties, getBorderRadius, getPadding, getTextAlignment } from 'utils/styles';
import { getCSSBackgroundBrand } from 'utils/styles/getBackground';

export type CTAStylesContext = {
  colors: Models.BrandColorsList;
  defaultAssetBackgroundColor: string;
  images: CombinedDocumentsMap;
};

export type CTAStylesConfig = {
  allowBackgroundOpacity: boolean;
};

export type CTAStyles = {
  alignment: AssetAlignmentMap;
  assetBackgroundColor?: Models.BrandColorMap;
  assetBackgroundGradient?: Models.BrandColorGradientMap;
  assetBackgroundOpacity: number;
  assetBorderRadius: BorderRadiusMap;
  assetPadding: PaddingMap;
  backgroundColor?: Models.BrandColorMap;
  backgroundColorOpacity: number;
  backgroundGradient?: Models.BrandColorGradientMap;
  backgroundImage?: BackgroundImageMap;
  border?: IMap<Border<BrandColor>>;
  borderRadius?: BorderRadiusMap;
  fitToCell: boolean;
  height: number;
  padding: BoxPropertyMap;
  textAlignment: AssetAlignmentMap;
  width: number;
};

type SpecialSetters = {
  assetBackgroundGradient: (gradient?: Models.BrandColorGradientMap, backupColor?: Models.BrandColorMap) => void;
  backgroundGradient: (gradient?: Models.BrandColorGradientMap, backupColor?: Models.BrandColorMap) => void;
};

export type CTAStylesSetters = {
  [K in keyof Omit<CTAStyles, keyof SpecialSetters>]-?: (value: CTAStyles[K]) => void;
} & {
  [K2 in keyof SpecialSetters]: SpecialSetters[K2];
};

export function ctaStylesFromSource(
  source: IMap<CallToActionStyles>,
  colors: Models.BrandColorsList,
): CTAStyles | null {

  if (!source) {
    return null;
  }

  return {
    alignment: source.get('alignment'),
    assetBackgroundColor: colorFromSource(
      colors,
      source.get('assetBackgroundColor'),
      source.get('assetBackgroundColorTint'),
    ),
    assetBackgroundGradient: brandColorGradientFromSource(colors, source.get('assetBackgroundGradient')),
    assetBackgroundOpacity: source.get('assetBackgroundOpacity'),
    assetBorderRadius: source.get('assetBorderRadius'),
    assetPadding: source.get('assetPadding'),
    backgroundColor: colorFromSource(
      colors,
      source.get('backgroundColor'),
      source.get('backgroundColorTint'),
    ),
    backgroundColorOpacity: source.get('backgroundColorOpacity'),
    backgroundGradient: brandColorGradientFromSource(colors, source.get('backgroundGradient')),
    backgroundImage: source.get('backgroundImage'),
    border: brandBorderFromSource(colors, source.get('border')),
    borderRadius: source.get('borderRadius'),
    fitToCell: source.get('fitToCell'),
    height: source.get('height'),
    padding: source.get('padding'),
    textAlignment: source.get('textAlignment'),
    width: source.get('width'),
  };
}

export function ctaStylesToSource(
  styles: CTAStyles,
  source: IMap<CallToActionStyles>,
): IMap<CallToActionStyles> {
  return source.withMutations(values => values
    .set('alignment', styles.alignment)
    .set('assetPadding', styles.assetPadding)
    .set('assetBackgroundColor', colorToSource(styles.assetBackgroundColor))
    .set('assetBackgroundColorTint', colorToSourceTint(styles.assetBackgroundColor))
    .set('assetBackgroundOpacity', styles.assetBackgroundOpacity)
    .set('assetBackgroundGradient', brandColorGradientToSource(styles.assetBackgroundGradient))
    .set('assetBorderRadius', styles.assetBorderRadius)
    .set('backgroundColor', colorToSource(styles.backgroundColor))
    .set('backgroundColorTint', colorToSourceTint(styles.backgroundColor))
    .set('backgroundColorOpacity', styles.backgroundColorOpacity)
    .set('backgroundGradient', brandColorGradientToSource(styles.backgroundGradient))
    .set('backgroundImage', styles.backgroundImage)
    .set('border', brandBorderToSource(styles.border))
    .set('borderRadius', styles.borderRadius)
    .set('fitToCell', styles.fitToCell)
    .set('height', styles.height)
    .set('padding', styles.padding)
    .set('textAlignment', styles.textAlignment)
    .set('width', styles.width),
  );
}

export function ctaStylesToCSSForElement(
  styles: CTAStyles,
  context: Pick<CTAStylesContext, 'defaultAssetBackgroundColor'>,
  config: CTAStylesConfig,
): CSSProperties {
  const { defaultAssetBackgroundColor } = context;
  const { allowBackgroundOpacity } = config;

  const bgColor = styles.assetBackgroundColor
    ? styles.assetBackgroundColor
    : brandColorFromHEX(defaultAssetBackgroundColor);
  const bgOpacity = allowBackgroundOpacity ? styles.assetBackgroundOpacity : undefined;

  return {
    ...getAlignment(styles.textAlignment),
    ...getBorderRadius(styles.assetBorderRadius),
    ...getCSSBackgroundBrand(bgColor, bgOpacity, styles.assetBackgroundGradient, undefined),
    ...getPadding(styles.assetPadding),
    ...getTextAlignment(styles.textAlignment),
  };
}

export function ctaStylesToCSSForContainer(
  styles: CTAStyles,
  context: Pick<CTAStylesContext, 'images'>,
  config: CTAStylesConfig,
): CSSProperties {
  const { images } = context;
  const { allowBackgroundOpacity } = config;

  return {
    ...getAlignment(styles.alignment),
    ...getBorderCSSProperties(styles.border),
    ...getBorderRadius(styles.borderRadius),
    ...getCSSBackgroundBrand(
      styles.backgroundColor,
      allowBackgroundOpacity ? styles.backgroundColorOpacity : undefined,
      styles.backgroundGradient,
      styles.backgroundImage,
      images,
    ),
    ...getPadding(styles.padding),
  };
}
