import React from 'react';
/* eslint-disable max-len */

const ToolbarJustify = (): JSX.Element => (
  <svg viewBox="0 0 18 18">
    <path d="M0,18 L18,18 L18,16 L0,16 L0,18 L0,18 Z M0,14 L18,14 L18,12 L0,12 L0,14 L0,14 Z M0,10 L18,10 L18,8 L0,8 L0,10 L0,10 Z M0,6 L18,6 L18,4 L0,4 L0,6 L0,6 Z M0,0 L0,2 L18,2 L18,0 L0,0 L0,0 Z" />
  </svg>
);

export default ToolbarJustify;
