import Draft from 'draft-js';

import * as Models from 'models';
import { getValues } from './getter';
import { removeTags } from './removeTags';

export function getCleanText(textComponent: Models.TextComponent | Models.TextComponentMap): string {
  const { rawContent, text } = getValues(textComponent, ['rawContent', 'text']);

  return rawContent
    ? Draft.EditorState.createWithContent(Draft.convertFromRaw(JSON.parse(rawContent))).getCurrentContent().getPlainText()
    : removeTags(text);
}
