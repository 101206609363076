import Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';
import { ModalType } from 'const';
import { CreateCustomClickHandlerWithToolbar, Priority, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import * as Models from 'models';
import { getInsertPosition, getSSIInsertPosition } from 'utils/dragAndDrop';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isFixedSection } from 'utils/screenDefinitions/isFixedSection';
import { LayoutDragSourceTypes, SSIDragSourceTypes } from './dnd';
import { SectionProps, UseLayoutDragHotspotProps } from './models';

export const useLayoutDragHotspot = <P extends UseLayoutDragHotspotProps>(
  props: P,
  layoutIds: Immutable.List<string>,
  groupLayoutId?: string,
) => {
  const {
    dragHotspotGroupLayoutId,
    dragHotspotPosition,
    getLayoutsRefs,
    dragSourceType,
    updateDragHotspotGroupLayoutId,
    updateDragHotspotPosition,
  } = props;

  const draggingLayout = (): boolean => LayoutDragSourceTypes.includes(dragSourceType);

  const getLayoutDragHotspotPosition = _.throttle(
    (mouseY: number) => {
      if (!draggingLayout()) {
        return;
      }

      const layoutsRefs = getLayoutsRefs();

      const separators = layoutIds.reduce(
        (separators, layoutId, position) => {
          const element = layoutsRefs.get(layoutId);

          if (element) {
            const { top, height } = element.getBoundingClientRect();
            separators[position] = Math.floor(top + height / 2);
          }

          return separators;
        },
        {} as Record<string, number>,
      );

      const position = getInsertPosition(mouseY, separators);

      if (dragHotspotPosition !== position || dragHotspotGroupLayoutId !== groupLayoutId) {
        updateDragHotspotGroupLayoutId(groupLayoutId);
        updateDragHotspotPosition(position);
      }
    },
    100,
  );

  return {
    isDraggingLayout: draggingLayout,
    getLayoutDragHotspotPosition,
  };
};

export const useSection = (props: SectionProps) => {
  const {
    allLayouts,
    artboardLayoutIds,
    dragSourceType,
    isDraggingAsset,
    isOpenToolbar,
    isResizingColumn,
    isResizingLayout,
    isResizingRow,
    layouts,
    section,
    sectionHeight,
    showModal,
    ssiHotspotPosition,
    updateSSIHotspotPosition,
  } = props;

  const createCustomClickHandler: CreateCustomClickHandlerWithToolbar<HTMLDivElement> = (nameContainer, toolbar, toggleEditModeOff, editMode) => {
    return (event) => {
      const eventTarget = event.target as Node;

      if (!editMode || toolbar.contains(eventTarget) || !nameContainer.current || nameContainer.current.contains(eventTarget)) {
        return;
      }

      toggleEditModeOff();
    };
  };

  const {
    on: editMode,
    container: sectionHintRef,
    toggleOn: toggleEditModeOn,
  } = useClickOutsideWithToggle<HTMLDivElement>(Priority.TOOLBAR, { createCustomClickHandler });

  const enableEditMode = (): void => { !isOpenToolbar && toggleEditModeOn(); };

  const sectionRef = React.useRef<HTMLDivElement>();
  const fullContentRef = React.useRef<HTMLDivElement>();
  const resizeObserver = React.useRef<ResizeObserver>();

  const { isDraggingLayout, getLayoutDragHotspotPosition } = useLayoutDragHotspot(props, artboardLayoutIds);
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [isExpand, setIsExpand] = React.useState(false);
  const [canExpand, setCanExpand] = React.useState(false);
  const [isTooltipShown, setIsTooltipShown] = React.useState(false);
  const draggingLayout = isDraggingLayout();

  React.useEffect(
    () => {
      if (!isFixedSection(section)) {
        return;
      }

      const { current: { offsetHeight: contentHeight } } = fullContentRef;

      if (contentHeight > sectionHeight) {
        setCanExpand(true);
      }
    },
    [],
  );

  const handleContentResize = React.useCallback(
    _.throttle<ResizeObserverCallback>(
      (entries) => {
        const { target: resizedFullContent } = entries[0];
        const isDraggingContent = draggingLayout || isDraggingAsset;

        if (isDraggingContent) {
          return;
        }

        if ((resizedFullContent as HTMLDivElement).offsetHeight > sectionHeight) {
          setCanExpand(true);
          // TODO: expand section when resize image component as well
          isMouseOver && (isResizingLayout || isResizingColumn || isResizingRow) && setIsExpand(true);
        } else {
          setCanExpand(false);
          setIsExpand(false);
        }
      },
      100,
    ),
    [draggingLayout, isDraggingAsset, sectionHeight, isResizingLayout, isResizingColumn, isResizingRow],
  );

  React.useEffect(
    () => {
      resizeObserver.current = new ResizeObserver(handleContentResize);
      fullContentRef.current && resizeObserver.current.observe(fullContentRef.current);

      return () => {
        fullContentRef.current &&
          resizeObserver.current &&
          resizeObserver.current.unobserve(fullContentRef.current);
      };
    },
    [handleContentResize],
  );

  const isResizeActive = isResizingLayout || isResizingColumn || isResizingRow;

  const onMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isResizeActive) {
      return;
    }

    setIsMouseOver(false);
  };
  const onMouseEnter: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isResizeActive) {
      return;
    }

    setIsMouseOver(true);
  };
  const onMouseOver: React.MouseEventHandler<HTMLDivElement> = _.throttle(
    () => {
      if (isResizeActive || isMouseOver) {
        return;
      }

      setIsMouseOver(true);
    },
    100,
  );

  const toggleExpand = (): void => setIsExpand(!isExpand);
  const isEditMode = (): void => setIsExpand(true);

  const showTooltip = (): void => setIsTooltipShown(true);
  const hideTooltip = (): void => setIsTooltipShown(false);

  const isDraggingSSI = (): boolean => SSIDragSourceTypes.includes(dragSourceType);

  const isHighlighted = (): boolean => {
    const { section, isDraggingAsset } = props;
    const isDraggingContent = isDraggingLayout() || isDraggingAsset || isDraggingSSI();

    return !!section && (editMode || isMouseOver || isDraggingContent);
  };

  const getSSIDragHotspotPosition = _.throttle(
    (mouseX: number, mouseY: number) => {
      const { current: section } = sectionRef;

      if (isDraggingSSI() && section) {
        const sectionRect = section.getBoundingClientRect();
        const position = getSSIInsertPosition(mouseX, mouseY, sectionRect);

        if (ssiHotspotPosition !== position) {
          updateSSIHotspotPosition(position);
        }
      }
    },
    100,
  );

  const saveAsGroupLayout = () => {
    const sectionDisplayName = section.get('displayName');
    const groupLayout = layouts.size === 1 && isGroupLayout(layouts.first())
      ? layouts.first(Immutable.Map() as Models.GroupLayoutMap).toJS() as Models.GroupLayout
      : null;
    const layoutsInsideGRL = groupLayout
      ? _.map(groupLayout.layoutIds, layoutId => allLayouts.get(layoutId).toJS()) as Models.Layout[]
      : layouts.valueSeq().toJS() as Models.Layout[];

    showModal(
      ModalType.SAVE_GROUP_LAYOUT,
      {
        groupLayout,
        layouts: layoutsInsideGRL,
        sectionDisplayName,
      },
    );
  };

  return {
    sectionRef,
    fullContentRef,

    editMode,
    sectionHintRef,
    enableEditMode,
    isEditMode,

    canExpand,

    isMouseOver,
    onMouseLeave,
    onMouseEnter,
    onMouseOver,

    isExpand,
    toggleExpand,

    isTooltipShown,
    showTooltip,
    hideTooltip,

    isDraggingSSI,
    isDraggingLayout,
    isHighlighted,

    getSSIDragHotspotPosition,
    getLayoutDragHotspotPosition,
    saveAsGroupLayout,
  };
};
