import _ from 'lodash';

import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { pickWithOrder } from 'utils/pickWithOrder';
import { isGroupLayout } from './isGroupLayout';
import { isPlainLayout } from './isPlainLayout';

export function flattenLayouts<T extends Models.CombinedLayouts>(
  layoutsToBeFlattened: T | DeepIMap<T>,
  allLayouts: T | DeepIMap<T>,
  omitGroupLayouts = false,
): Models.Layouts | Models.CombinedLayouts | Models.LayoutsMap | Models.CombinedLayoutsMap {
  if (isImmutable(layoutsToBeFlattened) && isImmutable(allLayouts)) {
    return layoutsToBeFlattened
      .flatMap((layout, id) => isGroupLayout(layout)
        ? [
          [id, layout],
          ...flattenLayouts(
            pickWithOrder(allLayouts, (layout as Models.GroupLayoutMap).get('layoutIds')),
            allLayouts,
          ) as Models.LayoutsMap | Models.CombinedLayoutsMap,
        ]
        : [
          [id, layout],
        ],
      )
      .filter(layout => !omitGroupLayouts || isPlainLayout(layout));
  }

  return _(layoutsToBeFlattened as Models.CombinedLayouts)
    .flatMap<Models.CombinedLayout>(layout => isGroupLayout(layout)
    ? [
      layout,
      ..._.values(flattenLayouts(
        pickWithOrder(allLayouts as Models.CombinedLayouts, layout.layoutIds),
        allLayouts as Models.CombinedLayouts,
      )),
    ] as Models.CombinedLayout[]
    : layout as Models.CombinedLayout,
  )
    .filter(layout => !omitGroupLayouts || isPlainLayout(layout))
    .keyBy(({ id }) => id)
    .value();
}

export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: T,
  layouts: Models.LayeredCombinedLayouts,
): Models.LayeredCombinedLayouts;
export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: T,
  layouts: Models.LayeredCombinedLayouts,
  omitGroupLayouts: true,
): Models.LayeredCombinedLayouts;
export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: T,
  layouts: Models.CombinedLayouts | Models.LayeredCombinedLayouts,
): Models.CombinedLayouts;
export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: T,
  layouts: Models.CombinedLayouts | Models.LayeredCombinedLayouts,
  omitGroupLayouts: true,
): Models.Layouts;
export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: DeepIMap<T>,
  layouts: Models.CombinedLayoutsMap,
): Models.CombinedLayoutsMap;
export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: DeepIMap<T>,
  layouts: Models.CombinedLayoutsMap,
  omitGroupLayouts: true,
): Models.LayoutsMap;
export function getFlattenedLayouts<T extends { layoutIds: string[] }>(
  data: T | DeepIMap<T>,
  layouts: Models.CombinedLayouts | Models.CombinedLayoutsMap | Models.LayeredCombinedLayouts,
  omitGroupLayouts = false,
): Models.CombinedLayouts | Models.CombinedLayoutsMap | Models.LayeredCombinedLayouts {
  const layoutsToBeFlattened = isImmutable(data) && isImmutable(layouts as Models.CombinedLayouts | Models.CombinedLayoutsMap)
    ? pickWithOrder(layouts as Models.CombinedLayoutsMap, data.get('layoutIds'))
    : _((data as T).layoutIds).map<[string, Models.CombinedLayout]>(id => [id, layouts[id]]).fromPairs().value();

  return flattenLayouts(layoutsToBeFlattened, layouts as Models.CombinedLayoutsMap, omitGroupLayouts);
}

