import React from 'react';

const ArrowRotate = (): JSX.Element => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    viewBox="-100 -100 712 712"
    height="200px"
    width="200px"
  >
    <path
      // eslint-disable-next-line max-len
      d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z"
    />
  </svg>
);

export default ArrowRotate;


