import { first } from 'lodash';
import * as React from 'react';

import { Details } from 'components/Details';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ReferenceCitation } from 'models';
import { intlGet } from 'utils/intlGet';
import { ReferenceCitationDetailsProps, ReferenceCitationDetailsState } from './models';
import styles from './styles.module.scss';

class ReferenceCitationDetails extends React.PureComponent<ReferenceCitationDetailsProps, ReferenceCitationDetailsState> {
  componentWillUnmount() {
    this.props.deleteSelectedCitation();
  }

  private onClose = (): void => {
    const { hideModal, modalWindowId } = this.props;

    hideModal(modalWindowId);
  };

  private renderSubtitle = (): JSX.Element => {
    const { selectedCitation } = this.props;
    const { number, product, status } = selectedCitation.toJS() as ReferenceCitation;
    const productName = first(product);

    return (
      <div className={styles.metadata}>
        {/* product and status can be absent, e.g. in components from ASM7 */}
        {status && <span className={styles.status}>{ status.toUpperCase() }</span>}
        <span className={styles.number}>{number}</span>
        {productName && <span className={styles.productName}>{productName}</span>}
      </div>
    );
  };

  render() {
    const { selectedCitation } = this.props;

    return selectedCitation && (
      <div className={styles.ReferenceCitationDetails}>
        <Details
          title={intlGet('ProjectPanel.Modal.ReferenceCitationDetails', 'Title')}
          subtitle={this.renderSubtitle()}
          onClose={this.onClose}
        >
          <div className={styles.citationInfo}>
            <Icon type={IconType.REFERENCE_CITATION} size="sm-md" color="primary" />
            <div className={styles.htmlText}>
              <p dangerouslySetInnerHTML={{ __html: selectedCitation.get('text') }}/>
            </div>
          </div>
        </Details>
      </div>
    );
  }
}

export default ReferenceCitationDetails;
