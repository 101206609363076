import React from 'react';

const ArrowUp = (): JSX.Element => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    viewBox="-100 -100 584 712"
    height="200px"
    width="200px"
  >
    <path
      // eslint-disable-next-line max-len
      d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
    />
  </svg>
);

export default ArrowUp;
