import { CSSProperties, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as converterUtils from 'utils/converters';
import * as stylesUtils from 'utils/styles';

const MINIMAL_PADDING = 1;

export type LayoutStylesState = {
  backgroundColor?: Models.BrandColorMap;
  backgroundColorOpacity: number;
  backgroundGradient?: Models.BrandColorGradientMap;
  backgroundImage?: Models.BackgroundImageMap;
  border?: Models.BorderMap<Models.BrandColor>;
  borderRadius?: Models.BorderRadiusMap;
  height?: number;
  minHeight: number;
  padding: Models.BoxPropertyMap;
  responsive: boolean;
  scrollable: boolean;
};

type SpecialSetters = {
  backgroundGradient: (gradient: Models.BrandColorGradientMap | undefined, backupColor: Models.BrandColorMap | undefined) => void;
  minHeight: never;
};

export type LayoutStylesStateSetters = {
  [K in keyof Omit<LayoutStylesState, keyof SpecialSetters>]-?: (value: LayoutStylesState[K]) => void;
} & {
  [K2 in keyof SpecialSetters as SpecialSetters[K2] extends never ? never : K2]: SpecialSetters[K2];
};

export function layoutStylesStateToSource(
  styles: LayoutStylesState,
  source: Models.LayoutStylesMap,
): Models.LayoutStylesMap {
  return source.withMutations(values => values
    .set('backgroundColor', converterUtils.colorToSource(styles.backgroundColor))
    .set('backgroundColorTint', converterUtils.colorToSourceTint(styles.backgroundColor))
    .set('backgroundColorOpacity', styles.backgroundColorOpacity)
    .set('backgroundGradient', converterUtils.brandColorGradientToSource(styles.backgroundGradient))
    .set('backgroundImage', styles.backgroundImage)
    .set('border', converterUtils.brandBorderToSource(styles.border))
    .set('borderRadius', styles.borderRadius)
    .set('height', styles.height)
    .set('padding', styles.padding)
    .set('responsive', styles.responsive)
    .set('scrollable', styles.scrollable),
  );
}

export function layoutStylesStateFromSource(
  source: Models.LayoutStylesMap,
  colors: Models.BrandColorsList | undefined,
): LayoutStylesState {
  const border = converterUtils.brandBorderFromSource(colors, source.get('border'));

  return {
    backgroundColor: converterUtils.colorFromSource(
      colors,
      source.get('backgroundColor'),
      source.get('backgroundColorTint'),
    ),
    backgroundColorOpacity: source.get('backgroundColorOpacity'),
    backgroundGradient: converterUtils.brandColorGradientFromSource(colors, source.get('backgroundGradient')),
    backgroundImage: source.get('backgroundImage'),
    border,
    borderRadius: source.get('borderRadius'),
    height: source.get('height'),
    padding: source.get('padding'),
    responsive: source.get('responsive'),
    scrollable: source.get('scrollable'),
    minHeight: border
      ? (border.getIn(['width', 'top'], 0) as number) + (border.getIn(['width', 'bottom'], 0) as number) + 1
      : 1,
  };
}

export function layoutStylesStateSetters(
  setStyles: Dispatch<SetStateAction<LayoutStylesState>>,
): LayoutStylesStateSetters {
  const props = [
    'backgroundColorOpacity',
    'backgroundImage',
    'border',
    'borderRadius',
    'height',
    'padding',
    'responsive',
  ] as const;

  const propsSetters = Object.fromEntries(
    props.map(
      <T extends keyof LayoutStylesState>(key: T) => [
        key,
        (value: LayoutStylesState[T]): void => setStyles(state => ({ ...state, [key]: value })),
      ],
    ),
  ) as { [P in (typeof props)[number]]: (value: LayoutStylesState[P]) => void };

  return {
    ...propsSetters,
    backgroundColor: color => setStyles(state => ({
      ...state,
      backgroundColor: color,
      backgroundGradient: undefined,
    })),
    backgroundGradient: (gradient, backupColor) => setStyles(state => ({
      ...state,
      backgroundColor: backupColor,
      backgroundGradient: gradient,
    })),
    scrollable: scrollable => setStyles((state) => {
      const { height, ...prev } = state;

      return {
        ...prev,
        height: scrollable ? state.minHeight : undefined,
        scrollable,
      };
    }),
  };
}

type Config = {
  allowBackgroundOpacity: boolean;
};

export function layoutStylesStateToCSS(
  styles: LayoutStylesState,
  images: Models.ImagesMap,
  config: Config,
  adjustPadding = false,
): CSSProperties {
  const { allowBackgroundOpacity } = config;

  const adjustedPadding = adjustPadding
    ? {
      top: styles.padding.get(Constants.BoxPropertySide.TOP) || MINIMAL_PADDING,
      left: styles.padding.get(Constants.BoxPropertySide.LEFT),
      right: styles.padding.get(Constants.BoxPropertySide.RIGHT),
      bottom: styles.padding.get(Constants.BoxPropertySide.BOTTOM) || MINIMAL_PADDING,
    }
    : styles.padding;

  return {
    ...stylesUtils.getBorderCSSProperties(styles.border),
    ...stylesUtils.getBorderRadius(styles.borderRadius),
    ...stylesUtils.getCSSBackgroundBrand(
      styles.backgroundColor,
      allowBackgroundOpacity ? styles.backgroundColorOpacity : undefined,
      styles.backgroundGradient,
      styles.backgroundImage,
      images as Models.CombinedDocumentsMap,
    ),
    ...stylesUtils.getPadding(adjustedPadding),
  };
}

type Context = {
  colors: Models.BrandColorsList;
  images: Models.ImagesMap;
  adjustPadding?: boolean;
};

export function useLayoutStyles(
  source: Models.LayoutStylesMap,
  context: Context,
  config: Config,
): [LayoutStylesState, LayoutStylesStateSetters, CSSProperties] {
  const { colors, images, adjustPadding = false } = context;

  const [styles, setStyles] = useState<LayoutStylesState>(
    () => layoutStylesStateFromSource(source, colors),
  );

  useEffect(() => {
    setStyles(layoutStylesStateFromSource(source, colors));
  }, [source, colors]);

  const setters = useMemo<LayoutStylesStateSetters>(
    () => layoutStylesStateSetters(setStyles),
    [],
  );

  const css = useMemo<CSSProperties>(
    () => layoutStylesStateToCSS(styles, images, config, adjustPadding),
    [styles, images, config, adjustPadding],
  );

  return [styles, setters, css];
}
