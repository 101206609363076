import { LayeredRelationsMap, LayoutMap, MasterScreen, RelationsMap, SSIMap } from 'models';
import { isVerticalSSIPosition } from 'utils/ssi/ssiPosition';
import { getSSIElementSize } from 'utils/styles/getSSISize';
import { fitLayoutIntoSection } from './fitLayoutIntoSection';

export function fitLayoutIntoSSIElement<R extends RelationsMap | LayeredRelationsMap>(
  layout: LayoutMap,
  sectionsWidth: MasterScreen.SectionsWidthMap,
  ssi: SSIMap,
  relations: R,
): R {
  if (!ssi || !ssi.get('source')) {
    return relations;
  }

  const targetSectionWidth = sectionsWidth.get(ssi.get('section'));
  const ssiElementWidth = isVerticalSSIPosition(ssi.get('position')) ? getSSIElementSize(ssi, targetSectionWidth) : targetSectionWidth;

  return fitLayoutIntoSection(layout, relations, ssiElementWidth);
}
