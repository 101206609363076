/**
 * Returns array of integers which are close to equality.
 * If width can not be divided into parts equally, remainder will be splitted and added to the first values.
 * E.g. `splitIntoEqualParts(8, 3)` will return `[3, 3, 2]`.
 *
 * @param {number} width        - width to divide
 * @param {number} columnsCount - number of required columns
 */
export const splitIntoEqualParts = (width: number, columnsCount: number): number[] => {
  const normalizedWidth = Math.abs(width);

  const columnWidth = Math.floor(normalizedWidth / columnsCount);
  const remainder = normalizedWidth % columnsCount;

  return Array(columnsCount).fill(columnWidth).map((
    (value, idx) => idx < remainder ? +value + 1 : value
  ));
};
