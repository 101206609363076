import React from 'react';
/* eslint-disable max-len */

const VerticalAlignmentCenter = (): JSX.Element => (
  <svg width="18px" height="16px" viewBox="0 0 18 16">
    <defs>
      <path d="M18,16 L18,22 C18,22.5522847 17.5522847,23 17,23 L13,23 C12.4477153,23 12,22.5522847 12,22 L12,16 L6,16 L6,14 L12,14 L12,8 C12,7.44771525 12.4477153,7 13,7 L17,7 C17.5522847,7 18,7.44771525 18,8 L18,14 L24,14 L24,16 L18,16 Z" id="path-vertical-alignment-center" />
    </defs>
    <g id="ImagesVerticalAlignmentCenter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Images_Vertical_Alignment_Center" transform="translate(-276.000000, -202.000000)">
        <g id="WidgetVerticalAlignmentCenter" transform="translate(0.000000, 180.000000)">
          <g id="Icons/VerticalAlignmentCenter" transform="translate(270.000000, 15.000000)">
            <g id="ToolbarIcons/VerticalAlignmentCenter">
              <rect id="VerticalAlignmentCenterRectangle" stroke="#FFFFFF" opacity="0" x="0.5" y="0.5" width="29" height="29" />
              <mask id="mask-vertical-alignment-center" fill="white">
                <use xlinkHref="#path-vertical-alignment-center" />
              </mask>
              <use id="VerticalAlignmentCenterMask" fill="#FFFFFF" xlinkHref="#path-vertical-alignment-center" />
              <g id="Colors/OrangeVerticalAlignmentCenter" mask="url(#mask-vertical-alignment-center)" fill="#FFFFFF">
                <g transform="translate(4.000000, 4.000000)" id="OrangeVerticalAlignmentCenter">
                  <rect x="0" y="0" width="22" height="22" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default VerticalAlignmentCenter;
