import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ColumnResizer from 'components/ColumnResizer';
import { ColumnResizerActions, ColumnResizerOwnProps, ColumnResizerSelectors } from 'components/ColumnResizer/models';
import { setIsResizingColumn, setLastEditedLayoutId } from 'containers/App/actions';
import { areActionsDisabled, artboardScale } from 'containers/App/selectors';
import { updateLayeredRelations } from 'containers/Relations/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';

const mapStateToProps = createStructuredSelector({
  areActionsDisabled,
  scale: artboardScale,
});

const mapDispatchToProps = {
  setLastEditedLayoutId,
  finalizeRelations: updateLayeredRelations,
  setIsResizingColumn,
  saveAppState,
};

export default connect<ColumnResizerSelectors, ColumnResizerActions, ColumnResizerOwnProps>(mapStateToProps, mapDispatchToProps)(ColumnResizer);
