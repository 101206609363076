import { call, select } from 'redux-saga/effects';

import { artboards as artboardsSelector } from 'containers/Artboards/selectors';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import * as Models from 'models';
import { getRemoveBackgroundImageByIdCallback, validateBackgroundImages } from 'utils/validator/validateBackgroundImages';

interface RemoveBackgroundImageFromAllStylesResult {
  artboards: Models.Artboards;
  sections: Models.Sections;
  layouts: Models.LayeredLayouts;
  relations: Models.LayeredRelations;
}

/**
 * Removes applied background images from all entities by ID after deleting this image from assets panel
 * @param imageId - ID of deleted image from assets panel
 */
export function* removeBackgroundImageFromAllStyles(imageId: string): Generator<unknown, RemoveBackgroundImageFromAllStylesResult> {
  let artboards = (yield select(artboardsSelector)).toJS() as Models.Artboards;
  let sections = (yield select(sectionsSelector)).toJS() as Models.Sections;
  let layouts = (yield select(layoutsSelector)).toJS() as Models.LayeredCombinedLayouts;
  let relations = (yield select(relationsSelector)).toJS() as Models.LayeredRelations;

  ({
    artboards,
    sections,
    layouts,
    relations,
  } = (yield call(
    validateBackgroundImages,
    artboards,
    sections,
    layouts,
    relations,
    getRemoveBackgroundImageByIdCallback(imageId),
  )) as ReturnTypeSaga<typeof validateBackgroundImages>);

  return {
    artboards,
    sections,
    layouts,
    relations,
  };
}
