import classNames from 'classnames';
import React from 'react';

import ArtboardCell from 'containers/ArtboardCell';
import ColumnResizer from 'containers/ColumnResizer';
import Row from 'containers/Row';
import * as Models from 'models';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import { toPx } from 'utils/toPx';
import { ColumnProps } from './models';
import styles from './styles.module.scss';

const Column: React.FunctionComponent<ColumnProps> = (props) => {
  const {
    activeLayoutIds,
    columnsCount,
    disableCellWidthEditing = false,
    editMode,
    height,
    isLayoutEmpty,
    isOpenToolbar,
    isReusableLayout,
    isSectionActive,
    layout,
    layoutId,
    layoutRelations,
    level,
    relation,
    sectionStyles,
    setRowHeightSilently,
    showColumnResizer,
    ssi,
    toggleExpandedMode,
    toggleLayoutRelations,
    width,
  } = props;
  const columnRelationId = relation.get('id');
  const relationIds = relation.get('relationIds');
  const onlyChild = !areThereHorizontalNeighbors(columnRelationId, layoutRelations);
  const rowsHeight = relation.getIn(['styles', 'rowsHeight']);
  const container = React.createRef<HTMLDivElement>();

  return (
    <div
      className={classNames(styles.Column, {
        [styles.stretchable]: height || !rowsHeight.every(Boolean),
      })}
      style={{ width: width ? toPx(width) : '100%', ...height ? { height } : {} }}
      ref={container}
    >
      {
        showColumnResizer &&
        <ColumnResizer
          container={container}
          layoutId={layoutId}
          relationIsBeingResizedId={columnRelationId}
          relations={layoutRelations}
          toggleRelations={toggleLayoutRelations}
        />}
      {
        relationIds.map((relationId, cellPosition) => {
          const relation = layoutRelations.get(relationId) as Models.LayeredRegularRelationMap | Models.RowRelationMap;
          const height = rowsHeight.get(cellPosition);
          const isLastCell = cellPosition === relationIds.size - 1;
          const showRowResizer = !isOpenToolbar && (onlyChild ? true : !isLastCell);

          if (isRegularRelation(relation)) {
            return (
              <ArtboardCell
                cellPosition={cellPosition}
                cellsCount={relationIds.size}
                columnRelationId={columnRelationId}
                columnsCount={columnsCount}
                disableCellWidthEditing={disableCellWidthEditing}
                height={height}
                isLayoutEmpty={isLayoutEmpty}
                isReusableLayout={isReusableLayout}
                isSectionActive={isSectionActive}
                key={relationId}
                layoutId={layoutId}
                layout={layout}
                layoutRelations={layoutRelations}
                notEditable={!!ssi}
                parentRelationId={columnRelationId}
                positionWithinColumn={cellPosition}
                relation={relation}
                sectionStyles={sectionStyles}
                setRowHeightSilently={setRowHeightSilently}
                showRowResizer={showRowResizer}
                toggleExpandedMode={toggleExpandedMode}
                toggleLayoutRelations={toggleLayoutRelations}
                width={width}
              />
            );
          }

          return (
            <Row
              activeLayoutIds={activeLayoutIds}
              columnRelationId={columnRelationId}
              disableCellWidthEditing={columnsCount < 2}
              editMode={editMode}
              height={height}
              isLast={isLastCell}
              isLayoutEmpty={isLayoutEmpty}
              isOpenToolbar={isOpenToolbar}
              isReusableLayout={isReusableLayout}
              isSectionActive={isSectionActive}
              key={relationId}
              layout={layout}
              layoutId={layoutId}
              layoutRelations={layoutRelations}
              level={level + 1}
              positionWithinColumn={cellPosition}
              relation={relation}
              sectionStyles={sectionStyles}
              setRowHeightSilently={setRowHeightSilently}
              showRowResizer={showRowResizer}
              ssi={ssi}
              toggleExpandedMode={toggleExpandedMode}
              toggleLayoutRelations={toggleLayoutRelations}
            />
          );
        })
      }
    </div>
  );
};

export default Column;
