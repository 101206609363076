import { call, put, select } from 'redux-saga/effects';

import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { convertTextComponentToRawEditorState, getSerializedRawContent, setBlockAnchorValue } from 'utils/editor';
import { setDocument } from '../actions';
import { Action } from '../models';
import { documents as documentsSelector } from '../selectors';

export function* removeAnchor(action: Action.IRemoveAnchor) {
  try {
    const { documentId, anchorBlockKey } = action.payload;
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const document = documents.get(documentId) as Models.TextComponentMap;
    const editorState = convertTextComponentToRawEditorState(document);
    const newEditorState = setBlockAnchorValue(editorState, anchorBlockKey, false);
    const rawContent = getSerializedRawContent(newEditorState);

    yield put(setDocument(document.set('rawContent', rawContent)));
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.removeAnchor', 'RemoveAnchor');
  }
}
