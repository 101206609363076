import _ from 'lodash';

import { RenditionType } from 'const';
import { getStoryCardImagesInfo, getStoryCardLayoutThumbnails, getStoryCardsPreviews } from 'containers/Documents/actions';
import { Action as DocumentAction } from 'containers/Documents/models';
import { getStoryCardsToPreviewUpdate } from 'containers/ProjectScreen/services/getStoryCardsToPreviewUpdate';
import * as Models from 'models';
import { isCombinedLayoutDocument, isStoryCard } from 'utils/entityType';
import { getImageDocuments } from 'utils/getImageDocuments';
import { getPriorityLayer } from 'utils/layers';

const accumulateStoryCardImagesUpdateActions = (
  actions: DocumentAction.IGetStoryCardImagesInfo[],
  storyCard: Models.StoryCard,
  prevStoryCard: Models.StoryCard,
): DocumentAction.IGetStoryCardImagesInfo[] => {
  const { documents, id } = storyCard;
  const { documents: prevDocuments = {} } = prevStoryCard || {};
  const imagesToUpdate = _(documents)
    .mapValues(getPriorityLayer)
    .thru(getImageDocuments)
    .filter(image =>
      image.isUpdatedInPromoMats
      || _.get(image, `renditions.${RenditionType.LOW}.isUpdatedInPromoMats`)
      || !prevDocuments[image.id],
    )
    .value();
  imagesToUpdate.length !== 0 && actions.push(getStoryCardImagesInfo(id, imagesToUpdate));

  return actions;
};

const accumulateStoryCardLayoutThumbnailsUpdateActions = (
  actions: DocumentAction.IGetStoryCardLayoutThumbnails[],
  storyCard: Models.StoryCard,
  prevStoryCard: Models.StoryCard,
): DocumentAction.IGetStoryCardLayoutThumbnails[] => {
  const { documents, id } = storyCard;
  const { documents: prevDocuments = {} } = prevStoryCard || {};
  const layoutThumbnailsToUpdate = _(documents)
    .filter<Models.CombinedLayoutDocument>(isCombinedLayoutDocument)
    .filter(layoutDocument => layoutDocument.isUpdatedInPromoMats || !prevDocuments[layoutDocument.id])
    .value();
  layoutThumbnailsToUpdate.length !== 0 && actions.push(getStoryCardLayoutThumbnails(id, layoutThumbnailsToUpdate));

  return actions;
};

export function getActionsToStoryCardThumbnailsLoading<T extends Models.RefreshProcessArgs>(arg: T): void {
  const {
    assets: {
      documents,
    },
    prevAssets: {
      documents: prevDocuments,
    },
  } = arg;

  const storyCardsToPreviewUpdate = getStoryCardsToPreviewUpdate(documents, prevDocuments);
  const storyCardImagesUpdateActions = _(documents)
    .filter(document => isStoryCard(document) && !!document.documents)
    .reduce(
      (acc, document, id) => {
        const prevDocument = prevDocuments[id] as Models.StoryCard;

        return accumulateStoryCardImagesUpdateActions(acc, document as Models.StoryCard, prevDocument);
      },
      [],
    );
  const storyCardLayoutThumbnailsUpdateActions = _(documents)
    .filter(document => isStoryCard(document) && !!document.documents)
    .reduce(
      (acc, document, id) => {
        const prevDocument = prevDocuments[id] as Models.StoryCard;

        return accumulateStoryCardLayoutThumbnailsUpdateActions(acc, document as Models.StoryCard, prevDocument);
      },
      [],
    );

  arg.actionsWithMiddleware.push(
    getStoryCardsPreviews(storyCardsToPreviewUpdate),
    ...storyCardImagesUpdateActions,
    ...storyCardLayoutThumbnailsUpdateActions,
  );
}
