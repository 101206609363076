import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { ProjectsConfig } from 'const';
import { setArtboards } from 'containers/Artboards/actions';
import { artboards as artboardsSelector } from 'containers/Artboards/selectors';
import { projectType as projectTypeSelector } from 'containers/Project/selectors';
import { setSurfaces } from 'containers/Surfaces/actions';
import { surfaces as surfacesSelector } from 'containers/Surfaces/selectors';
import { handleSagaError } from 'services/handleError';
import { Action } from '../models';

export function* setScreenFormat(action: Action.SetScreenFormat) {
  try {
    const {
      screenId,
      type,
      height,
      width,
    } = action.payload;

    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const { areScreensResizable } = ProjectsConfig[projectType];

    if (!areScreensResizable) {
      return;
    }

    const artboards: ReturnTypeSaga<typeof artboardsSelector> = yield select(artboardsSelector);
    const screens: ReturnTypeSaga<typeof surfacesSelector> = yield select(surfacesSelector);

    const artboardId = screens.getIn([screenId, 'artboardId']);
    const updatedScreens = screens.setIn([screenId, 'formatType'], type);
    const updatedArtboards = artboards.update(artboardId, artboard => artboard.update('styles', styles => (
      styles.set('height', height).set('width', width)
    )));

    yield put(batchActions([
      setSurfaces(updatedScreens),
      setArtboards(updatedArtboards),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Surfaces.setSurfaceSettings', 'SetScreenSettings');
  }
}
