import * as Models from 'models';
import { getExtraHeight } from 'utils/getExtraHeight';
import { toPx } from 'utils/toPx';

export function getCellHeight(
  artboardId: string,
  relation: Models.RegularRelationMap<Models.CombinedRelationStyles>,
  cellsHeight: Models.CellsHeightMap,
): string {
  const styles = relation.get('styles');
  const cellHeightId = `${artboardId}:${relation.get('id')}`;
  const cellHeight = cellsHeight.get(cellHeightId);

  const extraHeight = cellHeight && getExtraHeight(styles);

  return cellHeight ? toPx(cellHeight - extraHeight) : null;
}
