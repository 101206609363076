import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import UndoRedoControl from 'components/UndoRedoControl';
import { UndoRedoControlActions, UndoRedoControlSelectors } from 'components/UndoRedoControl/models';
import { startHandlingReusableLayoutsEditing, stopHandlingReusableLayoutsEditing } from 'containers/App/actions';
import { redo, undo } from 'containers/UndoRedoControl/actions';
import { isRedoDisabled, isUndoDisabled } from 'containers/UndoRedoControl/selectors';

const mapStateToProps = createStructuredSelector({
  isRedoDisabled,
  isUndoDisabled,
});

const mapDispatchToProps = {
  redo,
  undo,
  startHandlingReusableLayoutsEditing,
  stopHandlingReusableLayoutsEditing,
};

export default connect<UndoRedoControlSelectors, UndoRedoControlActions>(mapStateToProps, mapDispatchToProps)(UndoRedoControl);
