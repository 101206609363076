import _ from 'lodash';
import { all, call, put, take } from 'redux-saga/effects';
import guid from 'uuid';
import { ModalType, SourceProjectType } from 'const';
import { showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { ProjectAssets, ProjectFile, ProjectSource } from 'models';
import * as api from 'services/api';
import { getSavedLocallyProject } from 'services/localSave';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { deleteInternalInfo } from 'utils/deleteInternalInfo';
import { isProjectFile } from 'utils/entityType/isProjectFile';
import { intlGet } from 'utils/intlGet';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';

interface ProjectAssetsWithLocallyFlag {
  projectAssets: ProjectAssets;
  needToSaveProjectLocally: boolean;
}

type GeneratorProjectAssetsType = Generator<unknown, ProjectAssetsWithLocallyFlag>;

export function* generatorProjectAssets(rootDocumentId: number, logId = ''): GeneratorProjectAssetsType {
  let projectAssets: ProjectAssets;
  let rootDocument: ProjectAssets['rootDocument'] | ReturnTypeSaga<typeof api.getRootDocument>['data'];
  let documentTypes: ProjectAssets['documentTypes'] | ReturnTypeSaga<typeof api.getDocumentTypes>['data'];
  let projectDocuments: ProjectAssets['documents'];
  let leaveTranslation: ReturnTypeSaga<typeof Notifications.showConfirmation> = true;
  let lastLocalSaveTime: number;
  let projectName: string;
  let needToSaveLocally: boolean;


  function* getRemoteProjectData(suppressTranslation: boolean) {
    [
      { data: rootDocument },
      { data: projectAssets },
      { data: documentTypes },
    ] = yield all([
      api.getRootDocument(rootDocumentId),
      api.getProjectAssets(rootDocumentId, suppressTranslation),
      api.getDocumentTypes(),
    ]);
  }

  let savedLocallyProject: ReturnTypeSaga<typeof getSavedLocallyProject> =
    yield call(getSavedLocallyProject, rootDocumentId);

  const { data: {
    version: projectFileVersion,
    modifyDate,
    translatableProject,
    latestProjectFileVersion,
  } }: ReturnTypeSaga<typeof api.getProjectFileDetails> = yield call(api.getProjectFileDetails, rootDocumentId);

  if (translatableProject) {
    leaveTranslation = yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title: intlGet('ConfirmationWindow.Title', 'IsTranslationProject'),
        message: intlGet('ConfirmationWindow.Message', 'CreateTranslationView'),
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Yes'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'No'),
        disableClickOutside: true,
      },
    );
  }

  if (!savedLocallyProject) {
    yield call(getRemoteProjectData, !leaveTranslation);
  } else {
    const currentVersion = _.get(savedLocallyProject, 'project.version');

    if (latestProjectFileVersion !== currentVersion) {
      const { data: convertedAssets }: ReturnTypeSaga<typeof api.convertAssets> =
        yield call(api.convertAssets, savedLocallyProject);
      savedLocallyProject = { ...convertedAssets, lastLocalSaveTime: savedLocallyProject.lastLocalSaveTime };
    }

    ({ documents: projectDocuments, lastLocalSaveTime, rootDocument: { name: projectName } } = savedLocallyProject);
    const localProjectFile = _.find(projectDocuments, isProjectFile) as ProjectFile;
    const { version: localVersion = '0.1' } = localProjectFile || {};

    const projectSources: ProjectSource[] = [
      {
        version: projectFileVersion,
        modifyDate,
        sourceProjectType: SourceProjectType.PROMOMATS,
      },
      {
        version: localVersion,
        modifyDate: lastLocalSaveTime,
        sourceProjectType: SourceProjectType.LOCAL,
      },
    ];

    logger.performancePause(logId);

    const chooseProjectSourceWindowId = guid();
    yield put(showModal(ModalType.PROJECT_SOURCE, { projectSources, projectName }, chooseProjectSourceWindowId));

    const { payload: { response: sourceProjectType } }: ModalManagerModels.Action.IHideModal<SourceProjectType> =
      yield take(
        takeModalWindowResponse(chooseProjectSourceWindowId),
      );

    logger.performanceUnpause(logId);

    switch (sourceProjectType) {
      case SourceProjectType.LOCAL:
        const { data: masterScreenData }: ReturnTypeSaga<typeof api.getMasterScreenData> =
          yield call(api.getMasterScreenData, rootDocumentId);

        ({ rootDocument, documentTypes } = savedLocallyProject);
        projectAssets = {
          ...savedLocallyProject,
          masterScreenData,
        };
        break;
      case SourceProjectType.PROMOMATS:
        yield call(getRemoteProjectData, !leaveTranslation);
        needToSaveLocally = true;
        break;
    }
  }

  projectAssets.documentTypes = documentTypes;
  projectAssets.rootDocument = rootDocument;

  return {
    projectAssets: deleteInternalInfo(projectAssets),
    needToSaveProjectLocally: !savedLocallyProject || needToSaveLocally,
  };
}
