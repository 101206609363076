import _ from 'lodash';

import { Assembler } from 'const/ErrorType';
import { ImageInternalInfo } from 'models';
import { CustomError } from 'utils/customError';

export async function getImageInternalInfo(source: Blob | string): Promise<ImageInternalInfo> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = (): void => {
      const imageInfo: ImageInternalInfo = {
        width: image.naturalWidth,
        height: image.naturalHeight,
        source: image.src,
      };

      resolve(imageInfo);
    };

    image.onerror = (): void => {
      reject(new CustomError(Assembler.CORRUPTED_IMAGE, `Unable to load image: ${image.src}`));
    };

    image.src = _.isString(source) ? source : URL.createObjectURL(source);
  });
}
