import { SpinnerSize } from 'components/Spinner/models';
import { MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER, MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER } from 'const';

export const getLayoutSpinnerSize = (clientHeight: number = MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER): SpinnerSize => {
  if (clientHeight > MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER) {
    return 'lg';
  }

  return clientHeight <= MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER ? 'sm' : 'md';
};
