import { isImmutable } from 'immutable';
import _ from 'lodash';

import { createRelation } from 'factories/relationFactory';
import { ActionTypes } from './constants';
import { ActionCreator } from './models';

export const setRelations: ActionCreator.SetRelations = relations => ({
  type: ActionTypes.SET_RELATIONS,
  payload: {
    relations,
  },
});

export const mergeRelations: ActionCreator.SetRelations = relations => ({
  type: ActionTypes.MERGE_RELATIONS,
  payload: {
    relations,
  },
});

export const addRelations: ActionCreator.AddRelations = relations => ({
  type: ActionTypes.ADD_RELATIONS,
  payload: {
    relations,
  },
});

export const deleteRelations: ActionCreator.DeleteRelations = relationIds => ({
  type: ActionTypes.DELETE_RELATIONS,
  payload: {
    relationIds,
  },
});

export const updateRelation: ActionCreator.UpdateRelation = (relation, options) => {
  const processedOptions = _.defaults(options, { resetLayeredData: false });

  return {
    type: ActionTypes.UPDATE_RELATION,
    payload: {
      relation: isImmutable(relation) ? relation : createRelation(relation, processedOptions.size),
      options: processedOptions,
    },
  };
};

export const updateRelations: ActionCreator.UpdateRelations = relations => ({
  type: ActionTypes.UPDATE_RELATIONS,
  payload: {
    relations,
  },
});

export const updateLayeredRelationsSilently: ActionCreator.UpdateLayeredRelationsSilently = (relations, layoutId) => ({
  type: ActionTypes.UPDATE_LAYERED_RELATIONS_SILENTLY,
  payload: {
    relations,
    layoutId,
  },
});

export const deleteComponents: ActionCreator.DeleteComponents = ids => ({
  type: ActionTypes.DELETE_COMPONENTS,
  payload: {
    ids,
  },
});

export const replaceDocumentIdInRelations: ActionCreator.ReplaceDocumentIdInRelations = (id, newId) => ({
  type: ActionTypes.REPLACE_DOCUMENT_ID_IN_RELATIONS,
  payload: {
    id,
    newId,
  },
});

export const updateLayeredRelations: ActionCreator.UpdateLayeredRelations = relations => ({
  type: ActionTypes.UPDATE_LAYERED_RELATIONS,
  payload: {
    relations,
  },
});
