import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { setLastEditedLayoutId } from 'containers/App/actions';
import { artboards as artboardsSelectors } from 'containers/Artboards/selectors';
import { setLayouts } from 'containers/Layouts/actions';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { addRelations } from 'containers/Relations/actions';
import { layeredRelations as layeredRelationsSelector } from 'containers/Relations/selectors';
import { sectionsWidthByScreenAndId as sectionsWidthByScreenAndIdSelector } from 'containers/ScreenDefinitions/selectors';
import { surfaces } from 'containers/Surfaces/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { handleSagaError } from 'services/handleError';
import { addColumnToLayout } from 'utils/layouts/addColumnToLayout';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { updateReusableLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';
import { Action } from '../models';

export function* addLayoutColumn(action: Action.IAddLayoutColumn) {
  try {
    const { layout } = action.payload;
    const screens: ReturnTypeSaga<typeof surfaces> = yield select(surfaces);
    const artboards: ReturnTypeSaga<typeof artboardsSelectors> = yield select(artboardsSelectors);
    const layeredRelations: ReturnTypeSaga<typeof layeredRelationsSelector> = yield select(layeredRelationsSelector);
    const layouts: ReturnTypeSaga<typeof layoutsSelectors.layouts> = yield select(layoutsSelectors.layouts);
    const sectionsWidthByScreenAndId: ReturnTypeSaga<typeof sectionsWidthByScreenAndIdSelector> = yield select(sectionsWidthByScreenAndIdSelector);
    const artboard = artboards.find(artboard => getFlattenedLayouts(artboard, layouts).has(layout.get('id')));
    const screen = screens.find(screen => screen.get('artboardId') === artboard.get('id'));
    const screenWidth = sectionsWidthByScreenAndId.getIn([screen.get('id'), layout.get('section')]);
    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);

    const {
      relations: updatedRelations,
      layout: updatedLayout,
    } = addColumnToLayout(layout, layeredRelations, screenWidth, layer);

    yield put(batchActions([
      saveAppState(),
      addRelations(updatedRelations),
      setLastEditedLayoutId(layout.get('id')),
      setLayouts(updateReusableLayoutInstances(updatedLayout, layouts)),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.addLayoutColumn', 'AddLayoutColumn');
  }
}
