import React from 'react';

const ArrowLeft = (): JSX.Element => (
  <svg width="16px" height="16px" viewBox="7 7 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="ArrowLeft-mask-2">
        <polygon style={{ fill: '#ffffff' }} id="ArrowLeft-path-1" points="23 14 10.83 14 16.42 8.41 15 7 7 15 15 23 16.41 21.59 10.83 16 23 16" />
      </mask>
      <g mask="url(#ArrowLeft-mask-2)" fill="#4A4A4A">
        <rect x="0" y="0" width="23" height="23" />
      </g>
    </g>
  </svg >
);

export default ArrowLeft;
