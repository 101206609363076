import React from 'react';

const ToolbarBold = (): JSX.Element => (
  <svg viewBox="0 0 8 12">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontSize="14" fontWeight="bold">
      <g transform="translate(-403.000000, -24.000000)" fill="#FFFFFF">
        <g>
          <g>
            <g transform="translate(146.000000, 14.552765)">
              <text id="B">
                <tspan x="256" y="20.447235">B</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ToolbarBold;
