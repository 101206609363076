import { Effect, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { requestTextAbbreviations } from './requestTextAbbreviations';

const effects: Effect[] = [
  takeEvery(ActionTypes.REQUEST_TEXT_ABBREVIATIONS, requestTextAbbreviations),
];

export default effects;
