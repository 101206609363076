
export enum CellAction {
  TOGGLE_EDITMODE_ON = 'toggle-edit-mode-on',
  TOGGLE_EDITMODE_OFF = 'toggle-edit-mode-off',
  OPEN_TOOLBAR = 'toolbar',
  OPEN_ABBREVIATIONS_PANEL = 'open-abbreviations-panel',
  SET_CURSOR_ON_ABBREVIATION = 'set-cursonr-on-abbreviation',
  APPLY_SELECTION = 'apply-selection',
  CHANGE_ABBREVIATION = 'change-abbreviation',
  REMOVE_ABBREVIATION = 'remove-abbreviation',
}

type CellActionTypeMap = {
  [CellAction.TOGGLE_EDITMODE_ON]: [boolean];
  [CellAction.CHANGE_ABBREVIATION]: [string];
  [CellAction.REMOVE_ABBREVIATION]: [string];
  [CellAction.SET_CURSOR_ON_ABBREVIATION]: [string, number?];
  [CellAction.APPLY_SELECTION]: [string, number, number];
};

export type CellActionRequest<A extends CellAction = CellAction> =
  A extends keyof CellActionTypeMap
    ? [A, ...CellActionTypeMap[A]]
    : A | [A];

export type CellActionArguments<A extends CellAction> =
 CellActionRequest<A> extends [action: CellAction, ...args: infer T] ? T : [];

export type CellActionsDispatcher = (...actions: CellActionRequest[]) => void;

export type CellActionExecutor<A extends CellAction> =
  (...args: CellActionArguments<A>) => void | boolean;
