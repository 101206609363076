import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { intlGet } from 'utils/intlGet';
import { isExistsTermOrDefinition } from '../../../utils/isExistsTermOrDefinition';
import { MIN_DEFINITION_LENGTH } from '../constants';
import css from '../styles.module.scss';
import NewDefinitionForm from './NewDefinitionForm';

type Props = {
  onAdd: (definition: string) => Promise<void>;
  existingDefinitions: string[];
  onCancel?: ()=>void;
};

const NewAbbreviation: React.FunctionComponent<Props> = (props) => {
  const { onAdd, existingDefinitions, onCancel } = props;

  const [editState, setEditState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const addAbbreviation = useCallback((definition: string) => {
    const value = definition.trim();
    if (value.length < MIN_DEFINITION_LENGTH) {
      return setError(new Error(intlGet(
        'EditorToolbar.Abbreviation.Error',
        'MinLength',
        { MIN_DEFINITION_LENGTH },
      )));
    } else if (isExistsTermOrDefinition(value, existingDefinitions)) {
      return setError(new Error(intlGet('EditorToolbar.Abbreviation.Error', 'DefinitionAlreadyExists')));
    }

    setError(undefined);
    setLoading(true);

    onAdd(value).then(()=>{
      setEditState(false);
    }).catch(setError).finally(()=>{
      setLoading(false);
    });
  }, []);

  if (editState) {
    return (
      <div>
        <NewDefinitionForm
          onInput={():void=>setError(undefined)}
          error={error}
          onAdd={addAbbreviation}
          onCancel={():void => {
            setLoading(false);
            setError(undefined);
            setEditState(false);
            if (_.isFunction(onCancel)) {
              onCancel();
            }
          }}
          loading={loading}
        />
      </div>
    );
  }

  return (
    <button
      className={classNames(css.newAbbreviationButton, css.button)}
      onClick={(): void => {
        setError(undefined);
        setEditState(true);
      }}
    >
      <Icon
        color='primary'
        size="xs"
        type={IconType.ADD_SLIM}
      />
      {intlGet('EditorToolbar.Abbreviation', 'Button.NewDefinition')}
    </button>
  );
};

export default NewAbbreviation;
