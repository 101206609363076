import _ from 'lodash';

import * as Models from 'models';
import { isTextComponent } from '../entityType';
import { validateTextComponents } from './validateTextComponents';

export const validateTextAssets = <D extends Models.CombinedDocuments, R extends Models.LayeredRelations>(
  documents: D,
  relations: R,
): { documents: D; relations: R } => {
  const {
    components: processedTextComponents,
    relations: processedTextRelations,
  } = validateTextComponents(
    _.pickBy(documents, isTextComponent) as Models.TextComponents,
    _.pickBy(relations, isTextComponent) as Models.LayeredRegularRelations<Models.TextRelationStyles>,
  );

  return {
    documents: Object.assign({}, documents, processedTextComponents),
    relations: Object.assign({}, relations, processedTextRelations),
  };
};
