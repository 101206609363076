import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BackgroundColorTab from 'components/ModalWindows/ScreenSettings/components/BackgroundColorTab';
import { BackgroundColorTabOwnProps, BackgroundColorTabSelectors } from 'components/ModalWindows/ScreenSettings/components/BackgroundColorTab/models';
import { projectFlatColors } from 'containers/BrandDefinition/selectors';

const mapStateToProps = createStructuredSelector({
  colors: projectFlatColors,
});

export default connect<BackgroundColorTabSelectors, null, BackgroundColorTabOwnProps>(mapStateToProps)(BackgroundColorTab);
