import _ from 'lodash';
import { CallEffectDescriptor, SelectEffectDescriptor, SimpleEffect, all, call, select } from 'redux-saga/effects';

import { Layer } from 'const';
import { brandDefinition } from 'containers/BrandDefinition/selectors';
import * as commonSelectors from 'containers/Common/selectors';
import { getLayoutPreviewUrl } from 'containers/Common/services/getLayoutPreviewUrl';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { initApp } from 'containers/Common/services/initApp';
import { currentCountry, currentLanguage, currentProduct } from 'containers/Project/selectors';
import * as relationsSelectors from 'containers/Relations/selectors';
import * as Models from 'models';
import { createLayout, getAlreadyTranslatedReusableLayouts } from 'services/api';
import * as processes from 'services/processes';
import { mergeBrandDefinitions } from 'utils/brandStyles/mergeBrandDefinitions';
import { isReusableLayoutDocument, isTextComponent } from 'utils/entityType';
import { prepareRelationsToSave } from 'utils/reusableLayouts/prepareRelationsToSave';
import { replaceRelationsWithCopies } from 'utils/reusableLayouts/replaceRelationsWithCopies';
import { sagaFlow } from 'utils/sagaFlow';

export function* translateReusableLayouts(
  rootDocumentId: number,
  allReusableLayouts: Models.LayeredLayoutsMap,
  translatedReusableLayouts: Models.LayeredLayoutsMap,
  documents: Models.CombinedDocuments,
): Generator<unknown, Models.ReusableLayouts> {
  if (_.isEmpty(translatedReusableLayouts.toJS())) {
    return;
  }

  // translated internal document ids by original
  const translatedReusableLayoutDocumentIdsMap = _(translatedReusableLayouts.toJS() as Models.LayeredCombinedLayouts)
    .map(layout => layout.documentId)
    .reject(documentId => _.some(documentId, _.isNil))
    .keyBy(documentId => documentId.original)
    .mapValues(documentId => documentId.translated)
    .value();

  const language = (yield select(currentLanguage)).toJS() as string[];
  const country = (yield select(currentCountry)).toJS() as string[];
  const product = (yield select(currentProduct)).toJS() as string[];
  const layeredRelations = (yield select(relationsSelectors.layeredRelations)).toJS() as Models.LayeredRelations;
  const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;

  const documentIdsByInternalDocumentIds = allReusableLayouts
    .map(layout => layout.getIn(['documentId', Layer.ORIGINAL]))
    .filter(Boolean)
    .mapEntries(([, id]) => [id, (documents[id] as Models.ReusableLayout).documentId])
    .toJS() as Record<string, number>;

  // retrieve already translated RLs
  const { data: { translatedDocumentsByOriginalDocumentId } }: ReturnTypeSaga<typeof getAlreadyTranslatedReusableLayouts> = yield call(
    getAlreadyTranslatedReusableLayouts,
    _.values(documentIdsByInternalDocumentIds),
    _.first(language),
    _.first(country),
  );

  _.forEach(translatedDocumentsByOriginalDocumentId, (layoutDocument) => {
    if (isReusableLayoutDocument(layoutDocument)) {
      layoutDocument.entities.brandDefinition = mergeBrandDefinitions(projectBrandDefinition, layoutDocument.entities.brandDefinition);
    }

    return layoutDocument;
  });

  const alreadyTranslatedReusableLayouts = _(documentIdsByInternalDocumentIds)
    .mapValues((documentId, id) => {
      const translatedLayoutDocumentId = translatedReusableLayoutDocumentIdsMap[id];

      return translatedLayoutDocumentId && translatedDocumentsByOriginalDocumentId[documentId]
        ? _.set(translatedDocumentsByOriginalDocumentId[documentId], 'id', translatedLayoutDocumentId)
        : null;
    })
    .omitBy(_.isEmpty)
    // update translated document keys
    .mapKeys(document => document.id)
    .value();

  // proceed only with not translated RLs
  const alreadyTranslatedReusableLayoutIds = _.map(alreadyTranslatedReusableLayouts, 'id');
  const notTranslatedReusableLayouts = translatedReusableLayouts.filterNot(
    layout => alreadyTranslatedReusableLayoutIds.includes(layout.getIn(['documentId', Layer.TRANSLATED])),
  );

  const layoutPreviewByLayoutId: Record<string, string> = yield all(
    notTranslatedReusableLayouts
      .map((layout, layoutId) => call(getLayoutPreviewUrl, [layoutId]))
      .toJS() as Record<string, SimpleEffect<'CALL', CallEffectDescriptor>>,
  );

  const layoutAssetsByDocumentInternalId: Record<string, Models.LayoutAssetsMap> = yield all(
    notTranslatedReusableLayouts
      .map(layout => layout.getIn(['documentId', Layer.TRANSLATED]))
      .mapEntries(([layoutId, id]) =>
        [id, select(commonSelectors.layoutAssets(layoutId, { previewUrl: layoutPreviewByLayoutId[layoutId] }))],
      )
      .toJS() as Record<string, SimpleEffect<'SELECT', SelectEffectDescriptor>>,
  );

  const justTranslatedLayoutDocuments: Record<string, Models.ReusableLayout> = yield all(
    _.mapValues(layoutAssetsByDocumentInternalId, function* (layoutAssetsMap, id) {
      const layoutAssets = layoutAssetsMap.toJS() as Models.LayoutAssets;
      const { relationId, documents: layoutAssetsDocuments } = layoutAssets;
      const originalDocumentId = _.findKey(translatedReusableLayoutDocumentIdsMap, translatedDocumentId => translatedDocumentId === id);
      // use original documentId and name to create translated RL
      const { name, documentId, classification, subtype } = documents[originalDocumentId] as Models.ReusableLayout;
      const {
        relations: relationsToSet,
        newDocuments,
        newRelationIdsByOld,
      }: ReturnTypeSaga<typeof replaceRelationsWithCopies> = yield call(
        replaceRelationsWithCopies,
        relationId,
        layeredRelations,
        documents,
        Layer.TRANSLATED,
      );

      const updatedLayoutAssetsDocuments = _(layoutAssetsDocuments)
        .omitBy(isTextComponent)
        .assign(newDocuments)
        .value();
      const updatedLayoutAssets: Models.LayoutAssets = {
        ...layoutAssets,
        documents: updatedLayoutAssetsDocuments,
        relations: prepareRelationsToSave(relationsToSet, Layer.TRANSLATED),
        relationId: newRelationIdsByOld[relationId],
      };

      const { data: reusableLayout }: ReturnTypeSaga<typeof createLayout> = yield call(
        createLayout,
        rootDocumentId,
        updatedLayoutAssets,
        { name, subtype, classification },
        { language, country, product },
        documentId,
      );
      // restore layout id
      reusableLayout.id = id;
      reusableLayout.previewUrl = updatedLayoutAssets.previewUrl;
      reusableLayout._thumbnailUrl = updatedLayoutAssets.previewUrl;

      return reusableLayout;
    }),
  );

  const projectAssets: ReturnTypeSaga<typeof getProjectAssets> = yield call(getProjectAssets, { removeInternalInfo: false });
  const masterScreenData = (yield select(commonSelectors.masterScreenData)).toJS() as Models.MasterScreenData;
  const updatedAssets = _.set<Models.ProjectAssets>(_.cloneDeep(projectAssets), 'masterScreenData', masterScreenData);
  const translatedReusableLayoutDocuments = { ...justTranslatedLayoutDocuments, ...alreadyTranslatedReusableLayouts };
  const newLayoutDocumentIdByOld = {} as { [oldDocumentId: number]: number };

  // set translated RLs to project assets
  _.forEach(translatedReusableLayoutDocuments, (document: Models.ReusableLayout, id) => {
    const mockReusableLayout = updatedAssets.documents[id] as Models.ReusableLayout;

    if (mockReusableLayout) {
      newLayoutDocumentIdByOld[mockReusableLayout.documentId] = document.documentId;
    }

    updatedAssets.documents[id] = document;
  });

  // replace RL document ids in GRL entities
  _.forEach(newLayoutDocumentIdByOld, (newDocumentId, oldDocumentId) => {
    _.forEach(updatedAssets.documents, (document: Models.GroupLayoutDocument) => {
      const layouts = _.get(document, ['entities', 'layouts']);

      _.forEach(layouts, (documentId, i) => {
        if (documentId === Number(oldDocumentId)) {
          _.set(document, ['entities', 'layouts', i], newDocumentId);
        }
      });
    });
  });

  const dataToProcess: Models.ImportTranslationPackageArgs = {
    assets: updatedAssets,
    actionsWithMiddleware: [],
    isProjectTranslatable: true,
  };

  const translatedDataProcesses = sagaFlow<Models.ImportTranslationPackageArgs>(
    processes.removeDuplicateLayouts,
    processes.refreshLayoutsOnArtboards,
    processes.getActionsToImagesLoading,
    processes.getActionsToLayoutPreviewsLoading,
  );

  const { assets, actionsWithMiddleware }: ReturnTypeSaga<typeof translatedDataProcesses> = yield call(translatedDataProcesses, dataToProcess);

  yield call(initApp, { projectAssets: assets, actionsWithMiddleware });

  return translatedReusableLayoutDocuments;
}
