import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';

import ComponentItem from 'components/ComponentItem';
import { IComponentItemCollectedProps, IComponentItemOwnProps, IComponentItemProps } from 'components/ComponentItem/models';
import { DragSourceType } from 'const';
import { SSIElementStateProps } from './models';

const SSIElementDragSource: DragSourceSpec<IComponentItemProps & SSIElementStateProps, {}> = {
  beginDrag(props) {
    const { toggleDragState } = props;

    toggleDragState(DragSourceType.SSI);

    return {};
  },

  endDrag(props) {
    props.toggleDragState();
  },

  canDrag(props) {
    return !props.isSSIElementOnArtboard;
  },
};

const collect: DragSourceCollector<IComponentItemCollectedProps, IComponentItemOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

export default DragSource<IComponentItemOwnProps, IComponentItemCollectedProps, {}>(
  DragSourceType.SSI,
  SSIElementDragSource,
  collect,
)(ComponentItem);
