import enUS from 'locales/en-US.json';

const DEFAULT_LOCALE = 'en-US';

const intl = {
  'en-US': enUS,
};

/**
 * Returns translation or if there is not translation returns second parameter of function
 */
export function intlGet(prefix: string, name: string, keysToReplace?: { [key: string]: string | number }): string {
  const locale = DEFAULT_LOCALE;

  if (intl[locale]) {
    let resultString: string = intl[locale][`${prefix}.${name}`] || name;

    if (keysToReplace) {
      resultString = Object.keys(keysToReplace).reduce(
        (_resultString, key) => {
          const regExp = new RegExp(`\\\$\\{${key}\\}`, 'g');

          return _resultString.replace(regExp, String(keysToReplace[key]));
        },
        resultString,
      );
    }

    return resultString;
  }

  return name;
}
