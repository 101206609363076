import React from 'react';

const CallToActionLarge = (): JSX.Element => (
  <svg viewBox="0 0 96 96">
    <defs>
      <rect id="path-call-to-action-large" x="6" y="31" width="85" height="29" rx="4" />
      <filter id="filter-call-to-action-large" x="-8.2%" y="-17.2%" width="116.5%" height="148.3%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.230414117 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-a-citation-10" transform="translate(-1312.000000, -592.000000)">
        <g id="Group-6" transform="translate(1312.000000, 592.000000)">
          <g id="Rectangle-Copy-9">
            <use fill="black" fillOpacity="1" filter="url(#filter-call-to-action-large)" href="#path-call-to-action-large" />
            <rect stroke="#D8D8D8" strokeWidth="0.5" fill="#FFFFFF" fillRule="evenodd" x="6.25" y="31.25" width="84.5" height="28.5" rx="4" />
          </g>
          <text id="Button" fontFamily="Roboto-Medium, Roboto" fontSize="7" fontWeight="400" letterSpacing="1.05" fill="#A4A4A4">
            <tspan x="34" y="48">Button</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default CallToActionLarge;
