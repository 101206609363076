import { appendLeadingZeros } from './appendLeadingZeros';

export const convertTime = (time: number): string => {
  const date = new Date(time);
  const day = appendLeadingZeros(String(date.getDate()));
  const month = date.toLocaleString('en', { month: 'short' });
  const year = date.getFullYear();
  const hours = appendLeadingZeros(String(date.getHours()));
  const minutes = appendLeadingZeros(String(date.getMinutes()));

  return `${hours}:${minutes}, ${day} ${month} ${year}`;
};

export const convertToUTCTime = (time: number): string => {
  const date = new Date(time);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hours = appendLeadingZeros(String(date.getUTCHours()));
  const minutes = appendLeadingZeros(String(date.getUTCMinutes()));

  return `${month}/${day}/${year} ${hours}:${minutes} (UTC)`;
};
