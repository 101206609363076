import { ActionTypes } from './constants';
import * as Models from './models';

export const setTextAbbreviations: Models.ActionCreator.SetTextAbbreviations = textAbbreviationsList => ({
  type: ActionTypes.SET_TEXT_ABBREVIATIONS,
  payload: textAbbreviationsList,
});

export const requestTextAbbreviations: Models.ActionCreator.GetTextAbbreviations = rootDocumentId => ({
  type: ActionTypes.REQUEST_TEXT_ABBREVIATIONS,
  payload: rootDocumentId,
});
