import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { addArtboard } from 'containers/Artboards/actions';
import * as artboardsSelectors from 'containers/Artboards/selectors';
import { mergeDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { addLayouts } from 'containers/Layouts/actions';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import { addSurfaceId, updateActiveSurfaceId } from 'containers/Project/actions';
import * as projectSelectors from 'containers/Project/selectors';
import { mergeRelations } from 'containers/Relations/actions';
import { layeredRelations } from 'containers/Relations/selectors';
import { addSections } from 'containers/Sections/actions';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import { addSurface } from 'containers/Surfaces/actions';
import * as surfacesSelectors from 'containers/Surfaces/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { createSurfaceDuplicate } from 'utils/createSurfaceDuplicate';
import { Action } from '../models';

export function* duplicateSurface(action: Action.IDuplicateSurface) {
  try {
    const { id: surfaceId } = action.payload;

    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const layer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);
    const artboards: ReturnTypeSaga<typeof artboardsSelectors.artboards> = yield select(artboardsSelectors.artboards);
    const relations = (yield select(layeredRelations)).toJS() as Models.LayeredRelations;
    const layouts: ReturnTypeSaga<typeof layoutsSelectors.layouts> = yield select(layoutsSelectors.layouts);
    const sections: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
    const surfaceNames: ReturnTypeSaga<typeof surfacesSelectors.surfaceNames> = yield select(surfacesSelectors.surfaceNames);
    const surfaceIds: ReturnTypeSaga<typeof projectSelectors.surfaceIds> = yield select(projectSelectors.surfaceIds);
    const surfaces: ReturnTypeSaga<typeof surfacesSelectors.surfaces> = yield select(surfacesSelectors.surfaces);
    const surface = surfaces.get(surfaceId);
    const artboardId = (surface as Models.ScreenMap).get('artboardId');

    const {
      artboard: newArtboard,
      documents: newDocuments,
      layouts: newLayouts,
      relations: newRelations,
      sections: newSections,
      surface: newSurface,
    }: ReturnTypeSaga<typeof createSurfaceDuplicate> = yield call(
      createSurfaceDuplicate,
      surface as Models.ScreenMap,
      artboards.get(artboardId),
      sections,
      layouts,
      relations,
      surfaceNames,
      documents,
      layer,
    );

    const positionToPasteNewSurface = surfaceIds.indexOf(surfaceId) + 1;
    const newSurfaceId = newSurface.get('id');

    yield put(batchActions([
      addSections(newSections),
      addLayouts(newLayouts),
      addArtboard(newArtboard),
      addSurface(newSurface),
      addSurfaceId(newSurfaceId, positionToPasteNewSurface),
      mergeRelations(newRelations),
      mergeDocuments(newDocuments),
      updateActiveSurfaceId(newSurfaceId),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Surfaces.duplicateSurface', 'DuplicateScreen');
  }
}
