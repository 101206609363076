import Immutable from 'immutable';

import * as Models from 'models';
import { isImmutable } from './immutable';

/**
 * Returns Map of sections on an artboard by their name (from Screen Definition)
 * @param sections - all sections
 * @param sectionIds - ids of sections on an artboard
 */
export function getScreenSectionsByName(
  sections: Models.Sections,
  sectionIds: string[],
): Models.Sections;
export function getScreenSectionsByName(
  sections: Models.SectionsMap,
  sectionIds: Immutable.List<string>,
): Immutable.Map<string, Models.SectionMap>;
export function getScreenSectionsByName(
  sections: Models.Sections | Models.SectionsMap,
  sectionIds: string[] | Immutable.List<string>,
): Models.Sections | Immutable.Map<string, Models.SectionMap> {
  if (isImmutable(sections) && isImmutable(sectionIds)) {
    return sectionIds.reduce(
      (screenSections, sectionId) => {
        const section = sections.get(sectionId);

        return screenSections.set(section.get('name'), section);
      },
      Immutable.Map<string, Models.SectionMap>(),
    );
  }

  return (sectionIds as string[]).reduce(
    (screenSections, sectionId) => {
      const section = (sections as Models.Sections)[sectionId];
      screenSections[section.name] = section;

      return screenSections;
    },
    {} as Models.Sections,
  );
}
