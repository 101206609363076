import * as Constants from 'const';
import * as Models from 'models';

export const createAssetAlignment = (
  {
    horizontal = Constants.AssetAlignmentType.HORIZONTAL_CENTER,
    vertical = Constants.AssetAlignmentType.VERTICAL_CENTER,
  } = {} as Partial<Models.AssetAlignment>,
): Models.AssetAlignment => ({
  [Constants.AssetAlignmentDirection.HORIZONTAL]: horizontal,
  [Constants.AssetAlignmentDirection.VERTICAL]: vertical,
});

export const createAssectAlignmentWithTextAlignment = (
  horizontal: Constants.TextHorizontalAlignmentType,
  vertical: Constants.AssetAlignmentType,
): Models.AssetAlignment => ({
  [Constants.AssetAlignmentDirection.HORIZONTAL]: Constants.TextAlignmentMap[horizontal],
  [Constants.AssetAlignmentDirection.VERTICAL]: vertical,
});
