export enum AxiosMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  // TODO: add other methods if it is necessary
}

export const S3Keys = {
  REQUESTS: 'requests',
};

export const REQUEST_BODY_SIZE_LIMIT = 5 * 1024 * 1024; // 5mb
