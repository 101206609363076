import React from 'react';
/* eslint-disable max-len */

const SpacerMedium = (): JSX.Element => (
  <svg viewBox="0 0 96 96">
    <defs>
      <rect id="path-spacer-medium-1" x="4" y="28" width="88" height="38" rx="1" />
      <filter id="filter-spacer-medium-1" x="-8.0%" y="-13.2%" width="115.9%" height="136.8%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.110303759 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <rect id="path-spacer-medium-2" x="0" y="5" width="4" height="1" rx="0.5" />
      <filter id="filter-spacer-medium-2" x="-175.0%" y="-500.0%" width="450.0%" height="1500.0%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0905539773 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <rect id="path-spacer-medium-3" x="6" y="5" width="4" height="1" rx="0.5" />
      <filter id="filter-spacer-medium-3" x="-175.0%" y="-500.0%" width="450.0%" height="1500.0%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0905539773 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <rect id="path-spacer-medium-4" x="3" y="1.5" width="4" height="1" rx="0.5" />
      <filter id="filter-spacer-medium-4" x="-175.0%" y="-500.0%" width="450.0%" height="1500.0%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0905539773 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
      <path id="path-spacer-medium-5" d="M3.5,8 L6.5,8 C6.77614237,8 7,8.22385763 7,8.5 L7,8.5 C7,8.77614237 6.77614237,9 6.5,9 L3.5,9 C3.22385763,9 3,8.77614237 3,8.5 L3,8.5 C3,8.22385763 3.22385763,8 3.5,8 Z" />
      <filter id="filter-spacer-medium-5" x="-175.0%" y="-500.0%" width="450.0%" height="1500.0%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0905539773 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-a-citation-10" transform="translate(-1208.000000, -754.000000)">
        <g id="Group-8" transform="translate(1208.000000, 754.000000)">
          <g id="Rectangle-Copy-11">
            <use fill="black" fillOpacity="1" filter="url(#filter-spacer-medium-1)" href="#path-spacer-medium-1" />
            <rect stroke="#898989" strokeWidth="1" fill="#FFFFFF" fillRule="evenodd" x="4.5" y="28.5" width="87" height="37" rx="1" />
          </g>
          <g id="Group-Copy" transform="translate(43.000000, 42.000000)">
            <g id="Rectangle-Copy-2">
              <use fill="black" fillOpacity="1" filter="url(#filter-spacer-medium-2)" href="#path-spacer-medium-2" />
              <use fill="#1A1A1A" fillRule="evenodd" href="#path-spacer-medium-2" />
            </g>
            <g id="Rectangle-Copy-3">
              <use fill="black" fillOpacity="1" filter="url(#filter-spacer-medium-3)" href="#path-spacer-medium-3" />
              <use fill="#1A1A1A" fillRule="evenodd" href="#path-spacer-medium-3" />
            </g>
            <g id="Rectangle-Copy-4" transform="translate(5.000000, 2.000000) rotate(-90.000000) translate(-5.000000, -2.000000) ">
              <use fill="black" fillOpacity="1" filter="url(#filter-spacer-medium-4)" href="#path-spacer-medium-4" />
              <use fill="#1A1A1A" fillRule="evenodd" href="#path-spacer-medium-4" />
            </g>
            <g id="Rectangle-Copy-5" transform="translate(5.000000, 8.500000) rotate(-90.000000) translate(-5.000000, -8.500000) ">
              <use fill="black" fillOpacity="1" filter="url(#filter-spacer-medium-5)" href="#path-spacer-medium-5" />
              <use fill="#1A1A1A" fillRule="evenodd" href="#path-spacer-medium-5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SpacerMedium;
