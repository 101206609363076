import { Schemas } from 'services/ArtboardConverter/models';

export const createScreenRef = ({
  screenLink = '',
  screenName = '',
  screenPosition = null,
} = {} as Partial<Schemas.ScreenRef>): Schemas.ScreenRef => ({
  screenLink,
  screenName,
  screenPosition,
});
