import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArtboardPreview from 'components/ArtboardPreview';
import { togglePreview } from 'containers/App/actions';
import { generateArtboardPreview } from 'containers/ArtboardPreview/actions';
import { UIFontFaces } from 'containers/Common/selectors';
import { setActivePreviewOption } from 'containers/Project/actions';
import { activePreviewOptionName, projectType, surfaceIds } from 'containers/Project/selectors';
import { previewOptionsByScreenId, screenWidth } from 'containers/ScreenDefinitions/selectors';
import { resetPreviewsHtml } from './actions';
import { previewsHtml } from './selectors';

const mapStateToProps = createStructuredSelector({
  activePreviewOptionName,
  fontFaces: UIFontFaces,
  previewOptionsByScreenId,
  previewsHtml,
  projectType,
  screenWidth,
  surfaceIds,
});

const mapDispatchToProps = {
  setActivePreviewOption,
  resetPreviewsHtml,
  generatePreview: generateArtboardPreview,
  togglePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtboardPreview);
