import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LayoutList from 'components/LayoutList';
import { LayoutListActions, LayoutListOwnProps, LayoutListSelectors } from 'components/LayoutList/models';
import { toggleUpdatedDocumentsDisplaying } from 'containers/App/actions';
import { showUpdatedReusableLayouts } from 'containers/App/selectors';

const mapStateToProps = createStructuredSelector({
  showUpdatedReusableLayouts,
});

const mapDispatchToProps = {
  toggleUpdatedDocumentsDisplaying,
};

export default connect<LayoutListSelectors, LayoutListActions, LayoutListOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutList);
