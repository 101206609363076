import { isImmutable } from 'immutable';
import { LayoutType } from 'const';
import * as Models from 'models';

export const isAbbreviatioinsListLayout = (
  layout: Models.CombinedLayoutMap
  | Models.LayeredCombinedLayoutMap
  | Models.CombinedLayout
  | Models.LayeredCombinedLayout,
): boolean => {
  if (!layout) {
    return false;
  }
  const type = isImmutable(layout) ? (layout as unknown as Models.BaseLayoutMap).get('type') : layout.type;

  return type === LayoutType.ABBREVIATIONS_LIST;
};
