import React from 'react';
/* eslint-disable max-len */

const EdgeBrowserIcon = (): JSX.Element => (
  <svg height="88px" width="88px" viewBox="0 0 262 262">
    <linearGradient id="a" gradientTransform="matrix(1 0 0 -1 0 266)" gradientUnits="userSpaceOnUse" x1="63.33" x2="241.67" y1="84.03" y2="84.03">
      <stop offset="0" stop-color="#0c59a4" />
      <stop offset="1" stop-color="#114a8b" />
    </linearGradient>
    <radialGradient id="b" cx="161.83" cy="68.91" gradientTransform="matrix(1 0 0 -.95 0 248.84)" gradientUnits="userSpaceOnUse" r="95.38">
      <stop offset=".72" stop-opacity="0" />
      <stop offset=".95" stop-opacity=".53" />
      <stop offset="1" />
    </radialGradient>
    <linearGradient id="c" gradientTransform="matrix(1 0 0 -1 0 266)" gradientUnits="userSpaceOnUse" x1="157.35" x2="45.96" y1="161.39" y2="40.06"><stop offset="0" stop-color="#1b9de2" />
      <stop offset=".16" stop-color="#1595df" />
      <stop offset=".67" stop-color="#0680d7" />
      <stop offset="1" stop-color="#0078d4" />
    </linearGradient><radialGradient id="d" cx="-340.29" cy="62.99" gradientTransform="matrix(.15 -.99 -.8 -.12 176.64 -125.4)" gradientUnits="userSpaceOnUse" r="143.24">
      <stop offset=".76" stop-opacity="0" />
      <stop offset=".95" stop-opacity=".5" />
      <stop offset="1" />
    </radialGradient><radialGradient id="e" cx="113.37" cy="570.21" gradientTransform="matrix(-.04 1 2.13 .08 -1179.54 -106.69)" gradientUnits="userSpaceOnUse" r="202.43"><stop offset="0" stop-color="#35c1f1" />
      <stop offset=".11" stop-color="#34c1ed" />
      <stop offset=".23" stop-color="#2fc2df" />
      <stop offset=".31" stop-color="#2bc3d2" />
      <stop offset=".67" stop-color="#36c752" />
    </radialGradient>
    <radialGradient id="f" cx="376.52" cy="567.97" gradientTransform="matrix(.28 .96 .78 -.23 -303.76 -148.5)" gradientUnits="userSpaceOnUse" r="97.34">
      <stop offset="0" stop-color="#66eb6e" />
      <stop offset="1" stop-color="#66eb6e" stop-opacity="0" />
    </radialGradient>
    <path d="M235.68 195.46a93.73 93.73 0 0 1-10.54 4.71 101.87 101.87 0 0 1-35.9 6.46c-47.32 0-88.54-32.55-88.54-74.32A31.48 31.48 0 0 1 117.13 105c-42.8 1.8-53.8 46.4-53.8 72.53 0 73.88 68.09 81.37 82.76 81.37 7.91 0 19.84-2.3 27-4.56l1.31-.44a128.34 128.34 0 0 0 66.6-52.8 4 4 0 0 0-5.32-5.64z" fill="url(#a)" transform="translate(-4.63 -4.92)" />
    <path d="M235.68 195.46a93.73 93.73 0 0 1-10.54 4.71 101.87 101.87 0 0 1-35.9 6.46c-47.32 0-88.54-32.55-88.54-74.32A31.48 31.48 0 0 1 117.13 105c-42.8 1.8-53.8 46.4-53.8 72.53 0 73.88 68.09 81.37 82.76 81.37 7.91 0 19.84-2.3 27-4.56l1.31-.44a128.34 128.34 0 0 0 66.6-52.8 4 4 0 0 0-5.32-5.64z" fill="url(#b)" opacity=".35" transform="translate(-4.63 -4.92)" />
    <path d="M110.34 246.34A79.2 79.2 0 0 1 87.6 225a80.72 80.72 0 0 1 29.53-120c3.12-1.47 8.45-4.13 15.54-4a32.35 32.35 0 0 1 25.69 13 31.88 31.88 0 0 1 6.36 18.66c0-.21 24.46-79.6-80-79.6-43.9 0-80 41.66-80 78.21a130.15 130.15 0 0 0 12.11 56 128 128 0 0 0 156.38 67.11 75.55 75.55 0 0 1-62.78-8z" fill="url(#c)" transform="translate(-4.63 -4.92)" />
    <path d="M110.34 246.34A79.2 79.2 0 0 1 87.6 225a80.72 80.72 0 0 1 29.53-120c3.12-1.47 8.45-4.13 15.54-4a32.35 32.35 0 0 1 25.69 13 31.88 31.88 0 0 1 6.36 18.66c0-.21 24.46-79.6-80-79.6-43.9 0-80 41.66-80 78.21a130.15 130.15 0 0 0 12.11 56 128 128 0 0 0 156.38 67.11 75.55 75.55 0 0 1-62.78-8z" fill="url(#d)" opacity=".41" transform="translate(-4.63 -4.92)" />
    <path d="M156.94 153.78c-.81 1.05-3.3 2.5-3.3 5.66 0 2.61 1.7 5.12 4.72 7.23 14.38 10 41.49 8.68 41.56 8.68a59.56 59.56 0 0 0 30.27-8.35 61.38 61.38 0 0 0 30.43-52.88c.26-22.41-8-37.31-11.34-43.91-21.19-41.45-66.93-65.29-116.67-65.29a128 128 0 0 0-128 126.2c.48-36.54 36.8-66.05 80-66.05 3.5 0 23.46.34 42 10.07 16.34 8.58 24.9 18.94 30.85 29.21 6.18 10.67 7.28 24.15 7.28 29.52s-2.74 13.33-7.8 19.91z" fill="url(#e)" transform="translate(-4.63 -4.92)" />
    <path d="M156.94 153.78c-.81 1.05-3.3 2.5-3.3 5.66 0 2.61 1.7 5.12 4.72 7.23 14.38 10 41.49 8.68 41.56 8.68a59.56 59.56 0 0 0 30.27-8.35 61.38 61.38 0 0 0 30.43-52.88c.26-22.41-8-37.31-11.34-43.91-21.19-41.45-66.93-65.29-116.67-65.29a128 128 0 0 0-128 126.2c.48-36.54 36.8-66.05 80-66.05 3.5 0 23.46.34 42 10.07 16.34 8.58 24.9 18.94 30.85 29.21 6.18 10.67 7.28 24.15 7.28 29.52s-2.74 13.33-7.8 19.91z" fill="url(#f)" transform="translate(-4.63 -4.92)" />
  </svg>
);

export default EdgeBrowserIcon;
