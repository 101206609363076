import _ from 'lodash';
import guid from 'uuid';

import { Screen, Screens, Thumbnail } from 'models';

export const createThumbnail = (thumbnail?: Partial<Thumbnail>): Thumbnail => {
  const {
    border,
    name = null,
    url = null,
  } = thumbnail || {} as Thumbnail;

  return {
    name,
    border,
    url,
  };
};

export function screenFactory({
  name,
  artboardId = guid(),
  screenDefinitionId = null,
  formatType = null,
  id = guid(),
  thumbnail,
} = {} as Partial<Screen>): Screen {
  return {
    id,
    artboardId,
    screenDefinitionId,
    formatType,
    name,
    thumbnail: createThumbnail(thumbnail),
  };
}

export const createScreens = (screens: Screens): Screens => {
  return _.reduce(
    screens,
    (screens, screen) => {
      const createdScreen = screenFactory(screen);
      screens[createdScreen.id] = createdScreen;

      return screens;
    },
    {} as Screens,
  );
};
