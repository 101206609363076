import React from 'react';

import { SCRIPT_CONTROLS, ScriptType } from 'const';
import EditorToolbarButton from '../EditorToolbarButton';
import styles from './styles.module.scss';

type Props = {
  type: ScriptType | undefined;
  onToggle: (style: ScriptType) => void;
};

const ScriptControl: React.FunctionComponent<Props> = ({ type, onToggle }) => (
  <div className={styles.ScriptControl}>
    {SCRIPT_CONTROLS.map(control => (
      <EditorToolbarButton
        key={control.label}
        active={type === control.style}
        label={control.label}
        onToggle={onToggle}
        value={control.style}
        title={control.title}
      />
    ))}
  </div>
);

export default ScriptControl;
