import { takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { addTextToCell } from './addTextToCell';
import { copyCellContent } from './copyCellContent';
import { deleteReferenceCitationFromComponents } from './deleteReferenceCitationFromComponents';
import { dropAsset } from './dropAsset';

export default [
  takeEvery(ActionTypes.ADD_TEXT_TO_CELL, addTextToCell),
  takeEvery(ActionTypes.COPY_CELL_CONTENT, copyCellContent),
  takeEvery(ActionTypes.DROP_ASSET, dropAsset),
  takeEvery(ActionTypes.DELETE_REFERENCE_CITATION_FROM_COMPONENTS, deleteReferenceCitationFromComponents),
];
