import { ContentBlock, EditorState, Modifier } from 'draft-js';
import Immutable from 'immutable';

import { BlockDataKey, DefaultLineHeight, DraftEditorStateChangeType, TextLineHeightValue } from 'const';
import * as Models from 'models';
import { getSelectedBlocks, trimSelection } from './selection';

/**
 * Returns type of provided block with fallback to `TextLineHeightValue.ONE_POINT_FIVE`
 *
 * @param {ContentBlock} block  - DraftJS content block
 * @returns {TextLineHeightValue} - line height of text block
 */
const getLineHeight = (block: ContentBlock): TextLineHeightValue => {
  const lineHeight = Number(block.getData().get(BlockDataKey.LINE_HEIGHT)) as TextLineHeightValue;

  return lineHeight in TextLineHeightValue ? lineHeight : DefaultLineHeight;
};

export const getBlockLineHeight = (editorState: EditorState): TextLineHeightValue => {
  const contentState = editorState.getCurrentContent();
  const selectionsState = trimSelection(editorState);

  return getSelectedBlocks(selectionsState, contentState)
    .map(getLineHeight)
    .reduce((result, type) => result === type ? result : null);
};

export const getLineHeightMap = (editorState: Draft.EditorState): Models.TextLineHeight => {
  return editorState.getCurrentContent().getBlockMap().reduce(
    (lineHeights, block) => {
      const key = block.getKey();
      const lineHeight = getLineHeight(block);
      lineHeights[key] = lineHeight;

      return lineHeights;
    },
    {} as Models.TextLineHeight,
  );
};

export const setLineHeight = (editorState: EditorState, lineHeight: TextLineHeightValue): EditorState => {
  const aggregatedLineHeight = getBlockLineHeight(editorState);

  if (aggregatedLineHeight && aggregatedLineHeight === lineHeight) {
    return editorState;
  }

  const contentState = editorState.getCurrentContent();
  const selectionState = trimSelection(editorState);

  return EditorState.push(
    editorState,
    Modifier.mergeBlockData(contentState, selectionState, Immutable.fromJS({ [BlockDataKey.LINE_HEIGHT]: Number(lineHeight) })),
    DraftEditorStateChangeType.CHANGE_BLOCK_DATA,
  );
};
