import _ from 'lodash';

function compareColumnsWidths(oldWidths: number[], newWidths: number[]): boolean {
  const oldSum = _.sum(oldWidths);
  const newSum = _.sum(newWidths);

  const oldProportions = oldWidths.map(width => _.round(width / oldSum * 100));
  const newProportions = newWidths.map(width => _.round(width / newSum * 100));

  return newProportions.every((newProportion, index) => {
    const oldProportion = oldProportions[index];

    return Math.abs(newProportion - oldProportion) <= 1;
  });
}

function compareRowsHeight(oldHeights: number[], newHeights: number[]): boolean {
  return newHeights.every((newHeight, index) => {
    const oldHeight = oldHeights[index];

    // no need to compare if there wasn't previous value
    if (!oldHeight) {
      return true;
    }

    return oldHeight === newHeight;
  });
}

function compareBackgroundColorTint(oldTint: number, newTint: number): boolean {
  if (!oldTint && !newTint) {
    return true;
  }

  return oldTint === newTint;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stylesComparator(oldValue: any, newValue: any, propName: string): boolean | undefined {
  switch (propName) {
    case 'backgroundColorTint': return compareBackgroundColorTint(oldValue, newValue);
    case 'columnsWidth': return compareColumnsWidths(oldValue, newValue);
    case 'rowsHeight': return compareRowsHeight(oldValue, newValue);
    default: return undefined;
  }
}
