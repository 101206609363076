import Immutable from 'immutable';
import { useMemo } from 'react';
import * as Models from 'models';
import { getBrandStyles, getColors, getFonts } from '../utils/brand';

export type BrandProps = {
  brandStyles: Models.BrandStylesMap;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
};

type Props = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  brandStylesByRelationId: Immutable.Map<string, Models.BrandStylesMap>;
  flatColorsByRelationId: Models.BrandColorsMap;
  flatFontsByRelationId: Models.BrandFontsMap;
};

export default function useBrandProps(props: Props): BrandProps {
  const {
    relation,
    brandStylesByRelationId,
    flatColorsByRelationId,
    flatFontsByRelationId,
  } = props;

  return useMemo(
    (): BrandProps => ({
      brandStyles: getBrandStyles({ relation, brandStylesByRelationId }),
      colors: getColors({ relation, flatColorsByRelationId }),
      fonts: getFonts({ relation, flatFontsByRelationId }),
    }),
    [relation, brandStylesByRelationId, flatColorsByRelationId, flatFontsByRelationId],
  );
}
