import Immutable from 'immutable';
import guid from 'uuid';

import { Layer } from 'const';
import * as Models from 'models';
import { getSurfaceName } from 'utils/getSurfaceName';
import { replaceRelationsWithCopies } from 'utils/reusableLayouts/replaceRelationsWithCopies';
import { getFlattenedLayouts } from './layouts/getFlattenedLayouts';
import { isGroupLayout } from './layouts/isGroupLayout';
import { isPlainLayout } from './layouts/isPlainLayout';
import { isReusableLayout } from './reusableLayouts/isReusableLayout';

type ReturnType = {
  artboard: Models.ArtboardMap;
  documents: Models.CombinedDocuments;
  layouts: Immutable.List<Models.CombinedLayoutMap>;
  relations: Models.LayeredRelations;
  sections: Models.SectionsMap;
  surface: Models.ScreenMap;
};

export function createSurfaceDuplicate(
  surface: Models.ScreenMap,
  artboard: Models.ArtboardMap,
  sections: Models.SectionsMap,
  layouts: Models.CombinedLayoutsMap,
  relations: Models.LayeredRelations,
  surfaceNames: Immutable.List<string>,
  documents: Models.CombinedDocuments,
  activeLayer: Layer,
): ReturnType {
  let newRelations: Models.LayeredRelations = {};
  let newDocuments: Models.CombinedDocuments = {};

  const artboardLayouts = getFlattenedLayouts(artboard, layouts);
  // Section Map: old section id => section duplicate with new id
  const newSections = artboardLayouts.reduce(
    (acc, layout) => {
      const sectionId = layout.get('section');

      return acc.set(sectionId, sections.get(sectionId).set('id', guid()));
    },
    Immutable.Map() as Models.SectionsMap,
  );

  const newPlainLayoutsByOldId = artboardLayouts.filter(isPlainLayout).map((layout: Models.LayoutMap) => {
    const newSection = newSections.getIn([layout.get('section'), 'id']);

    return (layout as Models.LayoutMap)
      .set('id', guid())
      .set('section', newSection)
      .update('relationId', (relationId) => {
        if (isReusableLayout(layout, documents)) {
          return relationId;
        }

        const {
          relations: relationsToCreate,
          newDocuments: documentsToCreate,
          newRelationIdsByOld,
        } = replaceRelationsWithCopies(
          (layout as Models.LayeredLayoutMap).get('relationId'),
          relations,
          documents,
          activeLayer,
        );

        newRelations = Immutable.merge(newRelations, relationsToCreate);
        newDocuments = Immutable.merge(newDocuments, documentsToCreate);

        return newRelationIdsByOld[relationId];
      });
  });

  const newGroupLayoutsByOldId = artboardLayouts.filter(isGroupLayout).map((layout) => {
    const newSection = newSections.getIn([layout.get('section'), 'id']);

    return (layout as Models.GroupLayoutMap)
      .set('id', guid())
      .set('section', newSection)
      .update('layoutIds', oldIds => oldIds.map(oldId => newPlainLayoutsByOldId.getIn([oldId, 'id'])));
  });

  const newLayoutsByOldId = newPlainLayoutsByOldId.merge(newGroupLayoutsByOldId);
  const newArtboard = artboard
    .set('id', guid())
    .update('layoutIds', layoutIds => layoutIds.map(oldId => newLayoutsByOldId.getIn([oldId, 'id'])));

  const desiredSurfaceName = `${surface.get('name')} Copy`;
  const newSurface = surface
    .set('id', guid())
    .set('artboardId', newArtboard.get('id'))
    .set('name', getSurfaceName(surfaceNames, desiredSurfaceName));

  return {
    artboard: newArtboard,
    documents: newDocuments,
    relations: newRelations,
    layouts: newLayoutsByOldId.toList(),
    sections: newSections.mapKeys((_, section) => section.get('id')),
    surface: newSurface,
  };
}
