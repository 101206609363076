import _ from 'lodash';
import { put, take } from 'redux-saga/effects';
import guid from 'uuid';

import { ModalType, ProjectsConfig } from 'const';
import { showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import * as Models from 'models';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';

export function* setInitialScreensFormat<T extends Models.GetProjectDataProcessArgs>({ assets }: T): Generator<unknown, void> {
  const {
    artboards,
    surfaces: screens,
    masterScreenData: {
      projectType,
    },
  } = assets;
  const { areScreensResizable } = ProjectsConfig[projectType];

  if (
    !areScreensResizable ||
    _.every(screens, screen => !!screen.formatType) // some kind of format has already been assigned to each screen
  ) {
    return;
  }

  const chooseScreenFormatWindowId = guid();
  yield put(showModal(ModalType.CHOOSE_SCREEN_FORMAT, null, chooseScreenFormatWindowId));

  const { payload: { response: { type, height, width } } }: ModalManagerModels.Action.IHideModal<Models.ScreenFormat> = yield take(
    takeModalWindowResponse(chooseScreenFormatWindowId),
  );

  _.forEach(screens, screen => screen.formatType = type);
  _.forEach(artboards, (artboard) => {
    artboard.styles.height = height;
    artboard.styles.width = width;
  });
}
