import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextAbbreviationDocument } from 'models';
import { selectAbbreviationsSortedData } from '../store/selectors';

function useAbbreviation(
  abbreviationId: string | undefined,
  handleMissingAbbreviation?: (id: string) => void,
): TextAbbreviationDocument | undefined;

function useAbbreviation(
  abbreviationsIdList: string[],
  handleMissingAbbreviation?: (id: string) => void,
): TextAbbreviationDocument[];

function useAbbreviation(
  abbreviationIdOrIdList: string | string[] | undefined,
  handleMissingAbbreviation?: (id: string) => void,
): TextAbbreviationDocument | (TextAbbreviationDocument | undefined)[] | undefined {
  const textAbbreviationDocuments = useSelector(selectAbbreviationsSortedData);

  function getAbbreviationDocument(_abbreviationId: string): TextAbbreviationDocument | undefined {
    const entity = textAbbreviationDocuments.find(item => item.id === _abbreviationId);
    if (!entity && handleMissingAbbreviation) {
      handleMissingAbbreviation(_abbreviationId);
    }

    return entity;
  }

  return useMemo(() => {
    if (_.isString(abbreviationIdOrIdList)) {
      return getAbbreviationDocument(abbreviationIdOrIdList);
    } else if (_.isArray(abbreviationIdOrIdList)) {
      return abbreviationIdOrIdList.map(getAbbreviationDocument).filter(item => Boolean(item));
    } else {
      return undefined;
    }
  }, [abbreviationIdOrIdList, textAbbreviationDocuments]);
}

export default useAbbreviation;
