import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Row from 'components/ArtboardLayout/components/Row';
import { RowActions, RowOwnProps, RowSelectors } from 'components/ArtboardLayout/components/Row/models';
import { isResizingRow } from 'containers/App/selectors';

const mapStateToProps = createStructuredSelector({
  isResizingRow,
});

export default connect<RowSelectors, RowActions, RowOwnProps>(mapStateToProps, null)(Row);
