import _ from 'lodash';
import { createAbbreviationsListRelation, createAbbreviationsListRelationStyles } from 'modules/Abbreviations/factories';
import { AbbreviationsListRelationStyles } from 'modules/Abbreviations/types';
import guid from 'uuid';
import { ComponentSize, EntityType, Layer } from 'const';
import * as stylesFactories from 'factories/style';
import * as Models from 'models';
import { createLayers } from 'utils/layers';

export const createImageRelation = (
  {
    id = guid(),
    documentId = null,
    styles,
  } = {} as Partial<Models.RegularRelation<Models.ImageRelationStyles>>,
): Models.RegularRelation<Models.ImageRelationStyles> => ({
  id,
  documentId,
  entityType: EntityType.IMAGE,
  styles: stylesFactories.createImageStyles(styles),
});

export const createLayoutRenditionRelation = (
  {
    id = guid(),
    documentId = null,
    styles,
  } = {} as Partial<Models.RegularRelation<Models.ImageRelationStyles>>,
): Models.RegularRelation<Models.ImageRelationStyles> => ({
  id,
  documentId,
  entityType: EntityType.LAYOUT_RENDITION,
  styles: stylesFactories.createImageStyles(styles),
});

export const createTextRelation = (
  {
    id = guid(),
    documentId = null,
    styles,
  } = {} as Partial<Models.RegularRelation<Models.TextRelationStyles>>,
): Models.RegularRelation<Models.TextRelationStyles> => ({
  id,
  documentId,
  entityType: EntityType.TEXT,
  styles: stylesFactories.createTextStyles(styles),
});

export const createCallToActionRelation = (
  {
    id = guid(),
    documentId = null,
    styles,
  } = {} as Partial<Models.RegularRelation<Models.CallToActionStyles>>,
  size: ComponentSize,
): Models.RegularRelation<Models.CallToActionStyles> => ({
  id,
  documentId,
  entityType: EntityType.CALL_TO_ACTION,
  styles: stylesFactories.createCallToActionStyles(styles, size),
});

const createReferenceCitationElementRelation = ({
  id = guid(),
  documentId = null,
  styles,
} = {} as Partial<Models.RegularRelation<Models.ReferenceCitationElementStyles>>): Models.RegularRelation<Models.ReferenceCitationElementStyles> => ({
  id,
  documentId,
  entityType: EntityType.REFERENCE_CITATION_ELEMENT,
  styles: stylesFactories.createReferenceCitationElementStyles(styles),
});

const createSpacerRelation = (
  {
    id = guid(),
    documentId = null,
    styles,
  } = {} as Partial<Models.RegularRelation<Models.SpacerRelationStyles>>,
  size: ComponentSize,
): Models.RegularRelation<Models.SpacerRelationStyles> => ({
  id,
  documentId,
  entityType: EntityType.SPACER,
  styles: stylesFactories.createSpacerStyles(styles, size),
});

interface CreateRowRelationOptions {
  columnsCount?: number;
  width?: number;
}

export const createRowRelation = (
  {
    id = guid(),
    relationIds = [],
    styles,
  } = {} as Partial<Models.RowRelation>,
  options?: CreateRowRelationOptions,
): Models.RowRelation => {
  const { columnsCount, width } = _.defaults(options, {});

  return {
    id,
    relationIds,
    styles: stylesFactories.createRowRelationStyles(styles, relationIds.length || columnsCount, width),
    entityType: EntityType.ROW,
  };
};

export const createColumnRelation = (
  {
    id = guid(),
    relationIds = [],
    styles,
  } = {} as Partial<Models.ColumnRelation>,
): Models.ColumnRelation => ({
  id,
  relationIds,
  styles: stylesFactories.createColumnRelationStyles(styles, relationIds.length),
  entityType: EntityType.COLUMN,
});

export const createRelation = (relation?: Partial<Models.Relation>, size?: ComponentSize): Models.Relation => {
  const {
    entityType = EntityType.TEXT,
  } = relation || {} as Partial<Models.RegularRelation>;

  switch (entityType) {
    case EntityType.ROW: return createRowRelation(relation as Models.RowRelation);
    case EntityType.COLUMN: return createColumnRelation(relation as Models.ColumnRelation);
    case EntityType.IMAGE: return createImageRelation(relation as Models.RegularRelation<Models.ImageRelationStyles>);
    case EntityType.LAYOUT_RENDITION: return createLayoutRenditionRelation(relation as Models.RegularRelation<Models.ImageRelationStyles>);
    case EntityType.TEXT: return createTextRelation(relation as Models.RegularRelation<Models.TextRelationStyles>);
    case EntityType.CALL_TO_ACTION: return createCallToActionRelation(relation as Models.RegularRelation<Models.CallToActionStyles>, size);
    case EntityType.SPACER: return createSpacerRelation(relation as Models.RegularRelation<Models.SpacerRelationStyles>, size);
    case EntityType.REFERENCE_CITATION_ELEMENT:
      return createReferenceCitationElementRelation(relation as Partial<Models.RegularRelation<Models.ReferenceCitationElementStyles>>);
    case EntityType.ABBREVIATIONS_LIST:
      return createAbbreviationsListRelation(relation as Partial<Models.RegularRelation<AbbreviationsListRelationStyles>>);
    default: return createTextRelation(relation as Models.RegularRelation<Models.TextRelationStyles>);
  }
};

export const createStyles = (
  styles?,
  entityType?: EntityType,
  size?: ComponentSize,
  useDefaultStyleId = true,
): Models.CombinedRelationStyles => {
  switch (entityType) {
    case EntityType.IMAGE:
    case EntityType.LAYOUT_RENDITION: return stylesFactories.createImageStyles(styles);
    case EntityType.TEXT: return stylesFactories.createTextStyles(styles);
    case EntityType.CALL_TO_ACTION: return stylesFactories.createCallToActionStyles(styles, size);
    case EntityType.SPACER: return stylesFactories.createSpacerStyles(styles, size);
    case EntityType.REFERENCE_CITATION_ELEMENT:
      return stylesFactories.createReferenceCitationElementStyles(styles, useDefaultStyleId);
    case EntityType.ABBREVIATIONS_LIST:
      return createAbbreviationsListRelationStyles(styles);
    default: return stylesFactories.createTextStyles(styles);
  }
};

interface CreateLayeredRelationOptions {
  layer?: Layer;
  resetStyles?: boolean;
  size?: ComponentSize;
  useDefaultStyleId?: boolean;
}

export function createLayeredRelation(relation?: Partial<Models.LayeredRelation>, options?: CreateLayeredRelationOptions): Models.LayeredRelation {
  const {
    size,
    layer,
    resetStyles,
    useDefaultStyleId,
  } = _.defaults(options, { resetStyles: false, layer: Layer.ORIGINAL, useDefaultStyleId: true } as CreateLayeredRelationOptions);

  if (!relation) {
    return {
      id: guid(),
      documentId: createLayers<string>(),
      entityType: EntityType.TEXT,
      styles: createLayers({ [layer]: createStyles(undefined, EntityType.TEXT, size) }),
    };
  }

  switch (relation.entityType) {
    case EntityType.ROW: return createRowRelation(relation);
    case EntityType.COLUMN: return createColumnRelation(relation);
    default: {
      const {
        documentId = createLayers<string>(),
        entityType = EntityType.TEXT,
        id = guid(),
        styles = createLayers({ [layer]: createStyles(undefined, entityType, size) }),
      } = relation || {} as Partial<Models.LayeredRegularRelation>;

      return {
        id,
        documentId,
        entityType,
        styles: _.mapValues(styles, styles => styles && createStyles(resetStyles ? undefined : styles, entityType, size, useDefaultStyleId)),
      };
    }
  }
}

/**
 * Takes array of relation ids to create or Relations map
 */
export const createRelations = (relations: Models.Relations | string[]): Models.Relations => {
  if (Array.isArray(relations)) {
    return _(relations).map(id => createRelation({ id, entityType: EntityType.TEXT })).keyBy('id').value();
  }

  return _(relations).mapValues(createRelation).mapKeys('id').value();
};

export const createLayeredRelations = (
  relations: Models.LayeredRelations | string[],
  options?: CreateLayeredRelationOptions,
): Models.LayeredRelations => {
  if (Array.isArray(relations)) {
    return _(relations).map(id => createLayeredRelation({ id, entityType: EntityType.TEXT }, options)).keyBy('id').value();
  }

  return _(relations).mapValues(relation => createLayeredRelation(relation, options)).mapKeys('id').value();
};
