import { call, delay, put, select, spawn } from 'redux-saga/effects';

import { MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { getProjectPdfName } from 'utils/getProjectPdfName';
import { intlGet } from 'utils/intlGet';
import { downloadFileByUrl } from '../services/downloadFileByUrl';
import { generateProjectPdf } from '../services/generateProjectPdf';

export function* generateProjectPDF() {
  const logId = logger.performanceStart();
  const methodName = MethodName.GENERATE_PDF;

  try {
    yield put(AppActions.lockProjectContent(ProcessType.EXPORTING_FILES));
    yield delay(0); // needs to show loader immediately, we have render delay due to artboards.json generation

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const projectPdfName: ReturnTypeSaga<typeof getProjectPdfName> = yield call(getProjectPdfName, rootDocument.get('name'));

    const projectPdfUrl: ReturnTypeSaga<typeof generateProjectPdf> = yield call(generateProjectPdf, projectPdfName);

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    yield call(downloadFileByUrl, projectPdfUrl, projectPdfName, true);

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'ProjectPDF'),
    );
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Artboards.generateProjectPDF', 'GenerateProjectPDF');
  } finally {
    yield put(AppActions.unlockProjectContent());
  }
}
