import React from 'react';

import SaveLayout from 'components/ModalWindows/Shared/SaveLayout';
import { intlGet } from 'utils/intlGet';
import { SaveGroupLayoutProps } from './models';

const SaveGroupLayout: React.FunctionComponent<SaveGroupLayoutProps> = ({
  hideModal,
  modalWindowId,
  options,
  layoutPreview,
  generateLayoutPreview,
  updateAppState,
  cancelLayoutPreviewGeneration,
  saveGroupLayout,
  groupLayoutsNames,
}) => {
  const layoutIds = options.get('layouts').map(layout => layout.get('id')).toArray();
  const groupLayoutId = options.getIn(['groupLayout', 'id']);

  const getDefaultLayoutName = (count = 1): string => {
    const sectionDisplayName = options.get('sectionDisplayName');
    const defaultLayoutName = `${sectionDisplayName ? `${sectionDisplayName}_` : ''}${intlGet('SaveGroupReusableLayout', 'LayoutName', { count })}`;

    if (groupLayoutsNames.includes(defaultLayoutName)) {
      return getDefaultLayoutName(count + 1);
    }

    return defaultLayoutName;
  };
  const saveLayout = (layoutName: string): void => {
    saveGroupLayout(layoutIds, layoutName, layoutPreview.get('s3Url'), groupLayoutId);
  };
  const generatePreview = (): void => { generateLayoutPreview(layoutIds); };

  return (
    <SaveLayout
      cancelPreviewGeneration={cancelLayoutPreviewGeneration}
      initialLayoutName={getDefaultLayoutName()}
      generatePreview={generatePreview}
      hideModal={hideModal}
      layoutPreview={layoutPreview}
      modalWindowId={modalWindowId}
      saveLayout={saveLayout}
      title={intlGet('SaveGroupReusableLayout', 'Title')}
      updateAppState={updateAppState}
    />
  );
};

export default SaveGroupLayout;
