import _ from 'lodash';
import React, { RefObject } from 'react';

import { TOOLBAR_ELEMENT_ID } from 'const';
import { useToggle } from 'hooks/useToggle';
import { createOutsideClickHandler } from 'utils/events';
import { Priority } from './constants';
import { addEventListener, removeEventListener } from './listeners';

export type CreateCustomClickHandler<T> = (
  container: React.MutableRefObject<T>,
  toggleEditModeOff: () => void,
  editMode: boolean,
) => MouseEventHandler;

export type CreateCustomClickHandlerWithToolbar<T> = (
  container: React.MutableRefObject<T>,
  toolbar: HTMLElement,
  toggleEditModeOff: () => void,
  editMode: boolean,
) => MouseEventHandler;

export function useClickOutside<T extends Element>(
  priority: Priority,
  options?: {
    active: boolean;
    createCustomClickHandler?: CreateCustomClickHandler<T> | CreateCustomClickHandlerWithToolbar<T>;
    onClickOutside: () => void;
    onUnsubscribe?: () => void;
    useToolbar?: boolean;
  },
) {
  const {
    onUnsubscribe,
    createCustomClickHandler,
    useToolbar,
    active,
    onClickOutside,
  } = _.defaults(options, { useToolbar: false });
  const toolbar = useToolbar ? document.getElementById(TOOLBAR_ELEMENT_ID) : null;
  const container = React.useRef<T>(null);
  const prevActive = React.useRef<boolean>(active);

  React.useEffect(
    () => {
      const listener = createCustomClickHandler
        ?
        useToolbar
          ? (createCustomClickHandler as CreateCustomClickHandlerWithToolbar<T>)(container, toolbar, onClickOutside, active)
          : (createCustomClickHandler as CreateCustomClickHandler<T>)(container, onClickOutside, active)
        :
        useToolbar
          ? createOutsideClickHandler([container.current, toolbar], onClickOutside)
          : createOutsideClickHandler([container.current], onClickOutside);

      if (active) {
        addEventListener(priority, listener);
      }

      if (prevActive.current && !active) {
        onUnsubscribe && onUnsubscribe();
        removeEventListener(priority, listener);
      }

      prevActive.current = active;

      return () => removeEventListener(priority, listener);
    },
    [active],
  );

  return { container };
}

type UseClickOutsideWithToggleReturn<T> = {
  container: RefObject<T>;
  on: boolean;
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
};

export function useClickOutsideWithToggle<T extends Element>(
  priority: Priority,
  options?: {
    useToolbar?: true;
    closeDropdownCallback?: () => void;
    createCustomClickHandler?: CreateCustomClickHandlerWithToolbar<T>;
  },
): UseClickOutsideWithToggleReturn<T>;
export function useClickOutsideWithToggle<T extends Element>(
  priority: Priority,
  options?: {
    useToolbar?: false;
    closeDropdownCallback?: () => void;
    createCustomClickHandler?: CreateCustomClickHandler<T>;
  },
): UseClickOutsideWithToggleReturn<T>;
export function useClickOutsideWithToggle<T extends Element>(
  priority: Priority,
  options?: {
    useToolbar?: boolean;
    closeDropdownCallback?: () => void;
    createCustomClickHandler?: CreateCustomClickHandler<T> | CreateCustomClickHandlerWithToolbar<T>;
  },
): UseClickOutsideWithToggleReturn<T> {
  const {
    closeDropdownCallback,
    createCustomClickHandler,
    useToolbar,
  } = _.defaults(options, { useToolbar: true });
  const { on, toggle, toggleOn, toggleOff } = useToggle();
  const { container } = useClickOutside<T>(
    priority,
    {
      active: on,
      onClickOutside: toggleOff,
      onUnsubscribe: closeDropdownCallback,
      useToolbar,
      createCustomClickHandler,
    });

  return {
    container,
    on,
    toggle,
    toggleOn,
    toggleOff,
  };
}

export { Priority } from './constants';
