import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ScreensPanel from 'components/ScreensPanel';
import { ScreensPanelActions, ScreensPanelSelectors } from 'components/ScreensPanel/models';
import { toggleShowScreensPanel } from 'containers/App/actions';
import { showScreensPanel } from 'containers/App/selectors';
import { artboards } from 'containers/Artboards/selectors';
import { thumbnailsColor } from 'containers/BrandDefinition/selectors';
import { showModal } from 'containers/ModalManager/actions';
import { updateActiveSurfaceId } from 'containers/Project/actions';
import { activeSurfaceId, projectType, surfaceIds } from 'containers/Project/selectors';
import { screenDefinitions } from 'containers/ScreenDefinitions/selectors';
import {
  deleteSurfaceRequest,
  duplicateSurface,
  setScreenFormat,
  setSurfaceSettings,
  updateSurface,
  updateSurfaceType,
} from 'containers/Surfaces/actions';
import { orderedSurfaces, surfaceNames } from 'containers/Surfaces/selectors';
import { moveScreen } from './actions';
import { selectedEntityTypesByScreenId } from './selectors';

const mapStateToProps = createStructuredSelector({
  activeScreenId: activeSurfaceId,
  artboards,
  projectType,
  screenDefinitions,
  screenNames: surfaceNames,
  screens: orderedSurfaces,
  selectedEntityTypesByScreenId,
  showScreensPanel,
  surfaceIds,
  thumbnailsColor,
});

const mapDispatchToProps = {
  changeActiveScreen: updateActiveSurfaceId,
  deleteScreen: deleteSurfaceRequest,
  duplicateScreen: duplicateSurface,
  moveScreen,
  setScreenFormat,
  setScreenSettings: setSurfaceSettings,
  showModal,
  toggleShowScreensPanel,
  updateScreen: updateSurface,
  updateScreenType: updateSurfaceType,
};

export default connect<ScreensPanelSelectors, ScreensPanelActions>(mapStateToProps, mapDispatchToProps)(ScreensPanel);
