import _ from 'lodash';

const createClickHandler = <T extends Element>(
  elements: T[],
  onClickInside: MouseEventHandler = _.noop,
  onClickOutside: MouseEventHandler = _.noop,
): MouseEventHandler => (event) => {
  const clickInside = _.some(elements, element => element && element.contains(event.target as T));

  if (clickInside) {
    onClickInside(event);
  } else {
    // The following code is here because we need to manually block
    // the buttons from clicking when toolbar is opened (DCC-2711, DCC-4278)
    const target = event.target as HTMLDivElement;
    const buttonIdsToDisable = ['previewButton', 'uploadButton'];
    const targetButton = target.closest(buttonIdsToDisable.map(id => `#${id}`).join(','));
    targetButton && target.setAttribute('data-disabled_button', 'true');

    onClickOutside(event);
  }
};

export const createInsideClickHandler = <T extends Element>(
  elements: T[],
  onClickInside: MouseEventHandler = _.noop,
): MouseEventHandler => createClickHandler(elements, onClickInside);

export const createOutsideClickHandler = <T extends Element>(
  elements: T[],
  onClickOutside: MouseEventHandler = _.noop,
): MouseEventHandler => createClickHandler(elements, _.noop, onClickOutside);
