import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { EntityType, RenditionType } from 'const';
import { ImageInternalInfo } from 'models';
import { ImageCache } from 'services/imageCache';
import { updateLayoutDocumentThumbnailUrl } from '../actions';
import { Action } from '../models';
import { updateLayoutThumbnails } from '../services/updateLayoutThumbnails';

export function* getLayoutsThumbnails(action: Action.IGetLayoutsThumbnails): SagaIterator {
  try {
    const { documents } = action.payload;

    const actions = [];
    const imageCache = ImageCache.getInstance();
    const documentsToProcess = _.pickBy(documents, (document) => {
      const { id, entityType, renditions } = document;
      const checksum = _.get(renditions, [RenditionType.CUSTOM_THUMBNAIL, 'checksum']);
      const cachedData = imageCache.getItem(RenditionType.CUSTOM_THUMBNAIL, checksum) as ImageInternalInfo;
      if (cachedData) {
        actions.push(updateLayoutDocumentThumbnailUrl(id, entityType, cachedData));
      }

      return !cachedData;
    });

    yield put(batchActions(actions));

    if (_.isEmpty(documentsToProcess)) {
      return;
    }

    yield call(
      updateLayoutThumbnails,
      _.values(documentsToProcess),
      (id: string, thumbnailInternalInfo: ImageInternalInfo, entityType?: EntityType) => (
        updateLayoutDocumentThumbnailUrl(id, entityType, thumbnailInternalInfo)
      ),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during updating layouts previews.', error);
  }
}
