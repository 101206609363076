import _ from 'lodash';
import isPointInTriangle from 'point-in-triangle';
import { XYCoord } from 'react-dnd';

import { DragSourceType, SSIPosition } from 'const';

export function isDraggingAsset(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.ASSET;
}

export function isDraggingLayout(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.LAYOUT;
}

export function isDraggingReusableLayout(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.REUSABLE_LAYOUT;
}

export function isDraggingGroupLayout(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.GROUP_LAYOUT;
}

export function isDraggingArtboardLayout(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.ARTBOARD_LAYOUT;
}

export function isDraggingArtboardReusableLayout(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.ARTBOARD_REUSABLE_LAYOUT;
}

export function isDraggingArtboardGroupLayout(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.ARTBOARD_GROUP_LAYOUT;
}

export function isDraggingSSI(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.SSI;
}

export function isDraggingArtboardSSI(dragSourceType: DragSourceType): boolean {
  return dragSourceType === DragSourceType.ARTBOARD_SSI;
}

export function getInsertPosition(insert: number, offsets: Record<string, number>): number {
  const position = _.findKey(offsets, offset => insert < offset);

  if (!position) {
    const maxOffsetPosition = _(offsets).keys().last();

    return maxOffsetPosition ? +maxOffsetPosition + 1 : 0;
  }

  return Number(position);
}

export function getSpacerPosition(boundingClientRect: Record<string, number>, clientOffset: XYCoord, hoverIndex: number): number {
  const { top: hoverTop, bottom: hoverBottom } = boundingClientRect;
  const hoverMiddleY = (hoverBottom - hoverTop) / 2;
  const hoverClientY = clientOffset.y - hoverTop;

  return hoverClientY < hoverMiddleY ? hoverIndex - 1 : hoverIndex;
}

export function getSSIInsertPosition(mouseX: number, mouseY: number, sectionRect: ClientRect): SSIPosition {
  const { top, left, bottom, right } = sectionRect;

  const mousePoint: [number, number] = [mouseX, mouseY];
  const topRightTriangle: [number, number][] = [[left, top], [right, top], [right, bottom]];

  if (isPointInTriangle(mousePoint, topRightTriangle)) {
    return SSIPosition.VERTICAL;
  }

  return SSIPosition.HORIZONTAL;
}
