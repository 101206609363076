import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import FontsCategory from 'components/Toolbar/Shared/FontsCategory';
import { intlGet } from 'utils/intlGet';
import { FontFamilyProps } from './models';
import styles from './styles.module.scss';

const FontFamily: React.FunctionComponent<FontFamilyProps> = (props) => {
  const { activeFontFamily, fontsCategories, fonts, toggleFontFamily, onMouseEnter, onMouseLeave } = props;

  return (
    <Dropdown className={styles.FontFamily} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {
        fontsCategories.size !== 0
          ?
          fontsCategories.map(fontsCategory => (
            <FontsCategory
              key={fontsCategory.get('name')}
              title={fontsCategory.get('name')}
              fonts={fonts.filter(font => fontsCategory.get('brandFonts').includes(font.get('name')))}
              toggleFontFamily={toggleFontFamily}
              activeFontFamily={activeFontFamily}
            />
          ))
          :
          <div className={styles.emptyMenu}>{intlGet('EditorToolbar.FontFamily', 'NoFontsAvailable')}</div>
      }
    </Dropdown>
  );
};

export default FontFamily;
