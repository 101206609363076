import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Tooltip from 'components/Tooltip';
import { DragSourceType, EntityType, KeyboardKey } from 'const';
import { AssetDragObject } from 'models';
import { intlGet } from 'utils/intlGet';
import { isMac } from 'utils/platform';
import { AnchorCollectedProps, AnchorOwnProps, AnchorProps } from './models';
import styles from './styles.module.scss';

const AnchorDragSource: DragSourceSpec<AnchorProps, AssetDragObject> = {
  beginDrag(props) {
    const { anchorBlockKey, documentId, toggleDragState } = props;
    const type = EntityType.ANCHOR;
    toggleDragState(DragSourceType.ASSET, type);

    return { type, anchorBlockKey, documentId };
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

const collect: DragSourceCollector<AnchorCollectedProps, AnchorOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const Anchor: React.FunctionComponent<AnchorProps> = (props) => {
  const {
    anchorBlockKey,
    connectDragSource,
    connectDragPreview,
    documentId,
    isDragging,
    isDraggingAnchor,
    offsetTop,
    removeAnchor,
  } = props;
  const isAnchorApplied = !!anchorBlockKey;
  const isDisplayed = isAnchorApplied || isDraggingAnchor;

  const [isTooltipShown, setTooltipStatus] = React.useState(false);
  const anchorIconRef = React.createRef<HTMLDivElement>();

  const [isActive, setActiveStatus] = React.useState(false);
  React.useEffect(
    () => {
      isActive && !isDisplayed && setActiveStatus(false);
      !isActive && document.removeEventListener('click', handleClick);
    },
    [isActive, isDisplayed],
  );

  React.useEffect(
    () => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); },
    [],
  );

  const button = React.useRef<HTMLButtonElement>();

  const handleClick = React.useCallback(
    (event: MouseEvent) => {
      const { current: buttonElement } = button;
      if (!buttonElement) {
        return;
      }

      const clickOutside = !buttonElement.contains(event.target as Node);
      if (clickOutside) {
        setActiveStatus(false);
      } else {
        // HACK: need to focus, because keyboard events are not handled in Safari
        button.current && button.current.focus();
      }
    },
    [button],
  );

  if (!isDisplayed) {
    return null;
  }

  if (isDisplayed && !isAnchorApplied) {
    return <div className={styles.placeholder} style={{ top: offsetTop }} />;
  }

  const handleDeletePress: React.KeyboardEventHandler<HTMLButtonElement> = ({ key }) => {
    const { platform } = window.navigator;

    if (isMac(platform) ? key === KeyboardKey.BACKSPACE : key === KeyboardKey.DELETE) {
      removeAnchor(documentId, anchorBlockKey);
    }
  };

  const enableEditing = (): void => {
    setActiveStatus(true);
    // HACK: need to handle clicks and set focus, because onBlur event in Safari doesn't work or works unpredictable
    document.addEventListener('click', handleClick);
    // HACK: need to focus, because keyboard events are not handled in Safari
    button.current && button.current.focus();
  };

  const showTooltip = (): void => setTooltipStatus(true);
  const hideTooltip = (): void => setTooltipStatus(false);

  return (
    <button
      ref={button}
      onKeyUp={handleDeletePress}
      onClick={enableEditing}
      className={styles.Anchor}
      style={{ top: offsetTop }}
    >
      <div className={styles.triangle} />
      <hr className={styles.line} />
      {isActive && connectDragSource(
        <div className={styles.icon} ref={anchorIconRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          <Icon type={IconType.ANCHOR} size="sm" color="secondary" />
          <Tooltip
            className={styles.tooltip}
            parent={anchorIconRef}
            show={!isDragging && isTooltipShown}
            text={intlGet('Anchor.Tooltip', 'MoveAnchor')}
          />
        </div>,
      )}
    </button>
  );
};

export default DragSource<AnchorOwnProps, AnchorCollectedProps, AssetDragObject>(
  DragSourceType.ASSET,
  AnchorDragSource,
  collect,
)(Anchor);
