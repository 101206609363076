import _ from 'lodash';
import { RefObject, useCallback, useEffect } from 'react';
import * as Constants from 'const';
import { CTAStyles, CTAStylesSetters } from '../styles';

export function useResizeObserver(
  editorWrapperRef: RefObject<HTMLDivElement>,
  styles: CTAStyles,
  stylesSetters: CTAStylesSetters,
  editMode: boolean, // editorWrapperRef will be updated during editMode toggling so we need to resubscribe ResizeObserver
): void {

  const handler = useCallback(
    _.debounce<ResizeObserverCallback>(
      (entries) => {
        if (_.size(entries) === 0 || !styles.assetPadding) {
          return;
        }

        const contentHeight = entries[0].target.scrollHeight;
        const currentContentHeight = styles.height
        - styles.assetPadding.get(Constants.BoxPropertySide.TOP)
        - styles.assetPadding.get(Constants.BoxPropertySide.BOTTOM);
        const extraHeight = contentHeight - currentContentHeight;

        if (extraHeight > 0) {
          stylesSetters.height(styles.height + extraHeight);
        }
      },
      100,
    ),
    [styles, stylesSetters],
  );

  useEffect(() => {
    const { current } = editorWrapperRef;

    if (!current) {
      return;
    }

    const resizeObserver = new ResizeObserver(handler);
    resizeObserver.observe(current);

    return (): void => resizeObserver.unobserve(current);
  }, [editMode, handler]);
}
