import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import TextComponentItem from 'components/TextComponentItem';
import { ITextComponentItemActions, ITextComponentItemOwnProps, ITextComponentItemSelectors } from 'components/TextComponentItem/models';
import { addSelectedAssetPanelComponent, removeSelectedAssetPanelComponent, toggleDragState } from 'containers/App/actions';
import { selectedComponentItem } from 'containers/App/selectors';
import { deleteComponent } from 'containers/Documents/actions';
import { showModal } from 'containers/ModalManager/actions';
import { setAssetIdToScroll, setSelectedTextComponent } from 'containers/ProjectPanel/actions';
import { assetIdToScroll, selectComponentCount } from 'containers/ProjectPanel/selectors';

const mapStateToProps = (state, props) => {
  const id = props.component.get('id');

  return createStructuredSelector({
    assetIdToScroll,
    textCount: selectComponentCount(id),
    isSelected: selectedComponentItem(id),
  });
};

const mapDispatchToProps = {
  deleteComponent,
  setAssetIdToScroll,
  addSelectedAssetPanelComponent,
  removeSelectedAssetPanelComponent,
  setSelectedTextComponent,
  showModal,
  toggleDragState,
};

export default connect<ITextComponentItemSelectors, ITextComponentItemActions, ITextComponentItemOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TextComponentItem);
