import React from 'react';
/* eslint-disable max-len */

const LineHeightControl = (): JSX.Element => (
  <svg viewBox="0 0 21 17">
    <defs>
      <path d="M4.5,3.5 L7,3.5 L3.5,0 L0,3.5 L2.5,3.5 L2.5,13.5 L0,13.5 L3.5,17 L7,13.5 L4.5,13.5 L4.5,3.5 Z M8.5,1.5 L8.5,3.5 L20.5,3.5 L20.5,1.5 L8.5,1.5 Z M8.5,15.5 L20.5,15.5 L20.5,13.5 L8.5,13.5 L8.5,15.5 Z M8.5,9.5 L20.5,9.5 L20.5,7.5 L8.5,7.5 L8.5,9.5 Z" id="lineHeightId" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-831.000000, -202.000000)">
        <g transform="translate(0.000000, 180.000000)">
          <g transform="translate(826.000000, 15.000000)">
            <g id="baseline-settings_overscan-24px" transform="translate(3.000000, 3.000000)">
              <g id="Icon-Color" transform="translate(2.000000, 4.000000)">
                <mask id="lineHeightMask2" fill="white">
                  <use xlinkHref="#lineHeightId" />
                </mask>
                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#lineHeightId" />
                <g mask="url(#lineHeightMask2)" fill="#FFFFFF" id="White">
                  <g transform="translate(-3.000000, -5.000000)">
                    <rect x="0" y="0" width="26" height="25" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LineHeightControl;
