import { HEX_COLOR_START_KEY, colorTints } from 'const';
import * as Models from 'models';
import { colorWithTint } from 'utils/converters';

export function colorExistsInColorsCategories(backgroundColor: string, colors: Models.BrandColors): boolean {
  return colors.some(brandColor => brandColor.HEX === backgroundColor);
}

export function colorExistsInBrandColors(colorName: string, colors: Models.BrandColorsList): boolean {
  return !(colorName.startsWith(HEX_COLOR_START_KEY))
    ? colors.some(color => color.get('name') === colorName)
    : colors.some(color => colorTints
      .map(tint => colorWithTint(color, tint))
      .some(_color => _color.get('HEX') === colorName),
    );
}
