import { AssetAlignmentType } from 'const';
import { TextAlignment } from 'models';

export const createTextAlignment = ({
  horizontal = {},
  vertical = AssetAlignmentType.TOP,
} = {} as Partial<TextAlignment>): TextAlignment => ({
  horizontal,
  vertical,
});
