import _ from 'lodash';
import { call, delay, put, select, spawn } from 'redux-saga/effects';

import { MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import { downloadFileByUrl } from 'containers/Artboards/services/downloadFileByUrl';
import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import * as ProjectSelectors from 'containers/Project/selectors';
import { getScreensWithThumbnails } from 'containers/Project/services/getScreensWithThumbnails';
import { getThumbnailsInfo } from 'containers/Project/services/getThumbnailsInfo';
import { updateSurfacesThumbnailUrls } from 'containers/Project/services/updateSurfacesThumbnailUrls';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { updateSurfaces } from 'containers/Surfaces/actions';
import { orderedSurfaces } from 'containers/Surfaces/selectors';
import { Screens } from 'models';
import { getIPadPackage, getThumbnails } from 'services/api';
import { getUsedFontFaces } from 'services/getUsedFontFaces';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { getPackageName } from 'utils/getPackageName';
import { intlGet } from 'utils/intlGet';

export function* generatePackageForIPad(): Generator {
  const logId = logger.performanceStart();
  const methodName = MethodName.GENERATE_IPAD;

  try {
    yield put(AppActions.lockProjectContent(ProcessType.EXPORTING_FILES));
    yield delay(0); // needs to show loader immediately, we have render delay due to artboards.json generation

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    const projectType: ReturnTypeSaga<typeof ProjectSelectors.projectType> = yield select(ProjectSelectors.projectType);
    const archiveName: ReturnTypeSaga<typeof getPackageName> = yield call(getPackageName, rootDocument, projectType);
    const helperFileName: ReturnTypeSaga<typeof ProjectSelectors.helperFileName> = yield select(ProjectSelectors.helperFileName);
    const artboardsJson: ReturnTypeSaga<typeof getArtboardsJson> = yield call(getArtboardsJson);
    const surfaces: Screens = (yield select(orderedSurfaces)).toJS();
    const usedFontFaces: ReturnTypeSaga<typeof getUsedFontFaces> = yield call(getUsedFontFaces);

    const { data: thumbnailsUrls }: ReturnTypeSaga<typeof getThumbnails> = yield call(
      getThumbnails,
      artboardsJson,
      helperFileName,
      usedFontFaces,
    );

    const updatedSurfaces = _.flow(
      getScreensWithThumbnails,
      surfaces => updateSurfacesThumbnailUrls(surfaces, thumbnailsUrls),
    )(surfaces);

    const thumbnailsInfo: ReturnTypeSaga<typeof getThumbnailsInfo> = yield call(getThumbnailsInfo, updatedSurfaces);

    const { data: url }: ReturnTypeSaga<typeof getIPadPackage> = yield call(
      getIPadPackage,
      artboardsJson,
      usedFontFaces,
      thumbnailsInfo,
      archiveName,
      projectType,
      helperFileName,
    );

    yield put(updateSurfaces(updatedSurfaces));

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    yield call(downloadFileByUrl, url, archiveName);

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'ExportPackage'),
    );
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Artboards.generatePackageForIPad', 'GeneratePackageForIPad');
  } finally {
    yield put(AppActions.unlockProjectContent());
  }
}
