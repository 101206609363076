import { toPx } from 'utils/toPx';

export function getButtonSize(height: number, width: number, editMode = false): React.CSSProperties {
  if (editMode) {
    return {
      width: '100%',
      height: '100%',
    };
  }

  return {
    width: toPx(width),
    maxWidth: '100%',
    height: toPx(height),
  };
}
