import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Styles from './styles.module.scss';

const Loader = () => (
  <div className={Styles.Loader}>
    <Icon type={IconType.SPINNER} size="md" />
  </div>
);

export default Loader;
