import _ from 'lodash';
import { createSelector } from 'reselect';

import { LayoutType } from 'const';
import { unorderedActiveArtboardLayoutIds } from 'containers/Artboards/selectors';
import { activeLayer, documentIdsAreBeingUploaded } from 'containers/Project/selectors';
import { sections as screenDefinitionSections } from 'containers/ScreenDefinitions/selectors';
import { sections } from 'containers/Sections/selectors';
import * as Models from 'models';
import { getOrderedSections } from 'utils/getOrderedSections';
import { getScreenSectionsByName } from 'utils/getScreenSectionsByName';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { prioritizeLayeredLayouts } from 'utils/prioritizeLayeredLayouts';
import { State } from './models';

export const layeredLayouts = (state: Models.AppState.StateMap): State.IState => state.get('layouts');

export const layouts = createSelector(
  [layeredLayouts, activeLayer],
  prioritizeLayeredLayouts,
);

const unorderedLayoutsOnActiveArtboard = createSelector(
  [layouts, unorderedActiveArtboardLayoutIds],
  (layouts, layoutIds) => {
    return layoutIds.map(layoutId => layouts.get(layoutId)) as Models.LayoutsList;
  },
);

const unorderedActiveLayoutsBySectionId = createSelector(
  unorderedLayoutsOnActiveArtboard,
  (layouts): Models.LayoutsBySectionId => layouts.groupBy(layout => layout.get('section')) as Models.LayoutsBySectionId,
);

const unorderedActiveSectionIds = createSelector(
  unorderedActiveLayoutsBySectionId,
  layoutsBySectionId => layoutsBySectionId.keySeq().toList(),
);

export const activeScreenSectionsByName = createSelector(
  [sections, unorderedActiveSectionIds],
  (sections, sectionIds) => getScreenSectionsByName(sections, sectionIds),
);

export const extendedScreenSections = createSelector(
  [activeScreenSectionsByName, screenDefinitionSections],
  (sectionsByName, screenDefinitionSections) => getOrderedSections(sectionsByName, screenDefinitionSections)
    .map((section, index) => {
      const screenDefinitionSection = screenDefinitionSections.get(index);

      return section.merge({
        brandStyles: screenDefinitionSection.get('styles'),
        displayName: screenDefinitionSection.get('displayName'),
        height: screenDefinitionSection.get('height'),
      }) as Models.ExtendedSectionMap;
    }),
);

const orderedActiveLayouts = createSelector(
  [extendedScreenSections, unorderedActiveLayoutsBySectionId],
  (screenSections, layoutsBySectionId) => {
    return screenSections.map(section => layoutsBySectionId.get(section.get('id'))).flatMap(layouts => layouts) as Models.LayoutsList;
  },
);

export const orderedActiveLayoutsBySectionId = createSelector(
  [orderedActiveLayouts],
  (layouts): Models.LayoutsBySectionId => layouts.groupBy(layout => layout.get('section')) as Models.LayoutsBySectionId,
);

export const orderedActiveLayoutIds = createSelector(
  [orderedActiveLayouts],
  orderedActiveLayouts => orderedActiveLayouts.map(layout => layout.get('id')),
);

export const isReferenceCitationElementOnArtboard = createSelector(
  [orderedActiveLayouts],
  (layouts) => {
    return layouts.some(layout => layout.get('type') === LayoutType.REFERENCE_CITATION_ELEMENT);
  },
);

export const groupLayouts = createSelector(layouts, layouts => layouts.filter(isGroupLayout));

export const isChildLayoutBeingUploaded = (id: string) => createSelector(
  [layouts, documentIdsAreBeingUploaded],
  (layouts, documentIdsAreBeingUploaded): boolean => {
    if (!id) {
      return false;
    }

    const layout = layouts.get(id);
    if (!layout || !isGroupLayout(layout)) {
      return false;
    }

    return _.some(
      layout.get('layoutIds').toArray(),
      (layoutId) => {
        const layout = layouts.get(layoutId);

        return !!layout && documentIdsAreBeingUploaded.has(layout.get('documentId'));
      },
    );
  },
);

export const isLayoutBeingUploaded = (id: string) => createSelector(
  [layouts, documentIdsAreBeingUploaded, isChildLayoutBeingUploaded(id)],
  (layouts, documentIdsAreBeingUploaded, isChildLayoutBeingUploaded) => {
    if (!id) {
      return false;
    }

    const layout = layouts.get(id);

    return !!layout && documentIdsAreBeingUploaded.has(layout.get('documentId')) || isChildLayoutBeingUploaded;
  },
);
