import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import Checkbox from 'components/Checkbox';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import NumericInput from 'components/NumericInput';
import AssetAlignment from 'components/Toolbar/Controls/AssetAlignment/List';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import MobileViewImage from 'containers/Toolbar/Shared/MobileViewImage';
import { intlGet } from 'utils/intlGet';
import { ImageMobileSettingsDropdownProps } from './models';
import styles from './styles.module.scss';

const MOBILE_WIDTH_MIN_VALUE = 1;
const MOBILE_WIDTH_MAX_VALUE = 100;

const ImageMobileSettingsDropdown: React.FunctionComponent<ImageMobileSettingsDropdownProps> = (props) => {
  const {
    alignmentDisabled,
    alignment,
    isDefaultMobilePreview,
    mobileSettingsApplied,
    scale,
    mobileViewImage,
    toggleAlignment,
    toggleMobileSettingsApplied,
    toggleScale,
    toggleMobileViewImage,
    layoutId,
  } = props;
  const [scaleState, setScale] = React.useState(scale * 100);

  React.useEffect(
    () => setScale(scale * 100),
    [scale],
  );

  const onChangeScale = (value: number): void => {
    const newValue = _.round(_.clamp(value, MOBILE_WIDTH_MIN_VALUE, MOBILE_WIDTH_MAX_VALUE));
    setScale(newValue);
    toggleScale(newValue / 100);
  };

  const applyScale = (): void => {
    onChangeScale(scaleState);
  };

  return (
    <Dropdown
      className={classNames(
        styles.ImageMobileSettingsDropdownClass,
        { [styles.disabled]: !mobileSettingsApplied },
      )}
      stopClickPropagation={true}
    >
      <div className={styles.TitleGroup}>
        <div className={styles.title}>
          {intlGet(
            isDefaultMobilePreview ? 'EditorToolbar.ImageDesktopSettings' : 'EditorToolbar.ImageMobileSettings',
            'Title',
          )}
        </div>

        <Checkbox
          onChange={(): void => toggleMobileSettingsApplied(!mobileSettingsApplied)}
          checked={mobileSettingsApplied}
        />
      </div>

      <div className={styles.settingsArea}>
        <div className={classNames(styles.ResizeGroup, styles.bottomBorder)}>
          <div className={styles.controlTitle}>{intlGet('EditorToolbar.ImageMobileSettings.Resize', 'Title')}</div>
          <div className={styles.ResizeControl}>
            <NumericInput
              className={styles.resizeInput}
              disabled={!mobileSettingsApplied}
              disabledInput={false}
              value={scaleState}
              defaultValue={100}
              onChange={setScale}
              onEnterPress={applyScale}
              showButtonControls={true}
              min={MOBILE_WIDTH_MIN_VALUE}
              max={MOBILE_WIDTH_MAX_VALUE}
              maxLength={2}
              step={1}
              onChangeSize={onChangeScale}
              addEvent={true}
            />
            <span className={styles.resizeDescription}>{intlGet('EditorToolbar.ImageMobileSettings.Resize', 'Description')}</span>
          </div>
        </div>

        <div className={styles.bottomBorder}>
          <div className={styles.controlTitle}>{intlGet('EditorToolbar.ImageMobileSettings.Align', 'Title')}</div>
          <div className={styles.AlignmentControl}>
            <AssetAlignment
              isDisabled={alignmentDisabled || !mobileSettingsApplied}
              alignment={alignment}
              showVertical={false}
              toggleAlignment={toggleAlignment}
            />
          </div>
        </div>

        <div>
          <div className={classNames(styles.controlTitle, 'd-flex', 'align-items-center')}>
            {intlGet(
              isDefaultMobilePreview ? 'EditorToolbar.ImageDesktopSettings' : 'EditorToolbar.ImageMobileSettings',
              'Image',
            )}
            <Icon
              type={IconType.EXCLAMATION_MARK}
              size="badge"
              color="primary"
              hoverTooltipText={intlGet(
                isDefaultMobilePreview ? 'EditorToolbar.Tooltip.Desktop' : 'EditorToolbar.Tooltip',
                IconType.TOOLBAR_MOBILE_IMAGE)}
              className='ml-2'
            />
          </div>

          <MobileViewImage
            className={styles.BackgroundImageTab}
            mobileViewImage={mobileViewImage}
            toggleMobileViewImage={toggleMobileViewImage}
            layoutId={layoutId}
          />
        </div>
      </div>

    </Dropdown>
  );
};

export default ImageMobileSettingsDropdown;
