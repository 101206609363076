import React from 'react';

import { IErrorBoundaryProps, IErrorBoundaryState } from './models';
import styles from './styles.module.scss';

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  readonly state: IErrorBoundaryState = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // eslint-disable-next-line no-console
    console.log('Error occurred in React component.', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.ErrorBoundary}>
          Something went wrong. Please reload the app.
        </div>
      );
    }

    return this.props.children;
  }
}
