import React from 'react';

import { ScreenFormatType } from 'const';
import { isCustomScreenFormatType } from 'utils/screens/isCustomScreenFormat';

interface UseScreenFormatProps {
  initialFormatType?: ScreenFormatType;
  initialHeight?: number;
  initialWidth?: number;
}

export const useScreenFormat = ({
  initialFormatType = null,
  initialHeight = null,
  initialWidth = null,
}: UseScreenFormatProps = {}) => {
  const [formatType, setFormatType] = React.useState<ScreenFormatType>(initialFormatType); // TBC: should some format be selected by default?
  const [height, setHeight] = React.useState<number>(initialHeight);
  const [width, setWidth] = React.useState<number>(initialWidth);

  return {
    height,
    setHeight,
    width,
    setWidth,
    formatType,
    setFormatType,
    isDisabled: !isCustomScreenFormatType(formatType),
  };
};
