import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_ARTBOARD: null,
    ADD_SSI: null,
    ARTBOARD_IMAGES_LOADED: null,
    ARTBOARD_MOUNTED: null,
    ARTBOARD_READY: null,
    ARTBOARD_WILL_BE_MOUNTED: null,
    DELETE_ARTBOARDS: null,
    DELETE_SSI: null,
    DOWNLOAD_ARTBOARDS_JSON: null,
    DROP_STORY_CARD: null,
    MOVE_SSI: null,
    SET_ARTBOARDS: null,
    SET_SSI_SOURCE: null,
    TOGGLE_ACTIVE_ARTBOARD_MODE: null,
    UPDATE_ARTBOARD: null,
  },
  'Artboards',
);
