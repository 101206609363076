import { call } from 'redux-saga/effects';
import { Action } from '../models';
import { generatorTextAbbreviations } from '../services/generatorTextAbbreviations';

export function* requestTextAbbreviations(action: Action.GetTextAbbreviations) {
  const { payload: rootDocumentId } = action;
  if (!rootDocumentId) {
    return;
  }
  yield call(generatorTextAbbreviations, rootDocumentId);
}
