import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RefreshPanel from 'components/RefreshPanel';
import { RefreshPanelActions, RefreshPanelSelectors } from 'components/RefreshPanel/models';
import { isRefreshAvailable, lastRefreshTime } from 'containers/Project/selectors';
import { refreshProject } from 'containers/ProjectScreen/actions';

const mapStateToProps = createStructuredSelector({
  isRefreshAvailable,
  lastRefreshTime,
});

const mapDispatchToProps = {
  refreshProject,
};

export default connect<RefreshPanelSelectors, RefreshPanelActions>(mapStateToProps, mapDispatchToProps)(RefreshPanel);
