import { DragSourceCollector, DragSourceSpec } from 'react-dnd';

import { DragSourceType, EntityType } from 'const';
import { GroupLayoutDocumentMap, LayoutDragObject, ReusableLayoutMap } from 'models';
import * as Models from './models';
import { isGroupReusableLayoutValid, isReusableLayoutValid } from './utils';

export const ReusableLayoutListItemDragSource: DragSourceSpec<Models.LayoutListItemProps, LayoutDragObject> = {
  beginDrag(props) {
    const { toggleDragState, document, storyCardId } = props;
    toggleDragState(DragSourceType.REUSABLE_LAYOUT, EntityType.LAYOUT);

    return {
      document,
      storyCardId,
    };
  },
  canDrag(props) {
    const { document, documentIdsAreBeingUploaded } = props;
    const isLayoutBeingUploaded = documentIdsAreBeingUploaded.has(document.get('id'));

    return !isLayoutBeingUploaded && isReusableLayoutValid(document as ReusableLayoutMap);
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

export const reusableLayoutCollector: DragSourceCollector<Models.ReusableLayoutListItemCollectedProps, Models.LayoutListItemAllOwnProps> = (
  connect,
  monitor,
) => ({
  connectRLDragSource: connect.dragSource(),
  connectRLDragPreview: connect.dragPreview(),
  isRLDragging: monitor.isDragging(),
});

export const GroupLayoutListItemDragSource: DragSourceSpec<Models.LayoutListItemProps, LayoutDragObject> = {
  beginDrag(props) {
    const { toggleDragState, document, storyCardId } = props;
    toggleDragState(DragSourceType.GROUP_LAYOUT, EntityType.LAYOUT);

    return {
      document,
      storyCardId,
    };
  },
  canDrag(props) {
    const { document, documentIdsAreBeingUploaded } = props;
    const isLayoutBeingUploaded = documentIdsAreBeingUploaded.has(document.get('id'));

    return !isLayoutBeingUploaded && isGroupReusableLayoutValid(document as GroupLayoutDocumentMap);
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

export const groupLayoutCollector: DragSourceCollector<Models.GroupLayoutListItemCollectedProps, Models.LayoutListItemAllOwnProps> = (
  connect,
  monitor,
) => ({
  connectGLDragSource: connect.dragSource(),
  connectGLDragPreview: connect.dragPreview(),
  isGLDragging: monitor.isDragging(),
});
