import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import StoryCardDetails from 'components/ModalWindows/StoryCardDetails';
import { StoryCardDetailsActions, StoryCardDetailsOwnProps, StoryCardSelectors } from 'components/ModalWindows/StoryCardDetails/models';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: StoryCardDetailsOwnProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect<StoryCardSelectors, StoryCardDetailsActions, StoryCardDetailsOwnProps>(mapStateToProps, mapDispatchToProps)(StoryCardDetails);
