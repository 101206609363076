import Immutable from 'immutable';
import { call, put, select } from 'redux-saga/effects';

import { setLastEditedLayoutId } from 'containers/App/actions';
import { setLayouts } from 'containers/Layouts/actions';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import { CombinedLayoutMap } from 'models';
import { updateLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';
import * as Models from '../models';

export function* setLayoutAndInstances(action: Models.Action.ISetLayout) {
  try {
    const layout = Immutable.fromJS(action.payload.layout) as CombinedLayoutMap;
    const layouts: ReturnTypeSaga<typeof layoutsSelectors.layouts> = yield select(layoutsSelectors.layouts);
    const updatedLayouts = updateLayoutInstances(layout, layouts);

    yield put(setLastEditedLayoutId(layout.get('id')));

    // listening for this action in saveGroupLayout saga to wait for recalculating height of GRL
    yield put(setLayouts(updatedLayouts));
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], error);
  }
}
