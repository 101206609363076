import { Schemas } from 'services/ArtboardConverter/models';

export const createTextFormatting = ({
  bold = false,
  italic = false,
  underline = false,
} = {} as Partial<Schemas.TextFormatting>): Schemas.TextFormatting => ({
  bold,
  italic,
  underline,
});
