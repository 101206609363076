import React from 'react';

const ToolbarLeft = (): JSX.Element => (
  <svg viewBox="0 0 512 512">
    <g fill="#ffffff">
      <path d="M0,32h512v64H0V32z M0,128h320v64H0V128z M0,320h320v64H0V320z M0,224h512v64H0V224z M0,416h512v64H0V416z" />
    </g>
  </svg>
);

export default ToolbarLeft;
