import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MobileViewImage from 'components/Toolbar/Shared/MobileViewImage';
import { imagesForAssetsPanel } from 'containers/Documents/selectors';
import { showModal } from 'containers/ModalManager/actions';
import { documentIdsAreBeingUploaded } from 'containers/Project/selectors';

const mapStateToProps = createStructuredSelector({
  documentIdsAreBeingUploaded,
  imagesForAssetsPanel,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileViewImage);
