import _ from 'lodash';
import { all, call, put } from 'redux-saga/effects';

import { RenditionType } from 'const';
import { ImageInternalInfo } from 'models';
import { ImageCache } from 'services/imageCache';
import { updateStoryCardLayoutThumbnail } from '../actions';
import * as Models from '../models';
import { updateLayoutThumbnails } from '../services/updateLayoutThumbnails';

export function* getStoryCardLayoutThumbnails(action: Models.Action.IGetStoryCardLayoutThumbnails) {
  try {
    const { storyCardId, documents } = action.payload;

    const actions = [];
    const imageCache = ImageCache.getInstance();
    const documentsToProcess = _.filter(documents, (document) => {
      const { id, renditions } = document;
      const checksum = _.get(renditions, [RenditionType.CUSTOM_THUMBNAIL, 'checksum']);
      const cachedData = imageCache.getItem(RenditionType.CUSTOM_THUMBNAIL, checksum) as ImageInternalInfo;

      if (cachedData) {
        actions.push(() => put(updateStoryCardLayoutThumbnail(id, cachedData, storyCardId)));
      }

      return !cachedData;
    });

    yield all(actions.map((thumbnailAction: () => void) => thumbnailAction()));

    if (_.isEmpty(documentsToProcess)) {
      return;
    }

    yield call(
      updateLayoutThumbnails,
      documentsToProcess,
      (id: string, thumbnailInternalInfo: ImageInternalInfo) => updateStoryCardLayoutThumbnail(id, thumbnailInternalInfo, storyCardId),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during getting Story Card additional info.', error);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    yield call(alert, `Error occurred during getting Story Card additional info: ${error}`);
  }
}
