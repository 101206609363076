import { Character } from 'const';

export const replaceNonBreakingSpaces = (text: string): string => {
  return text.replace(new RegExp(Character.NBSP, 'g'), ' ');
};

/**
 * Unicode has the following zero-width characters:
 * U+200B zero width space
 * U+200C zero width non-joiner Unicode code point
 * U+200D zero width joiner Unicode code point
 * U+FEFF zero width no-break space Unicode code point
 */
export const removeZeroWidthSpace = (text: string): string => {
  return text.replace(/[\u200B-\u200D\uFEFF]/g, '');
};
