import { CharacterMetadata, EditorState, RichUtils, SelectionState } from 'draft-js';
import * as Constants from 'const';
import * as models from 'models';
import { removeEntities, reselectEntities } from './entity';
import { getSelectedEntities } from './selection';

function getSelectedAbbreviationEntity(
  editorState: EditorState,
): models.BlockEntityMap | undefined {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  return getSelectedEntities(selection, currentContent).find(
    entity => entity.get('type') === Constants.DraftEntity.ABBREVIATION,
  );
}

function getSelectionText(editorState: EditorState): string {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const anchorKey = selection.getAnchorKey();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  return currentContentBlock.getText().slice(start, end);
}

function trimSpacesFromSelection(
  editorState: EditorState,
): EditorState {
  const text = getSelectionText(editorState);
  const start = text.length - text.trimStart().length;
  const end = text.length - text.trimEnd().length;

  const selection = editorState.getSelection();
  const updatedSelection = selection.merge(selection.getIsBackward() ? {
    anchorOffset: selection.getAnchorOffset() - end,
    focusOffset: selection.getFocusOffset() + start,
  } : {
    anchorOffset: selection.getAnchorOffset() + start,
    focusOffset: selection.getFocusOffset() - end,
  });

  return EditorState.acceptSelection(editorState, updatedSelection as SelectionState);
}

const createAbbeviation = (
  editorState: EditorState,
  abbreviationId: string,
): EditorState => {
  let updatedEditorState = trimSpacesFromSelection(editorState);

  const updatedContent = updatedEditorState.getCurrentContent().createEntity(
    Constants.DraftEntity.ABBREVIATION,
    Constants.DraftEntityMutability.MUTABLE,
    { id: abbreviationId },
  );
  updatedEditorState = EditorState.set(updatedEditorState, { currentContent: updatedContent });
  const entityKey = updatedContent.getLastCreatedEntityKey();

  return RichUtils.toggleLink(updatedEditorState, reselectEntities(updatedEditorState), entityKey);
};

const deleteAbbreviation = (editorState: EditorState): EditorState => {
  const targetSelection = reselectEntities(editorState);

  // it doesn't work: https://github.com/facebookarchive/draft-js/issues/2391
  removeEntities(editorState);

  return RichUtils.toggleLink(editorState, targetSelection, null);
};

export const toggleAbbreviation = (
  editorState: EditorState,
  abbreviationId: string | undefined,
): EditorState => {
  return abbreviationId ? createAbbeviation(editorState, abbreviationId) : deleteAbbreviation(editorState);
};

type AbbreviationData = {
  id?: string;
  term?: string;
};

export function parseAbbreviationData(editorState: EditorState): AbbreviationData {
  const currentContent = editorState.getCurrentContent();
  const abbreviationEntity = getSelectedAbbreviationEntity(editorState);

  if (!abbreviationEntity) {
    return {
      term: getSelectionText(editorState).trim(),
    };
  }

  const currentEntity = currentContent.getEntity(abbreviationEntity.get('key'));
  const { id } = currentEntity?.getData() || {};
  const currentBlock = currentContent.getBlockForKey(abbreviationEntity.get('block'));
  const term = currentBlock.getText().slice(abbreviationEntity.get('startOffset'), abbreviationEntity.get('endOffset'));

  return { term, id };
}

export function selectAbbreviation(
  editorState: EditorState,
  abbreviationId: string,
  abbreviationNumber = 1,
): EditorState | false {
  const content = editorState.getCurrentContent();
  const getAbbreviationIdForChar = (char: CharacterMetadata | undefined): string | undefined => {
    const entityId = char?.getEntity();
    const { id } = entityId && content.getEntity(entityId)?.getData() || {};

    return id;
  };

  let start = -1;
  let end = -1;
  let number = 1;

  const validateNumberOrReset = (): boolean => {
    if (number === abbreviationNumber) {
      return true;
    }
    start = -1;
    end = -1;
    number += 1;

    return false;
  };

  const blockWithAbbreviation = content.getBlockMap().find((block) => {
    block?.getCharacterList().forEach((char, idx: number): boolean => {
      if (start === -1) {
        if (getAbbreviationIdForChar(char) === abbreviationId) {
          start = idx;
          end = idx;
        }

        return true;
      }

      if (getAbbreviationIdForChar(char) !== abbreviationId) {
        return !validateNumberOrReset();
      }

      end = idx;

      return true;
    });
    validateNumberOrReset();

    return start !== -1;
  });

  if (!blockWithAbbreviation) {
    return false;
  }

  const selection = SelectionState.createEmpty(blockWithAbbreviation.getKey())
    .set('anchorOffset', start)
    .set('focusOffset', end + 1);

  return EditorState.forceSelection(editorState, selection as SelectionState);
}
