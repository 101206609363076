import React from 'react';
/* eslint-disable max-len */

const UploadProject = (): JSX.Element => (
  <svg viewBox="0 0 27 19">
    <g transform="translate(0.783784, 0.157715)">
      <path d="M14.6881551,10.9627003 L14.6881551,15.1502726 L10.5005828,15.1502726 L10.5005828,10.9627003 L7.70878196,10.9627003 L12.5944012,6.07714562 L17.4798914,10.9627003 L14.6881551,10.9627003 Z M21.8987482,7.89548705 C21.8987482,7.89548705 21.7495906,2.07408487 16.0823781,3.56875701 C16.0823781,3.56875701 13.44967,-1.05344949 7.87987444,1.36603814 C5.34458327,2.46736531 4.15151646,4.82743507 4.0022944,7.65942847 C4.0022944,7.65942847 0.423093972,8.13148113 0.0502001113,11.9861585 C-0.322629235,15.8408359 1.39242447,17.5715021 4.82259638,18.0435548 L21.2276037,18.0435548 C21.2276037,18.0435548 26,18.1222625 26,13.0874857 C26,8.05277343 21.8987482,7.89548705 21.8987482,7.89548705 L21.8987482,7.89548705 Z" />
    </g>
  </svg>
);

export default UploadProject;
