import React from 'react';

export const useToggle = (initialToggle = false) => {
  const [on, setState] = React.useState(initialToggle);
  const toggle = React.useCallback(() => setState(on => !on), [on]);
  const toggleOn = React.useCallback(() => setState(true), [on]);
  const toggleOff = React.useCallback(() => setState(false), [on]);

  return {
    on,
    toggle,
    toggleOn,
    toggleOff,
  };
};
