import * as Models from 'models';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getImageSize } from 'utils/styles/getImageSize';
import { toPx } from 'utils/toPx';

export function getImageWidth(
  relation: Models.RegularRelationMap<Models.ImageRelationStyles>,
  docImageWidth: number,
  docImageHeight: number,
  columnWidth: number,
  cellHeight: number,
): string {
  const styles = relation.get('styles');
  const scale = styles.get('scale');
  const extraHeight = getExtraHeight(styles);
  const extraWidth = getExtraWidth(styles);

  const { width } = getImageSize({
    scale,
    docImageWidth,
    docImageHeight,
    cellInnerHeight: cellHeight - extraHeight,
    cellInnerWidth: columnWidth - extraWidth,
  });

  return toPx(width);
}
