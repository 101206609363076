import classNames from 'classnames';
import { CellActionsProvider } from 'context/CellActions';
import Immutable from 'immutable';
import React from 'react';
import { DropTarget, DropTargetCollector, DropTargetSpec } from 'react-dnd';

import ArtboardCellActions from 'components/ArtboardCellActions';
import { DragSourceType, EntityType, MinCellWidth } from 'const';
import ArtboardImage from 'containers/ArtboardImage';
import Text from 'containers/ArtboardText';
import CallToAction from 'containers/CallToAction';
import ColumnResizer from 'containers/ColumnResizer';
import RowResizer from 'containers/RowResizer';
import * as Models from 'models';
import { prioritizeLayeredRelation } from 'utils/prioritizeLayeredRelations';
import { useArtboardCell } from './hooks';
import { ArtboardCellAllOwnProps, ArtboardCellCollectedProps, ArtboardCellOwnProps, ArtboardCellProps } from './models';
import styles from './styles.module.scss';

const allowedToDropItems: EntityType[] = [
  // ANCHOR is allowed but handled separately
  // EntityType.ANCHOR,
  EntityType.CALL_TO_ACTION,
  EntityType.IMAGE,
  EntityType.REFERENCE_CITATION,
  EntityType.TEXT,
  EntityType.LAYOUT_RENDITION,
];

const DragHotspotDropTarget: DropTargetSpec<ArtboardCellProps> = {
  drop(props, monitor) {
    const { component, type } = monitor.getItem() as Models.AssetDragComponent;
    const {
      activeLayer,
      addTextToCell,
      dropAsset,
      relation: layeredRelation,
      sectionStyles,
      layoutId,
    } = props;
    const relation = prioritizeLayeredRelation(layeredRelation, activeLayer);

    // TODO: move all this stuff to sagas
    if ([EntityType.CALL_TO_ACTION, EntityType.IMAGE, EntityType.LAYOUT_RENDITION].includes(type)) {
      dropAsset(
        // eslint-disable-next-line max-len
        relation as Models.RegularRelationMap<Models.CallToActionStyles> | Models.RegularRelationMap<Models.ImageRelationStyles> | Models.RegularRelationMap<Models.LayoutStyles>,
        component as Models.CallToActionMap | Models.ImageMap | Models.ReusableLayoutMap,
        layoutId,
        (component as Models.CallToActionMap).get('size'),
      );
    } else if (type === EntityType.TEXT) {
      addTextToCell(
        relation.get('id'),
        Immutable.Map({ [relation.get('id')]: relation }) as Models.RelationsMap,
        component as Models.TextComponentMap,
        layoutId,
        [],
        sectionStyles,
        true,
      );
    }
  },
  canDrop(props, monitor) {
    const { notEditable, isDraggingAnchor, width } = props;
    const { type } = monitor.getItem() as Models.AssetDragObject;

    if (notEditable) {
      return isDraggingAnchor;
    }

    switch (type) {
      case EntityType.CALL_TO_ACTION: {
        return width >= MinCellWidth.CTA;
      }
      default: return allowedToDropItems.includes(type);
    }
  },
};

const collect: DropTargetCollector<ArtboardCellCollectedProps, ArtboardCellAllOwnProps> = (connect, monitor) => {
  return {
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    dropItem: monitor.getItem(),
    isOver: monitor.isOver(),
  } as ArtboardCellCollectedProps;
};

const ArtboardCell: React.FunctionComponent<ArtboardCellProps> = (props) => {
  const {
    activeLayer,
    areActionsDisabled,
    cellPosition,
    cellsCount,
    connectDropTarget,
    disableCellWidthEditing,
    dropItem,
    height,
    isDraggingAnchor,
    isIncreasingWidthDisabled = false,
    isLayoutEmpty,
    isOver,
    isResizingRow,
    isSectionActive,
    layoutId,
    layoutRelations,
    notEditable,
    relation,
    sectionStyles,
    showColumnResizer = false,
    showRowResizer = false,
    toggleLayoutRelations,
    width,
  } = props;
  const {
    canDropHere,
    component,
    container,
    copyingEnabled,
    deletingDisabled,
    document,
    docImageHeight,
    docImageWidth,
    documentImageSrc,
    editMode,
    enterEditMode,
    toggleEditModeOff,
    isAddingDisabled,
    isParentRelationRow,
    isSelected,
    maxCellHeight,
    minCellHeight,
    onAddCell,
    onCopyContent,
    onDeleteCell,
    onImageLoad,
    onSelectHandler,
    onSplitCell,
    toggleColumnWidth,
    toggleRowAndNeighborsHeight,
    toggleRowHeight,
    isAutoFitContent,
    toggleAutoFitContent,
  } = useArtboardCell(props);
  const isLastCell = cellPosition + 1 === cellsCount;

  const renderComponent = (): JSX.Element => {
    switch (relation.get('entityType')) {
      case EntityType.TEXT: return (
        <CellActionsProvider
          relationId={relation.get('id')}
          toggleEditModeOn={enterEditMode}
          toggleEditModeOff={toggleEditModeOff}
          containerRef={container}
        >
          <Text
            activeLayer={activeLayer}
            cellHeight={height}
            cellPosition={cellPosition}
            cellWidth={width}
            cellsCount={cellsCount}
            disableCellWidthEditing={disableCellWidthEditing}
            document={document as Models.TextComponentMap}
            dropItem={dropItem}
            editMode={editMode}
            isIncreasingWidthDisabled={isIncreasingWidthDisabled}
            layoutId={layoutId}
            layoutRelations={layoutRelations}
            maxCellHeight={maxCellHeight}
            minCellHeight={minCellHeight}
            notEditable={notEditable}
            relation={relation as Models.LayeredRegularRelationMap<Models.TextRelationStyles>}
            sectionStyles={sectionStyles}
            shrinkable={!isLayoutEmpty}
            toggleColumnWidth={toggleColumnWidth}
            toggleRowAndNeighborsHeight={toggleRowAndNeighborsHeight}
            toggleRowHeight={toggleRowHeight}
            isAutoFitContent={isAutoFitContent}
            toggleAutoFitContent={toggleAutoFitContent}
          />
        </CellActionsProvider>
      );
      case EntityType.LAYOUT_RENDITION:
      case EntityType.IMAGE: return (
        <CellActionsProvider
          relationId={relation.get('id')}
          toggleEditModeOn={enterEditMode}
          toggleEditModeOff={toggleEditModeOff}
          containerRef={container}
        >
          <ArtboardImage
            canDrop={canDropHere}
            cellHeight={height}
            cellPosition={cellPosition}
            cellWidth={width}
            cellsCount={cellsCount}
            disableCellWidthEditing={disableCellWidthEditing}
            documentNumber={document && document.get('number')}
            imageSrc={documentImageSrc}
            docImageWidth={docImageWidth}
            docImageHeight={docImageHeight}
            editMode={editMode}
            isIncreasingWidthDisabled={isIncreasingWidthDisabled}
            layoutId={layoutId}
            layoutRelations={layoutRelations}
            maxCellHeight={maxCellHeight}
            minCellHeight={minCellHeight}
            onImageLoad={onImageLoad}
            relation={relation as Models.LayeredRegularRelationMap<Models.ImageRelationStyles>}
            shrinkable={!isLayoutEmpty}
            toggleColumnWidth={toggleColumnWidth}
            toggleRowAndNeighborsHeight={toggleRowAndNeighborsHeight}
            toggleRowHeight={toggleRowHeight}
            isAutoFitContent={isAutoFitContent}
            toggleAutoFitContent={toggleAutoFitContent}
          />
        </CellActionsProvider>
      );
      case EntityType.CALL_TO_ACTION: return (
        <CellActionsProvider
          relationId={relation.get('id')}
          toggleEditModeOn={enterEditMode}
          toggleEditModeOff={toggleEditModeOff}
          containerRef={container}
        >
          <CallToAction
            cellHeight={height}
            cellWidth={width}
            disableCellWidthEditing={disableCellWidthEditing}
            document={document as Models.CallToActionMap}
            editMode={editMode}
            isIncreasingWidthDisabled={isIncreasingWidthDisabled}
            layoutId={layoutId}
            layoutRelations={layoutRelations}
            maxCellHeight={maxCellHeight}
            minCellHeight={minCellHeight}
            relation={relation as Models.LayeredRegularRelationMap<Models.CallToActionStyles>}
            toggleColumnWidth={toggleColumnWidth}
            toggleRowAndNeighborsHeight={toggleRowAndNeighborsHeight}
            toggleRowHeight={toggleRowHeight}
            isAutoFitContent={isAutoFitContent}
            toggleAutoFitContent={toggleAutoFitContent}
          />
        </CellActionsProvider>
      );
      default: return null;
    }
  };

  return connectDropTarget((
    <div
      ref={container}
      onDoubleClick={enterEditMode}
      className={classNames(styles.ArtboardCell, {
        [styles.dropHotspot]: canDropHere && !isDraggingAnchor,
        [styles.stretchable]: isLastCell && !height,
      })}
      style={{
        maxWidth: width || 'none',
        // do not override flex-basis of last cell, it's defined in css
        ...width && isParentRelationRow
          ? { flexBasis: width }
          : !isLastCell
            ? { flexBasis: 'auto' }
            : {},
        ...height ? { height } : {},
      }}
    >
      {
        showColumnResizer &&
        <ColumnResizer
          container={container}
          layoutId={layoutId}
          relationIsBeingResizedId={relation.get('id')}
          relations={layoutRelations}
          toggleRelations={toggleLayoutRelations}
        />
      }
      {
        // initially sliders are shown only within active section,
        // but when user resizes row on border of two section and move away from active one we should not hide slider
        (isSectionActive || isResizingRow) && showRowResizer &&
        <RowResizer
          container={container}
          height={height}
          layoutId={layoutId}
          relationIsBeingResizedId={relation.get('id')}
          relations={layoutRelations}
          toggleRelations={toggleLayoutRelations}
        />
      }
      {
        notEditable
          ?
          <div className={styles.ArtboardCellWrapper}>{renderComponent()}</div>
          :
          <ArtboardCellActions
            addCell={onAddCell}
            areActionsDisabled={areActionsDisabled}
            canDrop={canDropHere}
            cellWidth={width}
            copyContent={onCopyContent}
            copyingEnabled={copyingEnabled}
            deleteCell={onDeleteCell}
            deletingDisabled={deletingDisabled}
            isAddingDisabled={isAddingDisabled}
            isOver={isOver}
            isEditMode={editMode}
            isSelected={isSelected}
            outlineOnly={editMode}
            splitCell={onSplitCell}
            component={component}
            document={document}
            onSelectHandler={onSelectHandler}
          >
            {renderComponent()}
          </ArtboardCellActions>
      }
    </div>
  ));
};

export default DropTarget<ArtboardCellOwnProps, ArtboardCellCollectedProps>(
  [DragSourceType.ASSET],
  DragHotspotDropTarget,
  collect,
)(ArtboardCell);
