import { call, put, select } from 'redux-saga/effects';

import { setSurfaceIds } from 'containers/Project/actions';
import * as projectSelectors from 'containers/Project/selectors';
import { handleSagaError } from 'services/handleError';
import * as Models from '../models';

export function* moveScreen(action: Models.Action.IMoveScreen) {
  try {
    const { surfaceDefinitionId, position } = action.payload;
    const surfaceIds: ReturnTypeSaga<typeof projectSelectors.surfaceIds> = yield select(projectSelectors.surfaceIds);
    const currentPosition = surfaceIds.indexOf(surfaceDefinitionId);
    const updatedSurfaces = surfaceIds.delete(currentPosition).insert(position, surfaceDefinitionId).toArray();

    yield put(setSurfaceIds(updatedSurfaces));
  } catch (error) {
    yield call(handleSagaError, error, 'ScreensPanel.moveScreen', 'MoveScreen');
  }
}
