import guid from 'uuid';

import { DocumentsSource, EntityType } from 'const';
import { TextComponent } from 'models';
import { removeTags } from 'utils/removeTags';

export function textComponentFactory({
  id = guid(),
  text = '',
  status = null,
  rawContent = '',
  name = removeTags(text),
  documentSource = [DocumentsSource.ONLINE],
  language = [],
  country = [],
  referenceCitations = [],
  translatable = true,
} = {} as Partial<TextComponent>): TextComponent {
  return {
    id,
    text,
    entityType: EntityType.TEXT,
    language,
    country,
    name,
    documentSource,
    status,
    rawContent,
    referenceCitations,
    translatable,
  };
}
