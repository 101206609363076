import _ from 'lodash';

import { MasterScreen } from 'models';

// If screenDefinition has fixed artboard height and there are sections
// without own height, we should divide available height between these sections
export function ensureSectionsHeights(screenDefinitions: MasterScreen.ScreenDefinitions): MasterScreen.ScreenDefinitions {
  const newScreenDefinitions = _.cloneDeep(screenDefinitions);

  _.each(newScreenDefinitions, (screenDefinition) => {
    const { screenHeight, sections } = screenDefinition;
    const sectionsHeight = _.sumBy(sections, section => _.get(section, 'height', 0));

    if (!screenHeight || screenHeight <= sectionsHeight) {
      return;
    }

    const availableHeight = screenHeight - sectionsHeight;
    const sectionsWithoutHeightCount = sections.filter(({ height }) => !height).length;
    const calculatedSectionHeight = _.round(availableHeight / sectionsWithoutHeightCount);

    _.forEach(sections, (section) => { section.height = section.height || calculatedSectionHeight; });
  });

  return newScreenDefinitions;
}
