import { DraftInlineStyle } from 'draft-js';
import React from 'react';

import { CHAR_STYLES, InlineStylesForToggling } from 'const';
import { findFontWeightStyle, getFontWeightFromStyle } from 'utils/inlineStyles';
import { IconType } from '../../../Icon/constants';
import EditorToolbarButton from '../EditorToolbarButton';
import styles from './styles.module.scss';

type Props = {
  activeStyle: DraftInlineStyle | undefined;
  onToggle: (style: string) => void;
};

const CharStyleControl: React.FunctionComponent<Props> = (props) => {
  const { activeStyle: currentStyle, onToggle } = props;
  const fontWeightStyle = currentStyle && findFontWeightStyle(currentStyle);
  const fontWeight = fontWeightStyle
    ? getFontWeightFromStyle(fontWeightStyle)
    : getFontWeightFromStyle(InlineStylesForToggling.REGULAR);
  const fontWeightBoldOrHeavier = Number(fontWeight) >= Number(getFontWeightFromStyle(InlineStylesForToggling.BOLD));

  return (
    <div className={styles.CharStyleControl}>
      {CHAR_STYLES.map(type => (
        <EditorToolbarButton
          key={type.label}
          active={currentStyle?.has(type.style) || type.label === IconType.TOOLBAR_BOLD && fontWeightBoldOrHeavier}
          label={type.label}
          onToggle={onToggle}
          value={type.style}
          title={type.title}
        />
      ))}
    </div>
  );
};

export default CharStyleControl;
