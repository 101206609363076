import { all, call, put, select } from 'redux-saga/effects';

import { UIFontFaces } from 'containers/Common/selectors';
import { setProjectAssets } from 'containers/Common/services/setProjectAssets';
import { setCountryAbbreviations } from 'containers/CountryAbbreviations/actions';
import { updateActiveSurfaceId } from 'containers/Project/actions';
import * as Models from 'models';
import { appendCustomFontFaces } from 'services/appendFontFace';

interface InitAppArg {
  setActiveScreen?: boolean;
  actionsWithMiddleware: Models.IAction[];
  projectAssets: Models.ProjectAssetsToUpload;
  countryAbbreviations?: Models.CountryAbbreviations;
  textAbbreviations?: Models.TextAbbreviationDocuments;
}

export function* initApp<T extends InitAppArg>(arg: T): Generator<unknown, void> {
  const { projectAssets, actionsWithMiddleware = [], setActiveScreen = false, countryAbbreviations } = arg;

  if (setActiveScreen) {
    yield put(updateActiveSurfaceId(projectAssets.project.surfaceIds[0]));
  }

  if (countryAbbreviations) {
    yield put(setCountryAbbreviations(countryAbbreviations));
  }

  yield call(setProjectAssets, projectAssets as Models.ProjectAssets);
  yield all(actionsWithMiddleware.map(action => put(action)));
  yield call(appendCustomFontFaces, (yield select(UIFontFaces)).toJS() as Models.UIFontFace[]);
}
