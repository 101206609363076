import _ from 'lodash';

import { Styles } from 'const';
import { createCommonStyles } from 'factories/style';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { colorToSource, colorToTint } from 'utils/converters';
import { toJS } from 'utils/immutable';

export function getStylesToBeKept<S extends Models.CommonStyles>(styles: S): Models.CommonStyles {
  const {
    backgroundColor,
    backgroundColorTint,
    backgroundColorOpacity,
    backgroundGradient,
    backgroundImage,
    border,
    brandStyleChanged,
    isAutoFitContent,
    padding,
    borderRadius,
  } = styles;

  return createCommonStyles({
    backgroundColor,
    backgroundColorTint,
    backgroundColorOpacity,
    backgroundGradient,
    backgroundImage,
    border,
    brandStyleChanged,
    isAutoFitContent,
    padding,
    borderRadius,
  });
}

type GetRelationStylesToBeKeptResult<S> = S extends Models.TextRelationStyles
  ? Models.Layers<Models.CommonStyles & { brandStyleId: string }>
  : Models.Layers<Models.CommonStyles>;

export function getRelationStylesToBeKept<S extends Models.CombinedAssetRelationStyles = Models.CombinedAssetRelationStyles>(
  relation: Models.LayeredRegularRelation<S> | DeepIMap<Models.LayeredRegularRelation<S>>,
): GetRelationStylesToBeKeptResult<S> {
  const { styles } = toJS(relation);

  return _.mapValues(styles, (_styles) => {
    if (!_styles) {
      return _styles;
    }

    return {
      ...getStylesToBeKept(_styles),
    };
  }) as GetRelationStylesToBeKeptResult<S>;
}

function getDefaultStylesToBeKept(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): Models.CommonStyles {
  const getDefaultBrandStyles = () => {
    const {
      padding,
      backgroundColor,
    } = getTextStylesFromBrandStyle(brandStyle, colors, fonts);

    return {
      padding: toJS(padding),
      backgroundColor: colorToSource(backgroundColor),
      backgroundColorTint: colorToTint(backgroundColor),
    };
  };

  const {
    backgroundColor,
    backgroundColorTint,
    backgroundColorOpacity,
    backgroundGradient,
    backgroundImage,
    border,
    padding,
    brandStyleChanged,
  } = {
    ...createCommonStyles({ padding: Styles.DefaultTextPadding }),
    ...getDefaultBrandStyles(),
  };

  return {
    backgroundColor,
    backgroundColorTint,
    backgroundColorOpacity,
    backgroundGradient,
    backgroundImage,
    border,
    padding,
    brandStyleChanged,
  };
}

export const getChangedStylesToBeKept = (
  relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  brandStyles: Models.BrandStylesMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): Partial<Models.CommonStyles> => {
  const styles = toJS(relation.get('styles'));
  const { brandStyleId } = styles;
  const brandStyle = brandStyles.get(brandStyleId);

  const initialStyles = getDefaultStylesToBeKept(brandStyle, colors, fonts);
  const currentStyles = getStylesToBeKept(styles);

  return _.pickBy(currentStyles, (style, key) => !_.isEqual(style, initialStyles[key]));
};
