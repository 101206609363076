import React from 'react';

import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

const InterstitialPage: React.FunctionComponent = () => (
  <div className={styles.InterstitialPage}>
    <div className={styles.notification}>
      <header className={styles.header}>{intlGet('InterstitialPage', 'Assembler')}<span>.</span></header>
      <section className={styles.subheader}>{intlGet('InterstitialPage', 'DownForMaintenance')}</section>
      <section className={styles.text}>{intlGet('InterstitialPage', 'Apologize')}</section>
      <section className={styles.text}>{intlGet('InterstitialPage', 'CheckAgainLater')}</section>
    </div>
  </div>
);

export default InterstitialPage;
