import { Layer } from 'const';
import * as Models from 'models';
import { deleteColumn } from 'utils/deleteColumn';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { recalculateRowsHeight } from 'utils/rowsHeight';
import { getParent } from 'utils/rowsHeight/getParent';
import { isRelationUsedSeveralTimes } from 'utils/validator/isRelationStillUsed';

export function deleteRelationRecursively(
  relationIdToDelete: string,
  allRelations: Models.LayeredRelationsMap,
  allLayouts: Models.LayeredCombinedLayoutsMap,
  layer: Layer,
): Models.LayeredRelationsMap;
export function deleteRelationRecursively(
  relationIdToDelete: string,
  allRelations: Models.LayeredRelationsMap,
  allLayouts: Models.LayeredCombinedLayoutsMap,
  layer: Layer,
  relationIdsToReplace: [string],
): Models.LayeredRelationsMap;
export function deleteRelationRecursively(
  relationIdToDelete: string,
  allRelations: Models.LayeredRelationsMap,
  allLayouts: Models.LayeredCombinedLayoutsMap,
  layer: Layer,
  relationIdsToReplace: string[],
  sizesToReplace: number[],
): Models.LayeredRelationsMap;
export function deleteRelationRecursively(
  relationIdToDelete: string,
  allRelations: Models.LayeredRelationsMap,
  allLayouts: Models.LayeredCombinedLayoutsMap,
  layer: Layer,
  relationIdsToReplace?: string[],
  sizesToReplace?: number[],
): Models.LayeredRelationsMap {
  let relations = allRelations;

  const { parent, position: indexToDelete } = getParent(relationIdToDelete, relations);
  let parentRelation = parent;

  if (!parentRelation) {
    return relations;
  }

  // recalculate columns width
  if (isRowRelation(parentRelation)) {
    parentRelation = (parentRelation as Models.RowRelationMap).updateIn(['styles', 'columnsWidth'], (columnsWidth) => {
      switch (true) {
        case (!relationIdsToReplace || relationIdsToReplace.length === 0):
          return deleteColumn(columnsWidth, indexToDelete);
        case (relationIdsToReplace && relationIdsToReplace.length > 1):
          return columnsWidth.splice(indexToDelete, 1, ...sizesToReplace);
        default:
          return columnsWidth;
      }
    });
  // recalculate rows height
  } else if (isColumnRelation(parentRelation)) {
    parentRelation = (parentRelation as Models.ColumnRelationMap).updateIn(['styles', 'rowsHeight'], (rowsHeight) => {
      switch (true) {
        case (!relationIdsToReplace || relationIdsToReplace.length === 0): {
          relations = recalculateRowsHeight(relationIdToDelete, relations, rowsHeight.get(indexToDelete));
          const updatedRowsHeight = relations.getIn([parentRelation.get('id'), 'styles', 'rowsHeight']);

          return updatedRowsHeight.remove(indexToDelete);
        }
        case (relationIdsToReplace && relationIdsToReplace.length > 1):
          return rowsHeight.splice(indexToDelete, 1, ...sizesToReplace);
        default:
          return rowsHeight;
      }
    });
  }

  // remove from parent relation
  parentRelation = (parentRelation as Models.ColumnRelationMap).update('relationIds', (parentRelationIds) => {
    return relationIdsToReplace
      ? parentRelationIds.splice(indexToDelete, 1, ...relationIdsToReplace)
      : parentRelationIds.delete(indexToDelete);
  });

  relations = relations.set(parentRelation.get('id'), parentRelation);

  // remove from whole collection if possible
  if (!isRelationUsedSeveralTimes(relationIdToDelete, allLayouts, relations)) {
    relations = relations.delete(relationIdToDelete);
  }

  if (parentRelation.get('relationIds').size === 0) {
    // delete a whole relation since we've removed last child from it
    relations = deleteRelationRecursively(parentRelation.get('id'), relations, allLayouts, layer);
  } else if (parentRelation.get('relationIds').size === 1) {
    const lastRelationId = parentRelation.get('relationIds').first<string>();
    const lastRelation = relations.get(lastRelationId);

    if (isRegularRelation(lastRelation)) {
      // unwrap parent relation, no sense to keep relation with one child only
      relations = deleteRelationRecursively(parentRelation.get('id'), relations, allLayouts, layer, [lastRelationId]);
    } else if (isRowRelation(lastRelation)) {
      // unwrap row, single row relation shouldn't be wrapped to column relation
      relations = deleteRelationRecursively(parentRelation.get('id'), relations, allLayouts, layer, [lastRelationId]);
      const { parent: newParent } = getParent(lastRelationId, relations);

      // we do not remove parent relation if it is a root column,
      // so no need to unwrap last child relation in that case
      if (!relations.has(parentRelation.get('id')) && isRowRelation(newParent)) {
        // unwrap last child relation to avoid nesting row into the same type of relation
        const lastRelationChildRelations = lastRelation.get('relationIds').toArray();
        const lastRelationColumnsWidth = lastRelation.getIn(['styles', 'columnsWidth']).toArray();
        relations = deleteRelationRecursively(
          lastRelationId,
          relations,
          allLayouts,
          layer,
          lastRelationChildRelations,
          lastRelationColumnsWidth,
        );
      }
    } else if (isColumnRelation(lastRelation)) {
      // unwrap column, single column relation shouldn't be wrapped to row relation
      relations = deleteRelationRecursively(parentRelation.get('id'), relations, allLayouts, layer, [lastRelationId]);
      const { parent: newParent } = getParent(lastRelationId, relations);

      if (isColumnRelation(newParent)) {
        // unwrap last child relation to avoid nesting column into the same type of relation
        const lastRelationChildRelations = (lastRelation as Models.ColumnRelationMap).get('relationIds').toArray();
        const lastRelationRowsHeight = (lastRelation as Models.ColumnRelationMap).getIn(['styles', 'rowsHeight']).toArray();
        relations = deleteRelationRecursively(
          lastRelationId,
          relations,
          allLayouts,
          layer,
          lastRelationChildRelations,
          lastRelationRowsHeight,
        );
      }
    }
  }

  return relations;
}
