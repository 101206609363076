import React from 'react';
/* eslint-disable max-len */

const AlignmentCenterCenter = (): JSX.Element => (
  <svg viewBox="0 0 22 18">
    <path d="M15,6 L7,6 L7,12 L15,12 L15,6 Z M22,16 L22,1.98 C22,0.88 21.1,0 20,0 L2,0 C0.9,0 0,0.88 0,1.98 L0,16 C0,17.1 0.9,18 2,18 L20,18 C21.1,18 22,17.1 22,16 Z M20,16.02 L2,16.02 L2,1.97 L20,1.97 L20,16.02 Z" />
  </svg>
);

export default AlignmentCenterCenter;
