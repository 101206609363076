import { BrandStyleIdForAbbreviationsList } from 'modules/Abbreviations/constants';
import { createBrandedStylesForAbbreviationsList } from 'modules/Abbreviations/processes';
import { createBrandedStylesForReferenceElement } from 'modules/References/processes';
import { createSelector } from 'reselect';
import * as Constants from 'const';
import * as Models from 'models';
import { State } from './models';

export const brandDefinition = (state): State.IState => state.get('brandDefinition');
export const brandDefinitionChecksum = state => brandDefinition(state).get('brandDefinitionChecksum');
export const brandStylesChecksum = state => brandDefinition(state).get('brandStylesChecksum');

// COLORS
const colorsCategories = state => brandDefinition(state).get('colorsCategories');

export const availableForUserColorsCategories = createSelector(
  colorsCategories,
  colorsCategories => colorsCategories.filter(category => category.get('visible')),
);

export const projectFlatColors = state => brandDefinition(state).get('colors');
// COLORS

// FONTS
const fontsCategories = state => brandDefinition(state).get('fontsCategories');

export const availableForUserFontsCategories = createSelector(
  fontsCategories,
  fontsCategories => fontsCategories.filter(category => category.get('visible')),
);

export const projectFlatFonts = state => brandDefinition(state).get('fonts');
// FONTS

// BRAND STYLES
export const projectBrandStyles = state => brandDefinition(state).get('brandStyles');

type SelectBrandedStylesReturnType = {
  relationStyles?: Partial<Models.CombinedRelationStyles>;
  layoutStyles?: Partial<Models.LayoutStyles>;
};

export const selectBrandedStyles = createSelector(
  [
    projectBrandStyles,
    projectFlatColors,
    projectFlatFonts,
    (_: unknown, type: Constants.LayoutType): Constants.LayoutType => type,
  ],
  (brandStyles, colors, fonts, type): SelectBrandedStylesReturnType => {
    if (type === Constants.LayoutType.REFERENCE_CITATION_ELEMENT) {
      const brandStyle = brandStyles.get(Constants.Styles.DefaultReferenceCitationElementBrandStyleId);
      if (!brandStyle) {
        return {};
      }

      return createBrandedStylesForReferenceElement(brandStyle, colors, fonts);
    }

    if (type === Constants.LayoutType.ABBREVIATIONS_LIST) {
      const brandStyle = brandStyles.get(BrandStyleIdForAbbreviationsList);
      if (!brandStyle) {
        return {};
      }

      return createBrandedStylesForAbbreviationsList(brandStyle, colors, fonts);
    }

    return {};
  },
);

// BRAND STYLES

export const projectFontFaces = state => brandDefinition(state).get('UIFontFaces');

export const thumbnailsColor = createSelector(
  [colorsCategories, projectFlatColors],
  (colorsCategories, colors) => {
    const colorsCategoriesExceptGenerated = colorsCategories.filter(category => category.get('name') !== Constants.AUTOGEN_COLORS_CATEGORY);
    const firstBrandColorName = colorsCategoriesExceptGenerated.getIn([0, 'brandColors', 0]);
    const firstBrandColor = firstBrandColorName && colors.find(color => color.get('name') === firstBrandColorName);

    return firstBrandColor
      ? firstBrandColor.get('HEX', Constants.DefaultThumbnailBorderColor)
      : Constants.DefaultThumbnailBorderColor;
  },
);
