import React from 'react';
/* eslint-disable max-len */
// icon from Merck Design System 6.1

const ContentCopyTwo = (): JSX.Element => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.1" d="M6.26392 17.2776H9.59204C10.11 17.2776 10.5295 17.6971 10.5295 18.2151V21.5432H16.3889V6.91821H6.26392V17.2776Z"/>
    <path d="M20.3264 2.23071H7.76392C7.66079 2.23071 7.57642 2.31509 7.57642 2.41821V3.73071C7.57642 3.83384 7.66079 3.91821 7.76392 3.91821H19.3889V20.0432C19.3889 20.1463 19.4733 20.2307 19.5764 20.2307H20.8889C20.992 20.2307 21.0764 20.1463 21.0764 20.0432V2.98071C21.0764 2.56587 20.7413 2.23071 20.3264 2.23071Z"/>
    <path d="M17.3264 5.23071H5.32642C4.91157 5.23071 4.57642 5.56587 4.57642 5.98071V18.419C4.57642 18.6182 4.6561 18.8081 4.79673 18.9487L8.85845 23.0104C8.91001 23.062 8.9686 23.1041 9.03189 23.1393V23.1838H9.13032C9.21235 23.2143 9.29907 23.2307 9.38813 23.2307H17.3264C17.7413 23.2307 18.0764 22.8956 18.0764 22.4807V5.98071C18.0764 5.56587 17.7413 5.23071 17.3264 5.23071ZM9.02954 20.7979L7.01157 18.7776H9.02954V20.7979ZM16.3889 21.5432H10.5295V18.2151C10.5295 17.6971 10.11 17.2776 9.59204 17.2776H6.26392V6.91821H16.3889V21.5432Z"/>
  </svg>
);

export default ContentCopyTwo;
