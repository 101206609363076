export enum ProjectType {
  BRAND_ALERT_MEDSCAPE = 'Medscape',
  CARD = 'Card',
  DISPLAY_PANEL = 'DisplayPanel',
  EMAIL = 'email',
  IPAD = 'iPad',
  MOBILE_ALERT = 'MobileAlert',
  MOBILE_ALERT_DOXIMITY = 'Doximity',
  MOBILE_ALERT_EPOCRATES = 'Epocrates',
  UNIVERSAL_ARTBOARD = 'UniversalArtboard',
}
