import _ from 'lodash';
import { call } from 'redux-saga/effects';

import { DocumentsSource, ProcessType } from 'const';
import { CombinedDocuments, Document } from 'models';
import { linkDocumentsToProject } from 'services/api';
import { isCombinedLayoutDocument } from 'utils/entityType';
import { UploadProjectFlowArg } from '../models';
import { getDocumentsOnArtboards } from './getDocumentsOnArtboards';

export function* linkDocumentsOnArtboards(arg: UploadProjectFlowArg) {
  const { rootDocumentId, projectAssets } = arg;
  const documentsToSync = (yield call(getDocumentsOnArtboards, ProcessType.REFRESH)).toJS() as CombinedDocuments;

  const { data: { successfullyLinkedDocumentIds, notUpdatedLayoutIds } }: ReturnTypeSaga<typeof linkDocumentsToProject> = yield call(
    linkDocumentsToProject,
    rootDocumentId,
    documentsToSync,
  );

  _.forEach(projectAssets.documents, (document) => {
    if (isCombinedLayoutDocument(document)) {
      document.needToUpdatePreview = false;

      if (!notUpdatedLayoutIds.includes(document.documentId)) {
        document.isEdited = false;
      }
    }
  });

  arg.notUpdatedLayoutIds = notUpdatedLayoutIds;

  if (successfullyLinkedDocumentIds.length === 0) {
    return;
  }

  _.forEach(documentsToSync, (document) => {
    const { id, documentId, documentSource } = document as Document;

    if (!successfullyLinkedDocumentIds.includes(documentId)) {
      return;
    }

    const newDocument = projectAssets.documents[id];
    if (newDocument) {
      const newDocumentSource = _(documentSource).union([DocumentsSource.COMPONENTS]).compact().value();
      (newDocument as Document).documentSource = newDocumentSource;
    }
  });
}
