import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const TextCenter = (): JSX.Element => {
  const pathId = guid();
  const maskId = guid();

  return (
    <svg viewBox="5 5 20 20">
      <defs>
        <path d="M4,18 L7,18 L7,22 L9,22 L9,18 L12,18 L8,14 L4,18 Z M12,4 L9,4 L9,0 L7,0 L7,4 L4,4 L8,8 L12,4 Z M0,10 L0,12 L16,12 L16,10 L0,10 Z" id={pathId} />
      </defs>
      <g id="Icons/StyleBar/Alignment/middle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Color" transform="translate(7.000000, 4.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`#${pathId}`} />
          </mask>
          <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`} />
          <g id="Colors/White" mask={`url(#${maskId})`} fill="#FFFFFF">
            <g transform="translate(-5.000000, -2.000000)" id="White">
              <rect x="0" y="0" width="26" height="25" />
            </g>
          </g>
        </g>
      </g>
    </svg >
  );
};

export default TextCenter;
