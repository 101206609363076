import _ from 'lodash';
import guid from 'uuid';
import * as Constants from 'const';
import { createLayoutCommonStyles } from 'factories/style';
import * as Models from 'models';
import { AbbreviationsListLayoutDefaultStyles, AbbreviationsListRelationDefaultStyles } from './constants';
import { AbbreviationsListRelation, AbbreviationsListRelationStyles } from './types';

export const createAbbreviationsListRelationStyles = (
  styles: Partial<AbbreviationsListRelationStyles>,
): AbbreviationsListRelationStyles => {
  // brandStyleId should be added externally not from default values
  return _.defaultsDeep(
    {},
    styles,
    AbbreviationsListRelationDefaultStyles,
  );
};

export const createAbbreviationsListRelation = (
  data: Partial<AbbreviationsListRelation>,
): AbbreviationsListRelation => {
  const { id = guid(), styles } = data;

  return {
    id,
    documentId: null,
    entityType: Constants.EntityType.ABBREVIATIONS_LIST,
    styles: createAbbreviationsListRelationStyles(styles || {}),
  };
};

export function createAbbreviationsListLayoutStyles(
  styles = {} as Partial<Models.LayoutStyles>,
): Models.LayoutStyles {
  return createLayoutCommonStyles(
    _.defaultsDeep({}, styles, AbbreviationsListLayoutDefaultStyles),
  );
}
