import _ from 'lodash';

import { DocumentsSource, Layer } from 'const';
import { createDocuments } from 'factories/document/documentFactory';
import { createLayeredLayout } from 'factories/layoutFactory';
import { createLayeredRelations as createRelations } from 'factories/relationFactory';
import * as Models from 'models';
import { pickKeysMatchUUID } from 'services/processes';
import { isImage } from 'utils/entityType';
import { createLayers, moveToLayer } from 'utils/layers';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { getRemoveNonexistentBackgroundImagesCallback, removeBackgroundImageFromStylesByCondition } from 'utils/validator';
import { applyStyles } from './applyStyles';
import { checkForDuplicates } from './checkForDuplicates';

interface AddReusableLayout {
  layout: Models.LayeredLayout;
  newImages: Models.Images;
  relations: Models.LayeredRelations;
  documents: Models.CombinedDocuments;
}

function prepareLayoutToDrop(layoutDocument: Models.ReusableLayout): void {
  if (!layoutDocument) {
    return;
  }

  // HACK: in order to get rid of corrupted documents
  layoutDocument.documents = pickKeysMatchUUID(layoutDocument.documents);
}

export function addReusableLayout(
  layoutDocument: Models.ReusableLayout,
  sectionId: string,
  projectDocuments: Models.CombinedDocuments,
  layouts: Models.LayeredCombinedLayouts,
  projectBrandDefinition: Models.OnlineBrandDefinition,
  activeLayer: Layer,
  layoutId?: string,
  adaptStyles?: boolean,
): AddReusableLayout {
  const result = {
    documents: {},
    relations: {},
  } as AddReusableLayout;
  const existingLayout = layoutDocument.id && _.find(
    layouts,
    ({ documentId }) => documentId[activeLayer] && documentId[activeLayer] === layoutDocument.id,
  );

  if (existingLayout) {
    result.layout = createLayeredLayout({
      ...existingLayout as Models.LayeredLayout,
      section: sectionId,
      id: layoutId,
    });

    return result;
  }

  // TODO: should be on BE
  prepareLayoutToDrop(layoutDocument);

  const processedData = _.flow(
    checkForDuplicates,
    data => applyStyles(data, adaptStyles),
  )({
    activeLayer,
    layoutDocument,
    projectBrandDefinition,
    projectDocuments,
    documentsToSet: null,
  });
  const { layoutDocument: processedLayoutDocument, documentsToSet } = processedData;
  const {
    entities: {
      relations,
      styles,
      relationId,
    },
  } = processedLayoutDocument;

  result.documents = documentsToSet;
  processedLayoutDocument.documentSource = _.union(processedLayoutDocument.documentSource, [DocumentsSource.COMPONENTS]);
  result.documents[processedLayoutDocument.id] = layoutDocument;
  result.relations = _.mapValues(relations, rel => isRegularRelation(rel)
    ? {
      ...rel,
      styles: moveToLayer(rel.styles, activeLayer),
      documentId: moveToLayer(rel.documentId, activeLayer),
    }
    : rel,
  );
  result.layout = createLayeredLayout({
    documentId: createLayers<string>({ [activeLayer]: processedLayoutDocument.id }),
    section: sectionId,
    id: layoutId,
    relationId,
    styles,
  });

  // validate layout background image
  result.layout = removeBackgroundImageFromStylesByCondition(getRemoveNonexistentBackgroundImagesCallback(projectDocuments))(result.layout);

  // TODO: move it outside of this function
  result.newImages = _.pickBy<Models.CombinedDocument, Models.Image>(result.documents, isImage);

  // TODO: should be on project opening stage
  result.relations = createRelations(result.relations);
  result.documents = createDocuments(result.documents);

  return result;
}
