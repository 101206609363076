import _ from 'lodash';

import * as Models from 'models';
import { isCallToAction } from 'utils/entityType';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

/**
 * Deletes internal unused entities because they exist infinitely and can't be ever used
 * NOTE: artboards, layouts and relations can be undefined if it's first opening of the project (without project.json)
 */
export function removeUnusedEntities<T extends Models.GetProjectDataProcessArgs>(args: T): T {
  const { assets } = args;
  const { surfaces: screens, artboards, sections, layouts, relations, documents } = assets;

  // NOTE: Don't check for empty sections here, because there are old projects without sections at all.
  if (!artboards || !layouts || !relations) {
    // delete all internal documents (for now it's Call To Action only) because we won't use it anymore
    const unusedDocumentIds = _(documents)
      .pickBy(isCallToAction)
      .keys()
      .value();

    return _.set(args, 'assets.documents', _.omit(documents, unusedDocumentIds));
  }

  const usedArtboardIds = _.map(screens, ({ artboardId }) => artboardId);
  const usedLayoutIds = _(usedArtboardIds).map(artboardId => _.keys(getFlattenedLayouts(artboards[artboardId], layouts))).flatten().value();
  const usedSectionIds = _(usedLayoutIds).map(layoutId => layouts[layoutId].section).uniq().value();
  const usedRelationIds = _(usedLayoutIds)
    .map(layoutId => layouts[layoutId])
    .filter<Models.LayeredLayout>(isPlainLayout)
    .flatMapDeep<string>(layout => _.keys(getFlattenedRelations(layout, relations)))
    .value();

  // delete documents only if they are internal documents (for now it's Call To Action only)
  const allDocumentIds = _.keys(documents);
  const usedDocumentIds = _(usedRelationIds)
    .map(relationId => relations[relationId])
    .filter<Models.LayeredRegularRelation>(isRegularRelation)
    .flatMap(relation => _.values(relation.documentId))
    .compact()
    .uniq()
    .value();
  const unusedDocumentIds = _.difference(allDocumentIds, usedDocumentIds);
  unusedDocumentIds.forEach((documentId) => {
    if (!isCallToAction(documents[documentId])) {
      usedDocumentIds.push(documentId);
    }
  });

  assets.documents = _.pick(documents, usedDocumentIds);
  assets.artboards = _.pick(artboards, usedArtboardIds);
  assets.layouts = _.pick(layouts, usedLayoutIds);
  assets.relations = _.pick(relations, usedRelationIds);
  assets.sections = _.pick(sections, usedSectionIds);

  return args;
}
