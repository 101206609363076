import { List } from 'immutable';
import * as Constants from 'const';
import * as Models from 'models';
import { getCollectedAbbreviationIdsByRelations } from './getCollectedAbbreviationIdsByRelations';

function getAbbreviationJSONList(
  abbreviationsIds: string[],
  abbreviations: DeepIMap<Models.TextAbbreviationDocuments>,
): Models.TextAbbreviationJSON[] {
  return abbreviationsIds
    .filter(id => abbreviations.getIn([id, 'abbreviation']))
    .map(id => abbreviations.getIn([id, 'abbreviation']).toJS() as Models.TextAbbreviationJSON)
    .sort((a, b) => a.term.localeCompare(b.term, undefined, { sensitivity: 'base' }));
}

export type Context = {
  activeLayer?: Constants.Layer;
  relations: List<Models.LayeredRelationMap>;
  documents: Models.AppState.DocumentsMap;
  abbreviations: DeepIMap<Models.TextAbbreviationDocuments>;
};

export function abbreviationsListsFromContext(context: Context): Record<string, Models.TextAbbreviationJSON[]> {
  const { relations, documents, activeLayer, abbreviations } = context;
  const abbreviationIdsMapping = getCollectedAbbreviationIdsByRelations(relations, documents, activeLayer);

  return Object.fromEntries(
    Object.entries(abbreviationIdsMapping).map(
      ([id, list]) => [id, getAbbreviationJSONList(list, abbreviations)],
    ),
  );
}
