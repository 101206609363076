import { Layer } from 'const';
import * as Models from 'models';
import { getValue, getValueIn } from 'utils/getter';
import { isLayoutLayered } from 'utils/layouts/isLayoutLayered';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { isMockReusableLayout } from './isMockReusableLayout';

export function isReusableLayout(
  layout: Models.CombinedLayoutMap | Models.CombinedLayout,
  documents: Models.CombinedDocuments | Models.CombinedDocumentsMap,
): boolean;
export function isReusableLayout(
  layout: Models.LayeredCombinedLayoutMap | Models.LayeredCombinedLayout,
  documents: Models.CombinedDocuments | Models.CombinedDocumentsMap,
  activeLayer: Layer,
): boolean;
export function isReusableLayout(
  layout: Models.CombinedLayoutMap | Models.CombinedLayout | Models.LayeredCombinedLayoutMap | Models.LayeredCombinedLayout,
  documents: Models.CombinedDocuments | Models.CombinedDocumentsMap,
  activeLayer?: Layer,
): boolean {
  const documentId = (isLayoutLayered(layout as Models.LayeredCombinedLayout) && activeLayer)
    ? getValueIn(layout as Models.LayeredCombinedLayout, ['documentId', activeLayer])
    : getValue(layout as Models.CombinedLayout, 'documentId');

  if (!documentId || !isPlainLayout(layout) || isMockReusableLayout(layout, documents, activeLayer)) {
    return false;
  }

  return !!getValue(documents as Models.ReusableLayouts, documentId);
}
