import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import { ControlOptions } from 'components/Toolbar/models';
import { AssetAlignmentType } from 'const';

export const HorizontalAlignmentControlMap: PartialRecord<AssetAlignmentType, ControlOptions> = {
  [AssetAlignmentType.LEFT]: {
    icon: IconType.HORIZONTAL_ALIGNMENT_LEFT,
    title: Title.ASSET_ALIGNMENT_HORIZONTAL_LEFT,
  },
  [AssetAlignmentType.HORIZONTAL_CENTER]: {
    icon: IconType.HORIZONTAL_ALIGNMENT_CENTER,
    title: Title.ASSET_ALIGNMENT_HORIZONTAL_CENTER,
  },
  [AssetAlignmentType.RIGHT]: {
    icon: IconType.HORIZONTAL_ALIGNMENT_RIGHT,
    title: Title.ASSET_ALIGNMENT_HORIZONTAL_RIGHT,
  },
};

export const VerticalAlignmentControlMap: PartialRecord<AssetAlignmentType, ControlOptions> = {
  [AssetAlignmentType.TOP]: {
    icon: IconType.VERTICAL_ALIGNMENT_TOP,
    title: Title.ASSET_ALIGNMENT_VERTICAL_TOP,
  },
  [AssetAlignmentType.VERTICAL_CENTER]: {
    icon: IconType.VERTICAL_ALIGNMENT_CENTER,
    title: Title.ASSET_ALIGNMENT_VERTICAL_CENTER,
  },
  [AssetAlignmentType.BOTTOM]: {
    icon: IconType.VERTICAL_ALIGNMENT_BOTTOM,
    title: Title.ASSET_ALIGNMENT_VERTICAL_BOTTOM,
  },
};
