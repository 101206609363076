import _ from 'lodash';

import * as Models from 'models';
import { isCombinedLayoutDocument, isGroupLayoutDocument } from 'utils/entityType';
import { toImmutable } from 'utils/immutable';
import { ensureLayoutOnSections } from 'utils/layouts/ensureLayoutOnSections';
import { addGroupLayoutToArtboard } from 'utils/reusableLayouts/addGroupLayoutToArtboard';
import { addReusableLayoutToArtboard } from 'utils/reusableLayouts/addReusableLayoutToArtboard';
import { deleteLayoutsFromArtboardsByDocumentIds } from 'utils/reusableLayouts/deleteLayoutsFromArtboards';
import { whereUsed } from 'utils/reusableLayouts/whereUsed';
import { recalculateRowsAndNeighborsHeight } from 'utils/rowsHeight';

type Args = Models.RefreshProcessArgs
| Models.GetProjectDataProcessArgs
| Models.SyncProcessArgs
| Models.ImportTranslationPackageArgs
| Models.RestoreLayoutAssetsArgs
| Models.SetActiveLayerArgs;
type CombinedArgs = Models.RefreshProcessArgs
& Models.GetProjectDataProcessArgs
& Models.SyncProcessArgs
& Models.ImportTranslationPackageArgs
& Models.RestoreLayoutAssetsArgs
& Models.SetActiveLayerArgs;

export const refreshLayoutsOnArtboards = <T extends Args>(
  arg: T,
  adoptStyles?: boolean,
): void => {
  const {
    assets,
    prevAssets = {} as Models.PrevProjectAssets,
    layerToCleanUp,
  } = arg as CombinedArgs;
  const {
    documents: prevDocuments,
  } = prevAssets;
  const {
    documents,
    layouts,
    artboards,
    relations,
    sections,
    surfaces: screens,
    project: {
      activeLayer,
    },
    masterScreenData: {
      brandDefinition: projectBrandDefinition,
      screenDefinitions,
      projectType,
    },
  } = assets;
  // memorize translated documents for layouts as they will be replaced
  const translatedDocumentIdsByOriginal = getTranslatedDocumentIds(layouts);

  const layoutsToUpdate = (arg as Models.RestoreLayoutAssetsArgs).layoutsToRefresh || layouts;

  const layoutDocumentIds = _(prevDocuments || documents).pickBy(isCombinedLayoutDocument).keys().value();

  // save where layouts were used, to put there the updated ones later
  const layoutReferencesByLayoutDocumentId = whereUsed(layoutDocumentIds, layoutsToUpdate, artboards, activeLayer);

  const layoutIdsByArtboardId = _.mapValues(artboards, ({ layoutIds }) => [...layoutIds]);

  // delete outdated layouts from artboards
  deleteLayoutsFromArtboardsByDocumentIds({
    layoutDocumentIds,
    layouts,
    artboards,
    relations,
    documents,
    activeLayer: layerToCleanUp || activeLayer,
    layoutsToUpdate,
  });

  // put updated layouts on their places
  _.forEach(layoutReferencesByLayoutDocumentId, (layoutReferences, layoutDocumentId) => {
    const layoutDocument = documents[layoutDocumentId] as Models.ReusableLayout | Models.GroupLayoutDocument;

    if (!layoutDocument || !layoutDocument.entities) {
      // eslint-disable-next-line no-console
      console.error(`layout document ${layoutDocumentId} is empty or with corrupted data`);

      return;
    }

    layoutReferences.forEach(({ artboardId, sectionId, position }) => {
      const artboard = artboards[artboardId];
      const layoutId = layoutIdsByArtboardId[artboardId][position];

      const result = isGroupLayoutDocument(layoutDocument)
        ? addGroupLayoutToArtboard({
          groupLayoutDocument: layoutDocument,
          sectionId,
          position,
          artboard,
          projectDocuments: documents,
          layouts,
          projectBrandDefinition,
          activeLayer,
          storyCardId: null,
          groupLayoutId: layoutId,
          adoptStyles,
        })
        : addReusableLayoutToArtboard(
          layoutDocument,
          sectionId,
          position,
          artboard,
          documents,
          layouts,
          projectBrandDefinition,
          activeLayer,
          layoutId,
          adoptStyles
          // adapt styles force if RL was updated in Promomats
          || _.get(documents, [layoutDocumentId, 'isUpdatedInPromoMats']),
        );

      _.assign(layouts, result.layouts);
      _.assign(relations, result.relations);
      _.assign(documents, result.documents);
      _.assign(artboards, { [result.artboard.id]: result.artboard });
    });
  });

  // since all not edited layouts are removed from the artboards, we loose a correct order of them
  // previous layout ids is used to restore order
  _.forEach(layoutIdsByArtboardId, (prevLayoutIds, artboardId) => {
    const { layoutIds } = artboards[artboardId];

    artboards[artboardId].layoutIds = _.intersection(prevLayoutIds, layoutIds);
  });

  // restore translated document ids for layouts
  _.forEach(layouts, (layout) => {
    if (_.every(layout.documentId, _.isNil)) {
      return;
    }

    const preparedLayoutDocumentId = _.omitBy(layout.documentId, _.isEmpty);
    const translatedDocumentId = _.find<Models.Layers<string>>(translatedDocumentIdsByOriginal, preparedLayoutDocumentId);

    translatedDocumentId && (layout.documentId = translatedDocumentId);
  });

  // trigger force height recalculation for first relation to trigger full rerender
  // and update of heights
  recalculateRowsAndNeighborsHeight(
    Object.keys(relations)[0],
    toImmutable(relations),
    0,
  );

  // in case when layout has been unlinked or removed in PM and it has already been deleted from the artboard
  // we may have an empty section on the artboard that will cause errors, method below is called to avoid that.
  ensureLayoutOnSections({
    activeLayer,
    artboards,
    layouts,
    projectType,
    relations,
    screenDefinitions,
    screens,
    sections,
  });
};

function getTranslatedDocumentIds(layouts: Models.LayeredCombinedLayouts): Models.Layers<string>[] {
  return _.reduce(
    layouts,
    (translatedDocumentIds, { documentId: layeredDocumentId }) => {
      if (!_.some(layeredDocumentId, _.isNil)) {
        translatedDocumentIds.push(layeredDocumentId);
      }

      return translatedDocumentIds;
    },
    [] as Models.Layers<string>[],
  );
}
