import React from 'react';
/* eslint-disable max-len */

const PaddingInternal = (): JSX.Element => (
  <svg viewBox="0 0 25 30">
    <path d="M12.01,8 L10,5.5 L14,5.5 L12.01,8 Z M23.5,13 L23.5,17 L21,15.01 L23.5,13 Z M0.5,13 L3,15.01 L0.5,17 L0.5,13 Z M14,24.5 L10,24.5 L12.01,22 L14,24.5 Z M19.36,8 L4.64,8 C3.74,8 3,8.7 3,9.56 L3,20.44 C3,21.3 3.74,22 4.64,22 L19.36,22 C20.26,22 21,21.3 21,20.44 L21,9.56 C21,8.7 20.26,8 19.36,8 Z M19,20 L5,20 L5,10 L19,10 L19,20 L19,20 Z" />
  </svg>
);

export default PaddingInternal;
