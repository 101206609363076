import classNames from 'classnames';
import React from 'react';
import { ColorProps } from './models';
import styles from './styles.module.scss';

const Color: React.FunctionComponent<ColorProps> = (props) => {
  const { color, isActive, onToggle, toggleTooltip } = props;
  const className = classNames(
    'color',
    styles.Color,
    { [styles.active]: isActive },
  );
  const onClick = () => onToggle(color);
  const tooltipRef = React.useRef<HTMLDivElement>();
  // Here we show the percentage number of the lighter tint state of the primary color
  const text = color.get('tint') ? `${color.get('HEX')}, ${100 - color.get('tint')}%` : color.get('HEX');
  const toggleTooltipOn = () => toggleTooltip(true, text, tooltipRef);
  const toggleTooltipOff = () => toggleTooltip();

  return (
    <div
      className={className}
      onClick={onClick}
      style={{ backgroundColor: color.get('HEX') }}
      ref={tooltipRef}
      onMouseOver={toggleTooltipOn}
      onMouseLeave={toggleTooltipOff}
    />
  );
};

export default Color;
