import { List } from 'immutable';
import _ from 'lodash';
import * as Constants from 'const';
import * as Models from 'models';
import { getAbbreviationIdsFromRelationOrDocument } from './getAbbreviationIdsFromDocumentsAndRelations';
import { isAbbreviatioinsListRelation } from './isAbbreviatioinsListRelation';

export function getCollectedAbbreviationIdsByRelations(
  relations: List<Models.LayeredRelationMap>,
  documents: Models.AppState.DocumentsMap,
  activeLayer: Constants.Layer = Constants.Layer.ORIGINAL,
): Record<string, string[]> {
  const abbreviationIdsMapping: Record<string, string[]> = {};

  let abbreviationsIds: string[] = [];
  for (const relation of relations) {
    if (isAbbreviatioinsListRelation(relation)) {
      const relationId: string = relation.get('id');
      abbreviationIdsMapping[relationId] = _.uniq(abbreviationsIds);
      abbreviationsIds = [];
    } else {
      // remove duplicates according to DCC-8984
      const withoutDuplicatesAbbreviationDocumentIds =
        getAbbreviationIdsFromRelationOrDocument(relation, documents, activeLayer)
          .filter(
            documentId => !_.some(
              abbreviationIdsMapping,
              abbreviationIdsMap => abbreviationIdsMap.includes(documentId),
            ),
          );

      abbreviationsIds.push(...withoutDuplicatesAbbreviationDocumentIds);
    }
  }

  return abbreviationIdsMapping;
}
