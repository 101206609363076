import _ from 'lodash';

import * as Models from 'models';
import { sum } from 'utils/sum';
import { getMinCellWidth } from './getMinCellWidth';
import { isRegularRelation } from './isRegularRelation';
import { isRowRelation } from './isRowRelation';

export function getMinColumnWidth(
  relationId: string,
  relations: Models.LayeredRelationsMap | Models.RelationsMap,
): number {
  const relation = (relations as Models.LayeredRelationsMap).get(relationId);

  if (isRegularRelation(relation)) {
    return getMinCellWidth(relation);
  }

  if (isRowRelation(relation)) {
    return sum(relation.get('relationIds')
      .map(_relationId => getMinColumnWidth(_relationId, relations)));
  }

  return _.max(
    (relation)
      .get('relationIds')
      .map(_relationId => getMinColumnWidth(_relationId, relations),
      ).toArray());
}
