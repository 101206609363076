import Immutable from 'immutable';
import _ from 'lodash';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { getAbbreviationIdsFromRelationsAndDocuments } from 'modules/Abbreviations/utils/getAbbreviationIdsFromDocumentsAndRelations';
import { select } from 'redux-saga/effects';
import * as Constants from 'const';
import { EntityType, Layer, ProcessType } from 'const';
import { getImagesByScreenId, documents as documentsSelector, relationsOnScreens, documentsByEntityType } from 'containers/Documents/selectors';
import { layeredLayouts } from 'containers/Layouts/selectors';
import * as projectSelectors from 'containers/Project/selectors';
import * as relationsSelectors from 'containers/Relations/selectors';
import * as Models from 'models';
import { isMockDocument } from 'utils/documents';
import { isTextComponent } from 'utils/entityType';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

const EntityTypesByOperationType: PartialRecord<ProcessType, EntityType[]> = {
  [ProcessType.EXPORTING_FILES]: [EntityType.TEXT, EntityType.CALL_TO_ACTION, EntityType.IMAGE, EntityType.REFERENCE_CITATION],
  [ProcessType.IMPORT_TRANSLATION_PACKAGE]: [EntityType.IMAGE, EntityType.REFERENCE_CITATION],
  [ProcessType.REFRESH]: [EntityType.LAYOUT, EntityType.GROUP_LAYOUT, EntityType.IMAGE, EntityType.REFERENCE_CITATION],
};

export function* getDocumentsOnArtboards(
  processType?: ProcessType,
  entityTypesToInclude?: EntityType[],
): Generator<unknown, Models.CombinedDocumentsMap> {
  const imagesMap: ReturnTypeSaga<typeof getImagesByScreenId> = yield select(getImagesByScreenId);
  const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
  const relations: ReturnTypeSaga<typeof relationsSelectors.layeredRelations> = yield select(relationsSelectors.layeredRelations);
  const layouts: ReturnTypeSaga<typeof layeredLayouts> = yield select(layeredLayouts);
  const usedRelations: ReturnTypeSaga<typeof relationsOnScreens> = yield select(relationsOnScreens);

  const combinedImages: Models.BackgroundImageMap[] = [];
  const ids = new Set<string>();

  imagesMap.forEach(imageItem => combinedImages.push(...imageItem.values()));
  const imagesListUniqueById = Immutable.List(_.uniqBy(combinedImages, imageItem => imageItem.get('id')));

  imagesListUniqueById.forEach((imageItem) => {
    const imageId = imageItem.get('id');
    imageId && ids.add(imageId);
  });

  usedRelations.forEach((relation) => {
    const layeredRelation = relations.get(relation.get('id'));

    if (!isRegularRelation(layeredRelation)) {
      return;
    }

    const layeredDocumentId = layeredRelation.get('documentId');

    layeredDocumentId.forEach((documentId, layer) => {
      if (!documentId || isOnlyOriginalLayer(processType, layer)) {
        return;
      }

      ids.add(documentId);
    });
  });

  // collect internal layout documents
  layouts.forEach(layout => layout.get('documentId').forEach((documentId, layer) => {
    if (!documentId || !documents.get(documentId) || isOnlyOriginalLayer(processType, layer)) {
      return;
    }

    ids.add(documentId);
    collectReusableLayoutChildDocumentIds(ids, documents.get(documentId) as Models.ReusableLayoutMap);

    if (isGroupLayout(layout)) {
      const groupLayoutDocument = documents.get(documentId) as Models.GroupLayoutDocumentMap;
      const childLayoutDocumentIds = groupLayoutDocument.getIn(['entities', 'layouts']);
      const childLayoutDocuments = documents.filter(
        (document: Models.DocumentMap) => childLayoutDocumentIds && childLayoutDocumentIds.includes(document.get('documentId')),
      ) as Models.ReusableLayoutsMap;

      childLayoutDocuments.forEach(document => collectReusableLayoutChildDocumentIds(ids, document));
    }
  }));

  // collect reference citations
  documents
    .filter(document => isTextComponent(document) && ids.has(document.get('id')))
    .forEach((textComponent: Models.TextComponentMap) => {
      const referenceCitationIds = textComponent.get('referenceCitations');
      referenceCitationIds && referenceCitationIds.forEach(id => ids.add(id));
    });

  const entityTypes = processType && EntityTypesByOperationType[processType] || entityTypesToInclude;

  const activeLayer = (yield select(projectSelectors.activeLayer)) as Constants.Layer;
  const documentsByEntityTypes: ReturnTypeSaga<typeof documentsByEntityType> = yield select(documentsByEntityType);
  const textAbbreviations: ReturnTypeSaga<typeof selectAbbreviationsData> = yield select(selectAbbreviationsData);

  const abbreviationsIds = getAbbreviationIdsFromRelationsAndDocuments(
    usedRelations.toList(),
    documentsByEntityTypes,
    activeLayer,
  );
  const abbreviations = textAbbreviations.filter(doc => abbreviationsIds.includes(doc.get('id')));

  return documents
    .filterNot(isMockDocument)
    .filter(document => ids.has(document.get('id')))
    .filter(document => !entityTypes || entityTypes.includes(document.get('entityType')))
    .concat(abbreviations);
}

const collectReusableLayoutChildDocumentIds = (ids: Set<string>, document: Models.ReusableLayoutMap): void => {
  if (!document) {
    return;
  }

  ids.add(document.get('id'));

  document.get('documents')?.forEach(document => document && ids.add(document.get('id')));
};

const isOnlyOriginalLayer = (processType: ProcessType, layer: string): boolean => {
  return processType === ProcessType.EXPORTING_FILES && layer === Layer.TRANSLATED;
};
