import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { DraggableCore } from 'react-draggable';

import { AssetAlignmentType, MIN_IMAGE_WIDTH } from 'const';
import { getContainerSize, getExtraHeight, getExtraWidth } from 'utils/getContainerSize';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { toPx } from 'utils/toPx';
import * as Models from './models';
import styles from './styles.module.scss';
import * as utils from './utils';

const RESIZE_DELIMETER = 10;

export default class ResizeComponent extends React.PureComponent<Models.ResizeComponentProps, Models.ResizeComponentState> {
  static defaultProps: DefaultProps<Models.ResizeComponentProps> = {
    getStyle: null,
    isComponentWide: false,
    minHeight: 20,
    minWidth: 20,
    onResize: null,
  };

  readonly component = React.createRef<HTMLImageElement>();

  constructor(props: Models.ResizeComponentProps) {
    super(props);

    if (utils.areResponsiveProps(this.props)) {
      const { scale, isComponentWide } = this.props;

      this.state = {
        scale,
        isComponentWide,
      } as Models.ResizeComponentState;
    } else {
      const { container, height, width: initialWidth } = this.props;
      // recalculate width - should fit to the container
      const { width: maxWidth } = getContainerSize(container);
      const width = _.clamp(initialWidth, maxWidth);

      this.state = {
        height,
        width,
        ratio: width / height,
        isComponentWide: width > height,
        previousDelta: 0,
      } as Models.ResizeComponentState;
    }
  }

  componentWillReceiveProps(nextProps: Models.ResizeComponentProps) {
    if (utils.areResponsiveProps(nextProps)) {
      const { scale } = this.props as Models.ResizeComponentResponsiveProps;

      if (scale !== nextProps.scale) {
        this.setState({
          scale: nextProps.scale,
        });
      }
    } else {
      const { height, width } = this.props as Models.ResizeComponentNonResponsiveProps;

      if (height !== nextProps.height || width !== nextProps.width) {
        const { height, width } = nextProps;

        this.setState({
          height,
          width,
          ratio: width / height,
          isComponentWide: width > height,
        });
      }
    }
  }

  private onResizeTopLeft = (event: DragEvent): void => {
    const { alignment: { horizontal }, isResponsive } = this.props;
    const { boundingComponentRect: { top, left }, isComponentWide } = this.state;
    const { clientX, clientY } = event;

    const x = clientX - left;
    const y = clientY - top;
    const delta = utils.calcTopLeftDelta(x, y, horizontal, isComponentWide);

    isResponsive ? this.calculateNewScale(delta) : this.calculateNewSizes(delta, y, clientY, Models.ResizeType.TOP);
  };

  private onResizeTopRight = (event: DragEvent): void => {
    const { alignment: { horizontal }, isResponsive } = this.props;
    const { boundingComponentRect: { top, right }, isComponentWide } = this.state;
    const { clientX, clientY } = event;

    const x = clientX - right;
    const y = clientY - top;
    const delta = utils.calcTopRightDelta(x, y, horizontal, isComponentWide);

    isResponsive ? this.calculateNewScale(delta) : this.calculateNewSizes(delta, y, clientY, Models.ResizeType.TOP);
  };

  private onResizeBottomRight = (event: DragEvent): void => {
    const { alignment: { horizontal }, isResponsive } = this.props;
    const { boundingComponentRect: { right, bottom }, isComponentWide } = this.state;
    const { clientX, clientY } = event;

    const x = clientX - right;
    const y = clientY - bottom;
    const delta = utils.calcBottomRightDelta(x, y, horizontal, isComponentWide);

    isResponsive ? this.calculateNewScale(delta) : this.calculateNewSizes(delta, -y, clientY, Models.ResizeType.BOTTOM);
  };

  private onResizeBottomLeft = (event: DragEvent): void => {
    const { alignment: { horizontal }, isResponsive } = this.props;
    const { boundingComponentRect: { left, bottom }, isComponentWide } = this.state;
    const { clientX, clientY } = event;

    const x = clientX - left;
    const y = clientY - bottom;
    const delta = utils.calcBottomLeftDelta(x, y, horizontal, isComponentWide);

    isResponsive ? this.calculateNewScale(delta) : this.calculateNewSizes(delta, -y, clientY, Models.ResizeType.BOTTOM);
  };

  private onResizeTopCenter = (event: DragEvent): void => {
    const { boundingComponentRect: { top } } = this.state;
    const { clientY } = event;
    const delta = clientY - top;

    this.calculateNewHeight(delta, clientY, Models.ResizeType.TOP);
  };

  private onResizeRightCenter = (event: DragEvent): void => {
    const { boundingComponentRect: { right } } = this.state;
    const { clientX } = event;
    const delta = right - clientX;

    this.calculateNewWidth(delta);
  };

  private onResizeBottomCenter = (event: DragEvent): void => {
    const { boundingComponentRect: { bottom } } = this.state;
    const { clientY } = event;
    const delta = bottom - clientY;

    this.calculateNewHeight(delta, clientY, Models.ResizeType.BOTTOM);
  };

  private onResizeLeftCenter = (event: DragEvent): void => {
    const { boundingComponentRect: { left } } = this.state;
    const { clientX } = event;
    const delta = clientX - left;

    this.calculateNewWidth(delta);
  };

  private getStyles = (): React.CSSProperties => {
    const { isResponsive, getStyle } = this.props;

    if (getStyle) {
      return getStyle(this.state.scale);
    }

    if (isResponsive) {
      const { scale } = this.state;

      return {
        width: `${scale * 100}%`,
      };
    }

    const { height, width } = this.state;

    return {
      height: toPx(height),
      width: toPx(width),
    };
  };

  private getCorrectWidth(actualWidth: number): number {
    const { containerWidth, containerExtraWidth } = this.state;
    const { minWidth } = this.props;
    const maxWidth = containerWidth - containerExtraWidth;

    return _.clamp(actualWidth, minWidth, maxWidth);
  }

  private nullifyDeltaWhenResizeDirectionChanged = (delta: number, clientY: number, resizeDirection: Models.ResizeType) => {
    if (Math.abs(this.state.previousDelta) > Math.abs(delta)) {
      const { top, right, bottom, left } = this.state.boundingComponentRect;
      this.setState({
        boundingComponentRect: {
          top: resizeDirection === Models.ResizeType.TOP ? clientY : top,
          right,
          bottom: resizeDirection === Models.ResizeType.BOTTOM ? clientY : bottom,
          left,
        },
      });

      return 0;
    }

    return delta;
  };

  private calculateNewSizes = (actualDelta: number, resizeDelta: number, clientY: number, resizeDirection: Models.ResizeType): void => {
    const { alignment: { horizontal }, onResize } = this.props as Models.ResizeComponentNonResponsiveProps;
    const { ratio, prevWidth } = this.state;

    let delta = horizontal === AssetAlignmentType.HORIZONTAL_CENTER
      ? actualDelta * 2
      : actualDelta;

    const width = this.getCorrectWidth(prevWidth - delta);
    const height = width / ratio;

    if (onResize) {
      delta = this.nullifyDeltaWhenResizeDirectionChanged(resizeDelta, clientY, resizeDirection);
      const resizeHeight = (prevWidth - (delta / RESIZE_DELIMETER)) / ratio;
      // in case of resize callback, we are providing component with height from props
      onResize(resizeHeight);
      this.setState({
        width,
        previousDelta: delta,
      });
    } else {
      this.setState({
        height,
        width,
      });
    }
  };

  private calculateNewHeight = (actualDelta: number, clientY: number, resizeDirection: Models.ResizeType): void => {
    const { alignment: { vertical }, height, container, minHeight, onResize } = this.props as Models.ResizeComponentNonResponsiveProps;
    const {
      containerExtraHeight,
      height: currentHeight,
      fullHeight,
      isFullHeightChanged,
      tempHeight,
    } = this.state;

    let delta = actualDelta;

    if (vertical === AssetAlignmentType.VERTICAL_CENTER) {
      if (!fullHeight) {
        delta *= 2;
      }

      const fullHeightNow = _.ceil(currentHeight) + containerExtraHeight >= container.offsetHeight;
      const isFullHeightChangedNow = fullHeight !== fullHeightNow;

      if (isFullHeightChangedNow) {
        const { top, right, bottom, left } = this.component.current.getBoundingClientRect();
        let newHeight = isFullHeightChangedNow && isFullHeightChanged ? tempHeight - delta : height - delta;
        if (newHeight < minHeight) {
          newHeight = minHeight;
        }

        return this.setState({
          fullHeight: fullHeightNow,
          isFullHeightChanged: isFullHeightChangedNow,
          height: newHeight,
          tempHeight: newHeight,
          boundingComponentRect: {
            top,
            right,
            bottom,
            left,
          },
        });
      }
    }

    let newHeight = isFullHeightChanged ? tempHeight - delta : height - delta;
    if (newHeight < minHeight) {
      newHeight = minHeight;
    }

    if (onResize) {
      delta = this.nullifyDeltaWhenResizeDirectionChanged(delta, clientY, resizeDirection);
      const resizeHeight = isFullHeightChanged
        ? tempHeight - (delta / RESIZE_DELIMETER)
        : height - (delta / RESIZE_DELIMETER);
      this.setState({
        previousDelta: delta,
      });
      onResize(resizeHeight);
    } else {
      this.setState({
        height: newHeight,
      });
    }
  };

  private calculateNewWidth = (actualDelta: number): void => {
    const { alignment: { horizontal } } = this.props as Models.ResizeComponentNonResponsiveProps;
    const { prevWidth } = this.state;

    const delta = horizontal === AssetAlignmentType.HORIZONTAL_CENTER
      ? actualDelta * 2
      : actualDelta;

    const width = this.getCorrectWidth(prevWidth - delta);

    this.setState({
      width,
    });
  };

  private calculateNewScale = (actualDelta: number): void => {
    const { alignment: { horizontal }, onResize } = this.props;
    const { prevScale, containerWidth } = this.state;

    const delta = horizontal === AssetAlignmentType.HORIZONTAL_CENTER
      ? actualDelta * 2
      : actualDelta;

    const newCalculatedScale = _.round(prevScale - delta / containerWidth, 4);
    const minScale = _.ceil(MIN_IMAGE_WIDTH / containerWidth, 4);
    const newScale = _.clamp(newCalculatedScale, minScale, 1);

    if (onResize) {
      onResize(newScale);
    } else {
      this.setState({
        scale: newScale,
      });
    }
  };

  private stopResizing = (): void => {
    const { scale } = this.state;

    if (utils.areResponsiveProps(this.props)) {
      const { finishResizing } = this.props;

      this.setState({
        scale,
        prevScale: null,
      });

      finishResizing(scale);
    } else {
      const { finishResizing } = this.props;
      const { height, width } = this.state;

      finishResizing(width, height);
    }
  };

  private startResizing = (): void => {
    const { container } = this.props;

    // get container styles every time before resizing because they can change
    const computedStyles = getComputedStyle(container);

    if (utils.areResponsiveProps(this.props)) {
      const { scale: prevScale } = this.state;

      if (typeof this.props.startResizing === 'function') {
        this.props.startResizing(prevScale);
      }

      this.setState({
        prevScale,
      });
    } else {
      const { height: currentHeight, width: currentWidth } = this.state;
      const containerExtraHeight = getExtraHeight(computedStyles);
      const containerExtraWidth = getExtraWidth(computedStyles);
      const fullHeight = _.round(currentHeight) + containerExtraHeight >= container.offsetHeight;

      this.setState({
        containerExtraHeight,
        containerExtraWidth,
        fullHeight,
        isFullHeightChanged: false,
        tempHeight: null,
        prevWidth: currentWidth,
        previousDelta: 0,
      });
    }

    // get initial component offsets for calculating new component size within the container
    const { top, right, bottom, left } = this.component.current.getBoundingClientRect();
    const containerWidth = getIntegerFromStyle(computedStyles.width);

    this.setState({
      containerWidth,
      boundingComponentRect: {
        top,
        right,
        bottom,
        left,
      },
    });
  };

  render() {
    const { isResponsive } = this.props;

    const controls = [
      {
        onDrag: this.onResizeTopLeft,
        style: styles.resizeButton_topLeft,
      },
      {
        onDrag: this.onResizeTopRight,
        style: styles.resizeButton_topRight,
      },
      {
        onDrag: this.onResizeBottomRight,
        style: styles.resizeButton_bottomRight,
      },
      {
        onDrag: this.onResizeBottomLeft,
        style: styles.resizeButton_bottomLeft,
      },
    ];

    if (!isResponsive) {
      controls.push(
        {
          onDrag: this.onResizeTopCenter,
          style: styles.resizeButton_topCenter,
        },
        {
          onDrag: this.onResizeRightCenter,
          style: styles.resizeButton_rightCenter,
        },
        {
          onDrag: this.onResizeBottomCenter,
          style: styles.resizeButton_bottomCenter,
        },
        {
          onDrag: this.onResizeLeftCenter,
          style: styles.resizeButton_leftCenter,
        },
      );
    }

    return (
      <div className={styles.ResizeComponent} ref={this.component} style={this.getStyles()}>
        {this.props.children}
        {
          controls.map(({ onDrag, style }) => (
            <DraggableCore
              key={style}
              onStart={this.startResizing}
              onDrag={onDrag}
              onStop={this.stopResizing}
            >
              <div className={classNames(styles.resizeButton, style)} />
            </DraggableCore>
          ))
        }
      </div>
    );
  }
}
