import { call, select } from 'redux-saga/effects';

import * as ProjectSelectors from 'containers/Project/selectors';
import { getThumbnailsInfo } from 'containers/Project/services/getThumbnailsInfo';
import { getProjectPdf } from 'services/api';
import { ArtboardsJson } from 'services/ArtboardConverter/models';
import { getUsedFontFaces } from 'services/getUsedFontFaces';
import { getArtboardsJson } from './getArtboardsJson';

export function* generateProjectPdf(projectPdfName: string): Generator<unknown, string> {
  const artboardsJson: ArtboardsJson = yield call(getArtboardsJson);
  const projectType: ReturnTypeSaga<typeof ProjectSelectors.projectType> = yield select(ProjectSelectors.projectType);
  const helperFileName: ReturnTypeSaga<typeof ProjectSelectors.helperFileName> = yield select(ProjectSelectors.helperFileName);
  const thumbnailsInfo: ReturnTypeSaga<typeof getThumbnailsInfo> = yield call(getThumbnailsInfo);
  const usedFontFaces: ReturnTypeSaga<typeof getUsedFontFaces> = yield call(getUsedFontFaces);

  const { data: url }: ReturnTypeSaga<typeof getProjectPdf> = yield call(
    getProjectPdf,
    artboardsJson,
    projectPdfName,
    thumbnailsInfo,
    usedFontFaces,
    projectType,
    helperFileName,
  );

  return url;
}
