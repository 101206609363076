import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';

import { Character } from 'const';

interface LineFeedPosition {
  block: string;
  offset: number;
}

/**
 * Replace LF (line feed, '\n') characters in editor state with separate text blocks.
 * Required for text components from previous versions of Assembler because Quill js uses '\n' (e.g., when drop texts from Story Cards)
 */
export const replaceLineBreaks = (editorState: EditorState): EditorState => {
  const content = editorState.getCurrentContent();
  const lineFeedPositions = findLineFeedCharacters(content).reverse();
  const contentWithoutLineFeeds = lineFeedPositions.reduce(
    (contentState, position) => replaceLineFeed(contentState, position),
    content,
  );

  return EditorState.createWithContent(contentWithoutLineFeeds);
};

const findLineFeedCharacters = (content: ContentState): LineFeedPosition[] => {
  return content.getBlockMap().reduce(
    (lineFeedPositions, block) => findCharPositions(block.getText(), Character.LINE_FEED).reduce(
      (positions, position) => {
        positions.push({ block: block.getKey(), offset: position });

        return positions;
      },
      lineFeedPositions,
    ),
    [] as LineFeedPosition[],
  );
};

const findCharPositions = (text: string, charToFind: string): number[] => {
  return text.split('').reduce(
    (positions, char, idx) => {
      char === charToFind && positions.push(idx);

      return positions;
    },
    [] as number[],
  );
};

const replaceLineFeed = (content: ContentState, position: LineFeedPosition): ContentState => {
  const { block, offset } = position;
  // split blocks
  const selectionForSplit = SelectionState.createEmpty(block).merge({
    anchorOffset: offset,
    focusOffset: offset,
  }) as SelectionState;
  const contentAfterSplit = Modifier.splitBlock(content, selectionForSplit);
  // remove line feed character
  const blockKey = contentAfterSplit.getBlockAfter(block).getKey();
  const selectionToRemove = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: 0,
    focusOffset: 1,
  }) as SelectionState;

  return Modifier.removeRange(contentAfterSplit, selectionToRemove, 'forward');
};
