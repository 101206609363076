import { call, put, select } from 'redux-saga/effects';

import { setCellHeight } from 'containers/ArtboardPreview/actions';
import { Action } from 'containers/ArtboardPreview/models';
import { cellsHeight as cellsHeightSelector } from 'containers/ArtboardPreview/selectors';
import { activeArtboardId as activeArtboardIdSelector } from 'containers/Artboards/selectors';
import { handleSagaError } from 'services/handleError';

export function* updateCellsHeight(action: Action.IUpdateCellsHeight) {
  try {
    const { relationId, newHeight } = action.payload;
    const activeArtboardId: ReturnTypeSaga<typeof activeArtboardIdSelector> = yield select(activeArtboardIdSelector);
    const cellsHeight: ReturnTypeSaga<typeof cellsHeightSelector> = yield select(cellsHeightSelector);
    const key = `${activeArtboardId}:${relationId}`;

    if (newHeight === cellsHeight.get(key)) {
      return;
    }

    yield put(setCellHeight(key, newHeight));
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardPreview.updateCellsHeight', 'UpdateCellsHeight');
  }
}
