import { $isMarkNode, MarkNode } from '@lexical/mark';
import { $createRangeSelection, $getRoot, $getSelection, $isRangeSelection, $setSelection, RangeSelection } from 'lexical';

type GetAbbreviationNode = {
  abbreviationId?: string;
  abbreviationTerm?: string;
};

export const $getAbbreviationSelectionNode = (selection: RangeSelection): MarkNode | null | undefined => {
  const nodes = selection.getNodes();
  const abbreviationNode = nodes.length > 1
    ? nodes.find(node => node.getType() === MarkNode.getType())
    : nodes[0].getParent();

  return abbreviationNode && $isMarkNode(abbreviationNode)
    ? abbreviationNode
    : undefined;
};

export const $getAbbreviationData = (): GetAbbreviationNode => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return {};
  }

  const node = $getAbbreviationSelectionNode(selection);

  if (!node) {
    return {
      abbreviationId: undefined,
      abbreviationTerm: selection.getTextContent(),
    };
  }

  const [abbreviationId] = node.getIDs();

  return {
    abbreviationId,
    abbreviationTerm: node.getTextContent(),
  };
};

export const $selectAbbreviation = (abbreviationId: string, abbreviationNumber = 1): boolean => {
  const selection = $createRangeSelection();
  const nodes = $getRoot().getAllTextNodes();
  if (!nodes) {
    return false;
  }

  const abbreviations = nodes.filter((node) => {
    const parent = node.getParent();

    if (!parent) {
      return false;
    }

    return $isMarkNode(parent) && parent.getIDs()[0] === abbreviationId;
  });

  const target = abbreviations[abbreviationNumber - 1];

  if (!target) {
    return false;
  }

  selection.focus.set(target.getKey(), 0, 'text');
  selection.anchor.set(target.getKey(), target.getTextContentSize(), 'text');
  $setSelection(selection);

  return true;
};
