import React from 'react';
/* eslint-disable max-len */

const ResizeSpacerIcon = (): JSX.Element => (
  <svg>
    <g stroke="none" strokeWidth="1" fill="#FFFFFF" fillRule="evenodd" transform="rotate(-45.000000) translate(-15, 5)">
      <path d="M20.4035002,12.9529678 C18.7660533,14.6376096 14.0739277,19.4007378 13.0372186,20.4930297 L16,23.4666667 L8.53333333,23.4666667 L8.5605911,16 L11.586209,19.0367037 L18.9570978,11.5012658 L16,8.53333333 L23.4666667,8.53333333 L23.4394089,16 L20.4035002,12.9529678 Z" />
    </g>
  </svg>
);

export default ResizeSpacerIcon;
