import { Effect, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from 'containers/Documents/constants';
import { addImage } from './addImage';
import { addReferenceCitation } from './addReferenceCitation';
import { deleteComponent } from './deleteComponent';
import { downloadImages } from './downloadImages';
import { getAssetsCollectionData } from './getAssetsCollectionData';
import { getImagesAdditionalInfo } from './getImagesAdditionalInfo';
import { getLayoutsThumbnails } from './getLayoutsThumbnails';
import { getStoryCardImagesAdditionalInfo } from './getStoryCardImagesAdditionalInfo';
import { getStoryCardLayoutThumbnails } from './getStoryCardLayoutThumbnails';
import { getStoryCardsPreviews } from './getStoryCardsPreviews';
import { removeAnchor } from './removeAnchor';
import { updateImage } from './updateImage';
import { updateInternalImageInfoRequest } from './updateInternalImageInfoRequest';
import { updateReferenceRequest } from './updateReferenceRequest';

const effects: Effect[] = [
  takeLatest(ActionTypes.REMOVE_ANCHOR, removeAnchor),
  takeEvery(ActionTypes.DELETE_COMPONENT, deleteComponent),
  takeEvery(ActionTypes.GET_IMAGES_INFO, getImagesAdditionalInfo),
  takeEvery(ActionTypes.DOWNLOAD_IMAGES, downloadImages),
  takeEvery(ActionTypes.GET_LAYOUTS_THUMBNAILS, getLayoutsThumbnails),
  takeEvery(ActionTypes.GET_STORY_CARD_IMAGES_INFO, getStoryCardImagesAdditionalInfo),
  takeEvery(ActionTypes.GET_STORY_CARD_LAYOUT_THUMBNAILS, getStoryCardLayoutThumbnails),
  takeEvery(ActionTypes.GET_STORY_CARDS_PREVIEWS, getStoryCardsPreviews),
  takeEvery(ActionTypes.GET_ASSETS_COLLECTION_DATA, getAssetsCollectionData),
  takeEvery(ActionTypes.ADD_REFERENCE_CITATION, addReferenceCitation),
  takeEvery(ActionTypes.UPDATE_REFERENCE_REQUEST, updateReferenceRequest),
  takeEvery(ActionTypes.ADD_IMAGE, addImage),
  takeEvery(ActionTypes.UPDATE_IMAGE, updateImage),
  takeEvery(ActionTypes.UPDATE_INTERNAL_IMAGE_INFO_REQUEST, updateInternalImageInfoRequest),
];

export default effects;
