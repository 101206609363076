import _ from 'lodash';

import * as Models from 'models';

export function getScreensWithThumbnails(screens: Models.Screens): Models.Screens {
  return _.reduce(
    screens,
    (updatedScreens, screen: Models.Screen) => {
      const { id, name, thumbnail } = screen;
      _.defaults(thumbnail, { border: true, name });
      updatedScreens[id] = screen;

      return updatedScreens;
    },
    {} as Models.Screens,
  );
}
