import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DocumentDuplicates from 'components/ModalWindows/DocumentDuplicates';
import * as Models from 'components/ModalWindows/DocumentDuplicates/models';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: Models.IDocumentDuplicatesProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDuplicates);
