import React from 'react';

import ScreenSelect from 'components/ScreensPanelItem/components/Shared/ScreenSelect';
import { Option } from 'components/ScreensPanelItem/components/Shared/ScreenSelect/models';
import * as Constants from 'const';
import { getScreenFormatLabel } from 'utils/screens/getScreenFormatLabel';
import { isCustomScreenFormatType } from 'utils/screens/isCustomScreenFormat';
import { ScreenFormatTypeProps } from './models';

const ScreenFormatType: React.FunctionComponent<ScreenFormatTypeProps> = ({
  className,
  customHeight,
  customWidth,
  formatType,
  isTooltipDisabled = true,
  onFormateChange,
  showCustomFormatOnlyWhenItIsActive = false,
}) => {
  const options: Option[] = Constants.PredefinedScreenFormats.map(format => ({
    value: format.type,
    label: getScreenFormatLabel(format),
  }));
  const customFormatOption = {
    value: Constants.ScreenFormatType.CUSTOM,
    label: getScreenFormatLabel({ type: Constants.ScreenFormatType.CUSTOM, width: customWidth, height: customHeight }),
  };

  showCustomFormatOnlyWhenItIsActive
    ? isCustomScreenFormatType(formatType) && options.push(customFormatOption)
    : options.push(customFormatOption);

  const handleChange = (option: Option): void => {
    const selectedFormatType = option.value as Constants.ScreenFormatType;

    if (selectedFormatType === formatType) {
      return;
    }

    onFormateChange(selectedFormatType);
  };

  const activeOption = options.find(option => option.value === formatType);

  return (
    <ScreenSelect
      activeOption={activeOption}
      className={className}
      isTooltipDisabled={isTooltipDisabled}
      onChange={handleChange}
      options={options}
    />
  );
};

export default ScreenFormatType;
