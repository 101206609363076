import { BrandColorMap } from 'models';
import { colorToValue } from 'utils/converters';

export const getBackgroundColor = (
  color: BrandColorMap | undefined,
  defaultColor: string | null = null,
): Pick<React.CSSProperties, 'backgroundColor'> => {
  const colorToHex = colorToValue(color, defaultColor || '');

  return colorToHex ? { backgroundColor: `${colorToHex}` } : {};
};
