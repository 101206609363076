import React from 'react';

import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import { SCRIPT_CONTROLS } from 'const';
import { ControlMap } from './constants';
import { ScriptStyleProps } from './models';

const ScriptStyle: React.FunctionComponent<ScriptStyleProps> = (props) => {
  const { styles, toggleScriptStyle, controlState = ControlState } = props;

  return (
    <>
      {
        SCRIPT_CONTROLS.map(({ style }) => {
          const { icon, title } = ControlMap[style];

          return (
            <Control
              key={title}
              controlState={controlState}
              onToggle={toggleScriptStyle.bind(null, style)}
              state={styles && styles.has(style) ? controlState.ACTIVE : controlState.DEFAULT}
              title={title}
              type={icon}
            />
          );
        })
      }
    </>
  );
};

export default ScriptStyle;
