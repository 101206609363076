import React from 'react';

import AssetsCollectionScreen from 'components/AssetsCollectionScreen';
import * as Models from 'models';
import { AssetsCollectionScreensProps } from './models';
import styles from './styles.module.scss';

const AssetsCollectionScreens: React.FunctionComponent<AssetsCollectionScreensProps> = ({ document, showScreenDetails, toggleDragState }) => {
  const entities = (document as Models.StoryCardMap).get('entities');
  const screens = entities.get('screens');
  const screenIds = entities.get('screenIds');

  return (
    <ul className={styles.AssetsCollectionScreens}>
      {screenIds.map((screenId, index) => {
        const screen = screens.get(screenId);

        return (
          <li key={screenId} className={styles.item}>
            <AssetsCollectionScreen
              document={document}
              screen={screen}
              screenOrder={index + 1}
              showDetails={showScreenDetails}
              toggleDragState={toggleDragState}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default AssetsCollectionScreens;
