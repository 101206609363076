import _ from 'lodash';
import { createAbbreviationsListLayoutStyles } from 'modules/Abbreviations/factories';
import * as Constants from 'const';
import { DefaultReferenceCitationElementLayoutStyles } from 'const/Styles';
import * as Models from 'models';
import { splitIntoEqualParts } from 'utils/layouts/splitIntoEqualParts';
import { createAssetAlignment } from './alignment';
import { createBackgroundImage } from './backgroundImage';
import { createBorder } from './border';
import { createBoxProperty } from './boxProperty';
import { createFontDecoration } from './fontDecoration';
import { createTextAlignment } from './textAlignment';

export const createCommonStyles = (
  {
    backgroundColor = null,
    backgroundColorTint = null,
    backgroundColorOpacity = Constants.Styles.DefaultOpacity,
    backgroundGradient,
    backgroundImage,
    border = {},
    brandStyleChanged = false,
    isAutoFitContent = true,
    padding = {},
    borderRadius = { top: 0, right: 0, left: 0, bottom: 0 },
  } = {} as Partial<Models.CommonStyles>,
): Models.CommonStyles => ({
  backgroundColor,
  backgroundColorTint,
  backgroundColorOpacity,
  backgroundGradient,
  backgroundImage: createBackgroundImage(backgroundImage),
  border: createBorder(border),
  brandStyleChanged,
  isAutoFitContent,
  padding: createBoxProperty(padding),
  borderRadius: createBoxProperty(borderRadius),
});

export const createCallToActionStyles = (
  styles = {} as Partial<Models.CallToActionStyles>,
  size: Constants.ComponentSize = Constants.ComponentSize.SMALL,
): Models.CallToActionStyles => {
  const {
    alignment = {},
    assetBackgroundColor = null,
    assetBackgroundColorTint = null,
    assetBackgroundOpacity = Constants.Styles.DefaultOpacity,
    assetBackgroundGradient = null,
    assetPadding = {},
    assetBorderRadius = {
      top: Constants.Styles.DefaultCallToActionBorderRadius,
      right: Constants.Styles.DefaultCallToActionBorderRadius,
      left: Constants.Styles.DefaultCallToActionBorderRadius,
      bottom: Constants.Styles.DefaultCallToActionBorderRadius,
    },
    fitToCell = false,
    fontColor = null,
    fontColorTint = null,
    fontDecoration = {},
    fontFamily = Constants.DefaultCustomStyle.FONT_FAMILY,
    fontSize = Constants.DefaultCustomStyle.FONT_SIZE,
    fontStyle = null,
    textAlignment = {},
    height = Constants.CallToActionSizes[size].height,
    width = Constants.CallToActionSizes[size].width,
  } = styles;

  return {
    alignment: createAssetAlignment(alignment),
    assetBackgroundColor,
    assetBackgroundColorTint,
    assetBackgroundOpacity,
    assetBackgroundGradient,
    assetPadding: createBoxProperty(assetPadding),
    assetBorderRadius: createBoxProperty(assetBorderRadius),
    /* To adopt current internal structure of Box Properties to the CSS border-radius
      Next Mapping was used:
      TOP === TopLeft
      RIGHT === TopRight
      LEFT === BottomLeft
      BOTTOM === BottomRight */
    fitToCell,
    fontColor,
    fontColorTint,
    fontDecoration: createFontDecoration(fontDecoration),
    fontFamily,
    fontSize,
    fontStyle,
    height,
    width,
    textAlignment: createAssetAlignment(textAlignment),
    ...createCommonStyles(styles),
  };
};

export const createReferenceCitationElementStyles = (
  styles: Partial<Models.ReferenceCitationElementStyles>,
  useDefaultStyleId = true,
): Models.ReferenceCitationElementStyles => {
  const {
    brandStyleId = useDefaultStyleId ? Constants.Styles.DefaultReferenceCitationElementBrandStyleId : null,
    alignment,
    fontColor,
    fontSize,
    fontFamily,
    fontStyle,
    lineHeight,
  } = styles || {} as Models.ReferenceCitationElementStyles;

  return {
    brandStyleId,
    alignment,
    fontColor,
    fontFamily,
    fontSize,
    fontStyle,
    lineHeight,
    ...createCommonStyles({ ...styles, padding: Constants.Styles.DefaultReferenceCitationElementPadding }),
  };
};

export const createSpacerStyles = (
  styles = {} as Partial<Models.SpacerRelationStyles>,
  size: Constants.ComponentSize = Constants.ComponentSize.SMALL,
): Models.SpacerRelationStyles => {
  const {
    height = Constants.SpacerSizes[size].height,
  } = styles;

  return {
    height,
  };
};

export const createImageStyles = (styles = {} as Partial<Models.ImageRelationStyles>): Models.ImageRelationStyles => {
  const {
    alignment = {},
    altTag = '',
    scale = 1,
    link = '',
    mobileAlignment = {},
    mobileScale = 1,
    mobileSettingsApplied = false,
    height = 1,
    width = 1,
    isSizeAdjusted = false,
    mobileViewImage = createBackgroundImage(),
    abbreviations = [],
  } = styles;

  return {
    alignment: createAssetAlignment(alignment),
    altTag,
    scale,
    link,
    mobileAlignment: createAssetAlignment(mobileAlignment),
    mobileScale,
    mobileSettingsApplied,
    height,
    width,
    isSizeAdjusted,
    ...createCommonStyles(styles),
    mobileViewImage,
    abbreviations,
  };
};

export const createLayoutCommonStyles = (styles = {} as Partial<Models.LayoutStyles>): Models.LayoutStyles => {
  const {
    responsive = true,
    scrollable = false,
    height = null,
  } = styles;

  return {
    responsive,
    scrollable,
    height,
    ...createCommonStyles(styles),
  };
};

export const createLayoutStyles = (
  styles = {} as Partial<Models.LayoutStyles>,
  type: Constants.LayoutType,
): Models.LayoutStyles => {
  switch (type) {
    case Constants.LayoutType.REFERENCE_CITATION_ELEMENT:
      return createLayoutCommonStyles(_.defaults(
        {},
        styles,
        { padding: DefaultReferenceCitationElementLayoutStyles.padding },
      ));
    case Constants.LayoutType.ABBREVIATIONS_LIST:
      return createAbbreviationsListLayoutStyles(styles);
    default:
      return createLayoutCommonStyles(_.defaults({}, styles));
  }
};

export const createRowRelationStyles = (
  styles = {} as Partial<Models.RowRelationStyles>,
  columnsCount = 1,
  width = Constants.ARTBOARD_WIDTH,
): Models.RowRelationStyles => {
  const {
    columnsWidth = splitIntoEqualParts(width, columnsCount),
  } = styles;

  return {
    columnsWidth,
  };
};

export const createColumnRelationStyles = (
  styles = {} as Partial<Models.ColumnRelationStyles>,
  rowsCount = 1,
): Models.ColumnRelationStyles => {
  const {
    rowsHeight = Array<number>(rowsCount).fill(null),
  } = styles;

  return {
    rowsHeight,
  };
};

export const createSectionStyles = (styles = {} as Partial<Models.SectionStyles>): Models.SectionStyles => {
  const {
    backgroundColor = null,
    backgroundColorTint = null,
    backgroundColorOpacity = Constants.Styles.DefaultOpacity,
    backgroundGradient = null,
    backgroundImage,
  } = styles;

  return {
    backgroundColor,
    backgroundColorTint,
    backgroundColorOpacity,
    backgroundGradient,
    backgroundImage: createBackgroundImage(backgroundImage),
  };
};

export const createTextStyles = (styles = {} as Partial<Models.TextRelationStyles>): Models.TextRelationStyles => {
  const {
    alignment = {},
    padding = Constants.Styles.DefaultTextPadding,
    fontColor = [],
    bulletColor = [],
    fontSize = [],
    fontFamily = [],
    fontStyle = [],
    fontWeight = [],
    characterStyleName = [],
    lineHeight = {},
    brandStyleId = null,
    brandStyleChanged = false,
  } = styles;

  return {
    alignment: createTextAlignment(alignment),
    lineHeight,
    fontColor,
    bulletColor,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    characterStyleName,
    brandStyleId,
    brandStyleChanged,
    ...createCommonStyles({ ...styles, padding }),
  };
};

export const createArtboardStyles = (
  {
    backgroundColor = null,
    backgroundColorTint = null,
    backgroundGradient = null,
    backgroundImage,
    height = null,
    outsideBackgroundColor = null,
    width = null,
  } = {} as Partial<Models.ArtboardStyles>,
): Models.ArtboardStyles => ({
  backgroundColor,
  backgroundColorTint,
  backgroundGradient,
  backgroundImage: createBackgroundImage(backgroundImage),
  height,
  outsideBackgroundColor,
  width,
});
