import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isMockDocument(document: Models.CombinedDocument | Models.CombinedDocumentMap): boolean {
  return getValue(document as Models.Document, 'isMockDocument');
}

export function isNotMockDocument(document: Models.CombinedDocument | Models.CombinedDocumentMap) {
  return !isMockDocument(document);
}
