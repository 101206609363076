import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import ColorPickerSection from 'containers/Toolbar/Shared/ColorPicker';
import { ColorPickerProps } from './models';
import styles from './styles.module.scss';

const ColorPicker: React.FunctionComponent<ColorPickerProps> = props => (
  <Dropdown className={styles.ColorPicker} stopClickPropagation={true}>
    <ColorPickerSection {...props} />
  </Dropdown>
);

export default ColorPicker;
