import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import { ControlOptions } from 'components/Toolbar/models';
import { AssetAlignmentType } from 'const';

export const ControlMap: PartialRecord<AssetAlignmentType, ControlOptions & { name: string }> = {
  [AssetAlignmentType.LEFT]: {
    icon: IconType.TOOLBAR_LEFT,
    name: 'left',
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_LEFT,
  },
  [AssetAlignmentType.HORIZONTAL_CENTER]: {
    icon: IconType.TOOLBAR_CENTER,
    name: 'center',
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_CENTER,
  },
  [AssetAlignmentType.RIGHT]: {
    icon: IconType.TOOLBAR_RIGHT,
    name: 'right',
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_RIGHT,
  },
};
