import { debounce, fork, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { autoSave } from './autoSave';
import { downloadIssueReport } from './downloadIssueReport';
import { importTranslationPackage } from './importTranslationPackage';
import { saveProjectLocally } from './saveProjectLocally';
import { watchHotkeys } from './watchHotkeys';

export default [
  fork(watchHotkeys),
  // PERF_IMPROV: Autosave only on updating the state
  takeEvery(ActionTypes.UPDATE_APP_STATE, autoSave),
  debounce(500, ActionTypes.SAVE_PROJECT_LOCALLY, saveProjectLocally),
  takeEvery(ActionTypes.DOWNLOAD_ISSUE_REPORT, downloadIssueReport),
  takeEvery(ActionTypes.IMPORT_TRANSLATION_PACKAGE, importTranslationPackage),
];
