import _ from 'lodash';
import guid from 'uuid';

import { createMockGroupReusableLayoutOnActiveArtboard } from 'containers/Project/services/createMockGroupReusableLayoutOnActiveArtboard';
import { createMockReusableLayoutOnActiveArtboard } from 'containers/Project/services/createMockReusableLayoutOnActiveArtboard';
import { normalizeCallToActions } from 'containers/Project/services/normalizeCallToActions';
import * as Models from 'models';
import { isMockDocument } from 'utils/documents';
import { isReusableLayoutDocument } from 'utils/entityType';
import { getCurrentMetadataByLayer } from 'utils/getCurrentMetadataByLayer';
import { getOppositeLayer } from 'utils/layers';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';

type Args = Models.SetActiveLayerArgs | Models.GetProjectDataProcessArgs;

export function normalizeLayeredDocuments<T extends Args>(args: T, needToUpdateMockLayouts = false): T {
  const {
    assets: {
      project: {
        activeLayer,
        originalCountry,
        originalLanguage,
        originalProduct,
      },
      rootDocument: {
        country: targetCountry,
        language: targetLanguage,
        product: targetProduct,
      },
      layouts,
      documents,
      relations,
    },
    isProjectTranslatable,
  } = args;

  if (!isProjectTranslatable) {
    return args;
  }

  const oppositeLayer = getOppositeLayer(activeLayer);

  const metadata: Models.MarketMetaData = {
    language: getCurrentMetadataByLayer(oppositeLayer, originalLanguage, targetLanguage, isProjectTranslatable),
    country: getCurrentMetadataByLayer(oppositeLayer, originalCountry, targetCountry, isProjectTranslatable),
    product: getCurrentMetadataByLayer(oppositeLayer, originalProduct, targetProduct, isProjectTranslatable),
  };

  const translatedDocumentIdByOriginal = {} as Record<string, string>;

  const mockReusableLayoutDocuments: Models.ReusableLayouts = _(layouts)
    .map((layout) => {
      if (!isPlainLayout(layout)) {
        return null;
      }

      const layoutDocumentId = layout.documentId[activeLayer];
      const layoutDocumentIdOnOppositeLayer = layout.documentId[oppositeLayer];
      const document = layoutDocumentId && documents[layoutDocumentId] as Models.ReusableLayout;
      const documentOnOppositeLayer = layoutDocumentIdOnOppositeLayer && documents[layoutDocumentIdOnOppositeLayer] as Models.ReusableLayout;

      const needToCreateMockDocument = document && (!documentOnOppositeLayer || isMockDocument(documentOnOppositeLayer) && needToUpdateMockLayouts);

      if (!needToCreateMockDocument) {
        return null;
      }

      if (translatedDocumentIdByOriginal[layoutDocumentId]) {
        layout.documentId[oppositeLayer] = translatedDocumentIdByOriginal[layoutDocumentId];

        return null;
      }

      const newDocumentId = guid();
      // update layout documentId
      layout.documentId[oppositeLayer] = newDocumentId;
      translatedDocumentIdByOriginal[layoutDocumentId] = newDocumentId;

      return createMockReusableLayoutOnActiveArtboard(
        layout,
        document,
        oppositeLayer,
        documents,
        relations,
        metadata,
        newDocumentId,
      );
    })
    .omitBy(_.isEmpty)
    .keyBy(document => document.id)
    .value();

  // create mock GRLs only after all mock RLs are created
  const mockGroupReusableLayouts: Models.GroupLayoutDocuments = _(layouts)
    .map((layout) => {
      if (!isGroupLayout(layout)) {
        return null;
      }

      const layoutDocumentId = layout.documentId[activeLayer];
      const layoutDocumentIdOnOppositeLayer = layout.documentId[oppositeLayer];
      const document = documents[layoutDocumentId] as Models.GroupLayoutDocument;
      const documentOnOppositeLayer = layoutDocumentIdOnOppositeLayer && documents[layoutDocumentIdOnOppositeLayer] as Models.GroupLayoutDocument;
      const existingReusableLayouts = _.pickBy(documents, isReusableLayoutDocument) as Models.ReusableLayouts;

      const needToCreateMockDocument = document && (!documentOnOppositeLayer || isMockDocument(documentOnOppositeLayer) && needToUpdateMockLayouts);

      if (!needToCreateMockDocument) {
        return null;
      }

      if (translatedDocumentIdByOriginal[layoutDocumentId]) {
        layout.documentId[oppositeLayer] = translatedDocumentIdByOriginal[layoutDocumentId];

        return null;
      }

      const newDocumentId = guid();
      // update layout documentId
      layout.documentId[oppositeLayer] = newDocumentId;
      translatedDocumentIdByOriginal[layoutDocumentId] = newDocumentId;

      return createMockGroupReusableLayoutOnActiveArtboard(
        layout,
        layouts,
        // pass both newly created mock RLs and existing mock RLs and RLs
        { ...existingReusableLayouts, ...mockReusableLayoutDocuments },
        metadata,
        oppositeLayer,
        newDocumentId,
      );
    })
    .omitBy(_.isEmpty)
    .keyBy(document => document.id)
    .value();

  // add empty CTAs buttons on active layer
  const { callToActionDocuments, callToActionRelations } = normalizeCallToActions(relations, documents, oppositeLayer, metadata);

  // set mock documents and CTAs to the project assets
  _.forEach(
    { ...mockReusableLayoutDocuments, ...mockGroupReusableLayouts, ...callToActionDocuments },
    document => _.set(args.assets.documents, document.id, document),
  );

  // set CTAs' relations to the project assets
  _.forEach(callToActionRelations, relation => _.set(args.assets.relations, relation.id, relation));

  return args;
}
