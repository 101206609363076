import { takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { addSSI } from './addSSI';
import { deleteSSI } from './deleteSSI';
import { downloadArtboardsJson } from './downloadArtboardsJson';
import { dropStoryCard } from './dropStoryCard';
import { moveSSI } from './moveSSI';
import { waitArtboard } from './waitArtboard';

export default [
  takeLatest(ActionTypes.ARTBOARD_WILL_BE_MOUNTED, waitArtboard),
  takeLatest(ActionTypes.DOWNLOAD_ARTBOARDS_JSON, downloadArtboardsJson),
  takeLatest(ActionTypes.DROP_STORY_CARD, dropStoryCard),
  takeEvery(ActionTypes.DELETE_SSI, deleteSSI),
  takeEvery(ActionTypes.MOVE_SSI, moveSSI),
  takeEvery(ActionTypes.ADD_SSI, addSSI),
];
