import React from 'react';
/* eslint-disable max-len */

const Edit = (): JSX.Element => (
  <svg viewBox="0 0 25 25">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g>
          <rect width="25" height="25" style={{ fill: 'none' }} />
          <path d="M23.24,1.76c-2-2-3.59-1.74-3.59-1.74l-8.6,8.6L1.43,18.23,0,25l6.77-1.43,9.62-9.62L25,5.35S25.29,3.81,23.24,1.76Zm-16.88,21-2.31.5a5.27,5.27,0,0,0-1-1.32,5.31,5.31,0,0,0-1.32-1l.5-2.31L2.92,18a4.32,4.32,0,0,1,2.67,1.44A4.32,4.32,0,0,1,7,22.09Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Edit;
