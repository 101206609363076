import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Spacer from 'components/Spacer';
import { setMoveableLayoutId } from 'containers/App/actions';
import { areActionsDisabled, artboardScale } from 'containers/App/selectors';
import { deleteLayoutRequest } from 'containers/Layouts/actions';
import { updateLayeredRelations } from 'containers/Relations/actions';
import { layeredRelations } from 'containers/Relations/selectors';

const mapStateToProps = createStructuredSelector({
  areActionsDisabled,
  relations: layeredRelations,
  scale: artboardScale,
});

const mapDispatchToProps = {
  deleteLayout: deleteLayoutRequest,
  // TODO: get rid of it
  setMoveableLayout: setMoveableLayoutId,
  updateRelations: updateLayeredRelations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Spacer);
