import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ImageListItem from 'components/ImageListItem';
import { IImageListItemActions, IImageListItemOwnProps, IImageListItemSelectors } from 'components/ImageListItem/models';
import { addSelectedAssetPanelComponent, removeSelectedAssetPanelComponent } from 'containers/App/actions';
import { selectedComponentItem } from 'containers/App/selectors';
import { deleteComponent } from 'containers/Documents/actions';
import { documentIdsAreBeingUploaded } from 'containers/Project/selectors';
import { setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { assetIdToScroll, selectComponentCount } from 'containers/ProjectPanel/selectors';

const mapStateToProps = (state, props) => {
  const id = props.component.get('id');

  return createStructuredSelector({
    assetIdToScroll,
    imageCount: selectComponentCount(id),
    isSelected: selectedComponentItem(id),
    documentIdsAreBeingUploaded,
  });
};

const mapDispatchToProps = {
  deleteComponent,
  setAssetIdToScroll,
  addSelectedAssetPanelComponent,
  removeSelectedAssetPanelComponent,
};

export default connect<IImageListItemSelectors, IImageListItemActions, IImageListItemOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ImageListItem);
