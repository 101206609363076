import _ from 'lodash';

import * as Models from 'models';

/**
 * Checks whether or not the layout structure meets the actual one.
 * Used not to allow to drag and drop inconsistent layouts.
 */
export function isReusableLayoutValid(layout: Models.ReusableLayoutMap): boolean {
  const {
    entities: {
      relationId,
      styles,
      relations,
      brandDefinition,
    },
    documents,
  } = _.defaults(layout.toJS(), { entities: {} }) as Models.ReusableLayout;

  return Boolean(documents && relationId && styles && relations && brandDefinition);
}

export function isGroupReusableLayoutValid(layout: Models.GroupLayoutDocumentMap): boolean {
  const {
    entities: {
      layouts,
    },
  } = _.defaults(layout.toJS(), { entities: {} }) as Models.GroupLayoutDocument;

  return !!layouts;
}
