import { DefaultLineHeight } from 'const';
import { DefaultAlign, ParagraphType } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { createFont } from './createFont';
import { createSegment } from './createSegment';

export const createParagraph = ({
  align = DefaultAlign,
  anchor = false,
  lineHeight = String(DefaultLineHeight),
  segments = [],
  type,
  ...args
} = {} as Partial<Schemas.Paragraph>): Schemas.Paragraph => ({
  type,
  align,
  lineHeight,
  ...createFont(args),
  anchor,
  segments: segments.map(segment => createSegment(segment)),
});

export const createListItemParagraph = ({
  bulletWidth,
  listStyleType,
  level = 0,
  ...args
} = {} as Partial<Schemas.ListItemParagraph>): Schemas.ListItemParagraph => ({
  bulletWidth,
  listStyleType,
  level,
  ...createParagraph(args),
  type: ParagraphType.UNORDERED_LIST_ITEM,
});

export const createTextParagraph = ({
  ...args
} = {} as Partial<Schemas.TextParagraph>): Schemas.TextParagraph => ({
  ...createParagraph(args),
  type: ParagraphType.PLAINT_TEXT,
});

// TODO: split into 2 functions for ol and ul
export const createListParagraph = ({
  margin,
  padding,
  paragraphs,
} = {} as Partial<Schemas.ListParagraph>): Schemas.ListParagraph => ({
  margin,
  padding,
  type: ParagraphType.UNORDERED_LIST,
  paragraphs: paragraphs.map(createListItemParagraph),
});
