import Immutable from 'immutable';
import _ from 'lodash';

import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { isGroupLayout } from './isGroupLayout';

export function getGroupLayoutsChildLayoutIds(layouts: Models.CombinedLayouts | Models.LayeredCombinedLayouts): string[];
export function getGroupLayoutsChildLayoutIds(layouts: Models.CombinedLayoutsMap | Models.LayeredCombinedLayoutsMap): Immutable.List<string>;
export function getGroupLayoutsChildLayoutIds(
  layouts: Models.CombinedLayouts | Models.CombinedLayoutsMap | Models.LayeredCombinedLayouts | Models.LayeredCombinedLayoutsMap,
): string[] | Immutable.List<string> {
  if (isImmutable(layouts as Models.CombinedLayoutsMap)) {
    return (layouts as Models.CombinedLayoutsMap).toList().flatMap(layout => isGroupLayout(layout) ? layout.get('layoutIds').toArray() : []);
  }

  return _.flatMap(layouts, layout => isGroupLayout(layout) ? layout.layoutIds : []);
}
