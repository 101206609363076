import _ from 'lodash';
import { all, call, put, select } from 'redux-saga/effects';

import { Layer } from 'const';
import { getLayoutPreviewUrl } from 'containers/Common/services/getLayoutPreviewUrl';
import { updateDocument } from 'containers/Documents/actions';
import { layeredLayouts as layeredLayoutsSelector, layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { activeLayer as activeLayerSelector } from 'containers/Project/selectors';
import * as Models from 'models';
import { isGroupReusableLayoutEdited, isMockDocument, isReusableLayoutEdited } from 'utils/documents';
import { isCombinedLayoutDocument } from 'utils/entityType';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import * as actions from '../actions';
import { UploadProjectFlowArg } from '../models';
import { setActiveLayer } from '../sagas/setActiveLayer';

export function* updateReusableLayoutsPreviews(arg: UploadProjectFlowArg): Generator<unknown, void> {
  const {
    prevAssets: {
      documents: prevDocuments,
    },
    projectAssets: {
      documents,
      project: {
        activeLayer: originalLayer,
      },
    },
  } = _.defaults(arg, { prevAssets: {} });

  const priorityDocuments = prevDocuments || documents;
  const combinedLayoutDocuments = _(priorityDocuments)
    .pickBy(isCombinedLayoutDocument)
    .omitBy(isMockDocument)
    .value() as Models.CombinedLayoutDocuments;

  const layersToIterate: Set<Layer> = new Set();

  const layeredLayouts = (yield select(layeredLayoutsSelector)).toJS() as Models.LayeredCombinedLayouts;

  // collect layers with edited reusable layouts
  _.forEach(layeredLayouts, ({ documentId: layeredDocumentId }) =>
    _.forEach(layeredDocumentId, (documentId, layer: Layer) => {
      const document = priorityDocuments[documentId];

      if (
        document
        && (isReusableLayoutEdited(document) || isGroupReusableLayoutEdited(document, priorityDocuments) || !_.get(document, '_thumbnailUrl'))
      ) {
        layersToIterate.add(layer);
      }
    }),
  );

  for (const layer of layersToIterate) {
    const activeLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);

    // switch to the correct layer
    if (layer !== activeLayer) {
      yield call(setActiveLayer, actions.setActiveLayer(layer));
    }

    // need to take actual layouts after active layer is set
    const layouts = (yield select(layoutsSelector)).toJS() as Models.CombinedLayouts;

    const layoutIdsByDocumentInternalId = _(combinedLayoutDocuments)
      .pickBy(document =>
        isReusableLayoutEdited(document) || isGroupReusableLayoutEdited(document, priorityDocuments) || !_.get(document, '_thumbnailUrl'),
      )
      .mapValues((doc, id) => _.chain(layouts)
        .find({ documentId: id })
        .get('id')
        .value(),
      )
      .omitBy((layoutId, documentId) => !layoutId || !Object.prototype.hasOwnProperty.call(documents, documentId))
      .value();

    const previewUrlsByDocumentInternalId: Record<string, string> = yield all(
      _.mapValues(layoutIdsByDocumentInternalId, (layoutId) => {
        const layout = layouts[layoutId];
        const layoutIds = isGroupLayout(layout) ? layout.layoutIds : [layoutId];

        return call(getLayoutPreviewUrl, layoutIds);
      }),
    );

    const previewUrlsInternalInfoByDocumentInternalId: Record<string, Partial<Models.ImageInternalInfo>> = yield all(
      _.mapValues(previewUrlsByDocumentInternalId, (url, id) => {
        const layoutDocument = documents[id] as Models.CombinedLayoutDocument;

        return isGroupLayout(layoutDocument)
          ? { source: url }
          : call(getImageInternalInfo, url);
      }),
    );

    // update layout documents to fetch actual layout assets
    yield all(_.mapValues(previewUrlsInternalInfoByDocumentInternalId, (urlInternalInfo, id) => {
      const updateLayoutDocument = documents[id] as Models.CombinedLayoutDocument;

      const { source, width, height } = urlInternalInfo || {} as Models.ImageInternalInfo;

      updateLayoutDocument.previewUrl = source;
      updateLayoutDocument._thumbnailUrl = source;
      updateLayoutDocument.needToUpdatePreview = true;

      if (!isGroupLayout(updateLayoutDocument)) {
        updateLayoutDocument._thumbnailWidth = width;
        updateLayoutDocument._thumbnailHeight = height;
      }

      return put(updateDocument(updateLayoutDocument));
    }));
  }

  const activeLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);

  // restore original active layer
  if (activeLayer !== originalLayer) {
    yield call(setActiveLayer, actions.setActiveLayer(originalLayer));
  }
}
