import Immutable from 'immutable';
import _ from 'lodash';

import * as Models from 'models';
import { getValue } from 'utils/getter';
import { isImmutable } from 'utils/immutable';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { pickWithOrder } from 'utils/pickWithOrder';
import { isColumnRelation } from './isColumnRelation';
import { isRegularRelation } from './isRegularRelation';
import { isRowRelation } from './isRowRelation';

type Entity = Models.Layout | Models.RowRelation | Models.ColumnRelation | Models.LayeredLayout;

export function getFlattenedRelations<E extends Entity, R extends Models.Relations>(
  entity: E | DeepIMap<E>,
  allRelations: R,
  omitRowAndColumnRelations?: false,
): R;
export function getFlattenedRelations<E extends Entity, R extends Models.Relations>(
  entity: E | DeepIMap<E>,
  allRelations: DeepIMap<R>,
  omitRowAndColumnRelations?: false,
): DeepIMap<R>;
export function getFlattenedRelations<E extends Entity, R extends Models.Relations>(
  entity: E | DeepIMap<E>,
  allRelations: R | DeepIMap<R>,
  omitRowAndColumnRelations?: false,
): R | DeepIMap<R>;
export function getFlattenedRelations<E extends Entity, R extends Models.LayeredRelations>(
  entity: E | DeepIMap<E>,
  allRelations: R,
  omitRowAndColumnRelations?: false,
): R;
export function getFlattenedRelations<E extends Entity, R extends Models.LayeredRelations>(
  entity: E | DeepIMap<E>,
  allRelations: DeepIMap<R>,
  omitRowAndColumnRelations?: false,
): DeepIMap<R>;
export function getFlattenedRelations<E extends Entity, R extends Models.LayeredRelations>(
  entity: E | DeepIMap<E>,
  allRelations: R | DeepIMap<R>,
  omitRowAndColumnRelations?: false,
): R | DeepIMap<R>;
export function getFlattenedRelations<E extends Entity, R extends Models.Relations>(
  entity: E | DeepIMap<E>,
  allRelations: R,
  omitRowAndColumnRelations?: true,
): Models.RegularRelations;
export function getFlattenedRelations<E extends Entity, R extends Models.Relations>(
  entity: E | DeepIMap<E>,
  allRelations: DeepIMap<R>,
  omitRowAndColumnRelations?: true,
): DeepIMap<Models.RegularRelations>;
export function getFlattenedRelations<E extends Entity, R extends Models.Relations>(
  entity: E | DeepIMap<E>,
  allRelations: R | DeepIMap<R>,
  omitRowAndColumnRelations?: true,
): Models.RegularRelations | DeepIMap<Models.RegularRelations>;
export function getFlattenedRelations<E extends Entity, R extends Models.LayeredRelations>(
  entity: E | DeepIMap<E>,
  allRelations: R,
  omitRowAndColumnRelations?: true,
): Models.LayeredRegularRelations;
export function getFlattenedRelations<E extends Entity, R extends Models.LayeredRelations>(
  entity: E | DeepIMap<E>,
  allRelations: DeepIMap<R>,
  omitRowAndColumnRelations?: true,
): DeepIMap<Models.LayeredRegularRelations>;
export function getFlattenedRelations<E extends Entity, R extends Models.LayeredRelations>(
  entity: E | DeepIMap<E>,
  allRelations: R | DeepIMap<R>,
  omitRowAndColumnRelations?: true,
): Models.LayeredRegularRelations | DeepIMap<Models.LayeredRegularRelations>;
export function getFlattenedRelations(
  entity,
  allRelations,
  omitRowAndColumnRelations?,
) {
  if (isPlainLayout(entity)) {
    const relationId = getValue(entity, 'relationId');
    const layoutRelation = getValue(allRelations, relationId);

    return flattenRelations(
      isImmutable(entity) ? Immutable.OrderedMap({ [relationId]: layoutRelation }) : { [relationId]: layoutRelation },
      allRelations,
      omitRowAndColumnRelations,
    );
  }

  return flattenRelations(pickWithOrder(allRelations, getValue(entity, 'relationIds')), allRelations, omitRowAndColumnRelations);
}

function flattenRelations<
  T extends Record<string, Models.Relation | Models.LayeredRelation>,
  R extends Models.Relations | Models.LayeredRelations,
>(
  relationsToBeFlattened: T | DeepIMap<T>,
  allRelations: T | DeepIMap<T>,
  omitRowAndColumnRelations?: false,
): R | DeepIMap<R>;
function flattenRelations<
  T extends Record<string, Models.Relation | Models.LayeredRelation>,
  R extends Models.RegularRelations | Models.LayeredRegularRelations,
>(
  relationsToBeFlattened: T | DeepIMap<T>,
  allRelations: T | DeepIMap<T>,
  omitRowAndColumnRelations?: true,
): R | DeepIMap<R>;
function flattenRelations<
  T extends Record<string, Models.Relation | Models.LayeredRelation>,
  R extends Models.Relations | Models.LayeredRelations | Models.RegularRelations | Models.LayeredRegularRelations,
>(
  relationsToBeFlattened: T | DeepIMap<T>,
  allRelations: T | DeepIMap<T>,
  omitRowAndColumnRelations = false,
): R | DeepIMap<R> {
  if (isImmutable(relationsToBeFlattened) && isImmutable(allRelations)) {
    return relationsToBeFlattened
      .flatMap((relation, id) => isRowRelation(relation) || isColumnRelation(relation)
        ? [
          [id, relation],
          ...flattenRelations(
            pickWithOrder(allRelations, relation.get('relationIds')),
            allRelations,
          ) as DeepIMap<T>,
        ]
        :
        [[id, relation]],
      )
      .filter(relation => !omitRowAndColumnRelations || isRegularRelation(relation)) as DeepIMap<R>;
  }

  return _(relationsToBeFlattened as T)
    .flatMapDeep(relation => (
      isRowRelation(relation) || isColumnRelation(relation)
        ? [
          relation,
          ..._.values(flattenRelations(
            pickWithOrder(allRelations as T, relation.relationIds),
            allRelations,
          )),
        ] as T[keyof T][]
        :
        [relation]
    ),
    )
    .filter(relation => (!omitRowAndColumnRelations || isRegularRelation(relation)) && !!relation?.id)
    .keyBy(relation => relation.id)
    .value() as unknown as R;
}
