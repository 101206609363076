import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const TabletScreen = (): JSX.Element => {
  const id = guid();

  return (
    <svg viewBox="0 0 19 29" width="19px" height="29px">
      <defs>
        <path d="M9.28570376,25.8363343 L24.1276724,25.8363343 L24.1276724,7.20902874 L9.28570376,7.20902874 L9.28570376,25.8363343 Z M17.492594,29.4233827 C17.0589001,29.8682809 16.3544761,29.8682809 15.9207822,29.4233827 C15.4870883,28.9775856 15.4870883,28.2567606 15.9207822,27.8118623 C16.3544761,27.3651665 17.0589001,27.3651665 17.492594,27.8118623 C17.9262879,28.2567606 17.9262879,28.9775856 17.492594,29.4233827 L17.492594,29.4233827 Z M24.9127021,3.86644975 C24.3274344,3.32807792 23.6019828,3.33077428 23.3882024,3.34515483 L10.0242976,3.34515483 C9.80964102,3.33077428 9.08506558,3.32807792 8.49979786,3.86644975 C8.17912723,4.16125102 7.79625,4.72299123 7.79625,5.72603456 L7.79625,28.9281525 C7.82078218,29.8350259 8.43759127,31.185 10.0365637,31.185 L23.3768125,31.185 C24.9757849,31.185 25.592594,29.8350259 25.61625,28.9065817 L25.61625,5.72603456 C25.61625,4.72299123 25.2342489,4.16125102 24.9127021,3.86644975 L24.9127021,3.86644975 Z" id="tablet-sc-id-1"/>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Navigate-Card" transform="translate(-1277.000000, -132.000000)">
          <g id="content">
            <g transform="translate(0.000000, 28.000000)">
              <g id="bottom" transform="translate(46.743243, 92.000000)">
                <g id="usd" transform="translate(1188.000000, 0.000000)">
                  <g id="3.-Icons/ic_iPad" transform="translate(35.040787, 9.058299)">
                    <mask id={id} fill="white">
                      <use xlinkHref="#tablet-sc-id-1" />
                    </mask>
                    <g id="Icon" />
                    <g id="5.-Colors/Gray-70" mask={`url(#${id})`} fill="#B3B3B3" fillRule="evenodd">
                      <g transform="translate(2.227500, 2.227500)" id="Gray-70">
                        <rect x="0" y="0" width="29.0828571" height="29.0828571" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TabletScreen;
