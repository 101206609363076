import classNames from 'classnames';
import React from 'react';
import { IList } from 'typings/DeepIMap';
import ColorSection from 'components/Toolbar/Shared/ColorSection';
import Tooltip from 'components/Tooltip';
import { BrandColor, BrandColorMap, ColorsCategory } from 'models';
import styles from './styles.module.scss';

type Props = {
  colorsCategories: IList<ColorsCategory>;
  colors: IList<BrandColor>;
  value?: BrandColorMap;
  onChange: (value: BrandColorMap) => void;
  className?: string;
  tooltipClassName?: string;
};

const ColorsList: React.FunctionComponent<Props> = (props) => {
  const {
    colorsCategories,
    colors,
    value,
    onChange,
    className,
    tooltipClassName,
  } = props;

  const [tooltipState, setTooltipState] = React.useState({
    show: false,
    parent: null,
    text: '',
  });

  const toggleTooltipWithOptions = (show = false, text = '', parent = null): void => {
    setTooltipState({ show, parent, text });
  };

  return (
    <div className={classNames(className, styles.colorsList)}>
      <div>
        {
          colorsCategories.map(colorsCategory => (
            <ColorSection
              key={colorsCategory.get('name')}
              activeColor={value}
              colors={colors.filter(color => colorsCategory.get('brandColors').includes(color.get('name')))}
              title={colorsCategory.get('name')}
              toggleColor={onChange}
              toggleTooltip={toggleTooltipWithOptions}
            />
          ))
        }
      </div>
      <Tooltip
        arrow={false}
        className={classNames(tooltipClassName, styles.colorsListTooltip)}
        parent={tooltipState.parent}
        show={tooltipState.show}
        text={tooltipState.text}
      />
    </div>
  );
};

export default ColorsList;
