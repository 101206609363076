import Immutable from 'immutable';

import * as Models from 'models';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const artboardPreviewInitialState: State.IState = Immutable.fromJS({
  cellsHeight: Immutable.Map<string, number>(),
  previewsHtml: Immutable.Map(),
  screenIdsReadyForOutput: Immutable.Set(),
});

const artboardPreviewReducer: Models.Reducer<State.IState> = (state = artboardPreviewInitialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CELL_HEIGHT: return setCellHeight(state, action);
    case ActionTypes.DELETE_CELL_HEIGHTS: return deleteCellHeights(state, action);
    case ActionTypes.RESET_PREVIEWS_HTML: return resetPreviewHtml(state, action);
    case ActionTypes.SET_PREVIEW_HTML: return setPreviewHtml(state, action);
    case ActionTypes.ADD_SCREEN_READY_FOR_OUTPUT: return addScreenReadyForOutput(state, action);
    case ActionTypes.RESET_SCREENS_READY_FOR_OUTPUT: return resetScreensReadyForOutput(state, action);
    default: return state;
  }
};

const setCellHeight: Models.Reducer<State.IState, Action.ISetCellHeight> = (state, action) => {
  const { id, newHeight } = action.payload;
  const currentHeight = state.getIn(['cellsHeight', id]);

  return newHeight !== currentHeight ? state.setIn(['cellsHeight', id], newHeight) : state;
};

const deleteCellHeights: Models.Reducer<State.IState, Action.IDeleteCellHeights> = (state, action) => {
  const { ids } = action.payload;

  return state.updateIn(
    ['cellsHeight'],
    (cellsHeight: Models.CellsHeightMap) => ids.reduce((cellsHeight, id) => cellsHeight.delete(id), cellsHeight),
  );
};

const setPreviewHtml: Models.Reducer<State.IState, Action.ISetPreviewHtml> = (state, action) => {
  const { previewHtml, screenId, htmlUnicodeSize } = action.payload;

  return state
    .setIn(['previewsHtml', screenId, 'html'], previewHtml)
    .setIn(['previewsHtml', screenId, 'htmlUnicodeSize'], htmlUnicodeSize);
};

const addScreenReadyForOutput: Models.Reducer<State.IState, Action.IAddScreenReadyForOutput> = (state, action) => {
  const { screenId } = action.payload;

  return state.update('screenIdsReadyForOutput', screenIdsReadyForOutput => screenIdsReadyForOutput.add(screenId));
};

const resetScreensReadyForOutput: Models.Reducer<State.IState, Action.IResetScreensReadyForOutput> = (state) => {
  return state.set('screenIdsReadyForOutput', Immutable.Set<string>());
};

const resetPreviewHtml: Models.Reducer<State.IState, Action.IResetPreviewsHtml> = (state) => {
  return state.set('previewsHtml', Immutable.Map<string, string>());
};

export default artboardPreviewReducer;
