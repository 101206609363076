import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import { UIFontFaces as UIFontFacesSelector } from 'containers/Common/selectors';
import { getLayoutJson } from 'containers/Layouts/services/getLayoutJson';
import { helperFileName as helperFileNameSelector } from 'containers/Project/selectors';
import { UIFontFace } from 'models';
import { getThumbnails } from 'services/api';

export function* getLayoutPreviewUrl(layoutIds: string[]): Generator<unknown, string> {
  const helperFileName: ReturnTypeSaga<typeof helperFileNameSelector> = yield select(helperFileNameSelector);
  const UIFontFaces: UIFontFace[] = (yield select(UIFontFacesSelector)).toJS();
  const layoutJson: ReturnTypeSaga<typeof getArtboardsJson> = yield call(getLayoutJson, layoutIds);

  const { data: thumbnailUrlsByArtboardId }: ReturnTypeSaga<typeof getThumbnails> = yield call(
    getThumbnails,
    layoutJson,
    helperFileName,
    UIFontFaces,
  );

  return _(thumbnailUrlsByArtboardId).values().first();
}
