import { FontStyle } from 'const';
import * as Models from 'models';

export function getCharacterStyle(characterStyles: Models.CharacterStylesList, styleName?: string): Models.CharacterStyleMap {
  if (!characterStyles || characterStyles.size === 0) {
    return null;
  }

  const characterStyleByName = styleName && characterStyles.find(style => style.get('name') === styleName);

  return characterStyleByName || characterStyles.find(charStyle => charStyle.get('if') === FontStyle.REGULAR) || characterStyles.first();
}
