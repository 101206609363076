import _ from 'lodash';

import * as Models from 'models';

export function getThumbnailsByArtboardId(surfaces: Models.Screens): Models.ThumbnailsByArtboardId {
  return _.reduce(
    surfaces,
    (thumbnails, surface: Models.Screen) => {
      const { artboardId, thumbnail } = surface;
      thumbnails[artboardId] = thumbnail;

      return thumbnails;
    },
    {} as Models.ThumbnailsByArtboardId,
  );
}
