import classNames from 'classnames';
import React from 'react';
import { BrandColorGradientMap } from 'models/BrandColorGradient';
import { getCSSBackgroundBrandGradientLayer } from 'utils/styles/getBackground';
import styles from './styles.module.scss';

type Props = {
  gradient: BrandColorGradientMap | undefined;
  className?: string;
};

const GradientPreview: React.FunctionComponent<Props> = (props) => {
  const { gradient, className } = props;

  if (!gradient) {
    return (
      <div className={classNames(styles.gradientPreview, 'invalid', className)} />
    );
  }

  return (
    <div
      className={classNames(styles.gradientPreview, className)}
      style={{ background: getCSSBackgroundBrandGradientLayer(gradient) }}
    />
  );
};

export default GradientPreview;
