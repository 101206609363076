import { ContentBlock, ContentState, EditorState, RichUtils } from 'draft-js';

import { TextHorizontalAlignmentType } from 'const';
import { getSelectedBlocks, trimSelection } from './selection';

/**
 * Returns type of provided block with fallback to `TextHorizontalAlignmentType.LEFT`
 *
 * @param {ContentBlock} block  - DraftJS content block
 * @returns {TextHorizontalAlignmentType} - alignment type of text block
 */
const getType = (block: ContentBlock): TextHorizontalAlignmentType => {
  const type = block.getType();

  return Object.values(TextHorizontalAlignmentType).includes(type) ? (type as TextHorizontalAlignmentType) : TextHorizontalAlignmentType.LEFT;
};

export const getBlockType = (editorState: EditorState): TextHorizontalAlignmentType => {
  const contentState = editorState.getCurrentContent();
  const selectionsState = trimSelection(editorState);

  return getSelectedBlocks(selectionsState, contentState)
    .map(getType)
    .reduce((result, type) => result === type ? result : null);
};

export const toggleBlockType = (
  editorState: EditorState,
  type: TextHorizontalAlignmentType | undefined,
  disableUntoggle = false,
): EditorState => {
  // to be able to untoggle bulleted list
  if (disableUntoggle || type !== TextHorizontalAlignmentType.UNORDERED_LIST) {
    const aggregatedType = getBlockType(editorState);

    if (aggregatedType && aggregatedType === type) {
      return editorState;
    }
  }

  return RichUtils.toggleBlockType(editorState, type || '');
};

export const isListBlock = (block: Draft.ContentBlock): boolean => {
  const blockType = getType(block);

  return blockType === TextHorizontalAlignmentType.UNORDERED_LIST;
};

export const getAlignmentMap = (editorState: Draft.EditorState): Record<string, TextHorizontalAlignmentType> => {
  return editorState.getCurrentContent().getBlockMap().reduce(
    (map, block) => {
      const blockType = block.getType();
      const key = block.getKey();
      const type = Object.values(TextHorizontalAlignmentType).includes(blockType) ? blockType : TextHorizontalAlignmentType.LEFT;

      return Object.assign(map, { [key]: type });
    },
    {} as Record<string, TextHorizontalAlignmentType>,
  );
};

export const replaceBlocksKeys = (oldEditorState: EditorState, newEditorState: EditorState) => {
  const targetBlocks = newEditorState.getCurrentContent().getBlocksAsArray();
  const blocks = oldEditorState.getCurrentContent().getBlocksAsArray().map(
    (block, idx) => block.set('key', targetBlocks[idx].get('key')),
  ) as ContentBlock[];

  return EditorState.createWithContent(ContentState.createFromBlockArray(blocks));
};
