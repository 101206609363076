import _, { isNull } from 'lodash';
import * as Models from 'models';
import { isImage } from 'utils/entityType';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

function filterUnusedAbbreviationsFromStyle(
  style: Models.ImageRelationStyles,
  textAbbreviationDocuments: Models.TextAbbreviationDocumentsArray,
): void {
  if (isNull(style)) {
    return;
  }
  style.abbreviations = style.abbreviations?.filter(abbriviationId => abbriviationId in textAbbreviationDocuments) || [];
}

type StylesWithAbbreviations = Models.ImageRelationStyles | Models.Layers<Models.ImageRelationStyles>;

function reduceImageRelations(
  relations: Models.Relations | Models.LayeredRelations,
  callback: (styles: StylesWithAbbreviations) => void,
): void {
  _(relations)
    .pickBy(isRegularRelation)
    .pickBy(isImage)
    .forEach(relation => callback(relation.styles as StylesWithAbbreviations));
}

export function removeImageAbbreviationsFromStyles({ relations, textAbbreviationDocuments }: {
  relations: Models.Relations;
  textAbbreviationDocuments: Models.TextAbbreviationDocumentsArray;
}): void {
  reduceImageRelations(relations, (style: Models.ImageRelationStyles) => {
    filterUnusedAbbreviationsFromStyle(style, textAbbreviationDocuments);
  });
}

export function removeImageAbbreviationsFromLayeredRelations<T extends Models.GetProjectDataProcessArgs>(args: T): void {
  const { textAbbreviationDocuments, assets } = args;

  reduceImageRelations(assets.relations ?? {}, (styles: Models.Layers<Models.ImageRelationStyles>) => {
    for (const layer in styles) {
      const style: Models.ImageRelationStyles = styles[layer];
      filterUnusedAbbreviationsFromStyle(style, textAbbreviationDocuments);
    }
  });
}
