import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as Models from 'components/ModalWindows/Confirmation/models';
import SSISource from 'components/ModalWindows/SSISource';
import { setSSISource } from 'containers/Artboards/actions';
import { activeArtboardId } from 'containers/Artboards/selectors';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';
import { screenDefinitions } from 'containers/ScreenDefinitions/selectors';
import { orderedSurfaces } from 'containers/Surfaces/selectors';

const mapStateToProps = (state, props: Models.ConfirmationProps) => createStructuredSelector({
  activeArtboardId,
  options: modalWindowOptions(props.modalWindowId),
  screenDefinitions,
  screens: orderedSurfaces,
});

const mapDispatchToProps = {
  hideModal,
  setSSISource,
};

export default connect(mapStateToProps, mapDispatchToProps)(SSISource);
