import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Image from 'components/ArtboardAssets/Image';
import { ImageActions, ImageOwnProps, ImageSelectors } from 'components/ArtboardAssets/Image/models';
import { updateAppState } from 'containers/App/actions';
import { flatColorsByRelationId } from 'containers/Common/selectors';
import { imagesForAssetsPanel } from 'containers/Documents/selectors';
import { placeholderMinHeight } from 'containers/Project/selectors';
import { updateLayeredRelations } from 'containers/Relations/actions';
import { rootDocument } from 'containers/RootDocument/selectors';
import { saveAppState, cancel } from 'containers/UndoRedoControl/actions';

const makeMapStateToProps = createStructuredSelector({
  flatColorsByRelationId,
  images: imagesForAssetsPanel,
  placeholderMinHeight,
  rootDocument,
});

const mapDispatchToProps = {
  finalizeRelations: updateLayeredRelations,
  updateAppState,
  saveAppState,
  cancel,
};

export default connect<ImageSelectors, ImageActions, ImageOwnProps>(
  makeMapStateToProps,
  mapDispatchToProps,
)(Image);
