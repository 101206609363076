import _ from 'lodash';

import { IconType } from 'components/Icon/constants';
import { PreviewOptionName } from 'const';
import { PreviewOption } from 'models';
import { toPx } from 'utils/toPx';

export const getCustomPreviewOption = (width: number): PreviewOption => {
  const [name, iconType] = _.inRange(width, 0, 480)
    ? [PreviewOptionName.MOBILE, IconType.PHONE_SCREEN]
    : _.inRange(width, 480, 1025)
      ? [PreviewOptionName.IPAD, IconType.TABLET_SCREEN]
      : [PreviewOptionName.DESKTOP, IconType.DESKTOP_SCREEN];

  return {
    width: toPx(width),
    cleanPDF: true,
    iconType,
    name,
  };
};
