import React from 'react';
/* eslint-disable max-len */

const Image = (): JSX.Element => (
  <svg viewBox="0 0 21 21">
    <path d="M21,18.6666667 L21,2.33333333 C21,1.05 19.95,0 18.6666667,0 L2.33333333,0 C1.05,0 0,1.05 0,2.33333333 L0,18.6666667 C0,19.95 1.05,21 2.33333333,21 L18.6666667,21 C19.95,21 21,19.95 21,18.6666667 L21,18.6666667 Z M6.4166667,12.25 L9.3333333,15.7616667 L13.4166667,10.5 L18.6666667,17.5 L2.33333333,17.5 L6.4166667,12.25 L6.4166667,12.25 Z" />
  </svg>
);

export default Image;
