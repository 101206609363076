import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const DoubleArrowRight = (): JSX.Element => {
  const id = guid();

  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M14,9 L15.41,10.41 L10.83,15 L15.41,19.59 L14,21 L8,15 L14,9 Z M21,9 L22.41,10.41 L17.83,15 L22.41,19.59 L21,21 L15,15 L21,9 Z" id={id} fill="#B3B3B3" transform="translate(15.205000, 15.000000) scale(-1, 1) translate(-15.205000, -15.000000)" />
      </g>
    </svg>
  );
};

export default DoubleArrowRight;
