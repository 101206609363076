import React from 'react';

import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

const DragHotspot: React.FC = () => (
  <div className={styles.dragHotspot}>
    <span className={styles.hint}>{intlGet('Artboard.LayoutHotspot', 'Hint')}</span>
  </div>
);

export default DragHotspot;
