import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_SURFACE: null,
    DELETE_SURFACES: null,
    DELETE_SURFACE_REQUEST: null,
    DUPLICATE_SURFACE: null,
    REFRESH_THUMBNAIL: null,
    SET_SCREEN_FORMAT: null,
    SET_SURFACES: null,
    SET_SURFACE_SETTINGS: null,
    UPDATE_SURFACE: null,
    UPDATE_SURFACES: null,
    UPDATE_SURFACE_TYPE: null,
  },
  'Surfaces',
);
