import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProjectDetails from 'components/ModalWindows/ProjectDetails';
import { IProjectDetailsActions, IProjectDetailsOwnProps, IProjectDetailsSelectors } from 'components/ModalWindows/ProjectDetails/models';
import { hideModal } from 'containers/ModalManager/actions';
import { getProjectDetails } from 'containers/Project/actions';
import { areDetailsLoading } from 'containers/Project/selectors';
import { rootDocument } from 'containers/RootDocument/selectors';

const mapStateToProps = createStructuredSelector({
  rootDocument,
  areDetailsLoading,
});

const mapDispatchToProps = {
  hideModal,
  getProjectDetails,
};

export default connect<IProjectDetailsSelectors, IProjectDetailsActions, IProjectDetailsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetails);
