import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, select, spawn } from 'redux-saga/effects';
import guid from 'uuid';

import { DocumentType, DocumentsSource, EntityType, MethodName, ProcessType, ProjectPanelTab } from 'const';
import { lockProjectContent, toggleUpdatedDocumentsDisplaying, unlockProjectContent } from 'containers/App/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { deleteUploadingDocumentId } from 'containers/Project/actions';
import { currentCountry, currentLanguage, currentProduct } from 'containers/Project/selectors';
import { setActiveTab, setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { rootDocumentId as rootDocumentIdSelector } from 'containers/RootDocument/selectors';
import { imageFactory } from 'factories/document/imageFactory';
import * as Models from 'models';
import { uploadImage } from 'services/api';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { isImage } from 'utils/entityType';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';
import { getValue } from 'utils/getter';
import { deleteDocuments } from '../actions';
import { Action } from '../models';
import { getDocumentDuplicates } from '../services/getDocumentDuplicates';
import { replaceDocumentWithDuplicate } from '../services/replaceDocumentWithDuplicate';
import { uploadDocument } from '../services/uploadDocument';

export function* addImage({ payload }: Action.IAddImage): SagaIterator {
  let uploadingDocumentId = guid();
  let modalWindowId: string;

  const logId = logger.performanceStart();
  const methodName = MethodName.CREATE_IMAGE;

  try {
    const {
      imageDataToUpload: {
        documentName,
        subtype,
        classification,
        imageFiles: {
          source: sourceFile,
          low: lowRenditionFile,
        },
      },
    } = payload;
    ({ modalWindowId } = payload);

    const rootDocumentId: ReturnTypeSaga<typeof rootDocumentIdSelector> = yield select(rootDocumentIdSelector);
    const language = (yield select(currentLanguage)).toJS() as string[];
    const country = (yield select(currentCountry)).toJS() as string[];
    const product = (yield select(currentProduct)).toJS() as string[];
    const imageInternalInfo: ReturnTypeSaga<typeof getImageInternalInfo> = yield call(getImageInternalInfo, lowRenditionFile);

    yield put(lockProjectContent(ProcessType.DUPLICATES_CHECKING));

    const { documentDuplicates, documentLinksByDocumentNumber }: ReturnTypeSaga<typeof getDocumentDuplicates> = yield call(
      getDocumentDuplicates,
      sourceFile,
      _.first(language),
      _.first(country),
    );
    const imagesDuplicates = _.filter((documentDuplicates as Models.Image[]), (document) => {
      const type = getValue(document, 'type');

      return type === DocumentType.COMPONENT && isImage(document);
    });

    if (!Array.isArray(imagesDuplicates) || imagesDuplicates.length === 0) {
      yield put(batchActions([
        unlockProjectContent(),
        hideModal(modalWindowId),
        setAssetIdToScroll(uploadingDocumentId),
        setActiveTab(ProjectPanelTab.ASSETS),
        toggleUpdatedDocumentsDisplaying(true, EntityType.IMAGE),
      ]));

      const newImage: ReturnTypeSaga<typeof imageFactory> = yield call(
        imageFactory,
        {
          id: uploadingDocumentId,
          name: documentName,
          subtype,
          language,
          country,
          product,
          classification,
          _internalInfo: imageInternalInfo,
          documentSource: [DocumentsSource.COMPONENTS],
        },
      );

      const uploadNewDocument = async (): Promise<Models.Image> => {
        const { data: createdDocument } = await uploadImage(rootDocumentId, payload.imageDataToUpload, { language, country, product });
        createdDocument._internalInfo = imageInternalInfo;

        return createdDocument;
      };

      yield* uploadDocument(newImage, uploadNewDocument, modalWindowId);
    } else {
      uploadingDocumentId = yield* replaceDocumentWithDuplicate(
        uploadingDocumentId,
        imagesDuplicates,
        documentLinksByDocumentNumber,
        modalWindowId,
      );
    }

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    return uploadingDocumentId;
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Documents.addImage', 'AddImage');
    yield put(deleteDocuments([uploadingDocumentId]));
  } finally {
    yield put(batchActions([
      deleteUploadingDocumentId(uploadingDocumentId),
      unlockProjectContent(),
      hideModal(modalWindowId),
    ]));
  }
}
