import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';

import RadioButtonList from 'components/ModalWindows/Shared/RadioButtonList';
import { ReusableLayoutChangeType } from 'const';
import { intlGet } from 'utils/intlGet';
import { isGroupLayout as isGrouped } from 'utils/layouts/isGroupLayout';
import { EditReusableLayoutProps, LabelTexts } from './models';
import styles from './styles.module.scss';

const EditReusableLayout: React.FunctionComponent<EditReusableLayoutProps> = (props) => {
  const {
    groupLayoutDocuments,
    hideModal,
    layouts,
    modalWindowId,
    options,
    reusableLayoutDocuments,
  } = props;
  const isGroupLayout = options.get('isGroupLayout');
  const documentId = options.get('documentId');
  const layoutId = options.get('layoutId');

  const layoutDocument = isGroupLayout ? groupLayoutDocuments.get(documentId) : reusableLayoutDocuments.get(documentId);

  const isInsideOfGroupLayout = !isGroupLayout && !!layouts.find(layout => (
    isGrouped(layout) && layout.get('layoutIds').includes(layoutId)
  ));

  const availableOptions: ReusableLayoutChangeType[] = (isGroupLayout || isInsideOfGroupLayout)
    ? [ReusableLayoutChangeType.SAVE_AS_NEW, ReusableLayoutChangeType.UPDATE_EXISTING]
    : [ReusableLayoutChangeType.SAVE_AS_NEW, ReusableLayoutChangeType.UPDATE_EXISTING, ReusableLayoutChangeType.UNLINK];

  const name = layoutDocument.get('name');
  const number = layoutDocument.get('number');
  const status = layoutDocument.get('status');
  const product = layoutDocument.get('product');
  const productName = product && product.first();

  const editModalKeyByLayoutType = options.get('isGroupLayout') ? 'EditGroupReusableLayout' : 'EditReusableLayout';

  const TextByChangeType: Record<ReusableLayoutChangeType, LabelTexts> = {
    [ReusableLayoutChangeType.SAVE_AS_NEW]: {
      title: () => intlGet(`${editModalKeyByLayoutType}.Option`, 'SaveNew'),
      description: () => intlGet(`${editModalKeyByLayoutType}.Description`, 'SaveNew'),
    },
    [ReusableLayoutChangeType.UNLINK]: {
      title: () => intlGet(`${editModalKeyByLayoutType}.Option`, 'Unlink'),
      description: () => intlGet(`${editModalKeyByLayoutType}.Description`, 'Unlink'),
    },
    [ReusableLayoutChangeType.UPDATE_EXISTING]: {
      title: () => intlGet(`${editModalKeyByLayoutType}.Option`, 'UpdateExisting'),
      description: () => (
        <>
          <span className={styles.bold}>{`${intlGet('EditReusableLayout.Description', 'Note')} `}</span>
          {intlGet('EditReusableLayout.Description', 'UpdateExisting')}
        </>
      ),
    },
  };

  const [selectedChangeType, setSelectedChangeType] = React.useState(ReusableLayoutChangeType.SAVE_AS_NEW);

  const onSaveClick: React.MouseEventHandler = () => {
    hideModal(modalWindowId, selectedChangeType);
  };

  const onCancelClick: React.MouseEventHandler = () => {
    hideModal(modalWindowId);
  };

  const isDisabled = (changeType: ReusableLayoutChangeType): boolean => {
    return changeType === ReusableLayoutChangeType.UPDATE_EXISTING
      ? !options.get('allowUpdateExisting')
      : false;
  };

  const getLabel = (changeType: ReusableLayoutChangeType): JSX.Element => {
    const { title, description } = TextByChangeType[changeType];

    return (
      <div className={classNames(styles.label, { [styles.disabled]: isDisabled(changeType) })}>
        {title()}
        {<div className={styles.labelDescription}>{description()}</div>}
      </div>
    );
  };

  return (
    <div className={styles.EditReusableLayout}>
      <header>{intlGet(editModalKeyByLayoutType, 'Title')}</header>
      <div className={styles.metadata}>
        <div className={styles.title}>{name}</div>
        <div className={styles.info}>
          {/* product and status can be absent, e.g. in components from ASM7 */}
          {status && <span className={styles.status}>{ status.toUpperCase() }</span>}
          <span className={styles.number}>{number}</span>
          {productName && <span className={styles.productName}>{productName}</span>}
        </div>
      </div>
      <section>
        <RadioButtonList<ReusableLayoutChangeType>
          id={modalWindowId}
          options={availableOptions}
          onOptionChange={setSelectedChangeType}
          activeOption={selectedChangeType}
          isDisabled={isDisabled}
          getOptionLabel={getLabel}
        />
      </section>
      <footer>
        <Button onClick={onCancelClick} variant="secondary">{intlGet('EditReusableLayout.Button', 'Cancel')}</Button>
        <Button onClick={onSaveClick} variant="primary">{intlGet('EditReusableLayout.Button', 'Save')}</Button>
      </footer>
    </div>
  );
};

export default EditReusableLayout;
