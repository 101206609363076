import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CitationList from 'components/CitationList';
import { ICitationListActions, ICitationListOwnProps, ICitationListSelectors } from 'components/CitationList/models';
import {
  addSelectedAssetPanelComponent,
  removeSelectedAssetPanelComponent,
  toggleDragState,
  toggleUpdatedDocumentsDisplaying,
} from 'containers/App/actions';
import { showUpdatedReferences } from 'containers/App/selectors';
import { deleteComponent } from 'containers/Documents/actions';
import { showModal } from 'containers/ModalManager/actions';
import { documentIdsAreBeingUploaded } from 'containers/Project/selectors';
import { setAssetIdToScroll, setSelectedCitation } from 'containers/ProjectPanel/actions';
import { assetIdToScroll } from 'containers/ProjectPanel/selectors';

const mapStateToProps = createStructuredSelector({
  showUpdatedReferences,
  assetIdToScroll,
  documentIdsAreBeingUploaded,
});

const mapDispatchToProps = {
  deleteComponent,
  setSelectedCitation,
  addSelectedAssetPanelComponent,
  removeSelectedAssetPanelComponent,
  showModal,
  toggleDragState,
  toggleUpdatedDocumentsDisplaying,
  setAssetIdToScroll,
};

export default connect<ICitationListSelectors, ICitationListActions, ICitationListOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CitationList);
