import * as Models from 'models';
import { isModuleBundle } from 'utils/entityType';

export function isAssetsCollectionDraggable(document: Models.AssetsCollectionMap): boolean {
  if (isModuleBundle(document)) {
    return false;
  }

  const screenIds = (document as Models.AssetsCollectionMap).getIn(['entities', 'screenIds']);

  return !!screenIds && screenIds.size === 1;
}
