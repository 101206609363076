import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { createLayers } from 'utils/layers';
import { ensureLayoutOnSections } from 'utils/layouts/ensureLayoutOnSections';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { deleteLayoutsFromArtboardsByLayoutIds } from 'utils/reusableLayouts/deleteLayoutsFromArtboards';

export type ResetLayoutsWithoutDocumentArgs = { assets: Models.ProjectAssets };

export const resetLayoutsWithoutDocument = <T extends ResetLayoutsWithoutDocumentArgs>({ assets }: T): void => {
  const {
    artboards,
    documents,
    layouts,
    project,
    relations,
    sections,
    surfaces: screens,
    masterScreenData,
  } = assets;
  const { screenDefinitions } = masterScreenData || assets;
  const activeLayer = _.get(project, 'activeLayer', Layer.ORIGINAL);
  const projectType = _.get(masterScreenData, 'projectType') || _.get(project, 'projectType');

  const layoutIdsToDelete: string[] = [];

  _.forEach(layouts, (layout) => {
    const { documentId: layeredDocumentId } = layout;

    if (_.every(layeredDocumentId, _.isNil)) {
      isGroupLayout(layout) && layoutIdsToDelete.push(layout.id);

      return;
    }

    if (_.every(layeredDocumentId, documentId => !documentId || !documents[documentId])) {
      layout.documentId = createLayers();
      isGroupLayout(layout) && layoutIdsToDelete.push(layout.id);
    }
  });

  // GRL must have a documentId, otherwise we must delete it at all
  deleteLayoutsFromArtboardsByLayoutIds({ layoutIdsToDelete, layouts, artboards, relations, documents, activeLayer });
  ensureLayoutOnSections({ screens, artboards, layouts, sections, screenDefinitions, relations, activeLayer, projectType });
};
