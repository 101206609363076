import _ from 'lodash';

import { isAbbreviatioinsListLayout } from 'modules/Abbreviations/utils/isAbbreviatioinsListLayout';
import * as Models from 'models';
import { getValue } from 'utils/getter';
import { isImmutable, toJS } from 'utils/immutable';
import { isObjectLayered } from 'utils/layers';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isGroupLayout } from './isGroupLayout';
import { isPlainLayout } from './isPlainLayout';
import { isReferenceCitationElement } from './isReferenceCitationElement';
import { isSpacerElement } from './isSpacerElement';

export function isEmptyLayout(
  layout: Models.LayoutMap | Models.Layout | Models.LayeredLayoutMap | Models.LayeredLayout,
  relations: Models.RelationsMap | Models.Relations | Models.LayeredRelationsMap | Models.LayeredRelations,
  documents: Models.DocumentsMap | Models.Documents,
): boolean {
  // relation of Spacer or ReferenceCitationElement never has documentId however they should not be considered as empty layouts
  if (isSpacerElement(layout) || isReferenceCitationElement(layout) || isAbbreviatioinsListLayout(layout)) {
    return false;
  }

  const flattenedRelations = getFlattenedRelations(layout as Models.Layout, relations as Models.RelationsMap | Models.Relations, true);

  const everyPredicate = (
    relation: Models.RegularRelationMap | Models.RegularRelation | Models.LayeredRegularRelationMap | Models.LayeredRegularRelation,
  ): boolean => {
    const documentId: string | Models.Layers<string> = toJS(getValue(relation as Models.LayeredRegularRelation, 'documentId'));

    return isObjectLayered(documentId)
      ? !_.some(documentId, id => !!getValue(documents, id))
      : !getValue(documents, documentId);
  };

  return isImmutable(flattenedRelations)
    ? flattenedRelations.every(everyPredicate)
    : _.every(flattenedRelations, everyPredicate);
}

export function isGroupLayoutEmpty(
  groupLayout: Models.GroupLayoutMap | Models.GroupLayout,
  layouts: Models.CombinedLayouts | Models.CombinedLayoutsMap,
  relations: Models.RelationsMap | Models.Relations | Models.LayeredRelationsMap | Models.LayeredRelations,
  documents: Models.DocumentsMap | Models.Documents,
): boolean {
  const layoutIds = getValue(groupLayout, 'layoutIds');

  return layoutIds.every((layoutId) => {
    const layout = getValue(layouts, layoutId) as Models.Layout | Models.LayoutMap;

    return isEmptyLayout(layout, relations, documents);
  });
}

export function isCombinedLayoutEmpty(
  layout: Models.CombinedLayoutMap | Models.CombinedLayout,
  layouts: Models.CombinedLayouts | Models.CombinedLayoutsMap,
  relations: Models.RelationsMap | Models.Relations | Models.LayeredRelationsMap | Models.LayeredRelations,
  documents: Models.DocumentsMap | Models.Documents,
) {
  return isGroupLayout(layout)
    ? isGroupLayoutEmpty(layout, layouts, relations, documents)
    : isEmptyLayout(layout as Models.LayoutMap | Models.Layout, relations, documents);
}

export function isEmptyPlainLayout(
  layout: Models.CombinedLayoutMap | Models.CombinedLayout | Models.LayeredCombinedLayoutMap | Models.LayeredCombinedLayout,
  relations: Models.RelationsMap | Models.Relations | Models.LayeredRelationsMap | Models.LayeredRelations,
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
): layout is Models.Layout | Models.LayoutMap {
  return isPlainLayout(layout) && isEmptyLayout(layout, relations, documents as Models.Documents);
}
