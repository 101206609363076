import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { DraggableCore } from 'react-draggable';

import { ResizerDirection } from 'const';
import { ResizerProps } from './models';
import styles from './styles.module.scss';

const Resizer: React.FunctionComponent<ResizerProps> = (props) => {
  const {
    allowVisibilityChange,
    direction,
    hintMessage,
    onResize,
    onResizeStart,
    onResizeStop,
    scale,
  } = props;

  const resizerRef = React.useRef<HTMLDivElement>();
  const [active, setActive] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [hintCoord, setHintCoordState] = React.useState();

  const setHintYCoord = (clientY: number): void => {
    const { top, height } = resizerRef.current.getBoundingClientRect();
    const heightScale = height / scale;
    const topScale = (clientY - top) / scale;
    setHintCoordState(_.round(_.clamp(topScale, 0, heightScale)));
  };

  const setHintXCoord = (clientX: number): void => {
    const { left, width } = resizerRef.current.getBoundingClientRect();
    const widthScale = width / scale;
    const leftScale = (clientX - left) / scale;
    setHintCoordState(_.round(_.clamp(leftScale, 0, widthScale)));
  };

  const setHintCoord = (clientX: number, clientY: number) => {
    direction === ResizerDirection.VERTICAL
      ? setHintYCoord(clientY)
      : setHintXCoord(clientX);
  };

  const showResizer = (): void => {
    allowVisibilityChange && setVisible(true);
  };
  const hideResizer = (): void => {
    allowVisibilityChange && setVisible(false);
  };

  const onDrag = ({ clientX, clientY }: DragEvent): void => {
    setHintCoord(clientX, clientY);
    onResize({ clientX, clientY });
  };

  const onStart = ({ clientX, clientY }: DragEvent): void => {
    setHintCoord(clientX, clientY);
    setActive(true);
    onResizeStart();
  };

  const onStop = (): void => {
    setActive(false);
    setVisible(false);
    onResizeStop();
  };

  return (
    <DraggableCore
      onDrag={onDrag}
      onStop={onStop}
      onStart={onStart}
    >
      <div
        className={classNames(styles.Resizer, {
          [styles.horizontal]: direction === ResizerDirection.HORIZONTAL,
          [styles.visible]: visible,
          [styles.active]: active,
        })}
        onMouseEnter={showResizer}
        onMouseLeave={hideResizer}
        ref={resizerRef}
      >
        {
          active && hintMessage &&
          <div
            className={classNames(styles.hint)}
            style={
              direction === ResizerDirection.VERTICAL
                ? { top: hintCoord }
                : { left: hintCoord }
            }
          >
            {hintMessage}
          </div>
        }
      </div>
    </DraggableCore>
  );
};

export default Resizer;
