import React from 'react';
/* eslint-disable max-len */

const Responsive = (): JSX.Element => (
  <svg width="24px" height="16px" viewBox="0 0 24 16">
    <path d="M4,2 L22,2 L22,0 L4,0 C2.9,0 2,0.9 2,2 L2,13 L0,13 L0,16 L14,16 L14,13 L4,13 L4,2 Z M23,4 L17,4 C16.45,4 16,4.45 16,5 L16,15 C16,15.55 16.45,16 17,16 L23,16 C23.55,16 24,15.55 24,15 L24,5 C24,4.45 23.55,4 23,4 Z M22,13 L18,13 L18,6 L22,6 L22,13 Z" />
  </svg>
);

export default Responsive;
