import React from 'react';

import { SubtitleProps } from './models';
import styles from './styles.module.scss';

const Subtitle: React.FunctionComponent<SubtitleProps> = (props) => {
  const { title = '' } = props;

  return (
    <>
      {title && <div className={styles.title}>{title}</div>}
    </>
  );
};

export default Subtitle;
