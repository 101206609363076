import React from 'react';

const ReferenceCitationElement = (): JSX.Element => (
  <svg viewBox="0 0 96 96">
    <defs>
      <rect id="path-ref-element-1" x="10" y="33" width="76" height="33" />
      <mask id="mask-ref-element-1" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="76" height="33" fill="white">
        <use href="#path-ref-element-1" />
      </mask>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Edit-Citation" transform="translate(-1103.000000, -916.000000)">
        <g id="Group-2" transform="translate(1103.000000, 916.000000)">
          <g id="Group" transform="translate(19.000000, 42.000000)" fill="#4A4A4A">
            <rect id="Rectangle" x="0" y="0" width="3" height="3" />
            <rect id="Rectangle" x="0" y="6" width="3" height="3" />
            <rect id="Rectangle" x="0" y="12" width="3" height="3" />
            <rect id="Rectangle" x="5" y="0" width="53" height="3" />
            <rect id="Rectangle" x="5" y="6" width="53" height="3" />
            <rect id="Rectangle" x="5" y="12" width="53" height="3" />
          </g>
          <use id="Rectangle" stroke="#E0E0E0" mask="url(#mask-ref-element-1)" strokeWidth="2" strokeDasharray="2,2" href="#path-ref-element-1" />
        </g>
      </g>
    </g>
  </svg>
);

export default ReferenceCitationElement;
