import React from 'react';
/* eslint-disable max-len */

const HorizontalAlignmentRight = (): JSX.Element => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" style={{ transform: 'scale(-1,1)' }}>
    <defs>
      <path d="M6,2 L8,2 L8,20 L6,20 L6,2 Z M11,8 L23,8 C23.5522847,8 24,8.44771525 24,9 L24,13 C24,13.5522847 23.5522847,14 23,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,9 C10,8.44771525 10.4477153,8 11,8 Z" id="path-horizontal-alignment-right" />
    </defs>
    <g id="Images_HorizontalAlignmentRight" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Images_Horizontal_Alignment_Right" transform="translate(-156.000000, -201.000000)">
        <g id="Widget_HorizontalAlignmentRight" transform="translate(0.000000, 180.000000)">
          <g id="Icons/HorizontalAlignmentRight" transform="translate(150.000000, 15.000000)">
            <g id="ToolbarIcons/HorizontalAlignmentRight" transform="translate(0.000000, 4.000000)">
              <rect id="HorizontalAlignmentRightRectangle" stroke="#FFFFFF" opacity="0" x="0.5" y="-3.5" width="29" height="29" />
              <mask id="mask-horizontal-alignment-right" fill="white">
                <use xlinkHref="#path-horizontal-alignment-right" />
              </mask>
              <use id="MaskHorizontalAlignmentRight" fill="#FFFFFF" xlinkHref="#path-horizontal-alignment-right" />
              <g id="Colors/Orange_HorizontalAlignmentRight" mask="url(#mask-horizontal-alignment-right)" fill="#F9AD3D">
                <g transform="translate(4.000000, 0.000000)" id="OrangeHorizontalAlignmentRight">
                  <rect x="0" y="0" width="22" height="22" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HorizontalAlignmentRight;
