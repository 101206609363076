import _ from 'lodash';
import guid from 'uuid';

import { EntityType, LayoutType } from 'const';
import { createLayoutStyles } from 'factories/style';
import * as Models from 'models';
import { createLayers } from 'utils/layers';

// NOTE: By default layout consist of a column with a single row. This map represents entire content of that row.
export const LayoutColumnsMap: Record<Exclude<LayoutType, LayoutType.GROUP_LAYOUT>, EntityType[] | EntityType[][]> = {
  [LayoutType.FULL_WIDTH_IMAGE]: [
    EntityType.IMAGE,
  ],
  [LayoutType.TEXT_ONE_COLUMN]: [
    EntityType.TEXT,
  ],
  [LayoutType.TEXT_TWO_COLUMN]: [
    EntityType.TEXT,
    EntityType.TEXT,
  ],
  [LayoutType.TEXT_THREE_COLUMN]: [
    EntityType.TEXT,
    EntityType.TEXT,
    EntityType.TEXT,
  ],
  [LayoutType.TEXT_FOUR_COLUMN]: [
    EntityType.TEXT,
    EntityType.TEXT,
    EntityType.TEXT,
    EntityType.TEXT,
  ],
  [LayoutType.IMAGE_TEXT_LEFT]: [
    EntityType.TEXT,
    EntityType.IMAGE,
  ],
  [LayoutType.IMAGE_TEXT_RIGHT]: [
    EntityType.IMAGE,
    EntityType.TEXT,
  ],
  [LayoutType.TWO_IMAGE_AND_TEXT]: [
    [EntityType.IMAGE, EntityType.TEXT],
    [EntityType.IMAGE, EntityType.TEXT],
  ],
  [LayoutType.THREE_IMAGE_AND_TEXT]: [
    [EntityType.IMAGE, EntityType.TEXT],
    [EntityType.IMAGE, EntityType.TEXT],
    [EntityType.IMAGE, EntityType.TEXT],
  ],
  [LayoutType.FOUR_IMAGE_AND_TEXT]: [
    [EntityType.IMAGE, EntityType.TEXT],
    [EntityType.IMAGE, EntityType.TEXT],
    [EntityType.IMAGE, EntityType.TEXT],
    [EntityType.IMAGE, EntityType.TEXT],
  ],
  [LayoutType.SPACER]: [
    EntityType.SPACER,
  ],
  [LayoutType.REFERENCE_CITATION_ELEMENT]: [
    EntityType.REFERENCE_CITATION_ELEMENT,
  ],
  [LayoutType.ABBREVIATIONS_LIST]: [
    EntityType.ABBREVIATIONS_LIST,
  ],
};

export const createBaseLayout = (layout: Partial<Models.BaseLayout> = {}): Models.BaseLayout => {
  const {
    id,
    documentId = null,
    section = null,
    type = null,
  } = layout;

  return {
    id: id || guid(),
    documentId,
    section,
    type,
  };
};

export const createLayeredBaseLayout = (layout: Partial<Models.LayeredBaseLayout> = {}): Models.LayeredBaseLayout => {
  const {
    id,
    documentId = createLayers<string>(),
    section = null,
    type = null,
  } = layout;

  return {
    id: id || guid(),
    documentId,
    section,
    type,
  };
};

export const createLayout = (layout: Partial<Models.Layout> = {}): Models.Layout => {
  const {
    relationId = guid(),
    styles,
    type = LayoutType.TEXT_ONE_COLUMN,
  } = layout;

  return {
    ...createBaseLayout(layout),
    styles: createLayoutStyles(styles || {}, type),
    relationId,
    type,
  };
};

export const createLayeredLayout = (layout: Partial<Models.LayeredLayout> = {}): Models.LayeredLayout => {
  const {
    relationId = guid(),
    styles,
    type = LayoutType.TEXT_ONE_COLUMN,
  } = layout;

  return {
    ...createLayeredBaseLayout(layout),
    styles: createLayoutStyles(styles || {}, type),
    relationId,
    type,
  };
};

export const createLayeredGroupLayout = (layout: Partial<Models.LayeredGroupLayout> = {}): Models.LayeredGroupLayout => {
  const { layoutIds = [], styles = {} as Models.GroupLayoutStyles } = layout;
  const { height = null } = styles;

  return {
    ...createLayeredBaseLayout(layout),
    type: LayoutType.GROUP_LAYOUT,
    layoutIds,
    styles: {
      height,
    },
  };
};

export const createGroupLayout = (layout: Partial<Models.GroupLayout> = {}): Models.GroupLayout => {
  const { layoutIds = [], styles = {} as Models.GroupLayoutStyles } = layout;
  const { height = null } = styles;

  return {
    ...createBaseLayout(layout),
    type: LayoutType.GROUP_LAYOUT,
    layoutIds,
    styles: {
      height,
    },
  };
};

export function createCombinedLayout(layout: Partial<Models.CombinedLayout> = {}): Models.CombinedLayout {
  switch (layout.type) {
    case LayoutType.GROUP_LAYOUT: return createGroupLayout(layout as Partial<Models.GroupLayout>);
    default: return createLayout(layout as Partial<Models.Layout>);
  }
}

export function createLayeredCombinedLayout(layout: Partial<Models.LayeredCombinedLayout> = {}): Models.LayeredCombinedLayout {
  switch (layout.type) {
    case LayoutType.GROUP_LAYOUT: return createLayeredGroupLayout(layout as Partial<Models.LayeredGroupLayout>);
    default: return createLayeredLayout(layout as Partial<Models.LayeredLayout>);
  }
}

export function createLayeredLayouts(): Models.LayeredLayouts;
export function createLayeredLayouts(layouts?: Models.LayeredLayouts | Partial<Models.LayeredLayout>[]): Models.LayeredLayouts;
export function createLayeredLayouts(layouts?: Models.LayeredGroupLayouts | Partial<Models.LayeredGroupLayout>[]): Models.LayeredGroupLayouts;
export function createLayeredLayouts(
  layouts?: Models.LayeredCombinedLayouts | Partial<Models.LayeredCombinedLayout>[],
): Models.LayeredCombinedLayouts;
export function createLayeredLayouts(
  layouts: Models.LayeredCombinedLayouts | Partial<Models.LayeredCombinedLayout>[] = null,
): Models.LayeredCombinedLayouts {
  if (!layouts) {
    const defaultLayout = createLayeredCombinedLayout();

    return { [defaultLayout.id]: defaultLayout };
  }

  return _.reduce(
    layouts,
    (layouts, layout: Models.LayeredCombinedLayout) => {
      const createdLayout = createLayeredCombinedLayout(layout);
      layouts[createdLayout.id] = createdLayout;

      return layouts;
    },
    {} as Models.LayeredCombinedLayouts,
  );
}
