import React from 'react';
import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import ColorPicker from 'components/Toolbar/Dropdowns/ColorPicker';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { intlGet } from 'utils/intlGet';
import { getBackgroundColor } from 'utils/styles';
import { FillColorProps } from './models';
import styles from './styles.module.scss';

const FillColor: React.FunctionComponent<FillColorProps> = (props) => {
  const { activeColor, toggleColor, gradient, toggleGradient, opacity, toggleOpacity, returnFocusToEditor } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      preventBlur={false}
      ref={control}
      onToggle={toggleDropdown}
      size="sm-md"
      state={state}
      title={Title.BACKGROUND_COLOR}
      type={IconType.TOOLBAR_FILL_COLOR}
    >
      <div className={styles.FillColor} style={getBackgroundColor(activeColor)} />
      {
        isOpen &&
        <ColorPicker
          activeColor={activeColor}
          toggleColor={toggleColor}
          gradient={gradient}
          toggleGradient={toggleGradient}
          opacity={opacity}
          toggleOpacity={toggleOpacity}
          title={intlGet('EditorToolbar.BackgroundColorDropdown', 'Title')}
        />
      }
    </Control>
  );
};

export default FillColor;
