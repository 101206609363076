import { FontDecoration } from 'models';

export const createFontDecoration = ({
  bold = false,
  italic = false,
  underline = false,
} = {} as Partial<FontDecoration>): FontDecoration => ({
  bold,
  italic,
  underline,
});
