import { ProjectPanelSection, ProjectPanelTab } from 'const';
import { ActionTypes } from './constants';
import * as Models from './models';

export const setSelectedCitation: Models.ActionCreator.SetSelectedCitation = citation => ({
  type: ActionTypes.SET_SELECTED_CITATION,
  payload: {
    citation,
  },
});

export const setSelectedTextComponent: Models.ActionCreator.SetSelectedTextComponent = textComponent => ({
  type: ActionTypes.SET_SELECTED_TEXT_COMPONENT,
  payload: {
    textComponent,
  },
});

export const setContentFilter: Models.ActionCreator.SetContentFilter = (contentFilter: string[]) => ({
  type: ActionTypes.SET_CONTENT_FILTER,
  payload: {
    contentFilter,
  },
});

export const deleteSelectedCitation: Models.ActionCreator.DeleteSelectedCitation = () => ({
  type: ActionTypes.DELETE_SELECTED_CITATION,
  payload: {},
});

export const setAssetIdToScroll: Models.ActionCreator.SetAssetIdToScroll = assetIdToScroll => ({
  type: ActionTypes.SET_ASSET_ID_TO_SCROLL,
  payload: {
    assetIdToScroll,
  },
});

export const setActiveTab: Models.ActionCreator.SetActiveTab = (activeTab: ProjectPanelTab) => ({
  type: ActionTypes.SET_ACTIVE_TAB,
  payload: {
    activeTab,
  },
});

export const addActiveSection: Models.ActionCreator.AddActiveSection = (section: ProjectPanelSection) => ({
  type: ActionTypes.ADD_ACTIVE_SECTION,
  payload: {
    section,
  },
});

export const toggleSection: Models.ActionCreator.ToggleSection = (section: ProjectPanelSection) => ({
  type: ActionTypes.TOGGLE_SECTION,
  payload: {
    section,
  },
});
