import Editor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import { compositeDecorator } from 'modules/draftjs/decorators';
import React, { forwardRef } from 'react';
import * as Constants from 'const';
import * as editorUtils from 'utils/editor';

type Props = {
  placeholder?: string;
  editorState: Draft.EditorState;
  onEditorChange: (state: Draft.EditorState) => void;
};

export const ReferencesEditor = forwardRef<Editor, Props>((props, ref) => {
  const {
    placeholder,
    editorState,
    onEditorChange,
  } = props;

  return (
    <Editor
      editorState={editorState}
      decorators={[compositeDecorator]}
      plugins={Constants.DraftEditorPlugins}
      onChange={onEditorChange}
      placeholder={placeholder}
      ref={ref}
      customStyleFn={editorUtils.getReferenceCustomInlineStyle}
    />
  );
});
