import _ from 'lodash';

import { isAbbreviatioinsListRelation } from 'modules/Abbreviations/utils/isAbbreviatioinsListRelation';
import { createLayeredRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { isCallToAction, isReferenceCitationElementRelation, isSpacerRelation } from 'utils/entityType';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { getRelationStylesToBeKept } from 'utils/styles/getStylesToBeKept';

type Args = Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs | Models.SetActiveLayerArgs;

/**
 * Resets relations which don't have documents anymore
 * @param relations
 * @param documents
 */
export function resetRelationsWithoutDocument<T extends Args>({ assets }: T): void {
  const { relations, documents } = assets;
  const updatedRelations = {} as Models.LayeredRelations;

  _.forEach(relations, (relation) => {
    if (!isRegularRelation(relation)) {
      return;
    }

    const { documentId: layeredDocumentId, id } = relation;

    // ReferenceCitationElement and Spacer relations never have documentId. Corner case.
    if (
      _.some(layeredDocumentId, documentId => !!documents[documentId])
      || isReferenceCitationElementRelation(relation)
      || isSpacerRelation(relation)
      || isAbbreviatioinsListRelation(relation)
    ) {
      return;
    }

    // a call to action relation can't be without document id, in case we have such relation it should be reset including entity type
    const entityType = isCallToAction(relation) ? undefined : relation.entityType;
    const styles = getRelationStylesToBeKept(
      relation as Models.LayeredRegularRelation<Models.ImageRelationStyles | Models.CallToActionStyles | Models.TextRelationStyles>,
    );

    const updatedRelation = createLayeredRelation(
      { id, entityType, styles },
    );

    updatedRelations[updatedRelation.id] = updatedRelation;
  });

  _.assign(relations, updatedRelations);
}
