import classNames from 'classnames';
import React from 'react';
import { BrandColorGradientMap } from 'models';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = {
  gradient: BrandColorGradientMap | undefined;
  onClick: () => void;
};

const GradientSection: React.FunctionComponent<Props> = (props) => {
  const { gradient, onClick } = props;

  return (
    <div className={styles.gradientSection}>
      <button
        className={classNames(styles.gradientButton, { active: !!gradient })}
        onClick={onClick}
      >
        {intlGet('EditorToolbar.BackgroundColorDropdown', 'GradientTitle')}
      </button>
    </div>
  );
};

export default GradientSection;
