import _ from 'lodash';

import { Layer } from 'const';
import { createStyles } from 'factories/relationFactory';
import * as Models from 'models';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

export function prepareRelationsToSave(relations: Models.LayeredRelations, activeLayer: Layer): Models.Relations {
  return _.mapValues(
    relations,
    (relation) => {
      if (!isRegularRelation(relation)) {
        return relation;
      }

      const style = _.get(relation, ['styles', activeLayer]) || createStyles(undefined, relation.entityType);
      const documentId = _.get(relation, ['documentId', activeLayer], null);

      return { ...relation, styles: style, documentId } as Models.Relation;
    },
  );
}
