import Draft from 'draft-js';
import { applyEditorStateFontStylesForBrandStyles } from 'modules/draftjs';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor/brandStyle';
import { BrandProps } from './useBrandProps';
import useCell from './useCell';
import useEditor from './useEditor';
import useStyles from './useStyles';

type UseBrandStyleChange = {
  toggleBrandStyle: (
    brandStyle: Models.BrandStyleMap,
    editorState: Draft.EditorState,
    needToToggleAutoHeight: boolean,
    relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>,
    activeLayer: Constants.Layer,
  ) => Draft.EditorState;
  setBrandStyle: (style: Models.BrandStyleMap) => void;
};

export default function useBrandStyleChange(
  projectType: Constants.ProjectType,
  editorHook: ReturnType<typeof useEditor>,
  brandProps: BrandProps,
  cellHook: ReturnType<typeof useCell>,
  stylesHook: ReturnType<typeof useStyles>,
): UseBrandStyleChange {

  const brandPropsRef = useRef(brandProps);
  brandPropsRef.current = brandProps;

  const { setEditorStateAndOperations, returnFocusToEditor, getEditorState } = editorHook;
  const { props: { isAutoFitContent }, toggleAutoFitContent } = cellHook;
  const { setRawStyles } = stylesHook;

  const changeBrandStyle = useCallback((
    brandStyle: Models.BrandStyleMap,
    editorState: Draft.EditorState,
    needToToggleAutoHeight = true,
  ): Draft.EditorState => {
    const { colors, fonts } = brandPropsRef.current;
    const textStyles = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
    const {
      padding,
      verticalAlignment,
      backgroundColor,
    } = textStyles;
    let newEditorState = editorUtils.toggleBrandStyle(editorState, textStyles, colors, fonts);
    newEditorState = applyEditorStateFontStylesForBrandStyles(newEditorState, projectType, fonts);
    setEditorStateAndOperations(newEditorState);

    let brandStyleChanged = false;
    if (needToToggleAutoHeight) {
      if (!isAutoFitContent) {
        toggleAutoFitContent();
      }
    } else if (!isAutoFitContent) {
      brandStyleChanged = true;
    }
    setRawStyles({
      padding,
      verticalAlignment,
      backgroundColor,
      backgroundGradient: undefined, // TODO: should be refactored
      brandStyle,
      brandStyleChanged,
    });

    return newEditorState;
  }, [
    projectType,
    setEditorStateAndOperations, returnFocusToEditor,
    isAutoFitContent, toggleAutoFitContent,
    setRawStyles,
  ]);

  const toggleBrandStyle = useCallback((
    brandStyle: Models.BrandStyleMap, // to remove this parameter logic for DefaultTextBrandStyle should be updated
    editorState: Draft.EditorState,
    needToToggleAutoHeight,
    relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>,
    activeLayer: Constants.Layer,
  ) => {
    const state = changeBrandStyle(brandStyle, editorState, needToToggleAutoHeight);
    stylesHook.ensureStylesToBeKept(relation, activeLayer, brandPropsRef.current);

    return state;
  }, [changeBrandStyle]);

  const setBrandStyle = useCallback((style: Models.BrandStyleMap): void => {
    setTimeout(() => {
      returnFocusToEditor();
      changeBrandStyle(style, getEditorState());
    });
  }, [returnFocusToEditor, changeBrandStyle, getEditorState]);

  return {
    toggleBrandStyle,
    setBrandStyle,
  };
}
