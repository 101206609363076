import guid from 'uuid';

import { WEBSOCKET_BASE_URL } from 'const';

interface LaunchWebSocketResult {
  connectionId: string;
  responseHandler: () => Promise<string>;
  endPoint: string;
}


export async function launchWebSocket(): Promise<LaunchWebSocketResult> {
  if (!WEBSOCKET_BASE_URL) {
    throw new Error('unknown WEBSOCKET_BASE_URL');
  }

  const { href: webSocketUrl, hostname, pathname } = new URL(WEBSOCKET_BASE_URL);
  const ws = new WebSocket(webSocketUrl);

  let connectionId: string;

  try {
    const { data }: { data: string } = await new Promise(async (resolve, reject) => {
      const onOpen = cb => ws.onopen = cb;

      ws.onmessage = resolve;
      ws.onerror = ws.onclose = reject;

      await new Promise(onOpen);
      // need to send a message to get the id of the websocket connection
      ws.send(
        JSON.stringify({ action: 'sendMessage' }),
      );
    });

    ({ connectionId } = JSON.parse(data));
  } catch (error) {
    ws.close();
    throw error;
  }

  const responseHandlerPromise = new Promise<string>((resolve, reject) => {
    const pingToken = guid();
    const pingMessage = JSON.stringify({ action: 'sendMessage', connectionId, data: pingToken });
    const pingIntervalId = setInterval(() => ws.send(pingMessage), 60 * 1000);

    const safeExit = (method: typeof resolve | typeof reject, data?: any): void => {
      clearInterval(pingIntervalId);
      method(data);
      ws.close();
    };

    setTimeout(safeExit, 15 * 60 * 1000, reject, 'Websocket waiting time exceeded');

    ws.onmessage = ({ data }): void => {
      if (data === pingToken) {
        return;
      }

      const { data: responseS3Url }: { data: string } = JSON.parse(data);
      safeExit(resolve, responseS3Url);
    };
    ws.onclose = ws.onerror = safeExit.bind(null, reject);
  });

  return {
    connectionId,
    endPoint: `${hostname}${pathname}`,
    responseHandler: () => responseHandlerPromise,
  };
}
