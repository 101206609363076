import _ from 'lodash';
import { put, select } from 'redux-saga/effects';

import { ModalType } from 'const';
import * as documentsSelectors from 'containers/Documents/selectors';
import { showModal } from 'containers/ModalManager/actions';
import * as Models from 'models';
import { isGroupLayoutDocument, isReusableLayoutDocument } from 'utils/entityType';
import { intlGet } from 'utils/intlGet';

export function* showNotUpdatedLayouts(layoutIds: number[], isChangesReverted = false) {
  const documents = (yield select(documentsSelectors.documents)).toJS() as Models.CombinedDocuments;
  const isGroupedLayoutsNotUpdated = isLayoutsNotUpdated(layoutIds, documents, isGroupLayoutDocument);
  const isReusableLayoutsNotUpdated = isLayoutsNotUpdated(layoutIds, documents, isReusableLayoutDocument);

  const titleName = isGroupedLayoutsNotUpdated && isReusableLayoutsNotUpdated
    ? 'CantSaveReusableLayoutsAndGroupedReusableLayouts'
    : isGroupedLayoutsNotUpdated ? 'CantSaveGroupedReusableLayouts' : 'CantSaveReusableLayouts';

  const messageName = isGroupedLayoutsNotUpdated && isReusableLayoutsNotUpdated
    ? 'OneOrMoreReusableLayoutsAndGroupedReusableLayoutsCouldNotBeSaved'
    : isGroupedLayoutsNotUpdated ? 'OneOrMoreGroupedReusableLayoutsCouldNotBeSaved' : 'OneOrMoreReusableLayoutsCouldNotBeSaved';

  const changesReverted = isChangesReverted
    ? intlGet('Notification.Success', 'ReusableLayoutsAndGroupedReusableLayoutsChangesReverted')
    : '' ;

  const options: Models.NotificationWindowOptions = {
    title: intlGet('Notification', titleName),
    message: intlGet(
      'Notification.Success',
      messageName,
      { changesReverted },
    ),
  };

  yield put(showModal(ModalType.NOTIFICATION, options));
}

const isLayoutsNotUpdated = (
  layoutIds: number[],
  documents: Models.CombinedDocuments,
  predicate: (value: Models.CombinedDocument) => boolean,
): boolean => (
  _.some(documents, document => predicate(document) && layoutIds.includes((document as Models.CombinedLayoutDocument).documentId))
);
