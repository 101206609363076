import _ from 'lodash';

import * as Models from 'models';
import { isGroupLayoutDocument } from 'utils/entityType/isGroupLayoutDocument';

export function isGroupReusableLayoutEdited(
  document: Models.CombinedDocument,
  documents: Models.CombinedDocuments,
): boolean {
  return isGroupLayoutDocument(document) && (document.isEdited || _.some(document.entities.layouts, (documentId) => {
    return _.some(documents, { documentId, isEdited: true });
  }));
}
