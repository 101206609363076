export function downloadFileByUrl(url: string, fileName: string, needToOpenInNewTab = false): void {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  // This attribute only works for same-origin URLs
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
  tempLink.setAttribute('download', fileName);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined' || needToOpenInNewTab) {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}
