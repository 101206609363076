import React from 'react';
/* eslint-disable max-len */

const Menu = (): JSX.Element => (
  <svg width="4px" height="17px" viewBox="0 0 4 17">
    <defs>
      <path d="M15,11.0183338 C16.1,11.0183338 17,10.1168337 17,9.01500034 C17,7.91316697 16.1,7.01166693 15,7.01166693 C13.9,7.01166693 13,7.91316697 13,9.01500034 C13,10.1168337 13.9,11.0183338 15,11.0183338 L15,11.0183338 Z M15,13.0216672 C13.9,13.0216672 13,13.9231672 13,15.0250006 C13,16.1268339 13.9,17.028334 15,17.028334 C16.1,17.028334 17,16.1268339 17,15.0250006 C17,13.9231672 16.1,13.0216672 15,13.0216672 L15,13.0216672 Z M15,19.0316674 C13.9,19.0316674 13,19.9331674 13,21.0350008 C13,22.1368342 13.9,23.0383342 15,23.0383342 C16.1,23.0383342 17,22.1368342 17,21.0350008 C17,19.9331674 16.1,19.0316674 15,19.0316674 L15,19.0316674 Z" id="Menu-ID" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-231.000000, -291.000000)">
        <g transform="translate(-5.000000, 179.000000)">
          <g transform="translate(6.000000, 90.150000)">
            <g transform="translate(217.000000, 15.025000)">
              <g>
                <mask id="Menu-ID-mask" fill="white">
                  <use xlinkHref="#Menu-ID" />
                </mask>
                <use id="Mask" fill="#D8D8D8" xlinkHref="#Menu-ID" />
                <g mask="url(#Menu-ID-mask)" fill="#B3B3B3">
                  <rect x="0" y="0" width="30" height="30.0500011" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Menu;
