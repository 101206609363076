import React from 'react';

const CallToActionSmall = (): JSX.Element => (
  <svg viewBox="0 0 96 96">
    <defs>
      <rect id="path-call-to-action-small" x="0" y="1.54187774e-12" width="62" height="21.6060606" rx="3" />
      <filter id="filter-call-to-action-small" x="-11.3%" y="-23.1%" width="122.6%" height="164.8%" filterUnits="objectBoundingBox">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.230414117 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Add-a-citation-10" transform="translate(-1103.000000, -592.000000)">
        <g id="Group-4" transform="translate(1103.000000, 592.000000)">
          <g id="Group-3" transform="translate(16.000000, 35.696970)">
            <g id="Rectangle">
              <use fill="black" fillOpacity="1" filter="url(#filter-call-to-action-small)" href="#path-call-to-action-small" />
              <rect stroke="#D8D8D8" strokeWidth="0.5" fill="#FFFFFF" fillRule="evenodd" x="0.25" y="0.25" width="61.5" height="21.1060606" rx="3" />
            </g>
            <text id="Button" fontFamily="Roboto-Medium, Roboto" fontSize="7" fontWeight="400" letterSpacing="1.05" fill="#A4A4A4">
              <tspan x="17" y="12.3030303">Button</tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CallToActionSmall;
