import axios from 'axios';
import { API_BASE_URL, ResponseStatus } from 'const';
import * as Models from 'models';
import { CreateAbbreviationProps, TextAbbreviationDocuments } from './entities';

export async function getAbbreviations(rootDocumentId: number): Promise<TextAbbreviationDocuments> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/text-abbreviations`;
  const { data } = await axios.get<Models.Response<TextAbbreviationDocuments>>(url);

  if (data.responseStatus === ResponseStatus.SUCCESS) {
    return data.data;
  } else {
    throw new Error(data.error?.message);
  }
}

export async function createAbbreviation({ rootDocumentId, abbreviation }: CreateAbbreviationProps): Promise<string> {
  const { data } = await axios.post<Models.Response<number>>(`${API_BASE_URL}/projects/${rootDocumentId}/text-abbreviations`, abbreviation);

  if (data.responseStatus === ResponseStatus.SUCCESS) {
    return String(data.data);
  } else {
    throw new Error(data.error.message);
  }
}
