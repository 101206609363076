import _ from 'lodash';
import React from 'react';
import { Button, Form } from 'react-bootstrap';

import Checkbox from 'components/Checkbox';
import { UploadProjectType } from 'const';
import { intlGet } from 'utils/intlGet';
import { ProjectTypeSettings, UploadProjectProps, UploadProjectState } from './models';
import styles from './styles.module.scss';

const UploadProjectTypeSettings: Record<UploadProjectType, ProjectTypeSettings> = {
  [UploadProjectType.DRAFT]: {
    text: () => intlGet('UploadProjectWindow.Option', 'ReadyForReview'),
    completeTranslationDisabled: true,
  },
  [UploadProjectType.READY_FOR_REVIEW]: {
    text: () => intlGet('UploadProjectWindow.Option', 'Draft'),
    completeTranslationDisabled: false,
  },
};

export default class UploadProject extends React.PureComponent<UploadProjectProps, UploadProjectState> {
  readonly state: UploadProjectState = {
    selectedUploadProjectType: UploadProjectType.DRAFT,
    translationComplete: false,
  };

  private toggleTranslationComplete = (): void => {
    this.setState(prevState => ({
      translationComplete: !prevState.translationComplete,
    }));
  };

  private onUploadProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedUploadProjectType = event.target.name as UploadProjectType;
    const { completeTranslationDisabled } = UploadProjectTypeSettings[selectedUploadProjectType];

    this.setState(prevState => ({
      selectedUploadProjectType,
      translationComplete: !completeTranslationDisabled && prevState.translationComplete,
    }));
  };

  private uploadProject: React.MouseEventHandler = () => {
    const { hideModal, uploadToPromoMats } = this.props;
    const { selectedUploadProjectType, translationComplete } = this.state;

    uploadToPromoMats(selectedUploadProjectType, translationComplete);
    hideModal();
  };

  private getLabel = (UploadProjectType: UploadProjectType): JSX.Element => {
    const { text } = UploadProjectTypeSettings[UploadProjectType];

    return (
      <div className={styles.formCheckLabel}>{text()}</div>
    );
  };

  render() {
    const { selectedUploadProjectType, translationComplete } = this.state;
    const { hideModal, isProjectTranslatable } = this.props;

    return (
      <div className={styles.UploadProject}>
        <header>{intlGet('UploadProjectWindow', 'UploadToPromoMats')}</header>
        <section>
          <Form>
            <Form.Group>
              <Form.Label className={styles.formLabel}>{intlGet('UploadProjectWindow', 'SelectOption')}</Form.Label>
              {
                _.map(UploadProjectTypeSettings, (text, type: UploadProjectType) => {
                  return (
                    <Form.Check
                      type="radio"
                      key={type}
                      id={type}
                      label={this.getLabel(type)}
                      name={type}
                      checked={type === selectedUploadProjectType}
                      onChange={this.onUploadProjectTypeChange}
                      className={styles.formCheck}
                    />
                  );
                })
              }
            </Form.Group>
            {isProjectTranslatable && <Checkbox
              className={styles.checkbox}
              onChange={this.toggleTranslationComplete}
              checked={translationComplete}
              disabled={UploadProjectTypeSettings[selectedUploadProjectType].completeTranslationDisabled}
              label={intlGet('UploadProjectWindow.Translation', 'Complete')}
              comments={intlGet('UploadProjectWindow.Translation', 'Comments')}
            />}
          </Form>
        </section>
        <footer>
          <Button onClick={hideModal} variant="secondary">{intlGet('UploadProjectWindow.Button', 'Cancel')}</Button>
          <Button onClick={this.uploadProject} variant="primary">{intlGet('UploadProjectWindow.Button', 'Upload')}</Button>
        </footer>
      </div>
    );
  }
}
