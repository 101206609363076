import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import ImageMobileSettingsDropdown from 'components/Toolbar/Dropdowns/ImageMobileSettings';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { PreviewOptionName, ProjectOptionType, ProjectsConfig } from 'const';
import { useProjectType } from 'hooks/useProjectType';
import { ImageMobileSettingsControlProps } from './models';

const ImageMobileSettingsControl: React.FunctionComponent<ImageMobileSettingsControlProps> = (props) => {
  const {
    alignment,
    alignmentDisabled,
    className,
    controlState = ControlState,
    isDisabled,
    mobileSettingsApplied,
    returnFocusToEditor,
    priority,
    scale,
    mobileViewImage,
    toggleAlignment,
    toggleMobileSettingsApplied,
    toggleScale,
    toggleMobileViewImage,
    layoutId,
  } = props;

  const {
    control,
    isOpen,
    state,
    toggleDropdown,
  } = useControlWithDropdown({ priority, controlState, closeDropdownCallback: returnFocusToEditor });

  const projectType = useProjectType();
  const { defaultPreviewOption } = ProjectsConfig[projectType] || {} as ProjectOptionType;
  const isDefaultMobilePreview = defaultPreviewOption === PreviewOptionName.MOBILE;

  return (
    <Control
      className={className}
      controlState={controlState}
      disabled={isDisabled}
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={isDefaultMobilePreview ? Title.IMAGE_DESKTOP_SETTINGS : Title.IMAGE_MOBILE_SETTINGS}
      type={IconType.IMAGE_MOBILE_SETTINGS}
    >
      {isOpen &&
        <ImageMobileSettingsDropdown
          alignment={alignment}
          alignmentDisabled={alignmentDisabled}
          mobileSettingsApplied={mobileSettingsApplied}
          isDefaultMobilePreview={isDefaultMobilePreview}
          scale={scale}
          mobileViewImage={mobileViewImage}
          toggleAlignment={toggleAlignment}
          toggleMobileSettingsApplied={toggleMobileSettingsApplied}
          toggleScale={toggleScale}
          toggleMobileViewImage={toggleMobileViewImage}
          layoutId={layoutId}
        />
      }
    </Control>
  );
};

export default ImageMobileSettingsControl;
