import { EditorState } from 'draft-js';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import * as Models from 'models';
import { EditorSetters } from './editor';
import { UndoMiddleware } from './hooks/useUndo';
import { CTAStylesSetters } from './styles';

export type CallToActionStack = {
  // editor
  abbreviationId: string | undefined;
  // other
  alignment: IMap<Models.AssetAlignment<Constants.AssetAlignmentType>>;
  assetBackgroundColor?: IMap<Models.BrandColor>;
  assetBackgroundOpacity: number;
  assetBackgroundGradient: [Models.BrandColorGradientMap | undefined, IMap<Models.BrandColor> | undefined];
  assetBorderRadius: IMap<Models.BoxProperty>;
  assetPadding: IMap<Models.BoxProperty>;
  backgroundColor?: IMap<Models.BrandColor>;
  backgroundColorOpacity: number;
  backgroundImage?: IMap<Models.BackgroundImage>;
  backgroundGradient: [Models.BrandColorGradientMap | undefined, IMap<Models.BrandColor> | undefined];
  border?: IMap<Models.Border<Models.BrandColor>>;
  borderRadius?: IMap<Models.BoxProperty>;
  fitToCell: boolean;
  fontSize: number;
  link: string;
  padding: IMap<Models.BoxProperty>;
  textAlignment: IMap<Models.AssetAlignment<Constants.AssetAlignmentType>>;
  height: number;
  width: number;
  cellHeight: number;
  cellWidth: number;
  isAutoFitContent: boolean;
  editorState: EditorState;
};

export function wrapUndoMiddleware(
  middleware: UndoMiddleware,
  setters: CTAStylesSetters,
): CTAStylesSetters {
  return {
    ...setters,
    alignment: middleware(setters.alignment, 'alignment'),
    assetBackgroundColor: middleware(setters.assetBackgroundColor, 'assetBackgroundColor'),
    assetBackgroundOpacity: middleware(setters.assetBackgroundOpacity, 'assetBackgroundOpacity'),
    assetBackgroundGradient: middleware(setters.assetBackgroundGradient, 'assetBackgroundGradient'),
    assetBorderRadius: middleware(setters.assetBorderRadius, 'assetBorderRadius'),
    assetPadding: middleware(setters.assetPadding, 'assetPadding'),
    backgroundColor: middleware(setters.backgroundColor, 'backgroundColor'),
    backgroundColorOpacity: middleware(setters.backgroundColorOpacity, 'backgroundColorOpacity'),
    backgroundGradient: middleware(setters.backgroundGradient, 'backgroundGradient'),
    backgroundImage: middleware(setters.backgroundImage, 'backgroundImage'),
    border: middleware(setters.border, 'border'),
    borderRadius: middleware(setters.borderRadius, 'borderRadius'),
    fitToCell: middleware(setters.fitToCell, 'fitToCell'),
    height: middleware(setters.height, 'height'),
    padding: middleware(setters.padding, 'padding'),
    textAlignment: middleware(setters.textAlignment, 'textAlignment'),
    width: middleware(setters.width, 'width'),
  };
}

export function wrapEditorSettersWithUndo(
  middleware: UndoMiddleware,
  setters: EditorSetters,
): EditorSetters {
  return {
    // TODO Force - temporary, because we didn't save it in the relation
    abbreviationId: middleware(setters.abbreviationId, 'abbreviationId', true),
    fontColor: middleware(setters.fontColor),
    fontFamily: middleware(setters.fontFamily),
    fontSize: middleware(setters.fontSize, 'fontSize'),
  };
}
