import Immutable from 'immutable';

import { DocumentsSource } from 'const';
import { CombinedDocumentMap } from 'models';

export function filterDocuments(document: CombinedDocumentMap, contentFilters: Immutable.List<string> = Immutable.List<string>()): boolean {
  const documentSource = document.get('documentSource') || Immutable.List<DocumentsSource>();

  // don't show documents that linked only to group layouts
  if (documentSource.size === 1 && documentSource.first() === DocumentsSource.GROUP_LAYOUT) {
    return false;
  }

  if (contentFilters && contentFilters.size !== 0) {
    const searchFilter = [...contentFilters];

    searchFilter.push(DocumentsSource.PROJECT_FILE);
    if (contentFilters.includes(DocumentsSource.RELATED_DOCUMENTS)) {
      searchFilter.push(DocumentsSource.COMPONENTS);
    }

    return documentSource.some(document => searchFilter.includes(document));
  }

  return true;
}
