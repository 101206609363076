import Draft from 'draft-js';

export function applyEditorStateHacks(
  newEditorState: Draft.EditorState,
  prevEditorState: Draft.EditorState,
  editMode: boolean,
): Draft.EditorState {

  if (editMode) {
    const currentContentTextLength = prevEditorState.getCurrentContent().getPlainText().length;
    const newContentTextLength = newEditorState.getCurrentContent().getPlainText().length;

    // HACK: constantly save previous applied styles because Editor resets them if there is no a content (maybe in some other cases)
    // https://issues.merck.com/browse/DCC-2829
    if (newContentTextLength === 0) {
      newEditorState = Draft.EditorState.setInlineStyleOverride(newEditorState, prevEditorState.getCurrentInlineStyle());
    }

    // HACK: listens to input changes and focuses/moves cursor to back after typing in first character
    // https://github.com/facebook/draft-js/issues/1198
    // https://issues.merck.com/browse/DCC-2842
    // OR in case of copy-paste in empty cell
    // https://issues.merck.com/browse/DCC-2955
    if (currentContentTextLength === 0 && newContentTextLength >= 1) {
      newEditorState = Draft.EditorState.moveFocusToEnd(newEditorState);
    }

    // TODO: find a solution - there is still an issue when you open new (empty) text component and
    // immediately press Enter - all inline styles are removed
    // NOTE: DON'T change the order of HACKs
    // if we set inline styles (like setInlineStyleOverride) after moveFocusToEnd (or moveSelectionToEnd),
    // will be a magic which is prone to many interesting Editor bugs and focus (cursor position) will be incorrect as well
  }

  return newEditorState;
}
