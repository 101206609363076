import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { EntityType, ProjectPanelSection, ProjectPanelTab } from 'const';
import { startHandlingReusableLayoutsEditing, stopHandlingReusableLayoutsEditing, toggleUpdatedDocumentsDisplaying } from 'containers/App/actions';
import { handleReusableLayoutsEditing } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import * as commonSelectors from 'containers/Common/selectors';
import { getLayoutPreviewUrl } from 'containers/Common/services/getLayoutPreviewUrl';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { setProjectAssets } from 'containers/Common/services/setProjectAssets';
import { deleteDocuments, setDocument, updateDocument } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { deleteLayouts, setLayout } from 'containers/Layouts/actions';
import { ActionTypes } from 'containers/Layouts/constants';
import { layeredLayouts as layoutsSelector } from 'containers/Layouts/selectors';
import { deleteUploadingDocumentId, setUploadingDocumentId } from 'containers/Project/actions';
import * as projectSelectors from 'containers/Project/selectors';
import { addActiveSection, setActiveTab, setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { rootDocumentId as rootDocumentIdSelector } from 'containers/RootDocument/selectors';
import { groupLayoutDocumentFactory } from 'factories/document/groupLayoutDocumentFactory';
import { createLayeredGroupLayout } from 'factories/layoutFactory';
import * as Models from 'models';
import { createGroupLayout } from 'services/api';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { refreshLayoutsOnArtboards } from 'services/processes';
import { isMockDocument } from 'utils/documents';
import { toJS } from 'utils/immutable';
import { intlGet } from 'utils/intlGet';
import { createLayers } from 'utils/layers';
import { Action } from '../models';

export function* saveGroupLayout(action: Action.SaveGroupLayout) {
  let artboard: Models.ArtboardMap;
  let mockLayoutDocumentId: string;
  let newGroupLayoutId: string;
  let existentGroupLayout: Models.LayeredGroupLayoutMap;

  try {
    yield put(stopHandlingReusableLayoutsEditing());

    const actions: Models.IAction[] = [];
    const { groupLayoutId, layoutIds, name, previewUrl } = action.payload;
    const layer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);
    const layouts: ReturnTypeSaga<typeof layoutsSelector> = yield select(layoutsSelector);
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const language = (yield select(projectSelectors.currentLanguage)).toJS() as string[];
    const country = (yield select(projectSelectors.currentCountry)).toJS() as string[];
    const product = (yield select(projectSelectors.currentProduct)).toJS() as string[];
    artboard = yield select(activeArtboard);

    const documentInternalId = layouts.getIn([groupLayoutId, 'documentId', layer]);
    const currentLayoutDocument = _.get(documents, [documentInternalId, layer]);
    const needToCreateNewInstance = groupLayoutId && documentInternalId && !isMockDocument(currentLayoutDocument);
    const id = !needToCreateNewInstance && documentInternalId;

    const mockLayoutDocument = groupLayoutDocumentFactory({ id, previewUrl, name, language, country, product });
    mockLayoutDocumentId = mockLayoutDocument.id;

    if (!groupLayoutId) {
      // GRL hasn't been created yet, we'll create it from layouts that are used directly within artboard

      const firstLayoutId = _.first(layoutIds);
      // we can take section id by first RL because all RLs have the same section within GRL
      const section = layouts.getIn([firstLayoutId, 'section']);
      const position = artboard.get('layoutIds').findIndex(layoutId => layoutId === firstLayoutId);

      const newGroupLayout = createLayeredGroupLayout({
        documentId: createLayers({ [layer]: mockLayoutDocumentId }),
        layoutIds,
        section,
      });
      newGroupLayoutId = newGroupLayout.id;

      const updatedArtboard = artboard.update('layoutIds', artboardLayoutIds => artboardLayoutIds
        .filter(layoutId => !layoutIds.includes(layoutId))
        .insert(position, newGroupLayout.id),
      );

      actions.push(
        setLayout(newGroupLayout),
        updateArtboard(updatedArtboard),
      );
    } else {
      // GRL has been already created, just need to "recreate" it with new document
      existentGroupLayout = layouts.get(groupLayoutId) as Models.LayeredGroupLayoutMap;
      const newGroupLayout = existentGroupLayout.setIn(['documentId', layer], mockLayoutDocumentId).toJS() as Models.LayeredGroupLayout;

      actions.push(setLayout(newGroupLayout));
    }

    actions.push(
      setDocument(mockLayoutDocument),
      setUploadingDocumentId(mockLayoutDocumentId),
      setAssetIdToScroll(mockLayoutDocumentId),
      setActiveTab(ProjectPanelTab.ASSETS),
      addActiveSection(ProjectPanelSection.REUSABLE_LAYOUTS),
      toggleUpdatedDocumentsDisplaying(true, EntityType.LAYOUT),
    );
    yield put(batchActions(actions));

    // waiting for this action until GRL height will be recalculated
    yield race([
      take(ActionTypes.SET_LAYOUTS),
      delay(10000),
    ]);

    const groupLayoutAssetsSelector = commonSelectors.groupLayoutAssets(groupLayoutId || newGroupLayoutId);
    const groupLayoutAssets = (yield select(groupLayoutAssetsSelector)).toJS() as Models.GroupLayoutAssets;
    // let 'restoreLayoutAssets' saga know that group layout instances can be restored
    yield put({ type: ActionTypes.RESTORE_LAYOUT_ASSETS });

    if (!previewUrl) {
      const s3PreviewUrl: ReturnTypeSaga<typeof getLayoutPreviewUrl> = yield call(getLayoutPreviewUrl, layoutIds);
      yield put(updateDocument({ id: mockLayoutDocumentId, previewUrl: s3PreviewUrl, entityType: EntityType.GROUP_LAYOUT }));
      groupLayoutAssets.previewUrl = s3PreviewUrl;
    }
    const rootDocumentId: ReturnTypeSaga<typeof rootDocumentIdSelector> = yield select(rootDocumentIdSelector);

    const { data: groupLayoutDocument }: ReturnTypeSaga<typeof createGroupLayout> = yield call(
      createGroupLayout,
      rootDocumentId,
      groupLayoutAssets,
      name,
      { language, country, product },
    );

    groupLayoutDocument.id = mockLayoutDocumentId;
    groupLayoutDocument.previewUrl = groupLayoutAssets.previewUrl;
    groupLayoutDocument._thumbnailUrl = groupLayoutAssets.previewUrl;

    yield put(setDocument(groupLayoutDocument));

    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'GroupLayoutHasBeenSaved'),
      intlGet('Notification', 'Success'),
    );
  } catch (error) {
    if (!action.payload.groupLayoutId) {
      yield put(batchActions([
        deleteDocuments([mockLayoutDocumentId]),
        ...artboard ? [updateArtboard(artboard)] : [],
        ...newGroupLayoutId ? [deleteLayouts([newGroupLayoutId])] : [],
      ]));

      return;
    }

    const reusableLayoutsEditingActive: ReturnTypeSaga<typeof handleReusableLayoutsEditing> = yield select(handleReusableLayoutsEditing);
    reusableLayoutsEditingActive && (yield put(stopHandlingReusableLayoutsEditing()));

    const projectAssets: ReturnTypeSaga<typeof getProjectAssets> = yield call(getProjectAssets, { removeInternalInfo: false });
    const masterScreenData = (yield select(commonSelectors.masterScreenData)).toJS() as Models.MasterScreenData;
    const assets = _.set<Models.ProjectAssets>(_.cloneDeep(projectAssets), 'masterScreenData', masterScreenData);
    const arg = { assets } as Models.RestoreLayoutAssetsArgs;
    arg.layoutsToRefresh = { [existentGroupLayout.get('id')]: toJS(existentGroupLayout) };

    yield call(refreshLayoutsOnArtboards, arg);
    yield call(setProjectAssets, arg.assets);

    reusableLayoutsEditingActive && (yield put(startHandlingReusableLayoutsEditing()));

    yield call(handleSagaError, error, 'SaveReusableLayoutWindow.saveGroupLayout', 'SaveGroupReusableLayout');
  } finally {
    yield put(batchActions([
      deleteUploadingDocumentId(mockLayoutDocumentId),
      startHandlingReusableLayoutsEditing(),
    ]));
  }
}
