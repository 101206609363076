import classNames from 'classnames';
import React from 'react';

import createDndScrollZone from 'components/DndScrollZone';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import ScreensDragSpacer from 'components/ScreensDragSpacer';
import ScreensPanelItem from 'components/ScreensPanelItem';
import { EntityType, ModalType } from 'const';
import { createSmallVerticalStrength } from 'utils/dndScrollZone';
import { intlGet } from 'utils/intlGet';
import { ScreensPanelProps, ScreensPanelState } from './models';
import styles from './styles.module.scss';

const DnDScrollZone = createDndScrollZone<React.HTMLAttributes<HTMLElement>>('div');

export default class ScreensPanel extends React.PureComponent<ScreensPanelProps, ScreensPanelState> {
  readonly state: ScreensPanelState = {
    dragHotspotPosition: 0,
    isOver: false,
  };

  private updateHotspotPosition = (position: number): void => {
    this.setState({ dragHotspotPosition: position });
  };

  private onAddButtonClick = (): void => {
    this.props.showModal(ModalType.ADD_SCREEN);
  };

  private changeActiveScreen = (id: string): void => {
    const { activeScreenId, changeActiveScreen } = this.props;

    if (id !== activeScreenId) {
      changeActiveScreen(id);
    }
  };

  private toggleOverState = (isOver: boolean): void => {
    this.setState({ isOver });
  };

  private renderScreenPanelItems = () => {
    const {
      activeScreenId,
      artboards,
      deleteScreen,
      duplicateScreen,
      moveScreen,
      projectType,
      screenDefinitions,
      screenNames,
      screens,
      selectedEntityTypesByScreenId,
      setScreenFormat,
      setScreenSettings,
      surfaceIds,
      thumbnailsColor,
      updateScreen,
      updateScreenType,
    } = this.props;
    const isDeletingDisabled = screens.size === 1;

    return screens.valueSeq().map((screen) => {
      const id = screen.get('id');
      const position = surfaceIds.findIndex(surfaceId => surfaceId === id) + 1;
      const selectedEntityTypes = selectedEntityTypesByScreenId.get(id);
      const showIconComponent = !selectedEntityTypes.isEmpty();
      const entityType = selectedEntityTypes.last<EntityType>(null);

      return (
        <ScreensPanelItem
          artboards={artboards}
          changeActiveScreen={this.changeActiveScreen}
          deleteScreen={deleteScreen}
          duplicateScreen={duplicateScreen}
          entityType={entityType}
          isActive={id === activeScreenId}
          isDeletingDisabled={isDeletingDisabled}
          isOver={this.state.isOver}
          key={id}
          moveScreen={moveScreen}
          position={position}
          projectType={projectType}
          screen={screen}
          screenDefinitions={screenDefinitions}
          screenNames={screenNames}
          setScreenFormat={setScreenFormat}
          setScreenSettings={setScreenSettings}
          showIconComponent={showIconComponent}
          thumbnailsColor={thumbnailsColor}
          toggleOverState={this.toggleOverState}
          updateHotspotPosition={this.updateHotspotPosition}
          updateScreen={updateScreen}
          updateScreenType={updateScreenType}
        />
      );
    });
  };

  private togglePanel = (): void => {
    const { toggleShowScreensPanel, showScreensPanel } = this.props;

    toggleShowScreensPanel(!showScreensPanel);
  };

  private renderDragHotspot = (): JSX.Element => (
    <ScreensDragSpacer position={this.state.dragHotspotPosition} />
  );

  render() {
    const { showScreensPanel } = this.props;

    return (
      <div className={classNames(styles.ScreensPanel, { [styles.ScreensPanelHidden]: !showScreensPanel })}>
        <div className={styles.title}>
          {showScreensPanel && intlGet('ScreensPanel', 'Title')}
          <Icon
            size="md"
            color="primary"
            onClick={this.togglePanel}
            type={showScreensPanel ? IconType.DOUBLE_ARROW_LEFT : IconType.DOUBLE_ARROW_RIGHT}
          />
        </div>
        <DnDScrollZone
          className={styles.list}
          verticalStrength={createSmallVerticalStrength}
        >
          {this.renderScreenPanelItems()}
          {this.state.isOver && this.renderDragHotspot()}
        </DnDScrollZone>
        <div className={styles.toolbar} onClick={this.onAddButtonClick}>
          <div className={styles.iconWrapper}>
            <Icon color="secondary" size="badge" type={IconType.PLUS} />
          </div>
          <span className={styles.toolbarTitle}>{intlGet('ScreensPanel.Actions', 'Add')}</span>
        </div>
      </div>
    );
  }
}
