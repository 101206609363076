import classNames from 'classnames';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import AssetActions from 'components/AssetActions';
import AssetsCollectionEntities from 'components/AssetsCollectionEntities';
import AssetsCollectionScreens from 'components/AssetsCollectionScreens';
import DocumentThumbnail from 'components/DocumentThumbnail';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Loader from 'components/Loader';
import { DragSourceType, EntityType, ModalType } from 'const';
import * as Models from 'models';
import { isMagicForm, isStoryCard } from 'utils/entityType';
import { isAssetsCollectionDraggable } from 'utils/isAssetsCollectionDraggable';
import * as ComponentModels from './models';
import styles from './styles.module.scss';

const AssetItemDragSource: DragSourceSpec<ComponentModels.AssetsCollectionProps, Models.StoryCardDragObject> = {
  beginDrag(props) {
    const { toggleDragState, document } = props;
    toggleDragState(DragSourceType.STORY_CARD, EntityType.STORY_CARD);

    return {
      storyCardId: document.get('id'),
    };
  },

  endDrag(props) {
    props.toggleDragState();
  },

  canDrag(props) {
    const { document } = props;

    return isAssetsCollectionDraggable(document);
  },
};

const collect: DragSourceCollector<ComponentModels.AssetsCollectionCollectedProps, ComponentModels.AssetsCollectionAllOwnProps> = (
  connect,
  monitor,
) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  draggable: monitor.canDrag(),
  isDragging: monitor.isDragging(),
});

class AssetsCollection extends React.PureComponent<ComponentModels.AssetsCollectionProps, ComponentModels.AssetsCollectionState> {
  constructor(props: ComponentModels.AssetsCollectionProps) {
    super(props);

    this.state = {
      isExpanded: false,
      dataLoaded: false,
    };
  }

  componentDidUpdate(
    prevProps: ComponentModels.AssetsCollectionProps,
    prevState: ComponentModels.AssetsCollectionState,
  ): void {
    const { dataLoaded, isExpanded } = this.state;

    if (!prevState.dataLoaded) {
      const nextRelatedDocuments: Models.AssetsCollectionMap = this.props.document.get('documents');
      this.setState({ dataLoaded: nextRelatedDocuments && nextRelatedDocuments.size > 0 });
    } else {
      const { lastRefreshTime: nextLastRefreshTime } = this.props;
      if (nextLastRefreshTime && nextLastRefreshTime !== prevProps.lastRefreshTime) {
        this.setState({ dataLoaded: false });
      }
    }

    if (isExpanded && this.props.isNotificationAccessError) {
      this.handleToggleExpand();

      return;
    }

    if (!dataLoaded && isExpanded && !prevState.isExpanded) {
      this.loadInternalData();
    }
  }

  componentDidMount(): void {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  private loadInternalData(): void {
    const { document, getInternalData } = this.props;

    getInternalData(document.get('id'));
  }

  private readonly handleToggleExpand = (): void => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  private readonly retrievePreview = (): void => {
    const { document, getPreview } = this.props;

    isStoryCard(document) && getPreview && getPreview(document.toJS() as Models.StoryCard);
  };

  private readonly showDetailsModal = (previewUrls: string[]): void => {
    const { document, showModal } = this.props;

    const {
      description,
      name,
      number,
      product,
      status,
    } = document.toJS() as Models.StoryCard;

    showModal(ModalType.STORY_CARD_DETAILS, { description, name, number, previewUrls, product, status });
  };

  private readonly showStoryCardDetails = (): void => {
    const { document } = this.props;
    const { _previewUrls: previewUrls } = document.toJS() as Models.StoryCard;

    this.showDetailsModal(previewUrls);
  };

  private readonly showScreenDetails = (previewUrl: string): void => {
    this.showDetailsModal([previewUrl]);
  };

  private readonly areActionsAvailable = (): boolean => {
    const { document } = this.props;
    const previewUrls = (document as Models.StoryCardMap).get('_previewUrls');
    const isPreviewBeingLoaded = (document as Models.StoryCardMap).get('_isPreviewBeingLoaded');

    return isStoryCard(document) && !isPreviewBeingLoaded && previewUrls && previewUrls.size !== 0;
  };

  private readonly showDocumentsByScreens = (): boolean => {
    const { document } = this.props;
    const entities = (document as Models.StoryCardMap).get('entities');
    const screenIds = entities && entities.get('screenIds');

    return !!screenIds && screenIds.size > 1;
  };

  private readonly renderContent = (): JSX.Element => {
    const { document, toggleDragState } = this.props;

    return this.showDocumentsByScreens()
      ? <AssetsCollectionScreens document={document} showScreenDetails={this.showScreenDetails} toggleDragState={toggleDragState} />
      : <AssetsCollectionEntities document={document} screenOrder={1} />;
  };

  // NOSONAR
  render() {
    const { document, draggable, isDragging, connectDragSource } = this.props;
    const { isExpanded, dataLoaded } = this.state;
    const name = document.get('name');
    const number = document.get('number');
    const brand = (document.getIn(['product']) || []).join(', ');
    const description = document.get('description');
    const previewUrls = (document as Models.StoryCardMap).get('_previewUrls');
    const isPreviewBeingLoaded = (document as Models.StoryCardMap).get('_isPreviewBeingLoaded');

    return (
      <div className={classNames(styles.AssetsCollection, { [styles.isDragging]: isDragging })}>
        <div className={styles.contentInvisible}>
          {connectDragSource(
            <div className={classNames(styles.card__wrapper, { [styles.draggable]: draggable })}>
              <div className={styles.box}>
                <DocumentThumbnail
                  loaded={!!previewUrls}
                  loading={isPreviewBeingLoaded}
                  getThumb={this.retrievePreview}
                  url={previewUrls && previewUrls.get(0)}
                  placeholder={(isStoryCard(document) || isMagicForm(document))
                    ? <Icon type={IconType.IMAGE} size="lg" color="disabled" />
                    : <Icon type={IconType.TEXT_COLLECTION} size="xl" color="disabled" />
                  }
                />
                {
                  this.areActionsAvailable() &&
                  <AssetActions onPreviewOpen={this.showStoryCardDetails} />
                }
              </div>
              <div
                className={styles.info}
                onClick={this.handleToggleExpand}
              >
                <div className={styles.info__primary}>{name}</div>
                <div className={styles.info__secondary}>{description}</div>
                <div className={styles.info__bottom__container}>
                  <div className={styles.info__bottom}>
                    <div className={styles.info__bottom__number}>{number}</div>
                    <div className={styles.info__bottom__brand}>{brand}</div>
                  </div>
                  <div className={styles.chevron}>
                    <Icon
                      type={isExpanded ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
                      size="badge"
                      color="primary"
                    />
                  </div>
                </div>
              </div>
            </div>,
          )}
          <AnimateHeight
            duration={300}
            easing="ease-out"
            height={isExpanded ? 'auto' : 0}
          >
            {
              !dataLoaded
                ? <Loader />
                : this.renderContent()
            }
          </AnimateHeight>
        </div>
      </div>
    );
  }
}

export default DragSource<ComponentModels.AssetsCollectionAllOwnProps, ComponentModels.AssetsCollectionCollectedProps, Models.StoryCardDragObject>(
  DragSourceType.STORY_CARD,
  AssetItemDragSource,
  collect,
)(AssetsCollection);
