import classNames from 'classnames';
import React, { useCallback } from 'react';

import Icon from 'components/Icon';
import { AssetsCollectionNavIcons, AssetsCollectionNavItem, AssetsCollectionTooltipText } from 'const';
import { AssetsCollectionNavBarProps } from './models';
import styles from './styles.module.scss';

const AssetsCollectionNavBar: React.FunctionComponent<AssetsCollectionNavBarProps> = (props) => {
  const { navItems, disabledItems, activeItem, onChange } = props;

  const onItemChange = useCallback(
    (navItem: AssetsCollectionNavItem) => (): void => {
      onChange(navItem);
    },
    [onChange],
  );

  return (
    <div className={styles.AssetsNavBar}>
      {
        navItems.map((navItem) => {
          const isActive = activeItem === navItem;
          const isDisabled = disabledItems.includes(navItem);

          return (
            <div
              key={navItem}
              className={classNames(
                styles.icon,
                {
                  [styles.active]: isActive,
                  [styles.disabled]: isDisabled,
                },
              )}
              onClick={isDisabled ? null : onItemChange(navItem)}
              data-tooltip={AssetsCollectionTooltipText[navItem]()}
            >
              <Icon type={AssetsCollectionNavIcons[navItem]} size="sm" color={isActive ? 'secondary' : 'primary'} />
            </div>
          );
        })
      }
    </div>
  );
};

export default AssetsCollectionNavBar;
