import { intlGet } from 'utils/intlGet';

export enum BackgroundImageType {
  COVER = 'Cover',
  FILL = 'Fill',
  FIT = 'Fit',
  ORIGINAL_SIZE = 'OriginalSize',
  TILE = 'Tile',
}

export const BackgroundImageTypes: BackgroundImageType[] = [
  BackgroundImageType.COVER,
  BackgroundImageType.FILL,
  BackgroundImageType.FIT,
  BackgroundImageType.ORIGINAL_SIZE,
  BackgroundImageType.TILE,
];

export const TextByBackgroundImageType: Record<BackgroundImageType, () => string> = {
  [BackgroundImageType.COVER]: () => intlGet('EditorToolbar.BackgroundImage.Type', 'Cover'),
  [BackgroundImageType.FILL]: () => intlGet('EditorToolbar.BackgroundImage.Type', 'Fill'),
  [BackgroundImageType.FIT]: () => intlGet('EditorToolbar.BackgroundImage.Type', 'Fit'),
  [BackgroundImageType.ORIGINAL_SIZE]: () => intlGet('EditorToolbar.BackgroundImage.Type', 'OriginalSize'),
  [BackgroundImageType.TILE]: () => intlGet('EditorToolbar.BackgroundImage.Type', 'Tile'),
};
