import React from 'react';

import ColorControl from 'components/Toolbar/Shared/Color';
import { colorTints } from 'const';
import { brandColorsAreEqual } from 'models';
import { colorWithTint } from 'utils/converters';
import { ColorTintsProps } from './models';
import styles from './styles.module.scss';

const ColorTints: React.FunctionComponent<ColorTintsProps> = (props) => {
  const { activeColor, color, toggleColor, toggleTooltip } = props;
  const tintedColors = colorTints.map((tint, index) => ({
    key: index.toString(),
    tintedColor: colorWithTint(color, tint),
  }));

  return (
    <div className={styles.ColorTints}>
      <div className={styles.controls}>
        {color && tintedColors.map(({ tintedColor, key }) => (
          <ColorControl
            key={key}
            color={tintedColor}
            isActive={!!activeColor && brandColorsAreEqual(tintedColor, activeColor)}
            onToggle={toggleColor}
            toggleTooltip={toggleTooltip}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorTints;
