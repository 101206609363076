import Immutable from 'immutable';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import * as RelationsModels from 'containers/Relations/models';
import * as Models from 'models';
import { getExtraWidth } from 'utils/getExtraWidth';
import { isRowRelation } from 'utils/relations/isRowRelation';

import {
  getRowsHeightFromRelations,
  getAdjustedColumnsWidth,
  setColumnsWidthToRelations,
  setRowsHeightToRelations,
} from './utils';

type ReturnType = {
  relations: Models.LayeredRelationsMap<Models.CombinedRelationStyles>;
  toggleRelations: (updatedRelations: Models.LayeredRelationsMap<Models.CombinedRelationStyles>) => void;
  columnsWidth: Immutable.List<number>;
  toggleColumnsWidth: (_columnsWidth: Immutable.List<number>) => void;
  setRowHeightSilently: (relationId: string, position: number, height: number) => void;
};


export const useRelations = (
  allRelations: Models.LayeredRelationsMap,
  layout: Models.LayoutMap,
  ssi: Models.SSIMap,
  sectionsWidth: Models.MasterScreen.SectionsWidthMap,
  sectionWidth: number,
  updateRelationsSilently: RelationsModels.ActionCreator.UpdateLayeredRelationsSilently,
): ReturnType => {
  const [columnsWidthByRelId, toggleColumnsWidthByRelIdState] = React.useState(() => getAdjustedColumnsWidth(
    allRelations,
    layout,
    ssi,
    sectionsWidth,
    sectionWidth,
  ));

  const [rowsHeightByRelId, toggleRowsHeightByRelIdState] = React.useState(() => getRowsHeightFromRelations(allRelations));

  React.useEffect(
    () => {
      toggleColumnsWidthByRelIdState(getAdjustedColumnsWidth(
        allRelations,
        layout,
        ssi,
        sectionsWidth,
        sectionWidth,
      ));
    },
    [
      allRelations,
      layout,
      ssi,
      sectionsWidth,
      sectionWidth,
    ],
  );

  React.useEffect(
    () => {
      toggleRowsHeightByRelIdState(getRowsHeightFromRelations(allRelations));
    },
    [allRelations],
  );

  const relations = useMemo(() => _.flow(
    _relations => setColumnsWidthToRelations(columnsWidthByRelId, _relations),
    _relations => setRowsHeightToRelations(rowsHeightByRelId, _relations),
  )(allRelations), [rowsHeightByRelId, columnsWidthByRelId, allRelations]);

  const toggleRelations = useCallback((updatedRelations: Models.LayeredRelationsMap): void => {
    toggleColumnsWidthByRelIdState(getAdjustedColumnsWidth(
      updatedRelations,
      layout,
      ssi,
      sectionsWidth,
      sectionWidth,
    ));
    toggleRowsHeightByRelIdState(getRowsHeightFromRelations(
      updatedRelations,
    ));
  }, [
    toggleColumnsWidthByRelIdState,
    toggleRowsHeightByRelIdState,
    layout,
    ssi,
    sectionsWidth,
    sectionWidth,
  ]);

  const setRowHeightSilently = useCallback((relationId: string, position: number, height: number): void => {
    toggleRowsHeightByRelIdState((_rowsHeightByRelId) => {
      const newRowsHeightByRelId = _rowsHeightByRelId.update(
        relationId,
        rowsHeight => (rowsHeight || Immutable.List()).set(position, height),
      );

      if (newRowsHeightByRelId.every(rowsHeight => rowsHeight.every(Boolean))) {
        updateRelationsSilently(
          setRowsHeightToRelations(newRowsHeightByRelId, relations),
          layout.get('id'),
        );

        return newRowsHeightByRelId;
      }

      return newRowsHeightByRelId;
    });
  }, [toggleRowsHeightByRelIdState, updateRelationsSilently, setRowsHeightToRelations, layout]);

  // layout columns width
  const layoutRelation = allRelations.get(layout.get('relationId'));
  const layoutRelationChildIds = layoutRelation.get('relationIds');
  const firstLayoutChildRelationId = layoutRelationChildIds.first<string>();
  const firstLayoutChildRelation = allRelations.get(firstLayoutChildRelationId) as Models.RowRelationMap | Models.RegularRelationMap;

  const columnsWidth = layoutRelationChildIds.size === 1 && isRowRelation(firstLayoutChildRelation)
    ? columnsWidthByRelId.get(firstLayoutChildRelationId) || firstLayoutChildRelation.getIn(['styles', 'columnsWidth'])
    : Immutable.List([sectionWidth - getExtraWidth(layout.get('styles'))]);

  // toggle layout columns width
  const toggleColumnsWidth = (_columnsWidth: Immutable.List<number>): void => {
    if (!isRowRelation(allRelations.get(firstLayoutChildRelationId))) {
      return;
    }

    const newColumnsWidthByRelId = columnsWidthByRelId.set(firstLayoutChildRelationId, _columnsWidth);
    toggleRelations(setColumnsWidthToRelations(newColumnsWidthByRelId, allRelations));
  };

  return {
    relations,
    toggleRelations,
    columnsWidth,
    toggleColumnsWidth,
    setRowHeightSilently,
  };
};
