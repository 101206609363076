import Immutable from 'immutable';
import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { getTextComponentsIds } from 'utils/getTextComponentsIds';
import { isImmutable } from 'utils/immutable';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isMockReusableLayout } from 'utils/reusableLayouts/isMockReusableLayout';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';

/**
 * Since RL has text components that can not be used outside of it,
 * need to remove such text components when we remove cell, column or layout itself
 */
// TODO: we also need to delete CTAs because of the same reason
export function getTextComponentIdsToDelete(
  layout: Models.LayoutMap | Models.Layout,
  relationIdsToDelete: Immutable.List<string> | string[],
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
  relations: Models.LayeredRelationsMap | Models.LayeredRelations,
  layer: Layer,
): string[];
export function getTextComponentIdsToDelete(
  layout: Models.LayeredLayoutMap | Models.LayeredLayout,
  relationIdsToDelete: Immutable.List<string> | string[],
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
  relations: Models.LayeredRelationsMap | Models.LayeredRelations,
  layer: Layer,
): string[];
export function getTextComponentIdsToDelete(
  layout: Models.LayoutMap | Models.Layout | Models.LayeredLayoutMap | Models.LayeredLayout,
  relationIdsToDelete: Immutable.List<string> | string[],
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
  relations: Models.Relations | Models.RelationsMap,
): string[];
// TODO: get rid of this override
export function getTextComponentIdsToDelete(
  layout: Models.LayoutMap | Models.Layout | Models.LayeredLayoutMap | Models.LayeredLayout,
  relationIdsToDelete: Immutable.List<string> | string[],
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
  relations: Models.Relations | Models.RelationsMap | Models.LayeredRelations | Models.LayeredRelationsMap,
  layer?: Layer,
): string[];
export function getTextComponentIdsToDelete(
  layout: Models.LayoutMap | Models.Layout | Models.LayeredLayoutMap | Models.LayeredLayout,
  relationIdsToDelete: Immutable.List<string> | string[],
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
  relations: Models.Relations | Models.RelationsMap | Models.LayeredRelations | Models.LayeredRelationsMap,
  layer?: Layer,
): string[] {
  if (!isReusableLayout(layout as Models.LayeredLayout, documents, layer) && !isMockReusableLayout(layout, documents, layer)) {
    return [];
  }

  const actualLayoutRelations = getFlattenedRelations(layout as Models.LayeredLayout, relations as Models.Relations | Models.RelationsMap);
  const actualLayoutRelationIds = isImmutable(actualLayoutRelations) ? actualLayoutRelations.keySeq().toArray() : _.keys(actualLayoutRelations);
  const actualRelationIdsToDelete = isImmutable(relationIdsToDelete) ? relationIdsToDelete.toArray() : relationIdsToDelete;
  const relationIdsToLeave = _.difference(actualLayoutRelationIds, actualRelationIdsToDelete);

  const textComponentIdsToDelete = getTextComponentsIds(actualRelationIdsToDelete, relations as Models.LayeredRelations, layer).toArray();
  const textComponentIdsToLeave = getTextComponentsIds(relationIdsToLeave, relations as Models.LayeredRelations, layer).toArray();

  return _.without(textComponentIdsToDelete, ...textComponentIdsToLeave);
}
