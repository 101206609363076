import _ from 'lodash';
import React from 'react';

import Icon from 'components/Icon';
import { HorizontalAlignmentControlMap, VerticalAlignmentControlMap } from 'components/Toolbar/Controls/AssetAlignment/constants';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { AssetAlignmentDirection, AssetAlignmentType } from 'const';
import { intlGet } from 'utils/intlGet';
import { AssetAlignmentProps } from './models';
import styles from './styles.module.scss';

const AssetAlignmentDropdown: React.FunctionComponent<AssetAlignmentProps> = (props) => {
  const { alignment, toggleAlignment } = props;
  const horizontalAlignment = alignment.get(AssetAlignmentDirection.HORIZONTAL);
  const verticalAlignment = alignment.get(AssetAlignmentDirection.VERTICAL);

  return (
    <Dropdown className={styles.AssetAlignment}>
      {
        _.map(HorizontalAlignmentControlMap, (options, type: AssetAlignmentType) => {
          const { icon, title } = options;
          const targetAlignment = alignment.set(AssetAlignmentDirection.HORIZONTAL, type);

          return (
            <div className={styles.item} key={title} onClick={toggleAlignment.bind(null, targetAlignment)}>
              <Icon type={icon} size="sm" color={type === horizontalAlignment ? 'primary' : 'secondary'} />
              <span className={styles.title}>{intlGet('EditorToolbar.AssetAlignment', title)}</span>
            </div>
          );
        })
      }
      <div className={styles.spacer} />
      {
        _.map(VerticalAlignmentControlMap, (options, type: AssetAlignmentType) => {
          const { icon, title } = options;
          const targetAlignment = alignment.set(AssetAlignmentDirection.VERTICAL, type);

          return (
            <div className={styles.item} key={title} onClick={toggleAlignment.bind(null, targetAlignment)}>
              <Icon type={icon} size="sm" color={type === verticalAlignment ? 'primary' : 'secondary'} />
              <span className={styles.title}>{intlGet('EditorToolbar.AssetAlignment', title)}</span>
            </div>
          );
        })
      }
    </Dropdown>
  );
};

export default AssetAlignmentDropdown;
