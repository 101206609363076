import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import Dropdown from 'components/Toolbar/Dropdowns/Padding';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { PaddingControlProps } from './models';

const Padding: React.FunctionComponent<PaddingControlProps> = (props) => {
  const { padding, togglePadding, dropdownTitle, icon, className, returnFocusToEditor } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={dropdownTitle}
      type={icon}
      className={className}
      onToggle={toggleDropdown}
    >
      {isOpen && <Dropdown padding={padding} togglePadding={togglePadding} title={dropdownTitle} />}
    </Control>
  );
};

Padding.defaultProps = {
  dropdownTitle: Title.PADDING,
  icon: IconType.PADDING,
  title: Title.PADDING,
};

export default Padding;
