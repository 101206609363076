import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from '../Control/constants';
import { FitToCellProps } from './models';

const FitToCell: React.FunctionComponent<FitToCellProps> = (props) => {
  const { fitToCell, toggleFitToCell, controlState = ControlState } = props;

  return (
    <Control
      onToggle={toggleFitToCell}
      size="sm-md"
      state={fitToCell ? controlState.ACTIVE : controlState.DEFAULT}
      title={Title.FIT_TO_CELL}
      type={IconType.FIT_TO_CELL}
    />
  );
};

export default FitToCell;
