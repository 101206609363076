import * as React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import { EntityType } from 'const';
import StoryCard from 'containers/StoryCard';
import { MagicFormMap, StoryCardMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { IStoryCardListProps } from './models';
import styles from './styles.module.scss';

const StoryCardList: React.FunctionComponent<IStoryCardListProps> = (props) => {
  const {
    items,
    showUpdatedStoryCards,
    toggleUpdatedDocumentsDisplaying,
    updatedInPromoMats,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({
    toggleUpdatedDocumentsDisplaying,
    showUpdatedDocuments: showUpdatedStoryCards,
    entityType: EntityType.STORY_CARD,
  });

  return items && items.size > 0 && (
    <div className={styles.StoryCardList} data-title="story-cards">
      <Header
        title={intlGet('Assets', 'StoryCards', { count: items.size })}
        showUpdatesHint={updatedInPromoMats && showUpdatedStoryCards}
        expanded={expanded}
        onClick={toggle}
      />
      {
        expanded &&
        <List
          elements={items}
          renderElement={(storyCard: MagicFormMap | StoryCardMap) => (
            <StoryCard key={storyCard.get('id')} document={storyCard} />
          )}
        />
      }
    </div>
  );
};

export default StoryCardList;
