import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import { CallToActionEditor } from 'modules/draftjs';
import React from 'react';
import ResizeComponent from 'components/ResizeComponent';
import Toolbar from 'components/Toolbar/CallToAction';
import { MinCellWidth } from 'const';
import { useProjectType } from 'hooks/useProjectType';
import { AssetAlignment } from 'models';
import { allowLinkRedirect } from 'utils/allowLinkRedirect';
import * as editorUtils from 'utils/editor';
import * as styleUtils from 'utils/styles';
import UndoPortal from '../Text/components/UndoPortal';
import { useCallToAction } from './hooks';
import { useResizeObserver } from './hooks/useResizeObserver';
import { useUndo } from './hooks/useUndo';
import { CallToActionProps } from './models';
import css from './styles.module.scss';
import { wrapEditorSettersWithUndo, wrapUndoMiddleware } from './undo';

export function getCallToActionDOMId(relationId: string): string {
  return `call-to-action-cell-${relationId}`;
}

const CallToAction: React.FunctionComponent<CallToActionProps> = (props) => {
  const {
    cellHeight,
    cellWidth,
    disableCellWidthEditing,
    editMode,
    isIncreasingWidthDisabled,
    layoutId,
    maxCellHeight,
    minCellHeight,
    relation,
    toggleColumnWidth,
    toggleRowHeight,
    toggleAutoFitContent,
    isAutoFitContent,
    saveAppState,
    cancel,
  } = props;

  const {
    draftjs,
    container,
    editorWrap,
    styles,
    stylesSetters,
    stylesCSS,
    link,
    toggleLink,
    getHeight,
    getWidth,
    getMaxWidth,
    getResizeComponentHeight,
    getResizeComponentMinHeight,
    getResizeComponentWidth,
    finishResizing,
    onResize,
    brandProps,
  } = useCallToAction(props);

  useResizeObserver(editorWrap, styles, stylesSetters, editMode);

  const {
    editorRef,
    editorState,
    setEditorState,
    onEditorChange,
    returnFocusToEditor,
    props: editorProps,
    setters: editorSetters,
  } = draftjs;

  const undoHook = useUndo(
    editMode, saveAppState, cancel,
    draftjs,
    [styles, stylesSetters, stylesCSS],
    {
      isAutoFitContent,
      toggleAutoFitContent,
      link,
      toggleLink,
      cellHeight,
      cellWidth,
      getHeight,
      getWidth,
      toggleRowHeight,
      toggleColumnWidth,
    },
  );

  const projectType = useProjectType();
  const handleClick = (event: React.MouseEvent): Window => allowLinkRedirect(event) && link && window.open(link, '_blank');

  const stylesSettersWithUndo = wrapUndoMiddleware(undoHook.undoStackMiddleware, stylesSetters);
  const editorSettersWithUndo = wrapEditorSettersWithUndo(undoHook.undoStackMiddleware, editorSetters);

  useCellActionsExecutor(CellAction.SET_CURSOR_ON_ABBREVIATION, (abbreviationId, abbrreviationNumber) => {
    const newState = editorUtils.selectAbbreviation(editorState, abbreviationId, abbrreviationNumber);
    if (!newState) {
      return false;
    }
    setEditorState(newState);

    return true;
  });

  useCellActionsExecutor(CellAction.APPLY_SELECTION, (
    blockKey: string,
    start: number,
    end: number,
  ) => {
    setEditorState(editorUtils.applySelection(editorState, blockKey, start, end));
  });

  const buttonElement: JSX.Element = (
    <CallToActionEditor
      ref={editorRef}
      id={relation.get('id')}
      className={css.button}
      styles={{
        ...stylesCSS.element,
        ...styleUtils.getButtonSize(getHeight(), getWidth(), editMode),
      }}
      wrapperRef={editorWrap}
      editorState={editorState}
      onEditorChange={onEditorChange}
      returnFocusToEditor={returnFocusToEditor}
      editMode={editMode}
      fillUndoStackIfEmpty={undoHook.fillUndoStackIfEmpty}
      undoStackMiddleware={undoHook.undoStackMiddleware}
      brandProps={brandProps}
    />
  );

  return (
    <div
      className={css.CallToAction}
      onClick={handleClick}
      ref={container}
      style={stylesCSS.container}
    >
      {
        editMode
          ?
          <ResizeComponent
            alignment={styles.alignment.toJS() as AssetAlignment}
            container={container.current}
            finishResizing={finishResizing}
            isResponsive={false}
            onResize={onResize}
            width={getResizeComponentWidth()}
            minWidth={MinCellWidth.CTA}
            height={getResizeComponentHeight()}
            minHeight={getResizeComponentMinHeight()}
          >
            {buttonElement}
          </ResizeComponent>
          : buttonElement
      }
      {
        editMode &&
          <>
            <UndoPortal
              undo={undoHook.undo}
              redo={undoHook.redo}
              isRedoDisabled={undoHook.isRedoDisabled}
              isUndoDisabled={undoHook.isUndoDisabled}
            />
            <Toolbar
              editorProps={editorProps}
              editorSetters={editorSettersWithUndo}
              styles={styles}
              stylesSetters={stylesSettersWithUndo}
              projectType={projectType}
              toggleScriptStyle={undoHook.undoStackMiddleware(editorSetters.scriptStyle)}
              returnFocusToEditor={returnFocusToEditor}
              cellHeight={cellHeight}
              maxCellHeight={maxCellHeight}
              minCellHeight={minCellHeight}
              toggleCellHeight={undoHook.undoStackMiddleware(toggleRowHeight, 'cellHeight') as (newHeight: number) => number}
              cellWidth={cellWidth}
              toggleColumnWidth={undoHook.undoStackMiddleware(toggleColumnWidth, 'cellWidth') as (newWidth: number) => number}
              link={link}
              toggleLink={undoHook.undoStackMiddleware(toggleLink, 'link')}
              layoutId={layoutId}
              disableCellWidthEditing={disableCellWidthEditing}
              height={getResizeComponentHeight()}
              width={getResizeComponentWidth()}
              maxWidth={getMaxWidth()}
              isIncreasingWidthDisabled={isIncreasingWidthDisabled}
              editorState={editorState}
              toggleInlineStyle={undoHook.undoStackMiddleware(editorSetters.inlineStyle)}
              toggleAutoFitContent={undoHook.undoStackMiddleware(toggleAutoFitContent, 'isAutoFitContent')}
              isAutoFitContent={isAutoFitContent}
            />
          </>
      }
    </div>
  );
};

export default CallToAction;
