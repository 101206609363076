import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { deleteCellHeights } from 'containers/ArtboardPreview/actions';
import { cellsHeight as cellsHeightSelector } from 'containers/ArtboardPreview/selectors';
import { setArtboards } from 'containers/Artboards/actions';
import * as artboardsSelectors from 'containers/Artboards/selectors';
import { setDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { setLayouts } from 'containers/Layouts/actions';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import { deleteLayout } from 'containers/Layouts/services/deleteLayout';
import { setSurfaceIds, updateActiveSurfaceId } from 'containers/Project/actions';
import * as projectSelectors from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { deleteSections } from 'containers/Sections/actions';
import { deleteSurfaces } from 'containers/Surfaces/actions';
import * as surfacesSelectors from 'containers/Surfaces/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { Action } from '../models';

export function* deleteSurface(action: Action.IDeleteSurfaceRequest) {
  try {
    const accepted: boolean = yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title: intlGet('ConfirmationWindow.Title', 'DeleteThisScreen'),
        message: intlGet('ConfirmationWindow.Message', 'RemoveThisScreen'),
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Delete'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'Cancel'),
      },
    );

    if (!accepted) {
      return;
    }

    const { id: screenId } = action.payload;
    const cellsHeight: ReturnTypeSaga<typeof cellsHeightSelector> = yield select(cellsHeightSelector);
    const layer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);
    const surfaceIds: ReturnTypeSaga<typeof projectSelectors.surfaceIds> = yield select(projectSelectors.surfaceIds);
    const surfaces: ReturnTypeSaga<typeof surfacesSelectors.surfaces> = yield select(surfacesSelectors.surfaces);
    let artboards: ReturnTypeSaga<typeof artboardsSelectors.artboards> = yield select(artboardsSelectors.artboards);
    let layouts: ReturnTypeSaga<typeof layoutsSelectors.layeredLayouts> = yield select(layoutsSelectors.layeredLayouts);
    let relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    let documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);

    const newSurfaceIds = surfaceIds.filter(surfaceId => surfaceId !== screenId).toArray();
    const artboardId = (surfaces.get(screenId) as Models.ScreenMap).get('artboardId');
    const artboardLayouts = artboards.get(artboardId).get('layoutIds').map(layoutId => layouts.get(layoutId));

    // TODO: delete cell heights in all other cases
    const cellHeightsToDelete = cellsHeight.keySeq().toArray().filter(id => id.startsWith(artboardId));
    const sectionIdsToDelete = artboardLayouts.toArray().map(layout => layout.get('section'));

    artboardLayouts.forEach(layout => ({ layouts, relations, documents } = deleteLayout(
      layout,
      layouts,
      relations,
      documents,
      layer,
    )));

    // reset SSI sources which refer to the screen that will be deleted
    artboards = artboards.map(artboard => artboard.getIn(['ssi', 'source', 'screen']) === screenId
      ? artboard.setIn(['ssi', 'source'], null)
      : artboard,
    );
    artboards = artboards.delete(artboardId);

    yield put(batchActions([
      saveAppState(),
      updateActiveSurfaceId(newSurfaceIds[0]),
      setSurfaceIds(newSurfaceIds),
      deleteSurfaces([screenId]),
      setArtboards(artboards),
      deleteSections(_.uniq(sectionIdsToDelete)),
      setLayouts(layouts),
      setRelations(relations),
      setDocuments(documents),
      deleteCellHeights(cellHeightsToDelete),
    ]));

    yield call([Notifications, Notifications.success], intlGet('Notification.Success', 'ScreenHasBeenDeleted'));
  } catch (error) {
    yield call(handleSagaError, error, 'Surfaces.deleteSurface', 'DeleteScreen');
  }
}
