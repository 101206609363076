import React from 'react';
/* eslint-disable max-len */

const SaveProject = (): JSX.Element => (
  <svg viewBox="0 0 18 18">
    <defs>
      <path d="M9.02013273,14.3346417 C7.37232202,14.3346417 6.03649407,12.9988137 6.03649407,11.351003 C6.03649407,9.7031923 7.37232202,8.36736436 9.02013273,8.36736436 C10.6679434,8.36736436 12.0037075,9.7031923 12.0037075,11.351003 C12.0037075,12.9988137 10.6679434,14.3346417 9.02013273,14.3346417 L9.02013273,14.3346417 Z M12.8345756,0.352205056 L12.8345756,5.71768269 L5.08240426,5.71768269 L5.08240426,0.352205056 L3.06831402,0.352205056 C1.5743867,0.352205056 0.352262546,1.57445696 0.352262546,3.06832041 L0.352262546,14.8677081 C0.352262546,16.3615076 1.5743867,17.5837595 3.06831402,17.5837595 L14.8676378,17.5837595 C16.3615651,17.5837595 17.583817,16.3615076 17.583817,14.8677081 L17.583817,3.68340683 L14.2525514,0.352205056 L12.8345756,0.352205056 Z" id="save-project-path-1" />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Navigate-Card" transform="translate(-987.000000, -51.000000)">
        <g id="3.-Icons/ic_Save" transform="translate(981.000000, 46.000000)">
          <g id="rrr" transform="translate(5.935645, 5.024186)">
            <mask id="save-project-mask-2" fill="white">
              <use xlinkHref="#save-project-path-1" />
            </mask>
            <use id="Mask" fill="#F8AC3E" xlinkHref="#save-project-path-1" />
            <g id="5.-Colors/Orange" mask="url(#save-project-mask-2)" fill="#F9AD3D">
              <g transform="translate(-3.263932, -3.263932)" id="Orange">
                <rect x="0" y="0" width="24.16128" height="24.16128" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SaveProject;
