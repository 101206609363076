import * as Constants from 'const';
import * as Models from 'models';

// We have pretty the same util function on BE
export function getFontFamilyFromBrandStyle(brandStyle: Models.BrandStyleObjMap, brandFonts: Models.BrandFontsList): Models.FontFamily {
  const resultFontFamily: Models.FontFamily = {
    fontFamily: brandStyle.get(Models.BrandStyleProp.FONT_FAMILY),
    characterStyle: null,
  };
  const { fontFamily } = resultFontFamily;

  if (fontFamily.startsWith(Constants.EXTERNAL_REF_KEY)) {
    const [fontFamilyId, charStyleId] = fontFamily.split(Constants.ID_START_KEY)[1].split(Constants.ID_SEPARATOR_KEY);
    const brandFont = brandFonts.find(font => font.get('id') === fontFamilyId);
    const brandCharStyle =
      brandFont && brandFont.get('characterStyles') && brandFont.get('characterStyles').find(charStyle => charStyle.get('id') === charStyleId);

    resultFontFamily.fontFamily = brandFont ? brandFont.get('name') : null;
    resultFontFamily.characterStyle = brandCharStyle ? brandCharStyle.get('name') : null;
  }

  return resultFontFamily;
}
