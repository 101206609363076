import React from 'react';

import DragHotspot from 'components/DragHotspot';
import { ILayoutDragHotspotProps } from './models';
import styles from './styles.module.scss';

const LayoutDragHotspot: React.FC<ILayoutDragHotspotProps> = ({ position }) => (
  <div
    className={styles.layoutDragHotspot}
    style={{ order: position }}
  >
    <DragHotspot />
  </div>
);

export default LayoutDragHotspot;
