import { ActionTypes } from './constants';
import * as Models from './models';

export const saveReusableLayout: Models.ActionCreator.SaveReusableLayout = saveReusableLayoutPayload => ({
  type: ActionTypes.SAVE_LAYOUT,
  payload: {
    saveReusableLayoutPayload,
  },
});

export const generateLayoutPreview: Models.ActionCreator.GenerateLayoutPreview = layoutIds => ({
  type: ActionTypes.GENERATE_LAYOUT_PREVIEW,
  payload: {
    layoutIds,
  },
});

export const cancelLayoutPreviewGeneration: Models.ActionCreator.CancelLayoutPreviewGeneration = () => ({
  type: ActionTypes.CANCEL_LAYOUT_PREVIEW_GENERATION,
});

export const unlinkReusableLayout: Models.ActionCreator.UnlinkReusableLayout = layoutId => ({
  type: ActionTypes.UNLINK_REUSABLE_LAYOUT,
  payload: {
    layoutId,
  },
});
