import _ from 'lodash';

import * as Models from 'models';
import { getSSIElementSourceData } from 'utils/ssi/getSSIElementSourceData';

/**
 * Validates SSI element references on each artboard.
 * If the SSI element refers to nonexistent screen or section,
 * its references will be dropped.
 */
export function validateSSIElements<T extends Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs>({ assets }: T): void {
  const { surfaces: screens, artboards, layouts, sections } = assets;
  _.forEach(screens, (screen) => {
    const artboard = artboards[screen.artboardId];
    const ssiSource: Models.SSISource = _.get(artboard, 'ssi.source');

    if (!ssiSource) {
      return;
    }

    const { sourceScreen, sourceSection } = getSSIElementSourceData(
      ssiSource,
      screens,
      artboards,
      layouts,
      sections,
    );

    if (!sourceScreen || !sourceSection) {
      // eslint-disable-next-line no-console
      console.warn(`The SSI element from the "${screen.name}" screen refers to nonexistent screen or section, its source was dropped`);
      delete artboard.ssi.source;
    }
  });
}
