import * as Immutable from 'immutable';

/**
 * Gets a unique surface name by appending an index
 * @param allSurfaceNames - collection of all surfaces names
 * @param surfaceName - desired surface name
 * @param index - index to append
 */
export function getSurfaceName(allSurfaceNames: Immutable.List<string>, surfaceName: string, index = 0): string {
  const surfaceNameAffix = index ? ` (${index})` : '';
  const newSurfaceName = surfaceName + surfaceNameAffix;

  if (allSurfaceNames.includes(newSurfaceName)) {
    return getSurfaceName(allSurfaceNames, surfaceName, index + 1);
  }

  return newSurfaceName;
}
