import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_SURFACE_ID: null,
    DELETE_UPLOADING_DOCUMENT_ID: null,
    END_LOCAL_SAVE: null,
    GET_PROJECT_DETAILS: null,
    SET_ACTIVE_LAYER: null,
    SET_ACTIVE_PREVIEW_OPTION: null,
    SET_AUTO_SAVE_STATE: null,
    SET_LAST_LOCAL_SAVE_TIME: null,
    SET_LAST_REFRESH_TIME: null,
    SET_SURFACE_IDS: null,
    SET_UPLOADING_DOCUMENT_ID: null,
    START_LOCAL_SAVE: null,
    UPDATE_ACTIVE_SURFACE_ID: null,
    UPDATE_PROJECT_STATE: null,
    UPLOAD_TO_PROMOMATS: null,
  },
  'Project',
);
