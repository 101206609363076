import classNames from 'classnames';
import Immutable, { fromJS } from 'immutable';
import React, { useCallback } from 'react';
import AddImageItem from 'components/AddImageItem';
import ImageBox from 'components/ImageBox';
import SelectItem from 'components/SelectItem';
import AssetAlignment from 'components/Toolbar/Controls/AssetAlignment/List';
import { BackgroundImageType, BackgroundImageTypes, TextByBackgroundImageType } from 'const';
import { createBackgroundImage } from 'factories/style/backgroundImage';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { BackgroundImageProps } from './models';
import styles from './styles.module.scss';

const BackgroundImage: React.FunctionComponent<BackgroundImageProps> = (props) => {
  const { images, showModal, toggleBackgroundImage, documentIdsAreBeingUploaded, className, controlState } = props;
  const backgroundImage = props.backgroundImage || fromJS({ alignment : {} }) as Models.BackgroundImageMap;
  const backgroundImageId = backgroundImage.get('id');
  const backgroundImageType = backgroundImage.get('type');
  const backgroundImageAlignment = backgroundImage.get('alignment');
  const isImageBeingUploaded = documentIdsAreBeingUploaded.has(backgroundImageId);

  const deleteBackgroundImage = useCallback(
    (): void => toggleBackgroundImage(Immutable.fromJS(createBackgroundImage()) as Models.BackgroundImageMap),
    [toggleBackgroundImage],
  );

  const setBackgroundImage = useCallback(
    (imageId: string): void => toggleBackgroundImage(backgroundImage.set('id', imageId)),
    [backgroundImage, toggleBackgroundImage],
  );

  const toggleAlignment = useCallback(
    (alignment: Models.AssetAlignmentMap): void => toggleBackgroundImage(backgroundImage.set('alignment', alignment)),
    [backgroundImage, toggleBackgroundImage],
  );

  const changeBackgroundImageType = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
    event => toggleBackgroundImage(backgroundImage.set('type', event.target.value as BackgroundImageType)),
    [backgroundImage, toggleBackgroundImage],
  );

  return (
    <div className={classNames(styles.BackgroundImage, className)}>
      <div className={styles.title}>{intlGet('EditorToolbar.BackgroundImage', 'Title')}</div>
      <div className={styles.menu}>
        {backgroundImageId
          ?
          <>
            <ImageBox
              deleteImage={deleteBackgroundImage}
              image={images.get(backgroundImageId)}
              isImageBeingUploaded={isImageBeingUploaded}
            />
            <div className={styles.controls}>
              <div className={styles.controlTitle}>{intlGet('EditorToolbar.BackgroundImage', 'ImageScale')}</div>
              <SelectItem
                className={styles.select}
                onChange={changeBackgroundImageType}
                textByValue={TextByBackgroundImageType}
                value={backgroundImageType}
                values={BackgroundImageTypes}
              />
              <div className={styles.controlTitle}>{intlGet('EditorToolbar.BackgroundImage', 'Position')}</div>
              <div className={styles.positions}>
                <AssetAlignment alignment={backgroundImageAlignment} toggleAlignment={toggleAlignment} controlState={controlState} />
              </div>
            </div>
          </>
          :
          <div className={styles.placeholder}>
            <div className={styles.placeholderImage} />
            <div className={styles.placeholderName}>{intlGet('EditorToolbar.BackgroundImage', 'ImageName')}</div>
          </div>
        }
      </div>
      <div className={styles.title}>{intlGet('EditorToolbar.BackgroundImage', 'Images')}</div>
      <ul className={styles.list}>
        <AddImageItem showModal={showModal} />
        {images.valueSeq().map((image) => {
          const imageId = image.get('id');

          return (
            <li className={styles.item} key={imageId}>
              <ImageBox
                image={image}
                isActive={backgroundImageId === imageId}
                isImageBeingUploaded={documentIdsAreBeingUploaded.has(imageId)}
                onImageClick={setBackgroundImage}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BackgroundImage;
