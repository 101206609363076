import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { IComponentItemOwnProps } from 'components/ComponentItem/models';
import SSIElement from 'components/SSIElement';
import { SSIElementStateProps } from 'components/SSIElement/models';
import { isSSIElementOnArtboard } from 'containers/Artboards/selectors';

const mapStateToProps = createStructuredSelector({
  isSSIElementOnArtboard,
});

export default connect<SSIElementStateProps, {}, IComponentItemOwnProps>(mapStateToProps, null)(SSIElement);
