import React from 'react';
/* eslint-disable max-len */

const ClearAllFormatting = (): JSX.Element => (
  <svg width="18px" height="15px" viewBox="0 0 18 15">
    <defs>
      <path d="M1.27,0 L0,1.27 L6.97,8.24 L4.5,14 L7.5,14 L9.07,10.34 L14.73,16 L16,14.73 L1.55,0.27 L1.27,0 Z M4,0 L4,0.18 L6.82,3 L9.22,3 L8.5,4.68 L10.6,6.78 L12.21,3 L18,3 L18,0 L4,0 Z" id="ClearAllFormatting-path" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="ClearAllFormatting-mask-2" fill="white">
        <use xlinkHref="#ClearAllFormatting-path" />
      </mask>
      <use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#ClearAllFormatting-path" />
      <g mask="url(#ClearAllFormatting-mask-2)" fill="#FFFFFF">
        <g transform="translate(-1.000000, -4.000000)">
          <rect x="0" y="0" width="22" height="22" />
        </g>
      </g>
    </g>
  </svg>
);

export default ClearAllFormatting;
