import React from 'react';
import { Button } from 'react-bootstrap';

import DocumentDuplicateItem from 'components/DocumentDuplicateItem';
import { IconType } from 'components/Icon/constants';
import { IconSize } from 'components/Icon/models';
import { EntityType } from 'const';
import { intlGet } from 'utils/intlGet';
import { IDocumentDuplicatesProps, IDocumentDuplicatesState } from './models';
import styles from './styles.module.scss';

const iconData: PartialRecord<EntityType, { iconType: IconType; iconSize: IconSize }> = {
  [EntityType.IMAGE]: {
    iconType: IconType.IMAGE,
    iconSize: 'sm',
  },
  [EntityType.REFERENCE_CITATION]: {
    iconType: IconType.REFERENCE_CITATION,
    iconSize: 'sm',
  },
};

export default class DocumentDuplicates extends React.PureComponent<IDocumentDuplicatesProps, IDocumentDuplicatesState> {
  readonly state: IDocumentDuplicatesState = {
    selectedDocumentNumber: null,
  };

  private onSaveStatusChange = (documentNumber: string): void => {
    this.setState({
      selectedDocumentNumber: documentNumber,
    });
  };

  private saveChanges: React.MouseEventHandler = () => {
    const { hideModal, modalWindowId } = this.props;
    const { selectedDocumentNumber } = this.state;

    hideModal(modalWindowId, selectedDocumentNumber);
  };

  private cancelChange: React.MouseEventHandler = () => {
    const { hideModal, modalWindowId } = this.props;

    hideModal(modalWindowId);
  };

  render() {
    const { selectedDocumentNumber } = this.state;
    const { options } = this.props;
    const documents = options.get('documentDuplicates');
    const documentLinksByNumber = options.get('documentLinksByDocumentNumber');

    return (
      <div className={styles.DocumentDuplicates}>
        <header className={styles.header}>
          <div className={styles.title}>{intlGet('DocumentDuplicatesWindow', 'Title')}</div>
          <div>{intlGet('DocumentDuplicatesWindow', 'Description')}</div>
        </header>
        <section>
          <ul className={styles.duplicatesList}>
            {
              documents.valueSeq().map((document) => {
                const documentNumber = document.get('number');
                const entityType = document.get('entityType');
                const documentInternalInfo = document.get('_internalInfo');
                const imageSource = documentInternalInfo && documentInternalInfo.get('source');
                const { iconSize, iconType } = iconData[entityType];

                return (
                  <DocumentDuplicateItem
                    key={document.get('number')}
                    isSelected={document.get('number') === selectedDocumentNumber}
                    onSelect={this.onSaveStatusChange}
                    documentNumber={documentNumber}
                    link={documentLinksByNumber.get(documentNumber)}
                    documentName={document.get('name')}
                    documentStatus={document.get('status')}
                    iconType={iconType}
                    iconSize={iconSize}
                    imageSource={imageSource}
                  />
                );
              })
            }
          </ul>
        </section>
        <footer>
          <Button onClick={this.cancelChange} variant="secondary">{intlGet('DocumentDuplicatesWindow.Button', 'Cancel')}</Button>
          <Button
            disabled={!selectedDocumentNumber}
            onClick={this.saveChanges}
            variant="primary"
          >
            {intlGet('DocumentDuplicatesWindow.Button', 'Link')}
          </Button>
        </footer>
      </div>
    );
  }
}
