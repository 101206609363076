import { intlGet } from 'utils/intlGet';
import { EntityType } from './EntityType';
import { LayoutType } from './Layout';

export enum ComponentSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type ComponentType = EntityType | LayoutType;

const intlAssetsElement = (type: string): string => intlGet('Assets.Elements.Label', type);

export const ComponentLabel = {
  [EntityType.CALL_TO_ACTION]: intlAssetsElement('CallToAction'),
  [LayoutType.TEXT_ONE_COLUMN]: intlAssetsElement('TextOneColumn'),
  [LayoutType.TEXT_TWO_COLUMN]: intlAssetsElement('TextTwoColumn'),
  [LayoutType.TEXT_THREE_COLUMN]: intlAssetsElement('TextThreeColumn'),
  [LayoutType.TEXT_FOUR_COLUMN]: intlAssetsElement('TextFourColumn'),
  [LayoutType.IMAGE_TEXT_RIGHT]: intlAssetsElement('ImageTextRight'),
  [LayoutType.IMAGE_TEXT_LEFT]: intlAssetsElement('ImageTextLeft'),
  [LayoutType.FULL_WIDTH_IMAGE]: intlAssetsElement('FullWidthImage'),
  [LayoutType.TWO_IMAGE_AND_TEXT]: intlAssetsElement('TwoImageAndText'),
  [LayoutType.THREE_IMAGE_AND_TEXT]: intlAssetsElement('ThreeImageAndText'),
  [LayoutType.FOUR_IMAGE_AND_TEXT]: intlAssetsElement('FourImageAndText'),
  [LayoutType.REFERENCE_CITATION_ELEMENT]: intlAssetsElement('ReferenceCitation'),
  [LayoutType.ABBREVIATIONS_LIST]: intlAssetsElement('Abbreviations'),
  [LayoutType.SPACER]: intlAssetsElement('Spacer'),
  [EntityType.SSI_ELEMENT]: intlAssetsElement('SSIElement'),
};

export const AvailableComponentSizes: PartialRecord<ComponentType, ComponentSize[]> = {
  [EntityType.CALL_TO_ACTION]: [ComponentSize.SMALL, ComponentSize.MEDIUM, ComponentSize.LARGE],
  [LayoutType.SPACER]: [ComponentSize.SMALL, ComponentSize.MEDIUM, ComponentSize.LARGE],
};
