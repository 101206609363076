import Draft from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Immutable from 'immutable';
import _ from 'lodash';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { useDraftjsEditor, Features } from 'modules/draftjs';
import { useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { useLink } from 'components/ArtboardAssets/CallToAction/useLink';
import * as Constants from 'const';
import * as documentsActions from 'containers/Documents/actions';
import * as relationsActions from 'containers/Relations/actions';
import { getActiveLayer } from 'hooks/useActiveLayer';
import { useBeforeEditModeValue } from 'hooks/useBeforeEditModeValue';
import { useProjectType } from 'hooks/useProjectType';
import * as Models from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import * as editorUtils from 'utils/editor';
import { getContainerSize } from 'utils/getContainerSize';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getExtraWidth } from 'utils/getExtraWidth';
import { prioritizeLayeredRelation } from 'utils/prioritizeLayeredRelations';
import { stylesComparator } from 'utils/styles/stylesComparator';
import { toPx } from 'utils/toPx';
import { useStyles } from './hooks/useStyles';
import { CallToActionProps } from './models';
import { ctaStylesToSource } from './styles';

export const useCallToAction = (props: CallToActionProps) => {
  const {
    cellHeight,
    cellWidth,
    currentCountry,
    currentLanguage,
    document,
    editMode,
    flatColorsByRelationId,
    flatFontsByRelationId,
    isResizingRow,
    layoutId,
    layoutRelations,
    relation,
    toggleRowAndNeighborsHeight,
    updateRelationsSilently,
    isAutoFitContent,
    images,
  } = props;

  const projectType = useProjectType();
  const activeLayer = getActiveLayer();
  const relationId = relation.get('id');
  const colors = flatColorsByRelationId.get(relationId);
  const fonts = flatFontsByRelationId.get(relationId);

  const isFirstRendering = useRef(true);
  const container = useRef<HTMLDivElement>(null);
  const editorWrap = useRef<HTMLDivElement>(null);

  const textAbbreviations = useSelector(selectAbbreviationsData);

  const [styles, stylesSetters, stylesCSS] = useStyles(
    relation,
    activeLayer,
    { colors, images, defaultAssetBackgroundColor: Constants.DefaultCallToActionBackgroundColor },
    Constants.ProjectsConfig[projectType],
  );
  const {
    assetPadding,
    border,
    fitToCell,
    height,
    padding,
    width,
  } = styles;

  const getInitialEditorState = (): Draft.EditorState => editorUtils.getEditorStateFromCallToAction(
    document, prioritizeLayeredRelation(relation, activeLayer), colors, fonts, projectType,
  );

  const draftjs = useDraftjsEditor(
    getInitialEditorState,
    {
      projectType,
      colors,
      fonts,
      abbreviationsData: textAbbreviations,
    },
    [Features.ABBREVIATIONS, Features.DECORATORS, Features.FONT_COLOR, Features.INLINE_STYLE_EXTENDED],
  );
  const { editorState, resetEditorState, returnFocusToEditor } = draftjs;

  useEffect(() => {
    resetEditorState(getInitialEditorState());
  }, [document, activeLayer]);

  const { link, toggleLink } = useLink(document);

  const dispatch = useDispatch();

  const resetHeight = useCallback((
    heightValue: number,
    paddingValue: Models.PaddingMap,
    borderValue?: Models.BorderMap,
    currentLayeredRelation?: Models.LayeredRelationsMap,
  ): void => {
    const style = Immutable.Map({
      padding: paddingValue,
      border: borderValue || { width: { top: 0, right: 0, bottom: 0, left: 0 } },
    }) as Models.CommonStylesMap;
    const extraHeight = getExtraHeight(style);
    const newVerifiedHeight = heightValue + extraHeight;

    const contentHeights = ContentHeightCache.getInstance();
    contentHeights.setItem(relationId, heightValue + extraHeight);

    const shouldUpdateCellHeight = isAutoFitContent && !isResizingRow
      ? newVerifiedHeight !== cellHeight
      : newVerifiedHeight > cellHeight;

    if (cellHeight && shouldUpdateCellHeight) {
      toggleRowAndNeighborsHeight(newVerifiedHeight, currentLayeredRelation);
    }
  }, [relationId, isAutoFitContent, isResizingRow, cellHeight]);

  const getMaxWidth = (currentPudding = padding, currentBorder = border): number => {
    const extraWidth = getExtraWidth(Immutable.fromJS({ border: currentBorder, padding: currentPudding }));

    return Math.max(cellWidth - extraWidth, 0);
  };

  const resetWidth = useCallback((
    paddingValue: Models.PaddingMap,
    borderValue?: Models.BorderMap,
    cb?: (maxWidth: number) => void,
  ): void => {
    const maxWidth = getMaxWidth(paddingValue, borderValue);

    if (width > maxWidth) {
      if (_.isFunction(cb)) {
        cb(maxWidth);
      } else {
        stylesSetters.width(maxWidth);
      }
    }
  }, [stylesSetters, width]);

  useEffect(() => {
    if (editMode) {
      resetWidth(padding, border, (newWidth: number): void => {
        // if RL/GRL is placed into smaller width than it comes from,
        // the width should be adjusted silently, as it's done automatically it should not trigger update modal
        const updatedLayoutRelations = layoutRelations.setIn([relationId, 'styles', activeLayer, 'width'], newWidth);
        updateRelationsSilently(updatedLayoutRelations, layoutId);
      });
    }
  }, [editMode, width]);

  const prevCellWidth = useBeforeEditModeValue(cellWidth, editMode);
  const prevCellHeight = useBeforeEditModeValue(cellHeight, editMode);

  useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false;
    } else {
      if (editMode) {
        returnFocusToEditor();
      } else {
        const updatedRelation = relation.updateIn(['styles', activeLayer], values => values.withMutations(
          value => ctaStylesToSource(styles, value)
            .set('isAutoFitContent', isAutoFitContent),
        ));
        const rawContent = JSON.stringify(Draft.convertToRaw(editorState.getCurrentContent()));
        const title = editorState.getCurrentContent().getPlainText();
        const text = stateToHTML(editorState.getCurrentContent(), editorUtils.getTextStateToHTMLOptions(colors, fonts));
        const updatedDocument = document.withMutations(
          document => document
            .set('name', title)
            .set('text', text)
            .set('rawContent', rawContent)
            .set('link', link.trim())
            .set('isMockDocument', false)
            // https://issues.merck.com/browse/DCC-4748
            .set('language', currentLanguage)
            .set('country', currentCountry),
        );
        const actions: Models.IAction[] = [];

        if (
          prevCellWidth !== cellWidth ||
            prevCellHeight !== cellHeight ||
            !_.isEqualWith(updatedRelation.getIn(['styles', activeLayer]).toJS(), relation.getIn(['styles', activeLayer]).toJS(), stylesComparator)
        ) {
          actions.push(relationsActions.updateLayeredRelations(layoutRelations.set(updatedRelation.get('id'), updatedRelation)));
        }

        if (
          updatedDocument.get('link') !== document.get('link') ||
          updatedDocument.get('name') !== document.get('name') ||
          updatedDocument.get('rawContent') !== document.get('rawContent')
        ) {
          actions.push(documentsActions.setDocument(updatedDocument));
        }

        if (actions.length !== 0) {
          dispatch(batchActions(actions));
        }
      }
    }
  }, [editMode]);

  useEffect(() => {
    const { current } = editorWrap;
    if (current) {
      current.style.height = toPx(current.scrollHeight);
    }
  }, [editMode]);

  useEffect(() => {
    const { current } = editorWrap;
    if (current) {
      current.style.height = 'auto';
    }
  }, [editorState]);

  const onResize = (newHeight: number): void => {
    stylesSetters.fitToCell(false);
    stylesSetters.height(_.ceil(newHeight));
  };

  useEffect(
    () => {
      if (prevCellHeight < height || isAutoFitContent) {
        resetHeight(height, padding, border, layoutRelations);
      }
    },
    [
      height,
      width,
      padding,
      border,
      isAutoFitContent,
      fitToCell,
      editorState,
    ],
  );

  const finishResizing = (actualWidth: number, actualHeight: number): void => {
    stylesSetters.height(_.round(actualHeight));
    stylesSetters.width(_.round(actualWidth));
    stylesSetters.fitToCell(false);
  };

  const getMaxHeight = (): number => {
    if (!container.current) {
      return null;
    }

    return getContainerSize(container.current).height;
  };

  const getHeight = (): number => fitToCell ? getMaxHeight() : _.round(height);
  const getWidth = (): number => fitToCell ? getMaxWidth() : _.round(width);

  const assetVerticalPadding = assetPadding
    ? (assetPadding.get(Constants.BoxPropertySide.TOP) + assetPadding.get(Constants.BoxPropertySide.BOTTOM))
    : 0;
  const assetHorizontalPadding = assetPadding
    ? (assetPadding.get(Constants.BoxPropertySide.LEFT) + assetPadding.get(Constants.BoxPropertySide.RIGHT))
    : 0;

  const getResizeComponentHeight = (): number => assetVerticalPadding > getHeight() ? assetVerticalPadding : getHeight();
  const getResizeComponentMinHeight = (): number => assetVerticalPadding + (editorWrap.current ? editorWrap.current.scrollHeight : 0);
  const getResizeComponentWidth = (): number => assetHorizontalPadding > getWidth() ? assetHorizontalPadding : getWidth();

  const draftjsSetters = draftjs.setters;
  const modifiedDraftjsSetters = useMemo((): typeof draftjs.setters => ({
    ...draftjsSetters,
    fontSize: (value: number): void => {
      const minHeight = Constants.CallToActionLineHeightModifier * value;
      if (minHeight > styles.height) {
        resetHeight(styles.height, styles.padding, styles.border);
        stylesSetters.height(minHeight);
      }
      draftjsSetters.fontSize(value);
      returnFocusToEditor();
    },
    fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap): void => {
      draftjsSetters.fontFamily(font, characterStyle);
      returnFocusToEditor();
    },
  }), [resetHeight, returnFocusToEditor, draftjsSetters, styles, stylesSetters]);

  const modifiedStylesSetters = useMemo((): typeof stylesSetters => ({
    ...stylesSetters,
    border: (value: Models.BorderMap): void => {
      resetHeight(styles.height, styles.padding, value);
      resetWidth(styles.padding, value);
      stylesSetters.border(value);
    },
    padding: (value: Models.PaddingMap): void => {
      resetHeight(styles.height, value, styles.border);
      resetWidth(value, styles.border);
      stylesSetters.padding(value);
    },
    fitToCell: (value): void => {
      if (value) {
        return stylesSetters.fitToCell(true);
      }
      if (styles.fitToCell) {
        stylesSetters.height(getMaxHeight());
        stylesSetters.width(getMaxWidth());
      }
      stylesSetters.fitToCell(false);
    },
    height: (value): void => {
      resetHeight(value, styles.padding, styles.border);
      stylesSetters.height(value);
      if (styles.fitToCell) {
        stylesSetters.width(getMaxWidth());
        stylesSetters.fitToCell(false);
      }
    },
    width: (value): void => {
      stylesSetters.width(value);
      if (styles.fitToCell) {
        stylesSetters.height(getMaxHeight());
        stylesSetters.fitToCell(false);
      }
    },
  }), [styles, stylesSetters, resetHeight, resetWidth, getMaxHeight, getMaxWidth]);

  return {
    draftjs: {
      ...draftjs,
      setters: modifiedDraftjsSetters,
    },

    container,
    editorWrap,

    styles,
    stylesCSS,
    stylesSetters: modifiedStylesSetters,

    link,
    toggleLink,

    getHeight,
    getMaxHeight,

    getWidth,
    getMaxWidth,

    getResizeComponentHeight,
    getResizeComponentMinHeight,
    getResizeComponentWidth,

    finishResizing,
    onResize,

    brandProps: {
      colors,
      fonts,
    },
  };
};
