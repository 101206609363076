import _ from 'lodash';

import { createScreens, screenFactory as onlineSurfaceFactory } from 'factories/screenFactory';
import { Screens } from 'models';
import { intlGet } from 'utils/intlGet';

export function prepareScreens(screens: Screens, screenDefinitionId?: string): Screens {
  if (!_.isEmpty(screens)) {
    return createScreens(screens);
  }

  const name = intlGet('Artboard', 'DefaultScreenName', { count: 1 });
  const defaultScreen = onlineSurfaceFactory({ name, screenDefinitionId });

  return { [defaultScreen.id]: defaultScreen };
}
