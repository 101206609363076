import Immutable from 'immutable';
import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { createLayers } from 'utils/layers';
import { isLayoutLayered } from 'utils/layouts/isLayoutLayered';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const layoutsInitialState = Immutable.Map() as State.IState;

const layoutsReducer: Models.StateInjectedReducer<State.IState> = (state = layoutsInitialState, action, appState) => {
  switch (action.type) {
    case ActionTypes.SET_LAYOUT: return setLayout(state, action, appState);
    case ActionTypes.SET_LAYOUTS: return setLayouts(state, action, appState);
    case ActionTypes.ADD_LAYOUTS: return addLayouts(state, action, appState);
    case ActionTypes.DELETE_LAYOUTS: return deleteLayouts(state, action);
    default: return state;
  }
};

export const setLayout: Models.StateInjectedReducer<State.IState, Action.ISetLayout> = (state, action, appState) => {
  const layout = Immutable.fromJS(action.payload.layout) as Models.LayeredLayoutMap | Models.LayoutMap;
  const id = layout.get('id');

  if (isLayoutLayered(layout)) {
    return state.set(id, layout);
  }

  // TODO: use getLayerFromAppState
  const layer = appState.getIn(['project', 'activeLayer']);

  return addLayoutToLayeredCollection(layout, state, state, layer);
};

export const setLayouts: Models.StateInjectedReducer<State.IState, Action.ISetLayouts> = (state, action, appState) => {
  const { layouts } = action.payload;

  if (isLayoutsLayered(layouts)) {
    return Immutable.fromJS(layouts);
  }

  // TODO: use getLayerFromAppState
  const layer = appState.getIn(['project', 'activeLayer']);
  const updatedLayouts = Immutable.fromJS(layouts) as Models.CombinedLayoutsMap;

  return updatedLayouts.reduce(
    (updatedLayoutsState, layout) => addLayoutToLayeredCollection(layout, updatedLayoutsState, state, layer),
    Immutable.Map() as State.IState,
  );
};

export const addLayouts: Models.StateInjectedReducer<State.IState, Action.IAddLayouts> = (state, action, appState) => {
  const layouts = Immutable.fromJS(action.payload.layouts) as Models.LayeredLayoutsMap | Models.LayoutsMap;

  if (isLayoutsLayered(layouts)) {
    return layouts.reduce((state, layout) => state.set(layout.get('id'), layout), state);
  }

  // TODO: use getLayerFromAppState
  const layer = appState.getIn(['project', 'activeLayer']);

  return layouts.reduce((state, layout) => addLayoutToLayeredCollection(layout, state, state, layer), state);
};

export const deleteLayouts: Models.Reducer<State.IState, Action.IDeleteLayouts> = (state, action) => {
  const { layoutIds } = action.payload;

  return state.deleteAll(layoutIds);
};

export default layoutsReducer;

function isLayoutsLayered(layouts: Models.LayeredCombinedLayouts | Models.CombinedLayouts): layouts is Models.LayeredCombinedLayouts;
function isLayoutsLayered(layouts: Models.LayeredCombinedLayoutsMap | Models.CombinedLayoutsMap): layouts is Models.LayeredCombinedLayoutsMap;
function isLayoutsLayered(
  layouts: Models.LayeredCombinedLayouts | Models.CombinedLayouts | Models.LayeredCombinedLayoutsMap | Models.CombinedLayoutsMap,
): layouts is (Models.LayeredCombinedLayoutsMap | Models.LayeredCombinedLayouts);
function isLayoutsLayered(
  layouts: Models.LayeredCombinedLayouts | Models.CombinedLayouts | Models.LayeredCombinedLayoutsMap | Models.CombinedLayoutsMap,
): boolean {
  const layoutsToCheck = isImmutable(layouts as Models.LayoutsMap)
    ? (layouts as Models.LayoutsMap).toJS() as Models.LayeredCombinedLayouts | Models.CombinedLayouts
    : layouts as Models.LayeredCombinedLayouts | Models.CombinedLayouts;

  return _.every(layoutsToCheck, isLayoutLayered);
}

function addLayoutToLayeredCollection(
  layout: Models.CombinedLayoutMap,
  layouts: Models.LayeredCombinedLayoutsMap,
  existingLayouts: Models.LayeredCombinedLayoutsMap,
  layer: Layer,
): Models.LayeredCombinedLayoutsMap {
  const id = layout.get('id');
  const updatedLayout = (layout as Models.LayoutMap).update('documentId', documentId => Immutable.fromJS(createLayers({
    [layer]: documentId,
  }))) as Models.LayeredCombinedLayoutMap;
  const existingLayout = existingLayouts.get(id);

  if (!existingLayout) {
    return layouts.set(id, updatedLayout);
  }

  const existingDocumentId = existingLayout.get('documentId');
  const updatedDocumentId = existingDocumentId.set(layer, layout.get('documentId'));

  return layouts.set(id, (updatedLayout as Models.LayeredLayoutMap).set('documentId', updatedDocumentId));
}
