import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { isCombinedLayoutDocument } from 'utils/entityType';

export function removeDuplicateLayouts<T extends Models.ImportTranslationPackageArgs>(args: T): T {
  const { layouts, documents } = args.assets;

  const duplicateLayoutDocumentIdsByTranslatedDocumentId = _(documents)
    .pickBy<Models.CombinedLayoutDocument>(isCombinedLayoutDocument)
    .groupBy(document => document.documentId)
    .pickBy(documents => _.size(documents) > 1)
    .reduce(
      (duplicateLayoutDocumentIdsByTranslatedDocumentId, duplicateDocuments) => {
        const priorityDocumentId = _.get(_.first(duplicateDocuments), 'id');
        const documentIdsToReplace = _(duplicateDocuments).map(document => document.id).filter(id => id !== priorityDocumentId).value();

        duplicateLayoutDocumentIdsByTranslatedDocumentId[priorityDocumentId] = documentIdsToReplace;

        return duplicateLayoutDocumentIdsByTranslatedDocumentId;
      },
      {} as Record<string, string[]>,
    );

  _.forEach(duplicateLayoutDocumentIdsByTranslatedDocumentId, (oldLayoutDocumentIds, newLayoutDocumentId) => {
    _.forEach(oldLayoutDocumentIds, (oldLayoutDocumentId) => {
      const translatedNewLayoutDocument = documents[newLayoutDocumentId];
      const translatedOldLayoutDocumentSource = _.get(documents, [newLayoutDocumentId, 'documentSource']);
      // combine layout document sources
      translatedNewLayoutDocument.documentSource = _.uniq([...translatedNewLayoutDocument.documentSource, ...translatedOldLayoutDocumentSource]);

      // delete duplicate documents
      delete documents[oldLayoutDocumentId];

      // replace the documentId fields in layouts
      _.forEach(layouts, (layout) => {
        if (layout.documentId[Layer.TRANSLATED] === oldLayoutDocumentId) {
          layout.documentId[Layer.TRANSLATED] = newLayoutDocumentId;
        }
      });
    });
  });

  return args;
}
