import React from 'react';
/* eslint-disable max-len */

const FillColor = (): JSX.Element => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <defs>
      <path id="fillColorPath" d="M14.56,8.94 C15.15,9.53 15.15,10.48 14.56,11.06 L9.06,16.56 C8.77,16.85 8.38,17 8,17 C7.62,17 7.23,16.85 6.94,16.56 L1.44,11.06 C0.85,10.48 0.85,9.53 1.44,8.94 L6.59,3.79 L4.21,1.41 L5.62,0 L14.56,8.94 Z M3.21,10 L12.79,10 L8,5.21 L3.21,10 Z M17,11.5 C17,11.5 19,13.67 19,15 C19,16.1 18.1,17 17,17 C15.9,17 15,16.1 15,15 C15,13.67 17,11.5 17,11.5 Z" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="fillColorMask" fill="white">
        <use href="#fillColorPath" />
      </mask>
      <use fill="#000000" fillRule="nonzero" href="#fillColorPath" />
      <g mask="url(#fillColorMask)" fill="#FFFFFF">
        <g transform="translate(-1.000000, -1.000000)">
          <rect x="0" y="0" width="20" height="20" />
        </g>
      </g>
    </g>
  </svg>
);

export default FillColor;
