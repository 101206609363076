import _ from 'lodash';
import guid from 'uuid';

import { createSectionStyles } from 'factories/style';
import * as Models from 'models';

export const createSection = ({
  id = guid(),
  name = null,
  screenDefinition = null,
  styles,
} = {} as Partial<Models.Section>): Models.Section => ({
  id,
  name,
  screenDefinition,
  styles: createSectionStyles(styles),
});

export const createSectionsFromScreenDefinition = (
  sections: Partial<Models.MasterScreen.Section>[],
  screenDefinitionId: string,
): Models.Sections => _.reduce(
  sections,
  (sections, section) => {
    const sectionEntity = createSection({
      name: section.name,
      screenDefinition: screenDefinitionId,
    });
    sections[sectionEntity.id] = sectionEntity;

    return sections;
  },
  {} as Models.Sections,
);

export const createSections = (sections: Models.Sections): Models.Sections => _.reduce(
  sections,
  (sections, section) => {
    const createdSection = createSection(section);
    sections[createdSection.id] = createdSection;

    return sections;
  },
  {} as Models.Sections,
);
