import _ from 'lodash';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { removeMissingAbbreviationsFromReusableLayout } from 'modules/Abbreviations/utils/removeMissingAbbreviationsFromReusableLayout';
import { batchActions } from 'redux-batched-actions';
import { call, put, select, spawn } from 'redux-saga/effects';

import { cancelLastUndoState, setLastEditedLayoutId, updateDragHotspotPosition } from 'containers/App/actions';
import { dragHotspotGroupLayoutId, dragHotspotPosition } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { brandDefinition } from 'containers/BrandDefinition/selectors';
import { UIFontFaces } from 'containers/Common/selectors';
import { downloadImages, mergeDocuments, getLayoutsThumbnails } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import * as projectSelectors from 'containers/Project/selectors';
import { mergeRelations } from 'containers/Relations/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { appendCustomFontFaces } from 'services/appendFontFace';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { findDuplicateIdByDocumentId } from 'utils/findDocumentDuplicateId';
import { convertLayoutToLayered } from 'utils/layers';
import { updateGroupLayoutInstances } from 'utils/layouts/updateGroupLayoutInstances';
import { prioritizeLayeredLayout, prioritizeLayeredLayouts } from 'utils/prioritizeLayeredLayouts';
import { addReusableLayoutToArtboard } from 'utils/reusableLayouts/addReusableLayoutToArtboard';
import { addReusableLayoutToGroupLayout } from 'utils/reusableLayouts/addReusableLayoutToGroupLayout';
import { getUsingLayoutFontsNotExistsInProject } from 'utils/reusableLayouts/getUsingLayoutFontsNotExistsInProject';
import { addLayouts } from '../actions';
import { Action } from '../models';

export function* dropReusableLayout(action: Action.IDropReusableLayout) {
  try {
    const actions: Models.IAction[] = [saveAppState()];
    const { documentId, sectionId, storyCardId } = action.payload;
    const layouts = (yield select(layeredLayoutsSelector)).toJS() as Models.LayeredCombinedLayouts;
    const prevLayouts = _.cloneDeep(layouts);
    const artboard = (yield select(activeArtboard)).toJS() as Models.Artboard;
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const textAbbreviationDocuments = (yield select(selectAbbreviationsData)).toJS() as Models.TextAbbreviationDocumentsArray;
    const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;
    const position: ReturnTypeSaga<typeof dragHotspotPosition> = yield select(dragHotspotPosition);
    const groupLayoutId: ReturnTypeSaga<typeof dragHotspotGroupLayoutId> = yield select(dragHotspotGroupLayoutId);
    const layer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);

    const layoutDocumentKeyPath = storyCardId ? [storyCardId, 'documents', documentId] : [documentId];
    let layoutDocument = _.get(documents, layoutDocumentKeyPath) as Models.ReusableLayout;

    layoutDocument = removeMissingAbbreviationsFromReusableLayout(layoutDocument, textAbbreviationDocuments);

    // If layout has been dropped from assets collection and such document
    // exists in project documents - we need to use project one
    const duplicateLayoutDocumentId = findDuplicateIdByDocumentId(layoutDocument, documents as Models.Documents);

    if (duplicateLayoutDocumentId) {
      layoutDocument = documents[duplicateLayoutDocumentId] as Models.ReusableLayout;
    }

    const groupLayout = layouts[groupLayoutId] as Models.LayeredGroupLayout;
    const result: ReturnTypeSaga<typeof addReusableLayoutToArtboard | typeof addReusableLayoutToGroupLayout> = groupLayout
      ? yield call(
        addReusableLayoutToGroupLayout,
        layoutDocument,
        sectionId,
        position,
        groupLayout,
        documents,
        layouts,
        projectBrandDefinition,
        layer,
        undefined,
        true,
      )
      : yield call(
        addReusableLayoutToArtboard,
        layoutDocument,
        sectionId,
        position,
        artboard,
        documents,
        layouts,
        projectBrandDefinition,
        layer,
        undefined,
        true,
      );

    const groupLayoutDocumentId = groupLayout && groupLayout.documentId[layer];
    if (groupLayoutDocumentId) {
      const groupLayoutDocument = documents[groupLayoutDocumentId];

      const isEditedLayout = !(groupLayoutDocument as Models.GroupLayoutDocument).isEdited;
      actions.push(cancelLastUndoState(isEditedLayout));
    }

    let updatedLayouts = result.layouts;

    if (groupLayout) {
      updatedLayouts = _.mapValues(
        updateGroupLayoutInstances(prioritizeLayeredLayout(groupLayout, layer), prioritizeLayeredLayouts(_.assign(layouts, updatedLayouts), layer)),
        layout => convertLayoutToLayered(layout, layer),
      );
      actions.push(setLastEditedLayoutId(groupLayoutId));
    }

    actions.push(
      mergeRelations(result.relations),
      mergeDocuments(result.documents),
      addLayouts(updatedLayouts),
      updateDragHotspotPosition(null),
    );

    const { artboard: updatedArtboard } = result as ReturnTypeSaga<typeof addReusableLayoutToArtboard>;
    if (updatedArtboard) {
      actions.push(updateArtboard(updatedArtboard));
    }

    yield put(batchActions(actions));
    if (result.newImages) {
      yield put(downloadImages(result.newImages));
    }

    const layoutFontsNotExistsInProject = getUsingLayoutFontsNotExistsInProject(
      result.relations,
      projectBrandDefinition,
      prevLayouts,
      [documentId],
      layer,
    );
    if (layoutFontsNotExistsInProject.length > 0) {
      yield spawn([Notifications, Notifications.showMissingFonts], layoutFontsNotExistsInProject);
    }

    const fontFaces: ReturnTypeSaga<typeof UIFontFaces> = yield select(UIFontFaces);
    appendCustomFontFaces(fontFaces.toJS());
    yield put(getLayoutsThumbnails(result.documents));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.dropReusableLayout', 'DropReusableLayout');
  }
}
