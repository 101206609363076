import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LinkDropdown from 'components/Toolbar/Dropdowns/Link';
import { activeSurfaceId, projectType } from 'containers/Project/selectors';
import { orderedSurfaces } from 'containers/Surfaces/selectors';

const mapStateToProps = createStructuredSelector({
  activeScreenId: activeSurfaceId,
  projectType,
  screens: orderedSurfaces,
});

export default connect(mapStateToProps)(LinkDropdown);
