import { AssetAlignmentDirection, TextAlignmentStyleMap } from 'const';
import { AssetAlignmentMap } from 'models/styles';

export const getReferenceElementAlignment = (alignment: AssetAlignmentMap): React.CSSProperties => {
  if (!alignment) {
    return {};
  }

  return {
    ...TextAlignmentStyleMap[alignment.get(AssetAlignmentDirection.HORIZONTAL)] as React.CSSProperties,
    ...{ verticalAlign: alignment.get(AssetAlignmentDirection.VERTICAL) } as React.CSSProperties,
  };
};
