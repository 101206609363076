import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { ProjectsConfig } from 'const';
import { setLastEditedLayoutId } from 'containers/App/actions';
import { activeLayer as activeLayerSelector, projectType as projectTypeSelector } from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createLayeredRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { toImmutable } from 'utils/immutable';
import { recalculateRowsAndNeighborsHeight } from 'utils/rowsHeight';
import { Action } from '../models';

export function* addLayoutRow(action: Action.IAddLayoutColumn) {
  try {
    const { layout } = action.payload;
    const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const activeLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);
    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const layoutRelationId = layout.get('relationId');
    const layoutRelation = relations.get(layoutRelationId) as Models.ColumnRelationMap;
    const { defaultCellHeight } = ProjectsConfig[projectType];

    const newCell = createLayeredRelation(undefined, { layer: activeLayer });

    const updatedLayoutRelation = layoutRelation
      .update('relationIds', relationIds => relationIds.push(newCell.id))
      .updateIn(['styles', 'rowsHeight'], rowsHeight => rowsHeight.push(0));

    const updatedRelations = relations
      .set(newCell.id, toImmutable(newCell))
      .set(layoutRelationId, updatedLayoutRelation);

    yield put(batchActions([
      saveAppState(),
      setLastEditedLayoutId(layout.get('id')),
      setRelations(recalculateRowsAndNeighborsHeight(newCell.id, updatedRelations, -defaultCellHeight)),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.addLayoutColumn', 'AddLayoutColumn');
  }
}
