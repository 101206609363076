import React from 'react';
/* eslint-disable max-len */

const ToolbarItalic = (): JSX.Element => (
  <svg viewBox="0 0 4 11">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fontSize="14" fontStyle="italic" fontWeight="normal">
      <g id="Text-Edit-Widget" transform="translate(-422.000000, -24.000000)" fill="#FFFFFF">
        <g transform="translate(146.000000, 14.552765)">
          <text id="i">
            <tspan x="275" y="20.447235">i</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default ToolbarItalic;
