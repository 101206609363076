import Draft from 'draft-js';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';

export function getFontColor<T extends boolean | undefined>(
  isEnabled: T,
  editorState: Draft.EditorState,
  colors: T extends true ? Models.BrandColorsList : unknown,
): T extends true ? Models.BrandColorMap | undefined : undefined {
  if (!isEnabled) {
    return undefined;
  }

  const colorHex = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR) || '';
  const colorTint = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR_TINT) || 0;
  const colorName = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR_NAME) || '';
  const fontColor = editorUtils.getBrandColor(
    colors as Models.BrandColorsList,
    colorName || colorHex,
    colorTint,
  );

  return fontColor as T extends true ? Models.BrandColorMap | undefined : undefined;
}

type Setter<T> = T extends true ? ((color: Models.BrandColorMap, editorState?: Draft.EditorState) => void) : undefined;

export function createFontColorSetter<T extends boolean | undefined>(
  isEnabled: T,
  getEditorState: () => Draft.EditorState,
  setEditorState: (state: Draft.EditorState) => void,
  brandStyle: T extends true ? Models.BrandStyleMap | undefined : unknown, // brandStyle is not used for CTA
  colors: T extends true ? Models.BrandColorsList : unknown,
): Setter<T> {
  if (!isEnabled) {
    return undefined as Setter<T>;
  }

  const setter = (brandColor: Models.BrandColorMap | undefined, currentEditorState?: Draft.EditorState): void => {
    const value = brandColor
      ? brandColor.toJS() as BrandDefinition.BrandColorLike
      : (
        brandStyle && BrandDefinition.getDefaultBrandColor(
          brandStyle as Models.BrandStyleMap,
          colors as Models.BrandColorsList,
        )
      );
    const { name, HEX, tint } = value || {};

    const state = currentEditorState || getEditorState();
    let nextEditorState = editorUtils.toggleCustonInlineStyleValue(
      state,
      Constants.StylePrefix.FONT_COLOR,
      HEX || Constants.DefaultCustomStyle.FONT_COLOR,
    );
    nextEditorState = editorUtils.toggleCustonInlineStyleValue(
      nextEditorState,
      Constants.StylePrefix.FONT_COLOR_NAME,
      name ?? '',
    );
    nextEditorState = editorUtils.toggleCustonInlineStyleValue(
      nextEditorState,
      Constants.StylePrefix.FONT_COLOR_TINT,
      tint ?? 0,
    );

    setEditorState(nextEditorState);
  };

  return setter as Setter<T>;
}
