import { connect } from 'react-redux';

import ChooseScreenFormat from 'components/ModalWindows/ChooseScreenFormat';
import { hideModal } from 'containers/ModalManager/actions';

const mapDispatchToProps = {
  hideModal,
};

export default connect(null, mapDispatchToProps)(ChooseScreenFormat);
