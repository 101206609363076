import FourImageAndText from 'assets/images/FourImageAndText';
import FullWidthImage from 'assets/images/FullWidthImage';
import ImageTextLeft from 'assets/images/ImageTextLeft';
import ImageTextRight from 'assets/images/ImageTextRight';
import TextFourColumn from 'assets/images/TextFourColumn';
import TextOneColumn from 'assets/images/TextOneColumn';
import TextThreeColumn from 'assets/images/TextThreeColumn';
import TextTwoColumn from 'assets/images/TextTwoColumn';
import ThreeImageAndText from 'assets/images/ThreeImageAndText';
import TwoImageAndText from 'assets/images/TwoImageAndText';
import Immutable from 'immutable';
import React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import LayoutItem from 'components/LayoutItem';
import { LayoutType } from 'const';
import { intlGet } from 'utils/intlGet';
import { ILayoutsListContainerProps } from './models';
import styles from './styles.module.scss';

const LayoutIconsByType: PartialRecord<LayoutType, React.StatelessComponent> = {
  [LayoutType.TEXT_ONE_COLUMN]: TextOneColumn,
  [LayoutType.TEXT_TWO_COLUMN]: TextTwoColumn,
  [LayoutType.TEXT_THREE_COLUMN]: TextThreeColumn,
  [LayoutType.TEXT_FOUR_COLUMN]: TextFourColumn,
  [LayoutType.IMAGE_TEXT_RIGHT]: ImageTextRight,
  [LayoutType.IMAGE_TEXT_LEFT]: ImageTextLeft,
  [LayoutType.FULL_WIDTH_IMAGE]: FullWidthImage,
  [LayoutType.TWO_IMAGE_AND_TEXT]: TwoImageAndText,
  [LayoutType.THREE_IMAGE_AND_TEXT]: ThreeImageAndText,
  [LayoutType.FOUR_IMAGE_AND_TEXT]: FourImageAndText,
};

const layouts = Object.keys(LayoutIconsByType) as LayoutType[];

const LayoutListContainer: React.FunctionComponent<ILayoutsListContainerProps> = (props) => {
  const { isReferenceCitationElementOnArtboard, toggleDragState } = props;

  const {
    expanded,
    toggle,
  } = useToggle({ isExpand: true });

  return (
    <div className={styles.LayoutListContainer} data-title="blank-layouts">
      <Header
        title={intlGet('Layouts', 'BlankLayouts', { count: layouts.length })}
        expanded={expanded}
        onClick={toggle}
      />
      {
        expanded &&
        <List
          layout="grid"
          // TODO: define types properly
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          elements={Immutable.List(layouts) as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderElement={(type: any) => (
            <LayoutItem
              key={type}
              type={type}
              icon={LayoutIconsByType[type]}
              toggleDragState={toggleDragState}
              isReferenceCitationElementOnArtboard={isReferenceCitationElementOnArtboard}
            />
          )}
        />
      }
    </div>
  );
};

export default LayoutListContainer;
