import _ from 'lodash';

import { Box, Point } from 'models';
import { MEDIUM_EDGE_SIZE, SMALL_EDGE_SIZE } from './constants';

export const getNumberBetween = (min: number, max: number, val: number): number => Math.floor(
  _.clamp(val, min, max),
);

export const isPointInBox = (point: Point, box: Box) => {
  const { x, w, y, h } = box;

  return _.inRange(point.x, x, x + w) && _.inRange(point.y, y, y + h);
};

export const createHorizontalStrength = (edgeSize: number) => (box: Box, point: Point): number => {
  const actualEdgeSize = Math.min(box.w / 2, edgeSize);

  if (isPointInBox(point, box)) {
    if (point.x < box.x + actualEdgeSize) {
      return (point.x - box.x - actualEdgeSize) / actualEdgeSize;
    }

    if (point.x > (box.x + box.w - actualEdgeSize)) {
      return -(box.x + box.w - point.x - actualEdgeSize) / actualEdgeSize;
    }
  }

  return 0;
};

export const createVerticalStrength = (edgeSize: number) => (box: Box, point: Point): number => {
  const actualEdgeSize = Math.min(box.h / 2, edgeSize);

  if (isPointInBox(point, box)) {
    if (point.y < box.y + actualEdgeSize) {
      return (point.y - box.y - actualEdgeSize) / actualEdgeSize;
    }

    if (point.y > (box.y + box.h - actualEdgeSize)) {
      return -(box.y + box.h - point.y - actualEdgeSize) / actualEdgeSize;
    }
  }

  return 0;
};

export const createSmallVerticalStrength = createVerticalStrength(SMALL_EDGE_SIZE);
export const createMediumVerticalStrength = createVerticalStrength(MEDIUM_EDGE_SIZE);
