import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import { ProjectsConfig } from 'const';
import { activeArtboard, artboards } from 'containers/Artboards/selectors';
import { projectType } from 'containers/Project/selectors';
import { sections as artboardsSections } from 'containers/Sections/selectors';
import { activeSurface, surfaces } from 'containers/Surfaces/selectors';
import * as Models from 'models';
import { getScreenWidth } from 'utils/getScreenWidth';
import { getSectionsWidthByName } from 'utils/getSectionsWidth';
import { toImmutable } from 'utils/immutable';
import { getCustomPreviewOption } from 'utils/screens/getCustomPreviewOption';
import { getScreenHeight } from 'utils/screens/getScreenHeight';
import { isHorizontalSSIPosition } from 'utils/ssi/ssiPosition';
import { State } from './models';

export const screenDefinitions = (state): State.IState => state.get('screenDefinitions');

export const activeScreenDefinition = createSelector(
  [activeSurface, screenDefinitions],
  (activeSurface, screenDefinitions) => {
    const activeSurfaceDefinitionId = (activeSurface as Models.ScreenMap).get('screenDefinitionId');

    return screenDefinitions.get(activeSurfaceDefinitionId);
  },
);

export const sections = createSelector(
  activeScreenDefinition,
  (screenDefinition) => {
    if (screenDefinition) {
      const sections = screenDefinition.get('sections');

      if (sections && sections.size !== 0) {
        return sections;
      }
    }

    return Immutable.List<Models.MasterScreen.SectionMap>();
  },
);

export const screenWidth = createSelector([activeScreenDefinition, activeArtboard, projectType], getScreenWidth);

export const screenHeight = createSelector([activeScreenDefinition, activeArtboard, projectType], getScreenHeight);

export const sectionsWidthByName = createSelector([activeArtboard, screenWidth, activeScreenDefinition], getSectionsWidthByName);

export const sectionsWidthByScreenAndName = createSelector(
  [surfaces, artboards, screenDefinitions, projectType],
  (surfaces, artboards, screenDefinitions, projectType) => surfaces.reduce(
    (surfacesMap, surface) => {
      const artboardId = (surface as Models.ScreenMap).get('artboardId');
      const artboard = artboards.get(artboardId);
      const surfaceDefinitionId = (surface as Models.ScreenMap).get('screenDefinitionId');
      const screenDefinition = screenDefinitions.get(surfaceDefinitionId);
      const screenWidth = getScreenWidth(screenDefinition, artboard, projectType);

      return surfacesMap.set(surface.get('id'), getSectionsWidthByName(artboard, screenWidth, screenDefinition));
    },
    Immutable.Map() as Models.MasterScreen.SectionsWidthByScreenMap,
  ),
);

export const sectionsWidthByScreenAndId = createSelector(
  [surfaces, artboardsSections, sectionsWidthByScreenAndName],
  (surfaces, sections, sectionsWidthByScreenAndName) => sectionsWidthByScreenAndName.map((sectionsWidthByName, screenId) => {
    const screenDefinitionId = surfaces.getIn([screenId, 'screenDefinitionId']);

    return sectionsWidthByName.reduce(
      (sectionsWidthById, width, sectionName) => sections
        .filter(section => (
          section.get('name') === sectionName &&
          section.get('screenDefinition') === screenDefinitionId
        ))
        .map(() => width)
        .merge(sectionsWidthById)
      ,
      Immutable.Map<string, number>(),
    );
  }),
);

const getSectionsHeight = (
  artboard: Models.ArtboardMap,
  screenDefinition: Models.MasterScreen.ScreenDefinitionMap,
): Immutable.Map<string, number> => {
  let result: Immutable.Map<string, number> = Immutable.Map();
  const ssi = artboard.get('ssi');

  screenDefinition.get('sections').forEach((section) => {
    const sectionName = section.get('name');
    let sectionHeight = section.get('height');

    if (ssi && ssi.get('section') === sectionName && isHorizontalSSIPosition(ssi.get('position'))) {
      sectionHeight = Math.ceil(sectionHeight * (1 - ssi.get('scale')));
    }

    result = result.set(sectionName, sectionHeight);
  });

  return result;
};

export const sectionsHeight = createSelector([activeArtboard, activeScreenDefinition], getSectionsHeight);

export const sectionsHeightByScreen = createSelector(
  [surfaces, artboards, screenDefinitions],
  (surfaces, artboards, screenDefinitions) => surfaces.reduce(
    (surfacesMap, surface) => {
      const artboardId = (surface as Models.ScreenMap).get('artboardId');
      const artboard = artboards.get(artboardId);
      const surfaceDefinitionId = (surface as Models.ScreenMap).get('screenDefinitionId');
      const screenDefinition = screenDefinitions.get(surfaceDefinitionId);

      return surfacesMap.set(surface.get('id'), getSectionsHeight(artboard, screenDefinition));
    },
    Immutable.Map() as Models.MasterScreen.SectionsHeightByScreenMap,
  ),
);

export const previewOptionsByScreenId = createSelector(
  [surfaces, screenDefinitions, artboards, projectType],
  (surfaces, screenDefinitions, artboards, projectType) => {
    const { areScreensResizable } = ProjectsConfig[projectType];

    if (!areScreensResizable) {
      return surfaces.map(surface => screenDefinitions.getIn([surface.get('screenDefinitionId'), 'previewOptions']));
    }

    return surfaces.map((surface) => {
      const artboardId = surface.get('artboardId');
      const screenDefinitionId = surface.get('screenDefinitionId');
      const artboard = artboards.get(artboardId);
      const screenDefinition = screenDefinitions.get(screenDefinitionId);
      const screenWidth = getScreenWidth(screenDefinition, artboard, projectType);

      return toImmutable([getCustomPreviewOption(screenWidth)]);
    });
  },
);
