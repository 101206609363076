import { generatorTextAbbreviations } from 'modules/Abbreviations/store/services/generatorTextAbbreviations';
import { all, call } from 'redux-saga/effects';
import { CountryAbbreviations, ProjectAssets, TextAbbreviationDocumentsArray } from 'models';
import { generatorCountryAbbreviations } from './generatorCountryAbbreviations';
import { generatorProjectAssets } from './generatorProjectAssets';

interface ProjectSourceData {
  projectAssets: ProjectAssets;
  needToSaveProjectLocally: boolean;
  countryAbbreviations: CountryAbbreviations;
  textAbbreviationDocuments: TextAbbreviationDocumentsArray;
}

export function* getProjectSourceData(rootDocumentId: number, logId = ''): Generator<unknown, ProjectSourceData> {
  const [countryAbbreviations, projectAssets, textAbbreviationDocuments] = yield all([
    call(generatorCountryAbbreviations),
    call(generatorProjectAssets, rootDocumentId, logId),
    call(generatorTextAbbreviations, rootDocumentId),
  ]);

  return {
    countryAbbreviations,
    textAbbreviationDocuments,
    ...projectAssets,
  };
}
