import _ from 'lodash';
import guid from 'uuid';

import { Layer } from 'const';
import { callToActionFactory } from 'factories/document/callToActionFactory';
import * as Models from 'models';
import { isCallToAction } from 'utils/entityType';

export function normalizeCallToActions(
  relations: Models.LayeredRelations,
  documents: Models.CombinedDocuments,
  layer: Layer,
  metadata: Models.MarketMetaData,
): { callToActionDocuments: Models.CallToActions; callToActionRelations: Models.LayeredRegularRelations<Models.CallToActionStyles> } {
  const emptyCallToActionRelations = _(relations)
    .pickBy<Models.LayeredRegularRelation<Models.CallToActionStyles>>(isCallToAction)
    .pickBy(relation => !relation.documentId[layer] || !documents[relation.documentId[layer]])
    .value();

  return _.reduce(
    emptyCallToActionRelations,
    ({ callToActionDocuments, callToActionRelations }, relation) => {
      const newDocumentId = guid();

      callToActionRelations[relation.id] = { ...relation, documentId: { ...relation.documentId, [layer]: newDocumentId } };
      callToActionDocuments[newDocumentId] = callToActionFactory({
        ...metadata,
        id: newDocumentId,
        isMockDocument: true,
      });

      return { callToActionDocuments, callToActionRelations };
    },
    { callToActionDocuments: {}, callToActionRelations: {} } as {
      callToActionDocuments: Models.CallToActions;
      callToActionRelations: Models.LayeredRegularRelations<Models.CallToActionStyles>;
    },
  );
}
