import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const Redo = (): JSX.Element => {
  const maskId = guid();
  const pathId = guid();

  return (
    <svg viewBox="0 0 30 30">
      <defs>
        <path id={pathId} d="M16.86,3.6 C15.01,1.99 12.61,1 9.96,1 C5.31,1 1.38,4.03 0,8.22 L2.36,9 C3.41,5.81 6.41,3.5 9.96,3.5 C11.91,3.5 13.69,4.22 15.08,5.38 L11.46,9 L20.46,9 L20.46,0 L16.86,3.6 Z"/>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(5.000000, 11.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`#${pathId}`}/>
          </mask>
          <use fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`}/>
          <g mask={`url(#${maskId})`} fill="#D8D8D8">
            <g transform="translate(-3.000000, -9.000000)">
              <rect x="0" y="0" width="26" height="26"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Redo;
