import React from 'react';

const ThreeImageAndText = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <defs>
      <polygon points="0.0188306705 0.0430306653 26.9190841 0.0430306653 26.9190841 14.9298145 0.0188306705 14.9298145" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-240.000000, -610.000000)">
        <g transform="translate(240.000000, 610.000000)">
          <rect fill="#FFFFFF" x="0.75" y="0.75" width="107.5" height="107.5" />
          <rect fill="#F9AD3D" x="13" y="60.75" width="27" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="42" y="60.75" width="27" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="71" y="60.75" width="27" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="13" y="33.75" width="27" height="23" />
          <rect fill="#F9AD3D" x="42" y="33.75" width="27" height="23" />
          <rect fill="#F9AD3D" x="71" y="33.75" width="27" height="23" />
          <rect fill="#F9AD3D" x="13" y="68.75" width="27" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="42" y="68.75" width="27" height="5" rx="1.5" />
          <rect fill="#F9AD3D" x="71" y="68.75" width="27" height="5" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default ThreeImageAndText;
