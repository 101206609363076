import React from 'react';
/* eslint-disable max-len */

const PlusFilled = (): JSX.Element => (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <defs>
      <path d="M1043,560 C1034.71573,560 1028,553.284271 1028,545 C1028,536.715729 1034.71573,530 1043,530 C1051.28427,530 1058,536.715729 1058,545 C1058,553.284271 1051.28427,560 1043,560 Z M1050,546 L1050,544 L1044,544 L1044,538 L1042,538 L1042,544 L1036,544 L1036,546 L1042,546 L1042,552 L1044,552 L1044,546 L1050,546 Z" id="plus-filled-path-1" />
    </defs>
    <g id="!!!-Ref-Cit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="04-Add-a-citation" transform="translate(-1028.000000, -530.000000)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#plus-filled-path-1" />
        </mask>
        <use id="Combined-Shape" fill="#F9AD3D" xlinkHref="#plus-filled-path-1" />
      </g>
    </g>
  </svg>
);

export default PlusFilled;
