import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ModalType } from 'const';
import { intlGet } from 'utils/intlGet';
import { IAddImageItemProps, IAddImageItemState } from './models';
import styles from './styles.module.scss';

export default class AddImageItem extends React.PureComponent<IAddImageItemProps, IAddImageItemState> {
  private showAddImageModalWindow: React.MouseEventHandler = () => {
    this.props.showModal(ModalType.ADD_IMAGE);
  };

  private renderImageBox = (): JSX.Element => {
    return (
      <div className={styles.box} onClick={this.showAddImageModalWindow}>
        <div className={styles['box__icon-add']}>
          <Icon type={IconType.PLUS_FILLED} size="md" color="primary" />
        </div>
        <div className={styles.box__icon}>
          <Icon type={IconType.IMAGE} size="lg" color="light-grey" />
        </div>
      </div>
    );
  };

  render() {
    return (
      <li className={styles.AddImageItemWrapper}>
        <div className={styles.AddImageItem}>
          {this.renderImageBox()}
          <div className={styles.info}>{intlGet('ImagesList', 'AddImage')}</div>
        </div>
      </li>
    );
  }
}
