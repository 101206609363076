import { EditorCommand } from '@draft-js-plugins/editor';
import { undoRedoKeyBindings } from 'modules/draftjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EditorKeyBindingCommand, HandleValue } from 'const';
import { customCallback } from 'models/UndoRedo';
import {
  StackToggler,
  getLastFromStack,
  isEqual,
  pushStateToStack,
  removeLastFromStack,
  toggleProps,
} from '../components/ArtboardAssets/utils/stack';
import * as UndoRedoModels from '../containers/UndoRedoControl/models';

export type UndoRedoMiddleware<StackData> = (
  callback: customCallback,
  prop?: keyof StackData,
  force?: boolean
) => customCallback;

type Props<StackData> = {
  togglersMap: StackToggler<StackData>;
  editMode: boolean;
  currentStackData: StackData;
  saveAppState: UndoRedoModels.ActionCreator.SaveAppState;
  cancel: UndoRedoModels.ActionCreator.Cancel;
};

type ExportType<StackData> = {
  undoStack: StackData[];
  redoStack: StackData[];
  undo: (prevData: StackData) => void;
  redo: (prevData: StackData) => void;
  updateUndoRedoBeforeChange: (prevData: StackData) => void;
  undoStackMiddleware: (callback: customCallback, prop?: keyof StackData, force?: boolean) => customCallback;
};

export const useUndoRedo = <StackData>({
  togglersMap, editMode, currentStackData, saveAppState, cancel,
}: Props<StackData>): ExportType<StackData> => {
  const [undoStack, setUndoStack] = useState<StackData[]>([]);
  const [redoStack, setRedoStack] = useState<StackData[]>([]);
  const prevEditMode = useRef<boolean>(false);

  const updateUndoRedoBeforeChange = (newData: StackData): void => {
    setUndoStack(pushStateToStack(undoStack, newData));
    setRedoStack([]);
  };

  const undo = (prevData: StackData): void => {
    const newData = getLastFromStack(undoStack);

    toggleProps(prevData, newData, togglersMap);
    setUndoStack(removeLastFromStack(undoStack));
    setRedoStack(pushStateToStack(redoStack, prevData));
  };

  const redo = (prevData: StackData): void => {
    const newData = getLastFromStack(redoStack);

    toggleProps(prevData, newData, togglersMap);
    setUndoStack(pushStateToStack(undoStack, prevData));
    setRedoStack(removeLastFromStack(redoStack));
  };

  const handleKeyCommand = (command: EditorCommand): Draft.DraftHandleValue => {
    if (command === EditorKeyBindingCommand.UNDO && undoStack.length) {
      undo(currentStackData);

      return HandleValue.HANDLED;
    }
    if (command === EditorKeyBindingCommand.REDO && redoStack.length) {
      redo(currentStackData);

      return HandleValue.HANDLED;
    }

    return HandleValue.NOT_HANDLED;
  };

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (editMode) {
      const command = undoRedoKeyBindings(e);
      handleKeyCommand(command);
    }
  }, [editMode, currentStackData]);

  const syncUndoRedoState = useCallback(() => {
    if (editMode) {
      saveAppState();
    } else {
      if (!undoStack.length) {
        cancel();
      }
      setUndoStack([]);
      setRedoStack([]);
    }
    prevEditMode.current = editMode;
  }, [editMode]);

  const undoStackMiddleware = (callback: customCallback, prop?: keyof StackData, force = false): customCallback =>
    (...valToApply: unknown[]): unknown => {
      if (!prop || !isEqual(valToApply.length === 1 ? valToApply[0] : valToApply, currentStackData[prop]) || force) {
        updateUndoRedoBeforeChange(currentStackData);

        return callback(...valToApply);
      }

      return null;
    };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    if (prevEditMode.current !== editMode) {
      syncUndoRedoState();
    }
  }, [syncUndoRedoState]);

  return {
    undoStack,
    redoStack,
    undo,
    redo,
    updateUndoRedoBeforeChange,
    undoStackMiddleware,
  };
};
