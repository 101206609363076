import Immutable from 'immutable';
import _ from 'lodash';

import { isImmutable } from 'utils/immutable';

export function pickWithOrder<T extends { [P in string]: T[P] }>(
  collection: T,
  keys: string[],
): T;
export function pickWithOrder<T extends { [P in string]: T[P] }>(
  collection: DeepIMap<T>,
  keys: Immutable.List<string>,
): DeepIMap<T>;
export function pickWithOrder<T extends { [P in string]: T[P] }, P extends keyof T>(
  collection: T | DeepIMap<T>,
  keys: string[] | Immutable.List<string>,
): T | DeepIMap<T> {
  if (!keys) {
    return isImmutable(collection)
      ? Immutable.Map() as DeepIMap<T>
      : {} as T;
  }

  if (isImmutable(collection) && isImmutable(keys)) {
    return Immutable.OrderedMap(keys.map<[string, DeepIMap<T[P]>]>(key => [key, collection.get(key)])) as DeepIMap<T>;
  }

  return _(keys as string[]).map<[string, T[P]]>(key => [key, (collection as T)[key]]).fromPairs().value() as T;
}
