import Immutable from 'immutable';

import * as Models from 'models';

export function getAssetsCollectionsDocuments(
  assetsCollections: Models.MagicFormsMap | Models.StoryCardsMap | Models.TextCollectionsMap,
): Models.CombinedDocumentsMap {
  return (assetsCollections as Immutable.Map<string, Models.MagicFormMap | Models.StoryCardMap | Models.TextCollectionMap>).reduce(
    (documents, assetsCollection) => documents.merge(assetsCollection.get('documents')),
    Immutable.Map<string, Models.CombinedDocumentMap>(),
  );
}
