import { EditorState, RichUtils } from 'draft-js';

import { DraftEntity, DraftEntityMutability } from 'const';
import { reselectEntities } from './entity';
import { getSelectedEntities } from './selection';

const createLink = (editorState: EditorState, link: string): EditorState => {
  const contentWithLink = editorState.getCurrentContent().createEntity(
    DraftEntity.LINK,
    DraftEntityMutability.MUTABLE,
    { url: link },
  );
  const editorStateWithLink = EditorState.set(editorState, { currentContent: contentWithLink });
  const entityKey = contentWithLink.getLastCreatedEntityKey();

  return RichUtils.toggleLink(editorStateWithLink, reselectEntities(editorStateWithLink), entityKey);
};

const deleteLink = (editorState: EditorState): EditorState => {
  return RichUtils.toggleLink(editorState, reselectEntities(editorState), null);
};

export function canApplyLink(editorState: EditorState): boolean {
  const selection = reselectEntities(editorState);
  const link = getLink(editorState);

  return !selection.isCollapsed() || !!link;
}

export function getLink(editorState: EditorState): string {
  const contentState = editorState.getCurrentContent();
  const selection = reselectEntities(editorState);
  const linkEntity = getSelectedEntities(selection, contentState)
    .find(entity => entity.get('type') === DraftEntity.LINK);

  const entity = linkEntity && contentState.getEntity(linkEntity.get('key'));

  if (!entity) {
    return '';
  }

  const { url } = entity.getData();

  return url;
}

export const toggleLink = (editorState: EditorState, link: string): EditorState => {
  return link ? createLink(editorState, link) : deleteLink(editorState);
};

/**
 * TODO: remove deprecated method
 * @deprecated
 */
export const setLink = toggleLink;
