import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Anchor from 'components/Anchor';
import { toggleDragState } from 'containers/App/actions';
import { isDraggingAnchor } from 'containers/App/selectors';
import { removeAnchor } from 'containers/Documents/actions';

const mapStateToProps = createStructuredSelector({
  isDraggingAnchor,
});

const mapDispatchToProps = {
  removeAnchor,
  toggleDragState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Anchor);
