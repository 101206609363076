import * as React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { convertTime } from 'utils/convertTime';
import { intlGet } from 'utils/intlGet';
import { RefreshPanelProps } from './models';
import styles from './styles.module.scss';

export default class RefreshPanel extends React.PureComponent<RefreshPanelProps> {
  private refreshProject = (): void => {
    const { isRefreshAvailable, refreshProject } = this.props;

    if (isRefreshAvailable) {
      refreshProject();
    }
  };

  render() {
    const { lastRefreshTime, isRefreshAvailable } = this.props;

    return (
      <div className={styles.RefreshPanel}>
        <div className={styles.refreshAction} onClick={this.refreshProject}>
          <Icon type={IconType.REFRESH} size="badge" color="primary" isDisabled={!isRefreshAvailable} />
        </div>
        {
          lastRefreshTime &&
          <div className={styles.refreshTime}>{intlGet('ProjectPanel', 'RefreshAt', { time: convertTime(lastRefreshTime) })}</div>
        }
      </div>
    );
  }
}
