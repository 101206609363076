import _ from 'lodash';

import * as Models from 'models';

// TODO: move this methods into backend

/**
 * Moves redundant thumbnailUrl property from root of screen object to nested thumbnail object
 */
export function processLegacyThumbnailUrls<T extends Models.GetProjectDataProcessArgs>(args: T): T {
  const { assets } = args;
  assets.surfaces = _.mapValues(assets.surfaces, (screen: Models.Screen & { thumbnailUrl: string }) => {
    const { thumbnail, thumbnailUrl } = screen;

    if (thumbnailUrl) {
      screen.thumbnail = {
        ...thumbnail,
        url: thumbnailUrl,
      };

      delete screen.thumbnailUrl;
    }

    return screen;
  });

  return args;
}
