import Immutable from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { DocumentsKey, EntityType, Layer } from 'const';
import { artboards } from 'containers/Artboards/selectors';
import { layouts } from 'containers/Layouts/selectors';
import { activeLayer, documentIdsAreBeingUploaded } from 'containers/Project/selectors';
import { layeredRelations } from 'containers/Relations/selectors';
import { screenDefinitions } from 'containers/ScreenDefinitions/selectors';
import { sections } from 'containers/Sections/selectors';
import { orderedSurfaces } from 'containers/Surfaces/selectors';
import * as Models from 'models';
import { filterDocumentsForAssetsPanel } from 'utils/filterCombinedDocuments';
import { getAssetsCollectionsDocuments } from 'utils/getAssetsCollectionsDocuments';
import { getOrderedSections } from 'utils/getOrderedSections';
import { getScreenSectionsByName } from 'utils/getScreenSectionsByName';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { ReferenceCitationsDataMap, State } from './models';

export const documentsByEntityType = (state): State.IState => state.get('documents');
export const callToActions = state => documentsByEntityType(state).get(DocumentsKey.CALL_TO_ACTIONS);
export const groupLayouts = state => documentsByEntityType(state).get(DocumentsKey.GROUP_LAYOUTS);
export const images = state => documentsByEntityType(state).get(DocumentsKey.IMAGES);
export const reusableLayouts = state => documentsByEntityType(state).get(DocumentsKey.LAYOUTS);
export const magicForms = state => documentsByEntityType(state).get(DocumentsKey.MAGIC_FORMS);
export const moduleBundles = state => documentsByEntityType(state).get(DocumentsKey.MODULE_BUNDLES);
export const projectFiles = state => documentsByEntityType(state).get(DocumentsKey.PROJECT_FILES);
export const referenceCitations = state => documentsByEntityType(state).get(DocumentsKey.REFERENCE_CITATIONS);
export const storyCards = state => documentsByEntityType(state).get(DocumentsKey.STORY_CARDS);
export const textComponents = state => documentsByEntityType(state).get(DocumentsKey.TEXT_COMPONENTS);
export const textCollections = state => documentsByEntityType(state).get(DocumentsKey.TEXT_COLLECTIONS);
export const masterSurfaces = state => documentsByEntityType(state).get(DocumentsKey.MASTER_SURFACES);

export const documents = createSelector(
  [
    callToActions,
    groupLayouts,
    images,
    moduleBundles,
    reusableLayouts,
    magicForms,
    projectFiles,
    referenceCitations,
    storyCards,
    textComponents,
    textCollections,
    masterSurfaces,
  ],
  (
    callToActions,
    groupLayouts,
    images,
    moduleBundles,
    reusableLayouts,
    magicForms,
    projectFiles,
    referenceCitations,
    storyCards,
    textComponents,
    textCollections,
    masterSurfaces,
  ) => Immutable.Map().withMutations(result => result
    .merge(callToActions)
    .merge(groupLayouts)
    .merge(images)
    .merge(moduleBundles)
    .merge(reusableLayouts)
    .merge(magicForms)
    .merge(projectFiles)
    .merge(referenceCitations)
    .merge(storyCards)
    .merge(textComponents)
    .merge(textCollections)
    .merge(masterSurfaces),
  ) as Models.CombinedDocumentsMap,
);

export const nonUploadingDocuments = createSelector(
  [documents, documentIdsAreBeingUploaded],
  (documents, documentIdsAreBeingUploaded) => {
    return documents.filter((document, id) => !documentIdsAreBeingUploaded.has(id));
  },
);

export const callToActionsForAssetsPanel = createSelector(callToActions, filterDocumentsForAssetsPanel);

export const citationsForAssetsPanel = createSelector(referenceCitations, filterDocumentsForAssetsPanel);

export const reusableLayoutDocumentsForAssetsPanel = createSelector(reusableLayouts, filterDocumentsForAssetsPanel);

export const groupLayoutDocumentsForAssetsPanel = createSelector(groupLayouts, filterDocumentsForAssetsPanel);

export const imagesForAssetsPanel = createSelector(images, filterDocumentsForAssetsPanel);

export const moduleBundlesForAssetsPanel = createSelector(moduleBundles, filterDocumentsForAssetsPanel);

export const storyCardsForAssetsPanel = createSelector(storyCards, filterDocumentsForAssetsPanel);

export const magicFormsForAssetsPanel = createSelector(magicForms, filterDocumentsForAssetsPanel);

export const storyCardsAndMagicFormsForAssetsPanel = createSelector(
  [storyCardsForAssetsPanel, magicFormsForAssetsPanel],
  (storyCards, magicForms) => storyCards.merge(magicForms),
);

export const textCollectionsForAssetsPanel = createSelector(textCollections, filterDocumentsForAssetsPanel);

export const textComponentsForAssetsPanel = createSelector(textComponents, filterDocumentsForAssetsPanel);

export const storyCardsAndMagicFormsDocuments = createSelector(storyCardsAndMagicFormsForAssetsPanel, getAssetsCollectionsDocuments);

export const textCollectionsDocuments = createSelector(textCollectionsForAssetsPanel, getAssetsCollectionsDocuments);

export const assetsCollectionsDocuments = createSelector(
  [textCollectionsDocuments, storyCardsAndMagicFormsDocuments],
  (textCollectionsDocuments, storyCardsAndMagicFormsDocuments) => textCollectionsDocuments.merge(storyCardsAndMagicFormsDocuments),
);

export const reusableLayoutsNames = createSelector(
  reusableLayoutDocumentsForAssetsPanel, reusableLayoutDocuments => reusableLayoutDocuments.toList().map(document => document.get('name')),
);

export const groupLayoutsNames = createSelector(
  groupLayoutDocumentsForAssetsPanel, groupLayoutDocuments => groupLayoutDocuments.toList().map(document => document.get('name')),
);

/**
 * Returns OrderedMap, where key is artboardId
 * and value is List<RelationMap> of used ordered relations on this artboard
 *
 * OrderedMap: {
 *  [artboardId: string]: List<RelationMap>;
 * }
 */
export const relationsByScreenId = createSelector(
  [orderedSurfaces, artboards, sections, layouts, layeredRelations, screenDefinitions],
  (screens, artboards, sections, layouts, layeredRelations, screenDefinitions) => screens.reduce(
    (relationsOnArtboards, screen: Models.ScreenMap) => {
      const artboardId = screen.get('artboardId');
      const screenDefinitionId = screen.get('screenDefinitionId');
      const screenDefinition = screenDefinitions.get(screenDefinitionId);
      const artboard = artboards.get(artboardId);
      const layoutsOnArtboard = getFlattenedLayouts(artboard, layouts, true).toList();
      const layoutsBySectionId = layoutsOnArtboard.groupBy(
        layout => layout.get('section'),
      ) as unknown as Immutable.OrderedMap<string, Immutable.List<Models.LayoutMap>>;

      const screenSectionsByName = getScreenSectionsByName(sections, layoutsBySectionId.keySeq().toList());
      const orderedSections = getOrderedSections(screenSectionsByName, screenDefinition.get('sections'));
      const orderedLayouts = orderedSections.map(section => layoutsBySectionId.get(section.get('id'))).flatMap(layouts => layouts);

      const orderedRelations = orderedLayouts.filter(isPlainLayout).reduce(
        (orderedRelationsOnArtboard, layout) => orderedRelationsOnArtboard.push(...getFlattenedRelations(layout, layeredRelations).toList()),
        Immutable.List<Models.LayeredRelationMap>(),
      );

      return relationsOnArtboards.set(artboardId, orderedRelations);
    },
    Immutable.OrderedMap<string, Immutable.List<Models.LayeredRelationMap>>(),
  ),
);

/**
 * Returns List of all relations on the all screens
 *
 * List<RelationMap>
 */
// TODO: do we really need it? Why not just 'relations'?
export const relationsOnScreens = createSelector(
  [relationsByScreenId],
  relationsByScreenId => relationsByScreenId.toList().flatMap(relationsOnArtboard => relationsOnArtboard),
);

export const getImagesByScreenId = createSelector(
  [orderedSurfaces, artboards, layouts, layeredRelations, sections],
  (screens, artboards, layouts, layeredRelations, sections): DeepIMap<Record<string, Models.BackgroundImage[]>> => {
    const imagesByArtboardId: Record<string, Models.BackgroundImageMap[]> = {};
    const sectionIdsByArtboardId: Record<string, Set<string>> = {};

    screens.forEach((screen) => {
      const artboardId = screen.get('artboardId');
      const artboard = artboards.get(artboardId);
      const artboardLayouts = getFlattenedLayouts(artboard, layouts, true);

      const imagesOnScreen: Models.BackgroundImageMap[] = [];
      imagesByArtboardId[artboardId] = imagesOnScreen;
      const sectionIds = new Set<string>();
      sectionIdsByArtboardId[artboardId] = sectionIds;

      imagesOnScreen.push(artboard.getIn(['styles', 'backgroundImage']));

      artboardLayouts.forEach((layout) => {
        sectionIds.add(layout.get('section'));
        imagesOnScreen.push(layout.getIn(['styles', 'backgroundImage']));

        getFlattenedRelations(layout, layeredRelations, true).forEach(relation => (
          relation.get('styles').forEach((style) => {
            if (style) {
              imagesOnScreen.push(style.get('backgroundImage'));
              const mobileViewImage = style.get('mobileViewImage');
              if (mobileViewImage) {
                imagesOnScreen.push(mobileViewImage);
              }
            }
          })
        ));
      });
    });

    _.forEach(sectionIdsByArtboardId, (sectionIds, artboardId) => {
      sectionIds.forEach(sectionId => imagesByArtboardId[artboardId].push(
        sections.getIn([sectionId, 'styles', 'backgroundImage']),
      ));
    });

    return Immutable.fromJS(_.mapValues(imagesByArtboardId, imagesOnScreen => _(imagesOnScreen)
      .filter(imageItem => imageItem && Boolean(imageItem.get('id')))
      .uniqBy(imageItem => imageItem.get('id'))
      .value(),
    ));
  },
);

export const referencesData = createSelector(
  [textComponentsForAssetsPanel, citationsForAssetsPanel, relationsOnScreens, activeLayer],
  (textComponents, citations, relationsOnScreens, activeLayer): ReferenceCitationsDataMap => {
    const referenceCitationsData = {
      referenceCitationsByReferenceElements: {},
      referenceCitationsOrderByDocuments: {},
      documentIdsOrderBeforeReferenceElement: {},
    };
    let referenceCitationsByReferenceElements = new Set<Models.ReferenceCitationMap>();
    let documentIdsOrderBeforeReferenceElement = new Set<string>();

    relationsOnScreens.forEach((relation) => {
      if (!isRegularRelation(relation)) {
        return;
      }

      const id = relation.get('id');
      const documentId = relation.getIn(['documentId', activeLayer]);
      const document = textComponents.get(documentId);
      const entityType = relation.get('entityType');
      const referenceCitationsOrderByDocuments: number[] = [];

      if (entityType === EntityType.REFERENCE_CITATION_ELEMENT) {
        referenceCitationsData.referenceCitationsByReferenceElements[id] = [...referenceCitationsByReferenceElements];
        referenceCitationsByReferenceElements = new Set();

        referenceCitationsData.documentIdsOrderBeforeReferenceElement[id] = [...documentIdsOrderBeforeReferenceElement];
        documentIdsOrderBeforeReferenceElement = new Set();
      }

      if (document) {
        const referenceCitationsIds = (document.get('referenceCitations') || Immutable.List<string>()).toJS() as string[];

        documentIdsOrderBeforeReferenceElement.add(documentId);

        referenceCitationsIds.forEach((citationId) => {
          const referenceCitation = citations.get(citationId);

          if (!referenceCitation) {
            return;
          }

          referenceCitationsByReferenceElements.add(referenceCitation);
          const referenceCitationOrder = [...referenceCitationsByReferenceElements].indexOf(referenceCitation) + 1;

          referenceCitationsOrderByDocuments.push(referenceCitationOrder);
        });

        referenceCitationsData.referenceCitationsOrderByDocuments[`${id}-${documentId}`] = referenceCitationsOrderByDocuments;
      }
    });

    return Immutable.fromJS(referenceCitationsData);
  },
);

export const referenceCitationsByReferenceElements = createSelector(
  [referencesData],
  (referencesData) => {
    return referencesData.get('referenceCitationsByReferenceElements');
  },
);

export const referenceCitationsOrderByDocuments = createSelector(
  [referencesData],
  referencesData => referencesData.get('referenceCitationsOrderByDocuments'),
);

export const documentIdsOrderBeforeReferenceElement = createSelector(
  [referencesData],
  referencesData => referencesData.get('documentIdsOrderBeforeReferenceElement'),
);

export const updatedInPromoMatsEntities = createSelector(
  [documents],
  (documents) => {
    const entities = new Set<EntityType>();

    documents.forEach((document) => {
      if ((document as Models.DocumentMap).get('isUpdatedInPromoMats')) {
        entities.add(document.get('entityType'));
      }
    });

    return Immutable.Set(entities);
  },
);

export const layoutDocumentsOnScreens = createSelector(
  [orderedSurfaces, layouts, artboards],
  (screens, layouts, artboards) => screens.map((screen) => {
    const artboardId = screen.get('artboardId');
    const artboard = artboards.get(artboardId);

    const layoutsOnScreen = getFlattenedLayouts(artboard, layouts)
      .valueSeq()
      .map(layout => layout.get('documentId'));

    return Immutable.OrderedSet(layoutsOnScreen);
  }),
);

export const citationsOnScreens = createSelector(
  [relationsByScreenId, textComponentsForAssetsPanel, activeLayer],
  (relationsByScreenId, textComponents, activeLayer) => {
    const citationsOnArtboards = relationsByScreenId.map((relations) => {
      const referenceCitationIds: string[] = [];

      relations.forEach((relation) => {
        if (!isRegularRelation(relation)) {
          return;
        }

        const entityType = relation.get('entityType');

        if (entityType === EntityType.TEXT) {
          const documentId = relation.getIn(['documentId', activeLayer]);
          const document = textComponents.get(documentId);
          document && referenceCitationIds.push(...document.get('referenceCitations'));
        }
      });

      return referenceCitationIds;
    });

    return Immutable.fromJS(citationsOnArtboards);
  },
);

export const areReusableLayoutsBeingUploaded = createSelector(
  [reusableLayouts, groupLayouts, documentIdsAreBeingUploaded],
  (_reusableLayouts, _groupLayouts, _documentIdsAreBeingUploaded) => _documentIdsAreBeingUploaded.some((documentId) => {
    return _reusableLayouts.has(documentId) || _groupLayouts.has(documentId);
  }),
);

export const exportForTranslationsEnabled = createSelector(
  [documents, layeredRelations],
  (_documents, _layeredRelations) => _layeredRelations.some((layeredRelation) => {
    const documentId = layeredRelation.getIn(['documentId', Layer.ORIGINAL]);

    return _documents.has(documentId);
  }),
);
