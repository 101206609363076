import React from 'react';
/* eslint-disable max-len */

const ColumnWidth = (): JSX.Element => (
  <svg width="20px" height="16px" viewBox="2 4 20 16">
    <path d="M21,4 L3,4 C2.45,4 2,4.45 2,5 L2,19 C2,19.55 2.45,20 3,20 L21,20 C21.55,20 22,19.55 22,19 L22,5 C22,4.45 21.55,4 21,4 Z M8,18 L4,18 L4,6 L8,6 L8,18 Z M14,18 L10,18 L10,6 L14,6 L14,18 Z M20,18 L16,18 L16,6 L20,6 L20,18 Z" />
  </svg>
);

export default ColumnWidth;
