import classNames from 'classnames';
import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import DocumentThumbnail from 'components/DocumentThumbnail';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { DragSourceType, EntityType } from 'const';
import { AssetDragObject } from 'models';
import * as Models from './models';
import styles from './styles.module.scss';

const LayoutListItemRenditionDragSource: DragSourceSpec<Models.LayoutListItemRenditionProps, AssetDragObject> = {
  beginDrag(props) {
    const { toggleDragState, document } = props;
    const type = EntityType.LAYOUT_RENDITION;
    toggleDragState(DragSourceType.ASSET, type);

    return { type, component: document };
  },

  endDrag(props) {
    props.toggleDragState();
  },
};

const collect: DragSourceCollector<Models.LayoutListItemRenditionCollectedProps, Models.LayoutListItemRenditionOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const LayoutListItemRendition: React.FunctionComponent<Models.LayoutListItemRenditionProps> = (props) => {
  const { connectDragPreview, connectDragSource, isDragging, document } = props;

  React.useEffect(() => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); }, []);

  return (
    <div className={styles.LayoutListItemRendition}>
      <div className={styles.label}>Low rendition</div>
      {connectDragSource(
        <div
          className={classNames(styles.rendition, {
            [styles.dragging]: isDragging,
          })}
        >
          <>
            <Icon className={styles.layoutRenditionMarker} type={IconType.MOVE} size="badge" color="secondary" />
            <DocumentThumbnail
              placeholder={<Icon type={IconType.IMAGE} size="sm" color="disabled"/>}
              url={document.get('_thumbnailUrl')}
            />
          </>
        </div>,
      )}
    </div>
  );
};

export default DragSource<Models.LayoutListItemRenditionOwnProps, Models.LayoutListItemRenditionCollectedProps, AssetDragObject>(
  DragSourceType.ASSET,
  LayoutListItemRenditionDragSource,
  collect,
)(LayoutListItemRendition);
