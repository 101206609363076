import { List } from 'immutable';
import RelationAbbreviationControl from 'modules/Abbreviations/components/ToolbarAbbreviation/RelationAbbreviationControl';
import React from 'react';
import Subtitle from 'components/Toolbar/BaseToolbar/components/Subtitle';
import { HOVER_HINT } from 'components/Toolbar/constants';
import AltTag from 'components/Toolbar/Controls/AltTag';
import AssetAlignment from 'components/Toolbar/Controls/AssetAlignment/List';
import BackgroundImage from 'components/Toolbar/Controls/BackgroundImage';
import Border from 'components/Toolbar/Controls/Border';
import BorderRadius from 'components/Toolbar/Controls/BorderRadius';
import FillColor from 'components/Toolbar/Controls/FillColor';
import ImageMobileSettings from 'components/Toolbar/Controls/ImageMobileSettings';
import Link from 'components/Toolbar/Controls/Link';
import Padding from 'components/Toolbar/Controls/Padding';
import AutoFitContentToggle from 'components/Toolbar/Shared/AutoFitContentToggle';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import HeightWidth from 'components/Toolbar/Shared/HeightWidth';
import { MinCellWidth, ProjectsConfig } from 'const';
import BaseToolbar from 'containers/Toolbar/BaseToolbar';
import { allowImageMobileSettings } from 'utils/allowImageMobileSettings';
import { intlGet } from 'utils/intlGet';
import { ToolbarProps } from './models';

const Toolbar: React.FunctionComponent<ToolbarProps> = (props) => {
  const {
    styles,
    setters,
    alignmentDisabled,
    altTagDisabled,
    cellHeight,
    cellWidth,
    disableCellWidthEditing,
    height,
    isIncreasingWidthDisabled,
    layoutId,
    linkDisabled,
    maxCellHeight,
    maxContentHeight,
    maxContentWidth,
    minCellHeight,
    mobileSettingsDisabled,
    projectType,
    rootDocument,
    toggleCellHeight,
    toggleColumnWidth,
    width,
    toggleAutoFitContent,
    isAutoFitContent,
  } = props;
  const { allowBackgroundImage, allowBackgroundOpacity } = ProjectsConfig[projectType];

  return (
    <BaseToolbar layoutId={layoutId}>
      <Subtitle title={intlGet('EditorToolbar.Asset', 'Cell')} />
      <ControlGroup>
        <HeightWidth
          height={cellHeight}
          maxHeight={maxCellHeight}
          minHeight={minCellHeight}
          toggleHeight={toggleCellHeight}
          disableCellWidthEditing={disableCellWidthEditing}
          isIncreasingWidthDisabled={isIncreasingWidthDisabled}
          minWidth={MinCellWidth.IMAGE}
          width={cellWidth}
          toggleColumnWidth={toggleColumnWidth}
          toggleAutoFitContent={toggleAutoFitContent}
          isAutoFitContent={isAutoFitContent}
        />
        <AutoFitContentToggle
          isAutoFitContent={isAutoFitContent}
          isAutoHeight={false}
          toggleAutoFitContent={toggleAutoFitContent}
        />
      </ControlGroup>
      <Subtitle title={intlGet('EditorToolbar.Asset', 'Image')} />
      <ControlGroup>
        <HeightWidth
          width={width}
          maxWidth={maxContentWidth}
          toggleWidth={setters.width}
          height={height}
          maxHeight={maxContentHeight}
          toggleHeight={setters.height}
        />
      </ControlGroup>
      {allowImageMobileSettings(projectType, rootDocument) &&
        <ControlGroup>
          <ImageMobileSettings
            alignmentDisabled={alignmentDisabled}
            alignment={styles.mobileAlignment}
            mobileSettingsApplied={styles.mobileSettingsApplied}
            isDisabled={mobileSettingsDisabled}
            scale={styles.mobileScale}
            mobileViewImage={styles.mobileViewImage}
            toggleAlignment={setters.mobileAlignment}
            toggleScale={setters.mobileScale}
            toggleMobileSettingsApplied={setters.mobileSettingsApplied}
            toggleMobileViewImage={setters.mobileViewImage}
            layoutId={layoutId}
          />
        </ControlGroup>
      }
      <ControlGroup>
        <AssetAlignment
          isDisabled={alignmentDisabled}
          alignment={styles.alignment}
          toggleAlignment={setters.alignment}
        />
      </ControlGroup>
      <ControlGroup>
        <Padding padding={styles.padding} togglePadding={setters.padding} />
        <Border border={styles.border} toggleBorder={setters.border} />
        <FillColor
          activeColor={styles.backgroundColor}
          toggleColor={setters.backgroundColor}
          opacity={styles.backgroundColorOpacity}
          toggleOpacity={allowBackgroundOpacity ? setters.backgroundColorOpacity : undefined}
          gradient={styles.backgroundGradient}
          toggleGradient={setters.backgroundGradient}
        />
        {allowBackgroundImage && <BackgroundImage
          backgroundImage={styles.backgroundImage}
          toggleBackgroundImage={setters.backgroundImage}
        />}
        <BorderRadius
          borderRadius={styles.borderRadius}
          title={HOVER_HINT.CELL}
          dropdownWarningMessage={intlGet('EditorToolbar.Tooltip', 'WarningMessage')}
          toggleBorderRadius={setters.borderRadius}
        />
      </ControlGroup>
      <ControlGroup>
        <AltTag
          isDisabled={altTagDisabled}
          altTag={styles.altTag}
          toggleAltTag={setters.altTag}
        />
      </ControlGroup>
      <ControlGroup>
        <Link
          isDisabled={Boolean(linkDisabled)}
          link={styles.link}
          toggleLink={setters.link}
        />
        <RelationAbbreviationControl
          selectedAbbreviationIDs={styles.abbreviations?.toArray() ?? []}
          onChange={(value): void=>setters.abbreviations(List<string>(value))}
        />
      </ControlGroup>
    </BaseToolbar>
  );
};

export default Toolbar;
