import { Map, fromJS } from 'immutable';
import { Layer } from 'const';
import { BorderMap, BrandColor, BrandColorsList, LayeredRelationMap, LayoutMap, RelationMap } from 'models';
import { borderColorFromLayeredSource, borderColorFromSource, colorFromSource, colorToSource, colorToTint } from './color';

export const borderFromSource = (colors: BrandColorsList, source: RelationMap | LayoutMap): BorderMap => {
  const border = source.getIn(
    ['styles', 'border'],
    Map({
      width:  Map({ top: 0, left: 0, right: 0, bottom: 0 }),
    }),
  ) as BorderMap;

  return border.set('color', borderColorFromSource(colors, source));
};

export const borderFromLayeredSource = (colors: BrandColorsList, source: LayeredRelationMap, layer: Layer): BorderMap => {
  const border = source.getIn(['styles', layer, 'border']) as BorderMap;

  return border && border.set('color', borderColorFromLayeredSource(layer)(colors, source));
};

export const borderToSource = (border: BorderMap): BorderMap<string> => (
  (border as BorderMap<string>)
    .set('color', border.getIn(['color', 'name'], null))
    .set('colorTint', border.getIn(['color', 'tint'], null))
);

export function brandBorderToSource(
  border: BorderMap<BrandColor> | undefined,
): BorderMap<string> | undefined {
  if (!border) {
    return undefined;
  }

  return fromJS({
    color: colorToSource(border.get('color')),
    colorTint: colorToTint(border.get('color')),
    width: border.get('width'),
  });
}

export function brandBorderFromSource(
  colors: BrandColorsList | undefined,
  border: BorderMap<string> | undefined,
): BorderMap<BrandColor> | undefined {
  if (!border) {
    return undefined;
  }

  return fromJS({
    color: colorFromSource(colors, border.get('color'), border.get('colorTint')),
    width: border.get('width'),
  });
}
