import { ActionTypes } from './constants';
import { ActionCreator } from './models';

export const updateCellsHeight: ActionCreator.UpdateCellsHeight = (relationId, newHeight) => ({
  type: ActionTypes.UPDATE_CELLS_HEIGHT,
  payload: {
    relationId,
    newHeight,
  },
});

export const setCellHeight: ActionCreator.SetCellHeight = (id, newHeight) => ({
  type: ActionTypes.SET_CELL_HEIGHT,
  payload: {
    id,
    newHeight,
  },
});

export const deleteCellHeights: ActionCreator.DeleteCellHeights = ids => ({
  type: ActionTypes.DELETE_CELL_HEIGHTS,
  payload: {
    ids,
  },
});

export const generateArtboardPreview: ActionCreator.GenerateArtboardPreview = screenId => ({
  type: ActionTypes.GENERATE_ARTBOARD_PREVIEW,
  payload: {
    screenId,
  },
});

export const setPreviewHtml: ActionCreator.SetPreviewHtml = (screenId, previewHtml, htmlUnicodeSize) => ({
  type: ActionTypes.SET_PREVIEW_HTML,
  payload: {
    previewHtml,
    screenId,
    htmlUnicodeSize,
  },
});

export const resetPreviewsHtml: ActionCreator.ResetPreviewsHtml = () => ({
  type: ActionTypes.RESET_PREVIEWS_HTML,
});

export const addScreenReadyForOutput: ActionCreator.AddScreenReadyForOutput = screenId => ({
  type: ActionTypes.ADD_SCREEN_READY_FOR_OUTPUT,
  payload: {
    screenId,
  },
});

export const resetScreensReadyForOutput: ActionCreator.ResetScreensReadyForOutput = () => ({
  type: ActionTypes.RESET_SCREENS_READY_FOR_OUTPUT,
});
