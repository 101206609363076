import * as React from 'react';

import ArtboardContainer from 'components/ArtboardContainer';
import DragLayer from 'components/DragLayer';
import Main from 'components/Main';
import { AppRoute, ProjectOptionType, ProjectsConfig } from 'const';
import Header from 'containers/Header';
import LoadingOverlay from 'containers/LoadingOverlay';
import ProjectPanel from 'containers/ProjectPanel';
import ScreensPanel from 'containers/ScreensPanel';
import { validateEventKey } from 'containers/UndoRedoControl/utils';
import { useProjectType } from 'hooks/useProjectType';
import { outsideBackgroundColor as getOutsideBackgroundColor } from 'utils/converters';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { isUnsupportedBrowser } from 'utils/isUnsupportedBrowser';
import { getBackgroundColor } from 'utils/styles';
import { IProjectScreenProps } from './models';
import styles from './styles.module.scss';

const ProjectScreen: React.FunctionComponent<IProjectScreenProps> = (props) => {
  const {
    activeArtboard,
    artboardBackgroundFromMasterScreen,
    colors,
    getProjectData,
    history,
    isLocked,
    location,
    rootDocument,
    showScreensPanel,
    showPreview,
    scale,
  } = props;

  React.useEffect(
    () => {
      // to enable browser refreshing within an opened project
      // TODO: maybe need to redirect to welcome page
      if (isUnsupportedBrowser()) {
        history.replace(`/${AppRoute.UNSUPPORTED_BROWSER}`);
      } else if (!rootDocument.get('id') && !isLocked) {
        const projectNumberRegexp = new RegExp(`${AppRoute.PROJECT}\/(\\d+)`);

        if (location.pathname.search(projectNumberRegexp) !== -1) {
          const id = Number(location.pathname.match(projectNumberRegexp)[1]);

          !isNaN(id) && getProjectData(id);
        }
      }
    },
    [],
  );

  const KEYDOWN_EVENTNAME = 'keydown';

  function handleBodyKeydown(event: KeyboardEvent): void {
    if (event.target === document.body && validateEventKey(event)) {
      event.preventDefault();

      eventEmitter.emit(EMITTER_EVENTS.BODY_KEYDOWN, event);
    }
  }

  React.useEffect(
    () => {
      window.addEventListener(KEYDOWN_EVENTNAME, handleBodyKeydown);

      return (): void => {
        window.removeEventListener(KEYDOWN_EVENTNAME, handleBodyKeydown);
      };
    },
    [],
  );

  const projectType = useProjectType();
  const {
    backgroundColor: outsideBackgroundColor,
  } = getBackgroundColor(getOutsideBackgroundColor(colors, activeArtboard), artboardBackgroundFromMasterScreen);
  const { areScreensResizable } = ProjectsConfig[projectType] || {} as ProjectOptionType;
  const artboardHeight = areScreensResizable
    ? activeArtboard && activeArtboard.getIn(['styles', 'height'])
    : undefined;

  return (
    <div className={styles.ProjectScreen}>
      {
        rootDocument.get('id') &&
        <>
          <Header rootDocument={rootDocument} />
          <Main
            artboardBackground={outsideBackgroundColor}
            artboardHeight={artboardHeight}
            showScreensPanel={showScreensPanel}
            showPreview={showPreview}
            scale={scale}
            content={<ArtboardContainer />}
            assetPanel={<ProjectPanel />}
            screensPanel={<ScreensPanel />}
          />
        </>
      }
      <DragLayer />
      <LoadingOverlay />
    </div>
  );
};

export default ProjectScreen;
