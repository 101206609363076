import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SaveReusableLayout from 'components/ModalWindows/SaveReusableLayout';
import {
  SaveReusableLayoutActions,
  SaveReusableLayoutOwnProps,
  SaveReusableLayoutSelectors,
} from 'components/ModalWindows/SaveReusableLayout/models';
import { updateAppState } from 'containers/App/actions';
import { tempLayoutPreview } from 'containers/App/selectors';
import { reusableLayouts, reusableLayoutsNames } from 'containers/Documents/selectors';
import { documentTypes } from 'containers/DocumentTypes/selectors';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';
import { rootDocument } from 'containers/RootDocument/selectors';
import { cancelLayoutPreviewGeneration, generateLayoutPreview, saveReusableLayout } from './actions';

const mapStateToProps = (state, props: SaveReusableLayoutOwnProps) => createStructuredSelector({
  documentTypes,
  options: modalWindowOptions(props.modalWindowId),
  layoutPreview: tempLayoutPreview,
  reusableLayoutsDocuments: reusableLayouts,
  reusableLayoutsNames,
  rootDocument,
});

const mapDispatchToProps = {
  hideModal,
  saveReusableLayout,
  generateLayoutPreview,
  updateAppState,
  cancelLayoutPreviewGeneration,
};

export default connect<SaveReusableLayoutSelectors, SaveReusableLayoutActions, SaveReusableLayoutOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SaveReusableLayout);
