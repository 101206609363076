import React from 'react';

import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/TextVerticalAlignment';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { TEXT_VERTICAL_ALIGNMENTS } from 'const';
import { TextVerticalAlignmentProps } from './models';

const TextVerticalAlignment: React.FunctionComponent<TextVerticalAlignmentProps> = (props) => {
  const { alignment, toggleAlignment, controlState = ControlState } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  const icon = TEXT_VERTICAL_ALIGNMENTS.find(a => a.type === alignment).icon;

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      ref={control}
      state={state}
      title={Title.TEXT_ALIGNMENT_VERTICAL}
      type={icon}
    >
      {isOpen && <Dropdown alignment={alignment} toggleAlignment={toggleAlignment} />}
    </Control>
  );
};

export default TextVerticalAlignment;
