import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';
import guid from 'uuid';

import { EntityType } from 'const';
import { cancelLastUndoState, setLastEditedLayoutId } from 'containers/App/actions';
import { setDocument } from 'containers/Documents/actions';
import * as documentsSelector from 'containers/Documents/selectors';
import { layouts as layoutsSelector } from 'containers/Layouts/selectors';
import { currentCountry, currentLanguage } from 'containers/Project/selectors';
import { updateRelation } from 'containers/Relations/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createDocument } from 'factories/document/documentFactory';
import { createImageStyles } from 'factories/style';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { isCallToAction, isImage, isReusableLayoutDocument } from 'utils/entityType';
import { findImageDuplicateId } from 'utils/findDocumentDuplicateId';
import { toJS } from 'utils/immutable';
import { getStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { Action } from '../models';

export function* dropAsset(action: Action.IDropAsset) {
  try {
    const actions = [saveAppState()] as Models.IAction[];
    const { component, relation, size, layoutId } = action.payload;
    const prevEntityType = relation.get('entityType');
    const relationId = relation.get('id');
    const entityType = !isReusableLayoutDocument(component) ? component.get('entityType') : EntityType.LAYOUT_RENDITION;
    let componentId = component.get('id');

    if (isCallToAction(component)) {
      const language = (yield select(currentLanguage)).toJS() as string[];
      const country = (yield select(currentCountry)).toJS() as string[];
      const newDocument = createDocument({
        ...component.toJS() as Models.CallToAction,
        language,
        country,
      });
      // TODO: delete unused documents for CallToAction
      // or better to store CallToAction in documents only once
      actions.push(setDocument(newDocument));
    } else if (isImage(component)) {
      const images: ReturnTypeSaga<typeof documentsSelector.imagesForAssetsPanel> = yield select(documentsSelector.imagesForAssetsPanel);
      const assetsCollectionsDocuments: ReturnTypeSaga<typeof documentsSelector.assetsCollectionsDocuments> = yield select(
        documentsSelector.assetsCollectionsDocuments,
      );

      // it means that the image was dropped from Story Card
      if (assetsCollectionsDocuments.get(componentId)) {
        // TBC: Currently we pick only priority image from storyCard. Should we pick both layers together?
        const duplicateId = findImageDuplicateId(component, images);

        if (duplicateId) {
          componentId = duplicateId;
        } else {
          const newDocument = createDocument({
            ...component.toJS() as Models.Image,
            id: guid(),
          });
          componentId = newDocument.id;
          actions.push(setDocument(newDocument));
        }
      } else if (!images.has(componentId)) {
        const newDocument = createDocument({
          ...component.toJS() as Models.Image,
          id: guid(),
        });
        componentId = newDocument.id;
        actions.push(setDocument(newDocument));
      }
    }

    const layouts: ReturnTypeSaga<typeof layoutsSelector> = yield select(layoutsSelector);
    const layout = layouts.get(layoutId);
    if (layout.get('documentId')) {
      const documents: ReturnTypeSaga<typeof documentsSelector.documents> = yield select(documentsSelector.documents);
      const document = documents.get(layout.get('documentId'));

      const isEditedLayout = !(document as Models.ReusableLayoutMap).get('isEdited');
      actions.push(cancelLastUndoState(isEditedLayout));
    }

    const stylesToBeKept = isImage(component) && prevEntityType === EntityType.IMAGE
      ? createImageStyles(toJS(relation.get('styles')))
      : getStylesToBeKept(toJS(relation.get('styles')));

    actions.push(
      setLastEditedLayoutId(layoutId),
      updateRelation(
        {
          id: relationId,
          documentId: componentId,
          entityType,
          styles: {
            ...stylesToBeKept,
            isSizeAdjusted: false,
          },
        },
        {
          size,
          resetLayeredData: prevEntityType !== entityType,
        },
      ),
    );
    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.dropAsset', 'DropAsset');
  }
}
