import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import Dropdown from 'components/Toolbar/Dropdowns/BackgroundImage';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { BackgroundImageProps } from './models';

const BackgroundImage: React.FunctionComponent<BackgroundImageProps> = (props) => {
  const { backgroundImage, toggleBackgroundImage, returnFocusToEditor } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      preventBlur={false}
      onToggle={toggleDropdown}
      ref={control}
      size="sm"
      state={state}
      title={Title.BACKGROUND_IMAGE}
      type={IconType.IMAGE}
    >
      {isOpen && <Dropdown
        backgroundImage={backgroundImage}
        toggleBackgroundImage={toggleBackgroundImage}
      />}
    </Control>
  );
};

export default BackgroundImage;
