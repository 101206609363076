import React from 'react';
/* eslint-disable max-len */

const ResetColor = (): JSX.Element => (
  <svg width="18px" height="21px" viewBox="0 0 18 21">
    <path d="M17.65,18.6 L15.3,16.25 L9,9.96 L5.44,6.39 L4.02,4.98 L1.27,2.23 L0,3.5 L2.78,6.28 C0.23,9.42 0.42,14.04 3.34,16.97 C4.9,18.53 6.95,19.31 9,19.31 C10.79,19.31 12.57,18.72 14.03,17.53 L16.73,20.23 L18,18.96 L17.65,18.6 Z M9,17.32 C7.4,17.32 5.89,16.7 4.76,15.56 C3.62,14.42 3,12.92 3,11.32 C3,10 3.43,8.75 4.21,7.72 L9,12.5 L9,17.32 Z M9,2.83 L9,7.41 L16.25,14.67 C17.62,11.71 17.09,8.1 14.65,5.66 L9,0 L5.3,3.7 L6.71,5.11 L9,2.83 Z" />
  </svg>
);

export default ResetColor;
