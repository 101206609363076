import * as Constants from 'const';
import * as Models from 'models';
import { AbbreviationsListRelationStyles } from './types';

export const BrandStyleIdForAbbreviationsList = 'footnote';

export const AbbreviationsListRelationDefaultStyles: AbbreviationsListRelationStyles = {
  // brandStyleId should be added externally not inside default styles
  alignment: {
    horizontal: Constants.AssetAlignmentType.LEFT,
    vertical: Constants.AssetAlignmentType.TOP,
  },
  fontColor: '#111111',
  fontFamily: 'BaseFont',
  fontSize: 14,
  fontStyle: 'Regular',
  lineHeight: 1.5,
};

export const AbbreviationsListLayoutDefaultStyles: Models.LayoutStyles = {
  responsive: true,
  scrollable: false,
  height: undefined,
  backgroundColor: undefined,
  backgroundColorTint: undefined,
  backgroundColorOpacity: 100,
  backgroundImage: {
    // @ts-expect-error: let's skip this property
    id: undefined,
    type: Constants.BackgroundImageType.ORIGINAL_SIZE,
    alignment: {
      horizontal: Constants.AssetAlignmentType.HORIZONTAL_CENTER,
      vertical: Constants.AssetAlignmentType.VERTICAL_CENTER,
    },
  },
  border: {
    // @ts-expect-error: let's skip this property
    color: undefined,
    colorTint: undefined,
    width: { top: 0, left: 0, right: 0, bottom: 0 },
  },
  borderRadius: { top: 0, left: 0, right: 0, bottom: 0 },
  isAutoFitContent: true,
  padding: { top: 2, left: 8, right: 8, bottom: 0 },
};
