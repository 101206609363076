import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProjectScreen from 'components/ProjectScreen';
import { artboardScale, isLocked, showPreview, showScreensPanel } from 'containers/App/selectors';
import { activeArtboard } from 'containers/Artboards/selectors';
import { projectFlatColors } from 'containers/BrandDefinition/selectors';
import { artboardBackgroundFromMasterScreen } from 'containers/Project/selectors';
import { rootDocument } from 'containers/RootDocument/selectors';
import { getProjectData } from 'containers/WelcomeScreen/actions';

const mapStateToProps = createStructuredSelector({
  activeArtboard,
  artboardBackgroundFromMasterScreen,
  colors: projectFlatColors,
  isLocked,
  rootDocument,
  showScreensPanel,
  showPreview,
  scale: artboardScale,
});

const mapDispatchToProps = {
  getProjectData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectScreen);
