import { isEmptyLayout } from 'utils/layouts/isLayoutEmpty';
import * as Models from './models';

export const shouldDisableDragAndDeleteActions = <P extends Models.ArtboardLayoutOwnProps & Models.ArtboardLayoutSelectors>(props: P): boolean => {
  const {
    disableDragAndDelete,
    documents,
    isLastLayout,
    isGroupLayoutBeingUploaded,
    layout,
    layoutRelations,
  } = props;

  return isGroupLayoutBeingUploaded || disableDragAndDelete || (isLastLayout && layoutRelations && isEmptyLayout(layout, layoutRelations, documents));
};
