import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AddScreen from 'components/ModalWindows/AddScreen';
import { hideModal } from 'containers/ModalManager/actions';
import { projectType } from 'containers/Project/selectors';
import { screenDefinitions } from 'containers/ScreenDefinitions/selectors';
import { surfaceNames } from 'containers/Surfaces/selectors';
import { addScreen } from './actions';

const mapStateToProps = createStructuredSelector({
  projectType,
  screenDefinitions,
  screenNames: surfaceNames,
});

const mapDispatchToProps = {
  addScreen,
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddScreen);
