import _ from 'lodash';

import { LAST_VISITED_SLIDE, NEXT_SLIDE, PREVIOUS_SLIDE } from 'const';
import { ScreensMap } from 'models';
import { createScreenRef } from 'services/ArtboardConverter/factories';
import { Schemas } from 'services/ArtboardConverter/models';
import { getScreenPosition } from 'utils/getScreenPosition';

/**
 * surfaces argument needs in order to not create a link which refers to a not existing surface
 */
export function getLinks(link: string, surfaces: ScreensMap): Schemas.Links {
  const surface = surfaces.get(link.substr(1));
  const screenName = surface && surface.get('name');
  const screenPosition = surface && getScreenPosition(surface, surfaces);
  const screenLink = !!surface || _.includes([NEXT_SLIDE, PREVIOUS_SLIDE, LAST_VISITED_SLIDE], link) ? link : '';
  const href = !screenLink ? link : '';

  return {
    href,
    screenRef: createScreenRef({
      screenLink,
      screenName,
      screenPosition,
    }),
  };
}
