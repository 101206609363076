import _ from 'lodash';

import * as Models from 'models';
import { fitLayoutIntoSection } from 'utils/layouts/fitLayoutIntoSection';
import { fitLayoutIntoSSIElement } from 'utils/layouts/fitLayoutIntoSSIElement';

export function adjustRelationsToParticularLayout(
  relations: Models.LayeredRelationsMap,
  layout: Models.LayoutMap,
  ssi: Models.SSIMap,
  sectionsWidth: Models.MasterScreen.SectionsWidthMap,
  sectionWidth: number,
): Models.LayeredRelationsMap {
  return _.flow<[Models.LayeredRelationsMap], Models.LayeredRelationsMap, Models.LayeredRelationsMap>(
    relations => sectionWidth ? fitLayoutIntoSection(layout, relations, sectionWidth) : relations,
    relations => fitLayoutIntoSSIElement(layout, sectionsWidth, ssi, relations),
  )(relations);
}
