import { MapDispatchToPropsFunction, connect } from 'react-redux';

import AddReferenceCitation from 'components/ModalWindows/AddReferenceCitation';
import * as Models from 'components/ModalWindows/AddReferenceCitation/models';
import { addReferenceCitation } from 'containers/Documents/actions';
import { hideModal } from 'containers/ModalManager/actions';

type Actions = Models.AddReferenceCitationActions;
type OwnProps = Models.AddReferenceCitationOwnProps;

const mapDispatchToProps: MapDispatchToPropsFunction<Actions, OwnProps> = (dispatch, props) => ({
  hideModal: () => dispatch(hideModal(props.modalWindowId)),
  addCitation: text => dispatch(addReferenceCitation(props.modalWindowId, text)),
});

export default connect<null, Actions, OwnProps>(null, mapDispatchToProps)(AddReferenceCitation);
