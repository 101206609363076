import classNames from 'classnames';
import { fromJS } from 'immutable';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ColorsList from 'components/Toolbar/Shared/ColorsList';
import { BrandColorMap } from 'models';
import { BrandColorGradientMap, brandColorGradientIsMissingDirectionOnly, brandColorGradientIsValid } from 'models/BrandColorGradient';
import { BackgroundGradientDirection } from 'models/styles/style/BackgroundGradient';
import { intlGet } from 'utils/intlGet';
import Header from '../Shared/Header';
import BackupNotice from './BackupNotice';
import GradientDirection from './GradientDirection';
import GradientPreview from './GradientPreview';
import { IColorGradientPickerProps } from './models';
import styles from './styles.module.scss';

const ColorGradientPicker: React.FunctionComponent<IColorGradientPickerProps> = (props) => {
  const { options, hideModal } = props;
  const activeGradient = options.get('gradient');
  const activeBackupColor = options.get('backupColor');
  const onChange = options.get('onGradientChange');
  const colors = options.get('colors');
  const colorsCategories = options.get('colorsCategories');

  const [backupColor, setBackupColor] = useState<BrandColorMap>(activeBackupColor);
  const [gradient, setGradient] = useState<BrandColorGradientMap>(() => activeGradient || fromJS({}));

  const onSaveClick: React.MouseEventHandler<HTMLButtonElement> = (): void => {
    onChange(gradient, backupColor);
    hideModal();
  };

  useEffect(() => {
    if (brandColorGradientIsMissingDirectionOnly(gradient)) {
      setGradient(prev => prev.set('direction', BackgroundGradientDirection.DEG0));
    }
  }, [gradient]);

  const isValid = brandColorGradientIsValid(gradient);
  const isReadyToSave = !!(isValid && backupColor);

  return (
    <div className={styles.container}>
      <Header title={intlGet('ColorGradientPickerWindow', 'Title')} onHide={hideModal} />

      <Row className="my-4">
        <Col xs={4} className={classNames(styles.bodyCell, 'border-right')}>
          <div className="pe-3 d-flex flex-column h-100">

            <h6 className="d-flex align-items-center">
              <div>{intlGet('ColorGradientPickerWindow', 'BackupTitle')}</div>
              <BackupNotice
                className="mx-2"
                tooltipClassName={styles.colorsListTooltip}
              />
            </h6>

            <ColorsList
              colorsCategories={colorsCategories}
              colors={colors}
              value={backupColor}
              onChange={setBackupColor}
              className={styles.colorsList}
              tooltipClassName={styles.colorsListTooltip}
            />

          </div>
        </Col>
        <Col xs={8} className={classNames(styles.bodyCell)}>
          <div className="ps-3 d-flex flex-column h-100">

            <h6>{intlGet('ColorGradientPickerWindow', 'GradientTitle')}</h6>

            <GradientDirection
              className="mb-3"
              value={gradient.get('direction')}
              onChange={(value): void => setGradient(prev => prev.set('direction', value))}
            />

            <Row className="reset-height mb-3">
              <Col className="h-100 d-flex flex-column border-right">
                <div className="mb-1">{intlGet('ColorGradientPickerWindow', 'Color1Title')}</div>
                <ColorsList
                  colorsCategories={colorsCategories}
                  colors={colors}
                  value={gradient.get('color1')}
                  onChange={(value): void => setGradient(prev => prev.set('color1', value))}
                  className={styles.colorsList}
                  tooltipClassName={styles.colorsListTooltip}
                />
              </Col>
              <Col className="h-100 d-flex flex-column">
                <div className="mb-1">{intlGet('ColorGradientPickerWindow', 'Color2Title')}</div>
                <ColorsList
                  colorsCategories={colorsCategories}
                  colors={colors}
                  value={gradient.get('color2')}
                  onChange={(value): void => setGradient(prev => prev.set('color2', value))}
                  className={styles.colorsList}
                  tooltipClassName={styles.colorsListTooltip}
                />
              </Col>
            </Row>

            <div className="mb-1">{intlGet('ColorGradientPickerWindow', 'PreviewTitle')}</div>
            <GradientPreview gradient={isValid ? gradient : undefined} />

          </div>
        </Col>
      </Row>

      <footer>
        <Button variant="secondary" onClick={hideModal}>
          {intlGet('ColorGradientPickerWindow.Button', 'Cancel')}
        </Button>
        <Button variant="primary" onClick={onSaveClick} disabled={!isReadyToSave}>
          {intlGet('ColorGradientPickerWindow.Button', 'Save')}
        </Button>
      </footer>
    </div>
  );
};

export default ColorGradientPicker;
