import _ from 'lodash';

import { getIntegerFromStyle } from './getIntegerFromStyle';

export const getContainerSize = (container: HTMLElement): { height: number; width: number } => {
  const computedStyles = getComputedStyle(container);

  return {
    height: getAvailableHeight(computedStyles),
    width: getAvailableWidth(computedStyles),
  };
};

const getProperties = (...propertiesToPick: string[]) => (styles: CSSStyleDeclaration): number => {
  return _(_.pick(styles, propertiesToPick)).mapValues(getIntegerFromStyle).reduce(_.add);
};
const getWidth = getProperties('width');
const getHeight = getProperties('height');
const getAvailableWidth = (styles: CSSStyleDeclaration): number => Math.max(0, getWidth(styles) - getExtraWidth(styles));
const getAvailableHeight = (styles: CSSStyleDeclaration): number => Math.max(0, getHeight(styles) - getExtraHeight(styles));

export const getExtraWidth = getProperties('paddingLeft', 'paddingRight', 'borderLeft', 'borderRight');
export const getExtraHeight = getProperties('paddingTop', 'paddingBottom', 'borderTop', 'borderBottom');
