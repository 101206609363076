import React from 'react';
/* eslint-disable max-len */

const Add = (): JSX.Element => (
  <svg viewBox="0 0 15 16">
    <path d="M7.75,0 C8.30228475,-1.01453063e-16 8.75,0.44771525 8.75,1 L8.75,5.249 L13,5.25 C13.5522847,5.25 14,5.69771525 14,6.25 L14,7.75 C14,8.30228475 13.5522847,8.75 13,8.75 L8.75,8.749 L8.75,13 C8.75,13.5522847 8.30228475,14 7.75,14 L6.25,14 C5.69771525,14 5.25,13.5522847 5.25,13 L5.25,8.749 L1,8.75 C0.44771525,8.75 0,8.30228475 0,7.75 L0,6.25 C0,5.69771525 0.44771525,5.25 1,5.25 L5.25,5.249 L5.25,1 C5.25,0.44771525 5.69771525,1.01453063e-16 6.25,0 L7.75,0 Z" />
  </svg>
);

export default Add;
