import textAbbreviationSagas from 'modules/Abbreviations/store/sagas';
import { Effect, all } from 'redux-saga/effects';

import appSagas from 'containers/App/sagas';
import artboardCellSagas from 'containers/ArtboardCell/sagas';
import artboardPreviewSagas from 'containers/ArtboardPreview/sagas';
import artboardsSagas from 'containers/Artboards/sagas';
import documentsSagas from 'containers/Documents/sagas';
import layoutsSagas from 'containers/Layouts/sagas';
import modalManagerSagas from 'containers/ModalManager/sagas';
import addScreenSagas from 'containers/ModalWindows/AddScreen/sagas';
import exportWindowSagas from 'containers/ModalWindows/Export/sagas';
import saveGroupLayoutSagas from 'containers/ModalWindows/SaveGroupLayout/sagas';
import saveLayoutSagas from 'containers/ModalWindows/SaveReusableLayout/sagas';
import projectSagas from 'containers/Project/sagas';
import projectScreenSagas from 'containers/ProjectScreen/sagas';
import relationsSagas from 'containers/Relations/sagas';
import moveScreenSagas from 'containers/ScreensPanel/sagas';
import surfacesSagas from 'containers/Surfaces/sagas';
import welcomeScreenSagas from 'containers/WelcomeScreen/sagas';

export default function* rootSaga() {
  const effects = [
    ...addScreenSagas,
    ...appSagas,
    ...artboardCellSagas,
    ...artboardPreviewSagas,
    ...artboardsSagas,
    ...documentsSagas,
    ...exportWindowSagas,
    ...saveGroupLayoutSagas,
    ...layoutsSagas,
    ...modalManagerSagas,
    ...moveScreenSagas,
    ...projectSagas,
    ...projectScreenSagas,
    ...relationsSagas,
    ...saveLayoutSagas,
    ...surfacesSagas,
    ...welcomeScreenSagas,
    ...textAbbreviationSagas,
  ] as Effect[];

  yield all(effects);
}
