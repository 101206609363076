import _ from 'lodash';
import React from 'react';
import { Button, Form } from 'react-bootstrap';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import ImageInputBlock from 'components/ImageInputBlock';
import SelectItem from 'components/SelectItem';
import { DocumentClassification, DocumentSubtype, DocumentType, MAX_DOCUMENT_NAME_LENGTH, ResolutionType } from 'const';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { AddImageProps, AddImageState } from './models';
import styles from './styles.module.scss';

class AddImage extends React.PureComponent<AddImageProps, AddImageState> {
  readonly state: AddImageState = {
    imageFiles: {},
    documentName: '',
    subtype: null,
    classification: null,
  };

  private onSaveClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const { imageFiles, documentName, subtype, classification } = this.state;
    const { addImage } = this.props;

    addImage({
      documentName,
      subtype,
      classification,
      imageFiles,
    });
  };

  private changeDocumentName: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      documentName: event.target.value,
    });
  };

  private saveDocumentName: React.FocusEventHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name: sourceImageName } = this.state.imageFiles.source;

    this.setState({
      documentName: event.target.value || sourceImageName,
    });
  };

  private setImageFiles = (imageFiles: PartialRecord<ResolutionType, File>): void => {
    this.setState({ imageFiles });

    if (!this.state.documentName && imageFiles.source) {
      const documentName = imageFiles.source.name || this.state.documentName;
      this.setState({ documentName });
    }
  };

  private changeDocumentSubtype: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      subtype: event.target.value as DocumentSubtype,
      classification: null,
    });
  };

  private changeDocumentClassification: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      classification: event.target.value as DocumentClassification,
    });
  };

  private getSortedSubtypesAndClassifications = (objectToSort: Models.DocumentSubtypesMap | Models.DocumentClassificationsMap): string[] => {
    return objectToSort
      ? _.map(objectToSort.toJS() as Models.DocumentSubtypes & Models.DocumentClassifications, ({ label }) => label).sort()
      : [];
  };

  render() {
    const { documentTypes, hideModal } = this.props;
    const { imageFiles, documentName, subtype, classification } = this.state;

    const isSaveButtonAvailable = !!(imageFiles.source && imageFiles.low && subtype && classification);
    const documentType = documentTypes.get(DocumentType.COMPONENT);
    const documentSubtypesMap = documentType && documentType.get('subtypes');
    const documentSubtypes = this.getSortedSubtypesAndClassifications(documentSubtypesMap);

    const documentSubtypeMap = documentSubtypesMap && documentSubtypesMap.get(subtype);
    const documentClassificationsMap = documentSubtypeMap && documentSubtypeMap.get('classifications');
    const documentClassifications = this.getSortedSubtypesAndClassifications(documentClassificationsMap);

    return (
      <div className={styles.AddImage}>
        <header className={styles.header}>
          <div className={styles.info}>
            <div className={styles.title}>{intlGet('AddImageWindow', 'Title')}</div>
            <Icon className={styles.closeIcon} onClick={hideModal} type={IconType.CLOSE} size="sm-md" color="primary" />
          </div>
          <div>{intlGet('AddImageWindow', 'Description')}</div>
        </header>
        <main className={styles.main}>
          <Form.Group className={styles.imageFilesForm}>
            <Form.Control
              className={styles.imageDocumentName}
              type="text"
              maxLength={MAX_DOCUMENT_NAME_LENGTH}
              placeholder={intlGet('AddImageWindow.DocumentNamePlaceholder', 'DocumentName')}
              value={documentName}
              onChange={this.changeDocumentName}
              onBlur={this.saveDocumentName}
              disabled={!imageFiles.source}
            />
            <ImageInputBlock
              initialImage={null}
              imageFiles={imageFiles}
              setImageFiles={this.setImageFiles}
            />
          </Form.Group>
          <Form.Group className={styles.imageTypeForm}>
            <h3 className={styles.selectTitle}>{intlGet('AddImageWindow.Select', 'Subtype')}</h3>
            <SelectItem
              className={styles.selectWrapper}
              emptyOptionText={intlGet('AddImageWindow.Select.EmptyValue', 'SelectSubtype')}
              onChange={this.changeDocumentSubtype}
              value={subtype || ''}
              values={documentSubtypes}
            />
            <Icon type={IconType.ARROW_LEFT} size="sm" color={subtype ? 'dark-grey' : 'light-grey'} />
            <h3 className={styles.selectTitle}>{intlGet('AddImageWindow.Select', 'Classification')}</h3>
            <SelectItem
              className={styles.selectWrapper}
              emptyOptionText={intlGet('AddImageWindow.Select.EmptyValue', 'SelectClassification')}
              onChange={this.changeDocumentClassification}
              value={classification || ''}
              values={documentClassifications}
            />
          </Form.Group>
        </main>
        <footer className={styles.footer}>
          <Button variant="secondary" onClick={hideModal}>{intlGet('AddImageWindow', 'Cancel')}</Button>
          <Button variant="primary" onClick={this.onSaveClick} disabled={!isSaveButtonAvailable}>{intlGet('AddImageWindow', 'Save')}</Button>
        </footer>
      </div>
    );
  }
}

export default AddImage;
