import Immutable from 'immutable';

import { DEFAULT_ARTBOARD_BACKGROUND, Layer } from 'const';
import { Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

export const projectInitialState: Models.State.IState = Immutable.fromJS({
  activeLayer: Layer.ORIGINAL,
  originalLanguage: Immutable.List(),
  originalCountry: Immutable.List(),
  originalProduct: Immutable.List(),
  artboardBackground: DEFAULT_ARTBOARD_BACKGROUND,
  projectType: null,
  helperFileName: null,
  surfaceIds: Immutable.List(),
  activeSurfaceId: '',
  activePreviewOptionName: null,
  lastRefreshTime: null,
  lastLocalSaveTime: null,
  isLocalSaveInProgress: false,
  isAutoSaveActive: false,
  areDetailsLoading: false,
  documentIdsAreBeingUploaded: Immutable.Set<string>(),
  placeholderMinHeight: null,
  isOpenToolbar: false,
  suppressTranslation: false,
});

const projectReducer: Reducer<Models.State.IState> =
  (state = projectInitialState, action) => {
    switch (action.type) {
      case ActionTypes.ADD_SURFACE_ID:
        return addSurfaceId(state, action);
      case ActionTypes.SET_SURFACE_IDS:
        return setSurfaceIds(state, action);
      case ActionTypes.UPDATE_ACTIVE_SURFACE_ID:
        return updateActiveSurfaceId(state, action);
      case ActionTypes.SET_LAST_REFRESH_TIME:
        return setLastRefreshTime(state, action);
      case ActionTypes.SET_LAST_LOCAL_SAVE_TIME:
        return setLastLocalSaveTime(state, action);
      case ActionTypes.START_LOCAL_SAVE:
        return startLocalSave(state, action);
      case ActionTypes.END_LOCAL_SAVE:
        return endLocalSave(state, action);
      case ActionTypes.SET_AUTO_SAVE_STATE:
        return setAutoSaveState(state, action);
      case ActionTypes.SET_UPLOADING_DOCUMENT_ID:
        return setUploadingDocumentId(state, action);
      case ActionTypes.DELETE_UPLOADING_DOCUMENT_ID:
        return deleteUploadingDocumentId(state, action);
      case ActionTypes.SET_ACTIVE_PREVIEW_OPTION:
        return setActivePreviewOption(state, action);
      case ActionTypes.UPDATE_PROJECT_STATE:
        return updateProjectState(state, action);
      default:
        return state;
    }
  };

const addSurfaceId: Reducer<Models.State.IState, Models.Action.IAddSurfaceId> = (state, action) => {
  const { position, surfaceId } = action.payload;

  return state.update('surfaceIds', (surfaceIds: Immutable.List<string>) => surfaceIds.insert(position, surfaceId));
};

const setSurfaceIds: Reducer<Models.State.IState, Models.Action.ISetSurfaceIds> = (state, action) => {
  const { surfaceIds } = action.payload;

  return state.set('surfaceIds', Immutable.fromJS(surfaceIds));
};

const updateActiveSurfaceId: Reducer<Models.State.IState, Models.Action.IUpdateActiveSurfaceId> = (state, action) => {
  const { surfaceId } = action.payload;

  return state.set('activeSurfaceId', surfaceId);
};

const setLastRefreshTime: Reducer<Models.State.IState, Models.Action.ISetLastRefreshTime> = (state, action) => {
  const { time } = action.payload;

  return state.set('lastRefreshTime', time);
};

export const setLastLocalSaveTime: Reducer<Models.State.IState, Models.Action.ISetLastLocalSaveTime> = (state, action) => {
  const { time } = action.payload;

  return state.set('lastLocalSaveTime', time);
};

export const startLocalSave: Reducer<Models.State.IState, Models.Action.IStartLocalSave> = (state) => {
  return state.set('isLocalSaveInProgress', true);
};

export const endLocalSave: Reducer<Models.State.IState, Models.Action.IStartLocalSave> = (state) => {
  return state.set('isLocalSaveInProgress', false);
};

export const setAutoSaveState: Reducer<Models.State.IState, Models.Action.ISetAutoSaveState> = (state, action) => {
  const { isActive } = action.payload;

  return state.set('isAutoSaveActive', isActive);
};

const setUploadingDocumentId: Reducer<Models.State.IState, Models.Action.ISetUploadingDocumentId> = (state, action) => {
  const { id } = action.payload;

  return state.update('documentIdsAreBeingUploaded', (documentIdsAreBeingUploaded: Immutable.Set<string>) => documentIdsAreBeingUploaded.add(id));
};

const deleteUploadingDocumentId: Reducer<Models.State.IState, Models.Action.IDeleteUploadingDocumentId> = (state, action) => {
  const { id } = action.payload;

  return state.update(
    'documentIdsAreBeingUploaded',
    (documentIdsAreBeingUploaded: Immutable.Set<string>) => id ? documentIdsAreBeingUploaded.remove(id) : documentIdsAreBeingUploaded,
  );
};

const setActivePreviewOption: Reducer<Models.State.IState, Models.Action.ISetActivePreviewOptionName> = (state, action) => {
  const { previewOptionName } = action.payload;

  return state.set('activePreviewOptionName', previewOptionName);
};

// todo: replace with a bunch of separate actions to save performance (task)
const updateProjectState: Reducer<Models.State.IState, Models.Action.IUpdateProjectState> = (state, action) => {
  const { state: newState } = action.payload;

  return state.merge(Immutable.fromJS(newState));
};

export default projectReducer;
