import _ from 'lodash';
import { generatorTextAbbreviations } from 'modules/Abbreviations/store/services/generatorTextAbbreviations';
import { batchActions } from 'redux-batched-actions';
import { call, put, select, spawn } from 'redux-saga/effects';

import { MethodName, ProcessType } from 'const';
import * as AppActions from 'containers/App/actions';
import { initApp } from 'containers/Common/services/initApp';
import { processRefreshedAssets } from 'containers/Common/services/processRefreshedAssets';
import { nonUploadingDocuments as documentsSelector } from 'containers/Documents/selectors';
import * as ProjectActions from 'containers/Project/actions';
import { isProjectTranslatable as isProjectTranslatableSelector } from 'containers/Project/selectors';
import { getDocumentsOnArtboards } from 'containers/Project/services/getDocumentsOnArtboards';
import { rootDocumentId as rootDocumentIdSelector } from 'containers/RootDocument/selectors';
import * as Models from 'models';
import { getRefreshedProject } from 'services/api';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { deleteDocumentInternalInfo } from 'utils/deleteInternalInfo';
import { intlGet } from 'utils/intlGet';

export function* refreshProject() {
  const logId = logger.performanceStart();
  const methodName = MethodName.REFRESH_PROJECT;

  try {
    yield put(AppActions.lockProjectContent(ProcessType.REFRESH));
    yield put(AppActions.stopHandlingReusableLayoutsEditing());

    const isProjectTranslatable: ReturnTypeSaga<typeof isProjectTranslatableSelector> = yield select(isProjectTranslatableSelector);
    const rootDocumentId: ReturnTypeSaga<typeof rootDocumentIdSelector> = yield select(rootDocumentIdSelector);
    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const documentsToSync = (yield call(getDocumentsOnArtboards, ProcessType.REFRESH)).toJS() as Models.CombinedDocuments;

    const {
      data: refreshedAssets,
    }: ReturnTypeSaga<typeof getRefreshedProject> = yield call(
      getRefreshedProject,
      rootDocumentId,
      _.mapValues(documents, deleteDocumentInternalInfo),
      _.keys(documentsToSync),
    );

    const {
      assets,
      actionsWithMiddleware,
      newDocumentsQuantity,
      notificationOptions,
    }: ReturnTypeSaga<typeof processRefreshedAssets> = yield call(
      processRefreshedAssets,
      refreshedAssets,
      isProjectTranslatable,
    );

    yield call(initApp, { actionsWithMiddleware, projectAssets: assets });
    yield call(generatorTextAbbreviations, rootDocumentId);

    yield put(batchActions([
      ProjectActions.updateProjectState({ lastRefreshTime: Date.now() }),
      AppActions.toggleUpdatedDocumentsDisplaying(true),
    ]));

    const NOTIFICATION_SUCCESS_INTL = 'Notification.Success';

    yield call(
      [Notifications, Notifications.success],
      intlGet(
        NOTIFICATION_SUCCESS_INTL,
        'ItemsAddedToTheAssetPanel',
        {
          quantity: `${newDocumentsQuantity}`,
          subjectWithAction: newDocumentsQuantity === 1
            ? intlGet(NOTIFICATION_SUCCESS_INTL, 'ItemsAddedToTheAssetPanel.ItemHasBeenAdded')
            : intlGet(NOTIFICATION_SUCCESS_INTL, 'ItemsAddedToTheAssetPanel.ItemsHaveBeenAdded'),
        },
      ),
    );

    yield put(AppActions.unlockProjectContent());

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    // notifications should be shown after project content is unlocked
    // shouldn't be shown if there were errors
    for (const notificationOption of notificationOptions) {
      yield call(
        [Notifications, Notifications.showNotification],
        notificationOption,
      );
    }
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'ProjectScreen.refreshProject', 'Refresh');
  } finally {
    yield put(AppActions.unlockProjectContent());
    yield put(AppActions.startHandlingReusableLayoutsEditing());
  }
}
