import _ from 'lodash';

import { toPx } from 'utils/toPx';

type GetImageSizeArg<T extends boolean> = {
  cellInnerHeight?: number;
  cellInnerWidth: number;
  docImageHeight: number;
  docImageWidth: number;
  scale: number;
  isOnResize?: boolean;
  toPx?: T;
};

export type GetImageSizeResult<T> = T extends false ? { width: number; height: number } : { width: string; height: string };

export function getImageSize<T extends boolean = false>({
  cellInnerHeight,
  cellInnerWidth,
  docImageHeight,
  docImageWidth,
  isOnResize,
  scale,
  toPx: convertToPx = false as T,
}: GetImageSizeArg<T>): GetImageSizeResult<T> {
  let currentImageWidth = _.max([_.round(cellInnerWidth * scale), 1]);
  let currentImageHeight = _.max([_.round(currentImageWidth * docImageHeight / docImageWidth), 1]);

  if (cellInnerHeight && currentImageHeight > cellInnerHeight && !isOnResize) {
    currentImageHeight = cellInnerHeight;
    currentImageWidth = currentImageHeight * docImageWidth / docImageHeight;
  }

  if (cellInnerHeight && currentImageHeight < cellInnerHeight && !isOnResize) {
    currentImageWidth = currentImageHeight * docImageWidth / docImageHeight;
  }

  return {
    width: convertToPx ? toPx(currentImageWidth) : currentImageWidth,
    height: convertToPx ? toPx(currentImageHeight) : currentImageHeight,
  } as GetImageSizeResult<T>;
}
