import { EntityType } from 'const';
import { getValue } from 'utils/getter';

export function isTranslatableEntity(entity): boolean {
  const entityType = getValue(entity, 'entityType');

  return [
    EntityType.CALL_TO_ACTION,
    EntityType.GROUP_LAYOUT,
    EntityType.IMAGE,
    EntityType.LAYOUT,
    EntityType.REFERENCE_CITATION,
    EntityType.TEXT,
  ].includes(entityType);
}
