import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IButtonProps, IButtonState } from './models';
import Styles from './styles.module.scss';

export default class Button extends React.PureComponent<IButtonProps, IButtonState> {
  static defaultProps: DefaultProps<IButtonProps> = {
    size: 'sm',
    disabled: false,
    highlightOnHover: false,
    onClick: () => {},
    title: null,
  };

  private handleClick = (event: React.MouseEvent): void => {
    const { disabled, onClick } = this.props;
    // The following code is here because we need to manually block
    // the preview button and upload button from clicking when toolbar is opened (DCC-2711, DCC-4278)
    const target = event.target as HTMLDivElement;
    if (target.dataset.disabled_button) {
      target.removeAttribute('data-disabled_button');
    } else {
      !disabled && onClick(event);
    }
  };

  render() {
    const { icon, size, title, highlightOnHover, disabled } = this.props;

    return (
      <div
        className={classNames(Styles.Button, {
          [Styles.Button__disabled]: disabled,
        })}
        onClick={this.handleClick}
      >
        <Icon color="primary" size={size} type={icon} isDisabled={disabled} />
        {
          !!title &&
          <span className={classNames(Styles.title, { [Styles.withHighlighting]: highlightOnHover && !disabled })}>{title}</span>
        }
      </div>
    );
  }
}
