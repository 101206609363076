import { Schemas } from 'services/ArtboardConverter/models';

export const createSSI = ({
  position,
  section,
  layouts,
  height,
  width,
  sourceArtboardId,
  sourceSection,
} = {} as Partial<Schemas.SSI>): Schemas.SSI => {
  const ssiSchema: Schemas.SSI = {
    position,
    section,
    layouts,
    height,
    sourceArtboardId,
    sourceSection,
  };

  width && (ssiSchema.width = width);

  return ssiSchema;
};
