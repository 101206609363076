import _ from 'lodash';
import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { ErrorType } from 'const';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import * as projectActions from 'containers/Project/actions';
import { ActionTypes } from 'containers/Project/constants';
import { isLocalSaveInProgress } from 'containers/Project/selectors';
import { ProjectAssetsToUpload } from 'models';
import { handleSagaError } from 'services/handleError';
import { saveProjectLocally as saveProject } from 'services/localSave';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { Action } from '../models';

export function* saveProjectLocally(action: Action.ISaveProjectLocally) {
  const options = _.defaults(action.payload, { showNotifications: true });
  const { showNotifications } = options;
  let localSaveInProgress: boolean;

  try {
    localSaveInProgress = yield select(isLocalSaveInProgress);

    if (localSaveInProgress) {
      return;
    }

    yield put(projectActions.startLocalSave());
    let projectAssets = options.projectAssets;

    if (!projectAssets) {
      projectAssets = (yield call(getProjectAssets)) as ReturnTypeSaga<typeof getProjectAssets> as ProjectAssetsToUpload;
    }

    const lastLocalSaveTime: ReturnTypeSaga<typeof saveProject> = yield call(saveProject, projectAssets);

    if (showNotifications) {
      yield call(
        [Notifications, Notifications.success],
        intlGet('Notification.Success.SaveLocally', 'Message'),
        intlGet('Notification.Success.SaveLocally', 'Title'),
      );
    }

    yield put(projectActions.setLastLocalSaveTime(lastLocalSaveTime));
  } catch (error) {
    yield call(handleSagaError, error, 'App.saveProjectLocally', 'Save', ErrorType.Assembler.SAVE_LOCALLY);
  } finally {
    !localSaveInProgress && (yield race([
      call(endLocalSave),
      take(ActionTypes.START_LOCAL_SAVE),
    ]));
  }
}

function* endLocalSave() {
  try {
    yield put(projectActions.endLocalSave());
    yield delay(10000);
    yield put(projectActions.setLastLocalSaveTime(null));
  } catch (error) {
    yield call(handleSagaError, error, 'App.endLocalSave', 'Save', ErrorType.Assembler.SAVE_LOCALLY);
  }
}
