import { ActionTypes } from './constants';
import * as Models from './models';

export const addSurfaceId: Models.ActionCreator.AddSurfaceId = (surfaceId, position) => ({
  type: ActionTypes.ADD_SURFACE_ID,
  payload: {
    surfaceId,
    position,
  },
});

export const setSurfaceIds: Models.ActionCreator.SetSurfaceIds = surfaceIds => ({
  type: ActionTypes.SET_SURFACE_IDS,
  payload: {
    surfaceIds,
  },
});

export const updateActiveSurfaceId: Models.ActionCreator.UpdateActiveSurfaceId = surfaceId => ({
  type: ActionTypes.UPDATE_ACTIVE_SURFACE_ID,
  payload: {
    surfaceId,
  },
});

export const setLastRefreshTime: Models.ActionCreator.SetLastRefreshTime = time => ({
  type: ActionTypes.SET_LAST_REFRESH_TIME,
  payload: {
    time,
  },
});

export const setLastLocalSaveTime: Models.ActionCreator.SetLastLocalSaveTime = time => ({
  type: ActionTypes.SET_LAST_LOCAL_SAVE_TIME,
  payload: {
    time,
  },
});

export const startLocalSave: Models.ActionCreator.StartLocalSave = () => ({
  type: ActionTypes.START_LOCAL_SAVE,
});

export const endLocalSave: Models.ActionCreator.StartLocalSave = () => ({
  type: ActionTypes.END_LOCAL_SAVE,
});

export const activateAutoSave: Models.ActionCreator.SetAutoSaveState = () => ({
  type: ActionTypes.SET_AUTO_SAVE_STATE,
  payload: {
    isActive: true,
  },
});

export const disableAutoSave: Models.ActionCreator.SetAutoSaveState = () => ({
  type: ActionTypes.SET_AUTO_SAVE_STATE,
  payload: {
    isActive: false,
  },
});

export const getProjectDetails: Models.ActionCreator.GetProjectDetails = () => ({
  type: ActionTypes.GET_PROJECT_DETAILS,
});

export const setUploadingDocumentId: Models.ActionCreator.SetUploadingDocumentId = id => ({
  type: ActionTypes.SET_UPLOADING_DOCUMENT_ID,
  payload: {
    id,
  },
});

export const deleteUploadingDocumentId: Models.ActionCreator.DeleteUploadingDocumentId = id => ({
  type: ActionTypes.DELETE_UPLOADING_DOCUMENT_ID,
  payload: {
    id,
  },
});

export const uploadToPromoMats: Models.ActionCreator.UploadToPromoMats = (uploadProjectType, translationComplete) => ({
  type: ActionTypes.UPLOAD_TO_PROMOMATS,
  payload: {
    uploadProjectType,
    translationComplete,
  },
});

export const setActivePreviewOption: Models.ActionCreator.SetActivePreviewOptionName = previewOptionName => ({
  type: ActionTypes.SET_ACTIVE_PREVIEW_OPTION,
  payload: {
    previewOptionName,
  },
});

export const updateProjectState: Models.ActionCreator.UpdateProjectState = state => ({
  type: ActionTypes.UPDATE_PROJECT_STATE,
  payload: {
    state,
  },
});

export const setActiveLayer: Models.ActionCreator.SetActiveLayer = layer => ({
  type: ActionTypes.SET_ACTIVE_LAYER,
  payload: {
    layer,
  },
});
