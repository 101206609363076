import * as Models from 'models';
import { getValue } from './getter';

export function isGroupLayoutAssets(assets: Models.CombinedLayoutAssets): assets is Models.GroupLayoutAssets;
export function isGroupLayoutAssets(assets: Models.CombinedLayoutAssetsMap): assets is Models.GroupLayoutAssetsMap;
export function isGroupLayoutAssets(
  assets: Models.CombinedLayoutAssets | Models.CombinedLayoutAssetsMap,
): assets is Models.CombinedLayoutAssets | Models.GroupLayoutAssetsMap {
  return !!getValue(assets as Models.GroupLayoutAssets, 'layouts');
}
