import _ from 'lodash';

import { DefaultCustomStyle, Layer, StylePrefix } from 'const';
import { CombinedLayouts, LayeredCombinedLayouts, LayeredRelations, OnlineBrandDefinition } from 'models';
import { isCallToAction, isTextComponent } from 'utils/entityType';
import { isObjectLayered } from 'utils/layers';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

export function getUsingLayoutFontsNotExistsInProject(
  relations: LayeredRelations,
  projectBrandDefinition: OnlineBrandDefinition,
  layouts: CombinedLayouts | LayeredCombinedLayouts,
  documentIds: string[],
  layer: Layer,
): string[] {
  // To stop show font difference dialog on each RL drop we check is that RL is using on artboard.
  if (_.some(layouts, (layout => documentIds.includes(isObjectLayered(layout) ? layout.documentId[layer] : layout.documentId)))) {
    return [];
  }

  const projectFontNames = _(projectBrandDefinition.fonts).map(font => font.name).uniq().value();
  projectFontNames.push(DefaultCustomStyle.FONT_FAMILY);

  const layoutFontNames = new Set<string>();
  _.forEach(relations, (rel) => {
    if (!isRegularRelation(rel)) {
      return;
    }

    if (isTextComponent(rel)) {
      const { fontFamily } = rel.styles[layer] || rel.styles[Layer.ORIGINAL];
      _.forEach(fontFamily, (fontFamilyRange) => {
        const fontName = fontFamilyRange.style.slice(StylePrefix.FONT_FAMILY.length);
        if (fontName && fontName !== 'null') {
          layoutFontNames.add(fontName);
        }
      });
    }

    if (isCallToAction(rel)) {
      const { fontFamily } = rel.styles[layer] || rel.styles[Layer.ORIGINAL];
      if (fontFamily && fontFamily !== 'null') {
        layoutFontNames.add(fontFamily);
      }
    }
  });

  return _.difference(Array.from(layoutFontNames), projectFontNames);
}
