import Immutable from 'immutable';

import * as Models from 'models';

export function getScreenDefinitionSectionStylesBySectionName(
  screenDefinitionSections: Immutable.List<Models.MasterScreen.SectionMap>,
): Immutable.Map<string, Immutable.List<string>> {
  return screenDefinitionSections
    .toMap()
    .mapKeys((key, section) => section.get('name'))
    .map(section => section.get('styles'));
}
