import React from 'react';
/* eslint-disable max-len */

const Link = (): JSX.Element => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <defs>
      <path style={{ fill: '#ffffff' }} d="M1.9,5 C1.9,3.29 3.29,1.9 5,1.9 L9,1.9 L9,0 L5,0 C2.24,0 0,2.24 0,5 C0,7.76 2.24,10 5,10 L9,10 L9,8.1 L5,8.1 C3.29,8.1 1.9,6.71 1.9,5 Z M6,6 L14,6 L14,4 L6,4 L6,6 Z M15,0 L11,0 L11,1.9 L15,1.9 C16.71,1.9 18.1,3.29 18.1,5 C18.1,6.71 16.71,8.1 15,8.1 L11,8.1 L11,10 L15,10 C17.76,10 20,7.76 20,5 C20,2.24 17.76,0 15,0 Z" id="Link-ID" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-1.000000, 12.000000)">
      <mask id="Link-ID-mask" fill="white">
        <use xlinkHref="#Link-ID" />
      </mask>
      <g mask="url(#Link-ID-mask)" fill="#FFFFFF" transform="rotate(-40.000000)">
        <rect x="0" y="0" width="20" height="12" />
      </g>
    </g>
  </svg>
);

export default Link;
