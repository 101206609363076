import { Effect, takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { showModal } from './showModal';

const effects: Effect[] = [
  takeEvery(ActionTypes.SHOW_MODAL, showModal),
];

export default effects;
