import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Section from 'components/Section';
import { SectionDispatchProps, SectionOwnProps, SectionStateProps } from 'components/Section/models';
import { toggleDragState, updateDragHotspotGroupLayoutId, updateDragHotspotPosition, updateSSIHotspotPosition } from 'containers/App/actions';
import * as appSelectors from 'containers/App/selectors';
import { addSSI, moveSSI } from 'containers/Artboards/actions';
import { projectFlatColors } from 'containers/BrandDefinition/selectors';
import { documents } from 'containers/Documents/selectors';
import { addLayoutRequest, dropGroupReusableLayout, dropReusableLayout, moveLayout } from 'containers/Layouts/actions';
import { layouts, orderedActiveLayoutIds } from 'containers/Layouts/selectors';
import { showModal } from 'containers/ModalManager/actions';
import { documentIdsAreBeingUploaded, isOpenToolbar, projectType } from 'containers/Project/selectors';
import { relationsByRegularLayoutId } from 'containers/Relations/selectors';
import { updateSection } from 'containers/Sections/actions';

const mapStateToProps = createStructuredSelector({
  activeLayoutIds: appSelectors.activeLayoutIds,
  allLayouts: layouts,
  artboardLayoutIds: orderedActiveLayoutIds,
  colors: projectFlatColors,
  documentIdsAreBeingUploaded,
  documents,
  dragHotspotGroupLayoutId: appSelectors.dragHotspotGroupLayoutId,
  dragHotspotPosition: appSelectors.dragHotspotPosition,
  dragSourceType: appSelectors.dragSourceType,
  isDraggingAsset: appSelectors.isDraggingAsset,
  isOpenToolbar,
  isResizingColumn: appSelectors.isResizingColumn,
  isResizingLayout: appSelectors.isResizingLayout,
  isResizingRow: appSelectors.isResizingRow,
  moveableLayoutId: appSelectors.moveableLayoutId,
  projectType,
  relationsByRegularLayoutId,
  ssiHotspotPosition: appSelectors.ssiHotspotPosition,
});

const mapDispatchToProps = {
  addLayout: addLayoutRequest,
  addSSI,
  dropGroupReusableLayout,
  dropReusableLayout,
  moveLayout,
  moveSSI,
  showModal,
  toggleDragState,
  updateDragHotspotGroupLayoutId,
  updateDragHotspotPosition,
  updateSSIHotspotPosition,
  updateSection,
};

export default connect<SectionStateProps, SectionDispatchProps, SectionOwnProps>(mapStateToProps, mapDispatchToProps)(Section);
