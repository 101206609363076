import React from 'react';
/* eslint-disable max-len */

const ContentCopy = (): JSX.Element => (
  <svg width="19px" height="23px" viewBox="0 0 19 23">
    <defs>
      <path d="M20,4.00666682 L8,4.00666682 C6.9,4.00666682 6,4.90816685 6,6.01000023 L6,20.0333341 L8,20.0333341 L8,6.01000023 L20,6.01000023 L20,4.00666682 L20,4.00666682 Z M23,8.01333364 L12,8.01333364 C10.9,8.01333364 10,8.91483367 10,10.016667 L10,24.0400009 C10,25.1418343 10.9,26.0433343 12,26.0433343 L23,26.0433343 C24.1,26.0433343 25,25.1418343 25,24.0400009 L25,10.016667 C25,8.91483367 24.1,8.01333364 23,8.01333364 L23,8.01333364 Z" id="ContentCopy" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-224.000000, -288.000000)">
        <g transform="translate(-5.000000, 179.000000)">
          <g transform="translate(6.000000, 90.150000)">
            <g transform="translate(217.000000, 15.025000)">
              <mask id="ContentCopy-ID-mask" fill="white">
                <use xlinkHref="#ContentCopy" />
              </mask>
              <g mask="url(#ContentCopy-ID-mask)" fill="#B3B3B3">
                <g transform="translate(2.000000, 2.003333)">
                  <rect x="0" y="0" width="26" height="26.043335" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ContentCopy;
