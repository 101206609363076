import Immutable from 'immutable';
import _ from 'lodash';
import { call, select } from 'redux-saga/effects';
import guid from 'uuid';
import { selectBrandedStyles } from 'containers/BrandDefinition/selectors';
import { createLayout } from 'factories/layoutFactory';
import { createRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { processDocument } from './processDocument';

interface ProcessLayoutArg {
  layout: Models.Layout;
  relations: Models.Relations;
  documents: Models.CombinedDocumentsMap;
  textsForDuplicatesChecking: Models.TextComponentsMap;
  imagesForDuplicatesChecking: Models.ImagesMap;
  brandStyles: Models.BrandStylesMap;
  sectionStyles: Immutable.List<string>;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
  shouldLookForDuplicates?: boolean;
  shouldProcessBrandStyles?: boolean;

  relationsToMerge?: Models.Relations;
  documentsToMerge?: Models.CombinedDocuments;
  textDocumentsCache?: Models.TextCache;
  referenceCitationsCache?: Models.TextCache;
  newRelationIdByOldId?: Record<string, string>;
  newDocumentIdByOldDocumentId?: Record<string, string>;
  isReusableLayoutFlag?: boolean;
}

export function* processLayout(arg: ProcessLayoutArg): Generator<unknown, ProcessLayoutArg> {
  const {
    layout,
    relations,
    documents,
    textsForDuplicatesChecking,
    imagesForDuplicatesChecking,
    brandStyles,
    sectionStyles,
    colors,
    fonts,
    shouldLookForDuplicates,
    shouldProcessBrandStyles,
    relationsToMerge,
    documentsToMerge,
    textDocumentsCache,
    referenceCitationsCache,
    newRelationIdByOldId,
    newDocumentIdByOldDocumentId,
    isReusableLayoutFlag,
  } = _.defaults(arg, {
    relationsToMerge: {},
    documentsToMerge: {},
    textDocumentsCache: {},
    referenceCitationsCache: {},
    newRelationIdByOldId: {},
    newDocumentIdByOldDocumentId: {},
    shouldLookForDuplicates: true,
    shouldProcessBrandStyles: true,
  } as ProcessLayoutArg);
  const layoutRelations = getFlattenedRelations(layout, relations);
  const layoutRelationIds = _.keys(layoutRelations);
  const layoutRelationsToMerge = {} as Models.Relations;
  const { relationStyles, layoutStyles } = (
    yield select(selectBrandedStyles, layout.type)
  ) as ReturnTypeSaga<typeof selectBrandedStyles>;

  for (let i = 0; i < layoutRelationIds.length; i++) {
    const layoutRelation = layoutRelations[layoutRelationIds[i]];

    if (!isRegularRelation(layoutRelation)) {
      const newLayoutRelation = createRelation({
        ...layoutRelation,
        id: guid(),
      });
      layoutRelationsToMerge[newLayoutRelation.id] = newLayoutRelation;
      newRelationIdByOldId[layoutRelation.id] = newLayoutRelation.id;

      continue;
    }

    // process documents from Story Card
    // the document id which the relation refers to
    const { documentId: relationDocumentId } = layoutRelation;
    const documentId: ReturnTypeSaga<typeof processDocument> = yield call(
      processDocument,
      relationDocumentId,
      isReusableLayoutFlag,
      documents,
      textsForDuplicatesChecking,
      imagesForDuplicatesChecking,
      brandStyles,
      sectionStyles,
      colors,
      fonts,
      shouldLookForDuplicates,
      shouldProcessBrandStyles,
      textDocumentsCache,
      referenceCitationsCache,
      newDocumentIdByOldDocumentId,
      documentsToMerge,
      layoutRelation,
    );

    // process image documents which are applied to the relation in backgroundImage style
    const { backgroundImage } = layoutRelation.styles as Models.CombinedAssetRelationStyles;
    if (backgroundImage && backgroundImage.id) {
      const documentId: ReturnTypeSaga<typeof processDocument> = yield call(
        processDocument,
        backgroundImage.id,
        isReusableLayoutFlag,
        documents,
        textsForDuplicatesChecking,
        imagesForDuplicatesChecking,
        brandStyles,
        sectionStyles,
        colors,
        fonts,
        shouldLookForDuplicates,
        shouldProcessBrandStyles,
        textDocumentsCache,
        referenceCitationsCache,
        newDocumentIdByOldDocumentId,
        documentsToMerge,
      );

      backgroundImage.id = documentId;
    }

    // create a new relation
    const newLayoutRelation = createRelation({
      ...layoutRelation,
      ...{ styles: _.merge({}, layoutRelation.styles, relationStyles) },
      id: guid(),
      documentId,
    });
    // collect the new relation
    layoutRelationsToMerge[newLayoutRelation.id] = newLayoutRelation;
    newRelationIdByOldId[layoutRelation.id] = newLayoutRelation.id;
  }

  _.forEach(layoutRelationsToMerge, (relation) => {
    if (isRegularRelation(relation)) {
      return;
    }

    relation.relationIds = relation.relationIds.map(oldId => newRelationIdByOldId[oldId]);
  });
  _.assign(relationsToMerge, layoutRelationsToMerge);

  // process image documents which are applied to the layout in backgroundImage style
  const { backgroundImage } = layout.styles;
  if (backgroundImage && backgroundImage.id) {
    const documentId: ReturnTypeSaga<typeof processDocument> = yield call(
      processDocument,
      backgroundImage.id,
      isReusableLayoutFlag,
      documents,
      textsForDuplicatesChecking,
      imagesForDuplicatesChecking,
      brandStyles,
      sectionStyles,
      colors,
      fonts,
      shouldLookForDuplicates,
      shouldProcessBrandStyles,
      textDocumentsCache,
      referenceCitationsCache,
      newDocumentIdByOldDocumentId,
      documentsToMerge,
    );

    backgroundImage.id = documentId;
  }

  arg.layout = createLayout({
    ...layout,
    ...{ styles: _.merge({}, layout.styles, layoutStyles) },
    id: guid(),
    relationId: newRelationIdByOldId[layout.relationId],
  });

  return arg;
}
