import React from 'react';

import BoxInput from 'components/Toolbar/Shared/BoxInput';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import ColorPicker from 'containers/Toolbar/Shared/ColorPicker';
import { BorderWidthMap, BrandColorMap } from 'models';
import { areBoxPropertiesEqual } from 'utils/immutable';
import { intlGet } from 'utils/intlGet';
import { BorderProps } from './models';
import styles from './styles.module.scss';

const Border: React.FunctionComponent<BorderProps> = (props) => {
  const { border, toggleBorder } = props;
  const borderWidths = border.get('width');

  const toggleColor = React.useCallback(
    (color: BrandColorMap) => toggleBorder(border.set('color', color)),
    [border],
  );
  const toggleWidth = React.useCallback(
    (width: BorderWidthMap) => toggleBorder(border.set('width', width)),
    [border],
  );

  return (
    <Dropdown
      className={styles.Border}
      stopClickPropagation={true}
    >
      <div className={styles.title}>{intlGet('EditorToolbar.Border', 'Title')}</div>
      <BoxInput
        className={styles.boxInput}
        property={borderWidths}
        toggleProperty={toggleWidth}
        max={10}
        isShorthand={areBoxPropertiesEqual(borderWidths)}
      />
      <ColorPicker activeColor={border.get('color')} toggleColor={toggleColor} />
    </Dropdown>
  );
};

export default Border;
