import { EditorState } from 'draft-js';
import adjustBlockDepthForContentState from 'draft-js/lib/adjustBlockDepthForContentState';
import React from 'react';

import { LIST_STYLES } from 'const';
import { DraftEditorStateChangeType } from 'const/Draft';

export function adjustListBlockDepth(event: React.KeyboardEvent, editorState: EditorState, maxDepth = 4): EditorState {
  const selection = editorState.getSelection();
  const key = selection.getAnchorKey();
  if (key !== selection.getFocusKey()) {
    return editorState;
  }

  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(key);
  const type = block.getType();

  if (!LIST_STYLES.some(item => item.style === type)) {
    return editorState;
  }

  event.preventDefault();

  const prevBlock = content.getBlockBefore(key);
  if (!prevBlock) {
    return editorState;
  }

  const prevType = prevBlock.getType();
  const depth = block.getDepth();
  if (!event.shiftKey && (prevType !== type || depth === maxDepth)) {
    return editorState;
  }

  const withAdjustment = adjustBlockDepthForContentState(content, selection, event.shiftKey ? -1 : 1, maxDepth);

  return EditorState.push(editorState, withAdjustment, DraftEditorStateChangeType.ADJUST_DEPTH);
}
