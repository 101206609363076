import React from 'react';

const ImageTextRight = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <rect fill="#F9AD3D" x="57" y="40.75" width="30" height="5" rx="1.5" />
    <rect fill="#F9AD3D" x="16" y="40.75" width="35" height="32" />
    <rect fill="#F9AD3D" x="57" y="53.75" width="30" height="5" rx="1.5" />
    <rect fill="#F9AD3D" x="57" y="66.75" width="30" height="5" rx="1.5" />
  </svg>
);

export default ImageTextRight;
