import { call, select } from 'redux-saga/effects';

import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import * as ProjectSelectors from 'containers/Project/selectors';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { surfaces } from 'containers/Surfaces/selectors';
import { Screens } from 'models';
import { uploadPackageAndPdf } from 'services/api';
import { getUsedFontFaces } from 'services/getUsedFontFaces';
import { getPackageName } from 'utils/getPackageName';
import { UploadProjectFlowArg } from '../models';
import { getScreensWithThumbnails } from './getScreensWithThumbnails';
import { getThumbnailsInfo } from './getThumbnailsInfo';
import { updateSurfacesThumbnailUrls } from './updateSurfacesThumbnailUrls';

export function* uploadPackageAndProjectPdf(arg: UploadProjectFlowArg): Generator<unknown, void> {
  const { rootDocumentId, projectAssets } = arg;
  const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
  const artboardsJson: ReturnTypeSaga<typeof getArtboardsJson> = yield call(getArtboardsJson);
  const projectType: ReturnTypeSaga<typeof ProjectSelectors.projectType> = yield select(ProjectSelectors.projectType);
  const helperFileName: ReturnTypeSaga<typeof ProjectSelectors.helperFileName> = yield select(ProjectSelectors.helperFileName);
  const screens = getScreensWithThumbnails((yield select(surfaces)).toJS() as Screens);
  const thumbnailsInfo: ReturnTypeSaga<typeof getThumbnailsInfo> = yield call(getThumbnailsInfo, screens);
  const archiveName: ReturnTypeSaga<typeof getPackageName> = yield call(getPackageName, rootDocument, projectType);
  const usedFontFaces: ReturnTypeSaga<typeof getUsedFontFaces> = yield call(getUsedFontFaces);

  const { data: { thumbnailUrlsByArtboardId, htmlUnicodeSize } }: ReturnTypeSaga<typeof uploadPackageAndPdf> = yield call(
    uploadPackageAndPdf,
    rootDocumentId,
    artboardsJson,
    usedFontFaces,
    projectType,
    helperFileName,
    thumbnailsInfo,
    archiveName,
  );
  arg.htmlUnicodeSize = htmlUnicodeSize;

  projectAssets.surfaces = updateSurfacesThumbnailUrls(projectAssets.surfaces, thumbnailUrlsByArtboardId);
}
