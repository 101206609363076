import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ZoomControl from 'components/ZoomControl';
import { ZoomControlActions, ZoomControlSelectors } from 'components/ZoomControl/models';
import { updateArtboardZoomState, updatePreviewZoomState } from 'containers/App/actions';
import { getScale, showPreview } from 'containers/App/selectors';

const mapStateToProps = createStructuredSelector({
  showPreview,
  scale: getScale,
});

const mapDispatchToProps = {
  updateArtboardZoomState,
  updatePreviewZoomState,
};

export default connect<ZoomControlSelectors, ZoomControlActions>(mapStateToProps, mapDispatchToProps)(ZoomControl);
