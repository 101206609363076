import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { updateSSIHotspotPosition } from 'containers/App/actions';
import { ssiHotspotPosition } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { activeScreenSectionsByName } from 'containers/Layouts/selectors';
import { IAction } from 'models';
import { handleSagaError } from 'services/handleError';
import * as Models from '../models';

export function* moveSSI(action: Models.Action.IMoveSSI) {
  try {
    const actions: IAction[] = [];
    const { section: sectionId } = action.payload;
    const ssiPosition: ReturnTypeSaga<typeof ssiHotspotPosition> = yield select(ssiHotspotPosition);
    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    const sectionsByName: ReturnTypeSaga<typeof activeScreenSectionsByName> = yield select(activeScreenSectionsByName);
    const section = sectionsByName.find(section => section.get('id') === sectionId);

    const updatedArtboard = artboard.update('ssi', ssi => ssi.set('position', ssiPosition).set('section', section.get('name')));
    actions.push(
      updateArtboard(updatedArtboard),
      updateSSIHotspotPosition(null),
    );

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'Artboards.moveSSI', 'MoveSSI');
  }
}
