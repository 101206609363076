import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import { ClearFormattingProps } from './models';

const ClearFormatting: React.FunctionComponent<ClearFormattingProps> = (props) => {
  const { clearFormatting, controlState = ControlState } = props;

  return (
    <Control
      controlState={controlState}
      onToggle={clearFormatting}
      state={controlState.DEFAULT}
      title={Title.CLEAR_FORMATTING}
      type={IconType.CLEAR_ALL_FORMATTING}
    />
  );
};

export default ClearFormatting;
