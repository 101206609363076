import React from 'react';

const TextThreeColumn = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-370.000000, -358.000000)">
        <g transform="translate(370.000000, 358.000000)">
          <rect strokeWidth="1.5" fill="#FFFFFF" x="0.75" y="0.75" width="107.5" height="107.5" />
          <rect fill="#F9AD3D" x="17" y="40.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="44" y="40.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="71" y="40.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="17" y="53.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="44" y="53.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="71" y="53.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="17" y="66.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="44" y="66.75" width="23" height="5" rx="1" />
          <rect fill="#F9AD3D" x="71" y="66.75" width="23" height="5" rx="1" />
        </g>
      </g>
    </g>
  </svg>
);

export default TextThreeColumn;
