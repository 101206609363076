import React from 'react';
/* eslint-disable max-len */

const Text = (): JSX.Element => (
  <svg viewBox="8.86666667 10.1333333 20.26666663 17.7333334">
    <path d="M8.86666667,22.8 L29.1333333,22.8 L29.1333333,20.2666667 L8.86666667,20.2666667 L8.86666667,22.8 L8.86666667,22.8 Z M8.86666667,27.8666667 L29.1333333,27.8666667 L29.1333333,25.3333333 L8.86666667,25.3333333 L8.86666667,27.8666667 L8.86666667,27.8666667 Z M8.86666667,17.7333333 L29.1333333,17.7333333 L29.1333333,15.2 L8.86666667,15.2 L8.86666667,17.7333333 L8.86666667,17.7333333 Z M8.86666667,10.1333333 L8.86666667,12.6666667 L29.1333333,12.6666667 L29.1333333,10.1333333 L8.86666667,10.1333333 L8.86666667,10.1333333 Z" />
  </svg>
);

export default Text;
