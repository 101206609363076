import _ from 'lodash';
import React from 'react';

import Icon from 'components/Icon';
import { ControlMap } from 'components/Toolbar/Controls/TextHorizontalAlignment/constants';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { AssetAlignmentType } from 'const';
import { intlGet } from 'utils/intlGet';
import { TextHorizontalAlignmentProps } from './models';
import styles from './styles.module.scss';

const TextHorizontalAlignment: React.FunctionComponent<TextHorizontalAlignmentProps> = (props) => {
  const { alignment, toggleAlignment } = props;

  return (
    <Dropdown className={styles.TextHorizontalAlignment}>
      {_.map(ControlMap, ((options, type: AssetAlignmentType) => {
        const { icon, name, title } = options;

        return (
          <div className={styles.item} key={title} onClick={toggleAlignment.bind(null, type)}>
            <Icon type={icon} size="badge" color={type === alignment ? 'primary' : 'secondary'} />
            <span className={styles.hint}>{intlGet('EditorToolbar.TextAlignment.Horizontal', name)}</span>
          </div>
        );
      }))}
    </Dropdown>
  );
};

export default TextHorizontalAlignment;
