import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { CheckboxProps } from './models';
import styles from './styles.module.scss';

const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
  const { className, onChange, checked, label, name, comments, disabled = false } = props;

  return (
    <Form.Check
      className={classNames(styles.Checkbox, className, {
        [styles.disabled]: disabled,
      })}
      type="checkbox"
    >
      <Form.Check.Label className={styles.labelContainer}>
        <Form.Check.Input onChange={onChange} checked={checked} name={name} disabled={disabled}/>
        <Icon type={IconType.TICK} color="secondary" size="xs" />
        {label && <span className={styles.label}>{label}</span>}
      </Form.Check.Label>
      {comments && <span className={styles.comments}>{comments}</span>}
    </Form.Check>
  );
};

export default Checkbox;
