import React from 'react';

const ToolbarCenter = (): JSX.Element => (
  <svg viewBox="0 0 512 512">
    <g fill="#FFFFFF" fillRule="evenodd">
      <path d="M0,32h512v64H0V32z M96,128h320v64H96V128z M96,320h320v64H96V320z M0,224h512v64H0V224z M0,416h512v64H0V416z" />
    </g>
  </svg>
);

export default ToolbarCenter;
