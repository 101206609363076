import React from 'react';

import { ZoomWrapperProps } from './models';
import styles from './styles.module.scss';

const ZoomWrapper: React.FunctionComponent<ZoomWrapperProps> = (props) => {
  const { children, wrapContent = true, scale } = props;

  return (
    <>
      {
        wrapContent
          ? <div className={styles.ZoomWrapper} style={{ transform: `scale(${scale})` }}>
            {children}
          </div>
          : children
      }
    </>
  );
};

export default ZoomWrapper;
