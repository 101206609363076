import Draft from 'draft-js';
import { compositeDecorator } from 'modules/draftjs/decorators';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { removeZeroWidthSpace, replaceNonBreakingSpaces } from 'utils/replaceChars';
import { applyEditorStateHacks } from '../helpers/applyEditorStateHacks';

type Handler = (text: string, html: string) => Draft.DraftHandleValue;

export function useHandlePastedText(
  editMode: boolean,
  editorState: Draft.EditorState,
  setEditorState: (state: Draft.EditorState) => void,
  addOperation: (operation: Models.DraftEditorOperation) => void,
): Handler {

  const editModeRef = useRef(editMode);
  editModeRef.current = editMode;

  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  return useCallback((text: string, html: string): Draft.DraftHandleValue => {
    const state = editorStateRef.current;
    const textToPaste = removeZeroWidthSpace(replaceNonBreakingSpaces(html || text));
    const content = editorUtils.insertText(
      state.getCurrentContent(),
      state.getSelection(),
      textToPaste,
      {
        inlineStyles: state.getCurrentInlineStyle(),
        blockAlignment: editorUtils.getBlockType(state),
        blockLineHeight: editorUtils.getBlockLineHeight(state),
      },
    );
    let newEditorState = Draft.EditorState.push(state, content, Constants.EditorChangeType.INSERT_FRAGMENT);
    newEditorState = applyEditorStateHacks(newEditorState, state, editModeRef.current);
    newEditorState = Draft.EditorState.set(newEditorState, { decorator: compositeDecorator });

    const newOperation = {
      type: Constants.EditorChangeType.INSERT_FRAGMENT,
      text: textToPaste,
      selectionBefore: state.getSelection(),
      blockList: content.getBlocksAsArray().map(block => block.get('key')),
    };

    addOperation(newOperation);
    setEditorState(newEditorState);

    return Constants.HandleValue.HANDLED;
  }, [addOperation, setEditorState]);
}
