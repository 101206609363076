import Draft from 'draft-js';
import Immutable from 'immutable';
import _ from 'lodash';

import { DraftEntity } from 'const';
import { TextComponentMap } from 'models';
import * as editorUtils from 'utils/editor';

export function updateReferenceCitationsOnComponent(textComponent: TextComponentMap): TextComponentMap {
  const textComponentReferences = textComponent.get('referenceCitations').toJS() as string[];
  const rawContent: Draft.RawDraftContentState = JSON.parse(textComponent.get('rawContent'));
  const referencesIds = [] as string[];

  editorUtils.iterateEntities(rawContent, (entity) => {
    if (entity.type === DraftEntity.REFERENCE) {
      referencesIds.push(entity.data.id);
    }
  });

  // textComponentReferences is an array of previous reference citation ids
  if (_.isEqual(textComponentReferences, referencesIds)) {
    return textComponent;
  }

  return textComponent.set('referenceCitations', Immutable.List<string>(referencesIds));
}
