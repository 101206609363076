import React from 'react';
/* eslint-disable max-len */

const VerticalAlignmentBottom = (): JSX.Element => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <defs>
      <path d="M24,22 L24,24 L6,24 L6,22 L24,22 Z M18,7 L18,19 C18,19.5522847 17.5522847,20 17,20 L13,20 C12.4477153,20 12,19.5522847 12,19 L12,7 C12,6.44771525 12.4477153,6 13,6 L17,6 C17.5522847,6 18,6.44771525 18,7 Z" id="path-vertical-alignment-bottom" />
    </defs>
    <g id="ImagesVerticalAlignmentBottom" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Images_Vertical_Alignment_Bottom" transform="translate(-306.000000, -201.000000)">
        <g id="WidgetVerticalAlignmentBottom" transform="translate(0.000000, 180.000000)">
          <g id="Icons/VerticalAlignmentBottom" transform="translate(300.000000, 15.000000)">
            <g id="ToolbarIcons/VerticalAlignmentBottom">
              <rect id="VerticalAlignmentBottomRectangle" stroke="#FFFFFF" opacity="0" x="0.5" y="0.5" width="29" height="29" />
              <mask id="mask-vertical-alignment-bottom" fill="white">
                <use xlinkHref="#path-vertical-alignment-bottom" />
              </mask>
              <use id="VerticalAlignmentBottomMask" fill="#FFFFFF" xlinkHref="#path-vertical-alignment-bottom" />
              <g id="Colors/OrangeVerticalAlignmentBottom" mask="url(#mask-vertical-alignment-bottom)" fill="#FFFFFF">
                <g transform="translate(4.000000, 4.000000)" id="OrangeVerticalAlignmentBottom">
                  <rect x="0" y="0" width="22" height="22" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default VerticalAlignmentBottom;
