import { ProjectType, ProjectsConfig } from 'const';
import * as Models from 'models';
import { BackgroundGradientMap } from 'models/styles/style/BackgroundGradient';
import { brandColorGradientFromSource } from 'utils/converters/colorGradient';
import { getCSSBackgroundBrandGradientLayer } from 'utils/styles/getBackground';

export function getBackgroundGradient(
  projectType: ProjectType,
  colors: Models.BrandColorsList,
  gradient: BackgroundGradientMap | undefined,
  opacity?: number,
): string | null {
  const { allowBackgroundOpacity } = ProjectsConfig[projectType];
  const brandGradient = brandColorGradientFromSource(colors, gradient);

  return getCSSBackgroundBrandGradientLayer(
    brandGradient,
    allowBackgroundOpacity ? opacity : undefined,
  ) || null;
}
