import React from 'react';

export const useBeforeEditModeValue = <T>(value: T, editMode: boolean): T => {
  const valueRef = React.useRef<T>();

  React.useEffect(
    () => {
      if (!editMode) {
        valueRef.current = value;
      }
    },
  );

  return valueRef.current;
};
