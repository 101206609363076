import Immutable from 'immutable';

import { createBrandDefinition } from 'factories/brandDefinitionFactory';
import { Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

const brandDefinitionInitialState = Immutable.fromJS(createBrandDefinition()) as Models.State.IState;

const brandDefinition: Reducer<Models.State.IState> =
  (state = brandDefinitionInitialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_BRAND_DEFINITION: return setBrandDefinition(state, action);
      default: return state;
    }
  };

const setBrandDefinition: Reducer<Models.State.IState, Models.Action.ISetBrandDefinition> = (state, action) => {
  const brandDefinition = action.payload.brandDefinition || brandDefinitionInitialState;

  return Immutable.fromJS(brandDefinition);
};

export default brandDefinition;
