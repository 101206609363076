import _ from 'lodash';

import { isImmutable } from './isImmutable';

export function removeAll<T extends { [P in string]: T[P] }, P extends keyof T>(
  object: T | DeepIMap<T>,
  properties: P[],
): T | DeepIMap<T> {
  if (!_.isObjectLike(object)) {
    return object;
  }

  if (!isImmutable(object)) {
    _.forEach(properties, (property) => { delete object[property]; });

    return object;
  }

  return object.deleteAll(properties);
}
