import { AssetAlignmentDirection, TextAlignmentStyleMap } from 'const';
import { AssetAlignmentMap } from 'models';

/**
 * Now using only for text alignment inside CTA Button
 */
export const getTextAlignment = (alignment: AssetAlignmentMap): React.CSSProperties => {
  if (!alignment) {
    return {};
  }

  return {
    ...TextAlignmentStyleMap[alignment.get(AssetAlignmentDirection.HORIZONTAL)],
  };
};
