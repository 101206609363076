import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArtboardLayoutActions from 'components/ArtboardLayoutActions';
import {
  ArtboardLayoutActionsActions,
  ArtboardLayoutActionsOwnProps,
  ArtboardLayoutActionsSelectors,
} from 'components/ArtboardLayoutActions/models';
import { addActiveLayoutId, removeActiveLayoutId, setMoveableLayoutId } from 'containers/App/actions';
import { activeLayoutIds, areActionsDisabled, isResizingLayout } from 'containers/App/selectors';
import { documents } from 'containers/Documents/selectors';
import { copyLayout, deleteLayoutRequest, setLayoutAndInstances } from 'containers/Layouts/actions';
import { showModal } from 'containers/ModalManager/actions';
import { unlinkReusableLayout } from 'containers/ModalWindows/SaveReusableLayout/actions';
import { projectType } from 'containers/Project/selectors';

const mapStateToProps = createStructuredSelector({
  activeLayoutIds,
  areActionsDisabled,
  documents,
  isResizingLayout,
  projectType,
});

const mapDispatchToProps = {
  addActiveLayoutId,
  copyLayout,
  removeActiveLayoutId,
  deleteLayout: deleteLayoutRequest,
  unlinkReusableLayout,
  // TODO: get rid of it
  setMoveableLayout: setMoveableLayoutId,
  showModal,
  setLayout: setLayoutAndInstances,
};

export default connect<ArtboardLayoutActionsSelectors, ArtboardLayoutActionsActions, ArtboardLayoutActionsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ArtboardLayoutActions);
