import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    SET_PROJECT_DETAILS: null,
    SET_ROOT_DOCUMENT: null,
    UPDATE_ROOT_DOCUMENT: null,
  },
  'RootDocument',
);
