import _ from 'lodash';
import guid from 'uuid';

import { DraftEntity } from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { isImage, isReferenceCitation, isTextComponent } from 'utils/entityType';
import { findImageDuplicateId, findReferenceCitationDuplicateId } from 'utils/findDocumentDuplicateId';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

export function checkForDuplicates(arg: Models.DropReusableLayoutArg): Models.DropReusableLayoutArg {
  const {
    projectDocuments,
    layoutDocument,
  } = arg;
  const {
    documents,
    entities,
    entities: {
      relations,
      styles,
    },
  } = layoutDocument;

  const projectImages = _(projectDocuments)
    .pickBy<Models.Image>(isImage)
    .value();
  const projectReferenceCitations = _(projectDocuments)
    .pickBy<Models.ReferenceCitation>(isReferenceCitation)
    .value();

  const replacedTextComponentIds: string[] = [];
  const newDocIdByOldId: Record<string, string> = {};
  const getNewDocId = (id: string): string => newDocIdByOldId[id] || id;

  // skip document if duplicated one was found
  const documentsToSet = _(documents)
    .pickBy((document, id) => {
      // we specially check images and references before texts
      if (!isReferenceCitation(document) && !isImage(document)) {
        return true;
      }

      const duplicateId = isImage(document)
        ? findImageDuplicateId(document, projectImages)
        : findReferenceCitationDuplicateId(document, projectReferenceCitations);

      newDocIdByOldId[id] = duplicateId;

      return !duplicateId;
    })
    .pickBy((document) => {
      if (!isTextComponent(document)) {
        return true;
      }

      // replace all reference citation ids by duplicated ones
      const rawContent: Draft.RawDraftContentState = JSON.parse(document.rawContent);
      document.referenceCitations = document.referenceCitations.map(id => getNewDocId(id));
      editorUtils.iterateEntities(rawContent, ({ type, data }) => { type === DraftEntity.REFERENCE && (data.id = getNewDocId(data.id)); });
      document.rawContent = JSON.stringify(rawContent);

      return true;
    })
    .toPairs()
    .map<[string, Models.CombinedDocument]>(([id, document]) => {
    // potentially there are RLs on Production env which have text components and relations with the same identifiers (within each RL)
    // to be sure they won't override each other, we have to change the identifiers of those entities if such RL has been already added to a project
    if (!isTextComponent(document) || !projectDocuments[id]) {
      return [id, document];
    }

    const newId = guid();
    newDocIdByOldId[id] = newId;
    replacedTextComponentIds.push(id);

    document.id = newId;

    return [newId, document];
  })
    .fromPairs()
    .value();

  const newRelIdByOldId: Record<string, string> = {};
  const getNewRelId = (id: string): string => newRelIdByOldId[id] || id;

  const relationsToSet = _(relations)
    .toPairs()
    .map<[string, Models.Relation]>(([id, relation]) => {
    if (!isRegularRelation(relation) || !replacedTextComponentIds.includes(relation.documentId)) {
      return [id, relation];
    }

    const newId = guid();
    newRelIdByOldId[id] = newId;
    relation.id = newId;

    return [newId, relation];
  })
    .fromPairs()
    .mapValues((relation) => {
      if (!isRegularRelation(relation)) {
        relation.relationIds = relation.relationIds.map(getNewRelId);

        return relation;
      }

      const { documentId, styles } = relation;
      relation.documentId = getNewDocId(documentId);

      // replace background image ids
      _.each(styles, (styles) => {
        const backgroundImageId = _.get(styles, ['backgroundImage', 'id']);
        backgroundImageId && ((styles as Models.CommonStyles).backgroundImage.id = getNewDocId(backgroundImageId));
      });

      return relation;
    })
    .value();

  // replace background image id in layout styles
  styles.backgroundImage.id = getNewDocId(styles.backgroundImage.id);
  entities.relationId = getNewRelId(entities.relationId);

  // replace only text documents
  layoutDocument.documents = {
    ..._.pickBy(documentsToSet, isTextComponent),
    ..._.omitBy(layoutDocument.documents, isTextComponent),
  };
  arg.documentsToSet = documentsToSet;
  layoutDocument.entities.relations = relationsToSet;

  return arg;
}
