import Immutable from 'immutable';

import { Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

export const rootDocumentInitialState = Immutable.Map() as Models.State.IState;

const rootDocumentReducer: Reducer<Models.State.IState> =
  (state = rootDocumentInitialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_ROOT_DOCUMENT:
        return setRootDocument(state, action);
      case ActionTypes.SET_PROJECT_DETAILS:
      case ActionTypes.UPDATE_ROOT_DOCUMENT:
        return updateRootDocument(state, action);
      default:
        return state;
    }
  };

const setRootDocument: Reducer<Models.State.IState, Models.Action.ISetRootDocument> = (state, action) => (
  Immutable.fromJS(action.payload.rootDocument)
);

const updateRootDocument: Reducer<Models.State.IState, Models.Action.ISetRootDocument> = (state, action) => (
  state.merge(Immutable.fromJS(action.payload.rootDocument))
);

export default rootDocumentReducer;
