import * as Models from 'models';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

export function getParent(
  relationId: string,
  relations: Models.RelationsMap | Models.LayeredRelationsMap,
): {
    parent: Models.RowRelationMap | Models.ColumnRelationMap;
    position: number;
  } {
  let position: number;

  const parent = relations.find((relation) => {
    if (isRegularRelation(relation)) {
      return false;
    }

    position = relation.get('relationIds').indexOf(relationId);

    return position !== -1;
  }) as Models.RowRelationMap | Models.ColumnRelationMap;

  return {
    parent,
    position,
  };
}
