import React from 'react';

const CloseIcon = (): JSX.Element => (
  <svg viewBox="0 0 100 100">
    <path d="M 22 28 L 72 78 L 78 72 L 28 22 Z" fill="#ffffff"/>
    <path d="M 22 72 L 72 22 L 78 28 L 28 78 Z" fill="#ffffff"/>
  </svg>
);

export default CloseIcon;
