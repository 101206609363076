import _ from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';

import RadioButtonList from 'components/ModalWindows/Shared/RadioButtonList';
import { ExportType, ProjectsConfig } from 'const';
import { intlGet } from 'utils/intlGet';
import { IExportProps, IExportState, LabelTexts } from './models';
import styles from './styles.module.scss';

const TextByExportType: Record<ExportType, LabelTexts> = {
  [ExportType.HTML_PACKAGE]: {
    title: () => intlGet('ExportWindow.Option', 'HtmlPackage'),
    description: () => intlGet('ExportWindow.Description', 'SaveHTMLLocally'),
  },
  [ExportType.IPAD_PACKAGE]: {
    title: () => intlGet('ExportWindow.Option', 'IPadPackage'),
    description: () => intlGet('ExportWindow.Description', 'SaveIPadPackageLocally'),
  },
  [ExportType.PDF]: {
    title: () => intlGet('ExportWindow.Option', 'ProjectPDF'),
    description: () => intlGet('ExportWindow.Description', 'SavePDFLocally'),
  },
  [ExportType.TRANSLATION_PACKAGE]: {
    title: () => intlGet('ExportWindow.Option', 'TranslationPackage'),
    description: () => intlGet('ExportWindow.Description', 'SaveXliffFiles'),
  },
};

export default class ExportWindow extends React.PureComponent<IExportProps, IExportState> {
  readonly state: IExportState = {
    selectedExportType: ProjectsConfig[this.props.projectType].exportType,
  };

  private onExportTypeChange = (selectedExportType: ExportType): void => {
    this.setState({
      selectedExportType,
    });
  };

  private exportPackage: React.MouseEventHandler = () => {
    const { exportPackage, hideModal, modalWindowId } = this.props;
    const { selectedExportType } = this.state;

    exportPackage(selectedExportType);
    hideModal(modalWindowId);
  };

  private onCancelClick: React.MouseEventHandler = () => {
    const { hideModal, modalWindowId } = this.props;

    hideModal(modalWindowId);
  };

  private getLabel = (exportType: ExportType): JSX.Element => {
    const { title, description } = TextByExportType[exportType];

    return (
      <div className={styles.label}>
        {title()}
        {!!description && <span className={styles.labelDescription}>{`(${description()})`}</span>}
      </div>
    );
  };

  render() {
    const { selectedExportType } = this.state;
    const { projectType, modalWindowId } = this.props;

    return (
      <div className={styles.Export}>
        <header>{intlGet('ExportWindow', 'Export')}</header>
        <section>
          <RadioButtonList<ExportType>
            id={modalWindowId}
            options={[ProjectsConfig[projectType].exportType, ExportType.PDF/*, ExportType.TRANSLATION_PACKAGE, DCC-3120 */]}
            listLabel={intlGet('ExportWindow', 'SelectOption')}
            onOptionChange={this.onExportTypeChange}
            isDisabled={_.stubFalse}
            activeOption={selectedExportType}
            getOptionLabel={this.getLabel}
          />
        </section>
        <footer>
          <Button onClick={this.onCancelClick} variant="secondary">{intlGet('ExportWindow.Button', 'Cancel')}</Button>
          <Button onClick={this.exportPackage} variant="primary">{intlGet('ExportWindow.Button', 'Export')}</Button>
        </footer>
      </div>
    );
  }
}
