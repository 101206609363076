import _ from 'lodash';

import * as Models from 'models';
import { isColumnRelation } from './isColumnRelation';
import { isRowRelation } from './isRowRelation';

export function isRegularRelation(entity: Models.Relation): entity is Models.RegularRelation;
export function isRegularRelation(entity: Models.LayeredRelation): entity is Models.LayeredRegularRelation;
export function isRegularRelation(entity: Models.RelationMap): entity is Models.RegularRelationMap;
export function isRegularRelation(entity: Models.LayeredRelationMap): entity is Models.LayeredRegularRelationMap;
export function isRegularRelation(
  entity: Models.RowRelation | Models.ColumnRelation | Models.RegularRelation | Models.LayeredRegularRelation,
): entity is Models.RegularRelation | Models.LayeredRegularRelation;
export function isRegularRelation(
  entity: Models.RowRelationMap | Models.ColumnRelationMap | Models.RegularRelationMap | Models.LayeredRegularRelationMap,
): entity is Models.RegularRelationMap | Models.LayeredRegularRelationMap;
export function isRegularRelation(entity): boolean {
  return !!entity && !_.overSome<typeof entity>([isRowRelation, isColumnRelation])(entity);
}
