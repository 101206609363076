import Immutable from 'immutable';
import _ from 'lodash';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { createSelector } from 'reselect';
import { cellsHeight } from 'containers/ArtboardPreview/selectors';
import { activeSSI, artboards } from 'containers/Artboards/selectors';
import {
  brandDefinition,
  brandDefinitionChecksum,
  brandStylesChecksum,
  projectBrandStyles,
  projectFlatColors,
  projectFontFaces,
} from 'containers/BrandDefinition/selectors';
import {
  documents,
  documentsByEntityType,
  groupLayoutDocumentsForAssetsPanel,
  nonUploadingDocuments,
  referenceCitationsByReferenceElements,
  referenceCitationsOrderByDocuments,
  relationsOnScreens,
  reusableLayouts as reusableLayoutDocuments,
} from 'containers/Documents/selectors';
import { documentTypes } from 'containers/DocumentTypes/selectors';
import { layeredLayouts, layouts } from 'containers/Layouts/selectors';
import {
  activeLayer,
  artboardBackgroundFromMasterScreen,
  helperFileName,
  placeholderMinHeight,
  project,
  projectType,
} from 'containers/Project/selectors';
import { layeredRelations, relations } from 'containers/Relations/selectors';
import { rootDocument } from 'containers/RootDocument/selectors';
import {
  previewOptionsByScreenId,
  screenDefinitions,
  sectionsHeightByScreen,
  sectionsWidthByScreenAndName,
} from 'containers/ScreenDefinitions/selectors';
import { sections } from 'containers/Sections/selectors';
import { orderedSurfaces, surfaces } from 'containers/Surfaces/selectors';
import * as Models from 'models';
import { SectionStylesByRelationIdMap, SectionStylesMap } from 'models/MasterScreen';
import { mergeBrandDefinitions, mergeUIFontFaces } from 'utils/brandStyles/mergeBrandDefinitions';
import { deleteDocumentInternalInfo } from 'utils/deleteInternalInfo';
import { isTextComponent } from 'utils/entityType';
import { getTextComponentsIds } from 'utils/getTextComponentsIds';
import { toImmutable } from 'utils/immutable';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { pickWithOrder } from 'utils/pickWithOrder';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { isMockReusableLayout } from 'utils/reusableLayouts/isMockReusableLayout';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';

let cachedDocuments: unknown = undefined;

const reusableLayoutBrandDefinitionsByDocumentId = createSelector(
  reusableLayoutDocuments,
  (reusableLayoutDocumentsValue) => {
    const result = reusableLayoutDocumentsValue
      .map((layout: Models.ReusableLayoutMap) => layout.getIn(['entities', 'brandDefinition']))
      .filter(Boolean);

    const actualDocuments = result.toJS();
    if (!_.isEqual(cachedDocuments, actualDocuments)) {
      cachedDocuments = actualDocuments;
    }

    return cachedDocuments as Record<string, Models.OnlineBrandDefinition>;
  },
);

// PERF: on project load each execution cost 350ms, to remove immutable, executed for each image on project
const extendedBrandDefinitionsByDocumentId = createSelector(
  [reusableLayoutBrandDefinitionsByDocumentId, brandDefinition],
  (reusableLayoutBrandDefinitionsByDocumentIdValue, brandDefinitionValue) => {
    const extendedBrandDefinitionsByDocumentIdValue = _.mapValues(
      reusableLayoutBrandDefinitionsByDocumentIdValue,
      (reusableLayoutBrandDefinition) => {
        // NOTE: we specially create new projectBrandDefinition object on each iteration because it will be mutated during the merge process
        const projectBrandDefinition = brandDefinitionValue.toJS() as Models.OnlineBrandDefinition;

        return mergeBrandDefinitions(projectBrandDefinition, reusableLayoutBrandDefinition);
      },
    );

    return toImmutable(extendedBrandDefinitionsByDocumentIdValue);
  },
);

const extendedBrandDefinitionsByLayoutId = createSelector(
  [layouts, extendedBrandDefinitionsByDocumentId, brandDefinition, reusableLayoutDocuments],
  (layoutsVal, extendedBrandDefinitionsByDocumentIdVal, brandDefinitionVal, reusableLayoutDocumentsVal) => {
    return layoutsVal.filter<Models.LayoutMap>(isPlainLayout).map(
      layout => isReusableLayout(layout, reusableLayoutDocumentsVal)
        ? extendedBrandDefinitionsByDocumentIdVal.get(layout.get('documentId'))
        : brandDefinitionVal,
    );
  },
);

const extendedBrandDefinitionsByRelationId = createSelector(
  [layouts, extendedBrandDefinitionsByLayoutId, layeredRelations],
  (layoutsVal, brandDefinitionsByLayoutId, relationsVal) => {
    return layoutsVal.filter<Models.LayoutMap>(isPlainLayout).reduce(
      (brandDefinitionsByRelationId, layout) => {
        const layoutId = layout.get('id');
        const layoutBrandDefinition = brandDefinitionsByLayoutId.get(layoutId);
        let result = brandDefinitionsByRelationId;

        getFlattenedRelations(layout, relationsVal).forEach((rel, id) => { result = result.set(id, layoutBrandDefinition); });

        return result;
      },
      Immutable.Map() as DeepIMap<Record<string, Models.OnlineBrandDefinition>>,
    );
  },
);

// COLORS
export const flatColorsByLayoutId = createSelector(
  extendedBrandDefinitionsByLayoutId,
  extendedBrandDefinitionsByLayoutId => extendedBrandDefinitionsByLayoutId.map(extendedBrandDefinition => (
    extendedBrandDefinition.get('colors')
  )),
);

export const flatColorsByRelationId = createSelector(
  extendedBrandDefinitionsByRelationId,
  extendedBrandDefinitionsByRelationId => extendedBrandDefinitionsByRelationId.map(extendedBrandDefinition => (
    extendedBrandDefinition.get('colors')
  )),
);

export const createFlatColorsByRelationId = (relationId: string) => createSelector(
  flatColorsByRelationId, flatColorsByRelationId => flatColorsByRelationId.get(relationId),
);
// COLORS

// FONTS

export const flatFontsByRelationId = createSelector(
  extendedBrandDefinitionsByRelationId,
  extendedBrandDefinitionsByRelationId => extendedBrandDefinitionsByRelationId.map(extendedBrandDefinition => (
    extendedBrandDefinition.get('fonts')
  )),
);

export const createFlatFontsByRelationId = (relationId: string) => createSelector(
  flatFontsByRelationId, flatFontsByRelationId => flatFontsByRelationId.get(relationId),
);
// FONTS

// BRAND STYLES
export const brandStylesByRelationId = createSelector(
  extendedBrandDefinitionsByRelationId,
  extendedBrandDefinitionsByRelationId => extendedBrandDefinitionsByRelationId.map(extendedBrandDefinition => (
    extendedBrandDefinition.get('brandStyles')
  )),
);

export const createBrandStylesByRelationId = (relationId: string) => createSelector(
  brandStylesByRelationId, brandStylesByRelationId => brandStylesByRelationId.get(relationId),
);
// BRAND STYLES

export const UIFontFaces = createSelector(
  [projectFontFaces, extendedBrandDefinitionsByDocumentId],
  (projectFontFaces, extendedBrandDefinitionsByDocumentId) => {
    const fontFaces = extendedBrandDefinitionsByDocumentId
      .map(extendedBrandDefinition => extendedBrandDefinition.get('UIFontFaces'))
      .valueSeq() // Object.entries
      .toJS() as Models.UIFontFace[][];

    return toImmutable(mergeUIFontFaces(projectFontFaces.toJS() as Models.UIFontFace[], ...fontFaces));
  },
);

// NOTE: to retrieve the project assets DON'T use this selector directly
// use getProjectAssets service instead, it can clean up the data if need it.
export const projectAssets = createSelector(
  [
    nonUploadingDocuments,
    project,
    rootDocument,
    surfaces,
    artboards,
    layeredRelations,
    documentTypes,
    layeredLayouts,
    sections,
    screenDefinitions,
    brandDefinitionChecksum,
    brandStylesChecksum,
  ],
  (
    documents,
    project,
    rootDocument,
    surfaces,
    artboards,
    relations,
    documentTypes,
    layouts,
    sections,
    screenDefinitions,
    brandDefinitionChecksum,
    brandStylesChecksum,
  ): Models.ProjectAssetsToUploadMap => {
    const projectAssets: PlainObjectWithImmutableProps<Models.ProjectAssetsToUpload> = {
      rootDocument,
      documents,
      artboards,
      layouts,
      relations,
      sections,
      surfaces,
      project: Immutable.Map({
        activeLayer: project.get('activeLayer'),
        originalLanguage: project.get('originalLanguage'),
        originalCountry: project.get('originalCountry'),
        originalProduct: project.get('originalProduct'),
        projectType: project.get('projectType'),
        surfaceIds: project.get('surfaceIds'),
        version: project.get('version'),
        suppressTranslation: project.get('suppressTranslation'),
      }),
      documentTypes,
      screenDefinitions,
      brandDefinitionChecksum,
      brandStylesChecksum,
    };

    return Immutable.fromJS(projectAssets) as Models.ProjectAssetsToUploadMap;
  },
);

export const masterScreenData = createSelector(
  [screenDefinitions, brandDefinition, artboardBackgroundFromMasterScreen, placeholderMinHeight, projectType, helperFileName],
  (
    screenDefinitions,
    brandDefinition,
    artboardBackground,
    placeholderMinHeight,
    projectType,
    helperFileName,
  ): Models.MasterScreenDataMap => {
    const masterScreenData: PlainObjectWithImmutableProps<Models.MasterScreenData> = {
      brandDefinition,
      screenDefinitions,
      artboardBackground,
      placeholderMinHeight,
      projectType,
      helperFileName,
    };

    return Immutable.fromJS(masterScreenData);
  },
);

// NOTE: artboard context selector is split into parts
// because `createSelector` types do NOT allow to pass more than 12 args
const artboardsContextPart = createSelector(
  [
    activeLayer,
    relationsOnScreens,
    documentsByEntityType,
    selectAbbreviationsData,
    artboardBackgroundFromMasterScreen,
    referenceCitationsByReferenceElements,
    referenceCitationsOrderByDocuments,
    sectionsHeightByScreen,
    sectionsWidthByScreenAndName,
    rootDocument,
    projectType,
    sections,
  ],
  (
    stateActiveLayer,
    stateRelationsOnScreens,
    stateDocumentsByEntityType,
    stateAbbreviationsData,
    artboardBackgroundFromMasterScreen,
    citationsByReferenceElements,
    citationsOrderByDocuments,
    sectionsHeightByScreen,
    sectionsWidthByScreen,
    rootDocument,
    projectType,
    sections,
  ) => ({
    activeLayer: stateActiveLayer,
    relationsOnScreens: stateRelationsOnScreens,
    documentsByEntityType: stateDocumentsByEntityType,
    abbreviationsData: stateAbbreviationsData,
    artboardBackgroundFromMasterScreen,
    citationsByReferenceElements,
    citationsOrderByDocuments,
    sectionsHeightByScreen,
    sectionsWidthByScreen,
    brand: rootDocument.get('product').first() as string,
    projectType,
    sections,
  }),
);

const artboardsContextColors = createSelector(
  [
    projectFlatColors,
    flatColorsByLayoutId,
    flatColorsByRelationId,
  ],
  (
    availableForUserFlatColors,
    flatColorsByLayoutId,
    flatColorsByRelationId,
  ) => ({
    colors: availableForUserFlatColors,
    colorsByLayoutId: flatColorsByLayoutId,
    colorsByRelationId: flatColorsByRelationId,
  }),
);

const artboardsContextBrandStyles = createSelector(
  [
    projectBrandStyles,
    brandStylesByRelationId,
  ],
  (
    availableForUserBrandStyles,
    brandStylesByRelationId,
  ) => ({
    brandStyles: availableForUserBrandStyles,
    brandStylesByRelationId,
  }),
);

export const artboardsContext = createSelector(
  [
    orderedSurfaces,
    artboards,
    artboardsContextBrandStyles,
    cellsHeight,
    previewOptionsByScreenId,
    screenDefinitions,
    layouts,
    relations,
    documents,
    artboardsContextColors,
    flatFontsByRelationId,
    artboardsContextPart,
  ],
  (
    surfaces,
    artboards,
    artboardsContextBrandStyles,
    cellsHeight,
    previewOptionsByScreenId,
    screenDefinitions,
    layouts,
    relations,
    documents,
    colors,
    fontsByRelationId,
    artboardContextPart,
  ): Models.ArtboardsContextMap => {
    const artboardsContext: { [K in keyof Models.ArtboardsContext]: DeepIMap<Models.ArtboardsContext[K]> } = {
      surfaces,
      artboards,
      ...artboardsContextBrandStyles,
      cellsHeight,
      previewOptionsByScreenId,
      screenDefinitions,
      layouts,
      relations,
      documents,
      ...colors,
      fontsByRelationId,
      ...artboardContextPart,
    };

    return Immutable.fromJS(artboardsContext) as Models.ArtboardsContextMap;
  },
);

// move it here to prevent cyclic dependencies
// TODO: revise containers' dependencies on each other
export const layoutsInActiveSSI = createSelector(
  [activeSSI, surfaces, artboards, sections, layouts],
  (ssi, surfaces, artboards, sections, layouts): Models.CombinedLayoutsList => {
    const SSISource = ssi && ssi.get('source');

    if (!SSISource) {
      return null;
    }

    const screenId = SSISource.get('screen');
    const sectionName = SSISource.get('section');
    const screen = surfaces.get(screenId) as Models.ScreenMap;
    const artboardId = screen && screen.get('artboardId');
    const artboard = artboards.get(artboardId);
    const layoutsInArtboard = artboard && artboard.get('layoutIds').map(layoutId => layouts.get(layoutId));
    const layoutsInActiveSSI = layoutsInArtboard && layoutsInArtboard.filter(
      layout => sections.getIn([layout.get('section'), 'name']) === sectionName,
    );

    return layoutsInActiveSSI as Models.CombinedLayoutsList;
  },
);

export const sectionStylesByRelationId = createSelector(
  [layouts, sections, screenDefinitions, layeredRelations],
  (layouts, sections, screenDefinitions, relations) => {
    const sectionNamesByRelationId = layouts.filter<Models.LayoutMap>(isPlainLayout).reduce(
      (sectionNamesByRelationId, layout) => {
        const relationIds = getFlattenedRelations(layout, relations).keySeq().toList();
        const section = sections.get(layout.get('section'));
        const sectionName = section.get('name');
        const screenDefinitionId = section.get('screenDefinition');

        const screenDefinitionSection = screenDefinitionId && screenDefinitions
          .getIn([screenDefinitionId, 'sections']).find(section => section.get('name') === sectionName);
        const styles = screenDefinitionSection && screenDefinitionSection.get('styles');

        styles && relationIds.forEach(relationId => sectionNamesByRelationId[relationId] = styles);

        return sectionNamesByRelationId;
      },
      {} as Record<string, SectionStylesMap>,
    );

    return Immutable.fromJS(sectionNamesByRelationId) as SectionStylesByRelationIdMap;
  },
);

export const groupLayoutAssets = (
  layoutId: string,
  {
    previewUrl = null,
  } = {} as Models.LayoutAssetsSelectorOptions,
) => createSelector(
  [layouts, groupLayoutDocumentsForAssetsPanel, reusableLayoutDocuments],
  (layouts, groupLayoutDocuments, reusableLayoutDocuments): Models.GroupLayoutAssetsMap => {
    const groupLayout = layouts.get(layoutId);

    if (isPlainLayout(groupLayout)) {
      return null;
    }

    const documentId = groupLayout.get('documentId');
    let layoutPreviewUrl = previewUrl;

    if (!layoutPreviewUrl && documentId) {
      const groupLayoutDocument = groupLayoutDocuments.get(documentId);
      layoutPreviewUrl = groupLayoutDocument && groupLayoutDocument.get('previewUrl');
    }

    const layoutDocumentIds = groupLayout.get('layoutIds').map((layoutId) => {
      const layoutDocumentInternalId = layouts.getIn([layoutId, 'documentId']);

      return reusableLayoutDocuments.getIn([layoutDocumentInternalId, 'documentId']);
    });

    const assets: PlainObjectWithImmutableProps<Models.GroupLayoutAssets> = {
      layouts: layoutDocumentIds,
      previewUrl: layoutPreviewUrl,
      styles: groupLayout.get('styles'),
    };

    return Immutable.fromJS(assets);
  },
);

// used to restore GRL state
export const extendedGroupLayoutAssets = (
  layoutId: string,
  options = {} as Models.LayoutAssetsSelectorOptions,
) => createSelector(
  [layouts, reusableLayoutDocuments, groupLayoutAssets(layoutId, options)],
  (layouts, reusableLayoutDocuments, groupLayoutAssets): Models.ExtendedGroupLayoutAssetsMap => {
    const groupLayout = layouts.get(layoutId);

    if (isPlainLayout(groupLayout)) {
      return null;
    }

    const usedDocumentIds = groupLayout.get('layoutIds').map(layoutId => layouts.getIn([layoutId, 'documentId']));
    const usedDocuments = pickWithOrder(reusableLayoutDocuments, usedDocumentIds);

    const assets: PlainObjectWithImmutableProps<Models.ExtendedGroupLayoutAssets> = {
      styles: groupLayoutAssets.get('styles'),
      layouts: groupLayoutAssets.get('layouts'),
      previewUrl: groupLayoutAssets.get('previewUrl'),
      usedDocuments,
    };

    return Immutable.fromJS(assets);
  },
);

export const layoutAssets = (
  layoutId: string,
  {
    cleanUp = true,
    previewUrl = null,
  } = {} as Models.LayoutAssetsSelectorOptions,
) => createSelector(
  [layouts, documents, relations, brandDefinition],
  (layouts, documents, relations, projectBrandDefinition): Models.LayoutAssetsMap | null => {
    const layout = layouts.get(layoutId);
    if (!isPlainLayout(layout)) {
      return null;
    }

    let layoutBrandDefinition: Models.OnlineBrandDefinitionMap;
    let layoutSourceBrandDefinition: Models.OnlineBrandDefinitionMap;
    let layoutPreviewUrl = previewUrl;

    if (isReusableLayout(layout, documents) || isMockReusableLayout(layout, documents)) {
      const layoutDocument = documents.get(layout.get('documentId')) as Models.ReusableLayoutMap;
      layoutBrandDefinition = layoutDocument.getIn(['entities', 'brandDefinition']);
      layoutSourceBrandDefinition = layoutDocument.getIn(['entities', 'layoutSourceBrandDefinition']);
      layoutPreviewUrl = previewUrl || layoutDocument.get('previewUrl');
    }

    const relationsOnLayout = getFlattenedRelations(layout, relations);
    const regularRelationsOnLayout = relationsOnLayout.filter<Models.RegularRelationMap>(isRegularRelation);

    const documentIdsOnLayout = regularRelationsOnLayout.map(relation => relation.get('documentId')).toSet().filter(Boolean);
    const textComponentsOnLayout = documents.filter((document, id) => isTextComponent(document) && documentIdsOnLayout.includes(id));
    const referenceCitationIds: string[] = [];
    const attachedImageIds: string[] = [];

    // collect reference citation ids
    textComponentsOnLayout.forEach((document: Models.TextComponentMap) => referenceCitationIds.push(...document.get('referenceCitations')));

    // collect background image ids
    attachedImageIds.push(layout.getIn(['styles', 'backgroundImage', 'id']));
    regularRelationsOnLayout.forEach((relation) => {
      const relationStyles = relation.get('styles');
      if (relationStyles) {
        attachedImageIds.push(relationStyles.getIn(['backgroundImage', 'id']));
        attachedImageIds.push(relationStyles.getIn(['mobileViewImage', 'id']));
        attachedImageIds.push(relationStyles.getIn(['mobileViewImage', 'documentId']));
      }
    });

    const allDocumentIdsOnLayout = _.compact(documentIdsOnLayout.concat(referenceCitationIds, attachedImageIds).toArray());
    const layoutDocuments = _.mapValues(
      documents.filter((document, id) => allDocumentIdsOnLayout.includes(id)
        || allDocumentIdsOnLayout.includes(document.get('documentId')),
      ).toJS() as Models.CombinedDocuments,
      document => cleanUp ? deleteDocumentInternalInfo(document) : document,
    );
    const _layoutAssets: PlainObjectWithImmutableProps<Models.LayoutAssets> = {
      documents: layoutDocuments,
      relations: relationsOnLayout,
      styles: layout.get('styles'),
      relationId: layout.get('relationId'),
      brandDefinition: layoutBrandDefinition || projectBrandDefinition,
      previewUrl: layoutPreviewUrl,
      layoutSourceBrandDefinition,
    };

    return Immutable.fromJS(_layoutAssets);
  },
);

export const extendedCombinedLayoutAssets = (layoutId: string, options?: Models.LayoutAssetsSelectorOptions) => createSelector(
  [layouts, layoutAssets(layoutId, options), extendedGroupLayoutAssets(layoutId, options)],
  (
    layouts,
    layoutAssets,
    groupLayoutAssets,
  ): Models.ExtendedCombinedLayoutAssetsMap => isGroupLayout(layouts.get(layoutId)) ? groupLayoutAssets : layoutAssets,
);

export const reusableLayouts = createSelector(
  [layouts, reusableLayoutDocuments],
  (layouts, reusableLayoutDocuments) => layouts.filter(layout => isReusableLayout(layout, reusableLayoutDocuments)));

export const mockReusableLayouts = createSelector(
  [layouts, reusableLayoutDocuments],
  (layouts, reusableLayoutDocuments) => layouts.filter(layout => isMockReusableLayout(layout, reusableLayoutDocuments)));

export const reusableLayoutsRelationIds = createSelector(
  [reusableLayouts, layeredRelations],
  (reusableLayouts, relations) => reusableLayouts
    .toList()
    .map(layout => isPlainLayout(layout)
      ? getFlattenedRelations(layout, relations).keySeq().toList()
      : Immutable.List<string>(),
    )
    .flatten(false) as Immutable.List<string>,
);

export const reusableLayoutsTextComponentIds = createSelector([reusableLayoutsRelationIds, layeredRelations, activeLayer], getTextComponentsIds);
