import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    CANCEL_LAYOUT_PREVIEW_GENERATION: null,
    GENERATE_LAYOUT_PREVIEW: null,
    SAVE_LAYOUT: null,
    UNLINK_REUSABLE_LAYOUT: null,
  },
  'SaveReusableLayoutWindow',
);
