import { fromJS } from 'immutable';
import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import Dropdown from 'components/Toolbar/Dropdowns/BorderRadius';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { BorderRadiusMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { BorderRadiusControlProps } from './models';

const defaultBorderRadius: BorderRadiusMap = fromJS({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

const BorderRadius: React.FunctionComponent<BorderRadiusControlProps> = (props) => {
  const { borderRadius, toggleBorderRadius, title, icon, iconTooltipClassName, dropdownWarningIconClassName,
    dropdownWarningMessage, dropdownClassName, returnFocusToEditor } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={title}
      type={icon}
      iconTooltipClassName={iconTooltipClassName}
    >
      {isOpen && (
        <Dropdown
          borderRadius={borderRadius || defaultBorderRadius}
          title={intlGet('EditorToolbar.Tooltip', title)}
          dropdownWarningMessage={dropdownWarningMessage}
          toggleBorderRadius={toggleBorderRadius}
          className={dropdownClassName}
          iconTooltipClassName={dropdownWarningIconClassName}
        />
      )}
    </Control>
  );
};

BorderRadius.defaultProps = {
  icon: IconType.BORDER_RADIUS,
  title: Title.BORDER_RADIUS,
};

export default BorderRadius;
