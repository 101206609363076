import { IconType } from 'components/Icon/constants';
import { Alignment } from 'models';

export enum AssetAlignmentType {
  BOTTOM = 'bottom',
  VERTICAL_CENTER = 'verticalCenter',
  HORIZONTAL_CENTER = 'horizontalCenter',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export enum AssetAlignmentDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export const AlignmentValue: Record<AssetAlignmentType, string> = {
  [AssetAlignmentType.TOP]: 'top',
  [AssetAlignmentType.HORIZONTAL_CENTER]: 'center',
  [AssetAlignmentType.BOTTOM]: 'bottom',
  [AssetAlignmentType.LEFT]: 'left',
  [AssetAlignmentType.VERTICAL_CENTER]: 'center',
  [AssetAlignmentType.RIGHT]: 'right',
};

export const ASSET_ALIGNMENTS: Alignment[] = [
  {
    type: AssetAlignmentType.LEFT,
    icon: IconType.HORIZONTAL_ALIGNMENT_LEFT,
    style: { alignItems: 'flex-start' },
  },
  {
    type: AssetAlignmentType.HORIZONTAL_CENTER,
    icon: IconType.HORIZONTAL_ALIGNMENT_CENTER,
    style: { alignItems: 'center' },
  },
  {
    type: AssetAlignmentType.RIGHT,
    icon: IconType.HORIZONTAL_ALIGNMENT_RIGHT,
    style: { alignItems: 'flex-end' },
  },
  {
    type: AssetAlignmentType.TOP,
    icon: IconType.VERTICAL_ALIGNMENT_TOP,
    style: { justifyContent: 'flex-start' },
  },
  {
    type: AssetAlignmentType.VERTICAL_CENTER,
    icon: IconType.VERTICAL_ALIGNMENT_CENTER,
    style: { justifyContent: 'center' },
  },
  {
    type: AssetAlignmentType.BOTTOM,
    icon: IconType.VERTICAL_ALIGNMENT_BOTTOM,
    style: { justifyContent: 'flex-end' },
  },
];

/**
 * Now using only for text alignment inside CTA Button
 */
export const TextAlignmentStyleMap: PartialRecord<AssetAlignmentType, React.CSSProperties> = {
  [AssetAlignmentType.LEFT]: { textAlign: 'left' },
  [AssetAlignmentType.HORIZONTAL_CENTER]: { textAlign: 'center' },
  [AssetAlignmentType.RIGHT]: { textAlign: 'right' },
};
