import * as React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import { EntityType } from 'const';
import StoryCard from 'containers/StoryCard';
import { ModuleBundleMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { IModuleBundleListProps } from './models';

const ModuleBundleList: React.FunctionComponent<IModuleBundleListProps> = (props) => {
  const {
    items,
    showUpdatedModuleBundles,
    toggleUpdatedDocumentsDisplaying,
    updatedInPromoMats,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({
    toggleUpdatedDocumentsDisplaying,
    showUpdatedDocuments: showUpdatedModuleBundles,
    entityType: EntityType.MODULE_BUNDLE,
  });

  return items && items.size > 0 && (
    <div data-title="module-bundles">
      <Header
        title={intlGet('Assets', 'ModuleBundles', { count: items.size })}
        showUpdatesHint={updatedInPromoMats && showUpdatedModuleBundles}
        expanded={expanded}
        onClick={toggle}
      />
      {
        expanded &&
        <List
          elements={items}
          renderElement={(moduleBundle: ModuleBundleMap) => (
            <StoryCard key={moduleBundle.get('id')} document={moduleBundle} />
          )}
        />
      }
    </div>
  );
};

export default ModuleBundleList;
