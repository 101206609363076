import Immutable from 'immutable';
import { put } from 'redux-saga/effects';

import { EntityType, LATEST_REUSABLE_LAYOUT_VERSION } from 'const';
import { startHandlingReusableLayoutsEditing, stopHandlingReusableLayoutsEditing } from 'containers/App/actions';
import { updateDocument } from 'containers/Documents/actions';
import * as Models from 'models';
import { isGroupLayoutAssets } from 'utils/isGroupLayoutAssets';

export function* updateLayoutAssets(id: string, layoutAssets: Models.CombinedLayoutAssetsMap) {
  try {
    yield put(stopHandlingReusableLayoutsEditing());

    if (isGroupLayoutAssets(layoutAssets)) {
      const entities: PlainObjectWithImmutableProps<Models.GroupLayoutDocumentEntities> = {
        layouts: layoutAssets.get('layouts'),
        styles: layoutAssets.get('styles'),
      };

      yield put(updateDocument({
        id,
        entities: Immutable.Map(entities) as unknown as Models.GroupLayoutDocumentEntities,
        isEdited: true,
        entityType: EntityType.GROUP_LAYOUT,
      }));

      return;
    }

    const documents = layoutAssets.get('documents');
    const entities: PlainObjectWithImmutableProps<Models.ReusableLayoutEntities> = {
      brandDefinition: layoutAssets.get('brandDefinition'),
      relationId: layoutAssets.get('relationId'),
      relations: layoutAssets.get('relations'),
      styles: layoutAssets.get('styles'),
      version: LATEST_REUSABLE_LAYOUT_VERSION,
      layoutSourceBrandDefinition: layoutAssets.get('layoutSourceBrandDefinition'),
    };

    yield put(updateDocument({
      id,
      documents: documents as unknown as Models.CombinedDocuments,
      entities: Immutable.Map(entities) as unknown as Models.ReusableLayoutEntities,
      isEdited: true,
      entityType: EntityType.LAYOUT,
    }));
  } finally {
    yield put(startHandlingReusableLayoutsEditing());
  }
}
