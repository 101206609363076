import _ from 'lodash';

import * as Models from 'models';
import { mergeBrandDefinitions } from 'utils/brandStyles/mergeBrandDefinitions';
import { isReusableLayoutDocument } from 'utils/entityType';

type Args = Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs | Models.SyncProcessArgs | Models.AssetsCollectionDataToUpload;

export const extendReusableLayoutBrandDefinitions = <T extends Args>(args: T): T => {
  const { assets } = args;
  const { documents, masterScreenData: { brandDefinition: projectBrandDefinition } } = assets;

  _.forEach(documents, (document) => {
    if (!isReusableLayoutDocument(document)) {
      return;
    }

    const { entities: { brandDefinition: reusableLayoutBrandDefinition } } = document;
    const extendedBrandDefinition = mergeBrandDefinitions(_.cloneDeep(projectBrandDefinition), reusableLayoutBrandDefinition);
    document.entities.brandDefinition = extendedBrandDefinition;
    document.entities.layoutSourceBrandDefinition = reusableLayoutBrandDefinition;
  });

  return args;
};
