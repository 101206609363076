import { SagaIterator } from 'redux-saga';
import { call, put, select, spawn } from 'redux-saga/effects';

import { MethodName, ProcessType, UploadProjectType, VEEVA_CRM_EMAIL_HTML_LIMIT } from 'const';
import * as AppActions from 'containers/App/actions';
import { masterScreenData as masterScreenDataSelector } from 'containers/Common/selectors';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { initApp } from 'containers/Common/services/initApp';
import * as ProjectActions from 'containers/Project/actions';
import { isProjectTranslatable as isProjectTranslatableSelector } from 'containers/Project/selectors';
import { rootDocumentId as rootDocumentIdSelector } from 'containers/RootDocument/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { removeSavedLocallyProject } from 'services/localSave';
import logger from 'services/logger';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { ReturnTypeSagaFlow, sagaFlow } from 'utils/sagaFlow';
import { Action, UploadProjectFlowArg } from '../models';
import { completeTranslation } from '../services/completeTranslation';
import { deleteUnusedTextComponents } from '../services/deleteUnusedTextComponents';
import { linkDocumentsOnArtboards } from '../services/linkDocumentsOnArtboards';
import { refreshAssets } from '../services/refreshAssets';
import { syncAssets } from '../services/syncAssets';
import { updateReusableLayoutsPreviews } from '../services/updateReusableLayoutsPreviews';
import { uploadJsonToProject } from '../services/uploadJsonToProject';
import { uploadPackageAndProjectPdf } from '../services/uploadPackageAndProjectPdf';

export function* uploadToPromoMats({ payload }: Action.IUploadToPromoMats): SagaIterator {
  const logId = logger.performanceStart();
  const { uploadProjectType, translationComplete } = payload;
  const methodName = uploadProjectType === UploadProjectType.DRAFT
    ? MethodName.UPLOAD_DRAFT
    : MethodName.UPLOAD_FOR_REVIEW;

  try {
    yield put(ProjectActions.disableAutoSave());
    yield put(AppActions.lockProjectContent(ProcessType.UPLOAD_TO_PROMOMATS));
    yield put(AppActions.stopHandlingReusableLayoutsEditing());

    const rootDocumentId: ReturnTypeSaga<typeof rootDocumentIdSelector> = yield select(rootDocumentIdSelector);
    const isProjectTranslatable: ReturnTypeSaga<typeof isProjectTranslatableSelector> = yield select(isProjectTranslatableSelector);
    const masterScreenData = (yield select(masterScreenDataSelector)).toJS() as Models.MasterScreenData;
    // DON'T need to remove an internal info here because we need this data to get a correctly refreshed project
    // we remove an internal info before saving operations only (upload json to PM, local save)
    const projectAssets: ReturnTypeSaga<typeof getProjectAssets> = yield call(getProjectAssets, { removeInternalInfo: false });
    let uploadProjectFlow: ReturnTypeSagaFlow<UploadProjectFlowArg>;

    switch (payload.uploadProjectType) {
      case UploadProjectType.READY_FOR_REVIEW: {
        uploadProjectFlow = sagaFlow<UploadProjectFlowArg>(
          completeTranslation,
          initApp,
          deleteUnusedTextComponents,
          refreshAssets,
          initApp, // need to apply all changes to the artboards to generate correct layouts previews
          updateReusableLayoutsPreviews,
          syncAssets,
          initApp, // need to apply all changes to the artboards to generate the correct artboards JSON
          uploadPackageAndProjectPdf,
          linkDocumentsOnArtboards, // link + unlink abbreviations
          uploadJsonToProject,
        );

        break;
      }
      case UploadProjectType.DRAFT: {
        uploadProjectFlow = sagaFlow<UploadProjectFlowArg>(
          updateReusableLayoutsPreviews,
          linkDocumentsOnArtboards,
          uploadJsonToProject,
        );

        break;
      }
      default: return;
    }

    const uploadProjectFlowArg: UploadProjectFlowArg = {
      projectAssets: projectAssets as Models.ProjectAssetsToUpload,
      rootDocumentId,
      actionsWithMiddleware: [],
      isProjectTranslatable,
      masterScreenData,
      translationComplete,
      htmlUnicodeSize: 0,
    };

    const {
      notUpdatedLayoutIds,
      actionsWithMiddleware,
      projectAssets: updatedProjectAssets,
    }: ReturnTypeSaga<typeof uploadProjectFlow> = yield call(
      uploadProjectFlow,
      uploadProjectFlowArg,
    );

    yield call(initApp, { actionsWithMiddleware, projectAssets: updatedProjectAssets });
    yield call(removeSavedLocallyProject, rootDocumentId);

    yield spawn([logger, logger.performanceEnd], logId, { methodName });
    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', 'ProjectHasBeenUploadedToPromoMats'),
    );

    if (uploadProjectFlowArg.htmlUnicodeSize >= VEEVA_CRM_EMAIL_HTML_LIMIT) {
      yield call(
        [Notifications, Notifications.warn],
        intlGet('Notification.Warning', 'VeevaCrmHtmlLimitExceeded'),
      );
    }

    if (notUpdatedLayoutIds && notUpdatedLayoutIds.length > 0) {
      yield spawn(
        [Notifications, Notifications.showNotUpdatedLayouts],
        notUpdatedLayoutIds,
        payload.uploadProjectType === UploadProjectType.READY_FOR_REVIEW,
      );
    }
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Project.uploadToPromoMats', 'Upload');
  } finally {
    yield put(AppActions.unlockProjectContent());
    yield put(ProjectActions.activateAutoSave());
    yield put(AppActions.startHandlingReusableLayoutsEditing());
  }
}
