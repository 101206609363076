/* eslint-disable max-len */
import React from 'react';

const AddSlim = (): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.05188 6.9482H-0.00292969V5.05237H5.05188V-0.00244141H6.94772V5.05237H12.0025V6.9482H6.94772V12.003H5.05188V6.9482Z"/>
  </svg>
);

export default AddSlim;
