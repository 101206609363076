import { DocumentType, EntityType } from 'const';
import { Image } from 'models';
import { baseDocumentFactory } from './baseDocumentFactory';

export function imageFactory({
  _internalInfo,
  renditions = null,
  translatable = true,
  translationNote = '',
  ...args
} = {} as Partial<Image>): Image {
  return {
    ...baseDocumentFactory(args),
    _internalInfo,
    renditions,
    type: DocumentType.COMPONENT,
    entityType: EntityType.IMAGE,
    translatable,
    translationNote,
  };
}
