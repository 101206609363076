import { BorderRadius, BorderRadiusMap } from 'models';
import { boxPropertyToString } from './boxPropertyToString';

export function getBorderRadius(
  borderRadius?: BorderRadiusMap,
): Pick<React.CSSProperties, 'borderRadius'> {
  if (!borderRadius) {
    return {};
  }

  return {
    borderRadius: boxPropertyToString(borderRadius?.toJS() as BorderRadius),
  };
}
