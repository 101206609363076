import React from 'react';

import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { intlGet } from 'utils/intlGet';
import { ScrollableProps } from './models';
import styles from './styles.module.scss';

const Scrollable: React.FunctionComponent<ScrollableProps> = (props) => {
  const { scrollable, toggleScrollable } = props;

  return (
    <Dropdown className={styles.Scrollable} stopClickPropagation={true}>
      <Checkbox onChange={toggleScrollable} checked={scrollable} label={intlGet('EditorToolbar.Scrollable', 'ScrollableLayout')} />
      <div className={styles.description}>{intlGet('EditorToolbar.Scrollable', 'Description')}</div>
    </Dropdown>
  );
};

export default Scrollable;
