import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { KeyboardKey } from 'const';
import { INumericInputProps, INumericInputState } from './models';
import styles from './styles.module.scss';

export default class NumericInput extends React.PureComponent<INumericInputProps, INumericInputState> {
  static defaultProps: DefaultProps<INumericInputProps> = {
    allowEmptyStringValue: false,
    className: null,
    autoFocus: false,
    disabled: false,
    isAutoHeight: false,
    disabledInput: false,
    disableWhenValueAbsent: true,
    isIncreasingWidthDisabled: false,
    min: 0,
    max: Infinity,
    step: 1,
    onBlur: null,
    onFocus: null,
    onChange: _.noop,
    onButtonClick: null,
    onEnterPress: _.noop,
    showButtonControls: true,
    suffix: '',
    transfluentControls: false,
    maxLength: Infinity,
    onChangeSize: _.noop,
    addEvent: false,
    defaultValue: 0,
    onKeyDown: () => {},
  };

  private readonly numericInput = React.createRef<HTMLInputElement>();

  // This listener is an alternative to the onBlur event, because here the onBlur doesn't work as we expect.
  private listenerCloseInput: EventListener = (event: MouseEvent): void => {
    if (event.target !== this.numericInput.current) {
      this.props.onEnterPress();
    }
  };

  componentDidMount(): void {
    if (this.props.addEvent) {
      window.document.addEventListener('mousedown', this.listenerCloseInput);
    }
  }

  componentWillUnmount(): void {
    if (this.props.addEvent) {
      window.document?.removeEventListener('mousedown', this.listenerCloseInput);
    }
  }

  private onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { allowEmptyStringValue, onChange, onChangeSize, addEvent, defaultValue } = this.props;
    const { value } = event.target;

    if (value === '') {
      this.changeValue(allowEmptyStringValue ? null : defaultValue, onChange);

      return;
    }

    if (addEvent) {
      onChangeSize(Number(value));
    }
    this.changeValue(Number(value), onChange);
  };

  private onButtonClick = (newValue: number): void => {
    const { onChange, onButtonClick } = this.props;
    const changeHandler = onButtonClick || onChange;

    this.changeValue(newValue, changeHandler);
  };

  private onIncreaseClick: React.MouseEventHandler = () => {
    const { value, step } = this.props;

    this.onButtonClick(value + step);
  };

  private onDecreaseClick: React.MouseEventHandler = () => {
    const { value, step } = this.props;

    this.onButtonClick(value - step);
  };

  private changeValue = (nextValue: number, onChange: (newValue: number) => void): void => {
    const { min, max, value: prevValue } = this.props;
    const value = !_.isNil(nextValue) ? _.clamp(nextValue, min, max) : nextValue;

    if (prevValue === value) {
      return;
    }

    onChange(value);
  };

  private onEnterPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { onEnterPress, onKeyDown } = this.props;

    if (event.key === KeyboardKey.ENTER) {
      onEnterPress();
      event.preventDefault();
      event.stopPropagation();
    }

    onKeyDown(event);
  };

  render() {
    const {
      className,
      autoFocus,
      disabled,
      isAutoHeight,
      disabledInput,
      disableWhenValueAbsent,
      isIncreasingWidthDisabled,
      value,
      onBlur,
      onFocus,
      showButtonControls,
      suffix,
      transfluentControls,
      maxLength,
    } = this.props;
    const containerClassName = classNames(
      styles.NumericInput,
      {
        [styles.disabled]: disabled,
        [styles.withControls]: showButtonControls,
      },
      className,
    );

    const disableValue = disableWhenValueAbsent && !value && (value !== 0);
    const roundedValue = !_.isNil(value) ? _.round(value) : '';

    return (
      <div className={containerClassName}>
        <input
          type="number"
          className={classNames(styles.input, { [styles.transfluent]: transfluentControls })}
          autoFocus={autoFocus}
          disabled={disabled || disableValue || disabledInput}
          value={disableValue ? '' : roundedValue}
          placeholder={_.isNil(value) ? 'auto' : ''}
          onChange={this.onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={this.onEnterPress}
          maxLength={maxLength}
          ref={this.numericInput}
        />
        {isAutoHeight && <span className={styles.AutoHeightIndicator}>auto</span>}
        {
          suffix && <span className={classNames(styles.suffix, { [styles.transfluent]: transfluentControls })}>
            {suffix}
          </span>
        }
        {/* TODO: extract inc/dec button to separate component based on NumericInput */}
        {
          showButtonControls &&
          <div className={classNames(styles.buttonContainer, { [styles.transfluent]: transfluentControls })}>
            <div onClick={isIncreasingWidthDisabled ? null : this.onIncreaseClick}><div className={classNames(styles.arrow, styles.arrow_up)} /></div>
            <div onClick={this.onDecreaseClick}><div className={classNames(styles.arrow, styles.arrow_down)} /></div>
          </div>
        }
      </div>
    );
  }
}
