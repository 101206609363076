import React from 'react';
/* eslint-disable max-len */

const Gear = (): JSX.Element => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <path d="M15.8851615,9.8775 C15.9198766,9.59625 15.94302,9.30375 15.94302,9 C15.94302,8.69625 15.9198766,8.40375 15.8735898,8.1225 L17.8292071,6.6375 C18.0027826,6.5025 18.0490694,6.255 17.9449241,6.06375 L16.0934521,2.9475 C15.9777351,2.745 15.7347294,2.6775 15.5264388,2.745 L13.2236705,3.645 C12.7376591,3.285 12.2285043,2.9925 11.661491,2.7675 L11.31434,0.3825 C11.2796249,0.1575 11.082906,0 10.851472,0 L7.148528,0 C6.917094,0 6.7319468,0.1575 6.6972317,0.3825 L6.35008069,2.7675 C5.78306739,2.9925 5.26234089,3.29625 4.78790119,3.645 L2.48513289,2.745 C2.27684229,2.66625 2.03383659,2.745 1.91811959,2.9475 L0.0666475815,6.06375 C-0.0490694188,6.26625 -0.00278261868,6.5025 0.182364582,6.6375 L2.13798189,8.1225 C2.09169509,8.40375 2.05697999,8.7075 2.05697999,9 C2.05697999,9.2925 2.08012339,9.59625 2.12641019,9.8775 L0.170792882,11.3625 C-0.00278261868,11.4975 -0.0490694188,11.745 0.0550758814,11.93625 L1.90654789,15.0525 C2.02226489,15.255 2.26527059,15.3225 2.47356119,15.255 L4.77632949,14.355 C5.26234089,14.715 5.77149569,15.0075 6.33850899,15.2325 L6.68566,17.6175 C6.7319468,17.8425 6.917094,18 7.148528,18 L10.851472,18 C11.082906,18 11.2796249,17.8425 11.3027683,17.6175 L11.6499193,15.2325 C12.2169326,15.0075 12.7376591,14.70375 13.2120988,14.355 L15.5148671,15.255 C15.7231577,15.33375 15.9661634,15.255 16.0818804,15.0525 L17.9333524,11.93625 C18.0490694,11.73375 18.0027826,11.4975 17.8176354,11.3625 L15.8851615,9.8775 L15.8851615,9.8775 Z M9,12.375 C7.0906695,12.375 5.52848999,10.85625 5.52848999,9 C5.52848999,7.14375 7.0906695,5.625 9,5.625 C10.9093305,5.625 12.47151,7.14375 12.47151,9 C12.47151,10.85625 10.9093305,12.375 9,12.375 Z" />
  </svg>
);

export default Gear;
