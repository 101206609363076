import classNames from 'classnames';
import React, { useEffect } from 'react';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import { MultiCustomStyle } from 'const';
import FontFamily from 'containers/Toolbar/Dropdowns/FontFamily';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = {
  activeDropdown: ToolbarDropdownType | null;
  activeFontFamily: Models.FontFamily | undefined;
  returnFocusToEditor(): void;
  setFontFamily(font: Models.BrandFontMap, charStyle: Models.CharacterStyleMap): void;
  wrapperOnMouseDownHackCallback: (e: React.MouseEvent<HTMLDivElement>, dropDownType: ToolbarDropdownType) => void;
  closeDialog: (e: MouseEvent, type: ToolbarDropdownType, isTarget: boolean) => void;
};

const FontFamilyControl: React.FunctionComponent<Props> = (props: Props) => {
  const icon = React.createRef<HTMLButtonElement>();
  const { activeDropdown, closeDialog, activeFontFamily, wrapperOnMouseDownHackCallback } = props;
  const showDropdown = activeDropdown === ToolbarDropdownType.FONT_FAMILY;

  const handleFontSelection = (font: Models.BrandFontMap, charStyle: Models.CharacterStyleMap): void => {
    const { setFontFamily, returnFocusToEditor } = props;

    setFontFamily(font, charStyle);
    returnFocusToEditor();
  };

  const closeDialogHandler = (event: MouseEvent): void => {
    closeDialog(event, ToolbarDropdownType.FONT_FAMILY, Boolean(icon?.current?.contains(event.target as Node)));
  };

  useEffect(() => {
    document.addEventListener('click', closeDialogHandler);

    return (): void => document?.removeEventListener('click', closeDialogHandler);
  }, [activeDropdown]);

  return (
    <div className={styles.FontFamilyControl} data-title={Title.FONT_FAMILY} >
      <button
        onMouseDown={(e): void => wrapperOnMouseDownHackCallback(e, ToolbarDropdownType.FONT_FAMILY)}
        ref={icon}
        className={classNames(styles.fontFamilyWrapper, 'reset-button')}
        data-tooltip={activeFontFamily?.fontFamily ?? intlGet('EditorToolbar.Tooltip', 'FontFamily')}
      >
        <span className={styles.controlText}>{activeFontFamily?.fontFamily ?? MultiCustomStyle.FONT_FAMILY}</span>
        <Icon type={IconType.CHEVRON_DOWN} size="badge" color={showDropdown ? 'primary' : 'secondary'} hoverColor="primary-hover" />
      </button>
      {
        showDropdown &&
        <FontFamily
          activeFontFamily={activeFontFamily}
          toggleFontFamily={handleFontSelection}
        />
      }
    </div>
  );
};

export default FontFamilyControl;
