import classNames from 'classnames';
import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { FontSizeProps } from './models';
import styles from './styles.module.scss';

const FontSize: React.FunctionComponent<FontSizeProps> = (props) => {
  const { activeFontSize, fontSizes, toggleFontSize, onMouseEnter, onMouseLeave, onCloseDropdown } = props;

  return (
    <Dropdown className={styles.FontSize} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {fontSizes.map((
        (fontSize) => {
          const className = classNames(styles.item, { [styles.active]: activeFontSize === fontSize });
          const onClick = () => toggleFontSize(fontSize);
          const onDoubleClick = () => onCloseDropdown();

          return <div key={fontSize} className={className} onClick={onClick} onDoubleClick={onDoubleClick}>{fontSize}</div>;
        }
      ))}
    </Dropdown>
  );
};

export default FontSize;
