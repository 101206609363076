import Immutable from 'immutable';
import _ from 'lodash';
import { call } from 'redux-saga/effects';
import guid from 'uuid';

import { TextStatusType } from 'const';
import { addTextFromAssetsCollection } from 'containers/ArtboardCell/services/addTextFromAssetsCollection';
import { createDocument } from 'factories/document/documentFactory';
import * as Models from 'models';
import { isTextComponent } from 'utils/entityType';
import { findDocumentDuplicateId } from 'utils/findDocumentDuplicateId';
import { processBrandStyle } from './processBrandStyle';

/**
 * Processes documents to add from Story Card
 * @param id - document id to process
 * @param storyCardDocuments - internal Story Card documents
 * @param textComponents - text components in the project
 * @param images - images in the project
 * @param brandStyles - all available brand styles in the project
 * @param sectionStyles - list of available brand style ids on the current section
 * @param colors - all available colors in the project
 * @param fonts - all available fonts in the project
 *
 * NOTE: the following arguments are the result of document processing which will be mutated
 * @param textDocumentsCache - cache of clean texts (for duplicate searching)
 * @param referenceCitationsCache - cache of clean texts (for duplicate searching)
 * @param newDocumentIdByOldDocumentId - map of already processed document ids
 * @param documentsToMerge - new documents to add to the project
 * @param relation - relation of the document which will be processed (is used only for Text Components)
 * @param isReusableLayoutFlag - boolean value whether to apply default values to the styles section or not
 * @returns id of the document that was used as a result
 */
export function* processDocument(
  id: string,
  isReusableLayoutFlag: boolean,
  storyCardDocuments: Models.CombinedDocumentsMap,
  textComponents: Models.TextComponentsMap,
  images: Models.ImagesMap,
  brandStyles: Models.BrandStylesMap,
  sectionStyles: Immutable.List<string>,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
  shouldLookForDuplicates: boolean,
  shouldProcessBrandStyles: boolean,

  // the following arguments are the result of document processing which will be mutated
  textDocumentsCache: Models.TextCache,
  referenceCitationsCache: Models.TextCache,
  newDocumentIdByOldDocumentId: Record<string, string>,
  documentsToMerge: Models.CombinedDocuments,
  relation?: Models.Relation,
): Generator<unknown, string> {
  if (!id) {
    return id;
  }

  // for unlink logic need to switch brandStyleChanged flag
  // in order to render the <Text> component correctly while skipping processBrandStyle();
  if (isReusableLayoutFlag && relation) {
    const { styles } = relation as Models.RegularRelation<Models.TextRelationStyles>;
    styles.brandStyleChanged = true;
  }

  const processedDocumentId = newDocumentIdByOldDocumentId[id];
  // just assign new document id if this used document was already processed
  if (processedDocumentId) {
    // process Brand Style if it is Text Component
    const processedDocument = documentsToMerge[processedDocumentId];
    let textComponent: Models.TextComponent | Models.TextComponentMap;
    if (processedDocument && isTextComponent(processedDocument)) {
      textComponent = processedDocument;
    } else {
      textComponent = textComponents.get(processedDocumentId);
    }

    if (shouldProcessBrandStyles && textComponent) {
      processBrandStyle(
        textComponent,
        relation as Models.RegularRelation<Models.TextRelationStyles>,
        brandStyles,
        sectionStyles,
        colors,
        fonts,
        storyCardDocuments.get(id) as Models.TextComponentMap,
      );
    }

    return processedDocumentId;
  }

  const relationDocument = storyCardDocuments.get(id).toJS() as Models.CombinedDocument;

  // look for a duplicate
  const duplicateId = !shouldLookForDuplicates && isTextComponent(relationDocument)
    ? null // no need to check for duplicates of text from RL
    : findDocumentDuplicateId(relationDocument, textComponents, images, textDocumentsCache);

  if (duplicateId) {
    // collect new document id by old one in order to avoid searching duplicate several times for the same document
    newDocumentIdByOldDocumentId[id] = duplicateId;
    // process Brand Style if it is Text Component
    const textComponent = textComponents.get(duplicateId);

    if (shouldProcessBrandStyles && textComponent) {
      processBrandStyle(
        textComponent,
        relation as Models.RegularRelation<Models.TextRelationStyles>,
        brandStyles,
        sectionStyles,
        colors,
        fonts,
        relationDocument as Models.TextComponent,
      );
    }

    return duplicateId;
  }

  // create a new document
  let newRelationDocument = createDocument({
    ...relationDocument,
    id: guid(),
  });

  if (isTextComponent(newRelationDocument)) {
    const { referenceCitations } = newRelationDocument;
    // TODO: why do we need 'status' field?
    newRelationDocument.status = TextStatusType.COPY;

    // process reference citations if they are applied on the text
    if (referenceCitations && referenceCitations.length > 0) {
      const addTextResult: ReturnTypeSaga<typeof addTextFromAssetsCollection> = yield call(
        addTextFromAssetsCollection,
        newRelationDocument,
        storyCardDocuments,
        newDocumentIdByOldDocumentId,
        referenceCitationsCache,
      );
      const { textComponent, referenceCitations, newReferenceIdByOldReferenceId } = addTextResult;
      newRelationDocument = textComponent;
      // collect new reference citations documents
      _.assign(documentsToMerge, referenceCitations);
      // collect new reference citation ids by old ones in order to easier match them later
      _.assign(newDocumentIdByOldDocumentId, newReferenceIdByOldReferenceId);
    }

    if (shouldProcessBrandStyles && !isReusableLayoutFlag) {
      processBrandStyle(
        newRelationDocument,
        relation as Models.RegularRelation<Models.TextRelationStyles>,
        brandStyles,
        sectionStyles,
        colors,
        fonts,
      );
    }
  }

  const { id: newDocumentId } = newRelationDocument;
  // collect the new document
  documentsToMerge[newDocumentId] = newRelationDocument;
  // collect new document id by old one in order to easier match them later
  newDocumentIdByOldDocumentId[id] = newDocumentId;

  return newDocumentId;
}
