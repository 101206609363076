import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { AddCellDirection, ProjectsConfig } from 'const';
import { activeLayer as activeLayerSelector, projectType as projectTypeSelector } from 'containers/Project/selectors';
import { setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createColumnRelation, createLayeredRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { toImmutable } from 'utils/immutable';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { recalculateRowsAndNeighborsHeight } from 'utils/rowsHeight';
import { getParent } from 'utils/rowsHeight/getParent';
import { Action } from '../models';

export function* addCell(action: Action.IAddCell) {
  try {
    const { direction, parentRelationId, relationId } = action.payload;
    const addCellTop = direction === AddCellDirection.TOP;
    const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const activeLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);
    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const parentRelation = relations.get(parentRelationId) as Models.ColumnRelationMap | Models.RowRelationMap;
    const relationPosition = parentRelation.get('relationIds').indexOf(relationId);
    const { defaultCellHeight } = ProjectsConfig[projectType];

    const newCell = createLayeredRelation(undefined, { layer: activeLayer });
    let updatedRelations = relations.set(newCell.id, toImmutable(newCell));

    if (isColumnRelation(parentRelation)) {
      const newCellPosition = addCellTop ? relationPosition : relationPosition + 1;
      const updatedParentRelation = parentRelation
        .update('relationIds', relationIds => relationIds.insert(newCellPosition, newCell.id))
        .updateIn(['styles', 'rowsHeight'], rowsHeight => rowsHeight.insert(newCellPosition, 0));

      updatedRelations = updatedRelations.set(parentRelationId, updatedParentRelation);
    } else {
      const { parent: grandParentRelation, position: parentPosition } = getParent(parentRelation.get('id'), relations);
      const parentHeight = (grandParentRelation as Models.ColumnRelationMap).getIn(['styles', 'rowsHeight', parentPosition]);

      const relationIds = addCellTop ? [newCell.id, relationId] : [relationId, newCell.id];
      const rowsHeight = addCellTop ? [0, parentHeight] : [parentHeight, 0];
      const newColumn = createColumnRelation({ relationIds, styles: { rowsHeight } });
      const updatedParentRelation = parentRelation.update('relationIds', relationIds => relationIds.set(relationPosition, newColumn.id));

      updatedRelations = updatedRelations
        .set(newColumn.id, toImmutable(newColumn))
        .set(parentRelationId, updatedParentRelation);
    }

    yield put(batchActions([
      saveAppState(),
      setRelations(recalculateRowsAndNeighborsHeight(newCell.id, updatedRelations, -defaultCellHeight)),
    ]));

  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.addCell', 'AddCell');
  }
}
