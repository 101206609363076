import Draft from 'draft-js';
import { useCallback } from 'react';
import * as Constants from 'const';

type Handler = (chars: string) => Draft.DraftHandleValue;

export function useHandleBeforeInput(
  fillUndoStackIfEmpty: () => void,
): Handler {
  return useCallback((): Draft.DraftHandleValue => {
    fillUndoStackIfEmpty();

    return Constants.HandleValue.NOT_HANDLED;
  }, [fillUndoStackIfEmpty]);
}
