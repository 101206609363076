import { DocumentsSource, LATEST_REUSABLE_LAYOUT_VERSION, Layer } from 'const';
import { layoutDocumentFactory } from 'factories/document/layoutDocumentFactory';
import * as Models from 'models';
import { generateRandomInteger } from 'utils/generateRandomInteger';
import { prepareRelationsToSave } from 'utils/reusableLayouts/prepareRelationsToSave';
import { replaceRelationsWithCopies } from 'utils/reusableLayouts/replaceRelationsWithCopies';
import { normalizeCallToActions } from './normalizeCallToActions';

export function createMockReusableLayoutOnActiveArtboard(
  layout: Models.LayeredLayout,
  layoutDocument: Models.ReusableLayout,
  activeLayer: Layer,
  documents: Models.CombinedDocuments,
  relations: Models.LayeredRelations,
  metadata: Models.MarketMetaData,
  newDocumentId: string,
): Models.ReusableLayout {
  // create mock version of RL on another artboard without documentId
  // do not replace text components with hard copies, because they could be translated via import translation package
  // update only relations of text components
  const { relationId, styles } = layout;
  const {
    newDocuments,
    relations: relationsToSet,
    newRelationIdsByOld,
  } = replaceRelationsWithCopies(relationId, relations, documents, activeLayer, false);

  const { callToActionDocuments, callToActionRelations } = normalizeCallToActions(relationsToSet, documents, activeLayer, metadata);

  const newRelationId = newRelationIdsByOld[relationId];

  // convert relations' styles and document from layered to plain structure
  const updatedRelations = prepareRelationsToSave({ ...relationsToSet, ...callToActionRelations }, activeLayer);

  return layoutDocumentFactory({
    id: newDocumentId,
    documentId: generateRandomInteger(),
    entities: {
      relationId: newRelationId,
      relations: updatedRelations,
      brandDefinition: layoutDocument.entities.brandDefinition,
      styles,
      version: LATEST_REUSABLE_LAYOUT_VERSION,
      layoutSourceBrandDefinition: layoutDocument.entities.layoutSourceBrandDefinition,
    },
    ...metadata,
    documentSource: [DocumentsSource.ONLINE],
    documents: {
      ...newDocuments,
      ...callToActionDocuments,
    },
    isMockDocument: true,
  });
}
