import { Schemas } from 'services/ArtboardConverter/models';

export const createBackgroundImage = ({
  backgroundImageDocumentId = null,
  backgroundImage = null,
  backgroundPositionX = null,
  backgroundPositionY = null,
  backgroundSize = null,
  backgroundRepeat = null,
} = {} as Partial<Schemas.BackgroundImage>): Schemas.BackgroundImage => ({
  backgroundImageDocumentId: backgroundImageDocumentId && String(backgroundImageDocumentId),
  backgroundImage,
  backgroundPositionX,
  backgroundPositionY,
  backgroundSize,
  backgroundRepeat,
});
