import { Layer } from 'const';
import * as Models from 'models';
import { getParentColumn } from 'utils/rowsHeight';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import { getVerticalNeighborPosition } from 'utils/rowsHeight/getVerticalNeighborPosition';
import { getMinRowHeight } from './getMinRowHeight';

/**
 * Calculates maximum available height for a row or a regular cell
 * @param relationId cell or row relation identifier
 * @param relations all relations
 */
export function getMaxRowHeight(
  relationId: string,
  relations: Models.LayeredRelationsMap,
  activeLayer: Layer,
): number {
  const { parent, position } = getParentColumn(relationId, relations);

  if (!areThereHorizontalNeighbors(parent.get('id'), relations)) {
    return Infinity;
  }

  const neighborPosition = getVerticalNeighborPosition(relationId, parent);
  const rowsHeight = parent.getIn(['styles', 'rowsHeight']);
  const neighborHeight = neighborPosition !== -1 ? rowsHeight.get(neighborPosition) : 0;
  const totalHeight = rowsHeight.get(position) + neighborHeight;

  const nextRelationId = parent.getIn(['relationIds', neighborPosition]);
  const nextRelationMinHeight = nextRelationId ? getMinRowHeight(nextRelationId, relations, activeLayer) : 0;

  return totalHeight - nextRelationMinHeight;
}
