import Immutable from 'immutable';

import { Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

export const surfacesInitialState = Immutable.Map() as Models.State.IState;

const surfacesReducer: Reducer<Models.State.IState> = (state = surfacesInitialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_SURFACE: return addSurface(state, action);
    case ActionTypes.SET_SURFACES: return setSurfaces(state, action);
    case ActionTypes.UPDATE_SURFACE: return updateSurface(state, action);
    case ActionTypes.UPDATE_SURFACES: return updateSurfaces(state, action);
    case ActionTypes.DELETE_SURFACES: return deleteSurfaces(state, action);
    default: return state;
  }
};

const addSurface: Reducer<Models.State.IState, Models.Action.IAddSurface> = (state, action) => {
  const surface = Immutable.fromJS(action.payload.surface);

  return state.set(surface.get('id'), surface);
};

const setSurfaces: Reducer<Models.State.IState, Models.Action.ISetSurfaces> = (state, action) => {
  const { surfaces } = action.payload;

  return Immutable.fromJS(surfaces);
};

const updateSurfaces: Reducer<Models.State.IState, Models.Action.ISetSurfaces> = (state, action) => {
  const { surfaces } = action.payload;

  return state.merge(Immutable.fromJS(surfaces));
};

const updateSurface: Reducer<Models.State.IState, Models.Action.IUpdateSurface> = (state, action) => {
  const { surface } = action.payload;

  return state.update(surface.id, existingSurface => existingSurface.mergeDeep(Immutable.fromJS(surface)));
};

const deleteSurfaces: Reducer<Models.State.IState, Models.Action.IDeleteSurfaces> = (state, action) => {
  const { ids } = action.payload;

  return ids.reduce((state, surfaceId) => state.delete(surfaceId), state);
};

export default surfacesReducer;
