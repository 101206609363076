import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ZoomWrapper from 'components/ZoomWrapper';
import { ZoomWrappeOwnProps, ZoomWrappeSelectors } from 'components/ZoomWrapper/models';
import { getScale } from 'containers/App/selectors';

const mapStateToProps = createStructuredSelector({
  scale: getScale,
});

export default connect<ZoomWrappeSelectors, ZoomWrappeOwnProps>(mapStateToProps)(ZoomWrapper);
