import { AssetAlignmentType, TextHorizontalAlignmentType } from 'const';
import * as Models from 'models';

export enum TextBrandStyleField {
  BACKGROUND_COLOR = 'backgroundColor',
  BULLET_COLOR = 'bulletColor',
  CHARACTER_STYLE = 'characterStyle',
  FONT_COLOR = 'fontColor',
  FONT_FAMILY = 'fontFamily',
  FONT_SIZE = 'fontSize',
  IS_AUTOFIT_CONTENT = 'isAutoFitContent',
  LINE_HEIGHT = 'lineHeight',
  PADDING = 'padding',
  TEXT_ALIGN = 'textAlign',
  VERTICAL_ALIGNMENT = 'verticalAlignment',
}

export interface TextBrandStyles extends TextInlineStyles {
  [TextBrandStyleField.PADDING]: Models.BoxPropertyMap;
  [TextBrandStyleField.VERTICAL_ALIGNMENT]: AssetAlignmentType;
  [TextBrandStyleField.BACKGROUND_COLOR]: Models.BrandColorMap;
}

export interface TextInlineStyles {
  [TextBrandStyleField.CHARACTER_STYLE]: string;
  [TextBrandStyleField.FONT_SIZE]: number;
  [TextBrandStyleField.FONT_COLOR]: string;
  [TextBrandStyleField.BULLET_COLOR]?: string;
  [TextBrandStyleField.FONT_FAMILY]: string;
  [TextBrandStyleField.LINE_HEIGHT]: number;
  [TextBrandStyleField.TEXT_ALIGN]: TextHorizontalAlignmentType;
}
