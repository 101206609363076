import Draft from 'draft-js';
import _ from 'lodash';

import { TextHorizontalAlignmentType } from 'const';
import { DefaultAlign } from '../constants';

const ParagraphAlignByBlockType = {
  [TextHorizontalAlignmentType.CENTER]: 'center',
  [TextHorizontalAlignmentType.LEFT]: 'left',
  [TextHorizontalAlignmentType.RIGHT]: 'right',
  [TextHorizontalAlignmentType.JUSTIFY]: 'justify',
  [TextHorizontalAlignmentType.UNORDERED_LIST]: 'left',
  unstyled: 'left',
};

export function getParagraphAlign(block: Draft.ContentBlock) {
  const blockType = block.getType();

  return _.get(ParagraphAlignByBlockType, blockType, DefaultAlign);
}
