import { all, call, delay, put, race, select, take } from 'redux-saga/effects';

import { updateAppState } from 'containers/App/actions';
import { addScreenReadyForOutput } from 'containers/ArtboardPreview/actions';
import { artboardReady } from 'containers/Artboards/actions';
import { ActionTypes } from 'containers/Artboards/constants';
import { activeSurfaceId as activeSurfaceIdSelector } from 'containers/Project/selectors';

export function* waitArtboard() {
  const activeSurfaceId: ReturnTypeSaga<typeof activeSurfaceIdSelector> = yield select(activeSurfaceIdSelector);

  const effectsToWait = [
    take(ActionTypes.ARTBOARD_IMAGES_LOADED),
    take(ActionTypes.ARTBOARD_MOUNTED),
    call(() => {
      const { fonts } = document;
      if (fonts.status !== 'loaded') {
        // eslint-disable-next-line no-console
        fonts.forEach(font => font.load().catch(error => console.error('Unable to load font', error)));

        return fonts.ready;
      }

      return null;
    }),
  ];

  yield race([
    all(effectsToWait),
    delay(20000), // timeout is needed to avoid hanging if effects won't be completed within 20s for some reason
  ]);

  yield delay(300); // HACK: to be sure that all cells were processed by the resize observer
  yield put(addScreenReadyForOutput(activeSurfaceId));
  yield put(artboardReady());

  yield put(updateAppState({ isArtboardMounted: true }));

}
