import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import styles from './styles.module.scss';

const AnchorDragPreview: React.FunctionComponent = () => (
  <div className={styles.AnchorDragPreview}>
    <Icon type={IconType.ANCHOR} size="sm" color="secondary" />
  </div>
);

export default AnchorDragPreview;
