import Immutable from 'immutable';
import _ from 'lodash';
import { call } from 'redux-saga/effects';

import * as Models from 'models';
import { isReusableLayoutDocument, isTextComponent } from 'utils/entityType';
import { deleteReferenceCitationFromComponents } from './deleteReferenceCitationFromComponents';

interface DeleteReferenceCitationsFromComponentsResult {
  documents: Models.CombinedDocuments;
  relations: Models.LayeredRelations;
}

/**
 * Deletes Reference Citations from Text components
 * @param referenceCitationIds - ids to delete
 * @param documents - all documents
 * @param relations - all relations
 */
export function* deleteReferenceCitationsFromComponents(
  referenceCitationIds: string[],
  documents: Models.CombinedDocuments,
  relations: Models.LayeredRelations,
): Generator<unknown, DeleteReferenceCitationsFromComponentsResult> {
  let relationsMap = Immutable.fromJS(relations) as Models.LayeredRelationsMap;
  let textComponentsMap = Immutable.fromJS(documents).filter(document => isTextComponent(document)) as Models.TextComponentsMap;
  let reusableLayoutsMap = Immutable.fromJS(documents).filter(document => isReusableLayoutDocument(document)) as Models.ReusableLayoutsMap;

  for (let i = 0; i < referenceCitationIds.length; i++) {
    const referenceCitationId = referenceCitationIds[i];

    const deleteReferenceCitationResult: ReturnTypeSaga<typeof deleteReferenceCitationFromComponents> = yield call(
      deleteReferenceCitationFromComponents,
      referenceCitationId,
      textComponentsMap,
      relationsMap,
    );
    const { updatedDocuments, updatedRelations } = deleteReferenceCitationResult;

    const layoutsToUpdate = reusableLayoutsMap.filter(
      layoutDocument => layoutDocument && layoutDocument.get('documents') && layoutDocument.getIn(['documents', referenceCitationId]),
    );

    const updatedReusableLayouts = layoutsToUpdate.map((layoutDocument) => {
      const updatedLayoutInnerDocuments = layoutDocument && layoutDocument.get('documents')
        .filter((doc, docId) => docId !== referenceCitationId)
        .map((doc, docId) => updatedDocuments[docId] ? Immutable.fromJS(updatedDocuments[docId]) : doc);

      return layoutDocument.set('documents', updatedLayoutInnerDocuments);
    });

    reusableLayoutsMap = reusableLayoutsMap.merge(updatedReusableLayouts as Models.ReusableLayoutsMap);
    textComponentsMap = textComponentsMap.merge(Immutable.fromJS(updatedDocuments) as Models.TextComponentsMap);
    relationsMap = relationsMap.merge(Immutable.fromJS(updatedRelations) as Models.LayeredRelationsMap);
  }

  const docs = _.assign(documents, {
    ...textComponentsMap.toJS() as Models.TextComponents,
    ...reusableLayoutsMap.toJS() as Models.ReusableLayoutsMap,
  });

  return {
    documents: docs,
    relations: relationsMap.toJS(),
  };
}
