import { cleanUpHtml } from './cleanUpHtml';

function addNumbers(index: number, useBoldNumbers: boolean): string {
  return useBoldNumbers ? `<strong>${index}.</strong>` : `${index}.`;
}

export function getReferenceHtmlAsString(
  index: number,
  reference: string,
  useBoldNumbers = false,
  useNumbers = true,
): string {
  const sanitizedHtml = cleanUpHtml(reference);

  return useNumbers
    ? `${addNumbers(index, useBoldNumbers)} ${sanitizedHtml}`
    : sanitizedHtml;
}
