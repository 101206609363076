import { $isLinkNode } from '@lexical/link';
import { $getSelection, $isRangeSelection } from 'lexical';

export const $getLinkText = (): string | undefined => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const node = selection.getNodes()[0].getParent();
  if (!$isLinkNode(node)) {
    return undefined;
  }

  return node.getURL();
};

export const $isLinkApplicable = (): boolean => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }

  const node = selection.getNodes()[0].getParent();
  const isLink = $isLinkNode(node);

  return !!selection?.getTextContent() || isLink;
};
