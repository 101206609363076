import { Border } from 'models';
import { createBoxProperty } from './boxProperty';

export const createBorder = ({
  color = null,
  colorTint = null,
  width = {},
} = {} as Partial<Border<string>>): Border<string> => ({
  color,
  colorTint,
  width: createBoxProperty(width),
});
