import _ from 'lodash';

import { EntityType } from 'const';
import * as Models from 'models';
import { callToActionFactory } from './callToActionFactory';
import { imageFactory } from './imageFactory';
import { referenceCitationFactory } from './referenceCitationFactory';
import { textComponentFactory } from './textComponentFactory';

export function createDocument(document: Models.CombinedDocument): Models.CombinedDocument {
  switch (document.entityType) {
    case EntityType.IMAGE: return imageFactory(document);
    case EntityType.REFERENCE_CITATION: return referenceCitationFactory(document);
    case EntityType.TEXT: return textComponentFactory(document);
    case EntityType.CALL_TO_ACTION: return callToActionFactory(document);
    default: return document;
  }
}

export function createDocuments(documents: Models.CombinedDocuments): Models.CombinedDocuments {
  return _.mapValues(documents, document => document && createDocument(document));
}
