import React from 'react';
/* eslint-disable max-len */

const SaveReusableLayout = (): JSX.Element => (
  <svg viewBox="-2 -1 18 18">
    <path d="M7,12.9998858 L0,5.99991173 L3.99998679,5.99991173 L3.99998679,0 L10.0000132,0 L10.0000132,5.99991173 L14,5.99991173 L7,12.9998858 Z M0,16.9998577 L14,16.9998577 L14,14.9998255 L0,14.9998255 L0,16.9998577 Z" />
  </svg>
);

export default SaveReusableLayout;
