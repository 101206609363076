import classNames from 'classnames';
import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import Spinner from 'components/Spinner';
import { intlGet } from 'utils/intlGet';
import { MIN_DEFINITION_LENGTH } from '../constants';
import css from '../styles.module.scss';

const i18n = (name: string): string => intlGet('EditorToolbar.Abbreviation.New', name);

type Props = {
  error?: Error;
  onCancel: () => void;
  onAdd: (definition: string) => void;
  loading?: boolean;
  onInput: () => void;
};

const NewDefinitionForm: React.FunctionComponent<Props> = (props) => {
  const { error, onCancel, onAdd, loading, onInput } = props;

  const [disabledSave, setDisabledSave] = useState(true);
  const [value, setValue] = useState('');

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => {
    const { target } = event;
    setValue(target.value);
    onInput();
    if (target.parentNode) {
      // @ts-expect-error replicatedValue is custom data-attribute
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      target.parentNode.dataset.replicatedValue = target.value;
    }
  }, []);

  useEffect(()=>{
    setDisabledSave(value.length < MIN_DEFINITION_LENGTH);
  }, [value]);

  return (
    <div className={css.newAbbreviation}>
      <div className={css.textareaWrap}>
        <textarea
          rows={1}
          className={classNames({ ['is-invalid']: !!error })}
          placeholder={i18n('Placeholder')}
          value={value}
          onInput={onChange}
          disabled={loading}
          minLength={MIN_DEFINITION_LENGTH}
        />
      </div>
      {error && (
        <div className="form-text text-danger">
          {error.message}
        </div>
      )}
      <div className={css.buttons}>
        <button
          className={css.button}
          onClick={onCancel}
          disabled={loading}
        >
          {i18n('Button.Cancel')}
        </button>
        <button
          className={css.button}
          onClick={(): void => onAdd(value)}
          disabled={disabledSave || loading || Boolean(error)}
        >
          {i18n('Button.Save')}
          {loading && (
            <div className="loader">
              <Spinner size='sm'/>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

NewDefinitionForm.defaultProps = {
  error: undefined,
  loading: false,
};

export default NewDefinitionForm;
