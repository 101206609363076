import React from 'react';
/* eslint-disable max-len */

const LineHeightOnePointTwo = (): JSX.Element => (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <defs>
      <path d="M6,21 L24,21 L24,19 L6,19 L6,21 L6,21 Z M6,16 L24,16 L24,14 L6,14 L6,16 L6,16 Z M6,11 L24,11 L24,9 L6,9 L6,11 L6,11 Z" id="lineHeight1_2-id"/>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="lineHeight1_2-mask" fill="white">
        <use xlinkHref="#lineHeight1_2-id" />
      </mask>
      <g mask="url(#lineHeight1_2-mask)" fill="#FFFFFF">
        <g transform="translate(2.000000, 2.000000)" id="White">
          <rect x="0" y="0" width="26" height="26" />
        </g>
      </g>
    </g>
  </svg>
);

export default LineHeightOnePointTwo;
