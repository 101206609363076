import _ from 'lodash';
import guid from 'uuid';

import { Layer } from 'const';
import { callToActionFactory as createCallToAction } from 'factories/document/callToActionFactory';
import { textComponentFactory as createTextComponent } from 'factories/document/textComponentFactory';
import { createLayeredRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { isCallToAction, isTextComponent } from 'utils/entityType';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

interface ReplaceRelationsWithCopiesResult {
  newDocuments: Models.CombinedDocuments;
  relations: Models.LayeredRelations;
  newRelationIdsByOld: { [key: string]: string };
}

export function replaceRelationsWithCopies(
  layoutRelationId: string,
  relations: Models.LayeredRelations,
  documents: Models.CombinedDocuments,
  layer: Layer,
  needToReplaceComponents = true,
): ReplaceRelationsWithCopiesResult {
  const layoutRelation = relations[layoutRelationId] as Models.RowRelation | Models.ColumnRelation;
  const layoutRelationChildRelations = getFlattenedRelations(layoutRelation, relations);
  const layoutRelations = { ...layoutRelationChildRelations, [layoutRelationId]: layoutRelation };

  return _
    .chain(layoutRelations)
    .reduce(
      (result, relation) => {
        const { id: oldRelationId } = relation;
        const newRelationOptions = { ...relation, id: guid() };

        if (isRegularRelation(relation)) {
          const { documentId: layeredDocumentId } = relation;
          const documentId = layeredDocumentId[layer];
          const document = documents[documentId];

          if (needToReplaceComponents && (isTextComponent(document) || isCallToAction(document))) {
            const newId = guid();
            (newRelationOptions as Models.LayeredRegularRelation).documentId = _.set({ ...layeredDocumentId }, layer, newId);

            // create hard copies of text components and CTAs
            const newDocument = isTextComponent(document)
              ? createTextComponent({ ...document, id: newId })
              : createCallToAction({ ...document, id: newId });

            // create hard copies of text components and CTAs
            result.newDocuments[newId] = newDocument;
          }
        }

        const newRelation = createLayeredRelation(newRelationOptions);

        result.newRelationIdsByOld[oldRelationId] = newRelation.id;
        result.relations[newRelation.id] = newRelation;

        return result;
      },
      { relations: {}, newDocuments: {}, newRelationIdsByOld: {} } as ReplaceRelationsWithCopiesResult,
    )
    .thru((result) => {
      result.relations = _.mapValues(result.relations, relation => !isRegularRelation(relation)
        ? _.update(relation, 'relationIds', (relationIds: string[]) => relationIds.map(oldId => result.newRelationIdsByOld[oldId]))
        : relation,
      );

      return result;
    })
    .value();
}
