import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Layer } from 'const';
import { CallToActionStyles, LayeredRegularRelationMap } from 'models';
import {
  CTAStyles, CTAStylesConfig, CTAStylesContext, CTAStylesSetters,
  ctaStylesFromSource, ctaStylesToCSSForContainer, ctaStylesToCSSForElement,
} from '../styles';

type CSS = {
  element: CSSProperties;
  container: CSSProperties;
};

export function useStyles(
  cta:LayeredRegularRelationMap<CallToActionStyles>,
  layer: Layer,
  context: CTAStylesContext,
  config: CTAStylesConfig,
): [CTAStyles, CTAStylesSetters, CSS] {
  const { colors, images, defaultAssetBackgroundColor } = context;
  const { allowBackgroundOpacity } = config;

  const [styles, setStyles] = useState<CTAStyles>(
    () => ctaStylesFromSource(cta.getIn(['styles', layer]), colors),
  );

  useEffect(() => {
    const source = cta.getIn(['styles', layer]);

    if (source) {
      setStyles(ctaStylesFromSource(source, colors));
    }
  }, [cta, layer, colors]);

  const setters = useMemo<CTAStylesSetters>(() => {
    const props = [
      'alignment',
      'assetBackgroundOpacity',
      'assetBorderRadius',
      'assetPadding',
      'backgroundColorOpacity',
      'backgroundImage',
      'border',
      'borderRadius',
      'fitToCell',
      'height',
      'padding',
      'textAlignment',
      'width',
    ] as const;

    const propsSetters = Object.fromEntries(
      props.map(
        <T extends keyof CTAStyles>(key: T) => [
          key,
          (value: CTAStyles[T]): void => setStyles(state => ({ ...state, [key]: value })),
        ],
      ),
    ) as { [P in (typeof props)[number]]: (value: CTAStyles[P]) => void };

    return {
      ...propsSetters,
      assetBackgroundColor: color => setStyles(state => ({
        ...state,
        assetBackgroundColor: color,
        assetBackgroundGradient: undefined,
      })),
      assetBackgroundGradient: (gradient, backupColor) => setStyles(state => ({
        ...state,
        assetBackgroundColor:backupColor,
        assetBackgroundGradient: gradient,
      })),
      backgroundColor: color => setStyles(state => ({
        ...state,
        backgroundColor: color,
        backgroundGradient: undefined,
      })),
      backgroundGradient: (gradient, backupColor) => setStyles(state => ({
        ...state,
        backgroundColor:backupColor,
        backgroundGradient: gradient,
      })),
    };
  }, []);

  const css = useMemo<CSS>(
    () => ({
      element: ctaStylesToCSSForElement(
        styles,
        { defaultAssetBackgroundColor },
        { allowBackgroundOpacity },
      ),
      container: ctaStylesToCSSForContainer(styles, { images }, { allowBackgroundOpacity }),
    }),
    [styles, images, allowBackgroundOpacity],
  );

  return [styles, setters, css];
}
