import * as Draft from 'draft-js';
import Immutable from 'immutable';
import _ from 'lodash';

import { Character, DraftEntity, REFERENCES_MARKER, ScriptType } from 'const';

interface ReferenceBlockWithOrder {
  referenceKey: string;
  orderNumber: number;
  block: string;
  start: number;
  end: number;
}

export const addReferenceCitationsOrder = (
  editorState: Draft.EditorState,
  referencesNumbersOrder: Immutable.List<number>,
  expandReferencesList = false,
): Draft.EditorState => {
  if (!referencesNumbersOrder || referencesNumbersOrder.isEmpty()) {
    return editorState;
  }

  const referencesKeys = getReferenceEntityKeys(editorState);
  const refsNumbersOrder = referencesNumbersOrder.toJS();

  let contentState = editorState.getCurrentContent();

  let isRefsSwapNeeded = true;
  let referencesDraftEntities: ReferenceBlockWithOrder[];
  do {
    referencesDraftEntities = referencesKeys.map((referenceKey, idx) => ({
      ...getReferenceEntityRange(contentState, referenceKey),
      referenceKey,
      orderNumber: refsNumbersOrder[idx],
    }));
    let indexToSwap = 0;
    let isSwapWithSeparator = false;

    isRefsSwapNeeded = referencesDraftEntities.some((currentEntity, index, referencesDraftEntities) => {
      const nextEntity = referencesDraftEntities[index + 1];
      const nextEntityInTheSameBlock = nextEntity && nextEntity.block === currentEntity.block;
      const block = contentState.getBlockForKey(currentEntity.block);
      const separator = nextEntityInTheSameBlock ? block.getText().substring(currentEntity.end, nextEntity.start) : '';
      isSwapWithSeparator = [',', ' ', ', '].includes(separator);
      const isSwapNeeded = nextEntityInTheSameBlock
        && ((nextEntity.start === currentEntity.end) || (isSwapWithSeparator && nextEntity.start === currentEntity.end + separator.length))
        && nextEntity.orderNumber < currentEntity.orderNumber;
      indexToSwap = index;

      return isSwapNeeded;
    });

    if (isRefsSwapNeeded) {
      [referencesKeys[indexToSwap], referencesKeys[indexToSwap + 1]] = [referencesKeys[indexToSwap + 1], referencesKeys[indexToSwap]];
      [refsNumbersOrder[indexToSwap], refsNumbersOrder[indexToSwap + 1]] = [refsNumbersOrder[indexToSwap + 1], refsNumbersOrder[indexToSwap]];

      const currentEntity = referencesDraftEntities[indexToSwap];
      const nextEntity = referencesDraftEntities[indexToSwap + 1];

      if (isSwapWithSeparator) {
        const selectionFrom = Draft.SelectionState.createEmpty(nextEntity.block).merge({
          anchorOffset: currentEntity.end,
          focusOffset: nextEntity.start,
        }) as Draft.SelectionState;
        const selectionTo = Draft.SelectionState.createEmpty(currentEntity.block).merge({
          anchorOffset: currentEntity.start,
          focusOffset: currentEntity.start,
        }) as Draft.SelectionState;
        contentState = Draft.Modifier.moveText(
          contentState,
          selectionFrom,
          selectionTo,
        );
      }
      const selectionFrom = Draft.SelectionState.createEmpty(currentEntity.block).merge({
        anchorOffset: nextEntity.start,
        focusOffset: nextEntity.end,
      }) as Draft.SelectionState;
      const selectionTo = Draft.SelectionState.createEmpty(nextEntity.block).merge({
        anchorOffset: currentEntity.start,
        focusOffset: currentEntity.start,
      }) as Draft.SelectionState;
      contentState = Draft.Modifier.moveText(
        contentState,
        selectionFrom,
        selectionTo,
      );
    }
  } while (isRefsSwapNeeded);
  const referenceEntityGroups = collectReferenceGroups(referencesDraftEntities);
  const withReferences = referenceEntityGroups.reduce(
    (contentStateAccumulator, references) => {
      const orderNumbers = references.map(ref => ref.orderNumber);

      return references.reduce(
        (draftContentState, reference, idx) => {
          const isLast = idx === references.length - 1;

          const { referenceKey, orderNumber } = reference;

          const { block, start, end } = getReferenceEntityRange(draftContentState, referenceKey);
          const selection = Draft.SelectionState.createEmpty(block).merge({
            anchorOffset: start,
            focusOffset: end,
          }) as Draft.SelectionState;
          const blockMap = draftContentState.getBlockMap();

          const text = expandReferencesList ? getReferenceList(orderNumber, isLast) : getShortReferenceList(orderNumbers, isLast);
          const inlineStyle = blockMap.get(block).getInlineStyleAt(start).add(ScriptType.SUPERSCRIPT);

          return Draft.Modifier.replaceText(
            draftContentState,
            selection,
            text,
            inlineStyle,
            referenceKey,
          );
        },
        contentStateAccumulator);
    },
    contentState,
  );

  return Draft.EditorState.createWithContent(withReferences);
};

export const removeReferenceCitationsOrder = (
  editorState: Draft.EditorState,
): Draft.ContentState => {
  const referencesKeys = getReferenceEntityKeys(editorState);
  const contentState = editorState.getCurrentContent();

  return referencesKeys.reduce(
    (content, referenceKey) => {
      const { block, start, end } = getReferenceEntityRange(content, referenceKey);
      const selection = Draft.SelectionState.createEmpty(block).merge({
        anchorOffset: start,
        focusOffset: end,
      }) as Draft.SelectionState;
      const blockMap = content.getBlockMap();

      return Draft.Modifier.replaceText(
        content,
        selection,
        REFERENCES_MARKER,
        blockMap.get(block).getInlineStyleAt(start),
        referenceKey,
      );
    },
    contentState,
  );
};

const getReferenceEntityKeys = (editorState: Draft.EditorState): string[] => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  return blockMap.reduce(
    (references, block) => {
      block.findEntityRanges(
        character => isReferenceEntity(contentState, character),
        start => references.push(block.getEntityAt(start)),
      );

      return references;
    },
    [] as string[],
  );
};

const getReferenceEntityRange = (contentState: Draft.ContentState, entityKey: string): {
  block: string;
  start: number;
  end: number;
} => {
  const blockMap = contentState.getBlockMap();

  return blockMap.reduce(
    (range, block) => {
      block.findEntityRanges(
        char => char.getEntity() === entityKey,
        (start, end) => Object.assign(range, { block: block.getKey(), start, end }),
      );

      return range;
    },
    { block: '', start: 0, end: 0 },
  );
};

const isReferenceEntity = (contentState: Draft.ContentState, character: Draft.CharacterMetadata): boolean => {
  const entityKey = character.getEntity();
  const entity = entityKey && contentState.getEntity(entityKey);

  return entity && entity.getType() === DraftEntity.REFERENCE;
};

const getShortReferenceList = (orderNumbers: number[], isLast: boolean): string => {
  return isLast ? formatReferenceGroups(orderNumbers) : Character.WORD_JOINER;
};

const getReferenceList = (orderNumber: number, isLast: boolean): string => {
  return `${orderNumber}${isLast ? '' : ','}`;
};

export const formatReferenceGroups = (referenceNumbers: number[]): string => {
  return _(referenceNumbers)
    .reduce(
      (groups, referenceNumber) => {
        const lastGroup = _.last(groups);
        const lastReferenceNumber = _.last(lastGroup);

        if (!!lastReferenceNumber && lastReferenceNumber + 1 === referenceNumber) {
          lastGroup.push(referenceNumber);
        } else {
          groups.push([referenceNumber]);
        }

        return groups;
      },
      [] as number[][],
    )
    .map((group) => {
      if (_.size(group) <= 2) {
        return _.join(group, ',');
      }

      const first = _.head(group);
      const last = _.last(group);

      return `${first}${Character.WORD_JOINER}–${Character.WORD_JOINER}${last}`;
    })
    .join(',');
};

export const collectReferenceGroups = (referenceBlocks: ReferenceBlockWithOrder[]): ReferenceBlockWithOrder[][] => {
  return _.reduce(
    referenceBlocks,
    (groups, reference) => {
      const lastGroup = _.last(groups);
      const lastReference = _.last(lastGroup);

      if (!!lastReference && lastReference.end === reference.start) {
        lastGroup.push(reference);
      } else {
        groups.push([reference]);
      }

      return groups;
    },
    [] as ReferenceBlockWithOrder[][],
  );
};
