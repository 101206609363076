import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const ImageMobileSettings = (): JSX.Element => {
  const maskId = guid();
  const pathId = guid();

  return (
    <svg width="24px" height="16px" viewBox="0 0 24 16">
      <defs>
        <path id={pathId} d="M4,2 L22,2 L22,0 L4,0 C2.9,0 2,0.9 2,2 L2,13 L0,13 L0,16 L14,16 L14,13 L4,13 L4,2 Z M23,4 L17,4 C16.45,4 16,4.45 16,5 L16,15 C16,15.55 16.45,16 17,16 L23,16 C23.55,16 24,15.55 24,15 L24,5 C24,4.45 23.55,4 23,4 Z M22,13 L18,13 L18,6 L22,6 L22,13 Z"/>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id={maskId} fill="white">
          <use xlinkHref={`#${pathId}`} />
        </mask>
        <use fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`} />
        <g mask={`url(#${maskId})`} fill="#FFFFFF" fillRule="evenodd">
          <g transform="translate(-1.000000, -5.000000)">
            <rect x="0" y="0" width="26" height="26" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ImageMobileSettings;
