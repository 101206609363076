import Immutable from 'immutable';

import * as DocumentModels from 'containers/Documents/models';
import * as Models from 'models';

export const getReferenceNumberOrder = (
  relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  referenceOrder: DocumentModels.ReferenceCitationsOrderByDocumentsMap,
): Immutable.List<number> => {
  const documentId = relation.get('documentId');

  return referenceOrder.get(`${relation.get('id')}-${documentId}`, Immutable.List());
};
