import _ from 'lodash';

import { createRelation } from 'factories/relationFactory';
import * as Models from 'models';
import {
  applyOverridenPadding,
  getStylesToMergeFromBrandStyles,
  getTextStylesFromBrandStyle,
} from 'utils/brandStyles';
import * as editor from 'utils/editor';
import { applyBrandStyle } from 'utils/editor/brandStyle';
import { isReusableLayoutDocument, isTextComponent } from 'utils/entityType';
import { toImmutable, toJS } from 'utils/immutable';
import { getBulletColorStyle } from 'utils/inlineStyles';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

interface ApplyStylesArgs {
  layoutDocument: Models.ReusableLayout;
  projectBrandDefinition: Models.OnlineBrandDefinition;
}

// NOTE: have to adapt styles only when adding(dropping) new RL/GRL to artboard
// adaptStyles flag should be set to false for other operations, e.g. refresh, upload etc
export function applyStyles<T extends ApplyStylesArgs>(args: T, adaptStyles = false): T {
  const {
    layoutDocument,
    projectBrandDefinition,
  } = args;

  const {
    documents,
    entities: {
      brandDefinition: extendedBrandDefinition,
      layoutSourceBrandDefinition,
      relations,
    },
  } = layoutDocument;

  // no layout source brand definition is absent in case we unlinked layout
  // TODO: add layoutSourceBrandDefinition while unlinking
  const safeLayoutSourceBrandDefinition = layoutSourceBrandDefinition || extendedBrandDefinition;
  const projectBrandStyles = toImmutable(projectBrandDefinition.brandStyles);
  const layoutBrandstyles = toImmutable(safeLayoutSourceBrandDefinition.brandStyles);
  const brandStyles = toImmutable(extendedBrandDefinition.brandStyles);
  const colors = toImmutable(extendedBrandDefinition.colors);
  const fonts = toImmutable(extendedBrandDefinition.fonts);
  const layoutColors = toImmutable(safeLayoutSourceBrandDefinition.colors);
  const layoutFonts = toImmutable(safeLayoutSourceBrandDefinition.fonts);

  _.forEach(relations, (relation) => {
    if (!isTextComponent(relation) || !isRegularRelation(relation)) {
      return;
    }

    const { documentId, styles } = relation;
    const { brandStyleId, brandStyleChanged } = styles;

    if (!brandStyleId) {
      return;
    }

    const textComponent = documents[documentId] as Models.TextComponent;
    const stubRelation = createRelation({
      ...relation,
      styles: _.cloneDeep(styles),
    }) as Models.RegularRelation<Models.TextRelationStyles>;

    const projectBrandStyle = projectBrandStyles.get(brandStyleId);
    const isForcedTargetProjectBrandStyle = projectBrandStyle?.get('forced', false) && isReusableLayoutDocument(layoutDocument);

    if (brandStyleChanged && !isForcedTargetProjectBrandStyle) {
      const layoutBrandStyle = layoutBrandstyles.get(brandStyleId);


      if (!projectBrandStyle || !adaptStyles) {
        return;
      }

      const layoutBrandStyleProps = getTextStylesFromBrandStyle(layoutBrandStyle, layoutColors, layoutFonts);
      const projectBrandStyleProps = getTextStylesFromBrandStyle(projectBrandStyle, colors, fonts);

      const stylesToMergeFromBrandStyles =
        getStylesToMergeFromBrandStyles(
          textComponent,
          styles,
          layoutBrandStyleProps,
          projectBrandStyleProps,
          layoutColors,
        );

      const adoptedStylesFromBrandStyles = _.flow(
        editor.convertTextComponentToRawEditorState,
        editorState => editor.toggleBrandStyle(editorState, projectBrandStyleProps, colors, fonts),
        editorState => editor.isolateEditorStateStyles(editorState),
      )(textComponent);

      if (stylesToMergeFromBrandStyles.length > 0) {
        stylesToMergeFromBrandStyles.forEach((styleName) => {
          // TODO: need to revise bulletColor in editorState;
          if (styleName === Models.TextBrandStyleField.BULLET_COLOR) {
            _.forEach(stubRelation.styles[styleName], (item) => {
              item.style = getBulletColorStyle(projectBrandStyleProps.bulletColor);
            });
          } else if (styleName === Models.TextBrandStyleField.IS_AUTOFIT_CONTENT) {
            // is autofit content always false if exists as override
            stubRelation.styles[styleName] = false;
          } else {
            stubRelation.styles[styleName] = adoptedStylesFromBrandStyles[styleName];
          }
        });
      }

      applyOverridenPadding(
        toJS(projectBrandStyleProps.padding),
        toJS(layoutBrandStyleProps.padding),
        stubRelation,
      );

    } else {
      const brandStyle = brandStyles.get(brandStyleId);
      applyBrandStyle(textComponent, stubRelation, brandStyle, colors, fonts);
    }

    relation.styles = stubRelation.styles;
  });

  return args;
}
