import { DragSourceCollector, DragSourceSpec, DropTargetCollector, DropTargetSpec } from 'react-dnd';

import { DragSourceType } from 'const';
import * as Models from 'models';
import { ArtboardGroupLayoutDragObject } from 'models/DragObjects/ArtboardGroupLayoutDragObject';
import { GroupLayoutOwnProps, GroupLayoutProps, GroupLayoutSourceCollectedProps, GroupLayoutTargetCollectedProps } from './models';

export const allowedToDropItems: DragSourceType[] = [
  DragSourceType.GROUP_LAYOUT_REUSABLE_LAYOUT,
  DragSourceType.ARTBOARD_REUSABLE_LAYOUT,
  DragSourceType.REUSABLE_LAYOUT,
];

export const groupLayoutDragSource: DragSourceSpec<GroupLayoutProps, ArtboardGroupLayoutDragObject> = {
  beginDrag(props) {
    const { layout, toggleDragState } = props;
    toggleDragState(DragSourceType.ARTBOARD_GROUP_LAYOUT);

    return { layout };
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

export const sourceCollector: DragSourceCollector<GroupLayoutSourceCollectedProps, GroupLayoutOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

export const groupLayoutDropTarget: DropTargetSpec<GroupLayoutProps> = {
  drop(props, monitor) {
    const { moveLayout, section, dropReusableLayout } = props;
    const itemType = monitor.getItemType() as DragSourceType;
    const sectionId = section.get('id');

    switch (itemType) {
      case DragSourceType.GROUP_LAYOUT_REUSABLE_LAYOUT:
      case DragSourceType.ARTBOARD_REUSABLE_LAYOUT: {
        const { layout } = monitor.getItem() as Models.ArtboardLayoutDragObject;
        moveLayout(layout.get('id'), sectionId);
        break;
      }
      case DragSourceType.REUSABLE_LAYOUT: {
        const { document, storyCardId } = monitor.getItem() as Models.LayoutDragObject;
        dropReusableLayout(document.get('id'), sectionId, storyCardId);
        break;
      }
    }
  },
  canDrop(props, monitor) {
    const { isLayoutBeingUploaded } = props;
    const itemType = monitor.getItemType() as DragSourceType;

    return !isLayoutBeingUploaded && allowedToDropItems.includes(itemType);
  },
};

export const targetCollector: DropTargetCollector<GroupLayoutTargetCollectedProps, GroupLayoutOwnProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    dragItemType: monitor.getItemType() as DragSourceType,
  };
};
