import Immutable from 'immutable';
import { call, put, select } from 'redux-saga/effects';

import { addDocuments } from 'containers/Documents/actions';
import { textComponentsForAssetsPanel as textComponentsSelector } from 'containers/Documents/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { replaceReferenceCitationWithinComponent } from 'utils/referenceCitation';

export function* replaceReferenceCitationWithinComponents(componentIds: Immutable.List<string>, oldReferenceId: string, newReferenceId: string) {
  try {
    const textComponents: ReturnTypeSaga<typeof textComponentsSelector> = yield select(textComponentsSelector);
    const textComponentsToUpdate = textComponents.filter(
      textComponent => componentIds.includes(textComponent.get('id')) && textComponent.get('referenceCitations').includes(oldReferenceId),
    );

    const updatedComponents = textComponentsToUpdate.map(text => replaceReferenceCitationWithinComponent(text, oldReferenceId, newReferenceId));

    // TODO: just return results instead of updating store
    yield put(addDocuments(updatedComponents as Models.TextComponentsMap));
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.replaceReferenceCitationsWithinComponents', 'ReplaceReferenceCitations');
  }
}
