import { takeEvery } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { getProjectDetails } from './getProjectDetails';
import { setActiveLayer } from './setActiveLayer';
import { uploadToPromoMats } from './uploadToPromoMats';

export default [
  takeEvery(ActionTypes.GET_PROJECT_DETAILS, getProjectDetails),
  takeEvery(ActionTypes.SET_ACTIVE_LAYER, setActiveLayer),
  takeEvery(ActionTypes.UPLOAD_TO_PROMOMATS, uploadToPromoMats),
];
