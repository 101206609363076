import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const AltTag = (): JSX.Element => {
  const maskId = guid();
  const pathId = guid();

  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" >
      <defs>
        <path id={pathId} d="M23,5 L7,5 C5.9,5 5.01,5.9 5.01,7 L5,25 L9,21 L23,21 C24.1,21 25,20.1 25,19 L25,7 C25,5.9 24.1,5 23,5 Z M11,17 L9,17 L9,15 L11,15 L11,17 Z M11,14 L9,14 L9,12 L11,12 L11,14 Z M11,11 L9,11 L9,9 L11,9 L11,11 Z M18,17 L13,17 L13,15 L18,15 L18,17 Z M21,14 L13,14 L13,12 L21,12 L21,14 Z M21,11 L13,11 L13,9 L21,9 L21,11 Z" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-5.000000, -5.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`#${pathId}`} />
          </mask>
          <use fill="#000000" fillRule="nonzero" xlinkHref={`#${pathId}`} />
          <g mask={`url(#${maskId})`} fill="#FFFFFF" fillRule="evenodd">
            <g transform="translate(2.000000, 2.000000)" >
              <rect x="0" y="0" width="26" height="25" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AltTag;
