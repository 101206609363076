import { useSelector } from 'react-redux';

import store from 'store/store';
import { Layer } from 'const';
import { activeLayer as activeLayerSelector } from 'containers/Project/selectors';

export const useActiveLayer = (): Layer => {
  return useSelector(activeLayerSelector);
};

export const getActiveLayer = (): Layer => {
  return activeLayerSelector(store.getState()); // immediate get value from the store
};
