import classNames from 'classnames';
import React from 'react';
import { DropTarget } from 'react-dnd';
import ArtboardLayoutContainerList from 'components/ArtboardLayoutContainerList';
import HeightExceedingIndicator from 'components/HeightExceedingIndicator';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import LayoutDragHotspot from 'components/LayoutDragHotspot';
import SSIDragHotspot from 'components/SSIDragHotspot';
import Toolbar from 'components/Toolbar/Section';
import Tooltip from 'components/Tooltip';
import { ProjectsConfig } from 'const';
import * as Models from 'models';
import { isMockDocument } from 'utils/documents';
import { intlGet } from 'utils/intlGet';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { isFixedSection } from 'utils/screenDefinitions/isFixedSection';
import { toPx } from 'utils/toPx';
import { allowedToDropItems, collect, sectionDropTarget } from './dnd';
import { useSection } from './hooks';
import { SectionCollectedProps, SectionOwnProps, SectionProps } from './models';
import styles from './styles.module.scss';
import { useStyles } from './useStyles';

const Section: React.FunctionComponent<SectionProps> = (props) => {
  const {
    activeLayoutIds,
    artboardLayoutIds,
    connectDropTarget,
    documentIdsAreBeingUploaded,
    documents,
    dragHotspotGroupLayoutId,
    dragHotspotPosition,
    getLayoutsRefs,
    isOver,
    layouts,
    moveableLayoutId,
    relationsByRegularLayoutId,
    saveLayoutRef,
    section,
    ssiHotspotPosition,
    toggleDragState,
    updateSection,
    projectType,
    colors,
    allLayouts,
  } = props;

  const {
    sectionRef,
    fullContentRef,
    editMode,
    sectionHintRef,
    enableEditMode,
    canExpand,
    isMouseOver,
    onMouseLeave,
    onMouseEnter,
    onMouseOver,
    isEditMode,
    isExpand,
    toggleExpand,
    isTooltipShown,
    showTooltip,
    hideTooltip,
    isDraggingSSI,
    isDraggingLayout,
    isHighlighted,
    getSSIDragHotspotPosition,
    getLayoutDragHotspotPosition,
    saveAsGroupLayout,
  } = useSection(props);

  const [sectionStyles, sectionStylesSetters, sectionCSS] = useStyles(
    section as unknown as Models.SectionMap,
    { colors, images: documents },
    ProjectsConfig[projectType],
    editMode,
    updateSection,
  );

  const onDragMove = ({ clientX, clientY }: React.MouseEvent) => {
    if (!isOver) {
      return;
    }

    getLayoutDragHotspotPosition(clientY);
    getSSIDragHotspotPosition(clientX, clientY);
  };

  const renderContent = (): JSX.Element => {
    return (
      <ArtboardLayoutContainerList
        documents={documents}
        getLayoutsRefs={getLayoutsRefs}
        isSectionActive={isMouseOver}
        layoutIds={artboardLayoutIds}
        layouts={layouts}
        moveableLayoutId={moveableLayoutId}
        relationsByRegularLayoutId={relationsByRegularLayoutId}
        saveLayoutRef={saveLayoutRef}
        section={section}
        ssi={null}
        toggleDragState={toggleDragState}
      />
    );
  };

  const renderLayoutDragHotspot = (): JSX.Element => {
    return isOver && isDraggingLayout() && !dragHotspotGroupLayoutId && <LayoutDragHotspot position={dragHotspotPosition} />;
  };

  const renderSSIDragHotspot = (): JSX.Element => {
    return isOver && isDraggingSSI() && ssiHotspotPosition && <SSIDragHotspot position={ssiHotspotPosition} />;
  };

  const renderSectionHint = (): JSX.Element => {
    const sectionName = section.get('displayName');
    const iconType = isExpand ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN;

    const groupLayout = layouts.size === 1 && isGroupLayout(layouts.first()) ? layouts.first() as Models.GroupLayoutMap : null;
    const groupLayoutDocument = groupLayout && documents.get(groupLayout.get('documentId'));
    const layoutsToCheck = groupLayout ? groupLayout.get('layoutIds').map(layoutId => allLayouts.get(layoutId)) : layouts;
    const isSaveEnabled = (
      layoutsToCheck.every(layout => isReusableLayout(layout, documents) && !documentIdsAreBeingUploaded.includes(layout.get('documentId')))
      && (!groupLayoutDocument || isMockDocument(groupLayoutDocument))
    );

    return isHighlighted() && (
      <div className={styles.hintWrapper} ref={sectionHintRef}>
        <div className={styles.hint}>
          <span className={styles.hintTitle}>{sectionName}</span>
          <Icon className={styles.actionIcon} type={IconType.SETTINGS} size="badge" color="grey" onClick={enableEditMode} />
          <Icon
            className={styles.actionIcon}
            type={IconType.SAVE_LAYOUT}
            size="badge"
            color="grey"
            isDisabled={!isSaveEnabled}
            onClick={saveAsGroupLayout}
          />
          {
            canExpand &&
            <>
              <span className={classNames(styles.warning, styles.actionIcon)}>
                <Icon type={IconType.EXCLAMATION_MARK} size="badge" color="error" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} />
                <Tooltip className={styles.tooltip} show={isTooltipShown} text={intlGet('Section', 'Warning')} />
              </span>
              <Icon className={styles.actionIcon} type={iconType} size="xs" color="grey" onClick={toggleExpand} />
            </>
          }
        </div>
        {editMode && <Toolbar
          name={sectionName}
          styles={sectionStyles}
          stylesSetters={sectionStylesSetters}
          colors={colors}
        />}
      </div>
    );
  };

  const renderFlexibleSection = (): JSX.Element => connectDropTarget((
    <div
      className={classNames(styles.Section, { [styles.Section__highlighted]: isHighlighted() })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onDragOver={onDragMove}
      style={sectionCSS}
    >
      <div className={styles.content}>
        {renderLayoutDragHotspot()}
        {renderContent()}
        {renderSectionHint()}
      </div>
    </div>
  ));

  const renderFixedSection = (): JSX.Element => {
    const { connectDropTarget, sectionHeight } = props;

    return connectDropTarget((
      <div
        className={classNames(styles.Section, {
          [styles.Section__highlighted]: isHighlighted(),
          [styles.mouseOver]: isMouseOver,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onDragOver={onDragMove}
        ref={sectionRef}
        style={{
          height: isExpand ? 'auto' : toPx(sectionHeight),
          ...sectionCSS,
        }}
      >
        {renderSSIDragHotspot()}
        {renderSectionHint()}
        <div
          className={classNames(styles.visibleContent, {
            [styles.visibleContent__full]: isExpand || !canExpand,
            [styles.increased]: activeLayoutIds.size !== 0,
          })}
        >
          <div
            ref={fullContentRef}
            className={styles.fullContent}
            onDoubleClick={isEditMode}
            style={{ minHeight: toPx(sectionHeight) }}
          >
            {renderLayoutDragHotspot()}
            {renderContent()}
            <HeightExceedingIndicator
              offsetTop={sectionHeight}
              isShown={isExpand}
            />
          </div>
        </div>
      </div>
    ));
  };

  if (isFixedSection(section)) {
    return renderFixedSection();
  }

  return renderFlexibleSection();
};

export default DropTarget<SectionOwnProps, SectionCollectedProps>(
  allowedToDropItems,
  sectionDropTarget,
  collect,
)(Section);
