import _ from 'lodash';

import { MIN_SCREEN_FORMAT_HEIGHT, MIN_SCREEN_FORMAT_WIDTH, ScreenFormatType } from 'const';
import * as Models from 'models';

export function isCustomScreenFormatType(screenFormatType: ScreenFormatType): screenFormatType is ScreenFormatType.CUSTOM {
  return screenFormatType === ScreenFormatType.CUSTOM;
}

export function isCustomScreenFormat(screenFormat: Models.ScreenFormat): screenFormat is Models.ScreenFormat<ScreenFormatType.CUSTOM> {
  return isCustomScreenFormatType(screenFormat.type);
}

export function isScreenFormatValid(format: Models.ScreenFormat): format is Models.ScreenFormat {
  return (
    format &&
    _.includes(ScreenFormatType, format.type) &&
    format.height >= MIN_SCREEN_FORMAT_HEIGHT &&
    format.width >= MIN_SCREEN_FORMAT_WIDTH
  );
}
