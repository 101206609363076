import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';

export function getFontFaceFromBrandStyle(brandStyles: Models.BrandStyleObjMap, brandFonts: Models.BrandFontsList): string {
  let resultFontFamily = brandStyles.get(Models.BrandStyleProp.FONT_FAMILY);

  if (resultFontFamily.startsWith(Constants.EXTERNAL_REF_KEY)) {
    const [fontFamilyId, charStyleId] = resultFontFamily.split(Constants.ID_START_KEY)[1].split(Constants.ID_SEPARATOR_KEY);

    resultFontFamily = BrandDefinition.getCSSFontFamilyFromBrandFont(fontFamilyId, charStyleId, brandFonts);
  }

  return resultFontFamily;
}
