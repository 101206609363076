import * as Models from 'models';

export const removeAbsenceRelations = (relations: Models.LayeredRelations): Models.LayeredRelations => {
  return Object.entries(relations).reduce((acc, currRelation) => {
    const [id, value] = currRelation;
    if (!(value as unknown as Models.RowRelation)?.relationIds) {
      return { ...acc, ...{
        [id]: {
          ...value,
        },
      } };
    }

    const relationIds = (value as unknown as Models.RowRelation).relationIds.filter(item => relations?.[item]);
    const updated = {
      [id]: {
        ...value,
        relationIds,
      },
    };

    return {
      ...acc,
      ...updated,
    };
  }, {});
};
