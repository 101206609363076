import { CellActionRequest, CellActionsDispatcher } from './types';

const apiForCellActions: Record<string, CellActionsDispatcher> = {};

export function registerCellActionsAPI(relationId: string, runner: CellActionsDispatcher): void {
  apiForCellActions[relationId] = runner;
}

export function unregisterCellActionsAPI(relationId: string): void {
  delete apiForCellActions[relationId];
}

export function dispatchCellActions(relationId: string, ...args: CellActionRequest[]): void {
  const runner = apiForCellActions[relationId];
  if (!runner) {
    // eslint-disable-next-line no-console
    console.error(`api for ${relationId} is not registered`);
  } else {
    runner(...args);
  }
}
