import { takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { addCell } from './addCell';
import { addLayoutColumn } from './addLayoutColumn';
import { addLayoutRequest } from './addLayoutRequest';
import { addLayoutRow } from './addLayoutRow';
import { copyLayout } from './copyLayout';
import { deleteCell } from './deleteCell';
import { deleteLayoutColumn } from './deleteLayoutColumn';
import { deleteLayoutRequest } from './deleteLayoutRequest';
import { dropGroupReusableLayout } from './dropGroupReusableLayout';
import { dropReusableLayout } from './dropReusableLayout';
import { handleReusableLayoutsEditing } from './handleReusableLayoutsEditing';
import { moveLayout } from './moveLayout';
import { resetLayoutHeightSilently } from './resetLayoutHeightSilently';
import { setLayoutAndInstances } from './setLayoutAndInstances';
import { splitCell } from './splitCell';

export default [
  takeEvery('*', handleReusableLayoutsEditing),
  takeLatest(ActionTypes.ADD_CELL, addCell),
  takeLatest(ActionTypes.ADD_LAYOUT_COLUMN, addLayoutColumn),
  takeLatest(ActionTypes.ADD_LAYOUT_REQUEST, addLayoutRequest),
  takeLatest(ActionTypes.ADD_LAYOUT_ROW, addLayoutRow),
  takeLatest(ActionTypes.COPY_LAYOUT, copyLayout),
  takeLatest(ActionTypes.DELETE_CELL, deleteCell),
  takeLatest(ActionTypes.DELETE_LAYOUT_COLUMN, deleteLayoutColumn),
  takeLatest(ActionTypes.DELETE_LAYOUT_REQUEST, deleteLayoutRequest),
  takeLatest(ActionTypes.DROP_GROUP_REUSABLE_LAYOUT, dropGroupReusableLayout),
  takeLatest(ActionTypes.DROP_REUSABLE_LAYOUT, dropReusableLayout),
  takeLatest(ActionTypes.MOVE_LAYOUT, moveLayout),
  takeLatest(ActionTypes.RESET_LAYOUT_HEIGHT_SILENTLY, resetLayoutHeightSilently),
  takeLatest(ActionTypes.SET_LAYOUT_AND_INSTANCES, setLayoutAndInstances),
  takeLatest(ActionTypes.SPLIT_CELL, splitCell),
];
