import { DefaultCustomStyle } from 'const';
import { DefaultFontFamily } from 'services/ArtboardConverter/constants';
import { Schemas } from 'services/ArtboardConverter/models';
import { toPx } from 'utils/toPx';

export const createFont = ({
  fontFamily = DefaultFontFamily,
  fontSize = toPx(DefaultCustomStyle.FONT_SIZE),
  color = DefaultCustomStyle.FONT_COLOR as string,
} = {} as Partial<Schemas.Font>): Schemas.Font => ({
  fontFamily,
  fontSize,
  color,
});
