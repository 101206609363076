import * as Immutable from 'immutable';

import { ProjectType, ProjectsConfig } from 'const';
import { ReferenceCitationMap } from 'models';
import { REFERENCE_CITATION_INDENT } from 'services/ArtboardConverter/constants';
import { getReferenceHtmlAsString } from './getReferenceHtmlAsString';

export function getReferencesAsString(referencesOrder: Immutable.List<ReferenceCitationMap>, projectType: ProjectType): string {
  const { useBoldNumberingInReferences, wrapReferenceInList } = ProjectsConfig[projectType];
  const separator = wrapReferenceInList ? '' : ' ';

  const references = referencesOrder && referencesOrder.map(
    (reference, index) => {
      const referenceHtmlAsString = getReferenceHtmlAsString(
        index + 1,
        reference.get('text'),
        useBoldNumberingInReferences,
        !wrapReferenceInList,
      );

      return wrapReferenceInList
        ? `<li style="margin-left: ${REFERENCE_CITATION_INDENT}">${referenceHtmlAsString}</li>`
        : referenceHtmlAsString;
    },
  ).join(separator);

  return (references && wrapReferenceInList) ? `<ol>${references}</ol>` : references;
}
