import React from 'react';
import guid from 'uuid';
/* eslint-disable max-len */

const SSI = (): JSX.Element => {
  const polygonId = guid();
  const maskId = guid();

  return (
    <svg viewBox="0 0 96 96">
      <defs>
        <polygon id={polygonId} points="0 0 48 0 48 48 0 48" />
      </defs>
      <g id="AssetsPanel/Component/Elements/SSI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="24px-(1)" transform="translate(24.000000, 24.000000)">
          <mask id={maskId} fill="white">
            <use xlinkHref={`"#${polygonId}"`} />
          </mask>
          <g id="a" />
          <path d="M40,7.5 C42.4123695,7.5 44.3778231,9.37601382 44.4949866,11.7873036 L44.5,12 L44.5,36 C44.5,38.4123695 42.6239862,40.3778231 40.2126964,40.4949866 L40,40.5 L8,40.5 C5.58763054,40.5 3.62217693,38.6239862 3.5050134,36.2126964 L3.50000017,35.9995833 L3.52,12 C3.52,9.58474257 5.38012155,7.62204222 7.78735428,7.50501347 L8,7.5 L40,7.5 Z M19.5,18.5 L13.3333333,18.5 C12.8901181,18.5 12.5,18.9388829 12.5,19.5 L12.5,24 C12.5,24.5611171 12.8901181,25 13.3333333,25 L17.8333333,25 L17.8333333,27.5 L12.5,27.5 L12.5,29.5 L18.6666667,29.5 C19.1098819,29.5 19.5,29.0611171 19.5,28.5 L19.5,24 C19.5,23.4388829 19.1098819,23 18.6666667,23 L14.1666667,23 L14.1666667,20.5 L19.5,20.5 L19.5,18.5 Z M29.5,18.5 L23.3333333,18.5 C22.8901181,18.5 22.5,18.9388829 22.5,19.5 L22.5,24 C22.5,24.5611171 22.8901181,25 23.3333333,25 L27.8333333,25 L27.8333333,27.5 L22.5,27.5 L22.5,29.5 L28.6666667,29.5 C29.1098819,29.5 29.5,29.0611171 29.5,28.5 L29.5,24 C29.5,23.4388829 29.1098819,23 28.6666667,23 L24.1666667,23 L24.1666667,20.5 L29.5,20.5 L29.5,18.5 Z M35.5,18.5 L32.5,18.5 L32.5,29.5 L35.5,29.5 L35.5,18.5 Z" id="Combined-Shape" stroke="#E0E0E0" fillRule="nonzero" mask={`"url(#${maskId})"`} />
          <g id="Group" mask={`"url(#${maskId})"`} fill="#5B5B5A" fillRule="nonzero">
            <g transform="translate(12.000000, 18.000000)">
              <path d="M5.33333333,12 L6.66666667,12 C7.4,12 8,11.325 8,10.5 L8,6 C8,5.175 7.4,4.5 6.66666667,4.5 L2.66666667,4.5 L2.66666667,3 L8,3 L8,-5.32907052e-15 L5.33333333,-5.32907052e-15 L2.66666667,-5.32907052e-15 L1.33333333,-5.32907052e-15 C0.6,-5.32907052e-15 0,0.675 0,1.5 L0,6 C0,6.825 0.6,7.5 1.33333333,7.5 L5.33333333,7.5 L5.33333333,9 L0,9 L0,12 L2.66666667,12 L5.33333333,12 Z" id="Path" />
              <path d="M15.3333333,12 L16.6666667,12 C17.4,12 18,11.325 18,10.5 L18,6 C18,5.175 17.4,4.5 16.6666667,4.5 L12.6666667,4.5 L12.6666667,3 L18,3 L18,-5.32907052e-15 L15.3333333,-5.32907052e-15 L12.6666667,-5.32907052e-15 L11.3333333,-5.32907052e-15 C10.6,-5.32907052e-15 10,0.675 10,1.5 L10,6 C10,6.825 10.6,7.5 11.3333333,7.5 L15.3333333,7.5 L15.3333333,9 L10,9 L10,12 L12.6666667,12 L15.3333333,12 Z" id="Path" />
              <rect id="Rectangle" x="20" y="-5.32907052e-15" width="4" height="12" />
            </g>
          </g>
        </g>
      </g>
    </svg >
  );
};

export default SSI;
