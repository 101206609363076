import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import { activeSurfaceId, surfaceIds } from 'containers/Project/selectors';
import { ScreenMap } from 'models';
import * as Models from './models';

export const surfaces = (state): Models.State.IState => state.get('surfaces');

export const activeSurface = createSelector(
  [surfaces, activeSurfaceId],
  (surfaces, activeSurfaceId) => surfaces.get(activeSurfaceId),
);

export const orderedSurfaces = createSelector(
  [surfaceIds, surfaces],
  (surfaceIds, surfaces) => {
    const surfacesCollection = surfaceIds.toArray().map(id => [id, surfaces.get(id)] as [string, ScreenMap]);

    return Immutable.OrderedMap(surfacesCollection);
  },
);

export const surfaceNames = createSelector(
  [surfaces],
  surfaces => surfaces.toList().map(surface => surface.get('name')),
);

export const thumbnailUrls = createSelector(
  orderedSurfaces, surfaces => surfaces.valueSeq().map(surface => (surface as ScreenMap).getIn(['thumbnail', 'url'])).filter(Boolean),
);
