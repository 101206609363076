import { connect } from 'react-redux';

import WelcomeScreen from 'components/WelcomeScreen';
import { getProjectData } from './actions';

const mapDispatchToProps = {
  getProjectData,
};

export default connect(null, mapDispatchToProps)(WelcomeScreen);
