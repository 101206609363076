import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import ColorPicker from 'components/Toolbar/Dropdowns/ColorPicker';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { getBackgroundColor } from 'utils/styles';
import styles from './styles.module.scss';

type Props = {
  color: Models.BrandColorMap | undefined;
  defaultColor: string;
  toggleFontColor(color: Models.BrandColorMap): void;
  controlState?: Models.ControlState;
};

const FontColorControl: React.FunctionComponent<Props> = (props) => {
  const { color, defaultColor, toggleFontColor, controlState = ControlState } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      ref={control}
      size="sm"
      state={state}
      title={Title.FONT_COLOR}
      type={IconType.TOOLBAR_FONT_COLOR}
    >
      <div className={styles.FontColor} style={getBackgroundColor(color, defaultColor)} />
      {isOpen && <ColorPicker
        activeColor={color}
        toggleColor={toggleFontColor}
        title={intlGet('EditorToolbar.TextColorDropdown', 'Title')}
      />}
    </Control>
  );
};

export default FontColorControl;
