import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { AssetActionsProps } from './models';
import styles from './styles.module.scss';

const AssetActions: React.FunctionComponent<AssetActionsProps> = (props) => {
  const { onDelete, onPreviewOpen, assetClickable = false } = props;

  return (
    <div className={classNames(styles.AssetActions, { [styles.clickable]: assetClickable })}>
      <div className={styles.icons}>
        {
          !!onPreviewOpen &&
          <Icon type={IconType.VIEW_DETAILS} size="sm" color="secondary" className={styles.viewDetails} onClick={onPreviewOpen} />
        }
        {
          !!onDelete &&
          <Icon type={IconType.DELETE_BIN} size="badge" color="secondary" className={styles.deleteBin} onClick={onDelete} />
        }
      </div>
    </div>
  );
};

export default AssetActions;
