import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { StoryCardDragPreviewProps } from './models';
import styles from './styles.module.scss';

const StoryCardDragPreview: React.FunctionComponent<StoryCardDragPreviewProps> = ({ screenName, previewUrl }) => {
  if (!screenName) {
    return (
      <div className={styles.StoryCardDragPreviewIcon}>
        <Icon type={IconType.STORY_CARD} size="md" color="primary" />
      </div>
    );
  }

  return (
    <div className={styles.StoryCardDragPreviewScreen}>
      <div
        className={classNames(styles.preview, previewUrl ? styles.thumbnail : styles.placeholder)}
        style={{ backgroundImage: previewUrl ? `url(${previewUrl})` : 'none' }}
      >
        {!previewUrl && <Icon type={IconType.IMAGE} size="sm-md" color="grey" />}
      </div>
      <div className={styles.info}>{screenName}</div>
    </div>
  );
};

export default StoryCardDragPreview;
