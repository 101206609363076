import { Padding, PaddingMap } from 'models';
import { isImmutable, toJS } from 'utils/immutable';
import { boxPropertyToString } from './boxPropertyToString';

export function getPadding(
  padding: PaddingMap | Padding | undefined,
): Pick<React.CSSProperties, 'padding'> {
  if (!padding) {
    return {};
  }

  return { padding: boxPropertyToString(isImmutable(padding) ? toJS(padding) : padding) };
}
