import Immutable from 'immutable';

import { ProjectPanelTab } from 'const';
import * as Models from 'models';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const projectPanelInitialState: State.IState = Immutable.Map({
  activeSections: Immutable.Set(),
  activeTab: ProjectPanelTab.ASSETS,
  assetIdToScroll: null as string,
  contentFilter: Immutable.List<string>(),
  selectedCitation: Immutable.Map() as Models.ReferenceCitationMap,
  selectedTextComponent: Immutable.Map() as Models.TextComponentMap,
});

const projectPanelReducer: Models.Reducer<State.IState> =
  (state = projectPanelInitialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_SELECTED_CITATION:
        return setSelectedCitation(state, action);
      case ActionTypes.SET_SELECTED_TEXT_COMPONENT:
        return setSelectedTextComponent(state, action);
      case ActionTypes.SET_CONTENT_FILTER:
        return setContentFilter(state, action);
      case ActionTypes.DELETE_SELECTED_CITATION:
        return deleteSelectedCitation(state, action);
      case ActionTypes.SET_ASSET_ID_TO_SCROLL:
        return setAssetIdToScroll(state, action);
      case ActionTypes.SET_ACTIVE_TAB:
        return setActiveTab(state, action);
      case ActionTypes.ADD_ACTIVE_SECTION:
        return addActiveSection(state, action);
      case ActionTypes.TOGGLE_SECTION:
        return toggleSection(state, action);
      default:
        return state;
    }
  };

const setSelectedCitation: Models.Reducer<State.IState, Action.ISetSelectedCitation> = (state, action) => (
  state.set('selectedCitation', Immutable.fromJS(action.payload.citation))
);

const setSelectedTextComponent: Models.Reducer<State.IState, Action.ISetSelectedTextComponent> = (state, action) => (
  state.set('selectedTextComponent', Immutable.fromJS(action.payload.textComponent))
);

const setContentFilter: Models.Reducer<State.IState, Action.ISetContentFilter> = (state, action) => {
  return state.set('contentFilter', Immutable.List(action.payload.contentFilter));
};

const deleteSelectedCitation: Models.Reducer<State.IState, Action.ISetSelectedCitation> = state => (
  state.set('selectedCitation', null)
);

const setAssetIdToScroll: Models.Reducer<State.IState, Action.ISetAssetIdToScroll> = (state, action) => (
  state.set('assetIdToScroll', action.payload.assetIdToScroll)
);

const setActiveTab: Models.Reducer<State.IState, Action.ISetActiveTab> = (state, action) => {
  return state.set('activeTab', action.payload.activeTab);
};

const addActiveSection: Models.Reducer<State.IState, Action.AddActiveSection> = (state, action) => {
  return state.update('activeSections', activeSections => activeSections.add(action.payload.section));
};

const toggleSection: Models.Reducer<State.IState, Action.ToggleSection> = (state, action) => {
  const { section } = action.payload;

  return state.update('activeSections', sections => sections.has(section) ? sections.remove(section) : sections.add(section));
};

export default projectPanelReducer;
