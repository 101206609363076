import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SaveGroupLayout from 'components/ModalWindows/SaveGroupLayout';
import {
  SaveGroupLayoutActions,
  SaveGroupLayoutOwnProps,
  SaveGroupLayoutSelectors,
} from 'components/ModalWindows/SaveGroupLayout/models';
import { updateAppState } from 'containers/App/actions';
import { tempLayoutPreview } from 'containers/App/selectors';
import { groupLayoutsNames } from 'containers/Documents/selectors';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';
import { cancelLayoutPreviewGeneration, generateLayoutPreview } from 'containers/ModalWindows/SaveReusableLayout/actions';
import { saveGroupLayout } from './actions';

const mapStateToProps = (state, props: SaveGroupLayoutOwnProps) => createStructuredSelector<{}, SaveGroupLayoutSelectors>({
  options: modalWindowOptions(props.modalWindowId),
  layoutPreview: tempLayoutPreview,
  groupLayoutsNames,
});

const mapDispatchToProps = {
  cancelLayoutPreviewGeneration,
  generateLayoutPreview,
  hideModal,
  saveGroupLayout,
  updateAppState,
};

export default connect<SaveGroupLayoutSelectors, SaveGroupLayoutActions, SaveGroupLayoutOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SaveGroupLayout);
