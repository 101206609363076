export class ContentHeightCache {
  private static instance: ContentHeightCache;

  private readonly data: Record<string, number>;

  constructor() {
    this.data = {} as Record<string, number>;
  }

  public static getInstance(): ContentHeightCache {
    if (!ContentHeightCache.instance) {
      ContentHeightCache.instance = new ContentHeightCache();
    }

    return ContentHeightCache.instance;
  }

  public getItem(relationId: string): number {
    return this.data[relationId];
  }

  public setItem(relationId: string, height: number): void {
    this.data[relationId] = height;
  }
}
