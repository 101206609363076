import _ from 'lodash';

import { getLayoutsThumbnails } from 'containers/Documents/actions';
import * as Models from 'models';
import { isNotMockDocument } from 'utils/documents';
import { isCombinedLayoutDocument } from 'utils/entityType';

type GetActionsToLayoutPreviewsLoadingArgs = Models.GetProjectDataProcessArgs | Models.RefreshProcessArgs | Models.SyncProcessArgs;

export function getActionsToLayoutPreviewsLoading<T extends GetActionsToLayoutPreviewsLoadingArgs>(arg: T): void {
  const layoutDocumentsToUpdate = _(arg.assets.documents)
    .pickBy(isCombinedLayoutDocument)
    .pickBy(isNotMockDocument)
    .value();

  if (!_.isEmpty(layoutDocumentsToUpdate)) {
    arg.actionsWithMiddleware.push(getLayoutsThumbnails(layoutDocumentsToUpdate as Models.CombinedLayoutDocuments));
  }
}
