import * as Models from 'models';
import { isRegularRelation } from './isRegularRelation';

export function getParentRelation(
  relationId: string,
  relations: Models.RelationsMap | Models.LayeredRelationsMap,
) {
  return relations.find((relation) => {
    if (isRegularRelation(relation)) {
      return false;
    }

    return relation.get('relationIds').indexOf(relationId) !== -1;
  }) as Models.RowRelationMap | Models.ColumnRelationMap;
}
