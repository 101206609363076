import _ from 'lodash';

/**
 *  NOTE: Arrays are not merged recursively, they are overridden.
 */
export function mergeObjectsDeep<T, S>(target: T, source: S): T & S;
export function mergeObjectsDeep<T, S, S1>(target: T, source: S, source1: S1): T & S & S1;
export function mergeObjectsDeep<T, S>(target: T, ...sources: S[]): T & S {
  return _.mergeWith(target, ...sources, (trgValue, srcValue) => {
    if (_.isArray(trgValue)) {
      return srcValue;
    }
  });
}
