import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

import { TOOLBAR_ELEMENT_ID } from 'const';
import { BaseToolbarProps } from './models';
import styles from './styles.module.scss';

const BaseToolbar: React.FunctionComponent<PropsWithChildren<BaseToolbarProps>> = (props) => {
  const { children, className, layoutId, title, setLastEditedLayoutId, updateProjectState, renderViaPortal = true } = props;
  const toolbarClassName = classNames(styles.EditorToolbar, className);

  React.useEffect(
    () => {
      updateProjectState({ isOpenToolbar: true });

      return () => {
        updateProjectState({ isOpenToolbar: false });
        setLastEditedLayoutId(layoutId);
      };
    },
    [],
  );

  const toolbar = (
    <div className={toolbarClassName}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );

  return renderViaPortal
    ? ReactDOM.createPortal(toolbar, document.getElementById(TOOLBAR_ELEMENT_ID))
    : toolbar;
};

BaseToolbar.defaultProps = {
  className: '',
  title: '',
};

export default BaseToolbar;
