import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';

import RadioButtonList from 'components/ModalWindows/Shared/RadioButtonList';
import { DocumentSavingStatus, EntityType } from 'const';
import { intlGet } from 'utils/intlGet';
import { IDocumentSavingProps, LabelTexts } from './models';
import styles from './styles.module.scss';

const TextByDocumentSavingStatus: PartialRecord<DocumentSavingStatus, LabelTexts> = {
  [DocumentSavingStatus.CREATE]: {
    text: () => intlGet('DocumentSavingWindow.Option', 'CreateNew'),
  },
  [DocumentSavingStatus.EDIT]: {
    text: () => intlGet('DocumentSavingWindow.Option', 'ApplyChanges'),
  },
};

const TextByEntityType: PartialRecord<EntityType, LabelTexts> = {
  [EntityType.REFERENCE_CITATION]: {
    text: () => intlGet('DocumentSavingWindow', 'ReferenceCitation'),
  },
  [EntityType.IMAGE]: {
    text: () => intlGet('DocumentSavingWindow', 'ImageComponent'),
  },
};

const DocumentSaving: React.FunctionComponent<IDocumentSavingProps> = (props) => {
  const { hideModal, modalWindowId, options } = props;
  const entityTypeText = TextByEntityType[options.get('entityType')].text();

  const [selectedSaveStatus, setSelectedSaveStatus] = React.useState(DocumentSavingStatus.CREATE);

  const saveChanges: React.MouseEventHandler = () => {
    hideModal(modalWindowId, selectedSaveStatus);
  };

  const cancelChange: React.MouseEventHandler = () => {
    hideModal(modalWindowId, DocumentSavingStatus.CANCEL);
  };

  const getText = (status: DocumentSavingStatus): JSX.Element => {
    const { text } = TextByDocumentSavingStatus[status];

    return <div className={classNames(styles.formCheckLabel, { [styles.disabled]: isDisabled(status) })}>{text()}</div>;
  };

  const isDisabled = (documentSavingStatus: DocumentSavingStatus): boolean => {
    return documentSavingStatus === DocumentSavingStatus.EDIT
      ? !options.get('allowUpdateExisting')
      : false;
  };

  return (
    <div className={styles.DocumentSaving}>
      <header>{intlGet('DocumentSavingWindow', 'Title', { entityTypeText })}</header>
      <section>
        <RadioButtonList<DocumentSavingStatus>
          id={modalWindowId}
          options={[DocumentSavingStatus.CREATE, DocumentSavingStatus.EDIT]}
          onOptionChange={setSelectedSaveStatus}
          activeOption={selectedSaveStatus}
          isDisabled={isDisabled}
          getOptionLabel={getText}
        />
      </section>
      <footer>
        <Button onClick={cancelChange} variant="secondary">{intlGet('DocumentSavingWindow.Button', 'Cancel')}</Button>
        <Button onClick={saveChanges} variant="primary">{intlGet('DocumentSavingWindow.Button', 'Save')}</Button>
      </footer>
    </div>
  );
};

export default DocumentSaving;
