import React from 'react';
/* eslint-disable max-len */

const PredefinedScreenFormat = (): JSX.Element => (
  <svg viewBox="0 0 26 20">
    <path d="M22.8,0 L2.53333333,0 C1.14,0 0.0126666667,1.14 0.0126666667,2.53333333 L0,17.7333333 C0,19.1266667 1.14,20.2666667 2.53333333,20.2666667 L22.8,20.2666667 C24.1933333,20.2666667 25.3333333,19.1266667 25.3333333,17.7333333 L25.3333333,2.53333333 C25.3333333,1.14 24.1933333,0 22.8,0 Z M16.4666667,17.7333333 L2.53333333,17.7333333 L2.53333333,12.6666667 L16.4666667,12.6666667 L16.4666667,17.7333333 Z M16.4666667,11.4 L2.53333333,11.4 L2.53333333,6.33333333 L16.4666667,6.33333333 L16.4666667,11.4 Z M22.8,17.7333333 L17.7333333,17.7333333 L17.7333333,6.33333333 L22.8,6.33333333 L22.8,17.7333333 Z" />
  </svg>
);

export default PredefinedScreenFormat;
