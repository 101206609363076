import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LayoutDetails from 'components/ModalWindows/LayoutDetails';
import { LayoutDetailsActions, LayoutDetailsOwnProps, LayoutDetailsSelectors } from 'components/ModalWindows/LayoutDetails/models';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (_, props: LayoutDetailsOwnProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect<LayoutDetailsSelectors, LayoutDetailsActions, LayoutDetailsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutDetails);
