import { call } from 'redux-saga/effects';

import * as Models from 'models';
import * as processes from 'services/processes';
import { sagaFlow } from 'utils/sagaFlow';
import { getProjectAssets } from './getProjectAssets';

export function* processRefreshedAssets(
  refreshedData: Models.RefreshResponseData,
  isProjectTranslatable: boolean,
  projectAssets?: Models.ProjectAssetsToUpload,
): Generator<unknown, Models.RefreshProcessArgs> {
  const projectPrevAssets =
    projectAssets ||
    (yield call(getProjectAssets, { removeInternalInfo: false })) as ReturnTypeSaga<typeof getProjectAssets> as Models.ProjectAssetsToUpload;

  const prevAssets: Models.RefreshProcessArgs['prevAssets'] = {
    documents: projectPrevAssets.documents,
  };

  const assets: Models.RefreshProcessArgs['assets'] = {
    ...projectPrevAssets,
    documents: refreshedData.documents,
    rootDocument: refreshedData.rootDocument,
    masterScreenData: refreshedData.masterScreenData,
  };

  const dataToProcess: Models.RefreshProcessArgs = {
    assets,
    prevAssets,
    notificationOptions: [],
    actionsWithMiddleware: [],
    newDocumentsQuantity: null,
    documentStatusMap: refreshedData.documentStatusMap,
    isProjectTranslatable,
  };

  const refreshedDataProcesses = sagaFlow<Models.RefreshProcessArgs>(
    processes.getNewDocumentsQuantity,
    processes.resetRelationsWithoutDocument,
    processes.removeUnusedReferences,
    processes.processBrandDefinition,
    processes.processScreenDefinitions,
    processes.validateSSIElements,
    processes.normalizeLayeredDocuments,
    processes.extendReusableLayoutBrandDefinitions,
    processes.refreshLayoutsOnArtboards,
    processes.resetLayoutsWithoutDocument,
    processes.processBackgroundImages,
    processes.getActionsToStoryCardThumbnailsLoading,
    processes.getActionsToLayoutPreviewsLoading,
    processes.getActionsToImagesLoading,
  );

  const processedData: ReturnTypeSaga<typeof refreshedDataProcesses> = yield call(refreshedDataProcesses, dataToProcess);

  return processedData;
}
