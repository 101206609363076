
import * as Immutable from 'immutable';

import { AlignmentValue, AssetAlignmentDirection } from 'const';
import * as Models from 'models';
import { createAlignment } from 'services/ArtboardConverter/factories';
import { Schemas } from 'services/ArtboardConverter/models';

export function getAlignment(alignmentMap: Models.AssetAlignmentMap = Immutable.Map() as Models.AssetAlignmentMap): Schemas.Alignment {
  const vertical = alignmentMap.get(AssetAlignmentDirection.VERTICAL);
  const horizontal = alignmentMap.get(AssetAlignmentDirection.HORIZONTAL);

  return createAlignment({
    valign: AlignmentValue[vertical],
    align: AlignmentValue[horizontal],
  });
}
