import { CellAction, dispatchCellActions } from 'context/CellActions';

export function changeTextAbbreviation(
  relationId: string,
  abbreviationId: string,
  abbreviationNumber: number,
): void {
  dispatchCellActions(
    relationId,
    [CellAction.TOGGLE_EDITMODE_ON, true],
    [CellAction.SET_CURSOR_ON_ABBREVIATION, abbreviationId, abbreviationNumber],
    CellAction.OPEN_ABBREVIATIONS_PANEL,
    [CellAction.CHANGE_ABBREVIATION, abbreviationId],
  );
}

export function changeImageAbbreviation(
  relationId: string,
  abbreviationId: string,
): void {
  dispatchCellActions(
    relationId,
    [CellAction.TOGGLE_EDITMODE_ON, true],
    CellAction.OPEN_ABBREVIATIONS_PANEL,
    [CellAction.CHANGE_ABBREVIATION, abbreviationId],
  );
}

export function removeTextAbbreviation(
  relationId: string,
  abbreviationId: string,
  abbreviationNumber: number,
): void {
  dispatchCellActions(
    relationId,
    [CellAction.TOGGLE_EDITMODE_ON, false],
    [CellAction.SET_CURSOR_ON_ABBREVIATION, abbreviationId, abbreviationNumber],
    [CellAction.REMOVE_ABBREVIATION, abbreviationId],
    CellAction.TOGGLE_EDITMODE_OFF,
  );
}

export function removeImageAbbreviation(
  relationId: string,
  abbreviationId: string,
): void {
  dispatchCellActions(
    relationId,
    [CellAction.TOGGLE_EDITMODE_ON, false],
    [CellAction.REMOVE_ABBREVIATION, abbreviationId],
    CellAction.TOGGLE_EDITMODE_OFF,
  );
}
