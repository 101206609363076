import _ from 'lodash';

import * as Models from 'models';

interface SSIElementSourceData {
  sourceScreen: Models.Screen;
  sourceSection: Models.Section;
}

export function getSSIElementSourceData(
  ssiSource: Models.SSISource,
  screens: Models.Screens,
  artboards: Models.Artboards,
  layouts: Models.LayeredCombinedLayouts,
  sections: Models.Sections,
): SSIElementSourceData {
  const { section: sourceSectionName, screen: sourceScreenId } = ssiSource || {} as Models.SSISource;
  const sourceScreen = _.find(screens, screen => sourceScreenId === screen.id);
  const { artboardId: sourceArtboardId } = sourceScreen || {} as Models.Screen;
  const sourceLayoutIds = _.get(artboards, [sourceArtboardId, 'layoutIds'], [] as string[]);
  const sourceLayouts = _.filter(layouts, (layout => sourceLayoutIds.includes(layout.id)));
  const sourceSection = _(sourceLayouts)
    .map(layout => layout.section)
    .map(sectionId => sections[sectionId])
    .find(section => section.name === sourceSectionName);

  return {
    sourceScreen,
    sourceSection,
  };
}
