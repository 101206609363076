
import * as Constants from 'const';
import * as Models from 'models';
import { getDesktopBrandStyles } from 'utils/brandStyles';
import { getDefaultColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import * as editorUtils from 'utils/editor';

// replaces Models.BrandColor
export type BrandColorLike = {
  name?: string; // name property may be empty for old style documents
  HEX: string;
  tint?: number;
};

export function getDefaultBrandColor(
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
): BrandColorLike {
  const defaultBrandStyleColor = getDefaultColorFromBrandStyle(
    colors,
    getDesktopBrandStyles(brandStyle),
  );

  let brandColor: Models.BrandColorMap | undefined;
  if (defaultBrandStyleColor?.startsWith(Constants.EXTERNAL_REF_KEY)) {
    const brandColorId = defaultBrandStyleColor.split(Constants.ID_START_KEY)[1];
    brandColor = colors.find(color => color?.get('id') === brandColorId);
  } else {
    brandColor = editorUtils.getBrandColor(colors, defaultBrandStyleColor);
  }

  if (brandColor) {
    return {
      name: brandColor.get('name'),
      HEX: brandColor.get('HEX'),
      tint: brandColor.get('tint'),
    };
  }

  return {
    HEX: defaultBrandStyleColor,
    tint: 0,
  };
}

export function getCSSColorFromBrandColor(
  brandColor: BrandColorLike,
  colors: Models.BrandColorsList,
): string {
  const { name, HEX, tint } = brandColor;
  const color = editorUtils.getBrandColor(colors, name ? name : HEX, tint); // NOSONAR

  return color ? color.get('HEX') : HEX;
}
