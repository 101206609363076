import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select, spawn } from 'redux-saga/effects';
import guid from 'uuid';

import { DocumentsSource, MethodName, ProcessType } from 'const';
import { lockProjectContent, unlockProjectContent } from 'containers/App/actions';
import { deleteDocuments } from 'containers/Documents/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { deleteUploadingDocumentId } from 'containers/Project/actions';
import { currentCountry, currentLanguage, currentProduct } from 'containers/Project/selectors';
import { rootDocumentId as rootDocumentIdSelector } from 'containers/RootDocument/selectors';
import { referenceCitationFactory } from 'factories/document/referenceCitationFactory';
import * as Models from 'models';
import { uploadReferenceCitation } from 'services/api';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { isReferenceCitation } from 'utils/entityType';
import { generateReferenceCitationDocumentName } from 'utils/names';
import { Action } from '../models';
import { getDocumentDuplicates } from '../services/getDocumentDuplicates';
import { replaceDocumentWithDuplicate } from '../services/replaceDocumentWithDuplicate';
import { uploadDocument } from '../services/uploadDocument';

export function* addReferenceCitation({ payload }: Action.IAddReferenceCitation) {
  let uploadingDocumentId = guid();
  let modalWindowId: string;

  const logId = logger.performanceStart();
  const methodName = MethodName.CREATE_REFERENCE_CITATION;

  try {
    yield put(lockProjectContent(ProcessType.DUPLICATES_CHECKING));

    const { text: referenceCitationText } = payload;
    ({ modalWindowId } = payload);
    const rootDocumentId: ReturnTypeSaga<typeof rootDocumentIdSelector> = yield select(rootDocumentIdSelector);
    const language = (yield select(currentLanguage)).toJS() as string[];
    const country = (yield select(currentCountry)).toJS() as string[];
    const product = (yield select(currentProduct)).toJS() as string[];

    const { documentDuplicates, documentLinksByDocumentNumber }: ReturnTypeSaga<typeof getDocumentDuplicates> = yield call(
      getDocumentDuplicates,
      referenceCitationText,
      _.first(language),
      _.first(country),
    );
    const referenceCitationDuplicates = _.filter((documentDuplicates as Models.ReferenceCitation[]), isReferenceCitation);

    if (!Array.isArray(referenceCitationDuplicates) || referenceCitationDuplicates.length === 0) {
      yield put(batchActions([
        unlockProjectContent(),
        hideModal(modalWindowId),
      ]));

      const newReferenceCitation: ReturnTypeSaga<typeof referenceCitationFactory> = yield call(
        referenceCitationFactory,
        {
          id: uploadingDocumentId,
          name: generateReferenceCitationDocumentName(referenceCitationText),
          text: referenceCitationText,
          documentSource: [DocumentsSource.COMPONENTS],
        },
      );

      const uploadNewDocument = async (): Promise<Models.ReferenceCitation> => {
        const { data: createdDocument } = await uploadReferenceCitation(rootDocumentId, referenceCitationText, { language, country, product });

        return createdDocument;
      };

      yield* uploadDocument(newReferenceCitation, uploadNewDocument, modalWindowId);
    } else {
      uploadingDocumentId = yield* replaceDocumentWithDuplicate(
        uploadingDocumentId,
        referenceCitationDuplicates,
        documentLinksByDocumentNumber,
        modalWindowId,
        referenceCitationText,
      );
    }

    yield spawn([logger, logger.performanceEnd], logId, { methodName });

    return uploadingDocumentId;
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'Documents.addReferenceCitation', 'AddReferenceCitation');
    yield put(deleteDocuments([uploadingDocumentId]));
  } finally {
    yield put(batchActions([
      deleteUploadingDocumentId(uploadingDocumentId),
      unlockProjectContent(),
      hideModal(modalWindowId),
    ]));
  }
}
