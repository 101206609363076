import { BlockMap, ContentBlock, ContentState, EditorState, SelectionState } from 'draft-js';
import Immutable from 'immutable';
import _ from 'lodash';
import { DraftEntity } from 'const';
import { BlockEntityMap } from 'models';

/**
 * Removes empty selection of last block.
 * Triple-click can lead to a selection that includes 0-offset of following block.
 * The `SelectionState` for this case is accurate, but interaction with trailing block should be avoided
 * in some use-cases (like toggling block type) as it is a confusing behavior.
 * Note: this method changes selection direction.
 */
export const trimEnd = (selectionState: SelectionState, contentState: ContentState): SelectionState => {
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();

  if (startKey !== endKey && selectionState.getEndOffset() === 0) {
    const blockBefore = contentState.getBlockBefore(endKey);

    return selectionState.merge({
      anchorKey: startKey,
      anchorOffset: selectionState.getStartOffset(),
      focusKey: blockBefore.getKey(),
      focusOffset: blockBefore.getLength(),
      isBackward: false,
    }) as SelectionState;
  }

  return selectionState;
};

/**
 * Removes empty selection of first block.
 * Note: this method changes selection direction.
 */
export const trimStart = (selectionState: SelectionState, contentState: ContentState): SelectionState => {
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const startBlock = contentState.getBlockForKey(startKey);

  if (startKey !== endKey && selectionState.getStartOffset() === startBlock.getLength()) {
    const blockAfter = contentState.getBlockAfter(startKey);

    return selectionState.merge({
      anchorKey: blockAfter.getKey(),
      anchorOffset: 0,
      focusKey: endKey,
      focusOffset: selectionState.getEndOffset(),
      isBackward: false,
    }) as SelectionState;
  }

  return selectionState;
};

export const trimSelection = (editorState: EditorState): SelectionState => {
  const contentState = editorState.getCurrentContent();
  const trimSelectionStart = (selectionState: SelectionState): SelectionState => trimStart(selectionState, contentState);
  const trimSelectionEnd = (selectionState: SelectionState): SelectionState => trimEnd(selectionState, contentState);

  return _.flow(trimSelectionStart, trimSelectionEnd)(editorState.getSelection());
};

/**
 * Update SelectionState to select all text through all blocks in current EditorState
 */
export function setFullSelection(editorState: Draft.EditorState): Draft.EditorState {
  const resultEditorState = EditorState.moveSelectionToEnd(editorState);
  const firstBlockKey = resultEditorState.getCurrentContent().getFirstBlock().getKey();
  const selection = resultEditorState.getSelection().merge({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
  });

  return EditorState.set(resultEditorState, { selection });
}

/**
 * Returns `Immutable.OrderedMap` of selected blocks.
 * @param {SelectionState} selectionState - selection state
 * @param {ContentState} contentState     - content state
 * @returns {BlockMap}                    - ordered map of selected blocks
 */
export const getSelectedBlocks = (selectionState: SelectionState, contentState: ContentState): BlockMap => {
  const blockMap = contentState.getBlockMap();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();

  return blockMap
    .skipUntil((_, key) => key === startKey)
    .takeUntil((_, key) => key === endKey)
    .toOrderedMap()
    .set(endKey, blockMap.get(endKey));
};

/**
 * Extends selection to the range:
 * - from beginning of first selected block (selection start)
 * - to end of last selected block (selection end)
 */
export const selectBlocks = (editorState: EditorState): SelectionState => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const isBackward = selection.getIsBackward();
  const endBlock = selection.getEndKey();
  const endBlockLength = content.getBlockForKey(endBlock).getLength();

  return selection.merge({
    anchorOffset: isBackward ? endBlockLength : 0,
    focusOffset: isBackward ? 0 : endBlockLength,
  }) as SelectionState;
};

export const splitBlockToEntities = (block: ContentBlock): Immutable.List<BlockEntityMap> =>
  block.getCharacterList()
    .map(character => character.getEntity())
    .reduce(
      (offsets, entity, idx, blockEntities) => {
        if (idx === 0 || entity !== blockEntities.get(idx - 1)) {
          return offsets.push(idx);
        }

        return offsets;
      },
      Immutable.List<number>(),
    )
    .map((startOffset, idx, entityOffsets) => {
      const nextEntityOffset = entityOffsets.get(idx + 1);
      const endOffset = nextEntityOffset ? nextEntityOffset : block.getLength();

      return Immutable.Map({
        startOffset,
        endOffset,
        key: block.getEntityAt(startOffset),
        type: null,
        block: block.getKey(),
      }) as BlockEntityMap;
    });

/**
 * Return `Immutable.List` of selected entities.
 */
export const getSelectedEntities = (selectionState: SelectionState, contentState: ContentState): Immutable.List<BlockEntityMap> =>
  getSelectedBlocks(selectionState, contentState)
    .map((block: ContentBlock) => {
      const blockKey = block.getKey();
      const startOffset = blockKey === selectionState.getStartKey() ? selectionState.getStartOffset() : 0;
      const endOffset = blockKey === selectionState.getEndKey() ? selectionState.getEndOffset() : block.getLength();

      const entities = splitBlockToEntities(block)
        .skipUntil(entity => entity.get('endOffset') > startOffset)
        .takeWhile(entity => entity.get('startOffset') < endOffset)
        .map((entity) => {
          const key = entity.get('key');
          const type = key && contentState.getEntity(key).getType();

          return entity.set('type', type);
        });

      // if there are no entities on the edges, cut them to selection offsets
      return entities.size === 0
        ? entities
        : entities
          .update(0, entity => entity.get('type') ? entity : entity.set('startOffset', startOffset))
          .update(entities.size - 1, entity => entity.get('type') ? entity : entity.set('endOffset', endOffset));
    })
    .reduce(
      (entitiesList, blockEntities) => entitiesList.concat(blockEntities),
      Immutable.List(),
    );

/**
 * Method check that all characters under selection have same entity type without any gaps.
 * TRUE: ['NoWrap, NoWrap, NoWrap, NoWrap, NoWrap, NoWrap']
 * FALSE: ['NoWrap, NoWrap, 'Link', 'Link', NoWrap, NoWrap']
 * FALSE: ['NoWrap, null, NoWrap, null, NoWrap, NoWrap']
 * @param editorState Draft EditorState
 * @param entityType Entity type string to compare within all text selection
 */
export function isSameTypeEntitySelected(editorState: Draft.EditorState, entityType: DraftEntity): boolean {
  const contentState = editorState.getCurrentContent();
  const selectedChars = getSelectedCharsMeta(editorState);

  if (!selectedChars.size) {
    return false;
  }

  return selectedChars.every((char) => {
    const entityKey = char.getEntity();

    return !!entityKey && contentState.getEntity(entityKey).getType() === entityType;
  });
}

/**
 * Move selection focus `range` backward within the selected block.
 * If the selection goes beyond the start of the block, move focus to the end of previous block.
 */
export const moveSelectionBackward = (selection: SelectionState, content: ContentState, range: number): SelectionState => {
  const blockKey = selection.getStartKey();
  const offset = selection.getStartOffset();

  if (range > offset) {
    const blockBefore = content.getBlockBefore(blockKey);
    const blockToSelect = blockBefore ? blockBefore.getKey() : blockKey;

    return selection.merge({
      focusKey: blockToSelect,
      focusOffset: blockBefore ? content.getBlockForKey(blockToSelect).getLength() : 0,
      isBackward: true,
    }) as SelectionState;
  }

  return selection.merge({
    focusOffset: offset - range,
    isBackward: true,
  }) as SelectionState;
};

/**
 * Move selection focus `range` forward within the selected block.
 * If the selection goes beyond the end of the block, move focus to the start of next block.
 */
export const moveSelectionForward = (selection: SelectionState, content: ContentState, range: number): SelectionState => {
  const blockKey = selection.getEndKey();
  const offset = selection.getEndOffset();
  const block = content.getBlockForKey(blockKey);
  const blockTextLength = block.getLength();

  if (range > blockTextLength - offset) {
    const blockAfter = content.getBlockAfter(blockKey);
    const blockToSelect = blockAfter ? blockAfter.getKey() : blockKey;

    return selection.merge({
      focusKey: blockToSelect,
      focusOffset: blockAfter ? 0 : blockTextLength,
      isBackward: false,
    }) as SelectionState;
  }

  return selection.merge({
    focusOffset: offset + range,
    isBackward: false,
  }) as SelectionState;
};

export function getSelectedCharsMeta(editorState: Draft.EditorState): Immutable.List<Draft.CharacterMetadata> {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const selectedBlocks = getSelectedBlocks(selectionState, contentState);
  let result = Immutable.List<Draft.CharacterMetadata>();

  if (selectedBlocks.size === 1) {
    result = selectedBlocks.first().getCharacterList()
      .slice(selectionState.getStartOffset(), selectionState.getEndOffset()) as unknown as Immutable.List<Draft.CharacterMetadata>;
  } else {
    result = selectedBlocks.reduce(
      (
        selectedChars: Immutable.List<Draft.CharacterMetadata>,
        block: ContentBlock,
      ): Immutable.List<Draft.CharacterMetadata> => {
        if (block.getKey() === selectionState.getStartKey()) {
          return selectedChars
            .concat(block.getCharacterList().slice(selectionState.getStartOffset())) as Immutable.List<Draft.CharacterMetadata>;
        }
        if (block.getKey() === selectionState.getEndKey()) {
          return selectedChars
            .concat(block.getCharacterList().slice(0, selectionState.getEndOffset())) as Immutable.List<Draft.CharacterMetadata>;
        }

        return selectedChars.concat(block.getCharacterList()) as Immutable.List<Draft.CharacterMetadata>;
      },
      Immutable.List<Draft.CharacterMetadata>(),
    );
  }

  return result;
}

export function applySelection(
  editorState: Draft.EditorState,
  blockKey: string,
  start: number,
  end: number,
): Draft.EditorState {
  const selection = SelectionState.createEmpty(blockKey)
    .set('anchorOffset', start)
    .set('focusOffset', end);

  return EditorState.forceSelection(editorState, selection as SelectionState);
}
