import * as Mousetrap from 'mousetrap';
import 'mousetrap-global-bind';

import { downloadArtboardsJson } from 'containers/Artboards/actions';
import { isDOMElementEditable } from 'utils/isDOMElementEditable';

// import * as appActions from 'containers/App/actions';

const callbacks = {
/*  ['mod+a']: appActions.selectAll,
  ['mod+c']: appActions.copy,
  ['mod+v']: appActions.paste,
  ['mod+x']: appActions.cut,
  ['mod+z']: appActions.undo,
  ['del']: appActions.remove,
  ['backspace']: appActions.remove,*/
  ['mod+alt+.']: downloadArtboardsJson,
};

const hotkeys = Object.keys(callbacks);

const init = (emitter) => {
  Mousetrap.bindGlobal(hotkeys, (event, hotkey) => {
    if (!isDOMElementEditable(document.activeElement)) {
      event.preventDefault();

      emitter(callbacks[hotkey]());
    }
  });
};

const reset = () => {
  (Mousetrap as any).unbindGlobal(hotkeys);
};

export const Hotkeys = {
  init,
  reset,
};
