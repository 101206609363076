import _ from 'lodash';
import raf from 'raf';
import React from 'react';

import Resizer from 'components/Resizer';
import { ResizerDirection } from 'const';
import { getActiveLayer } from 'hooks/useActiveLayer';
import * as Models from 'models';
import { getMaxRowHeight } from 'utils/relations/getMaxHeight';
import { getMinRowHeight } from 'utils/relations/getMinRowHeight';
import { recalculateRowsHeight } from 'utils/rowsHeight';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import { getParent } from 'utils/rowsHeight/getParent';
import { RowResizerProps } from './models';

function getHintMessage(height: number, neighborHeight: number): string {
  let message = '';

  if (height) {
    message += `${height} px`;
  }

  if (neighborHeight) {
    message += ` / ${neighborHeight} px`;
  }

  return message;
}

const RowResizer: React.FunctionComponent<RowResizerProps> = (props) => {
  const {
    areActionsDisabled,
    container,
    finalizeRelations,
    height,
    layoutId,
    relationIsBeingResizedId,
    relations,
    scale,
    setIsResizingRow,
    setLastEditedLayoutId,
    saveAppState,
    toggleRelations,
  } = props;

  const [finalRelations, setFinalRelations] = React.useState<Models.LayeredRelationsMap>();

  const activeLayer = getActiveLayer();
  const minHeight = getMinRowHeight(relationIsBeingResizedId, relations, activeLayer);
  const maxHeight = getMaxRowHeight(relationIsBeingResizedId, relations, activeLayer);

  let neighborHeight: number = null;
  const { parent, position } = getParent(relationIsBeingResizedId, relations);

  if (areThereHorizontalNeighbors(parent.get('id'), relations)) {
    neighborHeight = parent.getIn(['styles', 'rowsHeight', position + 1], null);
  }

  const onResizeStart = (): void => {
    saveAppState();
    setIsResizingRow(true);
  };

  const onResize = ({ clientY }: { clientY: number }) => raf(() => {
    if (!height || !container.current) {
      return;
    }

    const { bottom } = container.current.getBoundingClientRect();
    const delta = _.round(bottom - clientY);

    const newHeight = _.round(_.clamp(height - delta / scale, minHeight, maxHeight));
    const verifiedDelta = height - newHeight;

    const calculateFinalRelations = recalculateRowsHeight(
      relationIsBeingResizedId,
      relations,
      verifiedDelta,
      activeLayer,
      true,
      false,
      true,
    );

    setFinalRelations(calculateFinalRelations);
    toggleRelations(calculateFinalRelations);
  });

  const onResizeStop = (): void => {
    setLastEditedLayoutId(layoutId);
    finalizeRelations(finalRelations || relations);
    setIsResizingRow(false);
  };

  return (
    <Resizer
      allowVisibilityChange={!areActionsDisabled}
      scale={scale}
      direction={ResizerDirection.HORIZONTAL}
      hintMessage={getHintMessage(height, neighborHeight)}
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
    />
  );
};

export default RowResizer;
