import _ from 'lodash';
import { all, call, put } from 'redux-saga/effects';

import { RenditionType } from 'const';
import { updateAppState } from 'containers/App/actions';
import { IAction, ImageInternalInfo } from 'models';
import { ImageCache } from 'services/imageCache';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import { updateInternalImageInfoRequest as updateImageInfo } from '../actions';
import { ActionTypes } from '../constants';
import * as Models from '../models';
import { updateImagesPreviews } from '../services/updateImagesPreviews';
import { updateInternalImageInfoRequest as updateImage } from './updateInternalImageInfoRequest';

export function* downloadImages(action: Models.Action.IDownloadImages) {
  let closeNotification: ReturnTypeSaga<typeof Notifications.success>;

  try {
    const { documents, options = {} } = action.payload;
    const { showNotification = false } = options;

    const actions: IAction[] = [];
    const imageCache = ImageCache.getInstance();
    const documentsToProcess = _.pickBy(documents, (document) => {
      const { id, renditions, _internalInfo } = document;
      const checksum = _.get(renditions, [RenditionType.LOW, 'checksum']);
      const cachedData = imageCache.getItem(RenditionType.LOW, checksum) as ImageInternalInfo;
      if (cachedData) {
        actions.push(call(
          updateImage,
          {
            type: ActionTypes.UPDATE_INTERNAL_IMAGE_INFO_REQUEST,
            payload: { id, internalImageInfo: cachedData },
          },
        ));
      }

      return !cachedData || !_internalInfo;
    });

    yield all(actions);

    if (_.isEmpty(documentsToProcess)) {
      return;
    }

    showNotification && (closeNotification = yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.DownloadImages', 'Message'),
      intlGet('Notification.DownloadImages', 'Title'),
      { autoClose: false },
    ));

    yield put(updateAppState({ areImagesBeingDownloaded: true }));

    yield call(updateImagesPreviews, _.values(documentsToProcess), (id: string, imageInfo: ImageInternalInfo) => updateImageInfo(id, imageInfo));
  } finally {
    closeNotification && (yield call(closeNotification));
    yield put(updateAppState({ areImagesBeingDownloaded: false }));
  }
}
