import classNames from 'classnames';
import React from 'react';

import { ListProps } from './models';
import styles from './styles.module.scss';

const List: React.FunctionComponent<ListProps> = (props) => {
  const {
    elements,
    renderElement,
    subheader,
    layout = 'list',
    border = true,
  } = props;

  return (
    <div
      className={classNames(styles.List, {
        [styles.border]: border,
        [styles.grid]: layout === 'grid',
      })}
    >
      {subheader}
      {elements && elements.valueSeq().map(renderElement)}
    </div>
  );
};

export default List;
