import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ITextComponentItemDragPreviewProps } from './models';
import styles from './styles.module.scss';

const TextComponentItemDragPreview: React.FunctionComponent<ITextComponentItemDragPreviewProps> = () => {
  return (
    <div className={styles.TextComponentItemDragPreview}>
      <Icon type={IconType.TEXT} size="sm-md" color="primary" />
    </div>
  );
};

export default TextComponentItemDragPreview;
