import Immutable from 'immutable';
import _ from 'lodash';

import { sum } from './sum';

// TODO: use more common name
/**
 * Delete column from columnsWidth list and returns list of proportionally updated columns widths
 * E.g. `deleteColumn([100, 340, 220], 2)` will return `[150, 510]`.
 *
 * @param {Immutable.List<number>} columnsWidth    - columnsWidth to update
 * @param {number} columnIndex                     - index of column to delete
 */
export const deleteColumn = (columnsWidth: Immutable.List<number>, columnIndex: number): Immutable.List<number> => {
  const availableColumnsWidthSum = sum(columnsWidth);
  const deletedColumnWidth = columnsWidth.get(columnIndex);
  const remainingColumnsWidth = columnsWidth.delete(columnIndex);
  let calculatedColumnsWidthSum = 0;

  return remainingColumnsWidth.map((width, index) => {
    const newColumnWidth = _.round(width + width / sum(remainingColumnsWidth) * deletedColumnWidth);

    if (index === remainingColumnsWidth.size - 1 || calculatedColumnsWidthSum + newColumnWidth > availableColumnsWidthSum) {
      return _.round(availableColumnsWidthSum - calculatedColumnsWidthSum);
    }

    calculatedColumnsWidthSum += newColumnWidth;

    return newColumnWidth;
  });
};
