/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-require-imports */
import { Store } from 'redux';

import { isProduction } from 'utils/isProduction';

const configureStore: () => Store<{}> = isProduction()
  ? require('./configureStore.production').default
  : require('./configureStore.development').default;

export default configureStore();
