import { call } from 'redux-saga/effects';

export interface ReturnTypeSagaFlow<T> {
  (arg: T): Generator<unknown, T>;
}

type ReturnTypeSagaFlowFunc<T> = (arg: T) => void | Promise<void> | T | Promise<T> | Generator<unknown, T> | Generator<unknown, void>;

/**
 * Creates pipeline like _.flow but for generators and async functions as well.
 * Passes previous value if function returns undefined
 * @param funcs function to invoke
 */
export function sagaFlow<T>(...funcs: ReturnTypeSagaFlowFunc<T>[]): ReturnTypeSagaFlow<T> {
  return function* (initialArg) {
    let arg = initialArg;

    for (const func of funcs) {
      const returnArg = yield call(func, arg);
      // if there was no mutation in a function and there is no return value
      arg = returnArg || arg;
    }

    return arg;
  };
}
