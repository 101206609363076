import { Styles } from 'const';
import { BrandStylesMap, MasterScreen } from 'models';

export const isBrandStyleAvailable = (brandStyles: BrandStylesMap, sectionStyles: MasterScreen.SectionStylesMap, brandStyleId: string): boolean => {
  return (sectionStyles.size === 0 || sectionStyles.includes(brandStyleId)) && brandStyles.has(brandStyleId);
};

export const isDefaultBrandStyleAvailable = (brandStyles: BrandStylesMap, sectionStyles: MasterScreen.SectionStylesMap): boolean => {
  return isBrandStyleAvailable(brandStyles, sectionStyles, Styles.DefaultTextBrandStyle);
};
