import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect } from 'react';

import { AppRoute } from 'const';
import { isInIframe } from 'utils/isInIframe';
import { isUnsupportedBrowser } from 'utils/isUnsupportedBrowser';
import { setStorageItems } from 'utils/setStorageItems';
import { WelcomeScreenProps } from './models';

const WelcomeScreen: React.FunctionComponent<WelcomeScreenProps> = (props) => {
  const { getProjectData, history, location: { search } } = props;

  useEffect(
    () => {
      async function openProject(): Promise<void> {
        const query = queryString.parse(search);
        const { userId, documentId } = query;

        const sessionId = query.sessionId || await new Promise((resolve) => {
          const MAX_MESSAGES = 3;
          let messageCount = 0;

          function parseEvent({ data }: MessageEvent): void {
            if (!_.isString(data)) {
              return;
            }

            try {
              const { message_id: messageId, data: messageData } = JSON.parse(data);

              if (messageId === 'session_id') {
                resolve(messageData.session_id);
                window.removeEventListener('message', parseEvent);
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log('Error occurred during parsing a message: ', error);
            } finally {
              if (messageCount++ > MAX_MESSAGES) {
                window.removeEventListener('message', parseEvent);
              }
            }
          }

          // https://developer.veevavault.com/docs/#sending-session-ids-with-post-message
          window.addEventListener('message', parseEvent);
          window.parent.postMessage(JSON.stringify({ message_id: 'ready', data: {} }), '*');
        });

        const projectPath = `/${AppRoute.PROJECT}/${documentId}`;

        if (isUnsupportedBrowser()) {
          history.replace(AppRoute.UNSUPPORTED_BROWSER);
        } else if (sessionId && userId && documentId) {
          const storageItems = {
            'pm-sessionId': sessionId as string,
            'pm-userId': userId as string,
          };

          setStorageItems(sessionStorage, storageItems);
          if (isInIframe()) {
            history.replace(AppRoute.IFRAME_PLACEHOLDER);

            const assemblerWindow = window.open(projectPath, '_blank');
            // some browsers don't inherit session storage items from iframe for some reason
            setStorageItems(assemblerWindow.sessionStorage, storageItems);
          } else {
            getProjectData(Number(documentId));
            history.replace(projectPath);
          }
        } else {
          history.replace(AppRoute.NO_MATCH);
        }
      }

      openProject();
    },
    [],
  );

  return null;
};

export default WelcomeScreen;
