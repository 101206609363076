import _ from 'lodash';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';

import * as Models from 'models';
import { EmittedObject, RunEventChanelOptions, SubscribeFunction } from './models';

/**
 * Creates event channel, which goes through collection and emits events simultaneously for every element
 * @param collection - array of any elements
 * @param subscribe - callback function, which is invoked for every element in the collection
 */
const createEventChannel = <T, E>(
  collection: E[],
  subscribe: SubscribeFunction<T, E>,
): EventChannel<EmittedObject<T>> => (
  eventChannel((emitter) => {
    if (collection.length === 0) {
      // we should close the channel in case we have no documents
      // but we can do it only after the channel has been created
      queueMicrotask(() => emitter({ done: true }));
    }

    const isDone = (): boolean => responseCounter === collection.length;
    // we use response counter to know when we will receive all responses
    let responseCounter = 1;

    collection.forEach(async (element) => {
      try {
        await subscribe(emitter, isDone, element);
      } catch (error) {
        emitter({ done: isDone() });
      } finally {
        responseCounter++;
      }
    });

    return () => { };
  })
);

const runEventChanelDefaultOptions: RunEventChanelOptions = {
  needToStop: _.stubFalse,
};

export function* runEventChanel<A extends Models.IAction, E>(
  collection: E[],
  subscribeFn: SubscribeFunction<A, E>,
  options?: RunEventChanelOptions,
): Generator<unknown, void> {
  const channel: ReturnTypeSaga<typeof createEventChannel> = yield call(createEventChannel, collection, subscribeFn);
  const { needToStop } = _.defaults(options, runEventChanelDefaultOptions);

  while (true) {
    const { action, done }: EmittedObject<A> = yield take(channel);

    if (action) {
      yield put(action);
    }

    if (done || (yield call(needToStop))) {
      yield call([channel, channel.close]);

      break;
    }
  }
}
