import React from 'react';
/* eslint-disable max-len */

const Unlink = (): JSX.Element => (
  <svg viewBox="0 0 12 12">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M4.75999991,1.5666667 L4.75999991,0.173333327 C4.22666657,0.313333331 3.72666657,0.533333338 3.27333323,0.813333347 L4.24666657,1.78666671 C4.41333324,1.70666671 4.57999991,1.62666671 4.75999991,1.5666667 Z M2.96059469e-16,0.94 L1.57333331,2.51333333 C1.05999999,3.32666667 0.75999999,4.29333333 0.75999999,5.33333333 C0.75999999,6.80666667 1.36666665,8.13333333 2.3333333,9.09333333 L0.75999999,10.6666667 L4.75999994,10.6666667 L4.75999994,6.66666667 L3.26666662,8.16 C2.54666663,7.43333333 2.09333331,6.44 2.09333331,5.33333333 C2.09333331,4.66666667 2.25999997,4.04 2.54666663,3.48666667 L7.93333323,8.87333333 C7.76666656,8.96 7.5999999,9.04 7.4199999,9.1 L7.4199999,10.4933333 C7.95333323,10.3533333 8.45333322,10.1333333 8.90666655,9.85333333 L10.4799999,11.4266667 L11.3266665,10.58 L0.853333322,0.0933333337 L2.96059469e-16,0.94 Z M11.4266669,0 L7.4266669,0 L7.4266669,3.99999985 L8.92000023,2.50666657 C9.64000023,3.23333321 10.0933336,4.22666651 10.0933336,5.33333313 C10.0933336,5.99999978 9.9266669,6.62666642 9.64000023,7.17999973 L10.6133336,8.15333303 C11.1266669,7.33999973 11.4266669,6.37333309 11.4266669,5.33333313 C11.4266669,3.85999986 10.8200002,2.53333324 9.85333356,1.57333327 L11.4266669,0 Z" />
    </g>
  </svg>
);

export default Unlink;
