import React from 'react';
import Font from 'components/Toolbar/Shared/Font';
import * as Models from 'models';
import styles from './styles.module.scss';

type Props = {
  activeFontFamily: Models.FontFamily | undefined;
  fonts: Models.BrandFontsList;
  title: string;
  toggleFontFamily(font: Models.BrandFontMap, charStyle: Models.CharacterStyleMap): void;
};

const FontsCategory: React.FunctionComponent<Props> = (props) => {
  const { activeFontFamily, toggleFontFamily, title, fonts } = props;

  return (
    <div className={styles.FontsCategory}>
      <div className={styles.title}>{title}</div>
      <div>
        {
          fonts.map(font => (
            <Font
              key={font.get('name')}
              activeFontFamily={activeFontFamily}
              font={font}
              fonts={fonts}
              toggleFontFamily={toggleFontFamily}
            />
          ))
        }
      </div>
    </div>
  );
};

export default FontsCategory;
