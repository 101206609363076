import classNames from 'classnames';
import * as React from 'react';

import AssetListFilter from 'components/AssetListFilter';
import ElementList from 'components/ElementList';
import ReusableLayoutList from 'components/LayoutList';
import LayoutListContainer from 'components/LayoutListContainer';
import ModuleBundleList from 'components/ModuleBundleList';
import StoryCardList from 'components/StoryCardList';
import TextCollectionList from 'components/TextCollectionList';
import TextComponentList from 'components/TextComponentList';
import { AssetFilter, EntityType, ProjectPanelSection, ProjectPanelTab } from 'const';
import CitationList from 'containers/CitationList';
import ImageList from 'containers/ImageList';
import RefreshPanel from 'containers/RefreshPanel';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { IProjectPanelState, ProjectPanelProps } from './models';
import Styles from './styles.module.scss';

const AssetFilterByEntityType: PartialRecord<EntityType, AssetFilter> = {
  [EntityType.LAYOUT]: AssetFilter.LAYOUTS,
  [EntityType.GROUP_LAYOUT]: AssetFilter.GROUP_LAYOUTS,
};

export default class ProjectPanel extends React.PureComponent<ProjectPanelProps, IProjectPanelState> {
  readonly state: IProjectPanelState = {
    assetFilters: [],
    filterExpanded: false,
  };

  private setActiveTab = (tab: ProjectPanelTab): void => {
    this.props.setActiveTab(tab);
  };

  private setAssetFilters = (assetFilters: string[]): void => {
    this.setState({ assetFilters });
  };

  private setContentFilters = (contentFilters: string[]): void => {
    this.props.setContentFilter(contentFilters);
  };

  private toggleFilterExpanded = (): void => {
    this.setState(prevState => ({
      filterExpanded: !prevState.filterExpanded,
    }));
  };

  private shouldDisplayAsset = (asset: AssetFilter): boolean => {
    const { assetFilters } = this.state;

    return assetFilters.length === 0 || assetFilters.includes(asset);
  };

  private getLayoutsToShow = (): Models.CombinedLayoutDocumentsMap => {
    const { reusableLayouts } = this.props;
    const { assetFilters } = this.state;

    if (assetFilters.length === 0) {
      return reusableLayouts;
    }

    return reusableLayouts.filter(document => assetFilters.includes(AssetFilterByEntityType[document.get('entityType')]));
  };

  render() {
    const { filterExpanded } = this.state;
    const {
      activeSections,
      activeTab,
      availableFilters,
      citations,
      images,
      isReferenceCitationElementOnArtboard,
      moduleBundles,
      projectType,
      showUpdatedModuleBundles,
      showUpdatedReusableLayouts,
      showUpdatedStoryCards,
      showUpdatedTextCollections,
      storyCardsAndMagicForms,
      textCollections,
      textComponents,
      toggleDragState,
      toggleSection,
      toggleUpdatedDocumentsDisplaying,
      updatedInPromoMatsEntities,
    } = this.props;

    return (
      <div className={Styles.ProjectPanel}>
        <div className={Styles.tabs__wrapper}>
          <ul className={Styles.tabs}>
            <li
              className={classNames({ [Styles.active]: activeTab === ProjectPanelTab.ASSETS })}
              value={ProjectPanelTab.ASSETS}
              onClick={() => this.setActiveTab(ProjectPanelTab.ASSETS)}
            >
              {intlGet('ProjectPanel.Label', 'Assets')}
            </li>
            <li
              className={classNames({ [Styles.active]: activeTab === ProjectPanelTab.ELEMENTS })}
              value={ProjectPanelTab.ELEMENTS}
              onClick={() => this.setActiveTab(ProjectPanelTab.ELEMENTS)}
            >
              {intlGet('ProjectPanel.Label', 'Elements')}
            </li>
          </ul>
        </div>
        <div className={Styles.container}>
          {
            <>
              <div className={classNames(Styles.filter__wrapper, { [Styles.show__flex]: activeTab === ProjectPanelTab.ASSETS })}>
                <AssetListFilter
                  activeTab={activeTab}
                  availableFilters={availableFilters}
                  setAssetFilters={this.setAssetFilters}
                  setContentFilters={this.setContentFilters}
                  toggleFilterExpanded={this.toggleFilterExpanded}
                />
              </div>
              <div
                className={classNames(Styles.content__wrapper, {
                  [Styles.short]: filterExpanded,
                  [Styles.show]: activeTab === ProjectPanelTab.ASSETS,
                })}
              >
                {
                  this.shouldDisplayAsset(AssetFilter.MODULE_BUNDLES) &&
                  <ModuleBundleList
                    items={moduleBundles}
                    showUpdatedModuleBundles={showUpdatedModuleBundles}
                    toggleUpdatedDocumentsDisplaying={toggleUpdatedDocumentsDisplaying}
                    updatedInPromoMats={
                      updatedInPromoMatsEntities.contains(EntityType.MODULE_BUNDLE)
                    }
                  />
                }
                {
                  this.shouldDisplayAsset(AssetFilter.TEXT_COLLECTIONS) &&
                  <TextCollectionList
                    items={textCollections}
                    showUpdatedTextCollections={showUpdatedTextCollections}
                    toggleUpdatedDocumentsDisplaying={toggleUpdatedDocumentsDisplaying}
                    updatedInPromoMats={updatedInPromoMatsEntities.contains(EntityType.TEXT_COLLECTION)}
                  />
                }
                {
                  this.shouldDisplayAsset(AssetFilter.STORY_CARDS) &&
                  <StoryCardList
                    items={storyCardsAndMagicForms}
                    showUpdatedStoryCards={showUpdatedStoryCards}
                    toggleUpdatedDocumentsDisplaying={toggleUpdatedDocumentsDisplaying}
                    updatedInPromoMats={
                      updatedInPromoMatsEntities.contains(EntityType.STORY_CARD) ||
                      updatedInPromoMatsEntities.contains(EntityType.MAGIC_FORM)
                    }
                  />
                }
                {
                  (this.shouldDisplayAsset(AssetFilter.LAYOUTS) || this.shouldDisplayAsset(AssetFilter.GROUP_LAYOUTS)) &&
                  <ReusableLayoutList
                    reusableLayouts={this.getLayoutsToShow()}
                    showUpdatedReusableLayouts={showUpdatedReusableLayouts}
                    toggleUpdatedDocumentsDisplaying={toggleUpdatedDocumentsDisplaying}
                    updatedInPromoMats={updatedInPromoMatsEntities.contains(EntityType.LAYOUT)}
                    isExpand={activeSections.has(ProjectPanelSection.REUSABLE_LAYOUTS)}
                    toggleExpand={toggleSection.bind(null, ProjectPanelSection.REUSABLE_LAYOUTS)}
                  />
                }
                {
                  this.shouldDisplayAsset(AssetFilter.IMAGES) &&
                  <ImageList
                    showHeader={true}
                    documents={images}
                    updatedInPromoMats={updatedInPromoMatsEntities.contains(EntityType.IMAGE)}
                    isExpand={activeSections.has(ProjectPanelSection.IMAGES)}
                    toggleExpand={toggleSection.bind(null, ProjectPanelSection.IMAGES)}
                  />
                }
                {
                  this.shouldDisplayAsset(AssetFilter.REFERENCES) &&
                  <CitationList
                    citations={citations}
                    updatedInPromoMats={updatedInPromoMatsEntities.contains(EntityType.REFERENCE_CITATION)}
                    isExpand={activeSections.has(ProjectPanelSection.REFERENCE_CITATIONS)}
                    toggleExpand={toggleSection.bind(null, ProjectPanelSection.REFERENCE_CITATIONS)}
                  />
                }
                {
                  this.shouldDisplayAsset(AssetFilter.TEXT) &&
                  <TextComponentList
                    showHeader={true}
                    components={textComponents}
                    isExpand={activeSections.has(ProjectPanelSection.TEXTS)}
                    toggleExpand={toggleSection.bind(null, ProjectPanelSection.TEXTS)}
                  />
                }
              </div>
            </>
          }
          {
            <div className={classNames(Styles.layout__wrapper, { [Styles.show]: activeTab === ProjectPanelTab.ELEMENTS })}>
              <LayoutListContainer
                toggleDragState={toggleDragState}
                isReferenceCitationElementOnArtboard={isReferenceCitationElementOnArtboard}
              />
              <ElementList
                toggleDragState={toggleDragState}
                isReferenceCitationElementOnArtboard={isReferenceCitationElementOnArtboard}
                projectType={projectType}
              />
            </div>
          }
        </div>
        <RefreshPanel />
      </div>
    );
  }
}
