import { MapDispatchToPropsFunction, Selector, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ColorGradientPicker from 'components/ModalWindows/ColorGradientPicker';
import {
  IColorGradientPickerDispatchProps,
  IColorGradientPickerOwnProps,
  IColorGradientPickerStateProps,
} from 'components/ModalWindows/ColorGradientPicker/models';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (
  _: unknown,
  props: IColorGradientPickerOwnProps,
): Selector<unknown, IColorGradientPickerStateProps> => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps: MapDispatchToPropsFunction<IColorGradientPickerDispatchProps, IColorGradientPickerOwnProps> = (
  dispatch,
  props,
) => ({
  hideModal: () => dispatch(hideModal(props.modalWindowId)),
});

export default connect<IColorGradientPickerStateProps, IColorGradientPickerDispatchProps, IColorGradientPickerOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ColorGradientPicker);
