import _ from 'lodash';

interface UseResizeArgs {
  height: number;
  minHeight?: number;
  calcMaxHeight(): number;
  getClientRect(): DOMRect;
  toggleHeight(height: number): void;
}

export const useResize = (props: UseResizeArgs): {
  calcHeight: () => number;
  onResize: (event: DragEvent) => void;
} => {
  const {
    height,
    minHeight = 1,
    calcMaxHeight,
    getClientRect,
    toggleHeight,
  } = props;
  const calcHeight = (): number => _.max([calcMaxHeight(), minHeight]) || 0;

  const onResize = ({ clientY }: DragEvent): void => {
    const { bottom } = getClientRect();
    const change = bottom - clientY;
    const maxHeight = calcHeight();
    const newHeight = height - change;

    if (_.inRange(newHeight, minHeight, maxHeight + 1)) {
      toggleHeight(newHeight);
    }
  };

  return {
    calcHeight,
    onResize,
  };
};
