import classNames from 'classnames';
import React from 'react';

import ActionIcon from 'components/ActionIcon';
import { ActionsColor } from 'components/ArtboardLayoutActions/constants';
import { IconType } from 'components/Icon/constants';
import { IReferenceCitationActionsProps, IReferenceCitationActionsState } from './models';
import styles from './styles.module.scss';

export default class ReferenceCitationActions extends React.PureComponent<IReferenceCitationActionsProps, IReferenceCitationActionsState> {
  readonly state: IReferenceCitationActionsState = {
    showActions: false,
  };

  private showActions = (): void => {
    this.setState({ showActions: true });
  };

  private hideActions = (): void => {
    this.setState({ showActions: false });
  };

  render() {
    const { children } = this.props;
    const cellClassName = classNames(styles.ReferenceCitationActions, {
      [styles.showActions]: this.state.showActions,
    });

    return (
      <div
        className={cellClassName}
        onMouseOver={this.showActions}
        onMouseLeave={this.hideActions}
      >
        {children}
        <div className={styles.deleteAction}>
          <ActionIcon
            backgroundColor={ActionsColor.ORANGE}
            type={IconType.DELETE}
            onClick={this.props.deleteReference}
            size="xs"
            color="secondary"
          />
        </div>
      </div>
    );
  }
}
