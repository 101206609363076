import _ from 'lodash';

import { LAYERS, Layer } from 'const';
import * as Models from 'models';
import { isTranslatableEntity } from 'utils/entityType';
import { getValue } from 'utils/getter';
import { isRegularRelation } from 'utils/relations/isRegularRelation';

export * from './convertLayoutToLayered';
export * from './convertRelationToLayered';

export const getOppositeLayer = (layer: Layer): Layer => {
  return layer === Layer.ORIGINAL ? Layer.TRANSLATED : Layer.ORIGINAL;
};

export function isObjectLayered<T>(object: T | Models.Layers<T>): object is Models.Layers<T> {
  const objectKeys = _.keys(object);

  return objectKeys.length !== 0 && _.every(objectKeys, (key: Layer) => LAYERS.includes(key));
}

export const createLayers = <T>({
  original = null,
  translated = null,
} = {} as Partial<Models.Layers<T>>): Models.Layers<T> => ({
  original,
  translated,
});

export const getPriorityLayerKey = <T extends Models.Layers<PropertyValue<T>>>(layeredData: T | DeepIMap<T>): Layer => {
  if (!layeredData) {
    return Layer.ORIGINAL;
  }

  return getValue(layeredData, Layer.TRANSLATED) ? Layer.TRANSLATED : Layer.ORIGINAL;
};

export function getPriorityLayer<T extends Models.Layers<PropertyValue<T>>>(object: T): PropertyValue<T>;
export function getPriorityLayer<T extends Models.Layers<PropertyValue<T>>>(object: DeepIMap<T>): DeepIMap<PropertyValue<T>>;
export function getPriorityLayer(object) {
  if (!object) {
    return object;
  }

  const layerKey = getPriorityLayerKey(object);

  return getValue(object, layerKey);
}

export function moveToLayer<T>(object: T | Models.Layers<T>, layer: Layer, priorityLayer?: Layer): Models.Layers<T> {
  let priorityLayerObject;

  if (isObjectLayered(object)) {
    priorityLayerObject = priorityLayer ? object[priorityLayer] : getPriorityLayer(object);
  } else {
    priorityLayerObject = object;
  }

  return createLayers({ [layer]: priorityLayerObject });
}

export function iterateLayeredCollection<T extends Models.LayersMap<PropertyValue<PropertyValue<T>>>>(
  collection: T,
  cb: (layer: PropertyValue<PropertyValue<T>>, layerKey: keyof PropertyValue<T>) => void,
  options?: { skipEmptyLayers?: boolean; layerToIterate?: Layer },
): void {
  const { skipEmptyLayers, layerToIterate } = _.defaults(options, { skipEmptyLayers: true, layerToIterate: null });

  _.each(collection, object => _.each(object, (layer, layerKey) => {
    if (skipEmptyLayers && _.isEmpty(layer)) {
      return;
    }

    if (layerToIterate && layerKey !== layerToIterate) {
      return;
    }

    cb(layer, layerKey as keyof PropertyValue<T>);
  }));
}

export function groupByLayer<T extends Models.LayersMap<PropertyValue<PropertyValue<T>>>>(
  collection: T,
): Models.Layers<PropertyValue<PropertyValue<T>>[]> {
  const result = createLayers({ original: [], translated: [] });
  iterateLayeredCollection(collection, (layer, layerKey: Layer) => result[layerKey].push(layer));

  return result;
}

export function getSafeLayer(entity, activeLayer: Layer): Layer {
  return isTranslatableEntity(entity) ? activeLayer : Layer.ORIGINAL;
}

export function getLayerFromAppState(entity, appState: Models.AppState.StateMap): Layer {
  const activeLayer = appState.getIn(['project', 'activeLayer']);

  return getSafeLayer(entity, activeLayer);
}

export function makeRelationsLayered(relations: Models.Relations, layer: Layer): Models.LayeredRelations {
  return _.mapValues(
    relations,
    rel => isRegularRelation(rel)
      ? {
        ...rel,
        styles: createLayers({ [layer]: rel.styles }),
        documentId: createLayers({ [layer]: rel.documentId }),
      } as Models.LayeredRelation
      : rel,
  );
}
