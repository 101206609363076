import React from 'react';
/* eslint-disable max-len */

const DeleteBin = (): JSX.Element => (
  <svg viewBox="0 0 15 20">
    <g id="ASM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Edit-Layout" transform="translate(-1005.000000, -399.000000)">
        <g id="LayoutMenu" transform="translate(255.000000, 340.000000)">
          <g id="Move-Copy-2">
            <g transform="translate(757.500000, 69.000000) scale(-1, 1) translate(-757.500000, -69.000000) translate(742.000000, 53.000000)">
              <g id="3.-Icons/ic_delete" transform="translate(0.500000, 0.000000)">
                <g id="Icon" />
                <g id="5.-Colors/White" fill="#FFFFFF" fillRule="evenodd">
                  <path d="M9,23.4666667 C9,24.64 9.9,25.6 11,25.6 L19,25.6 C20.1,25.6 21,24.64 21,23.4666667 L21,10.6666667 L9,10.6666667 L9,23.4666667 L9,23.4666667 Z M22,7.46666667 L18.5,7.46666667 L17.5,6.4 L12.5,6.4 L11.5,7.46666667 L8,7.46666667 L8,9.6 L22,9.6 L22,7.46666667 L22,7.46666667 Z" id="path-1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DeleteBin;
