import React from 'react';
/* eslint-disable max-len */

const FitToCell = (): JSX.Element => (
  <svg viewBox="0 0 24 22">
    <defs>
      <path id="FitToCellPath" d="M18,0 L22,3.99 L19,3.99 L19,20.01 L22,20.01 L18,24 L14,20.01 L17,20.01 L17,3.99 L14,3.99 L18,0 Z M11,0 C11.5522847,-1.01453063e-16 12,0.44771525 12,1 L12,23 C12,23.5522847 11.5522847,24 11,24 L1,24 C0.44771525,24 6.76353751e-17,23.5522847 0,23 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L11,0 Z M10.0909091,1.8522184 L2.09090909,1.8522184 L2.09090909,22.2266208 L10.0909091,22.2266208 L10.0909091,1.8522184 Z" />
    </defs>
    <g transform="translate(12, 11) rotate(90) translate(-11, -12)">
      <use xlinkHref="#FitToCellPath" />
    </g>
  </svg>
);

export default FitToCell;
