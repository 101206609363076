export const wrapperOnMouseDownHackStrict = (event: React.MouseEvent<HTMLDivElement>): void => {
  // HACK: to avoid toolbar closing
  // https://github.com/facebook/draft-js/issues/696
  // https://github.com/facebook/draft-js/issues/726
  if (event.target === event.currentTarget) {
    event.preventDefault();
  }
};

export const wrapperOnMouseDownHack = (event: React.MouseEvent<Element>): void => {
  // preventDefault() To save selection in Draft Editor when user start select a new font
  // https://github.com/facebook/draft-js/issues/696
  // https://github.com/facebook/draft-js/issues/726
  event.preventDefault();
};
