import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CallToAction from 'components/ArtboardAssets/CallToAction';
import { CallToActionActions, CallToActionOwnProps, CallToActionSelectors } from 'components/ArtboardAssets/CallToAction/models';
import { isResizingRow } from 'containers/App/selectors';
import { brandStylesByRelationId, flatColorsByRelationId, flatFontsByRelationId } from 'containers/Common/selectors';
import { imagesForAssetsPanel } from 'containers/Documents/selectors';
import { currentCountry, currentLanguage } from 'containers/Project/selectors';
import { updateLayeredRelationsSilently } from 'containers/Relations/actions';
import { saveAppState, cancel } from 'containers/UndoRedoControl/actions';

const createMapStateToProps = createStructuredSelector({
  currentCountry,
  currentLanguage,
  flatColorsByRelationId,
  flatFontsByRelationId,
  images: imagesForAssetsPanel,
  isResizingRow,
  brandStylesByRelationId,
});

const mapDispatchToProps = {
  updateRelationsSilently: updateLayeredRelationsSilently,
  saveAppState,
  cancel,
};

export default connect<CallToActionSelectors, CallToActionActions, CallToActionOwnProps>(createMapStateToProps, mapDispatchToProps)(CallToAction);
