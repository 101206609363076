import Immutable from 'immutable';
import _ from 'lodash';

import { sum } from './sum';

export const addColumn = (columnsWidth: Immutable.List<number>): Immutable.List<number> => {
  const availableColumnsWidthSum = sum(columnsWidth);

  const addedColumnWidth = availableColumnsWidthSum / (columnsWidth.size + 1);

  const newColumnsWidth = columnsWidth.push(addedColumnWidth);
  let calculatedColumnsWidthSum = 0;

  return newColumnsWidth.map((width, index) => {
    const newColumnWidth = _.round(width - width / availableColumnsWidthSum * addedColumnWidth);

    if (index === newColumnsWidth.size - 1 || calculatedColumnsWidthSum + newColumnWidth > availableColumnsWidthSum) {
      return _.round(availableColumnsWidthSum - calculatedColumnsWidthSum);
    }

    calculatedColumnsWidthSum += newColumnWidth;

    return newColumnWidth;
  });
};
