import React from 'react';
/* eslint-disable max-len */

const Tick = (): JSX.Element => (
  <svg width="20px" height="20px" viewBox="5 6 11 9">
    <path d="M10,0 10,0 Z M6.79545455,8.82089552 L5,10.6567164 L9.18181818,15 L16,7.8358209 L14.2045455,6 L9.18181818,11.3283582 L6.79545455,8.82089552 Z" />
  </svg>
);

export default Tick;
