import { List } from 'immutable';
import { DocumentsKey, DraftEntity, Layer } from 'const';
import * as Models from 'models';
import { isCallToAction, isImage, isTextComponent } from 'utils/entityType';
import { TextContent, TextContentAbbreviationEntity } from '../types';

type Document = Models.TextComponentMap | Models.CallToActionMap;

function getAbbreviationIdsFromDocument(document: Document | undefined): string[] {
  if (!document) {
    return [];
  }
  const rawContent = (document as Models.TextComponentMap).get('rawContent');
  if (!rawContent) {
    return [];
  }
  const content = JSON.parse(rawContent) as TextContent;

  return Object.values(content.entityMap || {})
    .filter(entity => entity.type === DraftEntity.ABBREVIATION)
    .map((entity: TextContentAbbreviationEntity) => entity.data.id);
}

export function getAbbreviationIdsFromRelationOrDocument(
  relation: Models.LayeredRelationMap,
  documents: Models.AppState.DocumentsMap,
  activeLayer: Layer,
): string[] {
  if (isImage(relation)) {
    return relation.getIn(['styles', activeLayer, 'abbreviations'], []); // ['912314','214124']
  }

  if (isTextComponent(relation)) {
    return getAbbreviationIdsFromDocument(
      documents.get(DocumentsKey.TEXT_COMPONENTS).get(
        relation.getIn(['documentId', activeLayer]),
      ),
    );
  }

  if (isCallToAction(relation)) {
    return getAbbreviationIdsFromDocument(
      documents.get(DocumentsKey.CALL_TO_ACTIONS).get(
        relation.getIn(['documentId', activeLayer]),
      ),
    );
  }

  return [];
}

export function getAbbreviationIdsFromRelationsAndDocuments(
  relations: List<Models.LayeredRelationMap>,
  documents: Models.AppState.DocumentsMap,
  activeLayer: Layer = Layer.ORIGINAL,
): string[] {
  const abbreviationsIds: string[] = [];

  for (const relation of relations) {
    abbreviationsIds.push(
      ...getAbbreviationIdsFromRelationOrDocument(relation, documents, activeLayer),
    );
  }

  return abbreviationsIds;
}
