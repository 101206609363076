import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LoadingOverlay from 'components/LoadingOverlay';
import { activeProcessType, isLocked, loadingOverlayOptions } from 'containers/App/selectors';

const mapStateToProps = createStructuredSelector({
  isLocked,
  activeProcessType,
  options: loadingOverlayOptions,
});

export default connect(mapStateToProps)(LoadingOverlay);
