import { AssemblerErrorType } from 'models';


export class CustomError extends Error {
  public type: AssemblerErrorType;

  constructor(type: AssemblerErrorType, error?) {
    if (error instanceof CustomError) {
      return error;
    }

    const errorMessage = error && (error.message || String(error)) || 'Internal Error';

    super(errorMessage || type);
    this.type = type;
  }

  public toString(): string {
    return `Error occurred: ${this.type} - ${this.stack || this.message}`;
  }
}
