import Draft from 'draft-js';
import { compositeDecorator } from 'modules/draftjs/decorators';
import { useCallback, useEffect, useRef } from 'react';
import * as Constants from 'const';
import * as DocumentModels from 'containers/Documents/models';
import * as Models from 'models';
import { removeReferenceCitationsOrder } from 'utils/addReferenceCitationsOrder';
import * as editorUtils from 'utils/editor';

type DropOptions = {
  document: Models.TextComponentMap;
  updateDocument: DocumentModels.ActionCreator.UpdateDocument;
  removeAnchor: DocumentModels.ActionCreator.RemoveAnchor;
  dropItem: Models.AssetDragObject;
};

function handleAnchorDrop(
  editorState: Draft.EditorState,
  props: DropOptions,
  selection: Draft.SelectionState,
): void {
  const { document, updateDocument, removeAnchor, dropItem } = props;
  const { documentId, anchorBlockKey } = dropItem as Models.AssetDragAnchor;

  const focusKey = selection.getFocusKey();
  const anchor: boolean = editorState.getCurrentContent().getBlockForKey(focusKey).getIn(['data', Constants.BlockDataKey.ANCHOR]);
  let newEditorState = editorUtils.setBlockAnchorValue(editorState, focusKey, true);

  // add anchor
  if (!documentId && !anchorBlockKey) {
    if (!anchor) {
      const rawContent = JSON.stringify(Draft.convertToRaw(removeReferenceCitationsOrder(newEditorState)));
      updateDocument(document.set('rawContent', rawContent));
    }

    return;
  }

  // move anchor
  const currentDocumentId = document && document.get('id');
  const isSameDocument = currentDocumentId === documentId;

  if ((isSameDocument && anchorBlockKey === focusKey) || anchor) {
    return;
  }

  if (isSameDocument) {
    newEditorState = editorUtils.setBlockAnchorValue(newEditorState, anchorBlockKey, false);
  } else {
    removeAnchor(documentId, anchorBlockKey);
  }

  const rawContent = JSON.stringify(Draft.convertToRaw(removeReferenceCitationsOrder(newEditorState)));
  updateDocument(document.set('rawContent', rawContent));
}

function handleReferenceCitationDrop(
  editorState: Draft.EditorState,
  dropItem: Models.AssetDragComponent,
  selection: Draft.SelectionState,
): [Draft.EditorState, Models.DraftEditorOperation] {
  const { component } = dropItem;

  let contentState = editorState.getCurrentContent();
  const offset = selection.getFocusOffset();
  const focusKey = selection.getFocusKey();

  const block = contentState.getBlockForKey(focusKey);
  let updatedOffset = offset;
  let updatedSelection = selection;

  // check if the reference was inserted inside another
  // if this is true, paste reference after this one
  const existingReferenceEntityKey = block.getEntityAt(offset);

  if (existingReferenceEntityKey) {
    block.findEntityRanges(
      value => value.getEntity() === existingReferenceEntityKey,
      (start, end) => updatedOffset = end,
    );

    updatedSelection = updatedSelection.set('focusOffset', updatedOffset).set('anchorOffset', updatedOffset) as Draft.SelectionState;
  }

  contentState = editorUtils.createReferenceEntity(
    contentState,
    updatedSelection,
    focusKey,
    updatedOffset,
    component as Models.ReferenceCitationMap,
  );
  const resultEditorState = Draft.EditorState.set(editorState, {
    lastChangeType: Constants.EditorChangeType.ADD_REFERENCE,
    currentContent: contentState,
  });

  const operation: Models.DraftEditorOperation = {
    type: Constants.EditorChangeType.ADD_REFERENCE,
    selectionBefore: updatedSelection,
    reference: component as Models.ReferenceCitationMap,
  };

  return [
    Draft.EditorState.set(resultEditorState, { decorator: compositeDecorator }),
    operation,
  ];
}


type DropHandler = (selection: Draft.SelectionState) => Draft.DraftHandleValue;

export function useDraftjsHandleDrop(
  options: DropOptions,
  editorState: Draft.EditorState,
  setEditorState: (state: Draft.EditorState) => void,
  addOperation: (operation: Models.DraftEditorOperation) => void,
  storeTextCallback: () => void,
): DropHandler {

  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  const dropOptionsRef = useRef(options);
  dropOptionsRef.current = options;

  const shouldRunStoreTextCallback = useRef(false);
  useEffect(() => {
    if (shouldRunStoreTextCallback.current) {
      storeTextCallback();
      shouldRunStoreTextCallback.current = false;
    }
  }, [editorState]);

  return useCallback(
    (selection: Draft.SelectionState): Draft.DraftHandleValue => {
      const { dropItem } = dropOptionsRef.current;

      switch (dropItem.type) {
        case Constants.EntityType.REFERENCE_CITATION: {
          const [newState, newOperation] = handleReferenceCitationDrop(
            editorStateRef.current,
            dropItem as Models.AssetDragComponent,
            selection,
          );
          addOperation(newOperation);
          setEditorState(newState);
          shouldRunStoreTextCallback.current = true;
          break;
        }

        case Constants.EntityType.ANCHOR: {
          handleAnchorDrop(editorStateRef.current, dropOptionsRef.current, selection);
          break;
        }
      }

      return Constants.HandleValue.HANDLED;
    },
    [setEditorState, addOperation],
  );
}
