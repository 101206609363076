import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { SourceProjectType } from 'const';
import * as Models from 'models';
import { convertToUTCTime } from 'utils/convertTime';
import { intlGet } from 'utils/intlGet';
import { IProjectSourceProps } from './models';
import styles from './styles.module.scss';

const ProjectSource: React.FunctionComponent<IProjectSourceProps> = (props) => {
  const setProjectSource = (projectSourceType: SourceProjectType): void => {
    const { hideModal, modalWindowId } = props;
    hideModal(modalWindowId, projectSourceType);
  };

  const getProjectSourceInfo = (projectSource: Models.ProjectSource): string => {
    const date = convertToUTCTime(projectSource.modifyDate);
    const sourceName = projectSource.sourceProjectType === SourceProjectType.LOCAL
      ? intlGet('ProjectSourceWindow', 'LocalSource')
      : intlGet('ProjectSourceWindow', 'PromoMats');

    return intlGet('ProjectSourceWindow', 'ProjectSource', { sourceName, date });
  };

  const { projectSources, projectName } = props.options.toJS() as Models.ProjectSourceWindowOptions;
  const title = intlGet('ProjectSourceWindow', 'Title');
  const message = intlGet('ProjectSourceWindow', 'Message');

  return (
    <div className={styles.ProjectSource}>
      <header>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
      </header>
      <section>
        {
          projectSources && projectSources.map((projectSource, index) => (
            <div
              key={index}
              className={styles.listItem}
              onClick={setProjectSource.bind(null, projectSource.sourceProjectType)}
            >
              <div className={styles.imageContainer}>
                <Icon type={IconType.IMAGE} size="sm" color="light-grey" />
              </div>
              <div>
                <div>{`${projectName} (${projectSource.version})`}</div>
                <div className={styles.listItemText}>{getProjectSourceInfo(projectSource)}</div>
              </div>
            </div>
          ))
        }
      </section>
    </div>
  );
};

export default ProjectSource;
