import { ActionTypes } from './constants';
import { ActionCreator } from './models';

export const setArtboards: ActionCreator.SetArtboards = artboards => ({
  type: ActionTypes.SET_ARTBOARDS,
  payload: {
    artboards,
  },
});

export const addArtboard: ActionCreator.AddArtboard = artboard => ({
  type: ActionTypes.ADD_ARTBOARD,
  payload: {
    artboard,
  },
});

export const deleteArtboards: ActionCreator.DeleteArtboards = artboardIds => ({
  type: ActionTypes.DELETE_ARTBOARDS,
  payload: {
    artboardIds,
  },
});

export const artboardMounted: ActionCreator.ArtboardMounted = () => ({
  type: ActionTypes.ARTBOARD_MOUNTED,
});

export const artboardWillBeMounted: ActionCreator.ArtboardWillBeMounted = () => ({
  type: ActionTypes.ARTBOARD_WILL_BE_MOUNTED,
});

export const artboardReady: ActionCreator.ArtboardReady = () => ({
  type: ActionTypes.ARTBOARD_READY,
});

export const artboardImagesLoaded: ActionCreator.ArtboardImagesLoaded = () => ({
  type: ActionTypes.ARTBOARD_IMAGES_LOADED,
});

export const updateArtboard: ActionCreator.UpdateArtboard = artboard => ({
  type: ActionTypes.UPDATE_ARTBOARD,
  payload: {
    artboard,
  },
});

export const downloadArtboardsJson: ActionCreator.GetArtboardsJson = () => ({
  type: ActionTypes.DOWNLOAD_ARTBOARDS_JSON,
});

export const dropStoryCard: ActionCreator.DropStoryCard = (storyCardId, screenOrder) => ({
  type: ActionTypes.DROP_STORY_CARD,
  payload: {
    storyCardId,
    screenOrder,
  },
});

export const addSSI: ActionCreator.AddSSI = section => ({
  type: ActionTypes.ADD_SSI,
  payload: {
    section,
  },
});

export const moveSSI: ActionCreator.MoveSSI = section => ({
  type: ActionTypes.MOVE_SSI,
  payload: {
    section,
  },
});

export const deleteSSI: ActionCreator.DeleteSSI = () => ({
  type: ActionTypes.DELETE_SSI,
});

export const setSSISource: ActionCreator.SetSSISource = (artboardId, SSISource) => ({
  type: ActionTypes.SET_SSI_SOURCE,
  payload: {
    artboardId,
    SSISource,
  },
});
