import * as Constants from 'const';
import * as Models from 'models';
import { isImmutable, toImmutable } from 'utils/immutable';

export function getDesktopBrandStyles(
  brandStyle: Models.BrandStyleMap | Models.BrandStyle,
): Models.BrandStyleObjMap | undefined {
  const convertedBrandStyle = isImmutable(brandStyle) ? brandStyle : toImmutable(brandStyle);

  const breakpoint = convertedBrandStyle
    .get('breakpoints')
    .find(item => item?.get('type') === Constants.DESKTOP_BREAKPOINT);

  return breakpoint?.get('style');
}

export function getDesktopBrandStylesRef(
  brandStyle: Models.BrandStyle,
): Models.BrandStyleObj | undefined {

  const breakpoint = brandStyle?.breakpoints?.find(
    item => item.type === Constants.DESKTOP_BREAKPOINT,
  );

  return breakpoint?.style;
}
