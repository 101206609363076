import React, { PropsWithChildren } from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  title: string;
  onHide: () => void;
}>;

const Header: React.FunctionComponent<Props> = (props) => {
  const { title, onHide, children } = props;

  return (
    <header className={styles.header}>
      <div className={styles.main}>
        <div className={styles.title}>{title}</div>
        <Icon className={styles.closeIcon} onClick={onHide} type={IconType.CLOSE} size="sm-md" color="primary" />
      </div>
      {children || null}
    </header>
  );
};

export default Header;
