import classNames from 'classnames';
import React from 'react';

import { toPx } from 'utils/toPx';
import { HeightExceedingIndicatorProps } from './models';
import styles from './styles.module.scss';

const HeightExceedingIndicator: React.FunctionComponent<HeightExceedingIndicatorProps> = ({ isShown, offsetTop }) => {
  return (
    <div
      className={classNames(styles.HeightExceedingIndicator, { [styles.shown]: isShown })}
      style={{ top: toPx(offsetTop) }}
    />
  );
};

export default HeightExceedingIndicator;
