import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import React, { useState } from 'react';
import { TextAbbreviationDocument } from 'models';
import { intlGet } from 'utils/intlGet';
import useAbbreviation from '../../../hooks/useAbbreviation';
import AbbreviationDetails from '../../AbbreviationDetails';
import css from '../styles.module.scss';
import AbbreviationsList from './AbbreviationsList';
import SelectedText from './SelectedText';

type Props = {
  selectedText: string;
  abbreviationDocumentId?: TextAbbreviationDocument['id'];
  onChange: (abbreviationDocumentId?: TextAbbreviationDocument['id']) => void;
};

const TextAbbreviationPanel: React.FunctionComponent<Props> = (props) => {
  const { selectedText, abbreviationDocumentId, onChange } = props;

  const abbreviationDocument = useAbbreviation(abbreviationDocumentId);

  const [showList, setShowList] = useState<boolean>(false);

  useCellActionsExecutor(CellAction.CHANGE_ABBREVIATION, (id) => {
    if (abbreviationDocumentId !== id) {
      return false;
    }
    setShowList(true);

    return true;
  });

  if (abbreviationDocument && !showList) {
    return (
      <AbbreviationDetails
        abbreviation={abbreviationDocument.abbreviation}
        onChange={(): void => setShowList(true)}
        onRemove={(): void => onChange(undefined)}
      />
    );
  }

  return (
    <div className={css.flexContainer}>
      <div className="d-flex justify-content-between align-items-start mb-2">
        <SelectedText text={selectedText} />
        {abbreviationDocument && (
          <button
            className={css.button}
            onClick={(): void => setShowList(false) }
          >
            {intlGet('EditorToolbar.Abbreviation', 'Button.Cancel')}
          </button>
        )}
      </div>
      <AbbreviationsList
        className={css.scroll}
        term={selectedText}
        abbreviationDocument={abbreviationDocument}
        onSelect={(_abbreviationDocumentId): void => {
          onChange(_abbreviationDocumentId);
          setShowList(false);
        }}
      />
    </div>
  );
};

export default TextAbbreviationPanel;
