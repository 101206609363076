import { IconType } from 'components/Icon/constants';
import { AssetAlignmentType } from 'const';

export const IconMap: PartialRecord<AssetAlignmentType, PartialRecord<AssetAlignmentType, IconType>> = {
  [AssetAlignmentType.HORIZONTAL_CENTER]: {
    [AssetAlignmentType.BOTTOM]: IconType.ALIGN_BOTTOM_CENTER,
    [AssetAlignmentType.VERTICAL_CENTER]: IconType.ALIGN_CENTER_CENTER,
    [AssetAlignmentType.TOP]: IconType.ALIGN_TOP_CENTER,
  },
  [AssetAlignmentType.LEFT]: {
    [AssetAlignmentType.BOTTOM]: IconType.ALIGN_BOTTOM_LEFT,
    [AssetAlignmentType.VERTICAL_CENTER]: IconType.ALIGN_CENTER_LEFT,
    [AssetAlignmentType.TOP]: IconType.ALIGN_TOP_LEFT,
  },
  [AssetAlignmentType.RIGHT]: {
    [AssetAlignmentType.BOTTOM]: IconType.ALIGN_BOTTOM_RIGHT,
    [AssetAlignmentType.VERTICAL_CENTER]: IconType.ALIGN_CENTER_RIGHT,
    [AssetAlignmentType.TOP]: IconType.ALIGN_TOP_RIGHT,
  },
};
