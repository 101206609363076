import React from 'react';
/* eslint-disable max-len */

const ZoomMinus = (): JSX.Element => (
  <svg width="300" height="300" viewBox="0 0 300 300">
    <g>
      <rect stroke="#000" height="20" width="150" y="135" x="70" fill="#f9ad3d"/>
    </g>
  </svg>
);

export default ZoomMinus;
