import { EditorState, Modifier, RawDraftContentState, RawDraftEntity, SelectionState } from 'draft-js';
import Immutable from 'immutable';

import { DraftEditorStateChangeType, DraftEntity, DraftEntityMutability, REFERENCES_MARKER, ScriptType } from 'const';
import { BlockEntityMap, ReferenceCitationMap } from 'models';
import { getSelectedEntities, trimSelection } from './selection';

export function removeEntities(editorState: EditorState): EditorState {
  const contentState = editorState.getCurrentContent();
  const contentWithoutEntities = Modifier.applyEntity(
    contentState,
    editorState.getSelection(),
    null,
  );

  return EditorState.push(
    editorState,
    contentWithoutEntities,
    DraftEditorStateChangeType.APPLY_ENTITY,
  );
}

export const reselectEntities = (editorState: EditorState): SelectionState => {
  const selection = trimSelection(editorState);
  const selectedEntities = getSelectedEntities(selection, editorState.getCurrentContent())
    .skipWhile(entity => entity.get('type') === DraftEntity.REFERENCE)
    .takeUntil(entity => entity.get('type') === DraftEntity.REFERENCE);

  const notSetEntity: BlockEntityMap = Immutable.Map({
    block: selection.getStartKey(),
    startOffset: selection.getStartOffset(),
    endOffset: selection.getStartOffset(),
    type: null,
    key: null,
  });

  const firstEntity = selectedEntities.first(notSetEntity);
  const lastEntity = selectedEntities.last(notSetEntity);

  return selection.merge({
    anchorKey: firstEntity.get('block'),
    anchorOffset: firstEntity.get('startOffset'),
    focusKey: lastEntity.get('block'),
    focusOffset: lastEntity.get('endOffset'),
    isBackward: false,
  }) as SelectionState;
};

// TODO: provide updated selection instead of merging selection with block key and offset
export function createReferenceEntity(
  contentState: Draft.ContentState,
  selection: Draft.SelectionState,
  blockKey: string,
  offset: number,
  reference: ReferenceCitationMap,
): Draft.ContentState {
  const updatedSelection = selection.merge({
    focusOffset: offset,
    anchorOffset: offset,
    focusKey: blockKey,
    anchorKey: blockKey,
  }) as Draft.SelectionState;

  let contentStateWithEntity = contentState.createEntity(
    DraftEntity.REFERENCE,
    DraftEntityMutability.IMMUTABLE,
    { id: reference.get('id') },
  );

  const block = contentStateWithEntity.getBlockForKey(blockKey);
  // get styles of previous character or first character and add superscript style
  const styles = block.getInlineStyleAt(offset - 1).add(ScriptType.SUPERSCRIPT);

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  contentStateWithEntity = Modifier.insertText(
    contentStateWithEntity,
    updatedSelection,
    REFERENCES_MARKER,
    styles,
    entityKey,
  );

  return contentStateWithEntity;
}

export function iterateEntities(rawContent: RawDraftContentState, cb: (entity: RawDraftEntity) => void): void {
  const { blocks, entityMap } = rawContent;

  blocks.forEach(({ entityRanges }) => {
    entityRanges.forEach(({ key }) => {
      const entity = entityMap[key];
      entity && cb(entity);
    });
  });
}
