import * as Models from 'models';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { getParent } from './getParent';

export function getParentColumn(relationId: string, relations: Models.LayeredRelationsMap | Models.RelationsMap): {
  parent: Models.ColumnRelationMap;
  position: number;
} {
  let { parent, position } = getParent(relationId, relations);

  if (!isColumnRelation(parent)) {
    ({ parent, position } = getParent(parent.get('id'), relations));
  }

  return {
    parent: parent as Models.ColumnRelationMap,
    position,
  };
}
