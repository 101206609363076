import Immutable from 'immutable';

import { ActionTypes } from './constants';
import { ActionCreator } from './models';

export const addTextToCell: ActionCreator.AddTextToCell = (
  relationId,
  layoutRelations,
  component,
  layoutId,
  operations,
  sectionStyles = Immutable.List<string>(),
  isDrop = false,
) => ({
  type: ActionTypes.ADD_TEXT_TO_CELL,
  payload: {
    component,
    layoutId,
    layoutRelations,
    operations,
    relationId,
    sectionStyles,
    isDrop,
  },
});

export const copyCellContent: ActionCreator.CopyCellContent = (layoutId, relationId) => ({
  type: ActionTypes.COPY_CELL_CONTENT,
  payload: {
    layoutId,
    relationId,
  },
});

export const dropAsset: ActionCreator.DropAsset = (relation, component, layoutId, size) => ({
  type: ActionTypes.DROP_ASSET,
  payload: {
    component,
    relation,
    layoutId,
    size,
  },
});

export const deleteReferenceCitationFromComponents: ActionCreator.DeleteReferenceCitationFromComponents = (
  referenceCitationId,
  textComponentIds,
) => ({
  type: ActionTypes.DELETE_REFERENCE_CITATION_FROM_COMPONENTS,
  payload: {
    referenceCitationId,
    textComponentIds,
  },
});
