import * as Bowser from 'bowser';

export function isUnsupportedBrowser(): boolean {
  try {
    const supportedBrowsers = ['chrome', 'safari', 'Microsoft Edge'];
    const currentBrowser = Bowser.getParser(window.navigator.userAgent);
    const devBypass = localStorage.getItem('dev_bypass') === 'true';

    return !devBypass && !supportedBrowsers.some(title => (currentBrowser.isBrowser(title)));
  } catch (e) {
    return true;
  }
}
