import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isReferenceCitationElementRelation(
  entity: Models.Relation
): entity is Models.RegularRelation<Models.ReferenceCitationElementStyles>;
export function isReferenceCitationElementRelation(
  entity: Models.RelationMap
): entity is Models.RegularRelationMap<Models.ReferenceCitationElementStyles>;
export function isReferenceCitationElementRelation(
  entity: Models.LayeredRelation,
): entity is Models.LayeredRegularRelation<Models.ReferenceCitationElementStyles>;
export function isReferenceCitationElementRelation(
  entity: Models.LayeredRelationMap,
): entity is Models.LayeredRegularRelationMap<Models.ReferenceCitationElementStyles>;

export function isReferenceCitationElementRelation(entity): boolean {
  return getValue(entity, 'entityType') === EntityType.REFERENCE_CITATION_ELEMENT;
}
