import { IconType } from 'components/Icon/constants';
import { LineHeight } from 'models';
import { intlGet } from 'utils/intlGet';

export enum TextLineHeightValue {
  ONE = 1,
  ONE_POINT_FIFTEEN = 1.15,
  ONE_POINT_TWO = 1.2,
  ONE_POINT_FIVE = 1.5,
  TWO = 2,
}

export const TEXT_LINE_HEIGHTS: LineHeight[] = [
  {
    type: TextLineHeightValue.ONE,
    icon: IconType.LINE_HEIGHT_ONE,
    hint: intlGet('TextLineHeight.Hint', '1'),
  },
  {
    type: TextLineHeightValue.ONE_POINT_TWO,
    icon: IconType.LINE_HEIGHT_ONE_POINT_TWO,
    hint: intlGet('TextLineHeight.Hint', '1.2'),
  },
  {
    type: TextLineHeightValue.ONE_POINT_FIVE,
    icon: IconType.LINE_HEIGHT_ONE_POINT_FIVE,
    hint: intlGet('TextLineHeight.Hint', '1.5'),
  },
  {
    type: TextLineHeightValue.TWO,
    icon: IconType.LINE_HEIGHT_TWO,
    hint: intlGet('TextLineHeight.Hint', '2'),
  },
];

// TODO: [#defaultStyles] we need to move all default styles to 1 place
export const DefaultLineHeight = TextLineHeightValue.ONE_POINT_FIVE;
