import classNames from 'classnames';
import React from 'react';

import { SpinnerProps } from './models';
import styles from './styles.module.scss';

const Spinner: React.FC<SpinnerProps> = ({ className, size }) => {
  return (
    <span
      className={classNames(styles.Spinner, {
        [className]: !!className,
        [styles[`size_${size}`]]: !!size,
      })}
    />
  );
};

export default Spinner;
