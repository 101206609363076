import _ from 'lodash';

import * as Models from 'models';
import { isImage, isTextComponent } from 'utils/entityType';
import { getCleanText } from './getCleanText';
import { getValue } from './getter';
import { toImmutable } from './immutable';
import { removeTags } from './removeTags';

export function findDuplicateIdByDocumentId<D extends Models.Document | Models.CombinedDocument>(
  document: D | DeepIMap<D>,
  documents: Record<string, D> | DeepIMap<Record<string, D>>,
): string {
  const number = getValue(document as Models.DocumentMap | Models.Document, 'documentId');
  let duplicateId = null;
  if (number) {
    Object.values(documents).some((currentDocument) => {
      if (!currentDocument) {
        return false;
      }
      const { documentId, id } = currentDocument;
      if (documentId === number) {
        duplicateId = id;

        return true;
      }

      return false;
    });
  }

  return duplicateId;
}

export function findImageDuplicateId(
  image: Models.Image | Models.ImageMap,
  images: Models.ImagesMap | Models.Images,
): string {
  return findDuplicateIdByDocumentId(image, images);
}

function findDuplicateId(
  document: Models.CombinedDocument | Models.CombinedDocumentMap,
  documents: Models.CombinedDocumentsMap | Models.CombinedDocuments,
  getText: (document: Models.CombinedDocument | Models.CombinedDocumentMap) => string,
  textCache?: Models.TextCache,
): string {
  const cache = _.defaults(textCache, {});

  const cleanText = getText(document);
  let duplicateId: string;

  toImmutable(documents).some((currentDocument) => {
    const id = currentDocument.get('id');
    const cachedText = cache[id];
    const currentCleanText = cachedText || getText(currentDocument);
    !cachedText && (cache[id] = currentCleanText);

    const isDuplicate = currentCleanText === cleanText;
    isDuplicate && (duplicateId = id);

    return isDuplicate;
  });

  return duplicateId;
}

/**
 * NOTE: Duplicates checking here depends on number of reference citations assigned to text component,
 * but it doesn't depend on order of these references.
 */
export function findTextComponentDuplicateId(
  textComponent: Models.TextComponent | Models.TextComponentMap,
  textComponents: Models.TextComponentsMap | Models.TextComponents,
  textCache?: Models.TextCache,
): string {
  const getText = (_textComponent: Models.TextComponent | Models.TextComponentMap) => getCleanText(_textComponent);

  return findDuplicateId(textComponent, textComponents, getText, textCache);
}

export function findDocumentDuplicateId(
  document: Models.CombinedDocument,
  texts: Models.TextComponentsMap,
  images: Models.ImagesMap,
  textDocumentsCache?: Models.TextCache,
): string {
  if (isTextComponent(document)) {
    return findTextComponentDuplicateId(document, texts, textDocumentsCache);
  }

  if (isImage(document)) {
    return findImageDuplicateId(document, images);
  }

  return null;
}


export function findReferenceCitationDuplicateId(
  referenceCitation: Models.ReferenceCitation | Models.ReferenceCitationMap,
  referenceCitations: Models.ReferenceCitationsMap | Models.ReferenceCitations,
  textCache?: Models.TextCache,
): string {
  const getText = (_referenceCitation: Models.ReferenceCitation | Models.ReferenceCitationMap): ReturnType<typeof removeTags> =>
    removeTags(getValue(_referenceCitation, 'text'));

  return findDuplicateId(referenceCitation, referenceCitations, getText, textCache);
}

