import React from 'react';
/* eslint-disable max-len */

const Content = (): JSX.Element => (
  <svg viewBox="7 9 17 13">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M13,22 L18,22 L18,16 L13,16 L13,22 L13,22 Z M7,22 L12,22 L12,9 L7,9 L7,22 L7,22 Z M19,22 L24,22 L24,16 L19,16 L19,22 L19,22 Z M13,9 L13,15 L24,15 L24,9 L13,9 L13,9 Z" />
    </g>
  </svg>
);

export default Content;
