import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { ISelectItemProps } from './models';
import styles from './styles.module.scss';

const SelectItem: React.FC<ISelectItemProps> = ({ value, onChange, textByValue, values, emptyOptionText = '', className }) => (
  <div className={classNames(styles.SelectItemWrapper, className)}>
    <select
      className={styles.SelectItem}
      value={value}
      onChange={onChange}
    >
      {!value && <option value={null}>{emptyOptionText}</option>}
      {values.map(value => (
        <option key={value} value={value}>{_.invoke(textByValue, value) || value}</option>
      ))}
    </select>
  </div>
);

export default SelectItem;
