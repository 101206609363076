import * as classNames from 'classnames';
import * as React from 'react';

import BalanceLine from 'components/BalanceLine';
import createDndScrollZone from 'components/DndScrollZone';
import { ProjectsConfig, TOOLBAR_ELEMENT_ID } from 'const';
import ZoomWrapper from 'containers/ZoomWrapper';
import { useProjectType } from 'hooks/useProjectType';
import { createMediumVerticalStrength } from 'utils/dndScrollZone';
import { IMainProps } from './models';
import styles from './styles.module.scss';

const DnDScrollZone = createDndScrollZone<React.HTMLAttributes<HTMLElement>>('section');

const Main: React.FunctionComponent<IMainProps> = (props) => {
  const {
    artboardBackground,
    artboardHeight,
    showScreensPanel,
    assetPanel,
    content,
    screensPanel,
    showPreview,
    scale,
  } = props;
  const projectType = useProjectType();
  const { areScreensResizable } = ProjectsConfig[projectType];

  return (
    <div className={styles.Main}>
      <aside className={styles.screensPanel}> {screensPanel} </aside>
      <DnDScrollZone
        className={styles.content}
        style={{ background: artboardBackground }}
        verticalStrength={createMediumVerticalStrength}
      >
        <div
          className={classNames(styles.content__wrapper, {
            [styles.content__wrapper_hiddenScreensPanel]: !showScreensPanel,
          })}
        >
          <ZoomWrapper wrapContent={!showPreview}>
            {content}
          </ZoomWrapper>
          <BalanceLine top={areScreensResizable ? artboardHeight : undefined} scale={scale} />
        </div>
      </DnDScrollZone>
      <aside className={styles.assetPanel}> {assetPanel} </aside>
      <div className={styles.toolbar} id={TOOLBAR_ELEMENT_ID} />
    </div >
  );
};

export default Main;
