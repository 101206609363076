import classNames from 'classnames';
import React from 'react';

import { ActionsColor } from 'components/ArtboardLayoutActions/constants';
import Icon from 'components/Icon';
import { IconOwnProps } from 'components/Icon/models';
import { ActionIconProps } from './models';
import styles from './styles.module.scss';

const ActionIcon: React.FunctionComponent<ActionIconProps> = (props) => {
  const {
    backgroundColor = ActionsColor.BLUE,
    isDisabled,
    ...otherProps
  } = props;

  return (
    <span
      className={classNames(
        styles.ActionIcon,
        styles[`backgroundColor_${backgroundColor}`],
        {
          [styles.disabled]: isDisabled,
        },
      )}
    >
      <Icon isDisabled={isDisabled} {...otherProps as IconOwnProps}/>
    </span>
  );
};

export default ActionIcon;
