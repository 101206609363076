import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { EntityType } from 'const';
import { deleteReferenceCitationFromComponents } from 'containers/ArtboardCell/actions';
import { setArtboards } from 'containers/Artboards/actions';
import { artboards as artboardsSelector } from 'containers/Artboards/selectors';
import { deleteDocuments, setDocuments } from 'containers/Documents/actions';
import { setLayouts } from 'containers/Layouts/actions';
import { layeredLayouts as layeredLayoutsSelector } from 'containers/Layouts/selectors';
import { activeLayer as activeLayerSelector, projectType as projectTypeSelector } from 'containers/Project/selectors';
import { deleteComponents, setRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { screenDefinitions as screenDefinitionsSelector } from 'containers/ScreenDefinitions/selectors';
import { setSections } from 'containers/Sections/actions';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import * as surfacesSelectors from 'containers/Surfaces/selectors';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { ResetLayoutsWithoutDocumentArgs, resetLayoutsWithoutDocument } from 'services/processes';
import { intlGet } from 'utils/intlGet';
import { ensureLayoutOnSections } from 'utils/layouts/ensureLayoutOnSections';
import { deleteLayoutsFromArtboardsByDocumentIds } from 'utils/reusableLayouts/deleteLayoutsFromArtboards';
import { sagaFlow } from 'utils/sagaFlow';
import { Action } from '../models';
import { documents as documentsSelector } from '../selectors';
import { deleteEmptyGroupLayoutDocuments } from '../services/deleteEmptyGroupLayouts';
import { removeBackgroundImageFromAllStyles } from '../services/removeBackgroundImageFromAllStyles';

const TextByEntityType: PartialRecord<EntityType, { [key: string]: () => string }> = {
  Image: {
    title: () => intlGet('ConfirmationWindow.Title', 'DeleteImageComponent'),
    message: () => intlGet('ConfirmationWindow.Message', 'RemoveAllInstances'),
    successMessage: () => intlGet('Notification.Success', 'ImageSuccessfullyDeleted'),
  },
  ReferenceCitation: {
    title: () => intlGet('ConfirmationWindow.Title', 'DeleteReferenceCitation'),
    message: () => intlGet('ConfirmationWindow.Message', 'RemoveAllInstances'),
    successMessage: () => intlGet('Notification.Success', 'ReferenceCitationSuccessfullyDeleted'),
  },
  Text: {
    title: () => intlGet('ConfirmationWindow.Title', 'DeleteTextComponent'),
    message: () => intlGet('ConfirmationWindow.Message', 'RemoveAllInstances'),
    successMessage: () => intlGet('Notification.Success', 'TextSuccessfullyDeleted'),
  },
  SurfaceLayout: {
    title: () => intlGet('ConfirmationWindow.Title', 'DeleteReusableLayout'),
    message: () => intlGet('ConfirmationWindow.Message', 'RemoveReusableLayoutAndContent'),
    successMessage: () => intlGet('Notification.Success', 'ReusableLayoutSuccessfullyDeleted'),
  },
  GroupLayout: {
    title: () => intlGet('ConfirmationWindow.Title', 'DeleteGroupReusableLayout'),
    message: () => intlGet('ConfirmationWindow.Message', 'RemoveGroupReusableLayoutAndContent'),
    successMessage: () => intlGet('Notification.Success', 'GroupReusableLayoutSuccessfullyDeleted'),
  },
};

export function* deleteComponent(action: Action.IDeleteComponent) {
  try {
    const actions: Models.IAction[] = [];
    const { id, entityType } = action.payload;
    const { title, message, successMessage } = TextByEntityType[entityType];

    const accepted: boolean = yield call(
      [Notifications, Notifications.showConfirmation],
      {
        title: title(),
        message: message(),
        acceptLabel: intlGet('ConfirmationWindow.Button', 'Delete'),
        declineLabel: intlGet('ConfirmationWindow.Button', 'Cancel'),
      },
    );

    if (!accepted) {
      return;
    }

    switch (entityType) {
      case EntityType.IMAGE: {
        const {
          artboards,
          sections,
          layouts,
          relations,
        }: ReturnTypeSaga<typeof removeBackgroundImageFromAllStyles> = yield call(removeBackgroundImageFromAllStyles, id);

        actions.push(
          setArtboards(artboards),
          setSections(sections),
          setLayouts(layouts),
          setRelations(relations),
        );
        break;
      }

      case EntityType.REFERENCE_CITATION: {
        yield put(deleteReferenceCitationFromComponents(id));
        break;
      }

      case EntityType.GROUP_LAYOUT:
      case EntityType.LAYOUT: {
        const artboards = (yield select(artboardsSelector)).toJS() as Models.Artboards;
        const layouts = (yield select(layeredLayoutsSelector)).toJS() as Models.LayeredCombinedLayouts;
        const relations = (yield select(relationsSelector)).toJS() as Models.LayeredRelations;
        const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
        const sections = (yield select(sectionsSelector)).toJS() as Models.Sections;
        const activeLayer: ReturnTypeSaga<typeof activeLayerSelector> = yield select(activeLayerSelector);
        const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
        const screenDefinitions = (yield select(screenDefinitionsSelector)).toJS() as Models.MasterScreen.ScreenDefinitions;
        const screens = (yield select(surfacesSelectors.surfaces)).toJS() as Models.Screens;

        yield call(
          sagaFlow(
            deleteLayoutsFromArtboardsByDocumentIds,
            ensureLayoutOnSections,
            deleteEmptyGroupLayoutDocuments,
            arg => resetLayoutsWithoutDocument({ assets: arg } as ResetLayoutsWithoutDocumentArgs),
          ),
          {
            activeLayer,
            projectType,
            artboards,
            documents,
            layoutDocumentIds: [id],
            layouts,
            relations,
            screenDefinitions,
            screens,
            sections,
            // TODO: in methods which used data from several containers it's better to use project assets as argument
            surfaces: screens,
            project: {
              activeLayer,
              projectType,
            },
          },
        );

        actions.push(
          setArtboards(artboards),
          setLayouts(layouts),
          setRelations(relations),
          setSections(sections),
          setDocuments(documents),
        );
        break;
      }
    }

    actions.push(
      deleteDocuments([id]),
      deleteComponents([id]),
    );
    yield put(batchActions(actions));

    yield call([Notifications, Notifications.success], successMessage());
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.deleteComponent', 'DeleteComponent');
  }
}
