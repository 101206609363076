import _ from 'lodash';
import { call } from 'redux-saga/effects';

import * as Models from 'models';
import { deleteReferenceCitationsFromComponents } from 'services/deleteReferenceCitationsFromComponents';
import { isReferenceCitation } from 'utils/entityType';

// TODO: Revise, it should process both layers
export function* removeUnusedReferences<T extends Models.RefreshProcessArgs>(args: T): Generator<unknown, T> {
  const { assets, prevAssets } = args;
  const { documents, relations } = assets;
  const { documents: prevDocuments } = prevAssets;
  // get deleted reference citation ids
  const deletedReferenceCitationIds = _(prevDocuments)
    .pickBy((document, id) => !documents[id] && isReferenceCitation(document))
    .keys()
    .value();

  // remove deleted reference citations from components
  const deleteReferenceCitationResult: ReturnTypeSaga<typeof deleteReferenceCitationsFromComponents> = yield call(
    deleteReferenceCitationsFromComponents,
    deletedReferenceCitationIds,
    documents,
    relations,
  );

  assets.documents = deleteReferenceCitationResult.documents;
  assets.relations = deleteReferenceCitationResult.relations;

  return args;
}
