import Immutable from 'immutable';
import { createSelector } from 'reselect';

import * as Models from './models';

export const past = (state): Immutable.List<Models.State.IState> => state.get('past');
export const future = (state): Immutable.List<Models.State.IState> => state.get('future');

export const isUndoDisabled = createSelector([past], p => p.size === 0);
export const isRedoDisabled = createSelector([future], f => f.size === 0);
