import { takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { generateArtboardPreview } from './generateArtboardPreview';
import { updateCellsHeight } from './updateCellsHeight';

export default [
  takeEvery(ActionTypes.UPDATE_CELLS_HEIGHT, updateCellsHeight),
  takeLatest(ActionTypes.GENERATE_ARTBOARD_PREVIEW, generateArtboardPreview),
];
