import { ActionTypes } from './constants';
import * as Models from './models';

export const moveScreen: Models.ActionCreator.MoveScreen = (surfaceDefinitionId, position) => ({
  type: ActionTypes.MOVE_SCREEN,
  payload: {
    surfaceDefinitionId,
    position,
  },
});
