import Immutable from 'immutable';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';

import { updateArtboard } from 'containers/Artboards/actions';
import * as artboardSelectors from 'containers/Artboards/selectors';
import * as documentsSelectors from 'containers/Documents/selectors';
import { addLayouts, deleteLayouts } from 'containers/Layouts/actions';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import * as projectSelectors from 'containers/Project/selectors';
import { mergeRelations } from 'containers/Relations/actions';
import { layeredRelations as relationsSelector } from 'containers/Relations/selectors';
import { screenDefinitions as screenDefinitionsSelector } from 'containers/ScreenDefinitions/selectors';
import { addSections, deleteSections } from 'containers/Sections/actions';
import * as sectionsSelectors from 'containers/Sections/selectors';
import { updateSurface } from 'containers/Surfaces/actions';
import * as surfacesSelectors from 'containers/Surfaces/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { adjustScreenForNewDefinition } from 'services/validateScreenDefinitions';
import { Action } from '../models';

export function* updateSurfaceType(action: Action.IUpdateSurfaceType) {
  try {
    const { surfaceId, screenDefinitionId } = action.payload;
    const currentLayer: ReturnTypeSaga<typeof projectSelectors.activeLayer> = yield select(projectSelectors.activeLayer);
    const screens = (yield select(surfacesSelectors.surfaces)).toJS() as Models.Screens;
    const artboards = (yield select(artboardSelectors.artboards)).toJS() as Models.Artboards;
    const sections = (yield select(sectionsSelectors.sections)).toJS() as Models.Sections;
    const layouts = (yield select(layoutsSelectors.layeredLayouts)).toJS() as Models.LayeredCombinedLayouts;
    const layeredRelations = (yield select(relationsSelector)).toJS() as Models.LayeredRelations;
    const screenDefinitions = (yield select(screenDefinitionsSelector)).toJS() as Models.MasterScreen.ScreenDefinitions;
    const documents = (yield select(documentsSelectors.documents)).toJS() as Models.CombinedDocuments;

    const surface = screens[surfaceId];
    const updatedSurface: Models.Screen = { ...surface, screenDefinitionId };
    const actions: Models.IAction[] = [saveAppState(), updateSurface(updatedSurface)];

    const {
      artboardSectionsToSet,
      layoutsToMove,
      layoutsToCreateInEmptySections,
      deletedLayoutIds,
      relationsToUpdate,
      relationsToCreate,
      missingUsedSectionIds,
      updatedArtboard,
    } = adjustScreenForNewDefinition(
      updatedSurface,
      artboards,
      sections,
      layouts,
      layeredRelations,
      screenDefinitions,
      documents,
      currentLayer,
    );

    actions.push(addSections(Immutable.fromJS(artboardSectionsToSet)));
    actions.push(mergeRelations({ ...relationsToUpdate, ...relationsToCreate }));
    actions.push(deleteSections(missingUsedSectionIds));
    actions.push(addLayouts([...layoutsToMove, ...layoutsToCreateInEmptySections]));
    actions.push(deleteLayouts(deletedLayoutIds));
    actions.push(updateArtboard(updatedArtboard));

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'Surfaces.updateSurfaceType', 'UpdateSurfaceType');
  }
}
