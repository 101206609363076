import React from 'react';
/* eslint-disable max-len */

const Copy = (): JSX.Element => (
  <svg viewBox="0 0 19 19">
    <path d="M11.7894737,1.77635684e-15 L11.7905107,0.0735035758 C12.3197982,0.256124117 12.7,0.758661615 12.7,1.35 C12.7,1.94133838 12.3197982,2.44387588 11.7905107,2.62649642 L11.7894737,2.63636364 L11.7583948,2.6371255 C11.6295587,2.67796712 11.4923524,2.7 11.35,2.7 C11.2076476,2.7 11.0704413,2.67796712 10.9416052,2.6371255 L2.68421053,2.63636364 L2.68620126,11.4562548 C2.69529434,11.5195253 2.7,11.5842131 2.7,11.65 C2.7,11.7157869 2.69529434,11.7804747 2.68620126,11.8437452 L2.68421053,12.0909091 L2.62615169,12.0915086 C2.44324412,12.620276 1.94096671,13 1.35,13 C0.759033292,13 0.256755877,12.620276 0.0738483054,12.0915086 L-6.1284311e-14,12.0909091 L-6.1284311e-14,1.63636364 C-6.1284311e-14,0.736363636 0.757894737,1.77635684e-15 1.68421053,1.77635684e-15 L1.68421053,1.77635684e-15 L11.7894737,1.77635684e-15 Z M16.3157895,5.27272727 L7.05263158,5.27272727 C6.12631579,5.27272727 5.36842105,5.95909092 5.36842105,6.79797983 L5.36842105,17.4747477 C5.36842105,18.3136366 6.12631579,19.0000003 7.05263158,19.0000003 L16.3157895,19.0000003 C17.2421053,19.0000003 18,18.3136366 18,17.4747477 L18,6.79797983 C18,5.95909092 17.2421053,5.27272727 16.3157895,5.27272727 Z M15.3157896,16.32 L8.05263158,16.32 L8.05263158,7.95 L15.3157896,7.95 L15.3157896,16.32 Z" />
  </svg>
);

export default Copy;
