import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SSIActions from 'components/SSIActions';
import { SSIActionsDispatchProps, SSIActionsOwnProps, SSIActionsStateProps } from 'components/SSIActions/models';
import { toggleDragState } from 'containers/App/actions';
import { areActionsDisabled } from 'containers/App/selectors';
import { deleteSSI } from 'containers/Artboards/actions';
import { SSISource } from 'containers/Artboards/selectors';
import { showModal } from 'containers/ModalManager/actions';

const mapStateToProps = createStructuredSelector({
  SSISource,
  areActionsDisabled,
});

const mapDispatchToProps = {
  deleteSSI,
  showModal,
  toggleDragState,
};

export default connect<SSIActionsStateProps, SSIActionsDispatchProps, SSIActionsOwnProps>(mapStateToProps, mapDispatchToProps)(SSIActions);
