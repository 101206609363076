import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/AltTag';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { AltTagControlProps } from './models';

const AltTagControl: React.FunctionComponent<AltTagControlProps> = (props) => {
  const {
    isDisabled,
    altTag,
    toggleAltTag,
    className,
    controlState = ControlState,
    returnFocusToEditor,
    priority,
  } = props;

  const {
    control,
    isOpen,
    state,
    toggleDropdown,
  } = useControlWithDropdown({ priority, controlState, closeDropdownCallback: returnFocusToEditor });

  return (
    <Control
      className={className}
      controlState={controlState}
      disabled={isDisabled}
      onToggle={toggleDropdown}
      preventBlur={false}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.ALT_TAG}
      type={IconType.ALT_TAG}
    >
      {isOpen && <Dropdown altTag={altTag} toggleAltTag={toggleAltTag} />}
    </Control>
  );
};

export default AltTagControl;
