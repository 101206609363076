import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Artboard from 'components/Artboard';
import { areImagesBeingDownloaded } from 'containers/App/selectors';
import { artboardImagesLoaded, artboardMounted, artboardWillBeMounted, dropStoryCard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { projectFlatColors } from 'containers/BrandDefinition/selectors';
import { imagesForAssetsPanel } from 'containers/Documents/selectors';
import { extendedScreenSections, orderedActiveLayoutsBySectionId } from 'containers/Layouts/selectors';
import { screenWidth, sectionsHeight, sectionsWidthByName } from 'containers/ScreenDefinitions/selectors';

const mapStateToProps = createStructuredSelector({
  areImagesBeingDownloaded,
  artboard: activeArtboard,
  colors: projectFlatColors,
  images: imagesForAssetsPanel,
  layoutsBySectionId: orderedActiveLayoutsBySectionId,
  sections: extendedScreenSections,
  sectionsHeight,
  sectionsWidth: sectionsWidthByName,
  width: screenWidth,
});

const mapDispatchToProps = {
  artboardImagesLoaded,
  artboardMounted,
  artboardWillBeMounted,
  dropStoryCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Artboard);
