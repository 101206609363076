import SSIIcon from 'assets/images/SSI';
import React from 'react';

import styles from './styles.module.scss';

const SSIElementDragPreview: React.FC = () => (
  <div className={styles.ssiElementDragPreview}>
    <SSIIcon />
  </div>
);

export default SSIElementDragPreview;
