import React, { useMemo } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import useAbbreviation from '../../../hooks/useAbbreviation';
import AbbreviationDetails from '../../AbbreviationDetails';
import { changeImageAbbreviation, changeTextAbbreviation, removeImageAbbreviation, removeTextAbbreviation } from '../actions';
import { AbbreviationWithRelation } from '../types';

type ItemToRender = AbbreviationWithRelation & {
  key: string;
  abbreviation: Models.TextAbbreviationDocument['abbreviation'];
};

const handleAbbreviationChange = (item: ItemToRender): void => {
  const { relationId, relationType, abbreviationId, abbreviationNumber } = item;
  if ([Constants.EntityType.TEXT, Constants.EntityType.CALL_TO_ACTION].includes(relationType)) {
    return changeTextAbbreviation(relationId, abbreviationId, abbreviationNumber);
  }

  if (relationType === Constants.EntityType.IMAGE) {
    return changeImageAbbreviation(relationId, abbreviationId);
  }

  // eslint-disable-next-line no-console
  console.error('Wrong relation entityType', relationType);
};

const handleAbbreviationRemove = (item: ItemToRender): void => {
  const { relationId, relationType, abbreviationId, abbreviationNumber } = item;
  if ([Constants.EntityType.TEXT, Constants.EntityType.CALL_TO_ACTION].includes(relationType)) {
    return removeTextAbbreviation(relationId, abbreviationId, abbreviationNumber);
  }

  if (relationType === Constants.EntityType.IMAGE) {
    return removeImageAbbreviation(relationId, abbreviationId);
  }

  // eslint-disable-next-line no-console
  console.error('Wrong relation entityType', relationType);
};

type Props = {
  list: AbbreviationWithRelation[];
  onClose: () => void;
  className?: string;
};

const Panel: React.FunctionComponent<Props> = (props) => {
  const { list, onClose, className } = props;

  const abbreviationsIdList = useMemo(
    () => [...new Set(list.map(({ abbreviationId }) => abbreviationId))],
    [list],
  );
  const abbreviationDocuments = useAbbreviation(abbreviationsIdList);

  const itemsToRender = useMemo(
    (): ItemToRender[] => {
      const items: ItemToRender[] = [];

      list.forEach((value, idx) => {
        const { abbreviationId, abbreviationNumber, relationId, relationType } = value;
        const document = abbreviationDocuments.find(doc => doc.id.toString() === abbreviationId);
        if (!document) {
          return;
        }
        items.push({
          key: `${relationId}-${abbreviationId}-${idx}`,
          relationId,
          relationType,
          abbreviationId,
          abbreviationNumber,
          abbreviation: document.abbreviation,
        });
      });

      return [...items].sort(
        (a, b) => a.abbreviation.term.localeCompare(b.abbreviation.term)
          || a.abbreviation.definition.localeCompare(b.abbreviation.definition),
      );
    },
    [abbreviationDocuments],
  );

  return (
    <div className={className}>
      {itemsToRender.map((item) => {
        return (
          <AbbreviationDetails
            key={item.key}
            abbreviation={item.abbreviation}
            onChange={(): void => {
              onClose();
              handleAbbreviationChange(item);
            }}
            onRemove={(): void => {
              handleAbbreviationRemove(item);
            }}
          />
        );
      })}
    </div>
  );
};

export default Panel;
