import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReferenceCitationElement from 'components/ReferenceCitationElement';
import {
  ReferenceCitationElementActions,
  ReferenceCitationElementOwnProps,
  ReferenceCitationElementSelectors,
} from 'components/ReferenceCitationElement/models';
import { deleteReferenceCitationFromComponents } from 'containers/ArtboardCell/actions';
import { flatColorsByLayoutId, flatFontsByRelationId } from 'containers/Common/selectors';
import { updateReferenceRequest } from 'containers/Documents/actions';
import { documentIdsOrderBeforeReferenceElement, documents, referenceCitationsByReferenceElements } from 'containers/Documents/selectors';
import { setLayoutAndInstances } from 'containers/Layouts/actions';
import { isOpenToolbar, projectType } from 'containers/Project/selectors';
import { updateLayeredRelations, updateLayeredRelationsSilently } from 'containers/Relations/actions';
import { relationsByRegularLayoutId } from 'containers/Relations/selectors';
import { sectionsWidthByName } from 'containers/ScreenDefinitions/selectors';

const createMapStateToProps = createStructuredSelector({
  documentIdsOrderBeforeReferenceElement,
  documents,
  flatColorsByLayoutId,
  flatFontsByRelationId,
  isOpenToolbar,
  projectType,
  referenceCitationsByReferenceElements,
  relationsByRegularLayoutId,
  sectionsWidth: sectionsWidthByName,
});

const mapDispatchToProps = {
  deleteReferenceCitationFromComponents,
  setLayout: setLayoutAndInstances,
  updateReference: updateReferenceRequest,
  updateRelations: updateLayeredRelations,
  updateRelationsSilently: updateLayeredRelationsSilently,
};

export default connect<ReferenceCitationElementSelectors, ReferenceCitationElementActions, ReferenceCitationElementOwnProps>(
  createMapStateToProps,
  mapDispatchToProps,
)(ReferenceCitationElement);
