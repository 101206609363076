import { call, put, select, spawn } from 'redux-saga/effects';

import { MethodName } from 'const';
import { showPreview } from 'containers/App/selectors';
import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import * as ProjectSelectors from 'containers/Project/selectors';
import { getArtboardPreview } from 'services/api';
import { getUsedFontFaces } from 'services/getUsedFontFaces';
import { handleSagaError } from 'services/handleError';
import logger from 'services/logger';
import { setPreviewHtml } from '../actions';
import { Action } from '../models';

export function* generateArtboardPreview(action: Action.IGenerateArtboardPreview) {
  const logId = logger.performanceStart();
  const methodName = MethodName.PREVIEW_PROJECT;

  try {
    const { screenId } = action.payload;
    const artboardJson: ReturnTypeSaga<typeof getArtboardsJson> = yield call(getArtboardsJson, [screenId]);
    const usedFontFaces: ReturnTypeSaga<typeof getUsedFontFaces> = yield call(getUsedFontFaces);
    const projectType: ReturnTypeSaga<typeof ProjectSelectors.projectType> = yield select(ProjectSelectors.projectType);
    const helperFileName: ReturnTypeSaga<typeof ProjectSelectors.helperFileName> = yield select(ProjectSelectors.helperFileName);
    const { data: { html, htmlUnicodeSize } }: ReturnTypeSaga<typeof getArtboardPreview> = yield call(
      getArtboardPreview,
      artboardJson,
      usedFontFaces,
      projectType,
      helperFileName,
    );

    const isPreview: ReturnTypeSaga<typeof showPreview> = yield select(showPreview);
    if (!isPreview) {
      return;
    }

    yield put(setPreviewHtml(screenId, html, htmlUnicodeSize));

    yield spawn([logger, logger.performanceEnd], logId, { methodName });
  } catch (error) {
    yield spawn([logger, logger.error], error, logId, { methodName });
    yield call(handleSagaError, error, 'ArtboardPreview.generateArtboardPreview', 'GenerateArtboardPreview');
  }
}
