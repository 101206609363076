import { call, put, select } from 'redux-saga/effects';

import { documents as documentsSelector } from 'containers/Documents/selectors';
import { DocumentMap } from 'models';
import { getDocumentAvailability } from 'services/api';
import { updateModalWindowOptions } from '../actions';
import { Action } from '../models';
import { isUpdateDocumentAction } from '../utils';

export function* showModal(action: Action.IShowModal) {
  try {
    if (!isUpdateDocumentAction(action)) {
      return;
    }

    const { options: { documentId: id }, id: modalWindowId } = action.payload;
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const documentId = (documents.get(id) as DocumentMap).get('documentId');

    const { data: isDocumentAvailableForUpdating }: ReturnTypeSaga<typeof getDocumentAvailability> = yield call(getDocumentAvailability, documentId);

    if (isDocumentAvailableForUpdating) {
      yield put(updateModalWindowOptions(modalWindowId, { allowUpdateExisting: isDocumentAvailableForUpdating }));
    }

  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during updating modal window options.', error);
  }
}
