import _ from 'lodash';
import React from 'react';

import ArtboardLayoutContainer from 'containers/ArtboardLayoutContainer';
import GroupLayout from 'containers/GroupLayout';
import { GroupLayoutMap, LayoutMap } from 'models';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { ArtboardLayoutContainerListProps } from './models';

const ArtboardLayoutContainerList: React.FunctionComponent<ArtboardLayoutContainerListProps> = (props) => {
  const {
    documents,
    getLayoutsRefs,
    groupLayoutId,
    isGroupLayoutWithOneChild,
    isSectionActive,
    layoutIds,
    layouts,
    moveableLayoutId,
    relationsByRegularLayoutId,
    saveLayoutRef = _.noop,
    section,
    skipFirstLayout = false,
    ssi,
    toggleDragState,
  } = props;
  const hasOneChildLayout = layouts.size === 1;

  return (
    <>
      {
        layouts.map((layout, idx) => {
          if (skipFirstLayout && idx === 0) {
            return null;
          }

          const layoutId = layout.get('id');
          const position = layoutIds.findIndex(id => layout.get('id') === id);

          switch (true) {
            case isGroupLayout(layout): return (
              <GroupLayout
                getLayoutsRefs={getLayoutsRefs}
                isSectionActive={isSectionActive}
                key={layoutId}
                layout={layout as GroupLayoutMap}
                position={position}
                saveLayoutRef={saveLayoutRef}
                section={section}
                ssi={ssi}
              />
            );
            default: return (
              <ArtboardLayoutContainer
                disableDragAndDelete={isGroupLayoutWithOneChild}
                documents={documents}
                groupLayoutId={groupLayoutId}
                isFirst={idx === 0}
                isLastLayout={hasOneChildLayout}
                isSectionActive={isSectionActive}
                key={layoutId}
                layout={layout as LayoutMap}
                layoutId={layoutId}
                layoutRelations={relationsByRegularLayoutId && relationsByRegularLayoutId.get(layoutId)}
                moveableArtboardLayoutId={moveableLayoutId}
                position={position}
                saveLayoutRef={saveLayoutRef}
                sectionDisplayName={section && section.get('displayName')}
                sectionName={section && section.get('name')}
                sectionStyles={section && section.get('brandStyles')}
                ssi={ssi}
                toggleDragState={toggleDragState}
              />
            );
          }
        })
      }
    </>
  );
};

export default ArtboardLayoutContainerList;
