import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';

interface ReusableLayoutReferences {
  artboardId: string;
  sectionId: string;
  position: number;
}

/**
 * NOTE: doesn't return nested references, but only references for artboards.
 * If RL is used within some GRL, then empty references will be returned
 */
export function whereUsed(
  layoutDocumentIds: string[],
  layouts: Models.LayeredCombinedLayouts,
  artboards: Models.Artboards,
  layer: Layer,
): Record<string, ReusableLayoutReferences[]> {
  return layoutDocumentIds.reduce(
    (referencesByDocumentId, layoutDocumentId) => {
      const layoutIds = _(layouts).pickBy(layout => layoutDocumentId === layout.documentId[layer]).keys().value();
      const references: ReusableLayoutReferences[] = [];

      _.forEach(artboards, (artboard) => {
        const { id: artboardId, layoutIds: artboardLayoutIds } = artboard;

        artboardLayoutIds.forEach((layoutId, position) => {
          if (layoutIds.includes(layoutId)) {
            const { section: sectionId } = layouts[layoutId];

            references.push({
              artboardId,
              sectionId,
              position,
            });
          }
        });
      });

      referencesByDocumentId[layoutDocumentId] = references;

      return referencesByDocumentId;
    },
    {} as Record<string, ReusableLayoutReferences[]>,
  );
}
