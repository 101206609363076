import { MapDispatchToPropsFunction, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import UploadProject from 'components/ModalWindows/UploadProject';
import { UploadProjectActions, UploadProjectOwnProps, UploadProjectSelectors } from 'components/ModalWindows/UploadProject/models';
import { hideModal } from 'containers/ModalManager/actions';
import { uploadToPromoMats } from 'containers/Project/actions';
import { isProjectTranslatable } from 'containers/Project/selectors';

const mapStateToProps = createStructuredSelector({
  isProjectTranslatable,
});

const mapDispatchToProps: MapDispatchToPropsFunction<UploadProjectActions, UploadProjectOwnProps> = (dispatch, props) => ({
  hideModal: () => dispatch(hideModal(props.modalWindowId)),
  uploadToPromoMats: (uploadProjectType, translationComplete) => dispatch(uploadToPromoMats(uploadProjectType, translationComplete)),
});

export default connect<UploadProjectSelectors, UploadProjectActions, UploadProjectOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UploadProject);
