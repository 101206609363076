import classNames from 'classnames';
import _ from 'lodash';
import AbbreviationsListLayout from 'modules/Abbreviations/components/AbbreviationsListLayout';
import React from 'react';
import { DndComponentClass, DragSource } from 'react-dnd';
import ArtboardLayout from 'components/ArtboardLayout';
import { DragSourceType, LayoutType } from 'const';
import ReferenceCitationElement from 'containers/ReferenceCitationElement';
import Spacer from 'containers/Spacer';
import { ArtboardLayoutDragObject, ColumnRelationMap } from 'models';
import { isEmptyLayout } from 'utils/layouts/isLayoutEmpty';
import { isLayoutScrollable } from 'utils/layouts/isLayoutScrollable';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import {
  ArtboardLayoutDragSource,
  ArtboardReusableLayoutDragSource,
  GroupLayoutReusableLayoutDragSource,
  artboardLayoutCollector,
  artboardReusableLayoutCollector,
  groupLayoutReusableLayoutCollector,
} from './dnd';
import * as Models from './models';
import styles from './styles.module.scss';
import { shouldDisableDragAndDeleteActions } from './utils';

class ArtboardLayoutContainer extends React.PureComponent<Models.ArtboardLayoutProps, Models.ArtboardLayoutState> {
  readonly state: Models.ArtboardLayoutState = {
    artboardLayoutContainer: null,
    isDraggable: false,
  };

  componentDidUpdate() {
    const isDraggable = (this.props.moveableArtboardLayoutId === this.props.layout.get('id'));
    this.setState({ isDraggable });
  }

  private saveLayoutRef = (element: HTMLDivElement): void => {
    const { saveLayoutRef, layout } = this.props;

    saveLayoutRef(layout.get('id'), element);
    this.setState({ artboardLayoutContainer: element });
  };

  private renderArtboardLayout() {
    const {
      connectGroupLayoutReusableLayoutDragSource,
      connectLayoutDragSource,
      connectReusableLayoutDragSource,
      documents,
      groupLayoutId,
      isFirst,
      isSectionActive,
      layout,
      layoutId,
      sectionDisplayName,
      sectionName,
      sectionStyles,
      ssi,
    } = this.props;
    const { artboardLayoutContainer } = this.state;
    const disableDragAndDeleteActions = shouldDisableDragAndDeleteActions(this.props);

    const connectDragSource = groupLayoutId
      ? connectGroupLayoutReusableLayoutDragSource
      : isReusableLayout(layout, documents)
        ? connectReusableLayoutDragSource
        : connectLayoutDragSource;

    // TODO: why????
    const dragSource = this.state.isDraggable ? connectDragSource : (i: JSX.Element) => i;

    switch (layout.get('type')) {
      case LayoutType.SPACER: {
        return <Spacer connectDragSource={dragSource} layout={layout} notEditable={!!ssi} />;
      }

      case LayoutType.REFERENCE_CITATION_ELEMENT: {
        return (
          <ReferenceCitationElement
            artboardLayoutContainer={artboardLayoutContainer}
            connectDragSource={dragSource}
            layout={layout}
            layoutId={layoutId}
            notEditable={!!ssi}
            sectionName={sectionName}
            ssi={ssi}
          />
        );
      }

      case LayoutType.ABBREVIATIONS_LIST: {
        return (
          <AbbreviationsListLayout
            artboardLayoutContainer={artboardLayoutContainer}
            connectDragSource={dragSource}
            layout={layout}
          />
        );
      }

      default: {
        return (
          <ArtboardLayout
            artboardLayoutContainer={artboardLayoutContainer}
            connectDragSource={dragSource}
            disableDragAndDelete={disableDragAndDeleteActions}
            groupLayoutId={groupLayoutId}
            isFirst={isFirst}
            isSectionActive={isSectionActive}
            layout={layout}
            layoutId={layoutId}
            sectionDisplayName={sectionDisplayName}
            sectionName={sectionName}
            sectionStyles={sectionStyles}
            ssi={ssi}
          />
        );
      }
    }
  }

  render() {
    const { position, layout, ssi, layoutRelations, documents, groupLayoutId } = this.props;
    const isScrollable = isLayoutScrollable(layout);
    const isStretchingLayout =
      layoutRelations &&
      isEmptyLayout(layout, layoutRelations, documents) &&
      !isScrollable &&
      !(layoutRelations.get(layout.get('relationId')) as ColumnRelationMap).getIn(['styles', 'rowsHeight']).every(Boolean)
      && layout.get('type') !== LayoutType.ABBREVIATIONS_LIST;

    return (
      <div
        ref={this.saveLayoutRef}
        className={classNames(styles.ArtboardLayoutContainer, {
          [styles.spacerContainer]: layout.get('type') === LayoutType.SPACER,
          [styles.insideOfSSI]: !!ssi && !groupLayoutId,
          [styles.stretchingLayout]: isStretchingLayout,
          [styles.scrollable]: isScrollable,
        })}
        style={{ order: position }}
      >
        {this.renderArtboardLayout()}
      </div>
    );
  }
}

export default _.flow(
  DragSource<Models.ArtboardLayoutOwnProps, Models.GroupLayoutReusableLayoutCollectedProps, ArtboardLayoutDragObject>(
    DragSourceType.GROUP_LAYOUT_REUSABLE_LAYOUT,
    GroupLayoutReusableLayoutDragSource,
    groupLayoutReusableLayoutCollector,
  ),
  DragSource<Models.ArtboardLayoutOwnProps, Models.ArtboardReusableLayoutCollectedProps, ArtboardLayoutDragObject>(
    DragSourceType.ARTBOARD_REUSABLE_LAYOUT,
    ArtboardReusableLayoutDragSource,
    artboardReusableLayoutCollector,
  ),
  DragSource<Models.ArtboardLayoutOwnProps, Models.ArtboardLayoutCollectedProps, ArtboardLayoutDragObject>(
    DragSourceType.ARTBOARD_LAYOUT,
    ArtboardLayoutDragSource,
    artboardLayoutCollector,
  ),
)(ArtboardLayoutContainer) as unknown as DndComponentClass<typeof ArtboardLayoutContainer, Models.ArtboardLayoutOwnProps>;
