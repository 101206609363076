import React from 'react';

import BoxInput from 'components/Toolbar/Shared/BoxInput';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { intlGet } from 'utils/intlGet';
import { PaddingProps } from './models';
import styles from './styles.module.scss';

const Padding: React.FunctionComponent<PaddingProps> = (props) => {
  const { padding, togglePadding, title } = props;

  return (
    <Dropdown
      className={styles.PaddingDropdown}
      stopClickPropagation={true}
    >
      <div className={styles.title}>{intlGet('EditorToolbar', title)}</div>
      <BoxInput
        property={padding}
        toggleProperty={togglePadding}
        step={5}
        isShorthand={false}
      />
    </Dropdown>
  );
};

export default Padding;
