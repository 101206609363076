import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    SAVE_APP_STATE: null,
    UNDO: null,
    REDO: null,
    CANCEL: null,
  },
  'UndoRedoControl',
);
