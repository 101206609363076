import { createSelector } from 'reselect';

import { DEFAULT_ARTBOARD_BACKGROUND, Layer } from 'const';
import * as Constants from 'const';
import { rootDocument, targetCountry, targetLanguage, targetProduct } from 'containers/RootDocument/selectors';
import * as Models from 'models';
import { getCurrentMetadataByLayer } from 'utils/getCurrentMetadataByLayer';
import { toJS } from 'utils/immutable';
import { isProjectTranslatable as projectTranslatable } from 'utils/isProjectTranslatable';
import { getOppositeLayer } from 'utils/layers';
import { State } from './models';

export const project = (
  state: Models.AppState.StateMap,
): State.IState => state.get('project');

export const activeLayer = (
  state: Models.AppState.StateMap,
): Constants.Layer => project(state).get('activeLayer');

export const originalLanguage = state => project(state).get('originalLanguage');
export const originalCountry = state => project(state).get('originalCountry');
export const originalProduct = state => project(state).get('originalProduct');
export const suppressTranslation = state => project(state).get('suppressTranslation');
export const activeSurfaceId = state => project(state).get('activeSurfaceId');
export const artboardBackgroundFromMasterScreen = state => project(state).get('artboardBackground') || DEFAULT_ARTBOARD_BACKGROUND;
export const projectType = state => project(state).get('projectType');
export const helperFileName = state => project(state).get('helperFileName');
export const surfaceIds = state => project(state).get('surfaceIds');
export const lastRefreshTime = state => project(state).get('lastRefreshTime');
export const lastLocalSaveTime = state => project(state).get('lastLocalSaveTime');
export const isLocalSaveInProgress = state => project(state).get('isLocalSaveInProgress');
export const isAutoSaveActive = state => project(state).get('isAutoSaveActive');
export const areDetailsLoading = state => project(state).get('areDetailsLoading');
export const documentIdsAreBeingUploaded = state => project(state).get('documentIdsAreBeingUploaded');
export const activePreviewOptionName = state => project(state).get('activePreviewOptionName');
export const placeholderMinHeight = state => project(state).get('placeholderMinHeight');
export const isOpenToolbar = state => project(state).get('isOpenToolbar');

export const nonActiveLayer = createSelector(activeLayer, getOppositeLayer);

export const isProjectTranslatable = createSelector(
  [rootDocument, project, suppressTranslation],
  (rootDocument, project, suppressTranslation) =>
    !suppressTranslation && projectTranslatable({ rootDocument: toJS(rootDocument), project: toJS(project) },
    ),
);

export const isRefreshAvailable = createSelector(
  [isLocalSaveInProgress],
  isLocalSaveInProgress => !isLocalSaveInProgress,
);

export const isUploadAvailable = createSelector(
  [isLocalSaveInProgress, documentIdsAreBeingUploaded],
  (isLocalSaveInProgress, documentIdsAreBeingUploaded) => !isLocalSaveInProgress && documentIdsAreBeingUploaded.size === 0,
);

export const currentLanguage = createSelector([activeLayer, originalLanguage, targetLanguage, isProjectTranslatable], getCurrentMetadataByLayer);

export const currentProduct = createSelector([activeLayer, originalProduct, targetProduct, isProjectTranslatable], getCurrentMetadataByLayer);

export const currentCountry = createSelector([activeLayer, originalCountry, targetCountry, isProjectTranslatable], getCurrentMetadataByLayer);

export const languageByLayer = (layer: Layer) => createSelector(
  [originalLanguage, targetLanguage, isProjectTranslatable],
  (originalLanguage, targetLanguage, isProjectTranslatable) =>
    getCurrentMetadataByLayer(layer, originalLanguage, targetLanguage, isProjectTranslatable),
);

export const countryByLayer = (layer: Layer) => createSelector(
  [originalCountry, targetCountry, isProjectTranslatable],
  (originalCountry, targetCountry, isProjectTranslatable) =>
    getCurrentMetadataByLayer(layer, originalCountry, targetCountry, isProjectTranslatable),
);

export const productByLayer = (layer: Layer) => createSelector(
  [originalProduct, targetProduct, isProjectTranslatable],
  (originalProduct, targetProduct, isProjectTranslatable) =>
    getCurrentMetadataByLayer(layer, originalProduct, targetProduct, isProjectTranslatable),
);
