import React from 'react';
/* eslint-disable max-len */

const Delete = (): JSX.Element => (
  <svg viewBox="7 6 18 18">
    <path d="M8,14.2727273 C7.44771525,14.2727273 7,13.825012 7,13.2727273 L7,12.3636364 C7,11.8113516 7.44771525,11.3636364 8,11.3636364 L10.2,11.363 L10.2,9 C10.2,7.8954305 11.0954305,7 12.2,7 L17.8,7 C18.9045695,7 19.8,7.8954305 19.8,9 L19.8,11.363 L22,11.3636364 C22.5522847,11.3636364 23,11.8113516 23,12.3636364 L23,13.2727273 C23,13.825012 22.5522847,14.2727273 22,14.2727273 L21.4,14.2727273 L21.4,14.2727273 L21.4,20.0909091 C21.4,21.6975556 19.9673112,23 18.2,23 L11.8,23 C10.0326888,23 8.6,21.6975556 8.6,20.0909091 L8.6,14.2727273 L8,14.2727273 Z M13.4,14.2727273 L11.8,14.2727273 L11.8,20.0909091 L13.399,20.0906364 L13.4,14.2727273 Z M18.2,14.2727273 L16.6,14.2727273 L16.6,20.0906364 L18.2,20.0909091 L18.2,14.2727273 Z" />
  </svg>
);

export default Delete;
