import Immutable from 'immutable';

import { Reducer } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

export const screenDefinitionsInitialState = Immutable.Map() as Models.State.IState;

const screenDefinitionsReducer: Reducer<Models.State.IState> = (state = screenDefinitionsInitialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SCREEN_DEFINITIONS: return setScreenDefinitions(state, action);
    default: return state;
  }
};

export const setScreenDefinitions: Reducer<Models.State.IState, Models.Action.ISetScreenDefinitions> = (state, action) => {
  const { screenDefinitions } = action.payload;

  return Immutable.fromJS(screenDefinitions);
};

export default screenDefinitionsReducer;
