import Immutable from 'immutable';
import _ from 'lodash';

import { AssetsCollectionNavItem, ModuleBundleNavItems, StoryCardNavItems, TextCollectionNavItems } from 'const';
import * as Models from 'models';
import { isMagicForm, isModuleBundle, isStoryCard, isTextCollection, isTextComponent } from 'utils/entityType';
import { filterGroupLayouts } from 'utils/filterGroupLayouts';
import { filterImages } from 'utils/filterImages';
import { filterReferenceCitations } from 'utils/filterReferenceCitations';
import { filterReusableLayouts } from 'utils/filterReusableLayouts';
import { filterTextComponents } from 'utils/filterTextComponents';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { AssetsCollectionEntities, FilteredDocuments } from './models';

export function internalDocumentsExist(
  entities: AssetsCollectionEntities,
  document: Models.AssetsCollectionMap,
): boolean {
  const navItems = (isStoryCard(document) || isMagicForm(document))
    ? StoryCardNavItems
    : isModuleBundle(document)
      ? ModuleBundleNavItems
      : TextCollectionNavItems;

  return _(entities)
    .filter(entity => navItems.includes(entity.type))
    .some(({ documents }) => documents.size !== 0);
}

export function getDisabledTabs(entities: AssetsCollectionEntities): AssetsCollectionNavItem[] {
  return _.reduce(
    entities,
    (disabledTabs, { documents, type }) => {
      if (documents.size === 0) {
        disabledTabs.push(type);
      }

      return disabledTabs;
    },
    [],
  );
}

export function getNavItems(document: Models.AssetsCollectionMap): AssetsCollectionNavItem[] {
  switch (true) {
    case isMagicForm(document):
    case isStoryCard(document): return StoryCardNavItems;
    case isTextCollection(document): return TextCollectionNavItems;
    case isModuleBundle(document): return ModuleBundleNavItems;

    default: return [];
  }
}

export function getActiveTab(tabs: AssetsCollectionNavItem[], disabledTabs: AssetsCollectionNavItem[]): AssetsCollectionNavItem {
  return tabs.find(tab => !disabledTabs.includes(tab));
}

export function getAssetsCollectionEntities(
  document: Models.AssetsCollectionMap,
  screenOrder: number,
): AssetsCollectionEntities {
  const documents = document.get('documents') || Immutable.Map() as Models.StoryCardsMap;
  const entities = (document as Models.StoryCardMap).get('entities');
  const { images, referenceCitations, texts, layouts } = getFilteredScreenDocuments(documents, entities, screenOrder);

  return {
    [AssetsCollectionNavItem.IMAGES]: {
      documents: images,
      type: AssetsCollectionNavItem.IMAGES,
    },
    [AssetsCollectionNavItem.REFERENCE_CITATIONS]: {
      documents: referenceCitations,
      type: AssetsCollectionNavItem.REFERENCE_CITATIONS,
    },
    [AssetsCollectionNavItem.TEXTS]: {
      documents: texts,
      type: AssetsCollectionNavItem.TEXTS,
    },
    [AssetsCollectionNavItem.LAYOUTS]: {
      documents: layouts,
      type: AssetsCollectionNavItem.LAYOUTS,
    },
  };
}

function getFilteredScreenDocuments(
  documents: Models.CombinedDocumentsMap,
  entities: Models.StoryCardEntitiesMap,
  screenOrder: number,
): FilteredDocuments {
  const screenDocuments = getScreenDocuments(documents, entities, screenOrder);

  return getFilteredDocuments(screenDocuments);
}

function getScreenDocuments(
  documents: Models.CombinedDocumentsMap,
  entities: Models.StoryCardEntitiesMap,
  screenOrder: number,
): Models.CombinedDocumentsMap {
  if (entities) {
    const screens = entities.get('screens');
    const artboards = entities.get('artboards');
    const layouts = entities.get('layouts');
    const relations = entities.get('relations');
    const screenId = entities.getIn(['screenIds', screenOrder - 1]);
    const artboardId = screens && screenId && screens.getIn([screenId, 'artboardId']);
    const artboardLayouts = artboardId && getFlattenedLayouts(artboards.get(artboardId), layouts);
    const plainLayoutIds = artboardLayouts && artboardLayouts.filter(isPlainLayout).keySeq().toList();
    const layoutIds = artboardLayouts && artboardLayouts.keySeq().toArray();

    const relationsList = plainLayoutIds && plainLayoutIds
      .map(id => getFlattenedRelations(layouts.get(id) as Models.LayoutMap, relations))
      .flatten(true) as Immutable.List<Models.RegularRelationMap>;
    let documentIds = relationsList.map((relation => relation.get('documentId'))).toArray();

    // we need to get reusable layouts that used on current screen
    const layoutDocIds = _(layoutIds).map(id => layouts.getIn([id, 'documentId'])).compact().uniq().value();

    // we have no relations for reference citations, so need to take their ids from text components
    relationsList.forEach((relation) => {
      const documentId = relation.get('documentId');
      const document = documents.get(documentId);

      if (isTextComponent(document)) {
        const referenceCitationIds = (document as Models.TextComponentMap).get('referenceCitations').toArray();
        documentIds.push(...referenceCitationIds);
      }
    });

    documentIds = _(documentIds).compact().uniq().value();
    const allDocIds = documentIds.concat(layoutDocIds);

    return documents.filter((_, documentId) => allDocIds.includes(documentId));
  }

  return documents;
}

function getFilteredDocuments(documents: Models.CombinedDocumentsMap): FilteredDocuments {
  const referenceCitations = filterReferenceCitations(documents);
  const texts = filterTextComponents(documents);
  const images = filterImages(documents);
  const layouts = filterReusableLayouts(documents)
    .merge(filterGroupLayouts(documents))
    .sortBy(document => document.get('name').trim(), (a, b) => a.localeCompare(b)) as Models.CombinedLayoutsMap;

  return {
    images,
    referenceCitations,
    texts,
    layouts,
  };
}
