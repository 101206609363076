import * as React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import { EntityType } from 'const';
import TextCollection from 'containers/TextCollection';
import { TextCollectionMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { TextCollectionListProps } from './models';
import styles from './styles.module.scss';

const TextCollectionList: React.FunctionComponent<TextCollectionListProps> = (props) => {
  const {
    items,
    showUpdatedTextCollections,
    toggleUpdatedDocumentsDisplaying,
    updatedInPromoMats,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({
    toggleUpdatedDocumentsDisplaying,
    showUpdatedDocuments: showUpdatedTextCollections,
    entityType: EntityType.TEXT_COLLECTION,
  });

  return items && items.size > 0 && (
    <div className={styles.TextCollectionList} data-title="text-collections">
      <Header
        title={intlGet('Assets', 'TextCollections', { count: items.size })}
        showUpdatesHint={updatedInPromoMats && showUpdatedTextCollections}
        expanded={expanded}
        onClick={toggle}
      />
      {
        expanded &&
        <List
          elements={items}
          renderElement={(item: TextCollectionMap, index) => (
            <TextCollection key={index} document={item} />
          )}
        />
      }
    </div>
  );
};

export default TextCollectionList;
