import React from 'react';

import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { intlGet } from 'utils/intlGet';
import { AltTagDropdownProps } from './models';
import styles from './styles.module.scss';

const AltTagDropdown: React.FunctionComponent<AltTagDropdownProps> = (props) => {
  const {
    altTag,
    toggleAltTag,
  } = props;
  const altTagRef = React.useRef(altTag);

  React.useEffect(
    () => { altTagRef.current = altTag; },
    [altTag],
  );

  React.useEffect(
    () => () => {
      const { current: altTag } = altTagRef;

      toggleAltTag(altTag);
    },
    [],
  );

  const onAltTagChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    toggleAltTag(event.target.value);
  };

  return (
    <Dropdown className={styles.AltTagDropdown} stopClickPropagation={true}>
      <div className={styles.title}>{intlGet('EditorToolbar.AltTag', 'Title')}</div>
      <div className={styles.container}>
        <textarea
          maxLength={255}
          autoFocus={true}
          className={styles.textarea}
          onChange={onAltTagChange}
          placeholder={intlGet('EditorToolbar.AltTag', 'Placeholder')}
          value={altTag}
        />
      </div>
    </Dropdown>
  );
};

export default AltTagDropdown;
