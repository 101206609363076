import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { useEffect } from 'react';
import * as Models from 'models';

export const BULLET_COLOR_COMMAND = createCommand<Models.BrandColorMap | undefined>();

type Props = {
  onChange: (color: Models.BrandColorMap | undefined) => void;
};

export function BulletColorPlugin(props: Props): null {
  const { onChange } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      BULLET_COLOR_COMMAND,
      (value) => {
        // eslint-disable-next-line no-console
        console.error('NOT IMPLEMENTED', value);

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => onChange(undefined);
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
