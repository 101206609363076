import _ from 'lodash';

import * as Models from 'models';
import { intlGet } from 'utils/intlGet';

export enum ScreenFormatType {
  CUSTOM = 'Custom',
  HALF_PAGE = 'HalfPage',
  LEADERBOARD = 'Leaderboard',
  MEDIUM_RECTANGLE = 'MediumRectangle',
  MOBILE_LEADERBOARD = 'MobileLeaderboard',
  MOBILE_LEADERBOARD_1 = 'MobileLeaderboard1',
  WIDE_SKYSCRAPER = 'WideSkyscraper',
}

export const PredefinedScreenFormats: Models.ScreenFormat<Models.PredefinedScreenFormatType>[] = [
  {
    height: 90,
    width: 728,
    type: ScreenFormatType.LEADERBOARD,
  },
  {
    height: 250,
    width: 300,
    type: ScreenFormatType.MEDIUM_RECTANGLE,
  },
  {
    height: 600,
    width: 300,
    type: ScreenFormatType.HALF_PAGE,
  },
  {
    height: 600,
    width: 160,
    type: ScreenFormatType.WIDE_SKYSCRAPER,
  },
  {
    height: 50,
    width: 320,
    type: ScreenFormatType.MOBILE_LEADERBOARD,
  },
  {
    height: 50,
    width: 300,
    type: ScreenFormatType.MOBILE_LEADERBOARD_1,
  },
];

export const PredefinedScreenFormatByType = _.keyBy(PredefinedScreenFormats, ({ type }) => type) as Models.PredefinedScreenFormatByType;

export const ScreenFormatNameByType: Record<ScreenFormatType, () => string> = {
  [ScreenFormatType.CUSTOM]:  () => intlGet('ScreenFormat.Name', 'Custom'),
  [ScreenFormatType.HALF_PAGE]: () => intlGet('ScreenFormat.Name', 'HalfPage'),
  [ScreenFormatType.LEADERBOARD]: () => intlGet('ScreenFormat.Name', 'Leaderboard'),
  [ScreenFormatType.MEDIUM_RECTANGLE]: () => intlGet('ScreenFormat.Name', 'MediumRectangle'),
  [ScreenFormatType.MOBILE_LEADERBOARD]:  () => intlGet('ScreenFormat.Name', 'MobileLeaderboard'),
  [ScreenFormatType.MOBILE_LEADERBOARD_1]:  () => intlGet('ScreenFormat.Name', 'MobileBanner'),
  [ScreenFormatType.WIDE_SKYSCRAPER]:  () => intlGet('ScreenFormat.Name', 'WideSkyscraper'),
};

export const MIN_SCREEN_FORMAT_HEIGHT = 50;
export const MIN_SCREEN_FORMAT_WIDTH = 50;
export const FORMAT_SIZE_DELIMITER = '\u00D7';
