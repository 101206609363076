import { CombinedDocumentsMap, TextComponentMap, TextComponentsMap } from 'models';
import { isTextComponent } from './entityType';
import { getTextFromHtml } from './html';

const compareOptions: Intl.CollatorOptions = {
  numeric: false,
  sensitivity: 'base',
  caseFirst: 'lower',
};

export const filterTextComponents = (documents: CombinedDocumentsMap): TextComponentsMap => {
  return documents
    .filter<TextComponentMap>(isTextComponent)
    .sortBy(
      document => getTextFromHtml(document.get('text').trim()),
      (a, b) => a.localeCompare(b, undefined, compareOptions),
    );
};
