import filenamify from 'filenamify';

import { MAX_DOCUMENT_NAME_LENGTH } from 'const';
import { getDecodedTextFromHtml } from './html';

function generateReferenceCitationName(referenceCitationText: string): string {
  const clearText = getDecodedTextFromHtml(referenceCitationText).substring(0, MAX_DOCUMENT_NAME_LENGTH);

  return `REFERENCE_${clearText}`.substring(0, MAX_DOCUMENT_NAME_LENGTH);
}

export function generateReferenceCitationDocumentName(referenceCitationText: string): string {
  const referenceCitationName = generateReferenceCitationName(referenceCitationText);

  return `${filenamify(referenceCitationName, { replacement: '' })}.ReferenceCitation`;
}
