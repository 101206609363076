import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_RELATIONS: null,
    DELETE_COMPONENTS: null,
    DELETE_RELATIONS: null,
    MERGE_RELATIONS: null,
    REPLACE_DOCUMENT_ID_IN_RELATIONS: null,
    SET_RELATIONS: null,
    UPDATE_LAYERED_RELATIONS: null,
    UPDATE_LAYERED_RELATIONS_SILENTLY: null,
    UPDATE_LAYOUT_ASSETS_SILENTLY: null,
    UPDATE_RELATION: null,
    UPDATE_RELATIONS: null,
  },
  'Relations',
);
