import _ from 'lodash';

import * as Models from 'models';
import { isImmutable } from 'utils/immutable';
import { isLayoutScrollable } from 'utils/layouts/isLayoutScrollable';

export function updateScrollable(layout: Models.Layout, value?: boolean): Models.Layout;
export function updateScrollable(layout: Models.LayoutMap, value?: boolean): Models.LayoutMap;
export function updateScrollable(layout: Models.LayoutMap | Models.Layout, value?: boolean): Models.LayoutMap | Models.Layout {
  const scrollable = value === undefined ? isLayoutScrollable(layout) : value;

  if (isImmutable(layout)) {
    return layout.withMutations((
      _layout => _layout
        .setIn(['styles', 'scrollable'], scrollable)
    ));
  }

  return _.chain(layout)
    .cloneDeep()
    .set(['styles', 'scrollable'], scrollable)
    .value();
}
