import React from 'react';
/* eslint-disable max-len */

const ToolbarBorder = (): JSX.Element => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <defs>
      <path id="ToolbarBorderPath-1" d="M11,5.77777778 L9,5.77777778 L9,7.66666667 L11,7.66666667 L11,5.77777778 Z M11,9.55555556 L9,9.55555556 L9,11.44444444 L11,11.44444444 L11,9.55555556 Z M15,9.55555556 L13,9.55555556 L13,11.44444444 L15,11.44444444 L15,9.55555556 Z M1,2 L1,19 L19,19 L19,2 L1,2 Z M17,17.1111111 L3,17.1111111 L3,3.88888889 L17,3.88888889 L17,17.1111111 Z M11,13.3333333 L9,13.3333333 L9,15.2222222 L11,15.2222222 L11,13.3333333 Z M7,9.55555556 L5,9.55555556 L5,11.44444444 L7,11.44444444 L7,9.55555556 Z"/>
    </defs>
    <mask id="ToolbarBorderMask-1">
      <use href="#ToolbarBorderPath-1" />
    </mask>
    <use href="#ToolbarBorderPath-1" />
    <g mask="url(#ToolbarBorderMask-1)">
      <rect x="0" y="0" width="20" height="20" />
    </g>
  </svg>
);

export default ToolbarBorder;
