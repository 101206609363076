import Editor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import React, { useMemo } from 'react';
import * as Constants from 'const';
import * as editorUtils from 'utils/editor';

type Props = {
  editorState: Draft.EditorState;
};

export const TextDetailsEditor: React.FunctionComponent<Props> = (props) => {
  const { editorState } = props;

  // we map unordered list item to div to disable the list element styling
  const blockRenderMap = useMemo(() => Draft.DefaultDraftBlockRenderMap.set(
    Constants.TextHorizontalAlignmentType.UNORDERED_LIST,
    { element: 'div' },
  ), []);

  return (
    <Editor
      editorState={editorState}
      readOnly={true}
      onChange={undefined}
      customStyleMap={Constants.CUSTOM_SCRIPT_STYLES}
      customStyleFn={editorUtils.getCustomInlineStyleForFontStyleAndWeightOnly}
      blockRenderMap={blockRenderMap}
    />
  );
};
