import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select, spawn } from 'redux-saga/effects';
import guid from 'uuid';

import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import {
  brandDefinition,
  projectBrandStyles,
  projectFlatColors,
  projectFlatFonts as projectFlatFontsSelector,
} from 'containers/BrandDefinition/selectors';
import { UIFontFaces } from 'containers/Common/selectors';
import { addDocuments } from 'containers/Documents/actions';
import * as documentsSelectors from 'containers/Documents/selectors';
import { linkDocument } from 'containers/Documents/services/linkDocument';
import { addLayouts, deleteLayouts } from 'containers/Layouts/actions';
import * as layoutsSelectors from 'containers/Layouts/selectors';
import { activeLayer, projectType as projectTypeSelector } from 'containers/Project/selectors';
import { textComponents } from 'containers/ProjectPanel/selectors';
import { addRelations, deleteRelations } from 'containers/Relations/actions';
import { relations as relationsSelector } from 'containers/Relations/selectors';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { sections as screenDefinitionSectionsSelector } from 'containers/ScreenDefinitions/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { createGroupLayout, createLayout } from 'factories/layoutFactory';
import * as Models from 'models';
import { appendCustomFontFaces } from 'services/appendFontFace';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { mergeBrandDefinitions } from 'utils/brandStyles/mergeBrandDefinitions';
import { isMagicForm } from 'utils/entityType';
import { findDuplicateIdByDocumentId } from 'utils/findDocumentDuplicateId';
import { toJS } from 'utils/immutable';
import { makeRelationsLayered } from 'utils/layers';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { isEmptyPlainLayout } from 'utils/layouts/isLayoutEmpty';
import { isPlainLayout } from 'utils/layouts/isPlainLayout';
import { isReferenceCitationElement } from 'utils/layouts/isReferenceCitationElement';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { applyStyles } from 'utils/reusableLayouts/applyStyles';
import { getUsingLayoutFontsNotExistsInProject } from 'utils/reusableLayouts/getUsingLayoutFontsNotExistsInProject';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { getScreenDefinitionSectionStylesBySectionName } from 'utils/screenDefinitions/getScreenDefinitionSectionStylesBySectionName';
import { Action } from '../models';
import { processLayout } from '../services/processLayout';

function resetRowsHeight(relations: Models.Relations): Models.Relations {
  _.forEach(relations, (relation) => {
    if (!isColumnRelation(relation)) {
      return;
    }

    relation.styles.rowsHeight = relation.styles.rowsHeight.map(() => null);
  });

  return relations;
}

export function* dropStoryCard(action: Action.IDropStoryCard) {
  try {
    const { storyCardId, screenOrder } = action.payload;
    const screenDefinitionSections: ReturnTypeSaga<typeof screenDefinitionSectionsSelector> = yield select(screenDefinitionSectionsSelector);
    const screenDefinitionSectionNames = screenDefinitionSections
      .map(screenDefinitionSection => screenDefinitionSection.get('name'))
      .toJS() as string[];
    const screenDefinitionSectionStylesBySectionName = getScreenDefinitionSectionStylesBySectionName(screenDefinitionSections);

    const relationsInProject: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
    const projectLayouts = (yield select(layoutsSelectors.layouts)).toJS() as Models.CombinedLayouts;
    const screenSectionsByName: ReturnTypeSaga<typeof layoutsSelectors.activeScreenSectionsByName> = yield select(
      layoutsSelectors.activeScreenSectionsByName,
    );
    const sectionsInProject: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
    const layoutsBySectionId: ReturnTypeSaga<typeof layoutsSelectors.orderedActiveLayoutsBySectionId> = yield select(
      layoutsSelectors.orderedActiveLayoutsBySectionId,
    );
    const layoutsBySectionName = layoutsBySectionId.mapKeys(sectionId => sectionsInProject.get(sectionId).get('name'));

    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);
    const documents: ReturnTypeSaga<typeof documentsSelectors.documents> = yield select(documentsSelectors.documents);
    const textsForDuplicatesChecking: ReturnTypeSaga<typeof textComponents> = yield select(textComponents);
    const images: ReturnTypeSaga<typeof documentsSelectors.imagesForAssetsPanel> = yield select(documentsSelectors.imagesForAssetsPanel);
    const colors: ReturnTypeSaga<typeof projectFlatColors> = yield select(projectFlatColors);
    const projectFlatFonts: ReturnTypeSaga<typeof projectFlatFontsSelector> = yield select(projectFlatFontsSelector);
    const availableForUserBrandStyles: ReturnTypeSaga<typeof projectBrandStyles> = yield select(projectBrandStyles);
    const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;
    const projectType: ReturnTypeSaga<typeof projectTypeSelector> = yield select(projectTypeSelector);
    const referenceElementExists: ReturnTypeSaga<typeof layoutsSelectors.isReferenceCitationElementOnArtboard> = yield select(
      layoutsSelectors.isReferenceCitationElementOnArtboard,
    );

    const storyCard = documents.get(storyCardId) as Models.MagicFormMap | Models.StoryCardMap;
    const storyCardEntities = storyCard.get('entities').toJS() as Models.StoryCardEntities;
    const storyCardDocuments = storyCard.get('documents');
    const storyCardNumber = storyCard.get('number');
    const {
      screenIds,
      screens,
      artboards,
      sections,
      layouts,
      relations,
      projectType: storyCardProjectType,
    } = storyCardEntities;

    if (storyCardProjectType !== projectType) {
      // eslint-disable-next-line no-console
      console.warn(`Imported Story Card (${
        storyCardNumber
      }, ${
        storyCardProjectType
      }) has different project type with the target project (${projectType})`);
    }

    // first Story Card screen
    const screenToMerge = screens[screenIds[screenOrder ? screenOrder - 1 : 0]];
    const artboardToMerge = artboards[screenToMerge.artboardId];
    const artboardLayouts = getFlattenedLayouts(artboardToMerge, layouts);

    const relationsToMerge = {} as Models.Relations;
    const newRelationIdByOldId: Record<string, string> = {};
    // map of already processed Story Card document ids: { [id: sting]: string }
    const newDocumentIdByOldDocumentId = {} as Record<string, string>;
    const documentsToMerge = {} as Models.CombinedDocuments;
    const textDocumentsCache = {} as Models.TextCache;
    const referenceCitationsCache = {} as Models.TextCache;
    let newLayoutsFontsNotExistsInProject: string[] = [];

    const layoutsToMergeBySectionName: Record<string, Models.CombinedLayout[]> = _(artboardLayouts)
      .omitBy(layout => isEmptyPlainLayout(layout, relations, storyCardDocuments))
      .groupBy(layout => layout.section)
      .mapKeys((layout, sectionId) => sections[sectionId].name)
      .pick(screenDefinitionSectionNames)
      .mapValues(layouts => referenceElementExists ? layouts.filter(layout => !isReferenceCitationElement(layout)) : layouts)
      .pickBy(layouts => !_.isEmpty(layouts))
      .value();

    // no sense to do anything if there are no appropriate layouts in Story Card
    if (_.isEmpty(layoutsToMergeBySectionName)) {
      // eslint-disable-next-line no-console
      console.log('There are no appropriate layouts on the first screen in Story Card.');

      return;
    }

    // delete empty layouts where new layouts from Story Card will be dropped to
    const relationIdsToDelete = [] as string[];
    const layoutIdsToDelete = _.reduce(
      layoutsToMergeBySectionName,
      (layoutIdsToDelete, layoutsToMerge, sectionName) => {
        const layouts = layoutsBySectionName.get(sectionName);
        const layout = layouts.first<Models.LayoutMap>();

        if (layouts.size === 1 && isEmptyPlainLayout(layout, relationsInProject, documents)) {
          const relationIds = getFlattenedRelations(layout, relationsInProject).keySeq().toArray();

          layoutIdsToDelete.push(layout.get('id'));
          relationIdsToDelete.push(...relationIds);
        }

        return layoutIdsToDelete;
      },
      [] as string[],
    );

    const alreadyDroppedReusableLayoutsRelationIds: string[] = [];
    const newLayoutIdByOldLayoutId: Record<string, string> = {};
    const layoutsSectionNames = _.keys(layoutsToMergeBySectionName);
    for (let i = 0; i < layoutsSectionNames.length; i++) {
      const sectionName = layoutsSectionNames[i];
      const sectionStyles = screenDefinitionSectionStylesBySectionName.get(sectionName);
      const layouts = layoutsToMergeBySectionName[sectionName].filter<Models.Layout>(isPlainLayout);
      const groupLayouts = layoutsToMergeBySectionName[sectionName].filter<Models.GroupLayout>(isGroupLayout);

      const sectionId = screenSectionsByName.getIn([sectionName, 'id']) as string;

      const newLayouts = [] as Models.Layout[];
      for (let j = 0; j < layouts.length; j++) {
        const layout = layouts[j];
        const isReusable = isReusableLayout(layout, storyCardDocuments);
        const layoutRelations = getFlattenedRelations(layout, relations);
        const shouldLookForDuplicates = !isReusable;
        const shouldProcessBrandStyles = !isReusable;

        const { layout: copiedLayout }: ReturnTypeSaga<typeof processLayout> = yield call(
          processLayout,
          {
            layout,
            relations: layoutRelations,
            documents: storyCardDocuments,
            textsForDuplicatesChecking,
            imagesForDuplicatesChecking: images,
            brandStyles: availableForUserBrandStyles,
            sectionStyles,
            colors,
            fonts: projectFlatFonts,
            shouldLookForDuplicates,
            shouldProcessBrandStyles,
            relationsToMerge,
            documentsToMerge,
            textDocumentsCache,
            referenceCitationsCache,
            newRelationIdByOldId,
            newDocumentIdByOldDocumentId,
          },
        );

        // create a new layout
        // If layout is reusable and there is no existing layout document we need to copy document for it into redux also
        if (isReusable) {
          const reusableLayoutDocument = storyCardDocuments.get(copiedLayout.documentId);
          const existingDocId = findDuplicateIdByDocumentId(reusableLayoutDocument, documents);

          if (existingDocId) {
            copiedLayout.documentId = existingDocId;
          } else if (reusableLayoutDocument) {
            documentsToMerge[reusableLayoutDocument.get('id')] = reusableLayoutDocument.toJS() as Models.CombinedDocument;
          } else {
            copiedLayout.documentId = null;
          }
        }

        const existingLayout = copiedLayout.documentId && _.find(
          projectLayouts,
          ({ documentId }) => documentId && documentId === copiedLayout.documentId,
        ) as Models.Layout;

        const [newLayoutBase, newLayoutBaseRelationId, newLayoutId] = (existingLayout && isReusable)
          ? [existingLayout, existingLayout.relationId, guid()]
          : [copiedLayout, copiedLayout.relationId, copiedLayout.id];

        const newLayout = createLayout({
          ...newLayoutBase,
          id: newLayoutId,
          section: sectionId,
          relationId: newLayoutBaseRelationId,
        });

        newLayouts.push(newLayout);
        newLayoutIdByOldLayoutId[layout.id] = newLayout.id;

        if (isReusable) {
          const projectDocuments = documents.toJS() as Models.CombinedDocuments;
          const [relationsToUse, documentsToUse] = existingLayout
            ? [relationsInProject.toJS() as Models.Relations, projectDocuments]
            : [relationsToMerge, { ...projectDocuments, ...documentsToMerge }];

          const relations = getFlattenedRelations(newLayout, relationsToUse);

          if (existingLayout) {
            alreadyDroppedReusableLayoutsRelationIds.push(..._.keys(relations));
          }

          const layeredLayoutRelations = makeRelationsLayered(layoutRelations, layer);
          const layoutDocumentsIds = _(relations).map(rel => isRegularRelation(rel) && rel.documentId).uniq().compact().value();
          const layoutDocuments = _.pick(documentsToUse, layoutDocumentsIds);

          const layoutDocument = documentsToUse[newLayout.documentId] as Models.ReusableLayout;
          layoutDocument.documents = layoutDocuments;
          layoutDocument.entities.relations = (isMagicForm(storyCard) && !existingLayout) // do not reset heights for already dropped layouts
            ? resetRowsHeight(relations)
            : relations;
          layoutDocument.entities.relationId = newLayoutBaseRelationId;
          layoutDocument.entities.styles.backgroundImage.id = newLayout.styles.backgroundImage.id;
          layoutDocument.entities.brandDefinition = mergeBrandDefinitions(projectBrandDefinition, layoutDocument.entities.brandDefinition);

          newLayoutsFontsNotExistsInProject = newLayoutsFontsNotExistsInProject.concat(
            getUsingLayoutFontsNotExistsInProject(
              layeredLayoutRelations,
              projectBrandDefinition,
              projectLayouts,
              [newLayout.documentId],
              layer,
            ),
          );

          const { layoutDocument: { entities: { relations: styledLayoutRelations } } } = applyStyles(
            {
              layoutDocument,
              projectBrandDefinition,
            },
            true,
          );

          _.merge(
            relationsToMerge,
            _.mapValues(styledLayoutRelations, rel => isRegularRelation(rel) ? _.set(rel, 'styles', rel.styles) : rel),
          );
        }
      }

      const newGroupLayouts = groupLayouts.map((groupLayout) => {
        const newGroupLayoutId = guid();
        let { documentId, styles } = groupLayout;
        newLayoutIdByOldLayoutId[groupLayout.id] = newGroupLayoutId;

        const groupLayoutDocument = storyCardDocuments.get(documentId);
        const existingGroupLayoutDocumentId = findDuplicateIdByDocumentId(groupLayoutDocument, documents);

        if (existingGroupLayoutDocumentId) {
          const existingGroupLayoutDocument = documents.get(existingGroupLayoutDocumentId) as Models.GroupLayoutDocumentMap;

          documentId = existingGroupLayoutDocumentId;
          styles = toJS(existingGroupLayoutDocument.getIn(['entities', 'styles']));
        } else {
          documentsToMerge[groupLayoutDocument.get('id')] = groupLayoutDocument.toJS() as Models.CombinedDocument;
        }

        return createGroupLayout({
          ...groupLayout,
          documentId,
          styles,
          section: sectionId,
          id: newGroupLayoutId,
          layoutIds: groupLayout.layoutIds.map(oldId => newLayoutIdByOldLayoutId[oldId]),
        });
      });

      layoutsToMergeBySectionName[sectionName] = [...newLayouts, ...newGroupLayouts];
    }

    const layoutsToMerge = _.flatMap(layoutsToMergeBySectionName);
    const layoutIdsToMerge = artboardToMerge.layoutIds.map(oldLayoutId => newLayoutIdByOldLayoutId[oldLayoutId]).filter(Boolean);
    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    const artboardLayoutIds = artboard.get('layoutIds').filter(layoutId => !layoutIdsToDelete.includes(layoutId)).concat(layoutIdsToMerge);
    const orderedArtboardLayoutIds = artboardLayoutIds
      .map(id => projectLayouts[id] || _.find(layoutsToMerge, { id }))
      .sort((layoutA, layoutB) => {
        const sectionNameA = sectionsInProject.getIn([layoutA.section, 'name']);
        const sectionNameB = sectionsInProject.getIn([layoutB.section, 'name']);

        const sectionIndexA = screenDefinitionSectionNames.findIndex(name => name === sectionNameA);
        const sectionIndexB = screenDefinitionSectionNames.findIndex(name => name === sectionNameB);

        return sectionIndexA !== -1 && sectionIndexB !== -1
          ? sectionIndexA - sectionIndexB
          : 0;
      })
      .map(item => item.id);

    const updatedArtboard = artboard.set('layoutIds', orderedArtboardLayoutIds);

    if (isMagicForm(storyCard)) {
      resetRowsHeight(_.omit(relationsToMerge, alreadyDroppedReusableLayoutsRelationIds));
    }

    yield put(batchActions([
      saveAppState(),
      updateArtboard(updatedArtboard),
      addLayouts(layoutsToMerge),
      addRelations(relationsToMerge),
      addDocuments(documentsToMerge),
      deleteLayouts(layoutIdsToDelete),
      deleteRelations(relationIdsToDelete),
    ]));

    const fontFaces: ReturnTypeSaga<typeof UIFontFaces> = yield select(UIFontFaces);
    appendCustomFontFaces(fontFaces.toJS());

    if (newLayoutsFontsNotExistsInProject.length > 0) {
      yield spawn([Notifications, Notifications.showMissingFonts], _.uniq(newLayoutsFontsNotExistsInProject));
    }

    const rootDocument: ReturnTypeSaga<typeof rootDocumentSelector> = yield select(rootDocumentSelector);
    linkDocument(rootDocument, storyCard.toJS() as Models.StoryCard).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(`Error occurred during linking imported Story Card (${storyCardNumber}, ${storyCardProjectType}) to the Project`, error);
    });

  } catch (error) {
    yield call(handleSagaError, error, 'Artboards.dropStoryCard', 'DropStoryCard');
  }
}
