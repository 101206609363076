import _ from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { RenditionType } from 'const';
import * as Models from 'models';
import { getDocumentRendition } from 'services/api';
import { runEventChanel } from 'services/EventChannel';
import { SubscribeFunction } from 'services/EventChannel/models';
import { ImageCache } from 'services/imageCache';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';

export function* updateImagesPreviews(
  images: Models.Image[],
  action: (id: string, imageInternalInfo: Models.ImageInternalInfo) => Models.IAction,
  needToStop = _.stubFalse, // any function that returns boolean value
): SagaIterator {
  try {
    const subscribeFunction: SubscribeFunction<ReturnType<typeof action>, Models.Image> = async (
      emitter,
      isDone,
      image,
    ) => {
      let imageInternalInfo: ReturnTypeSaga<typeof getImageInternalInfo> = null;
      const { documentId, id, renditions } = image;

      try {
        const lowRenditionChecksum = _.get(renditions, [RenditionType.LOW, 'checksum']);
        const imageCache = ImageCache.getInstance();
        imageInternalInfo = imageCache.getItem(RenditionType.LOW, lowRenditionChecksum) as Models.ImageInternalInfo;

        if (_.isEmpty(imageInternalInfo)) {
          const imageSource = await getDocumentRendition(documentId, lowRenditionChecksum, RenditionType.LOW);
          imageInternalInfo = await getImageInternalInfo(imageSource);
          imageCache.setItem(RenditionType.LOW, lowRenditionChecksum, imageInternalInfo);
        } else {
          // todo: check if we can remove this completely (test)
          imageInternalInfo = await getImageInternalInfo(imageInternalInfo.source);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error occurred during updating image preview (${documentId}).`, error);
      }

      emitter({
        action: action(id, imageInternalInfo),
        done: isDone(),
      });
    };

    yield call(runEventChanel, images, subscribeFunction, { needToStop });
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during updating images previews.', error);
  }
}
