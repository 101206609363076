import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isColumnRelation(
  entity: Models.RowRelation | Models.ColumnRelation | Models.RegularRelation | Models.LayeredRegularRelation,
): entity is Models.ColumnRelation;
export function isColumnRelation(
  entity: Models.RowRelationMap | Models.ColumnRelationMap | Models.RegularRelationMap | Models.LayeredRegularRelationMap,
): entity is Models.ColumnRelationMap;
export function isColumnRelation(
  entity: Models.ColumnRelation | unknown,
): entity is Models.ColumnRelation;
export function isColumnRelation(
  entity: Models.ColumnRelationMap | unknown,
): entity is Models.ColumnRelationMap;
export function isColumnRelation(entity): boolean {
  if (!entity) {
    return false;
  }

  return getValue(entity, 'entityType') === EntityType.COLUMN;
}
