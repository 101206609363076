import { DropTargetCollector, DropTargetSpec } from 'react-dnd';

import { DragSourceType } from 'const';
import * as Models from 'models';
import { SectionAllOwnProps, SectionCollectedProps, SectionProps } from './models';

export const LayoutDragSourceTypes: DragSourceType[] = [
  DragSourceType.ARTBOARD_GROUP_LAYOUT,
  DragSourceType.ARTBOARD_LAYOUT,
  DragSourceType.ARTBOARD_REUSABLE_LAYOUT,
  DragSourceType.GROUP_LAYOUT,
  DragSourceType.GROUP_LAYOUT_REUSABLE_LAYOUT,
  DragSourceType.LAYOUT,
  DragSourceType.REUSABLE_LAYOUT,
];

export const SSIDragSourceTypes: DragSourceType[] = [
  DragSourceType.ARTBOARD_SSI,
  DragSourceType.SSI,
];

export const allowedToDropItems: DragSourceType[] = [
  ...LayoutDragSourceTypes,
  ...SSIDragSourceTypes,
];

export const sectionDropTarget: DropTargetSpec<SectionProps> = {
  drop(props, monitor) {
    const { addLayout, moveLayout, section, addSSI, moveSSI, dropReusableLayout, dropGroupReusableLayout } = props;
    const itemType = monitor.getItemType() as DragSourceType;
    const sectionId = section.get('id');

    switch (itemType) {
      case DragSourceType.ARTBOARD_GROUP_LAYOUT:
      case DragSourceType.ARTBOARD_LAYOUT:
      case DragSourceType.ARTBOARD_REUSABLE_LAYOUT:
      case DragSourceType.GROUP_LAYOUT_REUSABLE_LAYOUT: {
        const { layout } = monitor.getItem() as Models.ArtboardLayoutDragObject | Models.ArtboardGroupLayoutDragObject;
        moveLayout(layout.get('id'), sectionId);
        break;
      }
      case DragSourceType.ARTBOARD_SSI: {
        moveSSI(sectionId);
        break;
      }
      case DragSourceType.GROUP_LAYOUT: {
        const { document, storyCardId } = monitor.getItem() as Models.LayoutDragObject;
        dropGroupReusableLayout(document.get('id'), sectionId, storyCardId);
        break;
      }
      case DragSourceType.LAYOUT: {
        const { type, size } = monitor.getItem() as Models.EmptyLayoutDragObject;
        addLayout(type, size, sectionId);
        break;
      }
      case DragSourceType.REUSABLE_LAYOUT: {
        const { document, storyCardId } = monitor.getItem() as Models.LayoutDragObject;
        dropReusableLayout(document.get('id'), sectionId, storyCardId);
        break;
      }
      case DragSourceType.SSI: {
        addSSI(sectionId);
        break;
      }
    }
  },
  canDrop(props, monitor) {
    const itemType = monitor.getItemType() as DragSourceType;

    return monitor.isOver({ shallow: true }) && allowedToDropItems.includes(itemType);
  },
};

export const collect: DropTargetCollector<SectionCollectedProps, SectionAllOwnProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
};
