import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { AutoSaveExceptions } from 'const';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { isAutoSaveActive } from 'containers/Project/selectors';
import { IAction, ProjectAssetsToUpload, ProjectAssetsToUploadMap } from 'models';
import { deleteInternalInfo } from 'utils/deleteInternalInfo';
import { saveProjectLocally } from '../actions';

let currentAssets: ProjectAssetsToUploadMap;

export function* autoSave(action: IAction) {
  try {
    const { type: currentActionType } = action;

    const autoSaveActive: ReturnTypeSaga<typeof isAutoSaveActive> = yield select(isAutoSaveActive);

    if (!autoSaveActive) {
      return;
    }

    const previousAssets = currentAssets;
    currentAssets = yield call(getProjectAssets, { removeInternalInfo: false, toJS: false });

    if (!previousAssets || AutoSaveExceptions.has(currentActionType) || previousAssets === currentAssets) {
      return;
    }

    const convertedCurrentAssets = deleteInternalInfo(currentAssets.toJS() as ProjectAssetsToUpload);

    if (!_.isEqual(deleteInternalInfo(previousAssets.toJS() as ProjectAssetsToUpload), convertedCurrentAssets)) {
      yield put(saveProjectLocally({ showNotifications: false, projectAssets: convertedCurrentAssets }));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during auto save.', error);
  }
}
