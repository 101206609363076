import { INSUFFICIENT_ACCESS_HELP } from 'const';
import { intlGet } from 'utils/intlGet';
import * as ErrorType from './ErrorType';

export enum ErrorHelpMessageType {
  HELP_OPTIONS = 'HelpOptions',
  CONTACT_SUPPORT = 'ContactSupport',
  SAVE_LOCALLY = 'SaveLocally',
}

// PromoMats errors
export const PromoMatsErrorMessageByErrorType: PartialRecord<ErrorType.PromoMats, () => string> = {
  // eslint-disable-next-line max-len
  [ErrorType.PromoMats.USERNAME_OR_PASSWORD_INCORRECT]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.USERNAME_OR_PASSWORD_INCORRECT),
  [ErrorType.PromoMats.USER_LOCKED_OUT]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.USER_LOCKED_OUT),
  [ErrorType.PromoMats.INVALID_SESSION_ID]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.INVALID_SESSION_ID),
  [ErrorType.PromoMats.INSUFFICIENT_ACCESS]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.INSUFFICIENT_ACCESS),
  [ErrorType.PromoMats.OPERATION_NOT_ALLOWED]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.OPERATION_NOT_ALLOWED),
  [ErrorType.PromoMats.EXCEEDS_FILE_MAX_SIZE]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.EXCEEDS_FILE_MAX_SIZE),
  [ErrorType.PromoMats.API_LIMIT_EXCEEDED]: () => intlGet('Notification.PromoMatsError.Message', ErrorType.PromoMats.API_LIMIT_EXCEEDED),
};

export const PromoMatsErrorHelpMessageByErrorType: PartialRecord<ErrorType.PromoMats, () => string> = {
  [ErrorType.PromoMats.INVALID_SESSION_ID]: () => intlGet('Notification.PromoMatsError.HelpMessage', ErrorType.PromoMats.INVALID_SESSION_ID),
  [ErrorType.PromoMats.INSUFFICIENT_ACCESS]: () => intlGet('Notification.PromoMatsError.HelpMessage', ErrorType.PromoMats.INSUFFICIENT_ACCESS),
  [ErrorType.PromoMats.INSUFFICIENT_ACCESS_UPLOAD]:
    () => intlGet(
      'Notification.PromoMatsError.HelpMessage',
      ErrorType.PromoMats.INSUFFICIENT_ACCESS_UPLOAD,
      { insufficientAccessHelp: INSUFFICIENT_ACCESS_HELP },
    ),
  [ErrorType.PromoMats.OPERATION_NOT_ALLOWED]: () => intlGet('Notification.PromoMatsError.HelpMessage', ErrorType.PromoMats.OPERATION_NOT_ALLOWED),
  [ErrorType.PromoMats.EXCEEDS_FILE_MAX_SIZE]: () => intlGet('Notification.PromoMatsError.HelpMessage', ErrorType.PromoMats.EXCEEDS_FILE_MAX_SIZE),
};

interface ErrorInfo {
  title(action?: string): string;
  message?(): string;
  helpType?: ErrorHelpMessageType;
}

type ErrorInfoType = ErrorType.ProjectConfiguration | ErrorType.Assembler;

// all errors except PromoMats errors
export const ErrorInfoByErrorType: Record<ErrorInfoType, ErrorInfo> = {
  // project configuration errors
  [ErrorType.ProjectConfiguration.MULTIPLE_JSON_FILES]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'MoreThanOneProjectFileFound'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.MULTIPLE_JSON_FILES),
  },
  [ErrorType.ProjectConfiguration.INVALID_JSON_FILE]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnsupportedFileType'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_JSON_FILE),
  },
  [ErrorType.ProjectConfiguration.ASSEMBLER_7_JSON_FILE]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'Assembler7ProjectFileFound'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.ASSEMBLER_7_JSON_FILE),
  },
  [ErrorType.ProjectConfiguration.MULTIPLE_MASTER_SCREENS]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'MoreThanOneMasterFound'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.MULTIPLE_MASTER_SCREENS),
  },
  [ErrorType.ProjectConfiguration.NO_MASTER_SCREEN]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnableToOpenThisProject'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.NO_MASTER_SCREEN),
  },
  [ErrorType.ProjectConfiguration.INVALID_MASTER_SCREEN]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnsupportedFileType'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_MASTER_SCREEN),
  },
  [ErrorType.ProjectConfiguration.INVALID_PREVIEW_OPTION_NAME]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'MasterScreenConfiguratedIncorrectly'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_PREVIEW_OPTION_NAME),
  },
  [ErrorType.ProjectConfiguration.NO_SCREEN_DEFINITION]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'IncompleteStarterProject'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.NO_SCREEN_DEFINITION),
  },
  [ErrorType.ProjectConfiguration.INVALID_SCREEN_DEFINITION]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnsupportedFileType'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_SCREEN_DEFINITION),
  },
  [ErrorType.ProjectConfiguration.MULTIPLE_SCREEN_TEMPLATES]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'StarterProjectConfiguredIncorrectly'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.MULTIPLE_SCREEN_TEMPLATES),
  },
  [ErrorType.ProjectConfiguration.NO_SCREEN_TEMPLATE]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'IncompleteStarterProject'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.NO_SCREEN_TEMPLATE),
  },
  [ErrorType.ProjectConfiguration.INVALID_SCREEN_TEMPLATE]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnsupportedFileType'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_SCREEN_TEMPLATE),
  },
  [ErrorType.ProjectConfiguration.MULTIPLE_BRAND_STYLES]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'StarterProjectConfiguredIncorrectly'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.MULTIPLE_BRAND_STYLES),
  },
  [ErrorType.ProjectConfiguration.INVALID_BRAND_STYLES]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnsupportedFileType'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_BRAND_STYLES),
  },
  [ErrorType.ProjectConfiguration.MULTIPLE_BRAND_DEFINITIONS]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'StarterProjectConfiguredIncorrectly'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.MULTIPLE_BRAND_DEFINITIONS),
  },
  [ErrorType.ProjectConfiguration.INVALID_BRAND_DEFINITIONS]: {
    title: () => intlGet('Notification.ProjectConfigurationError.Title', 'UnsupportedFileType'),
    message: () => intlGet('Notification.ProjectConfigurationError', ErrorType.ProjectConfiguration.INVALID_BRAND_DEFINITIONS),
  },

  // any custom errors
  [ErrorType.Assembler.CORRUPTED_IMAGE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.CORRUPTED_IMAGE),
  },
  [ErrorType.Assembler.UNCLASSIFIED_ERROR]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
  },
  [ErrorType.Assembler.NO_INTERNET_CONNECTION]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.NO_INTERNET_CONNECTION),
  },
  [ErrorType.Assembler.SAVE_LOCALLY]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    helpType: ErrorHelpMessageType.SAVE_LOCALLY,
  },

  // comes from server
  [ErrorType.Assembler.HIGH_IMAGES_DATA]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.HIGH_IMAGES_DATA),
    helpType: ErrorHelpMessageType.CONTACT_SUPPORT,
  },
  [ErrorType.Assembler.HTML_PACKAGE_ARCHIVE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.HTML_PACKAGE_ARCHIVE),
    helpType: ErrorHelpMessageType.CONTACT_SUPPORT,
  },
  [ErrorType.Assembler.HTML_GENERATION]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.HTML_GENERATION),
    helpType: ErrorHelpMessageType.CONTACT_SUPPORT,
  },
  [ErrorType.Assembler.IMPORT_TRANSLATION_PACKAGE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.IMPORT_TRANSLATION_PACKAGE),
  },
  [ErrorType.Assembler.INCORRECT_FOLDER_STRUCTURE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.INCORRECT_FOLDER_STRUCTURE),
  },
  [ErrorType.Assembler.INVALID_TRANSLATION_PROJECT_NUMBER]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.INVALID_TRANSLATION_PROJECT_NUMBER),
  },
  [ErrorType.Assembler.INVALID_TRANSLATION_PROJECT_LANGUAGE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.INVALID_TRANSLATION_PROJECT_LANGUAGE),
  },
  [ErrorType.Assembler.INVALID_XLIFF_FILE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.INVALID_XLIFF_FILE),
  },
  [ErrorType.Assembler.MISSING_TRANSLATED_IMAGES]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.MISSING_TRANSLATED_IMAGES),
  },
  [ErrorType.Assembler.NO_TRANSLATION_CONTROL_FILE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.NO_TRANSLATION_CONTROL_FILE),
  },
  [ErrorType.Assembler.NO_XLIFF_FILE]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.NO_XLIFF_FILE),
  },
  [ErrorType.Assembler.PDF_GENERATION]: {
    title: action => intlGet('Notification.AssemblerError.Title', 'UnableToPerformThisAction', { action }),
    message: () => intlGet('Notification.AssemblerError', ErrorType.Assembler.PDF_GENERATION),
    helpType: ErrorHelpMessageType.CONTACT_SUPPORT,
  },
};
