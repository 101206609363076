import classNames from 'classnames';
import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import ActionIcon from 'components/ActionIcon';
import { IconType } from 'components/Icon/constants';
import { DragSourceType, EntityType, ModalType } from 'const';
import { SSISourceWindowOptions } from 'models';
import { SSIActionsAnchorCollectedProps, SSIActionsOwnProps, SSIActionsProps, SSIActionsState } from './models';
import styles from './styles.module.scss';

const SSIActionsAnchorDragSource: DragSourceSpec<SSIActionsProps, { type: EntityType.ANCHOR }> = {
  beginDrag(props) {
    const { toggleDragState } = props;
    const type = EntityType.ANCHOR;
    toggleDragState(DragSourceType.ASSET, type);

    return { type };
  },
  endDrag(props) {
    props.toggleDragState();
  },
};

const collect: DragSourceCollector<SSIActionsAnchorCollectedProps, SSIActionsOwnProps> = (connect) => {
  return {
    connectAnchorDragSource: connect.dragSource(),
    connectAnchorDragPreview: connect.dragPreview(),
  };
};

class SSIActions extends React.PureComponent<SSIActionsProps, SSIActionsState> {
  readonly state: SSIActionsState = {
    rightActionsExpanded: false,
    showActions: false,
  };

  private showActions = (): void => { !this.props.areActionsDisabled && this.setState({ showActions: true }); };

  private hideActions = (): void => this.setState({ showActions: false, rightActionsExpanded: false });

  private handleMouseEnterOnRightActionMenu = (): void => {
    !this.props.areActionsDisabled && this.setState({ showActions: true, rightActionsExpanded: true });
  };

  private setSSISource = (): void => {
    // TODO: move this logic into saga and get rid of SSISource prop and selector
    const { showModal, SSISource } = this.props;
    showModal(ModalType.SSI_SOURCE, SSISource ? SSISource.toJS() as SSISourceWindowOptions : null);
  };

  componentDidMount() {
    const { connectAnchorDragPreview } = this.props;

    connectAnchorDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  render() {
    const { showActions, rightActionsExpanded } = this.state;
    const { deleteSSI, connectDragSource, connectAnchorDragSource } = this.props;

    return (
      <div
        className={classNames(styles.ssiActions, {
          [styles.showActions]: showActions,
        })}
        onMouseEnter={this.showActions}
        onMouseLeave={this.hideActions}
      >
        <div className={styles.left}>
          <div className={styles.leftContainer}>
            <div className={styles.leftActions}>
              {
                connectDragSource(
                  <div
                    className={styles.leftActionsIcon}
                    onMouseDown={this.hideActions}
                    onMouseUp={this.showActions}
                  >
                    <ActionIcon type={IconType.MOVE} size="xs" color="secondary" />
                  </div>,
                )
              }
            </div>
            <div className={styles.leftBorder} />
          </div>
        </div>
        <div className={styles.right}>
          <div className={classNames(styles.rightContainer, { [styles.rightContainer__expanded]: rightActionsExpanded })}>
            <div className={styles.rightBorder} onMouseEnter={this.showActions} onMouseLeave={this.hideActions} />
            <div className={styles.rightActionsContainer}>
              <div
                onMouseEnter={this.handleMouseEnterOnRightActionMenu}
                onMouseLeave={this.hideActions}
                className={classNames(styles.rightActions, { [styles.rightActions__expanded]: rightActionsExpanded })}
              >
                {rightActionsExpanded
                  ?
                  <div className={styles.rightActionsIcons}>
                    {connectAnchorDragSource(
                      <div>
                        <ActionIcon
                          className={styles.anchorDragSource}
                          type={IconType.ANCHOR}
                          size="xs"
                          color="secondary"
                        />
                      </div>,
                    )}
                    <ActionIcon
                      type={IconType.LIST}
                      size="xs"
                      color="secondary"
                      onClick={this.setSSISource}
                    />
                    <ActionIcon
                      type={IconType.DELETE}
                      size="xs"
                      color="secondary"
                      onClick={deleteSSI}
                    />
                  </div>
                  :
                  <div className={styles.rightActionsMenu}>
                    <ActionIcon type={IconType.MENU} size="xs" color="secondary" />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DragSource<SSIActionsOwnProps, SSIActionsAnchorCollectedProps, { type: EntityType.ANCHOR }>(
  DragSourceType.ASSET,
  SSIActionsAnchorDragSource,
  collect,
)(SSIActions);
