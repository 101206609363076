import _ from 'lodash';
import { call } from 'redux-saga/effects';

import { ProcessType } from 'const';
import { processRefreshedAssets } from 'containers/Common/services/processRefreshedAssets';
import * as Models from 'models';
import { getRefreshedProject } from 'services/api';
import { UploadProjectFlowArg } from '../models';
import { getDocumentsOnArtboards } from './getDocumentsOnArtboards';

export function* refreshAssets(arg: UploadProjectFlowArg): Generator<unknown, UploadProjectFlowArg> {
  const { projectAssets, rootDocumentId, isProjectTranslatable } = arg;
  const { documents } = projectAssets;
  const documentsToSync = (yield call(getDocumentsOnArtboards, ProcessType.REFRESH)).toJS() as Models.CombinedDocuments;

  const {
    data: refreshedAssets,
  }: ReturnTypeSaga<typeof getRefreshedProject> = yield call(
    getRefreshedProject,
    rootDocumentId,
    documents,
    _.keys(documentsToSync),
  );

  const {
    assets,
    prevAssets,
    actionsWithMiddleware,
  }: ReturnTypeSaga<typeof processRefreshedAssets> = yield call(
    processRefreshedAssets,
    refreshedAssets,
    isProjectTranslatable,
    projectAssets,
  );

  arg.actionsWithMiddleware.push(...actionsWithMiddleware);
  arg.prevAssets = prevAssets;
  arg.projectAssets = convertProjectAssetsToProjectAssetsToUpload(assets);
  arg.masterScreenData = refreshedAssets.masterScreenData;
}

function convertProjectAssetsToProjectAssetsToUpload(assets: Models.ProjectAssets): Models.ProjectAssetsToUpload {
  const keysToDelete: UnionToTuple<keyof Omit<Models.ProjectAssets, keyof Models.ProjectAssetsToUpload>> = ['masterScreenData', 'lastLocalSaveTime'];

  return _.omit(assets, keysToDelete) as Models.ProjectAssetsToUpload;
}
