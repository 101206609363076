import { ActionTypes } from './constants';
import * as Models from './models';

export const lockProjectContent: Models.ActionCreator.ProjectScreenStateSet = activeProcess => ({
  type: ActionTypes.LOCK_PROJECT_REQUEST,
  payload: {
    isLocked: true,
    activeProcess,
  },
});

export const unlockProjectContent: Models.ActionCreator.ProjectScreenStateSet = () => ({
  type: ActionTypes.UNLOCK_PROJECT_REQUEST,
  payload: {
    isLocked: false,
    activeProcess: null,
  },
});

export const toggleDragState: Models.ActionCreator.ToggleDragState = (type = null, subtype = null) => ({
  type: ActionTypes.TOGGLE_DRAG_STATE,
  payload: {
    type,
    subtype,
  },
});

export const updateDragHotspotPosition: Models.ActionCreator.UpdateDragHotspotPosition = position => ({
  type: ActionTypes.UPDATE_DRAG_HOTSPOT_POSITION,
  payload: {
    position,
  },
});

export const updateDragHotspotGroupLayoutId: Models.ActionCreator.UpdateDragHotspotGroupLayoutId = groupLayoutId => ({
  type: ActionTypes.UPDATE_DRAG_HOTSPOT_GROUP_LAYOUT_ID,
  payload: {
    groupLayoutId,
  },
});

export const updateSSIHotspotPosition: Models.ActionCreator.UpdateSSIHotspotPosition = position => ({
  type: ActionTypes.UPDATE_SSI_HOTSPOT_POSITION,
  payload: {
    position,
  },
});

export const toggleUpdatedDocumentsDisplaying: Models.ActionCreator.ToggleUpdatedDocumentsDisplaying = (show, entityType) => ({
  type: ActionTypes.TOGGLE_UPDATED_DOCUMENTS_DISPLAYING,
  payload: {
    show,
    entityType,
  },
});

export const saveProjectLocally: Models.ActionCreator.SaveProjectLocally = payload => ({
  type: ActionTypes.SAVE_PROJECT_LOCALLY,
  payload,
});

export const setIsResizingColumn: Models.ActionCreator.SetIsResizingColumn = isResizingColumn => ({
  type: ActionTypes.SET_IS_RESIZING_COLUMN,
  payload: {
    isResizingColumn,
  },
});

export const setIsResizingRow: Models.ActionCreator.SetIsResizingRow = isResizingRow => ({
  type: ActionTypes.SET_IS_RESIZING_ROW,
  payload: {
    isResizingRow,
  },
});

export const setIsResizingLayout: Models.ActionCreator.SetIsResizingLayout = isResizingLayout => ({
  type: ActionTypes.SET_IS_RESIZING_LAYOUT,
  payload: {
    isResizingLayout,
  },
});

export const setMoveableLayoutId: Models.ActionCreator.SetMoveableLayoutId = layoutId => ({
  type: ActionTypes.SET_MOVEABLE_LAYOUT_ID,
  payload: {
    layoutId,
  },
});

export const setLastError: Models.ActionCreator.SetLastError = lastError => ({
  type: ActionTypes.SET_LAST_ERROR,
  payload: {
    lastError,
  },
});

export const downloadIssueReport: Models.ActionCreator.SetLastError = () => ({
  type: ActionTypes.DOWNLOAD_ISSUE_REPORT,
});

export const startThumbnailRefresh: Models.ActionCreator.StartThumbnailRefresh = surfaceId => ({
  type: ActionTypes.START_THUMBNAIL_REFRESH,
  payload: {
    surfaceId,
  },
});

export const endThumbnailRefresh: Models.ActionCreator.EndThumbnailRefresh = surfaceId => ({
  type: ActionTypes.END_THUMBNAIL_REFRESH,
  payload: {
    surfaceId,
  },
});

// use it for simple app state updates instead of new staff creation
export const updateAppState: Models.ActionCreator.UpdateAppState = state => ({
  type: ActionTypes.UPDATE_APP_STATE,
  payload: {
    state,
  },
});

export const toggleShowScreensPanel: Models.ActionCreator.ToggleShowScreensPanel = showScreensPanel => ({
  type: ActionTypes.TOGGLE_SHOW_SCREENS_PANEL,
  payload: {
    showScreensPanel,
  },
});

export const updatePreviewZoomState: Models.ActionCreator.UpdatePreviewZoomState = zoom => ({
  type: ActionTypes.UPDATE_PREVIEW_ZOOM_STATE,
  payload: {
    zoom,
  },
});

export const updateArtboardZoomState: Models.ActionCreator.UpdateArtboardZoomState = zoom => ({
  type: ActionTypes.UPDATE_ARTBOARD_ZOOM_STATE,
  payload: {
    zoom,
  },
});

export const importTranslationPackage: Models.ActionCreator.ImportTranslationPackage = packageFile => ({
  type: ActionTypes.IMPORT_TRANSLATION_PACKAGE,
  payload: {
    packageFile,
  },
});

export const cancelLastUndoState: Models.ActionCreator.CancelLastUndoState = (cancelLastUndoState = false) => ({
  type: ActionTypes.CANCEL_LAST_UNDO_STATE,
  payload: {
    cancelLastUndoState,
  },
});

export const stopHandlingReusableLayoutsEditing: Models.ActionCreator.StopHandlingReusableLayoutsEditing = () => ({
  type: ActionTypes.STOP_HANDLING_REUSABLE_LAYOUTS_EDITING,
});

export const startHandlingReusableLayoutsEditing: Models.ActionCreator.StartHandlingReusableLayoutsEditing = () => ({
  type: ActionTypes.START_HANDLING_REUSABLE_LAYOUTS_EDITING,
});

export const setLastEditedLayoutId: Models.ActionCreator.SetLastEditedLayoutId = layoutId => ({
  type: ActionTypes.SET_LAST_EDITED_LAYOUT_ID,
  payload: {
    layoutId,
  },
});

export const togglePreview: Models.ActionCreator.TogglePreview = (showPreview, previewScale) => ({
  type: ActionTypes.TOGGLE_PREVIEW,
  payload: {
    showPreview,
    previewScale,
  },
});

export const addActiveLayoutId: Models.ActionCreator.AddActiveLayoutId = layoutId => ({
  type: ActionTypes.ADD_ACTIVE_LAYOUT_ID,
  payload: {
    layoutId,
  },
});

export const removeActiveLayoutId: Models.ActionCreator.RemoveActiveLayoutId = layoutId => ({
  type: ActionTypes.REMOVE_ACTIVE_LAYOUT_ID,
  payload: {
    layoutId,
  },
});

export const addSelectedAssetPanelComponent: Models.ActionCreator.AddSelectedAssetPanelComponent = documentId => ({
  type: ActionTypes.ADD_SELECTED_ASSET_PANEL_COMPONENT,
  payload: {
    documentId,
  },
});

export const removeSelectedAssetPanelComponent: Models.ActionCreator.RemoveSelectedAssetPanelComponent = documentId => ({
  type: ActionTypes.REMOVE_SELECTED_ASSET_PANEL_COMPONENT,
  payload: {
    documentId,
  },
});

export const addSelectedArtboardComponent: Models.ActionCreator.AddSelectedArtboardComponent = relationId => ({
  type: ActionTypes.ADD_SELECTED_ARTBOARD_COMPONENT,
  payload: {
    relationId,
  },
});

export const removeSelectedArtboardComponent: Models.ActionCreator.RemoveSelectedArtboardComponent = relationId => ({
  type: ActionTypes.REMOVE_SELECTED_ARTBOARD_COMPONENT,
  payload: {
    relationId,
  },
});
