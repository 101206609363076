import { ActionTypes } from './constants';
import { ActionCreator } from './models';

// layouts
export const setLayout: ActionCreator.SetLayout = layout => ({
  type: ActionTypes.SET_LAYOUT,
  payload: {
    layout,
  },
});

export const setLayoutAndInstances: ActionCreator.SetLayout = layout => ({
  type: ActionTypes.SET_LAYOUT_AND_INSTANCES,
  payload: {
    layout,
  },
});

export const setLayouts: ActionCreator.SetLayouts = layouts => ({
  type: ActionTypes.SET_LAYOUTS,
  payload: {
    layouts,
  },
});

export const addLayoutRequest: ActionCreator.AddLayoutRequest = (type, size, section) => ({
  type: ActionTypes.ADD_LAYOUT_REQUEST,
  payload: {
    type,
    size,
    section,
  },
});

export const addLayouts: ActionCreator.AddLayouts = layouts => ({
  type: ActionTypes.ADD_LAYOUTS,
  payload: {
    layouts,
  },
});

export const copyLayout: ActionCreator.CopyLayout = layoutId => ({
  type: ActionTypes.COPY_LAYOUT,
  payload: {
    layoutId,
  },
});

export const deleteLayoutRequest: ActionCreator.DeleteLayoutRequest = (layoutId, needToShowNotification = true) => ({
  type: ActionTypes.DELETE_LAYOUT_REQUEST,
  payload: {
    layoutId,
    needToShowNotification,
  },
});

export const deleteLayouts: ActionCreator.DeleteLayouts = layoutIds => ({
  type: ActionTypes.DELETE_LAYOUTS,
  payload: {
    layoutIds,
  },
});

export const moveLayout: ActionCreator.MoveLayout = (layoutId, section) => ({
  type: ActionTypes.MOVE_LAYOUT,
  payload: {
    layoutId,
    section,
  },
});

// columns
export const addLayoutColumn: ActionCreator.AddLayoutColumn = layout => ({
  type: ActionTypes.ADD_LAYOUT_COLUMN,
  payload: {
    layout,
  },
});

export const addLayoutRow: ActionCreator.AddLayoutRow = layout => ({
  type: ActionTypes.ADD_LAYOUT_ROW,
  payload: {
    layout,
  },
});

export const deleteLayoutColumn: ActionCreator.DeleteLayoutColumn = (layoutId, position) => ({
  type: ActionTypes.DELETE_LAYOUT_COLUMN,
  payload: {
    layoutId,
    position,
  },
});

// cells
export const addCell: ActionCreator.AddCell = (direction, parentRelationId, relationId) => ({
  type: ActionTypes.ADD_CELL,
  payload: {
    direction,
    parentRelationId,
    relationId,
  },
});

export const deleteCell: ActionCreator.DeleteCell = (layoutId, relationId) => ({
  type: ActionTypes.DELETE_CELL,
  payload: {
    layoutId,
    relationId,
  },
});

export const dropReusableLayout: ActionCreator.DropReusableLayout = (documentId, sectionId, storyCardId) => ({
  type: ActionTypes.DROP_REUSABLE_LAYOUT,
  payload: {
    documentId,
    sectionId,
    storyCardId,
  },
});

export const dropGroupReusableLayout: ActionCreator.DropGroupReusableLayout = (documentId, sectionId, storyCardId) => ({
  type: ActionTypes.DROP_GROUP_REUSABLE_LAYOUT,
  payload: {
    documentId,
    sectionId,
    storyCardId,
  },
});

export const resetLayoutHeightSilently: ActionCreator.ResetLayoutHeightSilently = (layoutId, height) => ({
  type: ActionTypes.RESET_LAYOUT_HEIGHT_SILENTLY,
  payload: {
    layoutId,
    height,
  },
});

export const splitCell: ActionCreator.SplitCell = (direction, layoutId, parentRelationId, relationId) => ({
  type: ActionTypes.SPLIT_CELL,
  payload: {
    direction,
    parentRelationId,
    relationId,
    layoutId,
  },
});
