import classNames from 'classnames';
import * as BrandDefinition from 'modules/BrandDefinition';
import React from 'react';
import { BrandStyleProp } from 'models';
import {
  getDesktopBrandStyles,
  getFontFamilyFromBrandStyle,
} from 'utils/brandStyles';
import { getFontColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import * as inlineStylesUtils from 'utils/inlineStyles';
import { StyleProps } from './models';
import styles from './styles.module.scss';

const Style: React.FunctionComponent<StyleProps> = (props) => {
  const { active, style, toggleBrandStyle, colors, fonts } = props;
  const brandStyleName = style.get('name');
  const desktopStyles = getDesktopBrandStyles(style);
  const fontSize = desktopStyles.get(BrandStyleProp.FONT_SIZE);
  const fontColor = getFontColorFromBrandStyle(desktopStyles.get(BrandStyleProp.COLOR), colors, desktopStyles, fonts);
  const { fontFamily: fontName, characterStyle } = getFontFamilyFromBrandStyle(desktopStyles, fonts);
  const brandFont = fonts.find(font => font.get('name') === fontName);
  let fontFaceToShow = '';
  let updatedFontFamily;
  let fontStyle;
  let fontWeight;

  if (brandFont) {
    const fontFamily = brandFont.get('name');
    const fontFamilyStyle = inlineStylesUtils.getFontFamilyStyle(fontFamily);
    const brandCharStyles = brandFont.get('characterStyles');
    const brandCharStyle = brandCharStyles && brandCharStyles.find(charStyle => charStyle.get('name') === characterStyle);
    const brandCharStyleName = brandCharStyle && brandCharStyle.get('name');
    const currentCharacterStyleNameStyle = brandCharStyleName && inlineStylesUtils.getCharacterStyleNameStyle(brandCharStyleName);
    if (fontFamilyStyle && currentCharacterStyleNameStyle) {
      updatedFontFamily = BrandDefinition.getCSSFontFamilyFromBrandFont(
        inlineStylesUtils.getFontFamilyFromStyle(fontFamilyStyle),
        inlineStylesUtils.getCharacterStyleNameFromStyle(currentCharacterStyleNameStyle),
        fonts,
      );
    }
    fontStyle = brandCharStyle ? brandCharStyle.get('fontStyle') : '';
    fontWeight = brandCharStyle ? Number(brandCharStyle.get('fontWeight')) : '';
    let charStyleToShow = '';
    if (brandCharStyle) {
      charStyleToShow = ` ${brandCharStyle.get('name')}`;
    }
    fontFaceToShow = `${brandFont.get('name')}${charStyleToShow}`;
  }

  const handleClick = (): void => {
    toggleBrandStyle(style);
  };

  return (
    <div
      className={classNames(styles.Style, {
        [styles.active]: active,
      })}
      onClick={handleClick}
    >
      <div className={styles.Description}>
        {fontColor && <div className={styles.Color} style={{ backgroundColor: fontColor }} />}
        <div className={styles.Name} style={{ fontSize, fontFamily: updatedFontFamily, fontStyle, fontWeight }}>{brandStyleName}</div>
      </div>
      <div className={styles.Details}>{fontFaceToShow && `${fontFaceToShow} - `}{fontSize}</div>
    </div>
  );
};

export default Style;
