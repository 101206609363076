import { UseFloatingReturn, arrow, autoUpdate, flip, hide, offset, size, useFloating } from '@floating-ui/react';
import { MutableRefObject, useEffect, useRef } from 'react';
import cssVariables from 'styles/variables.scss';

const PADDING = {
  top: parseInt(cssVariables.ProjectScreenHeaderHeight, 10),
  bottom: 10,
  left: 10,
  right: 10,
};

type Options = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

type Return = {
  refs: UseFloatingReturn['refs'];
  floatingStyles: UseFloatingReturn['floatingStyles'];
  context: UseFloatingReturn['context'];
  arrowRef: MutableRefObject<null>;
};

export function useFloatingWrapper(options: Options): Return {
  const { isOpen, setIsOpen } = options;

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    placement: 'top-start',
    middleware: [
      offset(10),
      flip({ padding: PADDING }),
      size({
        padding: PADDING,
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 20}px`,
          });
        },
      }),
      arrow({
        element: arrowRef,
      }),
      hide(),
    ],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (middlewareData.hide?.referenceHidden && isOpen) {
      setIsOpen(false);
    }
  }, [middlewareData.hide?.referenceHidden]);

  return {
    refs,
    floatingStyles,
    context,
    arrowRef,
  };
}
