import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

import { TOOLTIP_ELEMENT_ID } from 'const';
import { TooltipProps } from './models';
import styles from './styles.module.scss';

const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
  const { arrow = true, className: customClassName, show, text, parent } = props;
  const [left, setLeft] = React.useState(0);
  const [top, setTop] = React.useState(0);

  React.useEffect(
    () => {
      if (!parent || !parent.current || !show) {
        return;
      }

      const { top, left, width } = parent.current.getBoundingClientRect();
      setLeft(left + width / 2);
      setTop(top);
    },
    [show, parent],
  );

  if (!show) {
    return null;
  }

  const className = classNames(styles.Tooltip, customClassName, { [styles.withoutTriangle]: !arrow });

  if (!parent) {
    return <div className={className}>{text}</div>;
  }

  return ReactDOM.createPortal(
    <div className={className} style={{ top, left }}>{text}</div>,
    document.getElementById(TOOLTIP_ELEMENT_ID),
  );
};

export default Tooltip;
