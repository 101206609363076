import React, { useEffect } from 'react';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import { CHANGE_MARK } from 'const';
import StyleDropdown from 'containers/Toolbar/Dropdowns/BrandStyle';
import { BrandStyleMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { IBrandStyleControlProps } from './models';
import styles from './styles.module.scss';

const BrandStyleControl: React.FunctionComponent<IBrandStyleControlProps> = (props: IBrandStyleControlProps) => {
  const icon = React.createRef<HTMLDivElement>();
  const { activeDropdown, closeDialog, brandStyle, brandStyleChanged, sectionStyles, wrapperOnMouseDownHackCallback } = props;
  const defaultText = intlGet('EditorToolbar.StyleDropdown', 'Default');
  const showDropdown = activeDropdown === ToolbarDropdownType.BRAND_STYLE;
  const changeMark = <span className={styles.changeMark}>{CHANGE_MARK}</span>;

  const handleStyleSelection = (style: BrandStyleMap): void => {
    const { setStyle, returnFocusToEditor } = props;

    setStyle(style);
    returnFocusToEditor();
  };

  const closeDialogHandler = (event: MouseEvent): void => {
    closeDialog(event, ToolbarDropdownType.BRAND_STYLE, Boolean(icon?.current?.contains(event.target as Node)));
  };

  useEffect(() => {
    document.addEventListener('click', closeDialogHandler);

    return (): void => document?.removeEventListener('click', closeDialogHandler);
  }, [activeDropdown]);

  return (
    <div className={styles.BrandStyleControl} data-title={Title.TEXT_STYLE}>
      <div
        onMouseDown={(e): void => wrapperOnMouseDownHackCallback(e, ToolbarDropdownType.BRAND_STYLE)}
        ref={icon}
        className={styles.styleControl}
        data-tooltip={brandStyle ? brandStyle.get('name') : intlGet('EditorToolbar.Tooltip', 'FontStyle')}
      >
        <span className={styles.controlText}>
          <span className={styles.brandStyle}>{brandStyle ? brandStyle.get('name') : defaultText}</span>
          {brandStyle && brandStyleChanged && changeMark}
        </span>
        <Icon type={IconType.CHEVRON_DOWN} size="badge" color={showDropdown ? 'primary' : 'secondary'} hoverColor="primary-hover" />
      </div>
      {
        showDropdown &&
        <StyleDropdown
          activeBrandStyleId={brandStyle && brandStyle.get('id')}
          toggleBrandStyle={handleStyleSelection}
          sectionStyles={sectionStyles}
        />
      }
    </div>
  );
};

export default BrandStyleControl;
