import Immutable from 'immutable';

import * as Models from 'models';
import { ActionTypes } from './constants';
import { Action, State } from './models';

export const countryAbbreviationsInitialState = Immutable.Map() as State.IState;

const countryAbbreviationsReducer: Models.Reducer<State.IState> =
  (state = countryAbbreviationsInitialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_COUNTRY_ABBREVIATIONS:
        return setCountryAbbreviations(state, action);
      default:
        return state;
    }
  };

const setCountryAbbreviations: Models.Reducer<State.IState, Action.ISetCountryAbbreviations> = (state, action) => (
  Immutable.fromJS(action.payload.countryAbbreviations || {})
);

export default countryAbbreviationsReducer;
