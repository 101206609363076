import _ from 'lodash';

import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isImage(entity: Models.CombinedDocument): entity is Models.Image;
export function isImage(entity: Models.CombinedDocumentMap): entity is Models.ImageMap;
export function isImage(entity: Models.Relation): entity is Models.RegularRelation<Models.ImageRelationStyles>;
export function isImage(entity: Models.RelationMap): entity is Models.RegularRelationMap<Models.ImageRelationStyles>;
export function isImage(entity: Models.LayeredRelation): entity is Models.LayeredRegularRelation<Models.ImageRelationStyles>;
export function isImage(entity: Models.LayeredRelationMap): entity is Models.LayeredRegularRelationMap<Models.ImageRelationStyles>;
export function isImage(entity): boolean {
  return getValue(entity, 'entityType') === EntityType.IMAGE;
}

export function areImages(entities: Models.CombinedDocument[]): entities is Models.Image[] {
  return _.every(entities, isImage);
}
