import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RowResizer from 'components/RowResizer';
import { RowResizerActions, RowResizerOwnProps, RowResizerSelectors } from 'components/RowResizer/models';
import { setIsResizingRow, setLastEditedLayoutId } from 'containers/App/actions';
import { areActionsDisabled, artboardScale, isResizingColumn } from 'containers/App/selectors';
import { updateLayeredRelations, updateLayeredRelationsSilently } from 'containers/Relations/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';

const mapStateToProps = createStructuredSelector({
  areActionsDisabled,
  isResizingColumn,
  scale: artboardScale,
});

const mapDispatchToProps = {
  setLastEditedLayoutId,
  finalizeRelations: updateLayeredRelations,
  updateLayeredRelationsSilently,
  setIsResizingRow,
  saveAppState,
};

export default connect<RowResizerSelectors, RowResizerActions, RowResizerOwnProps>(mapStateToProps, mapDispatchToProps)(RowResizer);
