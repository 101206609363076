import { Map } from 'immutable';
import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from 'const';
import { setLayoutAndInstances } from 'containers/Layouts/actions';
import * as projectSelectors from 'containers/Project/selectors';
import * as relationsActions from 'containers/Relations/actions';
import { layoutStylesStateToSource } from 'hooks/layout/useLayoutStyles';
import { CreateCustomClickHandlerWithToolbar, Priority, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import * as Models from 'models';
import { AbbreviationsListLayeredRelationMap, AbbreviationsListRelationStylesMap } from '../../../types';
import { StylesState, stylesStateToRelationSource } from './useStyles';

type UseLayoutHook = {
  containerRef: MutableRefObject<HTMLDivElement>;
  editMode: boolean;
  enableEditMode: () => void;
};

export const useAbbreviationsLayout = (
  artboardLayoutContainer: HTMLDivElement,
  layout: Models.LayoutMap,
  relation: AbbreviationsListLayeredRelationMap,
  activeLayer: Constants.Layer,
  styles: StylesState,
): UseLayoutHook => {
  const dispatch = useDispatch();
  const isOpenToolbar = useSelector(projectSelectors.isOpenToolbar);

  const createCustomClickHandler: CreateCustomClickHandlerWithToolbar<HTMLDivElement> = (artboardLayout, toolbar, toggleEditModeOff, editMode) => {
    return (event) => {
      if (!editMode || !artboardLayoutContainer || !artboardLayout.current) {
        return;
      }

      const eventTarget = event.target as Node;
      const clickOutside = !artboardLayoutContainer.contains(eventTarget) && !toolbar.contains(eventTarget);
      const clickInLayout = artboardLayout.current.contains(eventTarget);

      if (clickOutside || clickInLayout) {
        toggleEditModeOff();
      }
    };
  };

  const {
    container,
    on: editMode,
    toggleOn: toggleEditModeOn,
  } = useClickOutsideWithToggle<HTMLDivElement>(Priority.TOOLBAR, { createCustomClickHandler });
  const containerRef: RefObject<HTMLDivElement> = container;

  const enableEditMode = useCallback((): void => {
    if (!isOpenToolbar) {
      toggleEditModeOn();
    }
  }, [toggleEditModeOn, isOpenToolbar]);

  const isFirstRendering = useRef(true);
  useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false;
    } else if (!editMode) {
      const updatedLayout = layout.update('styles', values => layoutStylesStateToSource(styles, values));
      dispatch(setLayoutAndInstances(updatedLayout));
      const updatedRelation = relation.updateIn(
        ['styles', activeLayer],
        values => stylesStateToRelationSource(styles, values || Map({}) as AbbreviationsListRelationStylesMap),
      );
      dispatch(relationsActions.updateLayeredRelations(
        Map({ [relation.get('id')]: updatedRelation }) as unknown as Models.LayeredRelationsMap,
      ));
    }
  }, [editMode, dispatch]);

  return {
    containerRef: containerRef as MutableRefObject<HTMLDivElement>,
    editMode,
    enableEditMode,
  };
};


