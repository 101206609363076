import React from 'react';
/* eslint-disable max-len */

const Anchor = (): JSX.Element => (
  <svg width="18px" height="18px" viewBox="-1 -1 20 20">
    <path d="M3.8544661,14.0922943 C4.99588897,15.2248589 6.48701395,15.9471266 8.09074252,16.144252 L8.09074252,8.99839916 L4.45445375,8.99839916 L4.45445375,7.19843622 L8.09074252,7.19843622 L8.09074252,5.24547642 C6.83845314,4.80715277 6.08726369,3.53941194 6.31187398,2.24337847 C6.53648427,0.947345006 7.67156124,0 8.99981472,0 C10.3280682,0 11.4631452,0.947345006 11.6877555,2.24337847 C11.9123657,3.53941194 11.1611763,4.80715277 9.90888691,5.24547642 L9.90888691,7.19843622 L13.5451757,7.19843622 L13.5451757,8.99839916 L9.90888691,8.99839916 L9.90888691,16.144252 C11.5126155,15.9471266 13.0037405,15.2248589 14.1451633,14.0922943 L12.8542808,12.8143206 L17.9996294,12.8143206 L15.4269551,15.3612682 C11.8770296,18.8746333 6.12259987,18.8746333 2.57267431,15.3612682 L0,12.8143206 L5.14534862,12.8143206 L3.8544661,14.0922943 Z M8.99981472,3.59851033 C9.50188143,3.59851033 9.90888691,3.1955749 9.90888691,2.69852886 C9.90888691,2.20148281 9.50188143,1.79854738 8.99981472,1.79854738 C8.49774801,1.79854738 8.09074252,2.20148281 8.09074252,2.69852886 C8.09074252,3.1955749 8.49774801,3.59851033 8.99981472,3.59851033 Z" id="Anchor-ID-path" />
  </svg>
);

export default Anchor;
