const DEFAULT_TIME_IN_SECONDS = 25 * 60;

type KeepAliveRequest = () => Promise<void>;

type Params = {
  time?: number;
  checkHandler: KeepAliveRequest;
  errorHandler: () => void;
};

class SessionKeepAliveManager {
  private time: number;

  private timerId?: ReturnType<typeof setTimeout>;

  private checkHandler: KeepAliveRequest;

  private errorHandler: () => void;

  private started = false;

  private error = false;

  constructor(options: Params) {
    const { time, checkHandler, errorHandler } = options;
    this.time = time || DEFAULT_TIME_IN_SECONDS;
    this.checkHandler = checkHandler;
    this.errorHandler = errorHandler;
  }

  start(): void {
    if (this.started) {
      return;
    }
    this.checkIsAlive();
  }

  checkIsAlive(): void {
    this.started = true;
    if (this.error) {
      return;
    }
    this.checkHandler()
      .then(() => {
        this.setAlive();
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
        this.setTerminated(true);
      });
  }

  setAlive(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      this.checkIsAlive();
    }, this.time * 1000);
  }

  setTerminated(throwError = true): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
    }
    if (this.error) {
      return;
    }
    this.error = true;
    if (throwError) {
      this.errorHandler();
    }
  }

  restart(): void {
    this.error = false;
    this.checkIsAlive();
  }

}

export default SessionKeepAliveManager;

