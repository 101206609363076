import Immutable from 'immutable';
import _ from 'lodash';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { removeAbbreviationsIfNotPresent } from 'modules/Abbreviations/utils/removeAbbreviationsIfNotPresent';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';
import guid from 'uuid';

import { TextStatusType, DraftEntity } from 'const';
import { setLastEditedLayoutId } from 'containers/App/actions';
import {
  createBrandStylesByRelationId,
  createFlatColorsByRelationId,
  createFlatFontsByRelationId,
  reusableLayoutsRelationIds as reusableLayoutsRelationIdsSelector,
} from 'containers/Common/selectors';
import { addDocuments, setDocument, updateDocument } from 'containers/Documents/actions';
import * as documentsSelectors from 'containers/Documents/selectors';
import { textComponents as textComponentsSelector } from 'containers/ProjectPanel/selectors';
import { updateRelation, updateRelations } from 'containers/Relations/actions';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { textComponentFactory } from 'factories/document/textComponentFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { useDefaultBrandStyle } from 'services/useDefaultBrandStyle';
import { findTextComponentDuplicateId } from 'utils/findDocumentDuplicateId';
import { getChangedStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { Action } from '../models';
import { addTextFromAssetsCollection } from '../services/addTextFromAssetsCollection';
import { applyStylesOnRelations } from '../services/applyStylesOnRelations';
import { updateReferenceCitationsOnComponent } from '../services/updateReferenceCitationsOnComponent';

// TODO: split this logic on several independent sagas (e.g., dropComponentFromAssetCollection, dropReferenceCitation, etc.)
export function* addTextToCell(action: Action.IAddTextToCell) {
  try {
    const actions: Models.IAction[] = [];
    const { component, layoutRelations, relationId, operations, sectionStyles, layoutId, isDrop } = action.payload;
    if (isDrop) {
      actions.push(saveAppState());
    }

    const relation = layoutRelations.get(relationId) as Models.RegularRelationMap<Models.TextRelationStyles>;
    const styles: ReturnTypeSaga<ReturnTypeSaga<typeof createBrandStylesByRelationId>> = yield select(createBrandStylesByRelationId(relationId));
    const colors: ReturnTypeSaga<ReturnTypeSaga<typeof createFlatColorsByRelationId>> = yield select(createFlatColorsByRelationId(relationId));
    const fonts: ReturnTypeSaga<ReturnTypeSaga<typeof createFlatFontsByRelationId>> = yield select(createFlatFontsByRelationId(relationId));
    const changedStylesToBeKept = getChangedStylesToBeKept(relation, styles, colors, fonts);

    const stylesToBeKept = {
      ...changedStylesToBeKept,
      brandStyleChanged: _.some(Models.TextBrandStyleField, styleField => changedStylesToBeKept[styleField]),
    };

    const appliedStylesResult: ReturnTypeSaga<typeof applyStylesOnRelations> = yield call(applyStylesOnRelations, component, relation, operations);
    const { component: updatedComponent, newReferenceCitationDocument } = appliedStylesResult;
    const updatedRelations = _.assign(layoutRelations.toJS() as Models.Relations, appliedStylesResult.updatedRelations);

    if (newReferenceCitationDocument) {
      actions.push(setDocument(newReferenceCitationDocument));
    }
    let updatedRelation = updatedRelations[relationId] as Models.RegularRelation<Models.TextRelationStyles>;
    const textsForDuplicatesChecking: ReturnTypeSaga<typeof textComponentsSelector> = yield select(textComponentsSelector);
    const textComponents: ReturnTypeSaga<typeof documentsSelectors.textComponentsForAssetsPanel> = yield select(
      documentsSelectors.textComponentsForAssetsPanel,
    );
    const documents: ReturnTypeSaga<typeof documentsSelectors.documents> = yield select(documentsSelectors.documents);
    const reusableLayoutsRelationIds: ReturnTypeSaga<typeof reusableLayoutsRelationIdsSelector> = yield select(reusableLayoutsRelationIdsSelector);
    const assetsCollectionsDocuments: ReturnTypeSaga<typeof documentsSelectors.assetsCollectionsDocuments> = yield select(
      documentsSelectors.assetsCollectionsDocuments,
    );

    const textComponent = component.toJS() as Models.TextComponent;
    const { status, entityType, id: componentId } = textComponent;
    const documentExists = !!documents.get(componentId);
    const isAssetCollectionsDocument = !!assetsCollectionsDocuments.get(componentId);
    const isRelationInsideReusableLayout = reusableLayoutsRelationIds.includes(relationId);
    const duplicateId = findTextComponentDuplicateId(textComponent, textsForDuplicatesChecking);

    if (documentExists) {
      if (status && status.includes(TextStatusType.EDIT)) {
        // document edited via editor
        let updatedTextComponent = updateReferenceCitationsOnComponent(updatedComponent);
        // TODO: re-check if status is required in store, probably remove it from store
        updatedTextComponent = updatedTextComponent.set('status', null);
        updatedRelation = {
          ...updatedRelation,
          id: relationId,
          entityType,
          documentId: componentId,
        };

        actions.push(setDocument(updatedTextComponent));
      } else {
        // document dropped from Text section
        let documentId = componentId;

        if (isRelationInsideReusableLayout) {
          // should we check for duplicates within RL?
          documentId = guid();
          actions.push(setDocument(updatedComponent.set('id', documentId)));
        }

        const withBrandStyle: ReturnTypeSaga<typeof useDefaultBrandStyle> = yield call(
          useDefaultBrandStyle,
          updatedComponent,
          sectionStyles,
          relationId,
        );
        updatedRelation = {
          id: relationId,
          entityType,
          documentId,
          styles: {
            ...withBrandStyle,
            ...stylesToBeKept,
          },
        };
      }
    } else if (duplicateId) {
      // document dropped: use existing duplicate instead of new created
      const duplicate = textComponents.get(duplicateId);
      const droppedTextHasAbbreviations = Object.values(
        (JSON.parse(updatedComponent.get('rawContent')) as Models.Document).entityMap ?? {},
      ).some(({ type }) => type === DraftEntity.ABBREVIATION);

      if (droppedTextHasAbbreviations) {
        // if there is abbreviations in text that we trying to add we should add these to existing document
        const textAbbreviationDocuments = (yield select(selectAbbreviationsData)).toJS() as unknown as Models.TextAbbreviationDocumentsArray;
        const newRawContent = removeAbbreviationsIfNotPresent(textAbbreviationDocuments, updatedComponent.get('rawContent'));
        actions.push(updateDocument(duplicate.set('rawContent', newRawContent)));
      }
      const withBrandStyle: ReturnTypeSaga<typeof useDefaultBrandStyle> = yield call(useDefaultBrandStyle, duplicate, sectionStyles, relationId);
      updatedRelation = {
        id: relationId,
        entityType,
        documentId: duplicateId,
        styles: {
          ...withBrandStyle,
          ...stylesToBeKept,
        },
      };
    } else {
      // document dropped from Story Card or Text Collection
      if (isAssetCollectionsDocument) {
        // pass the document through the factory to make sure that the document will match its interface
        // TBC: Currently we pick only priority text from storyCard. Should we pick both layers together?
        let newOnlineDocument = Immutable.fromJS(textComponentFactory(updatedComponent.toJS())) as Models.TextComponentMap;
        newOnlineDocument = newOnlineDocument.set('id', guid()).set('status', TextStatusType.COPY);

        const withBrandStyle: ReturnTypeSaga<typeof useDefaultBrandStyle> = yield call(
          useDefaultBrandStyle,
          newOnlineDocument,
          sectionStyles,
          relationId,
        );

        const storyCardsAndMagicFormsDocuments: ReturnTypeSaga<typeof documentsSelectors.storyCardsAndMagicFormsDocuments> = yield select(
          documentsSelectors.storyCardsAndMagicFormsDocuments,
        );
        const resultAddText: ReturnTypeSaga<typeof addTextFromAssetsCollection> = yield call(
          addTextFromAssetsCollection,
          newOnlineDocument.toJS() as Models.TextComponent,
          storyCardsAndMagicFormsDocuments,
        );
        const { textComponent: _textComponent, referenceCitations } = resultAddText;
        if (!_.isEmpty(referenceCitations)) {
          actions.push(addDocuments(referenceCitations));
        }
        actions.push(setDocument(_textComponent));

        updatedRelation = {
          id: relationId,
          entityType,
          documentId: newOnlineDocument.get('id'),
          styles: {
            ...withBrandStyle,
            ...stylesToBeKept,
          },
        };
        // document created via editor
      } else {
        const newOnlineDocument = Immutable.fromJS(textComponentFactory({
          text: updatedComponent.get('text'),
          rawContent: updatedComponent.get('rawContent'),
          language: updatedComponent.get('language').toJS(),
          country: updatedComponent.get('country').toJS(),
        })) as Models.TextComponentMap;
        updatedRelation = {
          ...updatedRelation,
          id: relationId,
          entityType,
          documentId: newOnlineDocument.get('id'),
        };

        actions.push(setDocument(newOnlineDocument));
      }
    }

    delete updatedRelations[updatedRelation.id];
    actions.push(
      setLastEditedLayoutId(layoutId),
      updateRelation(updatedRelation, { resetLayeredData: updatedRelation.entityType !== relation.get('entityType') }),
      updateRelations(updatedRelations),
    );

    yield put(batchActions(actions));
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.addTextToCell', 'AddTextToCell');
  }
}
