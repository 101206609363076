import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';
import { updateDragHotspotPosition } from 'containers/App/actions';
import { dragHotspotPosition } from 'containers/App/selectors';
import { updateArtboard } from 'containers/Artboards/actions';
import { activeArtboard } from 'containers/Artboards/selectors';
import { selectBrandedStyles } from 'containers/BrandDefinition/selectors';
import { orderedActiveLayoutIds } from 'containers/Layouts/selectors';
import { addRelations } from 'containers/Relations/actions';
import { sectionsWidthByName } from 'containers/ScreenDefinitions/selectors';
import { sections as sectionsSelector } from 'containers/Sections/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { handleSagaError } from 'services/handleError';
import { createLayoutAndRelations } from 'utils/createLayoutAndRelations';
import { setLayout } from '../actions';
import * as Models from '../models';

export function* addLayoutRequest(action: Models.Action.IAddLayoutRequest) {
  try {
    const { type, size, section } = action.payload;

    const artboard: ReturnTypeSaga<typeof activeArtboard> = yield select(activeArtboard);
    const position: ReturnTypeSaga<typeof dragHotspotPosition> = yield select(dragHotspotPosition);
    const { relationStyles, layoutStyles } = (
      yield select(selectBrandedStyles, type)
    ) as ReturnTypeSaga<typeof selectBrandedStyles>;

    const sections: ReturnTypeSaga<typeof sectionsSelector> = yield select(sectionsSelector);
    const sectionsWidthBySectionName: ReturnTypeSaga<typeof sectionsWidthByName> = yield select(sectionsWidthByName);
    const sectionWidth = sectionsWidthBySectionName.get(sections.getIn([section, 'name']));
    const { layout, relations } = createLayoutAndRelations(
      { type, section, styles: layoutStyles },
      { layoutWidth: sectionWidth, size, regularRelationStyles: relationStyles },
    );
    const layoutIds: ReturnType<typeof orderedActiveLayoutIds> = yield select(orderedActiveLayoutIds);
    const updatedArtboard = artboard.set('layoutIds', layoutIds.insert(position, layout.id));

    yield put(batchActions([
      saveAppState(),
      addRelations(relations),
      setLayout(layout),
      updateArtboard(updatedArtboard),
      updateDragHotspotPosition(null),
    ]));
  } catch (error) {
    yield call(handleSagaError, error, 'Layouts.addLayoutRequest', 'AddLayout');
  }
}
