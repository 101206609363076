export enum LayoutType {
  TEXT_ONE_COLUMN = 'TEXT_ONE_COLUMN',
  TEXT_TWO_COLUMN = 'TEXT_TWO_COLUMN',
  TEXT_THREE_COLUMN = 'TEXT_THREE_COLUMN',
  TEXT_FOUR_COLUMN = 'TEXT_FOUR_COLUMN',
  IMAGE_TEXT_RIGHT = 'IMAGE_TEXT_RIGHT',
  IMAGE_TEXT_LEFT = 'IMAGE_TEXT_LEFT',
  FULL_WIDTH_IMAGE = 'FULL_WIDTH_IMAGE',
  TWO_IMAGE_AND_TEXT = 'TWO_IMAGE_AND_TEXT',
  THREE_IMAGE_AND_TEXT = 'THREE_IMAGE_AND_TEXT',
  FOUR_IMAGE_AND_TEXT = 'FOUR_IMAGE_AND_TEXT',
  SPACER = 'SPACER',
  REFERENCE_CITATION_ELEMENT = 'REFERENCE_CITATION_ELEMENT',
  ABBREVIATIONS_LIST = 'ABBREVIATIONS_LIST',
  GROUP_LAYOUT = 'GROUP_LAYOUT',
}
