import _ from 'lodash';

import * as Models from 'models';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getValue } from 'utils/getter';
import { sum } from 'utils/sum';

type GetLayoutWidthOptions = {
  withoutExtraWidth: boolean;
};

export const getLayoutWidth = (
  layout: Models.LayoutMap | Models.Layout,
  sectionWidth: number,
  options?: GetLayoutWidthOptions,
): number => {
  const { withoutExtraWidth } = _.defaults(options, { withoutExtraWidth: false });

  return withoutExtraWidth
    ? sectionWidth - getExtraWidth(getValue(layout, 'styles'))
    : sectionWidth;
};

export const getRowWidth = (relation: Models.RowRelation | Models.RowRelationMap): number => {
  const styles = getValue(relation, 'styles');

  return sum(getValue(styles, 'columnsWidth'));
};
