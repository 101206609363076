import { EditorState, Modifier } from 'draft-js';

import { DraftEditorStateChangeType, DraftEntity, DraftEntityMutability } from 'const';
import { removeEntities, reselectEntities } from './entity';
import { getSelectedEntities, isSameTypeEntitySelected } from './selection';

export function createNoWrapEntity(editorState: Draft.EditorState): Draft.EditorState {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  let contentStateWithEntity = contentState.createEntity(DraftEntity.NO_WRAP, DraftEntityMutability.MUTABLE);
  contentStateWithEntity = Modifier.applyEntity(contentStateWithEntity, selectionState, contentStateWithEntity.getLastCreatedEntityKey());

  return EditorState.push(editorState, contentStateWithEntity, DraftEditorStateChangeType.APPLY_ENTITY);
}

export function isNoWrapActive(editorState: Draft.EditorState): boolean {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  if (selection.isCollapsed()) {
    if (getSelectedEntities(selection, contentState).find(entity => entity.get('type') === DraftEntity.NO_WRAP)) {
      return true;
    }
  } else {
    if (isSameTypeEntitySelected(editorState, DraftEntity.NO_WRAP)) {
      return true;
    }
  }

  return false;
}

export function toggleTextWrap(editorState: Draft.EditorState): Draft.EditorState {
  let resultEditorState = editorState;

  if (isNoWrapActive(editorState)) {
    if (editorState.getSelection().isCollapsed()) {
      resultEditorState = EditorState.acceptSelection(editorState, reselectEntities(editorState));
    }

    return removeEntities(resultEditorState);
  }

  return createNoWrapEntity(resultEditorState);
}
