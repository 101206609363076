/* eslint-disable max-len */
import React from 'react';

const CloseBold = (): JSX.Element => (
  <svg viewBox="6 6 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M8.4 17.0436L7 15.6415L10.6 12.0362L7 8.45581L8.4 7.05371L12 10.6591L15.575 7.05371L16.975 8.45581L13.375 12.0362L16.975 15.6415L15.575 17.0436L12 13.4383L8.4 17.0436Z" fill="white"/>
    </g>
  </svg>
);

export default CloseBold;
