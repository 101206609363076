import React from 'react';

import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import Control from 'components/Toolbar/Controls/Control';
import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import Dropdown from 'components/Toolbar/Dropdowns/Responsive';
import { useControlWithDropdown } from 'components/Toolbar/hooks';
import { ResponsiveProps } from './models';

const Responsive: React.FunctionComponent<ResponsiveProps> = (props) => {
  const { responsive, toggleResponsive, controlState = ControlState } = props;
  const { control, isOpen, state, toggleDropdown } = useControlWithDropdown({ controlState });

  return (
    <Control
      controlState={controlState}
      onToggle={toggleDropdown}
      ref={control}
      size="sm-md"
      state={state}
      title={Title.RESPONSIVE_LAYOUT}
      type={IconType.RESPONSIVE}
    >
      {isOpen && <Dropdown responsive={responsive} toggleResponsive={toggleResponsive} />}
    </Control>
  );
};

export default Responsive;
