import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Header from 'components/Header';
import { IHeaderActions, IHeaderOwnProps, IHeaderSelectors } from 'components/Header/models';
import { importTranslationPackage, saveProjectLocally, togglePreview } from 'containers/App/actions';
import { areImagesBeingDownloaded, showPreview } from 'containers/App/selectors';
import { resetPreviewsHtml } from 'containers/ArtboardPreview/actions';
import { countryAbbreviations } from 'containers/CountryAbbreviations/selectors';
import { areReusableLayoutsBeingUploaded, exportForTranslationsEnabled } from 'containers/Documents/selectors';
import { showModal } from 'containers/ModalManager/actions';
import { exportPackage } from 'containers/ModalWindows/Export/actions';
import { setActiveLayer } from 'containers/Project/actions';
import {
  activeLayer,
  activeSurfaceId,
  isOpenToolbar,
  isProjectTranslatable,
  isUploadAvailable,
  lastLocalSaveTime,
  originalCountry,
  originalLanguage,
} from 'containers/Project/selectors';

const mapStateToProps = createStructuredSelector({
  activeLayer,
  activeSurfaceId,
  areImagesBeingDownloaded,
  areReusableLayoutsBeingUploaded,
  countryAbbreviations,
  exportForTranslationsEnabled,
  isOpenToolbar,
  isTranslationAvailable: isProjectTranslatable,
  isUploadAvailable,
  lastLocalSaveTime,
  originalCountry,
  originalLanguage,
  showPreview,
});

const mapDispatchToProps = {
  exportPackage,
  importTranslationPackage,
  resetPreviewsHtml,
  saveProjectLocally,
  showModal,
  setActiveLayer,
  togglePreview,
};

export default withRouter(connect<IHeaderSelectors, IHeaderActions, IHeaderOwnProps>(mapStateToProps, mapDispatchToProps)(Header));
