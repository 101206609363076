import classNames from 'classnames';
import React from 'react';

import DragHotspot from 'components/DragHotspot';
import { isHorizontalSSIPosition, isVerticalSSIPosition } from 'utils/ssi/ssiPosition';
import { SSIDragHotspotProps } from './models';
import styles from './styles.module.scss';

const SSIDragHotspot: React.FC<SSIDragHotspotProps> = ({ position }) => (
  <div
    className={classNames(styles.sectionDragHotspot, {
      [styles.right]: isVerticalSSIPosition(position),
      [styles.bottom]: isHorizontalSSIPosition(position),
    })}
  >
    <DragHotspot />
  </div>
);

export default SSIDragHotspot;
