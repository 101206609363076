import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { ImageDataToUpload, ImageMap } from 'models';
import { updateImageDocument } from 'services/api';
import { getImagesInfo, setDocument } from '../actions';
import { getImagesAdditionalInfo } from '../sagas/getImagesAdditionalInfo';

export function* updateImageDocumentWithRenditions(imageToUpdate: ImageMap, imageDataToUpload: ImageDataToUpload): SagaIterator {
  const { data: createdDocument }: ReturnTypeSaga<typeof updateImageDocument> = yield call(
    updateImageDocument,
    imageToUpdate.get('documentId'),
    imageDataToUpload,
  );

  createdDocument.id = imageToUpdate.get('id');
  createdDocument.documentSource = imageToUpdate.get('documentSource').toJS();

  yield put(setDocument(createdDocument));
  yield call(getImagesAdditionalInfo, getImagesInfo([createdDocument]));
}
