import { keyMirror } from 'utils/keyMirror';

export const ActionTypes = keyMirror(
  {
    ADD_DOCUMENTS: null,
    ADD_IMAGE: null,
    ADD_REFERENCE_CITATION: null,
    DELETE_COMPONENT: null,
    DELETE_DOCUMENTS: null,
    DOWNLOAD_IMAGES: null,
    GET_ASSETS_COLLECTION_DATA: null,
    GET_CITATION_INFO: null,
    GET_IMAGES_INFO: null,
    GET_LAYOUTS_THUMBNAILS: null,
    GET_STORY_CARD_IMAGES_INFO: null,
    GET_STORY_CARD_LAYOUT_THUMBNAILS: null,
    GET_STORY_CARDS_PREVIEWS: null,
    MERGE_DOCUMENTS: null,
    REMOVE_ANCHOR: null,
    SET_ASSETS_COLLECTION_DATA: null,
    SET_DOCUMENT: null,
    SET_DOCUMENTS: null,
    UPDATE_DOCUMENT_SOURCE: null,
    UPDATE_DOCUMENT: null,
    UPDATE_IMAGE: null,
    UPDATE_INTERNAL_IMAGE_INFO_REQUEST: null,
    UPDATE_INTERNAL_IMAGE_INFO: null,
    UPDATE_LAYOUT_DOCUMENT_THUMBNAIL_URL: null,
    UPDATE_REFERENCE_REQUEST: null,
    UPDATE_STORY_CARD_IMAGE_INFO: null,
    UPDATE_STORY_CARD_LAYOUT_THUMBNAIL: null,
  },
  'Documents',
);
