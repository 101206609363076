import Immutable from 'immutable';

import { forbiddenArtboardNameCharactersRegExp } from 'const';

export function isScreenNameInvalid(screenName: string): boolean {
  return forbiddenArtboardNameCharactersRegExp.test(screenName);
}

export function isScreenNameDuplicated(screenName: string, screenNames: Immutable.List<string>): boolean {
  return screenNames.some(screen => screen.localeCompare(screenName, undefined, { sensitivity: 'accent' }) === 0);
}

export function getValidScreenName(screenName: string, screenNames: Immutable.List<string>, initialScreenName: string): string {
  const cleanScreenName = screenName && screenName.trim();
  const isNameInvalid = !cleanScreenName || isScreenNameInvalid(cleanScreenName);
  const isNameDuplicated = isScreenNameDuplicated(cleanScreenName, screenNames);
  const returnInitialScreenName = isNameInvalid || isNameDuplicated;

  return returnInitialScreenName ? initialScreenName.trim() : cleanScreenName;
}
