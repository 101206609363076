import React from 'react';
/* eslint-disable max-len */

const Padding = (): JSX.Element => (
  <svg width="22px" height="18px" viewBox="0 0 22 18">
    <defs>
      <path d="M11.01,2.5 L9,5 L13,5 L11.01,2.5 Z M17,7 L17,11 L19.5,9.01 L17,7 Z M5,7 L2.5,9.01 L5,11 L5,7 Z M13,13 L9,13 L11.01,15.5 L13,13 Z M20,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L20,18 C21.1,18 22,17.1 22,16 L22,2 C22,0.9 21.1,0 20,0 Z M20,16.01 L2,16.01 L2,1.99 L20,1.99 L20,16.01 L20,16.01 Z" id="Padding-ID" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-921.000000, -201.000000)">
        <g transform="translate(0.000000, 180.000000)">
          <g transform="translate(917.000000, 15.000000)">
            <g transform="translate(3.000000, 3.000000)">
              <g transform="translate(1.000000, 3.000000)">
                <mask id="Padding-mask" fill="white">
                  <use xlinkHref="#Padding-ID" />
                </mask>
                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#Padding-ID" />
                <g mask="url(#Padding-mask)" fill="#F9AD3D">
                  <g transform="translate(-2.000000, -4.000000)">
                    <rect x="0" y="0" width="26" height="25" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Padding;
