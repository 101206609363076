import React from 'react';

const FourImageAndText = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <defs>
      <polygon points="0.0188306705 0.0430306653 26.9190841 0.0430306653 26.9190841 14.9298145 0.0188306705 14.9298145" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-240.000000, -610.000000)">
        <g transform="translate(240.000000, 610.000000)">
          <rect id="Rectangle-Copy-48" fill="#FFFFFF" x="0.75" y="0.75" width="107.5" height="107.5" />
          <rect id="Rectangle-Copy-49" fill="#F9AD3D" x="17" y="60.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-52" fill="#F9AD3D" x="37" y="60.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-55" fill="#F9AD3D" x="57" y="60.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-58" fill="#F9AD3D" x="77" y="60.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-50" fill="#F9AD3D" x="17" y="33.75" width="15" height="23" />
          <rect id="Rectangle-Copy-53" fill="#F9AD3D" x="37" y="33.75" width="15" height="23" />
          <rect id="Rectangle-Copy-56" fill="#F9AD3D" x="57" y="33.75" width="15" height="23" />
          <rect id="Rectangle-Copy-59" fill="#F9AD3D" x="77" y="33.75" width="15" height="23" />
          <rect id="Rectangle-Copy-51" fill="#F9AD3D" x="17" y="68.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-54" fill="#F9AD3D" x="37" y="68.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-57" fill="#F9AD3D" x="57" y="68.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-60" fill="#F9AD3D" x="77" y="68.75" width="15" height="5" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default FourImageAndText;
