import { call } from 'redux-saga/effects';

import { ExportType } from 'const';
import { generatePackageForHTML } from 'containers/Artboards/sagas/generatePackageForHTML';
import { generatePackageForIPad } from 'containers/Artboards/sagas/generatePackageForIPad';
import { generateProjectPDF } from 'containers/Artboards/sagas/generateProjectPDF';
import { generateTranslationPackage } from 'containers/Artboards/sagas/generateTranslationPackage';
import { handleSagaError } from 'services/handleError';
import * as Models from '../models';

export function* exportPackage(action: Models.Action.IExport) {
  try {
    const { exportType } = action.payload;

    switch (exportType) {
      case ExportType.PDF: return yield* generateProjectPDF();
      case ExportType.HTML_PACKAGE: return yield* generatePackageForHTML();
      case ExportType.IPAD_PACKAGE: return yield* generatePackageForIPad();
      case ExportType.TRANSLATION_PACKAGE: return yield* generateTranslationPackage();

      default: return;
    }
  } catch (error) {
    yield call(handleSagaError, error, 'ExportWindow.exportPackage', 'ExportPackage');
  }
}
