import Immutable from 'immutable';

import { DEFAULT_ZOOM_SIZE, EntityType } from 'const';
import { Reducer, TempLayoutPreview } from 'models';
import { ActionTypes } from './constants';
import * as Models from './models';

export const appInitialState: Models.State.StateType = {
  activeLayoutIds: Immutable.Set() as unknown as Set<string>, // HACK: js Set can't be converted into Immutable.Set
  activeProcessType: null,
  areImagesBeingDownloaded: false,
  cancelLastUndoState: false,
  dragHotspot: {
    position: null,
    groupLayoutId: null,
  },
  dragState: {
    type: null,
    subtype: null,
  },
  handleReusableLayoutsEditing: true,
  lastEditedLayoutId: '',
  isLocked: false,
  isResizingColumn: false,
  isResizingLayout: false,
  isResizingRow: false,
  lastError: null,
  loadingOverlayOptions: {
    info: null,
    title: null,
  },
  moveableLayoutId: null,
  selectionState: {
    assetDocumentIds: Immutable.OrderedSet() as unknown as Set<string>,
    artboardRelationIds: Immutable.OrderedSet() as unknown as Set<string>,
  },
  showPreview: false,
  showScreensPanel: true,
  showUpdatedImages: false,
  showUpdatedModuleBundles: false,
  showUpdatedReferences: false,
  showUpdatedReusableLayouts: false,
  showUpdatedStoryCards: false,
  showUpdatedTextCollections: false,
  ssiHotspotPosition: null,
  thumbnailsRefreshProgress: {},
  tempLayoutPreview: {} as TempLayoutPreview,
  artboardScale: DEFAULT_ZOOM_SIZE,
  previewScale: DEFAULT_ZOOM_SIZE,
};

export const appReducer: Reducer<Models.State.IState> = (state = Immutable.fromJS(appInitialState), action) => {
  switch (action.type) {
    case ActionTypes.ADD_SELECTED_ASSET_PANEL_COMPONENT:
      return addSelectedAssetPanelComponent(state, action);
    case ActionTypes.REMOVE_SELECTED_ASSET_PANEL_COMPONENT:
      return removeSelectedAssetPanelComponent(state, action);
    case ActionTypes.ADD_SELECTED_ARTBOARD_COMPONENT:
      return addSelectedArtboardComponent(state, action);
    case ActionTypes.REMOVE_SELECTED_ARTBOARD_COMPONENT:
      return removeSelectedArtboardComponent(state, action);
    case ActionTypes.LOCK_PROJECT_REQUEST:
    case ActionTypes.UNLOCK_PROJECT_REQUEST:
      return setProjectLockState(state, action);
    case ActionTypes.TOGGLE_DRAG_STATE:
      return toggleDragState(state, action);
    case ActionTypes.UPDATE_DRAG_HOTSPOT_POSITION:
      return updateDragHotspotPosition(state, action);
    case ActionTypes.UPDATE_DRAG_HOTSPOT_GROUP_LAYOUT_ID:
      return updateDragHotspotGroupLayoutId(state, action);
    case ActionTypes.UPDATE_SSI_HOTSPOT_POSITION:
      return updateSSIHotspotPosition(state, action);
    case ActionTypes.TOGGLE_UPDATED_DOCUMENTS_DISPLAYING:
      return toggleUpdatedDocumentsDisplaying(state, action);
    case ActionTypes.SET_IS_RESIZING_LAYOUT:
      return setIsResizingLayout(state, action);
    case ActionTypes.SET_IS_RESIZING_COLUMN:
      return setIsResizingColumn(state, action);
    case ActionTypes.SET_IS_RESIZING_ROW:
      return setIsResizingRow(state, action);
    case ActionTypes.SET_MOVEABLE_LAYOUT_ID:
      return setMoveableLayoutId(state, action);
    case ActionTypes.SET_LAST_ERROR:
      return setLastError(state, action);
    case ActionTypes.START_THUMBNAIL_REFRESH:
      return startThumbnailRefresh(state, action);
    case ActionTypes.END_THUMBNAIL_REFRESH:
      return endThumbnailRefresh(state, action);
    case ActionTypes.UPDATE_APP_STATE:
      return updateAppState(state, action);
    case ActionTypes.UPDATE_PREVIEW_ZOOM_STATE:
      return updatePreviewZoomState(state, action);
    case ActionTypes.UPDATE_ARTBOARD_ZOOM_STATE:
      return updateArtboardZoomState(state, action);
    case ActionTypes.ADD_ACTIVE_LAYOUT_ID:
      return addActiveLayoutId(state, action);
    case ActionTypes.CANCEL_LAST_UNDO_STATE:
      return cancelLastUndoState(state, action);
    case ActionTypes.STOP_HANDLING_REUSABLE_LAYOUTS_EDITING:
      return stopHandlingReusableLayoutsEditing(state, action);
    case ActionTypes.START_HANDLING_REUSABLE_LAYOUTS_EDITING:
      return startHandlingReusableLayoutsEditing(state, action);
    case ActionTypes.SET_LAST_EDITED_LAYOUT_ID:
      return setLastEditedLayoutId(state, action);
    case ActionTypes.REMOVE_ACTIVE_LAYOUT_ID:
      return removeActiveLayoutId(state, action);
    case ActionTypes.TOGGLE_SHOW_SCREENS_PANEL:
      return toggleShowScreensPanel(state, action);
    case ActionTypes.TOGGLE_PREVIEW:
      return togglePreview(state, action);
    default:
      return state;
  }
};

const addSelectedAssetPanelComponent: Reducer<Models.State.IState, Models.Action.IAddSelectedAssetPanelComponent> =
  (state, action) => {
    const { documentId } = action.payload;

    return state.update(
      'selectionState',
      selectionState => selectionState
        .update('assetDocumentIds', assetDocumentIds => assetDocumentIds.add(documentId)),
    );
  };

const removeSelectedAssetPanelComponent: Reducer<Models.State.IState, Models.Action.IRemoveSelectedAssetPanelComponent> =
  (state, action) => {
    const { documentId } = action.payload;

    return state.update(
      'selectionState',
      selectionState => selectionState
        .update('assetDocumentIds', assetDocumentIds => assetDocumentIds.remove(documentId)),
    );
  };

const addSelectedArtboardComponent: Reducer<Models.State.IState, Models.Action.IAddSelectedArtboardComponent> =
  (state, action) => {
    const { relationId } = action.payload;

    return state.update(
      'selectionState',
      selectionState => selectionState
        .update('artboardRelationIds', artboardRelationIds => artboardRelationIds.add(relationId)),
    );
  };

const removeSelectedArtboardComponent: Reducer<Models.State.IState, Models.Action.IRemoveSelectedArtboardComponent> =
  (state, action) => {
    const { relationId } = action.payload;

    return state.update(
      'selectionState',
      selectionState => selectionState
        .update('artboardRelationIds', artboardRelationIds => artboardRelationIds.remove(relationId)),
    );
  };

const setProjectLockState: Reducer<Models.State.IState, Models.Action.IProjectScreenStateSet> = (state, action) => {
  const { isLocked, activeProcess } = action.payload;

  return state.set('isLocked', isLocked).set('activeProcessType', activeProcess);
};

const toggleDragState: Reducer<Models.State.IState, Models.Action.IToggleDragState> = (state, action) => {
  return state.set('dragState', Immutable.fromJS(action.payload));
};

export const updateDragHotspotPosition: Reducer<Models.State.IState, Models.Action.IUpdateDragHotspotPosition> = (state, action) => {
  const { position } = action.payload;

  return state.update('dragHotspot', dragHotspot => dragHotspot.set('position', position));
};

export const updateDragHotspotGroupLayoutId: Reducer<Models.State.IState, Models.Action.UpdateDragHotspotGroupLayoutId> = (state, action) => {
  const { groupLayoutId } = action.payload;

  return state.update('dragHotspot', dragHotspot => dragHotspot.set('groupLayoutId', groupLayoutId));
};

export const updateSSIHotspotPosition: Reducer<Models.State.IState, Models.Action.IUpdateSSIHotspotPosition> = (state, action) => {
  const { position } = action.payload;

  return state.set('ssiHotspotPosition', position);
};

const toggleUpdatedDocumentsDisplaying: Reducer<Models.State.IState, Models.Action.IToggleUpdatedDocumentsDisplaying> = (state, action) => {
  const { show, entityType } = action.payload;

  switch (entityType) {
    case EntityType.IMAGE: return state.set('showUpdatedImages', show);
    case EntityType.REFERENCE_CITATION: return state.set('showUpdatedReferences', show);
    case EntityType.LAYOUT: return state.set('showUpdatedReusableLayouts', show);
    case EntityType.MAGIC_FORM:
    case EntityType.STORY_CARD: return state.set('showUpdatedStoryCards', show);
    case EntityType.TEXT_COLLECTION: return state.set('showUpdatedTextCollections', show);
    case EntityType.MODULE_BUNDLE: return state.set('showUpdatedModuleBundles', show);
    default: return state.merge({
      showUpdatedImages: show,
      showUpdatedReferences: show,
      showUpdatedReusableLayouts: show,
      showUpdatedStoryCards: show,
      showUpdatedTextCollections: show,
      showUpdatedModuleBundles: show,
    });
  }
};

export const togglePreview: Reducer<Models.State.IState, Models.Action.ITogglePreview> = (state, action) => {
  const { showPreview, previewScale } = action.payload;

  if (previewScale) {
    return state.set('showPreview', showPreview).set('previewScale', previewScale);
  }

  return state.set('showPreview', showPreview);
};

export const setIsResizingLayout: Reducer<Models.State.IState, Models.Action.ISetIsResizingLayout> = (state, action) => {
  const { isResizingLayout } = action.payload;

  return state.set('isResizingLayout', isResizingLayout);
};

export const setIsResizingColumn: Reducer<Models.State.IState, Models.Action.ISetIsResizingColumn> = (state, action) => {
  const { isResizingColumn } = action.payload;

  return state.set('isResizingColumn', isResizingColumn);
};

export const setIsResizingRow: Reducer<Models.State.IState, Models.Action.ISetIsResizingRow> = (state, action) => {
  const { isResizingRow } = action.payload;

  return state.set('isResizingRow', isResizingRow);
};

export const setMoveableLayoutId: Reducer<Models.State.IState, Models.Action.ISetMoveableLayoutId> = (state, action) => {
  const { layoutId } = action.payload;

  return state.set('moveableLayoutId', layoutId);
};

export const updatePreviewZoomState: Reducer<Models.State.IState, Models.Action.ISetZoomState> = (state, action) => {
  const { zoom } = action.payload;

  return state.set('previewScale', zoom);
};
export const updateArtboardZoomState: Reducer<Models.State.IState, Models.Action.ISetZoomState> = (state, action) => {
  const { zoom } = action.payload;

  return state.set('artboardScale', zoom);
};

export const setLastError: Reducer<Models.State.IState, Models.Action.ISetLastError> = (state, action) => {
  const { lastError } = action.payload;

  return state.set('lastError', Immutable.fromJS(lastError));
};

export const startThumbnailRefresh: Reducer<Models.State.IState, Models.Action.IStartThumbnailRefresh> = (state, action) => {
  const { surfaceId } = action.payload;

  return state.setIn(['thumbnailsRefreshProgress', surfaceId], true);
};

export const endThumbnailRefresh: Reducer<Models.State.IState, Models.Action.IEndThumbnailRefresh> = (state, action) => {
  const { surfaceId } = action.payload;

  return state.setIn(['thumbnailsRefreshProgress', surfaceId], false);
};

export const updateAppState: Reducer<Models.State.IState, Models.Action.IUpdateAppState> = (state, action) => {
  const { state: newState } = action.payload;

  return state.mergeDeep(Immutable.fromJS(newState));
};

export const toggleShowScreensPanel: Reducer<Models.State.IState, Models.Action.IToggleShowPanel> = (state, action) => {
  const { showScreensPanel } = action.payload;

  return state.set('showScreensPanel', showScreensPanel);
};

export const addActiveLayoutId: Reducer<Models.State.IState, Models.Action.AddActiveLayoutId> = (state, action) => {
  const { layoutId } = action.payload;

  return state.update('activeLayoutIds', activeLayoutIds => activeLayoutIds.add(layoutId));
};

export const removeActiveLayoutId: Reducer<Models.State.IState, Models.Action.RemoveActiveLayoutId> = (state, action) => {
  const { layoutId } = action.payload;

  return state.update('activeLayoutIds', activeLayoutIds => activeLayoutIds.remove(layoutId));
};

export const cancelLastUndoState: Reducer<Models.State.IState, Models.Action.ICancelLastUndoState> = (state, action) => {
  const { cancelLastUndoState } = action.payload;

  return state.set('cancelLastUndoState', cancelLastUndoState);
};

export const stopHandlingReusableLayoutsEditing: Reducer<Models.State.IState, Models.Action.IStopHandlingReusableLayoutsEditing> =
  state => state.set('handleReusableLayoutsEditing', false);

export const startHandlingReusableLayoutsEditing: Reducer<Models.State.IState, Models.Action.IStopHandlingReusableLayoutsEditing> =
  state => state.set('handleReusableLayoutsEditing', true);

export const setLastEditedLayoutId: Reducer<Models.State.IState, Models.Action.ISetLastEditedLayoutId> = (state, action) => {
  const { layoutId } = action.payload;

  return state.set('lastEditedLayoutId', layoutId);
};

export default appReducer;
