import { put, select, take } from 'redux-saga/effects';
import guid from 'uuid';
import { ErrorType, ModalType } from 'const';
import { isArtboardMounted } from 'containers/App/selectors';
import { showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { AssemblerErrorType, NotificationWindowOptions } from 'models';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';

export function* showNotificationAccessError(options: NotificationWindowOptions): Generator<unknown, boolean> {
  const modalWindowId = guid();

  const artboardMounted: ReturnTypeSaga<typeof isArtboardMounted> = yield select(isArtboardMounted);

  yield put(showModal(ModalType.NOTIFICATION_ACCESS_ERROR, {
    disableClickOutside: !artboardMounted,
    ...options,
  }, modalWindowId),
  );
  const { payload: { response } }: ModalManagerModels.Action.IHideModal<boolean> = yield take(takeModalWindowResponse(modalWindowId));

  return response;
}

export function shallWeDisplayNotificationAccessErrorModal(type: AssemblerErrorType): boolean {
  const { INSUFFICIENT_ACCESS, INVALID_DATA } = ErrorType.PromoMats;

  return type === INSUFFICIENT_ACCESS || type === INVALID_DATA;
}
