import * as Immutable from 'immutable';

import { AnyArtboard, SSI, SSIMap } from 'models';
import { AnyScreenDefinition, SectionsWidthMap } from 'models/MasterScreen';
import { isVerticalSSIPosition } from 'utils/ssi/ssiPosition';
import { getValue } from './getter';

export function getSectionWidth(sectionName: string, screenWidth: number, ssi: SSI | SSIMap): number {
  if (ssi && getValue(ssi, 'section') === sectionName && isVerticalSSIPosition(getValue(ssi, 'position'))) {
    return Math.ceil(screenWidth * (1 - getValue(ssi, 'scale')));
  }

  return screenWidth;
}

export function getSectionsWidthByName(
  artboard: AnyArtboard,
  screenWidth: number,
  screenDefinition: AnyScreenDefinition,
): SectionsWidthMap {
  let result = Immutable.Map() as SectionsWidthMap;
  const ssi = getValue(artboard, 'ssi');

  getValue(screenDefinition, 'sections').forEach((section) => {
    const name = getValue(section, 'name');

    result = result.set(name, getSectionWidth(name, screenWidth, ssi));
  });

  return result;
}
