import React from 'react';

const TextFourColumn = (): JSX.Element => (
  <svg viewBox="0 0 109 109">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-370.000000, -358.000000)">
        <g transform="translate(370.000000, 358.000000)">
          <rect id="Rectangle-Copy-2" fill="#FFFFFF" x="0.75" y="0.75" width="107.5" height="107.5" />
          <rect id="Rectangle-Copy-11" fill="#F9AD3D" x="17" y="40.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-14" fill="#F9AD3D" x="37" y="40.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-17" fill="#F9AD3D" x="57" y="40.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-20" fill="#F9AD3D" x="77" y="40.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-12" fill="#F9AD3D" x="17" y="53.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-15" fill="#F9AD3D" x="37" y="53.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-18" fill="#F9AD3D" x="57" y="53.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-21" fill="#F9AD3D" x="77" y="53.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-13" fill="#F9AD3D" x="17" y="66.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-16" fill="#F9AD3D" x="37" y="66.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-19" fill="#F9AD3D" x="57" y="66.75" width="15" height="5" rx="1.5" />
          <rect id="Rectangle-Copy-22" fill="#F9AD3D" x="77" y="66.75" width="15" height="5" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

export default TextFourColumn;
