import { UNICODE_HASH } from 'const';
import * as Models from 'models';

export function compareByName<T extends { name: string }>(a: T, b: T): boolean {
  return a.name === b.name;
}

export function compareById<T extends { id: string }>(a: T, b: T): boolean {
  return a.id === b.id;
}

function getFontFaceHash(fontFace: Models.UIFontFace): string {
  return `f:${fontFace.fontFamily};s:${fontFace.fontStyle || ''};w:${fontFace.fontWeight || ''};${fontFace.S3Key.match(/uni/i) ? UNICODE_HASH : ''}`;
}

export function compareFontFaces(a: Models.UIFontFace, b: Models.UIFontFace): boolean {
  return getFontFaceHash(a) === getFontFaceHash(b);
}
