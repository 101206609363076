import _ from 'lodash';

import * as Models from 'models';

export function isProjectTranslatable<T extends { rootDocument: Models.RootDocument; project: Models.Project }>(assets: T): boolean {
  const {
    rootDocument: {
      language: targetLanguage,
      country: targetCountry,
    },
    project: {
      originalLanguage,
      originalCountry,
    },
  } = assets;

  return (
    !_.isEmpty(originalLanguage) && _(originalLanguage).intersection(targetLanguage).isEmpty()
    || !_.isEmpty(originalCountry) && _(originalCountry).intersection(targetCountry).isEmpty()
  );
}
