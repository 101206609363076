import { Layer } from 'const';
import * as Models from 'models';
import { addReusableLayout } from './addReusableLayout';

interface AddReusableLayoutToGroupLayout {
  layouts: Models.LayeredCombinedLayouts;
  newImages: Models.Images;
  relations: Models.LayeredRelations;
  documents: Models.CombinedDocuments;
}

export function addReusableLayoutToGroupLayout(
  layoutDocument: Models.ReusableLayout,
  sectionId: string,
  position: number,
  groupLayout: Models.LayeredGroupLayout,
  projectDocuments: Models.CombinedDocuments,
  layouts: Models.LayeredCombinedLayouts,
  projectBrandDefinition: Models.OnlineBrandDefinition,
  activeLayer: Layer,
  layoutId?: string,
  adoptStyles?: boolean,
): AddReusableLayoutToGroupLayout {
  const {
    layout,
    newImages,
    documents,
    relations,
  } = addReusableLayout(
    layoutDocument,
    sectionId,
    projectDocuments,
    layouts,
    projectBrandDefinition,
    activeLayer,
    layoutId,
    adoptStyles,
  );

  groupLayout.layoutIds.splice(position, 0, layout.id);

  return {
    newImages,
    documents,
    relations,
    layouts: { [layout.id]: layout, [groupLayout.id]: groupLayout },
  };
}
