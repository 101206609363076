import { takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionTypes } from '../constants';
import { deleteSurface } from './deleteSurface';
import { duplicateSurface } from './duplicateSurface';
import { getThumbnailUrl } from './getArtboardThumbnailUrl';
import { setScreenFormat } from './setScreenFormat';
import { setSurfaceSettings } from './setSurfaceSettings';
import { updateSurfaceType } from './updateSurfaceType';

export default [
  takeEvery(ActionTypes.UPDATE_SURFACE_TYPE, updateSurfaceType),
  takeLatest(ActionTypes.DELETE_SURFACE_REQUEST, deleteSurface),
  takeLatest(ActionTypes.DUPLICATE_SURFACE, duplicateSurface),
  takeLatest(ActionTypes.REFRESH_THUMBNAIL, getThumbnailUrl),
  takeLatest(ActionTypes.SET_SCREEN_FORMAT, setScreenFormat),
  takeLatest(ActionTypes.SET_SURFACE_SETTINGS, setSurfaceSettings),
];
