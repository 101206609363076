import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put } from 'redux-saga/effects';

import { RenditionType } from 'const';
import { updateDocument } from 'containers/Documents/actions';
import { Action } from 'containers/Documents/models';
import { StoryCard } from 'models';
import { getDocumentPreview } from 'services/api';
import { runEventChanel } from 'services/EventChannel';
import { SubscribeFunction } from 'services/EventChannel/models';
import { ImageCache } from 'services/imageCache';
import { getImageInternalInfo } from 'utils/getImageInternalInfo';

const subscribeFunction: SubscribeFunction<Action.IUpdateDocument, StoryCard> = async (emitter, isDone, document) => {
  const { documentId, id, renditions, entityType } = document;
  let previewUrls: string[] = [];

  try {
    const checksum = _.get(renditions, [RenditionType.VIEWABLE_RENDITION, 'checksum']);
    const imageCache = ImageCache.getInstance();
    previewUrls = (imageCache.getItem(RenditionType.VIEWABLE_RENDITION, checksum) || []) as string[];
    if (_.isEmpty(previewUrls)) {
      const { data: buffers } = await getDocumentPreview(documentId, checksum);

      previewUrls = await Promise.all(buffers.map(async (buffer) => {
        const infoImage = await getImageInternalInfo(new Blob([new Uint8Array(buffer)], { type: 'image/png' }));

        return infoImage.source;
      }));

      imageCache.setItem(RenditionType.VIEWABLE_RENDITION, checksum, previewUrls);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error occurred during updating story card preview (${documentId}).`, error);
  }

  emitter({
    action: updateDocument({ id, _previewUrls: previewUrls, _isPreviewBeingLoaded: false, entityType }),
    done: isDone(),
  });
};

export function* getStoryCardsPreviews(action: Action.IGetStoryCardsPreviews) {
  try {
    const { documents } = action.payload;
    const actions = documents.map(({ id, entityType }) => updateDocument({ id, _isPreviewBeingLoaded: true, entityType }));
    yield put(batchActions(actions));

    yield call(runEventChanel, documents, subscribeFunction);
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during updating story cards previews.', error);
  }
}
