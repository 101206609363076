import * as Draft from 'draft-js';

import { DraftEntity } from 'const';

export const getTextComponentNameWithoutReferences = (rawContent: Draft.RawDraftContentState): string => {
  const { blocks, entityMap } = rawContent;
  let resultText = '';

  blocks.forEach(({ entityRanges, text }) => {
    let blockText = text;

    [...entityRanges].reverse().forEach(({ offset, length, key }) => {
      const entity = entityMap[key];

      if (entity.type === DraftEntity.REFERENCE) {
        blockText = `${blockText.substr(0, offset)}${blockText.substr(offset + length)}`;
      }
    });

    resultText += blockText;
  });

  return resultText;
};
