import React from 'react';
/* eslint-disable max-len */

const Scrollable = (): JSX.Element => (
  <svg viewBox="0 0 22 24">
    <path d="M21,0 C21.5522847,0 22,0.44771525 22,1 L22,23 C22,23.5522847 21.5522847,24 21,24 L1,24 C0.44771525,24 0,23.5522847 0,23 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L21,0 Z M20,1.8522184 L2,1.8522184 L2,22.2266208 L20,22.2266208 L20,1.8522184 Z M11,4 L15,7.99 L12,7.99 L12,16.01 L15,16.01 L11,20 L7,16.01 L10,16.01 L10,7.99 L7,7.99 L11,4 Z" />
  </svg>
);

export default Scrollable;
