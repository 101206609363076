import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';

import Spinner from 'components/Spinner';
import { DocumentThumbnailProps } from './models';
import styles from './styles.module.scss';

const DocumentThumbnail: React.FunctionComponent<DocumentThumbnailProps> = (props) => {
  const {
    url,
    loaded,
    loading,
    placeholder,
    getThumb = _.noop,
  } = props;

  useEffect(() => { !loaded && !loading && getThumb(); }, []);

  return (
    <div
      className={classNames(styles.AssetsCollectionPreview, {
        [styles.withImage]: !!url,
      })}
      style={{
        backgroundImage: url ? `url(${url})` : 'none',
      }}
    >
      {
        loading
          ? <Spinner />
          : !url && placeholder
      }
    </div>
  );
};

export default DocumentThumbnail;
