import React, { PropsWithChildren } from 'react';

import { ControlGroupProps } from './models';
import styles from './styles.module.scss';

const ControlGroup: React.FunctionComponent<PropsWithChildren<ControlGroupProps>> = props => (
  <div className={styles.ControlGroup}>{props.children}</div>
);

export default ControlGroup;
