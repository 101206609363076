import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { removeAbbreviationsIfNotPresent } from 'modules/Abbreviations/utils/removeAbbreviationsIfNotPresent';
import { call, select } from 'redux-saga/effects';
import guid from 'uuid';

import { citationsForAssetsPanel as citationsSelector } from 'containers/Documents/selectors';
import { referenceCitationFactory } from 'factories/document/referenceCitationFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { findReferenceCitationDuplicateId } from 'utils/findDocumentDuplicateId';
import { replaceReferenceMarkersByEntities } from './replaceReferenceMarkersByEntities';

interface AddTextFromAssetsCollectionResult {
  newReferenceIdByOldReferenceId: Record<string, string>;
  referenceCitations: Models.ReferenceCitations;
  textComponent: Models.TextComponent;
}

/**
 * Processes text component and all its reference citations and removes its unused abbreviations
 * @param textComponent - text component from Assets Collection to add to the project
 * @param storyCardsAndMagicFormsDocuments - documents from Asset Collection
 * @param newReferenceCitationIdByOldReferenceCitationId - stores already processed reference citations ids
 * @param referenceCitationsCache - stores clear reference citations texts (for searching duplicates) in order to avoid to get them every time
 */
export function* addTextFromAssetsCollection(
  textComponent: Models.TextComponent,
  storyCardsAndMagicFormsDocuments: Models.CombinedDocumentsMap,
  newReferenceCitationIdByOldReferenceCitationId: Record<string, string> = {},
  referenceCitationsCache?: Models.TextCache,
): Generator<unknown, AddTextFromAssetsCollectionResult> {
  try {
    const result = {
      referenceCitations: {},
    } as AddTextFromAssetsCollectionResult;

    // map of Story Card reference citation ids: { [id: sting]: string }
    const newReferenceIdByOldReferenceId = {} as Record<string, string>;
    const referencesCache = referenceCitationsCache || {} as Models.TextCache;
    const citations: ReturnTypeSaga<typeof citationsSelector> = yield select(citationsSelector);
    const textAbbreviationDocuments = (yield select(selectAbbreviationsData)).toJS() as unknown as Models.TextAbbreviationDocumentsArray;

    textComponent.rawContent = removeAbbreviationsIfNotPresent(textAbbreviationDocuments, textComponent.rawContent);

    const getUpdatedReferenceId = (referenceId): string => {
      const reference = storyCardsAndMagicFormsDocuments.get(referenceId) as Models.ReferenceCitationMap;
      const newReferenceId = newReferenceIdByOldReferenceId[referenceId] || newReferenceCitationIdByOldReferenceCitationId[referenceId];

      if (!newReferenceId && (!referenceId || !reference)) {
        return '';
      }

      let updatedReferenceId: string;

      if (newReferenceId) {
        // the reference citation has been already processed, just assign new id
        updatedReferenceId = newReferenceId;
      } else {
        const referenceDuplicateId = findReferenceCitationDuplicateId(reference, citations, referencesCache);

        // use existing duplicate instead of new created
        if (referenceDuplicateId) {
          updatedReferenceId = referenceDuplicateId;
          // collect new reference citation id by old one in order to avoid searching duplicate several times for the same reference citation
          newReferenceIdByOldReferenceId[referenceId] = updatedReferenceId;
        } else {
          updatedReferenceId = guid();
          // pass the document through the factory to make sure that the document will match its interface
          const updatedReference = referenceCitationFactory(reference.set('id', updatedReferenceId).toJS());
          // collect new reference citations
          result.referenceCitations[updatedReferenceId] = updatedReference;
          // collect new reference citation id by old one in order to easier match them later
          newReferenceIdByOldReferenceId[referenceId] = updatedReferenceId;
        }
      }

      return updatedReferenceId;
    };

    result.textComponent = yield call(replaceReferenceMarkersByEntities, textComponent, getUpdatedReferenceId);
    result.newReferenceIdByOldReferenceId = newReferenceIdByOldReferenceId;

    return result;
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.addTextFromAssetsCollection', 'AddTextFromAssetCollection');
  }
}
