import _ from 'lodash';
import { CallEffectDescriptor, SelectEffectDescriptor, SimpleEffect, all, call, select } from 'redux-saga/effects';

import { Layer } from 'const';
import { brandDefinition } from 'containers/BrandDefinition/selectors';
import * as commonSelectors from 'containers/Common/selectors';
import { getLayoutPreviewUrl } from 'containers/Common/services/getLayoutPreviewUrl';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import { initApp } from 'containers/Common/services/initApp';
import { currentCountry, currentLanguage, currentProduct } from 'containers/Project/selectors';
import * as Models from 'models';
import { createGroupLayout, getAlreadyTranslatedReusableLayouts } from 'services/api';
import * as processes from 'services/processes';
import { mergeBrandDefinitions } from 'utils/brandStyles/mergeBrandDefinitions';
import { isReusableLayoutDocument } from 'utils/entityType';
import { sagaFlow } from 'utils/sagaFlow';

export function* translateGroupReusableLayouts(
  rootDocumentId: number,
  allGroupReusableLayouts: Models.GroupLayoutsMap,
  translatedGroupReusableLayouts: Models.GroupLayoutsMap,
  documents: Models.CombinedDocuments,
  translatedReusableLayoutDocuments: Models.ReusableLayouts,
): Generator<unknown, void> {
  if (_.isEmpty(translatedGroupReusableLayouts.toJS())) {
    return;
  }

  // translated internal document ids by original
  const translatedGroupReusableLayoutDocumentIdsMap = _(translatedGroupReusableLayouts.toJS() as Models.LayeredCombinedLayouts)
    .map(layout => layout.documentId)
    .reject(documentId => _.some(documentId, _.isNil))
    .keyBy(documentId => documentId.original)
    .mapValues(documentId => documentId.translated)
    .value();

  const language = (yield select(currentLanguage)).toJS() as string[];
  const country = (yield select(currentCountry)).toJS() as string[];
  const product = (yield select(currentProduct)).toJS() as string[];
  const projectBrandDefinition = (yield select(brandDefinition)).toJS() as Models.OnlineBrandDefinition;

  const documentIdsByInternalDocumentIds = allGroupReusableLayouts
    .map(layout => layout.getIn(['documentId', Layer.ORIGINAL]))
    .filter(Boolean)
    .mapEntries(([, id]) => [id, (documents[id] as Models.GroupLayoutDocument).documentId])
    .toJS() as Record<string, number>;

  // retrieve already translated GRLs
  const { data: {
    translatedDocumentsByOriginalDocumentId,
    additionalLayoutDocumentsByGroupedLayoutDocumentId,
  } }: ReturnTypeSaga<typeof getAlreadyTranslatedReusableLayouts> = yield call(
    getAlreadyTranslatedReusableLayouts,
    _.values(documentIdsByInternalDocumentIds),
    _.first(language),
    _.first(country),
  );

  _.forEach(
    [
      ..._.values(translatedDocumentsByOriginalDocumentId),
      ..._.flatMap(additionalLayoutDocumentsByGroupedLayoutDocumentId),
    ],
    (layoutDocument) => {
      if (isReusableLayoutDocument(layoutDocument)) {
        layoutDocument.entities.brandDefinition = mergeBrandDefinitions(projectBrandDefinition, layoutDocument.entities.brandDefinition);
      }

      return layoutDocument;
    });

  const alreadyTranslatedGroupReusableLayouts = _(documentIdsByInternalDocumentIds)
    .mapValues((documentId, id) => {
      const translatedLayoutDocumentId = translatedGroupReusableLayoutDocumentIdsMap[id];

      return translatedLayoutDocumentId && translatedDocumentsByOriginalDocumentId[documentId]
        ? _.set(translatedDocumentsByOriginalDocumentId[documentId], 'id', translatedLayoutDocumentId)
        : null;
    })
    .omitBy(_.isEmpty)
    // update translated document keys
    .mapKeys(document => document.id)
    .value();

  // leave only reusable layouts that have not been translated before
  const alreadyTranslatedChildGroupLayoutDocuments = _(additionalLayoutDocumentsByGroupedLayoutDocumentId)
    .flatMap(documents => documents)
    .filter(({ documentId }) => !_.some(translatedReusableLayoutDocuments, { documentId }))
    .keyBy(({ id }) => id)
    .value();

  // proceed only with not translated GRLs
  const alreadyTranslatedGroupReusableLayoutIds = _.map(alreadyTranslatedGroupReusableLayouts, 'id');
  const notTranslatedGroupReusableLayouts = translatedGroupReusableLayouts.filterNot(
    layout => alreadyTranslatedGroupReusableLayoutIds.includes(layout.getIn(['documentId', Layer.TRANSLATED])),
  );

  const groupLayoutPreviewByLayoutId: Record<string, string> = yield all(
    notTranslatedGroupReusableLayouts
      .map(layout => call(getLayoutPreviewUrl, layout.get('layoutIds').toArray()))
      .toJS() as Record<string, SimpleEffect<'CALL', CallEffectDescriptor>>,
  );

  const groupLayoutAssetsByDocumentInternalId: Record<string, Models.LayoutAssetsMap> = yield all(
    notTranslatedGroupReusableLayouts
      .map(layout => layout.getIn(['documentId', Layer.TRANSLATED]))
      .mapEntries(([layoutId, id]) =>
        [id, select(commonSelectors.groupLayoutAssets(layoutId, { previewUrl: groupLayoutPreviewByLayoutId[layoutId] }))],
      )
      .toJS() as Record<string, SimpleEffect<'SELECT', SelectEffectDescriptor>>,
  );

  const justTranslatedGroupLayoutDocuments: Record<string, Models.GroupLayoutDocument> = yield all(
    _.mapValues(groupLayoutAssetsByDocumentInternalId, function* (groupLayoutAssets, id) {
      const originalDocumentId = _.findKey(translatedGroupReusableLayoutDocumentIdsMap, translatedDocumentId => translatedDocumentId === id);
      // use original documentId and name to create translated RL
      const { name, documentId } = documents[originalDocumentId] as Models.GroupLayoutDocument;
      const { data: groupLayoutDocument }: ReturnTypeSaga<typeof createGroupLayout> = yield call(
        createGroupLayout,
        rootDocumentId,
        groupLayoutAssets.toJS() as Models.GroupLayoutAssets,
        name,
        { language, country, product },
        documentId,
      );
      // restore group layout id
      groupLayoutDocument.id = id;
      groupLayoutDocument.previewUrl = groupLayoutAssets.get('previewUrl');
      groupLayoutDocument._thumbnailUrl = groupLayoutAssets.get('previewUrl');

      return groupLayoutDocument;
    }),
  );

  const projectAssets: ReturnTypeSaga<typeof getProjectAssets> = yield call(getProjectAssets, { removeInternalInfo: false });
  const masterScreenData = (yield select(commonSelectors.masterScreenData)).toJS() as Models.MasterScreenData;

  const updatedAssets = _.set<Models.ProjectAssets>(_.cloneDeep(projectAssets), 'masterScreenData', masterScreenData);

  // set translated GRLs to project assets
  _.forEach(
    { ...justTranslatedGroupLayoutDocuments, ...alreadyTranslatedGroupReusableLayouts, ...alreadyTranslatedChildGroupLayoutDocuments },
    (document, id) => _.set(updatedAssets.documents, id, document),
  );

  const dataToProcess: Models.ImportTranslationPackageArgs = {
    assets: updatedAssets,
    actionsWithMiddleware: [],
    isProjectTranslatable: true,
  };

  const translatedDataProcesses = sagaFlow<Models.ImportTranslationPackageArgs>(
    processes.removeDuplicateLayouts,
    processes.refreshLayoutsOnArtboards,
    processes.getActionsToImagesLoading,
    processes.getActionsToLayoutPreviewsLoading,
  );

  const { assets, actionsWithMiddleware }: ReturnTypeSaga<typeof translatedDataProcesses> = yield call(translatedDataProcesses, dataToProcess);

  yield call(initApp, { projectAssets: assets, actionsWithMiddleware });
}
