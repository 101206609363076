import React from 'react';
/* eslint-disable max-len */

const UpAndDownArrows = (): JSX.Element => (
  <svg width="25px" height="25px" viewBox="4 -2 2 23">
    <path d="M5,-2.66453526e-15 L10,5 L6,5 C6,8.96637875 6.00469971,8.95184326 6,8.99772848 L5,10 L6,11.0022715 C6.00469971,11.0481567 6,11.0336213 6,15 L6,15 L10,15 L5,20 L1.90070182e-13,15 L4,15 L4,11 L5,10 L4,9 L4,5 L-3.90798505e-14,5 L5,-2.66453526e-15 Z"/>
  </svg>
);

export default UpAndDownArrows;
