import Immutable from 'immutable';
import _ from 'lodash';

import { AssetAlignmentDirection, Styles } from 'const';
import { createTextStyles } from 'factories/style';
import * as Models from 'models';
import { getTextStylesFromBrandStyle, isBrandStyleAvailable, isDefaultBrandStyleAvailable } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { applyBrandStyle } from 'utils/editor/brandStyle';
import { toJS } from 'utils/immutable';
import * as inlineStylesUtils from 'utils/inlineStyles';
import * as matchBrandStyles from './matchBrandStyles';

/**
 * Check whether a style is applied to text component and this style exists in the project
 */
function brandStyleExists(brandStyleId: string, sectionStyles: Immutable.List<string>, brandStyles: Models.BrandStylesMap): boolean {
  return !!brandStyleId && isBrandStyleAvailable(brandStyles, sectionStyles, brandStyleId);
}

/**
 * Saves applied styles everywhere if it's possible and applies brand styles where no chance to save applied styles
 * As a result, mutates styles of the text relation
 */
export function matchAppliedStylesWithBrandStyles(
  textRelation: Models.RegularRelation<Models.TextRelationStyles>,
  brandStyle: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  const {
    fontSize,
    fontColor,
    fontFamily,
    fontStyle,
    alignment,
    lineHeight,
    padding,
    backgroundColor,
    bulletColor,
  } = textRelation.styles;

  const {
    fontSize: brandFontSize,
    fontColor: brandFontColor,
    fontFamily: brandFontFamily,
    characterStyle: brandCharacterStyle,
    lineHeight: brandLineHeight,
    textAlign: brandHorizontalAlignment,
    padding: brandPadding,
    verticalAlignment: brandVerticalAlignment,
    backgroundColor: brandBackgroundColor,
    bulletColor: brandBulletColor,
  } = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
  const {
    fontFamilyStyle: brandFontFamilyStyle,
    characterStyleNameStyle: brandFontStyleStyle,
  } = inlineStylesUtils.getFontFamilyStylesFromBrandStyle(brandFontFamily, brandCharacterStyle, fonts);
  const brandFontColorStyle = inlineStylesUtils.getPreferredColorStyle(brandFontColor, colors);
  const brandBackgroundColorName = brandBackgroundColor ? brandBackgroundColor.get('name') : null;
  const brandBulletColorStyle = inlineStylesUtils.getPreferredColorStyle(brandBulletColor, colors, true);

  let brandStyleChanged = false;

  // FONT SIZE
  const brandFontSizeStyle = inlineStylesUtils.getFontSizeStyle(brandFontSize);
  matchBrandStyles.matchFontSize(fontSize, brandFontSizeStyle);

  // FONT COLOR
  const {
    areSeveralColorsApplied: areSeveralFontColorsApplied,
    colorStyle: fontColorStyle,
  } = matchBrandStyles.matchColor(fontColor, colors, brandFontColorStyle);

  if (!brandStyleChanged && (areSeveralFontColorsApplied || fontColorStyle !== brandFontColorStyle)) {
    brandStyleChanged = true;
  }

  // FONT FAMILY and FONT STYLE
  const {
    areSeveralFontFamiliesApplied,
    fontFamilyStyle,
  } = matchBrandStyles.matchFontFamilyAndFontStyle(
    fontFamily,
    fontStyle,
    fonts,
    textRelation.styles,
    brandFontFamilyStyle,
    brandFontStyleStyle,
  );
  const { fontStyle: newFontStyle } = textRelation.styles;

  if (!brandStyleChanged && (areSeveralFontFamiliesApplied || fontFamilyStyle !== brandFontFamilyStyle)) {
    brandStyleChanged = true;
  }

  if (!brandStyleChanged) {
    if (brandFontStyleStyle) {
      if (fontFamily.length !== newFontStyle.length || newFontStyle.some(({ style }) => style !== brandFontStyleStyle)) {
        brandStyleChanged = true;
      }
    } else {
      if (newFontStyle.length !== 0) {
        brandStyleChanged = true;
      }
    }
  }

  // ALIGNMENT
  const verticalAlignment = alignment[AssetAlignmentDirection.VERTICAL];
  const horizontalAlignments = _.values(alignment[AssetAlignmentDirection.HORIZONTAL]);
  const horizontalAlignment = _.first(horizontalAlignments);

  if (!brandStyleChanged && (
    verticalAlignment !== brandVerticalAlignment
    || horizontalAlignment !== brandHorizontalAlignment
    || horizontalAlignments.some(inlineHorizontalAlignment => inlineHorizontalAlignment !== horizontalAlignment)
  )) {
    brandStyleChanged = true;
  }

  // BACKGROUND COLOR
  textRelation.styles.backgroundColor = matchBrandStyles.matchBackgroundColor(backgroundColor, colors, brandBackgroundColorName);

  if (!brandStyleChanged && textRelation.styles.backgroundColor !== brandBackgroundColorName) {
    brandStyleChanged = true;
  }

  // BULLET COLOR
  const {
    areSeveralColorsApplied: areSeveralBulletColorsApplied,
    colorStyle: bulletColorStyle,
  } = matchBrandStyles.matchColor(bulletColor, colors, brandBulletColorStyle, true);

  if (!brandStyleChanged && (areSeveralBulletColorsApplied || bulletColorStyle !== brandBulletColorStyle)) {
    brandStyleChanged = true;
  }

  // LINE HEIGHT
  const lineHeights = _.values(lineHeight);
  const currentLineHeight = _.first(lineHeights);

  if (!brandStyleChanged && (currentLineHeight !== brandLineHeight || lineHeights.some(height => height !== currentLineHeight))) {
    brandStyleChanged = true;
  }

  // PADDING
  if (!brandStyleChanged && !_.isEqual(padding, brandPadding.toJS())) {
    brandStyleChanged = true;
  }

  textRelation.styles = {
    ...textRelation.styles,
    brandStyleChanged,
    brandStyleId: brandStyle.get('id'),
  };
}

/**
 * Resets nonexistent brand style and saves applied styles everywhere if it's possible
 * NOTE: for now, always resets font family and font style to default value
 */
function resetBrandStyle(
  textRelationStyles: Models.TextRelationStyles,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  const { fontFamily, fontColor, fontStyle, fontSize, backgroundColor } = textRelationStyles;

  // BRAND STYLE
  textRelationStyles.brandStyleId = null;
  textRelationStyles.brandStyleChanged = false;

  // FONT FAMILY and FONT STYLE
  matchBrandStyles.matchFontFamilyAndFontStyle(
    fontFamily,
    fontStyle,
    fonts,
    textRelationStyles,
  );

  // FONT COLOR
  matchBrandStyles.matchColor(fontColor, colors);

  // FONT SIZE
  matchBrandStyles.matchFontSize(fontSize);

  // BACKGROUND COLOR
  textRelationStyles.backgroundColor = matchBrandStyles.matchBackgroundColor(backgroundColor, colors);
}

/**
 * Saves applied styles everywhere if it's possible and applies default brand styles where no chance to save applied styles
 * Reset styles, if the default brand styles are not available
 * As a result, mutates styles of the text relation
 */
function matchAppliedStylesWithDefaultStylesIfPossible(
  textRelation: Models.RegularRelation<Models.TextRelationStyles>,
  brandStyles: Models.BrandStylesMap,
  sectionStyles: Immutable.List<string>,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  if (isDefaultBrandStyleAvailable(brandStyles, sectionStyles)) {
    const defaultBrandStyle = brandStyles.get(Styles.DefaultTextBrandStyle);
    matchAppliedStylesWithBrandStyles(textRelation, defaultBrandStyle, colors, fonts);
  } else {
    resetBrandStyle(textRelation.styles, colors, fonts);
  }
}

/**
 * Resets all styles which could not be applied
 */
function validateSensitiveStyles(
  textComponent: Models.TextComponent | Models.TextComponentMap,
  textRelation: Models.RegularRelation<Models.TextRelationStyles>,
): void {
  let editorState = editorUtils.convertTextComponentToRawEditorState(textComponent);

  const failedOperations: string[] = [];
  const { alignment, fontColor, fontSize, fontFamily, lineHeight, fontStyle } = _.cloneDeep(textRelation.styles);
  const defaultTextStyles = createTextStyles();

  try {
    editorState = editorUtils.applyAlignment(editorState, alignment.horizontal);
  } catch (error) {
    failedOperations.push('alignment');
    textRelation.styles.alignment.horizontal = defaultTextStyles.alignment.horizontal;
  }

  try {
    editorState = editorUtils.applyLineHeight(editorState, lineHeight);
  } catch (error) {
    failedOperations.push('line-height');
    textRelation.styles.lineHeight = defaultTextStyles.lineHeight;
  }

  try {
    editorState = editorUtils.applyInlineStyleRanges(editorState, fontColor);
  } catch (error) {
    failedOperations.push('font color');
    textRelation.styles.fontColor = defaultTextStyles.fontColor;
  }

  try {
    editorState = editorUtils.applyInlineStyleRanges(editorState, fontSize);
  } catch (error) {
    failedOperations.push('font size');
    textRelation.styles.fontSize = defaultTextStyles.fontSize;
  }

  try {
    editorState = editorUtils.applyInlineStyleRanges(editorState, fontFamily);
  } catch (error) {
    failedOperations.push('font family');
    textRelation.styles.fontFamily = defaultTextStyles.fontFamily;
  }

  try {
    editorState = editorUtils.applyInlineStyleRanges(editorState, fontStyle);
  } catch (error) {
    failedOperations.push('font style');
    textRelation.styles.fontStyle = defaultTextStyles.fontStyle;
  }

  if (failedOperations.length > 0) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to save styles (${failedOperations.join(', ')})!`);
    // eslint-disable-next-line no-console
    console.log('DEBUG INFO: Text Component:', _.cloneDeep(toJS(textComponent)));
    // eslint-disable-next-line no-console
    console.log('DEBUG INFO: Editor State:', editorState.getCurrentContent().toJS());
    // eslint-disable-next-line no-console
    console.log('DEBUG INFO: Text Relation:', _.cloneDeep(textRelation));
  }
}

export function processBrandStyle(
  textComponent: Models.TextComponent | Models.TextComponentMap,
  textRelation: Models.RegularRelation<Models.TextRelationStyles>,
  brandStyles: Models.BrandStylesMap,
  sectionStyles: Immutable.List<string>,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
  originalTextComponent?: Models.TextComponent | Models.TextComponentMap,
): void {
  const { styles, styles: { brandStyleId, brandStyleChanged } } = textRelation;
  const styleExists = brandStyleExists(brandStyleId, sectionStyles, brandStyles);

  if (originalTextComponent) {
    textRelation.styles = editorUtils.adjustStylesForTextComponentDuplicate(styles, originalTextComponent, textComponent);
  }

  // TODO: check processing border colors everywhere

  if (styleExists) {
    const brandStyle = brandStyles.get(brandStyleId);
    if (brandStyleChanged) {
      matchAppliedStylesWithBrandStyles(textRelation, brandStyle, colors, fonts);
    } else {
      applyBrandStyle(textComponent, textRelation, brandStyle, colors, fonts);
    }
  } else {
    matchAppliedStylesWithDefaultStylesIfPossible(
      textRelation,
      brandStyles,
      sectionStyles,
      colors,
      fonts,
    );
  }

  // make sure that all styles are compatible and applicable to avoid application crash
  validateSensitiveStyles(textComponent, textRelation);
}
