import { IconType } from 'components/Icon/constants';
import { Title } from 'components/Toolbar/constants';
import { ControlOptions } from 'components/Toolbar/models';
import { ScriptType } from 'const';

export const ControlMap: Record<ScriptType, ControlOptions> = {
  [ScriptType.SUBSCRIPT]: {
    icon: IconType.TOOLBAR_SUBSCRIPT,
    title: Title.FONT_SUBSCRIPT,
  },
  [ScriptType.SUPERSCRIPT]: {
    icon: IconType.TOOLBAR_SUPERSCRIPT,
    title: Title.FONT_SUPERSCRIPT,
  },
};
