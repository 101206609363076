import { batchActions } from 'redux-batched-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import guid from 'uuid';

import { DocumentSavingStatus, EntityType, ModalType } from 'const';
import { unlockProjectContent } from 'containers/App/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { hideModal, showModal } from 'containers/ModalManager/actions';
import * as ModalManagerModels from 'containers/ModalManager/models';
import { deleteUploadingDocumentId, setUploadingDocumentId } from 'containers/Project/actions';
import { setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { replaceDocumentIdInRelations } from 'containers/Relations/actions';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { takeModalWindowResponse } from 'utils/takeModalWindowResponse';
import { addImage } from '../actions';
import { Action } from '../models';
import { updateEditedDocument } from '../services/updateEditedDocument';
import { updateImageDocumentWithRenditions } from '../services/updateImageDocumentWithRenditions';
import { addImage as addImageRequest } from './addImage';

export function* updateImage({ payload }: Action.IUpdateImage): SagaIterator {
  let uploadingDocumentId = guid();
  const documentSaveWindowId = guid();
  let documentDetailWindowId: string;
  let needToCloseDocumentDetailWindow = true;

  try {
    const { imageDataToUpload, id } = payload;
    const { source: sourceFile } = imageDataToUpload.imageFiles;
    const documents: ReturnTypeSaga<typeof documentsSelector> = yield select(documentsSelector);
    const imageToUpdate = documents.get(id) as Models.ImageMap;
    ({ modalWindowId: documentDetailWindowId } = payload);

    if (!sourceFile) {
      uploadingDocumentId = id;

      yield put(batchActions([
        hideModal(documentDetailWindowId),
        setUploadingDocumentId(uploadingDocumentId),
        setAssetIdToScroll(uploadingDocumentId),
      ]));

      yield call(updateImageDocumentWithRenditions, imageToUpdate, imageDataToUpload);

      return;
    }

    yield put(showModal(ModalType.DOCUMENT_SAVE, { entityType: EntityType.IMAGE, documentId: id }, documentSaveWindowId));

    const { payload: { response: actionType } }: ModalManagerModels.Action.IHideModal<DocumentSavingStatus> = yield take(
      takeModalWindowResponse(documentSaveWindowId),
    );

    yield put(hideModal(documentSaveWindowId));

    switch (actionType) {
      case DocumentSavingStatus.CREATE:
        uploadingDocumentId = yield* addImageRequest(addImage(documentDetailWindowId, imageDataToUpload));
        uploadingDocumentId && (yield put(replaceDocumentIdInRelations(id, uploadingDocumentId)));

        return;
      case DocumentSavingStatus.EDIT:
        function* updateDocument() {
          yield call(updateImageDocumentWithRenditions, imageToUpdate, imageDataToUpload);
        }

        function* replaceDocument(uploadingDocumentId: string) {
          yield put(replaceDocumentIdInRelations(id, uploadingDocumentId));
        }

        uploadingDocumentId = yield call(
          updateEditedDocument,
          id,
          sourceFile,
          updateDocument,
          replaceDocument,
          documentDetailWindowId,
        );

        return;
      default:
        needToCloseDocumentDetailWindow = false;

        return;
    }
  } catch (error) {
    yield call(handleSagaError, error, 'Documents.updateImage', 'UpdateImage');
  } finally {
    yield put(batchActions([
      deleteUploadingDocumentId(uploadingDocumentId),
      unlockProjectContent(),
      hideModal(documentSaveWindowId),
      ...(needToCloseDocumentDetailWindow ? [hideModal(documentDetailWindowId)] : []),
    ]));
  }
}
