import { EntityType } from 'const';
import * as Models from 'models';
import { getValue } from 'utils/getter';

export function isSpacerRelation(
  entity: Models.Relation
): entity is Models.RegularRelation<Models.SpacerRelationStyles>;
export function isSpacerRelation(
  entity: Models.RelationMap
): entity is Models.RegularRelationMap<Models.SpacerRelationStyles>;
export function isSpacerRelation(
  entity: Models.LayeredRelation
): entity is Models.LayeredRegularRelation<Models.SpacerRelationStyles>;
export function isSpacerRelation(
  entity: Models.LayeredRelationMap
): entity is Models.LayeredRegularRelationMap<Models.SpacerRelationStyles>;

export function isSpacerRelation(entity): boolean {
  return getValue(entity, 'entityType') === EntityType.SPACER;
}
