import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BrandStyle from 'components/Toolbar/Dropdowns/BrandStyle';
import { BrandStyleOwnProps, BrandStyleSelectors } from 'components/Toolbar/Dropdowns/BrandStyle/models';
import { projectBrandStyles, projectFlatColors, projectFlatFonts } from 'containers/BrandDefinition/selectors';

const mapStateToProps = createStructuredSelector({
  brandStyles: projectBrandStyles,
  colors: projectFlatColors,
  fonts: projectFlatFonts,
});

export default connect<BrandStyleSelectors, void, BrandStyleOwnProps>(mapStateToProps)(BrandStyle);
