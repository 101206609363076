
export type CellState = {
  cellHeightChanged: boolean;
  cellWidthChanged: boolean;
};

export type CellProps = CellState & {
  cellHeight: number;
  cellWidth: number;
  cellPosition: number;
  cellsCount: number;
  disableCellWidthEditing: boolean;
  maxCellHeight: number;
  minCellHeight: number;
  isAutoFitContent: boolean;
  isIncreasingWidthDisabled: boolean;
  isResizingRow: boolean;
  isLastCell: boolean;
};

type CellIsLastOptions = {
  cellPosition: number;
  cellsCount: number;
};

export function cellIsLast(options: CellIsLastOptions): boolean {
  const { cellPosition, cellsCount } = options;

  return cellPosition + 1 === cellsCount;
}
