import Immutable from 'immutable';
import _ from 'lodash';
import React from 'react';

import SpacerActions from 'components/SpacerActions';
import { Layer } from 'const/Layer';
import * as Models from 'models';
import { toPx } from 'utils/toPx';
import { SpacerProps } from './models';
import styles from './styles.module.scss';

const Spacer: React.FunctionComponent<SpacerProps> = (props) => {
  const {
    areActionsDisabled,
    connectDragSource,
    deleteLayout,
    layout,
    notEditable,
    relations,
    setMoveableLayout,
    updateRelations,
    scale,
  } = props;

  const layoutId = layout.get('id');
  const layoutRelationId = layout.get('relationId');
  const layoutRelation = relations.get(layoutRelationId) as Models.ColumnRelationMap;
  const rowRelationId = layoutRelation.get('relationIds').first<string>();
  const rowRelation = relations.get(rowRelationId) as Models.RowRelationMap;
  const relationId = rowRelation.get('relationIds').first<string>();
  const relation = relations.get(relationId) as Models.LayeredRegularRelationMap<Models.SpacerRelationStyles>;
  const container = React.createRef<HTMLDivElement>();
  // Spacer element stores styles on original layer only (styles are shared between layers)
  const layer = Layer.ORIGINAL;

  const deleteSpacer = React.useCallback(
    () => {
      deleteLayout(layoutId);
    },
    [layoutId],
  );

  const setMoveableAsset = React.useCallback(
    (isDraggable: boolean) => {
      setMoveableLayout(isDraggable ? layoutId : '');
    },
    [layoutId],
  );

  const [height, setHeight] = React.useState(() => relation.getIn(['styles', layer, 'height']));

  const resize = ({ clientY }: DragEvent): void => {
    const { bottom } = container.current.getBoundingClientRect();
    const change = bottom - clientY;
    const newHeight = height - change / scale;

    if (newHeight > 0) {
      setHeight(newHeight);
    }
  };

  const finishResize = () => {
    const updatedRelation = relation.setIn(['styles', layer, 'height'], height);

    updateRelations(Immutable.Map({ [updatedRelation.get('id')]: updatedRelation }) as Models.LayeredRegularRelationsMap);
  };

  return (
    <>
      <div
        className={styles.Spacer}
        style={{ height: toPx(height) }}
        ref={container}
      />
      {
        !notEditable &&
        <SpacerActions
          connectDragSource={connectDragSource}
          deleteLayout={deleteSpacer}
          finishResize={finishResize}
          areActionsDisabled={areActionsDisabled}
          resize={resize}
          setMoveableAsset={setMoveableAsset}
          spacerHeight={_.round(height)}
        />
      }
    </>
  );
};

export default Spacer;
