import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextAbbreviationDocumentsArray } from 'models';
import { selectAbbreviationsSortedData } from '../store/selectors';

export default function useFilteredAbbreviations(termToSearch: string | undefined): TextAbbreviationDocumentsArray {
  const textAbbreviationDocuments: TextAbbreviationDocumentsArray = useSelector(selectAbbreviationsSortedData);

  return useMemo(() => {
    if (!termToSearch) {
      return [];
    }

    return textAbbreviationDocuments
      .filter(
        ({ abbreviation }) => abbreviation?.term?.trim().toLowerCase() === termToSearch?.trim().toLowerCase(),
      );
  }, [termToSearch, textAbbreviationDocuments]);
}
